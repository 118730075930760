// Hook to load and supply the complete polygon catalogue.

import {useMemo, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useIntl, defineMessages} from 'react-intl';
import {loadPredefinedPolygonCatalogue, loadSavedPolygonCatalogue} from "../../../../../modules/polygon";
import {loadDataPackages} from "../../../../../modules/dataPackages/actions";

const messages = defineMessages({
    saved: {
        id: 'usePolygonCatalogue.saved',
        defaultMessage: 'Saved',
        description: 'Label for the Saved entry in the navigation list'
    },
    dataPackages: {
        id: 'usePolygonCatalogue.dataPackages',
        defaultMessage: 'Data packages',
        description: 'Label for the Data packages entry in the navigation list'
    },
    myPolygons: {
        id: 'usePolygonCatalogue.myPolygons',
        defaultMessage: 'My polygons',
        description: 'Label for the My polygons entry in the navigation list'
    },
    predefinedPolygons: {
        id: 'usePolygonCatalogue.predefinedPolygons',
        defaultMessage: 'Predefined polygons',
        description: 'Label for the Predefined polygons entry in the navigation list'
    }
});

export default function usePolygonCatalogue() {
    // Each of these has { result, loading, error } properties
    const dataPackages = useSelector(state => state.dataPackages.list);
    const savedCatalogue = useSelector(state => state.polygon.savedCatalogue);
    const predefinedCatalogue = useSelector(state => state.polygon.predefinedCatalogue);
    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadSavedPolygonCatalogue());
    },[dispatch]);

    useEffect(() => {
        dispatch(loadDataPackages());
    }, [dispatch]);

    useEffect(() => {
        if(!predefinedCatalogue.result) {
            dispatch(loadPredefinedPolygonCatalogue());
        }
    },[dispatch, predefinedCatalogue.result]);

    // Translate all of the messgaes into the current locale. We use a memo around this to avoid doing it on every render
    const [myPolygonsLabel, dataPackagesLabel, savedPolygonsLabel, predefinedPolygonsLabel] = useMemo(() => {
        return [
            intl.formatMessage(messages.myPolygons),
            intl.formatMessage(messages.dataPackages),
            intl.formatMessage(messages.saved),
            intl.formatMessage(messages.predefinedPolygons),
        ]
    }, [intl])

    // Build up each of the sections of the catalogue. We do each with its own memo, so that we don't rework that
    // part of the catalogue when another part finishes loading
    const packages = useMemo(() => {
        const result = {
            path: dataPackagesLabel,
            loading: dataPackages.loading,
            files: (dataPackages.result) || []
        };

        // The data packages are sorted by date, but as we are not going to display the date we need to switch over
        // to a name-based order. The original list belongs to the store, and we should not modify that, so we copy
        // the list before we sort it.
        if(result.files.length > 0) {
            result.files = result.files.slice();
            result.files.sort((a, b) => a.name.localeCompare(b.name));
        }

        return result;
    }, [dataPackages, dataPackagesLabel]);

    const saved = useMemo(() => {
        const result = {
            path: savedPolygonsLabel,
            loading: savedCatalogue.loading,
            files: (savedCatalogue.result && savedCatalogue.result.saved) || []
        };
        return result;
    },[savedCatalogue, savedPolygonsLabel]);

    const predefinedPolygons = useMemo(() => {
        const result = {
            path: predefinedPolygonsLabel,
            loading: predefinedCatalogue.loading,
            collections: (predefinedCatalogue.result && predefinedCatalogue.result.collections) || []
        };

        return result;
    }, [predefinedCatalogue, predefinedPolygonsLabel]);

    // Stitch all of the catalogue parts together
    const catalogue = useMemo(() => {
        const myPolygons = {
            path: myPolygonsLabel,
            collections: [ packages, saved ]
        };

        return {
            path: null,
            collections: [myPolygons, predefinedPolygons]
        }
    }, [myPolygonsLabel, packages, saved, predefinedPolygons]);

    const paths = {
        dataPackages: myPolygonsLabel + '/' + dataPackagesLabel,
        savedPolygons: myPolygonsLabel + '/' + savedPolygonsLabel,
        predefinedPolygons: predefinedPolygonsLabel
    }

    const collectionError = savedCatalogue.error || predefinedCatalogue.error || dataPackages.error;

    // Return the top-level catalogue, plus some meta-data about the paths, and the first error that we found (if any).
    return [catalogue, paths, collectionError];
}