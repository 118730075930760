// TabbedCarousel.js
import { Fragment, useState } from 'react';
import { osColour } from "omse-components";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormattedMessage } from 'react-intl';
import { Typography, Tabs, Tab, Select, MenuItem, useMediaQuery, useTheme, keyframes } from "@mui/material";
import ContentContainer from './ContentContainer';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const FadeInContainer = styled('div')`
    opacity: 0;
    transform: translateY(20px);
    animation: ${fadeInUp} 0.2s ease forwards;
`

const StyledTypography = styled(Typography)`
    h2& { 
        margin: 0 0 2em 0;
        color: ${osColour.primary.berry};
        text-align: center;
        font-family: OSGill, sans serif;
        @media (max-width: 600px) {
            text-align: left;
            font-size: 24px;
        }
    }
`;

const StyledTab = styled(Tab, { shouldForwardProp: prop => prop !== 'isActive' })(({ isActive }) => `
    padding: 0.6em 1.4em;
    min-height: 0;
    border: none;
    border-radius: 4em;
    font-size: 1rem;
    color: ${osColour.primary.berry};
    background: ${isActive ? osColour.primary.lightestBerry : osColour.neutral.clouds};
`);

const StyledTabs = styled(Tabs)`
    margin: 0 0 4em 0;
    & > div > div {
        padding: 0.5em 0;
        display: flex;
        flex-wrap: wrap;
        gap: 0.8em;
        justify-content: center;
    };
    & .MuiTabs-indicator {
        display: none;
    };
`;

const StyledSelect = styled(Select)`
    margin: 0 0 2em 0;
    padding: 0.8em 1.4em 0.6em 1.4em;
    width: 100%;
    border: none;
    border-radius: 4em;
    color: ${osColour.primary.berry};
    background: ${osColour.primary.lightestBerry};
    font-weight: 600;
    &:before {
        border-bottom: none;
    };
    &:after {
        border-bottom: none;
    };
    & svg {
        margin-right: 0.4em;
    };
`;

export default function TabbedCarousel({ items, title }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [ activeSlide, setActiveSlide ] = useState(0);

    const mobileControl = (
        <StyledSelect
            value={activeSlide}
            onChange={(e) => setActiveSlide(e.target.value)}
        >
            {items.map((item, i) => (
                <MenuItem key={item.title} value={i}>
                    {item.title}
                </MenuItem>
            ))}
        </StyledSelect>
    );

    const desktopControl = (
        <StyledTabs
            centered
            value={activeSlide}
            onChange={(e, value) => setActiveSlide(value)}
        >
            {items.map((item, i) => (
                <StyledTab key={item.title} label={item.title} isActive={i === activeSlide} />
            ))}
        </StyledTabs>
    );

    return (
        <ContentContainer>
            <StyledTypography variant="h1" component="h2">
                <FormattedMessage {...title} />
            </StyledTypography>

            {(isMobile)
                ? mobileControl
                : desktopControl
            }
            
            {items.map((item, i) => (
                <Fragment key={item.title}>
                    {activeSlide === i && <FadeInContainer>{item.content}</FadeInContainer>}
                </Fragment>
            ))}
       </ContentContainer>
    );
}

TabbedCarousel.propTypes = {
    title: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.element.isRequired,
    }))
};