import React from 'react';
import {Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {defineMessages, FormattedMessage} from 'react-intl';
import {contentPadding} from 'omse-components';
import StepList from '../../../components/stepList/StepList';
import StepListItem from '../../../components/stepList/StepListItem';

export const messages = defineMessages({
    title: {
        id: 'CreateRecipeInstructions.title',
        defaultMessage: 'How to use the OS Select+Build recipe creator',
        description: 'Title for the create recipe instructions panel'
    },
    stepRecipeNameTitle: {
        id: 'CreateRecipeInstructions.stepRecipeNameTitle',
        defaultMessage: 'Give your recipe a name',
        description: 'Title for the give your recipe a name step'
    },
    stepRecipeNameContent: {
        id: 'CreateRecipeInstructions.stepRecipeNameContent',
        defaultMessage: 'Give your recipe a name that is easily identifiable. You can also add a description to explain the purpose of the recipe.',
        description: 'Content for the give your recipe a name step'
    },

    stepSelectDataTitle: {
        id: 'CreateRecipeInstructions.stepSelectDataTitle',
        defaultMessage: 'Select your data',
        description: 'Title for the select data step'
    },
    stepSelectDataMainDescription: {
        id: 'CreateRecipeInstructions.stepSelectDataMainDescription',
        defaultMessage: 'Use the left hand panel to navigate the OS NGD Themes, Collections and Feature Types. {linebreak}You can select any of the following to include in your recipe:',
        description: 'Content for the select data step'
    },
    stepSelectDataThemesSubtitle: {
        id: 'CreateRecipeInstructions.stepSelectDataThemesSubtitle',
        defaultMessage: 'Themes',
        description: 'Select data themes subtitle'
    },
    stepSelectDataThemesContent: {
        id: 'CreateRecipeInstructions.stepSelectDataThemesContent',
        defaultMessage: 'All of the Collections and Feature Types in the Themes you select.',
        description: 'Content for select data themes section'
    },
    stepSelectDataCollectionsSubtitle: {
        id: 'CreateRecipeInstructions.stepSelectDataCollectionsSubtitle',
        defaultMessage: 'Collections',
        description: 'Collections subtitle'
    },
    stepSelectDataCollectionsContent: {
        id: 'CreateRecipeInstructions.stepSelectDataCollectionsContent',
        defaultMessage: 'Just the Feature Types in the Collections you select.',
        description: 'Content for the select data collections section'
    },
    stepSelectDataFeatureTypesSubtitle: {
        id: 'CreateRecipeInstructions.stepSelectDataFeatureTypesSubtitle',
        defaultMessage: 'Feature Types',
        description: 'Select data feature types subtitle'
    },
    stepSelectDataFeatureTypesContent: {
        id: 'CreateRecipeInstructions.stepSelectDataFeatureTypesContent',
        defaultMessage: 'Just the individual Feature Types you select.',
        description: 'Content for the select data feature types section'
    },
    stepSelectDataFeatureTypeFilterOptsSubtitle: {
        id: 'CreateRecipeInstructions.stepSelectDataFeatureTypeFilterOptsSubtitle',
        defaultMessage: 'Feature Type Filter Options',
        description: 'Select data feature type filter options subtitle'
    },
    stepSelectDataFeatureTypeFilterOptsContent: {
        id: 'CreateRecipeInstructions.stepSelectDataFeatureTypeFilterOptsContent',
        defaultMessage: 'You can limit the features from a Feature Type to just the specific filter values you set.',
        description: 'Content for the select data feature type filter options section'
    },

    stepCreateRecipeTitle: {
        id: 'CreateRecipeInstructions.stepCreateRecipeTitle',
        defaultMessage: 'Create your recipe',
        description: 'Title for the create recipe step'
    },
    stepCreateRecipeContent: {
        id: 'CreateRecipeInstructions.stepCreateRecipeContent',
        defaultMessage: 'Once you have made your selection, you can click the Create recipe button to create your recipe.',
        description: 'Content for the create recipe step'
    },
});

const useStyles = createUseStyles(theme => ({
    root: {
        paddingTop: contentPadding.top,
        paddingRight: contentPadding.right,
        paddingBottom: contentPadding.bottom,
        paddingLeft: contentPadding.left,
        overflow: 'auto',
        flex: '1 1 auto',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
            paddingTop: 0
        }
    },
    instructionsTitle: {
        marginBottom: theme.spacing(2)
    },
    step: {
        marginTop: -2, // Align step title with the number
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    subtitle: {
        marginBottom: 0,
    },
    stepSubsectionHeader: {
        paddingTop: theme.spacing(2)
    }
}));

export default function CreateRecipeInstructions({showTitle}) {
    const classes = useStyles();

    return <div className={classes.root}>
        {showTitle && <Typography variant='h2' color='primary' className={classes.instructionsTitle}>
            <FormattedMessage {...messages.title} />
        </Typography>}
        <StepList>
            <StepListItem classNameContent={classes.step}>
                <Typography variant='subtitle1' paragraph={true} color='primary' className={classes.subtitle}>
                    <FormattedMessage {...messages.stepRecipeNameTitle} />
                </Typography>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.stepRecipeNameContent} />
                </Typography>
            </StepListItem>
            <StepListItem classNameContent={classes.step}>
                <Typography variant='subtitle1' paragraph={true} color='primary' className={classes.subtitle}>
                    <FormattedMessage {...messages.stepSelectDataTitle} />
                </Typography>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.stepSelectDataMainDescription} values={{linebreak: <br/>}}/>
                </Typography>
                <Typography variant='body2' className={classes.stepSubsectionHeader}>
                    <FormattedMessage {...messages.stepSelectDataThemesSubtitle} />
                </Typography>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.stepSelectDataThemesContent} />
                </Typography>
                <Typography variant='body2' className={classes.stepSubsectionHeader}>
                    <FormattedMessage {...messages.stepSelectDataCollectionsSubtitle} />
                </Typography>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.stepSelectDataCollectionsContent} />
                </Typography>
                <Typography variant='body2' className={classes.stepSubsectionHeader}>
                    <FormattedMessage {...messages.stepSelectDataFeatureTypesSubtitle} />
                </Typography>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.stepSelectDataFeatureTypesContent} />
                </Typography>
                {/* Filtering is not supported yet. */}
                {/*<Typography variant='body2' className={classes.stepSubsectionHeader}>*/}
                {/*    <FormattedMessage {...messages.stepSelectDataFeatureTypeFilterOptsSubtitle} />*/}
                {/*</Typography>*/}
                {/*<Typography variant='body1'>*/}
                {/*    <FormattedMessage {...messages.stepSelectDataFeatureTypeFilterOptsContent} />*/}
                {/*</Typography>*/}
            </StepListItem>
            <StepListItem classNameContent={classes.step}>
                <Typography variant='subtitle1' paragraph={true} color='primary' className={classes.subtitle}>
                    <FormattedMessage {...messages.stepCreateRecipeTitle} />
                </Typography>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.stepCreateRecipeContent} />
                </Typography>
            </StepListItem>
        </StepList>
    </div>;
}