import React from "react";
import PropTypes from "prop-types";
import { defineMessages, FormattedMessage } from "react-intl";
import {createUseStyles} from 'react-jss';
import { Typography, Box, Button } from "@mui/material";
import { osColour } from 'omse-components';
import APIUsageChart  from "../APIUsageChart";
import Link from '../../../../../components/Link';
import routes from '../../../../../util/routes';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const messages = defineMessages({
    heading:{
        id: "eaiUsage.heading",
        defaultMessage: "Energy and infrastructure plan usage",
        description: "EAI Usage heading"
    },
    subtitle: {
        id:"eaiUsage.subtitle",
        defaultMessage: "Current plan",
        description: "EAI Usage subtitle"
    },
    demoProduct:{
        id:"eaiUsage.demoProduct",
        defaultMessage: "Indicative transactions based on £{unitPrice} per {name} transaction",
        description: "Text for the unit price of the "
    },
    purchase: {
        id:"eaiUsage.purchase",
        defaultMessage:"Add a plan to your account",
        description: "Eai Usage purchase transactions button"
    },
    purchaseMore: {
        id:"eaiUsage.purchaseMore",
        defaultMessage:"Purchase more transactions",
        description: "Eai Usage purchase more transactions button"
    },
    allPlans: {
        id:"eaiUsage.allPlans",
        defaultMessage: "See all my purchased plans",
        description: "Eai USage see all my purchased plans button"
    },
    remainingTransactions: {
        id: "eaiUsage.remainingTransactions",
        defaultMessage: "{transactions} API transactions remaining",
        description: "Text for the Eai remaining transactions"
    }
});

const useStyles = createUseStyles(theme => ({
    subtitle: {
        fontWeight: "Bold",
        color: osColour.primary.berry,
    },
    bolderTransactions:{
        fontSize: "2rem"
    },
    chartContainer:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    buttonContainer:{
        "& a": {
            textDecoration: "none"
        },
        "&>div":{
            width:265,
            marginBottom: theme.spacing(2)
        },
        "& svg": {
            marginRight: theme.spacing(1)
        },
        "& button": {
            width: "100%"
        }
    }
}));

export default function EaiUsage({ currentPlan, demoProduct, hasManageApiPermission }) {
    const classes = useStyles();

    //calculate: how many transactions plan purchased, how many transactions have been used, how many remaining, Product Tier information
    let purchasedTier, totalTransactions=1, remainingTransactions=1, usedTransactions=0
    if(currentPlan){
        purchasedTier = demoProduct.tiers.find(tier => {
            return tier.tierId===currentPlan.tierId;
        });

        if(!purchasedTier){
            return <Typography variant="body1">There was a problem calculating usage</Typography>
        }

        totalTransactions = Math.floor(currentPlan.openingBalance / purchasedTier.unitPrice);
        remainingTransactions = Math.floor(currentPlan.provisionalBalance / purchasedTier.unitPrice);
        usedTransactions = totalTransactions - remainingTransactions;
    }

    let data = {
        datasets: [
            {
                data: [usedTransactions, remainingTransactions],
                backgroundColor: [osColour.primary.foxglove, osColour.neutral.mist],
                hoverBackgroundColor: [osColour.primary.foxglove, osColour.neutral.mist]
            }
        ]
    };

    return (<Box>
        <Box></Box>
        <Typography variant="h2">
            <FormattedMessage {...messages.heading}/>
        </Typography>
        <Typography variant="caption" className={classes.subtitle}>
            <FormattedMessage {...messages.subtitle}/>
        </Typography>
        { currentPlan &&
            <Typography variant="body2">
                <FormattedMessage {...messages.remainingTransactions}
                                  values={{
                                      transactions: <span
                                          className={classes.bolderTransactions}>{remainingTransactions.toLocaleString()}</span>
                                  }}
                />
            </Typography>
        }
        <Box className={classes.chartContainer}>
            <APIUsageChart
                chartData={data}
                variant={"eai"}
                transactions={usedTransactions}
                limit={totalTransactions}
            />
            {purchasedTier &&
                <Typography variant="caption">
                    <FormattedMessage {...messages.demoProduct}
                                      values={{ unitPrice: purchasedTier.unitPrice, name: demoProduct.name }}/>
                </Typography>
            }
        </Box>
        {hasManageApiPermission &&
            <Box className={classes.buttonContainer}>
                <Box>
                    <Link path={routes.addApiPlan}>
                        <Button variant="contained" color="primary">
                            <AddCircleOutlineIcon />
                            {currentPlan ?
                                <FormattedMessage {...messages.purchaseMore}/> :
                                <FormattedMessage {...messages.purchase}/>
                            }

                        </Button>
                    </Link>
                </Box>
            </Box>
        }
    </Box>)
}

EaiUsage.defaultProps = {
    currentPlan: null,
    hasManageApiPermission:false
}

EaiUsage.propTypes = {
    currentPlan: PropTypes.object,
    demoProduct: PropTypes.object.isRequired,
    hasManageApiPermission:PropTypes.bool
}
