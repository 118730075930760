import React from 'react';
import menuMessages from '../appContent/MenuMessages';
import {createUseStyles} from 'react-jss';
import Typography from '@mui/material/Typography';
import {defineMessages, FormattedMessage} from 'react-intl';
import { osColour, InternalLink, ExternalLink } from 'omse-components';
import Header from '../documentation/Header';
import Document from '../documentation/Document';
import style from './styles/legal';
import routes from '../../util/routes';
import {tableStyles} from '../styles/common';
import classNames from 'classnames';
import useFeatureCheck from '../../util/useFeatureCheck';
import features from "../../../shared/features";

const messages = defineMessages({
    title: {
        id: 'SLA.title',
        defaultMessage: 'OS Data Hub Service Level Agreement (SLA)',
        description: 'title text'
    }
});

const useStyles = createUseStyles(style);
const useTableStyles = createUseStyles(tableStyles);
const useStylesSLA = createUseStyles(theme => ({
    content: {
        '& > div': {
            marginBottom: theme.spacing(4)
        }
    },
    withoutTranspose: {
        '& th': {
            whiteSpace: 'unset'
        },
        '& td': {
            [theme.breakpoints.down('sm')]: {
                display: 'table-cell'
            }
        }
    },
    transposes: {
        '& tr td > div':{
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            '& thead':{ 
                display: 'none'
            },
            '& tr': {
                border: 'none',
                marginBottom: theme.spacing(1),
                display: 'block'
            },
            '& tr td': {
                background: osColour.neutral.clouds,
            },
            '& tr td:first-child': {
                background: 'transparent',
                color: osColour.primary.berry,
                fontSize: '1.25rem',
                fontWeight: 'bold',
                paddingLeft: 0
            },
            '& tr td > div':{
                display: 'block',
                fontWeight: 'bold'
            }
        }
    }
}));

const useServices = () => {
    const services = [
        {name: 'OS Maps API', uptime: 99.9, sourceData: useFeatureCheck(features.NGD_FEATURES)? 15:20},
        {name: 'OS Vector Tile API', uptime: 99.9, sourceData: useFeatureCheck(features.NGD_FEATURES)? 15:20},
        {name: 'OS Features API', uptime: 99.9, sourceData: 10},
        {name: 'OS Linked Identifiers API', uptime: 99.9, sourceData: useFeatureCheck(features.NGD_FEATURES)? 5:10},
        {name: 'OS Names API', uptime: 99.9, sourceData: useFeatureCheck(features.NGD_FEATURES)? 5:10},
        {name: 'OS Places API', uptime: 99.9, sourceData: 10},
        {name: 'OS Match & Cleanse API', uptime: 99.9, sourceData: 10},
        {name: 'OAuth2 API', uptime: 99.9},
        {name: 'OS Download API', uptime: 99.9},
        {name: 'OS Data Hub', uptime: 99.9},
        {name: 'Errors and Omissions Tool', uptime: 99.9}
    ];
    if (useFeatureCheck(features.NGD_TILES)) {
        services.splice(0, 0,{name: 'OS NGD API – Tiles', uptime: 99.9});
    }
    if (useFeatureCheck(features.NGD_FEATURES)) {
        services.splice(0, 0,{name: 'OS NGD API – Features', uptime: 99.9});
    }


    return services;
}

export default function SLA() {
    const classes = useStyles();
    const classesSLA = useStylesSLA();
    const classesTable = useTableStyles();
    const services = useServices();
    return <Document>
        <Header back={true}>
            <FormattedMessage {...menuMessages.sla} />
        </Header>
        <div className={classNames(classes.content, classesSLA.content)}>
            <Typography variant='h2'>
                <FormattedMessage {...messages.title} />
            </Typography>
            <Typography variant='h5' component='div'>
                <p>This Service Level Agreement (SLA) sets out the service levels we provide for the OS Data Hub. For the terms and conditions for the OS Data Hub please see the <InternalLink path={routes.termsConditions} message="OS Data Hub Terms" />.</p>
                <p>The following <strong>Services</strong> are covered by this SLA</p>
                <table className={classNames(classes.table, classesSLA.withoutTranspose, classesTable.table)}>
                    <thead>
                        <tr>
                            <th><strong>Service name</strong></th>
                            <th><strong>Target Monthly Uptime Percentage</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {services.map(service => (
                            <tr key={service.name}>
                                <td>{service.name}</td>
                                <td>{service.uptime}%</td>
                            </tr>))
                        }
                    </tbody>
                </table>
            </Typography>
            <Typography variant='h4' component='h3'>
                Monthly Uptime Percentage
            </Typography>
            <Typography variant='h5' component='div'>
                <p>Monthly Uptime Percentage in respect of any calendar month shall be calculated as follows:</p>
                <ol type='a'>
                    <li>the total number of minutes in the calendar month concerned minus the number of minutes of all Downtime Periods occurring wholly during the calendar month concerned; divided by</li>
                    <li>the total number of minutes in the calendar month concerned, multiplied by 100.</li>
                </ol>
                <p>For the avoidance of doubt, for the purposes of calculating the Monthly Uptime Percentage, a single Downtime Period may not commence in one calendar month and end in a different calendar month.</p> 
                <p>Ordnance Survey will use reasonable commercial efforts to meet the target <strong>Monthly Uptime Percentage</strong> of all <strong>Services</strong>.</p>
            </Typography>
            <Typography variant='h4' component='h4'>
                Definitions
            </Typography>
            <Typography variant='h5' component='div'>
                <p>In respect of the Monthly Uptime Percentage calculations, the following meanings shall apply:</p>
                <ol type='a'>
                    <li>Downtime Period means in respect of the applicable OS URL for the respective service, a period of 10 or more consecutive minutes of Downtime (not including any Downtime caused as a result of any Excluded Downtime).</li>
                    <li>Downtime means a response time in excess of 3 seconds as measured using an applicable OS URL for the respective service.</li>
                    <li>Excluded Downtime means any Downtime attributable to any of the following:
                        <ol type='i'>
                            <li>“Planned Maintenance” as described below;</li>
                            <li>“Emergency Maintenance” as described in below; and / or</li>
                            <li>faults attributable to a third party, including hosting suppliers.</li>
                        </ol>
                    </li>
                </ol>
            </Typography>
            <Typography variant='h4'>
                Planned Maintenance
            </Typography>
            <Typography variant='h5' component='div'>
                <p>Ordnance Survey shall give customers at least 5 Working Days' prior notification via OS Data Hub in-app notification and email of any planned maintenance that is reasonably expected to affect the service.  <strong>Planned Maintenance</strong> is not considered <strong>Downtime</strong> for the purposes of this SLA and will not be counted towards any <strong>Downtime Periods</strong>.</p>
            </Typography>
            <Typography variant='h4'>
                Emergency Maintenance
            </Typography>
            <Typography variant='h5' component='div'>
                <p>On rare occasion where Ordnance Survey is unable to notify Customers of periods of service unavailability 5 <strong>Working Days</strong> prior to the commencement of such service unavailability, Ordnance Survey will notify Customers via OS Data Hub in-app notification and email of such service unavailability at the same time as when <strong>Emergency Maintenance</strong> is commenced as far as practicable.  <strong>Emergency Maintenance</strong> is not considered <strong>Downtime</strong> for the purposes of this SLA and will not be counted towards any <strong>Downtime Periods</strong>.</p>
            </Typography>
            <Typography variant='h4'>
                Valid Requests
            </Typography>
            <Typography variant='h5' component='div'>
                <p>Valid requests are requests to a <strong>Service</strong> that conform to the Documentation and that would normally result in a non-Error response.</p>
            </Typography>
            <Typography variant='h4'>
                SLA Exclusions
            </Typography>
            <Typography variant='h5' component='div'>
                <p>The SLA does not apply to any:</p>
                <ol type='a'>
                    <li>features designated Alpha, Beta, etc. (unless otherwise set forth in the associated Documentation or if the controlling Agreement specifically states the SLA applies);</li>
                    <li>features or <strong>Services</strong> excluded from this SLA (as stated in any associated Documentation); or</li>
                    <li>performance issues or errors:
                        <ol type='i'>
                            <li>caused by factors outside of Ordnance Survey's reasonable control;</li>
                            <li>that resulted from any actions or inactions of the Customer or any third parties;</li>
                            <li>that resulted from the Customer's or a third party’s software or hardware;</li>
                            <li>that resulted from abuses or other behaviours from the Customer that violate the Agreement;</li>
                            <li>that resulted from quotas applied by the system or listed in the Admin Console; or</li>
                            <li>that resulted from Customer use of the <strong>Service</strong> inconsistent with the Documentation, including but not limited to invalid requests, unauthorized use, or inaccessible data.</li>
                        </ol>
                    </li>
                </ol>
            </Typography>
            <Typography variant='h4'>
                Customer Support
            </Typography>
            <Typography variant='h5' component='div'>
                <p>Customer Support is available as follows:</p>
                <table className={classNames(classes.table, classesSLA.transposes, classesTable.table)}>
                    <thead>
                        <tr>
                            <th><strong>Method</strong></th>
                            <th><strong>Contact</strong></th>
                            <th><strong>Available</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Web</td>
                            <td><div>Contact:</div>Product support pages, Service Dashboard, Web Contact Form</td>
                            <td><div>Available:</div>24 x 7 x 52 (subject to the SLAs)</td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><div>Contact:</div>03453 75 75 35</td>
                            <td><div>Available:</div>During <strong>Business Hours</strong></td>
                        </tr>
                        <tr>
                            <td>Web Contact Form</td>
                            <td><div>Contact:</div><ExternalLink type='supportCaps' /></td>
                            <td><div>Available:</div>24 x 7 x 52 (subject to the SLAs)</td>
                        </tr>
                    </tbody>
                </table>
            </Typography>
            <Typography variant='h4'>
                Technical Monitoring
            </Typography>
            <Typography variant='h5' component='div'>
                <p>We provide 24 x 7 x 52 day monitoring of all API services</p>
            </Typography>
            <Typography variant='h4'>
                Service Dashboard
            </Typography>
            <Typography variant='h5' component='div'>
                <p>Current &amp; historical service status will be available via the <InternalLink path='/serviceStatus' message="Service Dashboard" />.</p>
            </Typography>
            <Typography variant='h4'>
                Working Day
            </Typography>
            <Typography variant='h5' component='div'>
                <p>Any day other than a Saturday, a Sunday, any public holiday in England or any day falling during the period between Christmas Day and New Year’s Day.</p>
            </Typography>
            <Typography variant='h4'>
                Business Hours
            </Typography>
            <Typography variant='h5' component='div'>
                <p>The hours between 08:30 and 17:30 (UK local time) on a <strong>Working Day</strong>.</p>
            </Typography>
            <Typography variant='h4'>
                Data currency
            </Typography>
            <Typography variant='h5' component='div'>
                <p>Ordnance Survey will update the source data within the <strong>Services</strong> each time updates are released in accordance with the release information shown on the OS website.</p>
                <table className={classNames(classes.table, classesSLA.withoutTranspose, classesTable.table)}>
                    <thead>
                        <tr>
                            <th><strong>OS API</strong></th>
                            <th><strong>Source Data update target</strong><br/>(max working days from dataset publication)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {services.filter(s => s.sourceData !== undefined).map(service => (
                            <tr key={service.name}>
                                <td>{service.name}</td>
                                <td>{service.sourceData}</td>
                            </tr>))
                        }
                    </tbody>
                </table>
            </Typography>
        </div>
    </Document>
}