import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import TextBox from "../../components/TextBox";
import {defineMessages, FormattedMessage} from 'react-intl';
import DeleteProductDialog from './product/DeleteProductDialog';
import {connect} from 'react-redux'
import {deleteProduct} from '../../modules/project/actions';
import BarGraph from "../../components/BarGraph";
import {osColour, border1, contentPadding, Notification, DropDownMenu} from 'omse-components';
import ProductDocumentation from '../../components/ProductDocumentation';
import ServiceTag from "../../components/ServiceTag";
import Button from "@mui/material/Button";
import {ReactComponent as RemoveIcon} from "../../components/icons/remove-icon.svg";
import classNames from 'classnames';
import {
    OPEN,
    PREMIUM_FREE,
    PREMIUM_CHARGEABLE,
    statsLabels,
    statsMessages,
    PSGA,
    PREMIUM_FREE_SECONDARY,
    INTERNAL,
    COMMERCIAL
} from '../../components/barGraph/styles/graph.js';
import {hasManageProjectsPermission} from "../../util/permissions";
import {USER_PSGA_PLAN} from "../../../shared/plans";
import {canUseProduct, hasFreeTrialNotActive} from '../../../shared/product-util';
import {ACTIVE} from '../../../shared/free-trial-state';
import {ProductUnlockFormattedMessage} from '../products/ProductFreeTrialMessage';
import {LIVE_MODE} from '../../../shared/project-modes';

const messages = defineMessages({
    docLink: {
        id: 'Product.docLink',
        defaultMessage: 'Documentation',
        description: 'Label for the product documentation link'
    },
    detailLink: {
        id: 'Product.detailLink',
        defaultMessage: 'Product Details',
        description: 'Label for the product documentation link'
    },
    missingEndpoint: {
        id: 'Product.missingEndpoint',
        defaultMessage: 'The endpoint address is not available. Please contact support.',
        description: 'Missing endpoint details message'
    },
    removeProduct: {
        id: 'Product.removeProduct',
        defaultMessage: 'Remove from project',
        description: 'Label for remove product action'
    },
    projectUsage: {
        id: 'Product.projectUsage',
        defaultMessage: '{count} of your total {projectTotal} project transactions',
        description: 'Label for project usage'
    },
    vtsEndpointHeading: {
        id: 'Product.vtsEndpointHeading',
        defaultMessage: 'VTS API Endpoint address',
        description: 'Label for the API Endpoint address'
    },
    wfsEndpointHeading: {
        id: 'Product.wfsEndpointHeading',
        defaultMessage: 'WFS API Endpoint address',
        description: 'Label for the API Endpoint address'
    },
    ngdEndpointHeading: {
        id: 'Product.ngdEndpointHeading',
        defaultMessage: 'NGD API Endpoint address',
        description: 'Label for the API Endpoint address'
    },
    wmtsEndpointHeading: {
        id: 'Product.wmtsEndpointHeading',
        defaultMessage: 'WMTS API Endpoint address',
        description: 'Label for the API Endpoint address'
    },
    zxyEndpointHeading: {
        id: 'Product.zxyEndpointHeading',
        defaultMessage: 'ZXY API Endpoint address',
        description: 'For raster ZXY enpoint'
    },
    zxySelectorLabel: {
        id: 'Product.zxySelectorLabel',
        defaultMessage: 'Select a Layer for ZXY Endpoint',
        description: 'Label for ZXY Selector'
    },
    defaultEndpointHeading: {
        id: 'Product.endpointHeading',
        defaultMessage: 'API Endpoint address',
        description: 'Label for the API Endpoint address'
    },
    expiredFreeTrial: {
        id: 'Product.expiredFreeTrial',
        defaultMessage: 'Your {productName} {freeTrialDays} days free trial has expired.',
        description: 'Label for the expired free trial notification'
    },
    unlockProduct: {
        id: 'Product.unlockProduct',
        defaultMessage: `To unlock call: {phone}, or email: {email}`,
        description: 'Label for the unlock product notification message'
    },
    inProgressFreeTrial: {
        id: 'Product.inProgressFreeTrial',
        defaultMessage: 'You have {daysRemaining} days remaining of your {productName} free trial.',
        description: 'Label for the in progress free trial notification'
    },
    everyTransactionChargedFor: {
        id: 'Product.everyTransactionChargedFor',
        defaultMessage: `Every {productName} transaction in Live mode will be charged for, there is no free data for this API.`,
        description: 'Label for notification informing users every transaction is charged for for this product'
    }
});

function styles(theme) {
    return {
        root: {
            maxWidth: contentPadding.maxWidth,
            borderBottom: border1,
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),

            '&:last-of-type': {
                border: 0
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(2),
                paddingLeft: 0,
                paddingRight: 0
            }
        },
        header: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start'
        },
        titleHeader: {
            display: 'flex',
            flexWrap: 'wrap-reverse'
        },
        namePanel: {
            flex: '1 0 auto',
            marginRight: 12,
            '& h2': {
                marginBottom: theme.spacing(2)
            }
        },
        docsPanel: {
            flex: '1 0 auto',
            '& a:last-child': {
              marginRight: 8
            },
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                flex: 'initial'
            }
        },
        heading: {
            marginTop: theme.spacing(2)
        },
        serviceChip: {
            marginBottom:theme.spacing(1)
        },
        deleteButton: {
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(1)
            },
            padding: 0,
            '& svg': {
              color: osColour.neutral.stone,
              marginRight: 2
            },
            '&:hover': {
                background: 'transparent',

                '& svg': {
                    color: osColour.status.error
                },

                '& p': {
                    color: osColour.status.error,
                }
            }
        },
        deleteLabel: {
            paddingLeft: theme.spacing(0.5)
        },
        serviceTag: {
            marginLeft: theme.spacing(2)
        },
        tags: {
            marginBottom: theme.spacing(1),
            '& div:first-child': {
                marginLeft: 0
            }
        },
        layerSelectorField: {
            '&[type=button]': {
                backgroundColor: osColour.neutral.clouds,
                border: '1px solid ' + osColour.neutral.mist,
                color:  theme.palette.textPrimary.main,
                padding: '12px 16px',
                borderRadius: 3
            }
        },
        freeTrialNotification: {
            '&.inline': {
                marginBottom: theme.spacing(1),
                borderWidth: 0,
                borderLeftWidth: theme.spacing(0.5),
                paddingRight: theme.spacing(1),
                opacity: .8
            }
        },
        freeTrialNotificationWarning: {
            '&.inline': {
                // 29 = opacity, !important because notification sets inline background style which has high specificity.
                background: osColour.status.warning + '29 !important',
            }
        },
        freeTrialNotificationInfo: {
            '&.inline': {
                // 29 = opacity, !important because notification sets inline background style which has high specificity.
                background: osColour.status.info + '29 !important',
            }
        },
        freeTrialMessage: {
            display: 'inline',
            // Break message parts to separate lines when they start to overflow too much, otherwise looks cramped.
            [theme.breakpoints.down('lg')]: {
                display: 'inline-block'
            }
        }
    };
}

export class Product extends Component {
    state = {
        deleting: false,
        layer: 'Road_27700'
    };

    handleClose = () => {
        this.setState({deleting: false});
    };

    deleteProduct = () => {
        const {deleteProduct, projectId, product} = this.props;
        deleteProduct(projectId, product.id);
    };

    displayProductStats = () => {
        let {productStats, projectTotalUsage, userDetails, product} = this.props;

        // Some products (like the Downloads API) don't count stats, so there is no point showing a graph for them
        if(product.suppressStats && product.suppressStats !== "false") {
            return null;
        }

        // When stats are not loaded, we are passed an explict flag to tell us that they are not available yet
        if (productStats === -1) {
            return null;
        }

        // We know that stats are loaded, but there might not be any data recorded for this product. If we need to,
        // we put in an empty set of stats, to avoid loads of null checks later.
        if (!productStats) {
            productStats = { commercial:0, open: 0, psga: 0, internal: 0, premium: {total: {transactions: 0, price: 0}, free: 0, chargeable: 0} };
        }

        let totalUsage = 0;
        let breakdown = [];

        const allDataSets = {
            open: {
                name: statsLabels.open,
                data: productStats.open,
                class: OPEN
            },
            premiumFree: {
                name: statsLabels.premium.free,
                data: productStats.premium.free,
                class: PREMIUM_FREE
            },
            premiumChargeable: {
                name: statsLabels.premium.chargeable,
                data: productStats.premium.chargeable,
                class: PREMIUM_CHARGEABLE
            },
            psga: {
                name: statsLabels.psga,
                data: productStats.psga,
                class: PSGA
            },
            commercial:{
                name: statsLabels.commercial.live,
                data: productStats.commercial,
                class: COMMERCIAL
            },
            internal: {
                name: statsLabels.internal,
                data: productStats.internal,
                class: INTERNAL
            }
        }

        for (let dataType in allDataSets) {
            totalUsage += allDataSets[dataType].data;
            breakdown.push(allDataSets[dataType]);
        }

        if (userDetails.plan === USER_PSGA_PLAN) {
            allDataSets.premiumFree.class = PREMIUM_FREE_SECONDARY;
        }

        const graphData = [{total: totalUsage, breakdown:breakdown}];
        return <BarGraph graphData={graphData}
                         total={projectTotalUsage}
                         count={totalUsage}
                         countLabel={{...statsMessages.countLabel}}
                         displayCountLabel={true}
                         displayCount={true}
                         variant={'small'}
                         graphStyle={{height: 14, marginTop: 4}}
                />
    };

    handleLayerChange = (item) => {
        this.setState({layer: item.value});
    }

    render() {
        const {product, classes, projectMode, userDetails} = this.props;
        const { deleting, layer } = this.state;
        let zxyEndpointWithLayer = (product.zxyEndpoint) ? product.zxyEndpoint.replace("LAYER", layer) : "";
        let apiEndpoint;
        let rasterZxyEndpoint;

        const layers = [
            {value: "Road_27700", label: "Road 27700"},
            {value: "Road_3857", label: "Road 3857"},
            {value: "Outdoor_27700", label: "Outdoor 27700"},
            {value: "Outdoor_3857", label: "Outdoor 3857"},
            {value: "Light_27700", label: "Light 27700"},
            {value: "Light_3857", label: "Light 3857"},
            {value: "Leisure_27700", label: "Leisure 27700"}
        ];

        const sharedEndpointTextBoxProps = {
            text: product.endpoint || messages.missingEndpoint,
            labelId: product.name + ' Endpoint',
            disabled: !canUseProduct(product)
        };
        switch (product.serviceType) {
            case "NGD":
                apiEndpoint = <div>
                    <TextBox {...sharedEndpointTextBoxProps} label={messages.ngdEndpointHeading} />
                </div>
                break;
            case "Features":
                apiEndpoint = <div>
                        <TextBox {...sharedEndpointTextBoxProps} label={messages.wfsEndpointHeading} />
                    </div>
                break;
            case "Raster tiles":
                apiEndpoint = <div>
                        <TextBox {...sharedEndpointTextBoxProps} label={messages.wmtsEndpointHeading} />
                    </div>
                rasterZxyEndpoint = <div>
                        <TextBox {...sharedEndpointTextBoxProps}
                                 text={zxyEndpointWithLayer || messages.missingEndpoint}
                                 label={messages.zxyEndpointHeading} />
                            <DropDownMenu
                                label={messages.zxySelectorLabel}
                                buttonId='layer-selector-control'
                                buttonLabel={ layers[0].label }
                                buttonVariant='text'
                                buttonFontWeight='normal'
                                buttonClassName={classes.layerSelectorField}
                                arrowType='filled'
                                placement='bottom'
                                variant='block'
                                onChange={this.handleLayerChange}
                                value={this.state.layer}
                                items={layers}
                            />
                    </div>
                break;
            case "Vector tiles":
                apiEndpoint = <div>
                        <TextBox {...sharedEndpointTextBoxProps} label={messages.vtsEndpointHeading} />
                    </div>
                break;
            case "Search":
                apiEndpoint = <div>
                        <TextBox {...sharedEndpointTextBoxProps} label={messages.defaultEndpointHeading} />
                    </div>
                break;
            default:
                apiEndpoint = <div>
                        <TextBox {...sharedEndpointTextBoxProps} label={messages.defaultEndpointHeading} />
                    </div>
                break;
        }

        return <div className={classes.root}>
            <div className={classes.titleHeader}>
                <div className={classes.namePanel}>
                    <Typography variant='h2'>
                        {product.name}
                    </Typography>
                </div>
                <div className={classes.tags}>
                    {product.serviceType && <ServiceTag classes={{serviceTag: classes.serviceTag}} label={product.serviceType}/>}
                    {product.subServiceType && <ServiceTag classes={{serviceTag: classes.serviceTag}} label={product.subServiceType}/>}
                </div>
            </div>
            <div className={classes.header}>
                <div className={classes.docsPanel}>
                      <ProductDocumentation productName={product.name} productServiceType={product.serviceType} productSubServiceType={product.subServiceType}/>
                </div>
                {hasManageProjectsPermission(userDetails) &&
                <Button className={classes.deleteButton}
                        onClick={() => this.setState({deleting: true})}>
                    <RemoveIcon width={15} height={15} viewBox="3 3 18 18"/>
                    <Typography className={classes.deleteLabel} variant={'body2'}>
                        <FormattedMessage {...messages.removeProduct}/>
                    </Typography>
                </Button>
                }
            </div>

            {product.freeTrialState === ACTIVE && <Notification className={classNames(classes.freeTrialNotification,
                                                                                      classes.freeTrialNotificationInfo)}
                                                                appearance='inline'
                                                                variant='info'>
                <Typography variant='body2' className={classes.freeTrialMessage} component='strong'>
                    <FormattedMessage {...messages.inProgressFreeTrial} values={{
                        productName: product.name,
                        daysRemaining: product.freeTrialDaysRemaining,
                    }} />
                </Typography>
                {' ' /* Ensure space exists between components. */}
                <Typography variant='body1' className={classes.freeTrialMessage}>
                    <ProductUnlockFormattedMessage />
                </Typography>
            </Notification>}

            {hasFreeTrialNotActive(product) && <Notification className={classNames(classes.freeTrialNotification,
                                                                                   classes.freeTrialNotificationWarning)}
                                                             appearance='inline'
                                                             variant='warning'>
                <Typography variant='body2' className={classes.freeTrialMessage} component='strong'>
                    <FormattedMessage {...messages.expiredFreeTrial} values={{
                        productName: product.name,
                        freeTrialDays: product.freeTrialDays
                    }} />
                </Typography>
                {' ' /* Ensure space exists between components. */}
                <Typography variant='body1' className={classes.freeTrialMessage}>
                    <ProductUnlockFormattedMessage />
                </Typography>
            </Notification>}

            {projectMode === LIVE_MODE && product.noFreeQuota && <Notification className={classNames(classes.freeTrialNotification,
                                                                                                     classes.freeTrialNotificationInfo)}
                                                                               appearance='inline'
                                                                               variant='info'>
                <Typography variant='body2' component='strong'>
                    <FormattedMessage {...messages.everyTransactionChargedFor} values={{
                        productName: product.name
                    }} />
                </Typography>
            </Notification>}

            {this.displayProductStats()}
            {apiEndpoint}

            {rasterZxyEndpoint}

            {deleting && <DeleteProductDialog closed={this.handleClose} confirmed={this.deleteProduct} productName={product.name}/>}
        </div>;
    }
}

Product.propTypes = {
    product: PropTypes.object.isRequired,
    projectId: PropTypes.string.isRequired,
    projectMode: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        userDetails: state.user.current.result
    };
}

const style = withStyles(styles)(Product);
export default connect(mapStateToProps, {deleteProduct}) (style);
