import {ACTION_RESET_SUFFIX} from 'omse-components';

export const PREFIX = "action:dataPackages:";
export const CREATE_DATA_PACKAGE_ACTION = PREFIX + 'create';
export const LOAD_DATA_PACKAGES_ACTION = PREFIX + 'load';
export const LOADED_DATA_PACKAGES_ACTION = PREFIX + 'loaded';
export const LOAD_DATA_PACKAGE_ACTION = PREFIX + 'loadPackage';
export const LOADED_DATA_PACKAGE_ACTION = PREFIX + 'loadedPackage';
export const LOAD_DATA_PACKAGE_VERSION_ACTION = PREFIX + 'loadPackageVersion';
export const LOADED_DATA_PACKAGE_VERSION_ACTION = PREFIX + 'loadedPackageVersion';
export const SET_DATA_PACKAGE_NAME_ACTION = PREFIX + 'setName';
export const DELETE_DATA_PACKAGE_ACTION = PREFIX + 'delete';
export const STOP_DATA_PACKAGE_UPDATES_ACTION = PREFIX + 'stopUpdates';
export const SET_PAGE_SIZE_ACTION = PREFIX + 'setPageSize';
export const RESET_DRAFT_ORDER_ACTION = PREFIX + 'reset_draft_order_action';
export const UPDATE_DRAFT_ORDER_ACTION = PREFIX + 'update_draft_order_action';
export const UPDATE_DATA_PACKAGE_DOWNLOAD_FORMAT_ACTION = PREFIX + 'updateFormat';
export const GET_DATA_PACKAGE_PRICE_ACTION = PREFIX + 'loadPrice';


export function createDataPackage(order) {
    return {
        type: CREATE_DATA_PACKAGE_ACTION,
        order: order
    };
}

export function clearCreateDataPackage() {
    return {
        type: CREATE_DATA_PACKAGE_ACTION + ACTION_RESET_SUFFIX
    };
}

export function loadDataPackages() {
    return {
        type: LOAD_DATA_PACKAGES_ACTION
    };
}

export function loadDataPackage(packageId) {
    return {
        type: LOAD_DATA_PACKAGE_ACTION,
        packageId
    };
}

export function clearLoadedDataPackage() {
    return {
        type: LOADED_DATA_PACKAGE_ACTION,
        result: null
    };
}

export function loadDataPackageVersion(packageId, versionId) {
    return {
        type: LOAD_DATA_PACKAGE_VERSION_ACTION,
        packageId,
        versionId
    };
}

export function setDataPackageName(packageId, name) {
    return {
        type: SET_DATA_PACKAGE_NAME_ACTION,
        packageId,
        name
    };
}

export function deleteDataPackage(packageId) {
    return {
        type: DELETE_DATA_PACKAGE_ACTION,
        packageId
    };
}

export function stopDataPackageUpdates(packageId) {
    return {
        type: STOP_DATA_PACKAGE_UPDATES_ACTION,
        packageId
    };
}

export function setDownloadsPageSize(size) {
    return {
        type: SET_PAGE_SIZE_ACTION,
        size
    }
}

export function resetDraftOrder(resetAll) {
    return {
        type: RESET_DRAFT_ORDER_ACTION,
        resetAll
    }
}

export function updateDraftOrder(name, value) {
    return {
        type: UPDATE_DRAFT_ORDER_ACTION,
        name,
        value
    }
}

export function updateDataPackageDownloadFormat(dataPackage) {
    return {
        type: UPDATE_DATA_PACKAGE_DOWNLOAD_FORMAT_ACTION,
        dataPackage
    }
}

export function getDataPackagePrice(dataPackage) {
    return {
        type: GET_DATA_PACKAGE_PRICE_ACTION,
        dataPackage
    }
}

export function deleteDataPackagePrice() {
    return {
        type:GET_DATA_PACKAGE_PRICE_ACTION + ACTION_RESET_SUFFIX
    }
}
