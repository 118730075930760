import routes, {getLocation} from "../../util/routes";
import {createActionReducer, createActionMiddleware} from 'omse-components';
import {DELETE_PROJECT} from "./actions";
import {withOrganisation} from '../../util/organisation';

export const reducer = createActionReducer(DELETE_PROJECT);

function setupCall(action, store) {
    return withOrganisation({
        url: '/api/projects/' + action.projectId,
        method: 'DELETE'
    }, store);
}
function success(store) {
    // Now that the project has been updated, navigate to the projects list.
    const history = store.getState().history;
    const newLocation = getLocation(routes.projects, history.location);
    history.push(newLocation);
}

export const middleware = createActionMiddleware(DELETE_PROJECT, setupCall, success);
