import routes, {getLocation} from "../../util/routes";
import {createActionReducer, createActionMiddleware, modules} from 'omse-components';
import {closeNewProjectDialog, CREATE_PROJECT, LOADED_PROJECT_ACTION} from "./actions";
import {withOrganisation} from '../../util/organisation';
const {LOADED_USER_ACTION} = modules.actions.user;

export const reducer = createActionReducer(CREATE_PROJECT);

function prepareCall(action, store) {    
    return withOrganisation({
        url: '/api/projects',
        method: 'POST',
        body: {
            product: action.productName,
            name: action.projectName
        }
    }, store);
}
function success(store, action, project) {
    let user = store.getState().user.current.result;

    if (user.newUser) {
        //When created first project reload user to update newUser flag
        user.newUser = false;
        store.dispatch({
            type: LOADED_USER_ACTION,
            result: user
        });
    }
    // Store the project
    store.dispatch({
        type: LOADED_PROJECT_ACTION,
        result: project
    });

    // Close dialog
    store.dispatch(closeNewProjectDialog());

    // Now that the project has been created, navigate to it.
    const history = store.getState().history;
    const location = history.location;

    const pathname = routes.project.replace(':projectId', project.id);
    const newLocation = getLocation(pathname, location);
    newLocation.state = {
        projectName: project.name,
        title: project.name
    };

    history.push(newLocation);
}

export const middleware = createActionMiddleware(CREATE_PROJECT, prepareCall, success);
