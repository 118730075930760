import styled from "@emotion/styled";
import Heading from "./Heading";
import CardGallery from "./CardGallery";
import RecentItems from "./RecentItems";
import { useMediaQuery } from "@mui/material";
import { theme } from "omse-components";
import HeadingOrgPlan from "./HeadingOrgPlan";

const OverviewContainer = styled("div")`
    max-width: 60em;
`;

export default function WorkspaceOverview() {
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isTablet = useMediaQuery(theme.breakpoints.down(1315));
    return (
        <OverviewContainer data-testid="workspace-overview">
            <Heading />
            {isTablet && <HeadingOrgPlan />}
            {!isMobile && <CardGallery />}
            <RecentItems />
        </OverviewContainer>
    );
}
