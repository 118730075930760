import { getMainMenu } from '../pages/appContent/menu/menu-main';
import featureCheck from "./featureCheck";
import { getUserDetailsDefaultContext } from './organisation';

export function canShowToUser(item, userDetails, orgId) {
    if (!item.userCheck && !item.orgCheck) {
        return true;
    }
    if (item.userCheck) {
        return item.userCheck(userDetails);
    }
    if (item.orgCheck) {
       return item.orgCheck(userDetails, orgId);
    }
    return;
}

/**
 * Inline style for an element being aligned with the lower edge of 
 * a given ref, filling any remaining vertical viewport space.
 * 
 * @param {object} ref to the element being aligned with.
 * @param {number} paddingTop (optional)
 * @returns {object} which may be used as an inline style.
 */
 export function getAlignFillStyle(ref, paddingTop = 0) {
    if (ref) {
        let offset = 0; 
        let height = '100%';
        const rect = ref?.current?.getBoundingClientRect();
        if (rect) {
            offset = rect.y + rect.height;
            height = `calc(100vh - ${offset}px - ${paddingTop}px)`;
        }
        return {top: offset, height: height};
    } else {
        return {};
    }
}

export function routeIsInMenu(menu, route) {
    const search = item => item.route === route || (item.subMenu && item.subMenu.find(search));
    return menu.find(search);
}

export function findMenuItem(user, route, config) {
    const isValidRoute = route?.path;
    
    if (!isValidRoute) {
        return; 
    }

    const isMatchingRoute = (item) => {
        const isExactRoute = item.route === route;
        const isMatchingPath = item.route && route.path.startsWith(item.route.path);

        return isExactRoute || isMatchingPath || hasMatchingMenu(item.menu) || hasMatchingSubMenu(item.subMenu);
    };

    const hasMatchingMenu = (menu) => menu?.some(isMatchingRoute);
    const hasMatchingSubMenu = (subMenu) => subMenu?.some(isMatchingRoute);

    const mainMenu = getMainMenu(user, config);
    // To retain the "Workspace" first position in header nav we reverse the array first to 
    // find last matching menu object as there is now multiple menus which share common submenu items
    return mainMenu.reverse().find(isMatchingRoute);
}

export function filterTab(item, userDetails, config) {
    let displayTab = true;
    if (item.feature) {
        if (!featureCheck(item.feature, config)) {
            displayTab = false;
        }
    }
    if (item.userCheck) {
        if (!item.userCheck(userDetails)) {
            displayTab = false;
        }
    }
    return displayTab;
}

// use route attr of tab menu item if set, otherwise
// use initRoute or route attr of first menu entry
export function getTabRoute(tab, userDetails, config) {
    if (tab.route) {
        return tab.route;
    }
    const tabMenu = tab.menu.filter(item => filterTab(item, userDetails, config));
    if (tabMenu && tabMenu.length > 0 && (tabMenu[0].route || tabMenu[0].initRoute)) {
        return tabMenu[0].initRoute? tabMenu[0].initRoute : tabMenu[0].route;
    }
}

export function getTabLabels(userDetails, config) {
    const defaultContextUserDetails = getUserDetailsDefaultContext(userDetails);
    const tabItems = getMainMenu(defaultContextUserDetails, config).filter(item => filterTab(item, defaultContextUserDetails, config));
    return tabItems.filter(tabItems =>  {
        const tabRoute = getTabRoute(tabItems, defaultContextUserDetails, config);
        return ((userDetails && userDetails.active) || (!userDetails && tabRoute && tabRoute.public));
    }).map(tabItems => tabItems.label);
}

export function findTab(userDetails, tabLabel, config) {
    if (tabLabel) {
        const search = item => item.label && item.label.defaultMessage === tabLabel;
        return getMainMenu(userDetails, config).find(search);
    }
}

export function findTabLabel(userDetails, route, config) {
    if (route) {
        let menuItem = findMenuItem(userDetails, route, config);
        if (menuItem && menuItem.label) {
            return menuItem.label.defaultMessage;
        } else {
            return null;
        }
    }
}

export function shouldUseResponsiveMenu(totalTabs) {
    const width = window.innerWidth;
    if (totalTabs >= 8) {
        return width < 1651;
    }
    switch (totalTabs) {
        case 7: 
            return width < 1341;
        case 6: 
            return width < 1241;
        default:
            return width < 960;
    }
}