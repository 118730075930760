import React from 'react';
import {FormattedDate} from 'react-intl';

// get date from which to query
export function getDateFrom(filterFrom = null) {
    const date = new Date();
    if (filterFrom === 'last30Days') {
        date.setMonth(date.getMonth() - 1);
    } else if (filterFrom === 'yearToDate') {
        date.setMonth(0);
        date.setDate(0);
        date.setHours(23, 59, 59, 999);
    } else if (filterFrom === 'monthToDate') {
        date.setDate(0);
        date.setHours(23, 59, 59, 999);
    } else if (filterFrom === 'previousMonth') {
        date.setMonth(date.getMonth() - 1);
        date.setDate(0);
        date.setHours(23, 59, 59, 999);
    } else {
        // last 12 months
        date.setMonth(date.getMonth() - 12);
    }
    return date.toISOString();
}

// get date for the end of the last month
export function getDateTo() {
    const date = new Date();
    date.setMonth(date.getMonth());
    date.setDate(0);
    date.setHours(23, 59, 59, 999);
    return date.toISOString();
}

// current formatter to pounds in 2dp
export const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2
  })

// date formatter to style dd MMM YYYY
export function dateFormater(date) {
	return <FormattedDate value={date} day='numeric' month='short' year='numeric' />;
}

// convert amount from pence to pounds
export function formatCost(cost) {
	if (!cost) {
		return '0.00';
	}
	return (cost / 100).toFixed(2);
}