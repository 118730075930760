import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import Typography from '@mui/material/Typography';
import styles from './style';
import Table from './Table';

class ZoomLevels extends Component {
    constructor(props) {
        super(props);

        if (props.srid === 'EPSG:3857') {
            this.state = {
                levels: this.buildLevels(7, 1222.992452562495, 4622324.434309)
            }
        } else {
            this.state = {
                levels: this.buildLevels(0, 896, 3386449.92, 27700)
            }
        }
    }

    buildLevels(zoom, resolution, scale, tileMatrix) {
        let result = [];
        for (let i = 0; i < 14; i++) {
            result[i] = {
                zoom,
                resolution: this.format(resolution, tileMatrix),
                scale: this.formatScale(scale),
                open: i < 10,
                openLeisure: i < 6,
                unavailableLeisure: i > 9
            };
            zoom++;
            resolution /= 2;
            scale /= 2;
        }
        return result;
    }

    /*
    The 27700 scale is our own custom scale. For this reason we must never round the numbers as users must have exact values
    for their calculations.

    The 3857 scale is a known public scale. For this scale we can perform some rounding to make numbers look nicer as users
    can find completely precise numbers elsewhere if they want them.
    */

    format(number, typeTileMatrix) {
        if (typeTileMatrix === 27700) {
            return number.toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 20
            });
        } else {
            if (number > 10) {
                return Math.round(number).toLocaleString();
            }
            if (number > 1) {
                return number.toFixed(1);
            }
            return number.toFixed(2);
        }
    }

    formatScale(number) {
        if (number > 10) {
            return Math.round(number).toLocaleString();
        }
        if (number > 1) {
            return number.toFixed(1);
        }
        return number.toFixed(1);
    }

    render() {
        const {levels} = this.state;
        const {srid} = this.props;

        const is27700 = srid === 'EPSG:27700';

        return <Fragment>
            <Typography variant='h3'>
                {srid} Tile Matrix Set
            </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The table below shows the resolution (meters per pixel) and scale of the zoom levels within
                    the {srid} tile matrix set.
                    The resolution is calculated at a DPI of 96.
                    The table also shows which zoom levels are OS OpenData, and which are Premium data.
                </p>
                {srid === 'EPSG:3857' &&
                <p>
                    The zoom levels start at 7 to fit in with the industry standard initial zoom layer.
                    This improves the interoperability of this tile matrix set with third party tools and applications.
                </p>
                }
                {is27700 &&
                <p>
                    The zoom levels start at 0, rather than the 7 used for the EPSG:3857 tile matrix set, but the range
                    of resolutions & scales is comparable.
                    EPSG:27700 zoom level 0 is similar to EPSG:3857 zoom level 7.
                </p>
                }
                <Table>
                    <tbody>
                        <tr>
                            <th>Zoom level</th>
                            <th>Resolution</th>
                            <th>Scale</th>
                            <th>Road, Outdoor and Light styles</th>
                            {is27700 && <th>Leisure style</th>}
                        </tr>
                        {
                            levels.map(level =>
                                <tr key={level.zoom}>
                                    <td>{level.zoom}</td>
                                    <td>{level.resolution}</td>
                                    <td>1:{level.scale}</td>
                                    <td>{level.open ? 'Open data' : 'Premium data'}</td>
                                    {is27700 && <td>{leisureDescription(level)}</td>}
                                </tr>
                            )
                        }
                        </tbody>
                </Table>
            </Typography>
        </Fragment>

        function leisureDescription(level) {
            if (level.openLeisure) {
                return 'Open data';
            }
            if (level.unavailableLeisure) {
                return 'n/a';
            }
            return 'Premium data';
        }

    }

}

ZoomLevels.propTypes = {
    classes: PropTypes.object.isRequired,
    srid: PropTypes.oneOf(['EPSG:3857', 'EPSG:27700']).isRequired
}

export default withStyles(styles)(ZoomLevels);
