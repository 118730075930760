import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { osColour } from 'omse-components';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const StyledCard = styled('article')(
    ({ accent }) => `
    margin: 0;
    padding: 1.6em;
    border: 1px solid ${osColour.neutral.mist};
    border-top: 3px solid ${accent};
    background: ${osColour.neutral.white};
    & > h3 {
        font-family: OSGill, sans-serif;
    }
`
);

export default function PlanCard(p) {
    return (
        <StyledCard accent={p.accent}>
            {p.tag}
            <Typography
                variant='h2'
                component="h3"
                gutterBottom={true}
                sx={{
                    color: osColour.neutral.charcoal,
                    marginTop: '0.6em'
                }}
            >
                <FormattedMessage {...p.title} />
            </Typography>
            <Typography variant='body1' sx={{ color: osColour.neutral.charcoal }}>
                <FormattedMessage {...p.summary} />
            </Typography>
        </StyledCard>
    );
}

PlanCard.propTypes = {
    tag: PropTypes.element.isRequired,
    accent: PropTypes.string.isRequired,
    title: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired,
    }).isRequired,
    summary: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired,
    }).isRequired,
};
