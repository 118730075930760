import React, {Fragment, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Button, Typography} from '@mui/material';
import routes, {getLocation} from "../../util/routes";
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import ContentHeader from '../../components/ContentHeader';
import {defineMessages, FormattedMessage} from 'react-intl';
import { contentPadding, InputBox, InputLabel, ExternalLink } from "omse-components";
import {createUseStyles} from 'react-jss';
import {hasManageCompanyDetailsPermission, hasViewCompanyDetailsPermission} from "../../util/permissions";
import ChangeCompanyNameDialog from "./companyInformation/ChangeCompanyNameDialog";
import {USER_OS_INTERNAL_PLAN} from '../../../shared/plans';
import NavButton from "../../components/NavButton";
import { messages as userMessages } from '../../constants/user';

const messages = defineMessages({
    accountLink: {
        id: 'CompanyInformation.backLink',
        defaultMessage: "Account settings",
        description: "Label for the back link on the company information page"
    },
    title: {
        id: 'CompanyInformation.title',
        defaultMessage: 'Company information',
        description: 'Title for the company information page'
    },
    subtitle: {
        id: 'CompanyInformation.subtitle',
        defaultMessage: 'Your company',
        description: 'Subtitle for the company information page'
    },
    organisationName: {
        id: 'CompanyInformation.organisationName',
        defaultMessage: 'Organisation name / Company trading name',
        description: 'Label for the user org name'
    },
    organisationRole: {
        id: 'CompanyInformation.organisationRole',
        defaultMessage: 'Your role in this organisation',
        description: 'Label for display users role'
    },
    change: {
        id: 'CompanyInformation.change',
        defaultMessage: 'Request a change of name',
        description: 'Label for the button that allows Admins to change the company name'
    },
    changeNeedsAdmin: {
        id: 'CompanyInformation.changeNeedsAdmin',
        defaultMessage: 'You do not have permission to change the company name. If the name is incorrect please contact your Organisation admin.',
        description: 'Message shown when the user does not have permission to change the company name'
    },
    nameChangeRequested: {
        id: 'CompanyInformation.nameChangeRequested',
        defaultMessage: 'An admin has requested that the company name be changed to "{newOrgName}". We are reviewing the request. To check on the progress of the request please {link}.',
        description: 'Message shown when a name change has been requested'
    }
});

const styles = createUseStyles(function (theme) {
    return {
        content: {
            flex: '1 1 auto',
            paddingLeft: contentPadding.left,
            paddingRight: contentPadding.right,
            paddingBottom: contentPadding.bottom,
            maxWidth: contentPadding.maxWidth,
            marginTop: theme.spacing(8),
            '& > div': {
                marginTop: theme.spacing(2)
            },
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile,
                margin: 0
            }
        },
        subtitle: {
            marginBottom: theme.spacing(2)
        },
        afterInput: {
            marginTop: theme.spacing(2)
        }
    };
});

export default function CompanyInformation() {
    const classes = styles();
    const history = useHistory();
    const loading = useSelector(state => state.user.current.loading);
    const userDetails = useSelector(state => state.user.current.result);
    const orgName = useSelector(state => state.user.current.result && state.user.current.result.org);
    const newOrgName = useSelector(state => state.user.current.result && state.user.current.result.orgNameRequest);
    const [changing, setChanging] = useState(false);
    const canChange = hasManageCompanyDetailsPermission(userDetails);

    if(!loading && !hasViewCompanyDetailsPermission(userDetails)) {
        // The user is not on the premium plan so they should not see this page. Take them back to the main account page.
        const newLocation = getLocation(routes.account, history.location);
        history.replace(newLocation);
    }

    let content;
    if(loading) {
        content = <CircularProgress size={32}/>

    } else if(!userDetails.org && userDetails.plan === USER_OS_INTERNAL_PLAN) {
        content = <Fragment>
            <Typography variant='body1' paragraph={true}>
                You are not a member of an organisation. You can set up OS Data Hub projects for your own use, but you
                cannot invite other users to collaborate with you. If you set up a new organisation then you will become
                the organisation admin, and then you will be able to invite other OS users to join you.
            </Typography>
            <NavButton color='primary'
                        variant='contained'
                        path={routes.premiumSetup}>Set up a new organisation</NavButton>
        </Fragment>

    } else {
        content = <>
            <InputBox id='organisationName'
                      value={orgName}
                      label={messages.organisationName}
                      disabled={true}/>

            { newOrgName && <Typography variant='body1' className={classes.afterInput}>
                <FormattedMessage {...messages.nameChangeRequested} values={{
                    newOrgName, link: <ExternalLink type='support' />
                }} />
            </Typography> }

            { !newOrgName && !canChange && <Typography variant='body1' className={classes.afterInput}>
                <FormattedMessage {...messages.changeNeedsAdmin}/>
            </Typography> }

            { !newOrgName && canChange && <Button className={classes.afterInput}
                                                  onClick={e => setChanging(true)}
                                                  variant='contained'
                                                  color='primary'>
                <FormattedMessage {...messages.change}/>
            </Button> }

            {(userDetails && userDetails.role) &&
                <div>
                    <InputLabel>
                        <FormattedMessage {...messages.organisationRole} />
                    </InputLabel>
                    <Typography variant='body1'>
                        <FormattedMessage {...userMessages[userDetails.role]} />
                    </Typography>
                </div>
            }
        </>
    }

    return <>
        <ContentHeader 
            backPath={routes.account} 
            backLabel={messages.accountLink} 
            title={messages.title} />
        <div className={classes.content}>
            <Typography variant='h2' color='primary' className={classes.subtitle}>
                <FormattedMessage {...messages.subtitle} />
            </Typography>
            {content}
        </div>
        { changing && <ChangeCompanyNameDialog onClose={() => setChanging(false)}/> }
    </>;
}
