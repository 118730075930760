import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getHistoryStats, HISTORY_STATS_INTERVAL_WEEK, HISTORY_STATS_INTERVAL_MONTH, HISTORY_STATS_INTERVAL_YEAR} from "../../modules/stats/actions";
import {getProjects} from "../../modules/projects";
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {FormControl, Tabs, Tab, Typography} from '@mui/material';
import HistoryGraph from "./HistoryGraph";
import {createUseStyles} from 'react-jss';
import StatsTimestamp from '../../components/StatsTimestamp';
import {osColour, border1, contentPadding, InputLabel, DropDownMenu} from 'omse-components';
import ClickAwayTooltip from '../../components/ClickAwayTooltip';
import ModeControl from "../../components/ModeControl";
import useMode from "../../hooks/useMode";
import useStatsInterval from "../../hooks/useStatsInterval";
import useStatsProjectId from "../../hooks/useStatsProjectId";
import {hasManageProjectModePermission} from "../../util/permissions";

const messages = defineMessages({
    mapTitle: {
        id: 'TransactionHistory.mapTitle',
        defaultMessage: 'Map views',
        description: 'Title for the map views graph'
    },
    featureTitle: {
        id: 'TransactionHistory.featureTitle',
        defaultMessage: 'Feature requests',
        description: 'Title for the feature requests graph'
    },
    intervalLabel: {
        id: 'TransactionHistory.intervalLabel',
        defaultMessage: 'Time interval',
        description: 'Label for the week/month/year choice'
    },
    projectLabel: {
        id: 'TransactionHistory.projectLabel',
        defaultMessage: 'Project',
        description: 'Label for the project choice'
    },
    whatIsMapLabel: {
        id: 'TransactionHistory.whatIsMapLabel',
        defaultMessage: 'What is a map view?',
        description: 'Label for What is a map view tooltip'
    },
    whatIsMapBody: {
        id: 'TransactionHistory.whatIsMapBody',
        defaultMessage: 'A map view represents 15 map tiles returned from a raster mapping API, or 4 map tiles from a vector tile API.',
        description: 'Content for What is a map view tooltip'
    },
    whatIsFeatureRequestLabel: {
        id: 'TransactionHistory.whatIsFeatureRequestLabel',
        defaultMessage: 'What is a feature request?',
        description: 'Label for What is a feature request tooltip'
    },
    whatIsFeatureRequestBody: {
        id: 'TransactionHistory.whatIsFeatureRequestBody',
        defaultMessage: 'A feature request is a single call to a Feature API, OS Names API or OS Linked Identifiers API.',
        description: 'Content for What is a feature request tooltip'
    }
});

const styles = createUseStyles(theme => ({
    content: {
        flex: '1 1 auto',
        paddingLeft: contentPadding.left,
        paddingRight: contentPadding.right,
        paddingBottom: contentPadding.bottom,
        maxWidth: contentPadding.maxWidth,
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile,
            marginTop: 0
        }
    },
    heading: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    },
    statsLabel: {
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        minHeight: 36,
        padding: '0 0 12px 0',
        [theme.breakpoints.down('sm')]: {
            float: 'left',
            marginRight: theme.spacing(0.5)
        }
    },
    transactionControls: {
        borderBottom: border1,
        marginBottom: theme.spacing(1.5)
    },
    formControl: {
      minWidth: 81,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 0 8px 0',
      minHeight: 36
    },
    projectMenu: {
        borderRadius: 0
    },
    dateTabs: {
        display: 'flex',
        flex: '1 0 auto',
        border: 'none',
        boxShadow: 'none'
    },
    tabsRoot: {
        minHeight: 36
    },
    dateTabsFlexContainer: {
        justifyContent: 'flex-end',
        padding: '6px 0'
    },
    tabRoot: {
        padding: theme.spacing(0, 1.5, 1, 1.5),
        minWidth: 'auto',
        minHeight: '36px !important',
        opacity: 1,
        ...theme.typography.body1,
        color: osColour.primary.berry,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        '&:hover': {
            color: osColour.primary.lighterBerry
        },
        '&:hover:not($tabSelected), &:focus': {
            boxShadow: '0 3px 0 0 #fff, 0 6px 0 0 ' + osColour.primary.lighterBerry
        }
    },
    tabSelected: {
        ...theme.typography.body2,
        color: osColour.primary.berry,
        boxShadow: '0 3px 0 0 #fff, 0 6px 0 0 #645baf'
    },
    indicator: {
        display: 'none'
    },
    menuItem: {
        padding: '6px 20px',
        margin: 6
    }
}));

export default function TransactionHistory(props) {
    const [interval, setInterval] = useStatsInterval();
    const [projectId, setProjectId] = useStatsProjectId();
    const [mode] = useMode();
    const dispatch = useDispatch();
    const projects = useSelector(state => state.projects.current.result || []);
    const historyStats = useSelector(state => state.stats.history);
    const userDetails = useSelector(state => state.user.current.result);
    const orgId = useSelector(state => state.organisation.current.id);
    const intl = useIntl();
    const classes = styles();

    useEffect(() => {
        dispatch(getHistoryStats(interval, projectId, mode, orgId));
    }, [dispatch, interval, projectId, mode, orgId]);

    useEffect(() => {
        dispatch(getProjects());
    }, [dispatch, orgId]);

    function renderHistory() {
        const historyResult = historyStats.result;
        let result = [];

        if(historyResult && historyResult.mode === mode && historyResult.productTypes && (!orgId || (orgId && historyResult.orgId === orgId))) {
            result.push(<HistoryGraph key='map'
                interval={historyResult.interval}
                historyStats={historyResult.productTypes.map}
                graphMode={historyResult.mode}
                graphTitle={messages.mapTitle}
                graphTooltip={<ClickAwayTooltip id='whatIsMap'
                    label={messages.whatIsMapLabel} 
                    body={messages.whatIsMapBody} />} 
            />);
            result.push(<HistoryGraph key='feature'
                interval={historyResult.interval}
                historyStats={historyResult.productTypes.feature}
                graphMode={historyResult.mode}
                graphTitle={messages.featureTitle}
                graphTooltip={<ClickAwayTooltip id='whatIsFeatureReq'
                    label={messages.whatIsFeatureRequestLabel} 
                    body={messages.whatIsFeatureRequestBody} />} 
            />);
        }
        return result;
    }

    const tabs = [
        {value: HISTORY_STATS_INTERVAL_WEEK, label: 'Week'},
        {value: HISTORY_STATS_INTERVAL_MONTH, label: 'Month'},
        {value: HISTORY_STATS_INTERVAL_YEAR, label: 'Year'}
    ];
    const tabClasses = {
        root: classes.tabRoot,
        selected: classes.tabSelected
    };
    return <Fragment>
        {
            hasManageProjectModePermission(userDetails) &&
                <ModeControl/>
        }
        <div className={classes.content}>
            <div className={classes.transactionControls}>
                <Typography variant='subtitle1' component='h2' className={classes.heading}>
                    <div className={classes.statsLabel}>
                        <FormattedMessage defaultMessage='API statistics for'
                            id='TransactionHistory.heading' 
                            description='Heading for the users transaction history' 
                        />
                    </div>
                    <FormControl className={classes.formControl}>
                        <InputLabel id='projectLabel' htmlFor='project-menu' hidden={true}>
                            {intl.formatMessage(messages.projectLabel)}
                        </InputLabel>
                        <DropDownMenu 
                            buttonClasses={{root: classes.projectMenu}}
                            buttonFontWeight='bold'
                            buttonId='project-menu'
                            arrowType='filled'
                            arrowSize='medium'
                            value={projectId}
                            onChange={(item) => setProjectId(item.value)}
                            strategy='fixed'
                            variant='block'
                            placement='bottom'
                            items={[
                                {label: 'All projects', value: ''},
                                ...projects.map(project => ({label: project.name, value: project.projectId}))
                            ]}
                        />
                    </FormControl>

                    <Tabs className={classes.dateTabs}
                          aria-label='tabs to select date range'
                          value={interval}
                          onChange={(event, value) => setInterval(value)}
                          classes={{root: classes.tabsRoot, indicator: classes.indicator, 
                            flexContainer: classes.dateTabsFlexContainer}}>
                        {tabs.map(tab =>
                            <Tab classes={tabClasses}
                                disableTouchRipple                                
                                value={tab.value}
                                label={tab.label}
                                key={tab.label} />)
                        }
                    </Tabs>
                </Typography>
            </div>
            <StatsTimestamp stats={historyStats}/>
            {renderHistory()}
        </div>
    </Fragment>
}

TransactionHistory.propTypes = {}
