import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function MatchSearchTips({searchType, specificSearchType = searchType}) {
    return <>
        <Typography variant='h4'>Tips to improve your {searchType} results</Typography>
        <Typography variant='body1' paragraph={true} component='div'>
            <ul>
                <li>
                    {_.upperFirst(specificSearchType)} results can be greatly
                    improved by including commas to separate your search terms e.g. ‘Ordnance Survey, 4, Adanac Drive,
                    Nursling, Southampton, SO16 0AS’
                </li>
                <li>
                    Make sure the address elements in your search are in the correct order.
                </li>
                <li>
                    Make sure you provide enough information when searching for an address. Remember that
                    postcodes can cover up to 99 properties, therefore including a street name and building
                    name/number will narrow down the search results.
                </li>
                <li>
                    Don't over complicate your search with unnecessary elements e.g. ‘Room 301, Level 3,
                    Organisation Name, Building Name, Street, Postcode’ includes information that is not held in
                    the database (Room 301, Level 3) and could be removed from the search to help improve the
                    results.
                </li>
                <li>
                    Remember that organisations move or change names, therefore if your results are not
                    satisfactory, consider only searching on Building Name, Street and Postcode instead.
                </li>
                <li>
                    Make sure that postcodes in your search are current as they can be terminated. One option
                    for this is to use the Office for National Statistics Postcode Directory (ONSPD). ONSPD
                    identifies out-of-date postcodes with the date of termination (doterm attribute), where,
                    when populated, provides the date when the postcode was terminated.
                </li>
                <li>
                    Use the Country code filter parameter to limit results from specific countries only.
                </li>
            </ul>
        </Typography>
    </>;
}

MatchSearchTips.propTypes = {
    searchType: PropTypes.string,
    specificSearchType: PropTypes.string
}

MatchSearchTips.defaultProps = {
    searchType: 'match'
}