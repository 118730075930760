import React from 'react';
import Header from "./Header";
import Content from './Content';
import Typography from '@mui/material/Typography';
import DocLinks from "./DocLinks";
import figure1 from "./namesOverview/Figure1.jpg";
import Document from './Document';

export default function NamesOverview(props) {
    return <Document>
        <Header back={true}>OS Names API: Overview</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The OS Names API is a geographic directory containing basic information about identifiable places.
                </p>
                <p>
                    The OS Names API is a reliable way of supporting the discovery or identification and visualisation of a named place;
                    geocoding; routing and navigation, and linking diverse information such as statistics or descriptions.
                    OS Names can locate a feature using just its name, or it can find the closest location to a given point.
                </p>
            </Typography>
            <img src={figure1} alt='OS Names API: medium-scale map of Newcastle with districts and POIs selected.'/>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>Using the OS Names API,</p>
                <p>You can:</p>
                <ul>
                    <li>Search for areas, cities and roads.</li>
                    <li>Search for postcodes.</li>
                    <li>Search for named woodlands and forests.</li>
                </ul>
                <p>You can't:</p>
                <ul>
                    <li>Search for specific addresses (e.g. 21 Croxton Road).</li>
                    <li>Bulk-search for addresses.</li>
                </ul>
            </Typography>
            <DocLinks product='names' hasGettingStarted={true} hasTechnicalSpec={true} hasDemo={true}/>
        </Content>
    </Document>
}
