import React from 'react';
import Header from "./Header";
import Content from './Content';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import ResponseCodes from "./ResponseCodes";
import styles from './style';
import routes from "../../util/routes";
import Authentication from "./Authentication";
import DocLinks from "./DocLinks";
import Document from './Document';
import FeatureCheck from "../../components/FeatureCheck";
import {SpanTable} from "./CalculateRowSpan";
import OTAFeatureContent from "./OTAFeatureContent";
import { ExternalLink, InternalLink } from 'omse-components';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

const responseCodes = [
    [200, 'OK', 'Request was successful.'],
    [400, 'The request was not supported', 'For example, missing query parameter, malformed syntax.'],
    [401, 'Unauthorized', 'The client has not provided authentication or incorrect authentication.'],
    [404, 'Not Found', 'The server has not found anything matching the Request-URI.'],
    [406, 'Not Supported', 'A request header value was not supported.'],
    [504, 'Service Unavailable', 'Temporary outage due to overloading or maintenance.']
];

const endpointRow = {
    'Landing Page': {"/":['General information about the service and provides links to all available endpoints.']},
    'Conformance declaration':{'/conformance':[<>List of all conformance classes specified in the <ExternalLink type="generic" href="https://docs.ogc.org/is/20-057/20-057.html" message="OGC API – Tiles standard" /> that the API conforms to.</>,]},
    'API Definition':{'/api':['Metadata about the API itself and its implementation.']},
    'TileMatrixSets':{
        '/tilematrixsets':['List of available tile matrix sets.'],
        '/tilematrixsets/{tilematrixsetid}':['Definition of the specified tile matrix set.'],
    },
    'Collections': {
        '/collections':[<>List of all available collections in the API for example, <InlineCodeSnippet>ngd-base</InlineCodeSnippet>.</>],
        '/collections/{collectionId}': ['Information about the specified collection.'],
    },
    'Styles': {
        '/collections/{collectionId}/styles':['List of available styles for the specified collection.'],
        '/collections/{collectionId}/styles/{styleid}':['Style sheet for the specified collection.'],
    },
    'Tileset': {
        '/collections/{collectionId}/tiles':['List of available tilesets for the specified collection.'],
        '/collections/{collectionId}/tiles/{tilesetid}':['Tileset metadata for the specified collection and tileset.'],
    },
    'Tile': {
        '/collections/{collectionId}/tiles/{tilesetid}/{tilematrix}/{tilerow}/{tilecol}': ['Retrieve a vector tile for the specified collection.']
    }
}

export function OTADetail() {
    return <FeatureCheck feature='ngdTiles'><Document>
        <Header back={true}>OS NGD API – Tiles: Technical specification</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains the content of OS NGD API – Tiles and the methods that you can use to call it.
                    If you are not familiar with the API, you may want to read
                    the <InternalLink
                        path={routes.otaOverview}
                        message="overview documentation"
                    /> before proceeding.
                    Using OS NGD API – Tiles requires a basic knowledge of interacting with web-based products and
                    code libraries, such as OpenLayers, MapLibre GL JS and Leaflet
                </p>
                <p>
                    A vector tile service combines the benefits of pre-rendered raster map tiles with vector map data.
                    Compared to an un-tiled vector map, the data transfer size is significantly reduced because only
                    data within the current viewport at the current zoom level is transferred.
                </p>
                <p>
                    This data can be accessed, styled, and interrogated through a web-based application that is
                    accessible via a browser.
                </p>
            </Typography>
            <OTAFeatureContent />
            <br />
            <Typography variant='h2'>Technical Detail</Typography>
            <Typography variant='body1' paragraph={true}>
                OS NGD API – Tiles requests are structured using keyword-value pair (KVP) parameter encoding and HTTP
                {' '}<InlineCodeSnippet>GET</InlineCodeSnippet>. All requests must be encoded correctly according to standard percent-encoding
                procedures for a URI.
            </Typography>
            <Authentication gettingStartedRoute={routes.otaIntro}/>
            <Typography variant='h2'>Encryption</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    All calls must be made using a secure hypertext encryption (HTTPS).
                </p>
                <p>
                    Ordnance Survey APIs no longer support calls made using SSL v2/v3 or TLS v1.0/v1.1.
                    As of October 2018, and in line with current cyber security recommendations, calls made to any of
                    our APIs must support TLS v1.2.
                </p>
            </Typography>
            <ResponseCodes codes={responseCodes}/>

            <Typography variant='h2'>Endpoints</Typography>
            <Typography variant='body1' component='div'>
                <SpanTable headers={['Resource', 'Path', 'Description']} spanData={endpointRow} useCodeSnippet={true} />
            </Typography>
            <Typography variant='body1' paragraph={true}>
                You can freely discover the available collections without an API key up to a Tile endpoint request.
            </Typography>
            <Typography variant='h2'>Tile Matrix Sets</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                    OS NGD API – Tiles supports Web Mercator (EPSG: 3857) and British National Grid (BNG) EPSG:27700
                    Tile Matrix Sets.  All tiles supplied by the API default to Web Mercator (EPSG: 3857),
                    unless a path parameter is specified.
            </Typography>
            <DocLinks product='ota' hasGettingStarted={true} hasDemo={true}/>
        </Content>
    </Document>
    </FeatureCheck>
}



export default withStyles(styles)(OTADetail);
