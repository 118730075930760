import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles(theme => {
    return {
        faq: {
            marginBottom: theme.spacing(4),
            '&:last-of-type': {
                marginBottom: 0
            },
            '& ul': {
                listStyleType: 'none',
                paddingLeft: theme.spacing(2)
            }
        }
    }
});

export default function Faq(props) {
    const {linkId, title, children} = props;
    const classes = useStyles(props);
    return (
      <Fragment>
        <Typography data-testid="faq-title" variant="h3" id={linkId}>
          {title}
        </Typography>
        <Typography
          data-testid="faq-children"
          variant="body1"
          component={"div"}
          className={classes.faq}
        >
          {children}
        </Typography>
      </Fragment>
    );
}