import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Typography, RadioGroup } from "@mui/material";
import {
    USER_ROLE,
    ADMIN_ROLE,
    CONTRACTOR_ROLE,
    CONTRACTOR_READ_ONLY_ROLE,
    FINANCE_ROLE,
} from "../../../../shared/roles";
import {
    PSGA_ROLES,
    INTERNAL_ROLES,
    VALID_ROLES,
    messages as userMessages,
} from "../../../constants/user";
import { ExternalLink, LabelledRadioButton, Notification, osColour } from "omse-components";
import {
    USER_PSGA_PLAN,
    USER_OS_INTERNAL_PLAN,
    USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN,
} from "../../../../shared/plans";
import ServiceTag from "../../../components/ServiceTag";

const messages = defineMessages({
    roleLabel: {
        id: "RoleSelection.roleLabel",
        defaultMessage: "Role",
        description: "Label for the role selection group",
    },
    premiumAdminRole: {
        id: "RoleSelection.adminHelp",
        defaultMessage:
            "Can administer your organisation’s settings and billing information. {newLine} Can manage API Projects, Recipes and Data Packages.*",
        description: "Description for the Admin role option",
    },
    adminWithoutBilling: {
        id: "RoleSelection.adminWithoutBilling",
        defaultMessage:
            "Can administer your organisation’s settings. {newLine} Can manage API Projects, Recipes and Data Packages.*",
        description: "Description for the Admin role option (sans billing)",
    },
    financeRole: {
        id: "RoleSelection.financeHelp",
        defaultMessage:
            "Can administer your organisation’s billing information. {newLine} Can manage API Projects and Recipes, and view Data Packages.*",
        description: "Description for the Finance role option",
    },
    userRole: {
        id: "RoleSelection.userHelp",
        defaultMessage: "Can manage API Projects and Recipes, and view Data Packages.*",
        description: "Description for the User role option",
    },
    contractorRole: {
        id: "RoleSelection.contractorHelp",
        defaultMessage:
            "For users who are not part of your organisation. {newLine} Can manage API Projects, Recipes and Data Packages.*",
        description: "Description for the Contractor role option",
    },
    contractorReadOnlyRole: {
        id: "RoleSelection.contractorReadOnlyHelp",
        defaultMessage:
            "For users who are not part of your organisation. {newLine} Can view API Projects, Recipes and Data Packages.*",
        description: "Description for the Contractor Read Only role option",
    },
    contractorLicenceWarning: {
        id: "RoleSelection.contractorLicenceWarning",
        defaultMessage:
            "As a PSGA member, you can use our Public Sector Contractor Licence when a contractor provides works or services under a formal agreement.",
        description: "Warning about the need for a Public Sector Contractor Licence",
    },
    contractorLicenceLink: {
        id: "RoleSelection.contractorLicenceLink",
        defaultMessage: "Learn more",
        description: "Link text for the link to Public Sector Contractor Licence",
    },
    contractorLicenceLinkAriaLabel: {
        id: "RoleSelection.contractorLicenceLinkAriaLabel",
        defaultMessage: "Learn more about the Public Sector Contractor Licence (Opens in a New Tab).",
        description: "Aria-label text for the link to Public Sector Contractor Licence",
    },
    roleAsterix: {
        id: "RoleSelection.asterix",
        defaultMessage: "* Services are subject to eligibility based on your organisation type.",
        description: "Asterix regarding service eligibility",
    },
});

const RoleCandidate = {
    Container: styled("div")`
        margin: 0.4em 0 0 0;
        padding: 0.8em;
        border: 1px solid ${osColour.neutral.mist};
        border-radius: 0.2em;
        &:first-of-type {
            margin-top: 1em;
        }
        &:last-of-type {
            margin-bottom: 1em;
        }
    `,
    HeaderRegion: styled("div")`
        display: flex;
        gap: 1em;
        align-items: start;
        justify-content: space-between;
        & > label {
            width: 100%;
        }
    `,
    SummaryRegion: styled("div")`
        padding: 0 0 0 1.5em;
    `,
};

const SmallTypography = styled(Typography)`
    font-size: small;
    margin: 0.4em 0 1em 0;
`;

const OverridenNotification = styled(Notification)`
    // Apply some single-use overrides to make the
    // <Notification /> component fit better in a modal.
    padding: 0 0 0.4em 0;
    margin: 0.4em 0 1em 0;
    border-radius: 0.2em !important;
`;

export default function RoleSelection(p) {
    const { currentUserRole, currentSelectedRole, setRole } = p;
    const intl = useIntl();
    const plan = useSelector((state) => state.user.current.result.plan);
    const planSpecificMessages = {
        [ADMIN_ROLE]: messages.premiumAdminRole,
        [FINANCE_ROLE]: messages.financeRole,
        [USER_ROLE]: messages.userRole,
        [CONTRACTOR_ROLE]: messages.contractorRole,
        [CONTRACTOR_READ_ONLY_ROLE]: messages.contractorReadOnlyRole,
    };
    let roles;
    switch (plan) {
        case USER_PSGA_PLAN:
            roles = PSGA_ROLES;
            planSpecificMessages[ADMIN_ROLE] = messages.adminWithoutBilling;
            break;
        case USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN:
        case USER_OS_INTERNAL_PLAN:
            roles = INTERNAL_ROLES;
            planSpecificMessages[ADMIN_ROLE] = messages.adminWithoutBilling;
            break;
        default:
            roles = VALID_ROLES;
    }
    const contractorRoles = [CONTRACTOR_ROLE, CONTRACTOR_READ_ONLY_ROLE];
    const templatePublicSectorContractorLicence =
        "https://www.ordnancesurvey.co.uk/business-government/licensing-agreements/public-sector-licences-contractors-end-users";

    return (
        <>
            <RadioGroup
                name="role"
                aria-label={intl.formatMessage(messages.roleLabel)}
                value={currentSelectedRole}
                onChange={(event) => setRole(event.target.value)}
            >
                {roles.map((role) => (
                    <RoleCandidate.Container key={role}>
                        <RoleCandidate.HeaderRegion>
                            <LabelledRadioButton
                                selectedOption={currentSelectedRole}
                                option={role}
                                label={userMessages[role]}
                            />
                            {currentUserRole === role && (
                                <ServiceTag
                                    variant="shared"
                                    label="Current Role"
                                    hideGutter={true}
                                />
                            )}
                        </RoleCandidate.HeaderRegion>
                        <RoleCandidate.SummaryRegion>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                id={role.toLowerCase() + "_role_description"}
                            >
                                <FormattedMessage
                                    {...planSpecificMessages[role]}
                                    values={{ newLine: <br /> }}
                                />
                            </Typography>
                        </RoleCandidate.SummaryRegion>
                    </RoleCandidate.Container>
                ))}
            </RadioGroup>
            {contractorRoles.includes(currentSelectedRole) && (
                <OverridenNotification variant="info" appearance="inline">
                    <Typography variant="body1">
                        <FormattedMessage {...messages.contractorLicenceWarning} />{" "}
                        <ExternalLink
                            href={templatePublicSectorContractorLicence}
                            message={messages.contractorLicenceLink}
                            ariaLabel={intl.formatMessage(messages.contractorLicenceLinkAriaLabel)}
                        />
                    </Typography>
                </OverridenNotification>
            )}
            <SmallTypography variant="body1">
                <FormattedMessage {...messages.roleAsterix} />
            </SmallTypography>
        </>
    );
}

RoleSelection.propTypes = {
    currentUserRole: PropTypes.string.isRequired,
    currentSelectedRole: PropTypes.string.isRequired,
    setRole: PropTypes.func.isRequired, // Function to set the selected role
};
