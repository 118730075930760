import React from 'react';
import Header from "./Header";
import Content from './Content';
import figure1 from "./vtsOverview/Figure1.png";
import Typography from '@mui/material/Typography';
import UsingVTS from "./UsingVTS";
import DocLinks from "./DocLinks";
import Document from './Document';

export default function VTSOverview(props) {
    return <Document>
        <Header back={true}>OS Vector Tile API: Overview</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    OS Vector Tile API is a vector tile service providing detailed Ordnance Survey data as a customisable basemap. 
                    It can be used with most web mapping libraries including Mapbox GL JS, Leaflet, OpenLayers and ArcGIS API for 
                    JavaScript. Vector Tiles have the benefit of being optimised for use across the internet and are great for building 
                    interactive web maps. You can zoom, pan, rotate, tilt and even extrude 3D buildings.
                </p>
                <p>
                    You have a choice between using Ordnance Survey styles or creating your own. You can customise the content 
                    and the style to create a map that perfectly meets your needs. Design a map that matches your brand or get 
                    creative and add some cartographic flair to your application.
                </p>
                <p>
                    With OS Vector Tile API you can also select and interact with the individual map features.
                </p>
                <p>
                    OS Vector Tile API is available in two projections; British National Grid for GB data and Web Mercator which 
                    is a global coordinate system.
                </p>
            </Typography>
            <img src={figure1} alt='OS Vector Tile API: 3D large-scale cartographic view of Central London'/>
            <UsingVTS/>
            <DocLinks product='vts' hasGettingStarted={true} hasTechnicalSpec={true} hasDemo={true}/>
        </Content>
    </Document>
}
