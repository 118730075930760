import React, {useEffect} from 'react';
import withStyles from 'react-jss';
import Prism from 'prismjs';
import {CodeSnippetStyle as styles} from './styles/CodeSnippetStyle.js';

export function CodeSnippet (props) {
    useEffect(() => { Prism.highlightAll() }, []);
    const {classes, children} = props;

    return <div className={classes.codeSnippet}>
                <pre>
                    <code className="language-javascript" tabIndex='0'>
                        {children}
                    </code>
                </pre>
            </div>
};

export default withStyles(styles)(CodeSnippet);