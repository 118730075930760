import DataPackageDropdownField from './DataPackageDropdownField';
import PropTypes from 'prop-types';
import {defineMessages} from "react-intl";
import features from '../../../../../shared/features';
import useFeatureCheck from "../../../../util/useFeatureCheck";
import CatalogueScopedField from './CatalogueScopedField';
import {COM3_CATALOGUE} from '../../../../../shared/catalogues';

const componentName = 'RenewalPeriodDropdown';

export const orderFieldName = 'renewalPeriod';

export const messages = defineMessages({
    label: {
        id: componentName + '.label',
        defaultMessage: 'Contract length',
        description: 'Field label'
    },
    select: {
        id: componentName + '.select',
        defaultMessage: 'Select contract length',
        description: 'Select label'
    },
    fieldError: {
        id: componentName + '.fieldError',
        defaultMessage: 'Please select a contract length',
        description: 'Required error message'
    }
});

export default function RenewalPeriodDropdown({renewalOptions}) {
    return (useFeatureCheck(features.EAI) && renewalOptions?.length > 0)?
        <CatalogueScopedField catalogueId={COM3_CATALOGUE}>
            <DataPackageDropdownField 
                options={renewalOptions}
                fieldName={orderFieldName}
                labelMessage={messages.label}
                selectMessage={messages.select}
                fieldErrorMessage={messages.fieldError} />
        </CatalogueScopedField> : null;
}

RenewalPeriodDropdown.propTypes = {
    renewalOptions: PropTypes.array
}