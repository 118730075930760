import {generateTitle} from "./titles";

const metadataList = [
    {path: '/', description: 'Making Great Britain’s trusted location data accessible through OS mapping and data APIs with copy-paste ready examples, documentation, free & premium access plans.', title: 'OS Data Hub | Free Maps & API Data for Developers'},
    {path: '/brandLogo/brandImportance', description: 'Our brand is our personality, it represents who we are, so it’s extremely important that we protect our credibility and reputation. If you are using our API solutions it’s important that our brand is applied correctly.', title: 'The importance of our brand'},
    {path: '/brandLogo/copyrightOverview', description: 'A downloadable asset is available. This includes the copyright statement and links for both error reporting and OS terms and conditions on a tinted bar. This should be edited to the correct date while retaining all original links.', title: 'Copyright overview'},
    {path: '/brandLogo/exclusionZone', description: 'The logo exclusion zone is defined by the size of the ‘O’ from the word ‘Ordnance’ in the wordmark. The dimensions of the ‘O’ should be used on all sides of the logo to ensure clear space.', title: 'Exclusion zone'},
    {path: '/brandLogo/logoCopyrightPositioning', description: 'Our logo should be positioned in the embedded map window across all your digital applications.', title: 'Logo and copyright positioning'},
    {path: '/brandLogo/logoFullColour', description: 'Our coloured API logo is to be used on any illustrated map as shown below.', title: 'Logo overview - full colour'},
    {path: '/brandLogo/logoOverview', description: 'To ensure our logo stands out on a map application, there is a keyline ‘blur’ added to both logo versions to ensure readability. These logo versions can only be used on a digital map application.', title: 'Logo overview'},
    {path: '/brandLogo/logoWhite', description: 'Our white API logo is to be used on any satellite, photographic or illustrated map as shown below.', title: 'Logo overview - white'},
    {path: '/brandLogo/minMax', description: 'Minimum & maximum sizes have been set to ensure legibility for digital applications. The logo should remain the same size at all times, this is a fixed asset on a scalable map.', title: 'Minimum and maximum'},
    {path: '/brandLogo/prohibitedUse', description: 'Please use the OS logo as directed. Don’t stretch or distort the logo, don’t apply transparency,drop-shadows or change colours in any way.', title: 'Prohibited use'},
    {path: '/docs', description: 'The following links contain documentation for the API products that are available through this portal, along with code examples and demos.', title: 'API Documentation, Examples & Demos | OS Data Hub'},
    {path: '/docs/downloads/gettingStarted', description: 'This guide explains the steps required to automate the download of OS OpenData using the OS Downloads API.', title: 'OS Downloads API | Getting Started | OS Data Hub'},
    {path: '/docs/downloads/overview', description: 'The OS Downloads API is a service that lets users automate the discovery and download of OS OpenData. In doing so it improves the accessibility of the OS OpenData products.', title: 'OS Downloads API | Overview | OS Data Hub'},
    {path: '/docs/downloads/technicalSpecification', description: 'The OS Downloads API allows you to access information about available OS OpenData products and obtain product metadata as well as download links. The API can be used to automate the retrieval and update of OS OpenData products in your own systems.', title: 'OS Downloads API | Technical Specification | OS Data Hub'},
    {path: '/docs/linkedIdentifiers/gettingStarted', description: 'This guide explains how to generate an API key to access the OS Linked Identifiers API, and then how to use that API key with a Web Development IDE to create a web-based application.', title: 'OS Linked Identifiers API | Getting Started | OS Data Hub'},
    {path: '/docs/linkedIdentifiers/overview', description: 'The OS Linked Identifiers API allows you to access the valuable relationships between properties, streets and OS MasterMap identifiers for free.', title: 'OS Linked Identifiers API | Overview | OS Data Hub'},
    {path: '/docs/linkedIdentifiers/technicalSpecification', description: 'This guide explains the content of the OS Linked Identifiers API, and the methods that you can use to call it', title: 'OS Linked Identifiers API | Technical Specification | OS Data Hub'},
    {path: '/docs/names/gettingStarted', description: 'This guide explains how to generate an API key to access the OS Names API, and then how to use that API key with a Web Development IDE to create a web-based application.', title: 'OS Names API | Getting Started | Get an API key | OS Data Hub'},
    {path: '/docs/names/overview', description: 'The OS Names API is a geographic directory containing basic information about identifiable places. OS Names can locate a feature using just its name, or it can find the closest location to a given point.', title: 'OS Names API | Overview | OS Data Hub'},
    {path: '/docs/names/technicalSpecification', description: 'This guide explains the content of the OS Names API, and the methods that you can use to call it. The OS Names API is a geographic directory containing basic information about identifiable places.', title: 'OS Names API | Technical Specification | OS Data Hub'},
    {path: '/docs/oauth2/gettingStarted', description: 'This guide explains how to use the OAuth 2 API endpoint to generate an access token, and then how to use the token to access the OS Data Hub APIs.', title: 'OAuth 2 API | Getting Started | OS Data Hub'},
    {path: '/docs/oauth2/overview', description: 'OAuth 2 is an authentication mechanism for APIs that allows applications to use time limited tokens for access to resources.', title: 'OAuth 2 API | Overview | OS Data Hub'},
    {path: '/docs/oauth2/technicalSpecification', description: 'This guide explains how you can use the OAuth 2 API to create access tokens. Once you have an access token you can use it to authenticate requests to other OS Data Hub APIs.', title: 'OAuth 2 API | Technical Specification | OS Data Hub'},
    {path: '/docs/vts/gettingStarted', description: 'This guide explains how to generate an API key to access the OS Vector Tile API, and then how to use that API key with a Web Development IDE to create a web-based application.', title: 'OS Vector Tile API | Getting Started | Get a Vector API key | OS Data Hub'},
    {path: '/docs/vts/overview', description: 'OS Vector Tile API is a vector tile service providing detailed Ordnance Survey data as a customisable basemap.  You can zoom, pan, rotate, tilt and even extrude 3D buildings.', title: 'OS Vector Tile API | Overview | OS Data Hub'},
    {path: '/docs/vts/technicalSpecification', description: 'This guide explains the content of the OS Vector Tile API, and the methods that you can use to call it. The API is available using a version of RESTful design with KVP (Key Value Pair) elements.', title: 'OS Vector Tile API | Technical Specification | OS Data Hub'},
    {path: '/docs/wfs/gettingStarted', description: 'This guide explains how to generate an API key to access the OS Features API, and then how to use that API key within GIS software applications.', title: 'OS Features API | Getting Started | Get a WFS API key | OS Data Hub'},
    {path: '/docs/wfs/overview', description: 'Use OS Features API to filter Ordnance Survey data for buildings, roads, rivers, hospitals, playing fields, greenspaces – there are many data layers available.', title: 'OS Features API | Overview | OS Data Hub'},
    {path: '/docs/wfs/technicalSpecification', description: 'This guide explains the content of the OS Features API, and the methods that you can use to call it.', title: 'OS Features API | Technical Specification | OS Data Hub'},
    {path: '/docs/wmts/gettingStarted', description: 'This guide explains how to generate an API key to access the OS Maps API, and then how to use that API key within GIS software applications.', title: 'OS Maps API | Getting Started | Get a Maps API Key | OS Data Hub'},
    {path: '/docs/wmts/overview', description: 'Get started for free and integrate Ordnance Survey’s up-to-date, detailed maps in your apps and web pages. OS Maps API serves pre-rendered raster tiles in four styles: Road, Outdoor, Light and Leisure.', title: 'OS Maps API | Overview | Embed Web Maps & Static Maps | OS Data Hub'},
    {path: '/docs/wmts/technicalSpecification', description: 'This guide explains the content of the OS Maps API, and the methods that you can use to call it.', title: 'OS Maps API | Technical Specification | OS Data Hub'},
    {path: '/downloads/open', description: 'Ordnance Survey OpenData can be downloaded for free (no registration required).', title: 'Free OS OpenData Map Downloads | Free Vector & Raster Map Data | OS Data Hub'},
    {path: '/downloads/open/250kScaleColourRaster', description: 'Get the regional view of towns and villages, roads and places of interest.', title: '1:250 000 Scale Colour Raster™ GB Map download | Free OS Data downloads'},
    {path: '/downloads/open/BoundaryLine', description: 'From Euro constituencies to council wards, Boundary-Line™ maps every administrative boundary in detail for you.', title: 'Boundary-Line™ Vector Map Download | All of Great Britain | Free OS Data downloads'},
    {path: '/downloads/open/CodePointOpen', description: 'Get started with geographical analysis, simple route planning and asset management. Display on a map any information that contains a postcode.', title: 'Code-Point® Open | Map Postcode Data | Free OS Data downloads'},
    {path: '/downloads/open/GBOverviewMaps', description: 'Our simplest free maps of the British Isles. This is ideal as an initial map for your GIS.', title: 'GB Overview Maps | Free GB Maps for GIS | Free OS Data downloads'},
    {path: '/downloads/open/LIDS', description: 'A comprehensive dataset of cross-referenced identifiers, between various OS data products.', title: 'OS Open Linked Identifiers | Free OS Data downloads'},
    {path: '/downloads/open/MiniScale', description: 'A simple overview map of Great Britain. Free to download Zip file (containing EPS, Illustrator and TIFF-LZW)', title: 'MiniScale® | Raster Map of GB | Free OS Data downloads'},
    {path: '/downloads/open/OpenGreenspace', description: 'Covering a range of greenspaces in urban and rural areas including playing fields, sports’ facilities, play areas and allotments.', title: 'OS Open Greenspace | Vector Data for GIS | Free OS Data downloads'},
    {path: '/downloads/open/OpenMapLocal', description: 'Map, visualise and truly understand your data at street level. OS Open Map – Local is free, detailed, street-level, vector backdrop mapping data.', title: 'OS OpenMap - Local | Vector Maps for GIS | Free OS Data downloads'},
    {path: '/downloads/open/OpenNames', description: 'OS Open Names from Ordnance Survey is comprehensive dataset of place names, roads numbers and postcodes for Great Britain.', title: 'OS Open Names | Vector Map Data for GIS | Free OS Data downloads'},
    {path: '/downloads/open/OpenRivers', description: 'OS Open Rivers is ideal if you need a high-level view of watercourses in Great Britain – and it\'s completely free.', title: 'OS Open Rivers | Vector Map Data for GIS | Free OS Data downloads'},
    {path: '/downloads/open/OpenRoads', description: 'Ordnance Survey\'s OS Open Roads brings you a high-level view of the road network in Great Britain – and it\'s completely free.', title: 'OS Open Roads | Vector Map Data for GIS | Free OS Data downloads'},
    {path: '/downloads/open/OpenTOID', description: 'An open dataset providing access to a generalised location to key features found in OS MasterMap premium products enabling visualisation of third party data linked to their respective TOID identifier.', title: 'OS Open TOID | TOID Identifiers for Key Features | Free OS Data downloads'},
    {path: '/downloads/open/OpenUPRN', description: 'OS Open UPRN will enable you to share and link data related to UPRNs, which you can visualise using the accurate location.', title: 'OS Open UPRN | Unique Property Reference Numbers Vector Data | Free OS Data downloads'},
    {path: '/downloads/open/OpenUSRN', description: 'A dataset containing all USRNs from OS MasterMap Highways Network with a simplified line geometry.', title: 'OS Open USRN | Unique Street Reference Numbers Vector Data | Free OS Data downloads'},
    {path: '/downloads/open/OpenZoomstack', description: 'Ordnance Survey\'s OS Open Zoomstack is a comprehensive vector basemap showing coverage of Great Britain from a national level, right down to street detail.', title: 'OS Open Zoomstack | Free Vector Tile Basemap | OS Data downloads'},
    {path: '/downloads/open/Strategi', description: 'Strategi has been withdrawn and superseded by OS Open Roads.', title: 'Strategi® | Vector Map of Railways, Airports, Rivers, Villages, Woods | Free OS Data downloads'},
    {path: '/downloads/open/Terrain50', description: 'OS Terrain 50 allows you to visualise simple landscapes in 3D and bring your geographic analysis to life.', title: 'OS Terrain® 50 | Visualise Landscapes in 3D | Free OS Data downloads'},
    {path: '/downloads/open/VectorMapDistrict', description: 'Ideal for creating web applications, OS VectorMap District contains only the most important information, giving you a clear, uncluttered backdrop map.', title: 'OS VectorMap® District | Backdrop Map for Web Applications | Free OS Data downloads'},
    {path: '/legal/termsConditions', description: 'Summary of the OS Data Hub Terms. Terms and conditions for the use of OS Data Hub to OS OpenData & premium OS data under the terms of the Open Government Licence.', title: 'OS Data Hub Terms | Terms & Conditions'},
    {path: '/serviceStatus', description: 'Service availability dashboard for all OS Data Hub API services.', title: 'Service Status | Support'},
    {path: '/support', description: 'The following FAQs describe how to manage your account and access API products. Please contact our Support Team for other technical or account issues.', title: 'OS OpenData FAQ\'s | Account & API Access | OS Data Hub'},
    {path: '/support/plans', description: 'What is the difference between an OS OpenData plan and a Premium plan? The following FAQs detail the features and benefits of the different plans available via the OS Data Hub.', title: 'OS OpenData FAQ\'s | Free & Premium Data Access | OS Data Hub'},
    {path: '/docs/wmts/overview', description: 'Get started for free and integrate Ordnance Survey’s up-to-date, detailed maps in your apps and web pages. OS Maps API serves pre-rendered raster tiles in four styles: Road, Outdoor, Light and Leisure.', title: 'OS Maps API Overview | Embed Web Maps & Static Maps | OS Data Hub'},
    {path: '/plans', description: 'OS OpenData Plan API access is free for any use. OS Premium Plan API access provides up to £1000 free premium data per month.', title: 'Plans | OS Data Hub'},
    {path: '/tools/vn', description: 'Vernacular names are colloquial, alternative or local names given to places or objects.', title: 'Vernacular Names | OS Data Hub'},
    {path: '/tools/vn/reporting', description: 'Vernacular names are colloquial, alternative or local names given to places or objects.', title: 'Vernacular Names Reporting | OS Data Hub'}
];


// if path found in metadataList then return it's description otherwise return defaultDescription
export function routeDescription(location) {
    let defaultDescription = '';
    if(location) {
        for (let i = 0; i < metadataList.length; i++) {
            let metadata = metadataList[i];
            if (metadata.path === location.pathname) {
                return metadata.description;
            }
        }
    }


    return defaultDescription;
}

export function routeTitle(location) {
    if(location) {
        for (let i = 0; i < metadataList.length; i++) {
            let metadata = metadataList[i];

            if (metadata.path === location.pathname) {
                return metadata.title;
            }
        }
    }

    //If title is not matched within metadataList, title will be generated.
    return generateTitle(location);
}