import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography, Slider} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {osColour} from 'omse-components';
import {defineMessages, FormattedMessage} from "react-intl";
import {EAI_TIER_OVERAGE} from '../../../../shared/constants'

export const CONTACT_US = "CONTACT_US";

const useStyles = createUseStyles(theme=>({
    sliderContainer:{
        marginTop: theme.spacing(2),
        marginBottom:theme.spacing(2)
    },
    mark:{
        top:4,
        height:20,
        backgroundColor: osColour.neutral.stone
    },
    markActive:{
        backgroundColor: osColour.primary.berry
    },
    track:{
        height:4
    },
    rail:{
        height:1,
        color:osColour.neutral.stone
    },
    tiers: {
        display:"flex"
    },
    tierDetail: {
        display:'inline-block'
    }
}));

const messages = defineMessages({
    title:{
        id:"apiPlans.slider.title",
        defaultMessage:"{tierLabel}"
    },
    description:{
        id:"apiPlans.slider.description",
        defaultMessage:"Purchases made {price}"
    },
    discount:{
        id:"apiPlans.slider.discount",
        defaultMessage:"Receive {discount}% Discount"
    },
    unitPrice:{
        id:"apiPlans.slider.unitPrice",
        defaultMessage:"{apiName}: £{unitPrice} per transaction"
    },

})

export default function ApiPlanSlider(props) {
    const {
        demoProduct,
        handleChange,
        value,
        tiers
    } = props;

    const classes = useStyles();

    // The slider only needs to display information about tiers that can be purchased, i.e. not Overage.
    const purchasableTiers = tiers.sort((a,b) => a.lowerValue - b.lowerValue).filter(tier => tier.id !== EAI_TIER_OVERAGE);

    const steps = purchasableTiers
        .map(tier => tier.steps) // Gets the integer array of steps from each tier, e.g. [1000,2000],[4000,3000]
        .flat(1) // Concatenate the arrays into a new one, e.g. [1000,2000,4000,3000]
        .sort((a, b) => a - b); // Sort, e.g. [1000,2000,3000,4000]

    // Add a contact us step to the end of the slider.
    steps.push(CONTACT_US);

    // On the slider one step is one unit.
    // We can convert to step units by getting the index of a value from the array of steps.
    const toSliderValue = value => steps.indexOf(value);
    const sliderValue = toSliderValue(value);

    // We want a marker at the start of each tier so find the slider value of each tier's lower value.
    const marks = purchasableTiers.map(tier => ({
       value: toSliderValue(tier.lowerValue)
    }));

    // We can convert back from slider units by looking up the value in the array.
    const fromSliderValue = sliderValue => steps[sliderValue];
    const sliderChange = (event, newValue) => handleChange(fromSliderValue(newValue));

    return (<Box className={classes.sliderContainer}>
        <Slider
            id="purchase-api-slider"
            data-testid="purchase-api-slider"
            classes={{
                mark:classes.mark,
                markActive:classes.markActive,
                rail:classes.rail,
                track:classes.track
            }}
            min={0}
            max={steps.length-1}
            step={1}
            onChange={sliderChange}
            value={sliderValue}
            marks={marks}
            scale={fromSliderValue}
            aria-label="purchase api plan slider"
        />
        <Box className={classes.tiers} id="tier-details" data-testid="tier-details">
            {purchasableTiers.map(tier => (
                <Box className={classes.tierDetail}
                     width={`${((tier.steps.length/steps.length)*100).toFixed(1)}%`}
                     key={tier.name}
                     data-testid={tier.id}
                >
                    <Typography variant="body2">
                        <FormattedMessage {...messages.title} values={{tierLabel:tier.name}}/>
                    </Typography>
                    <Typography variant="body1">
                        <FormattedMessage {...messages.description} values={{
                            price:tier.lowerValue ?`from £${tier.lowerValue.toLocaleString()}`:`up to £${tier.upperValue.toLocaleString()}`
                        }}/>
                    </Typography>

                    {
                        demoProduct.tiers.find(demoProductTier => demoProductTier.tierId === tier.id)?.discountPercent ?
                        <Typography variant="body2" color="primary">
                            <FormattedMessage {...messages.discount} values={{
                                discount:demoProduct.tiers.find(demoProductTier => demoProductTier.tierId === tier.id)?.discountPercent
                            }}/>
                        </Typography> : null
                    }

                    <Typography variant="body1">
                        <FormattedMessage {...messages.unitPrice} values={{
                            apiName:demoProduct.name,
                            unitPrice:demoProduct.tiers.find(demoProductTier => demoProductTier.tierId === tier.id).unitPrice
                        }}/>
                    </Typography>
                </Box>
            ))}
        </Box>
    </Box>)
}

ApiPlanSlider.propTypes = {
    handleChange:PropTypes.func,
    value:PropTypes.number,
    tiers: PropTypes.array,
    demoProject: PropTypes.object
}

ApiPlanSlider.defaultProps = {
    value:0,
    tiers:[]
}
