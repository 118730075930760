import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import { osColour, ExternalLink } from 'omse-components';
import APIUsageChart from './APIUsageChart';
import Link from '../../../../components/Link';
import routes, { psgaAbout } from '../../../../util/routes';
import { connect, useSelector } from "react-redux";
import { DEV_MODE, LIVE_MODE } from "../../../../../shared/project-modes";
import {
    USER_OPEN_DATA_PLAN,
    USER_PREMIUM_DATA_PLAN,
    USER_PSGA_PLAN,
    USER_OS_INTERNAL_PLAN,
} from '../../../../../shared/plans';
import { loadStripes } from '../../../../util/canvas';
import { isOpenDataPersonal } from "../../../../util/organisation";
import useVatRateAdjuster from "../../../../hooks/useVatRateAdjuster";
import { premiumFreeLimitInPounds } from '../../../../../shared/constants';
import * as features from "../../../../../shared/features";
import { isEaiUser } from "../../../../util/plans";
import useFeatureCheck from "../../../../util/useFeatureCheck";

const messages = defineMessages({
    transactionUnit: {
        id: 'APIUsage.transactionUnit',
        defaultMessage: 'transactions',
        description: 'Caption for the API usage total'
    },
    transactionTotal: {
        id: 'APIUsage.transactionTotal',
        defaultMessage: '{count, number}',
        description: 'API usage total'
    },
    fairUse: {
        id: 'APIUsage.fairUse',
        defaultMessage: 'OS OpenData transactions are subject to our fair use policy.',
        description: 'Fair usage caption'
    },
    transactionCaption: {
        id: 'Transactions.transactionCaption',
        defaultMessage: 'Your free premium data allowance will renew at the start of each month.',
        description: 'Transaction allowance caption'
    },
    devTransactionCaption: {
        id: 'Transactions.devTransactionCaption',
        defaultMessage: 'You have unlimited premium data for projects that are in development mode.',
        description: 'Transaction allowance caption'
    },
    internalCaption: {
        id: 'Transactions.internalCaption',
        defaultMessage: 'As a member of the OS Internal Plan you are entitled to free Premium data transactions',
        description: 'Internal allowance caption'
    },
    psgaCaption: {
        id: 'Transactions.psgaCaption',
        defaultMessage: 'As a member of the {link} you are entitled to free Public Sector data transactions',
        description: 'PSGA allowance caption'
    },
    psgaCaptionLink: {
        id: 'Transactions.psgaCaptionLink',
        defaultMessage: 'PSGA',
        description: 'Text for link in PSGA caption'
    },
    freePremiumAllocation: {
        id: 'Transactions.freePremiumAllocation',
        defaultMessage: 'More about free premium transactions.',
        description: 'Link text for free premium FAQ'
    },
    devModeAllocation: {
        id: 'Transactions.devModeAllocation',
        defaultMessage: 'More about development mode.',
        description: 'Link text for dev mode FAQ'
    },
    upgradeCaptionText: {
        id: 'Transactions.upgradeCaptionText',
        defaultMessage: 'Upgrade to get a Premium data allowance which renews at the start of each month.',
        description: 'Text upgrade caption'
    }
});

function styles(theme) {
    return {
        usageContainer: {
            marginTop: theme.spacing(3),
            display: 'flex',
            flexWrap: 'wrap',
            minWidth: '400px',
        },
        astrix: {
            color: osColour.neutral.stone,
            top: 4,
            position: 'relative',
            lineHeight: 1,
            fontSize: '0.875rem'
        },
        captionContainer: {
            backgroundColor: osColour.neutral.clouds,
            padding: theme.spacing(1),
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            width: 'fit-content',

        },
        captions: {
            color: osColour.neutral.stone,
            fontSize: '0.875rem',
            padding: 0
        },
        transactionTotal: {
            display: 'flex',
            alignItems: 'flex-end',
            color: osColour.primary.berry
        },
        transactionUnit: {
            marginLeft: theme.spacing(1),
            fontSize: '1rem',
            color: osColour.neutral.charcoal
        },
        graphCaption: {
            display: 'flex',
            '& > div > a': {  
                fontSize: '14px'
            }
        },
        disabledGraph: {
            cursor: 'pointer'
        }
    };
}

export function APIUsage(props) {
    const { userStatsResult, classes, userDetails } = props;
    const mode = userStatsResult.mode;
    const [stripes, setStripes] = useState();
    const org = useSelector(state => state.organisation.current);
    const loading = useSelector(state => state.payments.config.loading);
    const vatRateAdjuster = useVatRateAdjuster();
    const hasEaiApiFeature = useFeatureCheck(features.EAIAPI);

    useEffect(()  => {
        let subscribed = true;
        const isSubscribed = () => subscribed;

        if (!stripes) {
            loadStripes(setStripes, false, isSubscribed);
        }

        return () => subscribed = false;
    }, [stripes, setStripes]);
    let premiumFreeUsed = 0;
    let transactionTotal = userStatsResult.open.transactions;
    if(userStatsResult.premium && userStatsResult.premium.free && userStatsResult.premium.free.price) {
        premiumFreeUsed = Math.floor(userStatsResult.premium.free.price / premiumFreeLimitInPounds);
        if(premiumFreeUsed === 0) {
            premiumFreeUsed = 1;
        }
        if (premiumFreeUsed > 100) {
            premiumFreeUsed = 100;
        }
    }
    let openUsed = 0;
    if(userStatsResult.open && userStatsResult.open.transactions) {
        openUsed = 100;
    }

    if(mode === DEV_MODE) {
        premiumFreeUsed = 0;
        if((userStatsResult.premium?.free?.transactions) ||
            (userStatsResult.commercial?.transactions)) {
            premiumFreeUsed = 100;
        }
    }

    const premiumFreeChartData = {
        datasets: [
            {
                data: [premiumFreeUsed, 100 - premiumFreeUsed],
                backgroundColor: [osColour.primary.foxglove, osColour.neutral.mist],
                hoverBackgroundColor: [osColour.primary.foxglove, osColour.neutral.mist]
            }
        ]
    };

    const openChartData = {
        datasets: [
            {
                data: [openUsed, 100 - openUsed],
                backgroundColor: [osColour.primary.lighterBerry, osColour.neutral.mist],
                hoverBackgroundColor: [osColour.primary.lighterBerry, osColour.neutral.mist]
            }
        ]
    };
    const charts = [{
        type: 'open',
        caption: <FormattedMessage {...messages.fairUse} />,
        data: openChartData,
        decorator: '*',
        transactions: userStatsResult.open.transactions
    }];

    if (!isOpenDataPersonal(userDetails, org)) {
        if (userDetails.plan === USER_OPEN_DATA_PLAN) {
            const premiumChartDataDisabled = {
                datasets: [
                    {
                        data: [0, 100],
                        backgroundColor: [osColour.primary.mist, osColour.neutral.mist],
                        hoverBackgroundColor: [osColour.primary.mist, osColour.neutral.mist]
                    }
                ]
            };

            charts.push({
                type: 'disabledPremium',
                caption: <FormattedMessage {...messages.upgradeCaptionText} />,
                data: premiumChartDataDisabled,
                decorator: '**',
                transactions: 0,
                price: 0,
                limit: premiumFreeLimitInPounds,
                loading: loading
            });
        } else if (userDetails.plan === USER_PREMIUM_DATA_PLAN) {
            transactionTotal += userStatsResult.premium.transactions;

            if (mode === LIVE_MODE) {
                charts.push({
                    type: 'freePremium',
                    decorator: '**',
                    caption: (
                        <>
                            <FormattedMessage {...messages.transactionCaption} />
                            &nbsp;
                            <Link path={routes.supportPlans} hash="#freePremiumAllocation">
                                <FormattedMessage {...messages.freePremiumAllocation} />
                            </Link>
                        </>
                    ),
                    limit: premiumFreeLimitInPounds,
                    data: premiumFreeChartData,
                    transactions: userStatsResult.premium.free.transactions,
                    price: userStatsResult.premium.free.price,
                    loading: loading
                });

                const premiumChargeableChartData = (canvas) => {
                    const ctx = canvas.getContext("2d");
                    let fill = '#F6CBDD';
                    if (stripes) {
                        fill = ctx.createPattern(stripes, 'repeat');
                    }
                    let percentage = userStatsResult.premium.chargeable.transactions ? 100 : 0;

                    return {
                        datasets: [{
                            data: [percentage, 100 - percentage],
                            backgroundColor: [fill, osColour.neutral.mist]
                        }]
                    }
                };

                let vatAdjuster = 1;
                let vatRateAvailable = true;
                if (vatRateAdjuster) {
                    vatAdjuster = vatRateAdjuster;
                } else {
                    vatRateAvailable = false;
                }

                charts.push({
                    type: 'chargeablePremium',
                    data: premiumChargeableChartData,
                    transactions: userStatsResult.premium.chargeable.transactions,
                    price: userStatsResult.premium.chargeable.price * vatAdjuster,
                    vatRateAvailable: vatRateAvailable,
                    loading: loading
                });
            } else {
                charts.push({
                    type: 'devPremium',
                    decorator: '**',
                    caption: (
                        <>
                            <FormattedMessage {...messages.devTransactionCaption} />
                            &nbsp;
                            <Link path={routes.supportPlans} hash="#modeDifference">
                                <FormattedMessage {...messages.devModeAllocation} />
                            </Link>
                        </>
                    ),
                    data: premiumFreeChartData,
                    transactions: userStatsResult.premium.free.transactions,
                    loading: loading
                });
            }
        } else if (userDetails.plan === USER_PSGA_PLAN || userDetails.plan === USER_OS_INTERNAL_PLAN) {
            const dataKey = userDetails.plan === USER_PSGA_PLAN ? 'psga' : 'internal';
            transactionTotal += userStatsResult[dataKey].transactions;
            let used = 0;
            if(userStatsResult[dataKey] && userStatsResult[dataKey].transactions) {
                used = 100;
            }
            const chartData = {
                datasets: [
                    {
                        data: [used, 100 - used],
                        backgroundColor: [osColour.primary.foxglove, osColour.neutral.mist],
                        hoverBackgroundColor: [osColour.primary.foxglove, osColour.neutral.mist]
                    }
                ]
            };

            let caption;
            if(userDetails.plan === USER_PSGA_PLAN) {
                caption = (
                    <FormattedMessage {...messages.psgaCaption} values={{
                        link: <ExternalLink type='generic' href={psgaAbout} message={messages.psgaCaptionLink} />
                    }}/>
                );
            } else {
                caption = <FormattedMessage {...messages.internalCaption}/>
            }

            charts.push({
                type: dataKey,
                decorator: '**',
                caption,
                data: chartData,
                transactions: userStatsResult[dataKey].transactions,
                loading: loading
            });
        } else if (isEaiUser(userDetails)
            && hasEaiApiFeature
            && mode === DEV_MODE) {
            transactionTotal += userStatsResult.commercial.transactions;
            charts.push({
                type: 'devEai',
                decorator: '**',
                caption: (
                    <>
                        <FormattedMessage {...messages.devTransactionCaption} />
                        &nbsp;
                        <Link path={routes.supportPlans} hash="#modeDifference">
                            <FormattedMessage {...messages.devModeAllocation} />
                        </Link>
                    </>
                ),
                data: premiumFreeChartData,
                transactions: userStatsResult.commercial.transactions,
                loading: loading
            });
        }
    }

    return (
        <div className={classes.root} data-testid="api-usage-container">
            <Typography variant='h2' component='div' className={classes.transactionTotal}>
                <FormattedMessage {...messages.transactionTotal} values={{count: transactionTotal}} />
                <Typography variant='body1' className={classes.transactionUnit}>
                    <FormattedMessage {...messages.transactionUnit} />
                </Typography>
            </Typography>
            <div className={classes.usageContainer}>
                {charts.map(chart =>
                    <APIUsageChart key={chart.type}
                        variant={chart.type}
                        chartData={chart.data}
                        transactions={chart.transactions}
                        price={chart.price}
                        vatRateAvailable={chart.vatRateAvailable}
                        loading = {chart.loading}
                        limit={chart.limit}
                    />
                )}
            </div>
            {charts.filter(c => c.caption).length > 0 &&
                <div className={classes.captionContainer}>
                    <Typography variant='caption' component='ul' className={classes.captions}>
                        {charts.filter(c => c.caption).map(chart =>
                            <li className={classes.graphCaption} key={chart.type}>
                                {chart.decorator &&
                                    <Typography variant='h2' component='span' className={classes.astrix}>{chart.decorator}</Typography>
                                }
                                <div className={classes.caption}>
                                    {chart.caption}
                                </div>
                            </li>)
                        }
                    </Typography>
                </div>
            }
        </div>
    );
}

APIUsage.propTypes = {
    userStatsResult: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,     // Injected by withStyles()
    userDetails: PropTypes.object.isRequired, // Injected by connect()
};

const mapStateToProps = state => {
    const {result} = state.user.current;
    return {
        userDetails: result,
    }
};
const styled = withStyles(styles)(APIUsage);
export default connect(mapStateToProps)(styled);
