import React from 'react';
import Table from './Table';
import { ExternalLink } from 'omse-components';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

const sourceProducts = {
    'MasterMapTopo': {
        name: 'OS MasterMap Topographic Layer',
        technicalDetails: 'OS MasterMap Topography Layer Technical Specification (PDF)',
        technicalDetailsUrl: 'https://www.ordnancesurvey.co.uk/documents/product-support/tech-spec/os-mastermap-topography-layer-technical-specification-v3.0.pdf',
    },
    'MasterMapHighways': {
        name: 'OS MasterMap Highways',
        technicalDetails: 'OS MasterMap Highways Network Roads Technical Specification (PDF)',
        technicalDetailsUrl: 'https://www.ordnancesurvey.co.uk/documents/product-support/tech-spec/osmm-highways-network-rami-technical-specification-v2.5.pdf',
    },
    'AddressBase': {
        name: 'AddressBase Premium',
        technicalDetails: 'AddressBase Premium Technical Specification (PDF)',
        technicalDetailsUrl: 'https://www.ordnancesurvey.co.uk/documents/product-support/tech-spec/addressbase-premium-technical-specification.pdf',
    },
    'OpenRoads': {
        name : 'Open Roads',
        technicalDetails: 'OS Open Roads User Guide & Technical Specification (PDF)',
        technicalDetailsUrl: 'https://www.ordnancesurvey.co.uk/documents/product-support/tech-spec/os-open-roads-technical-specification.pdf',
    },
}

const features = [
    {
        featureType: 'TopographicArea',
        identifierType: 'TOID',
        sourceProduct: sourceProducts.MasterMapTopo.name,
        description: 'Polygon limited to either a building outline or road surface area.',
        technicalDetails: sourceProducts.MasterMapTopo.technicalDetails,
        technicalDetailsUrl: sourceProducts.MasterMapTopo.technicalDetailsUrl,
    },
    {
        featureType: 'RoadLink',
        identifierType: 'TOID',
        sourceProduct: sourceProducts.MasterMapHighways.name,
        description: 'Section of road from junction to junction.',
        technicalDetails: sourceProducts.MasterMapHighways.technicalDetails,
        technicalDetailsUrl: sourceProducts.MasterMapHighways.technicalDetailsUrl,
    },
    {
        featureType: 'Road ',
        identifierType: 'TOID',
        sourceProduct: sourceProducts.MasterMapHighways.name,
        description: <>Collection of <InlineCodeSnippet>RoadLink</InlineCodeSnippet> with a given name.</>,
        technicalDetails: sourceProducts.MasterMapHighways.technicalDetails,
        technicalDetailsUrl: sourceProducts.MasterMapHighways.technicalDetailsUrl,
    },
    {
        featureType: 'BLPU',
        identifierType: 'UPRN',
        sourceProduct: sourceProducts.AddressBase.name,
        description: 'An address location with postal address. BLPU stands for Building Land Parcel Unit. UPRN stands for Unique Property Reference Number',
        technicalDetails: sourceProducts.AddressBase.technicalDetails,
        technicalDetailsUrl: sourceProducts.AddressBase.technicalDetailsUrl,
    },
    {
        featureType: 'Street',
        identifierType: 'USRN',
        sourceProduct: sourceProducts.MasterMapHighways.name,
        description: 'An identifier allocated to a street by a local authority. USRN stands for Unique Street Reference Number.',
        technicalDetails: sourceProducts.MasterMapHighways.technicalDetails,
        technicalDetailsUrl: sourceProducts.MasterMapHighways.technicalDetailsUrl,
    },
    {
        featureType: 'ORRoadNode',
        identifierType: 'GUID',
        sourceProduct: sourceProducts.OpenRoads.name,
        description: 'Road junctions from our open data product.',
        technicalDetails: sourceProducts.OpenRoads.technicalDetails,
        technicalDetailsUrl: sourceProducts.OpenRoads.technicalDetailsUrl,
    },
    {
        featureType: 'ORRoadLink',
        identifierType: 'GUID',
        sourceProduct: sourceProducts.OpenRoads.name,
        description: 'Road sections from our open data product.',
        technicalDetails: sourceProducts.OpenRoads.technicalDetails,
        technicalDetailsUrl: sourceProducts.OpenRoads.technicalDetailsUrl,
    }
];


function LinkedIdentifiersFeaturesTable() {
    return <Table>
        <tbody>
            <tr>
                <th>Feature Type</th>
                <th>Identifier Type</th>
                <th>Source Product</th>
                <th>Description</th>
                <th>Technical Specification</th>
            </tr>
            {
                features.map(feature => {
                    const featureType = feature.featureType;
                    return <tr key={featureType}>
                        <td>
                            <InlineCodeSnippet>
                                {featureType}
                            </InlineCodeSnippet>
                        </td>
                        <td>{feature.identifierType}</td>
                        <td>{feature.sourceProduct}</td>
                        <td>{feature.description}</td>
                        <td>
                            <ExternalLink
                                type="generic"
                                href={feature.technicalDetailsUrl}
                                message={feature.technicalDetails}
                            />
                        </td>
                    </tr>;
                })
            }
        </tbody>
    </Table>;
}

export default LinkedIdentifiersFeaturesTable;
