import React from 'react';
import {CommonDialog} from "omse-components";
import useRecipe from "../../../../hooks/useRecipe";
import {Typography} from "@mui/material";
import {defineMessages} from "react-intl";

const messages = defineMessages({
    title: {
        id: 'SharedWithDialog.title',
        defaultMessage: 'Organisations this recipe is shared with',
        description: 'Title for the "shared with" dialog'
    }
});

export default function SharedWithDialog({onClose}) {
    const {recipe} = useRecipe();

    return <CommonDialog onClose={onClose}
                         title={messages.title}
    >
        {
            recipe.sharedOrgs.map((org, index) => <Typography variant='body1' key={index}>
                {org}
            </Typography>)
        }
    </CommonDialog>
}
