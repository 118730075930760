import {osColour} from 'omse-components';
import {createUseStyles} from 'react-jss';

const styles = createUseStyles(theme => ({
    linkStyle: {
        color: osColour.link.visited,
        textDecoration: 'underline',
        fontFamily: 'Source Sans Pro, sans-serif',
        cursor: "pointer",
        "&:hover": {
            color: osColour.status.info
        },
    },
    buttons: {
        marginTop: theme.spacing(4),
        display: 'flex',
        justifyContent: 'flex-end',

        '& button:not(:last-of-type)': {
            marginRight: theme.spacing(2)
        }
    },
    introText: {
        maxWidth: '600px'
    },
    content: {
        maxWidth: 470,
        padding: theme.spacing(1)
    },
    radioGroup: {
        marginTop: theme.spacing(1),
        '& label': {
            marginTop: theme.spacing(1),
            marginRight: 0
        }
    }
}));

export default styles;