import {createPolygonDrawingTool} from "./ol/tool";
import {deleteStyle} from "./ol/style";

const DELETE_POLYGON_TYPE = 'deletePolygon';

export default function DeletePolygonAction (map, layers) {
    const {deleteLayer} = layers;

    const tool = createPolygonDrawingTool(deleteLayer, deleteStyle);
    map.addInteraction(tool);

    return {
        type: DELETE_POLYGON_TYPE,
        deactivate: () => {
            // To complete drawing a polygon the user needs to double-click. If completing the polygon deletes all of the
            // drawn shapes from the map, then this tool will be automatically deactivated... but it is deactivated before
            // the double click has been completely handled. The click ends up leaking through to the DoubleClickZoom,
            // which then zooms the map! To work around this edge case we turn the interaction off _after_ the click has
            // been processed.
            setTimeout(() => map.removeInteraction(tool), 0);
        }
    }
}

DeletePolygonAction.type = DELETE_POLYGON_TYPE;