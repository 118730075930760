import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {createUseStyles} from 'react-jss';
import classNames from 'classnames';
import {Typography} from '@mui/material';
import Header from '../documentation/Header';
import Document from '../documentation/Document';
import style from './styles/legal';
import {Tick, Warning, Error} from './styles/icons';
import { ExternalLink } from 'omse-components';

const messages = defineMessages({
    title: {
        id: 'TrialModeTerms.title',
        defaultMessage: 'Trial Mode Terms (Address API Services)',
        description: 'Trial Mode Terms title'
    },
    subtitle: {
        id: 'TrialModeTerms.subtitle',
        defaultMessage: 'OS Data Hub Trial Mode Terms (Address API Services)',
        description: 'Trial Mode Terms subtitle'
    }
});

const useTermsStyles = createUseStyles(style);
const useStyles = createUseStyles(theme => ({
    bulletedList: {
        '& ul': {
            listStyle: 'disc',
            paddingLeft: theme.spacing(5),
            margin: 0
        }
    },
    // We need to use these selectors to gain higher specificity than the terms styles selectors.
    content: {
        '& li > p': {
            marginTop: 0
        }
    },
    iconListInner: {
        marginTop: `${theme.spacing(1.5)} !important`,
        marginBottom: `${theme.spacing(3)} !important`,
        '& > li': {
            display: 'flex',
        },
        '& > li:before': {
            content: 'none !important'
        }
    },

    // It was a requirement to align these icon points vertically visually even though they are part of sub-lists
    // semantically. Achieved this with negative margins to offset the list padding.
    unIndentedListOneLevel: {
        marginLeft: theme.spacing(-7)
    },
    unIndentedListZeroLevel: {
        marginLeft: theme.spacing(-2)
    }
}));

export function TrialModeTerms() {
    const termsClasses = useTermsStyles();
    const classes = useStyles();

    return (
        <Document>
            <Header back={true}>
                <FormattedMessage {...messages.title} />
            </Header>
            <div className={classNames(termsClasses.content, classes.content)}>
                <Typography variant='h2'>
                    <FormattedMessage {...messages.subtitle} />
                </Typography>
                <Typography variant='h5' component='div' className={termsClasses.numberedList}>
                    <Typography variant='h4' component='h3'>Main terms</Typography>
                    <ol>
                        <li>
                            <strong>This Licence</strong> (as defined in the Small Print):

                            <div className={termsClasses.alphabeticList}>
                                <ol type='a'>
                                    <li>is <strong>free of charge</strong>;</li>
                                    <li>defines:</li>
                                        <div className={classes.bulletedList}>
                                        <ul>
                                            <li><strong>'OS API Data’</strong> as being any dataset that you have access
                                                to under this Licence via any Address API Service;</li>
                                            <li>an <strong>‘Address API Service’</strong> as being either the OS Places
                                                API Service or the OS Match & Cleanse API Service; and</li>
                                            <li>a <strong>'Transaction'</strong> as being a single request by you via
                                                your use of an Address API Service for no more than 100 addresses
                                                (including any associated attributes) and the response to that request
                                                generated by your use of such Address API Service.</li>
                                        </ul></div>
                                    <li>
                                        lets you access an Address API Service to access and use OS API Data for the
                                        Permitted Actions referred to below for <strong>a single period (on a per Address API
                                        Service basis) commencing on the date referred to in Clause 2.4 of the Small
                                        Print and ending 60 days thereafter or, if sooner, the date upon which the
                                        number of Transactions accessed by you via the applicable Address API Service
                                        during such period equals 2,000;</strong>
                                    </li>
                                    <li>
                                        lets you do, on a viewing only basis, any of the <strong>Permitted
                                        Actions</strong> we
                                        list below:
                                        <ul className={classNames(termsClasses.iconList, classes.iconListInner, classes.unIndentedListOneLevel)}>
                                            <li>
                                                <Tick/><span>Evaluate an Address API Service and its OS API Data to see if
                                                what we’re offering is what you need;</span>
                                            </li>
                                            <li>
                                                <Tick/><span>Use an Address API Service and its OS API Data to research and
                                                develop your ideas and propositions;</span>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        is subject to all of the following <strong>Requirements</strong> listed below and to
                                        the Small Print:
                                        <ul className={classNames(termsClasses.iconList, classes.iconListInner, classes.unIndentedListOneLevel)}>
                                            <li>
                                                <Warning/>
                                                <span>
                                                    Provide technological and security measures to make sure that the
                                                    Address API Service you access and all of its OS API Data is secure from
                                                    any unauthorised use or access.
                                                </span>
                                            </li>
                                            <li>
                                                <Warning/>
                                                <span>
                                                    Not do anything that would entitle us to refuse a licence, or terminate
                                                    an existing licence, in accordance with the OSLicence Exceptions Policy.
                                                    In this Licence, <strong>OS Licence Exceptions Policy</strong> means our
                                                    licence exceptions policy as set out in <ExternalLink
                                                        type='generic'
                                                        href="https://www.ordnancesurvey.co.uk/documents/licensing/licence-exceptions.pdf"
                                                        message="https://www.ordnancesurvey.co.uk/documents/licensing/licence-exceptions.pdf"
                                                        ariaLabel="Licence Exceptions Policy (Opens in a New Tab)"
                                                    />, as amended from time to time.</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                        </li>
                        <li>
                            Under this Licence you agree to comply with the <strong>Restrictions</strong> below and the
                            Small Print:
                            <ul className={classNames(termsClasses.iconList, classes.iconListInner, classes.unIndentedListZeroLevel)}>
                                <li>
                                    <Error />
                                    <span>
                                        Unless we expressly permit you to do so above, you will not make any Address API
                                        Service and / or any OS API Data available to third parties or create any
                                        products or services which have benefitted from, relied on or made any use of
                                        any Address API Service and / or any OS API Data (including, without limitation,
                                        where you have created your products or services by copying, publishing,
                                        modifying, re-formatting, analysing or performing searches, look ups or
                                        enquiries using any Address API Service and / or any OS API Data). You agree
                                        that, if you want to make any Address API Service and / or any OS API Data
                                        available in this way, you will tell us and will not proceed until we agree the
                                        basis on which that can happen.
                                    </span>
                                </li>
                                <li>
                                    <Error /><span>You may not download, store or extract any OS API Data.</span>
                                </li>
                                <li>
                                    <Error /><span>You shall not infringe or breach the intellectual property rights in
                                    any Address API Service and / or any OS API Data.</span>
                                </li>

                            </ul>
                        </li>
                    </ol>
                </Typography>

                <Typography variant='h5' component='div' className={termsClasses.numberedList}>
                    <Typography variant='h4'>The small print</Typography>
                    <ol>
                        <li>
                            <strong>Parties</strong>
                            <p>
                                This Licence is between Ordnance Survey Limited
                                (‘<strong>we</strong>/<strong>us</strong>/<strong>our</strong>/<strong>OS</strong>’), a
                                company registered in England and Wales (company registration number 09121572), whose
                                registered address is Explorer House, Adanac Drive, SOUTHAMPTON, SO16 0AS and the person
                                or organisation who is required to accept these terms in order to access and use an
                                Address API Service on a trial basis (‘<strong>you</strong>/<strong>your</strong>’). You
                                and we are each a ‘<strong>party</strong>’ and together the ‘<strong>parties</strong>’.
                            </p>
                        </li>
                        <li>
                            <strong>Licence</strong>
                            <ol>
                                <li>
                                    Subject to the restrictions and requirements referred to in this Licence, we grant
                                    you a free, non-exclusive, non-transferable, revocable licence to access and use OS
                                    API Data via an Address API Service for the relevant period and the Permitted
                                    Actions described in the Main Terms.
                                </li>
                                <li>
                                    In consideration of the mutual obligations in this Licence, you agree to be bound by
                                    all of the terms and conditions contained in this Small Print and the Main Terms,
                                    which forms the trial licence between you and us (the ‘<strong>Licence</strong>’).
                                    This Licence shall commence the first time you click the "start free trial" button
                                    in the OS Data Hub.
                                </li>
                                <li>
                                    Please read all terms before accessing any Address API Service so that you
                                    understand this Licence and what we agree you can and can’t do with the Address API
                                    Services and OS API Data.
                                </li>
                                <li>
                                    The 60 days' period referred to in the Main Terms (the ‘<strong>Trial
                                    Period</strong>’) commences, in respect of each Address API Service, with effect
                                    from the date you press the "start free trial" button relating to that Address API
                                    Service.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Intellectual Property Rights</strong>
                            <ol>
                                <li>
                                    We and/or our suppliers own all the intellectual property rights in the Address API
                                    Services and all OS API Data. All rights not expressly granted are reserved to us
                                    and/or our licensors. In particular, OS API Data contains intellectual property
                                    rights owned, in part, by Royal Mail and, accordingly, we reserve the right to share
                                    the details you submit in order to access the OS Data Hub with Royal Mail at its
                                    request for auditing purposes.
                                </li>
                                <li>
                                    You shall notify us as soon as you become aware of, or suspect, any infringement or
                                    other breach by you or any other third party of the intellectual property rights in
                                    the Address API Services and / or any OS API Data. You agree to co-operate with us
                                    at no cost to us to provide all reasonable assistance in pursuing or dealing with
                                    any such infringement or breach.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>No Warranty</strong>
                            <p>
                                It is your responsibility to ensure that the Address API Services and / or the OS API
                                Data is what you need. The Address API Services and all OS API Data are provided ‘as is’
                                and without any warranty or condition, express or implied, statutory or otherwise, as to
                                its quality or fitness for purpose. Except as expressly stated in this Licence, all
                                conditions, warranties, terms and undertakings express or implied statutory or otherwise
                                in respect of the Address API Service and / or all OS API Data are hereby excluded to
                                the fullest extent permitted by law.
                            </p>
                        </li>
                        <li>
                            <strong>Liabilities</strong>
                            <ol>
                                <li>
                                    Subject to clause 5.2:
                                    <div className={termsClasses.alphabeticList}>
                                        <ol type='a'>
                                            <li>
                                                neither party shall be liable to the other, whether in contract, tort
                                                (including negligence), breach of statutory duty, or otherwise, for any loss
                                                of profit, or any indirect, special or consequential loss or damage or any
                                                other financial loss (howsoever caused) arising as a result of the use of,
                                                or lack of performance of the Address API Services and / or any OS API Data;
                                                and
                                            </li>
                                            <li>
                                                there is nothing in this Licence that excludes or limits your liability for
                                                an infringement or breach of our intellectual property rights.
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    Nothing in this Licence shall limit or exclude either party’s liability for:
                                    <div className={termsClasses.alphabeticList}>
                                        <ol type='a'>
                                            <li>
                                                death or personal injury caused by its negligence, or the negligence of its
                                                employees, agents or subcontractors (as applicable);
                                            </li>
                                            <li>
                                                fraud or fraudulent misrepresentation; or
                                            </li>
                                            <li>
                                                any matter in respect of which it would be unlawful for either party to
                                                exclude or restrict liability.
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Termination</strong>
                            <ol>
                                <li>
                                    Either party may terminate this Licence with immediate effect, by giving written
                                    notice to the other party. Termination or expiry of this Licence shall not affect
                                    either party’s accrued rights and remedies. On the termination of this Licence you
                                    shall (except to the extent to which at the time of termination or expiry, you have
                                    an appropriate licence from us) immediately cease using all Address API Services and
                                    / or all OS API Data.
                                </li>
                                <li>
                                    Any provision that is expressly or by implication intended to survive the
                                    termination or expiry of this Licence shall continue in full force and effect.
                                </li>
                                <li>
                                    On the expiry of any Trial Period you shall (except to the extent to which at the
                                    time of expiry, you have an appropriate licence from us) immediately cease using the
                                    Address API Service applicable to such Trial Period and / or all OS API Data
                                    thereunder.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Data protection and privacy</strong>
                            <ol>
                                <li>
                                    You shall comply with all relevant laws and regulations relating to the processing
                                    of personal data and privacy, including, without limitation any data protection
                                    legislation from time to time in force in the UK, including the General Data
                                    Protection Regulation 2016/679, the Data Protection Act 2018 and any successor
                                    legislation to any of them.
                                </li>
                                <li>
                                    We shall comply with the terms of our privacy policy available at <ExternalLink
                                        type='generic'
                                        href="https://ordnancesurvey.co.uk/governance/policies/privacy"
                                        message="https://ordnancesurvey.co.uk/governance/policies/privacy"
                                        ariaLabel="Privacy Policy (Opens in a New Tab)"
                                    />
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Confidentiality</strong>
                            <p>
                                Neither you nor we will disclose to any person (either during the term of this Licence
                                or after), any information which might reasonable be considered confidential information
                                belonging to the other party which is in its possession as a result of this Licence,
                                unless with the consent of the other party or due to a legal, governmental or regulatory
                                requirement. You acknowledge that we are bound by the Freedom of Information Act 2000
                                and the Environment Information Regulations 2004, which might require us by law to
                                disclose confidential information. You will provide such assistance, without charge, as
                                we shall reasonably request to assist us to comply with this legislation.
                            </p>
                        </li>
                        <li>
                            <strong>Assignment</strong>
                            <ol>
                                <li>
                                    You must not assign, transfer or sub-license your rights under this Licence to any
                                    other person.
                                </li>
                                <li>
                                    We shall be entitled to assign, transfer or novate the benefits and obligations of
                                    this Licence or any part of it to:
                                    <div className={termsClasses.alphabeticList}>
                                        <ol type='a'>
                                            <li>
                                                in the event of the transfer of all or any of our activities or functions to
                                                any other entity, the entity to which our functions have been transferred;
                                                or
                                            </li>
                                            <li>
                                                any private sector body which substantially performs the functions of us,
                                                provided that any such assignment, transfer or novation shall not increase
                                                the burden of your obligations under this Licence.
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Other General Provisions</strong>
                            <ol>
                                <li>
                                    This Licence sets out the entire agreement and understanding between you and us in
                                    respect of the subject matter of this Licence.
                                </li>
                                <li>
                                    We may change any part of this Licence, including the availability of any Address
                                    API Service and / or any OS API Data, at any time with immediate effect for valid
                                    and legal reasons where reasonable circumstances dictate this. We shall endeavour to
                                    provide reasonable notice of such changes but in certain circumstances we may be
                                    required to make changes with immediate effect. If you continue to access updates of
                                    any Address API Service and / or any OS API Data after any such change is made, you
                                    shall be deemed to have accepted the modified terms. If you do not wish to accept
                                    the modified terms, you must terminate this Licence by giving us written notice. It
                                    is therefore recommended that you regularly access and review this Licence so that
                                    you are aware of the latest terms.
                                </li>
                                <li>
                                    Any notice under this Licence shall be affected when given:
                                    <div className={termsClasses.alphabeticList}>
                                        <ol type='a'>
                                            <li>
                                                by us to your e-mail address that you have provided to us; and
                                            </li>
                                            <li>
                                                by you, if you <ExternalLink type='support' /> or use any
                                                other such means of contact as we may provide to you.
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    Our failure to exercise or enforce any rights under the provisions of this Licence
                                    shall not be deemed to be a waiver of such
                                    rights at any time or times thereafter.
                                </li>
                                <li>
                                    If any of the provisions of this Licence are invalid, illegal or unenforceable, that
                                    will not affect the validity, legality and
                                    enforceability of the remaining provisions of this Licence. Each of the paragraphs
                                    of these Licence terms operates separately.
                                    If any court or relevant authority decides that any of them are unlawful, the
                                    remaining paragraphs will remain in full force
                                    and effect.
                                </li>
                                <li>
                                    This Licence shall not constitute or imply any partnership, joint venture, agency,
                                    fiduciary relationship or other relationship
                                    between the parties other than the contractual relationship expressly provided for
                                    in this Licence. Neither party to this
                                    Licence shall have, nor represent that it has, any authority to make any commitments
                                    on the other party’s behalf.
                                </li>
                                <li>
                                    A person who is not a party to this Licence has no right as a third party to enforce
                                    or enjoy the benefit of any term of this
                                    Licence.
                                </li>
                                <li>
                                    This Licence is governed by English law and both parties agree to the exclusive
                                    jurisdiction of the English courts.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </Typography>
            </div>
        </Document>
    );
}

export default TrialModeTerms;