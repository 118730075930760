import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from "@mui/material";
import {createUseStyles} from 'react-jss';
import { osColour } from "omse-components";
import { defineMessages, useIntl } from "react-intl";
import Link from '../../../../../components/Link';
import routes from '../../../../../util/routes';

const messages = defineMessages({
    menuAriaLabel: {
        id:"eaiTabMenu.ariaLabel",
        defaultMessage: "energy and infrastructure dashboard menu",
        description: "Aria label for the menu"
    },
    eaiTab:{
        id:'eaiTabMenu.eai',
        defaultMessage: "Energy and infrastructure plan",
        description: "Energy and Infrastructure Tab"
    },
    otherTab:{
        id: "eaiTabMenu.other",
        defaultMessage: "Other plans",
        description: "Other Plans Tab"
    },
    history: {
        id: "eaiTabMenu.history",
        defaultMessage: "API transaction history",
        description: "API transaction history"
    }
})

const useStyles = createUseStyles(theme=>({
    menuContainer: {
        borderBottom: `1px solid ${osColour.neutral.charcoal}`,
        display: 'flex',
        marginBottom: theme.spacing(3)
    },
    menuLink:{
        textDecoration: 'none !important',
    },
    menuTab:{
        borderRadius: 0,

        margin: theme.spacing(1),
        color: osColour.neutral.rock,
        fontWeight: "normal",
        fontSize: theme.typography.body1.fontSize,
    },
    activeTab:{
        color: osColour.neutral.charcoal
    }
}));

export default function EaiTabMenu({clickHandler, activeTab}) {
    const classes = useStyles();

    const intl = useIntl();

    const changeHandler = (event, newIndex)=>{
        clickHandler(newIndex);
    }

    const menuClasses = {
        textColorInherit: classes.menuTab,
        selected: classes.activeTab
    };

    return (
        <Tabs
            value={activeTab}
            className={classes.menuContainer}
            onChange={changeHandler}
            aria-label={intl.formatMessage(messages.menuAriaLabel)}
            textColor='inherit'
            indicatorColor="secondary"
        >
            <Tab
                classes={menuClasses}
                label={intl.formatMessage(messages.eaiTab)}
            />
            <Tab
                classes={menuClasses}
                label={intl.formatMessage(messages.otherTab)}
            />

            <Link path={routes.history} className={classes.menuLink}>
                <Tab className={classes.menuTab} label={intl.formatMessage(messages.history)}/>
            </Link>
        </Tabs>
    )
}

EaiTabMenu.defaultProps = {
    clickHandler: ()=>{},
    //1 is intentionally the default as we want people to see this page if they do not have an EAI API plan (other plans tab)
    activeTab: 1
}

EaiTabMenu.propTypes = {
    clickHandler: PropTypes.func,
    activeTab: PropTypes.number
}
