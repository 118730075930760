
import {getLocation} from "../util/routes";
import {useLocation, useHistory} from 'react-router';

// Hook that helps us navigate back from the current page to the predecessor. We can't always just pop the history
// stack, as that could take us outside our app... so when that isn't an option then we navigate forwards to the
// default path instead.

export default function useBackNavigation(defaultPath) {
    const location = useLocation();
    const history = useHistory();
    return function goBack() {
        if(location.state?.canGoBack) {
            history.goBack();
        } else {
            const newLocation = getLocation(defaultPath, location, {}, { state: location.state?.stored });
            history.push(newLocation);
        }
    }
}

