import React from "react";
import PropTypes from "prop-types";
import { defineMessages, FormattedMessage } from "react-intl";
import { createUseStyles } from 'react-jss';
import { Box, Typography, Table, TableRow, TableCell, TableBody } from "@mui/material";
import { osColour } from "omse-components";
import BarGraph from '../../../components/BarGraph';
import { COMMERCIAL } from "../../../components/barGraph/styles/graph";

const messages = defineMessages({
    valueWarning:{
        id: "apiPlanDetails.valueWarning",
        defaultMessage: "Looks like your plan has less than 20% of it's original value. You should think about purchasing a new API Plan soon.",
        description: "error message"
    },
    dateWarning:{
        id: "apiPlanDetails.dateWarning",
        defaultMessage: "Looks like your plan has less than 1 month remaining before expiring. You should think about purchasing a new API Plan soon.",
        description: "error message"
    },
    purchaseDate: {
        id:"apiPlanDetails.purchaseDate",
        defaultMessage: "Purchase date:",
        description: "table row 1"
    },
    openingBalance: {
        id: "apiPlanDetails.openingBalance",
        defaultMessage: "Opening balance:",
        description: "opening balance table row"
    },
    openingTransactions: {
        id: "apiPlanDetails.openingTransactions",
        defaultMessage: "Opening transactions*:",
        description: "Opening Transactions"
    },
    currentBalance: {
        id: "apiPlanDetails.currentBalance",
        defaultMessage: "Remaining balance:",
        description: "current balance table row"
    },
    currentBalanceDate: {
        id: "apiPlanDetails.currentBalanceDate",
        defaultMessage: "Balance last updated:",
        description: "current balance table row"
    },
    tier: {
        id:"apiPlanDetails.tier",
        defaultMessage: "Tier:",
        description: "tier table row"
    },
    remainingTransactions:{
        id: "apiPlanDetails.remainingTransactions",
        defaultMessage: "Remaining transactions*:",
        description: "remaining transactions"
    },
    expiryDate: {
        id:"apiPlanDetails.expiryDate",
        defaultMessage: "Plan expiration date:",
        description: "expiry table row"
    },
    demoProduct: {
        id: "apiPlanDetails.demoProduct",
        defaultMessage: "*Indicative transactions based on £{unitPrice} per {name} transaction",
        description:"footnote"
    },
    countLabel: {
        id: 'myApiPlans.countLabel',
        defaultMessage: 'Transactions used',
        description: 'total transactions graph label '
    },
});

const useStyles = createUseStyles(theme => ({
    detailsContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    detailTable: {
        marginBottom:theme.spacing(2)
    },
    errorMessage: {
        color: osColour.status.error
    }
}));

export default function ApiPlanDetails({plan, demoProductName}) {
    const classes = useStyles();

    const valueWarning = Number(plan.openingBalance)*0.2 > Number(plan.provisionalBalance);
    const today = new Date();
    const oneMonth = new Date(today.getFullYear(), today.getMonth()+1, today.getDate());
    const dateWarning = oneMonth.getTime() > plan.expirationDate.getTime();

    const formatDate = (dateField)=>{
        let date = new Date(dateField);
        return isNaN(date.getTime()) ? "-" : date.toLocaleDateString("en-GB");
    }

    return (
        <Box className={classes.detailsContainer}>
            {valueWarning &&
                <Typography variant="body1" className={classes.errorMessage} data-testid="low-value-warning">
                    <FormattedMessage {...messages.valueWarning}/>
                </Typography>
            }
            {dateWarning &&
                <Typography variant="body1" className={classes.errorMessage} data-testid="date-warning">
                    <FormattedMessage {...messages.dateWarning}/>
                </Typography>
            }

            <Table className={classes.detailTable} data-testid="api-details-table">
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <FormattedMessage {...messages.purchaseDate}/>
                        </TableCell>
                        <TableCell>
                            {formatDate(plan.created)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormattedMessage {...messages.openingBalance}/>
                        </TableCell>
                        <TableCell>
                            £{Number(plan.openingBalance).toLocaleString() || ""}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormattedMessage {...messages.openingTransactions}/>
                        </TableCell>
                        <TableCell>
                            {plan.openingTransactions?.toLocaleString() || ""}
                        </TableCell>
                    </TableRow>
                    {plan.tier &&
                        <TableRow>
                            <TableCell>
                                <FormattedMessage {...messages.tier}/>
                            </TableCell>
                            <TableCell>
                                {plan.tier.name || ""}
                            </TableCell>
                        </TableRow>
                    }
                    <TableRow>
                        <TableCell>
                            <FormattedMessage {...messages.currentBalance}/>
                        </TableCell>
                        <TableCell>
                            £{Number(plan.provisionalBalance).toLocaleString() || ""}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormattedMessage {...messages.remainingTransactions}/>
                        </TableCell>
                        <TableCell>
                            {plan.provisionalTransactions?.toLocaleString() || ""}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormattedMessage {...messages.currentBalanceDate}/>
                        </TableCell>
                        <TableCell>
                            {formatDate(plan.provisionalUpdated)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormattedMessage {...messages.expiryDate}/>
                        </TableCell>
                        <TableCell>
                            {formatDate(plan.expirationDate)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Typography variant="body2">
                <FormattedMessage {...messages.demoProduct} values={{unitPrice:plan.tier?.unitPrice, name:demoProductName}}/>
            </Typography>
            {(plan?.openingTransactions && plan?.usedTransactions>=0) &&
                <BarGraph
                    graphData={[{
                        total:plan.openingTransactions,
                        breakdown:[{
                            name: "OS Energy & Infrastructure",
                            data:plan.usedTransactions,
                            class:COMMERCIAL
                        }]
                    }]}
                    total={plan.openingTransactions}
                    count={plan.usedTransactions}
                    variant="small"
                    countLabel={{...messages.countLabel}}
                    graphStyle={{height: 22, marginTop: 4, marginBottom: 0}}
                    displayCountLabel={true}
                    displayCount={true}
                    displayKey={true}
                />
            }
        </Box>
    )
}

ApiPlanDetails.propTypes = {
    plan: PropTypes.object.isRequired,
    demoProductName: PropTypes.string
};
