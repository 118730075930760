// Hook to return the geometry for a file.
import {useSelector} from 'react-redux';

export default function useLoadedPolygon(file) {
    // Each of these has {result, loading, error} state
    const savedPolygon = useSelector(state => state.polygon.load);
    const dataPackage = useSelector(state => state.dataPackages.current);

    let polygon;
    if(file) {
        // Some loaded files are just a string, some have an id property.
        const id = file.id || file;
        if(savedPolygon.result && savedPolygon.result.id === id) {
            polygon = savedPolygon.result.geometry;
        } else if(dataPackage.result && dataPackage.result.id === id) {
            polygon = dataPackage.result.polygon;
        }
    }

    const loading = savedPolygon.loading || dataPackage.loading;
    const error = savedPolygon.error || dataPackage.error;

    return [polygon, loading, error];
}
