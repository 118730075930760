import React from "react";
import {defineMessages, FormattedMessage} from "react-intl";
import {createUseStyles} from "react-jss";
import { ReactComponent as Email} from "../../../../components/icons/email.svg"
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';

const messages = defineMessages({
    link: {
        id: "ExternalEmailButton.link",
        defaultMessage: "Get help from {provider}",
        description: "Email link label"
    }});

const styles = createUseStyles(theme => ({
    linkButton: {
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2)
        }
    },
    emailIcon: {
        marginRight: theme.spacing(1)
    }
}));

export default function ExternalEmailButton({ item }) {
    const classes = styles();

    if (!item?.thirdPartyInfo) {
        return;
    }

    const supportEmail = `mailto:${item.thirdPartyInfo.supportEmail}`;
    const providerShortName = item.thirdPartyInfo.dataProvider.shortName;

    return (<>
            <a href={supportEmail} className={classes.linkButton}>
                <Button variant={"outlined"} color={"primary"} size={"small"}>
                    <Email className={classes.emailIcon} />
                    <Typography>
                        <FormattedMessage
                            {...messages.link}
                            values={{provider: providerShortName}}
                        />
                    </Typography>
                </Button>
            </a>
        </>
    );
}
