// A react hook that pulls the named search parameter out of the location, and allows us
// to set it

import {useHistory, useLocation} from 'react-router';
import queryString from 'query-string';

export default function useSearchParam(paramName, defaultValue) {
    const location = useLocation();
    const history = useHistory();

    let parsed = queryString.parse(location.search);
    let hasValue = Object.keys(parsed).indexOf(paramName) !== -1;
    let value;
    if(hasValue) {
        value = parsed[paramName];
    } else {
        value = defaultValue;
    }

    // Check if we have an explicit param setting the default value. If we do then the param
    // is redundant, so we should clean it up to simplify the app url.
    if(value === defaultValue && hasValue) {
        setValue(defaultValue);
    }

    function setValue(value) {
        delete parsed[paramName];
        if(value !== defaultValue) {
            parsed[paramName] = value;
        }

        history.replace({
            ...location,
            search: queryString.stringify(parsed)
        });
    }

    return [value, setValue];
}
