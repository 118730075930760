import stripesSvg from '../components/icons/stripes.svg';

function asyncImgLoad(src) {    
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function() {            
            resolve(this);
        }
        img.onerror = function() {
            reject('Error loading ' + this.src);
        }
        img.src = src;
    });
}

// Safari bug https://bugs.webkit.org/show_bug.cgi?id=45277
export function redraw(img, alpha) {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    if (alpha) {
        ctx.globalAlpha = alpha;
    }
    ctx.drawImage(img, 0, 0, img.width, img.height);
    return canvas;
}

export async function loadStripes(setter, alpha, isSubscribed = () => true) {
    let stripes = await asyncImgLoad(stripesSvg);
    stripes = redraw(stripes, alpha);
    if (isSubscribed()) {
        setter(stripes);
    }
}