import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {CommonDialog, CommonDialogActions, useActionIdSelector} from 'omse-components';
import {FormattedMessage, defineMessages, useIntl} from 'react-intl';
import {Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {deleteRecipe} from "../../../../modules/recipes/actions";
import {useHistory, useLocation} from 'react-router';

const messages = defineMessages({
    title: {
        id: "DeleteRecipeDialog.title",
        defaultMessage: "Delete recipe?",
        description: "Title for recipe deletion dialog"
    },
    noDataPackageWarning: {
        id: "DeleteRecipeDialog.noDataPackageWarning",
        defaultMessage: "This recipe will disappear from your library.",
        description: "Warning for deleting recipe with existing data packages"
    },
    dataPackageWarning: {
        id: "DeleteRecipeDialog.dataPackageWarning",
        defaultMessage: "Data packages have been created from this recipe.",
        description: "Warning for deleting recipe with existing data packages"
    },
    dataPackageWarningStopedUpdates: {
        id: "DeleteRecipeDialog.dataPackageWarningStopedUpdates",
        defaultMessage: "These packages will stop receiving updates",
        description: "Warning information for deleting recipe with existing data packages"
    },
    dataPackageWarningImmutablePackages: {
        id: "DeleteRecipeDialog.dataPackageWarningImmutablePackages",
        defaultMessage: "You will not be able to make changes to the packages",
        description: "Warning information for deleting recipe with existing data packages"
    },
    dataPackageWarningRecipeRemoved: {
        id: "DeleteRecipeDialog.dataPackageWarningRecipeRemoved",
        defaultMessage: "The recipe will disappear from your library, and you will only be able to view it from an associated package",
        description: "Warning information for deleting recipe with existing data packages"
    },
    confirm: {
        id: "DeleteRecipeDialog.confirm",
        defaultMessage: "Delete recipe",
        description: "Label for the confirm button on recipe deletion dialog"
    },
    cancel: {
        id: "DeleteRecipeDialog.cancel",
        defaultMessage: "Cancel",
        description: "Label for the cancel button on recipe deletion dialog"
    },
    deletedRecipeFailure: {
        id: 'RecipeDetailsHeader.deletedRecipeFailure',
        defaultMessage: 'An error occurred while deleting the recipe. Please try again and contact support if the problem continues.',
        description: 'Notification message for failure to delete'
    }
});

const styles = createUseStyles(theme => ({
    dialogueBody: {
        gap: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column'
    },
    messageTextArea:{
        width: '100%',
        resize: 'none'
    },
    input: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
    innerInput: {
        margin: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingTop: 6,
        paddingBottom: 5,
        '&::placeholder': {
            paddingTop: 2
        }
    },
}));



export default function DeleteRecipeDialog(props) {
    const {recipeId, hasDatapackages, onClose} = props;

    const intl = useIntl();
    const classes = styles();
    const location = useLocation();
    const history = useHistory();

    const [{result, working, error}, dispatch] = useActionIdSelector("recipes.deleteRecipe");

    const warningMessage = hasDatapackages ? messages.dataPackageWarning : messages.noDataPackageWarning;

    useEffect(() => {
        // If we get a response we should close and the RecipeDetailsHeader will communicate the result
        if(result !== undefined) {
            onClose(true);
        }
    }, [result, working, onClose, history, location]);


    const actions = <CommonDialogActions
        working={working}
        showCancelButton={true}
        confirmLabel={intl.formatMessage(messages.confirm)}
        onClose={() => onClose(false)}
        onConfirm={() => dispatch(deleteRecipe(recipeId))}
    />;

    return <CommonDialog onClose={() => {onClose();}} actions={actions} title={messages.title} error={error && intl.formatMessage(messages.deletedRecipeFailure)}>
        <div className={classes.dialogueBody}>
            <Typography variant='body2'>
                <FormattedMessage {...warningMessage}/>
            </Typography>
            {
                hasDatapackages &&
                    <Typography variant='body1'>
                        <ul>
                            <li><FormattedMessage {...messages.dataPackageWarningStopedUpdates}/></li>
                            <li><FormattedMessage {...messages.dataPackageWarningImmutablePackages}/></li>
                            <li><FormattedMessage {...messages.dataPackageWarningRecipeRemoved}/></li>
                        </ul>

                    </Typography>
            }
        </div>
    </CommonDialog>;
}

DeleteRecipeDialog.propTypes = {
    recipeId: PropTypes.string.isRequired
};
