import React from 'react';
import Typography from '@mui/material/Typography';

export default function UsingWMTS(props) {
    return <Typography variant='body1' paragraph={true} component='div'>
        <p>With OS Maps API:</p>
        <p>You can:</p>
        <ul>
            <li>Use as a basemap in GIS, web or mobile applications.</li>
            <li>Overlay your own data to give it geographic context.</li>
            <li>Trace over OS MasterMap Topography Layers (Premium Data) detailed geometries.</li>
            <li>View the whole of Great Britain in unrivalled detail.</li>
            <li>View the whole of Great Britain in brilliant, elegant detail.</li>
            <li>Access the iconic 1:25 000 Scale Colour Raster (Premium Data).</li>
            <li>Present mapping in different styles according to your needs.</li>
            <li>Access the maps in different projections - British National Grid and Web Mercator.</li>
        </ul>
        <p>You can't:</p>
        <ul>
            <li>Restyle the maps as they are pre-rendered images.</li>
            <li>Interact with the map features.</li>
        </ul>
    </Typography>
}
