import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import routePaths, { getLocation } from "../../util/routes";
import { useHistory } from 'react-router';
import Link from "../../components/Link";
import { modules, ExternalLink } from 'omse-components';
import OrgDetailsForm from "../../components/OrgDetailsForm";
import Error404 from "../Error404";
import { hasUpgradeAccountPermission } from "../../util/permissions";
import { CRM_PENDING_PSGA_PLAN, CRM_PSGA_PLAN } from '../../../shared/plans';
import PlanSetup from '../../components/plans/PlanSetup';

const messages = defineMessages({
    title: {
        id: 'PremiumPlanSetup.title',
        defaultMessage: 'Premium plan details',
        description: 'Title for the premium plan dialog'
    },
    sorry: {
        id: 'PremiumPlanSetup.sorry',
        defaultMessage: 'Sorry, we were unable to upgrade your account',
        description: 'Prelude to the error message on the premium plan setup screen'
    },
    nextSteps: {
        id: 'PremiumPlanSetup.nextSteps',
        defaultMessage: "You should contact your organisation's DataHub administrator and request access to the Premium Plan.",
        description: 'Displayed when we have failed to set up the new organisation'
    },
    contactUs: {
        id: 'PremiumPlanSetup.contactUs',
        defaultMessage: "If you don't know who your account administrator is, please {link}.",
        description: 'Displayed when we have failed to set up the new organisation'
    },
    dashboardMessage: {
        id: 'PremiumPlanSetup.dashboardMessage',
        defaultMessage: "In the meantime, you may return to the {dashboard} and continue using free OS OpenData.",
        description: 'Displayed when we have failed to set up the new organisation'
    },
    dashboardLink: {
        id: 'PremiumPlanSetup.dashboardLink',
        defaultMessage: "API Dashboard",
        description: 'Displayed when we have failed to set up the new organisation'
    },
    getPremiumPlan: {
        id: 'PremiumPlanSetup.getPremiumPlan',
        defaultMessage: 'Get Premium plan',
        description: 'Title for confirmation button'
    }
});


export default function PremiumPlanSetup({ testAlreadyInitialised }) {
    const loading = useSelector(state => state.user.current.loading);
    const user = useSelector(state => state.user.current.result);
    const upgrade = useSelector(state => state.user.upgrade);
    const history = useHistory();
    const dispatch = useDispatch();
    const [initialised, setInitialised] = useState(testAlreadyInitialised || false);

    // Some users should move on as soon as this page loads. If we have one of those then this effect takes care of
    // the navigation. This will only act when we have our first look at the user object, as the user object can
    // change later and we don't want to head off too soon.
    useEffect(() => {
        if (user && !initialised) {
            setInitialised(true);

            if (user.hasInvitation) {
                // If the user has an outstanding invitation then they need to deal with that before trying
                // to upgrade themselves.
                const newLocation = getLocation(routePaths.invitation, history.location, { continuePremiumSetup: null });
                history.replace(newLocation);
                return;
            }
            if (!hasUpgradeAccountPermission(user)) {
                // If an existing organisation member types in the address of this page then they need to move on
                // to the dashboard.
                const newLocation = getLocation(routePaths.dashboard, history.location);
                history.replace(newLocation);
                return;
            }
        }
    }, [user, history, upgrade, initialised, setInitialised]);

    // When the upgrade is successful we need to move on to the payment details screen, but it can take a moment for
    // the updated user object to be available. We wait until that change has stabalised before we allow the navigation.
    useEffect(() => {
        if (upgrade.result && user === upgrade.result) {
            const newLocation = getLocation(routePaths.paymentSetup, history.location);
            history.replace(newLocation);
            return;
        }
    }, [user, history, upgrade]);

    if (loading) {
        return (
            <PlanSetup>
                <CircularProgress size={32} />
            </PlanSetup>
        );
    }

    if (upgrade.error) {
        return (
            <PlanSetup>
                <Typography variant='h1' color='primary'>
                    <FormattedMessage {...messages.sorry} />
                </Typography>
                <Typography variant='body2' paragraph={true}>
                    <FormattedMessage {...messages.nextSteps} />
                </Typography>
                <Typography variant='body1' paragraph={true}>
                    <FormattedMessage {...messages.dashboardMessage} values={{
                        dashboard: <Link path={routePaths.dashboard}>
                            <FormattedMessage {...messages.dashboardLink} />
                        </Link>
                    }} />
                </Typography>
                <Typography variant='caption' paragraph={true} className='captionText'>
                    <FormattedMessage {...messages.contactUs} values={{ link: <ExternalLink type='support' /> }} />
                </Typography>
            </PlanSetup>
        );
    }

    function submit(accountType, orgName, jobTitle, phone) {
        const action = modules.actions.user.upgradeToPremium(accountType, orgName, jobTitle, phone);
        dispatch(action);
    }

    function isOrgPlan(plan) {
        return Boolean(user?.orgPlan && user.orgPlan === plan);
    }

    if (isOrgPlan(CRM_PENDING_PSGA_PLAN) || isOrgPlan(CRM_PSGA_PLAN)) {
        return <Error404 />;
    }

    return (
        <PlanSetup>
            <Typography variant='h1' color='primary'>
                <FormattedMessage {...messages.title} />
            </Typography>

            <OrgDetailsForm onSubmit={submit}
                submitLabel={messages.getPremiumPlan}
                working={upgrade.working || !!upgrade.result}
            />
        </PlanSetup>
    );
}

PremiumPlanSetup.propTypes = {
    testAlreadyInitialised: PropTypes.bool
}
