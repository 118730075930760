import PropTypes from 'prop-types';
import BackLink from "./BackLink";
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import { border1, contentPadding, isWidthUp, withWidth } from "omse-components";
import { centeredContent } from '../pages/styles/common';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { css } from '@emotion/css';

// Variants
export const centerContainer = 'center-container';
export const centerText = 'center-text';

const StyledHeader = styled('header')(({ theme }) => `
    position: relative;
    padding-top: ${contentPadding.top}px;
    padding-right: ${contentPadding.right}px;
    padding-bottom: ${contentPadding.bottom}px;
    padding-left: ${contentPadding.left}px;
    border-bottom: ${border1};
    ${theme.breakpoints.down('sm')} {
        padding: ${contentPadding.mobile};
    }
`);

const centeredOuterClass = css`
    padding-left: 0;
    padding-right: 0;
`;
const useCenteredInnerStyles = maxWidth => css`
    ${centeredContent};
    padding-top: 0;
    padding-bottom: 0;
    max-width: ${maxWidth || contentPadding.maxWidth}px;
`;
const centeredTextClass = css`
    display: flex;
    justify-content: center;
`;
const withBacklinkClass = css`
    padding-top: ${contentPadding.top - contentPadding.backLink}px;
`;

function ContentHeader(props) {
    const { backPath, backLabel, title, subtitle, actions, children, variant, width, maxWidth } = props;
    const headerClasses = [];
    const headerInnerClasses = [];
    const centeredInnerClass = useCenteredInnerStyles(maxWidth);
    if ((variant === centerContainer) && isWidthUp('sm', width)) {
        headerClasses.push(centeredOuterClass);
        headerInnerClasses.push(centeredInnerClass);
    }
    if (variant === centerText) {
        headerInnerClasses.push(centeredTextClass);
    }
    if (backPath && backLabel) {
        headerClasses.push(withBacklinkClass);
    }
    return (
        <>
            {(backPath && backLabel) &&
                <BackLink path={backPath} label={backLabel} />
            }
            <StyledHeader className={classNames(headerClasses)}>
                <div className={classNames(headerInnerClasses)}>
                    <Typography variant='h1' color='primary'>
                        {(title && title.id && title.defaultMessage) ?
                            <FormattedMessage {...title} />
                            :
                            <>{title}</>
                        }
                        {actions}
                    </Typography>
                    {subtitle &&
                        <Typography variant='body1' color='textPrimary'>
                            <FormattedMessage {...subtitle} />
                        </Typography>
                    }
                    {children}
                </div>
            </StyledHeader>
        </>
    );
}

ContentHeader.propTypes = {
    backPath: PropTypes.string,
    backLabel: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    subtitle: PropTypes.object,
    maxWidth: PropTypes.number,
    variant: PropTypes.oneOf([centerContainer, centerText])
};

export default withWidth()(ContentHeader);