import { defineMessages } from 'react-intl'; 

export const CodeExamplesCardMessages = defineMessages({
    title: {
        id: 'CodeExamplesCard.title',
        defaultMessage: 'Code examples',
        description: 'Title'
    },
    description: {
        id: 'CodeExamplesCard.description',
        defaultMessage: 'Use our copy-and-paste code to start building your own solutions, maps and data visualisations.',
        description: 'Description'
    },
    link: {
        id: 'CodeExamplesCard.link',
        defaultMessage: 'Explore code examples',
        description: 'Link'
    },
    imageDescription: {
        id: 'CodeExamplesCard.imageDescription',
        defaultMessage: 'a person working at a computer with geospatial data on a map',
        description: 'Image description'
    }
});

export const ApiDemosCardMessages = defineMessages({
    title: {
        id: 'ApiDemosCard.title',
        defaultMessage: 'API demos',
        description: 'Title'
    },
    description: {
        id: 'ApiDemosCard.description',
        defaultMessage: 'View demos showing how to integrate OS Data Hub APIs into your own applications.',
        description: 'Description'
    },
    link: {
        id: 'ApiDemosCard.link',
        defaultMessage: 'View API demos',
        description: 'Link'
    },
    imageDescription: {
        id: 'ApiDemosCard.imageDescription',
        defaultMessage: 'a map showing unnamed points of interest with a search radius polygon visible',
        description: 'Image description'
    }
});

export const ApiTutorialsCardMessages = defineMessages({
    title: {
        id: 'ApiTutorialsCard.title',
        defaultMessage: 'API tutorials',
        description: 'Title'
    },
    description: {
        id: 'ApiTutorialsCard.description',
        defaultMessage: 'Access common use cases and step-by-step guides to build an interactive map on your website, or new innovative projects of your own.',
        description: 'Description'
    },
    link: {
        id: 'ApiTutorialsCard.link',
        defaultMessage: 'View API tutorials',
        description: 'Link'
    },
    imageDescription: {
        id: 'ApiTutorialsCard.imageDescription',
        defaultMessage: 'a large-scale map of Devon and Cornwall, with the boundaries of National Parks highlighted',
        description: 'Image description'
    }
});