import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {defineMessages, FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import {createUseStyles} from 'react-jss';
import PsgaOrgDetailsForm from "./PsgaOrgDetailsForm";
import FeatureCheck from "../../components/FeatureCheck";
import routePaths, {getLocation} from "../../util/routes";
import { AddButton, contentPadding, modules, ExternalLink } from 'omse-components';
import {useHistory} from 'react-router';
import {acceptOrg, clearDeclineOrg, declineOrg} from '../../modules/psga/actions';
import Error404 from "../Error404";
import { CRM_PENDING_PSGA_PLAN, CRM_PSGA_PLAN, USER_OPEN_DATA_PLAN } from '../../../shared/plans';

const {refreshUser, applyPsga, clearPsga} = modules.actions.user;

const messages = defineMessages({
    title: {
        id: 'PsgaPlanSetup.title',
        defaultMessage: 'Public Sector Plan details',
        description: 'Title for the premium plan dialog'
    },
    cancel: {
        id: 'PsgaPlanSetup.cancel',
        defaultMessage: 'Cancel',
        description: 'Title for cancel button'
    },
    applyForPlan: {
        id: 'PsgaPlanSetup.applyForPlan',
        defaultMessage: 'Get Public Sector Plan',
        description: 'Title for submit button'
    },
    decline: {
        id: 'PsgaPlanSetup.decline',
        defaultMessage: 'Get OS OpenData Plan',
        description: 'decline organisation button title'
    },
    needToContactAdmin: {
        id: 'PsgaPlanSetup.needToContactAdmin',
        defaultMessage: 'You need to contact your OS Data Hub account administrator',
        description: 'contact admin title'
    },
    notRegistered: {
        id: 'PsgaPlanSetup.notRegistered',
        defaultMessage: "We can see you’re trying to sign up with an existing organisation but that organisation does not have you registered as a user on the OS Data Hub.",
        description: 'user not registered message'
    },
    contactUs: {
        id: 'PsgaPlanSetup.contactUs',
        defaultMessage: 'If you don’t know who your account administrator is, please {link}.',
        description: 'Displayed when we have failed to set up the new organisation'
    },
    dashboardMessage: {
        id: 'PsgaPlanSetup.dashboardMessage',
        defaultMessage: "In the meantime, you may continue to use a free OS OpenData Plan.",
        description: 'Displayed when we have failed to set up the new organisation'
    },
    dashboardLink: {
        id: 'PsgaPlanSetup.dashboardLink',
        defaultMessage: "API Dashboard",
        description: 'Displayed when we have failed to set up the new organisation'
    },
    youShould: {
        id: 'PsgaPlanSetup.youShould',
        defaultMessage: 'You should contact your organisation’s OS Data Hub administrator and ask them to invite you to access your organisation’s OS Data Hub account.',
        description: 'contact admin instruction message'
    },
    getOpenDataPlan: {
        id: 'PsgaPlanSetup.getOpenDataPlan',
        defaultMessage: 'Get OS OpenData Plan',
        description: 'get os open data plan'
    }
});

const styles = createUseStyles(theme => ({
    title: {
        marginTop: theme.spacing(8),
        textAlign: 'center',
        marginBottom: theme.spacing(4)
    },
    emailLink: {
        '& a': {
            fontSize: theme.typography.caption.fontSize
        }
    },
    content: {
        flex: '1 0 auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '500px',
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile
        }
    },
    buttons: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        '& button:first-of-type': {
            marginRight: theme.spacing(2)
        }
    }
}));

export default function PsgaPlanSetup() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.current.result);
    const loading = useSelector(state => state.user.current.loading);
    const refresh = useSelector(state => state.user.refresh);
    const accept = useSelector(state => state.psga.accept);
    const psga = useSelector(state => state.user.psga);
    const decline = useSelector(state => state.psga.decline);
    const { b2clogin } = useSelector(state => state.config.current.result);
    const history = useHistory();
    const classes = styles();
    const referrer = document.referrer;

    useEffect(() => {
        if ((psga && psga.result) || (accept && accept.result) || (decline && decline.result)) {
            if (!refresh.working && !refresh.result) {
                dispatch(refreshUser());
            }

            if (refresh && refresh.result) {
                if (decline && decline.result) dispatch(clearDeclineOrg());
                const newLocation = getLocation(routePaths.dashboard, history.location);
                history.replace(newLocation);
            }
        }

    }, [history, psga, accept, decline, refresh, dispatch]);

    if (!isUpgrade() && !isSignUp()) {
        return <Error404/>;
    }

    if(loading) {
        return <div className={classes.root}>
            <CircularProgress size={32}/>;
        </div>
    }

    function isUpgrade() {
        return history.location.state && history.location.state.upgrade;
    }

    function isSignUp() {
        return referrer && referrer.includes(b2clogin);
    }

    function submit(orgName, jobTitle, phone) {
        if (user.org) {
            const action = acceptOrg(jobTitle, phone);
            dispatch(action);
        } else {
            const action = applyPsga(orgName, jobTitle, phone);
            dispatch(action);
        }
    }

    function declineOrganisation() {
        const action = declineOrg();
        dispatch(action);
    }

    function goToDashboard() {
        dispatch(clearPsga());
        const newLocation = getLocation(routePaths.dashboard, history.location);
        history.replace(newLocation);
    }

    function contactAdministrator()  {
        return <Fragment>
            <div className={classes.content}>
                <Typography variant='h1' color='primary' className={classes.title}>
                    <FormattedMessage {...messages.needToContactAdmin}/>
                </Typography>

                <Typography variant='body1' paragraph={true}>
                    <FormattedMessage {...messages.notRegistered}/>
                </Typography>

                <Typography variant='body1' paragraph={true}>
                    <FormattedMessage {...messages.youShould}/>
                </Typography>

                <Typography variant='body1' paragraph={true}>
                    <FormattedMessage {...messages.dashboardMessage}/>
                </Typography>

                <Typography variant='caption' paragraph={true} className={classes.emailLink}>
                    <FormattedMessage {...messages.contactUs} values={{ link: <ExternalLink type='support' /> }} />
                </Typography>

                <div className={classes.buttons}>
                    <AddButton action={goToDashboard}
                               label={messages.getOpenDataPlan}
                               showIcon={false}
                    />
                </div>

            </div>
        </Fragment>;
    }

    function isOrgPlan(plan) {
        return user.orgPlan && user.orgPlan === plan;
    }

    function orgExists() {
        return psga.error && psga.error.httpResponse === 'ORG_EXISTS';
    }

    if (isOrgPlan(CRM_PENDING_PSGA_PLAN) && !refresh.working && !refresh.result) {
        return <Error404/>;
    }

    if ((isOrgPlan(CRM_PSGA_PLAN) && user.plan === USER_OPEN_DATA_PLAN) || orgExists()) return contactAdministrator();

    return <FeatureCheck feature='psga'>
        <Fragment>
            <Typography variant='h1' color='primary' className={classes.title}>
                <FormattedMessage {...messages.title}/>
            </Typography>

            <PsgaOrgDetailsForm
                onSubmit={submit}
                onCancel={goToDashboard}
                onDecline={declineOrganisation}
                cancelLabel={messages.cancel}
                submitLabel={messages.applyForPlan}
                declineLabel={messages.decline}
                working={psga.working || accept.working || decline.working || refresh.working || (refresh.result ? true : false)}/>
        </Fragment>
    </FeatureCheck>;
}
