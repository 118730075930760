import { contentPadding } from 'omse-components';
import styled from '@emotion/styled';

const PlanSetup = styled.div(({ theme }) => `
    align-self: center;
    max-width: ${theme.breakpoints.values.sm}px;
    padding: ${theme.spacing(8, 0)};
    & h1 {
        text-align: center;
        margin: ${theme.spacing(0, 0, 4, 0)};
    }
    ${theme.breakpoints.down('sm')} {
        padding: ${contentPadding.mobile};
        & h1 {
            margin-top: ${theme.spacing(4)};
        }
    }
    & .captionText a {
        font-size: ${theme.typography.caption.fontSize};
    }
`);

export default PlanSetup;