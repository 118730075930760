import {createUseStyles} from 'react-jss';
import {border1} from 'omse-components';

const styles = createUseStyles(theme => ({
    muiDialogRoot: {
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100% - 175px)',
            maxWidth: 'calc(100% - 175px)',
            height: 785
        }
    },
    dialogContent: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
    },
    inputWrapper: {
        borderTop: border1,
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1)
        }
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: '1 0 auto'
    },
    search: {
        flex: '1 0 auto'
    },
    listWrapper: {
        borderTop: border1,
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    folderList: {
        borderRight: border1,
        flex: '1 0 200px',
        overflow: 'auto'
    },
    fileList: {
        flex: '2 0 auto',
        overflow: 'auto',
        '& .infinite-scroll-component': {
            padding: theme.spacing(1)
        }
    },
    nested1: {
        paddingLeft: theme.spacing(4) // The buttons have spacing(2) anyway, so this is the default + 2
    },
    nested2: {
        paddingLeft: theme.spacing(6)
    }
}));

export default styles;
