import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import {useLocation} from 'react-router';
import useNavigator from "../hooks/useNavigator";

export default function NavButton({path, children, state, hash, ...buttonProps}) {
    const location = useLocation();
    const {navigate, setElement} = useNavigator({path, state, hash});

    const disabled = (buttonProps && buttonProps.disabled)
        ? (buttonProps.disabled || location.pathname === path)
        : location.pathname === path;

    return <Button {...buttonProps} onClick={navigate} disabled={disabled} ref={setElement}>
        {children}
    </Button>;
}

NavButton.propTypes = {
    children: PropTypes.node.isRequired, // The content of the button
    path: PropTypes.string.isRequired,   // The path to navigate to
    state: PropTypes.oneOfType([
        PropTypes.object,                // Optional, state to pass to the new location, or
        PropTypes.func                   // a function that can build the state when the button is clicked
    ]),
    hash: PropTypes.string,              // Optional, hash to add to the new location

    ...Button.propTypes                  // And any props that the material-ui button exposes
};
