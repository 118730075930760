import {osColour} from 'omse-components';

import {Circle as CircleStyle, Style, Stroke, Text, Fill} from 'ol/style';
import {asArray, asString} from 'ol/color';
import {featureToMultiPoint} from "./feature";
import FillPattern from 'ol-ext/style/FillPattern';

const MAX_TILE_GRID_RESOLUTION = 250;
const MAX_IMAGERY_TILE_GRID_RESOLUTION = 100;
const MAX_TILE_GRID_TEXT_RESOLUTION = 50;

const DELETE_COLOUR = osColour.status.error;
const DRAWING_COLOUR = osColour.primary.berry;
const EXPAND_DRAWING_COLOUR = osColour.status.info;
const SELECTED_TILE_COLOUR = osColour.status.success;

const dashedLine = (colour) => {
    return new Stroke({
        color: colour,
        width: 3,
        lineDash: [3,8]
    });
}

const solidLine = (colour) => {
    return new Stroke({
        color: colour,
        width: 3,
    });
}

const circle = (colour) => {
    return new CircleStyle({
        radius: 4,
        fill: new Fill({
            color: osColour.neutral.white,
        }),
        stroke: new Stroke({
            color: colour,
            width: 1,
        })
    });
}

const fill = (colour, alpha = 0.25) => {
    return new Fill({
        color: colorWithAlpha(colour, alpha)
    })
}

const dashLineWithCircleStyle = (colour) => {
    return [new Style({
        stroke: dashedLine(colour),
        fill: fill(colour),
        image: circle(colour)
    }),
        new Style({
            image: circle(colour),
            geometry: featureToMultiPoint,
            zIndex: 10
        })];
}

const solidLineStyle = (colour) => {
    return [new Style({
        stroke: solidLine(colour),
        fill: fill(colour)
    })];
}

const dashedLineStyle = (colour) => {
    return [new Style({
        stroke: dashedLine(colour),
        fill: fill(colour)
    })];
}

export function drawStyle() {
    return dashLineWithCircleStyle(DRAWING_COLOUR);
}

export function expandDrawStyle() {
    return dashLineWithCircleStyle(EXPAND_DRAWING_COLOUR);
}

export function deleteStyle() {
    return dashLineWithCircleStyle(DELETE_COLOUR);
}

export function expandedDrawingLayerStyle() {
    return solidLineStyle(EXPAND_DRAWING_COLOUR);
}

export function drawingLayerStyle() {
    return solidLineStyle(DRAWING_COLOUR);
}

export function selectTileStyle() {
    return solidLineStyle(SELECTED_TILE_COLOUR);
}

export function expandedTileStyle() {
    return [new Style({
        stroke: solidLine(SELECTED_TILE_COLOUR),
        fill: new FillPattern({
            pattern: 'dot',
            spacing: 6,
            scale: 0,
            size: 2,
            color: colorWithAlpha(osColour.neutral.charcoal, 0.75),
            fill: fill(SELECTED_TILE_COLOUR)
        })
    })];
}

// To save creating new style objects every time we render a feature we set up some constants here. For the style that
// includes the feature id as text you might expect to new up a Style object each time, but we can use a static one
// and tailor it for each use.
// Inspired by: https://openlayers.org/en/latest/examples/vector-layer.html
const tileLayerNoStyle = [];
const tileLayerStroke = new Stroke({
    color: 'black'
});
const tileLayerWithoutText = new Style({
    stroke: tileLayerStroke
});
const tileLayerWithText = new Style({
    stroke: tileLayerStroke,
    text: new Text() // No label details, yet
});
function buildTileLayerStyle(gridMax, textMax) {
    return function tileLayerStyle(feature, resolution) {
        if(resolution > gridMax) return tileLayerNoStyle;
        if(resolution > textMax) return tileLayerWithoutText;

        // We are close enough in to need label text, so we need to customise the label
        tileLayerWithText.getText().setText(feature.getId());
        return tileLayerWithText;
    };
}

export const tileLayerStyle = buildTileLayerStyle(MAX_TILE_GRID_RESOLUTION, MAX_TILE_GRID_TEXT_RESOLUTION);
export const imageryTileLayerStyle = buildTileLayerStyle(MAX_IMAGERY_TILE_GRID_RESOLUTION, MAX_TILE_GRID_TEXT_RESOLUTION);

export function highlightedPolygonStyle() {
    return dashedLineStyle(DRAWING_COLOUR);
}

export function highlightedExpandPolygonStyle() {
    return dashedLineStyle(EXPAND_DRAWING_COLOUR);
}

export function clickedPolygonStyle() {
    return dashLineWithCircleStyle(DRAWING_COLOUR);
}

export function clickedExpandPolygonStyle() {
    return dashLineWithCircleStyle(EXPAND_DRAWING_COLOUR);
}

function colorWithAlpha(color, alpha) {
    const [r, g, b] = Array.from(asArray(color));
    return asString([r, g, b, alpha]);
}
