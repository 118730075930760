import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage, FormattedDate} from 'react-intl';
import {CommonDialog, CommonDialogActions} from "omse-components";
import {Typography, Table, TableBody, TableRow, TableCell} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {osColour} from "omse-components";

const messages = defineMessages({
    title:{
        id:"confirmationModal.title",
        defaultMessage:"Confirm order",
        description: "Confirmation title on the modal box"
    },
    apiTransactionsLabel:{
        id: "confirmationModal.apiTransactionsLabel",
        defaultMessage: "API total transactions",
        description: "API transactions table row label"
    },
    apiTransactionsValue: {
        id: "confirmationModal.apiTransactionValue",
        defaultMessage: "{transactions}",
        description: "API transactions table row value"
    },
    tierLabel: {
        id: "confirmationModal.tierLabel",
        defaultMessage: "{tierLabel}",
        description: "Tier table row label"
    },
    tierValue: {
        id: "confirmationModal.tierValue",
        defaultMessage: "£{unitPrice} per transaction",
        description: "Tier table row value"
    },
    product:{
        id:"confirmationModal.productLabel",
        defaultMessage: "Based on {product}",
        description: "Tier table row product label"
    },
    invoiceAmount:{
        id: "confirmationModal.invoiceAmount",
        defaultMessage: "Invoice amount: £{value}",
        description: "invoice amount for product label"
    },
    errorMessage: {
        id:"confirmationModal.errorMessage",
        defaultMessage: "There was an error purchasing API plan. Please try again later.",
        description: "error message for the confirmation page"
    },
    purchaseButton:{
        id: "confirmationModal.purchaseButton",
        defaultMessage: "Purchase",
        description: "Purchase Button Label"
    },
    planExpireLabel:{
        id:"confirmationModal.expireLabel",
        defaultMessage: "Plan Expiration Date",
        description: "Expiration Date label"
    },
    planExpire:{
        id: "confirmationModal.expire",
        defaultMessage: "{date}",
        description: "Plan expiration date"
    }
});

const useStyles=createUseStyles({
    table:{
        '& td':{
            verticalAlign:"top",
            border:'none'
        },
        '& tr:first-of-type':{
            borderTop:`1px solid ${osColour.neutral.stone}`
        },
        '& tr:last-of-type':{
            borderTop:`1px solid ${osColour.neutral.stone}`
        }
    },
    errorMessage: {
        color:osColour.status.error
    }
});

export default function ConfirmationModal(props) {
    const {value=0, tier={}, product={}, onConfirm, onClose, working, error, date} = props;

    const classes = useStyles();

    const expireDate = new Date(date.getFullYear()+1, date.getMonth(), date.getDate());

    const actions = <CommonDialogActions
        onClose={onClose}
        onConfirm={onConfirm}
        confirmLabel={messages.purchaseButton}
        confirmAllowed={value > 0 && !error}
        working={working}
    />

    return <CommonDialog title={messages.title} onClose={onClose} actions={actions} data-testid="confirmation-modal">
        <Table className={classes.table}>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="primary">
                            <FormattedMessage {...messages.apiTransactionsLabel}/>
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="primary">
                            <FormattedMessage {...messages.apiTransactionsValue} values={{transactions:Math.floor(value/tier.unitPrice).toLocaleString()}}/>
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <FormattedMessage {...messages.tierLabel} values={{tierLabel: tier.name}}/>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2">
                            <FormattedMessage {...messages.tierValue} values={{unitPrice:tier.unitPrice}} />
                        </Typography>
                        <Typography variant="caption">
                            <FormattedMessage {...messages.product} values={{product:product.name}} />
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2">
                            <FormattedMessage {...messages.planExpireLabel}/>
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2">
                            <FormattedDate value={expireDate}/>
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                        <FormattedMessage {...messages.invoiceAmount} values={{value:value.toLocaleString()}} />
                    </TableCell>
                </TableRow>

            </TableBody>
        </Table>
        {error &&
            <Typography variant="body2" className={classes.errorMessage}>
                <FormattedMessage {...messages.errorMessage}/>
            </Typography>
        }
    </CommonDialog>
}

ConfirmationModal.defaultProps = {
    date: new Date()
}

ConfirmationModal.propTypes = {
    onClose:PropTypes.func.isRequired,
    onConfirm:PropTypes.func,
    amount: PropTypes.number,
    tier:PropTypes.object.isRequired,
    product:PropTypes.object.isRequired,
    working: PropTypes.bool,
    error: PropTypes.bool
}
