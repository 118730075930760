import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import CreateProjectButton from '../../components/CreateProjectButton';
import OverallUserUsage from "../../components/OverallUserUsage";
import StatsTimestamp from '../../components/StatsTimestamp';
import {border1, contentPadding} from 'omse-components';
import {hasManageProjectsPermission} from '../../util/permissions';
import {withOrganisationMessage} from '../../util/organisation';

const messages = defineMessages({
    apiTitle: {
        id: 'ApiUsageStats.title',
        defaultMessage: 'My projects',
        description: 'Title shown on the My projects page',
    },    
    orgTitle: {
        id: 'ApiUsageStats.orgTitle',
        defaultMessage: '{org} projects',
        description: 'Title when an org is selected',
    },
    personalTitle: {
        id: 'ApiUsageStats.personalTitle',
        defaultMessage: 'My Personal projects',
        description: 'Title when Personal org is selected',
    },
    transactions: {
        id: 'ApiUsageStats.transactions',
        defaultMessage: 'Total account usage this month across all projects ',
        description: 'Wording for showing total transactions',
    },
    transactionsHelp: {
        id: 'ApiUsageStats.transactionsHelp',
        defaultMessage: 'When you\'ve set up a project and added some APIs to it, the API usage will show here.',
        description: 'Helper text below the transaction text',
    },
    createProjectButton: {
        id: 'ApiUsageStats.createFirstProjectButton',
        defaultMessage: 'Create a new project',
        description: 'Text for the button to create a project'
    },
});

const styles = theme => ({
    heading: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingBottom: 20,

        // Similar to the overall max, but a bit bigger to give the buttons some room
        maxWidth: contentPadding.maxWidth + contentPadding.left * 3
    },
    apiStats: {
        paddingLeft: contentPadding.left,
        paddingRight: contentPadding.right,
        paddingTop: contentPadding.top,
        paddingBottom: contentPadding.bottom,
        flex: '1 0 auto',
        textAlign: 'left',
        borderBottom: border1,
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile,
            paddingBottom: 0,
            '& h1': {
                marginBottom: theme.spacing(2)
            }
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(3)
        }
    },
    content: {
        maxWidth: contentPadding.maxWidth
    },
    statsTimestamp: {
        marginBottom: theme.spacing(2)
    }
});


export class ApiUsageStats extends Component {
    render() {
        const { classes, projects, userStats, userDetails, org} = this.props;
        const title = withOrganisationMessage(messages.apiTitle, messages.orgTitle, messages.personalTitle, userDetails, org);
        return (
            <header className={classes.apiStats}>
                <div className={classes.heading} >
                    <Typography variant='h1' color={'primary'}>
                        <FormattedMessage {...title} />
                    </Typography>
                    {hasManageProjectsPermission(userDetails) && 
                        <CreateProjectButton label={messages.createProjectButton} componentId='ApiUsageStats' />
                    }
                </div>
                <div className={classes.content}>
                    <Typography variant='subtitle1' paragraph={true}>
                        <FormattedMessage {...messages.transactions} />
                    </Typography>
                    {
                        projects && projects.length === 0?
                            <Typography variant='body1' paragraph={true}>
                                <FormattedMessage {...messages.transactionsHelp} />
                            </Typography>
                            :
                            <div className={classes.statsTimestamp}>
                                <StatsTimestamp stats={userStats} />
                            </div>
                    }
                    <OverallUserUsage userStatsResult={userStats.result}/>
                </div>
            </header>

        );
    }
}

ApiUsageStats.propTypes = {
    classes: PropTypes.object.isRequired,
    projects: PropTypes.array,
    userStats: PropTypes.object.isRequired
};

export function mapStateToProps(state) {
    const org = state.organisation.current;
    return {
        userDetails: state.user.current.result,
        org 
    }
}

const styled = withStyles(styles)(ApiUsageStats);
export default connect(mapStateToProps)(styled);
