import React from 'react';
import Typography from '@mui/material/Typography';

export default function UsingOTA(props) {
    return <Typography variant='body1' paragraph={true} component='div'>
        <p>
            With OS NGD API – Tiles:
        </p>
        <p>You can:</p>
        <ul>
            <li>Use it as a basemap in GIS, web or mobile applications.</li>
            <li>View the whole of Great Britain in unrivalled detail.</li>
            <li>Seamlessly pan, zoom, pitch and tilt the map.</li>
            <li>Overlay your own data to give it geographic context.</li>
            <li>Trace over OS NGD (Premium Data) detailed geometries.</li>
            <li>Customise the map style and content to create the map you need.</li>
            <li>Access maps in different projections - British National Grid and Web Mercator.</li>
        </ul>
        <p>You can't:</p>
        <ul>
            <li>Retrieve all the detailed attribution from OS data.</li>
        </ul>
    </Typography>
}
