const {
    OPEN_DATA_ROLE, 
    OS_INTERNAL_ROLE, 
    ADMIN_ROLE, 
    FINANCE_ROLE, 
    USER_ROLE, 
    OS_SUPPORT_ROLE, 
    OS_SUPPORT_READ_ONLY_ROLE,
    CONTRACTOR_READ_ONLY_ROLE,
    CONTRACTOR_ROLE
} = require('./roles');

const TERMS_STANDALONE_ROLES = [
    OS_INTERNAL_ROLE,
    OPEN_DATA_ROLE
];

const TERMS_ORGANISATION_ROLES = [
    ADMIN_ROLE,
    FINANCE_ROLE,
    USER_ROLE,
    OS_SUPPORT_ROLE,
    OS_SUPPORT_READ_ONLY_ROLE
];

const ROLES_ALLOWED_TO_VIEW_ORGANISATION_TERMS_ONLY = [
    CONTRACTOR_ROLE,
    CONTRACTOR_READ_ONLY_ROLE
];

exports.usesOrganisationTerms = function(role) {
    return TERMS_ORGANISATION_ROLES.includes(role);
}

exports.usesIndividualTerms = function(role) {
    return TERMS_STANDALONE_ROLES.includes(role);
}

exports.viewsOrganisationTermsOnly = function(role) {
    return ROLES_ALLOWED_TO_VIEW_ORGANISATION_TERMS_ONLY.includes(role);
}

exports.termsAcceptState = {
    latest: 'latest',
    update: 'update',
    none: 'none'
};

exports.generalTermsKey = 'generalTerms';
exports.apiTermsKey = 'apiTerms';
exports.downloadTermsKey = 'downloadTerms';