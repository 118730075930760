// This module is used to put the react-router history object into the store. Having access to the history
// allows middleware to re-route the user to other pages.

const ACTION = 'action:history';

export function setHistory(history) {
    return {
        type: ACTION,
        history: history
    }
}

export function reducer(state = null, action) {
    if(action.type === ACTION) {
        return action.history;
    }
    return state;
}
