import React from 'react';
import {connect} from 'react-redux';
import Typography from '@mui/material/Typography';
import Header from "../Header";
import Content from '../Content';
import DocLinks from "../DocLinks";
import Document from '../Document';
import { ExternalLink, isWidthDown, withWidth } from 'omse-components';
import CodeSnippet from '../CodeSnippet';
import InlineCodeSnippet from '../../../components/InlineCodeSnippet';

function OAuthIntro(props) {
    const {width, url} = props;
    const mobile = isWidthDown('sm', width);

    return <Document>
        <Header back={true}>OAuth 2 API: Getting started guide</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains how to use the OAuth 2 API endpoint to generate an access token, and then how to use the token to access the OS Data Hub APIs.
                    To help illustrate the process we will work through the steps required in the command line.
                </p>
                <p>
                    This guide assumes basic knowledge of command line use, and that you have the 'curl' utility installed.
                </p>
            </Typography>
            <Typography variant='h1' component={'h2'}>Instructions to generate a Project API Key and Project API Secret</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>The following instructions describe how to set up a Project, add APIs, and obtain the API Key and Secret required for OAuth 2 authentication:</p>
                <ol>
                    <li>Click this link to open the <ExternalLink
                        type="generic"
                        href="/"
                        message="OS Data Hub"
                    /> in a new tab.
                    </li>
                    {mobile &&
                        <li>Click the navigation menu button.</li>
                    }
                    <li>Click "API Dashboard" in the {!mobile && 'navigation'} menu.</li>
                    <li>Click "APIs" in the {!mobile && 'secondary navigation'} menu.</li>
                    <li>Click "Add to API project" next to the listed API you wish to access. For this example we'll use OS Maps API (WMTS).</li>
                    <li>If you already have a project you may choose to add the OS Maps API (WMTS) into that
                        project, or alternatively Select "Add to NEW PROJECT".
                        <p>The next screen will contain the Project API Key and the Project API Secret, as well as the OS Maps API (WMTS) API Endpoint address.</p>
                        <p>You can return to this screen by clicking "My projects" at any point in the future if you
                            need to copy your API Key, Secret, or the API Endpoint address, or if you need to regenerate your API Key.</p>
                    </li>
                    <li>Keep the project page open as you'll need the API Key and API Secret when you use the OAuth 2 API to request an access token.</li>
                </ol>
            </Typography>
            <Typography variant='h1' component={'h2'}>Instructions to generate and use an OAuth 2 access token</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The following instructions demonstrate how to generate an access token using the OAuth 2 API, and then use that access token to access the OS Maps API.
                    This example uses the 'curl' utility.
                </p>
                <ol>
                    <li>Open a command line window. How to do this depends on your operating system.</li>
                    <li>Type or copy the following into the command line while substituting projectAPIKey and projectAPISecret with your Project’s API Key and Secret.
                        <CodeSnippet>
                            curl -u "projectAPIKey:projectAPISecret" -d "grant_type=client_credentials" "{url}/oauth2/token/v1"
                        </CodeSnippet>
                        <p>
                            Note that when we supply the API Key and Secret to curl, they are surrounded by double quotes (<InlineCodeSnippet>"</InlineCodeSnippet>), and separated by a colon (<InlineCodeSnippet>:</InlineCodeSnippet>).
                        </p>
                        <p>
                            In this example we are using the curl support for HTTP basic authentication to base-64 encode the Project API Key and Secret, and to set up the Authorization header for us.
                            When using other HTTP clients or libraries you may need to use different options, or you may need to setup and encode the Authorization header manually.
                        </p>
                    </li>
                    <li>Execute the command by pressing "Enter".</li>
                    <li>
                        The response should look similar to the following:
                        <CodeSnippet>
                            {'{'}"access_token":"{'{'}accessToken{'}'}","expires_in":"{'{'}expiryPeriod{'}'}","issued_at":"{'{'}issuedTimestamp{'}'}","token_type":"Bearer"{'}'}
                        </CodeSnippet>
                        <ul>
                            <li><InlineCodeSnippet>{'{'}accessToken{'}'}</InlineCodeSnippet> is the access token you will need to include within requests to the OS Data Hub APIs.</li>
                            <li><InlineCodeSnippet>{'{'}expiryPeriod{'}'}</InlineCodeSnippet> is the length in seconds until the access token expires.</li>
                            <li><InlineCodeSnippet>{'{'}issuedTimestamp{'}'}</InlineCodeSnippet> is the timestamp of when the access token was issued.</li>
                        </ul>

                        <p>
                            If an access token is not generated, check that you have copied over the API Key and Secret correctly, and that they are surrounded by double quotes, and separated by a colon.
                            You may also find it helpful to add the <InlineCodeSnippet>--verbose</InlineCodeSnippet> flag to the curl command while debugging the issue.
                        </p>
                    </li>
                    <li>
                        You can now use the access token to make API requests.
                        Note that the access token is time limited, and will expire soon.
                    </li>
                    <li>
                        Type or copy the following into the command line while substituting <InlineCodeSnippet>{'{accessToken}'}</InlineCodeSnippet> with the access token returned in the previous step.
                        <CodeSnippet>
                            curl -H "Authorization: Bearer {'{'}accessToken{'}'}" "{url}/maps/raster/v1/wmts?service=wmts&request=getcapabilities"
                        </CodeSnippet>
                        Note that the value of the Authorization header is the word Bearer, then a space, and then the access token.
                    </li>
                    <li>If successful, this will return the GetCapabilities document for OS Maps API (WMTS).</li>
                </ol>
            </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <b>Well done!</b> You've just completed the following actions:
                <ol>
                    <li>You've obtained a Project API Key and Project API Secret from the OS DataHub.</li>
                    <li>You've authenticated against the OAuth 2 API endpoint to get an access token.</li>
                    <li>You have used the access token to make a request to the OS Maps API.</li>
                </ol>
            </Typography>

            <DocLinks product='oauth2' hasTechnicalSpec={true}/>
            <Typography variant='body1' paragraph={true}>
                Take a look at our <ExternalLink type="generic" href="https://github.com/OrdnanceSurvey/OS-Data-Hub-API-Demos" message="Airports-OAuth example on GitHub" />.
                This example uses a lightweight server to demonstrate a complete web application using the OAuth 2 API.
            </Typography>
        </Content>
    </Document>
}

function mapStateToProps(state) {
    const {result} = state.config.current;

    if(result) {
        return {
            url: result.apigeeUrl
        };
    }
    return {};
}

const temp = withWidth()(OAuthIntro);
export default connect(mapStateToProps)(temp);
