import { ExternalLink } from "omse-components";
import { FormattedMessage, defineMessages } from 'react-intl';
import tcCodeExamples from "../img/tc-code-examples.png";
import tcApiDemos from "../img/tc-api-demos.png";
import tcApiTutorials from "../img/tc-api-tutorials.png";
import { Typography } from "@mui/material";
import Card from "../../../components/card/Card";
import CardContainer from "../CardContainer";
import { CodeExamplesCardMessages, ApiDemosCardMessages, ApiTutorialsCardMessages } from "../message/GetStarted";
import { tabHeadingStyles } from './styles';
import { singleColBreakpoint } from '../ContentContainer';

import styled from '@emotion/styled';

const SectionContainer = styled.section`
   ${tabHeadingStyles}
   margin-bottom: ${props => props.theme.spacing(4)};
   ${props => props.theme.breakpoints.down(singleColBreakpoint)} {
        margin-bottom: ${props => props.theme.spacing(2)};
    }
`;

const TabSectionMessages = defineMessages({
    heading: {
        id: 'TabSection.heading',
        defaultMessage: 'Jump right in and get interactive with our data',
        description: 'Heading'
    }
});

export default function GetStarted() {

    return (
        <SectionContainer>
            <Typography gutterBottom variant='h2' component='h3'>
                <FormattedMessage {...TabSectionMessages.heading} />
            </Typography>
            <CardContainer>

                <Card
                    imgSrc={tcCodeExamples}
                    imgAltMessage={CodeExamplesCardMessages.imageDescription}
                    title={CodeExamplesCardMessages.title}
                    titleVariant='h3'
                    titleComponent='h4'
                    description={CodeExamplesCardMessages.description}
                    link={(
                        <ExternalLink
                            href="https://labs.os.uk/public/os-data-hub-examples/"
                            message={CodeExamplesCardMessages.link}
                        />
                    )}
                />

                <Card
                    imgSrc={tcApiDemos}
                    imgAltMessage={ApiDemosCardMessages.imageDescription}
                    title={ApiDemosCardMessages.title}
                    titleVariant='h3'
                    titleComponent='h4'
                    description={ApiDemosCardMessages.description}
                    link={(
                        <ExternalLink
                            href="https://github.com/OrdnanceSurvey/OS-Data-Hub-API-Demos"
                            message={ApiDemosCardMessages.link}
                        />
                    )}
                />

                <Card
                    imgSrc={tcApiTutorials}
                    imgAltMessage={ApiTutorialsCardMessages.imageDescription}
                    title={ApiTutorialsCardMessages.title}
                    titleVariant='h3'
                    titleComponent='h4'
                    description={ApiTutorialsCardMessages.description}
                    link={(
                        <ExternalLink
                            href="https://github.com/OrdnanceSurvey/os-data-hub-tutorials"
                            message={ApiTutorialsCardMessages.link}
                        />
                    )}
                />

            </CardContainer>
        </SectionContainer>
    )
}
