import {useSelector} from "react-redux";
import useLocationState from "./useLocationState";
import {FROM_DATE, TO_DATE} from "./RecipeLibraryDatePicker";
import searchText from "../../../util/search/searchText";
import {fromISOString} from "../../../../shared/dateUtils";
import {sortBy} from "lodash";
import {SEARCH} from "./RecipeLibrarySearch";
import {SORT} from "./RecipeLibrarySort";
import {VIEW} from "./RecipeLibraryView";

export default function useRecipeList(featureTypeMap) {
    const [search] = useLocationState(SEARCH);
    const [selectedSort] = useLocationState(SORT);
    const [selectedView] = useLocationState(VIEW);
    const [fromDate] = useLocationState(FROM_DATE);
    const [toDate] = useLocationState(TO_DATE);
    const allRecipes = useSelector(state => state.recipes.library.result);
    const user = useSelector(state => state.user.current.result);

    let filteredRecipes = allRecipes;

    function recipeIsInUserOrg(recipe) {
        return user.orgs.find(org => recipe.dataHubOrgId.includes(org.value));
    }

    if(allRecipes) {
        // Take a copy of the recipe list, so that we don't accidentally alter the state in the redux store
        filteredRecipes = allRecipes.slice();

        // Text searching
        if(search) {
            filteredRecipes = filteredRecipes.filter(recipe => {
                const textToSearch = new Set();
                textToSearch.add(recipe.name);
                textToSearch.add(recipe.description);
                textToSearch.add(recipe.createdBy);

                // Add themes, collections and feature type labels to the search set.
                for(const {featureTypeId} of recipe.datasets) {
                    const entry = featureTypeMap[featureTypeId];
                    if(entry) {
                        const {theme, collection, featureType} = entry;
                        textToSearch.add(theme.label);
                        textToSearch.add(collection.label);
                        textToSearch.add(featureType.label);
                    }
                }

                return searchText(search, textToSearch);
            });
        }

        // Date filtering
        if (fromDate instanceof Date) {
            filteredRecipes = filteredRecipes.filter(r => fromISOString(r.created) >= fromDate);
        }
        if (toDate instanceof Date) {
            const nextDay = new Date(toDate.valueOf());
            nextDay.setDate(toDate.getDate() + 1);
            filteredRecipes = filteredRecipes.filter(r => fromISOString(r.created) < nextDay);
        }

        //Apply the selected view to the filtered list.
        if (selectedView === 'userRecipe') {
            const currentUser = user.firstName + " " + user.lastName;
            filteredRecipes = filteredRecipes.filter(recipe => currentUser === recipe.createdBy);
        } else if (selectedView === 'organisationRecipe') {
            filteredRecipes = filteredRecipes.filter(recipeIsInUserOrg);
        } else if (selectedView === 'organisationsSharedRecipes') {
            filteredRecipes = filteredRecipes
                .filter(recipeIsInUserOrg)
                .filter(recipe => recipe.sharedOrgs.length > 0);
        } else if (selectedView === 'sharedRecipes') {
            filteredRecipes = filteredRecipes.filter(recipes => recipes.sharedWithMe === true);
        }

        //Apply the selected sort to the filtered list.
        if(selectedSort === 'earliestCreationDate'){
            filteredRecipes.reverse();

        } else if(selectedSort === 'recipeName'){
            filteredRecipes = sortBy(filteredRecipes, ['name']);

        } else if(selectedSort === 'authorName'){
            filteredRecipes = sortBy(filteredRecipes, ['createdBy']);
        }
    }
    return filteredRecipes;
}