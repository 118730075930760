import React from 'react';
import Typography from "@mui/material/Typography";
import {SpanTable} from "./CalculateRowSpan";
import { ExternalLink } from "omse-components";
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

const featureLinks = {
    'bld': <ExternalLink
            type="generic"
            href='https://docs.os.uk/osngd/data-structure/buildings'
            message="Buildings"
        />,
    'gnm': <ExternalLink
            type="generic"
            href='https://docs.os.uk/osngd/data-structure/geographical-names'
            message="Geographical Names"
        />,
    'lnd': <ExternalLink
            type="generic"
            href='https://docs.os.uk/osngd/data-structure/land'
            message="Land"
        />,
    'lus': <ExternalLink
            type="generic"
            href='https://docs.os.uk/osngd/data-structure/land-use'
            message="Land Use"
        />,
    'str': <ExternalLink
            type="generic"
            href='https://docs.os.uk/osngd/data-structure/structures'
            message="Structures"
        />,
    'trn': <ExternalLink
            type="generic"
            href='https://docs.os.uk/osngd/data-structure/transport'
            message="Transport"
        />,
    'wtr': <ExternalLink
            type="generic"
            href='https://docs.os.uk/osngd/data-structure/water'
            message="Water"
        />,

}

function featureTypeString(features) {
    return features.join(", ")
}
const spanData = {
    'Buildings': {
        'primary': featureLinks.bld,
        'shared': [<InlineCodeSnippet>bld</InlineCodeSnippet>],
        'Building Features': [
            <InlineCodeSnippet>fts</InlineCodeSnippet>,
            featureTypeString(['Building', 'Building Line', 'Building Part']),
        ],
    },
    'Geographical Names': {
        'primary': featureLinks.gnm,
        'shared': [<InlineCodeSnippet>gnm</InlineCodeSnippet>],
        'Named Features': [
            <InlineCodeSnippet>fts</InlineCodeSnippet>,
            featureTypeString(['Named Area', 'Named Point', 'Named Road Junction']),
        ],
    },
    'Land': {
        'primary': featureLinks.lnd,
        'shared': [<InlineCodeSnippet>lnd</InlineCodeSnippet>],
        'Land Features': [
            <InlineCodeSnippet>fts</InlineCodeSnippet>,
            featureTypeString(['Land, Land Point', 'Landform', 'Landform Line', 'Landform Point']),
        ]
    },
    'Land Use': {
        'primary': featureLinks.lus,
        'shared': [<InlineCodeSnippet>lus</InlineCodeSnippet>],
        'Land Use Features': [
            <InlineCodeSnippet>fts</InlineCodeSnippet>,
            featureTypeString(['Site', 'Site Access Location', 'Site Routing Point']),
        ]
    },
    'Structures': {
        'primary': featureLinks.str,
        'shared': [<InlineCodeSnippet>str</InlineCodeSnippet>],
        'Structure Features': [
            <InlineCodeSnippet>fts</InlineCodeSnippet>,
            featureTypeString(['Compound Structure', 'Field Boundary', 'Structure', 'Structure Line', 'Structure Point']),
        ]
    },
    'Transport': {
        'primary': featureLinks.trn,
        'shared': [<InlineCodeSnippet>trn</InlineCodeSnippet>],
        'Transport Features': [
            <InlineCodeSnippet>fts</InlineCodeSnippet>,
            featureTypeString(['Cartographic Rail Detail',
            'Rail',
            'Road Line',
            'Road Track or Path',])
        ],
        'Transport Network': [
            <InlineCodeSnippet>ntwk</InlineCodeSnippet>,
            featureTypeString(['Connecting Link',
                'Connecting Node',
                'Ferry Link',
                'Ferry Node',
                'Ferry Terminal',
                'Path',
                'Path Link',
                'Path Node',
                'RailwayLink',
                'RailwayLinkSet',
                'RailwayNode',
                'PavementLink',
                'Road',
                'Road Junction',
                'Road Link',
                'Road Node',
                'Street',
                'Tram On Road']),
        ],
        'RAMI': [
            <InlineCodeSnippet>rami</InlineCodeSnippet>,
            featureTypeString([
                'Average and Indicative Speed',
                'Highway Dedication',
                'Maintenance Area',
                'Maintenance Line',
                'Maintenance Point',
                'Reinstatement Area',
                'Reinstatement Line',
                'Reinstatement Point',
                'Restriction',
                'Routing Hazard',
                'Routing Structure',
                'Special Designation Area',
                'Special Designation Line',
                'Special Designation Point',
            ])
        ]
    },
    'Water': {
        'primary': featureLinks.wtr,
        'shared': [<InlineCodeSnippet>wtr</InlineCodeSnippet>],
        'Water Features': [
            <InlineCodeSnippet>fts</InlineCodeSnippet>,
            featureTypeString([
                'Inter Tidal Line',
                'Tidal Boundary',
                'Water',
                'Water Point',
            ],
            )
        ],
        'Water Network': [
            <InlineCodeSnippet>ntwk</InlineCodeSnippet>,
            featureTypeString([
                'Water Link',
                'Water Link Set',
                'Water Node',
            ])
        ]
    }

}

const tableHeaders = [
    'Theme',
    'Theme Short Code',
    'NGD Collection',
    'NGD Collection Short Code',
    'NGD Feature Types'
]

export default function OFAFeatureTypes() {
    return <>
        <Typography variant='h1' component={'h2'}>Content</Typography>
        <Typography variant='body1' paragraph={true} component='div'>
            <p>
                The following table documents the OS NGD datasets available through the OS NGD API – Features showing
                the themes, collections and lists the available feature types.
            </p>
            <p>
                OS NGD themes and collections have been created to group similar geographic entities and data types,
                making it quicker and easier to identify the data you need. The OGC API - Features standard also
                references feature collections, and in the context of OS NGD datasets, this is equivalent to feature
                types.
            </p>
            <p>
                The following naming convention has been applied to the feature collections:
                theme-collection-featuretype-version. Short codes have been used for both the theme and collection to keep the
                feature collection names manageable and not overly long. An example of a short code used is <InlineCodeSnippet>
                bld-fts-buildingline-1</InlineCodeSnippet>.
            </p>
            <p>
                Click on each theme to find out more information about the dataset.
            </p>
            <SpanTable headers={tableHeaders} spanData={spanData} />
            <p>
                Please note the OS NGD Address Theme and OS NGD Administrative and Statistical Units Theme are only
                available via OS Select+Build and are not available via the OS NGD API – Features.
            </p>
        </Typography>
    </>
}
