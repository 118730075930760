import React from 'react';
import Typography from '@mui/material/Typography';
import Header from './Header';
import Content from './Content';
import DocLinks from './DocLinks';
import { ExternalLink } from 'omse-components';
import figure1 from './linkedIdentifiersIntro/Figure1.png';
import figure2 from './linkedIdentifiersIntro/Figure2.png';
import figure3 from './linkedIdentifiersIntro/Figure3.png';
import figure4 from './linkedIdentifiersIntro/Figure4.png';
import figure5 from './linkedIdentifiersIntro/Figure5.png';
import figure6 from './linkedIdentifiersIntro/Figure6.png';
import figure7 from './linkedIdentifiersIntro/Figure7.png';
import figure8 from './linkedIdentifiersIntro/Figure8.png';
import figure9 from './linkedIdentifiersIntro/Figure9.png';
import figure10 from './linkedIdentifiersIntro/Figure10.png';
import figure11 from './linkedIdentifiersIntro/Figure11.png';
import Document from './Document';
import { isWidthDown, withWidth } from 'omse-components';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

export function LinkedIdentifiersIntro(props) {
    const {width} = props;
    const mobile = isWidthDown('sm', width);
    return <Document>
        <Header back={true}>OS Linked Identifiers API: Getting started guide</Header>
        <Content>

            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains how to generate an API key to access the OS Linked Identifiers API, and then how to use
                    that API key with a Web Development IDE to create a web-based application.
                </p>
                <p>
                    It assumes basic knowledge of web development and will require access to an IDE (Integrated
                    Development Environment).
                </p>
            </Typography>
            <Typography variant='h1' component={'h2'}>Instructions to Generate a Project API Key and URL</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>The following instructions describe how to obtain the API service URL and generate an API key:</p>
                <ol>
                    <li>Click this link to open the <ExternalLink
                        type="generic"
                        href="/"
                        message="OS Data Hub"
                    /> in a new tab.
                    </li>
                    {mobile &&
                    <li>Click the navigation menu button.</li>
                    }
                    <li>Click "API Dashboard" in the {!mobile && 'navigation'} menu.</li>
                    <li>Click "APIs" in the {!mobile && 'secondary navigation'} menu.</li>
                    <li>Click "Add to API project" {!mobile && 'to the right of'} {mobile && 'under'} OS Linked Identifiers API.</li>
                    <li>If you already have a project you may choose to add the OS Linked Identifiers API into that
                        project, or alternatively Select "Add to NEW PROJECT".
                        <p>The next screen will contain the Project API Key and the API Endpoint address (API URL).</p>
                        <p>You can return to this screen by clicking "My projects" at any point in the future if you
                            need to copy your API key or the API address, or if you need to regenerate your API Key.</p>
                    </li>
                    <li>Keep this page open as you'll need the key when you apply the OS Linked Identifiers API service
                        in your web-based application.
                    </li>
                </ol>
                <p>
                    For demonstrative purposes, we will apply the service through an IDE to create a web-based
                    application.
                </p>
            </Typography>
            <Typography variant='h1' component={'h2'}>Instructions to create a web-based application</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    To create a web-based application, you will need access to an IDE and to Ordnance Survey’s GitHub Demo repository. You
                    will not need a GitHub account in order to access the demos.
                </p>
            </Typography>

            <Typography variant='h2'>Download and install the OSLinkedIdentifiersAPI repository</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <ol>
                    <li>Navigate to the <ExternalLink
                        type="generic"
                        href="https://github.com/OrdnanceSurvey/OS-Data-Hub-API-Demos"
                        message="API demos repository"
                    /> on GitHub.</li>
                    <li>
                        <p>Click the green <i>Code</i> button.</p>
                        <img src={figure1} alt='GitHub repository clone/download button'/>
                    </li>
                    <li>
                        <p>
                            Click the option to <i>Download ZIP</i>.
                        </p>
                        <img src={figure2} alt='GitHub Download ZIP button'/>
                    </li>
                    <li>
                        <p>Navigate to your Downloads folder and unzip <InlineCodeSnippet>OS-Data-Hub-API-Demos-master.zip</InlineCodeSnippet>. Put the newly
                            unzipped folder in your IDE project folder. When you open your IDE, the new files should appear
                            automatically in your project window. In your IDE navigate to the <InlineCodeSnippet>OSLinkedIdentifiersAPI</InlineCodeSnippet> folder.</p>
                        <div style={{justifyContent: 'space-evenly', display: 'flex'}}>
                            <img src={figure3} alt='Project window'/>
                        </div>
                    </li>
                    <li>
                        <p>The folders you have installed contains all the coding required to call and interact with the
                            code libraries. To launch the web-based application navigate to and open the <InlineCodeSnippet>index.html</InlineCodeSnippet> file
                            found in the <InlineCodeSnippet>OSLinkedIdentifiersAPI</InlineCodeSnippet> folder. (Note - This launch method may differ between IDEs.)
                            Once you've done this, run the application.</p>
                        <img src={figure4} alt='index.html in OSLinkedIdentifiersAPI folder'/>
                    </li>
                </ol>

                <Typography variant='h2'>Using the web-based demo</Typography>
                <ol>
                    <li>
                        <p>Running the project will launch a window in your browser that looks like this:</p>
                        <img src={figure5} alt='demo project window'/>
                        <p>The demo application is now running on a temporary local server from your own computer. This
                            server is private to your IP address so it cannot be shared with other users. This server
                            will exist as long as your IDE is open. You will have to run the <InlineCodeSnippet>index.html</InlineCodeSnippet> file
                            through your IDE each time you wish to use the demo.</p>
                    </li>
                    <li>
                        <p>To use the demo, open up your <i>My projects</i> page on the OS Data Hub and copy the <i>Project
                            API Key</i>.</p>
                        <img src={figure6} alt='Copy API key'/>
                    </li>
                    <li>
                        <p>Paste the key into the <i>Project API</i> Key box on the local server demo.</p>
                        <img src={figure7} alt='Paste API key'/>

                        <p>There are four queries to run within the Linked Identifiers API demo application.</p>
                    </li>
                    <li>
                        <p>To run the first query, you will need to enter a linked identifier
                            ID into the input box and click <i>Run query</i>.</p>
                        <img src={figure8} alt='Paste ID and run query'/>
                    </li>
                    <li>
                        <p>To run the second query, you will need to select a feature type, enter a linked identifier
                            ID into the input box and click <i>Run query</i>.</p>
                        <img src={figure9} alt='Select feature type, paste ID and run query'/>
                    </li>
                    <li>
                        <p>To run the third query, you will need to select an identifier type, enter a linked identifier
                            ID into the input box and click <i>Run query</i>.</p>
                        <img src={figure10} alt='Select identifier type, paste ID and run query'/>
                    </li>
                    <li>
                        <p>To run the fourth query, you will need to select a correlation method type and click <i>Run query</i>.</p>
                        <img src={figure11} alt='Select correlation method run query'/>
                    </li>
                </ol>
            </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <b>Well done!</b> You've just completed the following actions:
                <ol>
                    <li>You've obtained the URL and generated an API key for OS Linked Identifiers API.</li>
                    <li>You've accessed and downloaded the OS Linked Identifiers API demo from our GitHub repository.</li>
                    <li>You've created a localised demo using your IDE and have applied your API key in order to
                        interact with the data.
                    </li>
                </ol>
            </Typography>
            <DocLinks product='linkedIdentifiers' hasTechnicalSpec={true} hasDemo={true}/>
        </Content>
    </Document>
}

export default withWidth()(LinkedIdentifiersIntro);