const PREFIX = 'action:serviceStatus:';
export const LOAD_SERVICE_STATUS_APIS_ACTION   = PREFIX + 'load';
export const LOADED_SERVICE_STATUS_APIS_ACTION = PREFIX + 'loaded';

const PREFIX_API = 'action:serviceStatus:api:';
export const LOAD_SERVICE_STATUS_API_ACTION   = PREFIX_API + 'load';
export const LOADED_SERVICE_STATUS_API_ACTION = PREFIX_API + 'loaded';

export const CLEAR_SERVICE_STATUS_CACHE_ACTION   = PREFIX + 'cache:clear';

export function getApisServiceStatus() {
    return {
        type: LOAD_SERVICE_STATUS_APIS_ACTION
    };
}

export function getApiServiceStatus(apiId) {
    return {
        type: LOAD_SERVICE_STATUS_API_ACTION,
        apiId
    };
}

export function clearServiceStatusCache() {
    return {
        type: CLEAR_SERVICE_STATUS_CACHE_ACTION
    };
}