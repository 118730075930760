import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import { getPremiumProductOptions } from "../../../modules/premium/actions";
import {resetDraftOrder, updateDraftOrder} from "../../../modules/dataPackages/actions";
import routes, {useRedirect} from "../../../util/routes";
import FeatureCheck from "../../../components/FeatureCheck";
import {hasEditPremiumDownloadsPermission} from "../../../util/permissions";
import DataPackageMap from "../premiumItem/DataPackageMap";
import NoChangeDialog from "../premiumItem/addDataPackage/NoChangeDialog";
import AddDataPackage from "../premiumItem/AddDataPackage";
import ExpandPanel from "./ExpandPanel";
import ExpandSuccessPanel from "./ExpandSuccessPanel";
import {defineMessages} from 'react-intl';
import features from "../../../../shared/features";

const messages = defineMessages({
    errorMessage: {
        id: 'ExpandDataPackage.errorMessage',
        defaultMessage: 'There was a problem expanding your data package area. Please retry and if the problem persists then {link}.',
        description: 'error message text'
    },
    submitLabel: {
        id: 'ExpandDataPackage.submitLabel',
        defaultMessage: 'Expand area',
        description: 'submit button label'
    }
});

export default function ExpandPremiumDataPackage() {
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const location = useLocation();

    // We expect the data package page to put the data package into location state, but we can't completely rely
    // on that because someone could navigate to the expansion by altering the URL... and while that is rare we
    // may as well handle it. If the correct data package is not available then we'll end up navigating back to the
    // data package page, and the user can start over from there.
    // We could also look for the data package in redux state (state.dataPackages.current.result), but that location
    // is cleared before this page loads, and may be overwritten (if we use the polygon library to load a polygon from
    // another data package) so it's simpler to just pass the correct data package into the location state.
    let dataPackage = location.state && location.state.dataPackage;

    const userDetails = useSelector(state => state.user.current.result);
    const optionsResult = useSelector(state => state.premium.options.result);
    const draftOrder = useSelector((state) => state.dataPackages.draftOrder);

    let productId;
    if(dataPackage) {
        productId = dataPackage.productId;
    }

    const aoiEmpty = draftOrder.area === 'Polygon' && (!draftOrder.AOI);
    const tilesEmpty = draftOrder.area === 'Polygon' && (!draftOrder.tiles || draftOrder.tiles.length === 0);
    const tiled = draftOrder.tiled;

    useEffect(() => {
        if(!hasEditPremiumDownloadsPermission(userDetails)) {
            redirect.replace(routes.landing);
        }
    }, [userDetails, redirect]);

    useEffect(() => {
        if (dataPackage && dataPackage.fixedPriceAreas) {
            redirect.replace(routes.error404Premium);
        }
    }, [dataPackage, redirect]);

    useEffect(() => {
        if (productId) {
            dispatch(getPremiumProductOptions(productId));
        }
    }, [productId, dispatch]);

    useEffect(() => {  // if we have a data package set the values in the store so we can display them
        if (dataPackage) {
            dispatch(resetDraftOrder(true));
            dispatch(updateDraftOrder('productId', dataPackage.productId));
            dispatch(updateDraftOrder('id', dataPackage.id));
            dispatch(updateDraftOrder('updates', dataPackage.updateScheduleId));
            dispatch(updateDraftOrder('packageName', dataPackage.name));
            dispatch(updateDraftOrder('format', dataPackage.dataFormatId));
            dispatch(updateDraftOrder('area', 'Polygon'));
            dispatch(updateDraftOrder('changeCode', 'EXPAND_REQUESTED'));
            dispatch(updateDraftOrder('renewalPeriod', dataPackage.termId))
            dispatch(updateDraftOrder('users', dataPackage.users));
            dispatch(updateDraftOrder('terminals', dataPackage.terminals));
            dispatch(updateDraftOrder('catalogueId', dataPackage.catalogueId));

        }
    }, [dataPackage, dispatch]);

    const showNoChangeDialog = draftOrder.displayErrors &&
        (aoiEmpty || (tiled && tilesEmpty));

    function closeNoChangeDialog() {
        dispatch(updateDraftOrder('displayErrors', false));
    }

    let product = null;
    if (optionsResult && optionsResult.products && optionsResult.products.itemCount === 1) {
        if (optionsResult.products.items[0].id === productId) {
            product = optionsResult.products.items[0]
        }
    }

    let panel, successPanel;
    if(dataPackage && product) {
        const dpTiles = dataPackage.tiles || [];
        const draftTiles = draftOrder.tiles || [];

        panel = <ExpandPanel dataPackage={dataPackage} isExpanding={draftTiles.some(draftTile => !dpTiles.includes(draftTile))} product={product} optionsResult={optionsResult}/>;
        successPanel = <ExpandSuccessPanel dataPackage={dataPackage} label={product.label}/>;
    }

    let dialog;
    if(showNoChangeDialog) {
        dialog = <NoChangeDialog onClose={closeNoChangeDialog} tiled={tiled}/>;
    }

    return <FeatureCheck feature={features.PREMIUM}>
        <AddDataPackage panel={panel}
                        successPanel={successPanel}
                        dataPackageMap={<DataPackageMap productId={productId} dataPackage={dataPackage}/>}
                        dialog={dialog}
                        submitLabel={messages.submitLabel}
                        errorMessage={messages.errorMessage}/>
    </FeatureCheck>;
}
