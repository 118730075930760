import React from 'react';
import Header from "./Header";
import Content from './Content';
import figure1 from "./wmtsOverview/Figure1.png";
import Typography from '@mui/material/Typography';
import UsingWMTS from "./UsingWMTS";
import DocLinks from "./DocLinks";
import Document from './Document';

export default function WMTSOverview(props) {
    return <Document>
        <Header back={true}>OS Maps API: Overview</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Get started for free and integrate Ordnance Survey’s up-to-date, detailed maps in your apps, enabling your customers to make location-based decisions with confidence,
                    removing the overhead of managing complex datasets yourself.
                </p>
                <p>
                    Choose the style that suites your use case best, whether you need the detail of OS MasterMap or our iconic Leisure maps.
                    There are four styles available: Road, Outdoor, Light and Leisure. Each map style contains OS OpenData and Premium data layers.
                </p>
                <p>
                    OS Maps API serves pre-rendered raster tiles and is available in two projections; British National Grid for GB data and Web Mercator which is a global coordinate system.
                    You can request the maps using the Open Geospatial Consortium Web Map Tile Service (WMTS) standard or RESTful ZXY.
                    WMTS is supported by most GIS software and ZXY is efficient for web mapping.
                </p>
            </Typography>
            <img src={figure1} alt='OS Maps API: large-scale map showing a residential road with buildings and nearby greenspace'/>
            <UsingWMTS/>
            <DocLinks product='wmts' hasGettingStarted={true} hasTechnicalSpec={true} hasDemo={true}/>
        </Content>
    </Document>
}
