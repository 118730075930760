import React, {Fragment, useState} from 'react';
import StickyDataGridTable from '../../../components/StickyDataGridTable';
import {Button, Checkbox, CircularProgress, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {defineMessages, FormattedDate, FormattedMessage} from 'react-intl';
import { linkButton, Notification, osColour, ExternalLink } from "omse-components";
import {ReactComponent as TickIcon} from '../../../components/icons/tick.svg';
import pluralize from 'pluralize';
import LicenceDialog from "./dialog/LicenceDialog";
import ProductDialog from "./dialog/ProductDialog";
import {createPartnerContracts} from "../../../modules/partner/actions";
import useActionIdSelector from "../../../hooks/useActionIdSelector";
import useNewLicences from "./useNewLicences";
import { useSelector } from 'react-redux';

const LOCKED = 'locked';
const UNAVAILABLE = 'unavailable';
const ACTIVE = 'active';
const INACTIVE = 'inactive';
const CHECKED = 'checked';
const NOT_REQUESTED = 'not requested';

const messages = defineMessages({
    unlock: {
        id: 'ProductContractTable.unlock',
        defaultMessage: 'Unlock',
        description: 'unlock message'
    },
    until: {
        id: 'ProductContractTable.until',
        defaultMessage: '{term}{years} until {endDate}',
        description: 'until message'
    },
    unavailable: {
        id: 'ProductContractTable.unavailable',
        defaultMessage: 'unavailable',
        description: 'unavailable message'
    },
    notRequested: {
        id: 'ProductContractTable.notRequested',
        defaultMessage: 'not requested',
        description: 'notRequested message'
    },
    cancel: {
        id: 'ProductContractTable.cancel',
        defaultMessage: 'Cancel',
        description: 'Label for the cancel button'
    },
    confirm: {
        id: 'ProductContractTable.confirm',
        defaultMessage: 'Confirm licensing choices',
        description: 'Label for the confirm button'
    },
    error: {
        id: 'ProductContractTable.error',
        defaultMessage: 'There was a problem requesting your new licences. Please retry and if the problem persists then {link}.',
        description: 'Label for the error notification'
    },
    adamsLaw: {
        id: 'ProductContractTable.adamsLaw',
        defaultMessage: 'You are only licensed to use (including benefit from or rely on) Licensed Data if there is a Royalty for the use of such Licensed Data in the royalty appendix of the relevant Partner Contract.',
        description: 'Label shown containing information surrounding legal expectations'
    }
});

const myStyles = createUseStyles(theme => ({
    gridContainer: {
        marginBottom: theme.spacing(3)
    },
    bold: {
        fontWeight: 'bold'
    },
    green: {
        color: osColour.status.success
    },
    amber: {
        color: osColour.status.warning
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    cancelButton: {
        marginRight: theme.spacing(2)
    },
    buttonWorking: {
        color: theme.palette.action.disabled,
        marginRight: theme.spacing(1)
    },
    adamsLaw : {
        paddingBottom: theme.spacing(1)
    }

}));

const useLinkStyles = createUseStyles({linkButton});

export default function ProductContractTable(props) {
    const {filteredProducts} = props;
    const myClasses = myStyles();
    const classesLink = useLinkStyles();
    const [showLicenceDialog, setShowLicenceDialog] = useState(null);
    const [showProductDialog, setShowProductDialog] = useState(null);
    const [{working, error}, dispatch] = useActionIdSelector("partners.createContracts");
    const {newLicences, addPartnerContract, togglePartnerContract, reset} = useNewLicences();
    const [showNotification, setShowNotification] = useState(true);

    const contracts = useSelector(state => state.partners.contracts.result);
    const catalogue = useSelector(state => state.partners.catalogue.result);

    // Search through both the existing and new contracts for an active BMI code
    function findActiveBMI(contractId, bmiCode) {
        function find(contract) {
            if(contract && contract.bmis) {
                const bmi = contract.bmis[bmiCode];
                return bmi && bmi.active;
            }
            return false;
        }
        return find(contracts[contractId]) || find(newLicences[contractId]);
    }

    // We do not need to accept the product terms twice, so if we have an existing contract for the product bmi
    // then we are ok. We are also ok if we have just set up another licence for this bmi code, but not saved it yet.
    function needsProductTerms(contract, product) {
        let needsProductTerms = product && product.productTerms;
        if(needsProductTerms) {
            const bmiCode = product.bmiCode;
            // Any contract with the BMI active is enough, so we check them all
            const found = catalogue.contracts.find(c => findActiveBMI(c.id, bmiCode));
            needsProductTerms = !found;
        }
        return needsProductTerms;
    }

    // When adding a product that has a bmiDependency to a contract we may need to also add in the dependency
    function needsProductDependencies(contract, product) {
        let bmiDependency = product && product.bmiCodeDependency;
        if(bmiDependency) {
            // The BMI must be active for the current contract, so we only check that one
            const found = findActiveBMI(contract.id, bmiDependency);
            if(found) {
                bmiDependency = null;
            }
        }
        if(bmiDependency) {
            return catalogue.products.filter(p => p.bmiCode === bmiDependency);
        }
        return [];
    }

    function setupNewLicenceDialog(contract, product) {
        const props = {
            contract,
            product,
            firstContract: (Object.keys(contracts).length === 0) && (Object.keys(newLicences).length === 0),
            needsProductTerms: needsProductTerms(contract, product),
            productDependencies: needsProductDependencies(contract, product),
            onConfirm: function accept(term) {
                setShowLicenceDialog(null);
                addPartnerContract(contract, product, term);
            },
            onClose: function close() {
                setShowLicenceDialog(null);
            }
        };
        setShowLicenceDialog(props);
    }
    function setupNewProductDialog(contract, product, existingContract, needsProductTerms, productDependencies) {
        const props = {
            contract,
            product,
            needsProductTerms,
            productDependencies,
            onConfirm: function accept() {
                setShowProductDialog(null);
                togglePartnerContract(contract, product, existingContract.term);
            },
            onClose: function close() {
                setShowProductDialog(null);
            }
        };
        setShowProductDialog(props);
    }

    function selectHeading(contract) {
        // The heading is only clickable if we do not already have this partner contract set up. We need to accept
        // the licence before we can continue, so we pop a dialog to take care of that
        setupNewLicenceDialog(contract);
    }
    function selectProduct(contract, product) {
        // The cell is clickable if this product is not active. We might already have the contract in place, but if
        // we do not then we need to set the contract up before we can make any changes
        const existingContract = contracts[contract.id] || newLicences[contract.id];
        const terms = needsProductTerms(contract, product);
        const productDependencies = needsProductDependencies(contract, product);
        if(!existingContract) {
            setupNewLicenceDialog(contract, product);
        } else if(terms || productDependencies.length) {
            setupNewProductDialog(contract, product, existingContract, terms, productDependencies);
        } else {
            togglePartnerContract(contract, product, existingContract.term);
        }
    }

    function renderContract(contract) {
        return product => {
            const value = getValue(contract.id, product);
            if (value === LOCKED) {
                return <Button className={classesLink.linkButton}
                               onClick={() => selectProduct(contract, product)}
                               variant='text'
                               disableRipple>
                    <Typography variant='body1'>
                        <FormattedMessage {...messages.unlock} />
                    </Typography>
                </Button>
            } else if (value === ACTIVE) {
                return <TickIcon width={24} height={24} className={myClasses.green}/>
            } else if (value === INACTIVE) {
                return <Checkbox checked={false}
                                 onClick={() => selectProduct(contract, product)}
                                 inputProps={{ 'aria-label': `Select ${product.label} under ${contract.label}`}}
                                 color='secondary'
                />
            } else if (value === CHECKED) {
                return <Checkbox checked={true}
                                 onClick={() => selectProduct(contract, product)}
                                 inputProps={{ 'aria-label': `Deselect ${product.label} under ${contract.label}` }}
                                 color='secondary'
                />
            } else if(value === NOT_REQUESTED) {
                return <Typography variant='body1'>
                    <FormattedMessage {...messages.notRequested} />
                </Typography>;
            }

            return <Typography variant='body1'>
                <FormattedMessage {...messages.unavailable} />
            </Typography>;
        }
    }

    function renderHeader(contract) {
        return () => {
            let className = myClasses.green;
            let currentContract = contracts[contract.id];
            if(!currentContract) {
                currentContract = newLicences[contract.id];
                className = myClasses.amber;
            }

            if (currentContract) {
                const formattedEndDate = <FormattedDate value={currentContract.endDate}
                                                     day='numeric'
                                                     month='short'
                                                     year='numeric'/>
                return <Fragment>
                    <Typography className={myClasses.bold} variant='body1'>{contract.label}</Typography>
                    <Typography variant='body1' className={className}>
                        <FormattedMessage {...messages.until} values={{
                            years: pluralize('yr', currentContract.term),
                            term: currentContract.term,
                            endDate: formattedEndDate}}/>
                    </Typography>
                </Fragment>;
            } else {
                return <Fragment>
                    <Typography className={myClasses.bold} variant='body1'>{contract.label}</Typography>
                    <Button className={classesLink.linkButton}
                            onClick={() => selectHeading(contract)}
                            variant='text'
                            disableRipple>
                        <Typography variant='body1'>
                            <FormattedMessage {...messages.unlock} />
                        </Typography>
                    </Button>
                </Fragment>;
            }
        }
    }

    function generateColumns() {
        let columns = [{
                id: 'label',
                field: 'label',
                label: 'Product name/Licence',
                minWidth: 200
            }
        ];

        catalogue.contracts.forEach(c => {
            columns.push({
                id: c.id,
                field: c.id,
                label: c.label,
                minWidth: 150,
                renderCell: renderContract(c),
                renderHeader: renderHeader(c),
                align: 'center',
            });
        });

        return columns;
    }

    function getValue(cid, product) {
        let pid = product.bmiCode;

        if (!product.partnerUsageContracts.includes(cid)) {
            return UNAVAILABLE;
        }

        const newLicence = newLicences[cid];
        if(newLicence && newLicence.bmis[pid]) {
            return newLicence.bmis[pid].active ? CHECKED : INACTIVE;
        }

        if (contracts[cid]) {
            if (contracts[cid].bmis && contracts[cid].bmis[pid]) {
                return contracts[cid].bmis[pid].active ? ACTIVE : INACTIVE;
            } else {
                return product.thirdParty ? NOT_REQUESTED : INACTIVE;
            }
        }

        return product.thirdParty ? NOT_REQUESTED : LOCKED;
    }

    function generateRows() {
        let rows = filteredProducts.map(p => {
            let row = {...p};
            catalogue.contracts.forEach(c => {
                row[c.id] = p;
            });
            return row;
        });

        return rows;
    }

    const currentError = error && showNotification;
    const hasNewLicences = Object.keys(newLicences).length > 0;

    return <Fragment>
        <div className={myClasses.gridContainer}>
            <StickyDataGridTable
                rows={generateRows()}
                columns={generateColumns()}
            />
        </div>
        { hasNewLicences && <>
            <Typography className={myClasses.adamsLaw} variant='body1'>
                <FormattedMessage {...messages.adamsLaw}/>
            </Typography>
            <div className={myClasses.buttons}>
                <Button variant='outlined'
                        onClick={() => {
                            setShowNotification(false);
                            reset();
                        }}
                        className={myClasses.cancelButton}
                        disabled={working}
                >
                    <FormattedMessage {...messages.cancel}/>
                </Button>
                <Button color='primary'
                        variant='contained'
                        disabled={working || currentError}
                        onClick={() => {
                            // Make sure that we show an error if the creation fails
                            setShowNotification(true);
                            dispatch(createPartnerContracts(newLicences));
                        }}
                >
                    { working && <CircularProgress size={24} className={myClasses.buttonWorking}/> }
                    <FormattedMessage {...messages.confirm}/>
                </Button>
            </div>
        </>}
        {
            showLicenceDialog && <LicenceDialog {...showLicenceDialog}/>
        }
        {
            showProductDialog && <ProductDialog {...showProductDialog}/>
        }
        {
            currentError && <Notification variant='error'
                                          appearance='toast'
                                          onClose={() => setShowNotification(false)}
            >
                <Typography variant='body1'>
                    <FormattedMessage {...messages.error} values={{ link: <ExternalLink type='support' /> }} />
                </Typography>
            </Notification>
        }

    </Fragment>;
}
