import React from 'react';
import { Typography } from '@mui/material';
import { defineMessages, FormattedMessage } from 'react-intl';
import Link from '../../components/Link';
import routes, {logInLink} from "../../util/routes";
import useHeading from "../../hooks/useHeading";
import {ReactComponent as Padlock} from './padlock_closed.svg';
import { StyledErrorPage } from "../../util/ErrorPageStyles";

const messages = defineMessages({
    title: {
        id: 'NotLoggedIn.title',
        defaultMessage: 'You are not logged in',
        description: 'Text for the title'
    },
    intro: {
        id: 'NotLoggedIn.intro',
        defaultMessage: "You're not logged in at the moment.",
        description: 'Text for the title'
    },
    logIn: {
        id: 'NotLoggedIn.logIn',
        defaultMessage: 'Please {logIn} or {signUp} to get started. For an overview of available products you can browse the {documentation}.',
        description: 'Text for the body'
    },
    logInLink: {
        id: 'NotLoggedIn.logInLink',
        defaultMessage: 'log in',
        description: 'Link text for log in link'
    },
    signUpLink: {
        id: 'NotLoggedIn.signUpLink',
        defaultMessage: 'sign up',
        description: 'Link text for sign up link'
    },
    documentationLink: {
        id: 'NotLoggedIn.documentationLink',
        defaultMessage: 'documentation',
        description: 'Link text for documentation link'
    }
});

export default function NotLoggedIn() {
    const logIn = <a href={logInLink}><FormattedMessage {...messages.logInLink} /></a>;
    const signUp = <Link path={routes.plans}><FormattedMessage {...messages.signUpLink} /></Link>
    const documentation = <Link path={routes.documentation}><FormattedMessage {...messages.documentationLink} /></Link>
    const title = useHeading() || messages.title;

    return <StyledErrorPage>
        <Typography variant='h1' color='primary' className={"content header"}>
            <FormattedMessage {...title} />
        </Typography>
        <div className={"content body"}>
            <Padlock/>
            <Typography variant='h2'>
                <FormattedMessage {...messages.intro}/>
            </Typography>
            <Typography variant='body1' className={'"bodyText'}>
                <FormattedMessage {...messages.logIn}
                                  values={{
                                      logIn,
                                      signUp,
                                      documentation
                                  }} />
            </Typography>
        </div>
    </StyledErrorPage>
}