import React from 'react';
import {createUseStyles} from 'react-jss';
import {Typography} from '@mui/material';
import { contentPadding, Notification, ExternalLink } from 'omse-components';
import {defineMessages, FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import routePaths, { getLocation } from "../util/routes";

const styles = createUseStyles(theme => ({
    content: {
        marginLeft: contentPadding.left,
        marginRight: contentPadding.right,
        maxWidth: contentPadding.maxWidth,
        marginBottom: contentPadding.bottom,
        marginTop: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            margin: contentPadding.mobile
        }
    }
}));

const messages = defineMessages({
    intro: {
        id: 'LogInError.intro',
        defaultMessage: 'There was a problem logging you in to the OS Data Hub.',
        description: 'Intro to the LoginFailure page'
    },
    content: {
        id: 'LogInError.content',
        defaultMessage: 'Please try to log in again, and if the problem persists then {link}.',
        description: 'Intro to the LoginFailure page'
    }
});

export default function LogInError() {
    const classes = styles();
    const user = useSelector(state => state.user.current.result);
    const history = useHistory();

    if(user) {
        // We must be logged in, as we have a valid user. Redirect to the dashboard.
        const newLocation = getLocation(routePaths.dashboard, history.location);
        history.replace(newLocation);
    }

    return <div className={classes.content}>
        <Notification variant='error' appearance='inline'>
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.intro}/>
            </Typography>
            <Typography variant='body1'>
                <FormattedMessage {...messages.content} values={{ link: <ExternalLink type='support' /> }} />
            </Typography>
        </Notification>
    </div>;
}
