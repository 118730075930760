import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DownloadImage from "../DownloadImage";
import Typography from '@mui/material/Typography';
import {osColour} from 'omse-components';
import withStyles from 'react-jss';
import {compose} from 'redux';
import PremiumDesc from "./PremiumDesc";
import Link from "../../../../components/Link";
import CatalogueTag from "../../../../components/CatalogueTag";
import { isWidthDown, withWidth } from 'omse-components';

const styles = theme => ({
    downloadContainer: {
        display: 'flex',
        marginBottom: theme.spacing(4),
        borderTop: `solid ${osColour.neutral.mist} 1px`,
        paddingTop: theme.spacing(3),
        flexWrap: 'wrap-reverse',
        alignItems: 'start',
        justifyContent: 'space-between'
    },
    download: {
        display: 'flex',
        flex: '1 1 auto',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    downloadTitle: {
        marginBottom: 10,
        marginTop: 2,
        marginRight: theme.spacing(1)
    },
    downloadInfo: {
        marginLeft: theme.spacing(3),
        flex: '1 1 auto',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 20
        }
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            alignItems: 'start'
        }
    },
    title: {
        maxWidth: 600
    }
});

export function PremiumDownloadsItem(props) {
    const [animate, setAnimate] = useState(false);
    const {item, itemType, classes, path} = props;

    function startAnimation() {
        // To prevent react from doing unnecessary rendering we avoid altering the state of the component
        // when it is already correct.
        if(!animate) {
            setAnimate(true);
        }
    }

    let imageItem;
    if (isWidthDown('xs', props.width)) {
        imageItem = [item.images[0]];
    }

    return <div className={classes.downloadContainer}
                onMouseEnter={startAnimation}
                onMouseLeave={() => setAnimate(false)}
                onMouseMove={startAnimation}>
            <div className={classes.download}>
                {item.images.length > 0 &&
                    <DownloadImage images={imageItem ? imageItem : item.images} animate={animate} productName={item.label} />
                }
                <div className={classes.downloadInfo}>
                    <div className={classes.titleContainer}>
                        <div className={classes.title}>
                            <Link path={path}
                                  state={{item}}
                                  className={classes.downloadTitle}>
                                <Typography variant='h2'>
                                    {item.label}
                                </Typography>
                            </Link>
                        </div>
                        <div>{itemType.map(aItem => <CatalogueTag key={aItem.id} type={aItem.id}/>)}</div>
                    </div>
                    <PremiumDesc item={item} />
                </div>
            </div>
        </div>;
}

PremiumDownloadsItem.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    itemType: PropTypes.array.isRequired,
    path: PropTypes.string.isRequired
}

export default compose(withWidth(), withStyles(styles))(PremiumDownloadsItem);
