import React from 'react';
import classNames from 'classnames';
import {createUseStyles} from 'react-jss';
import {useIntl, defineMessages} from 'react-intl';
import {osColour} from 'omse-components';
import {ReactComponent as CloseIcon} from '../../components/icons/close-small.svg';
import {ReactComponent as WarningIcon} from '../../components/icons/warning-notification-outline.svg';
import {ReactComponent as TickIcon} from '../../components/icons/tick.svg';

export const messages = defineMessages({
    available: {
        id: 'ServiceStatusTable.available',
        defaultMessage: 'Available',
        description: 'Text describing an API/request/item that is available'
    },
    degraded: {
        id: 'ServiceStatusTable.degraded',
        defaultMessage: 'Degraded',
        description: 'Text describing an API/request/item that is degraded'
    },
    unavailable: {
        id: 'ServiceStatusTable.unavailable',
        defaultMessage: 'Interrupted availability',
        description: 'Text describing an API/request/item that is unavailable at some point within the hour'
    }
});


/* Common */
export const availableColour = osColour.status.success;
export const degradedColour = osColour.neutral.charcoal;
export const unavailableColour = osColour.status.error;
const iconSizeProps = {height: 24, width: 24};

/* Icons */
const useIconStyles = createUseStyles({
    available: {
        color: availableColour
    },
    degraded: {
        color: degradedColour
    },
    unavailable: {
        color: unavailableColour
    }
});
export function AvailableIcon({className}) {
    const classes = useIconStyles();
    const intl = useIntl();
    return <TickIcon className={classNames(className, classes.available)}
                     title={intl.formatMessage(messages.available)}
                     aria-label={intl.formatMessage(messages.available)}
                     {...iconSizeProps} />
}
export function DegradedIcon({className}) {
    const classes = useIconStyles();
    const intl = useIntl();
    return <WarningIcon className={classNames(className, classes.degraded)}
                        title={intl.formatMessage(messages.degraded)}
                        aria-label={intl.formatMessage(messages.degraded)}
                        {...iconSizeProps} />
}
export function UnavailableIcon({className}) {
    const classes = useIconStyles();
    const intl = useIntl();
    return <CloseIcon className={classNames(className, classes.unavailable)}
                      title={intl.formatMessage(messages.unavailable)}
                      aria-label={intl.formatMessage(messages.unavailable)}
                      {...iconSizeProps} />
}