import React from 'react';
import {createUseStyles} from 'react-jss';
import openRoadsImage from './img/open-roads-britain-1.jpg'
import Typography from "@mui/material/Typography";
import {defineMessages, FormattedMessage} from "react-intl";
import {osColour} from 'omse-components';

const useStyles = createUseStyles(theme => {
    return {
        content: {
            padding: "0",
            margin: "0 auto",
            display: "flex",
            height: "100%",
            width: "100%",
            backgroundColor: "#f9faf2",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'auto 100%',
            color: osColour.primary.berry
        },
        notice: {
            width: 640,
            display: "flex",
            zIndex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            minWidth: 300,
            background: 'linear-gradient(to right, #f5f5f5 0%, rgba(245, 245, 245, 0.9) 30%, rgba(245, 245, 245, 0.8) 45%, rgba(245, 245, 245, 0.4) 65%, rgba(245, 244, 243, 0.1) 80%, rgba(242, 239, 231, 0) 100%)',
            paddingLeft: 30
        },
        subText: {
            fontSize: '1.25rem',
            fontWeight: '600',
            lineHeight: '1.6',
            margin: 0,
        },
        mainText:{
            fontSize: '2rem',
            lineHeight: '1.2',
            marginTop: 0,
        }
    }
});

const messages = defineMessages({
    main: {
        id: 'main',
        defaultMessage: 'The OS DataHub is undergoing scheduled maintenance, please try again later.',
        description: 'Main heading of the holding page'
    },
    actionsMessage: {
        id: 'actionsMessage',
        defaultMessage: 'Through the OS Data Hub, you’ll be able to:',
        description: 'Actions that the user can do on datahub'
    },
    message1: {
        id: 'message1',
        defaultMessage: 'Download OS OpenData and paid-for products',
        description: 'Actions that the user can do on datahub'
    },
    message2: {
        id: 'message2',
        defaultMessage: 'Access API services',
        description: 'Actions that the user can do on datahub'
    },
    message3: {
        id: 'message3',
        defaultMessage: 'Manage your accounts and view your data usage',
        description: 'Actions that the user can do on datahub'
    },
    message4: {
        id: 'message4',
        defaultMessage: 'Access data in new and improved formats',
        description: 'Actions that the user can do on datahub'
    },
    message5: {
        id: 'message5',
        defaultMessage: 'Report errors in OS data',
        description: 'Report errors in OS data'
    }
});


export default function HoldingPage() {
    const classes = useStyles();

    return (
        <div className={classes.content} style={{backgroundImage: `url(${openRoadsImage})`}}>
            <div className={classes.notice}>
                <Typography variant='h1' className={classes.mainText}>
                    <FormattedMessage {...messages.main}/>
                </Typography>
                <br/>
                <Typography variant='h3' className={classes.subText}>
                    <FormattedMessage {...messages.actionsMessage}/>
                </Typography>
                <Typography component={'span'} variant='h3' className={classes.subText}>
                    <ul>
                        <li><FormattedMessage {...messages.message1}/></li>
                        <li><FormattedMessage {...messages.message2}/></li>
                        <li><FormattedMessage {...messages.message3}/></li>
                        <li><FormattedMessage {...messages.message4}/></li>
                        <li><FormattedMessage {...messages.message5}/></li>
                    </ul>
                </Typography>
            </div>
        </div>
    );
}
