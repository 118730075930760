import React from "react";
import PropTypes from "prop-types";
import { defineMessages, FormattedMessage } from "react-intl";
import {createUseStyles} from 'react-jss';
import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {PREVIOUS, PENDING} from '../../MyApiPlans';

const messages = defineMessages({
    noplans:{
        id: "ApiPlansDroppdown.noplans",
        defaultMessage:"There are no plans to show here.",
        description: "No plans text"
    }
});

const useStyles = createUseStyles(theme => ({
    childrenContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    accordionContainer: {
        marginBottom: theme.spacing(2)
    },
    accordionDetails: {
        display:"block"
    },
    expandSummaryIcon: {
        padding: theme.spacing(1.5, 0)
    }
}));

export default function ApiPlansDropdown({ title, plans, type, children}) {
    const classes = useStyles();

    const displayStatus = {
        ACTIVE:"Active",
        ENDED: "Ended",
        EXPIRED:"Expired"
    }

    return (
        <Box className={classes.accordionContainer}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} classes={{expandIconWrapper: classes.expandSummaryIcon}}>
                    <Typography variant='h3'>
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <Box className={classes.childrenContainer}>
                        {children}
                    </Box>
                    {(!plans || plans.length===0) &&
                        <Typography variant="body2" data-testid="no-plans-warning">
                            <FormattedMessage {...messages.noplans}/>
                        </Typography>
                    }
                    {plans?.length>0 &&
                        <Table data-testid="plans-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Purchase Date</TableCell>
                                    <TableCell>Opening Balance</TableCell>
                                    <TableCell>Tier</TableCell>
                                    {type === PENDING &&
                                        <TableCell>Plan Expiration Date</TableCell>
                                    }
                                    {type===PREVIOUS &&
                                        <>
                                        <TableCell>End Date</TableCell>
                                        <TableCell>End Reason</TableCell>
                                        </>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {plans.map(plan=>(
                                <TableRow key={plan.id}>
                                    <TableCell>{new Date(plan.created).toLocaleDateString()}</TableCell>
                                    <TableCell>£{Number(plan.openingBalance).toLocaleString()}</TableCell>
                                    <TableCell>{plan.tier?.name}</TableCell>
                                    {type === PENDING &&
                                        <TableCell>{new Date(plan.expirationDate).toLocaleDateString()}</TableCell>
                                    }
                                    {type===PREVIOUS &&
                                        <>
                                            <TableCell>{new Date(plan.stableEnd).toLocaleDateString() || ""}</TableCell>
                                            <TableCell>{displayStatus[plan.status]}</TableCell>
                                        </>
                                    }
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    }
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

ApiPlansDropdown.defaultProps = {};
ApiPlansDropdown.propTypes = {
    title:PropTypes.string,
    plans: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string
};
