import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { recipesMessages } from "../messages";
import { FormattedMessage } from "react-intl";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AddButton, InternalLink, osColour, theme } from "omse-components";
import { Typography } from "@mui/material";
import ThemeChips from "../../../../components/ThemeChips";
import SharedWithMeTag from "../../../../components/SharedWithMeTag";

const CardContainer = styled("article")`
    margin: 1em 1em 0 0;
    padding: 0;
    display: grid;
    gap: 1em;
    align-items: start;
    grid-template-columns: 1fr auto;
    border-bottom: 1px solid ${osColour.neutral.clouds};
    ${theme.breakpoints.down("sm")} {
        grid-template-columns: 1fr;
        gap: 0;
    }
    &:last-of-type {
        border-bottom: none;
    }
    & > div:first-of-type > h3 {
        padding: 0 0 0 0;
    }
    & > div:first-of-type > h3 > a {
        font-size: 1.2em;
    }
    & > div:last-of-type {
        min-width: 20em;
        ${theme.breakpoints.down("md")} {
            min-width: 0;
        }
    }
    & > div:last-of-type > div {
        margin: 0 0 1em 0;
    }
    & > div:last-of-type > dl {
        margin: 0 0 1em 0;
        min-width: 16em;
        font-size: 1em;
        list-style-type: none;
        display: grid;
        grid-template-columns: auto 1fr;
    }
    & > div:last-of-type > dl > dt {
        color: ${osColour.neutral.stone};
        min-width: 6.6em;
        ${theme.breakpoints.down("sm")} {
            padding: 0 0.6em 0 0;
            min-width: 0;
        }
    }
    & > div:last-of-type > dl > dd {
        color: ${osColour.neutral.charcoal};
        font-weight: 600;
        margin-inline-start: 0;
    }
    & > button,
    > div:first-of-type > button {
        margin: 0 0 1em 0;
        ${theme.breakpoints.up(500)} {
            max-width: 12em;
        }
    }
`;

export default function RecipeCard(p) {
    const {
        recipeMetadata,
        userHasWritePermission,
        licencesAreLoading,
        handleAddDataPackageButtonClick,
    } = p;
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

    const AddDataPackageButton = () => (
        <AddButton
            label={recipesMessages.instanceAddDataPackage}
            action={() => handleAddDataPackageButtonClick(recipeMetadata.id)}
            working={licencesAreLoading}
            disabled={licencesAreLoading}
            showIcon={false}
        />
    );

    return (
        <CardContainer>
            <div>
                <Typography variant="h4" component="h3">
                    <InternalLink
                        path={`/downloads/recipes/${recipeMetadata.id}`}
                        message={recipeMetadata.name}
                    />
                </Typography>
                <ThemeChips themes={recipeMetadata.themes} />
                {!isMobile && userHasWritePermission && <AddDataPackageButton />}
            </div>
            <div>
                {recipeMetadata.sharedWithMe && <SharedWithMeTag />}
                <dl>
                    <Typography variant="body1" component="dt">
                        <FormattedMessage {...recipesMessages.instanceAuthor} />
                    </Typography>
                    <Typography variant="body1" component="dd">
                        {recipeMetadata.author}
                    </Typography>
                    <Typography variant="body1" component="dt">
                        <FormattedMessage {...recipesMessages.instanceDate} />
                    </Typography>
                    <Typography variant="body1" component="dd">
                        {recipeMetadata.created}
                    </Typography>
                </dl>
            </div>
            {isMobile && userHasWritePermission && <AddDataPackageButton />}
        </CardContainer>
    );
}

RecipeCard.propTypes = {
    userHasWritePermission: PropTypes.bool,
    userHasNgdDownloadsLicence: PropTypes.bool,
    recipeMetadata: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        author: PropTypes.string,
        themes: PropTypes.arrayOf(PropTypes.string),
        created: PropTypes.string,
    }),
};
