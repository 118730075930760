import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {CommonDialog, modules} from 'omse-components';
import {FormattedMessage, defineMessages} from 'react-intl';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {createUseStyles} from 'react-jss';
const {getThemeTree} = modules.actions.themes;

const messages = defineMessages({
    title: {
        id: "MixedCrsDialog.title",
        defaultMessage: "CRS Details",
        description: "Title for mixed crs dialog"
    },
    content: {
        id: "MixedCrsDialog.content",
        defaultMessage: "The collections in this recipe have different default coordinate reference systems. Given your current CRS selection, they will be supplied as shown in the table below.",
        description: "Content for mixed crs required dialog"
    },
    link: {
        id: "MixedCrsDialog.link",
        defaultMessage: "See available coordinate reference systems for all collections",
        description: "Link for mixed crs required dialog"
    }
});

const styles = createUseStyles(theme => ({
    theme : {
        margin: theme.spacing(2)
    },
    alignLeft: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(0),
        minWidth: '7em'
    },
    alignRight: {
        marginRight: theme.spacing(0),
        margin: theme.spacing(1)
    },
    collectionWrapper: {
        display: 'flex !important'
    }
}));

const documentationUrl = "https://docs.os.uk/osngd/getting-started/os-ngd-core-principles/coordinate-reference-systems#default-coordinate-reference-systems";

function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}

function mapThemesFromDatasets(themeTree, datasets, classes, crsOptions, selectedCrsOption=null){
    const themes = {};
    if(!themeTree || !datasets) {return null;}
    function mapCrsLabel(dataset) {
        return selectedCrsOption && dataset.availableCrs.find(crs => crs === selectedCrsOption) ?
            crsOptions.filter(option => option.value === selectedCrsOption)[0].label :
            //otherwise it will be supplied in the default crs
            crsOptions.filter(option => option.value === dataset.defaultCrs)[0].label;
    }
    themeTree.forEach((theme) => {
        theme.collections.forEach((collection) => {
            const uniqueCrsLabels = datasets
                .filter(d => collection.featureTypes.map(f => f.featureId).includes(d.featureTypeId))
                .map(mapCrsLabel)
                .filter(onlyUnique);
            if (uniqueCrsLabels.length > 0) {
                if (!themes[theme.label]) { themes[theme.label] = []; }
                themes[theme.label].push(
                    // We then asemble the table row for this collection
                    <div key={collection.label} className={classes.collectionWrapper}>
                        <div className={classes.alignLeft}>
                            <Typography>{collection.label}</Typography>
                        </div>
                        <div className={classes.alignRight}>
                            {uniqueCrsLabels.map(label => <Typography key={label}>{label}</Typography>)}
                        </div>
                    </div>
                );
            }
        });
    });
    return <>
        <hr/>
        <div>
            {Object.keys(themes).map((theme) => {
                return <>
                    <div className={classes.theme}>
                        <Typography variant='h2'>
                            {theme}
                        </Typography>
                        {themes[theme]}
                    </div>
                    {Object.keys(themes).indexOf(theme) !== Object.keys(themes).length - 0 && <hr/>}
                </>;
            })}
        </div>
    </>;
}

export default function MixedCrsDialog(props) {
    const dispatch = useDispatch();
    const classes = styles();
    useEffect(() => {
        dispatch(getThemeTree());
    }, [dispatch]);

    const selectedCrsOption = useSelector((state) => state.dataPackages.draftOrder["crs"]) || null;
    const themeTree = useSelector(state => state.themes.themeTree.result);
    const datasetOptions = useSelector(state => state.recipes.datasetOptions.result);
    const {onClose, crsOptions} = props;
    return <CommonDialog onClose={onClose} title={messages.title}>
        <div>
            <Typography variant='body1'>
                <FormattedMessage {...messages.content}/>
            </Typography>
            <a target={"_blank"} rel={"noopener noreferrer"} href={documentationUrl}>
                <FormattedMessage {...messages.link}/>
            </a>
            {mapThemesFromDatasets(themeTree, datasetOptions, classes, crsOptions, selectedCrsOption)}
        </div>
    </CommonDialog>;
}

MixedCrsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    crsOptions: PropTypes.array.isRequired
};
