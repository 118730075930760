import React from "react";
import withStyles from 'react-jss';
import {osColour} from 'omse-components';
import {ReactComponent as CloseIcon} from "../../../components/icons/close-large.svg";
import {ButtonBase, Chip} from '@mui/material';
import PropTypes from 'prop-types';

function styles(theme) {
    return {
        squareChip: {
            flex: '0 0 auto',
            borderRadius:0,
            background: osColour.neutral.mist,
            color: osColour.primary.berry,
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: 60,
            height: 32,
            textOverflow: 'unset',
            '&:focus': {
                outline: 'auto'
            }
        },
        closeIcon: {
            flex: '0 0 auto',
            color: osColour.primary.berry,
            margin: 0,
            padding: theme.spacing(0.5)
        },
        label: {
            flex: '0 0 auto',
            overflow: 'unset',
            textOverflow: 'unset',
            paddingRight: 0,
            fontSize: '1rem',
            lineHeight: 1
        },
        deleteIconSmall: {
            margin: 0
        },
        deleteIcon: {
            '&:hover': {
                color: osColour.primary.berry
            }
        },
        deletable: {
            '&:focus': {
                background: osColour.neutral.mist
            }
        }
    }
}

export function GridChip(props) {
    const {classes, square, chipRef} = props;
    return <Chip label={square}
        classes={{label: classes.label, deleteIcon: classes.deleteIcon, deleteIconSmall: classes.deleteIcon, deletable: classes.deletable}}
        className={classes.squareChip}
        size='small'
        onDelete={props.onDelete(square)}
        component={ButtonBase}
        disableTouchRipple
        deleteIcon={<CloseIcon width={14} height={14} className={classes.closeIcon} />}
        ref={chipRef}
    />;
}

GridChip.propTypes = {
    classes: PropTypes.object.isRequired,
    square: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default withStyles(styles)(GridChip);