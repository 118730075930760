import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import {osColour} from 'omse-components';
import cadcorp1LaunchDesktop from './cadcorpSIS/1-launch-desktop.png';
import cadcorp2AddOverlayWizard from './cadcorpSIS/2-add-overlay-wizard.png';
import cadcorp3OSDataHub from './cadcorpSIS/3-os-data-hub.png';
import cadcorp4OSFeaturesAPI from './cadcorpSIS/4-os-features-api.png';
import cadcorp4NoAPIKey from './cadcorpSIS/4-no-api-key.png';
import cadcorp4WithAPIKey from './cadcorpSIS/4-with-api-key.png';
import cadcorp5LayerChoice from './cadcorpSIS/5-layer-choice.png';
import cadcorp6FeatureTypesSelection from './cadcorpSIS/6-feature-type-selection.png';
import cadcorp7ThemeChoice from './cadcorpSIS/7-theme-choice.png';
import cadcorp8CacheOrImport from './cadcorpSIS/8-cache-or-import.png';
import cadcorp9Filtering from './cadcorpSIS/9-filtering.png';
import cadcorp9OSFeaturesAPI from './cadcorpSIS/9-os-features-api.png';
import cadcorp9SpatialTest from './cadcorpSIS/9-spatial-test.png';
import WFSCompletionSummary, { messages as wfsCompletionMessages } from './WFSCompletionSummary';
import {defineMessages} from "react-intl";

export const messages = defineMessages({
    achievement3: {
        id: 'CadcorpSIS.achievement3',
        defaultMessage: `You've filtered the features that are loaded by a maximum number or a specific area.`,
        description: 'Achievement 3'
    }
});

const styles = {
    guide: {
        '& img': {
            border: `solid ${osColour.neutral.mist} 1px`
        }
    }
};

export function CadcorpSIS(props) {
    const {classes} = props;
    return <Fragment>
        <Typography variant='body1' paragraph={true} component='div'>
            <p>
                The Cadcorp Spatial Information System® (Cadcorp SIS®) is an integrated family of geospatial products
                comprising desktop, web, and developer applications.
            </p>

            <p>
                Cadcorp SIS Desktop and Cadcorp SIS Desktop Express (free) connects directly to the Ordnance Survey Data
                Hub through dedicated wizards.
            </p>
            <p>
                The following instructions will demonstrate how to connect to the OS Features API. For the purposes of
                this guide, the version of Cadcorp SIS Desktop Express used is 9.0.2275.
            </p>
        </Typography>
        <Typography variant='h2'>Integrating OS Features API in Cadcorp SIS</Typography>
        <Typography variant='body1' paragraph={true} component='div' className={classes.guide}>
            <ol>
                <li>
                    <p>
                        Start Cadcorp SIS Desktop Express. From the opening window, select a Blank Map or a Map Template
                        with pre-defined base mapping.
                    </p>
                    <img src={cadcorp1LaunchDesktop}
                         alt='Cadcorp SIS create map screen with Blank Map or Map template highlighted' />
                </li>
                <li>
                    <p>Navigate to the Home tab at the top of the interface and launch the Add Overlay wizard.</p>
                    <img src={cadcorp2AddOverlayWizard}
                         alt='Cadcorp SIS Home toolbar with Add Overlay button highlighted' />
                </li>
                <li>
                    <p>Navigate to Ordnance Survey (GB) → OS (GB) Data Hub → click Next.</p>
                    <img src={cadcorp3OSDataHub}
                         alt='Cadcorp SIS Overlay Types selection screen with OS (GB) Data Hub option and next button
                              highlighted' />
                </li>
                <li>
                    <p>
                        If you do not have an API key yet, you can use the Cadcorp API Key included as standard. The
                        Cadcorp API key is restricted to the free Open Data Plan and cannot be used to access Premium
                        features. Select the OS Features API → click Next.
                    </p>
                    <img src={cadcorp4OSFeaturesAPI}
                         alt='Cadcorp SIS OS (GB) Data Hub menu with OS Features API option and next button highlighted' />

                    <p>Select Yes to use the Cadcorp API Key.</p>
                    <img src={cadcorp4NoAPIKey}
                         alt='Confirmation dialog to use the default Cadcorp key OS Data Hub API with Yes button
                              highlighted' />

                    <p>
                        If you have an API key, add the API key. If you have access to
                        Premium features, select Premium Plan. Select the OS Features API → click Next.
                    </p>
                    <img src={cadcorp4WithAPIKey}
                         alt='Cadcorp SIS OS (GB) Data Hub menu with API Key filled in, Premium Plan ticked, and
                              next button highlighted' />
                </li>
                <li>
                    <p>A window will display the OS Features API layers. Select the layer you wish to add to the map.</p>
                    <img src={cadcorp5LayerChoice}
                         alt='Cadcorp SIS OS Data Hub Features API product and Feature Type selection menu' />
                </li>
                <li>
                    <p>
                        You will be presented with a list of feature types for the chosen layer. All features will be
                        selected by default. You can choose to add individual features to the map by deselecting the
                        feature types that are not required.
                    </p>
                    <img src={cadcorp6FeatureTypesSelection}
                         alt='Cadcorp SIS OS Data Hub Features API product and Feature Type selection menu with Feature
                              Types highlighted' />
                </li>
                <li>
                    <p>
                        Select the style for the map under the theme option. This option is only applicable to some OS
                        Features API layers.
                    </p>
                    <img src={cadcorp7ThemeChoice}
                         alt='Cadcorp SIS OS Data Hub Features API product and Feature Type selection menu with theme
                              drop-down menu expanded and highlighted' />
                </li>
                <li>
                    <p>Select if the download is a one-off import or a local cache.</p>
                    <img src={cadcorp8CacheOrImport}
                         alt='Cadcorp SIS OS Data Hub Features API product and Feature Type selection menu with
                              download options highlighted' />
                </li>
                <li>
                    <p>
                        The selected features will load within the current map extent. For best practice, we recommend
                        that you limit the maximum number of features.
                    </p>
                    <img src={cadcorp9Filtering}
                         alt='Cadcorp SIS OS Data Hub Features API product and Feature Type selection menu with
                              feature filtering options highlighted' />

                    <p>
                        You can also load features within a specific area of interest as opposed to the extent of the
                        current map window. Before step 2, select the polygon feature on the map for your area of
                        interest. Follow steps 2 to 9 and then select the ‘Intersect with current item extent’ for the
                        spatial filter. If a different spatial test is preferred, select ‘Spatial test with current
                        item’, and this will activate the spatial test drop-down menu where you can select your spatial test
                        of choice.
                    </p>
                    <img src={cadcorp9SpatialTest}
                         alt='Cadcorp SIS OS Data Hub Features API product and Feature Type selection menu with spatial
                              filtering options highlighted' />

                    <p>Click Finish to add the layer to the Map.</p>
                    <img src={cadcorp9OSFeaturesAPI}
                         alt='Cadcorp SIS display of rendered map using options from previous steps' />
                </li>
            </ol>
        </Typography>
        <WFSCompletionSummary software="Cadcorp SIS" achievements={[
            wfsCompletionMessages.achievement1,
            wfsCompletionMessages.achievement2,
            messages.achievement3
        ]} />
    </Fragment>
}

CadcorpSIS.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CadcorpSIS);