import { css } from '@emotion/react';
import { osColour } from 'omse-components';

export const tabHeadingStyles = css`
   & > h3 {
        font-family: OSGill, sans-serif;
        color: ${osColour.primary.berry};
        margin-bottom: 24px;
    }
`;

export default tabHeadingStyles;