import React, {useState, useCallback, Fragment, useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {createUseStyles} from 'react-jss';
import Typography from '@mui/material/Typography';
import {useIntl, defineMessages, FormattedMessage} from 'react-intl';
import {tableStyles} from '../styles/common';
import {osColour} from 'omse-components';
import CircularProgress from '@mui/material/CircularProgress';
import HoverTooltip from '../../components/HoverTooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from 'react-jss';
import {
    availableColour,
    AvailableIcon,
    degradedColour,
    DegradedIcon,
    unavailableColour,
    UnavailableIcon
} from './ServiceStatusIcons';
import ServiceStatusTableHeader from './ServiceStatusTableHeader';

const messages = defineMessages({
    error: {
        id: 'ServiceStatusTable.error',
        defaultMessage: 'Service availability data is not accessible at the moment. Please try again later.',
        description: 'Message displayed when service status data is not ready yet.'
    },
    nameTimeHeader: {
        id: 'ServiceStatusTable.nameTimeHeader',
        defaultMessage: 'Name/Time',
        description: 'Text for the cell showing in the corner of the table for labelling name column and time row'
    },
    currentTimeTableCellHeader: {
        id: 'ServiceStatusTable.currentTimeTableCellHeader',
        defaultMessage: 'latest',
        description: 'Header put above the first time to indicate the current time (i.e. now).'
    },
    lastUpdateTimeFooter: {
        id: 'ServiceStatusTable.lastUpdateTimeFooter',
        defaultMessage: 'Last update time: {time}',
        description: 'Footer of the service status table showing the last update time.'
    },
    overallStatusAriaLabelAvailable: {
        id: 'ServiceStatusTable.overallStatusAriaLabel',
        defaultMessage: 'Overall status: available',
        description: 'ARIA label for the overall status box when available'
    },
    overallStatusAriaLabelDegraded: {
        id: 'ServiceStatusTable.overallStatusAriaLabelDegraded',
        defaultMessage: 'Overall status: degraded',
        description: 'ARIA label for the overall status box when degraded'
    },
    overallStatusAriaLabelUnavailable: {
        id: 'ServiceStatusTable.overallStatusAriaLabelUnavailable',
        defaultMessage: 'Overall status: unavailable',
        description: 'ARIA label for the overall status box when down'
    }
});

/* ServiceStatusTable */
const useStyles = createUseStyles(theme => ({
    alignWrapper: {
        /* There is an awkward combination of factors to combine in this component:
           1) We have a service status table
             1a) If this is larger than the main content width then it needs to overflow.
             1b) If this is smaller than the main content its width does not reach the edge.
           2) We have a key. This needs to be aligned to the right of the table in the two cases described above. But
           it needs to not overflow when the table overflows.

        Using display: 'inline-grid' copes with both cases. It:
        1) Causes the container around the table to shrink-to-fit to the size of the table when it is larger than the
        table, handling 1a.
        2) It shrinks its width even when it is smaller than the size of the table, handling 1b. display: inline-block
        does not do this and will not shrink. */
        display: 'inline-grid'
    }
}));

export default function ServiceStatusTable({className, title, showDegradedIcon, loading, data, lastTimeReceived, instanceId}) {
    const classes = useStyles();

    let state;
    if(loading) {
        state = 'loading';
    } else if(data && data.length > 0) {
        state = 'ready';
    } else {
        state = 'error';
    }

    // Our alignWrapper gives us the shrink-to-fit behaviour we need, but we still want this component to be a block
    // component, so wrap it in a final block div.
    return <div className={className}>
        <div className={classes.alignWrapper}>
            <ServiceStatusTableHeader title={title} showIcons={state === 'ready'} showDegradedIcon={showDegradedIcon} />
            <MainContent state={state} data={data} lastTimeReceived={lastTimeReceived} instanceId={instanceId} />
        </div>
    </div>
}

/* Main content */
const useMainContentStyles = createUseStyles(theme => ({
    // Having a wrapper div with overflow auto ensures this component is responsible for overflow handling and will
    // automatically stretch to the width of its parent, but then overflow if wider.
    tableScrollWrapper: {
        overflow: 'auto'
    },

    /* Footer */
    lastUpdateTimeFooter: {
        textAlign: 'right',
        marginTop: theme.spacing(1),
        color: osColour.neutral.stone
    }
}));
function MainContent({state, data, lastTimeReceived, instanceId}) {
    const classes = useMainContentStyles();

    // Box shadow applied when have scrolled the table.
    const [isScrolled, setIsScrolled] = useState(false);
    const onScroll = useCallback(evt => setIsScrolled(evt.target.scrollLeft > 0), []);

    if(state === 'loading') {
        return <CircularProgress size={32} />;
    } else if(state === 'ready') {
        const lastTimeReceivedDate = new Date(lastTimeReceived);
        return <>
            <div className={classes.tableScrollWrapper} onScroll={onScroll}>
                <Table data={data} isScrolled={isScrolled} instanceId={instanceId}/>
            </div>
            <Typography className={classes.lastUpdateTimeFooter} variant='caption'>
                <FormattedMessage {...messages.lastUpdateTimeFooter} values={{
                    time: <time dateTime={lastTimeReceivedDate.toISOString()}>{getLocalisedTimeForFooter(lastTimeReceivedDate)}</time>
                }} />
            </Typography>
        </>;
    } else {
        return <Typography variant='body1'>
            <FormattedMessage {...messages.error} />
        </Typography>;
    }
}

ServiceStatusTable.propTypes = {
    className: PropTypes.string,
    title: PropTypes.element,
    showDegradedIcon: PropTypes.bool,
    loading: PropTypes.bool,
    data: PropTypes.array,
    lastTimeReceived: PropTypes.string,
};


/* The actual table */
const useTableStyles = createUseStyles(tableStyles);
const useServiceStatusTableStyles = createUseStyles(theme => ({
    table: {
        '& td': {
            padding: '0 !important',
            verticalAlign: 'middle'
        },
        '& thead th': {
            verticalAlign: 'bottom'
        }
    },
    highlightHoverRow: {
        '&:hover': {
            background: osColour.primary.lightestBerry
        },
    },
    timeHeaderCell: {
        verticalAlign: 'bottom'
    },
    // Styles to apply to headers we want to be more muted.
    mutedTableHeaderCell: {
        padding: '0 !important',
        borderBottom: '0 !important'
    },

    /* Footer */
    lastUpdateTimeFooter: {
        textAlign: 'right',
        marginTop: theme.spacing(1),
        color: osColour.neutral.stone
    },

    /* Desktop specific styles */
    desktopFirstCol: {
        // Make the first column fixed.
        position: 'sticky',
        left: 0,
        zIndex: 1, // Otherwise the SVGs appear over the top of the first column.
        background: `${osColour.primary.lightestBerry} !important`
    },
    desktopFirstColWrapper: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    desktopNameTimeText: {
        paddingRight: theme.spacing(1) // When Name/Time is larger than all request names needs some padding.
    },
    desktopFirstColNameText: {
        whiteSpace: 'nowrap',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    desktopFirstColStatus: {},
    desktopFakeSecondColumnWrapper: props => ({
        left: props?.firstColWidth || 0,
        textAlign: 'right',
        paddingLeft: '0 !important',
        '& svg': {
            marginRight: theme.spacing(1)
        }
    }),
    desktopFakeSecondColumn: {
        marginLeft: 'auto' // Aligns to the end.
    },

    // First cell - scroll shadow
    scrollShadow: {
        '&:after': {
            transition: 'box-shadow 0.5s ease-in-out',
            content: '" "',
            height: '100%',
            position: 'absolute',
            top: '0',
            width: '10px',
            right: '-10px'
        }
    },
    scrollShadowApplied: {
        '&:after': {
            // If you use a regular outer box shadow then you cannot get it exactly cut off on one side and it bleeds
            // into other sides. An inset box shadow is painted cut off so allows us to get around this problem.
            boxShadow: '10px 0px 5px -9px inset rgba(0, 0, 0, 0.2)'
        }
    },

    /* Mobile specific styles */
    mobileTable: {
        '& tr': {
            // In mobile table item name is not determining row height, instead we set it explicitly.
            height: theme.spacing(4)
        }
    },
    // Apply padding inside the cell to the text rather than to the cell itself to stop padding being lost on scroll,
    // as it's the cell content that's sticky not the cell itself.
    mobileNameTimeRow: {
        '& th': {
            paddingLeft: '0 !important',
            borderBottom: '0 !important',
            background: `${osColour.primary.lightestBerry} !important`
        }
    },
    mobileNameTimeHeaderText: {
        padding: theme.spacing(1)
    },
    // Mobile has a few inline header rows, no borders should appear on these.
    mobileMutedHeaderRow: {
        borderBottom: '0 !important'
    },
    // Styles relating to first column
    // A colspan element that is being made sticky left must be inline so it doesn't expand to the width of the entire
    // cell, if that happens the sticky behaviour breaks.
    colspanElStickyLeft: {
        position: 'sticky',
        left: 0,
        display: 'inline'
    },
    mobileItemOverallStatusAndNameWrapper: {
        // Needs to be inline otherwise sticky stops working as above.
        display: 'inline-flex',
        float: 'left', // This is needed to make sure the content stretches to the height of the row.
        height: theme.spacing(4)
    },
}));

/**
 * @param data Item status data to be displayed in the table. The following format: [{
 *   id: 'item-id',
 *   component: ReactElement, // Component which will be displayed as the name of this item.
 *   data: [{
 *     time: '2020-12-01T12:00:00.000Z', // Time this status occurred.
 *     status: 'available' // Status
 *   }, ...]
 * }, ...]
 * @param isScrolled If the table is currently scrolled, used for styling.
 */
function Table({data, isScrolled, instanceId}) {
    const classesTable = useTableStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const firstColRef = useRef();
    let firstColWidth;
    if (firstColRef) {
        firstColWidth = firstColRef.current?.getBoundingClientRect()?.width;
    }
    const classes = useServiceStatusTableStyles({firstColWidth});

    /* Calculate some data up front */
    // Record all different times we have been given across all items to loop over when filling table.
    const allTimesAsIsoSet = new Set();
    const itemIdToOverallStatus = {}; // Overall status info for an item.
    const itemIdToIsoTimesToStatus = {}; // Data about individual hours for an item in an easy to look up form.
    data.forEach(({id, data: singleItemData}) => {
        singleItemData.forEach(({time, status}) => {
            // Record the unique time.
            allTimesAsIsoSet.add(time);

            // Set overall status for this item. Should be the status of the latest time which should be the first entry.
            itemIdToOverallStatus[id] = itemIdToOverallStatus[id] || status;

            // Record the status at this time for this item.
            itemIdToIsoTimesToStatus[id] = itemIdToIsoTimesToStatus[id] ?? [];
            itemIdToIsoTimesToStatus[id][time] = status;
        });
    });
    const getItemStatusOverall = (id) => itemIdToOverallStatus[id];
    const getItemStatusAtTime = (id, time) => itemIdToIsoTimesToStatus[id][time];
    const allTimes = [...allTimesAsIsoSet];
    const latestTime = allTimes[0];
    const remainingTimes = allTimes.slice(1);

    if(isMobile) {
        return <table className={classNames(classesTable.table, classes.table, classes.mobileTable)}>
            <thead>
                {/* Mobile has an extra header row to contain the name/time header. */}
                <tr className={classNames(classes.mobileMutedHeaderRow, classes.mobileNameTimeRow)}>
                    <th colSpan={allTimes.length}>
                        <Typography variant='body2' className={classNames(classes.colspanElStickyLeft,
                                                                          classes.mobileNameTimeHeaderText)}>
                            <FormattedMessage {...messages.nameTimeHeader}/>
                        </Typography>
                    </th>
                </tr>
                {/* Second row contains all the different times. */}
                <tr>
                    <TimeWithTooltipHeaderCell key={latestTime} isoTime={latestTime} includeNow={true} instanceId={instanceId} />
                    {remainingTimes.map(isoTime => <TimeWithTooltipHeaderCell key={isoTime} isoTime={isoTime} instanceId={instanceId} />)}
                </tr>
            </thead>
            <tbody>
                {/* Loop over data to ensure the order we were given data in is maintained for display. */}
                {data.map(({id, component}) => {
                    return <Fragment key={id}>
                        {/* Mobile has name and overall status on a separate row. */}
                        <tr className={classes.mobileMutedHeaderRow}>
                            <th id={id} colSpan={allTimes.length} className={classes.mutedTableHeaderCell}>
                                <ItemOverallStatusAndName component={component}
                                                   className={classNames(classes.colspanElStickyLeft,
                                                                         classes.mobileItemOverallStatusAndNameWrapper)}
                                                   status={getItemStatusOverall(id)}/>
                            </th>
                        </tr>
                        {/* Second row contains status data for each hour. */}
                        <tr className={classes.highlightHoverRow}>
                            {allTimes.map(isoTime => <ItemStatusCell key={isoTime}
                                                                     headers={id + ' ' + headerId(isoTime, instanceId)}
                                                                     status={getItemStatusAtTime(id, isoTime)} />)}
                        </tr>
                    </Fragment>;
                })}
            </tbody>
        </table>;
    } else {
        const scrollShadowClasses = classNames(classes.scrollShadow, isScrolled && classes.scrollShadowApplied)
        return <table className={classNames(classesTable.table, classes.table)}>
            <thead>
                <tr>
                    {/* Non-mobile has the name/time header and the latest time as the first header cell. */}
                    <th className={classNames(classes.desktopFirstCol, classes.timeHeaderCell)} ref={firstColRef}>
                        <div className={classes.desktopFirstColWrapper}>
                            <Typography className={classes.desktopNameTimeText} variant='body2'>
                                <FormattedMessage {...messages.nameTimeHeader}/>
                            </Typography>
                        </div>
                    </th>
                    <th className={classNames(classes.desktopFirstCol, classes.desktopFakeSecondColumnWrapper, scrollShadowClasses)}>
                        {/* Our second column with "fake" values, see above comments in CSS for reasoning. */}
                        <TimeWithTooltip className={classes.desktopFakeSecondColumn} isoTime={latestTime} includeNow={true} />
                    </th>
                    {/* Then other header cells are created for all the remaining times. */}
                    {remainingTimes.map(isoTime => <TimeWithTooltipHeaderCell key={isoTime} isoTime={isoTime} instanceId={instanceId} />)}
                </tr>
            </thead>
            <tbody>
                {/* Loop over data to ensure the order we were given data in is maintained for display. */}
                {data.map(({id, component}) => {
                    return <Fragment key={id}>
                        {/* Row containing time data. */}
                        <tr className={classes.highlightHoverRow}>
                            {/* For non-mobile name is in the first cell */}
                            <th className={classNames(classes.desktopFirstCol, classes.mutedTableHeaderCell)}>
                                <ItemOverallStatusAndName component={component}
                                                   textClassName={classes.desktopFirstColNameText}
                                                   status={getItemStatusOverall(id)}>
                                </ItemOverallStatusAndName>
                            </th>
                            <td className={classNames(classes.desktopFirstCol, classes.desktopFakeSecondColumnWrapper, scrollShadowClasses)}>
                                {/* Our second column with "fake" values for the latest time, see above comments in CSS for reasoning. */}
                                <ItemStatus className={classNames(classes.desktopFirstColStatus, classes.desktopFakeSecondColumn)}
                                            status={getItemStatusAtTime(id, latestTime)} />
                            </td>
                            {/* Status cells after the first status cell. */}
                            {remainingTimes.map(isoTime => <ItemStatusCell key={isoTime} status={getItemStatusAtTime(id, isoTime)} />)}
                        </tr>
                    </Fragment>;
                })}
            </tbody>
        </table>;
    }
}

/* Component which will display an hour time with a tooltip with the date included. */
const useTimeWithTooltipStyles = createUseStyles(theme => ({
    nowText: {
        lineHeight: 1
    }
}));

const headerId = (time, id) => time + '-' + id;

function TimeWithTooltipHeaderCell({className, isoTime, includeNow = false, instanceId}) {
    const classes = useServiceStatusTableStyles();

    return <th id={headerId(isoTime, instanceId)} className={classNames(classes.timeHeaderCell, className)}>
        <TimeWithTooltip isoTime={isoTime} includeNow={includeNow} />
    </th>
}
function TimeWithTooltip({className, isoTime, includeNow = false}) {
    const classes = useTimeWithTooltipStyles();

    return <div className={className}>
        <HoverTooltip title={<Typography variant='body2'>
            <time dateTime={isoTime}>{formatIsoTimeForHeaderTooltip(isoTime)}</time>
        </Typography>}
            // Have a quick exit otherwise you can see lots of tooltips at once if moving the mouse quickly.
                      TransitionProps={{timeout: {exit: 50}}}>
            {/* Tooltip requires a single child. */}
            <div>
                {/* The first cell has "now" above it. */}
                {includeNow && <Typography className={classes.nowText} variant='body1' component='h6'>
                    <FormattedMessage {...messages.currentTimeTableCellHeader}/>
                </Typography>}
                <Typography variant='body2'>
                    <time dateTime={isoTime}>{formatIsoTimeForHeader(isoTime)}</time>
                </Typography>
            </div>
        </HoverTooltip>
    </div>;
}

/* Item overall status and name component */
const useItemOverallStatusAndNameStyles = createUseStyles(theme => ({
    /* First cell - we use the first cell to show two columns. It's done this way to make position: sticky work with
    what appears to be two columns. */
    ItemOverallStatusAndNameWrapper: {
        display: 'flex',
        alignItems: 'center'
    },

    /* First cell - overall status box in the first cell of each row. */
    overallStatusBox: {
        width: theme.spacing(1),
        marginRight: theme.spacing(1),
        alignSelf: 'stretch',
        flexShrink: 0
    },
    overallStatusBoxAvailable: {
        background: availableColour
    },
    overallStatusBoxDegraded: {
        background: degradedColour
    },
    overallStatusBoxUnavailable: {
        background: unavailableColour
    },
}));
function ItemOverallStatusAndName({className, textClassName, status, component, children}) {
    const classes = useItemOverallStatusAndNameStyles();
    const intl = useIntl();
    const overallStatusBoxVariables = {
        available:   {className: classes.overallStatusBoxAvailable,   labelMessage: messages.overallStatusAriaLabelAvailable},
        degraded:    {className: classes.overallStatusBoxDegraded,    labelMessage: messages.overallStatusAriaLabelDegraded},
        unavailable: {className: classes.overallStatusBoxUnavailable, labelMessage: messages.overallStatusAriaLabelUnavailable}
    }[status];

    return <div className={classNames(className, classes.ItemOverallStatusAndNameWrapper)}>
        {/* Overall status for item */}
        <div aria-label={intl.formatMessage(overallStatusBoxVariables.labelMessage)}
             className={classNames(classes.overallStatusBox, overallStatusBoxVariables.className)} />
        {/* Item name. */}
        <Typography className={textClassName} variant='body1'>
            {component}
        </Typography>
        {children}
    </div>
}

/* Item status component. Displays appropriate icon for given status. */
const useItemStatusCellStyles = createUseStyles(theme => ({
    statusCell: {
        textAlign: 'center',
        '& svg': {
            verticalAlign: 'middle'
        }
    },
}));
// Helper component for a table cell that displays just the item status.
function ItemStatusCell({status, headers}) {
    const classes = useItemStatusCellStyles();
    return <td className={classes.statusCell} headers={headers}>
        <ItemStatus status={status} />
    </td>
}
function ItemStatus({status, className}) {
    let cellContent;
    if (status === 'degraded') {
        cellContent = <DegradedIcon className={className} />;
    } else if (status === 'unavailable') {
        cellContent = <UnavailableIcon className={className} />;
    } else {
        // If 'available' or if no data assume up.
        cellContent = <AvailableIcon className={className} />;
    }
    return cellContent;
}

/* Utilities */
function formatIsoTimeForHeader(isoTime) {
    return new Intl.DateTimeFormat(undefined, {
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
    }).format(new Date(isoTime));
}

function formatIsoTimeForHeaderTooltip(isoTime) {
    return new Intl.DateTimeFormat(undefined, {
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
    }).format(new Date(isoTime));
}

export function getLocalisedTimeForFooter(time) {
    return formatIsoTimeForHeaderTooltip(time.toISOString());
}