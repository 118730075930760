import React from 'react';
import OFAFeatureTypes from "./OFAFeatureTypes";
import Header from "./Header";
import Content from './Content';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import ResponseCodes from "./ResponseCodes";
import styles from './style';
import routes from "../../util/routes";
import Authentication from "./Authentication";
import DocLinks from "./DocLinks";
import Document from './Document';
import Table from './Table';
import FeatureCheck from "../../components/FeatureCheck";
import {calculateRowSpan, EndpointResource} from "./CalculateRowSpan";
import { InternalLink, ExternalLink } from 'omse-components';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

const responseCodes = [
    [200, 'OK', 'Request had been successful.'],
    [400, 'The request was not supported', 'E.g. missing query parameter, malformed syntax.'],
    [401, 'Unauthorized', 'The client has not provided authentication or incorrect authentication.'],
    [404, 'Not Found', 'The server has not found anything matching the Request-URI.'],
    [406, 'Not Supported', 'A request header value was not supported.'],
    [504, 'Service Unavailable', 'Temporary outage due to overloading or maintenance.']
];

const endpoints = {
    'Landing Page': {
        resource: 'Landing Page ',
        path: '/',
        description: 'General information about the service and provides links to all available endpoints. ',
    },
    'Conformance Declaration': {
        resource: 'Conformance Declaration ',
        path: '/conformance ',
        description: 'This endpoint list of all conformance classes specified in a OGC API – Features standard that the API conforms to. ',
    },
    'API definition': {
        resource: 'API definition ',
        path: '/api ',
        description: 'The API definition provides metadata about the API itself and its implementation.',
    },
    'Feature collections': {
        resource: 'Feature collections ',
        path: '/collections ',
        description: <>Lists all the available Feature collections through the API e.g., <InlineCodeSnippet>bld-fts-buildingline</InlineCodeSnippet>.</>,
    },
    'Feature collection': {
        resource: 'Feature collection ',
        path: '/collections/{collectionId} ',
        description: 'Provides information about a specific Feature collection including spatial & temporal extents, available coordinate reference systems etc.',
    },
    'Feature collection Schema': {
        resource: 'Feature collection ',
        path: '/collections/{collectionId}/schema  ',
        description: 'Provides the schema for the feature collection. ',
    },
    'Feature collection Queryables': {
        resource: 'Feature collection ',
        path: '/collections/{collectionId}/queryables ',
        description: 'Lists the attributes that can be used to filter your query.',
    },
    'Features': {
        resource: 'Features',
        path: '/collections/{collectionId}/items ',
        description: <>List of the features provided by the feature collection (limited to <InlineCodeSnippet>100</InlineCodeSnippet> per transaction).</>,
    },
    'Feature': {
        resource: 'Feature ',
        path: '/collections/{collectionId}/items/{featureId} ',
        description: 'This endpoint returns the latest version of a single feature within the specific feature collection. ',
    },

}

const filterHeaders = [
    'Parameter',
    'Value',
    'Example',
]

const spatialFilters = [
    [<InlineCodeSnippet>bbox</InlineCodeSnippet>,
        <>
            <p>
                The bbox parameter specifies a valid bounding box. Only features that have a
                geometry that intersects the bounding box are selected. The bounding box is provided as four numbers:
            </p>
            <ul>
                <li>Lower left corner, coordinate axis 1 (e.g., min x axis)</li>
                <li>Lower left corner, coordinate axis 2 (e.g., min y axis)</li>
                <li>Upper right corner, coordinate axis 1 (e.g., max x axis)</li>
                <li>Upper right corner, coordinate axis 2 (e.g., max y axis)</li>
            </ul>
        </>,
        <ExternalLink
            type="generic"
            href='https://labs.os.uk/public/osngd/os-ngd-api-features/examples/items.html#filter=bbox'
            message="Simple bbox"
        />],
    [<InlineCodeSnippet>bbox-crs</InlineCodeSnippet>, 'The coordinate reference system of the bbox parameter.', '']
]

const temporalFilters = [
    [<InlineCodeSnippet>datetime</InlineCodeSnippet>,
        <>
            <p>
                The datetime parameter specifies a valid date-time with UTC time zone (Z) or an open or closed interval.
                Only features that have a temporal geometry (<InlineCodeSnippet>versionavailablefromdate</InlineCodeSnippet> or <InlineCodeSnippet>versionavailabletodate</InlineCodeSnippet>) that
                intersects the value in the datetime parameter are selected.
            </p>
            <p>
                Date and time expressions adhere to <ExternalLink
                    type="generic"
                    href='https://tools.ietf.org/html/rfc3339#section-5.6'
                    message="RFC3339"
                />:
            </p>
            <ul>
                <li>A date-time: <InlineCodeSnippet>2021-12-12T13:20:50Z</InlineCodeSnippet></li>
                <li>A closed interval: <InlineCodeSnippet>2021-12-12T13:20:50Z/2021-12-18T12:31:12Z</InlineCodeSnippet></li>
                <li>Open-start intervals: <InlineCodeSnippet>../2021-12-18T12:31:12Z</InlineCodeSnippet> or <InlineCodeSnippet>/2021-12-18T12:31:12Z</InlineCodeSnippet></li>
                <li>Open-end intervals: <InlineCodeSnippet>2021-12-12T13:20:50Z/..</InlineCodeSnippet> or <InlineCodeSnippet>2021-12-12T13:20:50Z/</InlineCodeSnippet></li>
            </ul>
        </>,
        <ExternalLink
            type="generic"
            href='https://labs.os.uk/public/osngd/os-ngd-api-features/examples/items.html#filter=bbox,datetime'
            message="Datetime filter"
        />
    ],
]
const crsFilters = [
    [<InlineCodeSnippet>crs</InlineCodeSnippet>,
        'The coordinate reference system of the response geometries. It must be a coordinate reference system supported by the collection and supplied as the full URL. Default is WGS84 longitude/latitude (http://www.opengis.net/def/crs/OGC/1.3/CRS84).'
    ]
]
const attributeFilters = [
    [<InlineCodeSnippet>filter</InlineCodeSnippet>, 'A filter expression in CQL format which is applied when retrieving features to determine which features are included in a result set.'],
    [<InlineCodeSnippet>filter-crs</InlineCodeSnippet>, 'Specify which of the supported CRSs to use to encode geometric values in a filter expression. It must be a coordinate reference system supported by the collection. Default is WGS84 longitude / latitude.'],
    [<InlineCodeSnippet>filter-lang</InlineCodeSnippet>, <>Specify the language used for the filter expression. The default language is <InlineCodeSnippet>cql-text</InlineCodeSnippet>.</>],
    [<InlineCodeSnippet>limit</InlineCodeSnippet>, <>Limits the maximum number of features to be returned in a single response (default and max is <InlineCodeSnippet>100</InlineCodeSnippet> per request).</>],
    [<InlineCodeSnippet>offset</InlineCodeSnippet>, <>Skips past the specified number of features in the collection (default and min is <InlineCodeSnippet>0</InlineCodeSnippet> per request).</>],
]

const cqlOperators = [
    ['Logical', <><InlineCodeSnippet>and</InlineCodeSnippet>, <InlineCodeSnippet>or</InlineCodeSnippet>, not [ <InlineCodeSnippet>{'<>'}</InlineCodeSnippet> ]</>, ''],
    ['Spatial', <InlineCodeSnippet>intersects</InlineCodeSnippet>, <>
        <ExternalLink
            type="generic"
            href='https://labs.os.uk/public/osngd/os-ngd-api-features/examples/items.html#filter=cql-spatial-intersects-point'
            message="CQL spatial filter: intersects – point"
        />
        <ExternalLink
            type="generic"
            href='https://labs.os.uk/public/osngd/os-ngd-api-features/examples/items.html#filter=cql-spatial-intersects-polygon'
            message="CQL spatial filter: intersects – polygon"
        />
    </>
    ],
    ['Comparison', <>equal to [ <InlineCodeSnippet>=</InlineCodeSnippet> ], less than [ <InlineCodeSnippet>{'<'}</InlineCodeSnippet> ], less than or equal to [ <InlineCodeSnippet>{'<='}</InlineCodeSnippet> ], greater than [ <InlineCodeSnippet>{'>'}</InlineCodeSnippet> ], greater than or equal to [ <InlineCodeSnippet>{'>='}</InlineCodeSnippet> ], <InlineCodeSnippet>null</InlineCodeSnippet>, <InlineCodeSnippet>like</InlineCodeSnippet>, <InlineCodeSnippet>in</InlineCodeSnippet>, <InlineCodeSnippet>between</InlineCodeSnippet></>,
        <ExternalLink
            type="generic"
            href='https://labs.os.uk/public/osngd/os-ngd-api-features/examples/items.html#filter=bbox,cql-comparison-equal-to'
            message="CQL comparison filter: equal to"
        />],
    ['Array', <><InlineCodeSnippet>aequals</InlineCodeSnippet>, <InlineCodeSnippet>acontains</InlineCodeSnippet>, <InlineCodeSnippet>contained by</InlineCodeSnippet>, <InlineCodeSnippet>aoverlaps</InlineCodeSnippet></>,
        <ExternalLink
            type="generic"
            href='https://labs.os.uk/public/osngd/os-ngd-api-features/examples/items.html#filter=bbox,cql-array-aequals'
            message="CQL array filter: aequals"
        />,]
]

const rowSpansEndpoints = calculateRowSpan(endpoints, 'resource')

function getEndpointRows(rowSpans) {
    return Object.keys(endpoints).map(endpointResource => {
        const endpoint = endpoints[endpointResource];
        return <tr key={endpointResource}>
            <EndpointResource endpoint={endpoint} rowSpans={rowSpans} />
            <td>
                <InlineCodeSnippet>
                    {endpoint.path}
                </InlineCodeSnippet>
            </td>
            <td>{endpoint.description}</td>
        </tr>
    })
}

export function OFADetail() {
    const rowSpans = {...rowSpansEndpoints}
    return <FeatureCheck feature='ngdFeatures'><Document>
        <Header back={true}>OS NGD API – Features: Technical specification</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains the content of the OS National Geographic Database (OS NGD) API – Features, and the
                    methods that you can use to call it.
                    If you are not familiar with the OS NGD API – Features, you may want to read the <InternalLink
                    path={routes.ofaOverview} message="overview documentation" /> before proceeding.
                </p>
                <p>
                    OS NGD API – Features let users access, call and interact directly with the OS National Geographic
                    Database (OS NGD). It exposes the data through an
                    Open Geospatial Consortium API – Feature API.
                </p>
            </Typography>
            <OFAFeatureTypes/>
            <Typography variant='h1' component={'h2'}>Technical Detail</Typography>
            <Typography variant='body1' paragraph={true}>
                OS NGD API – Features requests are structured using keyword-value pair (KVP) parameter encoding, and
                HTTP <InlineCodeSnippet>GET</InlineCodeSnippet>.
                All requests must be encoded correctly according to standard percent-encoding procedures for a URI.
            </Typography>
            <Authentication gettingStartedRoute={routes.ofaIntro}/>
            <Typography variant='h2'>Encryption</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    As of October 2018, all calls must be made using a secure hypertext encryption (HTTPS).
                </p>
                <p>
                    Ordnance Survey APIs no longer support calls made using SSL v2/v3 or TLS v1.0/v1.1.
                    Thus, all calls made to any of our APIs must support TLS v1.2 as of October 2018 in line with
                    current cyber security recommendations.
                </p>
            </Typography>
            <ResponseCodes codes={responseCodes}/>
            <Typography variant='h2'>Endpoints</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <Table>
                    <thead>
                        <tr>
                            <th>Resource</th>
                            <th>Path</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getEndpointRows(rowSpans)}
                    </tbody>
                </Table>
            </Typography>
            <Typography variant='body1' paragraph={true}>
                Freely discover the available data collections without an API key up to a Features endpoint request.
            </Typography>
            <Typography variant='h2'>Coordinate Reference System</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    All data supplied by the OS NGD API – Features will default to WGS84 longitude/latitude (CRS84) unless otherwise
                    specified
                    using the <InlineCodeSnippet>crs</InlineCodeSnippet> parameter.
                </p>
                <p>
                    Other options (if supported by the feature collection) include British National Grid (BNG)
                    EPSG:27700, British National Grid +
                    ODN Height (EPSG: 7405), World Geodetic System (WGS84: EPSG: 4326), Web Mercator (EPSG: 3857).
                </p>
                <Table header={['Parameter', 'Value']} values={crsFilters} />
            </Typography>
            <Typography variant='h2'>Queryables</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    With the OS NGD API – Features, you can filter by attributes to create customised data selections;
                    however, the OGC API – Features standard does not assume that all the attributes of a feature
                    collection are available to construct a filtered query.
                </p>
                <p>
                    For each feature collection, a set of attributes have been defined to build a filtered query; as a
                    result, only attributes identified in the <InlineCodeSnippet>/queryables</InlineCodeSnippet> endpoint for each feature collection are
                    supported when applying attribute filtering.
                </p>
            </Typography>
            <Typography variant='h2'>Filtering</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Any of the parameters listed in the tables below are optional parameters to refine the features
                    returned.
                </p>
            </Typography>
            <Typography variant='h3'>Spatial</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <Table header={filterHeaders} values={spatialFilters}/>
            </Typography>
            <Typography variant='h3'>Temporal</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <Table header={filterHeaders} values={temporalFilters}/>
            </Typography>
            <Typography variant='h3'>Attribute</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <Table header={[
                    'Parameter',
                    'Value'
                ]} values={attributeFilters}/>
            </Typography>
            <Typography variant='body1' paragraph={true} component='div'>Certain symbols that may be present in filters (such as <InlineCodeSnippet>+</InlineCodeSnippet>) must be HTML url encoded before the request is sent.</Typography>
            <Typography variant='h3'>Use of CQL Operators</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The OS NGD API – Features supports a generic filter grammar called Common Query Language (CQL) for
                    specifying enhanced filter criteria to return a subset of features. CQL was created as a text
                    encoding based on the capabilities defined in the OGC Filter Encoding standard, which is more
                    straightforward and more readable.
                </p>
                <p>
                    The following table documents the supported operators.
                </p>
                <Table header={[
                    'Operator Type',
                    'Supported Operators',
                    'Examples',
                ]} values={cqlOperators}/>
            </Typography>
            <DocLinks product='ofa' hasGettingStarted={true} hasDemo={true}/>
        </Content>
    </Document>
    </FeatureCheck>
}

export default withStyles(styles)(OFADetail);
