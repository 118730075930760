import {CommonDialog} from 'omse-components';
import {defineMessages, FormattedMessage} from 'react-intl';
import { Typography } from '@mui/material';
import {createUseStyles} from 'react-jss';
import PropTypes from 'prop-types';
import ProductDependencyMessage from './ProductDependencyMessage';

export const messages = defineMessages({
    title: {
        id: 'PremiumProductDependencyDialog.title',
        defaultMessage: 'Unavailable: Additional product required',
        description: 'Additional licence required'
    },
    unavailable: {
        id: 'PremiumProductDependencyDialog.unavailable',
        defaultMessage: '{productName} is unavailable:',
        description: 'unavailable'
    }
});

const useStyles = createUseStyles(theme => ({
    dialogBody: {
        '& p': {
            margin: theme.spacing(1, 0)
        }
    }
}));

export default function PremiumProductDependencyDialog({productId, productName, products, onClose}) {
    const classes = useStyles();
    if (!Array.isArray(products) || products.length === 0) {
        return null;
    }
    return <CommonDialog onClose={onClose} title={messages.title}>
        <div className={classes.dialogBody}>
            <Typography variant='h2'>
                <FormattedMessage {...messages.unavailable} values={{productName}} />
            </Typography>
            <ProductDependencyMessage productId={productId} productName={productName} products={products} />
        </div>
    </CommonDialog>;
}

PremiumProductDependencyDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    productId: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
    products: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
}