import { isOpenDataPersonalById } from '../../../util/organisation';
import {
    hasManageBillingPermission,
    hasViewCompanyDetailsPermission,
    hasManageUsersPermission,
} from "../../../util/permissions";
import { findRoute } from '../../../util/routes';
import messages from '../MenuMessages';

export const secondaryMenu = [
    {
        label: messages.accountSettings,
        route: findRoute('account')
    },
    {
        label: messages.invite,
        route: findRoute('accountInvitation'),
        orgCheck: (user, orgId) => !isOpenDataPersonalById(user, orgId)
    },
    {
        label: messages.manageTeamMembers,
        route: findRoute('manageTeamMembers'),
        userCheck: hasManageUsersPermission
    },
    {
        label: messages.companyInfo,
        route: findRoute('companyInformation'),
        userCheck: hasViewCompanyDetailsPermission
    },
    {
        label: messages.billingInformation,
        route: findRoute('billing'),
        feature: 'monetisation',
        userCheck: hasManageBillingPermission
    },
    {
        label: messages.payments,
        route: findRoute('payments'),
        feature: 'monetisation',
        userCheck: hasManageBillingPermission
    },
    {
        label: messages.contactPreferences,
        route: findRoute('contactPreferences'),
        feature: 'contactPreferences'
    }
];
