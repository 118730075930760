import { holdingPageCheck } from '../../../../../shared/holdingPageCheck';
import { isOpenDataPersonalById } from '../../../../util/organisation';
import { hasManageBillingPermission, hasManageUsersPermission, hasViewCompanyDetailsPermission } from "../../../../util/permissions";
import { findRoute, logOut } from '../../../../util/routes';
import messages from '../../MenuMessages';

export const getAccountMenu = (userDetails, config) => {
    const holdingPageDisplayed = holdingPageCheck(userDetails, config?.holdingPageUserPlans)

    const secondaryMenuItems = [
        {
            label: {
                id: 'AccountMenu.UserFirstName',
                defaultMessage: userDetails.firstName
            },
            heading: true
        },
        {
            label: messages.logOut,
            action: logOut
        }
    ];

    // When holding page is disabled we can show the whole menu
    if (!holdingPageDisplayed) {
        secondaryMenuItems.splice(1, 0,
            {
                label: messages.accountSettings,
                route: findRoute('account')
            },
            {
                label: messages.invite,
                route: findRoute('accountInvitation'),
                orgCheck: (user, orgId) => !isOpenDataPersonalById(user, orgId)
            },
            {
                label: messages.manageTeamMembers,
                route: findRoute('manageTeamMembers'),
                userCheck: hasManageUsersPermission
            },
            {
                label: messages.companyInfo,
                route: findRoute('companyInformation'),
                userCheck: hasViewCompanyDetailsPermission
            },
            {
                label: messages.billingInformation,
                route: findRoute('billing'),
                feature: "monetisation",
                userCheck: hasManageBillingPermission
            },
            {
                label: messages.payments,
                route: findRoute('payments'),
                feature: 'monetisation',
                userCheck: hasManageBillingPermission
            },
            {
                label: messages.contactPreferences,
                route: findRoute('contactPreferences'),
                feature: 'contactPreferences'
            }
        )
    }

    return {
        label: messages.dataHub, items: secondaryMenuItems
    };
}