import {createRectangleDrawingTool} from "./ol/tool";
import {drawStyle, expandDrawStyle} from "./ol/style";

const ADD_RECTANGLE_TYPE = 'addRectangle';

export default function AddRectangleAction (map, layers) {
    const {drawingLayer, currentPolygonLayer} = layers;

    const tool = createRectangleDrawingTool(drawingLayer, currentPolygonLayer ? expandDrawStyle : drawStyle);
    map.addInteraction(tool);

    return {
        type: ADD_RECTANGLE_TYPE,
        deactivate: () => {
            map.removeInteraction(tool);
        }
    }
}

AddRectangleAction.type = ADD_RECTANGLE_TYPE;
