// Hook that navigates to a new location. This also stores the current scroll position into the current location,
// so that when/if we come back to this location then we restore the scroll position.
import {useCallback} from 'react';
import {useLocation, useHistory} from 'react-router';
import useScrollPosition from "./useScrollPosition";
import {getLocation} from "../util/routes";

export default function useNavigator({path, search, hash, state}) {
    const location = useLocation();
    const history = useHistory();
    const {getScrollPosition, setElement} = useScrollPosition();

    const navigate = useCallback(event => {
        event.preventDefault();
        event.stopPropagation();

        // We are about to navigate. Store the current scroll position in the location state, so that we can come back
        // to it when/if the user uses the back button.
        const updatedState = {
            ...location.state,
            scrollPosition: getScrollPosition()
        };
        const updatedLocation = {
            ...location,
            state: updatedState
        };

        // Prepare to go to the new location. It is important we do this before we alter the current
        // location, as we don't want to re-render and mess up the current scroll position.
        let newState = state;
        if(typeof state === 'function') {
            // We have a state-building function. We are already about to navigate, so now is the right time to
            // call in and get the state that we should use.
            newState = state();
        }
        const newLocation = getLocation(path, location, search, { hash, state: newState });

        // Replace the current location (to save the scroll position) and then navigate
        history.replace(updatedLocation);
        history.push(newLocation);
    }, [getScrollPosition, path, search, hash, state, history, location]);

    return {navigate, setElement};
}