const {USER_PARTNER, USER_PREMIUM_DATA_PLAN} = require("./plans");
const {PART_CATALOGUE} = require("./catalogues");

function isPartnerUser(user) {
    return !!user && user.plan === USER_PREMIUM_DATA_PLAN && user.catalogues && user.catalogues.includes(PART_CATALOGUE);
}

exports.holdingPageCheck = function(user, holdingPageUserPlans = '') {
    const userPlansToShowHoldingPage = holdingPageUserPlans.split(',').map(item => item.trim());
    return typeof user == 'string'
        ? userPlansToShowHoldingPage.includes(user)
        : (!!user && (userPlansToShowHoldingPage.includes(user.plan) || (isPartnerUser(user) && userPlansToShowHoldingPage.includes(USER_PARTNER))));
}
