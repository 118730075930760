import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import routes, {getLocation} from "../../util/routes";
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import ContentHeader from '../../components/ContentHeader';
import {FormattedMessage, defineMessages} from 'react-intl';
import {contentPadding, AddButton, osColour} from 'omse-components';
import {createUseStyles} from 'react-jss';
import Link from '../../components/Link';
import {getOrganisationUsers} from '../../modules/organisation/action';
import {hasManageUsersPermission} from '../../util/permissions';
import TeamMember, {headerMessages} from "./manageTeamMembers/TeamMember";
import {InviteNewMembersDialog} from './manageTeamMembers/InviteNewMembersDialog'

const messages = defineMessages({
    backLink: {
        id: 'ManageTeamMembers.backLink',
        defaultMessage: "Account settings",
        description: "Label for the back link on the manage team members page"
    },
    title: {
        id: 'ManageTeamMembers.title',
        defaultMessage: 'Manage team members',
        description: 'Title for the manage team members page'
    },
    intro: {
        id: 'ManageTeamMembers.intro',
        defaultMessage: 'You can add as many people as you like to your account. You can read about the different roles and the actions they are allowed to perform in the {link}.',
        description: 'Introduction text for the manage team members page'
    },
    linkLabel: {
        id: 'ManageTeamMembers.linkLabel',
        defaultMessage: 'user roles FAQs',
        description: 'Label for link that points to the FAQs'
    },
    inviteTeamMembers: {
        id: 'ManageTeamMembers.inviteTeamMembers',
        defaultMessage: 'Invite team members',
        description: 'Label for Invite team members'
    }
});

const styles = createUseStyles(function (theme) {
    return {
        content: {
            flex: '1 1 auto',
            paddingLeft: contentPadding.left,
            paddingRight: contentPadding.right,
            paddingBottom: contentPadding.bottom,
            maxWidth: contentPadding.maxWidth,
            marginTop: theme.spacing(8),
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile,
                margin: 0
            }
        },
        intro: {
            marginBottom: theme.spacing(2)
        },
        introLink: {
          display: 'inline'
        },
        table: {
            '& thead': {
                color: osColour.primary.berry,
                borderBottom: 0,
                '& th': {
                    textAlign: 'left',
                    padding: theme.spacing(1,2,1,0),
                    fontWeight: 'bold'
                },
                '& th:last-of-type': {
                    textAlign: 'right',
                    paddingRight: 0
                }
            },
            width: '100%',
            border: 'none',
            borderCollapse: 'collapse',
            '& tr': {
                borderBottom: '1px solid ' + osColour.neutral.mist
            },
            '& tr:nth-child(even)': {
                backgroundColor: '#f6f5f9'
            },
            [theme.breakpoints.down('sm')]: {
                '& thead': {
                    display: 'none'
                },
                '&, & tbody, tr': {
                    display: 'block',
                    padding: 0
                }
            }
        },
        inviteTeamMembersStyle:{
            float:'right',
            [theme.breakpoints.down('sm')]: {
                float: 'none'
            }
        }
    };
});

export default function ManageTeamMembers() {
    const classes = styles();
    const history = useHistory();
    const userLoading = useSelector(state => state.user.current.loading);
    const usersLoading = useSelector(state => state.organisation.users.loading);
    const users = useSelector(state => state.organisation.users.result);
    const [showInviteNewMembers, setShowInviteNewMembers] = useState(false);

    const hasPermission = useSelector(state =>
        state.user.current.result &&
        hasManageUsersPermission(state.user.current.result)
    );
    const dispatch = useDispatch();

    // As soon as the component loads, try to get the list of users
    useEffect(function() {
        dispatch(getOrganisationUsers());
    }, [dispatch]);

    if(!userLoading && !hasPermission) {
        // The user should not see this page. Take them back to the main account page.
        const newLocation = getLocation(routes.account, history.location);
        history.replace(newLocation);
    }

    let content;
    if(userLoading || usersLoading) {
        content = <CircularProgress size={32}/>
    } else {
        content = <table className={classes.table}>
            <thead>
                <tr>
                    <Typography variant='body1' component='th'>
                        <FormattedMessage {...headerMessages.user} />
                    </Typography>
                    <Typography variant='body1' component='th'>
                        <FormattedMessage {...headerMessages.role} />
                    </Typography>
                    <Typography variant='body1' component='th'>
                        <FormattedMessage {...headerMessages.actions} />
                    </Typography>
                </tr>
            </thead>
            <tbody>
                { users.map(user => <TeamMember key={user.email} user={user} setShowInviteNewMembers={setShowInviteNewMembers}/>) }
            </tbody>
        </table>;
    }

    return <>
        <ContentHeader
			backPath={routes.account} 
			backLabel={messages.backLink} 
			title={messages.title}
            actions={<AddButton variant="contained"
                color="primary"
                classes={{ button: classes.inviteTeamMembersStyle}}
                label={<FormattedMessage {...messages.inviteTeamMembers}/>}
                action={() => setShowInviteNewMembers(true)} />}
        />
        {showInviteNewMembers && <InviteNewMembersDialog handleClose={() => setShowInviteNewMembers(false)}/>}

        <div className={classes.content}>
            <Typography variant='body1' className={classes.intro} paragraph={true}>
                <FormattedMessage {...messages.intro} values={{
                    link: <Link className={classes.introLink} path={routes.supportPlans} hash={'#roleDifference'}>
                        <FormattedMessage {...messages.linkLabel}/>
                    </Link>
                }}/>
            </Typography>
            {content}
        </div>
    </>;
}
