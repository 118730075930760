import React from 'react';
import {ExpansionList, osColour} from 'omse-components';
import PropTypes from 'prop-types';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles(theme => ({
    root: {
        maxWidth: 'initial',
        marginBottom: theme.spacing(2.5)
    },
    expandSummary: {
        '&.Mui-expanded': {
            borderBottom: osColour.neutral.mist + ' solid 1px'
        }
    }
}));

export default function GettingStartedGuides(props) {
    const {guides} = props;
    const classes = useStyles();
    if (guides.length === 0) {
        return null;
    }
    return <ExpansionList options={guides} showControls={false} classes={classes} />;
}

GettingStartedGuides.propTypes = {
    guides: PropTypes.array.isRequired
}