
const PREFIX = 'action:project:';
export const LOAD_PROJECT_ACTION   = PREFIX + 'load';
export const LOADED_PROJECT_ACTION = PREFIX + 'loaded';
export const ADD_PRODUCT    = PREFIX + 'addProduct';
export const CREATE_PROJECT = PREFIX + 'create';
export const DELETE_PROJECT = PREFIX + 'delete';
export const SET_PROJECT_MODE = PREFIX + 'setMode';
export const SET_PROJECT_NAME = PREFIX + 'setName';
export const REGENERATE_KEY_ACTION = PREFIX + 'regenerateKey';
export const DELETE_PRODUCT = PREFIX + 'deleteProduct';
export const OPEN_NEW_PROJECT_DIALOG = PREFIX + 'new:open';
export const CLOSE_NEW_PROJECT_DIALOG = PREFIX + 'new:close';

export function getProject(projectId) {
    return {
        type: LOAD_PROJECT_ACTION,
        projectId
    };
}

export function addProductToProject(product, project, actionId) {
    return {
        type: ADD_PRODUCT,
        productName: product.name,
        projectId: project.projectId,
        actionId: actionId
    };
}

// When setting this action up, the product name is optional. If it is included then the
// project will include that product when it has been created.
// The action id is also optional. It can be used to keep track of a specific action, rather
// than all actions of the same type.
export function createProject(productName, actionId, projectName) {
    return {
        actionId: actionId,
        type: CREATE_PROJECT,
        productName: productName,
        projectName: projectName
    };
}

export function deleteProject(projectId) {
    return {
        type: DELETE_PROJECT,
        projectId
    };
}

export function setProjectName(id, name) {
    return {
        type: SET_PROJECT_NAME,
        projectId: id,
        name: name
    };
}

export function regenerateKey(id) {
    return {
        type: REGENERATE_KEY_ACTION,
        id
    }
}

export function deleteProduct(projectId, productId) {
    return {
        type: DELETE_PRODUCT,
        productId,
        projectId
    }
}

export function setProjectMode(projectId, mode, actionId) {
    return {
        type: SET_PROJECT_MODE,
        projectId,
        mode,
        actionId
    };
}

export function openNewProjectDialog(componentId, addProduct) {
    return {
        type: OPEN_NEW_PROJECT_DIALOG,
        componentId,
        addProduct
    };
}

export function closeNewProjectDialog() {
    return {
        type: CLOSE_NEW_PROJECT_DIALOG
    };
}