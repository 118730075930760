import React from 'react';
import {InputBox} from "omse-components";
import {useSelector, useDispatch} from "react-redux";
import {updateDraftOrder} from '../../../../modules/dataPackages/actions';
import {defineMessages} from "react-intl";

const messages = defineMessages({
    packageName: {
        id: 'PackageNameInput.packageName',
        defaultMessage: 'Package name',
        description: 'packageName label'
    },
    packageNameNeeded: {
        id: 'PackageNameInput.packageNameNeeded',
        defaultMessage: 'Please give your package a name',
        description: 'packageName needed'
    }
});

export default function PackageNameInput () {

    const packageName = useSelector((state) => state.dataPackages.draftOrder.packageName) || '';
    const displayErrors = useSelector((state) => state.dataPackages.draftOrder.displayErrors);
    const dispatch = useDispatch();

    function handleChange (event) {
        dispatch(updateDraftOrder('packageName', event.target.value));
    }

    return <InputBox id={'packageName'}
                  label={messages.packageName}
                  value={packageName}
                  onChange={handleChange}
                  fullWidth
                  placeholder={'Give your data package a name'}
                  error={displayErrors && packageName.trim().length === 0 && messages.packageNameNeeded}/>
}