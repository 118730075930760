import {createLoadingMiddleware, createLoadingReducer, createActionReducer, createActionMiddleware} from 'omse-components';
import {selectOrganisationReducer, selectOrganisationMiddleware} from './organisation/select';
import {combineReducers} from 'redux';
import {ADMIN_ROLE} from "../../shared/roles";
import {modules} from 'omse-components';
import {
    PREFIX,
    LOAD_ORG_USERS_ACTION,
    LOADED_ORG_USERS_ACTION,
    INVITE_USER,
    CANCEL_INVITE,
    SET_ROLE,
    REVOKE_USER,
    INVITATION_RESPONSE,
    getOrganisationUsers,
    SET_NAME
} from './organisation/action';
import {getNotificationStatus} from "./notifications/actions";

const {LOADED_USER_ACTION} = modules.actions.user;

// To have a stable list of users in the UI, we sort the result once it comes back from the server
function callback(action) {
    const result = action.result;
    if(result) {
        result.sort((a, b) => {
            let result = compareProperty(a, b, 'firstName');
            if(result === 0) {
                result = compareProperty(a, b, 'lastName');
            }
            if(result === 0) {
                result = compareProperty(a, b, 'email');
            }
            return result;
        });

        // Find the count of the admin users, and store that as part of the result
        result.adminCount = result.reduce((adminCount, user) => {
            // Only count real users that are admins
            if(!user.invitedDate && user.role === ADMIN_ROLE) {
                return adminCount + 1;
            }
            return adminCount;
        }, 0)
    }
}
function compareProperty(a, b, prop) {
    const aValue = (a[prop] || '').toUpperCase();
    const bValue = (b[prop] || '').toUpperCase();

    if(aValue < bValue) {
        return -1;
    }
    if(aValue > bValue) {
        return 1
    }
    return 0;
}

export const organisationReducer = combineReducers({
    users: createLoadingReducer(LOAD_ORG_USERS_ACTION, LOADED_ORG_USERS_ACTION, true, callback),
    invite: createActionReducer(INVITE_USER),
    cancelInvitation: createActionReducer(CANCEL_INVITE),
    setRole: createActionReducer(SET_ROLE),
    revokeUser: createActionReducer(REVOKE_USER),
    invitationResponse: createActionReducer(INVITATION_RESPONSE),
    setName: createActionReducer(SET_NAME),
    current: selectOrganisationReducer
});

function prepareCall(action) {
    const skipOpenDataOrgs = action.skipOpenDataOrgs ? "true" : "false";
    const skipOSInternalOrgs = action.skipOSInternalOrgs ? "true" : "false";

    return `/api/organisation/users?skipOpenDataOrgs=${skipOpenDataOrgs}&skipOSInternalOrgs=${skipOSInternalOrgs}`;
}

function setupInvitation(action) {
    return {
        url: '/api/organisation/invite',
        method: 'POST',
        body: {
            email: action.email,
            role: action.role
        }
    };
}

function setupCancelInvitation(action) {
    return {
        url: '/api/organisation/invite/' + action.contactId,
        method: 'DELETE'
    };
}

function setupSetRole(action) {
    return {
        url: '/api/organisation/setRole',
        method: 'POST',
        body: {
            contactId: action.contactId,
            role: action.role,
            sendEmail: action.sendEmail
        }
    }
}

function setupRevokeUser(action) {
    return {
        url: '/api/organisation/revokeUser',
        method: 'PUT',
        body: {
            contactId: action.contactId,
            sendEmail: action.sendEmail
        }
    }
}

function setupInvitationResponse(action) {
    return {
        url: '/api/organisation/invitationResponse',
        method: 'POST',
        body: {
            accept: action.accept
        }
    }
}

function setupSetName(action) {
    return {
        url: '/api/organisation/name',
        method: 'POST',
        body: {
            name: action.name,
            reason: action.reason
        }
    }
}

export const organisationMiddleware = [
    selectOrganisationMiddleware,
    createLoadingMiddleware(LOAD_ORG_USERS_ACTION, LOADED_ORG_USERS_ACTION, prepareCall),
    createActionMiddleware(INVITE_USER, setupInvitation),
    createActionMiddleware(CANCEL_INVITE, setupCancelInvitation),
    createActionMiddleware(SET_ROLE, setupSetRole),
    createActionMiddleware(REVOKE_USER, setupRevokeUser),
    createActionMiddleware(INVITATION_RESPONSE, setupInvitationResponse),
    createActionMiddleware(SET_NAME, setupSetName),

    // Another piece of middleware, to refresh the list of users whenever we perform an organisation action
    // After the user accepts or declines an invitation, simulate a load for the user object,
    // so that the rest of the app knows about the new plan etc.
    store => next => action => {
        const type = action.type;
        if(type.startsWith(INVITATION_RESPONSE) && action.result !== undefined) {
            // Put the new user object into the store, so that the store state is consistent before the
            // original action response is handled.
            store.dispatch({type: LOADED_USER_ACTION, result: action.result});

            // Processing the invitation may have changed the 'read' state of some notifications too, so we need
            // to trigger a new get for the notification count
            store.dispatch(getNotificationStatus());

            // Then complete processing this action
            return next(action);
        } else if(type.startsWith(SET_NAME) && action.result !== undefined) {
            // The response to the set name call is a complete user object, so we need to put that into the store
            store.dispatch({type: LOADED_USER_ACTION, result: action.result});

            return next(action);
        } else if(type.startsWith(PREFIX) && type.indexOf('load') === -1 && action.result !== undefined) {
            next(action);
            // and then refresh the users list
            return store.dispatch(getOrganisationUsers());
        } else {
            return next(action);
        }
    }
];