import {pointerMove} from 'ol/events/condition';
import {Modify, Select} from 'ol/interaction';
import Collection from 'ol/Collection';
import Draw, {createBox} from 'ol/interaction/Draw';

const CTRL_Z = 90;
const ESC = 27;

export function createPolygonDrawingTool(layer, style) {
    let draw = new Draw({
        source: layer.getSource(),
        type: 'Polygon',
        style: style
    });

    addDrawToolUndoKeyStrokes(draw);

    return draw;
}

export function createRectangleDrawingTool(layer, style) {
    let draw = new Draw({
        source: layer.getSource(),
        type: 'Circle',
        geometryFunction: createBox(),
        style: style
    });

    addDrawToolUndoKeyStrokes(draw);

    return draw;
}

function addDrawToolUndoKeyStrokes(draw) {
    function undoKeyStrokes(event) {
        if(event.keyCode === CTRL_Z){
            draw.removeLastPoint();
        } else if (event.keyCode === ESC) {
            draw.abortDrawing();
        }
    }

    draw.on('drawabort', function (event) {
        document.removeEventListener("keydown", undoKeyStrokes, false);
    });

    draw.on('drawend', function (event) {
        document.removeEventListener("keydown", undoKeyStrokes, false);
    });

    draw.on('drawstart', function(evt){
        document.addEventListener("keydown", undoKeyStrokes, false);
    });
}

export function createModifyTool(feature) {
    let modify = new Modify({features: new Collection([feature])});
    return modify;
}

export function createSelectTool(layer, style) {
    return new Select({
        layers: [layer],
        style: style,
        toggleCondition: () => {return false;}
    });
}

export function createHoverTool(layer, features, style) {
    let selectableFeatures = features || layer.getSource().getFeatures();
    return new Select({
        condition: pointerMove,
        layers: [layer],
        filter: (f) => { return selectableFeatures.includes(f)},
        style: style,
    });
}
