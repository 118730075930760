import React from 'react';
import {Box} from '@mui/material';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    root: {
        border: '0',
        clip: 'rect(0 0 0 0)',
        height: '1px',
        overflow: 'hidden',
        padding: '0',
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: '1px',
    }
});

/**
 * This component should be used to display text intended for screen readers. It is more reliable across different
 * screen readers than aria-label.
 */
export default function VisuallyHidden({children}) {
    const classes = useStyles();
    return <Box className={classes.root}>{children}</Box>;
}