import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserStats, USER_STATS_DETAIL_PRODUCT_TYPE } from "../../modules/stats/actions";
import Transactions from "./dashboardContent/Transactions";
import { createUseStyles } from "react-jss";
import { contentPadding, Notification, ExternalLink } from "omse-components";
import Link from "../../components/Link";
import routes, { logInLink } from "../../util/routes";
import { defineMessages, FormattedMessage } from "react-intl";
import { ReactComponent as InfoIcon } from "../../components/icons/info-notification.svg";
import Typography from "@mui/material/Typography";
import WhatNext from "../../components/WhatNext";
import ModeControl from "../../components/ModeControl";
import BannerNotification from "../../components/BannerNotification";
import useMode from "../../hooks/useMode";
import { hasManageProjectModePermission } from "../../util/permissions";
import { USER_PSGA_PLAN, USER_OPEN_DATA_PLAN, CRM_PENDING_PSGA_PLAN } from "../../../shared/plans";
import { isEaiUser } from "../../util/plans";
import Button from "@mui/material/Button";
import { LOCAL_STORAGE_KEYS } from "../../constants/constants";
import { ReactComponent as MaximiseIcon } from "../../components/icons/maximise.svg";

const messages = defineMessages({
    welcome: {
        id: "DashboardContent.weclome",
        defaultMessage:
            "Please {logIn} or {signUp} to get started, or browse the {docs} for an overview of the products that are available.",
        description: "Welcome text when users are not logged in",
    },
    logIn: {
        id: "DashboardContent.logIn",
        defaultMessage: "log in",
        description: "Link text for the log in link",
    },
    signUp: {
        id: "DashboardContent.signUp",
        defaultMessage: "sign up",
        description: "Link text for the sign up link",
    },
    upgradeAdTitle: {
        id: "DashboardContent.upgradeAdTitle",
        defaultMessage: "Upgrade to a Premium plan",
        description: "Title text for the upgrade account ad banner",
    },
    upgradeAdMessage: {
        id: "DashboardContent.upgradeAdMessage",
        defaultMessage: "Access premium products, apps and services with free data every month. ",
        description: "Message text for the upgrade account ad banner",
    },
    documentation: {
        id: "DashboardContent.documentation",
        defaultMessage: "documentation",
        description: "Link text for the documentation link",
    },
    inactiveText: {
        id: "DashboardContent.inactiveText",
        defaultMessage:
            "Your account has been suspended. If you think that this is incorrect, please {link}.",
        description: "Text shown in the dashboard when an inactive user logs in",
    },
});

const styles = createUseStyles((theme) => ({
    content: {
        marginLeft: contentPadding.left,
        marginRight: contentPadding.right,
        maxWidth: contentPadding.maxWidth,
        marginBottom: contentPadding.bottom,
        marginTop: theme.spacing(8),
        [theme.breakpoints.down("sm")]: {
            margin: contentPadding.mobile,
        },
    },
    link: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    icon: {
        width: 20,
        height: 20,
        marginRight: theme.spacing(1),
    },
    ctaButton: {
        display: "flex",
        alignItems: "center",
        gap: "0.6em",
    },
}));

export default function DashboardContent() {
    const userDetails = useSelector((state) => state.user.current.result);
    const dispatch = useDispatch();
    const classes = styles();
    const [mode] = useMode();
    const org = useSelector((state) => state.organisation.current);
    const orgId = org && org.id;
    useEffect(() => {
        if (userDetails && userDetails.active) {
            dispatch(getUserStats(USER_STATS_DETAIL_PRODUCT_TYPE, mode, orgId));
        }
    }, [userDetails, mode, dispatch, orgId]);

    let content = null;
    let modeControl = null;
    if (!userDetails) {
        // The user is not logged in
        content = (
            <Typography variant="body1">
                <FormattedMessage
                    {...messages.welcome}
                    values={{
                        logIn: (
                            <a href={logInLink}>
                                <FormattedMessage {...messages.logIn} />
                            </a>
                        ),
                        signUp: (
                            <Link path={routes.plans}>
                                <FormattedMessage {...messages.signUp} />
                            </Link>
                        ),
                        docs: (
                            <Link path={routes.documentation}>
                                <FormattedMessage {...messages.documentation} />
                            </Link>
                        ),
                    }}
                />
            </Typography>
        );
    } else if (!userDetails.active) {
        content = (
            <Notification variant={"error"} appearance={"inline"}>
                <Typography variant="body1">
                    <FormattedMessage
                        {...messages.inactiveText}
                        values={{ link: <ExternalLink type="support" /> }}
                    />
                </Typography>
            </Notification>
        );
    } else if (
        userDetails.newUser &&
        userDetails.plan !== USER_PSGA_PLAN &&
        !isEaiUser(userDetails)
    ) {
        content = <WhatNext />;
    } else {
        content = (
            <>
                <Transactions mode={mode} />
                <Link className={classes.link} path={routes.history}>
                    <InfoIcon aria-label="info" className={classes.icon} />
                    <FormattedMessage
                        defaultMessage="See API transaction history"
                        description="Label for the API transaction history link"
                        id="DashboardContent.link"
                    />
                </Link>
            </>
        );
        if (hasManageProjectModePermission(userDetails)) {
            modeControl = <ModeControl />;
        }
    }

    const showPremiumAd =
        userDetails &&
        userDetails.plan === USER_OPEN_DATA_PLAN &&
        userDetails.orgPlan !== CRM_PENDING_PSGA_PLAN;

    return (
        <>
            {modeControl}
            <div className={classes.content}>
                {showPremiumAd && (
                    <BannerNotification
                        id={LOCAL_STORAGE_KEYS.dashboardPremiumAd}
                        title={<FormattedMessage {...messages.upgradeAdTitle} />}
                        summary={<FormattedMessage {...messages.upgradeAdMessage} />}
                        buttons={[
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => window.open(routes.plans, "_blank")}
                                className={classes.ctaButton}
                            >
                                Learn More
                                <MaximiseIcon height={20} width={20} alt="Opens in a New Tab" />
                            </Button>,
                        ]}
                    />
                )}
                {content}
            </div>
        </>
    );
}
