export function scrollTo(hash) {
    if (hash && hash.length > 1) {
        const element = document.getElementById(hash.substr(1));
        if (element) {
            element.scrollIntoView();
        }
    }
}

export const scrollHashIntoView = () => {
    scrollTo(window.location.hash);
}

export const scrollToTop = (e) => {
    window.history.pushState(null, null, window.location.href.split('#')[0]);
    e.preventDefault();
    scrollTo(e.target.hash);
};