import React from 'react';
import { createUseStyles } from 'react-jss';
import InfoActionContent, { FIND_PARTNER, EAI } from '../../components/InfoActionContent';
import classNames from 'classnames';

const useStyles = createUseStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center'
    },
    enquiries: {
        maxWidth: 1400,
        display: 'flex',
        '& > div': {
            marginLeft: theme.spacing(1.5),
            marginRight: theme.spacing(1.5)
        },
        '& > div:first-of-type': {
            marginLeft: 0,
            [theme.breakpoints.down('md')]: {
                marginRight: 0,
                marginBottom: theme.spacing(2)
            }
        },
        '& > div:last-of-type': {
            marginRight: 0
        },
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
            '& > div:last-of-type': {
                margin: 0
            }
        }
    }
}));

export default function PlanEnquiries(props) {
    const classes = useStyles(props);
    return <div className={classNames(classes.container, props.classes.container)}>
        <div className={classes.enquiries}>
            <InfoActionContent variant={FIND_PARTNER} />
            <InfoActionContent variant={EAI} />
        </div>
    </div>
};

PlanEnquiries.defaultProps = {
    classes: {}
}