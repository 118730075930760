import {defineMessages} from 'react-intl';
import {generalTermsName, apiTermsName, downloadTermsName} from '../../../shared/constants';

const messages = defineMessages({
    [generalTermsName]: {
        id: 'TermsTitles.dataHubTerms',
        defaultMessage: 'OS Data Hub Terms',
        description: 'OS Data Hub Terms'
    },
    [apiTermsName]: {
        id: 'TermsTitles.apiTerms',
        defaultMessage: 'API Service Terms',
        description: 'API Terms'
    },
    [downloadTermsName]: {
        id: 'TermsTitles.downloadTerms',
        defaultMessage: 'OS Download Terms',
        description: 'OS Download Terms'
    }
});

export default messages;