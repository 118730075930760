import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import { CommonDialog, CommonDialogActions, InputBox, ExternalLink } from 'omse-components';
import {defineMessages, FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {setName} from '../../../modules/organisation/action';
import useActionIdSelector from "../../../hooks/useActionIdSelector";

const messages = defineMessages({
    title: {
        id: 'ChangeNameDialog.title',
        defaultMessage: 'Change organisation name',
        description: 'Title for the change company name dialog'
    },
    submit: {
        id: 'ChangeNameDialog.submit',
        defaultMessage: 'Submit request',
        description: 'Label for the submit button'
    },
    newOrgLabel: {
        id: 'ChangeNameDialog.newOrgLabel',
        defaultMessage: 'New organisation name / Company trading name',
        description: 'Label for the new org name'
    },
    reasonLabel: {
        id: 'ChangeNameDialog.reasonLabel',
        defaultMessage: 'Reason for change',
        description: 'Label for the new org name'
    },
    nameError: {
        id: 'ChangeNameDialog.nameError',
        defaultMessage: 'Please enter a new organisation name',
        description: 'Label for the org name error'
    },
    reasonError: {
        id: 'ChangeNameDialog.reasonError',
        defaultMessage: 'Please provide a valid reason for this change of organisation name',
        description: 'Label for the reason error'
    },
    whatNextHeading: {
        id: 'ChangeNameDialog.whatNextHeading',
        defaultMessage: 'What happens next?',
        description: "Heading for the what's next explanation"
    },
    whatNext: {
        id: 'ChangeNameDialog.whatNext',
        defaultMessage: 'Your request will be sent to our team and reviewed. During this time all members of your organisation with access to the Company Information page will see that this request is in progress.',
        description: "Text for the what's next explanation"
    },
    error: {
        id: 'ChangeNameDialog.error',
        defaultMessage: 'We failed to create the name change request. Please try again later, and if the problem persists then please {link}.',
        description: "Text shown when the name change request fails"
    },
    invalidLength: {
        id: 'ChangeNameDialog.invalidLength',
        defaultMessage: 'Length must be less than 100 characters',
        description: "Text shown when the field is too long"
    }
});

const styles = createUseStyles(theme => ({
    whatNextHeading: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2)
    }
}));

export default function ChangeCompanyNameDialog({onClose}) {
    const orgName = useSelector(state => state.user.current.result.org);
    const [{working, result, error}, dispatch] = useActionIdSelector('organisation.setName');
    const [newName, setNewName] = useState(orgName);
    const [reason, setReason] = useState('');
    const [nameInvalid, setNameInvalid] = useState(false);
    const [reasonInvalid, setReasonInvalid] = useState(false);
    const [nameInvalidMessage, setNameInvalidMessage] = useState(messages.nameError);
    const [reasonInvalidMessage, setReasonInvalidMessage] = useState(messages.reasonError);
    const orgNameRef = useRef(null);
    const classes = styles();

    function confirm() {
        dispatch(setName(newName, reason));
    }

    useEffect(() => {
        if(result) {
            // The action was successful, so we can close the dialog
            onClose();
        }
    }, [result, onClose]);

    const actions = <CommonDialogActions confirmAllowed={!error && newName !== '' && newName !== orgName && reason !== '' && !nameInvalid && !reasonInvalid}
                                         onClose={onClose}
                                         onConfirm={confirm}
                                         working={working}
                                         confirmLabel={messages.submit}
    />;

    function updateName(event) {
        const name = event.target.value;
        setNewName(name);
        validateName(name);
    }
    function updateReason(event) {
        const reason = event.target.value;
        setReason(reason);
        validateReason(reason);
    }

    // We don't want to show red ink on the form until the user starts to interact with it, so we trigger
    // validation of all of the fields after each interaction.
    const exceedsAllowedLength = str => (str.length > 100);

    function validateName(name) {
        const nameLengthError = exceedsAllowedLength(name);
        setNameInvalid(name === '' || name === orgName || nameLengthError);
        setNameInvalidMessage(nameLengthError? messages.invalidLength : messages.nameError);
    }

    function validateReason(reason) {
        const reasonLengthError = exceedsAllowedLength(reason);
        setReasonInvalid(reason === '' || reasonLengthError);
        setReasonInvalidMessage(reasonLengthError? messages.invalidLength : messages.reasonError);
    }

    // We normally use the autofocus prop on the InputBox, but that doesn't work in a dialog. Instead we get a
    // callback when the dialog is open, and then we can send the focus to the org name input component.
    function focus() {
        if(orgNameRef.current) {
            orgNameRef.current.focus();
        }
    }

    return <CommonDialog title={messages.title}
                         actions={actions}
                         onClose={onClose}
                         onEntered={focus}
        error={error && <FormattedMessage {...messages.error} values={{ link: <ExternalLink type='support' /> }} />}
    >
        <InputBox id='newOrganisationName'
                  value={newName}
                  label={messages.newOrgLabel}
                  onChange={updateName}
                  error={nameInvalid && nameInvalidMessage}
                  required={true}
                  inputRef={orgNameRef}
                  fullWidth={true}
                  />
        <InputBox id='reason'
                  value={reason}
                  label={messages.reasonLabel}
                  onChange={updateReason}
                  error={reasonInvalid && reasonInvalidMessage}
                  required={true}
                  multiline={true}
                  fullWidth={true}
                  />
        <Typography variant='h3' className={classes.whatNextHeading}>
            <FormattedMessage {...messages.whatNextHeading}/>
        </Typography>
        <Typography variant='body1'>
            <FormattedMessage {...messages.whatNext}/>
        </Typography>
    </CommonDialog>;
}

ChangeCompanyNameDialog.propTypes = {
    onClose: PropTypes.func.isRequired
}
