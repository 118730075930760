const PREFIX = 'action:premium:';

export const LOAD_PREMIUM_CATALOGUE_ACTION = PREFIX + 'premium_catalogue_load';
export const LOADED_PREMIUM_CATALOGUE_ACTION = PREFIX + 'premium_catalogue_loaded';
export const LOAD_PREMIUM_PRODUCT_ACTION = PREFIX + 'premium_product_load';
export const LOADED_PREMIUM_PRODUCT_ACTION = PREFIX + 'premium_product_loaded';
export const LOAD_PREMIUM_PRODUCT_OPTIONS_ACTION = PREFIX + 'premium_product_options_load';
export const LOADED_PREMIUM_PRODUCT_OPTIONS_ACTION = PREFIX + 'premium_product_options_loaded';
export const LOAD_PREMIUM_LICENSED_PRODUCTS = PREFIX + 'premium_licensed_products_load';
export const LOADED_PREMIUM_LICENSED_PRODUCTS = PREFIX + 'premium_licensed_products_loaded';

export function getPremiumCatalogue() {
    return {
        type: LOAD_PREMIUM_CATALOGUE_ACTION
    }
}

export function getPremiumProduct(productId) {
    return {
        type: LOAD_PREMIUM_PRODUCT_ACTION,
        id: productId
    }
}

export function clearPremiumProduct() {
    return {
        type: LOADED_PREMIUM_PRODUCT_ACTION,
        result: null
    }
}

export function getPremiumProductOptions(productId, options={}) {
    return {
        type: LOAD_PREMIUM_PRODUCT_OPTIONS_ACTION,
        id: productId,
        options: options
    }
}

export function getLicensedProducts() {
    return {
        type: LOAD_PREMIUM_LICENSED_PRODUCTS
    };
}

export function clearLicensedProducts() {
    return {
        type: LOADED_PREMIUM_LICENSED_PRODUCTS,
        result: null
    };
}