import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import Modal from "@mui/material/Modal";
import {osColour} from 'omse-components';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import {FormattedMessage, defineMessages, injectIntl} from 'react-intl';
import SwipeableViews from 'react-swipeable-views';
import {ReactComponent as CloseIcon} from "../../../components/icons/close-large.svg";
import classNames from 'classnames';

const messages = defineMessages({
    next: {
        id: 'DownloadImageModal.next',
        defaultMessage: 'Next',
        description: 'Label for the Next button'
    },
    back: {
        id: 'DownloadImageModal.back',
        defaultMessage: 'Back',
        description: 'Label for the Back button'
    },
})

const styles = {
    closeIcon: {
        color: osColour.neutral.stone
    },
    zoomedImg: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '1px 1px 5px 2px rgba(0, 0, 0, 0.4)',
        outline: 'none',

        // This helps the UI on small screens, including mobile
        maxWidth: '100%',
        maxHeight: '100%',

        // missing controls issue
        '& div:first-child': {
            zIndex: -1,
            position: 'relative'
        },
        '&.error': {
            width: '100%',
            height: '100%'
        }
    },
    zoomedClose: {
        zIndex: 1,
        background: osColour.neutral.white,
        opacity: 0.9,
        height: 64,
        width: 64,
        borderRadius: 32,
        position: 'absolute',
        right: 10,
        top: 10,
        cursor: 'pointer',
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        '&.loaded': {
            display: 'flex'
        },
        '&.error': {
            display: 'flex'
        }
    },
    mobileStepper: {
        display: 'none',
        '&.loaded': {
            display: 'flex'
        }
    }
};

export class DownloadImageModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: props.initialIndex,
            loaded: false,
            error: false
        };
    }

    handleNext = () => {
        const newIndex = this.state.index + 1;
        this.setIndex(newIndex);
    }

    handleBack = () => {
        const newIndex = this.state.index - 1;
        this.setIndex(newIndex);
    }

    setIndex = index => {
        this.setState({index});
    }

    imageLoaded() {
        const {loaded} = this.state;
        if (!loaded) {
            this.setState({loaded: true});
        }
    }

    imageError() {
        const {error, loaded} = this.state;
        if (!loaded && !error) {
            this.setState({error: true});
        }
    }

    render() {
        const {index, loaded, error} = this.state;
        const {images, classes, close, productName} = this.props;

        let itemCount = images.length;

        const nextButton = <Button size="small"
                                   onClick={this.handleNext}
                                   disabled={index === itemCount - 1}
                                   tabIndex={0}
        >
            <FormattedMessage {...messages.next}/>
        </Button>;
        const backButton = <Button size="small"
                                   onClick={this.handleBack}
                                   disabled={index === 0}
                                   tabIndex={0}
        >
            <FormattedMessage {...messages.back}/>
        </Button>;

        const imgArray = [];
        for(let i = 0; i < images.length; i++) {
            const url = images[i].large;
            imgArray.push(
                <img key={i}
                     src={url}
                     alt={`Example of product - full screen modal: ${productName} ${i + 1}`}
                     onLoad={() => this.imageLoaded()}
                     onError={() => this.imageError()}
                />
            );
        }

        return <Modal
            open={true}
            onClose={close}>
            <div className={classNames({[classes.zoomedImg]: true, error})}>
                <button className={classNames({[classes.zoomedClose]: true, loaded, error})}
                      onClick={close}
                      onKeyDown={event => event.key === 'Enter' && close()}
                      aria-label='Close preview modal'
                      tabIndex={0}
                >
                    <CloseIcon width={24}
                               height={24}
                               className={classes.closeIcon}
                               alt=''
                               aria-hidden={true}
                    />
                </button>
                <SwipeableViews containerStyle={{'maxWidth': '1280px'}} // Matches the image width
                                enableMouseEvents={true}
                                index={index}
                                onChangeIndex={this.setIndex}>
                    { imgArray }
                </SwipeableViews>

                {images.length > 1 &&
                <MobileStepper className={classNames({[classes.mobileStepper]: true, loaded})}
                               steps={images.length}
                               variant="dots"
                               activeStep={index}
                               nextButton={nextButton}
                               backButton={backButton}
                />
                }
            </div>
        </Modal>
    }
}

DownloadImageModal.propTypes = {
    classes: PropTypes.object.isRequired,
    images: PropTypes.array.isRequired,
    initialIndex: PropTypes.number.isRequired,
    close: PropTypes.func.isRequired,
    productName: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(DownloadImageModal));
