import React from 'react';
import Header from './Header';
import Content from './Content';
import DocLinks from './DocLinks';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import ResponseCodes from './ResponseCodes';
import styles from './style';
import Authentication from './Authentication';
import ParametersTable from './ParametersTable';
import routes from '../../util/routes';
import Table from './Table';
import Document from './Document';
import MatchStatusCodes from './match/MatchStatusCodes';
import MatchDatasetExplained from './match/MatchDatasetExplained';
import MatchSearchTips from './match/MatchSearchTips';
import TextBox from '../../components/TextBox';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

const matchParameters = [
    [<InlineCodeSnippet>key</InlineCodeSnippet>, 'required', 'string', 'free text', 'The API Key provided by the OS Data Hub.'],
    [<InlineCodeSnippet>query</InlineCodeSnippet>, 'required', 'string', 'free text', 'The free text search parameter.'],
    [<InlineCodeSnippet>format</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>JSON</InlineCodeSnippet><br /><InlineCodeSnippet>XML</InlineCodeSnippet></>, <>The format the response will be returned in. Default: <InlineCodeSnippet>JSON</InlineCodeSnippet>.</>],
    [<InlineCodeSnippet>maxresults</InlineCodeSnippet>, 'optional', 'integer', <><InlineCodeSnippet>1</InlineCodeSnippet> - <InlineCodeSnippet>100</InlineCodeSnippet></>, <>The maximum number of results to return. Default: <InlineCodeSnippet>100</InlineCodeSnippet>.</>],
    [<InlineCodeSnippet>offset</InlineCodeSnippet>, 'optional', 'integer', 'an integer', 'Offset the list of returned results by this amount.'],
    [<InlineCodeSnippet>dataset</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>DPA</InlineCodeSnippet><br /><InlineCodeSnippet>LPI</InlineCodeSnippet></>, <>The dataset to return. Multiple values can be sent, separated by a comma. Default: <InlineCodeSnippet>DPA</InlineCodeSnippet>.</>],
    [<InlineCodeSnippet>lr</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>EN</InlineCodeSnippet><br /><InlineCodeSnippet>CY</InlineCodeSnippet></>, <>Which language of addresses to return. Valid languages are English and Welsh. Default value is both languages. Default: <InlineCodeSnippet>EN, CY</InlineCodeSnippet>.</>],
    [<InlineCodeSnippet>minmatch</InlineCodeSnippet>, 'optional', 'double', <><InlineCodeSnippet>0.1</InlineCodeSnippet> - <InlineCodeSnippet>1.0</InlineCodeSnippet> (inclusive)</>, 'The minimum match score a result has to have to be returned.'],
    [<InlineCodeSnippet>matchprecision</InlineCodeSnippet>, 'optional', 'integer', <><InlineCodeSnippet>1</InlineCodeSnippet> - <InlineCodeSnippet>10</InlineCodeSnippet></>, 'The decimal point position at which the match score value is to be truncated.'],
    [<InlineCodeSnippet>fq</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>CLASSIFICATION_CODE:[code]</InlineCodeSnippet><br /><InlineCodeSnippet>LOGICAL_STATUS_CODE:[code]</InlineCodeSnippet><br /><InlineCodeSnippet>COUNTRY_CODE:[code]</InlineCodeSnippet><br /><InlineCodeSnippet>LPI_LOGICAL_STATUS_CODE:[code]</InlineCodeSnippet></>, <>A filter that allows filtering of results by classification code, logical status code and/or country code. Filtering for classification code will accept wildcard ( <InlineCodeSnippet>*</InlineCodeSnippet> ) searches. Multiple searches can be done on classification code and country code by separating the codes with a space. Multiple filters can be used together by including the parameter multiple times: <InlineCodeSnippet>fq=CLASSIFICATION_CODE:CI CLASSIFICATION_CODE:RD&fq=LOGICAL_STATUS_CODE:6</InlineCodeSnippet></>],
    [<InlineCodeSnippet>output_srs</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>BNG</InlineCodeSnippet><br /><InlineCodeSnippet>EPSG:27700</InlineCodeSnippet><br /><InlineCodeSnippet>WGS84</InlineCodeSnippet><br /><InlineCodeSnippet>EPSG:4326</InlineCodeSnippet><br /><InlineCodeSnippet>EPSG:3857</InlineCodeSnippet><br /><InlineCodeSnippet>EPSG:4258</InlineCodeSnippet></>, <>The intended output spatial reference system. Default: <InlineCodeSnippet>EPSG:27700</InlineCodeSnippet>.</>]
];

function MatchDetail(props) {
    return <Document>
        <Header back={true}>OS Match & Cleanse API: Technical specification</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains the content of the OS Match & Cleanse API, and the methods that you can use to call it.
                </p>
                <p>
                    The OS Match & Cleanse API provides a detailed view of an address and its life cycle. It contains
                    all the records of AddressBase® Premium and AddressBase® Premium – Islands and so provides all the information
                    relating to an address or property from creation to retirement.
                </p>
                <p>
                    It contains local authority, Ordnance Survey and Royal Mail® addresses, current addresses,
                    multilingual and alternatives for current addresses, provisional addresses (such as planning
                    developments) and historic information, plus cross references to the OS MasterMap® TOIDS® and
                    Unique Property Reference Numbers (UPRNs).
                </p>
                <p>
                    OS Match & Cleanse API contains addresses located within the United Kingdom, Jersey, Guernsey and the
                    Isle of Man. For address records in Jersey and Guernsey the coordinates will be <InlineCodeSnippet>0.0</InlineCodeSnippet> as they fall outside of
                    the British National Grid.
                </p>
                <p>
                    Each address returned is given a match score so you can be confident of the matched address based on
                    its likeness to the input text string. Scores are provided from <InlineCodeSnippet>0</InlineCodeSnippet> to <InlineCodeSnippet>1.0</InlineCodeSnippet>, where <InlineCodeSnippet>1.0</InlineCodeSnippet> represents an
                    EXACT match. You can control the precision of the match score in the event of results sharing a
                    close match score.
                </p>
            </Typography>

            <Typography variant='h1' component='h2'>Technical Detail</Typography>
            <Typography variant='body1' paragraph={true}>
                OS Match & Cleanse API is a RESTful API, returning queries in either XML or JSON. The Match resource
                enables you to cleanse full or partial addresses by comparing against AddressBase® Premium and AddressBase® Premium - Islands
                and returning the closest matches. Various parameters are available to help optimise results.
            </Typography>

            <Authentication gettingStartedRoute={routes.matchIntro}/>
            <Typography variant='h2'>Encryption</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    As of October 2018, all calls must be made using a secure hypertext encryption (HTTPS).
                </p>
                <p>
                    Ordnance Survey APIs no longer support calls made using SSL v2/v3 or TLS v1.0/v1.1.
                    Thus, all calls made to any of our APIs must support TLS v1.2 as of 24th October 2018 in line with
                    current cyber security recommendations.
                </p>
            </Typography>
            <ResponseCodes/>

            <Typography variant='h2'>Operation</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    OS Match & Cleanse API has one type of request:
                </p>
                <ul>
                    <li><b>Match</b> for granular matching and cleansing of existing address records.</li>
                </ul>
            </Typography>

            <Typography variant='h3'>Match</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <TextBox
                    text="https://api.os.uk/search/match/v1/match?query=Ordnance%20Survey%2C%20Adanac%20Drive%2C%20SO16&key="
                    label="OS Match & Cleanse API - Match Endpoint"
                    variant="code"
                />
                <ParametersTable classes={props.classes} values={matchParameters}/>
            </Typography>

            <MatchStatusCodes />
            <MatchDatasetExplained />

            <Typography variant='h4'>Example searches and their match scores</Typography>
            <Typography variant='body1' paragraph={true} component={'div'}>
                <p>
                    If an exact match isn’t found, then the results will be returned in an order based on their match
                    scores. The match score can help you select the most appropriate address record. These example
                    searches show the affect that different search terms and the use of different parameters can have on
                    the match score of the top result returned:
                </p>
                <Table header={['Query', 'Match scores']} values={[
                    [<InlineCodeSnippet>query=ORDNANCE SURVEY, 4, ADANAC DRIVE, NURSLING, SOUTHAMPTON, SO16 0AS</InlineCodeSnippet>, <InlineCodeSnippet>"MATCH": 1.0,<br />"MATCH_DESCRIPTION": "EXACT"</InlineCodeSnippet>],
                    [<InlineCodeSnippet>query=ORDNANCE SURVEY, ADANAC DRIVE, SOUTHAMPTON, SO16 0AS</InlineCodeSnippet>, <InlineCodeSnippet>"MATCH": 0.9,<br />"MATCH_DESCRIPTION": "GOOD"</InlineCodeSnippet>],
                    [<InlineCodeSnippet>query=ORDNANCE SURVEY, ADANAC DRIVE, SO16 0AS&matchprecision=2</InlineCodeSnippet>, <InlineCodeSnippet>"MATCH": 0.89,<br />"MATCH_DESCRIPTION": "GOOD"</InlineCodeSnippet>],
                    [<InlineCodeSnippet>query=ORDNANCE SURVEY, ADANAC DRIVE, SO16 0AS&matchprecision=2&minmatch=0.9</InlineCodeSnippet>, 'No results'],
                ]} />
            </Typography>

            <MatchSearchTips />

            <DocLinks product='match' hasGettingStarted={true} hasDemo={false}/>
        </Content>
    </Document>
}

export default withStyles(styles)(MatchDetail);