import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { Route, Switch, withRouter, matchPath } from "react-router";
import { routes } from '../util/routes';
import Menu from './appContent/Menu';
import Landing from './Landing';
import Footer from "./Footer";
import Error404 from "./Error404";
import ResponsiveNavigation from './appContent/ResponsiveNavigation';
import { headerHeight } from 'omse-components';
import HoldingPage from './holding/HoldingPage';
import { holdingPageCheck } from "../../shared/holdingPageCheck";

const styles = {
    root: {
        position: 'relative',
        flex: '1 1 auto',
        display: 'flex',
        minHeight: `calc(100vh - ${headerHeight}px)`,
    },
    content: {
        flex: '1 1 auto',
        overflowY: 'auto',
        '-webkit-overflow-scrolling': 'touch',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
};

export class AppContent extends Component {

    contentRef = React.createRef();
    accessibleContentSkip = React.createRef();

    componentDidMount() {
        if (window.location.href.includes('#main-content')) {
            this.accessibleContentSkip.current.focus();
        }
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;

        if (this.contentRef.current && prevProps.location.pathname !== location.pathname) {
            const scrollPosition = location.state && location.state.scrollPosition;
            const desiredAppContentPosition = (scrollPosition && scrollPosition.currentElement) || 0;
            const desiredHTMLPosition = (scrollPosition && scrollPosition.htmlElement) || 0;
            this.contentRef.current.scrollTop = desiredAppContentPosition;
            document.children[0].scrollTop = desiredHTMLPosition;
        }

    }

    render() {
        const { classes, loading, loggedIn, active, location, userDetails, headerRef, config } = this.props;

        if (loading) {
            // Lock the user down to the dashboard, for now
            return (
                <div className={classes.root} id='main-content' ref={this.accessibleContentSkip} tabIndex='-1'>
                    <main className={classes.content}>
                        <Landing />
                    </main>
                </div>
            )
        }

        let validRoutes = routes;
        if (!loggedIn || !active) {
            // Lock the user down to the public pages
            validRoutes = validRoutes.filter(route => route.public);
        }

        let showMenu = false;
        if (location) {
            // Use the complete routes list to decide if we should show the menu. This way if the user is not
            // logged in then they still get the same page layout and menus that they would normally see.
            const match = routes.find(r => {
                // match the path to valid routes, allow partial matches
                const match = matchPath(location.pathname, {
                    path: r.path,
                    exact: true
                });
                return match;
            });
            showMenu = match && !match.hideMenu;
        }

        if (holdingPageCheck(userDetails, config?.holdingPageUserPlans)) {
            return (
                <div className={classes.root} id='main-content' ref={this.accessibleContentSkip} tabIndex='-1'>
                    <ResponsiveNavigation headerRef={headerRef} />
                    <main className={classes.content} ref={this.contentRef}>
                        <HoldingPage />
                        <Footer />
                    </main>
                </div>
            )
        }

        return (
            <div className={classes.root} id='main-content' ref={this.accessibleContentSkip} tabIndex='-1'>
                <ResponsiveNavigation headerRef={headerRef} />
                {showMenu &&
                    <Menu headerRef={headerRef} />
                }
                <main className={classes.content} ref={this.contentRef}>
                    <Switch>
                        {
                            validRoutes.map(route => <Route key={route.path}
                                exact path={route.path}
                                component={route.component} />)
                        }
                        <Route path='/' component={Error404} />
                    </Switch>
                    <Footer />
                </main>
            </div>
        )
    }
}

AppContent.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    active: PropTypes.bool,
    userDetails: PropTypes.object
};

function mapStateToProps(state) {
    const { loading, result } = state.user.current;
    const configLoading = state.config.current.loading;
    const config = state.config.current.result;
    return {
        loading: loading || configLoading,
        loggedIn: !!result,
        active: result && result.active,
        userDetails: result,
        config: config
    }
}

const styled = withStyles(styles)(AppContent);
const connected = connect(mapStateToProps)(styled);
export default withRouter(connected);
