import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Typography} from '@mui/material';
import routes from '../../util/routes';
import Link from "../../components/Link";
import useHeading from "../../hooks/useHeading";
import { StyledErrorPage } from "../../util/ErrorPageStyles";
import {ReactComponent as Icon} from './follow_route.svg';

const messages = defineMessages({
    title: {
        id: 'MissingPermission.title',
        defaultMessage: 'You do not have permission to access this page',
        description: 'Title for the missing permission page'
    },
    intro: {
        id: 'MissingPermission.contentIntro',
        defaultMessage: 'To access this page you need extra permissions.',
        description: 'Intro for the missing permission page'
    },
    content: {
        id: 'MissingPermission.content',
        defaultMessage: "Please contact your organisation's administrator for more information. Meanwhile, you can {link}.",
        description: 'Content for the missing permission page'
    },
    dashboard: {
        id: 'MissingPermission.dashboard',
        defaultMessage: 'return to the dashboard',
        description: 'Text for the button that takes the user to the dashboard'
    }
});

export default function MissingPermission() {
    const title = useHeading() || messages.title;

    return <StyledErrorPage>
        <Typography variant='h1' color='primary' className={"content header"}>
            <FormattedMessage {...title} />
        </Typography>
        <div className={"content body"}>
            <Icon aria-hidden = {true}/>
            <Typography variant='h2' component='h2' paragraph={true}>
                <FormattedMessage {...messages.intro}/>
            </Typography>
            <Typography variant='body1' className='bodyText'>
                <FormattedMessage {...messages.content} values={{
                    link: <Link path={routes.dashboard}>
                        <FormattedMessage {...messages.dashboard}/>
                    </Link>
                }}/>
            </Typography>
        </div>
    </StyledErrorPage>;
}