import React, {Component} from 'react';
import {defineMessages} from 'react-intl';
import CompletionSummary from '../CompletionSummary';
import PropTypes from 'prop-types';

export const messages = defineMessages({
    achievement1: {
        id: 'OTACompletionSummary.achievement1',
        defaultMessage: `You've obtained the URL and generated an API key for OS NGD API – Tiles.`,
        description: 'Achievement 1'
    },
    achievement2: {
        id: 'OTACompletionSummary.achievement2',
        defaultMessage: `You've accessed the OS NGD API – Tiles interactive code examples.`,
        description: 'Achievement 2'
    },
    achievement3: {
        id: 'OTACompletionSummary.achievement3',
        defaultMessage: `You’ve generated a web map and have applied your API key in order to interact with the data.`,
        description: 'Achievement 3'
    }
});

export class OTACompletionSummary extends Component {
    render() {
        const {software, achievements} = this.props;
        return <CompletionSummary achievements={achievements} software={software} api={`ota`} />
    }
}

OTACompletionSummary.propTypes = {
    achievements: PropTypes.arrayOf(PropTypes.object),
    software: PropTypes.string.isRequired
}

OTACompletionSummary.defaultProps = {
    achievements: [
        messages.achievement1,
        messages.achievement2,
        messages.achievement3
    ]
}

export default OTACompletionSummary;