import React from 'react';
import PropTypes from 'prop-types';
import Table from './Table';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

export default function TypesTable(props) {
    const {values} = props;
    return <Table>
        <tbody>
        <tr>
            <th>Type</th>
            <th>local_type</th>
        </tr>
        {values.map((parameter, index) =>
            <tr key={index}>
                <td>
                    <InlineCodeSnippet>
                        {parameter[0]}
                    </InlineCodeSnippet>
                </td>
                <td>
                    <InlineCodeSnippet>
                        {parameter[1]}
                    </InlineCodeSnippet>
                </td>
            </tr>
        )
        }
        </tbody>
    </Table>;
}

TypesTable.propTypes = {
    values: PropTypes.array.isRequired
}