import React from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import {Typography} from "@mui/material";
import {InlineDialog} from "omse-components"
import routes from '../../../../../util/routes';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {withTermsActionCheck} from '../../../../../util/terms';
import apiTermsName from '../../../../../../shared/terms'
import { openNewProjectDialog, closeNewProjectDialog } from '../../../../../modules/project/actions';
import NameProjectDialog from "../../../../../components/createProjectButton/NameProjectDialog";
import useProjectsLimit from "../../../../../hooks/useProjectsLimit";
import ProjectLimitDialog from "../../../../../components/createProjectButton/ProjectLimitDialog";

const messages = defineMessages({
    title:{
        id:"AddProjectDialog.title",
        defaultMessage: "Welcome to your API plan",
        description:"This is the title of the dialog box"
    },
    body1: {
        id:"AddProjectDialog.body1",
        defaultMessage: "It looks like you don't currently have an API project setup.",
        description: "Body text for dialog"
    },
    body2:{
        id:"AddProjectDialog.body2",
        defaultMessage: "To get started view our documentation or setup a new project.",
        description: "Body text for dialog"
    },
    cancel:{
        id:"AddProjectDialog.cancelButton",
        defaultMessage: "View API Documentation",
        description: "This is the cancel button on the dialog box"
    },
    confirm: {
        id:"AddProjectDialog.confirm",
        defaultMessage: "Setup a new project",
        description: "This is the confirm button on the dialog box"
    }
});

export default function AddProjectDialog(props) {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const componentId = "AddProjectDialog"
    const user = useSelector(state => state.user.current.result);
    const org = useSelector(state => state.organisation.current);
    const projectDialogId = useSelector(state => state.project.newProject.dialogId);
    const projectDialogOpen = useSelector(state => state.project.newProject.open) && (projectDialogId === componentId);
    const [availableProjects, initialising] = useProjectsLimit();

    const closeHandler=()=>{
        goTo(routes.documentation)
    }
    const confirmHandler=()=>{
        if(!initialising){
            dispatch(withTermsActionCheck(apiTermsName, openNewProjectDialog(componentId), user, org));
        } else {
            goTo(routes.projects);
        }
    }
    const handleNameProjectClose = ()=>{
        dispatch(closeNewProjectDialog());
    }
    const goTo=(route)=>{
        history.push(route)
    }

    return (
        <InlineDialog title={messages.title}
            closeLabel={intl.formatMessage(messages.cancel)}
            onClose={closeHandler}
            confirmLabel={intl.formatMessage(messages.confirm)}
            onConfirm={confirmHandler}
        >
            <Typography variant="body2">
                <FormattedMessage {...messages.body1}/>
            </Typography>
            <Typography variant="body1">
                <FormattedMessage {...messages.body2}/>
            </Typography>
            {projectDialogOpen &&
                <>
                    {availableProjects?
                        <NameProjectDialog handleClose={handleNameProjectClose}/> :
                        <ProjectLimitDialog handleClose={handleNameProjectClose}/>
                    }
                </>
            }
        </InlineDialog>
    )
}
