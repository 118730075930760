import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjects } from "../../../modules/projects";
import { USER_STATS_DETAIL_PROJECT, getUserStats } from "../../../modules/stats/actions";
import { osColour } from "omse-components";
import { graphPlanStyles } from "../../../components/barGraph/styles/graph";
import PropTypes from 'prop-types';
import ProjectListEntry from "../../apiProjects/projectList/ProjectListEntry";
import OverallUserUsage from "../../../components/OverallUserUsage";
import styled from "@emotion/styled";
import useMode from "../../../hooks/useMode";
import RecentItemsLoading from "./RecentItemsLoading";
import RecentItemsEmpty from "./RecentItemsEmpty";
import apiSVG from '../../../components/icons/api-large.svg';
import { apiProjectMessages as messages } from "../emptyState/messages";
import CreateProjectButton from "../../../components/CreateProjectButton";
import { apiProjectsMessages } from "./messages";
import { findRoute } from "../../../util/routes";
import Accordion from "../../../components/Accordion";
import { InternalLink } from "omse-components";
import RecentItemsActions from "./RecentItemsActions";
import RecentItemsError from "./RecentItemsError";
import { hasManageProjectsPermission } from "../../../util/permissions";

const StyledProjectList = styled.ul`
    padding: 0;
    margin: 0;
`;

const StyledProjectListEntry = styled(ProjectListEntry)(({ theme }) => `
    margin-bottom: 0;
    padding-top: ${theme.spacing(1.5)};
    padding-bottom: ${theme.spacing(1.5)};
    border-top: 0;    
    border-bottom: solid 1px #d8d8d8;
    ${theme.breakpoints.down('sm')} {
        padding-bottom: ${theme.spacing(1.5)};
    }
    & .linkStyle {
        font-size: 1rem; 
        font-weight: 600;
        line-height: 1.5;
    }
    & .listHeader {
        margin-bottom: 0;
    }
    & .created {
        margin-top: 0;
    }
    & .created .createdLabel {
        color: ${osColour.neutral.charcoal};
    }
    & .created .createdDate {
        font-weight: 600;
    }
    & .usageContainer > div {
        margin-bottom: 0 !important;
    }
    & .usageContainer .graphHeader {
        margin-bottom: ${theme.spacing(1)};
    }
    & .usageContainer .graphStats .totals .countInfo .counterText {
        font-size: 1rem;
        font-weight: 600;
        font-family: Source Sans Pro, sans-serif;
    }
    & .usageContainer .graphStats .totals .countInfo .countLabel {
        color: ${osColour.neutral.charcoal};
    }
    & .graph.displayCount {
        margin-top: 0 !important;
    }
    & div.graph {
        background: transparent !important;
        border-radius: 0;
        border: 0;
        height: 10px !important;
        outline: solid 1px #c4c4c4;
        & .graphData { 
            border-radius: 0;
        }
        ${graphPlanStyles}
    }
`);

const StyledProjectListInfoActions = styled.div(({theme}) => `
    margin: 0;
    padding: 1em 0 0 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5em;
    ${theme.breakpoints.down("sm")} {
        justify-content: space-between;
    }
`);

const StyledOverallUserUsage = styled(OverallUserUsage)(({ theme }) => `    
    & .chartKey {
        margin-top: 0;
        gap: 1em;
        flex-wrap: wrap;
    }
    & .chartKey .keyItem {
        margin-bottom: 0;
        margin-right: 0;
    }
    & .chartKey .keyItem p {
        font-size: 0.875rem;
        color: ${osColour.neutral.charcoal};
    }
    & .chartKey .keyItem .graphKey {
        width: 12px;
        height: 12px;
        margin-right: ${theme.spacing(1)};
    }
`);

const StyledRecentItemsActions = styled(RecentItemsActions)`
    padding: 0;
    border-top: 0;
    flex-grow: 1;
    flex-wrap: wrap;
`;

const APIProjects = ({ openByDefault }) => {
    const userDetails = useSelector(state => state.user.current.result);
    const projects = useSelector(state => state.projects.current.result);
    const projectsLoading = useSelector(state => state.projects.current.loading);
    const projectsError = useSelector(state => state.projects.current.error);
    const userStats = useSelector(state => state.stats.user);
    const orgId = useSelector(state => state.organisation.current.id);
    const dispatch = useDispatch();
    const [mode] = useMode();

    const routeForApiProjects = findRoute("projects");
    const projectListIsEmpty = !projects || projects.length === 0;
    const showContentBorder = Boolean(projectsLoading || projectsError || projectListIsEmpty);
    const sortByCreated = (a, b) => b.createdAt - a.createdAt;

    useEffect(() => {
        if(!projects) {
            dispatch(getProjects());
        }
    }, [dispatch, projects]);

    useEffect(() => {
        if(!userStats) {
            dispatch(getUserStats(USER_STATS_DETAIL_PROJECT, mode, orgId, true));
        }
    }, [dispatch, orgId, mode, userStats]);
    return (
        <Accordion
            title={apiProjectsMessages.title}
            openByDefault={openByDefault}
            showContentBorder={showContentBorder}
        >
            <>
                {projectsLoading && <RecentItemsLoading />}
                {!projectsLoading &&
                    <>
                        {projectsError ?
                            <RecentItemsError errorName={apiProjectsMessages.errorName.defaultMessage} />
                            : 
                            <>
                                {projectListIsEmpty ?
                                    <RecentItemsEmpty
                                        message={messages.emptyMessage}
                                        icon={apiSVG}
                                        iconWidth={100}
                                        iconAlt="API Projects icon"
                                        actionButton={hasManageProjectsPermission(userDetails) ?
                                            <CreateProjectButton label={messages.createProjectButton} showIcon={false} componentId='Workspace-APIProjects-EmptyState' /> : null
                                        }
                                    />
                                    : 
                                    <>
                                        <StyledProjectList>
                                            {projects.sort(sortByCreated).filter((item, i) => i < 5).map(project =>
                                                <StyledProjectListEntry key={project.projectId}
                                                    project={project} userStatsResult={userStats.result} />)
                                            }
                                        </StyledProjectList>
                                        <StyledProjectListInfoActions>
                                            <StyledOverallUserUsage userStatsResult={userStats.result} keyOnly={true} />
                                            <StyledRecentItemsActions>
                                                <InternalLink
                                                    path={routeForApiProjects.path}
                                                    message={apiProjectsMessages.viewMore}
                                                />
                                                {hasManageProjectsPermission(userDetails) && 
                                                    <CreateProjectButton label={apiProjectsMessages.addNew} showIcon={false} componentId='Workspace-APIProjects' />
                                                }
                                            </StyledRecentItemsActions>
                                        </StyledProjectListInfoActions>
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </>
        </Accordion>
    );
}

APIProjects.defaultProps = {
    openByDefault: false
};

APIProjects.propTypes = {
    openByDefault: PropTypes.bool
};

export default APIProjects;