import React, { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import { osColour } from 'omse-components';
import WFSCompletionSummary, { messages as wfsCompletionMessages } from './WFSCompletionSummary';
import PropTypes from 'prop-types';
import Link from '../../../components/Link';
import routes from '../../../util/routes';
import mapInfo1WFSServerInfo from './MapInfoSIS/map-info-1-WFS-Server-Information.png';
import mapInfo2WFSLayerSelection from './MapInfoSIS/map-info-2-WFS-Layer-Selection.png';
import mapInfo3PickRowWFSFilter from './MapInfoSIS/map-info-3-Pick-Row-WFS-Filter.png';
import { defineMessages } from 'react-intl';
import InlineCodeSnippet from '../../../components/InlineCodeSnippet';

export const messages = defineMessages({
    achievement2: {
        id: 'WFSMapInfoSIS.achievement2',
        defaultMessage: `You’ve used the URL and API key to apply an OS Features API layer in MapInfo Pro.`,
        description: 'Achievement 2'
    },
    achievement3: {
        id: 'WFSMapInfoSIS.achievement3',
        defaultMessage: `You’ve applied a query and limited the features to your area of interest.`,
        description: 'Achievement 3'
    }
});

const styles = {
    guide: {
        '& img': {
            border: `solid ${osColour.neutral.mist} 1px`
        }
    }
};

export function MapInfoSIS(props) {
    const { classes } = props;
    return <Fragment>
        <Typography variant='body1' paragraph={true} component='div' className={classes.guide}>
            <p>For the purposes of this guide, the version of MapInfo Pro used is 2021 (64-bit).</p>
        </Typography>
        <Typography variant='h2'>
                Integrating OS Features API in MapInfo Pro
        </Typography>
        <Typography variant='body1' paragraph={true} component='div' className={classes.guide}>
            <ol>
                <li>
                    <p>
                        Launch MapInfo Pro. If working with an empty workspace, then it’s best to load a base map to help you with navigating to features.
                        For this guide, we'll use OS Maps API as the base map. Instructions on how to load OS Maps API into MapInfo Pro can be found in the <Link path={routes.wmtsIntro}>Getting Started Guide for OS Maps API.</Link>
                    </p>
                </li>
                <li>
                    <p>With OS Maps API loaded as the base map, zoom to an area of interest that you'd like to use.</p>
                </li>
                <li>
                    <p>
                        Navigate to the ‘Home’ tab and select the drop-down arrow under the ‘Open’ button to reveal ‘Web Services’.
                    </p>
                </li>
                <li>
                    <p>
                        Select ‘Feature (WFS)’ to open the WFS Table configuration window.
                    </p>
                </li>
                <li>
                    <p>
                        Click on the ‘Servers…’ button to open the WFS Servers List window. From here, you have the option of adding new WFS feeds. Click on the ‘Add…’ button.
                    </p>
                </li>
                <li>
                    <p>
                        In the new WFS Server Information window that appears, you'll need to insert the OS Features API URL, which includes the key, from the OS Data Hub into the Server URL box. Whilst you're under no obligation to include a description, it may help with identification if you provide a name in the Description box. Click ‘OK’.
                    </p>
                    <img src={mapInfo1WFSServerInfo} alt='WFS Server Information window in MapInfo Pro.' />
                </li>
                <li>
                    <p>When back in the WFS Servers List configuration window, select the new connection you've just made and click ‘OK’.</p>
                </li>
                <li>
                    <p>
                        Back in the WFS Table window, you'll now see that the OS Features API service has been added and a list of vector feature layers is available:
                    </p>
                    <img src={mapInfo2WFSLayerSelection} alt='WFS Layers window in MapInfo Pro listing the vector feature layers available for selection.' />
                </li>
                <li>
                    <p>Select the vector feature layer that you require. Make sure the following settings are now applied:</p>
                    <ol type="a">
                        <li>Max. Features is unticked</li>
                        <li>‘Enable feature paging’ tick box is ticked, under the ‘Paging’ section </li>
                        <li>Page size is <InlineCodeSnippet>100</InlineCodeSnippet></li>
                    </ol>
                </li>
                <li>
                    <p>
                        It's recommended at this stage that you also apply a filter to the vector feature layer to ensure you keep within the feature limit thresholds that are applied to the OS Features API. As such, click on the ‘Row Filter…’ button.
                    </p>
                </li>
                <li>
                    <p>
                        In the new Row Picker window that appears, you'll be presented with a ‘Column’ drop-down to pick the field to use as a filter. There are various filters that may be available (or suitable) but at a minimum, it's recommended to use the ‘SHAPE’ column and the ‘BBOX’ operator. This ensures features will be returned only within the bounding box of the zoomed in base map from Step 2. Click ‘OK’.
                    </p>
                    <img src={mapInfo3PickRowWFSFilter} alt='Row Picker window in MapInfo Pro.' />
                </li>
                <li>
                    <p>Back in the WFS Table window, you may now click ‘OK’ to add to the layer to the map.</p>
                </li>
                <li>
                    <p>You should now have the features loaded as a separate layer, on top of your base map.</p>
                </li>
            </ol>
        </Typography>
        <WFSCompletionSummary software="Cadcorp SIS" achievements={[
            wfsCompletionMessages.achievement1,
            messages.achievement2,
            messages.achievement3
        ]} />
    </Fragment>
}

MapInfoSIS.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string
}

export default withStyles(styles)(MapInfoSIS);
