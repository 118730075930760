import { Fragment } from 'react';
import classNames from 'classnames';
import Typography from "@mui/material/Typography";
import styled from '@emotion/styled';
import { osColour } from 'omse-components';
import { graphPlanStyles, keyStyles } from './barGraph/styles/graph';

const StyledChartKey = styled.div(({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    margin-top: ${theme.spacing(2)};
    padding-left: 1px;
    & .keyItem {
        display: flex;
        margin-bottom: ${theme.spacing(1)};
        margin-right: ${theme.spacing(3)};
        align-items: center;
        &:last-of-type {
            margin-right: 0;
        }
        & p {
            color: ${osColour.neutral.stone};
        }
    }
    ${keyStyles(theme)}
    ${graphPlanStyles}
`);

const GraphKey = ({ graphData }) => (
    <>
        {graphData.map((data, index) => (
            <StyledChartKey className='chartKey' key={'key' + index}>
                {data.breakdown.map(item => (
                    <Fragment key={item.name}>
                        {(item.data > 0) &&
                            <div className={'keyItem'}>
                                <span className={classNames('graphKey', item.class)}></span>
                                <Typography key={item.name} variant='body2'>
                                    {item.name}
                                </Typography>
                            </div>
                        }
                    </Fragment>
                ))}
            </StyledChartKey>
        ))}
    </>
);

export default GraphKey;