import {HISTORY_STATS, HISTORY_STATS_LOADED} from "./actions";
import {createLoadingReducer} from 'omse-components';
import {setupMiddleware} from "./statsMiddleware";

export const reducer = createLoadingReducer(HISTORY_STATS, HISTORY_STATS_LOADED, false, copyAttrs);

// When the stats are returned we only want to display them if they reflect the correct mode, so we copy the
// mode from the request over to the result.
function copyAttrs(action) {
    if(action.result) {
        action.result.mode = action.originalAction.mode;
        action.result.orgId = action.originalAction.orgId;
    }
}

function prepareCall(action, store) {
    let result = '/api/stats/history?interval=' + action.interval;
    if(action.projectId) {
        result += '&projectId=' + action.projectId;
    }
    if(action.mode) {
        result += '&mode=' + action.mode;
    }
    if(action.orgId) {
        result += '&orgId=' + action.orgId;
    }
    return result;
}

export const middleware = setupMiddleware(HISTORY_STATS, HISTORY_STATS_LOADED, prepareCall);