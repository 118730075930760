import {theme} from 'omse-components';

export const styles = {
    mainContainer: {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'left',
            alignItems: 'baseline',

            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flex: '0 0 auto',
                flexDirection: 'column',

                '& div:nth-child(n+2)': {
                    marginTop: theme.spacing(3),
                },
            },
        },
    
    //Used for MinMax.js
    stackedContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    
    oneItem: {
            maxWidth: 900,
    },

    twoItems: {
        '& > div': {
            maxWidth: 350,
            marginRight: theme.spacing(11),
        },
    },

    threeItems: {
        '& > div': {
            maxWidth: 225,
            marginRight: theme.spacing(3),
            marginTop: theme.spacing(3),
        }
    },

    eightItems: {
        '& > div': {
            maxWidth: 225,
            minWidth: 220,
            flex: '1 0 20%',
        }
    },

    //Used for Helpful links in BrandImportance.js
    list: {
        '& li': {
            marginBottom: theme.spacing(2),
        }
    }
};