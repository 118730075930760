// Helper to set up a config object that we can use with react-awesome-query-builder components

import FeatureQueryDatePicker from "./FeatureQueryDatePicker";
import {MuiConfig, Utils} from '@react-awesome-query-builder/mui';
import buildFields from "./buildFields";
import {defineMessages, FormattedMessage} from 'react-intl';
import {Typography} from '@mui/material';
import NumberWidget from "./NumberWidget";

const messages = defineMessages({
    betweenError: {
        id: 'buildConfig.betweenError',
        defaultMessage: 'Invalid range',
        description: 'Text for invalid range error'
    }
});

// We disable the built-in MaterialConfig renderProvider, as it relies on a different version of the DatePicker,
// and anyway, we have all the Material UI config that we need higher up in the app. We still pop in a div with
// the .mui class on it, as there are styles for that in QueryBuilder.css.
function renderProvider({children}) {
    return <div className="mui">{children}</div>;
}

function renderRuleError({error}) {
    let errorContent = error;
    try {
        const {message, values} = JSON.parse(error);
        errorContent = <FormattedMessage  {...message} values={values}/>
    } catch (e) {
        // Do nothing if error is not JSON
    }
        return <Typography variant='body2' color='error' role="alert">
            {errorContent}
        </Typography>;
}

// We need to override the validation logic for between because the
// backend doesn't deal with betweens for the same value ie. between 1 and 1
function betweenValidation(values){
    if (values[0] !== undefined && values[1] !== undefined) {
        return values[0] < values[1] ? null : JSON.stringify({message: messages.betweenError, values: []});
    }
    return null;
};

// This is the custom config builder our Implementation of react-awesome-query-builder
// For an in depth list of config values see https://github.com/ukrbublik/react-awesome-query-builder/blob/master/CONFIG.adoc
export default function buildConfig(featureTypeSchema) {
    const config = { ...MuiConfig };

    // Behaviour settings
    config.settings.maxNesting =  2;
    config.settings.showErrorMessage = true;
    config.settings.showNot = false;
    config.settings.canRegroup = false;
    config.settings.canReorder = false;

    // Render settings
    config.settings.renderProvider = renderProvider;
    config.settings.renderRuleError = renderRuleError;

    // Widget settings
    config.widgets.date.factory = FeatureQueryDatePicker;
    config.widgets.number.factory = NumberWidget;

    // Field settings
    config.fields = buildFields(featureTypeSchema)

    //Operator settings
    setUpOperator(config, 'equal', {label : '='});
    setUpOperator(config, 'not_equal', {label:'Not Equal',sqlOp : '<>'});
    setUpOperator(config, 'select_equals', {label : '='});
    setUpOperator(config, 'select_not_equals', {label:'Not Equal'});
    setUpOperator(config, 'select_any_in', {label : 'In'});
    setUpOperator(config, 'select_not_any_in', {label : 'Not In'});
    setUpOperator(config, 'between', {label : 'Between' ,validateValues : betweenValidation});
    setUpOperator(config, 'not_between', {label : 'Not Between', validateValues : betweenValidation});
    setUpOperator(config, 'is_null', {label : 'Null'});
    setUpOperator(config, 'is_not_null', {label : 'Not Null'});

    //Alter the behaviour of the SqlString helper. This uses backslashes to escape quotes embedded in strings,
    //but CQL Chef needs us to double up quotes instead.
    const originalEscape = Utils.ExportUtils.SqlString.escape;
    Utils.ExportUtils.SqlString.escape = function modifiedEscape() {
        let result = originalEscape.apply(this, arguments);
        if(!result) {
            return result;
        }

        // Any string value should be surrounded with single quotes. The SqlString helper will have escaped any
        // interesting characters between those two, but CQL needs a doubled-up quote rather than a backslash to
        // escape it.
        if(result.length > 2 && result[0] === "'" && result[result.length-1] === "'") {
            // The string needs to start and end with a single quote, and we don't want our search-and-replace to
            // affect them. We strip them off with substring, and then put them back in once we have sorted out
            // any escaped quotes
            result = result.substring(1, result.length-1);
            result = result.replace(/\\'/g, "''");
            result = "'" + result + "'";
        }

        return result;
    }

    return config;
}

function setUpOperator(config, key, overrides){
    Object.keys(overrides).forEach(k => config.operators[key][k] = overrides[k])
}
