import { useState } from "react";
import styled from "@emotion/styled";
import { theme } from "omse-components";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory, useLocation } from "react-router-dom";
import { defineMessages, FormattedMessage } from "react-intl";
import { Dialog, Typography, Button, IconButton, Grow } from "@mui/material";
import { ReactComponent as WorkspaceGraphic } from "./introModal/workspaceGraphic.svg";

const Container = styled("div")`
    & > div:first-of-type {
        padding: 1em;
        text-align: right;
    }
    & > div:last-of-type {
        padding: 2em 8em 6em 8em;
        display: flex;
        gap: 6em;
        align-items: center;
        flex-direction: row;
        ${theme.breakpoints.down("md")} {
            padding: 0 4em 4em 4em;
        }
        ${theme.breakpoints.down("sm")} {
            padding: 0em 2em 2em 2em;
            gap: 0.2em;
            align-items: flex-start;
            flex-direction: column-reverse;
        }
    }
    & > div:last-of-type > div {
        max-width: 22em;
    }
    & > div:last-of-type > div > h2 {
        margin: 0 0 1em 0;
    }
    & > div:last-of-type > div > ul {
        padding: 0 0 0 1em;
    }
    & > div:last-of-type > div > ul > li {
        margin: 0 0 0.4em 0;
    }
    & > div:last-of-type > div > button {
        margin: 1em 0 0 0;
        cursor: select;
        ${theme.breakpoints.down("sm")} {
            width: 100%;
        }
    }
    & > div:last-of-type > svg {
        width: 10em;
        flex-shrink: 0;
        user-drag: none;
        user-select: none;
        ${theme.breakpoints.down("sm")} {
            width: 6em;
        }
    }
`;

const modalMessages = defineMessages({
    title: {
        id: "modalMessages.title",
        defaultMessage: "Welcome to Workspace!",
        description: "Welcome Modal > Title",
    },
    summary: {
        id: "modalMessages.summary",
        defaultMessage:
            "Your new workspace brings all your projects, downloads, and API usage all together in one place. You can also:",
        description: "Welcome Modal > Summary",
    },
    summaryLi1: {
        id: "modalMessages.summaryLi1",
        defaultMessage: "Find our latest documentation and support",
        description: "Welcome Modal > Summary > List Item 1",
    },
    summaryLi2: {
        id: "modalMessages.summaryLi2",
        defaultMessage: "Track your most recent activity",
        description: "Welcome Modal > Summary > List Item 2",
    },
    summaryLi3: {
        id: "modalMessages.summaryLi3",
        defaultMessage: "Get the latest product updates and features",
        description: "Welcome Modal > Summary > List Item 3",
    },
    beginButton: {
        id: "modalMessages.beginButton",
        defaultMessage: "Start using workspace",
        description: "Welcome Modal > Begin Button",
    },
});

export default function WelcomeModal() {
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamsWelcome = queryParams.has("welcome");
    const [modalOpen, setModalOpen] = useState(queryParamsWelcome);
    const [modalInDom, setModalInDom] = useState(queryParamsWelcome);

    function dismissModal() {
        setModalOpen(false);
        setTimeout(() => {
            setModalInDom(false);
        }, 400);
        // remove param to avoid bookmarking the modal
        queryParams.delete("welcome");
        history.replace({
            search: queryParams.toString(),
        });
    }

    return (
        modalInDom && (
            <Dialog maxWidth={"md"} open={modalOpen} TransitionComponent={Grow}>
                <Container>
                    <div>
                        <IconButton aria-label="close" onClick={() => dismissModal()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div>
                        <div>
                            <Typography variant="h2">
                                <FormattedMessage {...modalMessages.title} />
                            </Typography>
                            <Typography variant="body1">
                                <FormattedMessage {...modalMessages.summary} />
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant="body1">
                                        <FormattedMessage {...modalMessages.summaryLi1} />
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        <FormattedMessage {...modalMessages.summaryLi2} />
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        <FormattedMessage {...modalMessages.summaryLi3} />
                                    </Typography>
                                </li>
                            </ul>
                            <Button variant={"contained"} onClick={() => dismissModal()}>
                                <FormattedMessage {...modalMessages.beginButton} />
                            </Button>
                        </div>
                        <WorkspaceGraphic aria-hidden="true" />
                    </div>
                </Container>
            </Dialog>
        )
    );
}
