import {createUseStyles} from 'react-jss';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const useStyles = createUseStyles({
    root: {
        display: 'flex',
        position: 'relative',
        flex: '1 1 auto',
        flexDirection: 'column',
        padding: 0,
        margin: 0
    }
});

/**
 * A component which renders a list of steps. Each step has numbered in a circle and has a line connecting it to the
 * next step. You can disable choose to "disable" lines.
 *
 * This component expects to be rendered with a number of StepListItems as its children.
 */
// Note StepList expects to be in a flex container with a column direction.
export default function StepList({children, className}) {
    // Set the step number and whether a line should exist to the next step.
    const countChildren = React.Children.count(children);
    const childrenWithNumberLine = React.Children.map(children, (child, i) => {
        const isLastChild  = i === countChildren-1;
        return React.cloneElement(child, {
            stepNum: i + 1,
            showLine: !isLastChild
        });
    });

    const classes = useStyles();
    return <ol className={classNames(classes.root, className)}>
        {childrenWithNumberLine}
    </ol>;
}

StepList.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};