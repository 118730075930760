const RESET_TYPE = 'reset';

export default function ResetAction(map, layers) {

    const {drawingLayer, selectedTileLayer} = layers;

    selectedTileLayer.getSource().clear();
    drawingLayer.getSource().clear();

    return {
        type: RESET_TYPE
    }
}

ResetAction.type = RESET_TYPE;