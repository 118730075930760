import {useEffect} from 'react';
import {useParams} from 'react-router';
import {useSelector, useDispatch} from 'react-redux';
import {getRecipe} from "../modules/recipes/actions";

export default function useRecipe() {
    const {recipeId} = useParams();
    const dispatch = useDispatch();
    let {result, error} = useSelector(state => state.recipes.recipe);

    let recipe;
    // Get the recipe from the redux store
    if(result && result.id === recipeId) {
        recipe = result;
        error = null;
    }

    useEffect(() => {
        if(!recipe || recipe.id !== recipeId) {
            dispatch(getRecipe(recipeId));
        }
    }, [recipeId, recipe, dispatch]);

    return {recipe, error};
}
