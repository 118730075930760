import React from 'react';
import {ReactComponent as TickIcon} from './icons/tick.svg';
import {ReactComponent as CrossIcon} from './icons/close-small.svg';
import {defineMessages, useIntl} from "react-intl";

const messages = defineMessages({
    included: {
        id: 'FeatureIcon.included',
        defaultMessage: 'Included',
        description: 'Item included label'
    },
    notIncluded: {
        id: 'FeatureIcon.notIncluded',
        defaultMessage: 'Not included',
        description: 'Item not included label'
    }
});

export default function FeatureIcon(props) {
    const intl = useIntl();
    const {on} = props;
    if (typeof on === 'undefined') {
        return <strong>n/a</strong>;
    }
    return on? 
        <TickIcon width={24} height={24} aria-label={intl.formatMessage(messages.included)} role='img' data-testid="included-svg" />
        : 
        <CrossIcon width={24} height={24} aria-label={intl.formatMessage(messages.notIncluded)} role='img' data-testid="not-included-svg" />;
}