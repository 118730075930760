import {useLocation, useHistory} from "react-router";

export default function useLocationState(key) {
    const location = useLocation();
    const history = useHistory();

    function setValue(value) {
        const state = {
            ...location.state,
            [key]: value
        };
        history.replace({
            ...location,
            state
        });
    }

    const currentValue = (location.state && location.state[key]) || '';

    return [currentValue, setValue];
}

export function useResetLocationState(stateToKeep) {
    const location = useLocation();
    const history = useHistory();

    function resetAll() {
        const newState = {}
        if (stateToKeep != null) {
             stateToKeep.forEach(key => {
                newState[key] = location.state[key]
            })
        }
        history.replace({
            ...location,
            state: newState
        });
    }

    return resetAll;
}
