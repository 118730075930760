import {isOpenDataPersonal} from './organisation';
import {apiTermsKey, downloadTermsKey, generalTermsKey, termsAcceptState} from '../../shared/terms';
import {openTerms} from '../modules/terms/actions';
import {apiTermsName, downloadTermsName} from '../../shared/constants';
import menuMessages from '../pages/appContent/MenuMessages';

export function withTermsActionCheck(termsName, targetAction, user, org) {
    const userTermsAcceptState = getUserTermsAcceptState(termsName, user, org);
    if (userTermsAcceptState === termsAcceptState.latest) {
       return targetAction;
    } else {
        return openTerms(apiTermsName, targetAction);
    }
}

export const getUserTermsAcceptState = (termsName, user, org) => {
    let termsKey;
    switch(termsName) {
        case apiTermsName:
            termsKey = apiTermsKey;
        break;
        case downloadTermsName:
            termsKey = downloadTermsKey;
        break;
        default:
            termsKey = generalTermsKey;
    }

    let userTermsState;
    if ((user && user.terms && user.terms[termsKey])) {
        if (isOpenDataPersonal(user, org) && termsKey !== generalTermsKey) {
            if (user.terms.personal?.[termsKey]) {
                userTermsState = user.terms.personal[termsKey];
            }
        } else {
            userTermsState = user.terms[termsKey];
        }
    }
    return userTermsState;
}

export function productMessage(variant) {
    let product;
    switch(variant) {
        case apiTermsName:
            product = menuMessages.apiProducts;
            break;
        case downloadTermsName:
            product = menuMessages.premiumDownloads;
            break;
        default:
    }
    return product;
}