import React,  {useState, useRef}  from 'react';
import PropTypes from 'prop-types';
import {CommonDialog, CommonDialogActions} from 'omse-components';
import {defineMessages} from 'react-intl';
import PaymentDetailsForm from './PaymentDetailsForm';
import {createUseStyles} from 'react-jss';

const messages = defineMessages({
    dialogTitle: {
        id: 'SetupPaymentDialog.dialogTitle',
        defaultMessage: 'Payment card details',
        description: 'Title for the setup payment details dialog'
    },
    submit: {
        id: 'SetupPaymentDialog.submit',
        defaultMessage: 'Submit Payment Details',
        description: 'Label for the submit payment details button'
    }
});

const useStyles = createUseStyles(theme => ({
    content: {
        paddingRight: theme.spacing(6.5),
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(1)
        }
    }
}));

export default function SetupPaymentDialog({onClose}) {
    const classes = useStyles();
    const [valid, setValid] = useState(false);
    const [working, setWorking] = useState(false);
    const [error, setError] = useState(null);
    const confirmRef = useRef();

    const actions = <CommonDialogActions onClose={onClose}
                                         confirmLabel={messages.submit}
                                         onConfirm={() => confirmRef.current()}
                                         confirmAllowed={valid && !error}
                                         working={working && !error}
    />;

    return <CommonDialog onClose={onClose}
                         title={messages.dialogTitle}
                         actions={actions}
                         error={error}
                         classes={{content: classes.content}}>
        <PaymentDetailsForm 
            onValid={setValid} 
            onWorking={setWorking} 
            onError={setError} 
            confirmRef={confirmRef}
            onClose={onClose}
        />
    </CommonDialog>
}

SetupPaymentDialog.propTypes = {
    onClose: PropTypes.func.isRequired
}
