import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import Link from "./Link";
import routes from "../util/routes";
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';

const messages = defineMessages({
    overview: {
        id: 'ProductDocumentation.overview',
        defaultMessage: 'Overview',
        description: 'Label for the overview link'
    },
    gettingStarted: {
        id: 'ProductDocumentation.gettingStarted',
        defaultMessage: 'Getting started',
        description: 'Label for the getting started link'
    },
    techSpec: {
        id: 'ProductDocumentation.techSpec',
        defaultMessage: 'Technical Specification',
        description: 'Label for the technical specification link'
    },
    overviewAria: {
        id: 'ProductDocumentation.overviewAria',
        defaultMessage: '{productName}: Overview',
        description: 'Label for the overview link aria-label'
    },
    gettingStartedAria: {
        id: 'ProductDocumentation.gettingStartedAria',
        defaultMessage: '{productName}: Getting started',
        description: 'Label for the getting started link aria-label'
    },
    techSpecAria: {
        id: 'ProductDocumentation.techSpecAria',
        defaultMessage: '{productName}: Technical Specification',
        description: 'Label for the technical specification link aria-label'
    }
});

function styles(theme) {
    return {
        spacer: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1)
            }
        }
    };
}

function ProductDocumentation(props) {
    const {classes, productName, productServiceType, productSubServiceType, intl} = props;

    const overviewLabel = <FormattedMessage {...messages.overview}/>;
    const gettingStartedLabel = <FormattedMessage {...messages.gettingStarted}/>;
    const techSpecLabel = <FormattedMessage {...messages.techSpec}/>;

    const overviewLabelAria = intl.formatMessage(messages.overviewAria, {productName});
    const gettingStartedLabelAria = intl.formatMessage(messages.gettingStartedAria, {productName});
    const techSpecLabelAria = intl.formatMessage(messages.techSpecAria, {productName});

    let overview = null;
    let gettingStarted = null;
    let techSpec = null;

    if(productName.indexOf('OS Maps') !== -1 || productServiceType === 'Raster tiles') {
        overview = routes.wmtsOverview;
        gettingStarted = routes.wmtsIntro;
        techSpec = routes.wmtsDetail;
    }
    if(productName.indexOf('OS NGD API') !== -1 || productServiceType === 'NGD') {
        overview = routes.ofaOverview;
        gettingStarted = routes.ofaIntro;
        techSpec = routes.ofaDetail;
    }
    if(productName.indexOf('OS NGD API') !== -1 && productServiceType === 'NGD' && productSubServiceType ==='Vector tiles') {
        overview = routes.otaOverview;
        gettingStarted = routes.otaIntro;
        techSpec = routes.otaDetail;
    }
    if(productName.indexOf('OS Features') !== -1 || productServiceType === 'Features') {        
        overview = routes.wfsOverview;
        gettingStarted = routes.wfsIntro;
        techSpec = routes.wfsDetail;
    }
    if(productName.indexOf('OS Vector Tile') !== -1 || productServiceType === 'Vector tiles') {
        overview = routes.vtsOverview;
        gettingStarted = routes.vtsIntro;
        techSpec = routes.vtsDetail;
    }
    if(productName.indexOf('Linked Identifiers') !== -1 && productServiceType === 'Search') {
        overview = routes.linkedIdentifiersOverview;
        gettingStarted = routes.linkedIdentifiersIntro;
        techSpec = routes.linkedIdentifiersDetail;
    }
    if(productName.indexOf('OS Match') !== -1 && productServiceType === 'Search') {
        overview = routes.matchOverview;
        gettingStarted = routes.matchIntro;
        techSpec = routes.matchDetail;
    }
    if(productName.indexOf('OS Names') !== -1 && productServiceType === 'Search') {
        overview = routes.namesOverview;
        gettingStarted = routes.namesIntro;
        techSpec = routes.namesDetail;
    }
    if(productName.indexOf('OS Places') !== -1 && productServiceType === 'Search') {
        overview = routes.placesOverview;
        gettingStarted = routes.placesIntro;
        techSpec = routes.placesDetail;
    }
    if(productName.indexOf('Download') !== -1 || productServiceType === 'OS OpenData downloads') {
        overview = routes.downloadsOverview;
        gettingStarted = routes.downloadsIntro;
        techSpec = routes.downloadsDetail;
    }
    if(productName.indexOf('OAuth') !== -1) {
        techSpec = routes.oauthDetail;
    }

    if(!gettingStarted && !overview && !techSpec) {
        return null;
    }

    let overviewLink, gettingStartedLink, techSpecLink;
    if (overview) {
        overviewLink = <Link path={overview} ariaLabel={overviewLabelAria}>{overviewLabel}</Link>;
    }
    if (gettingStarted) {
        gettingStartedLink = <Link path={gettingStarted} ariaLabel={gettingStartedLabelAria}>{gettingStartedLabel}</Link>;
    }
    if (techSpec) {
        techSpecLink = <Link path={techSpec} ariaLabel={techSpecLabelAria}>{techSpecLabel}</Link>;
    }

    return <Fragment>
        {overviewLink}
        {overviewLink && <span className={classes.spacer}>|</span>}
        {gettingStartedLink}
        {gettingStartedLink && <span className={classes.spacer}>|</span>}
        {techSpecLink}
    </Fragment>;
}

ProductDocumentation.propTypes = {
    productName: PropTypes.string.isRequired,
    productServiceType: PropTypes.string,
    productSubServiceType: PropTypes.string,
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(ProductDocumentation));

