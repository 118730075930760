import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createUseStyles} from 'react-jss';
import classNames from 'classnames';
import {
    AddButton,
    FieldError,
    InputBox,
    LinkButton,
    modules,
    Notification,
    osColour,
    primaryColour,
    ExternalLink
} from 'omse-components';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Button, Drawer, IconButton, Typography} from '@mui/material';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import features from '../../../shared/features';
import FeatureCheck from '../../components/FeatureCheck';
import ItemDetails from './recipeEditor/ItemDetails';
import {ReactComponent as CloseIcon} from '../../components/icons/close-large.svg';
import CreateRecipeInstructions, {messages as createRecipeInstructionsMessages} from './recipeEditor/CreateRecipeInstructions';
import HelpButton from '../../components/HelpButton';
import ThemesList from './recipeEditor/ThemesList';
import {
    changeRecipeDescription,
    changeRecipeName,
    createRecipe,
    displayErrors,
    resetFeatureTypes,
    resetRecipeState, setCurrentQueryTree
} from '../../modules/recipeEditor/actions';
import useActionIdSelector from '../../hooks/useActionIdSelector';
import routes, {useRedirect} from '../../util/routes';
import RecipeDescriptionDialog from './recipeEditor/RecipeDescriptionDialog';
import FeatureFilterPanel from "./recipeEditor/FeatureFilterPanel";
import DiscardFiltersDialog from "./recipeEditor/DiscardFiltersDialog";
import PopoutPanel from "./PopoutPanel";
import {getFeatureTypeSchema} from "../../modules/recipes/actions";
import SearchBox from "../../components/SearchBox";
import useBackNavigation from "../../hooks/useBackNavigation";

const {getThemeTree} = modules.actions.themes;
const messages = defineMessages({
    title: {
        id: 'RecipeEditor.mainTitle',
        defaultMessage: 'Create your recipe',
        description: 'Label for recipe editor main panel title'
    },
    recipeHelp: {
        id: 'RecipeEditor.recipeHelp',
        defaultMessage: 'Get help with creating a recipe',
        description: 'Label for the recipe help text'
    },
    recipeDescriptionButtonAdd: {
        id: 'RecipeEditor.recipeDescriptionButtonAdd',
        defaultMessage: 'Add a description',
        description: 'Label for recipe editor add a description button'
    },
    recipeDescriptionButtonEdit: {
        id: 'RecipeEditor.recipeDescriptionButtonEdit',
        defaultMessage: 'edit',
        description: 'Label for recipe editor add a description button'
    },
    recipeDescriptionAddedText: {
        id: 'RecipeEditor.recipeDescriptionAddedText',
        defaultMessage: 'Description added - <editButton>edit</editButton>',
        description: 'Label for recipe editor add a description button'
    },
    themes: {
        id: 'RecipeEditor.themes',
        defaultMessage: 'Themes',
        description: 'Label for recipe editor themes panel'
    },
    themesListResetSelection: {
        id: 'RecipeEditor.themesListResetSelection',
        defaultMessage: 'Reset selection',
        description: 'Label for the reset selection button in the themes panel'
    },
    recipeNameLabel: {
        id: 'RecipeEditor.recipeNameLabel',
        defaultMessage: 'Recipe name',
        description: 'Label for recipe name input label'
    },
    recipeNameInputPlaceholder: {
        id: 'RecipeEditor.recipeNameInputPlaceholder',
        defaultMessage: 'Give your recipe a name',
        description: 'Label for recipe name input placeholder text'
    },
    featureTypeSearch: {
        id: 'RecipeEditor.featureTypeSearch',
        defaultMessage: 'Search by feature type',
        description: 'Label for feature type search input placeholder text'
    },
    itemDetailsResetButtonLabel: {
        id: 'RecipeEditor.itemDetailsResetButtonLabel',
        defaultMessage: 'View recipe creator step by step guide',
        description: 'Label for the reset button'
    },
    createRecipeButton: {
        id: 'RecipeEditor.createRecipeButton',
        defaultMessage: 'Create recipe',
        description: 'Label for create recipe button'
    },
    cancelButton: {
        id: 'RecipeEditor.cancelButton',
        defaultMessage: 'Cancel',
        description: 'Label for the cancel button'
    },
    errorMessageThemeTree: {
        id: 'RecipeEditor.errorMessageThemeTree',
        defaultMessage: 'There was a problem loading the recipe editor. Please retry and if the problem persists then {link}.',
        description: 'Failed to load theme tree message'
    },
    errorMessage: {
        id: 'RecipeEditor.errorMessage',
        defaultMessage: 'There was a problem creating your recipe. Please retry and if the problem persists then {link}.',
        description: 'Failed request error message'
    },
    validationErrorRecipeNameNeeded: {
        id: 'RecipeEditor.validationErrorRecipeNameNeeded',
        defaultMessage: 'Please give your recipe a name',
        description: 'Recipe name needed validation message'
    },
    noResultsFoundInTree: {
        id: 'RecipeEditor.noResultsFoundInTree',
        defaultMessage: 'No feature types matched your search. Please try another word or browse the tree below to see what is available.',
        description: 'Message displayed when no theme tree search results are found'
    },
    validationErrorFeatureTypeMustBeSelected: {
        id: 'RecipeEditor.validationErrorFeatureTypeMustBeSelected',
        defaultMessage: 'Please select at least one feature type',
        description: 'At least one feature type must be selected validation message'
    },
    openFilterPanel:{
        id: 'RecipeEditor.openFilterPanel',
        defaultMessage: 'Open filter panel for {featureType}',
        description: 'Open filter panel button label'
    },
    closeFilterPanel:{
        id: 'RecipeEditor.closeFilterPanel',
        defaultMessage: 'Close filter panel for {featureType}',
        description: 'Close filter panel button label'
    }
});

const leftSideSectionPadding = 2;
const websiteHeaderHeight = 65;
const drawerOffset = websiteHeaderHeight * 1.5;
const useStyles = createUseStyles(theme => ({
    container: {
        display: 'flex',
        flex: '1',
        // On desktop we want any scrolling to be inside the theme list only. Setting minHeight: 0 allows this container
        // to shrink below its contents size and exactly fit the available space in the viewport. That way the
        // descendant theme list will be able to handle any overflow and scrolling.
        minHeight: 0,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            // On mobile we want the entire content to scroll (including the footer) instead of just the theme list,
            // this gives us more room to see each part. Resetting minHeight: auto to force the container to be at least
            // the size of its contents and therefore be the thing the overflows and scrolls.
            minHeight: 'auto',
        }
    },
    leftSide: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 1 360px',
        [theme.breakpoints.down('md')]: {
            // Should take up the entire width when on mobile.
            flex: '1',
        },
        boxShadow: '0 1px 6px 3px rgba(0, 0, 0, 0.2)',
    },
    center: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
    },
    drawerPaper: {
        height: `calc(100% - ${drawerOffset}px)`,
        borderTop: `4px solid ${primaryColour}`
    },
    closeButton: {
        marginLeft: 'auto',
    },
    leftSideHeaderSection: {
        padding: theme.spacing(leftSideSectionPadding),
        paddingTop: theme.spacing(5),
        borderBottom: '1px solid ' + osColour.neutral.mist,
    },
    recipeNameInput: {
        paddingTop: theme.spacing(2),
    },
    recipeDescriptionSection: {
        marginTop: theme.spacing(1),
    },
    recipeDescriptionButtonEdit: {
        minWidth: 'auto',
        verticalAlign: 'baseline',
    },
    resetButton: {
        float: 'right'
    },
    featureTypeSearch: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)

    },
    themesListSection: {
        flex: '1',
        minHeight: 0, // Allows flex to shrink below the content's height.
        display: 'flex',
        flexDirection: 'column',
        // Remove padding here to allow dividers to fit the entire width of the container.
        padding: 0,
        borderBottom: 'none',
    },
    themesHeader: {
        marginBottom: theme.spacing(.5),
        padding: `${theme.spacing(leftSideSectionPadding)} ${theme.spacing(leftSideSectionPadding)} 0 ${theme.spacing(leftSideSectionPadding)}`
    },
    themesValidationErrorMessages: {
        paddingLeft: theme.spacing(leftSideSectionPadding)
    },
    actionButtonsSection: {
        display: 'flex',
        justifyContent: 'flex-end',
        border: 'none',
        padding: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(1)} 0`,
        backgroundColor: osColour.neutral.clouds
    },
    cancelButton: {
        marginRight: theme.spacing(2)
    },
    leftSideContent: {
        overflowY: 'auto',
        flex: 'auto',
        '& ul': {
            overflow: 'visible'
        }
    }
}));

function NO_OP() {}

export default function RecipeEditor() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();
    const redirect = useRedirect();
    const onCancel = useBackNavigation(routes.recipeLibrary);
    const recipeEditorState = useSelector(state => state.recipeEditor);
    const {name, description, displayValidationErrors, featureTypes} = recipeEditorState;
    const themeTreeError = Boolean(useSelector(state => state.themes.themeTree.error));
    const [{working: createRecipeWorking, result, error: createRecipeError}, dispatchCreateRecipe] = useActionIdSelector('recipeEditor.createRecipe');
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    // All of the state that this component manages
    const [showDescDialog, setShowDescDialog] = useState(false);
    const [selectedThemesListItem, setSelectedThemesListItem] = useState(null);
    const [popoutOpen, setPopoutOpen] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [discardFiltersAction, setDiscardFiltersAction] = useState(null);
    const [leafFilter, setLeafFilter] = useState('');
    const [noResult, setNoResult] = useState(false);

    // If we have a work-in-progress edit then we know that we have unsaved filter state
    const hasUnsavedFilters = recipeEditorState.currentQueryTree.treeEdited;

    useEffect(() => {
        // Ensure our display of errors is reset when we reload this component. This avoids the case where the user
        // navigates around the app then comes back to this page and finds validation messages being displayed before
        // they've clicked the create recipe button.
        dispatch(resetRecipeState());
        // Get an up to date tree of theme / collection / feature types
        dispatch(getThemeTree());
    }, [dispatch]);

    // For now on success redirect to recipe library.
    useEffect(() => {
        if(result) {
            redirect.push(routes.recipeLibrary);
        }
    }, [dispatch, redirect, result]);

    // If the current selection is a feature, try to find the matching entry in the featureTypes list
    let featureTypesItem = null;
    // Work out if the currently selected item is also filterable.
    let selectedItemIsFilterable = false;
    if(selectedThemesListItem) {
        featureTypesItem = featureTypes.find(item => item.featureTypeId === selectedThemesListItem.featureId)
        selectedItemIsFilterable = !!featureTypesItem;
    }

    // If a feature type is selected, then go and load the matching feature type schema. We load it here because both
    // the query view and the documentation view need the feature type schema, and we don't want to load it twice.
    useEffect(() => {
        if(selectedThemesListItem?.featureId) {
            let version = selectedThemesListItem.versions[0];
            if(featureTypesItem) {
                version = featureTypesItem.featureTypeVersion;
            }
            dispatch(getFeatureTypeSchema(selectedThemesListItem.featureId, version));
        }
    }, [dispatch, selectedThemesListItem, featureTypesItem]);

    const closeFilterPanel = useCallback(function closeFilterPanel() {
        setPopoutOpen(false);
        setShowDrawer(false);
        setDiscardFiltersAction(null);

        // This editing session is over, so clean up the query tree from the redux store.
        dispatch(setCurrentQueryTree());
    }, [dispatch]);

    // The user can un-tick feature types while they are editing a feature query. If they remove the feature
    // that is currently being edited then we'd better end the editing session! We considered adding a check
    // for unapplied filters here, but decided that putting that in here would be inappropriate. (After all,
    // if they have an _applied_ filter we'd allow them to remove the feature type, so why stop them when they
    // have an incomplete one!)
    useEffect(function checkSelectedItemIsFilterable() {
        if(!selectedItemIsFilterable && popoutOpen) {
            // Close the filter panel without saving.
            closeFilterPanel();
        }
    }, [selectedItemIsFilterable, popoutOpen, closeFilterPanel]);

    // The drawer can show 3 kinds of content: an attribute filter, docs for a theme tree item, or general help.
    // When the drawer closes we need to shut down the matching state.
    function hideMobileInfoPanel() {
        if(popoutOpen) {
            // Either close the panel directly, or pop a dialog to ask for permission. Either way, we're done.
            // We return early to avoid setting setShowDrawer(false) when there is a dialog still around.
            tryToCloseFilterPanel(NO_OP);
            return;
        }
        if(selectedThemesListItem) {
            setSelectedThemesListItem(null);
        }
        setShowDrawer(false);
    }

    // Setting what displays in the info panel content (desktop: right hand side, mobile: drawer component).
    function showRecipeInstructions() {
        tryToCloseFilterPanel(function doShowRecipeInstructions() {
            setSelectedThemesListItem(null);
            setShowDrawer(true);
        });
    }
    function showThemesListItemContent(listItem) {
        if(listItem !== selectedThemesListItem) {
            tryToCloseFilterPanel(function doShowThemesListItemContent() {
                setSelectedThemesListItem(listItem);
                setShowDrawer(true);
            });
        } else {
            // The user mashed the link again, so make sure the drawer is open
            setShowDrawer(true);
        }
    }
    function openFilterPanelForFeatureType(listItem) {
        if(listItem !== selectedThemesListItem) {
            tryToCloseFilterPanel(function doOpenFilterPanelForFeatureType() {
                setSelectedThemesListItem(listItem);
                setPopoutOpen(true);
                setShowDrawer(true);
            });
        } else {
            // The user mashed the filter button again, so make sure the popout and drawer are both open
            setPopoutOpen(true);
            setShowDrawer(true);
        }
    }

    // Note that this reset does not clear the name or description, but it does reset the feature type state in the
    // store, and resets the rest of the component state too.
    const resetRecipe = () => {
        dispatch(resetFeatureTypes());
        dispatch(setCurrentQueryTree());
        setSelectedThemesListItem(null);
        setPopoutOpen(false);
        setShowDrawer(false);
    };

    // Check to see if the editor is ok to move on and do something new. If there are unapplied filter changes then
    // it is not ok to carry on right now, and we pop a dialog for the user to interact with.
    const tryToCloseFilterPanel = useCallback(function tryToCloseFilterPanel(action) {
        if (!hasUnsavedFilters) {
            closeFilterPanel();
            // Run the action immediately
            action();
            return true;
        } else {
            // Make sure we can see the filters, and save the action for later. Saving the action will pop the
            // dialog onto the screen. Calling 'setDiscardFiltersAction' with a function will run the function,
            // so we need to pass in a function that returns the action, e.g. the setDiscardFiltersActionHelper.
            setPopoutOpen(true);
            setDiscardFiltersAction(function setDiscardFiltersActionHelper() {
                return action
            });
            return false;
        }
    }, [closeFilterPanel, hasUnsavedFilters]);

    function handleCreate() {
        if(isStateValidForCreation(recipeEditorState)) {
            // We pass in a NO_OP here, as we only want to actually create the recipe if there are no unapplied filter
            // changes. If there are unapplied changes then we pop the dialog to ask the user what to do, but we don't
            // want to resume the action automatically... instead we wait for them to click the button again.
            if(tryToCloseFilterPanel(NO_OP)) {
                dispatchCreateRecipe(createRecipe());
            }
        } else {
            dispatchCreateRecipe(displayErrors());
        }
    }

    // Prepare to show the info and feature content (desktop), or drawer (mobile)

    // Note that we may not have a selected filterable list item at this point, but the panel still needs to exist,
    // as the panel that it is displayed in the PopoutPanel, and it animates as it closes... so the content needs to
    // be there for a few seconds longer than you might expect.
    let featureFilterPanelContent = <FeatureFilterPanel listItem={selectedItemIsFilterable ? selectedThemesListItem : null}
                                                        closePanel={() => {
                                                            if(isMobile) {
                                                                hideMobileInfoPanel();
                                                            } else {
                                                                setPopoutOpen(false);
                                                            }
                                                        }}/>;
    let infoPanelContent;
    if(selectedThemesListItem) {
        infoPanelContent = <ItemDetails showResetButton={isMobile ? false : 'top'}
                                        resetButtonLabel={messages.itemDetailsResetButtonLabel}
                                        onResetClick={showRecipeInstructions}
                                        listItem={selectedThemesListItem}
                                        tryToCloseFilterPanel={tryToCloseFilterPanel}
                                        resetButtonIcon={true}
        />;
    } else {
        infoPanelContent = <CreateRecipeInstructions showTitle={!isMobile}/>;
    }

    // The drawer can show 3 things. Most important is a filter panel, and that is all ready to use if we need it.
    // Failing that we show the content that would be in the desktop info panel - either theme/collection/feature
    // info, or the general help for the component.
    let drawerContent;
    if(popoutOpen) {
        drawerContent = featureFilterPanelContent;
    } else {
        drawerContent = <>
            <div className={classes.drawerHeader}>
                <Typography variant='subtitle1' color='textSecondary'>
                    {/*TODO: This message is probably inappropriate when showing theme / collection / feature details*/}
                    <FormattedMessage {...createRecipeInstructionsMessages.title} />
                </Typography>
                <IconButton
                    className={classes.closeButton}
                    aria-label="Close"
                    onClick={hideMobileInfoPanel}
                    size="large">
                    <CloseIcon height={24} width={24}/>
                </IconButton>
            </div>
            {infoPanelContent}
        </>;
    }

    // If result is populated it means recipe creation succeeded, we want to also disable in that case until we redirect.
    const disabledInputs = !!(themeTreeError || createRecipeWorking || result);

    function onDiscard() {
        // Get rid of the filter panel that we were displaying. This will also clear the current query tree.
        closeFilterPanel();

        // Continue the action that we interrupted with the dialog
        discardFiltersAction();
    }
    function onClose() {
        // The user has dismissed the dialog. We can forget about the interrupted action
        setDiscardFiltersAction(null);
    }

    return <FeatureCheck feature={features.NGD_DOWNLOADS}>

        {createRecipeError && <Notification variant='error' appearance='inline'>
            <Typography variant='body1'>
                <FormattedMessage {...messages.errorMessage} values={{ link: <ExternalLink type='support' /> }} />
            </Typography>
        </Notification>}

        {themeTreeError && <Notification variant='error' appearance='inline'>
            <Typography variant='body1'>
                <FormattedMessage {...messages.errorMessageThemeTree} values={{ link: <ExternalLink type='support' /> }} />
            </Typography>
        </Notification>}

        {discardFiltersAction && <DiscardFiltersDialog onConfirm={onDiscard}
                                                       onClose={onClose}/>}

        <div className={classes.container}>
            <div className={classes.leftSide}>
                <div className={classes.leftSideContent}>
                    <div className={classes.leftSideHeaderSection}>
                        <Typography variant='h1' color='primary'>
                            <FormattedMessage {...messages.title} />
                        </Typography>
                        {isMobile && <HelpButton helpMessage={messages.recipeHelp} onClick={showRecipeInstructions}/>}

                        <InputBox id='recipe-editor-name-input'
                                className={classes.recipeNameInput}
                                label={messages.recipeNameLabel}
                                value={name}
                                onChange={e => dispatch(changeRecipeName(e.target.value))}
                                fullWidth
                                placeholder={intl.formatMessage(messages.recipeNameInputPlaceholder)}
                                disabled={disabledInputs}
                                error={displayValidationErrors && !isRecipeNameValid(recipeEditorState) && messages.validationErrorRecipeNameNeeded} />

                        {description.length === 0 ?
                            <LinkButton className={classes.recipeDescriptionSection}
                                        onClick={() => setShowDescDialog(true)}
                                        disabled={disabledInputs}>
                                <FormattedMessage {...messages.recipeDescriptionButtonAdd}/>
                            </LinkButton>
                            :
                            <Typography variant='body1'
                                        className={classes.recipeDescriptionSection}>
                                <FormattedMessage {...messages.recipeDescriptionAddedText} values={{
                                    editButton: chunks => <LinkButton className={classes.recipeDescriptionButtonEdit}
                                                                    onClick={() => setShowDescDialog(true)}>{chunks}</LinkButton>
                                }}/>
                            </Typography>}

                        {showDescDialog && <RecipeDescriptionDialog initialDescription={description}
                                                                    onConfirm={newDescription => {
                                                                        setShowDescDialog(false);
                                                                        dispatch(changeRecipeDescription(newDescription));
                                                                    }}
                                                                    onClose={() => setShowDescDialog(false)} />}
                    </div>
                    <div className={classNames(classes.leftSideHeaderSection, classes.themesListSection)}>
                        <div className={classes.themesHeader}>
                            <Typography variant='h2' color='primary'>
                                <FormattedMessage {...messages.themes} />
                            </Typography>
                            <SearchBox id='leaf-filter-input'
                                    onChange={e => setLeafFilter(e.target.value)}
                                    fullWidth
                                    className={classes.featureTypeSearch}
                                    search={leafFilter}
                                    setSearch={leafFilter => setLeafFilter(leafFilter)}
                                    placeholder={messages.featureTypeSearch}
                                    label={messages.featureTypeSearch}
                                    disabled={disabledInputs}
                                    error={noResult ? messages.noResultsFoundInTree : null}/>
                            <LinkButton className={classes.resetButton} onClick={() => resetRecipe()} disabled={disabledInputs}>
                                <FormattedMessage {...messages.themesListResetSelection}/>
                            </LinkButton>
                        </div>
                        {displayValidationErrors && !areFeatureTypesValid(recipeEditorState) &&
                            <FieldError className={classes.themesValidationErrorMessages}
                                        error={messages.validationErrorFeatureTypeMustBeSelected} />}
                        <ThemesList rootListItemExtraInlinePadding={leftSideSectionPadding}
                                    disabled={disabledInputs}
                                    onListItemTextClick={listItem => showThemesListItemContent(listItem)}
                                    onFilterButtonClick={listItem => openFilterPanelForFeatureType(listItem)}
                                    leafFilter={leafFilter}
                                    setNoResult={setNoResult}
                        />
                    </div>
                </div>
                <div className={classNames(classes.leftSideHeaderSection, classes.actionButtonsSection)}>
                    <Button key='cancel'
                            onClick={onCancel}
                            color='primary'
                            variant='outlined'
                            disabled={createRecipeWorking}
                            className={classes.cancelButton}>
                        <FormattedMessage {...messages.cancelButton}/>
                    </Button>
                    <AddButton label={messages.createRecipeButton}
                               showIcon={false}
                               color='primary'
                               variant='contained'
                               disabled={disabledInputs}
                               action={handleCreate}
                               working={createRecipeWorking} />
                </div>
            </div>

            {/* Non-mobile shows content in the right-hand panel and enable filters*/}
            {!isMobile && <>
                <div className={classes.center}>
                    {infoPanelContent}
                </div>
                <PopoutPanel visible={selectedItemIsFilterable}
                             open={popoutOpen}
                             setOpen={setPopoutOpen}
                             panelContent={featureFilterPanelContent}
                             openTabAriaLabel={intl.formatMessage(messages.openFilterPanel, {featureType: selectedThemesListItem?.label})}
                             closeTabAriaLabel={intl.formatMessage(messages.closeFilterPanel, {featureType: selectedThemesListItem?.label})}
                />
            </>}

            {/* Mobile has less space so we use an expandable drawer to show the content. */}
            {isMobile && <Drawer
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor='bottom'
                open={showDrawer}
                onClose={hideMobileInfoPanel}
            >
                {drawerContent}
            </Drawer>}
        </div>
    </FeatureCheck>;
}

function isStateValidForCreation(recipeEditorState) {
    return isRecipeNameValid(recipeEditorState) &&
        areFeatureTypesValid(recipeEditorState);
}

function isRecipeNameValid({name}) {
    return name && name.length > 0;
}

function areFeatureTypesValid({featureTypes}) {
    return featureTypes.length > 0;
}