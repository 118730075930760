import {
    USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN,
    CRM_ENERGY_AND_INFRASTRUCTURE_PLAN,
} from "../../shared/plans";
import { userHasEnabledWorkspace } from "../hooks/useTryWorkspace";
import { cookieSettingToIndex, isCookieControlSettingAccepted } from "./cookie-control";
import { hasShowWorkspacePermission } from "./permissions";

export function isLoggedIn(user){
    return !!user;
}

export function isEaiUser(user){
    return isLoggedIn(user) && user.plan === USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN && user.orgPlan === CRM_ENERGY_AND_INFRASTRUCTURE_PLAN;
}

export function isWorkspaceUser(user) {
    return isLoggedIn(user) && isCookieControlSettingAccepted(cookieSettingToIndex.functionality) && hasShowWorkspacePermission(user) && userHasEnabledWorkspace(user?.id);
}