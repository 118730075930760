import {defineMessages} from "react-intl";
import {isNgdDataPackage} from "../../../../shared/ngd";

const messages = defineMessages({
    changeOnlyUpdateInfo: {
        id: 'useExpandInfoMessage.changeOnlyUpdateInfo',
        defaultMessage: 'When you expand an area, you will initially receive a full supply of data for the area you have added. Your regular change only updates will apply to the whole area.',
        description: 'cou info label'
    },
    noUpdatesInfo: {
        id: 'useExpandInfoMessage.noUpdatesInfo',
        defaultMessage: 'When you expand an area, you will receive data for the area you have added.',
        description: 'no updates info label'
    },
    fullSupplyInfo: {
        id: 'useExpandInfoMessage.fullSupplyInfo',
        defaultMessage: 'When you expand an area, you will initially receive data for the area you have added. Your regular updates will apply to the whole area.',
        description: 'full supply info label'
    },
    ngdChangeOnlyUpdateInfo: {
        id: 'useExpandInfoMessage.ngdChangeOnlyUpdateInfo',
        defaultMessage: 'When you expand an area, you will receive data for the area you have added, at your last supply date. Your regular change only updates will apply to the whole area.',
        description: 'cou info label'
    },
    ngdChangeOnlyUpdateWithoutUpdatesInfo: {
        id: 'useExpandInfoMessage.ngdChangeOnlyUpdateWithoutUpdatesInfo',
        defaultMessage: 'When you expand an area, you will receive data for the area you have added, at your last supply date.',
        description: 'cou info label'
    },
    ngdFullSupplyWithUpdatesInfo: {
        id: 'useExpandInfoMessage.ngdFullSupplyInfo',
        defaultMessage: 'When you expand an area, you will receive data for the whole area, at your last supply date. Your regular updates will supply the whole area.',
        description: 'info label for expansion of a ngd data package, with one-off supply.'
    },
    ngdFullSupplyWithoutUpdatesInfo: {
        id: 'useExpandInfoMessage.ngdFullSupplyWithoutUpdatesInfo',
        defaultMessage: 'When you expand an area, you will receive data for the whole area, at your last supply date.',
        description: 'info label for expansion of a ngd data package, with one-off supply.'
    }
});

export default function useExpandInfoMessage(dataPackage) {
    let infoMessage;

    if(isNgdDataPackage(dataPackage)) {
        if(dataPackage.supplyType === 'FULL') {
            if(dataPackage.status === 'ACTIVE') {
                infoMessage = messages.ngdFullSupplyWithUpdatesInfo;
            } else {
                infoMessage = messages.ngdFullSupplyWithoutUpdatesInfo;
            }
        } else {
            if(dataPackage.status === 'ACTIVE') {
                infoMessage = messages.ngdChangeOnlyUpdateInfo;
            } else {
                infoMessage = messages.ngdChangeOnlyUpdateWithoutUpdatesInfo;
            }
        }
    } else {
        infoMessage = messages.changeOnlyUpdateInfo;
        if (dataPackage && dataPackage.updateScheduleId) {
            if (dataPackage.updateScheduleId === 'NOTREQD') {
                infoMessage = messages.noUpdatesInfo;
            } else if (dataPackage.updateScheduleId.startsWith("FULL")) {
                infoMessage = messages.fullSupplyInfo;
            }
        }
    }

    return infoMessage;
}