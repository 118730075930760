import React from 'react';
import PropTypes from 'prop-types';
import {Box, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress} from '@mui/material';
import {createUseStyles} from 'react-jss';
import { osColour } from "omse-components";

const useStyles = createUseStyles(theme =>({
    loading:{
        padding: theme.spacing(2)
    },
    pricingTable:{
        '& thead th':{
            color: osColour.primary.berry,
            fontWeight: 'bold',
            borderBottom: `2px solid ${osColour.primary.berry}`
        },
        '& td':{
            fontWeight: 'normal',
            padding:5,
            '&:nth-last-child(2)':{
                backgroundColor:osColour.neutral.mist
            },
            '&:nth-last-child(1)':{
                backgroundColor:osColour.neutral.stone,
                color: osColour.neutral.white
            }
        }
    },
}))

export default function ApiPriceTable(props) {
    const {showExtraInfo, products, tiers} = props;
    const classes = useStyles();

    if(!products || !tiers){
        return <Box className={classes.loading}><CircularProgress /></Box>
    }

    const sortedFilteredTiers = [...tiers]
        .sort((a,b) => a.lowerValue  - b.lowerValue)
        .filter(tier => tier.lowerValue || tier.upperValue)

    return (
        <Table className={classes.pricingTable} data-testid="api-plan-pricing">
            <TableHead>
                <TableRow>
                    <TableCell>API Product</TableCell>
                    {showExtraInfo && <TableCell>You get</TableCell>}
                    {sortedFilteredTiers.map(tier =>
                        <TableCell data-tierid={tier.id}>{tier.name}</TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Discount applied</TableCell>
                    {showExtraInfo && <TableCell></TableCell>}
                    <TableCell>0%</TableCell>
                    <TableCell>5%</TableCell>
                    <TableCell>7%</TableCell>
                </TableRow>

                {products.map(product => {
                    return <TableRow key={product.service}>
                        <TableCell>{product.name}</TableCell>
                        {showExtraInfo &&
                            <TableCell>{product.unitDescription}</TableCell>
                        }
                        {sortedFilteredTiers.map(sfTier =>{
                            const foundTier = product.tiers.find(pTier => sfTier.id === pTier.tierId)
                            return <TableCell data-productTierId={foundTier.tierId}>£{foundTier.unitPrice}</TableCell>
                        })}
                    </TableRow>
                })}
            </TableBody>
        </Table>
    )
}

ApiPriceTable.propTypes = {
    showExtraInfo: PropTypes.bool,
    products: PropTypes.arrayOf(PropTypes.object),
    tiers: PropTypes.arrayOf(PropTypes.object)
}

ApiPriceTable.defaultPropTypes = {
    showExtraInfo:false
}
