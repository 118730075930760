import { useEffect, useRef, useState } from "react";
/**
 * Hook to track visibility of a component in viewport using Intersection Observer.
 * @param {threshold} threshold - percentage of the target element's visibility in the viewport the callback function should be triggered.
 * @returns {[Object, boolean]} - Returns a ref to the component and its visibility status.
 */
export const useIntersectionObserver = ({ threshold }) => {
    const [isVisible, setIsVisible] = useState(false);
    const targetRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false);
                    }
                });
            },
            { threshold: threshold, delay: 250, trackVisibility: true, }
        );
        const targetNode = targetRef.current;
        if (targetNode) {
            observer.observe(targetNode);
        }
        return () => {
            if (targetNode) {
                observer.unobserve(targetNode);
            }
        };
    }, [threshold]);
    return [targetRef, isVisible];
};
