import React from 'react';
import {createUseStyles} from 'react-jss';
import Typography from '@mui/material/Typography';
import {defineMessages, FormattedMessage} from 'react-intl';
import {contentPadding, AddButton, modules} from 'omse-components';
import ContentHeader from '../../components/ContentHeader';
import routes from '../../util/routes';
import {useSelector, useDispatch} from 'react-redux';
import Link from '../../components/Link';

const {refreshUser} = modules.actions.user;

const messages = defineMessages({
	title: {
		id: 'AccountInvitation.title',
		defaultMessage: 'Invitations',
		description: 'Label for title'
	},
	accountLink: {
		id: 'AccountInvitation.accountLink',
		defaultMessage: 'Account settings',
		description: 'Label for back link'
	},
	invitationText: {
		id: 'AccountInvitation.invitationText',
		defaultMessage: 'You have been invited to join {invitingOrg}. Please click {link}.',
		description: 'Label for the text for the invitation page'
	},
	invitationEmptyText: {
		id: 'AccountInvitation.invitationEmptyText',
		defaultMessage: 'If you have any invitations to join other organisations, they will show here.',
		description: 'Label for the text for the invitation page'
	},
	invitationLink: {
		id: 'AccountInvitation.invitationLink',
		defaultMessage: 'to accept or decline the invitation',
		description: 'Link to accept or decline the invitation'
	},
	refresh: {
		id: 'AccountInvitation.refresh',
		defaultMessage: 'Refresh',
		description: 'Label for the refresh button'
	}
});

const styles = createUseStyles((theme) => ({
	content: {
		flex: '1 1 auto',
		paddingLeft: contentPadding.left,
		paddingRight: contentPadding.right,
		paddingBottom: contentPadding.bottom,
		maxWidth: contentPadding.maxWidth,
		marginTop: theme.spacing(8),
		[theme.breakpoints.down('sm')]: {
			padding: contentPadding.mobile,
			margin: 0
		},

		'& button': {
			marginTop: theme.spacing(4)
		}
	}
}));

export default function AccountInvitation() {
	const classes = styles();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.current.result);
    const {working} = useSelector((state) => state.user.refresh);

	let textContent =  (
		<Typography variant='body1'>
			<FormattedMessage {...messages.invitationEmptyText}	/>
		</Typography>
	);
	if (user.hasInvitation) {
        textContent = (
			<Typography variant='body1'>
				<FormattedMessage
					{...messages.invitationText}
					values={{
						invitingOrg: user.hasInvitation,
						link: <Link path={routes.invitation}>
							<FormattedMessage {...messages.invitationLink} />
						</Link>
					}}
				/>

			</Typography>
        );
	}

	function refresh() {
		dispatch(refreshUser());
	}

	return (<>
		<ContentHeader
			backPath={routes.account} 
			backLabel={messages.accountLink} 
			title={messages.title} />
		<div className={classes.content}>
			{textContent}
			<AddButton action={refresh}
						label={messages.refresh}
						working={working}
						showIcon={false}
			/>
		</div>
	</>);
}