import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { CommonDialog, CommonDialogActions, ExternalLink } from 'omse-components';
import {defineMessages, useIntl} from 'react-intl';
import {createUseStyles} from 'react-jss';
import {handleRecipeInvitation} from "../../../../modules/recipes/actions";
import routePaths, {getLocation} from "../../../../util/routes";
import useRecipe from "../../../../hooks/useRecipe";
import {useHistory, useLocation} from 'react-router';
import useActionIdSelector from "../../../../hooks/useActionIdSelector";

const messages = defineMessages({
    dialogAcceptTitle: {
        id: 'ShareRecipeConfirmationDialog.dialogAcceptTitle',
        defaultMessage: 'Accept recipe',
        description: 'Title for the accept recipe dialog'
    },
    dialogRejectTitle: {
        id: 'ShareRecipeConfirmationDialog.dialogRejectTitle',
        defaultMessage: 'Reject recipe',
        description: 'Title for the reject recipe dialog'
    },
    dialogAcceptDescription: {
        id: 'ShareRecipeConfirmationDialog.acceptRecipeDescription',
        defaultMessage: 'When you accept a recipe, it is added to your organisation’s recipe library. It will show as "shared". You can create data packages from it but you can’t share the recipe with other organisations.',
        description: 'Text for label of accept recipe description dialog box'
    },
    dialogRejectDescription: {
        id: 'ShareRecipeConfirmationDialog.rejectRecipeDescription',
        defaultMessage: 'When you reject a recipe, it will not be added to your library and you will no longer be able to view these details.',
        description: 'Text for label of reject recipe description dialog box'
    },
    confirm: {
        id: 'ShareRecipeConfirmationDialog.confirm',
        defaultMessage: 'Accept recipe',
        description: 'Label for the accept recipe button on the dialog'
    },
    reject: {
        id: 'ShareRecipeConfirmationDialog.reject',
        defaultMessage: 'Reject recipe',
        description: 'Label for the reject recipe button on the dialog'
    },
    close: {
        id: 'ShareRecipeConfirmationDialog.close',
        defaultMessage: 'Cancel',
        description: 'Label for the cancel button on the dialog'
    },
    error: {
        id: 'ShareRecipeConfirmationDialog.error',
        defaultMessage: 'There was a problem handling the recipe invitation. Please try again later or {link} if the problem persists.',
        description: 'Error text that is shown if the accept/reject fails'
    }
});

const useStyles = createUseStyles(theme => ({
    content: {
        paddingRight: theme.spacing(6.5),
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(1)
        }
    }
}));

export default function ShareRecipeConfirmationDialog({onClose, confirmationType}) {
    const [{result, error, working}, dispatch] = useActionIdSelector("recipes.handleRecipeInvitation");
    const {recipe} = useRecipe();
    const location = useLocation();
    const history = useHistory();
    const intl = useIntl();

    const accepting = confirmationType === 'ACCEPT';

    useEffect(() => {
        // A POST with no reply comes back as a null result, which is different from the initial undefined state
        if(result === null) {
            const path = routePaths.recipeLibrary;
            const newLocation = getLocation(path, location, {}, { state: { recipeAccepted: accepting }});
            history.push(newLocation);
        }
    }, [result, history, location, accepting]);

    function onConfirm() {
        dispatch(handleRecipeInvitation(recipe.id, accepting));
    }


    const classes = useStyles();

    let confirm;
    let title;
    let description;


    if (confirmationType === 'ACCEPT') {
        confirm = messages.confirm;
        title = messages.dialogAcceptTitle;
        description = messages.dialogAcceptDescription;

    } else {
        confirm = messages.reject;
        title = messages.dialogRejectTitle;
        description = messages.dialogRejectDescription;
    }

    const actions = <CommonDialogActions onClose={onClose}
                                         closeLable={messages.close}
                                         confirmLabel={confirm}
                                         onConfirm={onConfirm}
                                         working={working}
    />;

    let errorToShow = null;
    if(error) {
        errorToShow = intl.formatMessage(messages.error, {
            link: <ExternalLink type='support' />
        });
    }

    return <CommonDialog onClose={onClose}
                         title={title}
                         actions={actions}
                         subtitle={description}
                         classes={{content: classes.content}}
                         error={errorToShow}
    />
}

ShareRecipeConfirmationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    confirmationType: PropTypes.oneOf(['ACCEPT', 'REJECT']).isRequired
}
