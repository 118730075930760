import React, {useEffect, useMemo, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {defineMessages, FormattedDate, FormattedMessage, useIntl} from 'react-intl';
import {CircularProgress, Drawer, Typography} from '@mui/material';
import {styles} from './DownloadStyles';
import features, {NGD_RECIPE_EDITING} from '../../../shared/features';
import FeatureCheck from '../../components/FeatureCheck';
import useRecipe from '../../hooks/useRecipe';
import routePaths, {getLocation, useRedirect} from '../../util/routes';
import {
    LinkButton,
    Notification, StyledCheckbox,
    ThemeTreeUtils,
    ExternalLink, contentPadding
} from 'omse-components';
import {useSelector} from 'react-redux';
import {useLocation, useHistory} from 'react-router';
import {hasEditNgdDownloadsPermission, hasPartnerCatalogue} from '../../util/permissions';
import Link from '../../components/Link';
import ThemeTree from './recipeDetails/ThemeTree';
import ItemDetails from './recipeEditor/ItemDetails';
import {loadPartnerContractCatalogue, loadPartnerContracts} from "../../modules/partner/actions";
import {ReactComponent as FilterIcon} from "../../components/icons/filter.svg";
import PopoutPanel from "./PopoutPanel";
import FeatureFilterReadOnlyPanel from "./recipeDetails/FeatureFilterReadOnlyPanel";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import RecipeDetailsHeader from "./recipeDetails/RecipeDetailsHeader";
import {getFeatureTypeSchema, setRecipeDescription} from "../../modules/recipes/actions";
import TabGroup from "../../components/TabGroup";
import ChangeLogTable, {RECIPE} from "./dataPackage/ChangeLogTable";
import BackLink from "../../components/BackLink";
import classNames from "classnames";
import EditableField from "../../components/EditableField";
import feature from "../../../shared/features";
import useFeatureCheck from "../../util/useFeatureCheck";
import useActionIdSelector from "../../hooks/useActionIdSelector";
import ExistingPackagesMessage, {NGD} from "../../components/ExistingPackagesMessage";


const messages = defineMessages({
    error: {
        id: 'RecipeDetails.error',
        defaultMessage: 'There was a problem loading your recipe. ' +
            'Please return to the {recipeLibrary} and try again. ' +
            'If the problem persists then {link}.',
        description: 'Label for the error message if the recipe cannot be loaded'
    },
    back: {
        id: 'RecipeDetails.back',
        defaultMessage: 'Recipe Library',
        description: 'Label for the back link within the error message'
    },
    created: {
        id: 'RecipeDetails.created',
        defaultMessage: 'Date created:',
        description: 'Label for the date created.'
    },
    author: {
        id: 'RecipeDetails.author',
        defaultMessage: 'Author:',
        description: 'Label for the created by.'
    },
    returnToTop: {
        id: 'RecipeDetails.returnToTop',
        defaultMessage: 'Back to recipe details',
        description: 'Label for the return to top button.'
    },
    filterText: {
        id: 'RecipeDetails.filterText',
        defaultMessage: 'Filters have been applied to this recipe.',
        description: 'Label to inform the use the recipe has filters applied.'
    },
    filterButtonText: {
        id: 'RecipeDetails.filterButtonText',
        defaultMessage: 'View all filters',
        description: 'Label for the button to open the read only filter panel.'
    },
    filterButtonLabel: {
        id: 'RecipeDetails.filterButtonLabel',
        defaultMessage: 'Show filters for {featureTypeLabel}',
        description: 'Label for the button to open the read only filter panel.'
    },
    openFilterPanel:{
        id: 'RecipeDetails.openFilterPanel',
        defaultMessage: 'Open filter panel for {featureType}',
        description: 'Open filter panel button label'
    },
    closeFilterPanel:{
        id: 'RecipeDetails.closeFilterPanel',
        defaultMessage: 'Close filter panel for {featureType}',
        description: 'Close filter panel button label'
    },
    openAllFilterPanel:{
        id: 'RecipeDetails.openAllFilterPanel',
        defaultMessage: 'Open filter panel for all filters',
        description: 'Open all filter panel button label'
    },
    closeAllFilterPanel:{
        id: 'RecipeDetails.closeAllFilterPanel',
        defaultMessage: 'Close filter panel for all filters',
        description: 'Close all filter panel button label'
    },
    checkbox: {
        id: 'RecipeDetails.checkbox',
        defaultMessage: 'Show schema version',
        description: 'Show schema version'
    },
    changeLog: {
        id: 'RecipeDetails.changeLog',
        defaultMessage: 'View change log',
        description: 'Label for view change log tab'
    },
    tabGroupAriaLabel: {
        id: 'RecipeDetails.tabGroupAriaLabel',
        defaultMessage: 'Change logs',
        description: 'Aria label for tabs'
    },
    buttonAriaLabel: {
        id: 'RecipeDetails.buttonAriaLabel',
        defaultMessage: 'Recipe description, click to edit',
        description: 'Label used for the aria-label attribute on the name button'
    },
    iconAriaLabel: {
        id: 'RecipeDetails.iconAriaLabel',
        defaultMessage: 'Click to edit the recipe description',
        description: 'Label used for the alt text on the edit icon'
    },
    inputAriaLabel: {
        id: 'RecipeDetails.inputAriaLabel',
        defaultMessage: 'Recipe description',
        description: 'Label used for the aria-label attribute on the name input field'
    },
    maxLength: {
        id: 'RecipeDetails.maxLength',
        defaultMessage: 'The recipe description must be less than 256 characters long',
        description: 'Label used for error message if the name is too long'
    },
    setDescriptionError: {
        id: 'RecipeDetailsHeader.setDescriptionError',
        defaultMessage: 'An error occurred while updating the description. Please try again and contact support if the problem continues.',
        description: 'Notification that is shown when a recipe description edit fails'
    },
    clickToAddDescription: {
        id: 'RecipeDetailsHeader.clickToAddDescription',
        defaultMessage: 'Click to add a description.',
        description: 'Label for the editable field when there is no recipe description'
    }
});

const useDownloadStyles = createUseStyles(styles);
const useStyles = createUseStyles(theme => ({
    scrollingThemeTree: {
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '500px',
        minWidth: '500px',
        maxWidth: '45vw',
        padding: theme.spacing(1)
    },
    filterButton: {
        width: '24px',
        height: '24px',
        padding: '0px'
    },
    filterMessage: {
        display: 'flex' // Flex does a nice job of vertically aligning the content in the filter message line
    },
    allFiltersButton: {
        marginLeft: theme.spacing(1)
    },
    reducedPadding:{
        paddingLeft: `${theme.spacing(1)} !important`,
        paddingRight: `${theme.spacing(1)} !important`
    },
    contentContainer:{
        display: 'flex',
        justifyContent: 'space-between',
        // Allows the container to match the header in width see download style inner header
        maxWidth: contentPadding.maxWidth + contentPadding.left * 3,
        flexDirection: 'row-reverse'
    },
    mobileContentContainer:{
        display: 'block'
    }
}));

export default function RecipeDetails() {
    const downloadClasses = useDownloadStyles();
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const redirect = useRedirect();
    const intl = useIntl();
    const user = useSelector(state => state.user.current.result);
    const {recipe, error: loadRecipeError} = useRecipe();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const recipeEditing = useFeatureCheck(feature.NGD_RECIPE_EDITING) && recipe && !recipe.sharedWithMe && hasEditNgdDownloadsPermission(user) && !recipe.deleted;

    const [showVersion, setShowVersion] = useState(false)
    const [showFilterPanel, setShowFilterPanel] = useState(false);
    const [overrideDatasets, setOverrideDatasets] = useState(null);
    const [popoutOpen, setPopoutOpen] = useState(false);
    const [openTabIndex, setOpenTabIndex] = useState(0);
    const [savedState] = useState({
        ...location?.state?.stored
    });
    // By using the action id selector this instance of the recipe details component won't display success/failure
    // from other instances.
    const [setDescriptionResult, dispatch] = useActionIdSelector("recipes.setDescription");
    const [notification, setNotification] = useState(null);

    // When the description is updated then an action is dispatched and eventually a result will come back. If the
    // action fails then we need to show an error. When the notification is dismissed the error state will still
    // be in the redux store, but as it doesn't change until the next attempt, the effect won't fire again until
    // a new result arrives.
    useEffect(() => {
        if(setDescriptionResult.error) {
            setNotification(<Notification variant='error'
                                          appearance='toast'
                                          autoClose={false}
                                          onClose={() => setNotification(null)}>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.setDescriptionError}/>
                </Typography>
            </Notification>);
        }
    }, [setDescriptionResult]);

    function setDescription(description) {
        dispatch(setRecipeDescription(recipe.id, description));
    }

    function handleShowVersionCheckbox() {
        setShowVersion(!showVersion)
    }

    let popoutProps = {
        openTabAriaLabel: messages.openAllFilterPanel,
        closeTabAriaLabel: messages.closeAllFilterPanel
    };
    // Get an up-to-date view of the partner state, if appropriate
    useEffect(() => {
        if(hasPartnerCatalogue(user)) {
            dispatch(loadPartnerContracts());
            dispatch(loadPartnerContractCatalogue());
        }
    }, [dispatch, user]);

    // Access theme tree (used for theme tree and also for docs page). We don't need to load it, as the tree view
    // component will take care of that
    const {result: themes, error: themeTreeError} = useSelector(state => state.themes.themeTree);

    // If our attempt to load the recipe failed then go to the 404 page
    useEffect(() => {
        if(loadRecipeError) {
            const newLocation = getLocation(routePaths.error404Recipe, location);
            history.push(newLocation);
        }
    }, [loadRecipeError, history, location]);

    if(overrideDatasets && overrideDatasets[0] && themes){
        const label = ThemeTreeUtils.findById(themes, overrideDatasets[0].featureTypeId).label;

        popoutProps.openTabAriaLabel = intl.formatMessage(messages.openFilterPanel, {featureType: label});
        popoutProps.closeTabAriaLabel = intl.formatMessage(messages.closeFilterPanel, {featureType: label});
    }

    // We're viewing a theme tree item's doc if its ID is included in the URL hash.
    const themeTreeItemId = location.hash ? location.hash.slice(1) : null;
    const isViewingThemeTreeItem = themeTreeItemId != null;


    // Display documentation when an item in the tree is selected. We do this by redirecting to a fragment link for
    // the item. We also need to load the matching schema if we have selected a feature type.
    const onItemSelected = item => {
        setShowFilterPanel(false);
        setPopoutOpen(false);
        if(item.featureId) {
            const feature = recipe.datasets.find(d => d.featureTypeId === item.featureId);
            const version = feature?.featureTypeVersion;

            dispatch(getFeatureTypeSchema(item.featureId, version));
        }
        redirect.push(location.pathname, { hash: ThemeTreeUtils.getId(item) });
    };

    let content = <CircularProgress size={32} className={downloadClasses.loader}/>;

    let datasets = recipe && recipe.datasets;

    const tabContent = useMemo(() => {
        const tabsToShow = [];
        if(recipe?.changeLog?.length) {
            tabsToShow.push({heading: messages.changeLog, component: <ChangeLogTable changeLog={recipe.changeLog} type={RECIPE} /> });
        }
        return tabsToShow;
    }, [recipe]);

    if(themeTreeError) {
        content = <Notification variant='error' appearance='inline'>
            <Typography variant='body1'>
                <FormattedMessage {...messages.error} values={{
                    link: <ExternalLink type='support' />,
                    recipeLibrary: <Link path={routePaths.recipeLibrary}>
                        <FormattedMessage {...messages.back}/>
                    </Link>}}/>
            </Typography>
        </Notification>;
    }
        // If a theme tree item is selected we display the documentation for that item.
    else if(isViewingThemeTreeItem) {
        if(themes && recipe) {
            const selectedThemesTreeItem = ThemeTreeUtils.findById(themes, themeTreeItemId);
            if(selectedThemesTreeItem && ThemeTreeUtils.isItemInRecipe(selectedThemesTreeItem, recipe.datasets)) {
                const feature = recipe.datasets.find(d => d.featureTypeId === selectedThemesTreeItem.featureId);
                const version = feature?.featureTypeVersion;
                content = <div className={classNames(downloadClasses.contentWithMobilePadding, classes.reducedPadding)}>
                    <ItemDetails    showResetButton='right'
                                    resetButtonLabel={messages.returnToTop}
                                    onResetClick={() => redirect.push(location.pathname)}
                                    listItem={selectedThemesTreeItem}
                                    scrollOnLoad={true}
                                    reducedPadding={true}
                                    fixedSchemaVersion={version}
                    />
                </div>;
            }
            // If the fragment does not match an item in this recipe then redirect back to the main details pane.
            else {
                redirect.replace(location.pathname);
            }
        }
    }
    // If no theme tree item is selected we display the main recipe details content.
    else if(recipe) {
        // If there is no description then we don't want to add extra whitespace into the page, so only put in
        // Typography if we actually have a description to show, or if the user has the chance to add one.
        const description = (recipe.description || recipeEditing) &&
            <EditableField  fieldValue={recipe.description || ""}
                            setFieldValue={setDescription}
                            readonly={!recipeEditing}
                            buttonAriaLabel={messages.buttonAriaLabel}
                            iconAriaLabel={messages.iconAriaLabel}
                            inputAriaLabel={messages.inputAriaLabel}
                            maxLength={255}
                            maxLengthMessage={messages.maxLength}
                            fieldTypeName={"description"}
                            placeholder={intl.formatMessage(messages.clickToAddDescription)}
            />;
        const filterMessage = (recipe.datasets && recipe.datasets.find(d => d.filterExpression)) &&
                <Typography variant='body1' paragraph={true} className={classes.filterMessage}>
                    <FilterIcon aria-hidden={true}/>
                    <FormattedMessage {...messages.filterText}/>
                    <LinkButton onClick={openFilterPanel} className={classes.allFiltersButton}>
                        <FormattedMessage {...messages.filterButtonText}/>
                    </LinkButton>
                </Typography>;

        function buildAction(item){
            const dataset = recipe.datasets.filter(dataset => dataset.featureTypeId === item.featureId)[0];
            return dataset.filterExpression ? <IconButton
                onClick={() => {
                    setPopoutOpen(true);
                    setShowFilterPanel(true);
                    setOverrideDatasets([dataset]);
                }}
                aria-label={intl.formatMessage(messages.filterButtonLabel, {featureTypeLabel: item.label})}
                className={classes.filterButton}
                size="large">
                <FilterIcon/>
            </IconButton> : <div className={classes.filterButton} aria-hidden={true}></div>;
        }
        content = <div className={isMobile ? classes.mobileContentContainer : classes.contentContainer}>
            <div>
                <ExistingPackagesMessage existingDataPackages={recipe.datapackages} product={NGD}/>
            </div>
            <div>
                {description}
                {filterMessage}
                <dl>
                    <MetaData label={messages.created}>
                        <FormattedDate value={recipe.created}
                                       day='numeric'
                                       month='long'
                                       year='numeric'/>
                    </MetaData>
                    <MetaData label={messages.author}>
                        {recipe.createdBy}
                    </MetaData>
                    <StyledCheckbox checked={showVersion} onClick={handleShowVersionCheckbox}
                                      label={messages.checkbox}/>
                </dl>
                {/*Make theme tree scroll for none mobile to preserve height of popout panel*/}
                <div className={!isMobile ? classes.scrollingThemeTree : null} >
                    <ThemeTree onItemSelected={onItemSelected} buildAction={buildAction} showVersion={showVersion} />
                </div>
            </div>
        </div>;

    }

    function closeFilterPanel(){
        if(isMobile) {
            setShowFilterPanel(false);
        } else {
            setPopoutOpen(false);
        }
    }

    function openFilterPanel(){
        setPopoutOpen(true);
        setShowFilterPanel(true);
        // Stops it changing back to all data sets as the panel is closing
        setOverrideDatasets(null);
    }

    const filterContent =  <FeatureFilterReadOnlyPanel single={!!overrideDatasets}
                                                       datasets={overrideDatasets ? overrideDatasets : datasets}
                                                       closePanel={closeFilterPanel}/>;
    const tabs = useFeatureCheck(NGD_RECIPE_EDITING) ? <div className={downloadClasses.contentWithMobilePadding}>
        <TabGroup tabs={tabContent}
                  tabGroupAriaLabel={messages.tabGroupAriaLabel}
                  onTabChange={setOpenTabIndex}
                  openTab={openTabIndex}
        />
    </div> : <div className={downloadClasses.contentWithMobilePadding} />;

    return <FeatureCheck feature={features.NGD_DOWNLOADS}>
        <BackLink path={routePaths.recipeLibrary} label={messages.back} state={savedState} />
        <div className={downloadClasses.rootWithBackLink}>
            <RecipeDetailsHeader />
            <div >
                {content}
            </div>
        </div>
        {tabs}
        {isMobile ?
            <Drawer anchor='bottom'
                    open={showFilterPanel}
                    onClose={closeFilterPanel} >
                {filterContent}
            </Drawer> :
            <PopoutPanel panelContent={filterContent}
                         open={popoutOpen}
                         setOpen={setPopoutOpen}
                         visible={showFilterPanel}
                         {...popoutProps}
            />
        }
        {
            notification
        }
    </FeatureCheck>;
}

const metaDataStyles = createUseStyles(theme => ({
    aligned: {
        display: 'flex',
    },
    padRight: {
        marginRight: theme.spacing(1)
    }
}));

function MetaData({label, children}) {
    const myClasses = metaDataStyles();

    return <div className={myClasses.aligned}>
        <Typography component='dt' variant='body1' color='textSecondary' className={myClasses.padRight}>
            <FormattedMessage {...label}/>
        </Typography>
        <Typography component='dd' variant='body1'>
            {children}
        </Typography>
    </div>;
}
