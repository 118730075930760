import React, {useState} from 'react';
import PropTypes from 'prop-types'
import FCPDialog from "./FCPDialog";
import ContractDialog from "./ContractDialog";
import ProductDialog from "./ProductDialog";

export default function LicenceDialog(props) {
    const {contract, firstContract, product, needsProductTerms, productDependencies, onConfirm, onClose} = props;

    const [showFCP, setShowFCP] = useState(firstContract);
    const [term, setTerm] = useState(0);

    if (showFCP) {
        return <FCPDialog onConfirm={() => setShowFCP(false)} onClose={onClose}/>;
    }
    if (term === 0) {
        return <ContractDialog contract={contract} onConfirm={term => {
            if (needsProductTerms || productDependencies.length) {
                // Save the term for later
                setTerm(term);
            } else {
                onConfirm(term);
            }
        }} onClose={onClose}/>;
    }
    if (needsProductTerms || productDependencies.length) {
        return <ProductDialog contract={contract}
                              product={product}
                              needsProductTerms={needsProductTerms}
                              productDependencies={productDependencies}
                              onConfirm={() => onConfirm(term)}
                              onClose={onClose}/>;
    }
    return null;
}

LicenceDialog.propTypes = {
    contract: PropTypes.object.isRequired,
    product: PropTypes.object,
    firstContract: PropTypes.bool.isRequired,
    needsProductTerms: PropTypes.bool,
    productDependencies: PropTypes.arrayOf(PropTypes.object),
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}
