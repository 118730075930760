import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const StyledEmptyState = styled.div(({theme}) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 0;
    & > div {
        width: 224px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    & > div > img {
        margin-bottom: 0.6em;
    }
    & > div > p {
        text-align: center;
        color: ${theme.palette.textPrimary.main};
    }
    & > div > button {
        margin-top: 1em;
    }
`);

const RecentItemsEmpty = ({message, icon, iconWidth, iconAlt, actionButton}) => (
    <StyledEmptyState>
        <div>
            <img alt={iconAlt} src={icon} width={iconWidth} />
            <Typography>
                <FormattedMessage {...message} />
            </Typography>
            {actionButton}
        </div>
    </StyledEmptyState>
);

RecentItemsEmpty.defaultProps = {
    iconWidth: 124,
    iconAlt: ''
}

RecentItemsEmpty.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string,
        description: PropTypes.string
    }).isRequired,
    icon: PropTypes.string.isRequired,
    iconWidth: PropTypes.number,
    iconAlt: PropTypes.string,
    actionButton: PropTypes.object
}

export default RecentItemsEmpty;