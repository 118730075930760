import { Typography } from '@mui/material';
import { defineMessages, FormattedMessage } from 'react-intl';
import {createUseStyles} from 'react-jss';
import Document from '../documentation/Document';
import Header from '../documentation/Header';
import style from './styles/legal';

const useStyles = createUseStyles(style);

const useVNTermStyles = createUseStyles({
    // Override h2 styles for this particular page as we don't wont to
    // change global h2 styles too much
    subHeading: {
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: 1.235,
    },
});

export const messages = defineMessages({
    title: {
        id: 'VernacularNamesTerms.title',
        defaultMessage: 'Vernacular Names Terms',
        description: 'Vernacular Names Terms Title',
    },
    subtitle: {
        id: 'VernacularNamesTerms.subtitle',
        defaultMessage: 'OS Data Hub Vernacular Names Terms',
        description: 'OS Data Hub Vernacular Names Terms Title',
    },
});

export function VernacularNamesTerms() {
    const classes = useStyles();
    const classesVNTerms = useVNTermStyles();
    return (
        <Document>
            <Header back>
                <FormattedMessage {...messages.title} />
            </Header>
            <div className={classes.content}>
                <Typography variant={'h2'}>
                    <FormattedMessage {...messages.subtitle} />
                </Typography>
                <Typography variant={'h5'} component={'div'} aria-label={'agreement-section'}>
                    <ol className={classes.bulletedList}>
                        <li>
                            This Agreement sets out the terms on which Ordnance Survey Limited (company registration
                            number 09121572) (<strong>we, us, our, OS</strong>) makes the Vernacular Names Reporting
                            online tool (the <strong>Vernacular Names Tool</strong>) available to its customers,
                            including you, the person or entity entering into this Agreement (<strong>you </strong>
                            and <strong>your</strong>). You and we are each a <strong>party</strong> and together the{' '}
                            <strong>parties</strong>.
                        </li>
                        <li>
                            You warrant that you have entered into the OS Data Hub Terms; in the event of any conflict
                            or inconsistency between these terms and the OS Data Hub Terms in relation to the Vernacular
                            Names Tool, these terms will have precedence.
                        </li>
                        <li>
                            By using the Vernacular Names Tool (including accessing any data made available in the
                            tool), you indicate your acceptance of these terms.
                        </li>
                        <li>
                            You warrant that: a) you hold, and shall continue to hold throughout the term of this
                            Agreement, a PSGA member licence; and b) you are, and shall continue to be throughout the
                            term of this Agreement, a member of an Emergency Services organisation, as such term is
                            defined in the PSGA member licence.
                        </li>
                    </ol>
                </Typography>
                <Typography variant="h5" component="div" className={classes.numberedList} aria-label={'terms-section'}>
                    <Typography variant="h2" className={classesVNTerms.subHeading}>
                        Intellectual Property Rights
                    </Typography>
                    <ol>
                        <li>
                            The Vernacular Names Tool is provided to allow you to provide vernacular names and change
                            intelligence (together, <strong>Change Data</strong>) to Ordnance Survey.
                        </li>
                        <li>
                            Neither party claims any ownership of any intellectual property rights which arise/subsist
                            in the Change Data. Accordingly, Ordnance Survey shall be entitled to display, use, store,
                            reproduce, make derivative works of, and make your Change Data available to third parties,
                            for any other purpose. (You agree to waive any moral rights or other rights subsisting in
                            your Change Data).
                        </li>
                        <li>
                            Where permissible by law, Ordnance Survey may disclose your identity and any other necessary
                            information to any third party who claims that any Change Data submitted by you constitutes
                            a violation of their intellectual property or other legal rights.
                        </li>
                        <li>
                            Ordnance Survey grants you a licence to use the data accessible via the Vernacular Names
                            Tool (the <strong> VN Data</strong>) for the sole purpose of supplying Change Data to
                            Ordnance Survey. To be clear, the VN Data includes any existing submissions of vernacular
                            names that have already been submitted to the Vernacular Names Tool and that are visible in
                            the data (<strong>Existing Vernacular Names</strong>
                            ).
                        </li>
                        <li>
                            You are not permitted to copy, sub-license, distribute, sell or otherwise make available the
                            VN Data to third parties in any form, or to otherwise benefit from or rely on the VN Data,
                            the same terms as permitted under your PSGA member licence.
                        </li>
                    </ol>
                </Typography>
                <Typography
                    variant={'h5'}
                    component={'div'}
                    className={`${classes.numberedList}`}
                    aria-label={'terms-section'}
                >
                    <Typography variant={'h2'} className={classesVNTerms.subHeading}>
                        Warranties and Liability
                    </Typography>
                    <ol>
                        <li>
                            You warrant that:
                            <ol type="a" className={classes.alphabeticList}>
                                <li>
                                    you have the authority to make the Change Data available to us in accordance with
                                    Clause A2;
                                </li>
                                <li>you have full power to enter into this Agreement;</li>
                                <li>
                                    your supply and assignment of the Change Data will not infringe any third party
                                    intellectual property rights; and
                                </li>
                                <li>
                                    although the Change Data may not be error free, you provide it to Ordnance Survey in
                                    good faith and have used reasonable endeavours to ensure its accuracy and
                                    completeness.
                                </li>
                            </ol>
                        </li>
                        <li>
                            You shall not be liable to Ordnance Survey for misuse by Ordnance Survey of the Change Data.
                        </li>
                        <li>
                            You shall ensure that any Change Data will NOT include any of the following (Restricted
                            Content):
                            <ol type="a" className={classes.alphabeticList}>
                                <li>material which is illegal or promotes illegality;</li>
                                <li>misleading information;</li>
                                <li>material which is likely to cause harm to others;</li>
                                <li>
                                    material which is threatening, abusive, or insulting, which includes without
                                    limitation material which is likely to stir up racial hatred;
                                </li>
                                <li>material which is offensive or indecent;</li>
                                <li>defamatory material;</li>
                                <li>
                                    material which infringes copyright or any other intellectual property rights of a
                                    third party;
                                </li>
                                <li>advertisements;</li>
                                <li>personal information about you or a third party; or</li>
                                <li>material which contains a virus or other harmful component.</li>
                            </ol>
                        </li>
                        <li>
                            The Vernacular Names Tool are made available ‘as is’ and Ordnance Survey excludes all
                            representations, warranties, obligations and liabilities in relation to such information to
                            the maximum extent permitted by law. Ordnance Survey is not liable for any errors or
                            omissions in the Vernacular Names Tool and shall not be liable for any loss, injury or
                            damage of any kind caused by their use.
                        </li>
                        <li>
                            Ordnance Survey may suspend access to the Vernacular Names Tool for any reason, including
                            but not limited to repairs, planned maintenance or upgrades, and shall not be liable to you
                            for any such suspension, or for any delay or interruption in the availability of the
                            Vernacular Names Tool.
                        </li>
                    </ol>
                </Typography>
                <Typography
                    variant={'h5'}
                    component={'div'}
                    className={classes.numberedList}
                    aria-label={'terms-section'}
                >
                    <Typography variant={'h2'} className={classesVNTerms.subHeading}>
                        Terms and Termination
                    </Typography>
                    <ol>
                        <li>
                            Ordnance Survey may terminate this Agreement at any time at its sole discretion either by
                            notice to you in writing and/or by removing your access rights to the Vernacular Names Tool.
                        </li>
                    </ol>
                </Typography>
                <Typography
                    variant={'h5'}
                    component={'div'}
                    className={classes.numberedList}
                    aria-label={'terms-section'}
                >
                    <Typography variant={'h2'} className={classesVNTerms.subHeading}>
                        General
                    </Typography>
                    <ol>
                        <li>
                            Subject to Clause D2, neither party may assign or transfer their rights or obligations under
                            this Agreement without the prior written consent of the other party.
                        </li>
                        <li>
                            Ordnance Survey may assign or transfer rights and obligations under this Agreement to any
                            government body, nominated subcontractor or any entity to which the activities or functions
                            of Ordnance Survey have been transferred.
                        </li>
                        <li>This Agreement shall be governed by the law of England and Wales.</li>
                    </ol>
                </Typography>
            </div>
        </Document>
    );
}

export default VernacularNamesTerms;
