import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages} from 'react-intl';
import ProjectModeModal from "./ProjectModeModal";

const messages = defineMessages({
    toDevTitle: {
        id: 'LiveToDevModal.toDevTitle',
        defaultMessage: 'Change project mode to Development?',
        description: 'Title for the change dialog when switching to dev mode'
    },
    toDevButton: {
        id: 'LiveToDevModal.toDevButton',
        defaultMessage: 'Set project to Development mode',
        description: 'Title for the accept button when switching to dev mode'
    },
    toDevHeading: {
        id: 'LiveToDevModal.toDevHeading',
        defaultMessage: 'Setting a project to development mode:',
        description: 'Heading used when setting the project into dev mode'
    },
    toDevItem1: {
        id: 'LiveToDevModal.toDevItem1',
        defaultMessage: 'gives you unlimited Premium data transactions (which do not take from your monthly free allocation)',
        description: 'Text used when setting the project into dev mode'
    },
    eaiToDevItem1: {
        id: 'LiveToDevModal.eaiToDevItem1',
        defaultMessage: 'Gives you premium data transactions to use in trials or tests within your business or organisation for a maximum period of 90 days to determine whether you wish to license the APIs for your internal business use',
        description: 'Text used when setting the project into dev mode for EAI customers'
    },
    toDevItem2: {
        id: 'LiveToDevModal.toDevItem2',
        defaultMessage: 'throttles premium data transactions more aggressively down to 50 transactions per minute',
        description: 'Text used when setting the project into dev mode'
    },
    toDevWarning: {
        id: 'LiveToDevModal.toDevWarning',
        defaultMessage: 'You may ONLY use Development mode for projects that are NOT released live. Using Development mode for projects that are in a production environment breaks the terms and conditions of use of this service.',
        description: 'Warning used when setting the project into dev mode'
    },
    toDevCheckboxText: {
        id: 'LiveToDevModal.toDevCheckboxText',
        defaultMessage: 'This project is not used in a production environment.',
        description: 'Text used when setting the project into dev mode'
    }
});

export default function LiveToDevModal({onClose, isEai}) {
    const points = isEai ? [messages.eaiToDevItem1, messages.toDevItem2] : [messages.toDevItem1, messages.toDevItem2];

    return <ProjectModeModal onClose={onClose}
                             title={messages.toDevTitle}
                             confirmLabel={messages.toDevButton}
                             termsLabel={messages.toDevCheckboxText}
                             heading={messages.toDevHeading}
                             points={points}
                             warning={messages.toDevWarning}
                             targetMode='dev'
    />
}

LiveToDevModal.defaultProps={
    isEai: false
}

LiveToDevModal.propTypes = {
    isEai: PropTypes.bool,
    onClose: PropTypes.func.isRequired
};
