import React, {useState} from 'react';
import {connect} from 'react-redux';
import withStyles from 'react-jss';
import { Avatar, Box, Typography, List, ListItem, ListItemAvatar, Button } from '@mui/material';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {injectIntl, defineMessages, FormattedMessage} from "react-intl";
import {border1, osColour, AddButton, CommonDialog, Notification} from 'omse-components';
import ProductDocumentation from './ProductDocumentation';
import ServiceTag from './ServiceTag'
import {addProductToProject} from '../modules/project/actions';
import {ReactComponent as MapIcon} from "./icons/map.svg";
import {startProductFreeTrial} from '../modules/products';
import ProductFreeTrialMessage from '../pages/products/ProductFreeTrialMessage';
import ProductFreeTrialButton from '../pages/products/ProductFreeTrialButton';
import ProductFreeTrialHoverTooltip from '../pages/products/ProductFreeTrialHoverTooltip';
import {canUseProduct} from '../../shared/product-util';
import SearchBox from "./SearchBox";
import {ACTIVE} from '../../shared/free-trial-state';
import ProductUnlockSteps from '../pages/products/ProductUnlockSteps';
import {COST_TYPE_PREMIUM, COST_TYPE_MIXED} from '../../shared/constants';
import {LIVE_MODE} from '../../shared/project-modes';
import featureCheck from '../util/featureCheck';
import {EAIAPI} from '../../shared/features';
import { isEaiUser } from "../util/plans";

const messages = defineMessages({
    searchLabel: {
        id: 'ProductList.searchLabel',
        defaultMessage: 'Search API products',
        description: 'Label for the product search box'
    },
    searchPlaceholder: {
        id: 'ProductList.searchPlaceholder',
        defaultMessage: 'Search by API product name',
        description: 'Placeholder for the product search box'
    },
    addLabel: {
        id: 'ProductList.addLabel',
        defaultMessage: 'Add to project',
        description: 'Label for the add to project button'
    },
    addedLabel: {
        id: 'ProductList.addedLabel',
        defaultMessage: 'Added to project',
        description: 'Label for the added to project button'
    },
    noResults: {
        id: 'ProductList.noResults',
        defaultMessage: 'No products match your search',
        description: 'Label for no results'
    },
    clearSearch: {
        id: 'ProductList.clearSearch',
        defaultMessage: 'Clear search',
        description: 'Clear search label'
    },
    unableToAdd:{
        id: "ProductList.unableToAdd",
        defaultMessage: "Unable to add",
        description: "Disabled button text for com3 users"
    }
});

const styles = theme => ({
    muiDialogRoot: {
        height: '60rem',
        width: '100%',
        maxWidth: 650,
        [theme.breakpoints.down('md')]: {
            height: '100%',
            width: '100%'
        }
    },
    dialogContent: {
        borderTop: `solid ${osColour.neutral.mist} 1px`,
        padding: '17px 40px 0 40px'
    },
    productList: {
      margin: 0,
      padding: 0,
    },
    serviceTag: {
        [theme.breakpoints.up('sm')]: {
            float: 'right'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1)
        }
    },
    listItemRoot: {
        display: 'block',
        borderBottom: border1,
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 0,
        paddingRight: 0,
        alignItems:'flex-start',

        '&:last-of-type': {
            border: 0
        }
    },
    button: {
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1) // Needed when buttons stack on small mobiles.
        },
    },
    freeTrialButton: {
        marginRight: theme.spacing(2)
    },
    searchOption: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 0 16px 0',
        borderBottom: border1,
    },
    header: {
        display: 'flex',
        flex: '1 1 auto',
        marginBottom: theme.spacing(2),
    },
    productName: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2)
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1)
        }
    },
    actionsRow: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: theme.spacing(-1), // Offset button margin bottom to balance padding.
        },
    },
    actionsContainer: {
        // If adjusting these styles ensure you handle the start free trial button being present as well.
        marginLeft: 'auto',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            marginLeft: 0,
            whiteSpace: 'normal',
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right'
        }
    },
    productUnlockSteps: {
        marginTop: `${theme.spacing(1)} !important`
    },
    productStatus: {
        fontSize: '1rem'
    },
    mapIcon: {
        color: osColour.neutral.stone
    },
    infoNotification: {
        marginBottom: theme.spacing(3),
        minWidth: 460,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'initial'
        }
    },
    errorMessages: {
        color: osColour.status.error
    }
});


export function AddAPIDialog (props) {


    const [search, setSearch] = useState('');

    let filteredProducts = props.products;

    const filterProducts = (products, searchTerm) => {
        return products.filter(product => {
            return product.name.toLowerCase().includes(searchTerm) ||
                product.displayName.toLowerCase().includes(searchTerm) ||
                (product.dataHubId && product.dataHubId.toLowerCase() === searchTerm)
        });
    }

    if(filteredProducts && search) {
        const searchTerm = search.toLowerCase();
        filteredProducts = filterProducts(filteredProducts, searchTerm);
    }

    const handleClose = () => {
            props.handleClose()
    }



    const resetSearch = () => {
            setSearch('')
    }

    const getItems = () => {
        const {
            classes,
            project,
            addProductToProject,
            creating,
            actionId,
            user,
            apiPlans,
            config
        } = props;
        const isLiveProject = project.mode === LIVE_MODE;
        return filteredProducts.map(product => {
            const isProductAdded = project.products ? project.products.some(p => product.name === p.id) : false;

            let buttonLabel = isProductAdded ? messages.addedLabel : messages.addLabel;

            const com3UserHasApiPlan = isEaiUser(user) && apiPlans?.length > 0;
            const productIsPremiumOnly = product.costType === COST_TYPE_PREMIUM;
            const productIsMixed = product.costType === COST_TYPE_MIXED;
            const com3LiveProjectNoPlans = isEaiUser(user) && isLiveProject && !com3UserHasApiPlan;
            const com3CannotAddPremium =  com3LiveProjectNoPlans && productIsPremiumOnly;
            const mixedWarning = productIsMixed && com3LiveProjectNoPlans && !isProductAdded;
            if(com3CannotAddPremium){
                buttonLabel = messages.unableToAdd
            }

            return (<ListItem key={product.name} classes={{root: classes.listItemRoot}}>
                <div className={classes.serviceTag}>
                    { product.serviceType && <ServiceTag label={product.serviceType}/> }
                    { product.subServiceType && <ServiceTag label={product.subServiceType}/> }
                </div>
                <div className={classes.header}>
                    <ListItemAvatar>
                        <Avatar>
                            <MapIcon height={24} width={24} alt='' className={classes.mapIcon} />
                        </Avatar>
                    </ListItemAvatar>

                    <div className={classes.productName}>
                        <Typography variant='subtitle1' component='h2'>{product.displayName}</Typography>
                        <div>
                            <ProductDocumentation productName={product.name} productServiceType={product.serviceType} productSubServiceType={product.subServiceType}/>
                        </div>
                    </div>
                </div>
                <div className={classes.actionsRow}>
                    <ProductFreeTrialMessage product={product} />
                    <div className={classes.actionsContainer}>
                        <ProductFreeTrialButton className={classNames(classes.button, classes.freeTrialButton)}
                                                product={product} />
                        <ProductFreeTrialHoverTooltip product={product}>
                            <AddButton disabled={isProductAdded || !canUseProduct(product) || com3CannotAddPremium}
                                       className={classes.button}
                                       color={product.freeTrialState === ACTIVE ? 'secondary' : 'primary'}
                                       label={buttonLabel}
                                       showIcon={false}
                                       working={(actionId === product.name) && creating}
                                       action={() => addProductToProject(product, {projectId: project.id}, product.name)} />
                        </ProductFreeTrialHoverTooltip>
                    </div>
                </div>
                {featureCheck(EAIAPI, config) &&
                    <Box className={classes.errorMessages}>
                        {mixedWarning &&
                            <Typography variant="caption">This product contains open and premium data, which you may not be able to access without an API Plan</Typography>
                        }
                        {com3CannotAddPremium &&
                            <Typography variant="caption">This product is only available with a purchased API Plan</Typography>
                        }
                    </Box>
                }

                <ProductUnlockSteps className={classes.productUnlockSteps} product={product} />
            </ListItem>);
        });
    }

    const {classes, title, titleValues, intl} = props;

        const actions = (
            <Button
                onClick={handleClose}
                color='primary'
                size='small'
                variant='outlined'
                className={classes.dialogButton}
            >
                Done
            </Button>
        )

        return (
            <CommonDialog
                onClose={handleClose}
                title={title}
                titleValues={titleValues}
                contentClassName={classes.dialogContent}
                actions={actions}
                muiClasses={{ paperWidthSm: classes.muiDialogRoot }}
            >
                <SearchBox
                    label={messages.searchLabel}
                    placeholder={messages.searchPlaceholder}
                    search={search}
                    setSearch={setSearch}
                    autofocus={true}
                    className={classes.searchOption}
                />
                <div aria-live='polite'>
                    {(filteredProducts.length === 0) && (
                        <Notification
                            variant='info'
                            appearance='inline'
                            onClose={resetSearch}
                            classes={{ root: classes.infoNotification }}
                            closeAriaLabel={intl.formatMessage(messages.clearSearch)}
                        >
                            <Typography variant='body1'>
                                <FormattedMessage {...messages.noResults} />
                            </Typography>
                        </Notification>
                    )}
                    {filteredProducts.length > 0 && (
                        <List className={classes.productList}>
                            {getItems()}
                        </List>
                    )}
                </div>
        </CommonDialog>
        );
    }


AddAPIDialog.propTypes = {
    title: PropTypes.object.isRequired,
    titleValues: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    addProductToProject: PropTypes.func.isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    project: PropTypes.object.isRequired,
    apiPlans: PropTypes.arrayOf(PropTypes.object)
};

const mapDispatchToProps = function(dispatch){
    return {
        addProductToProject:(product, project, actionId)=>dispatch(addProductToProject(product, project, actionId)),
        startProductFreeTrial: (product, actionId)=>dispatch(startProductFreeTrial(product, actionId)),
    }
}

export function mapStateToProps() {
    return function(state) {
        return {
            actionId: state.project.addProduct.actionId,
            creating: state.project.addProduct.working,
            user: state.user.current.result,
            config: state.config.current.result,
        }
    }
}
const withIntl = injectIntl(AddAPIDialog);
const styled = withStyles(styles)(withIntl);
export default connect(mapStateToProps, mapDispatchToProps)(styled);
