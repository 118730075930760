import React, {useEffect} from 'react';
import {isNgdDataPackage} from "../../../shared/ngd";
import ExpandNgdDataPackage from "./expandDataPackage/ExpandNgdDataPackage";
import ExpandPremiumDataPackage from "./expandDataPackage/ExpandPremiumDataPackage";
import routes, {useRedirect} from "../../util/routes";
import {useLocation, useParams} from "react-router-dom";
import {useSelector} from "react-redux";


export default function ExpandDataPackage() {
    const redirect = useRedirect();
    const location = useLocation();
    const params = useParams();
    const dataPackageId = params.packageId;
    const {working, result} = useSelector(state => state.dataPackages.create);


    let dataPackage = location.state && location.state.dataPackage;
    if(dataPackage && dataPackage.id !== dataPackageId) {
        dataPackage = null;
    }

    useEffect(() => {
        const expandRequested = working || result;
        if(expandRequested) {
            // Don't re-direct if we've submitted an expand request, as this causes the data packaged to be locked when it
            // returns which then causes us to immediately redirect to the data package page. But we want to stay on this
            // page so the user can see the success panel. (Create result is cleared every time the component re-mounts so
            // this should work even when returning to this component after previously creating/expanding a data package).
            return;
        }

        if (!dataPackage || dataPackage.locked) {
            redirect.replace(routes.dataPackage.replace(":packageId", dataPackageId));
        }
    }, [working, result, dataPackage, redirect, dataPackageId]);

    if (!dataPackage)
        return // oops, someone typed in their own URL; back they go!

    if (isNgdDataPackage(dataPackage)) {
        return <ExpandNgdDataPackage />
    } else {
        return <ExpandPremiumDataPackage />
    }
}
