import {registerComponent} from "./util/routes";
import History from "./pages/History";
import APIProject from "./pages/APIProject";
import APIProjects from "./pages/APIProjects";
import Products from "./pages/Products";
import DownloadsOverview from "./pages/documentation/downloads/DownloadsOverview";
import DownloadsIntro from "./pages/documentation/downloads/DownloadsIntro";
import DownloadsDetail from "./pages/documentation/downloads/DownloadsDetail";
import OAuthOverview from "./pages/documentation/oauth/OAuthOverview";
import OAuthIntro from "./pages/documentation/oauth/OAuthIntro";
import OAuthDetail from "./pages/documentation/oauth/OAuthDetail";
import WFSOverview from "./pages/documentation/WFSOverview";
import WFSIntro from "./pages/documentation/WFSIntro";
import WFSDetail from "./pages/documentation/WFSDetail";
import WMTSOverview from "./pages/documentation/WMTSOverview";
import WMTSIntro from "./pages/documentation/WMTSIntro";
import WMTSDetail from "./pages/documentation/WMTSDetail";
import VTSOverview from "./pages/documentation/VTSOverview";
import VTSIntro from "./pages/documentation/VTSIntro";
import VTSDetail from "./pages/documentation/VTSDetail";
import MatchOverview from "./pages/documentation/MatchOverview";
import MatchIntro from "./pages/documentation/MatchIntro";
import MatchDetail from "./pages/documentation/MatchDetail";
import NamesOverview from "./pages/documentation/NamesOverview";
import NamesIntro from "./pages/documentation/NamesIntro";
import NamesDetail from "./pages/documentation/NamesDetail";
import LinkedIdentifiersOverview from "./pages/documentation/LinkedIdentifiersOverview";
import LinkedIdentifiersIntro from "./pages/documentation/LinkedIdentifiersIntro";
import LinkedIdentifiersDetail from "./pages/documentation/LinkedIdentifiersDetail";
import PlacesOverview from "./pages/documentation/PlacesOverview";
import PlacesIntro from "./pages/documentation/PlacesIntro";
import PlacesDetail from "./pages/documentation/PlacesDetail";
import Index from "./pages/documentation/Index";
import Dashboard from "./pages/Dashboard";
import Workspace from "./pages/Workspace";
import Account from "./pages/Account";
import SupportIndex from "./pages/support/faqs/Index";
import SupportPlans from "./pages/support/faqs/Plans";
import OpenDataDownloads from "./pages/download/dataDownloads/openDataDownloads/OpenDataDownloads";
import OpenDataItem from "./pages/download/dataDownloads/openDataDownloads/OpenDataItem";
import PremiumDownloads from "./pages/download/dataDownloads/premiumDataDownloads/PremiumDownloads";
import PremiumItem from "./pages/download/dataDownloads/premiumDataDownloads/PremiumItem";
import RecipeDetails from "./pages/download/RecipeDetails";
import RecipeEditor from "./pages/download/RecipeEditor";
import RecipeLibrary from "./pages/download/RecipeLibrary";
import AddDataPackagePremium from "./pages/download/premiumItem/AddDataPackagePremium";
import AddDataPackageNgd from "./pages/download/premiumItem/AddDataPackageNgd";
import ExpandDataPackage from "./pages/download/ExpandDataPackage";
import DataPackages from "./pages/download/DataPackages";
import DataPackage from "./pages/download/DataPackage";
import ManageLicences from "./pages/download/licences/ManageLicences";
import Landing from "./pages/Landing";
import Plans from "./pages/Plans";
import LegalOverview from './pages/legal/LegalOverview';
import LegalTermsPage from "./pages/legal/LegalTermsPage";
import ErrorReportingTerms from "./pages/legal/ErrorReportingTerms";
import VernacularNamesTerms from "./pages/legal/VernacularNamesTerms";
import FrameworkContractTerms from './pages/legal/FrameworkContractTerms';
import PartnerContracts from "./pages/legal/PartnerContracts";
import SLA from './pages/legal/SLA';
import TrialModeTerms from './pages/legal/TrialModeTerms';
import ServiceStatus from "./pages/support/ServiceStatus";
import ServiceStatusApi from "./pages/support/ServiceStatusApi";
import Error404 from "./pages/Error404";
import ErrorOmissions from "./pages/ErrorsOmissions";
import ErrorOmissionsLanding from "./pages/ErrorOmissionsLanding";
import HoldingPage from "./pages/holding/HoldingPage";
import VernacularNamesLanding from "./pages/VernacularNamesLanding";
import VernacularNamesReporting from "./pages/VernacularNamesReporting";
import BillingInformation from "./pages/account/BillingInformation";
import Payments from "./pages/account/Payments";
import ContactPreferences from "./pages/account/ContactPreferences";
import BrandImportance from "./pages/documentation/brandLogo/BrandImportance";
import LogoOverview from "./pages/documentation/brandLogo/LogoOverview";
import LogoFullColour from "./pages/documentation/brandLogo/LogoFullColour";
import LogoWhite from "./pages/documentation/brandLogo/LogoWhite";
import CopyrightOverview from "./pages/documentation/brandLogo/CopyrightOverview";
import LogoCopyrightPositioning from "./pages/documentation/brandLogo/LogoCopyrightPositioning";
import ExclusionZone from "./pages/documentation/brandLogo/ExclusionZone";
import MinMax from "./pages/documentation/brandLogo/MinMax";
import ProhibitedUse from "./pages/documentation/brandLogo/ProhibitedUse";
import ManageTeamMembers from "./pages/account/ManageTeamMembers";
import CompanyInformation from "./pages/account/CompanyInformation";
import PremiumPlanSetup from "./pages/planUpgrade/PremiumPlanSetup";
import PaymentSetup from "./pages/planUpgrade/PaymentSetup";
import PsgaPlanSetup from "./pages/planUpgrade/PsgaPlanSetup";
import EnergyAndInfrastructurePlanSetup from "./pages/newUser/energyAndInfrastructure/EnergyAndInfrastructurePlanSetup";
import Invitation from './pages/Invitation';
import AccountInvitation from './pages/account/AccountInvitation';
import LogInError from "./pages/LogInError";
import SupportDownload from "./pages/support/faqs/Download";
import SupportOsSelectAndBuild from "./pages/support/faqs/OsSelectAndBuild";
import OFAOverview from "./pages/documentation/OFAOverview";
import OFAIntro from "./pages/documentation/OFAIntro";
import OFADetail from "./pages/documentation/OFADetail";
import OTAOverview from "./pages/documentation/OTAOverview";
import OTAIntro from "./pages/documentation/OTAIntro";
import OTADetail from "./pages/documentation/OTADetail";
import MyApiPlans from "./pages/MyApiPlans";
import EaiPlanPricing from './pages/apiPlans/ApiPlansPricing';
import AddApiPlan from './pages/apiPlans/AddApiPlan';
import ConfirmApiPlanPurchase from "./pages/apiPlans/ConfirmApiPlanPurchase";

// Register the components that make up the primary app pages so that we have access to the matching component
// when we need to render the route. We register them here to avoid circular dependencies when loading the
// util/routes module.
registerComponent('history', History);
registerComponent('project', APIProject);
registerComponent('projects', APIProjects);
registerComponent('workspace', Workspace)
registerComponent('apis', Products);
registerComponent('downloadsOverview', DownloadsOverview);
registerComponent('downloadsIntro', DownloadsIntro);
registerComponent('downloadsDetail', DownloadsDetail);
registerComponent('oauthOverview', OAuthOverview);
registerComponent('oauthIntro', OAuthIntro);
registerComponent('oauthDetail', OAuthDetail);
registerComponent('wfsOverview', WFSOverview);
registerComponent('wfsIntro', WFSIntro);
registerComponent('wfsDetail', WFSDetail);
registerComponent('wmtsOverview', WMTSOverview);
registerComponent('wmtsIntro', WMTSIntro);
registerComponent('wmtsDetail', WMTSDetail);
registerComponent('vtsOverview', VTSOverview);
registerComponent('vtsIntro', VTSIntro);
registerComponent('vtsDetail', VTSDetail);
registerComponent('matchOverview', MatchOverview);
registerComponent('matchIntro', MatchIntro);
registerComponent('matchDetail', MatchDetail);
registerComponent('namesOverview', NamesOverview);
registerComponent('namesIntro', NamesIntro);
registerComponent('namesDetail', NamesDetail);
registerComponent('linkedIdentifiersOverview', LinkedIdentifiersOverview);
registerComponent('linkedIdentifiersIntro', LinkedIdentifiersIntro);
registerComponent('linkedIdentifiersDetail', LinkedIdentifiersDetail);
registerComponent('placesOverview', PlacesOverview);
registerComponent('placesIntro', PlacesIntro);
registerComponent('placesDetail', PlacesDetail);
registerComponent('documentation', Index);
registerComponent('dashboard', Dashboard);
registerComponent('account', Account);
registerComponent('support', SupportIndex);
registerComponent('supportPlans', SupportPlans);
registerComponent('openDataDownloads', OpenDataDownloads);
registerComponent('openDataItem', OpenDataItem);
registerComponent('premiumDownloads', PremiumDownloads);
registerComponent('premiumItem', PremiumItem);
registerComponent('recipeDetails', RecipeDetails);
registerComponent('recipeEditor', RecipeEditor);
registerComponent('recipeLibrary', RecipeLibrary);
registerComponent('dataPackageNew', AddDataPackagePremium);
registerComponent('dataPackageNewNgd', AddDataPackageNgd);
registerComponent('dataPackageExpand', ExpandDataPackage);
registerComponent('dataPackages', DataPackages);
registerComponent('dataPackage', DataPackage);
registerComponent('manageLicences', ManageLicences);
registerComponent('plans', Plans);
registerComponent('legalOverview', LegalOverview);
registerComponent('apiTermsConditions', LegalTermsPage);
registerComponent('termsConditions', LegalTermsPage);
registerComponent('downloadTerms', LegalTermsPage);
registerComponent('errorReportingTerms', ErrorReportingTerms);
registerComponent('vernacularNamesTerms', VernacularNamesTerms);
registerComponent('frameworkContractTerms', FrameworkContractTerms);
registerComponent('trialModeTerms', TrialModeTerms);
registerComponent('partnerContracts', PartnerContracts);
registerComponent('sla', SLA);
registerComponent('serviceStatus', ServiceStatus);
registerComponent('serviceStatusApi', ServiceStatusApi);
registerComponent('landing', Landing);
registerComponent('error404Downloads', Error404);
registerComponent('error404Projects', Error404);
registerComponent('error404Premium', Error404);
registerComponent('error404Recipe', Error404);
registerComponent('errorReporting', ErrorOmissions);
registerComponent('errorsAndOmissions', ErrorOmissionsLanding);
registerComponent('holdingPage', HoldingPage);
registerComponent('vernacularNames', VernacularNamesLanding);
registerComponent('vernacularNamesReporting', VernacularNamesReporting);
registerComponent('billing', BillingInformation);
registerComponent('payments', Payments);
registerComponent('contactPreferences', ContactPreferences);
registerComponent('brandImportance', BrandImportance);
registerComponent('logoOverview', LogoOverview);
registerComponent('logoFullColour', LogoFullColour);
registerComponent('logoWhite', LogoWhite);
registerComponent('copyrightOverview', CopyrightOverview);
registerComponent('logoCopyrightPositioning', LogoCopyrightPositioning);
registerComponent('exclusionZone', ExclusionZone);
registerComponent('minMax', MinMax);
registerComponent('prohibitedUse', ProhibitedUse);
registerComponent('manageTeamMembers', ManageTeamMembers);
registerComponent('companyInformation', CompanyInformation);
registerComponent('premiumSetup', PremiumPlanSetup);
registerComponent('paymentSetup', PaymentSetup);
registerComponent('invitation', Invitation);
registerComponent('accountInvitation', AccountInvitation);
registerComponent('psgaSetup', PsgaPlanSetup);
registerComponent('energyAndInfraSetup', EnergyAndInfrastructurePlanSetup);
registerComponent('logInError', LogInError);
registerComponent('supportDownload', SupportDownload);
registerComponent('supportOsSelectAndBuild', SupportOsSelectAndBuild);
registerComponent('ofaOverview', OFAOverview);
registerComponent('ofaIntro', OFAIntro);
registerComponent('ofaDetail', OFADetail);
registerComponent('otaOverview', OTAOverview);
registerComponent('otaIntro', OTAIntro);
registerComponent('otaDetail', OTADetail);
registerComponent("myApiPlans", MyApiPlans);
registerComponent('pricingApiPlans', EaiPlanPricing);
registerComponent('addApiPlan', AddApiPlan);
registerComponent('addedApiPlan', ConfirmApiPlanPurchase)
