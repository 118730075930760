import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import Refs from "../pages/plans/Refs";
import { osColour } from 'omse-components';
import { OPEN_PLAN, PREMIUM_PLAN, PSGA_PLAN } from '../../shared/plans';
import FeatureIcon from './FeatureIcon';
import { css } from '@emotion/css';
import { useTheme } from "@emotion/react";
import classNames from 'classnames';

const disabledClass = css`
    & p > svg, & p {
        color: ${osColour.neutral.stone};
    }
`;

const usePremiumFeatureStyles = theme => css`
    ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(0, 0.5)};
    }
`;

const usePremiumFeatureWithBackgroundStyles = theme => css`
    color: ${osColour.primary.berry};
    background-color: ${osColour.neutral.clouds};
    clip-path: polygon(0 0%, 100% 0%, 97% 100%, 0% 100%);
    width: 100%;
    padding: 4px 8px;
    margin-bottom: 8px !important;
    position: relative;
    left: -8px;
    top: -4px;
    & p > svg {
        color: ${osColour.primary.berry};
    };
    ${theme.breakpoints.down('sm')} {
        left: 0;
        padding: 4px 8px 4px 4px;
        width: calc(100% - 24px);
    }
`;

export default function FeaturesList(props) {
    const { plan, type } = props;
    const theme = useTheme();
    const premiumFeatureClass = usePremiumFeatureStyles(theme);
    const premiumFeatureWithBackgroundClass = usePremiumFeatureWithBackgroundStyles(theme);
    if (!plan.features) {
        return false;
    }
    return plan.features.map(feature => {
        let featureClasses = [];
        let icon = <FeatureIcon on={feature.enabled} />;

        if (type === PREMIUM_PLAN) {
            featureClasses.push(premiumFeatureClass);
        }

        if (feature.premiumStyle) {
            featureClasses.push(premiumFeatureWithBackgroundClass);
        }

        if (!feature.enabled) {
            featureClasses = [disabledClass];
        }
        return (
            <Typography variant='body2' component='li' key={feature.text.id + type} className={classNames(featureClasses)}>
                <p>
                    {icon}
                    <span>
                        <FormattedMessage {...feature.text} values={{
                            b: chunks => <b>{chunks}</b>
                        }}
                        />
                        <Refs item={feature} type={type} />
                    </span>
                </p>
            </Typography>
        );
    });
}

FeaturesList.propTypes = {
    plan: PropTypes.object,
    type: PropTypes.oneOf([OPEN_PLAN, PREMIUM_PLAN, PSGA_PLAN]).isRequired
}