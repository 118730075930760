import React from 'react';
import Header from './Header';
import Content from './Content';
import Typography from '@mui/material/Typography';
import DocLinks from './DocLinks';
import figure1 from './placesOverview/Figure1.png';
import Document from './Document';

export default function PlacesOverview() {
    return <Document>
        <Header back={true}>OS Places API: Overview</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                Identify UK addresses accurately with OS Places API. Improve efficiency – use OS Places API to verify the address records
                you’re capturing against authoritative data from Ordnance Survey’s AddressBase® Premium and AddressBase® Premium - Islands.
                </p>
                <p>
                Make requests using a full or partial address, a postcode, or a UPRN. Searches can
                also find addresses closest to a given point or all the addresses known within a user-defined area, bounding box or circle. 
                </p>
            </Typography>
            <img src={figure1} alt='OS Places API: large-scale map view of buildings each with an address point'/>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>With OS Places API,</p>
                <p>You can:</p>
                <ul>
                    <li>Find a specific address accurately</li>
                    <li>Find all addresses within a defined area</li>
                    <li>Geocode addresses (add the location element)</li>
                    <li>Reverse geocode an address from its location</li>
                    <li>Obtain the UPRN for addresses</li>
                    <li>Search both Royal Mail and Local Authority address datasets</li>
                    <li>Find addresses in the United Kingdom, Isle of Man, Jersey and Guernsey</li>
                </ul>
                <p>You can't:</p>
                <ul>
                    <li>Find addresses outside the United Kingdom, Isle of Man, Jersey and Guernsey</li>
                    <li>Locate general areas such as cities, forests or beaches</li>
                    <li>Carry out bulk queries (transactions are subject to API throttling)</li>
                </ul>
            </Typography>
            <DocLinks product='places' hasGettingStarted={true} hasTechnicalSpec={true} hasDemo={true}/>
        </Content>
    </Document>
}