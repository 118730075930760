import React from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { ExternalLink, LinkButton, osColour } from "omse-components";
import { GitHub } from "@mui/icons-material";
import { ReactComponent as MediumIcon } from "../img/medium-icon.svg";
import { ReactComponent as MaximiseIcon } from "../../../components/icons/maximise.svg";
import CardImage from "../img/get-inspired-case-study.jpg";
import { tabHeadingStyles } from './styles';

const getInspiredMessages = defineMessages({
	leftTitle: {
		id: "GetInspired.leftTitle",
		defaultMessage: "Case studies and demos",
		description: "Title"
	},
	leftDescription: {
		id: "GetInspired.leftDescription",
		defaultMessage:
			"Check out our customer case studies and discover how OS data is driving development and decision making, in organisations like yours. View resources from our own developers on GitHub, or follow our developer blog on Medium for industry insights, examples and tutorials.",
		description: "Description"
	},
	leftSubDescription: {
		id: "GetInspired.leftSubDescription",
		defaultMessage: "Discover what’s possible using OS data and see if you can get inspired.",
		description: "Sub Description"
	},
	leftButton: {
		id: "GetInspired.leftButton",
		defaultMessage: "Browse case studies",
		description: "Button text"
	},
	leftButtonLabel: {
		id: "GetInspired.leftButtonLabel",
		defaultMessage: "Case studies (Opens in New Tab)",
		description: "Button label"
	},
	leftFirstLink: {
		id: "GetInspired.leftFirstLink",
		defaultMessage: "See what we're doing on GitHub",
		description: "Link text"
	},
	leftFirstLabel: {
		id: "GetInspired.leftFirstLabel",
		defaultMessage: "OS Github (Opens in New Tab)",
		description: "Button label"
	},
	leftSecondLink: {
		id: "getInsGetInspiredpiredMessages.leftSecondLink",
		defaultMessage: "Follow us on Medium",
		description: "Link text"
	},
	leftSecondLabel: {
		id: "GetInspired.leftSecondLabel",
		defaultMessage: "OS Medium blog (Opens in New Tab)",
		description: "Button label"
	},
	rightTitle: {
		id: "GetInspired.rightTitle",
		defaultMessage: "Unlocking vital insight in less time",
		description: "Card Title"
	},
	rightDescription: {
		id: "GetInspired.rightDescription",
		defaultMessage: "How North Yorkshire Fire and Rescue use OS Select+Build to reduce data analysis timeframes.",
		description: "Card Description"
	},
	rightButton: {
		id: "GetInspired.rightButton",
		defaultMessage: "Read case study",
		description: "Button text"
	},
	rightImageDesc: {
		id: "GetInspired.rightImageDesc",
		defaultMessage: "Fire and rescue team attending fire emergency",
		description: "Img Description"
	}
});

const Section = styled("section")(({ theme }) => ({
	display: "grid",
	gap: "6em",
	gridTemplate: "1fr / auto auto",
	alignItems: "center",
	justifyContent: "space-between",
	[theme.breakpoints.down("md")]: {
		gridTemplate: " auto auto / 1fr"
	}
}));

const TextContainer = styled.article`
	${tabHeadingStyles}
	${props => props.theme.breakpoints.down("md")} {
		max-width: 100%;
	}
`;

const CardContainer = styled.article`
	display: grid;
	max-width: 552px;
	border: 1px solid ${osColour.neutral.mist};
`;

const Image = styled.img`
	width: 100%;
	height: 310px;
	object-fit: cover;
`;

const ContentContainer = styled.div`
	padding: 24px;
    & h3 {
		font-family: OSGill, sans-serif;
        margin-bottom: 16px; 
        color: ${props => props.theme.palette.textPrimary.main};
	}
    & p {
        margin: 0 0 24px 0;
    }
`;

const LinkContainer = styled("div")`
	font-family: 'Source Sans Pro', sans-serif;
	display: flex;
	gap: 24px;
`;

const StyledButton = styled(Button)`
	margin-bottom: 36px;
	display: flex;
	gap: 6px;
	@media (max-width: 800px) {
		width: 100%;
	}
`;

export default function GetInspired() {
	return (
		<Section>
			<TextContainer>
				<Typography variant="h2" component="h3">
					<FormattedMessage {...getInspiredMessages.leftTitle} />
				</Typography>
				<Typography paragraph sx={{ marginBottom: "24px", lineHeight: "140%" }} variant="body1">
					<FormattedMessage {...getInspiredMessages.leftDescription} />
				</Typography>
				<Typography paragraph sx={{ marginBottom: "36px" }} variant="body1">
					<FormattedMessage {...getInspiredMessages.leftSubDescription} />
				</Typography>
				<StyledButton
					variant="outlined"
					onClick={() => window.open("https://www.ordnancesurvey.co.uk/customers/case-studies")}
					aria-label={getInspiredMessages.leftButtonLabel.defaultMessage}
				>
					<FormattedMessage {...getInspiredMessages.leftButton} />
					<MaximiseIcon height={20} width={20} alt="External Link Icon" />
				</StyledButton>
				<LinkContainer>
					<LinkButton
						startIcon={<GitHub htmlColor={osColour.neutral.charcoal} />}
						sx={{ color: osColour.primary.berry, fontSize: "16px" }}
						target="_blank"
						rel="noopener noreferrer"
						aria-label={getInspiredMessages.leftFirstLabel.defaultMessage}
						href="https://github.com/OrdnanceSurvey"
					>
						<FormattedMessage {...getInspiredMessages.leftFirstLink} />
						<MaximiseIcon height={20} width={20} alt="External Link Icon" />
					</LinkButton>
					<LinkButton
						startIcon={<MediumIcon htmlColor={osColour.neutral.charcoal} height={20} width={20} />}
						sx={{ color: osColour.primary.berry, fontSize: "16px" }}
						target="_blank"
						rel="noopener noreferrer"
						aria-label={getInspiredMessages.leftSecondLabel.defaultMessage}
						href="https://osdeveloper.medium.com/"
					>
						<FormattedMessage {...getInspiredMessages.leftSecondLink} />
						<MaximiseIcon height={20} width={20} alt="External Link Icon" />
					</LinkButton>
				</LinkContainer>
			</TextContainer>
			<CardContainer>
				<Image src={CardImage} alt={getInspiredMessages.rightImageDesc.defaultMessage} />
				<ContentContainer>
					<Typography variant="h2" component='h3'>
						<FormattedMessage {...getInspiredMessages.rightTitle} />
					</Typography>
					<Typography variant="body1">
						<FormattedMessage {...getInspiredMessages.rightDescription} />
					</Typography>
					<ExternalLink
						type="generic"
						href={"https://www.ordnancesurvey.co.uk/customers/case-studies/north-yorkshire-fire-and-rescue"}
						message={getInspiredMessages.rightButton.defaultMessage}
					/>
				</ContentContainer>
			</CardContainer>
		</Section>
	);
}
