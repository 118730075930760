import React from 'react';
import Header from './Header';
import withStyles from 'react-jss';
import styles from './style';
import Content from './Content';
import DocLinks from './DocLinks';
import Typography from '@mui/material/Typography';
import Link from '../../components/Link';
import routes from '../../util/routes';
import Authentication from './Authentication';
import ResponseCodes from './ResponseCodes';
import LinkedIdentifiersFeaturesTable from './LinkedIdentifiersFeaturesTable';
import Document from './Document';
import Table from './Table';
import { ExternalLink } from 'omse-components';
import TextBox from '../../components/TextBox';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

const identifierTypes = [
    {
        identifierType: 'TOID',
        identifierDescription: 'A unique identifier for a TopographicArea, RoadLink or Road.',
        exampleIdentifier: <InlineCodeSnippet>osgb4000000007103455</InlineCodeSnippet>,
    },
    {
        identifierType: 'UPRN',
        identifierDescription: 'A Unique Property Reference Number.',
        exampleIdentifier: <InlineCodeSnippet>200001025758</InlineCodeSnippet>,
    },
    {
        identifierType: 'USRN',
        identifierDescription: 'A Unique Street Reference Number.',
        exampleIdentifier: <InlineCodeSnippet>80833669</InlineCodeSnippet>,
    },
    {
        identifierType: 'GUID',
        identifierDescription: 'A unique identifier for RoadLink or RoadNode.',
        exampleIdentifier: <><InlineCodeSnippet>60E4A4AE-AC0C-4641-BE57-BD813C436526</InlineCodeSnippet> (example form only as not persistent identifier)</>,
    },
];

const correlationMethods = [
    {
        correlationMethod: 'RoadLink_TOID_TopographicArea_TOID_2',
        relationship: "RoadLink ↔ TopographicArea",
    },
    {
        correlationMethod: 'Road_TOID_TopographicArea_TOID_3',
        relationship: "Road ↔ TopographicArea",
    },
    {
        correlationMethod: 'Street_USRN_TopographicArea_TOID_4',
        relationship: "Street ↔ TopographicArea",
    },
    {
        correlationMethod: 'BLPU_UPRN_TopographicArea_TOID_5',
        relationship: "BLPU ↔ TopographicArea",
    },
    {
        correlationMethod: 'RoadLink_TOID_Road_TOID_7',
        relationship: "RoadLink ↔ Road",
    },
    {
        correlationMethod: 'RoadLink_TOID_Street_USRN_8',
        relationship: "RoadLink ↔ Street",
    },
    {
        correlationMethod: 'BLPU_UPRN_RoadLink_TOID_9',
        relationship: "BLPU ↔ RoadLink",
    },
    {
        correlationMethod: 'Road_TOID_Street_USRN_10',
        relationship: "Road ↔ Street",
    },
    {
        correlationMethod: 'BLPU_UPRN_Street_USRN_11',
        relationship: "BLPU ↔ Street",
    },
    {
        correlationMethod: 'ORRoadLink_GUID_RoadLink_TOID_12',
        relationship: "Open Roads RoadLink ↔ OSMM Highways RoadLink",
    },
    {
        correlationMethod: 'ORRoadNode_GUID_RoadLink_TOID_13',
        relationship: "Open Roads RoadNode ↔ OSMM Highways RoadLink",
    },
];


export function LinkedIdentifiersDetail() {
    return <Document>
        <Header back={true}>OS Linked Identifiers API: Technical specification</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains the content of the OS Linked Identifiers API, and the methods that you can use
                    to call it. If you are not familiar with the OS Linked Identifiers API you may want to read
                    the <Link path={routes.linkedIdentifiersOverview}>overview documentation</Link> before proceeding.
                    The overview and technical specification for the OS Linked Identifier data product may also be
                    useful for your reference.
                </p>
            </Typography>
            <Typography variant='h1' component={'h2'}>Content</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <LinkedIdentifiersFeaturesTable/>
            </Typography>
            <Typography variant='h1' component={'h2'}>Technical Detail</Typography>
            <Typography variant='body1' paragraph={true}>
                OS Linked Identifiers API requests are structured using keyword-value pair (KVP) parameter encoding, and HTTP <InlineCodeSnippet>GET</InlineCodeSnippet>.
                All requests must be encoded correctly according to standard percent-encoding procedures for a URI.
            </Typography>

            <Authentication gettingStartedRoute={routes.linkedIdentifiersIntro}/>

            <Typography variant='h2'>Encryption</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    As of October 2018, all calls must be made using a secure hypertext encryption (HTTPS).
                </p>
                <p>
                    Ordnance Survey APIs no longer support calls made using SSL v2/v3 or TLS v1.0/v1.1.
                    Thus, all calls made to any of our APIs must support TLS v1.2 as of 24 October 2018 in line with
                    current cyber security recommendations.
                </p>
            </Typography>

            <ResponseCodes/>

            <Typography variant='h2'>Operation</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    OS Linked Identifiers API has four main RESTful endpoints. These can be built into your web
                    browsing application or explored individually using a web-browser.
                    These endpoints follow the RESTful <ExternalLink
                        type="generic"
                        href="https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.2.md"
                        message="OpenAPI v3.0.2 specification"
                    /> and data is returned in <ExternalLink
                        type="generic"
                        href="https://www.ecma-international.org/publications-and-standards/standards/ecma-404/"
                        message="JSON"
                    /> format.
                </p>
            </Typography>
            <Typography variant='h3'>1) Linked Identifier lookup</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Looks up an identifier and finds its associated identifiers. Returns a list.
                </p>
                <TextBox
                    text="https://api.os.uk/search/links/v1/identifiers/{id}?key="
                    label="OS Linked Identifiers API - 'Identifer Lookup' Endpoint"
                    variant="code"
                />
            </Typography>
            <Typography variant='h3'>2) Feature type lookup</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Looks up linked identifiers when the input feature type is known.
                </p>
                <TextBox
                    text="https://api.os.uk/search/links/v1/featureTypes/{featureType}/{id}?key="
                    label="OS Linked Identifiers API - 'Feature Type Lookup' Endpoint"
                    variant="code"
                />
                <p>
                    The full list of feature types is in the Feature Type column of the table of types and identifiers.
                </p>
            </Typography>
            <Typography variant='h3'>3) Identifier type lookup</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Looks up linked identifiers when the input identifier type is known.
                </p>
                <TextBox
                    text="https://api.os.uk/search/links/v1/identifierTypes/{identifierType}/{id}?key="
                    label="OS Linked Identifiers API - 'Identifier Type Lookup' Endpoint"
                    variant="code"
                />
                <p>
                    The table below shows all the identifier types:
                </p>
                <Table>
                    <thead>
                        <tr>
                            <th>Identifier type</th>
                            <th>Description</th>
                            <th>Example identifier of this type</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        identifierTypes.map(identifierType => {
                            return <tr key={identifierType.identifierType}>
                                <td>{identifierType.identifierType}</td>
                                <td>{identifierType.identifierDescription}</td>
                                <td>{identifierType.exampleIdentifier}</td>
                            </tr>;
                        })
                    }
                    </tbody>
                </Table>
            </Typography>

            <Typography variant='h3'>4) Current product version information</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>This service is available as an HTTP <InlineCodeSnippet>GET</InlineCodeSnippet> resource method allowing you to discover the current product version information.</p>
                <TextBox
                    text="https://api.os.uk/search/links/v1/productVersionInfo/{correlationMethod}?key="
                    label="OS Linked Identifiers API - 'Product Version Lookup' Endpoint"
                    variant="code"
                />
                <p>
                    The table list below shows all of the correlation methods:
                </p>
                <Table>
                    <tbody>
                    <tr>
                        <th>Correlation method</th>
                        <th>Feature relationship</th>
                    </tr>
                    {
                        correlationMethods.map(correlationMethod => {
                            return <tr key={correlationMethod.correlationMethod}>
                                <td>
                                    <InlineCodeSnippet>
                                        {correlationMethod.correlationMethod}
                                    </InlineCodeSnippet>
                                </td>
                                <td>{correlationMethod.relationship}</td>
                            </tr>;
                        })
                    }
                    </tbody>
                </Table>
            </Typography>
            <Typography variant='h2'>Correlation Methods</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                Details for how each of these identifiers have been linked to each other can be found in the Technical
                Specification for OS Open Linked Identifiers listed on
                the <ExternalLink
                    type="generic"
                    href="https://www.ordnancesurvey.co.uk/business-government/tools-support/open-identifiers-support"
                    message="Open Identifiers support page"
                />.
            </Typography>
            <DocLinks product='linkedIdentifiers' hasGettingStarted={true} hasDemo={true}/>
        </Content>
    </Document>
}

export default withStyles(styles)(LinkedIdentifiersDetail);
