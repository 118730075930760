import React, {useState} from 'react';
import { CommonDialog, CommonDialogActions, ExternalLink, DropDownMenu } from 'omse-components';
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage, defineMessages } from 'react-intl';
import {partnerContracts, summaryMessages} from '../../../legal/data/partnerContracts';
import {Typography} from '@mui/material';

const messages = defineMessages({
    title: {
        id: 'ContractDialog.title',
        defaultMessage: '{name} licence',
        description: 'Title for the dialog'
    },
    agree: {
        id: 'ContractDialog.agree',
        defaultMessage: 'Agree to licence',
        description: 'Label for the confirmation button on the dialog'
    },
    defaultSummary: {
        id: 'ContractDialog.defaultSummary',
        defaultMessage: 'Please review the licence terms before proceeding.',
        description: 'Summary text used for an unknown partner contract'
    },
    review: {
        id: 'ContractDialog.review',
        defaultMessage: 'Review the contract (opens in a new window)',
        description: 'Label for the link to the contract'
    },
    label: {
        id: 'ContractDialog.label',
        defaultMessage: 'Select duration:',
        description: 'Label for the term selection'
    },
    optionLabel: {
        id: 'ContractDialog.optionLabel',
        defaultMessage: '{years, plural, one {# year} other {# years}}',
        description: 'Label for each specific term selection'
    }
});

export default function ContractDialog(props) {
    const {contract, onConfirm, onClose} = props;
    const [term, setTerm] = useState(contract.allowedTerms[0]);
    const intl = useIntl();

    const contractKey = Object.keys(partnerContracts).find(key => {
        const contractIds = partnerContracts[key].contractIds;
        return contractIds && contractIds.includes(contract.id);
    });

    const summaryMessage = summaryMessages[contractKey] || messages.defaultSummary;

    const actions = <CommonDialogActions onConfirm={() => onConfirm(term)}
                                         onClose={onClose}
                                         confirmLabel={messages.agree}/>;

    return <CommonDialog actions={actions}
                         onClose={onClose}
                         title={messages.title}
                         titleValues={{
                             name: contract.label
                         }}
    >
        <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...summaryMessage}/>
        </Typography>
        <ExternalLink type='generic' href={contract.termsUrl} message={messages.review} />

        <div>
            <DropDownMenu
                buttonId='term'
                buttonVariant='outlined'
                label={intl.formatMessage(messages.label)}
                items={contract.allowedTerms.map(number=>({
                    label: intl.formatMessage(messages.optionLabel, { years: number }),
                    value: number
                }))}
                onChange={item => setTerm(item.value)}
                value={term}
                mode='modal'
                variant='block'
            />
        </div>
    </CommonDialog>;
}

ContractDialog.propTypes = {
    contract: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};
