import React from 'react';
import {useHistory} from 'react-router';
import {defineMessages, FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import {createUseStyles} from 'react-jss';
import {CommonDialog, CommonDialogActions} from 'omse-components';
import routePaths, {getLocation} from "../../util/routes";

const messages = defineMessages({
  dialogTitle: {
    id: "InvitationSkipDialog.dialogTitle",
    defaultMessage: "Skipping means we can't sign you up for a Premium account right now",
    description: "Title for the dialog box to skip step",
  },
  skipConfirmation: {
    id: "InvitationSkipDialog.skipConfirmation",
    defaultMessage: "Yes, skip this for now",
    description: "Text for Yes, skip this for now",
  },
  description1: {
    id: "InvitationSkipDialog.description1",
    defaultMessage:
      "If you skip this, you'll continue with an OS OpenData account. Your invitation will be waiting for you and you can accept or decline it at any time.",
    description: "Text for description 1",
  },
  description2: {
    id: "InvitationSkipDialog.description2",
    defaultMessage: "Are you sure you'd like to skip this? You'll continue with an OS OpenData account.",
    description: "Text for description 2",
  },
  goBack: {
    id: "InvitationSkipDialog.goBack",
    defaultMessage: "Go back",
    description: "Text for go back",
  },
});

const styles = createUseStyles({
    desc1: {
        paddingTop: 10, 
        paddingBottom: 25
    },
    desc2: {
        paddingBottom: 70
    }
});

export default function InvitationSkipDialog({ handleClose }) {
  const history = useHistory();
  const classes = styles();

  const onClick = () => {
    const newLocation = getLocation(routePaths.dashboard, history.location);
    history.replace(newLocation);
  };

  const actions = (
    <CommonDialogActions onClose={handleClose} onConfirm={onClick} confirmLabel={messages.skipConfirmation} cancelLabel={messages.goBack} />
  );

  return (
    <CommonDialog onClose={handleClose} title={messages.dialogTitle} actions={actions} data-testid='invitation-skip-dialog'>
      <Typography component={"div"} inline='true' variant='body1' className={classes.desc1}>
        <FormattedMessage {...messages.description1} />
      </Typography>

      <Typography component={"div"} inline='true' variant='body1' className={classes.desc2}>
        <FormattedMessage {...messages.description2} />
      </Typography>
    </CommonDialog>
  );
}