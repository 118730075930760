import React, { Component } from 'react';
import { defineMessages } from 'react-intl';
import { FormattedMessage } from "react-intl";
import { ExternalLink } from 'omse-components';
import Typography from '@mui/material/Typography';
import Link from './Link';
import routes, {gitHubAddress} from '../util/routes';

const messages = defineMessages ({
    helperParagraph: {
        id: 'EmptyStateMessages.helperParagraph',
        defaultMessage: 'If you\'re not that familiar with our APIs yet, find out more in the {docs}, or take a look at code examples in our {demo}.',
        description: 'Helper paragraph for ways to get started'
    },
    docsLink: {
        id: 'EmptyStateMessages.docsLink',
        defaultMessage: 'documentation',
        description: 'Link to documentation'
    },
    demoLink: {
        id: 'EmptyStateMessages.demoLink',
        defaultMessage: 'API demos repository',
        description: 'Link to help'
    }
});

export default class EmptyStateMessages extends Component {
    render() {
        const {className} = this.props;
        return (
            <div className={className}>
                <Typography variant='h5' component='div'>
                    <FormattedMessage {...messages.helperParagraph} values={{
                        docs: <Link path={routes.documentation}>
                            <FormattedMessage {...messages.docsLink} />
                        </Link>,
                        demo: <ExternalLink
                            type="generic"
                            href={gitHubAddress}
                            message={messages.demoLink}
                        />
                    }}/>
                </Typography>
            </div>
        )
    }
}

