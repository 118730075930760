import routes, {getLocation} from "../../util/routes";
import {createActionMiddleware, createActionReducer} from 'omse-components';
import {ADD_PRODUCT, LOADED_PROJECT_ACTION} from "./actions";
import {withOrganisation} from '../../util/organisation';

export const reducer = createActionReducer(ADD_PRODUCT);

function prepareCall(action, store) {
    return withOrganisation({
        url: '/api/projects/' + action.projectId,
        method: 'POST',
        body: {
            product: action.productName
        }
    }, store);
}
function success(store, action, project) {
    const history = store.getState().history;
    const pathname = routes.project.replace(':projectId', project.id);
    if (history.location.pathname !== pathname) {
        const newLocation = getLocation(pathname, history.location);
        newLocation.state = {
            projectName: project.name
        };
        history.push(newLocation);
    }

    store.dispatch({
        type: LOADED_PROJECT_ACTION,
        result: project,
    });
}
export const middleware = createActionMiddleware(ADD_PRODUCT, prepareCall, success);

