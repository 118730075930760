import React from "react";
import {defineMessages} from "react-intl";
import DataPackageDropdownField from './DataPackageDropdownField';

export const messages = defineMessages({
    fileFormat: {
        id: 'FormatDropdown.fileFormat',
        defaultMessage: 'File format',
        description: 'File format label'
    },
    fileFormatNeeded: {
        id: 'FormatDropdown.fileFormatNeeded',
        defaultMessage: 'Please select a file format',
        description: 'fileFormat needed'
    },
    select: {
        id: 'FormatDropdown.select',
        defaultMessage: 'Select file format',
        description: 'Select message'
    }
});

export default function FormatDropdown({formatOptions}) {
    return <DataPackageDropdownField 
        options={formatOptions}
        fieldName='format'
        labelMessage={messages.fileFormat}
        selectMessage={messages.select}
        fieldErrorMessage={messages.fileFormatNeeded} />;
}