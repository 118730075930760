import React, {Fragment, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import OrgDetailsForm from "../../components/OrgDetailsForm";
import {modules} from 'omse-components';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Typography} from '@mui/material';
import routes, {getLocation} from "../../util/routes";
import styles from "./styles";
const {updateUser} = modules.actions.user;

const messages = defineMessages({
    intro1: {
        id: 'UserDetailsPage.intro1',
        defaultMessage: "You are now a member of the {orgName} organisation.",
        description: 'Intro text for the user details page.'
    },
    intro2: {
        id: 'UserDetailsPage.intro2',
        defaultMessage: "Please update your job title and telephone number. If you choose not to update your details now you may update them at any time using the Account menu.",
        description: 'Intro text for the user details page.'
    },
    error: {
        id: 'UserDetailsPage.error',
        defaultMessage: "We were unable to save your user details. Please edit the values and try again, or skip this step and set them up later.",
        description: 'Error text for the user details page.'
    },
    skip: {
        id: 'UserDetailsPage.skip',
        defaultMessage: 'Skip',
        description: 'Label for the Skip button'
    },
    update: {
        id: 'UserDetailsPage.update',
        defaultMessage: 'Update',
        description: 'Label for the update details button'
    }
});

let actionIdCounter = 0;

export default function UserDetailsPage({ testActionId }) {
    const [actionId] = useState(testActionId || actionIdCounter++);
    const user = useSelector(state => state.user.current.result);
    const {result, error, working} = useSelector(state => {
        if(state.user.update.actionId === actionId) {
            return state.user.update;
        }
        return {};
    });
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = styles();

    useEffect(() => {
        if(result !== undefined) {
            const newLocation = getLocation(routes.dashboard, history.location);
            history.push(newLocation);
        }
    }, [result, history]);

    function submit(accountType, orgName, jobTitle, phone) {
        const action = updateUser(user.firstName, user.lastName, jobTitle, phone);
        action.actionId = actionId;
        dispatch(action);
    }

    return <Fragment>
        <Typography variant='body1' className={classes.introText} paragraph={true}>
            <FormattedMessage {...messages.intro1} values={{
                orgName: user.org
            }}/>
        </Typography>
        <Typography variant='body1' className={classes.introText} paragraph={true}>
            <FormattedMessage {...messages.intro2}/>
        </Typography>
        {
            error &&
            <Typography variant='body1' color='error'>
                <FormattedMessage {...messages.error}/>
            </Typography>

        }
        <OrgDetailsForm onSubmit={submit}
                        working={working}
                        cancelLabel={messages.skip}
                        submitLabel={messages.update}
                        showInfo={false}
        />
    </Fragment>;
};