import React from "react";
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';
import { osColour } from "omse-components";
import { styled } from "@mui/material";
import { defineMessages, useIntl } from 'react-intl';

const CustomStack = styled(Stack)(({ theme }) => ({
    background: osColour.primary.berry,
    display: 'flex',
    alignItems: 'end',
    width: '100%',
    color: osColour.neutral.white,
    '& span': {
        color: osColour.neutral.white
    },
    '& .MuiIconButton-root': {
        padding: theme.spacing(3)
    }
}));

const messages = defineMessages(({
    toCalendarView: {
        id: 'ToolbarWithKeyboardViewSwitch.toCalendarView',
        defaultMessage: 'text input view is open, go to calendar view',
        description: 'Text shown when in text input view'
    },
    toTextView: {
        id: 'ToolbarWithKeyboardViewSwitch.toTextView',
        defaultMessage: 'calendar view is open, go to text input view',
        description: 'Text shown when in calendar view'
    },
}));

export default function ToolbarWithKeyboardViewSwitch(props) {
    const { showKeyboardViewSwitch, showKeyboardView, setShowKeyboardView, ...other } = props;
    const intl = useIntl();

    if (showKeyboardViewSwitch) {
        const iconLabel = showKeyboardView ? intl.formatMessage(messages.toCalendarView) : intl.formatMessage(messages.toTextView);
        return (
            <CustomStack
                spacing={2}
                direction={other.isLandscape ? 'column' : 'row'}
                alignItems="center"
                sx={{
                    p: 0,
                    ...((other.isLandscape) ?
                        {
                            gridColumn: 1,
                            gridRow: '1 / 3',
                        } : {
                            gridColumn: '1 / 4',
                            gridRow: 1,
                        })
                }}
            >
                <DatePickerToolbar {...other} sx={{ flex: '1 1 100%' }} />
                <IconButton
                    aria-label={iconLabel}
                    color="inherit"
                    onClick={() => setShowKeyboardView((prev) => !prev)}
                >
                    {showKeyboardView ? <CalendarMonthIcon /> : <ModeEditIcon />}
                </IconButton>
            </CustomStack>
        );
    }

    return <DatePickerToolbar {...other} />;
}
