// The functions and objects in this code are derived from the OS Cookie control managed by the Web Estate Team.
// Further information can be found at https://dev.azure.com/ordnancesurvey/OSWebEstate/_wiki/wikis/OSWebEstate.wiki/10545/Using-Cookie-Control

/**
 * Mapping of OS cookie settings to their corresponding index values.
 * @type {CookieSettingToIndex}
 */
export const cookieSettingToIndex = {
    performance: 0,
    functionality: 1,
    targeting: 2,
};

/**
 * Checks if the OS cookie control has been initialized.
 * @returns {boolean} True if cookie control is initialized, false otherwise.
 */
export function hasCookieControlInitialised() {
    return Boolean(window.CookieControl)
}

/**
 * Opens the cookie control if it has been initialized.
 * This function checks if the cookie control is already initialized and, if so, opens the cookie control interface.
 */
export function openCookieControl() {
    if (hasCookieControlInitialised()) {
        window.CookieControl.open();
    }
}

/**
 * Checks if a specific OS cookie setting is accepted.
 * @param {string} setting - The name of the cookie setting (e.g., "performance").
 * @returns {boolean} True if the specified cookie setting is accepted, false otherwise.
 */
export function isCookieControlSettingAccepted(setting) {
    if (hasCookieControlInitialised()) {
        return window.CookieControl.getCategoryConsent(setting);
    }
}
