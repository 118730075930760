import React, {Fragment, useCallback, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {osColour, Pagination} from "omse-components";
import Typography from "@mui/material/Typography";
import {defineMessages, FormattedDate, FormattedMessage} from "react-intl";

export const DATAPACKAGE = 'DATAPACKAGE';
export const RECIPE = 'RECIPE';

const messages = defineMessages({
    date: {
        id: 'ChangeLogTable.date',
        defaultMessage: 'Date',
        description: 'Date table heading'
    },
    changedBy: {
        id: 'ChangeLogTable.changedBy',
        defaultMessage: 'Changed by',
        description: 'Changed by table heading'
    },
    changeDetail: {
        id: 'ChangeLogTable.changeDetail',
        defaultMessage: 'Change detail',
        description: 'Change detail table heading'
    },
    unknownUser: {
        id: 'ChangeLogTable.unknownUser',
        defaultMessage: 'Unknown user',
        description: 'Value to return for changed by log if the user is unknown'
    }
})

const variableMessages = {
    DATAPACKAGE: defineMessages({
        CREATED: {
            id: 'ChangeLogTableDatapackage.CREATED',
            defaultMessage: 'Data package created',
            description: 'Value to return for data package created'
        },
        RENAMED: {
            id: 'ChangeLogTableDatapackage.RENAMED',
            defaultMessage: 'Data package renamed',
            description: 'Value to return for data package renamed'
        },
        EXPAND_REQUESTED: {
            id: 'ChangeLogTableDatapackage.EXPAND_REQUESTED',
            defaultMessage: 'Expansion requested',
            description: 'Value to return for data package expansion requests'
        },
        RESUPPLY_REQUESTED: {
            id: 'ChangeLogTableDatapackage.RESUPPLY_REQUESTED',
            defaultMessage: 'Resupply requested',
            description: 'Value to return for data package resupply requests'
        },
        DELETE_REQUESTED: {
            id: 'ChangeLogTableDatapackage.DELETE_REQUESTED',
            defaultMessage: 'Delete requested',
            description: 'Value to return for data package delete requests'
        },
        UPDATES_STOPPED: {
            id: 'ChangeLogTableDatapackage.UPDATES_STOPPED',
            defaultMessage: 'Updates stopped',
            description: 'Value to return for data package updates stopped'
        },
        DATA_FORMAT_CHANGED: {
            id: 'ChangeLogTableDatapackage.DATA_FORMAT_CHANGED',
            defaultMessage: 'Data format changed',
            description: 'Value to return for data package format changed'
        },
        RECIPE_DELETED:{
            id: 'ChangeLogTableDatapackage.RECIPE_DELETED',
            defaultMessage: 'Recipe deleted',
            description: 'Value to return for deleted recipe'
        }
    }),
    RECIPE: defineMessages({
        CREATED: {
            id: 'ChangeLogTableRecipe.CREATED',
            defaultMessage: 'Recipe created',
            description: 'Value to return for recipe created'
        },
        UPDATED_NAME: {
            id: 'ChangeLogTableRecipe.UPDATED_NAME',
            defaultMessage: 'Recipe renamed',
            description: 'Change log message when recipe is renamed'
        },
        UPDATED_DESCRIPTION: {
            id: 'ChangeLogTableRecipe.UPDATED_DESCRIPTION',
            defaultMessage: 'Recipe description updated',
            description: 'Change log message when recipe description is updated'
        },
        DELETED: {
            id: 'ChangeLogTableRecipe.DELETED',
            defaultMessage: 'Recipe deleted',
            description: 'Value to return for recipe delete requests'
        }
    })
};

const myStyles = createUseStyles(theme => ({
    table: {
        border: 'none',
        borderCollapse: 'collapse',
        width: '100%',
        '& th': {
            color: osColour.primary.berry,
            borderBottom: `2px solid ${osColour.primary.berry}`,
            padding: theme.spacing(1),
            textAlign: 'left',
            verticalAlign: 'bottom',
            [theme.breakpoints.up('lg')]: {
                whiteSpace: 'nowrap'
            }
        },
        '& tbody': {
            ...theme.typography.body1
        },
        '& tr': {
            borderBottom: '1px solid ' + osColour.neutral.mist
        },
        '& td': {
            padding: theme.spacing(1)
        },
        '& p': {
            marginTop: 0
        },
        '& p:last-of-type': {
            marginBottom: 0
        }
    }
}))

export default function ChangeLogTable({changeLog, type}) {
    const classes = myStyles();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);

    const slice = useCallback(() => {
        const start = page * pageSize;
        const end = start + pageSize;
        return changeLog.slice(start, end);
    }, [changeLog, page, pageSize]);

    function displayChangedBy (row) {
        if (row.changedBy) {
            return row.changedBy;
        } else {
            return <FormattedMessage {...messages.unknownUser} />
        }
    }

    function displayChangeDetail (row) {
        const message = variableMessages[type][row.changeCode];
        return message ? <FormattedMessage {...message} /> : <p>{row.changeCode}</p>;
    }

    function renderRows (row, i) {
        return <tr key={i}>
            <td><FormattedDate value={row.date}
                               day='numeric'
                               month='long'
                               year='numeric'/></td>
            <td>{displayChangedBy(row)}</td>
            <td>{displayChangeDetail(row)}</td>
        </tr>
    }

    function renderHeadings() {
        const headings = ['date', 'changedBy', 'changeDetail'];
        return headings.map(heading => <th key={heading}><Typography variant='h5' component='h3'><FormattedMessage {...messages[heading]}/></Typography></th>)
    }

    return <Fragment>
        <table className={classes.table}>
        <thead>
        <tr>
            { renderHeadings() }
        </tr>
        </thead>
        <tbody>
            { slice().map(renderRows) }
        </tbody>
    </table>
        <Pagination page={{
                        number: page,
                        size: pageSize,
                        totalElements: changeLog.length
                    }}
                    onChangePage={page => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={size => {
                        setPage(0);
                        setPageSize(size);
                    }}
        />
    </Fragment>
}
