import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {defineMessages, useIntl} from 'react-intl';
import {useParams} from 'react-router';
import FeatureCheck from '../../../components/FeatureCheck';
import features from '../../../../shared/features';
import AddDataPackage from './AddDataPackage';
import {getDatasetOptions, getRecipe} from '../../../modules/recipes/actions';
import {updateDraftOrder, resetDraftOrder} from '../../../modules/dataPackages/actions';
import {hasEditNgdDownloadsPermission} from '../../../util/permissions';
import routes, {useRedirect} from '../../../util/routes';
import AddDataPackagePanel from "./addDataPackage/AddDataPackagePanel";
import DataPackageMap from "./DataPackageMap";
import ConfirmDataPackage from "./ConfirmDataPackage";
import routePaths from '../../../util/routes';
import {useDataPackageNgdOptions} from "./useDataPackageNgdOptions";
import PartnerLicences from "../../../components/PartnerLicences";
import {NGD_PRODUCT_ID} from "../../../../shared/constants";

const messages = defineMessages({
    subtitle: {
        id: 'AddDataPackageNgd.subtitle',
        defaultMessage: 'OS NGD Recipe: {recipe}',
        description: 'Subtitle for the add data package panel'
    }
});

export default function AddDataPackageNgd() {
    const dispatch = useDispatch();
    const params = useParams();
    const redirect = useRedirect();
    const intl = useIntl();

    const userDetails = useSelector(state => state.user.current.result);
    const {result: recipe} = useSelector((state) => state.recipes.recipe);
    const {areaRadioOptions, formatOptions, crsOptions, updatesOptions, initialSupplyDateOptions} = useDataPackageNgdOptions();


    useEffect(() => {
        dispatch(getRecipe(params.recipeId));
        dispatch(resetDraftOrder(true));
        dispatch(updateDraftOrder('recipeId', params.recipeId));
        dispatch(updateDraftOrder('isNgd', true));
    }, [dispatch, params.recipeId]);

    useEffect(() => {
        const hasFilters = recipe?.datasets?.length && recipe.datasets.find(d => !!d.filterExpression);
        dispatch(updateDraftOrder('hasFilters', !!hasFilters));
        if(recipe?.datasets?.length) {
            dispatch(getDatasetOptions(recipe.datasets));
        }
    }, [recipe, dispatch])

    useEffect(() => {
        if(!hasEditNgdDownloadsPermission(userDetails)) {
            redirect.replace(routes.landing);
        }
    }, [userDetails, redirect]);

    let panel;
    if(recipe) {
        panel = <AddDataPackagePanel subtitle={intl.formatMessage(messages.subtitle, { recipe: recipe.name })}
                                     areaRadioOptions={areaRadioOptions}
                                     formatOptions={formatOptions}
                                     crsOptions={crsOptions}
                                     updatesOptions={updatesOptions}
                                     initialSupplyDateOptions={initialSupplyDateOptions}
                                     inputContainerExtraChildren={<PartnerLicences productId={NGD_PRODUCT_ID}/>}
                                     isNgd={true}/>;
    }
    const successPanel = <ConfirmDataPackage addAnotherPackagePath={routePaths.recipeLibrary} />;
    const map = <DataPackageMap/>;

    return <FeatureCheck feature={features.NGD_DOWNLOADS}>
        <AddDataPackage panel={panel}
                        successPanel={successPanel}
                        dataPackageMap={map} />
    </FeatureCheck>;
}
