import React from 'react';
import Header from "../Header";
import Content from '../Content';
import Typography from '@mui/material/Typography';
import logoExample4 from './brandLogoImg/OS_guidelines_API-logo-example-4@2x.png';
import logoExample5 from './brandLogoImg/OS_guidelines_API-logo-example-5@2x.png';
import logoExample6 from './brandLogoImg/OS_guidelines_API-logo-example-6@2x.png';
import withStyles from 'react-jss';
import BrandImage from './BrandImage';
import PropTypes from 'prop-types';
import { styles } from './BrandStyling';
import {defineMessages, FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import Document from '../Document';

const messages = defineMessages({
    paragraph: {
        id: 'LogoWhite.paragraph',
        defaultMessage: 'Our white API logo is to be used on any satellite, photographic or illustrated map as shown below.',
        description: 'White API logo'
    }
})

function LogoWhite(props) {

    const {classes} = props;
    const containerClasses = [classes.mainContainer, classes.threeItems]

    return (
        
        <Document>
            <Header back={true}>Logo overview - white</Header>

            <Content>
                <Typography variant='body1' paragraph={true} component='div' color='textPrimary'>
                    <FormattedMessage {...messages.paragraph} />
                </Typography>

                    <div className={classNames(containerClasses)}>
                            <BrandImage 
                                image={logoExample4} 
                                alt='Example of white-fill Ordnance Survey logo on OS Imagery Layer raster'
                            />
                            <BrandImage 
                                image={logoExample5} 
                                alt='Example of white-fill Ordnance Survey logo on small-scale dark-theme OS Open Zoomstack'
                            />
                            <BrandImage 
                                image={logoExample6} 
                                alt='Example of full-colour Ordnance Survey logo on 3D large-scale vector map'
                            />
                    </div>
            </Content>
        </Document>
    )
}

LogoWhite.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(LogoWhite)