import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DownloadImage from "../DownloadImage";
import OpenDataDesc from "./OpenDataDesc";
import Typography from '@mui/material/Typography';
import Link from '../../../../components/Link';
import {osColour,isWidthDown, withWidth} from 'omse-components';
import withStyles from 'react-jss';
import {compose} from 'redux';
import CatalogueTag from "../../../../components/CatalogueTag";
import {OPEN_CATALOGUE} from "../../../../../shared/catalogues";

const styles = theme => ({
    downloadContainer: {
        display: 'flex',
        marginBottom: theme.spacing(4),
        borderTop: `solid ${osColour.neutral.mist} 1px`,
        paddingTop: theme.spacing(3),
        flexWrap: 'wrap-reverse',
        alignItems: 'start',
        justifyContent: 'space-between'
    },
    download: {
        display: 'flex',
        flex: '1 1 auto',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    downloadTitle: {
        marginBottom: 10,
        marginTop: 2,
        marginRight: theme.spacing(1)
    },
    downloadInfo: {
        flex: '1 1 auto',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 20
        }
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap-reverse',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            alignItems: 'start'
        }
    }
});

export function OpenDataDownloadsItem(props) {
    const [animate, setAnimate] = useState(false);
    const {item, classes, path} = props;

    function startAnimation() {
        // To prevent react from doing unnecessary rendering we avoid altering the state of the component
        // when it is already correct.
        if(!animate) {
            setAnimate(true);
        }
    }

    let imageItem;
    if (isWidthDown('xs', props.width)) {
        imageItem = [item.images[0]];
    }

    return <div className={classes.downloadContainer}
                onMouseEnter={startAnimation}
                onMouseLeave={() => setAnimate(false)}
                onMouseMove={startAnimation}>
            <div className={classes.download}>
                {item.images.length > 0 &&
                    <DownloadImage images={imageItem ? imageItem : item.images} animate={animate} productName={item.name} />
                }
                <div className={classes.downloadInfo}>
                    <div className={classes.titleContainer}>
                        <Link path={path}
                              state={{item}}
                              className={classes.downloadTitle}>
                            <Typography variant='h2'>
                                {item.name}
                            </Typography>
                        </Link>
                        <CatalogueTag type={OPEN_CATALOGUE} />
                    </div>
                    <OpenDataDesc item={item} />
                </div>
            </div>
        </div>;
}

OpenDataDownloadsItem.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired
}

export default compose(withWidth(), withStyles(styles))(OpenDataDownloadsItem);
