import {Text, Fill, Style, Stroke} from 'ol/style';

const gridFillColor = 'rgba(255,255,255,0)';
const gridLineColor = '#979797';
const selectedFillColor = 'rgba(69, 60, 144, 0.2)';
const selectedLineColor = '#453c90';
const inactiveLineColor = 'rgba(172,178,181,1)';
const initialFontSize = 12;
const maxFontSize = 2000;
const scaleConstant = 1000;
const opacityConstant = 1000;
const opacityMin = 0.2;
const selectedMaxResolutionForOpacity = 320;

function getText(feature) {
    return feature.get('TILE_NAME');
}

function getTextColor(opacity, status) {
    let textColor;
    switch (status) {
        case 'selected':
            textColor = `rgba(69,60,144,${opacity})`;
            break;
        case 'inactive':
            textColor =`rgba(172,178,181,${opacity})`;
            break;
        default:
            textColor = `rgba(0,0,0,${opacity})`;
    }
    return textColor;
}

const createTextStyle = (feature, resolution, status) => {

    let scale = scaleConstant / resolution;
    let fontSize = initialFontSize;
    let opacity = 1;

    if (resolution < scaleConstant) {

        fontSize = initialFontSize * scale;
        if (fontSize > maxFontSize) {
            fontSize = 0;
        }

        opacity = resolution / opacityConstant;
        if (opacity < opacityMin) {
            opacity = opacityMin;
        }
    }

    // increase opacity of 'selected' text
    if (status === 'selected') {
        if (resolution > selectedMaxResolutionForOpacity) {
            opacity = 1;
        } else {
            opacity += 0.3;
        }
    }

    let textColor = getTextColor(opacity, status);

    return new Text({
        font: `${fontSize}px Calibri,sans-serif`,
        text: getText(feature),
        fill: new Fill({
            color: textColor
        })
    });
};

export const styleFunction = (feature, resolution) => {
    let status = feature.get('status');
    let style = new Style({
        fill: new Fill({
            color: gridFillColor
        }),
        stroke: new Stroke({
            color: gridLineColor,
            width: 2
        }),
        text: createTextStyle(feature, resolution, status),
        zIndex: 100
    });

    switch (status) {
        case 'inactive':
            style.getStroke().setColor(inactiveLineColor);
            style.setZIndex(50);
            break;

        case 'selected':
            style.getFill().setColor(selectedFillColor);
            style.getStroke().setColor(selectedLineColor);
            style.setZIndex(150);
            break;

        default:
            // empty
    }
    return style;
};