import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import FeatureCheck from "../../../components/FeatureCheck";
import routePaths from "../../../util/routes";
import features from '../../../../shared/features';
import PartnerLicences from '../../../components/PartnerLicences';
import {getPremiumProductOptions} from '../../../modules/premium/actions';
import {loadDataPackage, updateDraftOrder} from '../../../modules/dataPackages/actions';
import AddDataPackage from './AddDataPackage';
import useDataPackagePremiumOptions from './useDataPackagePremiumOptions';
import AddDataPackagePanel from "./addDataPackage/AddDataPackagePanel";
import ConfirmDataPackage from "./ConfirmDataPackage";
import DataPackageMap from "./DataPackageMap";


export default function AddDataPackageLegacyPremium() {
    const dispatch = useDispatch();
    const params = useParams();

    const [productId, setProductId] = useState(params.productId);

    const userDetails = useSelector(state => state.user.current.result);
    let optionsResult = useSelector(state => state.premium.options.result);

    const {areaRadioOptions, formatOptions, updatesOptions, renewalOptions, terminalsOptions} = useDataPackagePremiumOptions();

    // In edit mode we use the data package to tell us which product we are working with.
    // If the data package is not the correct one then we need to be careful not to use it!
    const editMode = !!params.packageId;
    let dataPackage = useSelector(state => state.dataPackages.current.result);
    if(dataPackage && params.packageId !== dataPackage.id) {
        dataPackage = null;
    }

    useEffect(() => { // when in edit mode get the data package (if it isn't already loaded)
        if (params.packageId && !dataPackage) {
            dispatch(loadDataPackage(params.packageId));
        }
    }, [params.packageId, dispatch, dataPackage]);

    useEffect(() => {
        if (productId) {
            dispatch(getPremiumProductOptions(productId));
            dispatch(updateDraftOrder('productId', productId));
        }
    }, [productId, dispatch]);

    useEffect(() => {
        if(optionsResult && optionsResult.catalogues && optionsResult.catalogues.items &&
            userDetails && userDetails.catalogues) {
            // We don't give the user a choice of catalogue, so we just need to find the first catalogue that
            // they hold that is a valid choice for this product. We should never get to this page in the UI
            // if there is no valid catalogue, so we don't need to worry about the error case now.
            const catalogue = optionsResult.catalogues.items.find(cat => userDetails.catalogues.includes(cat.id));
            if(catalogue) {
                dispatch(updateDraftOrder('catalogueId', catalogue.id));
            }
        }
    }, [optionsResult, userDetails, dispatch]);

    useEffect(() => {  // if we have a data package set the values in the store so we can display them
        if (dataPackage) {
            dispatch(updateDraftOrder('id', dataPackage.id));
            dispatch(updateDraftOrder('updates', dataPackage.updateScheduleId));
            dispatch(updateDraftOrder('packageName', dataPackage.name));
            dispatch(updateDraftOrder('format', dataPackage.dataFormatId));

            if (dataPackage.fixedPriceAreas && dataPackage.fixedPriceAreas.length > 0) {
                dispatch(updateDraftOrder('area', dataPackage.fixedPriceAreas[0].id));
            } else {
                dispatch(updateDraftOrder('area', 'Polygon'));
            }

            if (dataPackage.polygon) {
                dispatch(updateDraftOrder("AOI", dataPackage.polygon));
            }

            setProductId(dataPackage.productId);
        }
    }, [dataPackage, dispatch]);

    let product = null;
    if (optionsResult && optionsResult.products && optionsResult.products.itemCount === 1) {
        if (optionsResult.products.items[0].id === productId) {
            product = optionsResult.products.items[0]
        }
    }

    let waitForDataPackage = false;
    if(editMode && !dataPackage) {
        // In edit mode we also need to wait for the data package to load
        waitForDataPackage = true;
    }

    let panel;
    if(product && !waitForDataPackage) {
        panel = <AddDataPackagePanel editMode={editMode}
                                     subtitle={product.label}
                                     areaRadioOptions={areaRadioOptions}
                                     formatOptions={formatOptions}
                                     updatesOptions={updatesOptions}
                                     renewalOptions={renewalOptions}
                                     terminalsOptions={terminalsOptions}
                                     inputContainerExtraChildren={<PartnerLicences productId={productId}/>}
        />;
    }
    const successPanel = <ConfirmDataPackage addAnotherPackagePath={routePaths.premiumDownloads}
                                             editMode={editMode}/>;
    const map = <DataPackageMap productId={productId}/>;

    return <FeatureCheck feature={features.PREMIUM}>
        <AddDataPackage panel={panel}
                        successPanel={successPanel}
                        dataPackageMap={map} />
    </FeatureCheck>;
}
