import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from "react-intl";
import Typography from "@mui/material/Typography";
import {osColour} from "omse-components";
import {createUseStyles} from 'react-jss';
import {ReactComponent as SuccessIcon} from "../../../components/icons/success-green.svg";
import routePaths from "../../../util/routes";
import Link from "../../../components/Link";
import NavButton from "../../../components/NavButton";
import {useSelector} from 'react-redux';

const messages = defineMessages({
    confirmation: {
        id: 'ConfirmDataPackage.confirmation',
        defaultMessage: 'Creating package {packageName}',
        description: 'order confirmation label'
    },
    confirmationEdit: {
        id: 'ConfirmDataPackage.confirmationEdit',
        defaultMessage: 'Edit data package',
        description: 'order confirmation label'
    },
    thankYou: {
        id: 'ConfirmDataPackage.thankYou',
        defaultMessage: 'Thank you.',
        description: 'thank you text'
    },
    creatingPackage: {
        id: 'ConfirmDataPackage.creatingPackage',
        defaultMessage: 'We’re creating your data package. We’ll email you when it’s ready.',
        description: 'creating package text'
    },
    editPackage: {
        id: 'ConfirmDataPackage.editPackage',
        defaultMessage: 'We’re making the changes you requested. We’ll email you when they’re ready.',
        description: 'creating package text'
    },
    checkStatus: {
        id: 'ConfirmDataPackage.checkStatus',
        defaultMessage: 'You can check {link} for package status information.',
        description: 'check status text'
    },
    dataPackages: {
        id: 'ConfirmDataPackage.dataPackages',
        defaultMessage: 'data packages',
        description: 'data packages text'
    },
    addAnother: {
        id: 'ConfirmDataPackage.addAnotherButton',
        defaultMessage: 'Add another data package',
        description: 'add another package button text'
    }
});

const styles = createUseStyles(theme => ({
    title: {
        paddingTop: 50,
        paddingLeft: theme.spacing(3),
        borderBottom: '1px solid ' + osColour.neutral.mist,
        zIndex: 50
    },
    titleText: {
        marginBottom: 25,
        color: osColour.primary.berry
    },
    successContainer: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));

export default function ConfirmDataPackage(props) {
    const {editMode, addAnotherPackagePath} = props;
    const packageName = useSelector((state) => state.dataPackages.draftOrder.packageName);

    const classes = styles();

    return (<Fragment>
        <div className={classes.title}>
            <Typography variant={'h1'} className={classes.titleText}>
                {editMode ?
                    <FormattedMessage {...messages.confirmationEdit} />
                    :
                    <FormattedMessage {...messages.confirmation} values={{packageName}}/>
                }
            </Typography>
        </div>
        <div className={classes.successContainer}>
            <SuccessIcon width={64} height={64}/>
            <Typography variant={'body1'} paragraph={true}>
                <FormattedMessage {...messages.thankYou} />
            </Typography>
            <Typography variant={'body1'} paragraph={true}>
                {editMode ?
                    <FormattedMessage {...messages.editPackage} />
                    :
                    <FormattedMessage {...messages.creatingPackage} />
                }
            </Typography>
        </div>
        <div className={classes.successContainer}>
            <Typography variant={'body1'} paragraph={true}>
                <FormattedMessage {...messages.checkStatus} values={{
                    link:  <Link path={routePaths.dataPackages}>
                        <FormattedMessage {...messages.dataPackages} />
                    </Link>
                }}/>
            </Typography>
        </div>
        <div className={classes.successContainer}>
            <NavButton path={addAnotherPackagePath}
                       color='primary' variant='contained'>
                <FormattedMessage {...messages.addAnother}/>
            </NavButton>
        </div>
    </Fragment>);
}

ConfirmDataPackage.propTypes = {
    addAnotherPackagePath: PropTypes.string.isRequired,
    editMode: PropTypes.bool
};

