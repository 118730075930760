import ContentContainer, { singleColBreakpoint } from './ContentContainer';
import { FormattedMessage, defineMessages } from 'react-intl';
import { ExternalLink } from "omse-components";
import tcCodeExamples from "./img/tc-code-examples.png";
import tcApiDemos from "./img/tc-api-demos.png";
import tcApiTutorials from "./img/tc-api-tutorials.png";
import { Typography } from "@mui/material";
import Card from "../../components/card/Card";
import CardContainer from "./CardContainer";
import { CodeExamplesCardMessages, ApiDemosCardMessages, ApiTutorialsCardMessages } from "./message/GetStarted";
import styled from '@emotion/styled';

const messages = defineMessages({
    title: {
        id: 'ExamplesTutorials.title',
        defaultMessage: 'Examples and Tutorials',
        description: 'Title'
    }
});

const SectionContainer = styled('section')(({ theme }) => `
    & h3 {
        font-family: OSGill, sans-serif;
        color: ${theme.palette.primary.main};
        font-size: 1.8rem;
        margin-bottom: ${theme.spacing(5)};
        ${theme.breakpoints.down(singleColBreakpoint)} {
            margin-bottom: ${theme.spacing(3)};
        }
    }
`);

const ExamplesTutorials = () => (
    <ContentContainer>
        <SectionContainer>
            <Typography gutterBottom variant='h3'>
                <FormattedMessage {...messages.title} />
            </Typography>
            <CardContainer>
                <Card
                    imgSrc={tcCodeExamples}
                    imgAltMessage={CodeExamplesCardMessages.imageDescription}
                    title={CodeExamplesCardMessages.title}
                    titleVariant='h3'
                    titleComponent='h4'
                    description={CodeExamplesCardMessages.description}
                    link={(
                        <ExternalLink
                            href="https://labs.os.uk/public/os-data-hub-examples/"
                            message={CodeExamplesCardMessages.link}
                        />
                    )}
                />

                <Card
                    imgSrc={tcApiDemos}
                    imgAltMessage={ApiDemosCardMessages.imageDescription}
                    title={ApiDemosCardMessages.title}
                    titleVariant='h3'
                    titleComponent='h4'
                    description={ApiDemosCardMessages.description}
                    link={(
                        <ExternalLink
                            href="https://github.com/OrdnanceSurvey/OS-Data-Hub-API-Demos"
                            message={ApiDemosCardMessages.link}
                        />
                    )}
                />

                <Card
                    imgSrc={tcApiTutorials}
                    imgAltMessage={ApiTutorialsCardMessages.imageDescription}
                    title={ApiTutorialsCardMessages.title}
                    titleVariant='h3'
                    titleComponent='h4'
                    description={ApiTutorialsCardMessages.description}
                    link={(
                        <ExternalLink
                            href="https://github.com/OrdnanceSurvey/os-data-hub-tutorials"
                            message={ApiTutorialsCardMessages.link}
                        />
                    )}
                />
            </CardContainer>
        </SectionContainer>
    </ContentContainer>
);

export default ExamplesTutorials;