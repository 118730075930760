import React from "react";
import {Button} from '@mui/material';
import {useIntl, FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import buttonStyles from "./buttonStyles";
import useTooltipHandler from "./useTooltipHandler";
import classNames from 'classnames';

function ToolbarButton(props, ref) {
    const {label, ariaLabel, icon, disabled, active, onClick, tooltip} = props;
    const intl = useIntl();
    const hoverCallback = useTooltipHandler(tooltip);
    const ariaLabelFormatted = intl.formatMessage(ariaLabel);
    const classes = buttonStyles();

    return <div ref={hoverCallback}>
        <Button disableTouchRipple={true} disableRipple={true}
                onClick={onClick}
                disabled={disabled}
                color='primary'
                variant={active ? 'contained' : 'outlined'}
                classes={{root: classNames(classes.button, classes.verticalIconButton), startIcon: classes.icon}}
                aria-label={ariaLabelFormatted}
                startIcon={icon}
                ref={ref}
        >
            <FormattedMessage {...label} />
        </Button>
    </div>;
}

// When the button triggers a menu then the menu position is controlled by a ref. To allow this component to handle
// taking a ref we need to wrap it with React.forwardRef().
const forwarded = React.forwardRef(ToolbarButton);
forwarded.propTypes = {
    label: PropTypes.object.isRequired,
    ariaLabel: PropTypes.object.isRequired,
    icon: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    tooltip: PropTypes.object,
    disabled: PropTypes.bool,
    active: PropTypes.bool
};

export default forwarded;
