import { useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@emotion/styled';

const StyledVideoContent = styled('div')`
    position: relative;

    & .videoIFrame {
        aspect-ratio: 16/9;
        object-fit: cover;
        width: 100%;
    }

    & .loading {
        position: absolute;
        z-index: 1;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
`;

const VideoContent = ({ videoId, videoTitle, provider }) => {
    const [ loaded, setLoaded ] = useState(false);

    let videoSrc;
    if (provider === 'youtube') {
        videoSrc = "https://www.youtube-nocookie.com/embed/" + videoId + '?rel=0';
    }

    return (
        <StyledVideoContent>
            {provider === 'youtube' && (
                <>
                    {!loaded &&
                        <CircularProgress size={24} className='loading' />
                    }
                    <iframe
                        className='videoIFrame'
                        onLoad={() => setLoaded(true)}
                        title={`YouTube Video - ${videoTitle}`}
                        src={videoSrc}
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        aria-label={`YouTube Video - ${videoTitle}`}
                    />
                </>
            )}
        </StyledVideoContent>
    );
}

VideoContent.propTypes = {
    videoId: PropTypes.string.isRequired,
    videoTitle: PropTypes.string.isRequired,
    provider: PropTypes.string,
    variant: PropTypes.string
}

VideoContent.defaultProps = {
    provider: 'youtube'
}

export default VideoContent;