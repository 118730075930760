import React from 'react';
import { CommonDialog, CommonDialogActions, ExternalLink } from 'omse-components';
import PropTypes from 'prop-types'
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'FCPDialog.title',
        defaultMessage: 'Accept Framework Contract',
        description: 'Label for the title on the dialog'
    },
    agree: {
        id: 'FCPDialog.agree',
        defaultMessage: 'Accept and continue',
        description: 'Label for the confirmation button on the dialog'
    },
    intro: {
        id: 'FCPDialog.intro',
        defaultMessage: 'Please read and accept the {link} to use Premium products.',
        description: 'Text for the FCP dialog'
    },
    link: {
        id: 'FCPDialog.link',
        defaultMessage: 'Framework Contract (Partners)',
        description: 'Link text for the FCP link'
    }
});

export default function FCPDialog(props) {
    const {onConfirm, onClose} = props;

    const actions = <CommonDialogActions onConfirm={onConfirm}
                                         onClose={onClose}
                                         confirmLabel={messages.agree}/>;

    return <CommonDialog actions={actions}
                         onClose={onClose}
                         title={messages.title}
    >
        <FormattedMessage {...messages.intro} values={{
            link: <ExternalLink
                type='generic'
                href={'https://www.ordnancesurvey.co.uk/documents/licences/framework-contract-partners.pdf'}
                message={messages.link}
            />
        }}/>
    </CommonDialog>;
}

FCPDialog.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}
