import {useLocation, useRouteMatch} from "react-router-dom";
import {useEffect} from "react";
import {getDownloadsForProduct, getProduct} from "../modules/downloads/actions";
import {useDispatch, useSelector} from "react-redux";

const getProductResult = (productId, location, product, productLoading) => {
    if (location?.state?.item) {
        product = location.state.item;
        productLoading = false;
    } else if (product?.id && product.id !== productId) {
        product = null;
        productLoading = true;
    }

    return {
        product,
        productLoading
    }
}

const getDownloadsResult = (productId, downloads, downloadsLoading) => {
    if (downloads?.id && downloads.id !== productId) {
        downloads = null;
        downloadsLoading = true;
    }

    return {
        downloads: downloads || [],
        downloadsLoading
    }
}

const getError = (productId, productResultError, downloadsResultError) => {
    const isError =
        productResultError && productResultError.id === productId &&
        downloadsResultError && downloadsResultError.id === productId;

    return {
        error: isError
    }
}

export default function useOpenDataItem() {
    const match = useRouteMatch();
    const location = useLocation();
    const dispatch = useDispatch();
    const { result: productResult, loading: productResultLoading, error: productResultError } = useSelector(state => state.downloads.product);
    const { result: downloadsResult, loading: downloadsResultLoading, error: downloadsResultError } = useSelector(state => state.downloads.downloads);

    const productId = match.params.downloadId;

    useEffect(() => {
        dispatch(getProduct(productId));
        dispatch(getDownloadsForProduct(productId));
    }, [dispatch, productId]);

    return {
        ...getProductResult(productId, location, productResult, productResultLoading),
        ...getDownloadsResult(productId, downloadsResult, downloadsResultLoading),
        ...getError(productId, productResultError, downloadsResultError)
    }
}