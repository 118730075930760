import React from "react";
import {defineMessages} from "react-intl";
import DataPackageDropdownField from './DataPackageDropdownField';
import {useDispatch} from "react-redux";
import {updateDraftOrder} from "../../../../modules/dataPackages/actions";

const messages = defineMessages({
    updates: {
        id: 'UpdatesDropdown.updates',
            defaultMessage: 'Updates',
            description: 'updates label'
    },
    updatesNeeded: {
        id: 'UpdatesDropdown.updatesNeeded',
        defaultMessage: 'Please select an update option',
        description: 'updates needed'
    },
    select: {
        id: 'UpdatesDropdown.select',
        defaultMessage: 'Select updates',
        description: 'updates select'
    },
    checkBoxMessage: {
        id: 'UpdatesDropdown.checkBoxMessage',
        defaultMessage: 'I want a one-off snapshot of a current or past date',
        description: 'updates checkbox Message'
    }
});



export default function UpdatesDropdown({updatesOptions, isNgd}) {
    const dispatch = useDispatch();
    function checkBoxFunction(isChecked){
        dispatch(updateDraftOrder('isSnapBack',isChecked));
        if(!isChecked){
            dispatch(updateDraftOrder('initialSupplyDataDate', undefined));
            dispatch(updateDraftOrder('updates', undefined));
        }
    }

    // Show checkbox for ngd only via optional checkbox props
    const checkBoxProps = isNgd ? {
        checkBoxMessage : messages.checkBoxMessage,
        checkBoxFunction : checkBoxFunction,
        checkBoxFieldName : 'isSnapBack'
    } : {};

    return <DataPackageDropdownField
        options={updatesOptions}
        fieldName='updates'
        labelMessage={messages.updates}
        selectMessage={messages.select}
        fieldErrorMessage={messages.updatesNeeded}
        {...checkBoxProps}
    />
}