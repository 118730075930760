import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {CommonDialog, CommonDialogActions, StyledCheckbox} from 'omse-components';
import {defineMessages, FormattedMessage} from 'react-intl';
import {FormControlLabel, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {revokeUser} from "../../../modules/organisation/action";

const messages = defineMessages({
    title: {
        id: 'RevokeAccessDialog.title',
        defaultMessage: 'Revoke access',
        description: 'Title for the change role dialog'
    },
    description1: {
        id: 'RevokeAccessDialog.description1',
        defaultMessage: 'This action is permanent. It revokes the user’s access and they will not be able to log in to your organisation’s account.',
        description: 'description1 text for the revoke access dialog'
    },
    description2: {
        id: 'RevokeAccessDialog.description2',
        defaultMessage: 'You’ll still be able to see historical statistics for this user.',
        description: 'description2 text for the revoke access dialog'
    },
    description3: {
        id: 'RevokeAccessDialog.description3',
        defaultMessage: 'You can re-invite them at a later date.',
        description: 'description3 text for the revoke access dialog'
    },
    apiKeySuggestion: {
        id: 'RevokeAccessDialog.apiKeySuggestion',
        defaultMessage: 'You may wish to consider regenerating your API keys as the user whose access is being revoked may still have a record of them.',
        description: 'apiKeySuggestion text for the revoke access dialog'
    },
    ok: {
        id: 'RevokeAccessDialog.ok',
        defaultMessage: 'Revoke access',
        description: 'Label for the confirm button'
    },
    cancel: {
        id: 'RevokeAccessDialog.cancel',
        defaultMessage: 'Cancel',
        description: 'Label for the cancel button'
    },
    sendEmail: {
        id: 'RevokeAccessDialog.sendEmail',
        defaultMessage: 'Send {email} an email to confirm that their access to this system has been revoked',
        description: 'Label for the email checkbox'
    }
});

const styles = createUseStyles(theme => ({
    selection: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1)
    },
    apiKeySuggestion: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        fontWeight: 'bold'
    },
    checkbox: {
        paddingTop: theme.spacing(0.5),
        alignSelf: "flex-start"
    }
}));

let actionIdCounter = 0;

export default function RevokeAccessDialog({user, onClose, testActionId}) {
    const [sendEmail, setSendEmail] = useState(true);
    const [actionId] = useState(testActionId || actionIdCounter++);
    const {working, result} = useSelector(state => {
        if(state.organisation.revokeUser.actionId === actionId) {
            return state.organisation.revokeUser;
        }
        return {};
    });
    const dispatch = useDispatch();
    const classes = styles();

    // Auto-close the dialog once we have finished setting the role
    useEffect(() => {
        if(result !== undefined) {
            onClose();
        }
    }, [result, onClose]);

    const actions = <CommonDialogActions confirmLabel={messages.ok}
                                         cancelLabel={messages.cancel}
                                         onClose={onClose}
                                         onConfirm={() => {
                                            const action = revokeUser(user.id, sendEmail);
                                            action.actionId = actionId;
                                            dispatch(action);
                                         }}
                                         working={working}
    />;
    return <CommonDialog title={messages.title}
                         actions={actions}
                         onClose={onClose}
    >
        <Typography className={classes.selection}>
        <FormattedMessage {...messages.description1} />
        </Typography>

        <Typography className={classes.selection}>
        <FormattedMessage {...messages.description2} />
        </Typography>

        <Typography className={classes.selection}>
        <FormattedMessage {...messages.description3} />
        </Typography>

        <Typography className={classes.apiKeySuggestion}>
            <FormattedMessage {...messages.apiKeySuggestion} />
        </Typography>
 
        <FormControlLabel className={classes.selection}
                          control={<StyledCheckbox classes={{checkBox: classes.checkbox}}
                                                    checked={sendEmail}
                                                    onClick={() => setSendEmail(!sendEmail)}/>}
                          label={<FormattedMessage {...messages.sendEmail}
                                                   values={{email: user.email}}/>}/>
    </CommonDialog>;
}

RevokeAccessDialog.propTypes = {
    user: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
