import { defineMessages } from "react-intl";

export const messages = defineMessages({
    emptyProjectReadOnly: {
        id: 'APIProject.emptyProjectReadOnly',
        defaultMessage: 'Your project is empty.',
        description: 'Empty Project page header for read-only users'
    },
    emptyProject: {
        id: 'APIProject.emptyProject',
        defaultMessage: 'Your project is empty. Add some APIs to get going!',
        description: 'Empty Project page header'
    },
    projectPageDescription: {
        id: 'APIProject.projectPageDescription',
        defaultMessage: 'This is a project page. You can group APIs by projects that you are working on. You might have one for your mobile app, ' +
        'and one for your web app to keep all your stats separate. This would let you track which version of your app is using the most API transactions.',
        description: 'Paragraph explaining what the page is about'
    },
    whatNext: {
        id: 'APIProject.whatNext',
        defaultMessage: 'What next?',
        description: 'What next section header'
    },
    rename: {
        id: 'APIProject.rename',
        defaultMessage: 'Rename this project',
        description: 'Rename project paragraph header'
    },
    renameParagraph: {
        id: 'APIProject.renameParagraph',
        defaultMessage: 'Click on the project name at the top of this page to rename it.',
        description: 'Paragraph explaining how to rename a project'
    },
    addAPIs: {
        id: 'APIProject.addAPIs',
        defaultMessage: 'Add some APIs to this project',
        description: 'Add APIs paragraph header'
    },
    addAPIsParagraph: {
        id: 'APIProject.addAPIsParagraph',
        defaultMessage: 'If you know which API you want you can {addLink}.',
        description: 'Paragraph explaining how to add an API'
    },
    addAPILink: {
        id: 'APIProject.addAPILink',
        defaultMessage: 'add it from here',
        description: 'Link to add API'
    },
    addAPIToThisProjectButton: {
        id: 'APIProject.addAPIToThisProjectButton',
        defaultMessage: 'Add an API to this project',
        description: 'Add API to project button text'
    }
});