import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {createUseStyles} from 'react-jss';
import {defineMessages, FormattedMessage, FormattedDate} from 'react-intl';
import Typography from '@mui/material/Typography';
import {AddButton, osColour} from 'omse-components';
import routes, {getLocation} from '../../../util/routes';
import Link from "../../../components/Link";
import routePaths from "../../../util/routes";
import useScrollPosition from "../../../hooks/useScrollPosition";
import {hasEditNgdDownloadsPermission} from "../../../util/permissions";
import usePartnerContracts from "../../../hooks/usePartnerContracts";
import {NOT_NEEDED, LOADING} from "../../../constants/state";
import PartnerLicenceRequiredDialog from "../premiumItem/PartnerLicenceRequiredDialog";
import {NGD_PRODUCT_ID} from "../../../../shared/constants";
import SharedWithMeTag from "../../../components/SharedWithMeTag";
import {styles} from '../DownloadStyles';
import ThemeChips from '../../../components/ThemeChips';

const messages = defineMessages({
    createdBy: {
        id: 'RecipeListItem.createdBy',
        defaultMessage: 'Author:',
        description: 'Label for created by label'
    },
    createdOn: {
        id: 'RecipeListItem.createdOn',
        defaultMessage: 'Creation date:',
        description: 'Label for created date label'
    },
    addDataPackageButton: {
        id: 'RecipeListItem.addDataPackageButton',
        defaultMessage: 'Add data package',
        description: 'Label for the subscribe button'
    }
});

const useDownloadStyles = createUseStyles(styles);
const useStyles = createUseStyles(theme => {
    const sectionSpacing = theme.spacing(3);
    return {
        root: {
            // Reduced top padding takes into account the whitespace that the name header typography includes, making
            // things appear visually balanced.
            padding: theme.spacing(2, 0, 3, 0),
            borderTop: `solid 1px ${osColour.neutral.mist}`,
            listStyleType: 'none',
            '&:first-child': {
                borderTop: 0,
                paddingTop: 0,
                [theme.breakpoints.down('sm')]: {
                    paddingTop: theme.spacing(2),
                }
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }
        },
        metadataSection: {
            display: 'grid',
            gridTemplateColumns: theme.spacing(16, '1fr'),
            gridAutoRows: '1fr',
            alignItems: 'end',
            marginTop: sectionSpacing,
            marginBottom: sectionSpacing,
        },
        metadataLabel: {
            display: 'flex',
            flexDirection: 'column',
            color: osColour.neutral.stone,
        },
        description: {
            marginBottom: sectionSpacing,
        },
        addDataPackageButton: {
            marginTop: sectionSpacing,
        }
    };
});

export default function RecipeListItem({recipe, featureTypeMap}) {
    const classes = useStyles();
    const downloadClasses = useDownloadStyles();
    const location = useLocation();
    const history = useHistory();
    const { getScrollPosition, setElement } = useScrollPosition();
    const user = useSelector(state => state.user.current.result);

    const themesLabels = new Set();
    recipe.datasets.forEach(dataset => {
        const feature = featureTypeMap[dataset.featureTypeId];
        if(feature) {
            themesLabels.add(feature.theme.label);
        }
    });

    const licences = usePartnerContracts(NGD_PRODUCT_ID);
    const [showDialog, setShowDialog] = useState(false);

    const linkPath = routePaths.recipeDetails.replace(':recipeId', recipe.id);

    // When building up the state for a link, we want to store the current search text and scroll position.
    function buildState() {
        return {
            stored: {
                ...location.state,
                scrollPosition: getScrollPosition()
            },
            canGoBack: true
        };
    }

    function handleAddDataPackageClick() {
        if(licences === NOT_NEEDED || (Array.isArray(licences) && licences.length)) {
            const newLocation = getLocation(
                routes.dataPackageNewNgd.replace(':recipeId', recipe.id),
                location,
                {},
                { state: buildState() });
            history.push(newLocation);
        } else {
            setShowDialog(true);
        }
    }

    function closeDialog() {
        setShowDialog(false);
    }

    return <li className={classes.root} ref={setElement}>
        <div className={downloadClasses.title}>
            <Link path={linkPath}
                  state={buildState}>
                <Typography variant='h2'>
                    {recipe.name}
                </Typography>
            </Link>
            {recipe.sharedWithMe && <SharedWithMeTag />}
        </div>
        <dl className={classes.metadataSection}>
            <RecipeMetadata labelMessage={messages.createdBy} value={recipe.createdBy} />
            <RecipeMetadata labelMessage={messages.createdOn} value={<FormattedDate value={recipe.created}
                                                                                    day='numeric'
                                                                                    month='numeric'
                                                                                    year='numeric'/>} />
        </dl>

        <Typography variant='body1' className={classes.description}>
            {recipe.description}
        </Typography>

        <ThemeChips themes={Array.from(themesLabels)} />

        {
            hasEditNgdDownloadsPermission(user) &&
            <AddButton label={messages.addDataPackageButton}
                       className={classes.addDataPackageButton}
                       action={handleAddDataPackageClick}
                       disabled={licences === LOADING}
                       working={licences === LOADING}
                       showIcon={false}
            />
        }
        {
            showDialog && <PartnerLicenceRequiredDialog onClose={closeDialog} productId={NGD_PRODUCT_ID} />
        }
    </li>
}

/* Recipe metadata */
const useRecipeMetadataStyles = createUseStyles({
    label: {
        color: osColour.neutral.stone,
    }
});

function RecipeMetadata({labelMessage, value}) {
    const classes = useRecipeMetadataStyles();
    return <>
        <Typography component='dt' className={classes.label}>
            <FormattedMessage {...labelMessage}/>
        </Typography>
        <Typography component='dd' variant='body2'>{value}</Typography>
    </>;
}