import {createActionReducer, createActionMiddleware} from 'omse-components';
import {DELETE_PRODUCT, LOADED_PROJECT_ACTION} from "./actions";
import {withOrganisation} from '../../util/organisation';
import _remove from 'lodash/remove'

export const reducer = createActionReducer(DELETE_PRODUCT);

function setupCall(action, store) {
    return withOrganisation({
        url: '/api/projects/' + action.projectId + "/product/" + action.productId,
        method: 'DELETE'
    }, store);
}
function success(store, action) {
    // Now that the project has been updated, put it back into the store
    const { result } = store.getState().project.current;
    if(result && result.id === action.projectId) {

        const newResult = {...result};
        _remove(newResult.products, (product) => {
            return product.id === action.productId
        });

        store.dispatch({
            type: LOADED_PROJECT_ACTION,
            result: newResult
        });
    }
}

export const middleware = createActionMiddleware(DELETE_PRODUCT, setupCall, success);
