import {combineReducers} from "redux";
import {middleware as projectStatsMiddleware, reducer as projectStatsReducer} from './stats/project';
import {middleware as userStatsMiddleware, reducer as userStatsReducer} from './stats/user';
import {middleware as historyStatsMiddleware, reducer as historyStatsReducer} from './stats/history';

export const statsReducer = combineReducers({
    user: userStatsReducer,
    project: projectStatsReducer,
    history: historyStatsReducer
});

export const statsMiddleware = [
    ...userStatsMiddleware,
    ...projectStatsMiddleware,
    ...historyStatsMiddleware
];