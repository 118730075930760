import React, {Component} from 'react';
import {defineMessages} from 'react-intl';
import CompletionSummary from '../CompletionSummary';
import PropTypes from 'prop-types';

export const messages = defineMessages({
    achievement1: {
        id: 'WFSCompletionSummary.achievement1',
        defaultMessage: `You've obtained the URL and generated an API key for OS Features API.`,
        description: 'Achievement 1'
    },
    achievement2: {
        id: 'WFSCompletionSummary.achievement2',
        defaultMessage: `You've used the URL and Key to install OS Features API layers in {software}.`,
        description: 'Achievement 2'
    },
    achievement3: {
        id: 'WFSCompletionSummary.achievement3',
        defaultMessage: `You've set minimum and maximum scales at which a specific feature will become visible.`,
        description: 'Achievement 3'
    }
});

export class WFSCompletionSummary extends Component {

    render() {
        const {software, achievements} = this.props;
        return <CompletionSummary achievements={achievements} software={software} api={`wfs`} />
    }
}

WFSCompletionSummary.propTypes = {
    achievements: PropTypes.arrayOf(PropTypes.object),
    software: PropTypes.string.isRequired
}

WFSCompletionSummary.defaultProps = {
    achievements: [
        messages.achievement1,
        messages.achievement2,
        messages.achievement3
    ]
}

export default WFSCompletionSummary;