import React, {Fragment} from 'react';
import ActivityRoleTable from '../tables/ActivityRoleTable';
import TransactionTable from '../tables/TransactionTable';
import PricingTable from "../../../plans/PricingTable";
import { dataProducts, featureRequestType, mapView } from './transactions';
import {defineMessages} from "react-intl";
import { ExternalLink } from 'omse-components';
import figureSwitchAccounts from '../img/SwitchAccounts.png';
import routePaths from "../../../../util/routes";
import routes, {
    PARTNER_MEMBER_URL,
    PSGA_COMMERCIAL_ENQUIRIES,
    PSGA_DESCRIPTION_URL,
    PSGA_MEMBER_URL,
    ROYAL_MAIL_SOLUTION_PROVIDER_LICENSE
} from "../../../../util/routes";
import Link from "../../../../components/Link";
import featureCheck from "../../../../util/featureCheck";
import features from "../../../../../shared/features";

const messages = defineMessages({
    findOutMember: {
        id: 'plans.findOutMember',
        defaultMessage: 'Find out if you are a member',
        description: 'Find out if member text'
    },
    findParter: {
        id: 'plans.findParter',
        defaultMessage: 'find a partner',
        description: 'Find partner text'
    },
    contactUs: {
        id: 'plans.contactUs',
        defaultMessage: 'contact us',
        description: 'Contact us text'
    },
    psgaFindOutMore: {
        id: 'plans.psgaFindOutMore',
        defaultMessage: 'Find out more about joining the PSGA',
        description: 'PSGA find out more text'
    },
    pafLicense: {
        id: 'plans.pafLicense',
        defaultMessage: 'PAF Data Solutions Provider Licence',
        description: 'Royal Mail PAF Data Solutions Provider license text'
    }
});
function mapListing(config){
    if (featureCheck(features.NGD_TILES, config)){
        return "OS Maps API, OS Vector Tile API and OS NGD API – Tiles"
    }
    return "OS Maps API and OS Vector Tile API"

}
const plans = config => {
    return [
        {
            heading: 'Plans',
            faqs: [
                {
                    id: 'differentPlans',
                    title: 'What’s the difference between the OS OpenData Plan, the Premium Plan, the Public Sector Plan, and the Energy & Infrastructure Plan?',
                    body: <Fragment>
                        <p>The <strong>OS OpenData Plan</strong> is free to use for any purpose, including for services
                            from
                            which you make a profit. Our OS OpenData Plan means you consume only OS OpenData. It locks
                            down
                            calls to our APIs so that you can’t use or be charged for our premium data. You can change
                            to
                            the Premium Plan at any point to unlock access to our premium data.</p>
                        <p>Our <strong>Premium Plan</strong> gives you access to our premium data, which is our most
                            detailed mapping and geospatial feature data. You get up to £1,000 of free premium data
                            transactions each month and once you've used up your free limit, we'll charge you for any
                            more
                            premium data you use. You can register a payment card, and so long as that card stays valid,
                            we’ll charge you for what you use (above the free transactions). If your payment details
                            lapse,
                            or you didn’t provide them, we’ll pause your access to our premium data until the next month
                            when your £1,000 limit of free premium data starts again. We will notify you when the number
                            of
                            premium transactions are exceeded and you can continue to access our OS OpenData during this
                            time.</p>
                        <p>Our <strong>Public Sector Plan</strong> is for approved PSGA Members and gives them free
                            access to premium data and OS APIs. PSGA Members can invite contractors to access data
                            as part of their account. </p>
                        <p>The <strong>Energy & Infrastructure Plan</strong> is for companies in that sector who use our
                            data for internal business use. This plan does not qualify you to receive any free premium
                            OS
                            data.</p>
                    </Fragment>
                },
                {
                    id: 'whoCanAccessPlans',
                    title: 'Who can access the different plans?',
                    body: <p>
                        Anyone can access our OS OpenData Plan, and you can use OS OpenData for any purpose, including
                        for your own use or for other companies. Our Premium Plan is for Partners building an app or
                        service
                        for use by a third party such as the public or another business. Our Public Sector Plan is for
                        approved PSGA Members and their contractors. <ExternalLink
                            type='generic'
                            href={PSGA_MEMBER_URL}
                            message={messages.findOutMember}
                        />.
                        Our Energy & Infrastructure Plan is for customers in that sector who use OS data for internal
                        business use to maintain and use their assets effectively.
                    </p>
                },
                {
                    id: 'whatIsAPremiumPlan',
                    title: 'What is a Partner, and do I qualify as one?',
                    body: <p>
                        We class a Partner as an individual or organisation who builds an app or service
                        for use by a third party, such as the public or another business. Organisations that build apps
                        or services
                        only for their own internal business use, i.e. for the internal administration or business
                        operations,
                        are not a Partner. If you want to use our data for internal business use, you can use our OS
                        OpenData
                        Plan or contact one of our Partners (<ExternalLink
                            type='generic'
                            href={PARTNER_MEMBER_URL}
                            message={messages.findParter}
                        />). If you’re in the
                        energy and infrastructure sector,
                        please <ExternalLink
                            type='generic'
                            href={PSGA_COMMERCIAL_ENQUIRIES}
                            message={messages.contactUs}
                        /> so we can help
                        you.
                    </p>

                },
                {
                    id: 'howToChangePlan',
                    title: 'How do I change my plan? ',
                    body: <Fragment>
                        <p>
                            OS OpenData Plan users can change to the Premium Plan or Public Sector Plan within their OS
                            Data
                            Hub account on the <a href={routes.plans}>Plans</a> page.
                            Please <ExternalLink type='support' /> if you want to change from a Premium Plan, a Public Sector Plan,
                            or
                            an Energy & Infrastructure Plan to an OS OpenData Plan.
                        </p>
                        <p>
                            To change from a Premium Plan to Public Sector Plan on the <a href={routes.plans}>API Plans
                            &
                            Pricing page</a>, your organisation must have signed up to the Public Sector Geospatial
                            Agreement (PSGA) in order to receive free data through our Public Sector Plan. <ExternalLink
                                type='generic'
                                href={PSGA_DESCRIPTION_URL}
                                message={messages.psgaFindOutMore}
                            />. Once you're a PSGA member,
                            you will be able to change from the Premium Plan to the Public Sector Plan within your OS
                            Data
                            Hub account on the <a href={routes.plans}>Plans</a> page.
                        </p>
                    </Fragment>
                },
                {
                    id: 'howToCancelPlan',
                    title: 'How do I cancel my plan?',
                    body: <p>
                        If you no longer require your account, please <ExternalLink type='support' />.
                    </p>
                },
                {
                    id: 'applyForMorePlans',
                    title: 'Can I apply for more than one plan per organisation?',
                    body: <p>
                        It is possible to register for multiple OS OpenData Plan accounts using different email
                        addresses.
                        If you would like to register for multiple Premium Plan, Public Sector Plan, or Energy &
                        Infrastructure Plan accounts, please <ExternalLink type='support' />.
                    </p>
                },
                {
                    id: 'personalProjectsPostInviteAccept',
                    title: 'Can I still see my personal projects on the OS OpenData Plan if I accept an invite to join an organisation on the Premium Plan?',
                    body: <p>Yes. You'll have access to an organisation's projects from your account with them as well
                        as access to your projects on your personal account.</p>
                },
                {
                    id: 'howToSwitchAccounts',
                    title: 'How do I switch between my accounts?',
                    body: <Fragment>
                        <p>When you log into the OS Data Hub, your default view will be the organisation account you’ve
                            joined on the Premium Plan. To view your personal account on the OS OpenData Plan, switch
                            views by selecting &lt;Personal&gt; in the secondary navigation menu.</p>
                        <img src={figureSwitchAccounts} alt='OS Data Hub Screenshot: Dashboard View'/>
                    </Fragment>
                },
                {
                    id: 'previousApiUsage',
                    title: 'What happens to my current API projects when I have upgraded from the OpenData Plan to the Premium Plan?',
                    body: <Fragment>
                        <p>When you upgrade to the Premium Plan all your existing projects will still be available.
                            However, to accurately track your OpenData and Premium data transaction usage, your previous
                            usage is reset and is not displayed on the API dashboard or for any existing API
                            project.</p>
                    </Fragment>
                },
            ]
        },
        {
            heading: 'OS Data Hub user roles',
            faqs: [
                {
                    id: 'adminRole',
                    title: 'What is an admin user?',
                    body: <p>
                        Admin users exist on the Premium Plan and Public Sector Plan and have additional permissions for
                        account management processes. When you first register your organisation for the Premium Plan or
                        Public Sector Plan you become the first admin user for that organisation. You can assign other
                        users
                        to be admins and, so long as there are other admins, you can also remove yourself as an admin.
                        On the
                        Public Sector Plan, admin users can create and manage data download requests.
                        See our <Link path={routePaths.supportDownload}>Download FAQs</Link> for further information.
                    </p>
                },
                {
                    id: 'financeRole',
                    title: 'What is a finance user?',
                    body: <p>
                        Finance users are only on the Premium Plan. An admin can assign this role to any existing user
                        within your organisation OR invite a new member to join with the role of Finance user. Finance
                        users
                        have additional permissions relating to payment processes.
                    </p>
                },
                {
                    id: 'userRole',
                    title: 'What is a user?',
                    body: <p>
                        Users exist on all OS Data Hub Plans. You become a user by either registering for the OS
                        OpenData
                        Plan or being invited to join an organisation's Premium Plan or Public Sector Plan by an admin.
                    </p>
                },
                {
                    id: 'contractorRole',
                    title: 'What is a contractor user?',
                    body: <p>
                        Contractor users exist only on the Public Sector Plan. Contractor users are invited by an admin
                        to
                        join their PSGA Member organisation with either read and write access or read-only access to
                        data.
                        Contractors assigned read and write access can create new API projects, use existing projects,
                        and
                        create and manage data download requests on behalf of the PSGA Member.
                        See our <Link path={routePaths.supportDownload}>Download FAQs</Link> for further information.
                        Contractors assigned read-only access can’t modify any API actions such as regenerating keys or
                        creating new projects,
                        though they can access existing API projects’ keys.
                    </p>
                },
                {
                    id: 'roleDifference',
                    title: 'An overview of the permissions for each OS Data Hub role',
                    body: <Fragment>
                        <p>The following tables give an overview of the permissions available to each OS Data Hub user
                            role
                            and lists the notifications users will receive:</p>
                        <ActivityRoleTable/>
                    </Fragment>
                },
                {
                    id: 'inviteUser',
                    title: 'How do I invite another user to join my organisation?',
                    body: <Fragment>
                        <p>Only admins can do this.</p>
                        <ol>
                            <li>Log in to the OS Data Hub and click on your display name.</li>
                            <li>In the drop-down menu, select <i>Account</i>.</li>
                            <li>Select <i>Manage team members</i> in the secondary navigation menu.</li>
                            <li>Click <i>Invite team members</i>.</li>
                            <li>Select the role and enter the email address of the team member you want invited.</li>
                            <li>Click <i>Add team member</i>.</li>
                        </ol>
                        <p>The user will now show as ‘invited’ on the <i>Manage team members</i> page, they’ll get an
                            email
                            notification with instructions on how to join. They have 14 days to accept the invitation.
                        </p>
                    </Fragment>
                },
                {
                    id: 'inviteExpires',
                    title: 'My invitation has expired. What can I do?',
                    body: <p>After 14 days, the invitation to join the OS Data Hub expires. You can contact your admin
                        to
                        ask for a new invitation.</p>
                },
                {
                    id: 'addContractor',
                    title: 'How do I add a contractor to my organisation?',
                    body: <p>Under the Public Sector Plan, admins can invite contractors to join their organisation as a
                        read/write or read-only user using the normal invite another user process.</p>
                },
                {
                    id: 'multipleContractorUsers',
                    title: 'I’m a contractor user, can I access multiple Public Sector Plan organisations?',
                    body: <p>Yes, although you’ll need to use a different email address as currently you can only use an
                        email address once across multiple Public Sector Plan organisations. This functionality will be
                        implemented in a future
                        release.</p>
                },
                {
                    id: 'changeRoles',
                    title: 'How do I change user roles?',
                    body: <Fragment>
                        <p>Only admins can do this.</p>
                        <ol>
                            <li>Log in to the OS Data Hub and click on your display name.</li>
                            <li>In the drop-down menu, select <i>Account</i>.</li>
                            <li>Select <i>Manage team members</i> in the secondary navigation menu.</li>
                            <li>Select <i>Actions</i> against the relevant user.</li>
                            <li>Click <i>Change role</i> from the drop-down menu.</li>
                            <li>Select the new role to be allocated and uncheck <i>send email</i> if not needed.</li>
                            <li>Click <i>Change role</i>.</li>
                        </ol>
                        <p>
                            There must always be at least one Admin user per Premium Plan or Public Sector Plan
                            organisation. If your change means there would no longer be an admin, the request will be
                            denied.
                        </p>
                    </Fragment>
                },
                {
                    id: 'removeUser',
                    title: 'How do I remove a user from my organisation?',
                    body: <Fragment>
                        <p>Only admins can do this.</p>
                        <ol>
                            <li>Log in to the OS Data Hub and click on your display name.</li>
                            <li>In the drop-down menu, select <i>Account</i>.</li>
                            <li>Select <i>Manage team members</i> in the secondary navigation menu.</li>
                            <li>Select <i>Actions</i> against the relevant user.</li>
                            <li>Click <i>Revoke access</i> from the drop-down menu.</li>
                            <li>Uncheck <i>send email</i> if not needed.</li>
                            <li>Click <i>Revoke access</i>.</li>
                        </ol>
                        <p>
                            The user is removed from the Manage team members page and they receive an email notification
                            if
                            this option is selected. They’ll no longer be able to access projects and APIs under your
                            organisation, though they’ll have access to the OS Data Hub under the OS OpenData Plan.
                        </p>
                    </Fragment>
                },
            ]
        },
        {
            heading: 'Transactions, map views and feature requests',
            id: 'apiTransactionViewsRequests',
            faqs: [
                {
                    id: 'apiTransactions',
                    title: 'What’s an API transaction?',
                    body: <Fragment>
                        <p>API transactions are how we measure and charge for the use of our APIs. What counts as one
                            API
                            transaction depends on the data format. Raster and vector transactions are measured in map
                            views. Others are measured as feature requests.
                        </p>
                        <p>Below are the transactions for different APIs:</p>
                        <TransactionTable products={dataProducts(config)}/>
                        <p>For example, if you make 750 calls to the OS Maps API it will return 750 map tiles,
                            which equal to 50 "map views", so you'll have used up 50 transactions.</p>
                        <p>On all plan types, you get an unlimited number of OpenData transactions. On the Premium Plan,
                            you
                            get up to £1,000 of free premium data transactions every month (excluding OS Places API and
                            OS
                            Match &amp; Cleanse API). On the Public Sector Plan, you get an unlimited number of premium
                            data
                            transactions. The Energy and Infrastructure Plan does not entitle you to any free data transactions.</p>
                    </Fragment>
                },
                {
                    id: 'mapView',
                    title: 'What’s a map view?',
                    body: <Fragment>
                        <p>A map view contains enough mapping to fill the average desktop computer screen. A map view
                            represents one transaction in {mapListing(config)}.</p>
                        <TransactionTable products={mapView(config)}/>
                        <p>OS Maps API uses raster tiles and OS Vector Tile
                            API {mapListing(config)} uses vector
                            tiles. A raster tile does not cover
                            as large an area as a vector tile, so it takes 15 raster tiles to fill the same map view as
                            only
                            four vector tiles.</p>
                    </Fragment>
                },
                {
                    id: 'featureRequest',
                    title: 'What’s a feature request?',
                    body: <Fragment>
                        <p>A feature request represents one transaction from these APIs:</p>
                        <TransactionTable products={featureRequestType(config)}/>
                        <p>Each successful feature request can return up to 100 features, though it may be fewer. For
                            example, if you make five calls to OS Features API for building geometry and each of those
                            calls
                            returns the maximum 100 buildings, this is five transactions. Equally, if you make five
                            calls to
                            OS Features API that return fewer than 100 features, this is still five transactions.</p>
                    </Fragment>
                },
            ]
        },
        {
            heading: 'Projects',
            faqs: [
                {
                    id: 'projectDefinition',
                    title: 'What’s a Project?',
                    body: <p>A project helps you group your services within the OS Data Hub. A project has its own
                        unique
                        API key and you can view usage statistics categorised by project. For example, you might be
                        working
                        on a hotel-finder application that you're developing on three separate platforms: a web app, an
                        Android app and an iOS app. You can create three separate projects within the OS Data Hub to
                        give
                        you three individual API keys and you can track API usage (and costs) separately for each app
                        you're
                        building.
                    </p>
                },
                {
                    id: 'projectsLimit',
                    title: 'How many projects can I set up on the OS Data Hub?',
                    body: <p>
                        You can create up to 100 projects within your organisation. You can delete unused projects to
                        let
                        you create more.
                    </p>
                }
            ]
        },
        {
            heading: 'API throttling',
            faqs: [
                {
                    id: 'apiThrottling',
                    title: 'What throttling is applied to the APIs?',
                    body: <Fragment>
                        <p>Our APIs are throttled to 600 transactions per minute, per API, per project to allow fair use
                            by
                            all customers of the OS Data Hub.</p>
                        <p>Premium Plan organisations can create projects in development mode. You aren’t charged for
                            using
                            premium data for a development project, but the API calls are throttled to 50 transactions
                            per
                            minute, per API, per project.</p>
                    </Fragment>
                },
                {
                    id: 'apiNotThrottled',
                    title: 'When is throttling not applied to the APIs?',
                    body: <p>Emergency Services, classified as Category 1 responders, have unthrottled access to all OS
                        APIs
                        as they are on our Authorised List. Other PSGA Members who have invoked support for a Mapping
                        for
                        Emergency incident can be added to the Authorised List to gain unthrottled access to OS APIs for
                        a
                        specified period.</p>

                },
            ]
        },
        {
            heading: 'Development and live mode',
            id: 'developmentLiveModes',
            faqs: [
                {
                    id: 'modeDifference',
                    title: 'What’s the difference between putting a project in development mode and live mode?',
                    body: <p>Under the Premium Plan, projects can be created in development or live mode. For projects
                        in
                        development mode you’re not charged for using premium data and they don’t count towards your
                        free
                        £1,000 premium data limit however these projects can’t be used in live applications. The API
                        calls
                        for a development project are throttled to 50 transactions per minute, per API. In live mode,
                        the
                        premium data you use counts towards your free monthly limit, and you can pay for any premium
                        data
                        you use above this. The API throttling is much faster at 600 transactions per minute, per API,
                        per
                        project. In accordance with our terms of service, only projects created in live mode can be used
                        in
                        live applications (excluding any APIs in trial mode).
                    </p>

                },
                {
                    id: 'devModeReleases',
                    title: 'Can I release what I’m building if it uses API calls from a project in development mode?',
                    body: <p>No. It's against our terms of service to release a live application using API calls
                        from a project in development mode.</p>
                },
                {
                    id: 'devModeAvailability',
                    title: 'Why is development mode just for projects in the Premium Plan?',
                    body: <p>All OS OpenData is free under the OS OpenData Plan and all OS OpenData and premium data is
                        free
                        under the Public Sector Plan so there's no advantage to you creating a project in development
                        mode.
                    </p>
                },
            ]
        },
        {
            heading: 'Using free premium data',
            sup: '1',
            id: 'freePremiumAllocation',
            faqs: [
                {
                    id: 'freeData',
                    title: 'How much premium data do I get for free?',
                    body: <><p>
                        Under the Premium Plan, you get up to £1,000 of free premium data transactions each month on a
                        use-it-or-lose-it basis (excluding OS Places API and OS Match &amp; Cleanse API). Once you've
                        used up
                        your free credit, we'll either charge you for the premium data you use or, if you don't have a
                        valid
                        payment card set up, we'll stop you being able to use our premium data until the next month when
                        you'll
                        start another £1,000 of free premium data.
                    </p>
                    <p><sup>1</sup> For Energy and Infrastructure Plan Users this is not available</p>
                    </>
                },
                {
                    id: 'freeDataTypes',
                    title: 'What data can I use with my free up to £1,000 monthly limit?',
                    body: <p>Your free up to £1,000 limit can be used for premium data and for multiple APIs (excluding
                        OS
                        Places API and OS Match &amp; Cleanse API). Our OS OpenData is always free.</p>
                },
                {
                    id: 'freeDataAPIs',
                    title: 'Is my free up to £1,000 monthly limit reset at the beginning of each calendar month?',
                    body: <p>Yes. You get another free up to £1,000 of premium data at the start of each calendar
                        month.</p>
                },
                {
                    id: 'freeDataCarryOver',
                    title: 'Can I carry over unused credit from the free up to £1,000 monthly limit into the following calendar month?',
                    body: <p>No, you start every month with the free up to £1,000 limit.</p>
                },
            ]
        },
        {
            heading: 'Using Address APIs',
            id: 'usingAddressApis',
            faqs: [
                {
                    id: 'whoCanAccessAddressApis',
                    title: 'Who can access OS Places API and OS Match & Cleanse API?',
                    body: <Fragment>
                        <p>OS Places API is available on the Premium Plan and Public Sector Plan.</p>
                        <p>OS Match &amp; Cleanse API is currently only available on the Premium Plan.</p>
                    </Fragment>
                },
                {
                    id: 'addressApisNotOnOpenDataPlan',
                    title: 'Why are OS Places API and OS Match & Cleanse API not available on the OS OpenData Plan?',
                    body: <p>OS Places API and OS Match &amp; Cleanse API both contain AddressBase&reg; Premium data.
                        There is no OS OpenData available via these APIs.</p>
                },
                {
                    id: 'addressApisNoFreeTransactions',
                    title: 'Why don’t OS Places API and OS Match & Cleanse API have any free transactions?',
                    body: <Fragment>
                        <p>
                            OS Places API and OS Match &amp; Cleanse API both contain AddressBase&reg; Premium data
                            which contains IPR owned, in part, by Royal Mail.
                            Accordingly, the use of these API Services is subject to the additional terms and fees as
                            set out in the
                            Royal Mail <ExternalLink
                                type='generic'
                                href={ROYAL_MAIL_SOLUTION_PROVIDER_LICENSE}
                                message={messages.pafLicense}
                            />.
                        </p>
                        <p>
                            OS Places API and OS Match &amp; Cleanse API transactions do not count towards the free up
                            to £1,000 monthly
                            limit. Every transaction from these APIs will be charged for.
                        </p>
                    </Fragment>
                },
                {
                    id: 'whoCanTrialAddressApis',
                    title: 'Who can trial OS Places API and OS Match & Cleanse API?',
                    body: <p>Premium Plan users can trial OS Places API and/or OS Match & Cleanse API. Every account can
                        start one free, 60-day trial with a 2000 transaction limit for each API.</p>
                },
                {
                    id: 'addressApisTrialScope',
                    title: 'What can I do during the 60-day trial of OS Places API and/or OS Match & Cleanse API?',
                    body: <Fragment>
                        <p>OS Places API and OS Match &amp; Cleanse API are available for a free, 60-day trial on the
                            Premium Plan.</p>
                        <p>The trial is for evaluation purposes only. Once a trial is started, you will have 60 days
                            access to the API during which time you can add it any projects,
                            however it can’t be used in live applications and is limited to 2000 transactions.</p>
                        <p>To use OS Places API and/or OS Match &amp; Cleanse API in live applications and with no
                            transaction limit you need to contact us and once purchased,
                            we will unlock access on your account.</p>
                    </Fragment>
                },
                {
                    id: 'addressApisBuildRelease',
                    title: 'Can I release what I’m building if it uses API calls from a project in trial mode?',
                    body: <p>No. It's against our terms of service to release a live application using API calls during
                        a free trial.
                        The trial period is for evaluation purposes only.</p>
                },
                {
                    id: 'addressApisUnlocking',
                    title: 'I’m on the Premium Plan, why do I need to contact OS to unlock access to OS Places API and/or OS Match & Cleanse API?',
                    body: <Fragment>
                        <p>
                            OS Places API and OS Match &amp; Cleanse API contain IPR owned, in part, by Royal Mail.
                            Accordingly, the use of these API Services is subject to the additional terms and fees as
                            set out in the
                            Royal Mail <ExternalLink
                                type='generic'
                                href={ROYAL_MAIL_SOLUTION_PROVIDER_LICENSE}
                                message={messages.pafLicense}
                            />.
                        </p>
                        <p>We currently have an additional purchase process for this which requires you to contact us
                            first.</p>
                        <p>We will:</p>
                        <ul>
                            <li>Discuss your use case and find the right solution</li>
                            <li>Discuss pricing and payment</li>
                            <li>Get you set up and ready for access</li>
                            <li>Unlock your access so you can benefit from the power of address data</li>
                        </ul>
                    </Fragment>
                }
            ]
        },
        {
            heading: 'How do the charges and payment work?',
            id: 'gettingCharged',
            faqs: [
                {
                    id: 'transactionChargesLimit',
                    title: 'Am I charged for transactions that go over the £1,000 limit each month?',
                    body: <p>Yes, you’ll be charged for premium data once you’ve used up your monthly free up to £1,000
                        limit.</p>
                },
                {
                    id: 'transactionChargesScope',
                    title: 'When will I pay for all transactions that go over the £1,000 limit each month?',
                    body: <p>You will pay for all premium data transactions that go over the £1,000 monthly limit at the
                        end
                        of every month or when a transaction goes £2,400 incl. VAT over the free £1,000 limit.</p>
                },
                {
                    id: 'pricingTable',
                    title: 'How much is premium data?',
                    body: <PricingTable/>
                },
                {
                    id: 'paymentMethods',
                    title: 'How can I pay for premium data?',
                    body: <p>You can use a credit or debit card. An admin or finance user can register payment card
                        details
                        either at registration or on the Billing Information page in their account.
                    </p>
                },
                {
                    id: 'paymentDeclined',
                    title: 'My card payment was declined, how can I fix this?',
                    body: <Fragment>
                        <p>Only an admin or finance user can do this.</p>
                        <ol>
                            <li>Log in to the OS Data Hub and click on your display name.</li>
                            <li>In the drop-down menu, select <i>Account</i>.</li>
                            <li>Select <i>Billing Information</i> in the secondary navigation menu.</li>
                            <li>Either select <i>Retry payment</i> OR <i>Change card details</i>.</li>
                        </ol>
                        <p>
                            We'll pause your ability to make any more calls to our premium data above our free monthly
                            limit
                            until you set up a payment and clear any outstanding balance necessary.
                        </p>
                    </Fragment>
                },
                {
                    id: 'notificationFreeLimitReached',
                    title: 'Will I receive notification that I have reached the free £1,000 monthly threshold?',
                    body: <p>
                        Yes. All users get notifications when you reach 50%, 80% and 100% of your free up to £1,000
                        monthly
                        limit. If you’ve registered payment card details, you’ll also receive email notifications when
                        you
                        use 50% and 75% of the £2,400 incl. VAT credit limit before payment is taken.
                    </p>
                },
                {
                    id: 'changeCardDetails',
                    title: 'How can I change my payment card details?',
                    body: <Fragment>
                        <p>Only an admin or finance user can do this.</p>
                        <ol>
                            <li>Log in to the OS Data Hub and click on your display name.</li>
                            <li>In the drop-down menu, select <i>Account</i>.</li>
                            <li>Select <i>Billing Information</i> in the secondary navigation menu.</li>
                            <li>Click <i>Change card details</i>.</li>
                            <li>Make the change and submit.</li>
                        </ol>
                        <p>
                            Your new card details are verified by our payment process provider and any issues
                            identified.
                        </p>
                    </Fragment>
                },
                {
                    id: 'unpaidPremiumData',
                    title: 'What happens if I haven’t paid for premium data for some time?',
                    body: <p>If you don’t access any premium data above the free £1,000 monthly limit for more than six
                        months, we’ll send email and in-app notifications to the admin and finance users. You may want
                        to
                        remove your payment card details from your account.</p>
                },
                {
                    id: 'removeCardDetails',
                    title: 'How can I remove my payment card details?',
                    body: <Fragment>
                        <p>
                            Only an admin or finance user can do this. If you have an outstanding payment, you’ll need
                            to
                            settle this first.
                        </p>
                        <ol>
                            <li>Log in to the OS Data Hub and click on your display name.</li>
                            <li>In the drop-down menu, select <i>Account</i>.</li>
                            <li>Select <i>Billing Information</i> in the secondary navigation menu.</li>
                            <li>Click <i>Remove card details</i>.</li>
                            <li>Make the change and submit.</li>
                        </ol>
                        <p>
                            You can still access the free £1,000 monthly limit of premium data after you’ve removed your
                            card details.
                        </p>
                    </Fragment>
                },
                {
                    id: 'apiPriceAndProductsDifferences',
                    title: 'Are the APIs and products priced differently?',
                    body: <p>Yes, see the <a href="#pricingTable">pricing table</a>.</p>
                },
                {
                    id: 'privateSectorPricing',
                    title: 'Does the API and product pricing apply to all customers?',
                    body: <p>No, the pricing outlined above is for Premium Plan customers only. There’s no charge for
                        using
                        data under the OS OpenData Plan or Public Sector Plan. For Energy and Infrastructure Customers the costs are outlined within your OS Data Hub account. If you need any further assistance with your API plan, please contact your account manager.</p>
                },
                {
                    id: 'paperInvoices',
                    title: 'Will I receive a paper invoice for payments made?',
                    body: <p>Yes. A paper invoice will be triggered to confirm payment received. This will be triggered
                        during the 2nd week of the month following payment.</p>
                },
                {
                    id: 'eaiChangingDataPackageFormat',
                    title: 'As an Energy & Infrastructure Plan user, do I need to pay again if I change the format of a data package?',
                    body: <p>There is no additional charge for Energy & Infrastructure Plan users if you change the
                        format
                        of a data package – please note that if you were receiving updates for this data package, then
                        these
                        updates will now be received in the new format.</p>
                },
                {
                    id: 'eaiSelectingUsersTerminals',
                    title: 'Where can I select the number of users/terminals?',
                    body: <Fragment>
                        <p>When you create a new data package, you can select how many terminals or machines you want to
                            be able to access this data package from the dropdown menu of pre-populated figures. If
                            you’re
                            ordering any of our AddressBase products, then you'll be able to enter the number of users
                            that
                            you would like to access the data in a free text field.</p>

                    <p>If you’d like to amend the number of terminals or users part of the way through your contract,
                            please <ExternalLink type='support' />.</p>
                </Fragment>
            },
            {
                id: 'paymentInvoiceQueries',
                title: 'I have a query relating to my invoice/payment. Who can I contact?',
                body: <p>Please <ExternalLink type='support' />.</p>

            }
        ]
    }
]};


export default plans;
