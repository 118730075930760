import {defineMessages, FormattedMessage} from 'react-intl';
import React from 'react';
import {createUseStyles} from 'react-jss';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import {ReactComponent as ExpandIcon} from '../../components/icons/expand.svg';
import {AccordionDetails} from '@mui/material';
import {border1} from 'omse-components';
import {COMMERCIAL_ENQUIRIES_EMAIL, COMMERCIAL_ENQUIRIES_PHONE} from '../../constants/constants';

const messages = defineMessages({
    unlockProductSummary: {
        id: 'ProductUnlockSteps.unlockProductSummary',
        defaultMessage: 'Unlock {product}',
        description: 'Unlock product accordion title'
    },
    startTheUnlockProcess: {
        id: 'ProductUnlockSteps.startTheUnlockProcess',
        defaultMessage: 'To start the process, please call us on: {phone}, or email us at: {email}',
        description: 'Text describing how to unlock a product with a free trial that is expired'
    },
    unlockProductDetailsTitle: {
        id: 'ProductUnlockSteps.unlockProductDetailsTitle',
        defaultMessage: 'Contact us to unlock {product}',
        description: 'Title of the unlock product accordion details panel'
    },
    unlockProductDetailsIntro: {
        id: 'ProductUnlockSteps.unlockProductDetailsIntro',
        defaultMessage: 'Get in touch to discuss your options for purchasing {product} and start using up to date, ' +
            'accurate information about addresses. We will:',
        description: 'Unlock product accordion details'
    },
    unlockProductDetailsSteps: {
        id: 'ProductUnlockSteps.unlockProductDetailsSteps',
        defaultMessage: `<step>Discuss your use case and find the right solution</step>
<step>Discuss pricing and payment</step>
<step>Get you set up and ready for access</step>
<step>Unlock your access so you can benefit from the power of address data</step>`,
        description: 'Unlock product accordion details'
    },
});

const useStyles = createUseStyles(theme => ({
    expansionPanel: {
        borderWidth: 1,
        borderTopWidth: theme.spacing(0.5),
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main
    },
    summary: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    icon: {
        color: theme.palette.primary.main,
        padding: 0
    },
    expanded: {
        // MUI uses double class selector for some of its default styles on expansion so need to increase specificity
        // to remove these.
        '&.MuiExpansionPanel-root': {
            margin: '0'
        }
    },
    expansionPanelDetails: {
        display: 'block',
        borderTop: border1,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    unlockProductDetailsTitle: {
        paddingBottom: theme.spacing(1),
    },
    unlockProductSummary: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    list: {
        listStyleType: 'disc',
        marginTop: theme.spacing(2),
        marginBottom: 0,
    }
}));

export default function ProductUnlockSteps({className, product}) {
    const classes = useStyles();

    if(product.hasFreeTrial) {
        return <Accordion square={true} className={className} classes={{
            root: classes.expansionPanel,
            expanded: classes.expanded,
        }}>
            <AccordionSummary expandIcon={<ExpandIcon className={classes.icon} />} classes={{
                root: classes.summary
            }}>
                <div>
                    <Typography variant='subtitle1' component='h2' color='primary'>
                        <FormattedMessage {...messages.unlockProductSummary} values={{
                            product: product.displayName
                        }} />
                    </Typography>
                    <Typography variant='body1'>
                        <FormattedMessage {...messages.startTheUnlockProcess} values={{
                            phone: <a href={'tel:' + COMMERCIAL_ENQUIRIES_PHONE}>{COMMERCIAL_ENQUIRIES_PHONE}</a>,
                            email: <a href={'mailto:' + COMMERCIAL_ENQUIRIES_EMAIL}>{COMMERCIAL_ENQUIRIES_EMAIL}</a>
                        }} />
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails classes={{
                root: classes.expansionPanelDetails,
            }}>
                <Typography variant='body2' className={classes.unlockProductDetailsTitle}>
                    <FormattedMessage {...messages.unlockProductDetailsTitle} values={{
                        product: product.displayName
                    }} />
                </Typography>
                <Typography variant='body1' className={classes.unlockProductDetailsIntro}>
                    <FormattedMessage {...messages.unlockProductDetailsIntro} values={{
                        product: product.displayName
                    }} />
                </Typography>
                <ul className={classes.list}>
                    <FormattedMessage {...messages.unlockProductDetailsSteps} values={{
                        product: product.displayName,
                        step: chunk => <li><Typography variant='body1'>{chunk}</Typography></li>
                    }} />
                </ul>
            </AccordionDetails>
        </Accordion>;
    } else {
        return null;
    }
}