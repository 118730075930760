import React from 'react';
import {createUseStyles} from 'react-jss';
import Typography from '@mui/material/Typography';
import {FormattedMessage} from 'react-intl';
import {AvailableIcon, DegradedIcon, UnavailableIcon} from './ServiceStatusIcons';
import {messages} from './ServiceStatusIcons';

/* Header */
const useHeaderStyles = createUseStyles(theme => ({
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: theme.spacing(3)
    },
    titleWrapper: {
        marginRight: 'auto',
        marginBottom: theme.spacing(.5),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2)
        }
    },
    keyText: {
        display: 'inline',
        marginLeft: theme.spacing(.5),
        marginRight: theme.spacing(2)
    },
    iconWithText: {
        display: 'inline-block',
        '& > *': {
            verticalAlign: 'middle'
        }
    }
}));

export default function ServiceStatusTableHeader({title, showIcons, showDegradedIcon = true}) {
    const classes = useHeaderStyles();
    return <div className={classes.header}>
        <div className={classes.titleWrapper}>
            {title}
        </div>
        {showIcons && <div className={classes.key}>
            <IconWithText Icon={AvailableIcon} message={messages.available} />
            {showDegradedIcon && <IconWithText Icon={DegradedIcon} message={messages.degraded} />}
            <IconWithText Icon={UnavailableIcon} message={messages.unavailable} />
        </div>}
    </div>
}

function IconWithText({Icon, message}) {
    const classes = useHeaderStyles();
    return <div className={classes.iconWithText}>
        <Icon />
        <Typography className={classes.keyText} variant='body1'>
            <FormattedMessage {...message}/>
        </Typography>
    </div>
}