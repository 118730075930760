const PREFIX = 'action:downloads:';

export const LOAD_CATALOGUE_ACTION = PREFIX + 'catalogue_load';
export const LOADED_CATALOGUE_ACTION = PREFIX + 'catalogue_loaded';
export const LOAD_PRODUCT_ACTION = PREFIX + 'product_load';
export const LOADED_PRODUCT_ACTION = PREFIX + 'product_loaded';
export const LOAD_DOWNLOADS_ACTION = PREFIX + 'downloads_load';
export const LOADED_DOWNLOADS_ACTION = PREFIX + 'downloads_loaded';

export function getCatalogue() {
    return {
        type: LOAD_CATALOGUE_ACTION
    }
}

export function getProduct(productId) {
    return {
        type: LOAD_PRODUCT_ACTION,
        id: productId
    }
}

export function getDownloadsForProduct(productId) {
    return {
        type: LOAD_DOWNLOADS_ACTION,
        id: productId
    }
}