import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

/**
 * Hook to help manage the new licence state. Provides functions to activate partner contracts and
 * BMIs. Also includes synchronization logic, so that when the redux state for licences is updated
 * (e.g. by successfully saving some new state) then the new licence state is slimmed down automatically.
 */

export default function useNewLicences() {
    const contracts = useSelector(state => state.partners.contracts.result);
    const catalogue = useSelector(state => state.partners.catalogue.result);

    const [newLicences, setNewLicences] = useState({});

    // The set of productContracts will be updated when the contracts are saved, and the newLicence state can change
    // whenever the user clicks on a box. This effect makes sure that any state in the newLicences object is a real
    // change, so that we know if there are any changes that we need to save.
    useEffect(function synchroniseNewLicences() {
        let changed = false;
        const contractsToRemove = [];

        Object.keys(newLicences).forEach(contractId => {
            let actualContract = contracts[contractId];
            if(!actualContract) {
                // This is a new contract, it is a real change and we can move on.
                return;
            }

            const bmisToRemove = [];
            const newBmis = newLicences[contractId].bmis;
            Object.keys(newBmis).forEach(bmi => {
                let actualBmi = actualContract.bmis[bmi];
                if(!actualBmi) {
                    // This is a new bmi, it is a real change and we can move on
                } else if(actualBmi.active !== newBmis[bmi].active) {
                    // This BMI has been activated, it is a real change and we can move on
                } else {
                    // This BMI can be cleaned up
                    bmisToRemove.push(bmi);
                    changed = true;
                }
            });
            bmisToRemove.forEach(bmi => delete newBmis[bmi]);

            // If the list of BMIs to change is now empty, we can discard this whole contract
            if(Object.keys(newBmis).length === 0) {
                contractsToRemove.push(contractId);
                changed = true;
            }
        });
        contractsToRemove.forEach(contractId => delete newLicences[contractId]);

        if(changed) {
            // Pop a new object into the state so that React knows about the changes we made
            setNewLicences({...newLicences});
        }
    }, [contracts, newLicences]);

    function addPartnerContract(contract, product, term) {
        // The licence was accepted. We need to update the new licence state to include this contract, and we
        // choose to include all of the BMIs... but we only activate them if we specifically want this product.
        // We do not create BMIs for third-party products. Those are supposed to be added by the admin team
        // after communication with the customer.

        // If the supplied product has a BMI code dependency then we also activate the dependent products
        const bmiCodes = [];
        if(product) {
            bmiCodes.push(product.bmiCode);
            if(product.bmiCodeDependency) {
                bmiCodes.push(product.bmiCodeDependency);
            }
        }

        let endDate = new Date();
        endDate.setFullYear(endDate.getFullYear() + term);
        endDate.setDate(endDate.getDate() - 1);

        let updatedLicences = {...newLicences};
        let updatedLicence = updatedLicences[contract.id] = {
            term,
            endDate,
            bmis: {}
        };
        catalogue.products
            .filter(p => p.partnerUsageContracts.includes(contract.id))
            .filter(p => !p.thirdParty)
            .forEach(p => {
                const bmi = { active: false };
                if(bmiCodes.includes(p.bmiCode)) {
                    bmi.active = true;
                }
                updatedLicence.bmis[p.bmiCode] = bmi;
            });
        setNewLicences(updatedLicences);
    }

    function togglePartnerContract(contract, product, term) {
        let updatedLicences = {...newLicences};
        let updatedLicence = updatedLicences[contract.id];
        if(!updatedLicence) {
            updatedLicence = updatedLicences[contract.id] = {
                term,
                bmis: {}
            }
        }
        let bmi = updatedLicence.bmis[product.bmiCode];
        if(!bmi) {
            bmi = updatedLicence.bmis[product.bmiCode] = { active: true };
        } else {
            // This might be the second click for this particular BMI, so we toggle the active flag
            bmi.active = !bmi.active;
        }

        if(bmi.active) {
            // Ensure that we have also activated the dependency, if there is one
            if (product.bmiCodeDependency) {
                updatedLicence.bmis[product.bmiCodeDependency] = {active: true};
            }
        } else {
            // If there is a stored contract for this contract id then it is safe for us to delete inactive
            // BMIs. If there is no stored contract then we have a new one that we have filled with inactive
            // BMIs, and we don't want to delete those.
            const shouldDelete = contracts[contract.id];

            if(shouldDelete) {
                delete updatedLicence.bmis[product.bmiCode];
            }

            // As this BMI is no longer active then we may also need to turn off/delete BMIs that are dependent on it
            catalogue.products
                .filter(p => p.bmiCodeDependency === product.bmiCode)
                .map(p => p.bmiCode)
                .forEach(otherCode => {
                    const bmi = updatedLicence.bmis[otherCode];
                    if(bmi) {
                        if(shouldDelete) {
                            delete updatedLicence.bmis[otherCode];
                        } else {
                            bmi.active = false;
                        }
                    }
                });
        }

        setNewLicences(updatedLicences);
    }

    function reset() {
        setNewLicences({});
    }

    return {
        newLicences,
        addPartnerContract,
        togglePartnerContract,
        reset
    }
}