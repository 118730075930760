import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import {CommonDialog, CommonDialogActions} from 'omse-components';
import {PSGA_DESCRIPTION_URL} from '../../util/routes';

const messages = defineMessages({
    dialogTitle: {
        id: 'ApplyPsgaDialog.dialogTitle',
        defaultMessage: 'Changing to a Public Sector Plan',
        description: 'dialog title'
    },
    yourOrganisationMust: {
        id: 'ApplyPsgaDialog.yourOrganisationMust',
        defaultMessage: 'Your organisation must have signed up to the Public Sector Geospatial Agreement (PSGA) in order to receive free data through our Public Sector Plan.',
        description: 'your organisation must'
    },
    findOutMore: {
        id: 'ApplyPsgaDialog.findOutMore',
        defaultMessage: 'find out more about joining the PSGA here',
        description: 'find out more'

    },
    onceYoureAMember: {
        id: 'ApplyPsgaDialog.onceYoureAMember',
        defaultMessage: 'Once you’re a PSGA member we can change you to a Public Sector Plan on the OS Data Hub.',
        description: 'Once youre a PSGA member'
    },
    keepInMind: {
        id: 'ApplyPsgaDialog.keepInMind',
        defaultMessage: 'Keep in mind:',
        description: 'Keep in mind'
    },
    yourExistingAccount: {
        id: 'ApplyPsgaDialog.yourExistingAccount',
        defaultMessage: 'Your existing account will remain unchanged until your organisation is a member of the PSGA.',
        description: 'Your existing account'
    },
    anyOutstandingTransactions: {
        id: 'ApplyPsgaDialog.anyOutstandingTransactions',
        defaultMessage: 'Any outstanding transactions or declined payments will be collected before the upgrade is complete.',
        description: 'Any outstanding transactions'
    },
    findOutHow: {
        id: 'ApplyPsgaDialog.findOutHow',
        defaultMessage: 'Find out how to apply',
        description: 'find out how'
    }
});

export default function ApplyPsgaDialog({handleClose}) {

    function showHowToApply() {
        handleClose();
        window.open(PSGA_DESCRIPTION_URL, "_blank");
    }

    const actions = <CommonDialogActions onClose={handleClose}
                                         confirmLabel={messages.findOutHow}
                                         onConfirm={() => showHowToApply()}
    />;

    return <CommonDialog onClose={handleClose}
                         title={messages.dialogTitle}
                         actions={actions}>
        <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...messages.yourOrganisationMust} />
        </Typography>

        <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...messages.onceYoureAMember}/>
        </Typography>

        <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...messages.keepInMind}/>
        </Typography>

        <ul>
            <li>
                <Typography variant='body1' paragraph={true}>
                    <FormattedMessage {...messages.yourExistingAccount}/>
                </Typography>
            </li>
            <li>
                <Typography variant='body1' paragraph={true}>
                    <FormattedMessage {...messages.anyOutstandingTransactions}/>
                </Typography>
            </li>
        </ul>
            
    </CommonDialog>
}

ApplyPsgaDialog.propTypes = {
    handleClose: PropTypes.func.isRequired
};
