const moment = require('moment');

/**
 * Helper that maps a date object over to an ISO date. This ignores the timezone in the date, so the result will
 * not jump to an adjacent day if we are in an offset time zone and it is nearly midnight. This is usually what
 * we want for a date!
 */
function toISODate(date) {
    return moment(date).format('YYYY-MM-DD');
}

/**
 * Helper that maps from an ISO date string (yyyy-mm-dd) to a local date. We cannot simply use new Date(dateString),
 * as that would return a Date for 00:00 on that day in UTC, and when viewed in the current timezone that might
 * not be the correct day! Moment gives us back a Date object set to 00:00 in the local timezone, which is more useful.
 */
function fromISOString(dateString) {
    return moment(dateString).toDate();
}

module.exports = {
    toISODate,
    fromISOString
}
