import React from 'react';
import Typography from "@mui/material/Typography";
import Table from './Table';
import TextBox from '../../components/TextBox';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

const dataProducts = {
    'OS MasterMap Topography Layer': {
        open: false,
        dataAvailableFrom: '2004',
        featureTypes: [
            'Topography_BoundaryLine',
            'Topography_CartographicSymbol',
            'Topography_CartographicText',
            'Topography_TopographicArea',
            'Topography_TopographicLine',
            'Topography_TopographicPoint'
        ]
    },
    'OS MasterMap Highways Network': {
        open: false,
        dataAvailableFrom: '2016',
        featureTypes: [
            'Highways_ConnectingLink',
            'Highways_ConnectingNode',
            'Highways_FerryLink',
            'Highways_FerryNode',
            'Highways_PathLink',
            'Highways_PathNode',
            'Highways_RoadLink',
            'Highways_RoadNode',
            'Highways_Street'
        ],
    },
    'OS Open UPRN': {
        open: true,
        dataAvailableFrom: '2020',
        featureTypes: [
            'OpenUPRN_Address '
        ],
    },
    'OS MasterMap Integrated Transport Network - Roads': {
        open: false,
        dataAvailableFrom: '2004-2019',
        featureTypes: [
            'ITN_Roads_FerryNode',
            'ITN_Roads_RoadLink',
            'ITN_Roads_RoadNode'
        ]
    },
    'OS MasterMap Integrated Transport Network - Urban Paths': {
        open: false,
        dataAvailableFrom: '2010-2019',
        featureTypes: [
            'ITN_UrbanPaths_ConnectingNode',
            'ITN_UrbanPaths_FerryNode',
            'ITN_UrbanPaths_PathLink',
            'ITN_UrbanPaths_PathNode'
        ]
    },
}

export default function ProductArchiveFeatureTypes() {
    return <>
        <Typography variant='h2'>Product Archive</Typography>
        <Typography variant='body1' paragraph={true} component='div'>
            <p>
                The following table documents the OS data products that are available through the OS Product Archive
                service through the OS Features API. For each product, the table shows if the data is OS OpenData or
                Premium data, lists the valid WFS feature type names and the years available (as an annual snapshot
                taking the last full release in the calendar year).
            </p>
            <TextBox
                text="https://api.os.uk/features/v1/wfs/archive/{year}"
                label="Product Archive - Endpoint"
                variant="code"
            />
            <Table>
                <tbody>
                    <tr>
                        <th>Product</th>
                        <th>Data category</th>
                        <th>Data Available From </th>
                        <th>Feature type names</th>
                    </tr>
                    {
                        Object.keys(dataProducts).map(productName => {
                            const product = dataProducts[productName];
                            return <tr key={productName}>
                                <td>{productName}</td>
                                <td>{product.open ? 'Open data' : 'Premium data'}</td>
                                <td>{product.dataAvailableFrom}</td>
                                <td>
                                    {product.featureTypes.map((featureType, i) => (
                                        <>
                                            {i !== 0 && <br />}
                                            <InlineCodeSnippet>
                                                {featureType}
                                            </InlineCodeSnippet>
                                        </>
                                    ))}
                                </td>
                            </tr>;
                        })
                    }
                </tbody>
            </Table>
        </Typography>

    </>
}
