import {modules} from "omse-components";
import {OPEN_TERMS_ACTION, CLEAR_TERMS_ACTION, clearTerms} from "./terms/actions";

const {ACCEPTED_TERMS_ACTION} = modules.actions.user;

/** Stores next action to invoke when an action-based terms barrier is being used */
export function termsReducer(state = {}, action) {
    switch (action.type) {
        case OPEN_TERMS_ACTION:
            return ({...state, [action.terms]: {onAcceptTerms: action.onAccept}});
        case CLEAR_TERMS_ACTION:
            if (action.terms) {
                const terms = {...state};
                delete terms[action.terms];
                return terms;
            } else {
                return {};
            }
        default:
            return state;
    }
}

export const termsMiddleware = [store => next => action => {
    switch(action.type) {
        case ACCEPTED_TERMS_ACTION:
            const termsName = action.terms.name;
            const termsState = store.getState()?.terms[termsName];
            if (termsName) {
                const onAcceptAction = termsState?.onAcceptTerms;
                if (onAcceptAction) {
                    store.dispatch(onAcceptAction);
                }
                if (termsState) {
                    store.dispatch(clearTerms(termsName));
                }
            }
            break;
        default:
    }
    return next(action);
}];