import {Typography, IconButton, CircularProgress} from '@mui/material';
import {createUseStyles} from 'react-jss';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {osColour, ThemeSubList} from 'omse-components';
import {ReactComponent as ErrorIcon} from '../../../components/icons/error-notification.svg';
import classNames from 'classnames';
import {selectFeatureTypes, unselectFeatureTypes} from "../../../modules/recipeEditor/actions";
import {ReactComponent as FilterIcon} from '../../../components/icons/filter.svg';
import {ReactComponent as AddFilterIcon} from '../../../components/icons/add-filter.svg';
import HoverTooltip from "../../../components/HoverTooltip";
import DiscardFiltersDialog from "./DiscardFiltersDialog";
import {ThemeTreeUtils} from "omse-components";
import {debounce} from "lodash";

const messages = defineMessages({
    error: {
        id: 'ThemesList.error',
        defaultMessage: 'Failed to load',
        description: 'Label for themes list when an error has occurred'
    },
    addFilterButtonLabel: {
        id: 'ThemesList.addFilterButtonLabel',
        defaultMessage: 'Add filters for {featureLabel} feature',
        description: 'Aria label message for add filters button'
    },
    addFilterButtonHoverLabel: {
        id: 'ThemesList.addFilterButtonHoverLabel',
        defaultMessage: 'Add a filter',
        description: 'Hover message for add filters button'
    },
    editFilterButtonLabel: {
        id: 'ThemesList.editFilterButtonLabel',
        defaultMessage: 'Edit filters for {featureLabel} feature',
        description: 'Aria label message for amend filters button'
    },
    editFilterButtonHoverLabel: {
        id: 'ThemesList.editFilterButtonHoverLabel',
        defaultMessage: 'Edit filter',
        description: 'Hover message for edit filters button'
    }

});

const useStyles = createUseStyles(theme => ({
    paddedRoot: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    spinner: {
        alignSelf: 'center'
    },
    error: {
        display: 'flex'
    },
    errorIcon: {
        color: osColour.status.error,
        marginRight: theme.spacing(1)
    }
}));


function findFeatureTypeIdsAndVersions(themeTreeItem) {
    const children = themeTreeItem.collections || themeTreeItem.featureTypes;
    if (children && children.length) {
        return children.flatMap(findFeatureTypeIdsAndVersions);
    }
    return [{
        featureTypeId: themeTreeItem.featureId,
        featureTypeVersion: themeTreeItem.versions[0]
    }];
}

function findFeatureType(featureTypeId, selectedFeatureTypes) {
    return selectedFeatureTypes.find(selected => featureTypeId === selected.featureTypeId);
}

function countSelected(featureTypesAndVersions, selectedFeatureTypes) {
    let result = 0;
    let filtered = 0;
    featureTypesAndVersions.forEach(ft => {
        const found = findFeatureType(ft.featureTypeId, selectedFeatureTypes);
        if (found) {
            result++;
            if(found.filterExpression) {
                filtered++;
            }
        }
    });
    return [result, filtered];
}

function setFeatureProperties(listItem, selectedFeatureTypes) {
    const myFeatureTypes = findFeatureTypeIdsAndVersions(listItem);
    const [count] = countSelected(myFeatureTypes, selectedFeatureTypes);
    const checked = count === myFeatureTypes.length;
    const indeterminate = count > 0 && count < myFeatureTypes.length;
    const empty = count === 0;
    listItem.checked = checked;
    listItem.indeterminate = indeterminate;
    listItem.empty = empty;

    const collectionChildren = listItem.collections;
    if (collectionChildren && collectionChildren.length) {
        let newChildren = collectionChildren.map(child => setFeatureProperties(child, selectedFeatureTypes));
        listItem.collectionChildren = newChildren;
    }

    const featureChildren = listItem.featureTypes;
    if (featureChildren && featureChildren.length) {
        let newChildren = featureChildren.map(child => {
            child = setFeatureProperties(child, selectedFeatureTypes);
            return child;
        });
        listItem.featureTypes = newChildren;
    }

    return listItem
}

function updateTree(selectedFeatureTypes, themeTree, setTreeState, leafFilter, setActiveFilter, setNoResult) {
    if (themeTree && selectedFeatureTypes) {
        const {tree, matchCount} = ThemeTreeUtils.filterTreeLeaves([...themeTree], leafFilter);
        setNoResult(matchCount === 0 && !!leafFilter);
        setTreeState(tree.map(listItem => setFeatureProperties(listItem, selectedFeatureTypes)));
        setActiveFilter(leafFilter);
    }
}

export default function ThemesList({className, disabled, rootListItemExtraInlinePadding, onListItemTextClick, onFilterButtonClick, leafFilter, setNoResult=()=>{}}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const isLoading = useSelector(state => state.themes.themeTree.loading);
    const error = useSelector(state => state.themes.themeTree.error);
    const themeTree = useSelector(state => state.themes.themeTree.result);
    const selectedFeatureTypes = useSelector(state => state.recipeEditor.featureTypes);
    const hasUnsavedFilters = useSelector(state => state.recipeEditor.currentQueryTree.treeEdited);
    const {result: featureTypeSchema} = useSelector(state => state.recipes.featureTypeSchema);
    const [treeState, setTreeState] = useState();
    const [dialogAction, setDialogAction] = useState(null);
    const [dialogSaved, setDialogSaved] = useState(false);
    const [activeFilter, setActiveFilter] = useState(false);
    const intl = useIntl();
    const currentFilterFeatureId = featureTypeSchema?.featureId;

    const updateTreeDebounce = useMemo(() => debounce(updateTree, 100), []);
    useEffect(() => {
        updateTreeDebounce(selectedFeatureTypes, themeTree, setTreeState, leafFilter, setActiveFilter, setNoResult);
    }, [selectedFeatureTypes, themeTree, setTreeState, leafFilter, setActiveFilter, setNoResult, updateTreeDebounce]);

    const getName = useMemo(function() {
        const featureTypesLabelMap = {};
        if (themeTree) {
            themeTree.forEach((theme) => {
                theme.collections.forEach((collection) => {
                    collection.featureTypes.forEach((featureType) => {
                        featureTypesLabelMap[featureType.featureId] = featureType.label;
                    });
                });
            });
        }

        return (featureTypeId) => {
            // Default to the featureId if no name is found for whatever reason
            return featureTypesLabelMap[featureTypeId] || featureTypeId;
        }
    }, [themeTree]);


    if (isLoading) {
        return <CircularProgress size={24} className={classNames(classes.paddedRoot, classes.spinner)}/>;
    } else if (error) {
        return <Typography variant='body1' className={classNames(classes.paddedRoot, classes.error)}>
            <ErrorIcon width={24} height={24} className={classes.errorIcon}/>
            <FormattedMessage {...messages.error}/>
        </Typography>;
    }

    //  A click on this item needs to toggle all of the items on, or turn them all off.
    //  Selections that are outside of this sub-tree need to be left alone.
    function onCheckboxChange(clickedItem) {
        let myFeatureTypes = findFeatureTypeIdsAndVersions(clickedItem);
        const [count, filtered] = countSelected(myFeatureTypes, selectedFeatureTypes);

        if (count < myFeatureTypes.length) {
            // Some, but not all, are currently selected. We handle the click by selecting the whole set, which will
            // enable the missing ones.
            dispatch(selectFeatureTypes(myFeatureTypes));
        } else {
            // All the items are currently selected, so we handle the click by removing them all. If the user
            // has unsaved edits then we may need to pop a dialog to handle that, and if they have saved filters that
            // will be discarded then we need to manage that case too.
            function action() {
                dispatch(unselectFeatureTypes(myFeatureTypes));
                setDialogAction(null);
                setDialogSaved(false);
            }
            const unsavedFiltersThatMatter = hasUnsavedFilters && findFeatureType(currentFilterFeatureId, myFeatureTypes);
            if(filtered || unsavedFiltersThatMatter) {
                // We need to pop a dialog to ask the user what to do
                // Setting a function into state doesn't work directly, as React will call the function to find the
                // new state. Wrapping the function in another works around that.
                setDialogAction(() => action);
                setDialogSaved(filtered > 0);
            } else {
                // There are no saved or unsaved filters, so we can run the action immediately
                action();
            }
        }
    }

    function renderActionButton(listItem) {
        const selectedItem = selectedFeatureTypes.find(item => item.featureTypeId === listItem.featureId);
        const hasFilters = selectedItem && selectedItem.filterExpression;
        const hoverMessage = hasFilters ? messages.editFilterButtonHoverLabel : messages.addFilterButtonHoverLabel
        const icon = hasFilters ? <FilterIcon/> : <AddFilterIcon/>
        const message = hasFilters ? messages.editFilterButtonLabel : messages.addFilterButtonLabel;
        //quick timeout stops tooltips from showing more than one at a time when moving mouse quickly
        return <HoverTooltip title={intl.formatMessage(hoverMessage)} TransitionProps={{timeout: {exit: 50}}}>
            <IconButton size='small'
                        onClick={() => onFilterButtonClick(listItem)}
                        onKeyDown={(e) => {if (e.key === "Enter") {onFilterButtonClick(listItem)}}}
                        aria-label={intl.formatMessage(message, {featureLabel: getName(listItem.featureId)})}
            >
                {icon}
            </IconButton>
        </HoverTooltip>
    }

    return <>
        <ThemeSubList   listItems={treeState}
                        disabled={disabled}
                        className={className}
                        rootListItemExtraInlinePadding={rootListItemExtraInlinePadding}
                        onCheckboxChange={onCheckboxChange}
                        onListItemTextClick={onListItemTextClick}
                        renderActionButton={renderActionButton}
                        leafFilter={activeFilter}/>
        {
            dialogAction && <DiscardFiltersDialog onClose={() => setDialogAction(null)}
                                                  onConfirm={dialogAction}
                                                  saved={dialogSaved}/>
        }
    </>;
}

ThemesList.propTypes = {
    classNames: PropTypes.string,
    // Applies additional padding in the root list item, but not to dividers between those list items. This allows
    // dividers to reach the entire width of any container, but have the list items themselves still be padded.
    rootListItemExtraInlinePadding: PropTypes.number
};
