import React, {Fragment, useEffect, useState} from 'react';
import {
    CommonDialog,
    CommonDialogActions,
    Notification,
    ExternalLink
} from 'omse-components';
import {Typography} from '@mui/material';
import {defineMessages, FormattedMessage} from 'react-intl';
import {stopDataPackageUpdates} from "../../../modules/dataPackages/actions";
import useActionIdSelector from '../../../hooks/useActionIdSelector';

const messages = defineMessages({
    stopUpdatesTitle: {
        id: 'StopUpdatesDialog.stopUpdatesTitle',
        defaultMessage: 'Stop data package updates',
        description: 'stop updates dialog title'
    },
    whenYouStop: {
        id: 'StopUpdatesDialog.whenYouStop',
        defaultMessage: 'When you stop updates on a data package:',
        description: 'dialog message'
    },
    youWillNotReceive: {
        id: 'StopUpdatesDialog.youWillNotReceive',
        defaultMessage: 'You will not receive any further updates',
        description: 'dialog message'
    },
    itCannotBe: {
        id: 'StopUpdatesDialog.itCannotBe',
        defaultMessage: 'It cannot be re-activated',
        description: 'dialog message'
    },
    itCanStillBe: {
        id: 'StopUpdatesDialog.itCanStillBe',
        defaultMessage: 'It can still be downloaded for 12 months from when you received your last supply',
        description: 'dialog message'
    },
    stopProceed: {
        id: 'StopUpdatesDialog.stopProceed',
        defaultMessage: 'Stop updates',
        description: 'stop proceed message'
    },
    stopCancel: {
        id: 'StopUpdatesDialog.stopCancel',
        defaultMessage: 'Keep updates active',
        description: 'stop cancel message'
    },
    stopFailure: {
        id: 'StopUpdatesDialog.stopFailure',
        defaultMessage: 'There was a problem stopping your data package updates. Please retry and if the problem persists then {link}.',
        description: 'error message text'
    }
});


export default function StopUpdatesDialog({dataPackage, onClose, onSuccess = () => {}}) {
    const [{working, error}, dispatch] = useActionIdSelector("dataPackages.stopUpdates");
    const [updating, setUpdating] = useState(false);

    function dispatchStopUpdates() {
        dispatch(stopDataPackageUpdates(dataPackage.id));
        setUpdating(true);
    }

    useEffect(() => {
        if (updating && !working) {
            onClose();
        }
    }, [working, updating, onClose])

    const actions = <CommonDialogActions onClose={onClose}
                                         onConfirm={dispatchStopUpdates}
                                         confirmLabel={messages.stopProceed}
                                         cancelLabel={messages.stopCancel}
                                         working={working}/>;

    let content = <CommonDialog onClose={onClose}
                                title={messages.stopUpdatesTitle}
                                actions={actions}>
        <Typography variant='body1'>
            <FormattedMessage {...messages.whenYouStop}/>
        </Typography>
        <ul>
            <li><Typography variant='body1'>
                <FormattedMessage {...messages.youWillNotReceive}/>
            </Typography></li>
            <li><Typography variant='body1'>
                <FormattedMessage {...messages.itCannotBe}/>
            </Typography></li>
            <li><Typography variant='body1'>
                <FormattedMessage {...messages.itCanStillBe}/>
            </Typography></li>
        </ul>
    </CommonDialog>;

    if (error) {
        content = <Notification variant='error' appearance='toast' onClose={onClose}>
            <Typography variant='body1'>
                <FormattedMessage {...messages.stopFailure} values={{ link: <ExternalLink type='support' /> }} />
            </Typography>
        </Notification>
    }

    return <Fragment>
        {content}
    </Fragment>
}