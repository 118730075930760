import React, {useState} from 'react';
import {Input} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {useIntl, defineMessages, FormattedMessage} from 'react-intl';
import {InputLabel} from '@mui/material';
import {CommonDialog, CommonDialogActions} from 'omse-components';

const messages = defineMessages({
    dialogTitle: {
        id: 'RecipeDescriptionDialog.dialogTitle',
        defaultMessage: 'Give your recipe a description',
        description: 'Title for the dialog box to name your project'
    },
    dialogDescription: {
        id: 'RecipeDescriptionDialog.recipeDescriptionInput',
        defaultMessage: 'Describe the purpose of your recipe. This will help you and your colleagues identify your recipe.',
        description: 'Text for label of recipe description input box'
    },
    recipeDescriptionPlaceholder: {
        id: 'RecipeDescriptionDialog.recipeDescriptionPlaceholder',
        defaultMessage: 'Enter a description',
        description: 'Enter a description placeholder'
    },
    submitButtonTextNew: {
        id: 'RecipeDescriptionDialog.submitButtonTextNew',
        defaultMessage: 'Add description',
        description: 'Text for confirming description change when adding a new one'
    },
    submitButtonTextEdit: {
        id: 'RecipeDescriptionDialog.submitButtonTextEdit',
        defaultMessage: 'Update description',
        description: 'Text for confirming description change when updating and existing one'
    },
    cancelButton: {
        id: 'RecipeDescriptionDialog.cancelButton',
        defaultMessage: 'Cancel',
        description: 'Label for cancel button',
    },
});

const useStyles = createUseStyles(theme => ({
    // This label text is quite long, so make it less bold.
    inputLabelRoot: theme.typography.body1,
    input: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
    innerInput: {
        fontSize: '1.125rem',
        margin: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingTop: 6,
        paddingBottom: 5,
        '&::placeholder': {
            paddingTop: 2
        }
    },
}));

export default function RecipeDescriptionDialog({initialDescription, onClose, onConfirm}) {
    const intl = useIntl();
    const classes = useStyles();
    const [description, setDescription] = useState(initialDescription);

    const onConfirmWrapped = () => onConfirm(description);

    return <CommonDialog onClose={onClose}
                         title={messages.dialogTitle}
                         actions={
        <CommonDialogActions onClose={onClose}
                             onConfirm={onConfirmWrapped}
                             confirmLabel={initialDescription === '' ? messages.submitButtonTextNew : messages.submitButtonTextEdit}/>}
    >
        <InputLabel htmlFor='recipeDescription' classes={{root: classes.inputLabelRoot}}>
            <FormattedMessage {...messages.dialogDescription}/>
        </InputLabel>
        <Input id='recipeDescription'
               type='text'
               multiline
               rows={3}
               autoFocus={true}
               disableUnderline={true}
               value={description}
               onChange={e => setDescription(e.target.value)}
               placeholder={intl.formatMessage(messages.recipeDescriptionPlaceholder)}
               classes={{
                   root: classes.input,
                   input: classes.innerInput
               }}
        />
    </CommonDialog>;
}