import React, {Component} from 'react';

const withTracker = (WrappedComponent, options = {}) => {

    const trackPage = page => {
        if (window.ga) {
            window.ga('set', 'page', page);
            window.ga('send', 'pageview');
        }
    };

    return class extends Component {
        componentDidMount() {
            const page = this.props.location.pathname + this.props.location.search;
            trackPage(page);
        }

        componentDidUpdate(prevProps) {
            const currentPage =
                prevProps.location.pathname + prevProps.location.search;
            const nextPage =
                this.props.location.pathname + this.props.location.search;

            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {
            return (
                <WrappedComponent {...this.props}/>
            );
        }
    };
};

export default withTracker;

