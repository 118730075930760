import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {FormattedMessage, defineMessages} from 'react-intl';
import {createUseStyles} from 'react-jss';
import {CommonDialog, CommonDialogActions, Notification, InputBox} from "omse-components";
import {Typography} from '@mui/material';
import {ADMIN_ROLE} from "../../../../shared/roles";
import {emailMatcher, messages as userMessages} from "../../../constants/user";
import {isOSEmail} from "../../../constants/user";
import InviteError from "./InviteError";
import {invite} from '../../../modules/organisation/action';
import RoleSelection from "./RoleSelection";
import useActionIdSelector from "../../../hooks/useActionIdSelector";
import {USER_OS_INTERNAL_PLAN} from "../../../../shared/plans";

const messages = defineMessages ({
    inviteTeamMembers: {
        id: 'inviteTeamMembersDialog.inviteTeamMembers',
        defaultMessage: 'Invite team members to your account',
        description: 'Text for Invite team members to your account'
    },
    addTeamMember: {
        id: 'inviteTeamMembersDialog.addTeamMember',
        defaultMessage: 'Add team member',
        description: 'Label for the add team member button'
    },
    emailAddress: {
        id: 'inviteTeamMembersDialog.emailAddress',
        defaultMessage: 'Email address',
        description: 'Label for the email address box'
    },
    instructions: {
        id: 'inviteTeamMembersDialog.instructions',
        defaultMessage: "Please choose a role and enter the email address of the new team member you'd like to add to your account.",
        description: 'Instructions displayed in the form'
    },
    error: {
        id: 'inviteTeamMembersDialog.error',
        defaultMessage: "We were unable to invite the new team member. The following message may help to explain why we could not send the invitation:",
        description: 'Displayed when we are unable to invite the new user'
    },
    success: {
        id: 'inviteTeamMembersDialog.success',
        defaultMessage: "Your invitation has been sent.",
        description: 'Displayed when we have invited the new user'
    },
    finalInstruction: {
        id: 'inviteTeamMembersDialog.finalInstruction',
        defaultMessage: "We'll send an email inviting them to join your organisation.",
        description: 'Help text for the final line in the dialog'
    }
});
const styles = createUseStyles(theme => ({
    spaceSubHeader: {
        paddingTop: 39
    },
    roleContainer: {
        paddingLeft: theme.spacing(2)
    },
    finalInstruction: {
        paddingTop: theme.spacing(2)
    }
}));

export function InviteNewMembersDialog({handleClose, testActionId}) {
    const classes = styles();
    const [selectedRole, setSelectedRole] = useState(ADMIN_ROLE);
    const [email, setEmail] = useState('');
    const [{result, working, error}, dispatch] = useActionIdSelector('organisation.invite');
    const {plan} = useSelector(state => state.user.current.result);

    function cancel() {
        handleClose();
    }

    // Replace the whole dialog if we have successfully sent the invitation. A successful result will be empty, so
    // we just need to check that is not undefined...
    if(result !== undefined) {
        return <Notification variant='success'
                             appearance='toast'
                             autoClose={true}
                             onClose={cancel}>
            <Typography variant='body1'>
                <FormattedMessage {...messages.success}/>
            </Typography>
        </Notification>
    }

    // Replace the whole dialog if we get an error from the server
    if(error) {
        return <CommonDialog onClose={cancel}
                             title={messages.inviteTeamMembers}>
            <InviteError error={error}/>
        </CommonDialog>
    }

    let emailMessage = userMessages.emailValidationError;
    let validEmail = emailMatcher.test(email);

    if(validEmail && plan === USER_OS_INTERNAL_PLAN) {
        emailMessage = userMessages.emailIsNotOS;
        validEmail = isOSEmail(email);
    }

    function submit() {
        if(validEmail) {
            const action = invite(email, selectedRole);
            dispatch(action);
        }
    }

    const actions = <CommonDialogActions onConfirm={submit}
                                         confirmAllowed={!!validEmail}
                                         working={working}
                                         onClose={cancel}
                                         confirmLabel={messages.addTeamMember}
                                         confirmLabelVariant='contained'/>;

    return <CommonDialog onClose={cancel}
                         title={messages.inviteTeamMembers}
                         actions={actions}>
        <form onSubmit={submit}>
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.instructions}/>
            </Typography>
            <RoleSelection
                className={classes.roleContainer}
                setRole={setSelectedRole}
                currentUserRole={null}
                currentSelectedRole={selectedRole}
            />
            <InputBox id='email'
                      type='email'
                      label={messages.emailAddress}
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                      onEnterKey={submit}
                      error={!validEmail && email !== '' && emailMessage}
                      required={true}
                      placeholder="example@domain.co.uk"
                      style={{ width: '100%' }}
            />
            <Typography variant='body1' className={classes.finalInstruction}>
                <FormattedMessage {...messages.finalInstruction}/>
            </Typography>
        </form>
    </CommonDialog>
};

InviteNewMembersDialog.propTypes = {
    handleClose: PropTypes.func.isRequired
};
