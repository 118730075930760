import _uniq from 'lodash/uniq';
import {useSelector} from 'react-redux';
import {defineMessages} from 'react-intl';

const messages = defineMessages({
    fixedAreaOption: {
        id: 'AddDataPackagePremium.fixedAreaOption',
        defaultMessage: 'All of {area}',
        description: 'fixedAreaOption label'
    },
    areaOptionCustomAOI: {
        id: 'AddDataPackagePremium.areaOptionCustomAOI',
        defaultMessage: 'Draw a polygon/upload a file/use an OS polygon',
        description: 'areaOptionCustomAOI label'
    },
});

/**
 * Returns the different options that should be used to populate the Premium Add Data Package page components used to
 * configure the data package.
 *
 * @returns {{areaRadioOptions: [], formatOptions: [], updatesOptions: [], renewalOptions: [], terminalsOptions: []}}
 */
export default function useDataPackagePremiumOptions() {
    let optionsResult = useSelector(state => state.premium.options.result);
    const format = useSelector(state => state.dataPackages.draftOrder.format);
    const area = useSelector(state => state.dataPackages.draftOrder.area);
    const updates = useSelector(state => state.dataPackages.draftOrder.updates);
    const renewal = useSelector(state => state.dataPackages.draftOrder.renewalPeriod);
    const terminals = useSelector(state => state.dataPackages.draftOrder.terminals);

    const areaRadioOptions = getAreaRadioOptions({optionsResult, format, updates, renewal, terminals});
    const formatOptions = getFormatOptions({optionsResult, area, updates, renewal, terminals});
    const updatesOptions =  getUpdateOptions({optionsResult, area, format, renewal, terminals});
    const renewalOptions =  getRenewalOptions({optionsResult, area, format, updates, terminals});
    const terminalsOptions = getTerminalsOptions({optionsResult, area, format, updates, renewal});

    return {areaRadioOptions, formatOptions, updatesOptions, renewalOptions, terminalsOptions};
}

function buildOptions(result) {
    if (!result) {
        return [];
    }
    let areaOptions = result.areas.items;
    let fixedAreaOptions = areaOptions.filter(option => !option.id.includes('Polygon'));
    let customAreas = areaOptions.filter(option => option.id.includes('Polygon'));
    let options = [];

    if (fixedAreaOptions.length > 0) {
        fixedAreaOptions.forEach(option => {
            let fixedAreaOption = {
                id: option.id,
                label: messages.fixedAreaOption,
                values: {area: option.label},
                disabled: false
            };
            options.push(fixedAreaOption);
        })
    } else {
        let allOfGbOption = {
            id: 'GB',
            label: messages.fixedAreaOption,
            values: {area: 'Great Britain'},
            disabled: true
        };

        options.push(allOfGbOption);
    }
    let customAreaOption;
    if (customAreas.length > 0) {
        let customArea = customAreas[0];
        customAreaOption = {
            id: customArea.id,
            label: messages.areaOptionCustomAOI,
            disabled: false
        };
    } else {
        customAreaOption = {
            id: 'Polygon',
            label: messages.areaOptionCustomAOI,
            disabled: true
        };
    }
    options.push(customAreaOption);

    return options;
}

function getAreaRadioOptions({optionsResult, format, updates, renewal, terminals}) {
    const areaOptions = buildOptions(optionsResult);
    if (optionsResult) {
        let relationships = optionsResult.relationships.items;

        if (format) {
            relationships = relationships.filter(r => r.format === format);
        }

        if (updates) {
            relationships = relationships.filter(r => r.schedule === updates);
        }

        if (renewal) {
            relationships = relationships.filter(r => r.terms === renewal);
        }

        if (terminals) {
            relationships = relationships.filter(r => r.terminals === terminals);
        }

        let allowedAreas = _uniq(relationships.map(r => r.area));
        areaOptions.forEach(a => a.disabled = !allowedAreas.includes(a.id));
    }
    return areaOptions;
}

function getFormatOptions({optionsResult, area, updates, renewal, terminals}) {
    let formatOptions = [];
    if (optionsResult) {
        formatOptions = optionsResult.formats.items.map(f => ({...f, enabled: true}) );

        let relationships = optionsResult.relationships.items;

        if (area) {
            relationships = relationships.filter(r => r.area === area);
        }

        if (updates) {
            relationships = relationships.filter(r => r.schedule === updates);
        }

        if (renewal) {
            relationships = relationships.filter(r => r.terms === renewal);
        }

        if (terminals) {
            relationships = relationships.filter(r => r.terminals === terminals);
        }

        let allowedFormats = _uniq(relationships.map(r => r.format));
        formatOptions.forEach(f => f.enabled = allowedFormats.includes(f.id));
    }

    return formatOptions;
}

function getUpdateOptions({optionsResult, area, format, renewal, terminals}) {
    let updatesOptions = [];
    if (optionsResult) {
        updatesOptions = optionsResult.updates.items.map(f => ({...f, enabled: true}) );

        let relationships = optionsResult.relationships.items;

        if (area) {
            relationships = relationships.filter(r => r.area === area);
        }

        if (format) {
            relationships = relationships.filter(r => r.format === format);
        }

        if (renewal) {
            relationships = relationships.filter(r => r.terms === renewal);
        }

        if (terminals) {
            relationships = relationships.filter(r => r.terminals === terminals);
        }

        let allowedUpdates = _uniq(relationships.map(r => r.schedule));
        updatesOptions.forEach(u => u.enabled = allowedUpdates.includes(u.id));
    }
    return updatesOptions;
}

function getRenewalOptions({optionsResult, area, format, updates, terminals}) {
    let renewalOptions = [];
    if (optionsResult) {
        renewalOptions = optionsResult.terms.items.map(f => ({...f, enabled: true}));

        let relationships = optionsResult.relationships.items;
        if (area) {
            relationships = relationships.filter(r => r.area === area);
        }

        if (format) {
            relationships = relationships.filter(r => r.format === format);
        }

        if (updates) {
            relationships = relationships.filter(r => r.schedule === updates);
        }

        if (terminals) {
            relationships = relationships.filter(r => r.terminals === terminals);
        }

        const allowedRenewals = _uniq(relationships.map(r => r.terms));
        renewalOptions.forEach(r => r.enabled = allowedRenewals.includes(r.id));
    }

    return renewalOptions;
}

function getTerminalsOptions({optionsResult, area, format, updates, renewal}) {
    let terminalsOptions = [];
    if (optionsResult) {
        terminalsOptions = optionsResult.terminals.items.map(f => ({...f, enabled: true}) );

        let relationships = optionsResult.relationships.items;

        if (area) {
            relationships = relationships.filter(r => r.area === area);
        }

        if (format) {
            relationships = relationships.filter(r => r.format === format);
        }

        if (updates) {
            relationships = relationships.filter(r => r.schedule === updates);
        }

        if (renewal) {
            relationships = relationships.filter(r => r.terms === renewal);
        }

        let allowedTerminals = _uniq(relationships.map(r => r.terminals));
        terminalsOptions.forEach(t => t.enabled = allowedTerminals.includes(t.id));
    }
    return terminalsOptions;
}
