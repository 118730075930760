import React from 'react';
import Header from './Header';
import Content from './Content';
import Typography from '@mui/material/Typography';
import CodeSnippet from './CodeSnippet';
import DocLinks from "./DocLinks";
import Document from './Document';
import { ExternalLink, isWidthDown, withWidth } from 'omse-components';

export function PlacesIntro(props) {
    const {width} = props;
    const mobile = isWidthDown('sm', width);
    return <Document>
        <Header back={true}>OS Places API: Getting started guide</Header>
        <Content>
        <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains how to generate an API key to access the OS Places API, and then how to use
                    that API key with a Web Development IDE to create a web-based application.
                </p>
                <p>
                    This guide assumes basic knowledge of web development and will require access to an IDE (Integrated
                    Development Environment).
                </p>
            </Typography>
            <Typography variant='h1' component={'h2'}>Instructions to Generate a Project API Key and URL</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>The following instructions describe how to obtain the API service URL and to generate an API key:</p>
                <ol>
                    <li>Click this link to open the <ExternalLink type="generic" href="/" message="OS Data Hub" /> in a new tab.
                    </li>
                    {mobile &&
                        <li>Click the navigation menu button.</li>
                    }
                    <li>Click "API Dashboard" in the {!mobile && 'navigation'} menu.</li>
                    <li>Click "APIs" in the {!mobile && 'secondary navigation'} menu.</li>
                    <li>Click "Add to API project" next to the OS Places API.</li>
                    <li>If you already have a project you may choose to add the OS Places API into that
                        project, or alternatively Select "Add to NEW PROJECT".
                        <p>The next screen will contain the Project API Key and the API Endpoint address (API URL).</p>
                        <p>You can return to this screen by clicking "My projects" at any point in the future if you
                            need to copy your API key or the API address, or if you need to regenerate your API Key.</p>
                    </li>
                    <li>Keep this page open as you'll need the key when you apply the OS Places API service
                        in your web-based application.
                    </li>
                </ol>
                <p>
                    For demonstrative purposes, we will apply the service through an IDE to create a web-based
                    application.
                </p>
            </Typography>
            <Typography variant='h1' component={'h2'}>Sample implementation using Node.js</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    These examples rely on a working installation of <ExternalLink type="generic" href='https://nodejs.org' message="Node.js" /> and the 
                    popular <ExternalLink type="generic" href='https://github.com/axios/axios' message="axios" /> module.
                </p>
                <ol>
                    <li>
                        Run a Find query.
                        <CodeSnippet>{`const axios = require('axios');

const apiKey = 'INSERT_YOUR_API_KEY_HERE';

async function find() {
    axios.get('https://api.os.uk/search/places/v1/find?maxresults=1&query=Ordnance%20Survey,%20Adanac%20Drive,%20SO16&key=' + apiKey)
    .then(function(response) {
        var response = JSON.stringify(response.data, null, 2);
        console.log(response);
    });
}
find();`
                        }</CodeSnippet>
                    </li>
                    <li>
                        Run a Postcode query.
                        <CodeSnippet>{`const axios = require('axios');

const apiKey = 'INSERT_YOUR_API_KEY_HERE';

async function postcode() {
    axios.get('https://api.os.uk/search/places/v1/postcode?postcode=SO16&key=' + apiKey)
    .then(function(response) {
        var response = JSON.stringify(response.data, null, 2);
        console.log(response);
    });
}
postcode();`
                        }</CodeSnippet>
                    </li>
                    <li>
                        Run a UPRN query.
                        <CodeSnippet>{`const axios = require('axios');

const apiKey = 'INSERT_YOUR_API_KEY_HERE';

async function uprn() {
    axios.get('https://api.os.uk/search/places/v1/uprn?uprn=200010019924&key=' + apiKey)
    .then(function(response) {
        var response = JSON.stringify(response.data, null, 2);
        console.log(response);
    });
}
uprn();`
                        }</CodeSnippet>
                    </li>
                    <li>
                        Run a Nearest query.
                        <CodeSnippet>{`const axios = require('axios');

const apiKey = 'INSERT_YOUR_API_KEY_HERE';

async function nearest() {
    axios.get('https://api.os.uk/search/places/v1/nearest?point=437293,115515&key=' + apiKey)
    .then(function(response) {
        var response = JSON.stringify(response.data, null, 2);
        console.log(response);
    });
}
nearest();`
                        }</CodeSnippet>
                    </li>
                    <li>
                        Run a Bounding Box query.
                        <CodeSnippet>{`const axios = require('axios');

const apiKey = 'INSERT_YOUR_API_KEY_HERE';

async function bbox() {
    axios.get('https://api.os.uk/search/places/v1/bbox?bbox=437201,115447,437385,115640&key=' + apiKey)
    .then(function(response) {
        var response = JSON.stringify(response.data, null, 2);
        console.log(response);
    });
}
bbox();`
                        }</CodeSnippet>
                    </li>
                    <li>
                        Run a Radius query.
                        <CodeSnippet>{`const axios = require('axios');

const apiKey = 'INSERT_YOUR_API_KEY_HERE';

async function radius() {
    axios.get('https://api.os.uk/search/places/v1/radius?point=437293,115515&radius=100&key=' + apiKey)
    .then(function(response) {
        var response = JSON.stringify(response.data, null, 2);
        console.log(response);
    });
}
radius();`
                        }</CodeSnippet>
                    </li>
                    <li>
                        Run a Polygon query.
                        <CodeSnippet>{`const axios = require('axios');

const apiKey = 'INSERT_YOUR_API_KEY_HERE';

function polygon() {
    axios.post('https://api.os.uk/search/places/v1/polygon/?key=' + apiKey, {
        "type" : "Polygon",
        "crs" : {
          "type" : "name",
          "properties" : {
            "name" : "urn:ogc:def:crs:EPSG::27700"
          }
        },
        "coordinates": [[[437226,115652],[437212,115495],[437387,115495],[437368,115663],[437226,115652]]]
    })
    .then(function(response) {
        console.log(response);
    })
    .catch(function(error) {
        console.log(error);
    });
};
polygon();`
                        }</CodeSnippet>
                    </li>
                </ol>
            </Typography>
            <DocLinks product='places' hasTechnicalSpec={true} hasDemo={true}/>
        </Content>
    </Document>
}
export default withWidth()(PlacesIntro);