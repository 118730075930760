import withStyles from 'react-jss';
import style from './legal';
import {ReactComponent as SuccessIcon} from '../../../components/icons/success-notification.svg';
import {ReactComponent as WarningIcon} from '../../../components/icons/warning-notification.svg';
import {ReactComponent as ErrorIcon} from '../../../components/icons/error-notification.svg';
import React from 'react';

export const Tick = withStyles(style)(function (props) {
    const {classes} = props;
    return <SuccessIcon width={24} height={24} className={classes.success}/>;
});

export const Warning = withStyles(style)(function (props) {
    const {classes} = props;
    return <WarningIcon width={24} height={24} className={classes.warning}/>;
});

export const Error = withStyles(style)(function (props) {
    const {classes} = props;
    return <ErrorIcon width={24} height={24} className={classes.error}/>;
});