import PropTypes from 'prop-types';
import {COM3_CATALOGUE} from "../../../../../shared/catalogues";
import {hasCom3Catalogue} from "../../../../util/permissions";
import {isIncludedInCatalogue} from "../../../../util/product";
import {useSelector} from "react-redux";

/** 
 * Field should only be included if the user has permission to view the catalogue and the product 
 * is associated with the catalogue.
 * */
export default function CatalogueScopedField({catalogueId, children}) {
    const user = useSelector(state => state.user.current.result);
    const product = useSelector(state => state.premium.product.result);

    let includeField = false;
    let userHasCataloguePermissionsFn;

    // Map to appropriate user permissions checking function.
    switch(catalogueId) {
        case COM3_CATALOGUE:
            userHasCataloguePermissionsFn = hasCom3Catalogue;
            break;
        default:
    }

    // Permissions and product/catalogue association check.
    if (userHasCataloguePermissionsFn) {
        includeField = Boolean(userHasCataloguePermissionsFn(user) && isIncludedInCatalogue(product, catalogueId));
    }

    return includeField? children : null;
}

CatalogueScopedField.propTypes = {
    catalogueId: PropTypes.oneOf([COM3_CATALOGUE]).isRequired
}