import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import {CommonDialog, CommonDialogActions, StyledCheckbox} from 'omse-components';
import {setProjectMode} from '../../modules/project/actions';

let actionCounter = 0;
export default function ProjectModeModal({targetMode, onClose, confirmLabel, title, termsLabel, heading, points, warning}) {
    const [myActionId] = useState('ProjectModeModal' + actionCounter++);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const dispatch = useDispatch();
    const working = useSelector(state => {
        const setMode = state.project.setMode;
        if(setMode.actionId === myActionId) {
            return setMode.working;
        }
    });
    const projectId = useSelector(state => state.project.current.result.id);
    const mode = useSelector(state => state.project.current.result.mode);

    // We don't need to show the terms checkbox if we are switching back to live mode
    const requireConfirmation = targetMode === 'dev';

    function setMode() {
        dispatch(setProjectMode(projectId, targetMode, myActionId));
    }

    if(mode === targetMode) {
        // We have finished
        onClose();
    }

    const actions = <CommonDialogActions onClose={onClose}
                                         onConfirm={setMode}
                                         confirmAllowed={!requireConfirmation || termsAccepted}
                                         confirmLabel={confirmLabel}
                                         working={working}
    />
    return <CommonDialog onClose={onClose}
                         title={title}
                         actions={actions}
    >
        <Typography variant='body2'>
            <FormattedMessage {...heading}/>
        </Typography>
        <ul>
            {
                points.map(item => <li key={item.id}>
                    <Typography variant='body1'>
                        <FormattedMessage {...item}/>
                    </Typography>
                </li>)
            }
        </ul>
        {
            warning &&
            <Typography variant='body2' paragraph={true}>
                <FormattedMessage {...warning}/>
            </Typography>
        }
        {
            requireConfirmation &&
            <FormControlLabel control={<StyledCheckbox checked={termsAccepted}
                                                       onClick={() => setTermsAccepted(!termsAccepted)}/>}
                              label={<FormattedMessage {...termsLabel}/>}/>
        }
        {
            !requireConfirmation &&
                <Typography variant='body2'>
                    <FormattedMessage {...termsLabel}/>
                </Typography>
        }
    </CommonDialog>;
}

ProjectModeModal.propTypes = {
    targetMode: PropTypes.oneOf(['live', 'dev']),
    onClose: PropTypes.func.isRequired,
    title: PropTypes.object.isRequired,
    confirmLabel: PropTypes.object.isRequired,
    heading: PropTypes.object.isRequired,
    points: PropTypes.array.isRequired,
    warning: PropTypes.object,
    termsLabel: PropTypes.object
};
