import styled from "@emotion/styled";
import NavButton from '../../../components/NavButton';
import tcAccessApis from '../img/tc-access-apis.png';
import { Typography } from "@mui/material";
import { tabHeadingStyles } from './styles';

const StyledSection = styled('section')`
    display: grid;
    gap: 2em;
    grid-template: 1fr / auto auto;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 800px) {
        grid-template: auto auto / 1fr;
    }
`;

const StyledTextContainer = styled('div')`
    max-width: 30em;
    @media (max-width: 800px) {
        max-width: 100%;
    }
    ${tabHeadingStyles}
`;

const StyledNavButton = styled(NavButton)`
    @media (max-width: 800px) {
        width: 100%;
    }
`;

const StyledImage = styled('img')`
    max-width: 100%;
`;

export default function AccessAPIs() {
    return (
        <StyledSection>
            <StyledTextContainer>
                <Typography gutterBottom variant="h2" component='h3'>
                    Access APIs
                </Typography>
                <Typography paragraph gutterBottom variant="body1">
                    OS APIs make it easier to build awesome things with our trusted geospatial data.
                    Accelerate your time-to-value.
                    Reduce your data management overheads, automate your workflows and innovate at pace.
                    Get £1000 free Premium API transactions, every month.
                </Typography>
                <StyledNavButton
                    path="/docs"
                    color="primary"
                    variant="outlined"
                >
                    Explore our APIs
                </StyledNavButton>
            </StyledTextContainer>
            <StyledImage
                src={tcAccessApis}
                alt="a cartographic small-scale map of an industrial site"
            />
        </StyledSection>
    )
}