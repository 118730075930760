import {createUseStyles} from 'react-jss';

export default createUseStyles(theme => ({
    button: {
        height: 48,
        minWidth: '0 !important',
        padding: theme.spacing(0.5) + " !important",
        border: "0 !important",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginLeft: theme.spacing(2)
    },
    splitButton: {
        paddingRight: "0px !important"
    },
    splitMenuButton: {
        paddingLeft: "0px !important",
        marginLeft: '0 !important'
    },
    verticalIconButton: {
        fontSize: '0.6875rem',
        // Aligns the content of the button vertically.
        flexDirection: 'column'
    },
    icon: {
        margin: 0
    }
}));