// TotalResultsBar.js
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const messages = {
    totalResults: {
        id: 'OpenDataDownloads.totalResults',
        defaultMessage: '{i} Result{plural}.',
        description: 'Total Result is Count'
    },
    totalResultsNone: {
        id: 'OpenDataDownloads.totalResultsNone',
        defaultMessage: 'No Results. Please refine your search.',
        description: 'Total Results is None'
    },
    totalResultsLoading: {
        id: 'OpenDataDownloads.totalResultsLoading',
        defaultMessage: 'Still loading, one second...',
        description: 'Total Result is N/A due to loading.'
    }
}

export function TotalResultsBar(props) {
    return (
        <div style={{ marginTop: '1em' }} >
            {(props.isLoading)
                ? <ResultsLoading />
                : <ResultsLoaded totalResults={props.totalResults} />
            }
        </div >
    );
}

TotalResultsBar.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    totalResults: PropTypes.number.isRequired
}

function ResultsLoading() {
    return (
        <Typography aria-live='polite'>
            <FormattedMessage {...messages.totalResultsLoading} />
        </Typography>
    );
}

function ResultsLoaded(props) {
    return (props.totalResults) ? (
        <Typography aria-live='polite'>
            <FormattedMessage {...messages.totalResults} values={{
                i: props.totalResults,
                plural: (props.totalResults === 1) ? '' : 's'
            }} />
        </Typography>
    ) : (
        <Typography role='alert' color='error'>
            <FormattedMessage {...messages.totalResultsNone} />
        </Typography >
    );
}
