import { CommonDialog, CommonDialogActions, ExternalLink } from 'omse-components';
import {defineMessages, FormattedMessage} from 'react-intl';
import { Typography } from '@mui/material';
import {createUseStyles} from 'react-jss';
import PropTypes from 'prop-types';
import {formattedTextToSanitizedHTML} from '../../../../util/text';

const messages = defineMessages({
    productTermsDialogTitle: {
        id: 'ProductTermsDialog.productTermsDialogTitle',
        defaultMessage: 'Licence agreement',
        description: 'Licence agreement'
    },
    prompt1: {
        id: 'ProductTermsDialog.prompt1',
        defaultMessage: 'Please read and accept our licence agreement below:',
        description: 'prompt1'
    },    
    cancel: {
        id: 'ProductTermsDialog.cancel',
        defaultMessage: 'Cancel',
        description: 'Cancel'
    },
    accept: {
        id: 'ProductTermsDialog.accept',
        defaultMessage: 'Accept',
        description: 'Accept'
    },
});

const useStyles = createUseStyles(theme => ({
    dialogBody: {
        '& p': {
            margin: theme.spacing(1, 0)
        }
    },
}));

export default function ProductTermsDialog({onClose, onConfirm, product}) {
    const classes = useStyles();
    const dialogActions = <CommonDialogActions 
        onClose={onClose} 
        confirmLabel={messages.accept} 
        onConfirm={onConfirm} 
    />;
    return <CommonDialog onClose={onClose} actions={dialogActions} title={messages.productTermsDialogTitle}>
        <div className={classes.dialogBody}>
            <Typography variant='h2'>
                {product.label}
            </Typography>
            <Typography variant='body1'>
                <FormattedMessage {...messages.prompt1} />
            </Typography>
            {product.productTerms?.termsAndConditions && 
                <Typography variant='body1' dangerouslySetInnerHTML={{__html: formattedTextToSanitizedHTML(product.productTerms.termsAndConditions)}} />
            }
            {product.productTerms?.url &&
                <Typography variant='body1'>
                    <ExternalLink type='generic' href={product.productTerms.url} /> 
                </Typography>
            }
        </div>
    </CommonDialog>
}

ProductTermsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired
}