import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import Typography from "@mui/material/Typography";
import classNames from 'classnames';
import {osColour} from 'omse-components';
import {defineMessages, useIntl} from "react-intl";

const messages = defineMessages({
    decorativeLabelAria: {
        id: 'ServiceTag.decorativeLabelAria',
        defaultMessage: 'Decorative label: {service}',
        description: 'Decorative label'
    }
});

const style = theme => ({
    serviceTag: {
        display: 'inline-flex',
        marginBottom: 9,
        height: 28,
        '& svg': {
            position: 'relative',
            right: -0.5,
            top: -1
        }
    },
    hideGutter: {
        marginBottom: 0,
    },
    serviceTagCaption: {
        fontWeight: 600,
        padding: '7px 7px 7px 13px',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        '-webkit-font-smoothing': 'antialiased'
    },
    default: {
        color: osColour.neutral.charcoal,
        background: osColour.neutral.mist
    },
    vector: {
        background: '#fee8cc'
    },
    features: {
        background: '#e4fdd0'
    },
    raster: {
        background: '#fcdbe4'
    },
    downloads: {
        background: '#dfe3ff'
    },
    ngd : {
        color: '#453C90',
        background: '#F5F5F5'
    },
    json: {
        background: '#dfe3ff'
    },
    shared: {
        color: osColour.neutral.white,
        background: osColour.primary.foxglove
    }
});

const variants = ['vector', 'features', 'raster', "downloads", 'ngd', 'json', 'shared'];

function ServiceTag(props) {
    let {classes, label, variant, hideGutter} = props;
    if(!variant) {
        // Some users of the service tag find the variant from the label
        variant = variants.find(service => label.match(new RegExp(service, 'i')));
    }
    const highlightClass = classes[variant] || classes.default;

    const intl = useIntl();
    let ariaLabel = intl.formatMessage(messages.decorativeLabelAria, {service: label});

    return (
        <div className={classNames(classes.serviceTag, highlightClass, hideGutter && classes.hideGutter)} role="img" alt='' aria-label={ariaLabel} >
            <Typography variant='caption' className={classes.serviceTagCaption}>
                {label}
            </Typography>
            <svg xmlns="http://www.w3.org/2000/svg" height="30" width="9" role='img' alt='' aria-hidden={true}>
                <polygon fill="white" points="0,30 8,0 9,0 9,30"/>
            </svg>
        </div>
    )
}

ServiceTag.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(variants),
    hideGutter: PropTypes.bool,
}

export default withStyles(style)(ServiceTag)
