import React from 'react';
import Header from './Header';
import Content from './Content';
import Typography from '@mui/material/Typography';
import DocLinks from './DocLinks';
import figure1 from './linkedIdentifiersOverview/Figure1.png';
import withStyles from 'react-jss';
import styles from './style';
import LinkedIdentifiersFeaturesTable from "./LinkedIdentifiersFeaturesTable";
import Document from './Document';
import { ExternalLink, InternalLink } from 'omse-components';

function LinkedIdentifiersOverview (props) {
    return <Document>
        <Header back={true}>OS Linked Identifiers API: Overview</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The OS Linked Identifiers API allows you to access the valuable relationships between properties,
                    streets and OS MasterMap identifiers for free. It's as easy as providing the identifier you are
                    interested in and the API will return the related feature identifiers. This allows you to find what
                    addresses exist on a given street, or the UPRN for a building on a map, or the USRN for a road and
                    more.
                </p>
                <p>
                    An identifier is a unique reference assigned to a specific thing, so when you are talking to
                    someone else you can use it to ensure you’re talking about the same thing. They are used all the
                    time, such as telephone numbers, postcodes and customer reference numbers. OS is striving to make
                    its identifiers more accessible and useful for its customers.
                </p>
                <p>
                    The OS Linked Identifiers API takes this further by enabling the linking together of datasets that
                    are using different identifiers; for example, linking a property address (UPRN - Unique Property
                    Reference Number) to the street that it is on (USRN - Unique Street Reference Number).
                </p>
                <p>
                    Some of these relationships need Premium OS data and for others it is possible to create these
                    relationships yourself through analysis of OS OpenData. However, in either situation this requires
                    significant effort and can yield errors. Wherever possible you should use an authoritative source
                    for better data reliability. The OS Linked Identifiers API gives you direct access to an
                    authoritative source for all of the relationships it publishes and removes the need for you to
                    manage and manipulate large datasets.
                </p>
                <p>These relationships can be used in conjunction with other OS APIs to:</p>
                <ul>
                    <li>
                        Create clickable interactive web app maps for selection of road or building features and
                        displaying their USRN or UPRN.
                    </li>
                    <li>
                        Search for a street name and highlight all the properties that are on it.
                    </li>
                    <li>
                        Visualise accident counts, pollution levels or average vehicle speed data using Open Roads.
                    </li>
                </ul>
                <p>
                    The API has been designed with ease-of-use in mind. It conforms to <ExternalLink
                        type="generic"
                        href="https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.2.md"
                        message="REST OpenAPI v3.0.2 specification"
                    /> and returns data in a readable <ExternalLink
                        type="generic"
                        href="https://www.ecma-international.org/publications-and-standards/standards/ecma-404/"
                        message="JSON"
                    /> format. These standards integrate
                    well with JavaScript and support the creation of sophisticated interactive map web
                    applications in conjunction with our OS Maps API, OS Vector Tile API and OS Features API.
                </p>
                <p>
                    The following diagram and table show all the feature types that we have included (such as address
                    records, building outlines, road surface area, road names, road sections and street records) and
                    the relationships between them that we provide links for.
                </p>
            </Typography>
            <img src={figure1} alt='OS Linked Indentifiers API: diagram showing attribution linkages between different OS products.'/>
            <Typography variant='body1' paragraph={true} component='div'>
                <LinkedIdentifiersFeaturesTable/>
            </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The OS Linked Identifiers API forms part of the family of OS Open Identifier products. The suite of
                    Open Identifier products and associated <ExternalLink
                        type="generic"
                        href="https://www.ordnancesurvey.co.uk/business-government/tools-support/open-mastermap-programme/open-id-policy"
                        message="Open Identifier Policy"
                    /> aim to promote the publishing of more open geospatial data to enable
                    better informed decisions for all. These identifier products are available for download on the OS
                    Data Hub or can be accessed directly via the <InternalLink path='/docs/wfs/overview' message='OS Features API'/>.
                </p>
            </Typography>

            <Typography variant='body1' paragraph={true} component='div'>
                <p>Using the OS Linked Identifiers API,</p>
                <p>You can:</p>
                <ul>
                    <li>
                        Create clickable interactive web app maps:
                        <ul>
                            <li>
                                The relationships to our OS MasterMap Topography Layer TopographicArea TOID
                                enable the creation of interactive clickable maps when used in conjunction
                                with our OS Vector Tile API and/or OS Features API.
                            </li>
                            <li>
                                When selecting a building the OS Linked Identifiers API query can provide details
                                of the UPRN for the building address.
                            </li>
                            <li>
                                If a road surface feature is selected, then the OS MasterMap Highways Road
                                TOID can be retrieved to access the name of the road.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Link properties to streets:
                        <ul>
                            <li>
                                Our link between USRN and UPRN makes it easy to find the property address on a
                                given street or identify the street that an address is on.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Visualise third party street data:
                        <ul>
                            <li>
                                Visualise road speed, traffic pollution and other road related data that is published
                                against our OSMM Highways RoadLink identifiers for free with the Open Roads dataset.
                                This is enabled via the OSMM Highways RoadLink TOID to Open Roads RoadLink/RoadNode
                                GUID relationships.
                            </li>
                        </ul>
                    </li>
                </ul>
                <p>You can't:</p>
                <ul>
                    <li>
                        Access geometry or other attribution linked to the identifier.
                        The OS Linked Identifier API only provides details of the related identifiers.
                        Once you have the related identifiers, you can retrieve geometry and attribution via
                        the OS Features API from either our OS Open Identifier or Premium products.
                    </li>
                </ul>
            </Typography>
            <DocLinks product='linkedIdentifiers' hasGettingStarted={true} hasTechnicalSpec={true} hasDemo={true}/>
        </Content>
    </Document>
}

export default withStyles(styles)(LinkedIdentifiersOverview);
