import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import withStyles from 'react-jss';
import {CircularProgress, Button, List, Typography, InputLabel} from '@mui/material';
import {defineMessages, FormattedMessage} from "react-intl";
import ProjectListEntry from './projectList/ProjectListEntry';
import {DropDownMenu, osColour, linkButton} from 'omse-components';
import {getAssociatedProjects, clearAssociatedProjects} from '../../modules/projects';
import {getDefaultOrganisation, getUserOrganisationById, hasManyOrganisations} from '../../util/organisation';
import SearchBox from "../../components/SearchBox";

const messages = defineMessages({
    searchLabel: {
        id: 'ProjectList.searchLabel',
        defaultMessage: 'Search projects',
        description: 'Label for the project search box'
    },
    searchPlaceholder: {
        id: 'ProjectList.searchPlaceholder',
        defaultMessage: 'Search by project name',
        description: 'Placeholder for the project search box'
    },
    sortLabel: {
        id: 'ProjectList.sortLabel',
        defaultMessage: 'Sort by',
        description: 'Label for the project sort drop-down'
    },
    sortModified: {
        id: 'ProjectList.sortModified',
        defaultMessage: 'Date modified',
        description: 'Label for the date modified option in the project sort drop-down'
    },
    sortCreated: {
        id: 'ProjectList.sortCreated',
        defaultMessage: 'Date created',
        description: 'Label for the date created option in the project sort drop-down'
    },
    sortName: {
        id: 'ProjectList.sortName',
        defaultMessage: 'Name',
        description: 'Label for the name option in the project sort drop-down'
    },
    noProjectsInOrganisation: {
        id: 'ProjectList.noProjectsInOrganisation',
        defaultMessage: 'No projects found in this organisation',
        description: 'Label when no projects found'
    },
    noProjectsInAssociatedOrgs: {
        id: 'ProjectList.noProjectsInAssociatedOrgs',
        defaultMessage: 'No projects found in other organisations',
        description: 'Label when no projects found in other organisations'
    },
    projectsFoundInAssociatedOrgs: {
        id: 'ProjectList.projectsFoundInAssociatedOrgs',
        defaultMessage: 'Projects found in other organisations',
        description: 'Label when projects are found in other organisations'
    },
    clearSearch: {
        id: 'ProjectList.clearSearch',
        defaultMessage: 'Clear search filter',
        description: 'Label for clear search link'
    },
    noResults: {
        id: 'ProjectList.noResults',
        defaultMessage: 'No results found',
        description: 'Label when no results are returned'
    }
});

const styles = theme => ({
    options: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            display: 'flex'
        }
    },
    searchOption: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(1)
        }
    },
    sortOption: {
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        }
    },
    label: {
        marginBottom: theme.spacing(1)
    },
    projectList: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 0,
        marginTop: theme.spacing(3),
        '&:first-of-type': {
            marginTop: 0
        }
    },
    noResults: {
        background: osColour.neutral.clouds,
        padding: theme.spacing(3),
        color: osColour.neutral.stone,
        marginBottom: theme.spacing(1)
    },
    noResultsMulti: {
        marginBottom: theme.spacing(1)
    },
    associatedResults: {
        marginTop: theme.spacing(2)
    },
    linkButton: {
        ...linkButton,
        marginTop: theme.spacing(0.5)
    }
});

function sortByCreated(a, b) {
    return b.createdAt - a.createdAt;
}
function sortByModified(a, b) {
    return b.lastModifiedAt - a.lastModifiedAt;
}
function sortByName(a, b) {
    return a.name.localeCompare(b.name);
}
export function filterSort(search, sort, results) {
    let filtered = [];
    const searchTerm = search.toLowerCase();
    if (results) {
        filtered = results.filter(project => {
            const name = project.name.toLowerCase();
            return name.indexOf(searchTerm) !== -1;
        })
        .sort(sort);
    }
    return filtered;
}

export class ProjectList extends Component {
    state = {
        search: '',
        filteredProjects: [],
        filteredAssociatedProjects: [],
        sort: sortByCreated,
        sortLabel: messages.sortCreated
    }

    constructor(props) {
        super(props);
        const {projects} = this.props;
        const {sort} = this.state;

        this.state.filteredProjects = projects.slice().sort(sort);
    }

    componentDidMount() {
        const {clearAssociatedProjects} = this.props;
        clearAssociatedProjects();
    }

    componentDidUpdate(prevProps) {
        const {search, sort} = this.state;
        const {loadingAssociatedProjects, associatedProjects} = this.props;
        if (prevProps.loadingAssociatedProjects && !loadingAssociatedProjects && associatedProjects) {
            this.setState({filteredAssociatedProjects: filterSort(search, sort, associatedProjects)});
        }
    }

    setSearch = search => {
        const {sort} = this.state;
        const {projects, userDetails, associatedProjects, getAssociatedProjects, orgId} = this.props;

        // trigger an associated projects search when none are loaded
        if (hasManyOrganisations(userDetails) && search && !associatedProjects) {
            let org;
            if (orgId) {
                org = getUserOrganisationById(userDetails, orgId);
            } else {
                org = getDefaultOrganisation(userDetails);
            }

            // get the projects for a single alternative organisation, which is not selected/default
            if (org && org.value) {                                
                const altOrg = userDetails.orgs.find(o => o.value !== org.value);
                if (altOrg && altOrg.value) {
                    getAssociatedProjects(altOrg.value);
                }
            }
        }

        this.setState({
            search,
            filteredProjects: filterSort(search, sort, projects),
            filteredAssociatedProjects: filterSort(search, sort, associatedProjects)
        });
    }

    setSort = item => {
        const {filteredProjects, filteredAssociatedProjects} = this.state;
        this.setState({
            sort: item.sort,
            sortLabel: item.label,
            filteredProjects: filteredProjects.sort(item.sort),
            filteredAssociatedProjects: filteredAssociatedProjects.sort(item.sort)
        });
    }

    resetSearch = () => {
        const {projects} = this.props;
        const {sort} = this.state;
        this.setState({
            search: '',
            filteredProjects: projects.slice().sort(sort)
        });
    }

    render() {
        const {userStatsResult, classes, userDetails, loadingAssociatedProjects} = this.props;
        const {search, filteredProjects, filteredAssociatedProjects, sortLabel} = this.state;
        const hasManyOrgs = hasManyOrganisations(userDetails);
        return <Fragment>
            <div className={classes.options}>
                <SearchBox label={messages.searchLabel}
                           placeholder={messages.searchPlaceholder}
                           search={search}
                           setSearch={this.setSearch}
                           className={classes.searchOption}
                           limitWidth={true}
                />
                <div className={classes.sortOption}>
                    <InputLabel htmlFor='sort' className={classes.label}>
                        <FormattedMessage {...messages.sortLabel}/>
                    </InputLabel>
                    <DropDownMenu buttonId='sort'
                        buttonLabel={sortLabel}
                        buttonVariant='outlined'
                        buttonFontWeight='bold'
                        variant='block'
                        value={sortLabel?.id}
                        items={[
                            { label: messages.sortCreated, value: messages.sortCreated.id, action: this.setSort, sort: sortByCreated },
                            { label: messages.sortModified, value: messages.sortModified.id, action: this.setSort, sort: sortByModified },
                            { label: messages.sortName, value: messages.sortName.id, action: this.setSort, sort: sortByName }
                    ]} />
                </div>
            </div>
                <List className={classes.projectList} aria-live='polite'>
                    {(filteredProjects.length > 0)?
                        <Fragment>
                            {filteredProjects.map(project =>
                                <ProjectListEntry key={project.projectId} 
                                    project={project} userStatsResult={userStatsResult} />)}
                        </Fragment>
                        :
                        <Fragment>
                            {hasManyOrgs?
                                <Typography component='div' variant='body1'>
                                    <FormattedMessage {...messages.noProjectsInOrganisation} />
                                </Typography>
                                :
                                <div className={classes.noResults}>
                                    <Typography variant='h2'>
                                        <FormattedMessage {...messages.noResults}/>
                                    </Typography>
                                    <Button className={classes.linkButton} onClick={this.resetSearch}>
                                        <FormattedMessage {...messages.clearSearch}/>
                                    </Button>
                                </div>
                            }
                        </Fragment>
                    }

                    {(search && hasManyOrgs) &&
                        <Fragment>
                            {loadingAssociatedProjects?
                                <CircularProgress size={32} />
                                :
                                <Fragment>
                                    {(filteredAssociatedProjects.length > 0)?
                                        <div className={classes.associatedResults}>
                                            <Typography component='span' variant='body1'>
                                                <FormattedMessage {...messages.projectsFoundInAssociatedOrgs} />
                                            </Typography>
                                            {filteredAssociatedProjects.map(project => {
                                                if (project.orgId) {
                                                    const matched = getUserOrganisationById(userDetails, project.orgId);
                                                    if (matched && matched.name) {
                                                        project.orgName = matched.name;
                                                    }
                                                }
                                                return <ProjectListEntry key={project.projectId} 
                                                    project={project} orgName={project.orgName} />})
                                            }
                                        </div>
                                        :
                                        <Typography component='div' variant='body1' className={classes.noResultsMulti}>
                                            <FormattedMessage {...messages.noProjectsInAssociatedOrgs} />
                                        </Typography>
                                    }
                                </Fragment>
                            }
                        </Fragment>
                    }
                </List>
        </Fragment>;
    }
}

ProjectList.propTypes = {
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.object.isRequired,
    userStatsResult: PropTypes.object,
    orgId: PropTypes.string
};

export function mapStateToProps(state) {
    const userDetails = state.user.current.result;
    const orgId = state.organisation.current.id;
    const {associated} = state.projects;
    return {
        orgId,
        userDetails,
        associatedProjects: associated.result,
        loadingAssociatedProjects: associated.loading
    };
}

const styled = withStyles(styles)(ProjectList);
export default connect(mapStateToProps, {getAssociatedProjects, clearAssociatedProjects})(styled);