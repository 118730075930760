import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import { InternalLink } from 'omse-components';
import routes from '../../../util/routes';
import InlineCodeSnippet from '../../../components/InlineCodeSnippet';

export default function OAuth(props) {
    return <Fragment>
        <Typography variant='body1'>
            Using OAuth 2 for authentication is a two-stage process. First you use the Project API Key and Secret to
            create an access token, and then you include the access token in an Authorization header on your API request.
            The value of the Authorization header should be 'Bearer', a space, and then the access token, e.g. <InlineCodeSnippet>Bearer {'<accessToken>'}</InlineCodeSnippet>.
        </Typography>
        <Typography variant='body1'>
            See the <InternalLink path={routes.oauthOverview} message="OAuth 2 API documentation" /> for detailed instructions on how to create an access token.
        </Typography>
    </Fragment>
}

