import React from 'react';
import Typography from '@mui/material/Typography';
import {createUseStyles} from 'react-jss';
import {osColour} from 'omse-components';
import PropTypes from 'prop-types';

import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
    coverage: {
        id: 'PremiumDesc.actions',
        defaultMessage: 'Coverage:',
        description: 'Label coverage'
    },
    allOfGb: {
        id: 'PremiumDesc.allOfGb',
        defaultMessage: 'All of Great Britain',
        description: 'Label all of GB'
    },
    customArea: {
        id: 'PremiumDesc.customArea',
        defaultMessage: 'Customisable Area',
        description: 'Label custom area option'
    },
    dataStructure: {
        id: 'PremiumDesc.dataStructure',
        defaultMessage: 'Data structure:',
        description: 'Label data structure'
    },
    supplyFormat: {
        id: 'PremiumDesc.supplyFormat',
        defaultMessage: 'Supply format:',
        description: 'Label supply format'
    },
    updateFrequency: {
        id: 'PremiumDesc.updateFrequency',
        defaultMessage: 'Update frequency:',
        description: 'Label update frequency'
    }
});

const KNOWN_AREA_IDS = ['GB', 'GB_AND_NI', 'PRE_AREA'];

const useStyles = createUseStyles(theme => ({
    metadataContainer: {
        marginTop: 10
    },
    metadataLabel: {
        color: osColour.neutral.stone,
        marginRight: 4,
        width: 130,
        display: 'inline-block'
    },
    metadata: {
        '& > div': {
            display: 'flex'
        },
        '& $metadataLabel': {
            flexShrink: 0
        }
    },
    description: {
        '& p': {
            margin: theme.spacing(1,0,0,0)
        },
        '& ul': {
            margin: 0,
            padding: 0
        },
        '& ul li ': {
            margin: 0,
            listStyleType: 'none'
        }
    }
}));

function displayFormats(formats) {
    if(formats.length > 2) {
        formats = formats.slice(0, -1).join(', ') + ', or ' + formats[formats.length-1];
    } else {
        formats = formats.join(' or ');
    }
    return formats;
}

export default function PremiumDesc(props) {
    let {item} = props;
    let classes = useStyles();

    let coverageLabels = item.areas ? item.areas.filter(area => {
        return KNOWN_AREA_IDS.includes(area.id);
    }).map(area => area.label) : [];
    
    if (item.customAreaEnabled) {
        coverageLabels.push("Customisable Area");
    }

    return (<div className={classes.description}>
            <Typography variant='h5' component='div' color='textPrimary'>
                {item.summary}
            </Typography>
            <div className={classes.metadataContainer}>
                <Typography component='div' variant='body1' className={classes.metadata}>
                    <div>
                        <div className={classes.metadataLabel}>
                            <FormattedMessage {...messages.coverage} />
                        </div>
                        {coverageLabels.join(', ')}
                    </div>
                    <div>
                        <div className={classes.metadataLabel}>
                            <FormattedMessage {...messages.dataStructure} />
                        </div>
                        {item.dataStructures && item.dataStructures.join(', ')}
                    </div>
                    <div>
                        <div className={classes.metadataLabel}>
                            <FormattedMessage {...messages.supplyFormat} />
                        </div>
                        {displayFormats(item.supplyFormats)}
                    </div>
                    <div>
                        <div className={classes.metadataLabel}>
                            <FormattedMessage {...messages.updateFrequency} />
                        </div>
                        {item.updateFrequency}
                    </div>
                </Typography>
            </div>
    </div>);
}

PremiumDesc.propTypes = {
    item: PropTypes.object.isRequired
};
