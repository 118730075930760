// Looks up human-readable labels for datapackage fields.
export default function optionsLabelLookup(rawValue, optionsCategory){
    if(!(rawValue && optionsCategory && optionsCategory.items && optionsCategory.itemCount > 0)){
        return null;
    }

    let label;
    optionsCategory.items.some(ele => {
        if(ele.id === rawValue){
            label = ele.label;
            return true;
        }
        return false;
    })

    return label;
}
