export const drawingTools = [
    {
        name: 'Search',
        definition: 'Search for postcode or place name using OS Names API to zoom to that location'
    },
    {
        name: 'Polygon+',
        definition: 'Add a user defined polygon to the map'
    },
    {
        name: 'Polygon-',
        definition: 'Remove all or part of a user defined polygon from the map (click the down arrow to access this function)'
    },
    {
        name: 'Rectangle+',
        definition: 'Add a rectangle to the map'
    },
    {
        name: 'Rectangle-',
        definition: 'Remove a rectangular area from the map (click the down arrow to access this function)'
    },
    {
        name: 'Reset',
        definition: 'Removes all polygons from the map'
    },
    {
        name: 'Load Polygon',
        definition: 'Load a polygon from the polygon library or upload a polygon from a file (click the down arrow to access this function)'
    },
    {
        name: 'Save',
        definition: 'Saves the drawn polygon on the map to the polygon library'
    }
]