import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { defineMessages, useIntl } from "react-intl";
import Card from "../../components/card/Card";
import cardPsgaImg from "./cardGallery/cardPsga.png";
import cardPremiumImg from "./cardGallery/cardPremium.jpg";
import cardOsDevBlogImg from "./cardGallery/cardOsDevBlog.jpg";
import cardMoreThanMapsImg from "./cardGallery/cardMoreThanMaps.png";
import { ExternalLink, theme } from "omse-components";
import { hasPartnerCatalogue } from "../../util/permissions";
import { USER_PREMIUM_DATA_PLAN, USER_PSGA_PLAN } from "../../../shared/plans";
import { osMedium, osNgdGitBookMoreThanMaps, osSelectAndBuildAccess } from "../../util/routes";

const cardPsgaMessages = defineMessages({
    title: {
        id: "cardPsgaMessages.title",
        defaultMessage: "Get started with Recipes",
        description: "PSGA-Only Card > Title",
    },
    summary: {
        id: "cardPsgaMessages.summary",
        defaultMessage: "Create your own customised data selection with OS Select+Build.",
        description: "PSGA-Only Card > Summary",
    },
    altText: {
        id: "cardPsgaMessages.altText",
        defaultMessage: "Illustration of the Ordnance Survey recipe creation tool.",
        description: "PSGA-Only Card > Image Alternative Text",
    },
    linkText: {
        id: "cardPsgaMessages.linkText",
        defaultMessage: "Find out more",
        description: "PSGA-Only Card > Link Text",
    },
    linkAriaLabel: {
        id: "cardPsgaMessages.linkAriaLabel",
        defaultMessage: "Find out more about creating Recipes (Opens in a New Tab)",
        description: "PSGA-Only Card > Link Aria Label",
    },
});

const cardPremiumMessages = defineMessages({
    title: {
        id: "cardPremiumMessages.title",
        defaultMessage: "Our Premium products",
        description: "Premium-Only Card > Title",
    },
    summary: {
        id: "cardPremiumMessages.summary",
        defaultMessage: "Learn what data products you can access and use on your Premium plan.",
        description: "Premium-Only Card > Summary",
    },
    altText: {
        id: "cardPremiumMessages.altText",
        defaultMessage: "Aerial image of suburban settlement",
        description: "Premium-Only Card > Image Alternative Text",
    },
    linkText: {
        id: "cardPremiumMessages.linkText",
        defaultMessage: "View our Premium products",
        description: "Premium-Only Card > Link Text",
    },
    linkAriaLabel: {
        id: "cardPremiumMessages.linkAriaLabel",
        defaultMessage: "View our Premium products (Opens in a New Tab)",
        description: "Premium-Only Card > Link Aria Label",
    },
});

const cardMoreThanMapsMessages = defineMessages({
    title: {
        id: "cardMoreThanMapsMessages.title",
        defaultMessage: "More than maps",
        description: "MoreThanMaps Card > Title",
    },
    summary: {
        id: "cardMoreThanMapsMessages.summary",
        defaultMessage:
            "For all your technical geospatial support with a range of accessible information and resources.",
        description: "MoreThanMaps Card > Summary",
    },
    altText: {
        id: "cardMoreThanMapsMessages.altText",
        defaultMessage: "Cartographic mid-scale image of London with the River Thames.",
        description: "MoreThanMaps Card > Image Alternative Text",
    },
    linkText: {
        id: "cardMoreThanMapsMessages.linkText",
        defaultMessage: "Learn more",
        description: "MoreThanMaps Card > Link Text",
    },
    linkAriaLabel: {
        id: "cardMoreThanMapsMessages.linkAriaLabel",
        defaultMessage: "Visit our More Than Maps site (Opens in a New Tab)",
        description: "MoreThanMaps Card > Link Aria Label",
    },
});

const cardOsDevBlogMessages = defineMessages({
    title: {
        id: "cardOsDevBlogMessages.title",
        defaultMessage: "Get inspired",
        description: "OS Dev Blog Card > Title",
    },
    summary: {
        id: "cardOsDevBlogMessages.summary",
        defaultMessage: "Hear from our developers on how they're using OS data and products.",
        description: "OS Dev Blog Card Card > Summary",
    },
    altText: {
        id: "cardOsDevBlogMessages.altText",
        defaultMessage: "developer glancing at laptop screen",
        description: "OS Dev Blog Card Card > Image Alternative Text",
    },
    linkText: {
        id: "cardOsDevBlogMessages.linkText",
        defaultMessage: "Read our blog",
        description: "OS Dev Blog Card Card > Link Text",
    },
    linkAriaLabel: {
        id: "cardOsDevBlogMessages.linkAriaLabel",
        defaultMessage: "Read our OS Developer blog on Medium (Opens in a New Tab)",
        description: "OS Dev Blog Card Card > Link Aria Label",
    },
});

const GalleryContainer = styled("section")`
    margin: 3em 0 1em 0;
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 1fr 1fr;
    ${theme.breakpoints.down(1550)} {
        grid-template-columns: 1fr 1fr;
    }
    ${theme.breakpoints.down(1340)} {
        grid-template-columns: 1fr 1fr 1fr;
    }
    ${theme.breakpoints.down("sm")} {
        grid-template-columns: 1fr;
    }
`;

function CardBuilder(p) {
    const { img, text, link } = p;
    const intlHandler = useIntl();
    const ariaLabelString = intlHandler.formatMessage(text.linkAriaLabel);
    return (
        <Card
            imgSrc={img}
            imgAltMessage={text.altText}
            title={text.title}
            titleComponent={"h2"}
            description={text.summary}
            link={<ExternalLink href={link} message={text.linkText} ariaLabel={ariaLabelString} />}
        />
    );
}

export default function CardGallery() {
    const user = useSelector((state) => state.user.current.result);
    const isPsgaUser = user?.plan === USER_PSGA_PLAN;
    const isPremiumUser = user?.plan === USER_PREMIUM_DATA_PLAN;
    const isPartnerUser = isPremiumUser && hasPartnerCatalogue(user);

    let planCardImg, planCardText, planCardLink;
    if (isPsgaUser || isPartnerUser) {
        planCardImg = cardPsgaImg;
        planCardText = cardPsgaMessages;
        planCardLink = osSelectAndBuildAccess;
    } else if (isPremiumUser) {
        planCardImg = cardPremiumImg;
        planCardText = cardPremiumMessages;
        planCardLink =
            "https://www.ordnancesurvey.co.uk/products/search-for-os-products?plan=0ebf35ff-3ec6-4aa7-962c-e8eb6a4771c2";
    }

    return (
        <GalleryContainer>
            {(isPsgaUser || isPremiumUser || isPartnerUser) && (
                <CardBuilder img={planCardImg} text={planCardText} link={planCardLink} />
            )}
            <CardBuilder
                img={cardMoreThanMapsImg}
                text={cardMoreThanMapsMessages}
                link={osNgdGitBookMoreThanMaps}
            />
            <CardBuilder img={cardOsDevBlogImg} text={cardOsDevBlogMessages} link={osMedium} />
        </GalleryContainer>
    );
}
