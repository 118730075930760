import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import PropTypes from "prop-types";
import {useHistory, useLocation} from 'react-router';
import {Link} from "react-router-dom";
import classNames from "classnames";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {Badge, Button, IconButton, Dialog, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {customBreakpoints, DropDownMenu, osColour} from 'omse-components';
import routePaths, {getLocation, logIn, logOut} from "../../util/routes";
import useFeatureCheck from "../../util/useFeatureCheck";
import {CRM_PENDING_PSGA_PLAN, USER_OPEN_DATA_PLAN } from "../../../shared/plans";
import { WORKSPACE } from "../../../shared/features";
import NavButton from "../NavButton";
import CustomSwitch from "../switch/Switch";
import {ReactComponent as InfoIcon} from "../icons/info-notification.svg";
import {ReactComponent as NotificationIcon} from "../icons/notification.svg";
import {ReactComponent as OverflowIcon} from "../icons/overflow-menu.svg";
import useTryWorkspace from "../../hooks/useTryWorkspace";
import menuMessages from "../../pages/appContent/MenuMessages";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { shouldUseResponsiveMenu } from "../../util/menu";
import { openAccountMenu } from "../../modules/menu/actions";
import { hasShowWorkspacePermission } from "../../util/permissions";
import { cookieSettingToIndex, isCookieControlSettingAccepted, openCookieControl } from "../../util/cookie-control";
import { sendEventToGTM, tagEventKeys } from "../../util/gtm";
import { getUserDetailsDefaultContext } from "../../util/organisation";

const COUNT_DISPLAY_LIMIT = 99;
const USERNAME_CHARS_DISPLAY_MAX = 15;
const SIGN_UP_MIN_W = 415;
const OVERFLOW_MAX_W = 400;

const messages = defineMessages({
    logIn: {
        defaultMessage: 'Log in',
        description: 'Label for the log in button in the app header',
        id: 'Header.logIn'
    },
    signUp: {
        defaultMessage: 'Sign up',
        description: 'Label for the sign up button in the app header',
        id: 'Header.signUp'
    },
    logOut: {
        defaultMessage: 'Log out',
        description: 'Label for the log out button in the app header',
        id: 'Header.logOut'
    },
    caption: {
        defaultMessage: 'Data Hub',
        description: 'Label placed close to the main OS logo in the app header',
        id: 'Header.caption'
    },
    account: {
        defaultMessage: 'Account',
        description: 'Label for the account menu item the app header',
        id: 'Header.Account'
    },
    accountAriaLabel: {
        defaultMessage: '{user} account',
        description: 'aria label for the account menu item the app header',
        id: 'Header.accountAriaLabel'
    },
    upgrade: {
        defaultMessage: 'Upgrade your plan',
        description: 'Text for the upgrade link',
        id: 'Header.upgrade'
    }
});

const StyledSignInButtons = styled.div(({theme}) => ` 
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    flex: 3 1 auto;
    padding: 8px 16px 8px 8px;
    ${theme.breakpoints.down(customBreakpoints.mobile)} {
        padding: 8px 16px 8px 0;
    }
    ${theme.breakpoints.down(customBreakpoints.minimal + 10)} {
        padding: 8px 8px 8px 0;
    }
    & button {
        ${theme.breakpoints.down(customBreakpoints.mobile + 36)} {
            padding: 6px 0 !important;
        }
    }
    & .signUpButton {
        margin-right: ${theme.spacing(1)};
        ${theme.breakpoints.down(SIGN_UP_MIN_W)} {
            display: none;
        }
        ${theme.breakpoints.up('sm')} {
            margin-right: ${theme.spacing(1.5)};
        }
    }
`);

const StyledUserButtons = styled.div(({theme}) => ` 
    display: flex;
    justify-content: flex-end;
    align-items: center;
    white-space: nowrap;
    padding-right: ${theme.spacing(1)};
    padding-left: 0;
    & button {
        ${theme.breakpoints.down(customBreakpoints.minimal + 5)} {
            padding: 6px;
        }
    }
    & .notifications {
        overflow: visible;
        padding: ${theme.spacing(1)};
    }
    & .notificationIcon {
        color: ${osColour.neutral.stone};
    }
    & .badge {
        & span {
            background: ${osColour.primary.foxglove};
            color: ${osColour.neutral.white};
            border: solid #fff 2px;
            border-radius: 12px;
            left: -3px;
            top: -1px;
            height: 24px;
            width: 24px;
            font-size: 0.875rem;
        }
        &.small > span {
            font-size: 0.75rem;
        }
    }
    & .usernameButton {
        max-height: 36px;
        ${theme.breakpoints.down(OVERFLOW_MAX_W)} {
            display: none;
        }
    }
    & .usernameLabel {
        color: ${osColour.neutral.stone};
        font-weight: normal;
        overflow: hidden;
        display: block;
        text-align: right;
        padding: ${theme.spacing(0.75, 3, 0.75, 1)};
        & svg {
            position: absolute;
            right: ${theme.spacing(1.5)};
            top: calc(50% - 1px);
        }
    }
    & .userOverflow {
        display: flex;
        padding: ${theme.spacing(1)};
        margin-right: 0;
        transform: rotate(90deg);
        border-radius: 24px;
        & svg {
            color: ${osColour.neutral.stone};
        }
        ${theme.breakpoints.down(OVERFLOW_MAX_W)} {
            display: flex !important;
        }
    }
    & .upgrade {
        margin-right: ${theme.spacing(1)};
        align-items: center;
        &, &:hover {
            color: ${osColour.primary.foxglove} !important;
        }
        & svg {
            margin-right: ${theme.spacing(0.5)};
        }
    }
`);

const StyledDialog = styled(Dialog)`
    & .MuiDialog-paper {
        display: block;
        padding: ${({ theme }) => theme.spacing(4)};
        max-width: 30em;
        line-height: 28px;
    }
    & .MuiDialog-paper > .headerRegion {
        display: flex;
        margin-bottom: ${({ theme }) => theme.spacing(2)};
        align-items: start;
        justify-content: space-between;
        gap: ${({ theme }) => theme.spacing(4)};
    }
    & .MuiDialog-paper > .MuiTypography-body1 {
        margin-bottom: ${({ theme }) => theme.spacing(4)};
    }
`;

export const UserDetails = ({
    useOverflow,
    hideNotifications,
    toggleNotifications,
    loading,
    userDetails,
    notificationCount,
    holdingPageDisplayed,
    isVNHeader
}, userDetailsRef) => {
    const intl = useIntl();
    const {breakpoints} = useTheme();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const menu = useSelector(state => state.menu);
    const [showDisabledCookiesDialog, setShowDisabledCookiesDialog] = useState(false);

    const belowLg = useMediaQuery(breakpoints.down('lg'));
    const belowMd = useMediaQuery(breakpoints.down('md'));

    // Workspace display is determined using permissions from the user's default organisation context.
    const defaultContextUserDetails = getUserDetailsDefaultContext(userDetails);
    const hasWorkspaceFeature = useFeatureCheck(WORKSPACE);
    const { workspaceEnabled, toggleWorkspace } = useTryWorkspace(defaultContextUserDetails?.id)
    
    // Map the double refs so instead of userActionsRef.current it will be userDetailsRef.current.userActionsRef
    const userActionsRef = useRef();
    const notificationButtonRef = useRef();
    useImperativeHandle(userDetailsRef, () => ({
        get userActionsRef() {
            return userActionsRef.current;
        },
        get notificationButtonRef() {
            return notificationButtonRef.current;
        }
    }));

    useEffect(()=>{
        if(!userDetails){ hideNotifications(); }
    }, [userDetails, hideNotifications]);

    const buttonContent = [];
    const logInActions = [];

    function handleToggle() {
        if (!functionalCookiesAccepted) {
            setShowDisabledCookiesDialog(true);
            return;
        }
        toggleWorkspace();
        sendEventToGTM(tagEventKeys.workspace.optIn, [{ workspaceOptIn: !workspaceEnabled, userPlan: userDetails?.plan  }]);
    };

    function handleChangeCookiePrefs() {
        // Close the modal to untrap focus.
        setShowDisabledCookiesDialog(false);
        openCookieControl();
    }

    const functionalCookiesAccepted = isCookieControlSettingAccepted(cookieSettingToIndex.functionality)

    if (userDetails && defaultContextUserDetails) {
        const userItems = [
            {label: messages.logOut, action: logOut}
        ];
        if(hasWorkspaceFeature && !isVNHeader) {
            if(hasShowWorkspacePermission(defaultContextUserDetails)) {
                !belowMd && buttonContent.push(
                    <>
                        <CustomSwitch 
                            key="CustomSwitch"
                            onChange={handleToggle} 
                            checked={workspaceEnabled} 
                            label={workspaceEnabled ? 'New workspace' : 'Try new workspace'} 
                            labelPlacement={'start'} 
                            id={'workspace-toggle'}
                            tooltip={workspaceEnabled ? menuMessages.workspaceCalloutOff.defaultMessage : menuMessages.workspaceCallout.defaultMessage}
                        />
                        {showDisabledCookiesDialog && (
                            <StyledDialog open={true} onClose={() => setShowDisabledCookiesDialog(false)}>
                                <div className="headerRegion">
                                    <Typography variant="h2">
                                        <FormattedMessage {...menuMessages.workspaceDisabledDesktopTitle} />
                                    </Typography>
                                    <IconButton aria-label="close" onClick={() => setShowDisabledCookiesDialog(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                                <Typography variant="body1">
                                    <FormattedMessage {...menuMessages.workspaceDisabledDesktopSummary} />
                                </Typography>
                                <Button color='primary' variant='contained' onClick={() => handleChangeCookiePrefs()}>
                                    <FormattedMessage {...menuMessages.amendCookie}/>
                                </Button>
                            </StyledDialog>
                        )}
                    </>
                )
            }
        }

        // When the holding page is active for a specific user group, we want to hide the account option
        !holdingPageDisplayed && userItems.unshift({
            label: messages.account, action: () => {
                return history.push(routePaths.account)
            }
        })


        const badgeClass = {'badge': true};
        if (notificationCount > COUNT_DISPLAY_LIMIT) {
            badgeClass.small = true;
        }

        if ((userDetails.plan === USER_OPEN_DATA_PLAN && userDetails.orgPlan !== CRM_PENDING_PSGA_PLAN) && (location.pathname !== routePaths.plans)
            && !holdingPageDisplayed) {
            // This link doesn't fit on medium screens and below, so miss it out there
            if (!belowLg) {
                buttonContent.push(
                    <Link key='upgrade'
                          to={getLocation(routePaths.plans, location)}
                          className='upgrade'
                    >
                        <InfoIcon height={24} width={24} alt=''/>
                        <FormattedMessage {...messages.upgrade}/>
                    </Link>
                )
            }
        }
        const usernameWithinCharLimit = (userDetails.firstName && userDetails.firstName.length <= USERNAME_CHARS_DISPLAY_MAX);
        const displayOverflow = (useOverflow || !usernameWithinCharLimit || (isVNHeader && belowMd));

       // When the holding page is active for a specific user group, we want to hide the notification bell
       !holdingPageDisplayed &&
        buttonContent.push(
            <IconButton aria-label='notifications' role='button' ref={notificationButtonRef}
                        key='notifications' onClick={toggleNotifications} className='notifications' size='large'>
                <Badge badgeContent={notificationCount} className={classNames(badgeClass)}>
                    <NotificationIcon height={24} width={24} alt='' className='notificationIcon'/>
                </Badge>
            </IconButton>);

            if (displayOverflow) {
                userItems.unshift({label: userDetails.firstName, heading: true});
            }
            buttonContent.push(
                <DropDownMenu
                    key='UserAccountButton'
                    buttonId='userAccountDropdown'
                    buttonProps={{'aria-label': intl.formatMessage(messages.accountAriaLabel, {user: userDetails.firstName})}}
                    buttonLabel={userDetails.firstName}
                    buttonClassName={displayOverflow? 'userOverflow' : 'usernameButton'}
                    buttonClasses={{root: displayOverflow? null : 'usernameLabel'}}
                    arrowType={displayOverflow? null : 'filled'}
                    icon={displayOverflow? <OverflowIcon alt='' height={24} width={24}/> : null}
                    items={userItems}
                    staticButtonText
                    variant='block'
                    placement='bottom-end'
                    onClick={() => {
                            if (shouldUseResponsiveMenu(menu?.tabs.length)) {
                                dispatch(openAccountMenu());
                                return true;
                            }
                        }
                    }
                />
            );

    } else if (!loading) {

        logInActions.push(<NavButton key='signup'
                                      path={routePaths.plans}
                                      size='small' variant='contained' color='primary' className='signUpButton'>
            <FormattedMessage {...messages.signUp}/>
        </NavButton>);

        logInActions.push(<Button key='login'
                                   onClick={logIn}
                                   size='small' variant='outlined' color='primary'
                                   id="logInButton">
            <FormattedMessage {...messages.logIn}/>
        </Button>);
    }


    return (
        <>
            {userDetails ?
                <StyledUserButtons ref={userActionsRef}>
                    {buttonContent}
                </StyledUserButtons>
                :
                <StyledSignInButtons>
                    {logInActions}
                </StyledSignInButtons>
            }
        </>
    )
};

const UserDetailsForwarded = forwardRef(UserDetails);
UserDetailsForwarded.propTypes = {
    hideNotifications: PropTypes.func.isRequired,
    toggleNotifications: PropTypes.func.isRequired,
    notificationCount: PropTypes.number.isRequired,
    userDetails: PropTypes.object,
    useOverflow: PropTypes.bool,
    loading: PropTypes.bool,
    holdingPageDisplayed: PropTypes.bool,
    isVNHeader:  PropTypes.bool
};

export default UserDetailsForwarded;
