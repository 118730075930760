import React, {useEffect} from 'react';
import Header from '../../documentation/Header';
import Content from "../../documentation/Content";
import Typography from '@mui/material/Typography';
import {createUseStyles} from 'react-jss';
import getBaseStyles from './styles/support';
import {scrollHashIntoView} from './util/scroll';
import Document from '../../documentation/Document';
import {osSelectAndBuildFaq} from '../../../util/routes';
import { ExternalLink, withWidth } from 'omse-components';

const useStylesBase = createUseStyles(theme => getBaseStyles(theme));

export function OsSelectAndBuild(props) {
    const classesBase = useStylesBase(props);

    useEffect(() => {
        scrollHashIntoView();
    }, []);

    return (<Document>
        <Header><span id='top'>Support</span></Header>
        <Content classes={{content: classesBase.support}}>
            <Typography variant='h2'>FAQs: OS Select+Build</Typography>
            <Typography variant='body1'>
                FAQs relating to ordering OS National Geographic Database (OS NGD) data using OS Select+Build via the OS Data Hub can be found on our <ExternalLink type='generic' href={osSelectAndBuildFaq} message="OS NGD Documentation Platform" />.
            </Typography>
        </Content>
    </Document>);
}

export default withWidth()(OsSelectAndBuild);