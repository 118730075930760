import React from 'react';
import SavePolygonModal from "./SavePolygonModal";

const SAVE_POLYGON_TYPE = 'save';

export default function SavePolygonAction(map, layers, setCurrentAction) {
    return {
        type: SAVE_POLYGON_TYPE,
        component: <SavePolygonModal/>
    };
}

SavePolygonAction.type = SAVE_POLYGON_TYPE;