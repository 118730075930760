import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import {osColour} from 'omse-components';
import online1 from './agol/Online-1.png';
import online2 from './agol/Online-2.png';
import online3 from './agol/Online-3.png';
import online4 from './agol/Online-4.png';
import online5 from './agol/Online-5.png';
import online6 from './agol/Online-6.png';
import WMTSCompletionSummary from './WMTSCompletionSummary';
import PropTypes from 'prop-types';

const styles = {
    guide: {
        '& img': {
            border: `solid ${osColour.neutral.mist} 1px`
        }
    }
};

export function ArcGISOnline(props) {
    const {classes} = props;
    return <Fragment>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    To follow this guide you will need access to the ArcGIS Online service.
                </p>
            </Typography>
            <Typography variant='h2'>Integrating OS Maps API in ArcGIS Online</Typography>
            <Typography variant='body1' paragraph={true} component='div' className={classes.guide}>
                <ol>
                    <li>
                        <p>Login to ArcGIS Online. On the homepage click 'Map'.</p>
                        <p>You will see an online map which could differ from the one shown according to your account settings.</p>
                        <img src={online1} alt="ArcGIS Online showing initial map" />
                    </li>
                    <li>
                        <p>Select ’Add’ and then ’Add Layer from Web’.</p>
                        <img src={online2} alt="'Add' menu with 'Add Layer from Web' option selected" />
                    </li>
                    <li>
                        <p>In the next box, select from the drop down ’A WMTS OGC web service’.</p>
                        <p>Enter the URL containing the API key in the appropriate URL box.</p>
                        <img src={online3} alt="'Add Layer from Web' dialog with URL input field" />
                    </li>
                    <li>
                        <p>Once this has been entered, the ’Get Layers’ button will become active. Click this button and then a drop-down list of layers available from the OS Maps API service will appear.</p>
                        <img src={online4} alt="'Add Layer from Web' showing Layers options" />
                    </li>
                    <li>
                        <p>Select the layer you would like to show. Note that in the OS Maps API service, two differing projections are offered for some of the layers, British National Grid (27700) and Web Mercator (3857).</p>
                        <p>For some of the layers, the ’Use as BaseMap’ box needs to be ticked if the Co-ordinate Reference System (CRS) differs from WGS84, which is the default CRS. Click ’Add Layer’.</p>
                        <img src={online5} alt="Layer options with 'Use as BaseMap' checkbox" />
                    </li>
                    <li>
                        <p>The data should now appear in the map window.</p>
                        <img src={online6} alt="Map window displaying data" />
                    </li>
                </ol>
            </Typography>
            <WMTSCompletionSummary software="ArcGIS Online" />
    </Fragment>
}

ArcGISOnline.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ArcGISOnline);