import React, {Fragment} from 'react'
import PropTypes from "prop-types";

const ConditionalWrapWithClass = (props) => {
    if (props.className && props.className !== '') {
        return <div className={props.className}>{props.children}</div>
    }

    return <Fragment>{props.children}</Fragment>;
}

ConditionalWrapWithClass.propTypes = {
    className: PropTypes.string
}

export default ConditionalWrapWithClass;