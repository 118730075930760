import {createPolygonDrawingTool} from "./ol/tool";
import {drawStyle, expandDrawStyle} from "./ol/style";

const ADD_POLYGON_TYPE = 'addPolygon';

export default function AddPolygonAction (map, layers) {
    const {drawingLayer, currentPolygonLayer} = layers;

    const tool = createPolygonDrawingTool(drawingLayer, currentPolygonLayer ? expandDrawStyle : drawStyle);
    map.addInteraction(tool);

    return {
        type: ADD_POLYGON_TYPE,
        deactivate: () => {
            map.removeInteraction(tool);
        }
    }
}

AddPolygonAction.type = ADD_POLYGON_TYPE;
