import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {defineMessages, useIntl} from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';
import {createUseStyles} from 'react-jss';
import {Typography} from '@mui/material';
import EnergyAndInfrastructureOrgDetailsForm from "./EnergyAndInfrastructureOrgDetailsForm";
import UserIssueContactAdmin from '../common/UserIssueContactAdmin';
import UserIssuePlanDenied from '../common/UserIssuePlanDenied';
import FeatureCheck from "../../../components/FeatureCheck";
import features from '../../../../shared/features';
import routePaths, {getLocation, goToLandingPage, PSGA_COMMERCIAL_ENQUIRIES} from "../../../util/routes";
import {modules, Notification} from 'omse-components';
import {useHistory} from 'react-router';
import Error404 from "../../Error404";
import {CRM_ENERGY_AND_INFRASTRUCTURE_PLAN, USER_OPEN_DATA_PLAN} from '../../../../shared/plans';
import {messages as planTagMessages} from '../../../components/PlanTag';
import TitledSingleColWithButton from '../../../components/layout/TitledSingleColWithButton';

const {completeEnergyAndInfrastructureSetup} = modules.actions.user;
const componentName = 'EnergyAndInfrastructurePlanSetup';

export const messages = defineMessages({
    title: {
        id: componentName + '.title',
        defaultMessage: 'Energy And Infrastructure Plan details',
        description: 'Title for the premium plan dialog'
    },
    applyForPlan: {
        id: componentName + '.accept',
        defaultMessage: 'Get Energy And Infrastructure Plan',
        description: 'Title for submit button'
    },
    decline: {
        id: componentName + '.decline',
        defaultMessage: 'Get OS OpenData Plan',
        description: 'decline organisation button title'
    },
});

const useStyles = createUseStyles({
    loading: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center'
    }
});

export default function EnergyAndInfrastructurePlanSetup() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.current.result);
    const loading = useSelector(state => state.user.current.loading);
    const energyAndInfrastructureSetup = useSelector(state => state.user.energyAndInfrastructure);
    const {b2clogin} = useSelector(state => state.config.current.result);
    const [initialised, setInitialised] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const referrer = document.referrer;
    const intl = useIntl();

    // Some users should move on as soon as this page loads. If we have one of those then this effect takes care of
    // the navigation. This will only act when we have our first look at the user object, as the user object can
    // change later and we don't want to head off too soon.
    useEffect(() => {
        if(user && !initialised) {
            setInitialised(true);

            if(user.hasInvitation) {
                // If the user has an outstanding invitation direct them to it.
                const newLocation = getLocation(routePaths.invitation, history.location);
                history.replace(newLocation);
                return;
            }
            
            if (!user.newUser) {
                 // If an existing organisation member types in the address of this page then they need to move on
                 // to the dashboard.
                goToLandingPage(history);
            }
        }
    }, [user, history, energyAndInfrastructureSetup, initialised, setInitialised]);

    // When the action is successful we need to move on to the dashboard, but it can take a moment for
    // the updated user object to be available. We wait until that change has stabilised before we allow the navigation.
    useEffect(() => {
        if (energyAndInfrastructureSetup.result && user === energyAndInfrastructureSetup.result) {
            goToLandingPage(history);
        }
    }, [user, history, energyAndInfrastructureSetup]);

    function isOrgPlan(plan) {
        return user?.orgPlan === plan;
    }

    function isSignUp() {
        return referrer && referrer.includes(b2clogin);
    }

    function confirmOrganisation(orgName, jobTitle, phone) {
        dispatch(completeEnergyAndInfrastructureSetup(orgName, jobTitle, phone, true));
    }

    function declineOrganisation(orgName, jobTitle, phone) {
        dispatch(completeEnergyAndInfrastructureSetup(orgName, jobTitle, phone, false));
    }

    if (loading) {
        return <div className={classes.loading}>
            <CircularProgress size={32}/>
        </div>
    }

    // Only show the page directly after sign-up.
    if (!isSignUp()) {
        return <Error404/>;
    }

    // User does not have an org. For example a new DH user signed-up using the 'Existing E&I customer' 
    // button but wasn't in Dynamics, they arrived as an OpenData user and a null org.
    if (!user.org) {
        return <UserIssueContactAdmin noOrganisation={true} />;
    }

    // User is an OpenData user of an E&I org. This scenario could occur if the contact was found in 
    // Dynamics and they are associated with an E&I org but had dataHubAccess=false, so they 
    // were assigned an OpenData role.
    if (isOrgPlan(CRM_ENERGY_AND_INFRASTRUCTURE_PLAN) && user.plan === USER_OPEN_DATA_PLAN) {
        return <UserIssueContactAdmin notRegistered={true} />;
    }

    // User associated with an org which is not on the E&I plan. They may have used the 
    // 'Existing E&I customer' button, exist in Dynamics and are associated with a non-E&I org.
    if (user.org && !isOrgPlan(CRM_ENERGY_AND_INFRASTRUCTURE_PLAN)) {
        return <UserIssuePlanDenied title={messages.title} 
            orgName={user.org} 
            planName={intl.formatMessage(planTagMessages.eai)} 
            applyUrl={PSGA_COMMERCIAL_ENQUIRIES} 
        />;
    }

    return <FeatureCheck feature={features.EAI}>
        <TitledSingleColWithButton title={messages.title}>
            {energyAndInfrastructureSetup.error && 
                <Notification appearance='toast' variant='error'>
                    <Typography variant='body1'>
                        {energyAndInfrastructureSetup.error.httpResponse}
                    </Typography>
                </Notification>
            }
            <EnergyAndInfrastructureOrgDetailsForm 
                onAccept={confirmOrganisation}
                onDecline={declineOrganisation}
                acceptLabel={messages.applyForPlan}
                declineLabel={messages.decline}
                working={energyAndInfrastructureSetup.working}
            />
        </TitledSingleColWithButton>
    </FeatureCheck>;
}
