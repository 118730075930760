import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {DropDownMenu} from 'omse-components';
import {useSelector} from "react-redux";
import {Typography} from "@mui/material";
import useChangeSchemaVersion from "./featureVersions/useChangeSchemaVersion";

const messages = defineMessages({
    chooseVersion: {
        id: 'FeatureVersions.chooseVersion',
        description: 'Title for the schema selection',
        defaultMessage: 'Choose Schema Version:'
    },
    displayVersion: {
        id: 'FeatureVersions.displayVersion',
        description: 'Title for the schema selection',
        defaultMessage: 'Schema Version: {version}'
    }
});

function getDisplayVersion(version) {
    return version.split('.')[0];
}

export default function FeatureVersions({featureTypeId, versions, tryToCloseFilterPanel}) {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const featureTypes = useSelector(store => store.recipeEditor.featureTypes);
    const feature = featureTypes.find(f => f.featureTypeId === featureTypeId);
    const selectedVersion = feature?.featureTypeVersion;
    const [setSchemaVersion, dialog] = useChangeSchemaVersion(featureTypeId);

    const options = versions.map(version => ({ label: getDisplayVersion(version), value: version }));

    // Handle an open request from the select component. We can only allow it to open if the user does not have
    // any unsaved filters... if they are still editing then the main editor code will pop a dialog for them
    function onOpen() {
        tryToCloseFilterPanel(function() {
            setOpen(true);
        });
    }

    if(selectedVersion && versions.length > 1) {
        return <>
            <DropDownMenu
                items={options}
                value={selectedVersion}
                buttonId='selectSchemaVersion'
                buttonVariant='outlined'
                variant='block'
                label={intl.formatMessage(messages.chooseVersion)}
                onChange={item => setSchemaVersion(item.value)}
                menuControl={{
                    onClose: () => setOpen(false),
                    onOpen,
                    open
                }}
            />
            {dialog}
        </>;
    } else {
        return <Typography variant='body1'>
            <FormattedMessage {...messages.displayVersion} values={{
                version: getDisplayVersion(versions[0])
            }}/>
        </Typography>;
    }
}

FeatureVersions.propTypes = {
    featureTypeId: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(PropTypes.string).isRequired,
    tryToCloseFilterPanel: PropTypes.func
};
