import {defineMessages, FormattedMessage} from 'react-intl';
import { Typography } from '@mui/material';
import {createUseStyles} from 'react-jss';
import PropTypes from 'prop-types';
import {osColour} from 'omse-components';
import ProductDependencyMessage from './ProductDependencyMessage';

export const messages = defineMessages({
    productDependency: {
        id: 'ProductDependencyInfo.productDependency',
        defaultMessage: 'Product dependency',
        description: 'product dependency'
    }
});

const useStyles = createUseStyles(theme => ({
    container: {
        marginTop: theme.spacing(1),
        background: osColour.neutral.clouds,
        padding: theme.spacing(2),
        borderLeft: 'solid 4px ' + osColour.primary.berry,
        minWidth: 230,
        [theme.breakpoints.down('md')]: {
            maxWidth: 'revert'
        }
    }
}));

export default function ProductDependencyInfo({productId, productName, products}) {
    const classes = useStyles();
    if (!Array.isArray(products) || products.length === 0) {
        return null;
    }
    return <div className={classes.container}>
        <Typography variant='body2'>
            <FormattedMessage {...messages.productDependency} />
        </Typography>
        <ProductDependencyMessage productId={productId} productName={productName} products={products} />
    </div>;
}

ProductDependencyInfo.propTypes = {
    productId: PropTypes.string.isRequired, 
    productName: PropTypes.string.isRequired,
    products: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
}