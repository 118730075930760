import React, {Fragment} from 'react';
import SiteNotice from './SiteNotice';
import {useSelector} from 'react-redux';

export default function SiteNotices(props) {
    const {activeNotices, getContent} = props;
    const isActiveNotice = notice => activeNotices.indexOf(notice.id) !== -1;
    const appNotices = useSelector(state => state.notifications.status.result && state.notifications.status.result.app);
    if (!appNotices || appNotices.length === 0) {
        return null;
    }

    return <Fragment>
        {appNotices.filter(isActiveNotice).map(notice => <SiteNotice key={notice.id} content={getContent(notice)} variant={notice.level} />)}
    </Fragment>
}