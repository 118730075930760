import { useId } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { osColour } from "omse-components";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormattedMessage } from "react-intl";
import {
    Typography,
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
} from "@mui/material";

const AccordionBase = styled(MuiAccordion)(() => `
    border-radius: 0 !important;
    border: none;
    border-bottom: 1px solid ${osColour.primary.evenLighterBerry};
    &::before {
        background: none;
    };
`);

const AccordionSummary = styled(MuiAccordionSummary)(() => `
    margin: 0.4em 0;
    padding: 0.4em 0.6em 0.4em 0;
    border-radius: 4px;
    transition: padding 400ms;
    & > div:first-of-type {
        padding: 0.6em 0;
        color: ${osColour.neutral.charcoal};
    };
    & > div:last-of-type {
        border: 2px solid ${osColour.primary.berry};
        border-radius: 10em;
    };
    &:hover {
        padding: 0.4em 0.6em;
        background: ${osColour.neutral.clouds};
    };
    &:active {
        background: ${osColour.primary.lightestBerry};
    }
`);

const AccordionDetails = styled(MuiAccordionDetails, {shouldForwardProp: prop => prop !== 'hasBorder' })(({ hasBorder }) => `
    margin: 0 0 0.6em 0;
    padding: 0 0 0.6em 0;
    border-top: 1px solid ${osColour.primary.evenLighterBerry}80;
    ${(hasBorder) && `
        padding: 0 0em 0.6em 0em;
        border: 1px solid ${osColour.neutral.mist};
        border-radius: 2px;
    `}
`);

export default function Accordion(p) {
    const { title, openByDefault, showContentBorder, children } = p;
    const elementId = useId();
    return (
        <AccordionBase defaultExpanded={openByDefault} disableGutters={true}>
            <AccordionSummary
                id={elementId}
                aria-controls={`${elementId}-content`}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography variant="h3" component="h2">
                    <FormattedMessage {...title} />
                </Typography>
            </AccordionSummary>
            <AccordionDetails hasBorder={showContentBorder}>
                {children}
            </AccordionDetails>
        </AccordionBase>
    )
}

Accordion.defaultProps = {
    openByDefault: false,
    showContentBorder: false
}

Accordion.propTypes = {
    title: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string,
        description: PropTypes.string
    }),
    openByDefault: PropTypes.bool,
    showContentBorder: PropTypes.bool,
    children: PropTypes.element
}