import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AddButton, InputBox, contentPadding, LabelledRadioButton } from "omse-components";
import { defineMessages, FormattedMessage } from 'react-intl';
import Typography from "@mui/material/Typography";
import NavButton from "./NavButton";
import ClickAwayTooltip from "./ClickAwayTooltip";
import routePaths from "../util/routes";
import userConstants from "../constants/user"
import infoIcon from "./icons/info-notification.svg";
import { RadioGroup } from "@mui/material";
import styled from '@emotion/styled';

const messages = defineMessages({
    orgName: {
        id: 'OrgDetailsForm.orgName',
        defaultMessage: 'Organisation name / Company trading name *',
        description: 'Title for the org name input'
    },
    orgNameInfo: {
        id: 'OrgDetailsForm.orgNameInfo',
        defaultMessage: 'Signing up for a Premium Plan will make you the administrator for your organisation. {toolTipValue}',
        description: 'Information on importance of organisation name'
    },
    tooltipInfo: {
        id: 'OrgDetailsForm.tooltipInfo',
        defaultMessage: 'The main administrator can invite other people to sign up as part of their team. If you think someone else should be the administrator for your organisation you should ask them to sign up for a Premium Account before you do.',
        description: 'more info on becoming organisation admin'
    },
    jobTitle: {
        id: 'OrgDetailsForm.jobTitle',
        defaultMessage: 'Job title',
        description: 'Title for the job title input'
    },
    phone: {
        id: 'OrgDetailsForm.phone',
        defaultMessage: 'Your phone number',
        description: 'Title for the phone number input'
    },
    orgMissing: {
        id: 'OrgDetailsForm.orgMissing',
        defaultMessage: 'Please enter an organisation name.',
        description: 'Message shown when the org name is incomplete'
    },
    orgNameValidation: {
        id: 'OrgDetailsForm.orgNameValidation',
        defaultMessage: "The organisation name should consist of letters, numbers and these special characters ._#-$%",
        description: 'Message shown when the organisation name is invalid'
    },
    orgNameLength: {
        id: 'OrgDetailsForm.orgNameLength',
        defaultMessage: "The organisation name should be at most 100 characters long",
        description: 'Message shown when the organisation name is too long'
    },
    org: {
        id: 'OrgDetailsForm.org',
        defaultMessage: "I’m an organisation",
        description: 'Radio button title for org account type'
    },
    individual: {
        id: 'OrgDetailsForm.individual',
        defaultMessage: "I’m an individual",
        description: 'Radio button title for individual account type'
    }
});

const StyledContent = styled.div(({ theme }) => `
    max-width: 370px;
    flex: 1 0 auto;
    margin-left: auto;
    margin-right: auto;
    ${theme.breakpoints.down('sm')} {
        padding: ${contentPadding.mobile};
    }

    & .orgActionButtons {
        margin-top: ${theme.spacing(4)};
        margin-bottom: ${theme.spacing(2)};
        display: flex;
        justify-content: flex-end;
        & button:last-of-type {
            margin-left: ${theme.spacing(2)};
        }
    }

    & .orgNameInfo {
        height: auto;
        position: relative;
        margin: auto;
        margin-top: ${theme.spacing(1)};
    }

    & .infoIcon {
        width: 40px;
        display: table-cell;
        position: relative;
        ${theme.breakpoints.down('sm')} {
            padding-left: 15px;
        }
    }

    & .infoTextWrapper {
        width: calc(100% - 40px);
        display: table-cell;
        position: relative;
        vertical-align: top;
    }
`);

export default function OrgDetailsForm({ onSubmit, submitLabel, cancelLabel, working, showInfo }) {
    const userDetails = useSelector(state => state.user.current.result);

    const [modified, setModified] = useState(false);
    const [orgName, setOrgName] = useState(userDetails?.org || '');
    const [jobTitle, setJobTitle] = useState(userDetails?.jobTitle || '');
    const [phone, setPhone] = useState(userDetails?.telephoneNumber || '');
    const alreadyInAnOrg = userDetails?.org ? true : false;
    const [accountType, setAccountType] = useState('org');

    function upgrade() {
        onSubmit(accountType, orgName, jobTitle, phone);
    }

    function handleInput(setter) {
        return e => {
            setModified(true);
            setter(e.target.value);
        }
    }

    let orgError = null;
    let titleError = null;
    let phoneError = null;

    // Once any field has been modified then all of the fields are fair game for validation
    if (modified) {
        // Unmodified org names should not trigger an error, as if we have an invalid org name stored then the
        // user has no choice other than to live with it.
        if (!userDetails?.org && accountType === 'org') {
            if (orgName.length === 0) {
                orgError = messages.orgMissing;
            } else {
                if (orgName.length > 100) {
                    orgError = messages.orgNameLength;
                } else if (!userConstants.nameMatcher.test(orgName)) {
                    orgError = messages.orgNameValidation;
                }
            }
        }

        // Empty and unmodified job titles should not trigger an error. If we have an invalid title stored
        // then we'll live with it until the user starts editing that field.
        if (jobTitle !== '' && jobTitle !== userDetails?.jobTitle) {
            if (jobTitle.length > 100) {
                titleError = userConstants.messages.jobTitleLengthError;
            } else if (!userConstants.nameMatcher.test(jobTitle)) {
                titleError = userConstants.messages.jobTitleValidationError;
            }
        }

        // Empty and unmodified phone numbers should not trigger an error. If we have an invalid number stored
        // then we'll live with it until the user starts editing that field.
        if (phone !== '' && phone !== userDetails?.telephoneNumber) {
            if (phone.length > 30) {
                phoneError = userConstants.messages.phoneLengthError;
            } else if (!userConstants.phoneMatcher.test(phone)) {
                phoneError = userConstants.messages.phoneValidationError;
            }
        }
    }

    const ready = ((accountType === 'org' && orgName.length > 0 && !orgError) || (accountType === 'individual')) && !titleError && !phoneError;

    return (
        <StyledContent>
            {!alreadyInAnOrg &&
                <RadioGroup name='accountType'
                    value={accountType}
                    onChange={handleInput(setAccountType)}>
                    <LabelledRadioButton selectedOption={accountType}
                        option={'org'}
                        label={messages.org} />
                    <LabelledRadioButton selectedOption={accountType}
                        option={'individual'}
                        label={messages.individual} />
                </RadioGroup>
            }

            <InputBox id='OrgName'
                label={messages.orgName}
                error={orgError}
                value={orgName}
                fullWidth={true}
                disabled={alreadyInAnOrg || (accountType === 'individual')} // If there is an existing org name then we are not allowed to change it.
                onChange={handleInput(setOrgName)} />

            {showInfo &&
                <div className='orgNameInfo'>
                    <div className='infoIcon'>
                        <img src={infoIcon} alt='information icon' />
                    </div>
                    <div className='infoTextWrapper'>
                        <Typography variant='body1' component="div">
                            <ClickAwayTooltip id='orgNameTip'
                                body={messages.tooltipInfo}
                                label={messages.orgNameInfo}
                                toolTipValue='What does this mean?' />
                        </Typography>
                    </div>
                </div>
            }

            <InputBox id='JobTitle'
                label={messages.jobTitle}
                error={titleError}
                value={jobTitle}
                fullWidth={true}
                onChange={handleInput(setJobTitle)} />

            <InputBox id='Phone'
                label={messages.phone}
                error={phoneError}
                value={phone}
                fullWidth={true}
                onChange={handleInput(setPhone)} />

            <div className='orgActionButtons'>
                {cancelLabel &&
                    <NavButton path={routePaths.dashboard}
                        variant='outlined'
                        color='primary'>
                        <FormattedMessage {...cancelLabel} />
                    </NavButton>
                }
                <AddButton action={upgrade}
                    label={submitLabel}
                    working={working}
                    disabled={!ready}
                    showIcon={false}
                />
            </div>
        </StyledContent>
    );
}

OrgDetailsForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,      // Function to be called with accountType, orgName, jobTitle, phoneNumber
    cancelLabel: PropTypes.object,            // Label for the cancel button
    submitLabel: PropTypes.object.isRequired, // Label for the submit button
    working: PropTypes.bool,                  // Should be true while the submit function is working
    showInfo: PropTypes.bool.isRequired       // Show or hide the info messages about organisations and admins
};

OrgDetailsForm.defaultProps = {
    showInfo: true
};
