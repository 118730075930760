import React from 'react';
import PropTypes from 'prop-types';
import { CommonDialog } from 'omse-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Typography } from "@mui/material";

const messages = defineMessages({
    noChangeTitle: {
        id: 'NoChangeDialog.noChangeTitle',
        defaultMessage: 'Area has not changed',
        description: 'no change dialog title'
    },
    noChangeMessage: {
        id: 'NoChangeDialog.noChangeMessage',
        defaultMessage: 'The polygon you have drawn has not expanded the area. No change will be made.',
        description: 'no change dialog message'
    },
    noChangeTiledMessage: {
        id: 'NoChangeDialog.noChangeTiledMessage',
        defaultMessage: 'The polygon you have drawn has not expanded the tiled area. No change will be made.',
        description: 'no change dialog message'
    }
});

export default function NoChangeDialog(props) {
    const {onClose, tiled} = props;
    return <CommonDialog onClose={onClose} title={messages.noChangeTitle}>
        <Typography variant='body1'>
            {
                tiled ?
                    <FormattedMessage {...messages.noChangeTiledMessage}/> :
                    <FormattedMessage {...messages.noChangeMessage}/>
            }
        </Typography>
    </CommonDialog>;
}

NoChangeDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    tiled: PropTypes.bool
};
