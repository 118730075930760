import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import SetupPaymentDialog from "../SetupPaymentDialog";
import ClearPaymentDetailsDialog from "./ClearPaymentDetailsDialog";
import {defineMessages, FormattedMessage} from 'react-intl';
import { Typography, Button, Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import {createUseStyles} from 'react-jss';
import {linkButton, osColour} from 'omse-components';
import classNames from 'classnames';
import creditCardType from '../../../util/creditCardType';

const borderRadusSpacing = 0.5;
const styles = createUseStyles(theme => ({
    addButton: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(3)
    },
    table: {
        width: '100%',
        marginTop: theme.spacing(3),
        borderSpacing: 0,
        '& th': {
            textAlign: 'left',
            padding: theme.spacing(1)
        },
        '& tbody': {
        },
        '& tbody tr': {
            boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
            borderRadius: theme.spacing(borderRadusSpacing)
        },
        '& td': {
            verticalAlign: 'top',
            // this colour matches the menu
            backgroundColor: alpha(osColour.primary.berry, theme.palette.action.hoverOpacity),
            padding: theme.spacing(2,1,1,1)
        },
        '& td:first-of-type': {
            borderTopLeftRadius: theme.spacing(borderRadusSpacing),
            borderBottomLeftRadius: theme.spacing(borderRadusSpacing),
            borderLeft: `5px solid ${osColour.primary.berry}`
        },
        '& td:last-of-type': {
            borderTopRightRadius: theme.spacing(borderRadusSpacing),
            borderBottomRightRadius: theme.spacing(borderRadusSpacing),
            padding: theme.spacing(1)
        },
        '&.red td:first-of-type': {
            borderColor: osColour.status.error
        }
    },
    right: {
        textAlign: 'right'
    },
    linkButton: {
        ...linkButton,
        marginRight: theme.spacing(0.5)
    },
    remove: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    cardStatus: {
        marginTop: theme.spacing(1),
        color: osColour.status.error
    },
    topCardDetails: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        "& img": {
            marginRight: 20
        }
    }
}));

const messages = defineMessages({
    paymentDetails: {
        id: 'PaymentDetails.paymentDetails',
        defaultMessage: 'Payment card details',
        description: 'Payment details message'
    },
    setupPayment: {
        id: 'PaymentDetails.setupPayment',
        defaultMessage: 'Add a new payment card',
        description: 'Setup payment details message'
    },
    replace: {
        id: 'PaymentDetails.replace',
        defaultMessage: 'Replace',
        description: 'Label for the Replace button'
    },
    remove: {
        id: 'PaymentDetails.remove',
        defaultMessage: 'Remove your payment card details',
        description: 'Label for the Remove button'
    },
    removeHelp: {
        id: 'PaymentDetails.removeHelp',
        defaultMessage: '(your final balance will be settled)',
        description: 'Help text for the Remove button'
    },
    cardNumber: {
        id: 'PaymentDetails.cardNumber',
        defaultMessage: 'Your payment card',
        description: 'Label for the card number'
    },
    cardExpiry: {
        id: 'PaymentDetails.cardExpiry',
        defaultMessage: 'Expires',
        description: 'Label for the expiry date'
    },
    cardDueToExpire: {
        id: 'PaymentDetails.dueToExpire',
        defaultMessage: 'Card due to expire soon',
        description: 'Label for the dueToExpire'
    },
    cardExpired: {
        id: 'PaymentDetails.cardExpired',
        defaultMessage: 'Card expired',
        description: 'Label for the cardExpired'
    },
    PASSED: {
        id: 'PaymentDetails.PASSED',
        defaultMessage: 'This card is verified and active',
        description: 'Used when a payment card is active'
    },
    PENDING: {
        id: 'PaymentDetails.PENDING',
        defaultMessage: 'This card is in the process of being verified',
        description: 'Used when a payment card is pending verification'
    },
    FAILED: {
        id: 'PaymentDetails.FAILED',
        defaultMessage: 'This card was not verified - payment cannot be taken from it. Please replace it with another.',
        description: 'Used when a payment card is pending verification'
    },
    deleteRequested: {
        id: 'PaymentDetails.deleteRequested',
        defaultMessage: 'Scheduled for deletion',
        description: 'Used when a payment card is scheduled for deletion'
    }
});

function cardDetailsExpiryStatus(cardDetails) {
    const reply = {};
    reply.isCardDueToExpire = false;
    reply.isCardExpired = false;

    if (cardDetails && cardDetails.status === 'PASSED') {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const cardYear = cardDetails.cardExpiryDate.year;
        const cardMonth = cardDetails.cardExpiryDate.month;

        if (cardYear < year || (cardYear === year && cardMonth < month)) {
            reply.isCardExpired = true;
            return reply;
        }

        // current month is the expiry month
        if (year === cardYear && month === cardMonth) {
            reply.isCardDueToExpire = true;
        }
    }

    return reply;
}

function shouldHighlight(cardDetails, cardExpiryStatus) {
    return cardDetails.status === "FAILED" || cardDetails.deleteRequested || cardExpiryStatus.isCardDueToExpire || cardExpiryStatus.isCardExpired;
}

export default function PaymentDetails() {
    const [settingUp, setSettingUp] = useState(false);
    const [clearing, setClearing] = useState(false);
    const {loading, result} = useSelector(state => state.payments.status);
    const cardDetails = result && result.cardDetails;
    const cardExpiryStatus = cardDetailsExpiryStatus(cardDetails);

    const classes = styles();

    const cardStatusMessage = message => <Typography variant='body1' className={classes.cardStatus}>
        <FormattedMessage {...message} />
    </Typography>;

    let content;

    if(loading && !cardDetails) {
        // We don't have any details to display, but we might have very soon. We may need need the dialogs to still
        // be drawn, so we render those plus some null content.
        content = null;
    } else if(!cardDetails) {
        content = <Button color='primary'
                          variant='contained'
                          className={classes.addButton}
                          onClick={() => setSettingUp(true)}>
            <FormattedMessage {...messages.setupPayment} />
        </Button>;
    } else {
        const cardType = creditCardType(cardDetails.cardNumber);
        content = <Fragment>
            <table className={classNames(classes.table, {red: shouldHighlight(cardDetails, cardExpiryStatus)})}>
                <thead>
                <tr>
                    <th>
                        <Typography variant='body2'>
                            <FormattedMessage {...messages.cardNumber}/>
                        </Typography>
                    </th>
                    <th>
                        <Typography variant='body2'>
                            <FormattedMessage {...messages.cardExpiry}/>
                        </Typography>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <Box className={classes.topCardDetails}>
                            {cardType?.type && <img src={cardType.image} width={50} alt={`${cardType.displayName} card logo`}/>}
                            <Typography variant='body1'>
                                {cardType?.type && cardType.displayName.toUpperCase()} {cardDetails.cardNumber}
                            </Typography>
                        </Box>
                            {
                                messages[cardDetails.status] &&
                                !(cardDetails.status === "PASSED" && cardDetails.deleteRequested) &&
                                    cardStatusMessage(messages[cardDetails.status])
                            }
                            {
                                cardDetails.deleteRequested &&
                                    cardStatusMessage(messages.deleteRequested)
                            }
                            {
                                cardExpiryStatus.isCardDueToExpire &&
                                    cardStatusMessage(messages.cardDueToExpire)
                            }
                            {
                                cardExpiryStatus.isCardExpired &&
                                    cardStatusMessage(messages.cardExpired)
                            }
                    </td>
                    <td className={classes.top}>
                        <Typography variant='body1'>
                            {cardDetails.cardExpiryDate.month}
                            /
                            {cardDetails.cardExpiryDate.year}
                        </Typography>
                    </td>
                    <td className={classNames(classes.right, classes.topRight)}>
                        <Button color='primary'
                                variant='contained'
                                onClick={() => setSettingUp(true)}>
                            <FormattedMessage {...messages.replace} />
                        </Button>
                    </td>
                </tr>
                </tbody>
            </table>
            {!cardDetails.deleteRequested &&
                <div className={classes.remove}>
                    <Button component='span'
                    className={classes.linkButton}
                    onClick={() => setClearing(true)}>
                    <FormattedMessage {...messages.remove} />
                    </Button>
                    <Typography component='span' variant='body1'>
                    <FormattedMessage {...messages.removeHelp}/>
                    </Typography>
                </div>
            }
        </Fragment>
    }

    return <div>
        { content }
        {
            settingUp && <SetupPaymentDialog onClose={() => setSettingUp(false)}/>
        }
        {
            clearing && <ClearPaymentDetailsDialog onClose={() => setClearing(false)}/>
        }
    </div>;
}
