import expressionParser, {START_GROUP, END_GROUP} from "./expressionParser";

export default function treeBuilder(expression) {
    const result = {
        children: []
    };
    let parents = [];
    let currentNode = result;

    expressionParser(expression, event => {
        if(event.type === START_GROUP) {
            const newGroup = {
                children: []
            };
            currentNode.children.push(newGroup);
            parents.push(currentNode);
            currentNode = newGroup;

        } else if(event.type === END_GROUP) {
            currentNode = parents.pop();

        } else {
            currentNode.children.push(event);
        }
    });

    if(parents.length) {
        // We had unmatched nesting, which means the parsing has probably gone off the rails
        throw new Error("Parsing failed for expression: " + expression);
    }

    return result;
}