import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as OpenIcon } from "../../../components/icons/menu.svg";
import { ReactComponent as CloseIcon } from "../../../components/icons/close-large.svg";
import { openNavMenu, closeMenu } from "../../../modules/menu/actions";
import { osColour, customBreakpoints } from 'omse-components';
import IconButton from '@mui/material/IconButton';
import { navIcon } from '../../../util/responsive-navigation';
import styled from '@emotion/styled';

const StyledIconButton = styled(IconButton)(({ theme }) => `
    padding: ${theme.spacing(2)};
    cursor: pointer;
    z-index: 9;
    justify-content: center;
    align-items: center;
    color: ${osColour.neutral.stone};
    &:hover {
        background: transparent;
    }
    ${theme.breakpoints.down(customBreakpoints.mobile)} {
        padding-right: ${theme.spacing(1.5)};
    }
`);

export default function MenuIcon() {
    const menu = useSelector(state => state.menu);
    const dispatch = useDispatch();
    const isOpen = menu.nav;

    function onMenuItemClick() {
        if (isOpen) {
            dispatch(closeMenu());
        } else {
            dispatch(openNavMenu());
        }
    }

    function onKeyDown(e) {
        if (isOpen && e.keyCode === 27) {
            dispatch(closeMenu());
        }
    }

    return (
        <StyledIconButton className={navIcon}
            onClick={onMenuItemClick}
            onKeyDown={onKeyDown}
            aria-label="toggle navigation menu"
            disableTouchRipple
            size="large">
            {isOpen ? <CloseIcon alt='close icon' height={24} width={24} /> : <OpenIcon alt='open icon' height={24} width={24} />}
        </StyledIconButton>
    );
}