import logo from '../../../client/components/header/ordnance-survey-logo.svg';
import osSimpleLogo from '../../../client/components/header/ordnance-survey-simple-logo.svg';
import {Typography} from '@mui/material';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import React, {Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';
import routePaths, {getLocation} from '../../../client/util/routes';
import {BREAKPOINT_VALUES, customBreakpoints, osColour, isWidthDown, withWidth} from 'omse-components';
import {compose} from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {createUseStyles} from 'react-jss';

const messages = defineMessages({
    dataHubHome: {
        defaultMessage: 'Data Hub home',
        description: 'Title for the Data Hub link',
        id: 'DataHubLogo.dataHubHome'
    },
    osLogo: {
        defaultMessage: 'Ordnance Survey logo',
        description: 'Alt text for the OS logo',
        id: 'DataHubLogo.osLogo'
    },
    osLogoSimple: {
        defaultMessage: 'Ordnance Survey logo simple',
        description: 'Alt text for the simple OS logo',
        id: 'DataHubLogo.osLogoSimple'
    },
    dataHubCaption: {
        defaultMessage: 'Data Hub',
        description: 'Label placed close to the main OS logo in the app header',
        id: 'DataHubLogo.dataHubCaption'
    },
    vnCaption: {
        defaultMessage: 'Vernacular Names',
        description: 'Label placed close to the main OS logo in the app header',
        id: 'DataHubLogo.vnCaption'
    }
})

const useStyles = createUseStyles(theme => ({
    logo: {
        [theme.breakpoints.down(customBreakpoints.mobile)]: {
            width: 96,
            height: 24
        }
    },
    simpleLogo: {
        margin: 'auto',
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-3)
    },

    logoPanel: {
        display: 'inline-flex',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(2.5),
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        letterSpacing: '0.4px',
        justifyContent: 'center',
        '& h3': {
            color: osColour.primary.berry,
            fontFamily: 'OSGill'
        },
        '&:hover > h3': {
            color: osColour.primary.lighterBerry
        },
        '& > div': {
            display: 'flex',
            alignItems: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0
        },
        [theme.breakpoints.down(customBreakpoints.mobile)]: {
            display: 'flex',
            alignItems: 'center',
            flex: '0 0 auto'
        }
    },
    logoPanelNoLink: {
        cursor: 'default'
    },
    logoPanelHolingPage: {
        justifyContent: 'flex-start'
    },
    divider: {
        marginLeft: theme.spacing(2),
        width: 1,
        height: 32,
        backgroundColor: osColour.primary.berry,
        [theme.breakpoints.down(customBreakpoints.mobile)]: {
            marginLeft: theme.spacing(1.5),
            height: 24
        }
    },
    menuTitle: {
        margin: 'auto',
        marginLeft: theme.spacing(2),
        // adding h1 styling, while keeping the component as a semantic span
        fontFamily: 'OSGill, sans-serif',
        fontSize: '1.125rem', // 18/16 = 1.125
        fontWeight: 'normal',
        color: osColour.primary.berry,
        [theme.breakpoints.down(customBreakpoints.mobile)]: {
            marginLeft: theme.spacing(1.5),
            fontSize: '1rem'
        }
    },
    vnCaption: {
        margin: 'auto',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: (isVNMobileNavBar) => isVNMobileNavBar ? 0 : 'fit-content',
        maxWidth: (isVNMobileNavBar) => isVNMobileNavBar ? 0 : 'fit-content',
        ...theme.typography.body1,
        color: osColour.neutral.charcoal,
        fontSize: '1rem',
        visibility: (isVNMobileNavBar) => isVNMobileNavBar ? 'hidden' : 'visible'
    }
}))
const DataHubLogo = ({logoRef, vnMenu, location, intl, width, isLink, holdingPageDisplayed}) => {


    const dataHubHome = intl.formatMessage(messages.dataHubHome);
    const isVNMobileNavBar = vnMenu && isWidthDown('sm', width);
    const classes = useStyles(isVNMobileNavBar)

    const logoComponent = <div ref={logoRef}>
        {isVNMobileNavBar ?
            <img className={classes.simpleLogo} width={96} height={24} src={osSimpleLogo}
                 alt={intl.formatMessage(messages.osLogoSimple)}/> :
            <React.Fragment>
                <img className={classes.logo} width={128} height={32} src={logo} alt={intl.formatMessage(messages.osLogo)}/>
                <div className={classes.divider}/>
                <Typography className={classes.menuTitle} variant='body1' component='span'>
                    <FormattedMessage {...messages.dataHubCaption}/>
                </Typography>
            </React.Fragment>
        }
    </div>;

    return <Fragment>
        {isLink ?
            <Link className={classNames(classes.logoPanel, {[classes.logoPanelHolingPage]: holdingPageDisplayed})}
                  title={dataHubHome}
                  to={getLocation(routePaths.landing, location)}>
                {logoComponent}
            </Link> :
            //We don't link the home page when on the premium setup because we don't want them to be able to
            // navigate away and lose their premium flow context unless they specifically click cancel.
            <span className={classNames(classes.logoPanel, classes.logoPanelNoLink)}>
                {logoComponent}
            </span>
        }

        {vnMenu && <Typography className={classes.vnCaption} variant='h3' component='h1'>
            <FormattedMessage {...messages.vnCaption}/>
        </Typography>}
    </Fragment>
}

DataHubLogo.propTypes = {
    logoRef: PropTypes.any,
    vnMenu: PropTypes.bool,
    location: PropTypes.object,
    intl: PropTypes.any,
    classes: PropTypes.any,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(BREAKPOINT_VALUES)
    ]),
    isLink: PropTypes.bool,
    holdingPageDisplayed: PropTypes.bool
};


export default withRouter(compose(withWidth())(injectIntl(DataHubLogo)));
