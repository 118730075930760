import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import {osColour} from 'omse-components';
import arcmap1 from './arcmap/Map-1.png';
import arcmap2 from './arcmap/Map-2.png';
import arcmap3 from './arcmap/Map-3.png';
import arcmap4 from './arcmap/Map-4.png';
import arcmap5 from './arcmap/Map-5.png';
import arcmap6 from './arcmap/Map-6.png';
import WMTSCompletionSummary from './WMTSCompletionSummary';
import PropTypes from 'prop-types';

const styles = {
    guide: {
        '& img': {
            border: `solid ${osColour.neutral.mist} 1px`
        }
    }
};

export function ArcMap(props) {
    const {classes} = props;
    return <Fragment>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The examples shown use version 10.5 of ESRI ArcMap. It is expected that later versions will behave in a similar fashion as described below, along with some earlier versions. If in doubt, please contact your ESRI representative for information on compatibility.
                </p>
            </Typography>
            <Typography variant='h2'>Integrating OS Maps API in ArcMap</Typography>
            <Typography variant='body1' paragraph={true} component='div' className={classes.guide}>
                <ol>
                    <li>
                        <p>Start ArcMap. Select the Catalog tab and open the window. Expand the GIS Servers tab to allow access to the various servers which can be added into ArcMap to make a connection with the OS Maps service on the OS Data Hub.</p>
                        <img src={arcmap1} alt='Catalog tab showing GIS Servers expanded' />
                    </li>
                    <li>
                        <p>Double-click ’Add WMTS Server’.</p>
                        <img src={arcmap2} alt="'Add WMTS Server' dialog" />
                    </li>
                    <li>
                        <p>Enter the full OS Maps URL as seen in your OS Data Hub API project into the URL Box. No other information will be required. Click 'OK'.</p>
                    </li>
                    <li>
                        <p>The OS Maps API will now appear in the list of servers from which data can be added.</p>
                        <img src={arcmap3} alt="URL in list of servers" />
                    </li>
                    <li>
                        <p>Click ’Add Data’.</p>
                        <img src={arcmap4} alt="'Add Data' button" />
                    </li>
                    <li>
                        <p>In the resulting window, browse to the folder where the API is located and expand it. Various options are then available to add differing data layers to the map. Select the preferred option and then click ’Add’.</p>
                        <img src={arcmap5} alt="'Add Data' dialog" />
                    </li>
                    <li>
                        <p>The data should now appear in the map window. Use the zoom or set scale tools to view different scales of mapping available. As in ArcGIS Pro, the OS Maps service offers different options for the data to be viewed, either under British National Grid (27700) or Web Mercator (3857).</p>
                        <img src={arcmap6} alt="Map window showing the data" />
                    </li>
                    <li>
                        <p>You can save the project as an .MXD file when finished. It should not be necessary to add the service again.</p>
                    </li>
                </ol>
            </Typography>
            <WMTSCompletionSummary software="ArcMap" />
    </Fragment>
}

ArcMap.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string
}

export default withStyles(styles)(ArcMap);