import {
    createLoadingMiddleware,
    createLoadingReducer,
    createActionMiddleware,
    createActionReducer, ACTION_UPDATE_SUFFIX
} from 'omse-components';
import {
    LOAD_FEATURE_TYPE_SCHEMA_ACTION,
    LOAD_RECIPE_ACTION,
    LOAD_RECIPE_LIBRARY_ACTION,
    LOADED_FEATURE_TYPE_SCHEMA_ACTION,
    LOADED_RECIPE_ACTION,
    LOADED_RECIPE_LIBRARY_ACTION,
    SHARE_RECIPE_ACTION,
    HANDLE_RECIPE_INVITATION_ACTION,
    LOAD_SHARABLE_ORGS_ACTION,
    LOADED_SHARABLE_ORGS_ACTION,
    LOAD_DATASET_OPTIONS_ACTION,
    LOADED_DATASET_OPTIONS_ACTION, SET_RECIPE_NAME_ACTION, SET_RECIPE_DESCRIPTION_ACTION, DELETE_RECIPE_ACTION
} from './recipes/actions';
import {combineReducers} from 'redux';

export const recipesReducer = combineReducers({
    library: createLoadingReducer(LOAD_RECIPE_LIBRARY_ACTION, LOADED_RECIPE_LIBRARY_ACTION, true),
    recipe: createLoadingReducer(LOAD_RECIPE_ACTION, LOADED_RECIPE_ACTION, true),
    featureTypeSchema: createLoadingReducer(LOAD_FEATURE_TYPE_SCHEMA_ACTION, LOADED_FEATURE_TYPE_SCHEMA_ACTION, true),
    handleRecipeInvitation: createActionReducer(HANDLE_RECIPE_INVITATION_ACTION),
    shareableOrgs: createLoadingReducer(LOAD_SHARABLE_ORGS_ACTION, LOADED_SHARABLE_ORGS_ACTION, false,
        (action) => {
            if(action.result){
                action.result.prefix = action.originalAction.prefix;
            }
        }
    ),
    shareRecipe: createActionReducer(SHARE_RECIPE_ACTION),
    datasetOptions: createLoadingReducer(LOAD_DATASET_OPTIONS_ACTION, LOADED_DATASET_OPTIONS_ACTION, true),
    setName: createActionReducer(SET_RECIPE_NAME_ACTION),
    setDescription: createActionReducer(SET_RECIPE_DESCRIPTION_ACTION),
    deleteRecipe: createActionReducer(DELETE_RECIPE_ACTION)
});

export const recipesMiddleware = [
    createLoadingMiddleware(LOAD_RECIPE_LIBRARY_ACTION, LOADED_RECIPE_LIBRARY_ACTION, () => '/api/recipes'),
    createLoadingMiddleware(LOAD_RECIPE_ACTION, LOADED_RECIPE_ACTION, (action) => `/api/recipes/${action.id}`),
    createLoadingMiddleware(LOAD_FEATURE_TYPE_SCHEMA_ACTION, LOADED_FEATURE_TYPE_SCHEMA_ACTION,
        (action) => `/api/schema/featureTypes/${action.featureTypeId}/version/${action.version}/schema`),
    createActionMiddleware(SHARE_RECIPE_ACTION, shareRecipe, onHandleShareRecipeSuccess),
    createActionMiddleware(HANDLE_RECIPE_INVITATION_ACTION, prepareHandleRecipeInvitation, onHandleRecipeInvitationSuccess),
    createLoadingMiddleware(LOAD_SHARABLE_ORGS_ACTION, LOADED_SHARABLE_ORGS_ACTION, (action) => `/api/recipes/${action.recipeId}/shareableOrgs?prefix=${action.prefix}`, true),
    createLoadingMiddleware(LOAD_DATASET_OPTIONS_ACTION, LOADED_DATASET_OPTIONS_ACTION, datasetOptions),
    createActionMiddleware(SET_RECIPE_NAME_ACTION, setupSetRecipeName, successfullySetAttribute),
    createActionMiddleware(SET_RECIPE_DESCRIPTION_ACTION, setupSetRecipeDescription, successfullySetAttribute),
    createActionMiddleware(DELETE_RECIPE_ACTION, setupDeleteRecipe, successfullySetAttribute),
        store => next => action => {
        if(action.result === null && action.type === SHARE_RECIPE_ACTION+ACTION_UPDATE_SUFFIX){
            // We should suppress the empty result
            return;
        }
        next(action);
    }
];

function prepareHandleRecipeInvitation(action) {
    return {
        method: 'POST',
        url: '/api/recipes/' + action.recipeId + '/handleInvitation',
        body: {
            accept: action.accept
        }
    };
}

function onHandleRecipeInvitationSuccess(store, action) {
    const recipeId = action.recipeId;
    const currentRecipe = store.getState().recipes.recipe.result;
    if (currentRecipe.id === recipeId) {
        if (action.accept) {
            // The user accepted the recipe. They must still have access to it, but it should not look like an
            // invitation. We could re-load it, but it seems simpler to just remove the viewingInvitation flag.
            const newRecipe = {...currentRecipe};
            delete newRecipe.viewingInvitation;
            store.dispatch({type: LOADED_RECIPE_ACTION, result: newRecipe});
        } else {
            // They declined the invitation, so they should not be able to see the recipe any more
            // TODO: We could navigate to a new page here, or the UI component could do it... either way we should
            // clean up the recipe state
            store.dispatch({type: LOADED_RECIPE_ACTION, result: null});
        }
    }
}

function onHandleShareRecipeSuccess(store, action, result) {
    // A null result is a successful post
    if(result === null){
        store.dispatch({type: `${SHARE_RECIPE_ACTION}${ACTION_UPDATE_SUFFIX}`, result: action.selectedOrg, actionId: action.actionId});
    }
}

function shareRecipe(action) {
    return {
        url: `/api/recipes/${action.id}/share`,
        method: 'POST',
        body: {
            orgName: action.selectedOrg,
            message: action.message
        }
    };
}

function datasetOptions(action) {
    return {
        url: `/api/schema/datasetOptions`,
        method: 'POST',
        body: action.datasets
    };
}

function setupSetRecipeName(action) {
    return {
        url: '/api/recipes/' + action.recipeId,
        method: 'PUT',
        body: {
            name: action.name
        }
    };
}

function setupSetRecipeDescription(action) {
    return {
        url: '/api/recipes/' + action.recipeId,
        method: 'PUT',
        body: {
            description: action.description
        }
    };
}

function successfullySetAttribute(store, action, updatedRecipe) {
    const state = store.getState();

    store.dispatch({ type: LOADED_RECIPE_ACTION, result: updatedRecipe });

    const recipeList = state.recipes.library.result;
    if(recipeList) {
        const index = recipeList.findIndex(recipe => recipe.id === updatedRecipe.id);
        if(index !== -1) {
            const updatedList = recipeList.slice();
            if(updatedRecipe.deleted){
                updatedList.splice(index, 1);
            }else{
                updatedList[index] = updatedRecipe;
            }
            store.dispatch({ type: LOADED_RECIPE_LIBRARY_ACTION, result: updatedList });
        }
    }
}

function setupDeleteRecipe(action){
    return {
        url: '/api/recipes/' + action.recipeId,
        method: 'DELETE',
    };
}