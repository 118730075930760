import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme, CommonDialog, CommonDialogActions } from "omse-components";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Typography as MuiTypography } from "@mui/material";
import Link from "../../../components/Link";
import routePaths from "../../../util/routes";

const licenceRequiredMessages = defineMessages({
    title: {
        id: "PartnerLicenceRequiredDialog.title",
        defaultMessage: "Your licences",
        description: "Dialog > Title",
    },
    paragraph: {
        id: "PartnerLicenceRequiredDialog.paragraph",
        defaultMessage:
            "You have not yet licensed this product for use. {br}You can review your {link} any time.",
        description: "Dialog > Paragraph",
    },
    link: {
        id: "PartnerLicenceRequiredDialog.link",
        defaultMessage: "licensing options",
        description: "Dialog > Paragraph Link",
    },
    cancel: {
        id: "PartnerLicenceRequiredDialog.cancel",
        defaultMessage: "Cancel",
        description: "Dialog > Cancel Button",
    },
});

const Typography = styled(MuiTypography)`
    ${theme.breakpoints.up("md")} {
        min-width: 26em;
    }
`;

export default function PartnerLicenceRequiredDialog(props) {
    const { onClose, productId } = props;
    const intl = useIntl();
    const actions = (
        <CommonDialogActions
            showCancelButton={false}
            confirmLabel={intl.formatMessage(licenceRequiredMessages.cancel)}
            confirmLabelVariant="outlined"
            confirmAllowed={true}
            onConfirm={onClose}
        />
    );
    return (
        <CommonDialog onClose={onClose} actions={actions} title={licenceRequiredMessages.title}>
            <Typography variant="body1">
                <FormattedMessage
                    {...licenceRequiredMessages.paragraph}
                    values={{
                        br: <br />,
                        link: (
                            <Link path={routePaths.manageLicences} state={{ productId }}>
                                <FormattedMessage {...licenceRequiredMessages.link} />
                            </Link>
                        ),
                    }}
                />
            </Typography>
        </CommonDialog>
    );
}

PartnerLicenceRequiredDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    productId: PropTypes.string.isRequired,
};
