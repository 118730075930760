import React from "react";
import DataPackages from "./recentItems/DataPackages";
import APIProjects from "./recentItems/APIProjects";
import Recipes from "./recentItems/Recipes";
import { useSelector } from "react-redux";
import { USER_PREMIUM_DATA_PLAN, USER_PSGA_PLAN } from "../../../shared/plans";
import {
    hasPartnerCatalogue,
    hasShowNgdDownloadsPermission,
    hasShowPremiumDownloadsPermission,
    hasViewDataPackagesPermission,
} from "../../util/permissions";

function checkNgdDownloadsAccess(user) {
    if (user?.plan === USER_PSGA_PLAN) {
        return true;
    }
    if (user?.plan === USER_PREMIUM_DATA_PLAN) {
        return hasPartnerCatalogue(user)
            ? hasShowPremiumDownloadsPermission(user) &&
                  hasShowNgdDownloadsPermission(user) &&
                  hasViewDataPackagesPermission(user)
            : false;
    }
    return false;
}

export default function RecentItems() {
    const user = useSelector((state) => state.user.current.result);
    const userCanViewApiProjects = true;
    const userCanViewNgdDownloads = checkNgdDownloadsAccess(user);

    return (
        <section>
            {userCanViewApiProjects && <APIProjects />}
            {userCanViewNgdDownloads && (
                <>
                    <DataPackages />
                    <Recipes />
                </>
            )}
        </section>
    );
}
