import React from 'react';
import DataPackageDropdownField from './DataPackageDropdownField';
import PropTypes from 'prop-types';
import {defineMessages} from "react-intl";
import features from '../../../../../shared/features';
import useFeatureCheck from "../../../../util/useFeatureCheck";
import CatalogueScopedField from "./CatalogueScopedField";
import {COM3_CATALOGUE} from '../../../../../shared/catalogues';

export const messages = defineMessages({
    label: {
        id: 'TerminalsDropdown.label',
        defaultMessage: 'Number of terminals',
        description: 'Field label'
    },
    select: {
        id: 'TerminalsDropdown.select',
        defaultMessage: 'Select a number of terminals',
        description: 'Select label'
    },
    fieldError: {
        id: 'TerminalsDropdown.fieldError',
        defaultMessage: 'Please select a number of terminals',
        description: 'Required error message'
    }
})

export default function TerminalsDropdown({terminalsOptions}) {
    return (useFeatureCheck(features.EAI)  && terminalsOptions?.length > 0) ?
        <CatalogueScopedField catalogueId={COM3_CATALOGUE}>
            <DataPackageDropdownField
                options={terminalsOptions}
                fieldName='terminals'
                labelMessage={messages.label}
                selectMessage={messages.select}
                fieldErrorMessage={messages.fieldError}
            />
        </CatalogueScopedField> : null;
}

TerminalsDropdown.propTypes = {
    terminalsOptions: PropTypes.array
}
