import {createActionReducer, createActionMiddleware, createLoadingReducer, createLoadingMiddleware} from 'omse-components';
import {combineReducers} from 'redux';
import queryString from 'query-string';

const PREFIX = "action:polygon:";
const LOAD_SAVED_POLYGON_CATALOGUE = PREFIX + "loadSavedCatalogue";
const LOADED_SAVED_POLYGON_CATALOGUE = PREFIX + "loadedSavedCatalogue";
const LOAD_PREDEFINED_POLYGON_CATALOGUE = PREFIX + "loadPredefinedCatalogue";
const LOADED_PREDEFINED_POLYGON_CATALOGUE = PREFIX + "loadedPredefinedCatalogue";
const STORE_POLYGON = PREFIX + "store";
const LOAD_POLYGON = PREFIX + "load";
const LOADED_POLYGON = PREFIX + "loaded";

export function loadSavedPolygonCatalogue() {
    return {
        type: LOAD_SAVED_POLYGON_CATALOGUE
    }
}
export function loadPredefinedPolygonCatalogue() {
    return {
        type: LOAD_PREDEFINED_POLYGON_CATALOGUE
    }
}

export function loadPolygon(name, path) {
    return {
        type: LOAD_POLYGON,
        name,
        path
    }
}

export function clearLoadedPolygon() {
    return {
        type: LOADED_POLYGON,
        result: null
    }
}

export function storePolygon(id, geometry, etag) {
    return {
        type: STORE_POLYGON,
        id,
        geometry,
        etag
    }
}

export const polygonReducer = combineReducers({
    savedCatalogue: createLoadingReducer(LOAD_SAVED_POLYGON_CATALOGUE, LOADED_SAVED_POLYGON_CATALOGUE, true),
    predefinedCatalogue: createLoadingReducer(LOAD_PREDEFINED_POLYGON_CATALOGUE, LOADED_PREDEFINED_POLYGON_CATALOGUE, true),
    load: createLoadingReducer(LOAD_POLYGON, LOADED_POLYGON),
    store: createActionReducer(STORE_POLYGON)
});

export const polygonMiddleware = [
    createLoadingMiddleware(LOAD_SAVED_POLYGON_CATALOGUE, LOADED_SAVED_POLYGON_CATALOGUE, prepareGetSavedPolygonCatalogue),
    createLoadingMiddleware(LOAD_PREDEFINED_POLYGON_CATALOGUE, LOADED_PREDEFINED_POLYGON_CATALOGUE, prepareGetPredefinedPolygonCatalogue),
    createLoadingMiddleware(LOAD_POLYGON, LOADED_POLYGON, prepareLoadPolygon),
    createActionMiddleware(STORE_POLYGON, prepareStorePolygon)
];

function prepareGetSavedPolygonCatalogue() {
    return '/api/polygons/organisation/saved';
}
function prepareGetPredefinedPolygonCatalogue() {
    return '/api/polygons/predefined';
}

function prepareLoadPolygon(action) {
    if(action.path) {
        const qs = {
            name: action.name,
            path: action.path
        }

        return '/api/polygons/predefined?' + queryString.stringify(qs);
    }
    return '/api/polygons/organisation/saved/' + action.name;
}

function prepareStorePolygon(action) {
    return {
        url: '/api/polygons/organisation/saved',
        method: 'POST',
        body: {
            id: action.id,
            geometry: action.geometry,
            etag: action.etag
        }
    }
}