import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { osColour } from "omse-components";
import { FormattedMessage } from "react-intl";
import { Typography as MuiTypography } from "@mui/material";

const messages = {
	workspaceHeading: {
		id: "messages.workspaceHeading",
		defaultMessage: "Welcome back, {name}",
		description: "Workspace > Heading (h1) Content"
	},
	workspaceHeadingNewUser: {
		id: "messages.workspaceHeadingNewUser",
		defaultMessage: "Welcome, {name}",
		description: "Workspace > Heading (h1) Content for New Users"
	}
};

const Typography = styled(MuiTypography)(
	({ theme }) => `
    margin: 0.4em 0 1.2em 0;
    color: ${osColour.primary.berry};
    ${theme.breakpoints.down("md")} {
        margin: 0.4em 0 0.4em 0;
    }
`
);

export default function Heading() {
	const user = useSelector((state) => state?.user.current?.result);
	const isReturningUser = false; // to be implemented via opt-in logic later
	return (
		<Typography variant="h1">
			<FormattedMessage
				{...(isReturningUser ? messages.workspaceHeading : messages.workspaceHeadingNewUser)}
				values={{ name: user.firstName }}
			/>
		</Typography>
	);
}
