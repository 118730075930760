import searchText from './searchText';

/**
 * Return a filter function which will search candidate property names for text that matches search text.
 */
export default function buildFilter(textToSeachFor, propertyNames) {
    // Function that checks if a candidate matches the overall filter. We grab the values for each of
    // the property names, concatenate them, and then see if that matches all of the search parts
    return function filter(candidate) {
        const textToSearch = new Set();
        for(const name of propertyNames) {
            if(candidate[name]) {
                textToSearch.add(String(candidate[name]));
            }
        }
        return searchText(textToSeachFor, textToSearch);
    };
}