import {ACTION_RESET_SUFFIX} from "omse-components";

const prefix = "action:commercialApiPlans:";
export const LOAD_API_PLANS = prefix + "planload";
export const LOADED_API_PLANS = prefix + 'planloaded';
export const LOAD_API_PRODUCTS = prefix + "productsload";
export const LOADED_API_PRODUCTS = prefix + 'productsloaded';
export const PURCHASE_API_PLAN = prefix+'purchaseplan';
export const PURCHASE_API_PLAN_RESET = PURCHASE_API_PLAN + ACTION_RESET_SUFFIX;
export const LOAD_API_TIERS = prefix+'tiersload';
export const LOADED_API_TIERS = prefix+'tiersloaded';

export function purchaseApiPlan(tierId, openingBalance){
    return {
        type: PURCHASE_API_PLAN,
        tierId,
        openingBalance
    }
}

export function resetPurchaseApiPlan(){
    return {
        type:PURCHASE_API_PLAN_RESET
    }
}

export function getApiPlans(){
    return {
        type: LOAD_API_PLANS
    }
}

export function setApiPlans(){
    return {
        type: LOADED_API_PLANS
    }
}

export function getApiProducts(){
    return {
        type: LOAD_API_PRODUCTS
    }
}

export function setApiProducts(){
    return {
        type: LOADED_API_PRODUCTS
    }
}

export function getApiTiers(){
    return {
        type: LOAD_API_TIERS
    }
}
