import React from 'react';
import Typography from '@mui/material/Typography';
import {createUseStyles} from 'react-jss';
import getCommonStyles from '../../../styles/common';
import getBaseStyles from '../styles/support';
import FeatureIcon from '../../../../components/FeatureIcon';
import activityRoleCompare from '../data/activities';
import Table, {OUTLINE} from '../../../documentation/Table';
import {renderToStaticMarkup} from "react-dom/server";

const useStylesCommon = createUseStyles(getCommonStyles);
const useStylesBase = createUseStyles(getBaseStyles);

const useStyles = createUseStyles(theme => {
    return {
        tableContainer: {
            margin: theme.spacing(5,0,5,0)

        },
        roles: {
            '& th': {
                padding: theme.spacing(1,0.5),
                textAlign: 'left',
                fontWeight: 'normal'
            },
            '& thead th': {
                textAlign: 'center',
                fontWeight: 'bold',
                whiteSpace: 'normal',
                '&:first-of-type': {
                    textAlign: 'left'
                }
            },
            '& td': {
                width: '10%',
                padding: 0,
                '& div': {
                    display: 'flex',
                    justifyContent: 'center'
                }
            },
            '& caption': {
                textAlign: 'left',
                marginBottom: theme.spacing(1),
                '& h4': {
                    marginBottom: 0
                }
            }
        }
    }
});

export default function ActivityRoleTable(props) {
    const classesCommon = useStylesCommon(props);
    const classesBase = useStylesBase(props);
    const classes = useStyles(props);
    const {activities = activityRoleCompare} = props;
    const premiumOnlySuperscript = <sup className={classesCommon.superScriptColor}>1</sup>;
    const publicSectorOnlySuperscript = <sup className={classesCommon.superScriptColor}>2</sup>;
    const publicSectorOrPartnerOnlySuperscript = <sup className={classesCommon.superScriptColor}>3</sup>;
    const partnerOnlySuperscript = <sup className={classesCommon.superScriptColor}>4</sup>;
    const eaiOnlySuperscript = <sup className={classesCommon.superScriptColor}>5</sup>

    const returnTableData = type => {
        const tableData = [];
        let item;
        activities.map(activity => {
            const key = activity.id;
            if (key.includes(type)) {
                item = <tr key={JSON.stringify(key)}>
                    <th>
                        {activity.name}
                        {activity.publicSectorOnly && publicSectorOnlySuperscript}
                        {activity.publicSectorOrPartnerOnly && publicSectorOrPartnerOnlySuperscript}
                        {activity.partnerOnly && partnerOnlySuperscript}
                        {activity.eaiOnly && eaiOnlySuperscript}
                    </th>
                    <td className={classesBase.center}>
                        <div>
                            <FeatureIcon on={activity.admin}/>
                            {activity.premiumOnly && premiumOnlySuperscript}
                        </div>
                    </td>
                    <td className={classesBase.center}>
                        <div><FeatureIcon on={activity.financeUser}/></div>
                    </td>
                    <td className={classesBase.center}>
                        <div>
                            <FeatureIcon on={activity.user}/>
                            {activity.user && activity.premiumOnly && premiumOnlySuperscript}
                        </div>
                    </td>
                    <td className={classesBase.center}>
                        <div><FeatureIcon on={activity.contractor}/></div>
                    </td>
                    <td className={classesBase.center}>
                        <div><FeatureIcon on={activity.contractorRead}/></div>
                    </td>
                </tr>

                tableData.push(item);
            }
            return item;
        })
        return tableData;
    }

    const activityRoleTableHeader = firstCol => {
        const headers = [
            firstCol || 'Activity',
            'Admin User',
            <>Finance User&nbsp;{premiumOnlySuperscript}</>,
            'User',
            <>Contractor Read/write&nbsp;{publicSectorOnlySuperscript}</>,
            <>Contractor Read-only&nbsp;{publicSectorOnlySuperscript}</>
        ];
        return <thead>
            <tr>
                {headers.map(tHeadCol => (
                    <Typography variant='body1' component='th' key={renderToStaticMarkup(tHeadCol)}>
                        {tHeadCol}
                    </Typography>))
                }
            </tr>
        </thead>;
    }

    return <>
        <Table className={classes.roles} variant={OUTLINE} classes={{container: classes.tableContainer}}>
            <caption>
                <Typography variant='h4' color='primary'>
                    Permissions available to OS Data Hub roles
                </Typography>
            </caption>
            {activityRoleTableHeader()}
            <tbody>
                {returnTableData('permission:')}
            </tbody>
        </Table>
        <Table className={classes.roles} variant={OUTLINE}>
            <caption>
                <Typography variant='h4' color='primary'>
                    Notifications received by OS Data Hub roles
                </Typography>
            </caption>
            {activityRoleTableHeader('Notification')}
            <tbody>
                {returnTableData('notification:')}
            </tbody>
        </Table>
        <p id="premiumOnly">{premiumOnlySuperscript} Only available for Premium Plan organisations</p>
        <p id="publicSectorOnly">{publicSectorOnlySuperscript} Only for Public Sector Plan organisations</p>
        <p id="publicSectorOrPartnerOnly">{publicSectorOrPartnerOnlySuperscript} Only for Public Sector Plan organisations, or organisations with a Licensed Partner agreement with Ordnance Survey, or Energy and Infrastructure Plan</p>
        <p id="partnerOnly">{partnerOnlySuperscript} Only for organisations with a Licensed Partner agreement with Ordnance Survey</p>
        <p id="eaionly">{eaiOnlySuperscript} Only available to Energy and Infrastructure Customers</p>
    </>
}
