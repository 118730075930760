import React, {useEffect} from 'react';
import {defineMessages} from 'react-intl';
import { ConfirmationDialog, ExternalLink } from 'omse-components';
import useActionIdSelector from "../../../hooks/useActionIdSelector";
import { clearPaymentDetails } from "../../../modules/payments/actions";

const messages = defineMessages({
    title: {
        id: 'ClearPaymentDetailsDialog.title',
        defaultMessage: 'Downgrade your service?',
        description: 'Title for the dialog'
    },
    clearPaymentMessage1: {
        id: 'ClearPaymentDetailsDialog.clearPaymentMessage1',
        defaultMessage: 'If you remove your card details we will not serve you Premium API data once you’ve used your free £1,000 monthly limit.',
        description: 'Text inside the delete card dialog'
    },
    clearPaymentMessage2: {
        id: 'ClearPaymentDetailsDialog.clearPaymentMessage2',
        defaultMessage: 'If you wish to use a different payment card, cancel this box and use the "Change payment details" button instead.',
        description: 'Text inside the delete card dialog'
    },
    errorMessage: {
        id: 'ClearPaymentDetailsDialog.errorMessage',
        defaultMessage: 'There was a problem clearing your payment details.',
        description: 'Feedback to the user if the cancellation fails'
    },
    errorMessage2: {
        id: 'ClearPaymentDetailsDialog.errorMessage2',
        defaultMessage: 'Please retry the action, and if the problem persists then please {link}.',
        description: 'Next steps for the user if the cancellation fails'
    },
    confirm: {
        id: 'ClearPaymentDetailsDialog.confirm',
        defaultMessage: 'Remove my card details',
        description: 'Label for the clear payment details button'
    }
});

export default function ClearPaymentDetailsDialog({onClose}) {
    const [{working, result, error}, dispatch] = useActionIdSelector("payments.clearPaymentDetails");

    useEffect(() => {
        if(result !== undefined) {
            onClose();
        }
    });

    let dialogMessages = [messages.clearPaymentMessage1, messages.clearPaymentMessage2];
    if(error) {
        dialogMessages = [
            messages.errorMessage,
            {
                ...messages.errorMessage2, values: { link: <ExternalLink type='support' /> }
            }

    ];
    }

    return <ConfirmationDialog title={messages.title}
                               contentMessages={dialogMessages}
                               confirmationButtonLabel={messages.confirm}
                               confirmationAction={() => dispatch(clearPaymentDetails())}
                               handleClose={onClose}
                               working={working}
    />
}
