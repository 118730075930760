import {createActionMiddleware, createActionReducer} from 'omse-components';
import {UPDATE_NOTIFICATION, LOADED_NOTIFICATIONS_ACTION, getNotificationStatus} from "./actions";

export const reducer = createActionReducer(UPDATE_NOTIFICATION);

function setupCall(action) {
    return {
        url: `/api/notifications/${action.id}`,
        method: 'POST',
        body: {...action.props}
    };
}

function success(store, action, result) {
    const state = store.getState();
    const {notifications} = state;
    const newItems = [...notifications.current.result];
    result.forEach(update => {
        const item = newItems.find(item => item.id === update.id || (item.type === 'service' && item.id === update.serviceId));
        if (item) {
            item.read = update.read;
            item.working = false;
            if (item.type === 'service') {
                item.type = update.type;
                item.id = update.id;
            }
        }
    });
    store.dispatch({type: LOADED_NOTIFICATIONS_ACTION, result: newItems});
    store.dispatch(getNotificationStatus());
}

export const middleware = createActionMiddleware(UPDATE_NOTIFICATION, setupCall, success);