import buildFilter from './buildFilter';

// When searching the polygon library there are 3 cases that we need to handle.
// Saved polygons: { id, _etag }
// Predefined polygons: a simple string, which we map to { id } before using
// Data Packages: { id, name }
//
// As we recurse through the library we build up a result list, where each result looks like:
// { path, id, name, _etag }

const MAX_RESULTS = 30;

export default function polygonCollectionSearch(searchText, catalogue) {
    if(!searchText) {
        return null;
    }
    const filter = buildPolygonCollectionFilter(searchText);
    return findMatches(catalogue, filter, null, []);
}

function findMatches(collection, filter, path, result) {
    if(collection.collections) {
        // We have a collection that contains nested collections. Search each of them, until we reach the result limit.
        const newPath = buildPath(path, collection);
        let i = 0;
        while(i < collection.collections.length && result.length < MAX_RESULTS) {
            findMatches(collection.collections[i++], filter, newPath, result);
        }

    } else if(collection.files) {
        // We have a collection that contains files.
        const newPath = buildPath(path, collection);
        let i = 0;
        while(i < collection.files.length && result.length < MAX_RESULTS) {
            let file = collection.files[i++];
            if(typeof file === "string") {
                file = {
                    id: file
                };
            }
            if(filter(file)) {
                result.push({
                    ...file,
                    path: newPath
                });
            }
        }
    }

    return result;
}

function buildPath(path, collection) {
    let collectionPath = collection.path;
    let newPath;
    if(path) {
        newPath = path + '/' + collectionPath;
    } else {
        newPath = collectionPath;
    }
    return newPath;
}


function buildPolygonCollectionFilter(searchText) {
    return buildFilter(searchText, ['name', 'id']);
}

// Function to check if two files are equal. As the search builds up new objects for some of the results we cannot
// simply use ===, so we have this as an alternative.
export function fileMatches(file1, file2) {
    return (file1 === file2) ||
        (file1 !== null && file2 !== null && file1.id === file2.id && file1.path === file2.path);
}
