import { useEffect, useState } from "react";
import { COOKIE_KEYS } from "../constants/constants";
import { findRoute, getLocation } from "../util/routes";
import { useLocation, useHistory} from "react-router-dom";
import { useDispatch } from "react-redux";
import { reloadTabs } from "../modules/menu/actions";
import Cookies from 'js-cookie'
/**
 * Checks if the user Workspace is enabled by reading from cookies
 * Sometimes we need to access this outside outside of React components i.e. menus etc.
 * @returns {boolean} True if the workspace is enabled, false otherwise.
 */

export function userHasEnabledWorkspace(id) {
    return Cookies.get(`${COOKIE_KEYS.workspaceOptIn}_${id}`) === "true"
}

/**
 * A React hook to manage the state of user Workspace enabling.
 * @returns {{ workspaceEnabled: boolean, toggleWorkspace: () => void }}
 */

export default function useTryWorkspace(userId) {
    const initialState = userId && userHasEnabledWorkspace(userId)
    const [workspaceEnabled, setWorkspaceEnabled] = useState()
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (initialState !== undefined) {
            setWorkspaceEnabled(initialState);
        }
    }, [initialState])

    useEffect(() => {
        if (initialState !== undefined && userId !== undefined && workspaceEnabled !== undefined) {
            Cookies.set(
                `${COOKIE_KEYS.workspaceOptIn}_${userId}`,
                workspaceEnabled,
                { expires: 365, secure: true}
            );
        }
    }, [workspaceEnabled, userId, initialState])

    const handlePageLocation = () => {
        const timeoutId = setTimeout(() => {
            let newLocation;
            if (workspaceEnabled) {
                if(location.pathname === findRoute('workspace')?.path) {
                    newLocation = getLocation(findRoute('landing')?.path, location, { workspacefeedback: true }, {});
                } else {
                    newLocation = getLocation(location.pathname, location, { workspacefeedback: true },{})
                }
            } else {
                newLocation = getLocation(findRoute('workspace')?.path, location, { welcome: true }, {});
            }
            if (newLocation) {
                history.replace(newLocation);
                dispatch(reloadTabs());
            }
        }, 300);
        return () => clearTimeout(timeoutId);
    };

    const toggleWorkspace = () => {
        setWorkspaceEnabled((prev) => !prev);
        handlePageLocation();
    };

    return { workspaceEnabled, toggleWorkspace };
}
