import {createHoverTool, createModifyTool, createSelectTool} from "./ol/tool";
import {clipToLayer, multiPolygonToPolygons, removeInternalCoords} from "./ol/feature";
import {addFeatureToSource} from "./ol/layer";
import { throttle } from 'lodash';
import {highlightedPolygonStyle, highlightedExpandPolygonStyle, clickedExpandPolygonStyle, clickedPolygonStyle} from "./ol/style";

const SELECT_AND_MODIFY_ACTION_TYPE = 'selectAndModify';

const LISTENER_WAIT_IN_MS = 250;

export default function SelectAndModifyAction(map, layers, refreshSelectedTiles, updateDraftOrderAOI) {
    const {drawingLayer, currentPolygonLayer, tileLayer} = layers;
    const source = drawingLayer.getSource();

    let hover, modify, selectedFeature;

    function addHover(features) {
        if(hover) {
            map.removeInteraction(hover);
        }
        hover = createHoverTool(drawingLayer, features, currentPolygonLayer ? highlightedExpandPolygonStyle : highlightedPolygonStyle);
        hover.set("name", "hover");
        map.addInteraction(hover);
    }

    function addModify(source, feature) {
        selectedFeature = feature;
        // replace the hover with one only works with the unselected features
        addHover(source.getFeatures().filter(f => f !== feature));

        if(modify) {
            map.removeInteraction(modify);
        }
        modify = createModifyTool(feature);
        modify.set("name", "modify");
        map.addInteraction(modify);

        const refreshSelectedTilesListener = throttle(() => refreshSelectedTiles(), LISTENER_WAIT_IN_MS, {trailing: false});

        if (tileLayer) {
            modify.on('modifystart', function () {
                source.on('change', refreshSelectedTilesListener);
            });
        }

        modify.on('modifyend', function () {
            if (tileLayer) {
                refreshSelectedTiles();
                source.un('change', refreshSelectedTilesListener);
            }

            const cleaned = removeInternalCoords(feature);
            if(cleaned) {
                feature.setGeometry(cleaned.getGeometry());
                addFeatureToSource(drawingLayer.getSource(), feature);

                if (currentPolygonLayer) {
                    clipToLayer(feature, currentPolygonLayer);
                }
            } else {
                // The edit managed to break the geometry, so remove it from the map
                source.removeFeature(feature);
            }

            updateDraftOrderAOI();
        });
    }

    // Setup the first hover tool
    addHover(source.getFeatures());

    const select = createSelectTool(drawingLayer, currentPolygonLayer ? clickedExpandPolygonStyle : clickedPolygonStyle);
    select.set("name", "select");
    map.addInteraction(select);

    function featureToMultiPolygon(feature) {
        const source = drawingLayer.getSource();
        if (feature && feature.getGeometry().getType() === 'MultiPolygon' && source.hasFeature(feature)) {
            source.removeFeature(feature);
            source.addFeatures(multiPolygonToPolygons(feature));
        }
    }

    function selectListener(event) {
        if (event.selected.length > 0) {
            addModify(source, event.selected[0]);
        } else {
            if (selectedFeature) {
                featureToMultiPolygon(selectedFeature);
                selectedFeature = null;
            }

            // Nothing is selected, so reset the hover and modify tools
            if(modify) {
                map.removeInteraction(modify);
                modify = null;
            }
            addHover();
        }
    }
    select.on('select', selectListener);

    return {
        type: SELECT_AND_MODIFY_ACTION_TYPE,
        deactivate: () => {
            if (selectedFeature) {
                featureToMultiPolygon(selectedFeature);
                selectedFeature = null;
            }

            if(modify) {
                map.removeInteraction(modify);
            }
            map.removeInteraction(hover);
            map.removeInteraction(select);
        }
    };
}

SelectAndModifyAction.type = SELECT_AND_MODIFY_ACTION_TYPE;