import React, {Fragment} from 'react';
import {defineMessages, FormattedMessage} from "react-intl";
import {logOutLink} from "../../../util/routes";

const messages = defineMessages({
    loggedInAs: {
        id: 'SupportNotices.loggedInAs',
        defaultMessage: 'OS Support{readOnly, select, true { (read only)} other {}}: You are logged in as another user.',
        description: 'loggedInAs'
    },
    logOut: {
        id: 'SupportNotices.logOut',
        defaultMessage: 'Log out.',
        description: 'log out link'
    }
});

function noticeContent(message, values = {}, path, linkMessage) {
    return <Fragment>
        {message &&
            <span>
                <FormattedMessage {...message} values={values} />
            </span>
        }
        {(path && linkMessage) &&
            <a href={path}>
                <FormattedMessage {...linkMessage} />
            </a>
        }
    </Fragment>
}

export const activeNotices = ['Support.LoggedInAs'];

export const getContent = notice => {
    let message, values;
    if (notice.id === 'Support.LoggedInAs') {
        message = messages.loggedInAs;
        if (notice.values) {
            values = notice.values
        }
    }
    return noticeContent(message, values, logOutLink, messages.logOut);
}