import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {useLocation} from 'react-router';
import {Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {styles} from "../DownloadStyles";
import ProductContractTable from "./ProductContractTable";
import {useDispatch, useSelector} from 'react-redux';
import {loadPartnerContractCatalogue, loadPartnerContracts} from "../../../modules/partner/actions";
import CircularProgress from '@mui/material/CircularProgress';
import SearchBox from "../../../components/SearchBox";
import { Notification, ExternalLink } from 'omse-components';
import buildFilter from "../../../util/search/productFilter";

const useStyles = createUseStyles(styles);

const messages = defineMessages({
    title: {
        id: 'ManageLicences.title',
        defaultMessage: 'Manage product licences',
        description: 'Title for the manage licences'
    },
    youMustAgree: {
        id: 'ManageLicences.youMustAgree',
        defaultMessage: 'You must agree to a licence for specific use cases before you download and use OS data.',
        description: 'you must agree text'
    },
    listOf: {
        id: 'ManageLicences.listOf',
        defaultMessage: 'List of product licences',
        description: 'list of text'
    },
    searchLabel: {
        id: 'ManageLicences.searchLabel',
        defaultMessage: 'Find a product to license',
        description: 'Label for licence search'
    },
    searchPlaceholder: {
        id: 'ManageLicences.searchPlaceholder',
        defaultMessage: 'Search by product name',
        description: 'Placeholder for licences search'
    },
    noResults: {
        id: 'ManageLicences.noResults',
        defaultMessage: 'No products match your search',
        description: 'Label for no results'
    },
    error: {
        id: 'ManageLicences.error',
        defaultMessage: 'There was a problem loading the licence state. Please retry and if the problem persists then {link}.',
        description: 'Label shown if there is an error loading the catalogue or the current licence state'
    },
    royaltyCalculator: {
        id: 'ManageLicences.royaltyCalculator',
        defaultMessage : 'View the royalty calculators',
        description: 'Link label for licence royalty calculator'
    }
});

const myStyles = createUseStyles(theme => ({
    loading: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    search: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    links: {
        marginBottom: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2)
        }
    }
}));

export default function ManageLicences() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const myClasses = myStyles();
    const location = useLocation();
    const productId = location.state && location.state.productId;
    const royaltyCalculatorHref = "https://www.ordnancesurvey.co.uk/partner-portal/royalty-calculator";

    const [search, setSearch] = useState('');

    const contracts = useSelector(state => state.partners.contracts.result);
    const catalogue = useSelector(state => state.partners.catalogue.result);
    const error = useSelector(state => state.partners.contracts.error || state.partners.catalogue.error);

    const loading = !(contracts && catalogue);

    useEffect(() => {
        if (productId && catalogue) {
            const product = catalogue.products.find(p => p.id === productId);
            if (product) {
                setSearch(product.label);
            }
        }
    }, [productId, catalogue]);

    useEffect(() => {
        dispatch(loadPartnerContracts());
        dispatch(loadPartnerContractCatalogue());
    }, [dispatch])

    const searchResults = useMemo(() => {
        if (catalogue && contracts) {
            const thirdPartyProducts = catalogue.products.filter(p => p.thirdParty).filter(p => {
                return Object.keys(contracts).find(contractId => {
                    let bmis = contracts[contractId].bmis;
                    return !!bmis[p.bmiCode];
                });
            });

            const products = catalogue.products.filter(p => p.thirdParty === false || thirdPartyProducts.includes(p));

            if (search) {
                return products.filter(buildFilter(search));
            }
            return products;
        }
    }, [search, catalogue, contracts]);

    let content;
    if(error) {
        content = <Notification variant='error' appearance='inline' children={
            <Typography variant='body1'>
                <FormattedMessage {...messages.error} values={{ link: <ExternalLink type='support' /> }} />
            </Typography>
        }/>;
    } else {
        if(loading) {
            content = <CircularProgress size={32} className={myClasses.loading}/>;
        } else {
            content = <ProductContractTable filteredProducts={searchResults}/>;
        }
    }

    return <Fragment>
        <div className={classes.root}>
            <Typography variant='h1' color='primary'>
                <FormattedMessage {...messages.title}/>
            </Typography>
            <Typography variant='body1'>
                <FormattedMessage {...messages.youMustAgree}/>
            </Typography>
        </div>
        <div className={classes.widerContentWithMobilePadding}>
            <Typography variant='h2'>
                <FormattedMessage {...messages.listOf}/>
            </Typography>
            <div className={myClasses.search}>
                <SearchBox label={messages.searchLabel}
                           placeholder={messages.searchPlaceholder}
                           setSearch={setSearch}
                           search={search}
                           className={classes.searchOption}
                />
                <div className={classes.links}>
                    {royaltyCalculatorHref && (
                        <ExternalLink
                            type="generic"
                            href={royaltyCalculatorHref}
                            message={messages.royaltyCalculator}
                        />
                    )}
                </div>
            </div>

            {content}

            {!loading && searchResults && searchResults.length === 0 &&
                <Notification variant='info'
                              appearance='inline'
                              onClose={() => setSearch('')}
                              classes={{root: classes.notification}}>
                    <Typography variant='body1'>
                        <FormattedMessage {...messages.noResults}/>
                    </Typography>
                </Notification>
            }
        </div>
    </Fragment>
}