const PREFIX = 'action:notifications:';
export const LOAD_NOTIFICATIONS_ACTION = PREFIX + 'load';
export const LOADED_NOTIFICATIONS_ACTION = PREFIX + 'loaded';
export const UPDATE_NOTIFICATION = PREFIX + 'update';
export const LOAD_NOTIFICATIONS_STATUS_ACTION = PREFIX + 'status';
export const LOADED_NOTIFICATIONS_STATUS_ACTION = PREFIX + 'status:loaded';

export function getNotifications() {
    return {
        type: LOAD_NOTIFICATIONS_ACTION
    }
}

export function getAllNotifications() {
    return {
        type: LOAD_NOTIFICATIONS_ACTION,
        filter: 'all'
    }
}

export function updateNotification(id, props) {
    return {
        type: UPDATE_NOTIFICATION,
        id,
        props
    }
}

export function updateAllNotifications() {
    return {
        type: UPDATE_NOTIFICATION,
        id: 'all'
    }
}

export function getNotificationStatus() {
    return {
        type: LOAD_NOTIFICATIONS_STATUS_ACTION
    }
}