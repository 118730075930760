import {createLoadingReducer, createLoadingMiddleware} from 'omse-components';
import {LOAD_CATALOGUE_ACTION, LOADED_CATALOGUE_ACTION, LOAD_PRODUCT_ACTION, LOADED_PRODUCT_ACTION, LOAD_DOWNLOADS_ACTION, LOADED_DOWNLOADS_ACTION} from "./downloads/actions";
import {combineReducers} from 'redux';

export const downloadsReducer = combineReducers({
    catalogue: createLoadingReducer(LOAD_CATALOGUE_ACTION, LOADED_CATALOGUE_ACTION, true, reformat),
    product: createLoadingReducer(LOAD_PRODUCT_ACTION, LOADED_PRODUCT_ACTION, true, reformatProduct),
    downloads: createLoadingReducer(LOAD_DOWNLOADS_ACTION, LOADED_DOWNLOADS_ACTION, true, reformatDownloads)
});

function prepareCall(action, store) {
    const { downloadsUrl } = store.getState().config.current.result;
    let result;

    switch(action.type) {
        case LOAD_CATALOGUE_ACTION:
            result = downloadsUrl + '/products?expanded';
            break;
        case LOAD_PRODUCT_ACTION:
            result = downloadsUrl + '/products/' + action.id;
            break;
        case LOAD_DOWNLOADS_ACTION:
            result = downloadsUrl + '/products/' + action.id + '/downloads';
            break;
        default:
            // This should never happen
    }

    return result;
}

export const downloadsMiddleware = [
    createLoadingMiddleware(LOAD_CATALOGUE_ACTION, LOADED_CATALOGUE_ACTION, prepareCall, true),
    createLoadingMiddleware(LOAD_PRODUCT_ACTION, LOADED_PRODUCT_ACTION, prepareCall, true),
    createLoadingMiddleware(LOAD_DOWNLOADS_ACTION, LOADED_DOWNLOADS_ACTION, prepareCall, true)
];

// https://stackoverflow.com/a/18650828/388951
export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 B';

    const k = 1000,
        dm = decimals,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}



// Callbacks to restructure the download API responses, to make it easier for the UI to consume
export function reformatDownloads(action) {
    const downloads = action.result;
    if(downloads) {
        // Store the product id, so that we can check it later
        downloads.id = action.originalAction.id;

        if(downloads.length > 0) {
            downloads.forEach(download => download.readableSize = formatBytes(download.size));
        }
    }
    let error = action.error;
    if(error) {
        error.id = action.originalAction.id;
    }
}

function reformatProduct(action) {
    let product = action.result;
    if(product) {
        let formats = [];
        let squares = false;
        product.formats && product.formats.forEach(f => {
            let format = f.format;
            if(formats.indexOf(format) === -1) {
                formats.push(format);
            }
        });
        if(product.areas && !product.areas.every(area => area === 'GB' || area === 'NI' || area === 'UK')) {
            squares = true;
        }

        // metadata
        product.customAreaEnabled = squares;
        product.dataStructures = product.dataStructures && product.dataStructures.sort();
        product.supplyFormats = formats.sort();
        product.images = [];

        if (product.imageTemplate && product.imageCount) {
            for(let i = 0; i < product.imageCount; i++) {
                let imageUrl = product.imageTemplate.replace('{index}', i);
                product.images.push({small: imageUrl, large: imageUrl + '?large'});
            }
        }
    }
    let error = action.error;
    if(error) {
        error.id = action.originalAction.id;
    }
}

export function reformat(action) {
    let products = action.result;
    if (products && products.length > 0) {
        products.forEach(p => reformatProduct({ result: p }));
    }
}

