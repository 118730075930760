import React, {Fragment, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import PaymentDetails from "./billingInformation/PaymentDetails";
import PaymentStatus from './billingInformation/PaymentStatus';
import FeatureCheck from "../../components/FeatureCheck";
import routes, {getLocation} from "../../util/routes";
import ContentHeader from '../../components/ContentHeader';
import {FormattedMessage, defineMessages} from 'react-intl';
import {contentPadding} from 'omse-components';
import {createUseStyles} from 'react-jss';
import {useSelector, useDispatch} from 'react-redux';
import {hasManageBillingPermission, hasViewBillingPermission} from "../../util/permissions";
import Link from "../../components/Link";
import { CircularProgress } from '@mui/material';
import {getPaymentStatus} from "../../modules/payments/actions";
import {useHistory} from 'react-router';
import {currencyFormatter, formatCost} from "./payments/PaymentHelperFunctions";
import useVatRateAdjuster from "../../hooks/useVatRateAdjuster";

const messages = defineMessages({
    accountLink: {
        id: 'BillingInformation.backLink',
        defaultMessage: "Account settings",
        description: "Label for the back link on the billing information page"
    },
    title: {
        id: 'BillingInformation.title',
        defaultMessage: "Pay by credit/debit card for Premium API usage",
        description: "Title for the billing information page"
    },
    intro0: {
        id: 'BillingInformation.intro0',
        defaultMessage: 'Adding a payment card ensures your Premium data service is never interrupted.',
        description: 'Zeroth paragraph of intro text for the billing page'
    },
    intro1: {
        id: 'BillingInformation.intro1',
        defaultMessage: 'We\'ll only charge your payment card after you\'ve used up your free Premium credits. We\'ll let you know when you\'ve used up your free credits (and we start charging you for Premium Data usage) by emailing all Admin users and Finance users.',
        description: 'First paragraph of intro text for the billing page'
    },
    intro2: {
        id: 'BillingInformation.intro2',
        defaultMessage: 'Payments are taken every time your balance reaches {threshold} incl. VAT, or at the end of the month if your balance is less than {threshold}. To find out when you\'ll be charged, how much you get for free, and answers to other questions, see the {billingFAQ}.',
        description: 'Second paragraph of intro text for the billing page'
    },
    intro2VatNotAvailable : {
        id: 'BillingInformation.intro2VatNotAvailable',
        defaultMessage: 'Payments are taken every time your balance reaches £2,000 excl. VAT, or at the end of the month if your balance is less than £2,000 excl. VAT. To find out when you\'ll be charged, how much you get for free, and answers to other questions, see the {billingFAQ}.',
        description: 'Second paragraph of intro text for the billing page when VAT rate not available'
    },
    onlyAdmins1: {
        id: 'BillingInformation.onlyAdmins1',
        defaultMessage: 'Only an Admin user or Finance user of this account can make changes to billing information.',
        description: 'First paragraph of intro text for non-admin view of page'
    },
    onlyAdmins2: {
        id: 'BillingInformation.onlyAdmins2',
        defaultMessage: "If you have questions about billing, you should contact your organisation's Admin or Finance user.",
        description: 'Second paragraph of intro text for non-admin view of page'
    },
    error: {
        id: 'BillingInformation.error',
        defaultMessage: "We are unable to display your billing information at this time. Please try again later.",
        description: 'Message shown if we fail to load payment status from the server'
    },
    billingFAQ: {
        id: 'BillingInformation.billingFAQ',
        defaultMessage: 'billing FAQ',
        description: 'Text for the billing FAQ link'
    }
});

const styles = createUseStyles(theme => {
    return {
        content: {
            flex: '1 1 auto',
            paddingLeft: contentPadding.left,
            paddingRight: contentPadding.right,
            paddingBottom: contentPadding.bottom,
            maxWidth: contentPadding.maxWidth,
            marginTop: theme.spacing(8),
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile,
                margin: 0
            }
        },

    };
});

export default function BillingInformation() {
    const classes = styles();
    const history = useHistory();
    const dispatch = useDispatch();
    const status = useSelector(state => state.payments.status);
    const paymentStatus = useSelector((state) => state.payments.status.result);
    const vatRateAdjuster = useVatRateAdjuster();

    const statusLoading = status.loading;
    const statusError = status.error;
    const cardDetails = status.result && status.result.cardDetails;

    useEffect(() => {
        dispatch(getPaymentStatus());
    }, [dispatch]);

    const user = useSelector(state => state.user.current.result);
    const loading = useSelector(state => state.user.current.loading);

    if(!loading && !hasViewBillingPermission(user)) {
        // The user does not have permission to see this page. Take them back to the main account page.
        const newLocation = getLocation(routes.account, history.location);
        history.replace(newLocation);
    }

    let thresholdInclVat;
    const threshold = paymentStatus && paymentStatus.threshold;
    if (threshold && vatRateAdjuster) {
        thresholdInclVat = threshold * vatRateAdjuster;
    }

    let pageContent = <Fragment>
        {
            !cardDetails &&
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.intro0}/>
            </Typography>
        }
        <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...messages.intro1}/>
        </Typography>
        {typeof thresholdInclVat === 'undefined' &&
        <Typography variant='body1' paragraph={true}>
            <FormattedMessage
                {...messages.intro2VatNotAvailable}
                values={{
                    billingFAQ: <Link path={routes.supportPlans}>
                        <FormattedMessage {...messages.billingFAQ}/>
                    </Link>
                }}
            />
        </Typography>
        }
        {typeof thresholdInclVat !== 'undefined' &&
        <Typography variant='body1' paragraph={true}>
            <FormattedMessage
                {...messages.intro2}
                values={{
                    threshold: <span>{currencyFormatter.format(formatCost(thresholdInclVat))}</span>,
                    billingFAQ: <Link path={routes.supportPlans}>
                        <FormattedMessage {...messages.billingFAQ}/>
                    </Link>
                }}
            />
        </Typography>
        }
        <PaymentStatus/>
        <PaymentDetails/>
        {
            // Only show a progress marker when we load for the first time. After that we don't need it,
            // as it just makes the page judder when we refresh the payment details.
            statusLoading && !status.result && <CircularProgress size={32}/>
        }
    </Fragment>

    if(user && !hasManageBillingPermission(user)) {
        pageContent = <Fragment>
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.onlyAdmins1}/>
            </Typography>
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.onlyAdmins2}/>
            </Typography>
        </Fragment>
    } else if(statusError || (!statusLoading && !status.result)) {
        pageContent = <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...messages.error}/>
        </Typography>

    }

    return <FeatureCheck feature='monetisation'>
        <ContentHeader
			backPath={routes.account} 
			backLabel={messages.accountLink} 
			title={messages.title} />
        <div className={classes.content}>
            {pageContent}
        </div>
    </FeatureCheck>;
}

