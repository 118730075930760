import React from "react";
import styled from "@emotion/styled";
import { osColour } from "omse-components";
import { Typography } from "@mui/material";
import { defineMessages, useIntl } from "react-intl";
import {ClickAwayTooltip} from "./ClickAwayTooltip";
import {ReactComponent as HelpIcon} from "../components/icons/help-notification.svg";
import {createUseStyles} from "react-jss";

const messages = defineMessages({
    name: {
        id: "notActiveTag.name",
        defaultMessage: "Not active",
        description: "Recipe NotActiveTag Name",
    },
    ariaLabel: {
        id: "notActiveTag.ariaLabel",
        defaultMessage: "Decorative label: Not active",
        description: "Recipe NotActiveTag Decorative Aria-Label",
    },
    toolTip:{
        id: "notActiveTag.toolTip",
        defaultMessage:"This recipe has been deleted. You cannot edit it or any associated data packages. Associated data packages no longer receive updates.",
        description: "Recipe NotActiveTag tooltip text",
    },
    toolTipLabel:{
        id: "notActiveTag.toolTipLabel",
        defaultMessage:"Not active recipe explanation",
        description: "Recipe NotActiveTag tooltip label",
    },
});

const useStyles = createUseStyles(theme => ({
    paper: {
        display: 'block',
        maxWidth: 'inherit',
        '& p': {
            width: '18em',
            wordBreak: 'break-word',
            whiteSpace: 'normal'
        }
    },
    tooltipIcon: {
        color: theme.palette.primary.main,
        width: '24px',
        height: '24px',
        paddingLeft: '0px',
        paddingBottom: theme.spacing(1)
    },
    tooltip: {
        float: 'unset',
        display: 'inline-flex',
        padding: '0px !important',
        margin: '0px !important',
        '& button': {
            minWidth: '20px !important',
            width: '17px',
            height: '19px',
            padding: '0px !important',
            margin: '0px !important',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5',
            borderRadius: '100%',
            marginTop: `${theme.spacing(0.5)} !important`,
            boxShadow:`inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
            "&:hover": {
                background: "1px #f5f5f5"
            },
            '& span': {
                width: '24px',
                height: '24px',
                '& svg': {
                    top: '-10px',
                    padding: '0px !important'
                }
            }
        }
    },
    container:{
        marginLeft: theme.spacing(2),
        display: 'inline-flex',
        color: `${osColour.neutral.white}`,
        background: `transparent`,
        justifyContent: 'flex-end',
        alignItems: 'stretch',
        alignContent: 'center',
        flexDirection: 'row'
    }
}));


const Tag = styled("div")`
    display: inline-flex;
    height: 28px;
    color: ${osColour.neutral.white};
    background: ${osColour.primary.foxglove};
    justify-content: flex-end;
    align-items: stretch;
    align-content: center;
    flex-direction: row;
    & > span {
        padding: 7px 7px 7px 7px;
        line-height: 1;
        font-weight: 600;
        white-space: nowrap;
        -webkit-font-smoothing: antialiased;
    }
    & > div {
        background-color: transparent;
    }
`;

export default function NotActiveTag({showToolTip=false}) {
    const classes = useStyles();
    const intl = useIntl();
    const name = intl.formatMessage(messages.name);
    const ariaLabel = intl.formatMessage(messages.ariaLabel);

    return (
        <div className={classes.container}>
            <Tag alt="" role="img" aria-label={ariaLabel}>
                <Typography variant="caption">
                    {name}
                </Typography>
                {showToolTip && <ClickAwayTooltip
                    classes={{clickAwayTooltip: classes.tooltip, paper: classes.paper}}
                    icon={<HelpIcon className={classes.tooltipIcon} />}
                    body={messages.toolTip}
                    ariaLabel={intl.formatMessage(messages.toolTipLabel)}
                    id={'recipeExplanation'}
                />}
            </Tag>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" height="28" width="9" role="img" alt="" aria-hidden={true}>
                    <polygon fill={osColour.primary.foxglove} points="0 0, 9 0, 0 30, 0 0" />
                </svg>
            </div>
        </div>
    );
}
