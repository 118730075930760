const osGBGrid100KM = {
"type": "FeatureCollection",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },

"features": [
{ "type": "Feature", "properties": { "TILE_NAME": "HP", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -2.001899672254093, 61.580960407767279 ], [ -0.119145724941588, 61.568018190200526 ], [ -0.171729534295368, 60.670739964196905 ], [ -2.001839368829891, 60.68321367918422 ], [ -2.001899672254093, 61.580960407767279 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "HT", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -3.831945464188393, 60.670682742396366 ], [ -2.001839368829891, 60.68321367918422 ], [ -2.001782812478349, 59.785345764194403 ], [ -3.782532523123807, 59.773259977331598 ], [ -3.831945464188393, 60.670682742396366 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "HU", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -2.001839368829891, 60.68321367918422 ], [ -0.171729534295368, 60.670739964196905 ], [ -0.221029683696443, 59.773315188928102 ], [ -2.001782812478349, 59.785345764194403 ], [ -2.001839368829891, 60.68321367918422 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "HW", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -7.33205263982106, 59.676983156284486 ], [ -5.560278708817838, 59.737098584739428 ], [ -5.467773495099454, 58.840789869516854 ], [ -7.193941774238428, 58.782764416542093 ], [ -7.33205263982106, 59.676983156284486 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "HX", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -5.560278708817838, 59.737098584739428 ], [ -3.782532523123807, 59.773259977331598 ], [ -3.736128365318532, 58.875690642085921 ], [ -5.467773495099454, 58.840789869516854 ], [ -5.560278708817838, 59.737098584739428 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "HY", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -3.782532523123807, 59.773259977331598 ], [ -2.001782812478349, 59.785345764194403 ], [ -2.001729681211369, 58.887354537009365 ], [ -3.736128365318532, 58.875690642085921 ], [ -3.782532523123807, 59.773259977331598 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "HZ", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -2.001782812478349, 59.785345764194403 ], [ -0.221029683696443, 59.773315188928102 ], [ -0.267327864021134, 58.875743949024873 ], [ -2.001729681211369, 58.887354537009365 ], [ -2.001782812478349, 59.785345764194403 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NA", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -8.911963444442652, 58.701799741736686 ], [ -7.193941774238428, 58.782764416542093 ], [ -7.063990651136892, 57.888230690577409 ], [ -8.739734107828683, 57.810025784330513 ], [ -8.911963444442652, 58.701799741736686 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NB", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -7.193941774238428, 58.782764416542093 ], [ -5.467773495099454, 58.840789869516854 ], [ -5.380754084298284, 57.944270762879242 ], [ -7.063990651136892, 57.888230690577409 ], [ -7.193941774238428, 58.782764416542093 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NC", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -5.467773495099454, 58.840789869516854 ], [ -3.736128365318532, 58.875690642085921 ], [ -3.692482297871492, 57.977974344454793 ], [ -5.380754084298284, 57.944270762879242 ], [ -5.467773495099454, 58.840789869516854 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "ND", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -3.736128365318532, 58.875690642085921 ], [ -2.001729681211369, 58.887354537009365 ], [ -2.001679689164496, 57.989237632635358 ], [ -3.692482297871492, 57.977974344454793 ], [ -3.736128365318532, 58.875690642085921 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NF", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -8.739734107828683, 57.810025784330513 ], [ -7.063990651136892, 57.888230690577409 ], [ -6.941544083230456, 56.993395640182449 ], [ -8.577402190744829, 56.917818032542037 ], [ -8.739734107828683, 57.810025784330513 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NG", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -7.063990651136892, 57.888230690577409 ], [ -5.380754084298284, 57.944270762879242 ], [ -5.29877768418477, 57.047546363869216 ], [ -6.941544083230456, 56.993395640182449 ], [ -7.063990651136892, 57.888230690577409 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NH", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -5.380754084298284, 57.944270762879242 ], [ -3.692482297871492, 57.977974344454793 ], [ -3.651370997584318, 57.080110959138494 ], [ -5.29877768418477, 57.047546363869216 ], [ -5.380754084298284, 57.944270762879242 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NJ", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -3.692482297871492, 57.977974344454793 ], [ -2.001679689164496, 57.989237632635358 ], [ -2.001632581717425, 57.090993167282704 ], [ -3.651370997584318, 57.080110959138494 ], [ -3.692482297871492, 57.977974344454793 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NK", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -2.001679689164496, 57.989237632635358 ], [ -0.310874201334263, 57.978025843342991 ], [ -0.35189151370888, 57.08016073861333 ], [ -2.001632581717425, 57.090993167282704 ], [ -2.001679689164496, 57.989237632635358 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NL", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -8.577402190744829, 56.917818032542037 ], [ -6.941544083230456, 56.993395640182449 ], [ -6.826015773745163, 56.098271063595 ], [ -8.424199787372329, 56.025198772807656 ], [ -8.577402190744829, 56.917818032542037 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NM", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -6.941544083230456, 56.993395640182449 ], [ -5.29877768418477, 57.047546363869216 ], [ -5.221448348825331, 56.150620801590577 ], [ -6.826015773745163, 56.098271063595 ], [ -6.941544083230456, 56.993395640182449 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NN", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -5.29877768418477, 57.047546363869216 ], [ -3.651370997584318, 57.080110959138494 ], [ -3.612594855991068, 56.18209994025343 ], [ -5.221448348825331, 56.150620801590577 ], [ -5.29877768418477, 57.047546363869216 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NO", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -3.651370997584318, 57.080110959138494 ], [ -2.001632581717425, 57.090993167282704 ], [ -2.001588131315093, 56.192619022185454 ], [ -3.612594855991068, 56.18209994025343 ], [ -3.651370997584318, 57.080110959138494 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NR", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -6.826015773745163, 56.098271063595 ], [ -5.221448348825331, 56.150620801590577 ], [ -5.148411026147699, 55.253498049019569 ], [ -6.716879627889631, 55.202867821753252 ], [ -6.826015773745163, 56.098271063595 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NS", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -5.221448348825331, 56.150620801590577 ], [ -3.612594855991068, 56.18209994025343 ], [ -3.575974952646913, 55.283941065389719 ], [ -5.148411026147699, 55.253498049019569 ], [ -5.221448348825331, 56.150620801590577 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NT", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -3.612594855991068, 56.18209994025343 ], [ -2.001588131315093, 56.192619022185454 ], [ -2.00154613400833, 55.294113563985832 ], [ -3.575974952646913, 55.283941065389719 ], [ -3.612594855991068, 56.18209994025343 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NU", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -2.001588131315093, 56.192619022185454 ], [ -0.390578957979255, 56.182148081823563 ], [ -0.42711504964536, 55.283987644168462 ], [ -2.00154613400833, 55.294113563985832 ], [ -2.001588131315093, 56.192619022185454 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NW", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -6.716879627889631, 55.202867821753252 ], [ -5.148411026147699, 55.253498049019569 ], [ -5.07934638146819, 54.356181287962009 ], [ -6.613662182279731, 54.307195301708241 ], [ -6.716879627889631, 55.202867821753252 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NX", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -5.148411026147699, 55.253498049019569 ], [ -3.575974952646913, 55.283941065389719 ], [ -3.541350426034231, 54.385633739644284 ], [ -5.07934638146819, 54.356181287962009 ], [ -5.148411026147699, 55.253498049019569 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NY", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -3.575974952646913, 55.283941065389719 ], [ -2.00154613400833, 55.294113563985832 ], [ -2.0015064064496, 54.395474928116208 ], [ -3.541350426034231, 54.385633739644284 ], [ -3.575974952646913, 55.283941065389719 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "NZ", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -2.00154613400833, 55.294113563985832 ], [ -0.42711504964536, 55.283987644168462 ], [ -0.461660286048555, 54.385678824989689 ], [ -2.0015064064496, 54.395474928116208 ], [ -2.00154613400833, 55.294113563985832 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "OV", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -0.427114919887234, 55.283987642497159 ], [ 1.145327932574612, 55.253591077074944 ], [ 1.076342082697941, 54.356271337725808 ], [ -0.461660159138007, 54.3856788233728 ], [ -0.427114919887234, 55.283987642497159 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SD", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -3.541350426034231, 54.385633739644284 ], [ -2.0015064064496, 54.395474928116208 ], [ -2.001468783395738, 53.496701739155633 ], [ -3.508576287361593, 53.48717773306241 ], [ -3.541350426034231, 54.385633739644284 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SE", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -2.0015064064496, 54.395474928116208 ], [ -0.461660286048555, 54.385678824989689 ], [ -0.49435932765074, 53.487221389139947 ], [ -2.001468783395738, 53.496701739155633 ], [ -2.0015064064496, 54.395474928116208 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SH", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -5.013966491010907, 53.458673696918787 ], [ -3.508576287361593, 53.48717773306241 ], [ -3.477521495596468, 52.588572478701117 ], [ -4.952011045979304, 52.560977793865383 ], [ -5.013966491010907, 53.458673696918787 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SJ", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -3.508576287361593, 53.48717773306241 ], [ -2.001468783395738, 53.496701739155633 ], [ -2.001433115509339, 52.597792393759512 ], [ -3.477521495596468, 52.588572478701117 ], [ -3.508576287361593, 53.48717773306241 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SK", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -2.001468783395738, 53.496701739155633 ], [ -0.49435932765074, 53.487221389139947 ], [ -0.525342918663815, 52.588614764977919 ], [ -2.001433115509339, 52.597792393759512 ], [ -2.001468783395738, 53.496701739155633 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SM", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -6.423315614087278, 52.515076368124191 ], [ -4.952011045979304, 52.560977793865383 ], [ -4.893244187194119, 51.663096206129801 ], [ -6.335449865433274, 51.618644782059327 ], [ -6.423315614087278, 52.515076368124191 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SN", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -4.952011045979304, 52.560977793865383 ], [ -3.477521495596468, 52.588572478701117 ], [ -3.44806735278992, 51.689817805482235 ], [ -4.893244187194119, 51.663096206129801 ], [ -4.952011045979304, 52.560977793865383 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SO", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -3.477521495596468, 52.588572478701117 ], [ -2.001433115509339, 52.597792393759512 ], [ -2.001399267526664, 51.698745780984872 ], [ -3.44806735278992, 51.689817805482235 ], [ -3.477521495596468, 52.588572478701117 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SP", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -2.001433115509339, 52.597792393759512 ], [ -0.525342918663815, 52.588614764977919 ], [ -0.554729488109069, 51.689858777164609 ], [ -2.001399267526664, 51.698745780984872 ], [ -2.001433115509339, 52.597792393759512 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SR", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -6.335449865433274, 51.618644782059327 ], [ -4.893244187194119, 51.663096206129801 ], [ -4.837451682667212, 50.7650310872734 ], [ -6.252020773927638, 50.721973873622822 ], [ -6.335449865433274, 51.618644782059327 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SS", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -4.893244187194119, 51.663096206129801 ], [ -3.44806735278992, 51.689817805482235 ], [ -3.420106074642601, 50.790913321801817 ], [ -4.837451682667212, 50.7650310872734 ], [ -4.893244187194119, 51.663096206129801 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "ST", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -3.44806735278992, 51.689817805482235 ], [ -2.001399267526664, 51.698745780984872 ], [ -2.001367116625677, 50.79956065465408 ], [ -3.420106074642601, 50.790913321801817 ], [ -3.44806735278992, 51.689817805482235 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SU", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -2.001399267526664, 51.698745780984872 ], [ -0.554729488109069, 51.689858777164609 ], [ -0.582626576027113, 50.790953030221786 ], [ -2.001367116625677, 50.79956065465408 ], [ -2.001399267526664, 51.698745780984872 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SV", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -7.66244723761469, 50.661841013040643 ], [ -6.252020773927638, 50.721973873622822 ], [ -6.172738519609553, 49.825069454001977 ], [ -7.557159856851996, 49.766807229930492 ], [ -7.66244723761469, 50.661841013040643 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SW", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -6.252020773927638, 50.721973873622822 ], [ -4.837451682667212, 50.7650310872734 ], [ -4.784438540471966, 49.866784425318656 ], [ -6.172738519609553, 49.825069454001977 ], [ -6.252020773927638, 50.721973873622822 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SX", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -4.837451682667212, 50.7650310872734 ], [ -3.420106074642601, 50.790913321801817 ], [ -3.393539581937748, 49.891858695777039 ], [ -4.784438540471966, 49.866784425318656 ], [ -4.837451682667212, 50.7650310872734 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SY", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -3.420106074642601, 50.790913321801817 ], [ -2.001367116625677, 50.79956065465408 ], [ -2.001336551046673, 49.900235904146925 ], [ -3.393539581937748, 49.891858695777039 ], [ -3.420106074642601, 50.790913321801817 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "SZ", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -2.001367116625677, 50.79956065465408 ], [ -0.582626576027113, 50.790953030221786 ], [ -0.609132039318909, 49.891897188736159 ], [ -2.001336551046673, 49.900235904146925 ], [ -2.001367116625677, 50.79956065465408 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "TA", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -0.461660286048555, 54.385678824989689 ], [ 1.076341956015236, 54.356271340957171 ], [ 1.011036717362711, 53.458760899075422 ], [ -0.49435932765074, 53.487221389139947 ], [ -0.461660286048555, 54.385678824989689 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "TF", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -0.49435932765074, 53.487221389139947 ], [ 1.011036717362711, 53.458760899075422 ], [ 0.949152069614175, 52.561062263437066 ], [ -0.525342918663815, 52.588614764977919 ], [ -0.49435932765074, 53.487221389139947 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "TG", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 1.011036717362711, 53.458760899075422 ], [ 2.513016217477062, 53.411392815238678 ], [ 2.42046565982624, 52.51520281588521 ], [ 0.949152069614175, 52.561062263437066 ], [ 1.011036717362711, 53.458760899075422 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "TL", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -0.525342918663815, 52.588614764977919 ], [ 0.949152069614175, 52.561062263437066 ], [ 0.890452417757993, 51.663178052925062 ], [ -0.554729488109069, 51.689858777164609 ], [ -0.525342918663815, 52.588614764977919 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "TM", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 0.949152069614175, 52.561062263437066 ], [ 2.42046565982624, 52.51520281588521 ], [ 2.332666511947977, 51.61876731159834 ], [ 0.890452417757993, 51.663178052925062 ], [ 0.949152069614175, 52.561062263437066 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "TQ", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -0.554729488109069, 51.689858777164609 ], [ 0.890452417757993, 51.663178052925062 ], [ 0.834723769941347, 50.765110413418725 ], [ -0.582626576027113, 50.790953030221786 ], [ -0.554729488109069, 51.689858777164609 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "TR", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 0.890452417757993, 51.663178052925062 ], [ 2.332666511947977, 51.61876731159834 ], [ 2.249300725257665, 50.722092636796717 ], [ 0.834723769941347, 50.765110413418725 ], [ 0.890452417757993, 51.663178052925062 ] ] ] } },
{ "type": "Feature", "properties": { "TILE_NAME": "TV", "250K": "t" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -0.582626576027113, 50.790953030221786 ], [ 0.834723769941347, 50.765110413418725 ], [ 0.781771352969617, 49.866861325937393 ], [ -0.609132039318909, 49.891897188736159 ], [ -0.582626576027113, 50.790953030221786 ] ] ] } }
]
}
export default osGBGrid100KM;