import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage, defineMessages, FormattedDate} from 'react-intl';
import {Typography} from '@mui/material';
import {Notification, AddButton, osColour} from 'omse-components';
import {retryPayments} from '../../../modules/payments/actions';
import {createUseStyles} from 'react-jss';
import useActionIdSelector from "../../../hooks/useActionIdSelector";
import CostInfoTooltip from '../../../components/payment/CostInfoTooltip';
import {currencyFormatter} from "../payments/PaymentHelperFunctions";
import useVatRateAdjuster from "../../../hooks/useVatRateAdjuster";

const messages = defineMessages({
    paymentTaken: {
        id: 'paymentStatus.paymentTaken',
        defaultMessage: 'Payment was last taken on {date}.',
        description: 'Message shown if the user has a successful payment on file'
    },
    zerobill: {
        id: 'paymentStatus.zerobill',
        defaultMessage: 'Your balance is £0.00. You will be invoiced when your balance reaches {threshold} incl. VAT or on the first day of the month, whichever comes first.',
        description: 'Message shown if the user does not have a in-flight payment'
    },
    bill: {
        id: 'paymentStatus.bill',
        defaultMessage: 'Your balance is £{cost} (incl. VAT) {tooltip}. You will be invoiced when your balance reaches {threshold} incl. VAT or on the first day of the month, whichever comes first.',
        description: 'Message shown if the user does not have a in-flight payment'
    },
    calculatingBill: {
        id: 'paymentStatus.calculatingBill',
        defaultMessage: 'Your balance is being calculated. You will be invoiced when your balance reaches £2000 or on the first day of the month, whichever comes first.',
        description: "Message shown if the user does not have a in-flight payment and we don't have any stats yet"
    },
    pending: {
        id: 'paymentStatus.pending',
        defaultMessage: 'A payment of £{cost} (incl. VAT) has been scheduled. This usually takes less than an hour to complete.',
        description: 'Message shown if the user has pending payment'
    },
    error1: {
        id: 'paymentStatus.error1',
        defaultMessage: 'You have an outstanding charge on your account. Fix this by retrying the payment, or changing your payment card details below.',
        description: 'Message shown if the user has a payment error'
    },
    error2: {
        id: 'paymentStatus.error2',
        defaultMessage: 'You have an outstanding invoice of £{cost} (incl. VAT) which was issued on {date}.',
        description: 'Message shown if the user has a payment error'
    },
    retryPayment: {
        id: 'paymentStatus.retryPayment',
        defaultMessage: 'Retry payment',
        description: 'Button label to retry payment'
    },
    retryPaymentsError: {
        id: 'paymentStatus.retryPaymentsError',
        defaultMessage: 'There was a problem submitting the request to retry payments. Please try again later.',
        description: 'Message for when there is an error submitting a request to retry payments.'
    }
});

function formatCost(cost) {
    return (cost / 100).toFixed(2);
}
function formatDate(date) {
    return <FormattedDate value={date} day='numeric' month='short' year='numeric'/>
}

const useStyles = createUseStyles(theme => {
    return {
        error: {
            marginTop: theme.spacing(1),
            color: osColour.status.error
        },
        retry: {
            marginTop: theme.spacing(1)
        }        
    }
});

export default function PaymentStatus() {
    const classes = useStyles();
    const paymentStatus = useSelector(state => state.payments.status.result);
    const [retryPaymentsState, dispatch] = useActionIdSelector("payments.retryPayments");
    const vatRateAdjuster = useVatRateAdjuster();

    if(!paymentStatus || !vatRateAdjuster) {
        return null;
    }

    let successfulPayment = null;
    if(paymentStatus.lastSuccessfulPaymentDate) {
        successfulPayment = <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...messages.paymentTaken} values={{
                date: formatDate(paymentStatus.lastSuccessfulPaymentDate)
            }}/>
        </Typography>;
    }

    let details;
    if(paymentStatus.nextPayment) {
        const nextPayment = paymentStatus.nextPayment;
        if(nextPayment.status === 'PROCESSING') {
            details = <Notification variant='info' appearance='inline'>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.pending} values={{cost: nextPayment.cost}}/>
                </Typography>
            </Notification>;
        } else {
            details = <Notification variant='error' appearance='inline'>
                <Typography variant='body1' paragraph={true}>
                    <FormattedMessage {...messages.error1}/>
                </Typography>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.error2} values={{
                        cost: nextPayment.cost,
                        date: formatDate(nextPayment.createdDate)
                    }}/>
                </Typography>

                {retryPaymentsState.error?
                    <Typography variant='body1' className={classes.error}>
                      <FormattedMessage {...messages.retryPaymentsError} />
                    </Typography>
                :
                    <AddButton color='primary'
                        variant='contained'
                        label={messages.retryPayment}
                        working={retryPaymentsState.working}
                        showIcon={false}
                        classes={{button: classes.retry}}
                        action={() => dispatch(retryPayments())} />
                }
            </Notification>;
        }
    } else {
        let cost, tooltip;
        let message = messages.bill;
        if(paymentStatus.balance !== undefined) {
            cost = Math.max(paymentStatus.balance, 0);
            if(cost === 0) {
                message = messages.zerobill;
            }
            cost = formatCost(cost * vatRateAdjuster);
            tooltip = <CostInfoTooltip />;
        } else {
            message = messages.calculatingBill;
        }

        let thresholdInclVat = paymentStatus.threshold * vatRateAdjuster;
        let formattedThresholdInclVat = currencyFormatter.format(formatCost(thresholdInclVat));
        details = <Typography variant='body1'>
            <FormattedMessage {...message} values={{
                threshold: <span>{formattedThresholdInclVat}</span>,
                cost,
                tooltip}} />
        </Typography>;
    }

    return <Fragment>
        {successfulPayment}
        {details}
    </Fragment>
}