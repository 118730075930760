import {createLoadingReducer, createLoadingMiddleware} from 'omse-components';
import {LOAD_VIDEOS_ACTION, LOADED_VIDEOS_ACTION} from "./videos/actions";
import {combineReducers} from 'redux';

export const videosReducer = combineReducers({
    current: createLoadingReducer(LOAD_VIDEOS_ACTION, LOADED_VIDEOS_ACTION, true)
});

function prepareCall() {
    return '/api/videos/';
}

export const videosMiddleware = [
    createLoadingMiddleware(LOAD_VIDEOS_ACTION, LOADED_VIDEOS_ACTION, prepareCall)
];