import { Table, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { defineMessages, FormattedDate, FormattedMessage } from "react-intl";
import Link from "../../../components/Link";
import NavButton from "../../../components/NavButton";
import { DOWNLOADS_HASH } from "../DataPackage";
import useScrollPosition from "../../../hooks/useScrollPosition";
import { border1, osColour } from "omse-components";
import routePaths from "../../../util/routes";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { ReactComponent as Icon } from "../../../components/icons/dataPackage-large.svg";
import { ReactComponent as DownloadIcon } from "../../../components/icons/download.svg";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const messages = defineMessages({
  dataPackage: {
    id: "DataPackagesList.dataPackage",
    defaultMessage: "Data package",
    description: "Heading for the data package column in the table",
  },
  date: {
    id: "DataPackagesList.date",
    defaultMessage: "Date",
    description: "Heading for the date column in the table",
  },
  product: {
    id: "DataPackagesList.product",
    defaultMessage: "Product",
    description: "Heading for the product column in the table",
  },
  status: {
    id: "DataPackagesList.status",
    defaultMessage: "Status",
    description: "Heading for the status column in the table",
  },
  download: {
    id: "DataPackagesList.download",
    defaultMessage: "Download",
    description: "Message shown when the download is available",
  },
  soon: {
    id: "DataPackagesList.soon",
    defaultMessage: "Available soon",
    description: "Message shown when the download is not yet available",
  },
});

const DataPackageListItem = styled(Table)(
  ({ theme }) => `
        .table {
            display: contents;
            width: 100%;
            border: none;
            border-collapse: collapse;
            font-family: Source Sans Pro, sans-serif;
            
            //styling overrides for smaller screens
            ${theme.breakpoints.down("sm")} {
              & .th {
                display: none;
                border: none;
              },
              
              & a {
                display: contents;
              },
              
              & .tr {
                display: grid;
                grid-template-columns: 1fr 1fr;
                text-align: contents;
                min-width: contents;
                & .td:nth-child(4) {
                  //removes status column items on small screens
                  display: none;
                }
              },
              
              & .tableContent {
                display: grid;
                grid-template-columns: 0.4fr 1fr;
                align-items: left;
                
                & svg {
                align-items: left;
                }
              },
        },
            
          & .th {
              background-color: ${osColour.primary.lightestBerry};
              border-bottom: 2px solid ${osColour.primary.berry};
              padding: ${theme.spacing(1)};
              text-align: left;
          },
            
          & .tr {
              text-align: left;
              border-bottom: ${border1};
              ${theme.breakpoints.up("lg")} {
                  & .td:nth-child(1) {
                      //For lists with at least one very long name, restrict width of the first row
                      max-width: 40vw;
                  }
              },
          },
        
          & .td {
              padding: ${theme.spacing(1)};
          },
                
            & .svg {
                padding-right: ${theme.spacing(1)};
                }
            },
            
        .tableContent {
            display: flex;
            text-align: left;
        },
        
        .detailContainer: {
            display: flex;
            flex-flow: column wrap;
            align-content: flex-start;
            text-align: left;
        }
`
);

function packageLink(dataPackage) {
  return routePaths.dataPackage.replace(":packageId", dataPackage.id);
}

// Laying out the columns is easier if we have a single array that controls them - this allows us to write all the
// headers and then render the data for each package in turn.
const columns = [
  {
    heading: messages.dataPackage,
    render: (dataPackage, buildState) => (
      <div className="tableContent">
        <Link path={packageLink(dataPackage)} state={buildState}>
          <Icon
            width={44}
            height={48}
            alt="Decorative icon representing a data package"
          />
        </Link>
        <div className="detailContainer">
          <Link path={packageLink(dataPackage)} state={buildState}>
            <Typography variant="body1">{dataPackage.name}</Typography>
          </Link>
          <Typography component="p" color="textSecondary">
            {dataPackage.id}
          </Typography>
        </div>
      </div>
    ),
  },
  {
    heading: messages.date,
    render: (dataPackage) => (
      <FormattedDate
        value={dataPackage.createdOn}
        day="numeric"
        month="short"
        year="numeric"
      />
    ),
  },
  {
    heading: messages.product,
    render: (dataPackage) => dataPackage.productName,
  },
  {
    heading: messages.status,
    render: (dataPackage, buildState) => {
      if (!dataPackage.downloadAvailable) {
        return <FormattedMessage {...messages.soon} />;
      }
      return (
        <NavButton
          variant="outlined"
          color="primary"
          path={packageLink(dataPackage)}
          hash={DOWNLOADS_HASH}
          state={buildState}
        >
          <DownloadIcon width={24} height={24} />
          <FormattedMessage {...messages.download} />
        </NavButton>
      );
    },
  },
];

export default function DataPackagesTable(props) {
  const { getScrollPosition, setElement } = useScrollPosition();
  const { packageList, className } = props;
  const pageSize = useSelector((state) => state.dataPackages.pageSize);
  const location = useLocation();

  const page = (location.state && location.state.page) || 0;

  const slice = useMemo(() => {
    const start = page * pageSize;
    const end = start + pageSize;
    return packageList.slice(start, end);
  }, [packageList, page, pageSize]);

  function buildState() {
    // Create a location state object that passes the current page number and scroll state over to the data
    // package page, so that it can echo them back to us if the user clicks on the back list.
    return {
      stored: {
        ...location.state,
        scrollPosition: getScrollPosition(),
      },
    };
  }

  function renderHeadings() {
    return (
      <tr className="tr">
        {columns.map((col) => (
          <th key={col.heading.id} className="th">
            <Typography variant="body2" color="primary">
              <FormattedMessage {...col.heading} />
            </Typography>
          </th>
        ))}
      </tr>
    );
  }

  function renderRow(dataPackage) {
    return (
      <tr key={dataPackage.id} className="tr">
        {columns.map((col) => (
          <td key={dataPackage.id + col.heading.id} className="td">
            {col.render(dataPackage, buildState)}
          </td>
        ))}
      </tr>
    );
  }

  if (packageList.length === 0) {
    return null;
  }

  return (
    <DataPackageListItem
      key={packageList}
      className={className}
      ref={setElement}
    >
      <Typography variant="body1" component="tbody" className="table">
        {renderHeadings()}
        {slice.map(renderRow)}
      </Typography>
    </DataPackageListItem>
  );
}

DataPackagesTable.propTypes = {
  packageList: PropTypes.array.isRequired,
};
