import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getNotificationStatus} from "../../modules/notifications/actions";
import PropTypes from 'prop-types';
import {matchPath, withRouter} from 'react-router';
import {defineMessages, injectIntl} from 'react-intl';
import withStyles from 'react-jss';
import routePaths, {accountRoutes, getLocation, routes} from '../../util/routes';
import {headerHeight, modules, osColour, isWidthDown, withWidth} from 'omse-components';
import {compose} from 'redux';
import Notifications from '../Notifications';
import PriorityNotification from '../PriorityNotification';
import {findTab, findTabLabel, getTabRoute} from '../../util/menu';
import {navActionsContainer, navNowrap, navHiddenTabsApp, navHeader, navMinimalHeader} from '../../util/responsive-navigation';
import {closeMenu} from '../../modules/menu/actions';
import MenuIcon from "../../pages/appContent/responsiveNavigation/MenuIcon";
import {TEN_MINUTES_IN_MS} from '../../constants/constants';
import UserSiteNotices from '../UserSiteNotices';
import {ESCAPE} from '../../constants/keys';
import DataHubLogo from './DataHubLogo';
import featureCheck from '../../util/featureCheck';
import {VN} from '../../../shared/features';
import UserDetails from "./UserDetails";
import ConditionalWrapWithClass from "./ConditionalWrapWithClass";
import HeaderNavButtons from "./HeaderNavButtons";
import {hasShowVernacularNamesPermission} from "../../util/permissions";
import {holdingPageCheck} from "../../../shared/holdingPageCheck";
import classNames from 'classnames';
import { getUserDetailsDefaultContext } from '../../util/organisation';

const {getUser} = modules.actions.user;

const messages = defineMessages({
    navLabel: {
        defaultMessage: 'Primary',
        description: 'aria-label for the primary navigation',
        id: 'Header.navLabel'
    },
});

export const styles = theme => ({
    '@global': {
        ':target': {
            scrollMarginTop: `calc(${headerHeight}px + ${theme.spacing(2)})`
        }
    },
    headerContainer: {
        position: 'sticky',
        width: '100%',
        top: 0,
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 5,
        '@media print': {
            position: 'static'
        }
    },
    header: {
        boxSizing: 'border-box',
        flex: '0 0 auto',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        boxShadow: '-2px 2px 3px 0 rgba(0, 0, 0, 0.2)',
        zIndex: 5,
        borderTop: `solid #e6e5e6 1px`,
        minHeight: headerHeight,
        background: osColour.neutral.white,
    },
    skipToMainContent: {
        position: 'absolute',
        left: '-10000px',
        top: 'auto',            
        overflow: 'hidden',
        '&:focus': {
            padding: '14px',
            position: 'fixed',
            borderRadius: '6px',   
            color: osColour.primary.darkerBerry,
            background: osColour.neutral.white,
            top: 8,
            left: 8,
            zIndex: 999
        }
    },
    vnMenu: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
    },
    vnAccountButtons: {
        alignSelf: 'flex-end'
    }
});

export class Header extends Component {

    state = {
        notifications: false,
        priorityOpen: false,
        priorityNotification: {title: ''},
        useOverflow: false,
        vnMenu: false
    }

    logoRef = React.createRef();
    lastTabRef = React.createRef();
    userDetailsRef = React.createRef();

    keydown = (event) => {

        // esc key close
        if (event.key === ESCAPE) {
            if (this.state.notifications) {
                this.setState({notifications: false});
            } else if (this.props.menuOpen) {
                this.props.closeMenu()
            }
        }
    }

    componentDidMount() {
        const {location, userDetails} = this.props;
        this.props.getUser();

        if (location) {
            this.initialiseTabs(userDetails, location);
        }

        this.setState({vnMenu: this.displayVNNavBar()});

        document.addEventListener('keydown', this.keydown);
        window.addEventListener('resize', this.updateUserActionsMode);
    }

    componentWillUnmount() {
        clearInterval(this.notificationsInterval);
        document.removeEventListener('keydown', this.keydown);
    }

    componentDidUpdate(prevProps) {
        const {priorityNotification, location, userDetails, config} = this.props;

        if (prevProps && prevProps.location !== location) {
            this.setState({currentTab: null});
            this.initialiseTabs(userDetails, location);
        }


        if ( // user load attempt completed and user is logged in, and config is already available
            ((userDetails && !prevProps.userDetails) && config) ||
            // or config loaded after the user did
            (userDetails && (config && !prevProps.config))) {
            this.setState({vnMenu: this.displayVNNavBar()});
            clearInterval(this.notificationsInterval);
            this.props.getNotificationStatus();
            this.notificationsInterval = setInterval(this.props.getNotificationStatus, TEN_MINUTES_IN_MS);
            this.initialiseTabs(userDetails, location);
        }

        if (!this.state.notifications && priorityNotification && priorityNotification.title !== '') {

            let update = false;

            // first open
            if (prevProps.priorityNotification === undefined && this.state.priorityNotification.id !== priorityNotification.id) {
                update = true;
            }

            // priority notice received while portal is open and it is different to previous
            if (prevProps.priorityNotification && prevProps.priorityNotification.id !== priorityNotification.id) {
                update = true;
            }

            if (update) {
                this.setState({priorityOpen: true, priorityNotification});
            }
        }
    }

    displayVNNavBar = () => {
        const {location, userDetails, config} = this.props;
        const checkRoute = routes.find(route => location.pathname.toLowerCase() === routePaths.vernacularNamesReporting);
        const checkAccess = userDetails && hasShowVernacularNamesPermission(userDetails);
        const checkFeatureFlag = featureCheck(VN, config);
        return Boolean(checkRoute && checkFeatureFlag && checkAccess);
    }

    getDisplaySettings(fullHeader) {
        const {classes, userDetails, config} = this.props;
        const holdingPageDisplayed = holdingPageCheck(userDetails, config?.holdingPageUserPlans)
        const displayVNNavBar = this.displayVNNavBar()
        const conditionalVNMenuClass = displayVNNavBar ? classes.vnMenu : undefined;
        const conditionalVNButtonsClass = displayVNNavBar ? classes.vnAccountButtons : undefined;
        let navButtonsDisplay = 'None'
        if (displayVNNavBar) {
            navButtonsDisplay = 'VernacularNames';
        }
        if (!holdingPageDisplayed && !displayVNNavBar && fullHeader) {
            navButtonsDisplay = 'FullHeader';
        }
        return {
            displayVNNavBar,
            conditionalVNMenuClass,
            conditionalVNButtonsClass,
            navButtonsDisplay,
            holdingPageDisplayed
        };
    }

    updateUserActionsMode = () => {
        const {width, userDetails} = this.props;
        if (userDetails && isWidthDown('md', width)) {
            let rect1;
            if (isWidthDown('sm', width)) {
                if (this.logoRef && this.logoRef.current) {
                    rect1 = this.logoRef.current.getBoundingClientRect();
                }
            } else if (this.lastTabRef && this.lastTabRef.current) {
                rect1 = this.lastTabRef.current.getBoundingClientRect();
            }
            let rect2;
            if (this.userDetailsRef &&
                this.userDetailsRef.current &&
                this.userDetailsRef.current.userActionsRef &&
                this.userDetailsRef.current.userActionsRef.getBoundingClientRect
            ) {
                rect2 = this.userDetailsRef.current.userActionsRef.getBoundingClientRect();
            }
            if (rect1 && rect2) {
                if (!this.state.useOverflow) {
                    const spacer = 10;
                    if (rect1.right > (rect2.left - spacer)) {
                        this.setState({useOverflow: true});
                    }
                } else {
                    const allocation = 190;
                    if (rect1.right < (rect2.left - allocation)) {
                        this.setState({useOverflow: false});
                    }
                }
            }
        }
    }

    initialiseTabs = (userDetails, location) => {
        const route = routes.find(route => location.pathname.toLowerCase().indexOf(route.path.toLowerCase()) === 0);
        const isAccountRoute = accountRoutes.find(r => r.path === route.path || r.path === location.pathname.toLowerCase() || route.path === routePaths.account)
        
        this.setState({vnMenu: this.displayVNNavBar()});

        if (isAccountRoute) {
            this.setState({currentTab: false})
        } else if (route && !this.state.vnMenu) {
            const tabLabel = findTabLabel(userDetails, route, this.props.config)
            this.setState({currentTab: tabLabel})
        }
    }

    changeTab = (event, tabLabel) => {
        const {location, userDetails, config} = this.props;
        const defaultContextUserDetails = getUserDetailsDefaultContext(userDetails);
        const tab = findTab(defaultContextUserDetails, tabLabel, config);
        const tabRoute = getTabRoute(tab, defaultContextUserDetails, config);
        const newLocation = getLocation(tabRoute.path, location);
        this.setState({vnMenu: this.displayVNNavBar()});
        this.props.history.push(newLocation);
    }

    toggleNotifications = () => {
        this.setState({notifications: !this.state.notifications});
    }

    hideNotifications = () => {
        this.setState({notifications: false});
    }

    showNotifications = () => {
        this.setState({notifications: true, priorityOpen: false});
    }

    hidePriorityNotification = () => {
        this.setState({priorityOpen: false});
    }

    render() {
        const {location, classes, userDetails, loading, intl, notificationCount, headerRef} = this.props;
        const {priorityNotification, currentTab} = this.state;
        const notificationsButtonRef = this.userDetailsRef && this.userDetailsRef.current && this.userDetailsRef.current.notificationButtonRef

        const route = routes.find(route => {
            const match = matchPath(location.pathname, {
                path: route.path,
                exact: true
            });
            return match;
        });
        const fullHeader = !(route && route.simpleHeader);
        const noLinkHeader = route && route.noLinkHeader;

        const navLabel = intl.formatMessage(messages.navLabel);
        let display = this.getDisplaySettings(fullHeader);

        const navActionContainerClasses = {[navActionsContainer]: true};
        const headerClass = {[classes.header]: true};
        if (this.state.vnMenu) {
            navActionContainerClasses[navNowrap] = true;
            headerClass[navHiddenTabsApp] = true;
        }
        if (!fullHeader || display.holdingPageDisplayed) {
            headerClass[navMinimalHeader] = true;
        }

        return <div className={classes.headerContainer} ref={headerRef}>
            <UserSiteNotices/>
            <header className={classNames(headerClass, navHeader)}>
                {/*NB: Vernacular Names page does not have access to the full Data Hub menu*/}
                {(!this.state.vnMenu && !display.holdingPageDisplayed) && <MenuIcon/>}
                <DataHubLogo logoRef={this.logoRef} vnMenu={display.displayVNNavBar} isLink={!noLinkHeader}
                             holdingPageDisplayed={display.holdingPageDisplayed}/>

                <div className={classNames(navActionContainerClasses)}>
                    <a href="#main-content" className={classes.skipToMainContent}>Skip to Main Content</a>

                    {(display.displayVNNavBar || fullHeader) && (
                        <ConditionalWrapWithClass className={display.conditionalVNMenuClass}>
                            <HeaderNavButtons display={display.navButtonsDisplay}
                                              navLabel={navLabel}
                                              currentTab={currentTab}
                                              changeTab={this.changeTab}
                                              updateUserActionsMode={this.updateUserActionsMode}
                                              ref={this.lastTabRef}
                                              userDetails={userDetails}
                            />
                            <UserDetails useOverflow={this.state.useOverflow}
                                         hideNotifications={this.hideNotifications}
                                         toggleNotifications={this.toggleNotifications}
                                         userDetails={userDetails}
                                         notificationCount={notificationCount}
                                         loading={loading}
                                         ref={this.userDetailsRef}
                                         holdingPageDisplayed={display.holdingPageDisplayed}
                                         isVNHeader={this.state.vnMenu}
                            />

                            {
                                !display.holdingPageDisplayed &&
                                <ConditionalWrapWithClass className={display.conditionalVNButtonsClass}>
                                    <Notifications open={this.state.notifications}
                                                anchorEl={notificationsButtonRef}
                                                handleClickAway={this.hideNotifications}/>

                                    <PriorityNotification handleClick={this.showNotifications}
                                                        open={this.state.priorityOpen}
                                                        anchorEl={notificationsButtonRef}
                                                        handleClickAway={this.hidePriorityNotification}
                                                        item={priorityNotification}/>
                                </ConditionalWrapWithClass>
                            }

                        </ConditionalWrapWithClass>
                    )}
                </div>
            </header>
        </div>
    }
}

Header.propTypes = {
    intl: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    getUser: PropTypes.func.isRequired,
    userDetails: PropTypes.object,
    loading: PropTypes.bool,
    getNotificationStatus: PropTypes.func.isRequired,
    notificationCount: PropTypes.number,
    priorityNotification: PropTypes.object
};

Header.defaultProps = {
    notificationCount: 0
}

function mapStateToProps(state) {
    const {result, loading} = state.user.current;
    const currentConfig = state.config.current;

    let notificationCount = 0;
    let priorityNotification;
    if (state.notifications &&
        (state.notifications.status && state.notifications.status.result && state.notifications.status.result.messages)) {
        notificationCount = state.notifications.status.result.messages.count;
        priorityNotification = state.notifications.status.result.messages.priority;
    }

    const menu = state.menu;

    return {
        userDetails: result,
        config: currentConfig && currentConfig.result,
        loading,
        notificationCount,
        priorityNotification,
        menuOpen: (menu.nav || menu.account)
    }
}

export default withRouter(compose(withWidth(), withStyles(styles), connect(mapStateToProps, {
    getUser,
    getNotificationStatus,
    closeMenu
}))(injectIntl(Header)));
