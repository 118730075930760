// BannerNotification.js
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { osColour } from 'omse-components';
import { Box, Button, Typography } from '@mui/material';
import withStyles from 'react-jss';
import { cookieSettingToIndex, isCookieControlSettingAccepted } from '../util/cookie-control';

const styles = {
    article: {
        margin: '1.4em 0 2em 0',
        padding: '1.6em',
        background: osColour.primary.lightestBerry,
        border: `1px solid ${osColour.neutral.mist}`,
        borderRadius: '0.4em'
    },
    messageBlock: {
        margin: 0,
        marginTop: '0.4em'
    },
    buttonContainer: {
        margin: 0,
        marginTop: '0.6em',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1em'
    }
}

function _localStorageGet(key) {
    return JSON.parse(localStorage.getItem(key));
}

function _localStorageSet(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

function BannerNotification(p) {
    const [ cookiesEnabled, setCookiesEnabled ] = useState(false);
    const [ isHidden, setIsHidden ] = useState(_localStorageGet(p.id));
    useEffect(() => {
        if (isCookieControlSettingAccepted(cookieSettingToIndex.functionality)) {
            // Changing cookie permissions instigates a reload, so
            // it is not neccesary to re-check cookie status for situations
            // where functionality cookies are DISABLED. In situations where
            // they are later ENABLED, the user must reload the page manually
            // to view the dimiss button.
            setCookiesEnabled(true);
        }
    }, [])
    
    useEffect(() => {
        // This approach will not write to LocalStorage until dismiss
        // is clicked, ensuring compliance with cookie law.
        if (_localStorageGet(p.id) !== isHidden) {
            _localStorageSet(p.id, isHidden);
        } 
    }, [p.id, isHidden]);

    return isHidden ? null : (
        <article className={p.classes.article}>
            <Typography variant='h3' >
                <Box fontWeight="fontWeightBold" fontSize={20}>
                    {p.title}
                </Box>
            </Typography>
            {p.summary && (
                <div className={p.classes.messageBlock}>
                    <Typography variant='body1' paragraph={true}>
                        {p.summary}
                    </Typography>
                </div>
            )}
            <div className={p.classes.buttonContainer}>
                {p.buttons && p.buttons.map((button, i) => (
                    <div key={`button${i}`}>
                        {button}
                    </div>
                ))}
                {cookiesEnabled && (
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => setIsHidden(true)}
                    >
                        Dismiss
                    </Button>
                )}
            </div>
        </article>
    );
}

BannerNotification.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.element.isRequired,
    summary: PropTypes.element.isRequired,
    buttons: PropTypes.arrayOf(PropTypes.element)
}

export default withStyles(styles)(BannerNotification);