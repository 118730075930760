import React from 'react';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {defineMessages, FormattedMessage} from 'react-intl';
import {formatBytes} from '../../../../modules/downloads';
import {compose} from 'redux';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import {ReactComponent as SaveAlt} from "../../../../components/icons/download.svg";
import { withWidth } from 'omse-components';

const messages = defineMessages({
    downloadLink: {
        id: 'OpenDataDownloadsItem.downloadLink',
        defaultMessage: '{name}{fileInfo, select, false {} other { ({fileInfo})}} - {format} {tile} ({size})',
        description: 'Download link text'
    },
    links: {
        id: 'OpenDataDownloadsItem.links',
        defaultMessage: 'Direct {data, plural, one {link} zero {link} other {links}}:',
        description: 'Link label'
    },
});

const styles = theme => ({
    downloadLink: {
        padding: 0,
        '& svg': {
            marginRight: theme.spacing(1),
            position: 'relative',
            top: -5,
            flex: '0 0 auto'
        },
        [theme.breakpoints.down('md')]: {
            '&:not(:last-child)': {
                marginBottom: 15
            }
        }
    },
    downloadLinks: {
        padding: '7px 0 0 0',
        [theme.breakpoints.down('md')]: {
            padding: 0
        }
    },
    linksLabel: {
        color: theme.palette.text.primary,
        margin: '20px 0 7px 0'
    }
});

function getFileInfo(download, name) {
    if(name === 'OS Open Linked Identifiers') {
        return download.fileName.match(/_([^._]+)\./)[1].replace(/-/g, ' ');
    }

    if(name === 'BGS Geology 625K') {
        const match = download.url.match('(bedrock|dykes|faults)');
        if (match) {
            return match[0].charAt(0).toUpperCase() + match[0].substring(1);
        }
    }
    return false;
}

export function OpenDataLinks(props) {
    let {classes, downloads, name, format, showLabel} = props;
    return (
        <List className={classes.downloadLinks}>
            {showLabel &&
                <Typography variant='body1' className={classNames(classes.stageLabels, classes.linksLabel)}>
                    <FormattedMessage {...messages.links} values={{data: downloads.length}}/>
                </Typography>
            }
            {downloads.map((dl, idx) => {
                return (<ListItem key={idx} className={classes.downloadLink}>
                    <ListItemText>
                        <a href={dl.url} download>
                            <SaveAlt width={24} />
                            <FormattedMessage {...messages.downloadLink} values={{name: name, fileInfo: getFileInfo(dl, name), format: format, tile: dl.area, size: formatBytes(dl.size)}} />
                        </a>
                    </ListItemText>
                </ListItem>)
            })
            }
        </List>
    );
}

OpenDataLinks.propTypes = {
    classes: PropTypes.object.isRequired,
    downloads: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    showLabel: PropTypes.bool
};

OpenDataLinks.defaultProps = {
    downloads: [],
    showLabel: false
};

export default compose(withWidth(), withStyles(styles))(OpenDataLinks);
