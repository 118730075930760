import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { routes } from '../../util/routes';
import {
    osColour,
    headerHeight,
    border1,
    ExternalLink,
} from 'omse-components';
import Link from '../../components/Link';
import featureCheck from '../../util/featureCheck';
import messages from './MenuMessages';
import OrganisationSelect from './OrganisationSelect';
import {
    canShowToUser,
    findMenuItem,
    routeIsInMenu,
    getAlignFillStyle,
} from '../../util/menu';
import { navSide } from '../../util/responsive-navigation';
import { secondaryMenu } from './menu/menu-secondary';
import { ENTER } from '../../constants/keys';

const menuPaddingTop = 20;

const MenuContainer = styled.div`
    width: 260px;
    border-right: ${border1};
    background-color: ${osColour.primary.lightestBerry};
    flex: 0 0 auto;
    box-sizing: border-box;
    & .listItem {
        padding: 0px 6px 0px 6px;
        & span {
            color: ${osColour.neutral.charcoal};
        }
        &.selected {
            border-radius: 0px;
            padding-left: 2px;
            border-left: 4px solid ${osColour.primary.berry};
            & span {
                color: ${osColour.primary.berry};
                font-weight: 600;
            }
        }
        &.selected .nested {
            padding-left: 35px;
        }
        & .menuSubHeading {
            cursor: pointer;
            & > span {
                display: flex;
                justify-content: space-between;
            }
        }
        &.MuiListItem-divider {
            margin-bottom: 0.2em;
            padding-bottom: 0.2em;
        }
        
    }
    & .link {
        text-decoration: none;
        width: 100%;
        padding: 13px 16px;
        border-radius: 4px;
        &.nested {
            padding-left: 35px;
            padding-top: 7px;
            padding-bottom: 7px;
            & a {
                width: 100%;
                text-decoration: none;
                color: ${osColour.neutral.charcoal} !important;
            }
            &:hover {
                color: ${osColour.neutral.charcoal} !important; 
            }
            &:visited {
                color: ${osColour.neutral.charcoal} !important; 
            }
            & svg {
                color: ${osColour.neutral.stone};
            }
        }
        &:hover {
            transition-duration: 150ms;
            background: ${osColour.primary.lighterBerry}20;
        }
        &:active {
            background: ${osColour.primary.lighterBerry}40;
        }
    }
    & .nestedListWrapper {
        padding: 0px;
    }
    & .nestedList {
        width: 100%;
    }
    & .expandArrow {
        transform: rotate(0deg);
        transition: transform 0.1s linear;
        &.expandArrowOpen {
            transform: rotate(180deg);
        }
    }
`;

const MenuNav = styled('nav')(({ theme }) => `
    position: sticky;
    height: calc(100vh - ${headerHeight}px - ${menuPaddingTop}px);
    overflow-y: auto;
    top: ${headerHeight}px;
    padding-top: ${menuPaddingTop}px;
    & ul {
        margin: 0;
        padding-left: 0;
    }
    & > ul {
        padding-top: ${theme.spacing(1)};
        padding-bottom: 6rem;
    }
    @media print {
        position: static;
        height: unset;
        overflow-y: unset;
    }

`)

export const Menu = ({ headerRef }) => {
    const [menu, setMenu] = useState([]);
    const [visible, setVisible] = useState(false);
    const [openHeadings, setOpenHeadings] = useState({});
    const location = useLocation();
    const history = useHistory();
    const intl = useIntl();

    const { userDetails, org, config } = useSelector(state => ({
        userDetails: state.user.current.result,
        org: state.organisation.current,
        config: state.config.current.result,
    }));

    const loggedIn = Boolean(userDetails?.active);
    const label = intl.formatMessage(messages?.label);
    
    const currentRoute = routes.find(route => {
        const pathIsLocation = route.path.toLowerCase() === location.pathname.toLowerCase();
        const pathAppearsInLocation = (!!route.path && location.pathname.toLowerCase().indexOf(route.path.toLowerCase()) === 0);
        return pathIsLocation || pathAppearsInLocation;
    })
    const accountRoute = secondaryMenu.map(item => item.route.path.toLowerCase()).find(path => path === history.location.pathname.toLowerCase())
    const routeInMenu = Boolean(routeIsInMenu(menu, currentRoute))
    
    useEffect(() => {
        const setMenuState = () => {
            let newMenu = []
            let newVisible = true

            if (loggedIn && history && accountRoute) {
                newMenu = secondaryMenu;
            } else {
                let pathname = location.pathname.toLowerCase();
                let route = routes.find(route => route.path.toLowerCase() === pathname || (route.path.length > 1 && pathname.indexOf(route.path.toLowerCase()) === 0));
                let menuEntry = findMenuItem(userDetails, route, config);
                newMenu = menuEntry?.menu ? menuEntry.menu : [];
                if (menuEntry && menuEntry.secondary !== 'undefined' && menuEntry.secondary === false ) {
                    newVisible = false;
                }
            }

            setMenu(newMenu)
            setVisible(newVisible)
        };
        setMenuState();
    }, [location, history, userDetails, org, config, loggedIn, accountRoute]);

    const filter = item => {
        // If it's not a feature then it's fine to show or else you have to check.
        const canShowFeature = !item.feature || featureCheck(item.feature, config);
        if(!canShowFeature || item.hidden) {
            return false;
        }

        if(!loggedIn) {
            // If the user is not logged in you have to check whether the route is public.
            const canShowToPublic = item?.initRoute?.public || item?.route?.public
            return canShowToPublic;
        } else {
            // If we are logged in, we can show it most of the time, but we might need a quick check            
            return canShowToUser(item, userDetails, org.id);
        }
    };

    const menuItem = (item, nested) => {
        const selection = routes.find(route => location.pathname.toLowerCase().indexOf(route.path.toLowerCase()) === 0 && routeIsInMenu(menu, route));
        let selected = selection === item.route;
        let open = item?.subMenu?.some(i => i.route === selection);

        let filteredNestedItems = item?.subMenu?.filter(filter);
    
        let path = item?.route?.path || (item?.initRoute?.path) || null;
    
        let key = item.id ? item.id : item.label.id;
        if (item?.route) {
            key += item.route.path;
        }
    
        const isExternalLink = item?.externalLink;
        const isHeadingOnly = item?.route === null;
    
        const handleHeadingClick = (key) => {
            setOpenHeadings(prevOpenHeadings => ({
                ...prevOpenHeadings,
                [key]: !prevOpenHeadings[key],
            }));
        };
        return (
            <Fragment key={key}>
                <ListItem className={`listItem ${selected ? 'selected' : ''}`} id={key} divider={!!item.divider}>
                    {isExternalLink ? (
                        <div className={`link ${nested ? 'nested' : ''}`}>
                            <ExternalLink message={item.label} href={item.href} />{' '}
                        </div>
                    ) : isHeadingOnly ? (
                        <ListItemText
                            className={`link ${nested ? 'nested' : ''} menuSubHeading`}
                            primaryTypographyProps={{ variant: nested ? 'body1' : 'h3', component: 'span' }}
                            onClick={() => handleHeadingClick(key)}
                            role='heading'
                            aria-level={2}
                            onKeyDown={e => {
                                if (e.key === ENTER) {
                                    handleHeadingClick(key);
                                }
                            }}
                            tabIndex={0}
                        >
                            <FormattedMessage {...item.label} />
                            <ExpandMore className={classNames('expandArrow', openHeadings[key] && 'expandArrowOpen')} />
                        </ListItemText>
                    ) : path?.length > 0 ? (
                        <Link path={path} className={`link ${nested ? 'nested' : ''}`}>
                            <ListItemText
                                primaryTypographyProps={{ variant: nested ? 'body1' : 'h3', component: 'span' }}
                            >
                                <FormattedMessage {...item.label} />
                            </ListItemText>
                        </Link>
                    ) : null}
                </ListItem>
                {!isHeadingOnly && (selected || open) && filteredNestedItems && filteredNestedItems.length !== 0 &&
                    <ListItem className={"nestedListWrapper"}>
                        <List className={"nestedList"} disablePadding={true}>
                            {filteredNestedItems
                                .map(i => menuItem(i, true))}
                        </List>
                    </ListItem>
                }
                {isHeadingOnly && filteredNestedItems && filteredNestedItems.length !== 0 && (
                    <Collapse in={openHeadings[key]} timeout={200} unmountOnExit>
                        <ListItem className={"nestedListWrapper"}>
                                <List className={"nestedList"} disablePadding={true}>
                                    {filteredNestedItems.map(i => menuItem(i, true))}
                                </List>
                        </ListItem>
                    </Collapse>
                )}
            </Fragment>
        );
    };
    return (
        visible && 
        <MenuContainer className={navSide} role='complementary' data-testid='menu'>
            <MenuNav style={getAlignFillStyle(headerRef, menuPaddingTop)}>
                <OrganisationSelect />
                <ul aria-label={label}>
                    {/* {menu.filter(filter).map(item => menuItem(item))} */}
                    {(routeInMenu || accountRoute) && menu.filter(filter).map(item => menuItem(item))}
                </ul>
            </MenuNav>
        </MenuContainer>
    );
}

export default Menu
