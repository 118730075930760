import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import AddDataPackage from './AddDataPackage';
import {updateDraftOrder, resetDraftOrder} from '../../../modules/dataPackages/actions';
import AddDataPackagePanel from "./addDataPackage/AddDataPackagePanel";
import DataPackageMap from "./DataPackageMap";
import ConfirmDataPackage from "./ConfirmDataPackage";
import routePaths from '../../../util/routes';
import useDataPackageNgdPoweredPremiumOptions from "./useDataPackageNgdPoweredPremiumOptions";
import PartnerLicences from "../../../components/PartnerLicences";

export default function AddDataPackageNgdPoweredPremium({product}) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetDraftOrder(true));
        dispatch(updateDraftOrder("isNgd", true));
        dispatch(updateDraftOrder("productId", product.id));
        dispatch(updateDraftOrder("productVersion", product.version));
    }, [dispatch, product]);

    const {areaRadioOptions, formatOptions, crsOptions, updatesOptions, initialSupplyDateOptions} = useDataPackageNgdPoweredPremiumOptions(product);

    let panel = <AddDataPackagePanel subtitle={product.label}
                                     areaRadioOptions={areaRadioOptions}
                                     formatOptions={formatOptions}
                                     crsOptions={crsOptions}
                                     updatesOptions={updatesOptions}
                                     initialSupplyDateOptions={initialSupplyDateOptions}
                                     inputContainerExtraChildren={<PartnerLicences productId={product.id}/>}
    />;
    const successPanel = <ConfirmDataPackage addAnotherPackagePath={routePaths.premiumDownloads} />;
    const map = <DataPackageMap/>;

    return <AddDataPackage panel={panel}
                           successPanel={successPanel}
                           dataPackageMap={map}
    />;
}
