import React from "react";
import Table from './Table';
import withStyles from 'react-jss';
import styles from "./style";
import InlineCodeSnippet from "../../components/InlineCodeSnippet";

export function calculateRowSpan(dataObject, matcher) {
    return Object.keys(dataObject).reduce((acc, el) => {
        const attributeToCount = dataObject[el][matcher];
        const returnObj = {...acc};
        if (returnObj[attributeToCount]) {
            returnObj[attributeToCount] += 1;
        } else {
            returnObj[attributeToCount] = 1;
        }
        return returnObj;
    }, {});
}

export function SpanTable({headers, spanData, useCodeSnippet}) {
    // Span Data
    // { firstColumnValues (shared): {
    //      firstNonSharedColumnValue: [2ndNonSharedColumn, 3rd 4th],
    //      primary: content,  (Optional)
    //      shared: [2ndSharedColumn, 3rdShared](Optional)
    // }}
    const rowColor = ['#f6f5f9', 'white']
    let rows = [];
    Object.keys(spanData).forEach(spanColumn => {
            const spanColour = rowColor[Object.keys(spanData).indexOf(spanColumn) % 2];
            let spanCreated = true;
            Object.keys(spanData[spanColumn]).forEach(row => {
                if (row !== 'shared' && row !== 'primary') {
                    let tableRow = [];
                    let rowKey = spanColumn + row
                    const keys = Object.keys(spanData[spanColumn])
                    let spanLength = keys.includes('shared') ? keys.length - 1 : keys.length
                    spanLength = keys.includes('primary') ? spanLength - 1 : spanLength
                    if (spanCreated === true) {
                        if (keys.includes('primary')) {
                            tableRow.push(<td
                                key={rowKey + 'spancolPrimary'}
                                rowSpan={spanLength}
                                              style={{backgroundColor: spanColour}}>{spanData[spanColumn]['primary']}</td>)
                        } else {
                            tableRow.push(
                                <td
                                    key={rowKey + 'spancolStandard'}
                                    rowSpan={spanLength}
                                    style={{backgroundColor: spanColour}}>{spanColumn}</td>
                            )
                        }
                        if (keys.includes('shared')) {
                            tableRow.push(Object.keys(spanData[spanColumn]['shared']).map((listedSpanColumn, index) => {
                                return <td
                                    key={rowKey + listedSpanColumn + index}
                                           rowSpan={spanLength}>{spanData[spanColumn]['shared'][listedSpanColumn]}</td>
                            }))
                        }
                        spanCreated = false
                    }
                    tableRow.push(
                        <td key={rowKey + 'seperator'}>
                            {useCodeSnippet ? (
                                <div style={{maxWidth: '32em', wordBreak: 'break-all'}}>
                                    <InlineCodeSnippet>
                                        {row}
                                    </InlineCodeSnippet>
                                </div>
                            ) : row}
                        </td>
                    )
                    tableRow.push(Object.keys(spanData[spanColumn][row]).map((column,index) => {
                        return <td key={(rowKey + column+index)}>{spanData[spanColumn][row][column]}</td>
                    }))
                    rows.push(<tr key={(spanColumn+row)}>{tableRow}</tr>);
                }
            })

        })
        return (<Table>
        <thead>
            <tr key={'headers'+headers[0]}>
                {headers.map(header => {return <th key={'header-'+header}>{header}</th>})}
        </tr>
        </thead>
        <tbody>
            {rows}
        </tbody>
    </Table>)
}


export function ThemeLinkAndShortCode({theme, rowSpans}) {
    if (rowSpans[theme.themeShortCode] > 1) {
        let tableRow = <>
            <td rowSpan={rowSpans[theme.themeShortCode]}>{theme.link}</td>
            <td rowSpan={rowSpans[theme.themeShortCode]}>{theme.themeShortCode} </td>
        </>
        rowSpans[theme.themeShortCode] = 0;
        return tableRow;
    } else if (rowSpans[theme.themeShortCode] !== 0) {
        return <>
            <td>{theme.link}</td>
            <td>{theme.themeShortCode}</td>
        </>
    }
    return <></>
}

export function EndpointResource({endpoint, rowSpans}) {

    if (rowSpans[endpoint.resource] > 1) {
        let tableRow = <td rowSpan={rowSpans[endpoint.resource]}>{endpoint.resource}</td>
        rowSpans[endpoint.resource] = 0;
        return tableRow;
    } else if (rowSpans[endpoint.resource] !== 0) {
        return <td>{endpoint.resource}</td>
    }
    return <></>
}

export default withStyles(styles)(SpanTable);
