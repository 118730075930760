import React, {Fragment} from 'react';
import {defineMessages, FormattedMessage} from "react-intl";
import {premiumFreeLimitInPounds} from '../../../../shared/constants';
import routePaths from "../../../util/routes";
import Link from '../../Link';

const messages = defineMessages({
    riskOfInterruption: {
        id: 'UserSiteNotices.riskOfInterruption',
        defaultMessage: 'There is a problem with your billing details. Your Premium Data service is at risk of being interrupted.',
        description: 'Message when risk of interruption.'
    },
    serviceStoppedBillingIssue: {
        id: 'UserSiteNotices.serviceStoppedBillingIssue',
        defaultMessage: 'Your Premium Data service has been stopped until next month. There was a problem with your billing details.',
        description: 'Message when data service has stopped due to a billing problem.'
    },
    serviceStoppedAboveThreshold: {
        id: 'UserSiteNotices.serviceStoppedAboveThreshold',
        defaultMessage: 'Your Premium Data service has been stopped until next month. You used up your free £{premiumThreshold, number} Premium data this month.',
        description: 'Message when data service has stopped due to being above threshold without billing details.'
    },
    cardExpiry: {
        id: 'UserSiteNotices.cardExpiry',
        defaultMessage: 'Your payment card is due to expire soon. Your Premium Data service is at risk of being interrupted.',
        description: 'Message when card is due to expire.'
    },
    issueLink: {
        id: 'UserSiteNotices.issueLink',
        defaultMessage: 'Investigate and resolve this issue now.',
        description: 'Message for the link to go to billing details.'
    }
});

function noticeContent(message, path, values) {
    return <Fragment>
        {message &&
            <span>
                <FormattedMessage {...message} values={values} />
            </span>
        }
        <Link path={path}><FormattedMessage {...messages.issueLink} /></Link>
    </Fragment>
}

export const activeNotices = [
    'PaymentIssue.RiskOfInterruption',
    'PaymentIssue.ServiceStoppedBillingIssue',
    'PaymentIssue.ServiceStoppedAboveThreshold',
    'PaymentIssue.CardExpiry'
];

export const getContent = notice => {
    let message, values;
    if (notice.id === 'PaymentIssue.RiskOfInterruption') {
        message = messages.riskOfInterruption;
    }
    if (notice.id === 'PaymentIssue.ServiceStoppedBillingIssue') {
        message = messages.serviceStoppedBillingIssue;
    }
    if (notice.id === 'PaymentIssue.ServiceStoppedAboveThreshold') {
        message = messages.serviceStoppedAboveThreshold;
        values = {premiumThreshold: premiumFreeLimitInPounds}
    }
    if (notice.id === 'PaymentIssue.CardExpiry') {
        message = messages.cardExpiry;
    }
    return noticeContent(message, routePaths.billing, values);
}