import React, {Fragment, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {createUseStyles} from 'react-jss';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Typography} from '@mui/material';
import {
    CommonDialog,
    CommonDialogActions,
    FieldError,
    osColour,
    ExternalLink
} from 'omse-components';
import Dropzone from 'react-dropzone';
import Button from '@mui/material/Button';
import {setMapTool} from "../../../../../modules/toolbar";
import {gridRefToPolygon} from "../ol/gridRef";
import {toOLGeometry, wktToOLFeatures} from "../ol/feature";
import {checkFeatureMerging} from "../ol/layer";
import {ReactComponent as UploadIcon} from "../../../../../components/icons/upload-big.svg";
import pretty from 'prettysize';
import shp from 'shpjs';
import { Vector as VectorSource } from 'ol/source';

import {fromExtent} from 'ol/geom/Polygon';
import Feature from 'ol/Feature';

const ACCEPTED_FILE_TYPES = [".wkt", ".shp", ".txt"];
const MAX_FILE_SIZE_BYTES = 5 * 1024 * 1024;

const styles = createUseStyles(theme => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: osColour.neutral.mist,
        borderStyle: 'dashed',
        backgroundColor: osColour.neutral.clouds,
        outline: 'none',
        transition: 'border .24s ease-in-out',

        // On small screens the modal is full-screen anyway, so we only need to fix the width on medium screens and up
        [theme.breakpoints.up('md')]: {
            width: '475px',
            height: '250px',
        }
    },
    icon: {
      margin: 20
    },
    resetButton: {
        color: osColour.link.base,
        fontWeight: 400,
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline'
        },
        padding: 0,
        marginTop: 10
    },
    filename: {
        margin: 10,
        fontWeight: theme.typography.body2.fontWeight
    },
}));

const messages = defineMessages({
    title: {
        id: 'UploadPolygonModal.title',
        defaultMessage: 'Upload polygon or tile list',
        description: 'Title for the confirm overwrite modal'
    },
    confirm: {
        id: 'UploadPolygonModal.confirm',
        defaultMessage: 'Upload',
        description: 'Label for the save button'
    },
    dropzone: {
        id: 'UploadPolygonModal.dropzone',
        defaultMessage: 'Upload your own polygon file (.shp or .wkt) or tile list (.txt) to add it to the map.',
        description: 'Text for the content of the dialog'
    },
    chooseFile: {
        id: 'UploadPolygonModal.chooseFile',
        defaultMessage: 'Choose file',
        description: 'Text for the choose file button'
    },
    fileTooBig: {
        id: 'UploadPolygonModal.fileTooBig',
        defaultMessage: "File size exceeds the maximum allowed size {max}",
        description: 'Error message when too file is too big'
    },
    invalidFileType: {
        id: 'UploadPolygonModal.invalidFileType',
        defaultMessage: "File extension must be .shp or .wkt or .txt",
        description: 'Error message when too file is too big'
    },
    reset: {
        id: 'UploadPolygonModal.reset',
        defaultMessage: "Upload another file",
        description: 'Text to reset the dialog'
    },
    couldNotLoad: {
        id: 'UploadPolygonModal.couldNotLoad',
        defaultMessage: "There was a problem loading your file. Please retry and if the problem persists then {link}.",
        description: 'Error message it all goes a bit wrong'
    },
    invalidTileReference: {
        id: 'UploadPolygonModal.invalidTileReference',
        defaultMessage: "There is an invalid tile reference [{ref}] at line {line}. Please correct the problem and try again.",
        description: 'Error message it all goes a bit wrong'
    },
    maximumCoordsError: {
        id: 'UploadPolygonModal.maximumCoordsError',
        defaultMessage: "To ensure good performance the OS Data Hub imposes a limit of {max} vertices. The file you are uploading will mean that limit is exceeded. Please simplify your polygon and try again.",
        description: 'Error message when too many coords loaded'
    },
    maximumFeaturesError: {
        id: 'UploadPolygonModal.maximumFeaturesError',
        defaultMessage: "To ensure good performance the OS Data Hub imposes a limit of {max} polygons. The file you are uploading will mean that limit is exceeded. Please reduce the number of polygons and try again.",
        description: 'Error message when too many features loaded'
    }
});

export default function UploadPolygonModal(props) {
    let {drawingLayer, map, currentPolygonLayer} = props;

    const dispatch = useDispatch();
    const classes = styles();

    const [working, setWorking] = useState(false);
    const [error, setError] = useState(null);
    const [uploadFile, setUploadFile] = useState(null);

    const close = useCallback(() => {
        dispatch(setMapTool());
    }, [dispatch]);

    function reset() {
        setError(null);
        setWorking(false);
        setUploadFile(null);
    }

    function mergeAndAddFeatures(olFeatures) {
        let sources = [drawingLayer.getSource()];

        checkFeatureMerging(sources, olFeatures, messages.maximumCoordsError, messages.maximumFeaturesError)
            .then(features => {
                addFeatures(features);
                zoomToExtent();
                close();
            })
            .catch(err => {
                if(err && err.message && err.message.id) {
                    setError(err);
                } else {
                    console.error(err);
                }
            });
    }

    function addFeatures(features) {
        drawingLayer.getSource().clear();
        drawingLayer.getSource().addFeatures(features);
    }

    function zoomToExtent() {
        let zoomSource = new VectorSource({
            features: [...drawingLayer.getSource().getFeatures()]
        });
        if (currentPolygonLayer) {
            zoomSource.addFeatures(currentPolygonLayer.getSource().getFeatures())
        }

        if (zoomSource.getFeatures().length > 0) {
            let geom = fromExtent(zoomSource.getExtent());
            geom.scale(1.3);
            map.getView().fit(geom, { size: map.getSize() });
        }
    }

    function readFile(reader, callback, readFunc) {
        let ready = false;
        let result = '';

        // only way to getting upload spinner working is to put the processing inside this timeout
        let check = function() {
            if (ready === true) {
                try {
                    callback(result);
                } catch (err) {
                    setError({ message: messages.couldNotLoad, values: { link: <ExternalLink type='support' /> } })
                }
            } else {
                setTimeout(check, 500);
            }
        }
        check();

        reader.onloadend = (evt) => {
            result = evt.target.result;
            ready = true;
        };

        readFunc.apply(reader, [uploadFile]);
    }

    function setFile(files) {
        let file = files[0];

        if (file.size > MAX_FILE_SIZE_BYTES) {
            setError({message: messages.fileTooBig, values: {max: pretty(MAX_FILE_SIZE_BYTES, {nospace: true})}});
            return;
        }

        setUploadFile(file);
    }

    function gridRefsToFeatures(gridRefs) {
        let features = [];
        let refs = gridRefs.split(/\r?\n/);

        for(let i = 0; i < refs.length; i++) {
            let ref = refs[i].replace(/\s/g,'');
            if (ref !== "") {
                try {
                    let polygon = gridRefToPolygon(ref);
                    features.push(new Feature(polygon));
                } catch(err) {
                    setError({message: messages.invalidTileReference, values: {ref: refs[i], line: i+1}})
                    return;
                }
            }
        }
        return features;
    }

    function upload() {
        setWorking(true);

        let fileExt = uploadFile.path.split('.').pop().toLowerCase();

        if (fileExt === 'wkt') {
            const reader = new FileReader();
            readFile(reader, (result) => {
                let olFeatures = wktToOLFeatures(result);
                mergeAndAddFeatures(olFeatures);
            }, reader.readAsText);
        } else if (fileExt === 'shp') {
            const reader = new FileReader();
            readFile(reader, (result) => {
                let geometries = shp.parseShp(result);
                let features = geometries.map(toOLGeometry).map(g => new Feature(g));
                mergeAndAddFeatures(features);
            }, reader.readAsArrayBuffer);
        } else if (fileExt === 'txt') {
            const reader = new FileReader();
            readFile(reader, (result) => {
                let olFeatures = gridRefsToFeatures(result);
                if (olFeatures) mergeAndAddFeatures(olFeatures);
            }, reader.readAsText);
        } else {
            setError({message: messages.invalidFileType})
        }
    }

    const actions = <CommonDialogActions confirmLabel={messages.confirm}
                                         confirmAllowed={uploadFile !== null && !error}
                                         onClose={close}
                                         onConfirm={() => upload()}
                                         working={working && !error}/>;

    return <CommonDialog actions={actions}
                         onClose={close}
                         title={messages.title}
                         contentClassName={classes.dialog}>

        <Dropzone maxFile={1} noClick={true} noKeyboard={true} accept={ACCEPTED_FILE_TYPES}
            onDropAccepted={setFile} disabled={uploadFile !== null}>
            {({ getRootProps, getInputProps, open }) => (
                <div {...getRootProps()} className={classes.container}>
                    <input {...getInputProps()} />

                    <UploadIcon className={classes.icon}/>

                    {uploadFile &&
                        <Typography variant='body1' className={classes.filename}>
                            {uploadFile.path}
                        </Typography>
                    }

                    {error &&
                        <Fragment>
                            <FieldError error={error.message} errorValues={error.values}/>
                            <Button className={classes.resetButton}
                                onClick={reset}
                                variant='text'>
                                <FormattedMessage {...messages.reset}/>
                            </Button>
                        </Fragment>
                    }

                    {!uploadFile && !error &&
                        <Fragment>
                            <Typography variant='body1'>
                                <FormattedMessage {...messages.dropzone} />
                            </Typography>
                            <Button onClick={open}
                            variant='contained'
                            color='primary'>
                                <FormattedMessage {...messages.chooseFile} />
                            </Button>
                        </Fragment>
                    }

                </div>
            )}
        </Dropzone>
    </CommonDialog>;
}
