import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import { osColour, ExternalLink, InternalLink } from 'omse-components';
import PropTypes from 'prop-types';
import {createUseStyles} from 'react-jss';
import classNames from 'classnames';
import commonStyles, {tableStyles} from '../styles/common';
import useFeatureCheck from '../../util/useFeatureCheck';
import features from '../../../shared/features';
import { defineMessages } from "react-intl";
import routePaths, {ROYAL_MAIL_SOLUTION_PROVIDER_LICENSE} from "../../util/routes";
import { isWidthDown, withWidth } from 'omse-components';

const linkMessages = defineMessages({
    royalMailLicense: {
        id: 'plans.royalMailLicense',
        defaultMessage: 'Royal Mail PAF Data Solutions Provider Licence',
        description: 'Royal Mail licence text'
    }
});

const useDataProducts = (royalMailFootnoteSup) => {
    const dataProducts = {}
    if (useFeatureCheck(features.NGD_FEATURES)) {
        dataProducts['OS NGD API – Features'] = {
            featureTypes: [
                {name: '1 premium feature request (up to 100 features)'}
            ],
            planCost: [
                {name: '£0.188'}
            ],
            freeTransactions: [
                {name: 'Up to 5,319', includeRateLimitFootnote: true}
            ]
        }
    }
    if (useFeatureCheck(features.NGD_TILES)) {
        dataProducts['OS NGD API – Tiles'] = {
            featureTypes: [
                {name: 'OS OpenData map views'},
                {name: '1 Premium map view (4 tiles)'}
            ],
            planCost: [
                {name: 'Free'},
                {name: '£0.0314'}
            ],
            freeTransactions: [
                {name: 'Unlimited', includeRateLimitFootnote: true},
                {name: 'Up to 31,847'}
            ]
        }
    }
    dataProducts['OS Maps API'] = {
            featureTypes: [
                {name: 'OS OpenData map views'},
                {name: '1 Leisure map 1:25 000/1:50 000 view (15 tiles)'},
                {name: '1 Premium OS MasterMap Topography Layer map view (15 tiles)'}
            ],
            planCost: [
                {name: 'Free'},
                {name: '£0.000525'},
                {name: '£0.0314'}
            ],
            freeTransactions: [
                {name: 'Unlimited', includeRateLimitFootnote: true},
                {name: 'Up to 1,904,762'},
                {name: 'Up to 31,847'}
            ]
        }

    dataProducts['OS Vector Tile API'] = {
        featureTypes: [
            {name: 'OS OpenData map views'},
            {name: '1 Premium map view (4 tiles)'}
        ],
        planCost: [
            {name: 'Free'},
            {name: '£0.0314'}
        ],
        freeTransactions: [
            {name: 'Unlimited', includeRateLimitFootnote: true},
            {name: 'Up to 31,847'}
        ]
    }

    dataProducts['OS Features API'] = {
        featureTypes: [
            {name: 'OS OpenData feature requests'},
            {name: '1 premium feature request (up to 100 features)'}
        ],
        planCost: [
            {name: 'Free'},
            {name: '£0.188'}
        ],
        freeTransactions: [
            {name: 'Unlimited', includeRateLimitFootnote: true},
            {name: 'Up to 5,319'}
        ]
    }
    dataProducts['OS Names API'] = {
        featureTypes: [
            {name: 'OS OpenData feature requests'}
        ],
        planCost: [
            {name: 'Free'}
        ],
        freeTransactions: [
            {name: 'Unlimited', includeRateLimitFootnote: true}
        ]
    }
    dataProducts['OS Linked Identifiers API'] = {
        featureTypes: [
            {name: 'OS Linked Identifiers API requests'}
        ],
        planCost: [
            {name: 'Free'}
        ],
        freeTransactions: [
            {name: 'Unlimited', includeRateLimitFootnote: true}
        ]
    }
    if (useFeatureCheck(features.PREMIUM_OS_PLACES)) {
        dataProducts['OS Places API'] = {
            featureTypes: [
                {name: '1 premium feature request (up to 100 addresses)'}
            ],
            planCost: [
                {name: '£0.01', includeRoyalMailFootnote: true}
            ],
            freeTransactions: [
                {name: 'No free transactions'}
            ]
        }
    }
    if (useFeatureCheck(features.MATCH_AND_CLEANSE)) {
        dataProducts['OS Match & Cleanse API'] = {
            featureTypes: [
                {name: '1 premium feature request (up to 100 addresses)'}
            ],
            planCost: [
                {name: '£0.01', includeRoyalMailFootnote: true}
            ],
            freeTransactions: [
                {name: 'No free transactions'}
            ]
        }
    }
    return dataProducts;
}

const useStylesCommon = createUseStyles(commonStyles);
const useTableStyles = createUseStyles(tableStyles);
const useStyles = createUseStyles(theme => ({
    table: {
        margin: `${theme.spacing(6)} 0 ${theme.spacing(4)} 0`,
        width: '100%',
        '& p': {
            marginTop: 0
        },
        '& p:last-of-type': {
            marginBottom: 0
        }
    },
    product: {
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: 400,
        lineHeight: 1.5
    },
    tableData: {
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: 600,
        lineHeight: 1.43
    },
    mobileTableProductItem: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        fontWeight: 'bold',
        backgroundColor: osColour.neutral.clouds
    },
    mobileTableItem: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
        backgroundColor: osColour.neutral.clouds
    },
    mobileTableHeading: {
        fontWeight: 'bold',
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1)
    },
    mobileFootnotes: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    }
}));

export function PricingTable(props) {
    const {width, customVatFootnoteLink, customRateLimitFootnoteLink} = props;
    const classesCommon = useStylesCommon(props);
    const classesTable = useTableStyles();
    const classes = useStyles(props);

    // By default will display 1 and 2 for VAT and premium plan footnotes.
    // If a custom link ref is provided for the VAT footnote then 2 doesn't make sense, so use * instead.
    const displayDefaultVatFootnote = customVatFootnoteLink == null;
    const displayDefaultRateLimitFootnote = customRateLimitFootnoteLink == null;
    const displayNumberedFootnotes = displayDefaultVatFootnote;
    const defaultVatFootnoteSup = 1;
    const vatFootnoteComponent = displayDefaultVatFootnote ? defaultVatFootnoteSup : customVatFootnoteLink;

    // Condition to make sure users have relevant features for Royal Mail footnote to be shown
    const hasPremiumOSPlaces = useFeatureCheck(features.PREMIUM_OS_PLACES);
    const hasMatchAndCleanse = useFeatureCheck(features.MATCH_AND_CLEANSE);
    const displayRoyalMailFootnote = hasMatchAndCleanse || hasPremiumOSPlaces;

    const getSup = function(child){
        return <sup
            className={classesCommon.superScriptColor}>{child}</sup>
    }
    const getSups = function(...args){
        if(args.length > 1){
            if(args.every(arg=>typeof arg === 'string' ||typeof arg === 'number')){
                return getSup(args.join(","));
            }
            return args.map(arg =>getSup(arg));
        }else if(args.length === 1){
            return getSup(args[0]);
        }
        return null;
    }
    // The rate limit footnote text only displays on the pricing page, where it will either be 4 or 3 depending on whether Royal Mail footnote is displayed
    const defaultRateLimitFootnoteSup = displayRoyalMailFootnote ? '4' : '3';
    const rateLimitFootnoteComponent = displayDefaultRateLimitFootnote ? defaultRateLimitFootnoteSup : customRateLimitFootnoteLink;
    const premiumPlansFootnoteSup = displayNumberedFootnotes ? '2' : '*';
    const royalMailFootnoteSup = displayNumberedFootnotes ? '3' : '†';
    const eaiFootnoteSup = displayNumberedFootnotes ? '5' : '§';

    const dataProducts = useDataProducts(royalMailFootnoteSup);

    const showMobile = isWidthDown('xs', width);

    if (showMobile) {
        return (
            <>
                {Object.keys(dataProducts).map(productName => {
                    const product = dataProducts[productName];
                    return (
                        <div key={productName}>
                            <Typography
                                color='primary'
                                variant='h5'
                                component='h4'
                                className={classes.mobileTableHeading}
                            >
                                {productName}
                            </Typography>
                            {product.featureTypes.map((featureType, index) => {
                                return (
                                    <Fragment key={index}>
                                        <Typography
                                            variant='h5'
                                            color='textPrimary'
                                            className={classes.mobileTableProductItem}
                                        >
                                            {featureType.name}
                                        </Typography>
                                        <Typography
                                            variant='h5'
                                            color='textPrimary'
                                            className={classes.mobileTableItem}
                                        >
                                            Cost{getSups(vatFootnoteComponent,eaiFootnoteSup)}: <b>{product.planCost[index].name}</b>
                                            {product.planCost[index].includeRoyalMailFootnote && getSup(royalMailFootnoteSup)}
                                        </Typography>
                                        <Typography
                                            variant='h5'
                                            color='textPrimary'
                                            className={classes.mobileTableItem}
                                        >
                                            Equivalent free transactions per month
                                            {getSups(premiumPlansFootnoteSup,eaiFootnoteSup)}:{" "}
                                        </Typography>
                                        <Typography
                                            variant='h5'
                                            color='textPrimary'
                                            className={classes.mobileTableItem}
                                        >
                                            {product.freeTransactions[index].name}
                                            {product.freeTransactions[index]?.includeRateLimitFootnote && getSup(rateLimitFootnoteComponent)}
                                        </Typography>
                                    </Fragment>
                                )
                            })}
                        </div>
                    );
                })}
                <Footnotes
                    className={classes.mobileFootnotes}
                    displayVatFootnote={displayDefaultVatFootnote}
                    vatFootnoteSup={getSup(defaultVatFootnoteSup)}
                    premiumPlansFootnoteSup={getSup(premiumPlansFootnoteSup)}
                    displayRoyalMailFootnote={displayRoyalMailFootnote}
                    royalMailFootnoteSup={getSup(royalMailFootnoteSup)}
                    displayRateLimitFootnoteSup={getSup(displayDefaultRateLimitFootnote)}
                    rateLimitFootnoteSup={getSup(defaultRateLimitFootnoteSup)}
                    eaiFootnoteSup={getSup(eaiFootnoteSup)}
                />
            </>
        );
    } else {
        return (
            <>
                <table className={classNames(classesTable.table, classes.table)}>
                    <thead>
                    <tr>
                        <Typography variant='subtitle2' component='th'>Product</Typography>
                        <Typography variant='subtitle2' component='th'>You get</Typography>
                        <Typography variant='subtitle2' component='th'>Cost{getSups(vatFootnoteComponent,eaiFootnoteSup)}</Typography>
                        <th>
                            <Typography variant='subtitle2' component='span'>Equivalent free</Typography>
                            <Typography variant='body1' color='textPrimary'>
                                transactions per month{getSups(premiumPlansFootnoteSup,eaiFootnoteSup)}
                            </Typography>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.keys(dataProducts).map(productName => {
                            const product = dataProducts[productName];
                            return (
                                <tr key={productName}>
                                    <th className={classes.tableData}>
                                        {productName}
                                    </th>
                                    <td className={classes.product}>
                                        {product.featureTypes.map(t => <div key={t.name}>{t.name}</div>)}
                                    </td>
                                    <td className={classes.tableData}>
                                        {product.planCost.map(t => (
                                            <div key={t.name}>
                                                {t.name}
                                                {t.includeRoyalMailFootnote && getSup(royalMailFootnoteSup)}
                                            </div>
                                        ))}
                                    </td>
                                    <td className={classes.product}>
                                        {product.freeTransactions.map(t => (
                                            <div key={t.name}>
                                                {t.name}
                                                {t.includeRateLimitFootnote && getSup(rateLimitFootnoteComponent)}
                                            </div>
                                        ))}
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
                <Footnotes
                    displayVatFootnote={displayDefaultVatFootnote}
                    vatFootnoteSup={getSup(defaultVatFootnoteSup)}
                    premiumPlansFootnoteSup={getSup(premiumPlansFootnoteSup)}
                    royalMailFootnoteSup={getSup(royalMailFootnoteSup)}
                    displayRoyalMailFootnote={displayRoyalMailFootnote}
                    displayRateLimitFootnoteSup={getSup(displayDefaultRateLimitFootnote)}
                    rateLimitFootnoteSup={getSup(defaultRateLimitFootnoteSup)}
                    eaiFootnoteSup={getSup(eaiFootnoteSup)}
                />
            </>
        );
    }
}

PricingTable.propTypes = {
    // Both optional as not used in the FAQ usage of pricing table
    customVatFootnoteLink: PropTypes.element,
    customRateLimitFootnoteLink: PropTypes.element
};


const useFootnoteStyles = createUseStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
    },
    footnote: {
        marginBottom: theme.spacing(1)
    }
}));

function Footnotes({
                       className,
                       displayVatFootnote,
                       vatFootnoteSup,
                       premiumPlansFootnoteSup,
                       displayRoyalMailFootnote,
                       royalMailFootnoteSup,
                       displayRateLimitFootnoteSup,
                       rateLimitFootnoteSup,
                       eaiFootnoteSup
                   }) {
    const classes = useFootnoteStyles();
    return (
        <div className={classNames(classes.root, className)}>
            {displayVatFootnote && (
                <Typography variant='body1' className={classes.footnote} aria-label={'VAT footnote'}>
                    {vatFootnoteSup}Our quoted prices are subject to VAT. VAT is charged at the applicable
                    rate at time of payment.
                </Typography>
            )}
            <Typography variant='body1' className={classes.footnote} aria-label={'Transactions footnote'}>
                {premiumPlansFootnoteSup}Premium Plans get up to the first £1,000 of premium data free every month
                (excluding OS Places API and OS Match & Cleanse API). This column shows, for each product, the
                number of equivalent free API calls you could make with your free up to £1,000. API access is
                subject to a fair-use throttle.
            </Typography>
            {displayRoyalMailFootnote && (
                <Typography variant='body1' className={classes.footnote} aria-label={'OS Places footnote'}>
                    {royalMailFootnoteSup}OS Places API and OS Match & Cleanse API contain IPR owned, in part, by
                    Royal Mail. Accordingly, the use of these API Services is subject to the additional terms and fees
                    as set out in the <ExternalLink
                        type='generic'
                        href={ROYAL_MAIL_SOLUTION_PROVIDER_LICENSE}
                        message={linkMessages.royalMailLicense}
                    />. Users on a Premium Plan can trial OS Places API and OS Match & Cleanse API for 60 days with a limit of 2000 transactions.
                </Typography>
            )}
            {displayRateLimitFootnoteSup && (
                <Typography variant='body1' className={classes.footnote} aria-label={'Throttling footnote'}>
                    {rateLimitFootnoteSup}All our API data (OS OpenData and Premium) are subject to a 600
                    transactions-per-minute throttle
                    for your live projects. This is equivalent to 100 concurrent users requesting a computer-screens worth of
                    map tiles every 10 seconds. <InternalLink message={'More about throttling.'} path={`${routePaths.supportPlans}#apiThrottling`} aria-label={'Link to Throttling in FAQs'}/>
                </Typography>
            )}
            <Typography variant="body1" className={classes.footnote} aria-label={'E&I customers footnote'}>
                {eaiFootnoteSup}For Energy and Infrastructure Customers the costs are outlined within your OS Data Hub account. If you need any further assistance with your API plan, please contact your account manager.
            </Typography>
        </div>
    );
}

export default withWidth()(PricingTable);
