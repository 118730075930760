import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmationDialog } from 'omse-components';
import {defineMessages} from 'react-intl';

const messages = defineMessages({
    deleteProjectTitle: {
        id: 'DeleteProjectDialog.deleteTitle',
        defaultMessage: 'Permanently delete this project and its API key?',
        description: 'Title for the delete project dialog'
    },
    content1: {
        id: 'DeleteProjectDialog.content1',
        defaultMessage: 'This action is permanent.',
        description: 'Warning text for the delete project dialog'
    },
    content2: {
        id: 'DeleteProjectDialog.content2',
        defaultMessage: 'Any apps you have built that use this API key will stop working.',
        description: 'Warning text for the delete project dialog'
    },
    buttonLabel: {
        id: 'DeleteProjectDialog.buttonLabel',
        defaultMessage: 'Permanently remove project',
        description: 'Button label for the delete project dialog'
    },
});

export default function render(props) {
    const {closed, confirmed} = props;
    
    return <ConfirmationDialog title={messages.deleteProjectTitle}
                               confirmationAction={confirmed}
                               contentMessages={[messages.content1, messages.content2]}
                               handleClose={closed}
                               confirmationButtonLabel={messages.buttonLabel}
                               confirmationCodeRequired={true}
                               />
}

render.propTypes = {
    closed: PropTypes.func.isRequired,
    confirmed: PropTypes.func.isRequired
};
