import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {IconButton, Typography, CircularProgress} from "@mui/material";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {createUseStyles} from 'react-jss';
import {ReactComponent as CloseIcon} from "../../../components/icons/close-large.svg";
import FeatureQueryViewer from "./FeatureQueryViewer";
import {ExpansionList, osColour} from 'omse-components';

const useStyles = createUseStyles(theme => ({
    heading: {
        display: "flex",
        padding: theme.spacing(3)
    },
    closeButton: {
        marginLeft: 'auto',
    },
    rightSide: {
        [theme.breakpoints.down('md')]: {
            // Should take up the entire width when on mobile.
            flex: '1',
        },
        position: 'relative',
        boxShadow: '0 -5px 6px 3px rgba(0, 0, 0, 0.2)',
        overflow: 'auto',
        height: '100%',
        width: '100%',
        backgroundColor: osColour.neutral.white,
    },
    loadingSpinner: {
        margin: theme.spacing(4)
    },
    body: {
        margin: theme.spacing(3),
        marginBottom: theme.spacing(10) // Helps to keep the content above the cookie control when on mobile
    },
    expansionRoot: {
        maxWidth: '100%'
    },
    expandDetails: {
        marginTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    }
}));

const messages = defineMessages({
    headingAll: {
        id: 'FeatureFilterReadOnlyPanel.headingAll',
        defaultMessage: 'All Filters',
        description: 'The heading for feature filter readonly panel'
    },
    headingSingle: {
        id: 'FeatureFilterReadOnlyPanel.headingSingle',
        defaultMessage: 'Selected Filter',
        description: 'Heading for the feature filter readonly panel when viewing a single dataset'
    },
    closeButton: {
        id: 'FeatureFilterReadOnlyPanel.closeButton',
        defaultMessage: 'Close filter panel',
        description: 'Aria label for close button'
    },
    expandAll: {
        id: 'FeatureFilterReadOnlyPanel.expandAll',
        defaultMessage: 'Expand all filters',
        description: 'Label for the expand-all button'
    },
    collapseAll: {
        id: 'FeatureFilterReadOnlyPanel.expandAllLabel',
        defaultMessage: 'Collapse all filters',
        description: 'Label for the collapse-all button'
    }
});

export default function FeatureFilterReadOnlyPanel({datasets, closePanel, single}) {
    const classes = useStyles();
    const iconSize = 24;
    const intl = useIntl();
    const themeTree = useSelector(state => state.themes.themeTree.result);

    // Collect the feature labels from the theme tree, and also work out the sort order for the datasets
    const [getName, themeTreeCompare] = useMemo(function() {
        const featureTypesLabelMap = {};
        const featureTypesIndexMap = {};

        let index = 1; // We start from 1 because we want all indexes to have truthy values.
        if(themeTree) {
            themeTree.forEach((theme) => {
                theme.collections.forEach((collection) => {
                    collection.featureTypes.forEach((featureType) => {
                        featureTypesLabelMap[featureType.featureId] = featureType.label;
                        featureTypesIndexMap[featureType.featureId] = index++;
                    });
                });
            });
        }

        function getName(featureTypeId){
            // Default to the featureId if no name is found for whatever reason
            return featureTypesLabelMap[featureTypeId] || featureTypeId;
        }
        function themeTreeCompare(datasetA, datasetB) {
            // We should have all the info we need, but if we fail to find an index in the map (perhaps the theme tree
            // isn't loaded yet), then we just let unknown content have a fixed index that is higher than all the
            // known ones.
            let indexA = featureTypesIndexMap[datasetA.featureTypeId] || index;
            let indexB = featureTypesIndexMap[datasetB.featureTypeId] || index;
            return indexA - indexB;
        }

        return [getName, themeTreeCompare];
    }, [themeTree]);


    // Show a loading spinner while we don't have a dataset
    const isLoading = !datasets || !datasets.length;
    let panelContent = null;

    if(!isLoading) {
        if(single) {
            panelContent = <>
                <Typography variant='h3' paragraph={true}>
                    {getName(datasets[0].featureTypeId)}
                </Typography>
                <FeatureQueryViewer filterExpression={datasets[0].filterExpression}/>
            </>;
        } else {
            const filteredItems = datasets
                .filter(dataset => dataset.filterExpression)
                .sort(themeTreeCompare)
                .map(dataset => ({
                    id: dataset.featureTypeId,
                    label: getName(dataset.featureTypeId),
                    content: <FeatureQueryViewer filterExpression={dataset.filterExpression}/>
                }));
            panelContent = <ExpansionList options={filteredItems}
                                          classes={{
                                              root: classes.expansionRoot,
                                              expandDetails: classes.expandDetails
                                          }}
                                          viewAllLabel={messages.expandAll}
                                          closeAllLabel={messages.collapseAll}
                                          initialOpenAll={true}
            />;
        }
    }

    return <div className={classes.rightSide}>
        <div className={classes.heading}>
            <Typography variant='h2' color='primary'>
                {!single ? <FormattedMessage {...messages.headingAll} /> : <FormattedMessage {...messages.headingSingle} values={{featureId : datasets && getName(datasets[0].featureTypeId)}} />}
            </Typography>
            <IconButton size='small'
                        aria-label={intl.formatMessage(messages.closeButton)}
                        className={classes.closeButton}
                        onClick={() => closePanel()}>
                <CloseIcon width={iconSize} height={iconSize} />
            </IconButton>
        </div>
        {isLoading ? <CircularProgress className={classes.loadingSpinner} size={32}/> :
            <div className={classes.body}>
                {panelContent}
            </div>
        }
    </div>;
}

FeatureFilterReadOnlyPanel.propTypes = {
    datasets: PropTypes.array,             // The datasets to view the filters for
    closePanel: PropTypes.func.isRequired, // Callback to close the panel
    single:     PropTypes.bool             // Acts as a hint, so that we know which labels to use at the top of the panel
}