import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {InputLabel} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {FieldError, osColour} from "omse-components";
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from "react-redux";
import {updateDraftOrder} from '../../../../modules/dataPackages/actions';
import ClickAwayTooltip from "../../../../components/ClickAwayTooltip";
import {ReactComponent as HelpIcon} from "../../../../components/icons/help-notification.svg";
import {DatePicker} from '@mui/x-date-pickers';
import {toISODate} from "../../../../../shared/dateUtils";
import LayoutWithKeyboardView from '../../../../components/datePicker/LayoutWithKeyboardView';
import ToolbarWithKeyboardViewSwitch from '../../../../components/datePicker/ToolbarWithKeyboardViewSwitch';

const styles = createUseStyles(theme => ({
    field: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        maxWidth: 396,
        margin: theme.spacing(1.5,1,0,1),
        '& label': {
            marginBottom: theme.spacing(1)
        }
    },
    datePickerWithTooltip: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    tooltip: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
        marginLeft: theme.spacing(1)
    },
    datePicker: {
        maxWidth: "360px",
        backgroundColor: "#f5f5f5",
        lineHeight: "1.5rem",
        marginRight: "10px",
        "& div" : {
            backgroundColor: "#ffffff",
        },
        "& input" : {
            width: "300px",
            color: "#333333",
            borderRadius: "3px",
            padding: '10px 40px 10px 15px !important'
        },
        "& p" : {
            backgroundColor: "#f5f5f5",
        },
        "& button" : {
            marginRight: "2px"
        }
    }
}));

function isValidDate(date, dateRange) {
    return date && date instanceof Date && (date <= dateRange.maxDate && date >= dateRange.minDate);
}

export default function DataPackageDatePicker(props) {
    const {fieldName, labelMessage, fieldErrorMessage, fieldErrorMessageValues, dateRange, tooltipMessage, tooltipAriaLabelMessage} = props;
    const classes = styles();
    const dispatch = useDispatch();
    const intl = useIntl();
    const [selectedDate, setSelectedDate] = useState(dateRange.maxDate);

    const displayErrors = useSelector((state) => state.dataPackages.draftOrder.displayErrors);

    useEffect(() => {
        // If the date inside the picker is not a valid date then we store a null into the draft order, as then the
        // SubmitButton can do simple validation without thinking about the valid date range.
        let chosenDate = null;
        if(isValidDate(selectedDate, dateRange)) {
            chosenDate = toISODate(selectedDate);
        }
        dispatch(updateDraftOrder(fieldName, chosenDate));
    }, [dispatch, fieldName, selectedDate, dateRange]);

    function Tooltip() {
        if(tooltipMessage && tooltipAriaLabelMessage){
            return (
                <ClickAwayTooltip id={fieldName}
                                  icon={<HelpIcon width={24} height={24}
                                                  style={{
                                                      color: osColour.primary.berry
                                                  }}/>}
                                  body={tooltipMessage}
                                  ariaLabel={intl.formatMessage(tooltipAriaLabelMessage)}
                                  disabled={false}
                />
            );
        }
        else {
            return null
        }
    }
    const error = (displayErrors && !isValidDate(selectedDate, dateRange)) ? fieldErrorMessage : null;

    const optionsLabelId = fieldName + '_label';
    return (
        <div className={classes.field}>
            <InputLabel id={optionsLabelId} className={classes.optionsLabel}>
                <FormattedMessage {...labelMessage}/>
            </InputLabel>
            <div className={classes.datePickerWithTooltip}>
                <DatePicker
                    openTo="year"
                    views={['year', 'month', 'day']}
                    value={selectedDate}
                    onChange={setSelectedDate}
                    maxDate={dateRange.maxDate}
                    minDate={dateRange.minDate}
                    format="dd/MM/yyyy"
                    slots={{
                        layout: LayoutWithKeyboardView,
                        toolbar: ToolbarWithKeyboardViewSwitch
                    }}
                    slotProps={{
                        textField: {
                            variant: 'standard',
                            className: classes.datePicker,
                            helperText: null,
                            inputProps: {
                                "aria-labelledby": optionsLabelId
                            }
                        },
                        popper: {
                            popperOptions: {
                                strategy: 'fixed',
                                modifiers: [
                                    {
                                        name: 'preventOverflow',
                                        enabled: true,
                                        options: {
                                            padding: 5
                                        }
                                    }
                                ]
                            }
                        }
                    }}
                />
                <Tooltip className={classes.tooltip} />
            </div>
            <FieldError error={error} errorValues={fieldErrorMessageValues}/>
        </div>
    )
}

DataPackageDatePicker.propTypes = {
    fieldName: PropTypes.string.isRequired, 
    labelMessage: PropTypes.object.isRequired,
    fieldErrorMessage: PropTypes.object.isRequired,
    fieldErrorMessageValues: PropTypes.object.isRequired,
    dateRange: PropTypes.object.isRequired,
    tooltipMessage: PropTypes.object,
    tooltipAriaLabelMessage: PropTypes.object
};
