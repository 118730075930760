import featureCheck from "../../../../util/featureCheck";
import features from "../../../../../shared/features"

export const mapView = config => {
    let baseMapView = [
        {
            productName: 'OS Maps API (WMTS)',
            transactionTypes: [
                {name: '1 map view (15 raster tiles)'}
            ]
        },
        {
            productName: 'OS Vector Tile API (VTS)',
            transactionTypes: [
                {name: '1 map view (4 vector tiles)'}
            ],
        }];

    if (featureCheck(features.NGD_TILES, config)) {
        baseMapView.splice(0, 0, {
            productName: 'OS NGD API – Tiles',
            transactionTypes: [
                {name: '1 map view (4 vector tiles)'}
            ],
            publicSectorAndPremiumOnly: true,

        })
    }
    return baseMapView;
}

export const dataProducts = config => {
    let baseDataProducts = [
        {
            productName: 'OS Maps API (WMTS)',
            transactionTypes: [
                {name: '1 map view (15 raster tiles)'}
            ],
        },
        {
            productName: 'OS Vector Tile API (VTS)',
            transactionTypes: [
                {name: '1 map view (4 vector tiles)'}
            ],
        },
        {
            productName: 'OS Features API (WFS)',
            transactionTypes: [
                {name: '1 feature request (1 response returning up to 100 features)'}
            ],
        },
        {
            productName: 'OS Names API',
            transactionTypes: [
                {name: '1 feature request (1 response returning up to 100 features)'}
            ],
        },
        {
            productName: 'OS Linked Identifiers API',
            transactionTypes: [
                {name: '1 request'}
            ],
        },
        {
            productName: 'OS Places API',
            transactionTypes: [
                {name: '1 feature request (1 response returning up to 100 addresses)'}
            ],
            publicSectorAndPremiumOnly: true,
        },
        {
            productName: 'OS Match & Cleanse API',
            transactionTypes: [
                {name: '1 feature request (1 response returning up to 100 addresses)'}
            ],
            premiumOnly: true,
            excludeEai: true
        }]
    if (featureCheck(features.NGD_TILES, config)) {
        baseDataProducts.splice(0, 0, {
            productName: 'OS NGD API – Tiles',
            transactionTypes: [
                {name: '1 map view (4 vector tiles)'}
            ],
            publicSectorAndPremiumOnly: true,
        })
    }
    if (featureCheck(features.NGD_FEATURES, config)) {
        baseDataProducts.splice(0, 0, {
            productName: 'OS NGD API – Features',
            transactionTypes: [
                {name: '1 feature request (1 response returning up to 100 features)'}
            ],
            publicSectorAndPremiumOnly: true,

        })
    }

    return baseDataProducts
}

export const featureRequestType = config => {
    let baseFeatureRequestType = [
        {
            productName: 'OS Features API (WFS)',
            transactionTypes: [
                {name: '1 feature request (1 response returning up to 100 features)'}
            ],
        },
        {
            productName: 'OS Names API',
            transactionTypes: [
                {name: '1 feature request (1 response returning up to 100 features)'}
            ],
        },

        {
            productName: 'OS Linked Identifiers API',
            transactionTypes: [
                {name: '1 request'}
            ],
        },
        {
            productName: 'OS Places API',
            transactionTypes: [
                {name: '1 feature request (1 response returning up to 100 addresses)'}
            ],
            publicSectorAndPremiumOnly: true,
        },
        {
            productName: 'OS Match & Cleanse API',
            transactionTypes: [
                {name: '1 feature request (1 response returning up to 100 addresses)'}
            ],
            premiumOnly: true,
        }]

    if (featureCheck(features.NGD_FEATURES, config)) {
        baseFeatureRequestType.unshift({
            productName: 'OS NGD API – Features',
            transactionTypes: [
                {name: '1 feature request (1 response returning up to 100 features)'}
            ],
            publicSectorAndPremiumOnly: true,
        })
    }
    return baseFeatureRequestType;
}
