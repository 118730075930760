/**
 * JSS conversion of prismjs/themes/prism-coy.css
 */
export const CodeSnippetStyle = {
    codeSnippet: {
        '& code[class*="language-"],& pre[class*="language-"]': {
            color: 'black',
            background: 'none',
            fontFamily: "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
            fontSize: '0.9rem',
            textAlign: 'left',
            whiteSpace: 'pre',
            wordSpacing: 'normal',
            wordBreak: 'normal',
            wordWrap: 'normal',
            lineHeight: 1.5,
            '-moz-tab-size': 4,
            '-o-tab-size': 4,
            'tab-size': 4,
            '-webkit-hyphens': 'none',
            '-moz-hyphens': 'none',
            '-ms-hyphens': 'none',
            'hyphens': 'none'
        },
        '& pre[class*="language-"]': {
            position: 'relative',
            margin: '.5em 0',
            overflow: 'visible',
            padding: 0
        },
        '& pre[class*="language-"]>code': {
            backgroundColor: '#F8FBFD',
            padding: '1em'
        },
        '& code[class*="language-"]': {
            maxHeight: 'inherit',
            height: 'inherit',
            padding: '0 1em',
            display: 'block',
            overflow: 'auto'
        },
        /* Inline code */
        '& :not(pre) > code[class*="language-"]': {
            position: 'relative',
            padding: '.2em',
            borderRadius: '0.3em',
            color: '#c92c2c',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'inline',
            whiteSpace: 'normal'
        },
        '& .token.comment,& .token.block-comment,& .token.prolog,& .token.doctype,& .token.cdata': {
            color: '#687480'
        },
        '& .token.punctuation': {
            color: '#5F6364'
        },
        '& .token.property,& .token.tag,& .token.boolean,& .token.number,& .token.function-name,& .token.constant,& .token.symbol,& .token.deleted': {
            color: '#c92c2c'
        }, 
        '& .token.selector,& .token.attr-name,& .token.string,& .token.char,& .token.function,& .token.builtin,& .token.inserted': {
            color: '#288408'
        },
        '& .token.operator,& .token.entity,& .token.url,& .token.variable': {
            color: '#8f6d4d',
            background: 'rgba(255, 255, 255, 0.5)'
        },
        '& .token.atrule,& .token.attr-value,& .token.keyword,& .token.class-name': {
            color: '#157c9e'
        },
        '& .token.regex,& .token.important': {
            color: '#9f6600'
        },
        '& .language-css .token.string, & .style .token.string': {
            color: '#8f6d4d',
            background: 'rgba(255, 255, 255, 0.5)'
        },
        '& .token.important': {
            fontWeight: 'normal'
        },
        '& .token.bold': {
            fontWeight: 'bold'
        },
        '& .token.italic': {
            fontStyle: 'italic'
        },
        '& .token.entity': {
            cursor: 'help'
        },
        '& .token.namespace': {
            opacity: .7
        },
        /* Plugin styles: Line Numbers */
        '& pre[class*="language-"].line-numbers.line-numbers': {
            paddingLeft: 0
        },
        '& pre[class*="language-"].line-numbers.line-numbers code': {
            paddingLeft: '3.8em'
        },
        '& pre[class*="language-"].line-numbers.line-numbers .line-numbers-rows': {
            left: 0
        },
        /* Plugin styles: Line Highlight */
        '& pre[class*="language-"][data-line]': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0
        },
        '& pre[data-line] code': {
            position: 'relative',
            paddingLeft: '4em'
        },
        '& pre .line-highlight': {
            marginTop: 0
        }
    }
};