import React from "react";
import styled from "@emotion/styled";
import { osColour } from "omse-components";
import { Typography } from "@mui/material";
import { defineMessages, useIntl } from "react-intl";

const sharedWithMeMessages = defineMessages({
    name: {
        id: "sharedWithMeMessages.name",
        defaultMessage: "Shared with me",
        description: "SharedWithMeTag > Name",
    },
    ariaLabel: {
        id: "sharedWithMeMessages.ariaLabel",
        defaultMessage: "Decorative label: Shared with me",
        description: "SharedWithMeTag > Decorative Aria-Label",
    },
});

const Tag = styled("div")`
    display: inline-flex;
    height: 28px;
    color: ${osColour.neutral.white};
    background: ${osColour.primary.foxglove};
    & > span {
        padding: 7px 7px 7px 13px;
        line-height: 1;
        font-weight: 600;
        white-space: nowrap;
        -webkit-font-smoothing: antialiased;
    }
    & > svg {
        position: relative;
        right: -0.5;
        top: -1;
    }
`;

export default function SharedWithMeTag() {
    const intl = useIntl();
    const name = intl.formatMessage(sharedWithMeMessages.name);
    const ariaLabel = intl.formatMessage(sharedWithMeMessages.ariaLabel);

    return (
        <Tag alt="" role="img" aria-label={ariaLabel}>
            <Typography variant="caption">{name}</Typography>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                width="9"
                role="img"
                alt=""
                aria-hidden={true}
            >
                <polygon fill="white" points="0,30 8,0 9,0 9,30" />
            </svg>
        </Tag>
    );
}
