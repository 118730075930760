import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { osColour, theme } from "omse-components";

const ActionsContainer = styled("nav")`
    margin: 0;
    padding: 1em 0 0 0;
    display: flex;
    gap: 2em;
    align-items: center;
    justify-content: flex-end;
    ${theme.breakpoints.down("sm")} {
        justify-content: space-between;
    }
    border-top: 1px solid ${osColour.neutral.mist};
`;

export default function RecentItemsActions({ className, children}) {
    return <ActionsContainer className={className}>{children}</ActionsContainer>;
}

RecentItemsActions.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
}