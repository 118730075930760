import React, {Fragment, Component} from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {Notification} from 'omse-components';
import withStyles from 'react-jss';

function style(theme) {
    return {
        notification: {
            marginBottom: theme.spacing(3),
            paddingRight: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                marginLeft: 16,
                marginRight: 16,
                '& p': {
                    paddingLeft: '0 !important'
                }
            }
        }
    };
}

export class IntegrateIntro extends Component {
        render() {
            const {api, classes, children} = this.props;
            return <Fragment>
                        <Typography variant='h1' component= {'h2'}>Instructions to Integrate with {api}</Typography>
                        <Typography variant='body1' paragraph={true} component='div'>
                            <p>The following software applications can be used to integrate with the {api}.&nbsp;
                                {children}
                            </p>

                            <p>Click on a heading to see the version of the software used to produce the guide and instructions on how to integrate with the {api} service.</p>
                        </Typography>
                        <Notification variant='info' appearance='inline' classes={{root: classes.notification}}>
                            <Typography variant='body1'>
                                {api === 'OS NGD API – Features' ? 'It\'s ' : 'When using a premium plan, it\'s ' }
                                 best not to select a lot of features, or a large geographic area, in the GIS software—they
                                can easily use up transactions.
                            </Typography>
                        </Notification>
                    </Fragment>
            }
}

IntegrateIntro.propTypes = {
    api: PropTypes.string.isRequired,
    children: PropTypes.node
}

export default withStyles(style)(IntegrateIntro);