import React from 'react';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import {osColour} from 'omse-components';
import PropTypes from 'prop-types';
import InlineCodeSnippet from "../../components/InlineCodeSnippet";

const styles = {
    bordered: {
        border: `solid ${osColour.neutral.mist} 1px`
    }
};

//TODO: Review images and alt text
export function QGIS(props) {
    const {classes, api, category, wfsDetails, qgisImages, children} = props;
    return <div>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    QGIS is an open GIS (Geospatial Information System) desktop application that allows you to display,
                    interrogate, visualise and create geospatial information including from geo-centric APIs (for
                    example, a WFS).
                </p>
                <p>
                    {wfsDetails ?
                        'The instructions that follow demonstrate how to integrate and apply the OS Features API into QGIS in order to produce a series of topography layers based on our latest OS MasterMap Topography layer data.'
                        :
                        'The instructions that follow demonstrate how to integrate and apply the OS NGD API – Features into QGIS using QGIS version 3.22 onwards. Other versions of QGIS can be used, from version 3.12 onwards.'
                    }
                </p>
                {wfsDetails && (
                    <p>For the purposes of this guide the version of QGIS used is 3.4.</p>
                )}
            </Typography>
            <Typography variant='h2'>Integrating {api} in QGIS</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <ol>
                    <li>
                        <p>Open a blank document in QGIS. Uncheck the "Render" box for now.</p>
                        <img src={qgisImages.qgis0} alt='QGIS render box' className={classes.bordered} />
                    </li>
                    <li>
                        <p>Navigate to Layer → Add Layer → Add WFS Layer...</p>
                        <img src={qgisImages.qgis1} alt='QGIS Add Layer option' className={classes.bordered} />
                        <p>The following window will be displayed:</p>
                        <img src={qgisImages.qgis2} alt='QGIS Add WFS Layer window'/>
                    </li>
                    <li>
                        <p>
                            If you have connected to a {category} before, you will have the options available in the drop down
                            immediately under the layers tab. If you've connected to {api} before,
                            select the layer in the drop-down menu and click Connect.
                        </p>
                        <img src={qgisImages.qgis3} alt='QGIS Add WFS Layer window options'/>
                    </li>
                    <li>
                        <p>If you have NOT called {wfsDetails ? api : category}  before, click the New button below the drop-down menu.</p>
                        <img src={qgisImages.qgis4} alt='QGIS Add WFS Layer window - New button'/>
                    </li>
                    <li>
                        <p>The window that pops up requires the API information you obtained in the OS Data Hub.</p>
                        <img src={qgisImages.qgis5} alt='QGIS New WFS Connection dialog window' className={classes.bordered}/>
                    </li>
                    <li>
                        <p>Provide the service details of the API as follows:</p>
                        <ol type='a'>
                            <li>
                                Provide a name for the API. It is good practice to name your connections in a way that
                                makes them instantly recognisable.
                            </li>
                            <li>
                                Input the {api} URL in the URL box.
                            </li>
                            <li>
                                Your API Key has been generated in the OS Data Hub and would have been automatically
                                added to the URL.
                            </li>
                            <li>
                                Click the Detect button to identify the {wfsDetails ? 'WFS version number' :'version' }.
                            </li>
                            <li>
                                As best practice we recommend that you limit your max number of features and page size to <InlineCodeSnippet>100</InlineCodeSnippet> to
                                ensure a smooth service. Larger values may result in a very slow response.
                            </li>
                        </ol>
                        <p>You will not be required to put styling, zoom or coordinate information in the URL. This will
                            be added at a later stage by the GIS application.</p>
                        <img src={qgisImages.qgis6} alt='QGIS New WFS Connection dialog filled in' className={classes.bordered} width="448"/>
                    </li>
                    <li>
                        <p>Leave all the other options blank and click OK.</p>
                        <p>It is worth noting that you will NOT require a User name or Password to use the service as
                            all authentication is done through your {api} key.</p>
                        <p>Once you've completed the initial connection, the details you have supplied will be saved by
                            your QGIS application and it can be used with any of your new or existing geospatial
                            projects.</p>
                    </li>
                    <li>
                        <p>The next step is to select the Server connection in the drop-down list and click Connect.</p>
                        <img src={qgisImages.qgis7} alt='QGIS WFS Data Source Manager - Server connections' width="800" />
                    </li>
                    <li>
                        <p>
                            You will be presented with a list of features. Click on the ones you want to apply. To
                            select more than one feature, use the Ctrl key when choosing.
                        </p>
                        <img src={qgisImages.qgis8} alt='QGIS WFS Data Source Manager - Features list' width="800" />
                    </li>
                    <li>
                        <p>
                            Depending on what you're attempting to do, you could either choose all layers
                            {wfsDetails && ' to simulate the OS MasterMap Topography Layer,' }
                            &nbsp;or individual layers with features of specific interest.
                            This can then be combined with back-drop mapping for visualisation, interrogation and analytical
                            purposes.
                        </p>
                    </li>
                    <li>
                        <p>
                            It is best practice to load only the feature/s which you are interested in. In order to
                            engage the bounding box, ensure you tick the option to <b>Only request features overlapping
                            the view extent</b>. In other words, only selected features within the viewing window will
                            load, not everything. Your viewing window in your GIS application sets your bbox.
                        </p>
                        <img src={qgisImages.qgis9}
                             alt='QGIS WFS Data Source Manager - Limit to request features overlapping the view extent checkbox'
                             width="800" />
                    </li>
                    <li>
                        <p>
                            Select the appropriate layer/s and click <i>Add</i>. This could take several minutes
                            depending on which features you choose to apply.
                        </p>
                        <p>
                            Your data will be presented at a GB-wide scale (1:4,400,000 scale).
                        </p>
                    </li>
                    <li>
                        <p>
                            Please be aware that each feature, regardless of its layer, will count towards the request
                            restriction and so the more types of features you call, the longer it will take to load into the
                            GIS.
                        </p>
                    </li>
                    <li>
                        <p>
                            We recommend you <b>uncheck the WFS layer/s you have called in the layers
                            panel</b> to limit the amount of feature transactions that are used. The layer
                            selection will not cause data to be loaded immediately however, because the "Render" option should be
                            unchecked (instructed in the first step of this guide).
                        </p>
                        <img src={qgisImages.qgis10} alt='QGIS Unchecked WFS layers in the layers panel' className={classes.bordered} />
                    </li>
                    <li>
                        <p>
                            Once you have done this, zoom to your desired level (we recommend a scale of 1:1,500 or
                            below for WFS requests to multiple layers).
                        </p>
                        <p>
                            Next, we'll configure the scale range at which a specific feature becomes visible. This will
                            allow you to navigate and pan to your area of interest using a background map, without
                            calling the {api} service when zoomed out beyond these restrictions.
                        </p>
                    </li>
                    <li>
                        Right click on a layer and click Properties. Or double click on the layer name.
                    </li>
                    <li>
                        <p>For this exercise, you'll need to click the Rendering sub-menu.</p>
                        <img src={qgisImages.qgis11} alt='QGIS Layer Properties showing the Rendering option' />
                    </li>
                    <li>
                        <p>In this menu, we highly recommend that you tick the Scale Dependent Visibility option and manually set the parameters.</p>
                        <img src={qgisImages.qgis12} alt='QGIS Layer Properties Scale Dependent Visibility option' />
                    </li>
                    <li>
                        <p>
                            Rather counter-intuitively, you want the higher value in the Minimum (exclusive) and the lower value in the Maximum (inclusive) field. The 'exclusive' and 'inclusive' means that in order to obtain your entire range, you need to input your minimum value +1 and your maximum value -1. This will allow the layer to display up to and including the desired values. The image below shows how this is taken into account for a scale ranging from 1:1,500 (1,501) to 1:50 (49).
                        </p>
                        <img src={qgisImages.qgis13} alt='QGIS Layer Properties Scale Dependent Visibility option checked' />
                    </li>
                    <li>
                        <p>
                            Click Apply. At this point you can optionally close the menu by pressing OK. Alternatively, you can click on the Style tab where you can either load a predefined style sheet or customise your own. Once finished, close the window.
                        </p>
                    </li>
                    <li>
                        <p>
                            You can now tick the{wfsDetails && ' WFS'} feature layer checkbox in the layers panel as well as the Render checkbox and use the data as intended.
                            QGIS will automatically stop calling features should you need to zoom out and reposition your bounding box area of interest.
                        </p>
                        <img src={qgisImages.qgis14} alt='QGIS Project showing layer and render options enabled' />
                    </li>
                </ol>
            </Typography>
            {children}
    </div>
}

QGIS.propTypes = {
    classes: PropTypes.object.isRequired,
    api: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    gqisImages: PropTypes.object,
    children: PropTypes.node
}

QGIS.defaultProps = {
  qgisImages: {}
};

export default withStyles(styles)(QGIS);