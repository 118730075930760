import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {clearLicensedProducts, getLicensedProducts, getPremiumCatalogue} from '../modules/premium/actions';
import {ERROR, LOADING, NOT_NEEDED} from "../constants/state";
import {isIncludedInCatalogue} from '../util/product';
import {COM3_CATALOGUE} from '../../shared/catalogues';
import {hasCom3Catalogue } from "../util/permissions";

export default function useProductDependency(productId) {
    const user = useSelector(state => state.user.current.result);
    const {result: catalogue, error: catalogueError} = useSelector(state => state.premium.catalogue);
    const {result: licensedProducts, loading: licensedProductsLoading, error: licensedProductsError} = useSelector(state => state.premium.licensedProducts);
    const [possibleProductMatches, setPossibleProductMatches] = useState([]);
    const dispatch = useDispatch();
    const enabledUserType = useCallback(user => hasCom3Catalogue(user), []);
    const isInSupportedCatalogue = product => isIncludedInCatalogue(product, COM3_CATALOGUE);
    const [catalogueConfigError, setCatalogueConfigError] = useState(false); 

    // Clear existing redux state so licensed products will be loaded/reloaded if they are needed.
    useEffect(() => {
        if (enabledUserType(user)) {
            dispatch(clearLicensedProducts());
        }
    }, [dispatch, user, enabledUserType]);

    // Determine whether product licences need to be requested, if required, dispatch.
    useEffect(() => {
        if (enabledUserType(user)) {

            // Get catalogue
            if (!catalogue) {
                dispatch(getPremiumCatalogue());
            
            } else if (catalogue.items?.length > 0) {

                // Find the product in the catalogue.
                const product = catalogue.items.find(p => p.id === productId);

                // Product has a dependency.
                if (product?.bmiCodeDependency) {
                
                    const results = catalogue.items.filter(p => p.bmiCode === product.bmiCodeDependency);
                    
                    // Required dependency was not found in the catalogue, or there are matches but none are in a supported catalogue.
                    if (results.length === 0 || (results.filter(p => isInSupportedCatalogue(p)).length === 0)) {
                        setCatalogueConfigError(true);

                    // The dependency resolves to possible products in catalogue, licensed products are required, dispatch.
                    } else {
                        setPossibleProductMatches(results);
                        
                        if (!licensedProducts) {
                            dispatch(getLicensedProducts());
                        }
                    }
                }
            }
        }
    }, [catalogue, dispatch, licensedProducts, productId, user, enabledUserType]);

    if (!enabledUserType(user)) {
        return NOT_NEEDED;
    }

    if(catalogueError || licensedProductsError || catalogueConfigError) {
        return ERROR;
    }

    if (!catalogue || (!licensedProducts && licensedProductsLoading)) {
        return LOADING;
    }

    // Filter results to return possible products which will resolve the dependency.
    // If the dependency is met by any of the products already ordered, return 'not needed'.
    if (Array.isArray(licensedProducts)) {
        // Create sets of IDs.
        const possibleProductMatchIds = new Set(possibleProductMatches.map(p => p.id));
        const licensedProductIds = new Set(licensedProducts);

        // Intersection shows products which meet the dependency and are licensed to the account.
        const metDependencies = new Set([...possibleProductMatchIds].filter(productId => licensedProductIds.has(productId)))

        if (metDependencies.size > 0) {
            return NOT_NEEDED;
        } else {
            return possibleProductMatches;
        }
    }

    return NOT_NEEDED;
}
