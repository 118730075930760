
import {combineReducers} from 'redux';

const SET_TOOLTIP_ACTION = "action:toolbar:setTooltip";
const RESET_TOOLTIP_ACTION = "action:toolbar:resetTooltip";
const SET_MAP_TOOL = "action:toolbar:setMapTool";

export function setTooltip(message) {
    // console.log('Activating tooltip: ' + message.id);
    return {
        type: SET_TOOLTIP_ACTION,
        message
    };
}

export function resetTooltip() {
    // console.log('Dectivating tooltip');
    return {
        type: RESET_TOOLTIP_ACTION
    }
}

export function setMapTool(tool) {
    // console.log('Setting map tool: ' + (tool && tool.type));
    return {
        type: SET_MAP_TOOL,
        tool
    };
}

const initialState = {};
function tooltipReducer(state = initialState, action) {
    switch(action.type) {
        case SET_TOOLTIP_ACTION:
            if(action.message.id === state.id) {
                // No real change, so keep the same object in the store
                return state;
            }
            return action.message;
        case RESET_TOOLTIP_ACTION:
            return initialState;
        default:
            return state;
    }
}

function activeToolReducer(state = initialState, action) {
    switch(action.type) {
        case SET_MAP_TOOL:
            // Deactivate any old map tool
            if(state.deactivate) {
                state.deactivate();
            }

            return action.tool || initialState;
        default:
            return state;
    }
}

export const toolbarReducer = combineReducers({
    activeTool: activeToolReducer,
    tooltip: tooltipReducer
});
