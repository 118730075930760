import React from 'react';
import Typography from '@mui/material/Typography';

export default function UsingWFS(props) {
    return <Typography variant='body1' paragraph={true} component='div'>
        <p>
            With OS Features API:
        </p>
        <p>You can:</p>
        <ul>
            <li>Request specific feature types and their attributes using spatial or attribute queries.</li>
            <li>Visualise and style Ordnance Survey data.</li>
            <li>Interrogate highly detailed feature information, including OS MasterMap Topography Layer.</li>
            <li>Create and apply your own styling preferences.</li>
        </ul>
        <p>You can't:</p>
        <ul>
            <li>Create a scalable map of Great Britain across zoom levels.</li>
            <li>Request more than 100 features in a single transaction.</li>
        </ul>
    </Typography>
}
