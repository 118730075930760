import React from 'react';
import routes from '../../util/routes';
import {defineMessages} from 'react-intl';
import ContentHeader from '../../components/ContentHeader';

const messages = defineMessages({
    backLabel: {
        id: 'Back.label',
        defaultMessage: 'All documentation',
        description: 'Label for the link that takes the user back to the documentation index'
    }
});

export default function Header(props) {
    const {children, back} = props;
    let extraProps = {};
    if (back) {
        extraProps = {
            backPath: routes.documentation,
            backLabel: messages.backLabel
        };
    }
    return <ContentHeader title={children} {...extraProps} />;
}
