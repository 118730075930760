import React, {useState} from 'react';
import {CommonDialog, CommonDialogActions, StyledCheckbox} from 'omse-components';
import PropTypes from 'prop-types'
import {defineMessages, FormattedMessage} from 'react-intl';
import {Typography, FormControlLabel} from '@mui/material';
import {formattedTextToSanitizedHTML} from '../../../../util/text';

const messages = defineMessages({
    title: {
        id: 'ProductTermsDialog.title',
        defaultMessage: '{contractName} - {productName}',
        description: 'Label for the title on the dialog'
    },
    agree: {
        id: 'ProductTermsDialog.agree',
        defaultMessage: 'Agree to terms',
        description: 'Label for the confirmation button on the dialog'
    },
    intro: {
        id: 'ProductTermsDialog.intro',
        defaultMessage: 'This product has extra terms:',
        description: 'Text for the product terms dialog'
    }
});

export default function ProductTermsDialog(props) {
    const {onConfirm, onClose, contract, product} = props;
    const [ticked, setTicked] = useState(false);

    const actions = <CommonDialogActions onConfirm={onConfirm}
                                         onClose={onClose}
                                         confirmLabel={messages.agree}
                                         confirmAllowed={ticked}
    />;

    // productTerms may contain newline chars. need to make sure these are shown nicely on the dialog
    const label = <Typography component='div'>
        <div dangerouslySetInnerHTML={{__html: formattedTextToSanitizedHTML(product.productTerms)}} />
    </Typography>;

    return <CommonDialog actions={actions}
                         onClose={onClose}
                         title={messages.title}
                         titleValues={{
                             contractName: contract.label,
                             productName: product.label
                         }}
    >
        <Typography variant='body2' paragraph={true}>
            <FormattedMessage {...messages.intro}/>
        </Typography>

        <FormControlLabel control={<StyledCheckbox checked={ticked}
                                                   onClick={() => setTicked(!ticked)}/>}
                          label={label}/>
    </CommonDialog>;
}

ProductTermsDialog.propTypes = {
    contract: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};
