// Helper function to map from a NGD schema to a list of fields that we can use with the react-awesome-query-builder components
import {defineMessages} from "react-intl";
import dateformat from "dateformat";
import {fromISOString} from '../../../../shared/dateUtils';

const messages = defineMessages({
    invalidDateFormat: {
        id: 'FilterQuery.invalidDateFormat',
        defaultMessage: 'The supplied date is invalid; please enter a date with format dd/mm/yyyy',
        description: 'Invalid date format error message'
    },
    DateOutOfRange: {
        id: 'FilterQuery.DateOutOfRange',
        defaultMessage: 'Dates must be between {minDate} and {currentDate}',
        description: 'Date out of range error message'
    },
    InvalidNumericValue: {
        id: 'FilterQuery.InvalidNumericValue',
        defaultMessage: 'The entered value is not a valid number',
        description: 'Invalid numeric value error message'
    },
    MaxValueExceeded: {
        id: 'FilterQuery.MaxValueExceeded',
        defaultMessage: 'The value exceeds the maximum of {max}',
        description: 'Max Value Exceeded error message'
    },
    ValueBelowMinimum: {
        id: 'FilterQuery.ValueBelowMinimum',
        defaultMessage: 'The value is bellow the minimum of {min}',
        description: 'Value Below Minimum error message'
    },
    MaxLengthExceeded: {
        id: 'FilterQuery.MaxLengthExceeded',
        defaultMessage: 'String is above the current max length of {maxLength}',
        description: 'Max Length Exceeded error message'
    },
    NotAnInteger: {
        id: 'FilterQuery.NotAnInteger',
        defaultMessage: 'The value must be an integer',
        description: 'Error message shown when a decimal is entered into an integer field'
    },
    NumberTooLong: {
        id: 'FilterQuery.NumberTooLong',
        defaultMessage: 'The value is longer than {maxLength} digits',
        description: 'Error message shown when too many digits are entered into a numeric field'
    }
});

// The query builder library only allows us to return a simple string for the error message, but
// we want to be able to send out translated messages with inserts. We could make calls to the
// react-intl library here to process the message, but the buildFields helper needs to be tested
// outside of the running app, so it's easier to just pack the info we need into a string and
// unpack it when rendering.
function translateMessage(message, values){
    return JSON.stringify({ message, values });
}

function propertyToType(property, propertyName, featureTypeId){
    if(property.format){
        switch (property.format.toLowerCase()){
            case "date":
                return "Date";
            default:
                console.warn("Found unsupported property format. " +
                    "Property: " + featureTypeId + ":" + propertyName + ", " +
                    "format: " + property.format);
                return null;
        }
    }else if(property.isCodelist){
        return "CodeList";
    }else {
        // Nullable types are handled by using a type array like: ["string", "null"], so we assume that the
        // first type in the array is the most important thing to work with.
        const propType = Array.isArray(property.type) ? property.type[0] : property.type;
        switch (propType?.toLowerCase()){
            // case "array":
            //     return "Array";
            case "number":
            case "integer":
            case "long":
                return "Number";
            case "boolean":
                return "Boolean";
            case "string":
                return "String";
            default:
                console.warn("Found unsupported property type. " +
                    "Property: " + featureTypeId + ":" + propertyName + ", " +
                    "type: " + propType);
                return null;
        }
    }
}

const propertyTypeOperatorsMap = {
    "String"   : ['equal', 'not_equal'],
    "Date"     : ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between', 'not_between'],
    "Boolean"  : ['equal'],
    "Number"   : ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between', 'not_between'],
    "CodeList" : ["select_equals", "select_not_equals", "select_any_in", "select_not_any_in"],
    "Array"    : ['multiselect_equals', 'multiselect_not_equals'],
}

const propertyTypeWidgetMap = {
    "String"   : "text",
    "Date"     : "date",
    "Boolean"  : "boolean",
    "Number"   : "number",
    "CodeList" : "select",
    "Array"    : "multiselect",
};

function validateDate(val, _fieldSettings) {
    const date = fromISOString(val);
    // current date needs to be in dd/mm/yyyy format
    const currentDate = dateformat(new Date(), 'dd/mm/yyyy');
    if (!isNaN(date) && !!date) {
        if (date.getFullYear() < 1900 || date > new Date()) {
            // date picker only supports dates from 1900 to 2099
            return translateMessage(messages.DateOutOfRange, {minDate:'01/01/1900', currentDate:currentDate});
        }
        return null;
    }
    return translateMessage(messages.invalidDateFormat);
}

function validateString(val, _fieldSettings) {
    const {maxLength} = _fieldSettings;
     if(val && val.length > maxLength){
        return translateMessage(messages.MaxLengthExceeded, {maxLength});
    }
    return null;
}

function validateNumber(val, _fieldSettings) {
    const {min, max, maxLength, integer} = _fieldSettings;

    if (isNaN(val)) {
        return translateMessage(messages.InvalidNumericValue);
    } else if (!(!min || val >= min)) {
        return translateMessage(messages.ValueBelowMinimum, {min});
    } else if (!(!max || val <= max)) {
        return translateMessage(messages.MaxValueExceeded, {max});
    } else if(integer && !Number.isInteger(val)) {
        return translateMessage(messages.NotAnInteger);
    } else if(val && maxLength && val.toString().length > maxLength) {
        return translateMessage(messages.NumberTooLong, { maxLength });
    }

    return null;
}

const propertyValidatorMap = {
    "String": validateString,
    "Date": validateDate,
    "Number": validateNumber,
    // Todo: fill in validation rules if required
}

function getOperators(type) {
    return propertyTypeOperatorsMap[type];
}

function getCodeList(items) {
    return items.map(item => ({
        value: item.label, // The value we want to pass to the backend needs to match the text that the user chooses
        title: item.label
    }));
}

function getFieldSettings(type, property) {
    let fieldSettings = {};
    if(propertyValidatorMap[type]){
        fieldSettings.validateValue = propertyValidatorMap[type];
    }
    switch (type) {
        case "Number":
            fieldSettings.max = property.max;
            fieldSettings.min = property.min;
            fieldSettings.maxLength = property.maxLength;
            if(property.type === 'long' || property.type === 'integer') {
                fieldSettings.integer = true;
            }
            break;
        case "String":
            fieldSettings.maxLength = property.maxLength;
            break;
        case "CodeList":
            fieldSettings.listValues = getCodeList(property.items);
            break;
        case "Array":
            if (property.items && property.items.isCodelist) {
                fieldSettings.listValues = getCodeList(property.items.items);
            }
            break;
        // Lint says we need a default but a default doesn't make
        // sense here; so we just break to appease the lint
        default:
            break;
    }
    return fieldSettings;
}

// This is the custom field builder our Implementation of react-awesome-query-builder
// here we parse the response form the schema service into filterable fields
// If this should be extended more indepth field config example can be found here ->
// https://codesandbox.io/s/github/ukrbublik/react-awesome-query-builder/tree/master/sandbox?file=/src/demo/config_simple.tsx
export default function buildFields(featureTypeSchema){
    const fields = {};
    const properties = featureTypeSchema.properties;
    for (const propertyName in properties) {
        const property = properties[propertyName];
        if(property.downloadFilter) {
            const propertyType = propertyToType(property, propertyName, featureTypeSchema.id);
            if(propertyType) {
                let operators = getOperators(propertyType);
                if(Array.isArray(property.type) && property.type.includes("null")) {
                    operators = [...operators, 'is_null', 'is_not_null'];
                }
                const fieldSettings = getFieldSettings(propertyType, property);
                //const preferWidgets = getPreferWidgets(propertyType);
                fields[propertyName] = {
                    label: property.originalName || propertyName,
                    type: propertyTypeWidgetMap[propertyType],
                    valueSources: ["value"],
                    operators,
                    fieldSettings
                };
            }
        }
    }

    return fields;
}
