import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import { AddButton, InputBox, InputLabel, ExternalLink } from "omse-components";
import {defineMessages, FormattedMessage} from 'react-intl';
import {createUseStyles} from 'react-jss';
import Typography from '@mui/material/Typography';
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {validateJobTitle, validatePhoneNumber} from '../common/fieldValidation';
import { isEaiUser } from "../../../util/plans";

const componentName = 'EnergyAndInfrastructureOrgDetailsForm';

export const messages = defineMessages({
    orgName: {
        id: componentName + '.orgName',
        defaultMessage: 'Organisation name ',
        description: 'Title for the org name input'
    },
    jobTitle: {
        id: componentName + '.jobTitle',
        defaultMessage: 'Job title',
        description: 'Title for the job title input'
    },
    phone: {
        id: componentName + '.phone',
        defaultMessage: 'Your telephone number',
        description: 'Title for the phone number input'
    },
    organisationNotCorrect1: {
        id: componentName + '.organisationNotCorrect1',
        defaultMessage: 'If you are not part of this organisation please {link} to let us know.',
        description: 'message displayed when organisation not correct part 1'
    },
    organisationNotCorrect2: {
        id: componentName + '.organisationNotCorrect2',
        defaultMessage: 'We can’t give you access to the Energy And Infrastructure Plan right now because you have told us we have you listed under the wrong organisation.',
        description: 'message displayed when organisation not correct part 2'
    },
    organisationNotCorrect3: {
        id: componentName + '.organisationNotCorrect3',
        defaultMessage: 'For now you may continue on to get access to our OS OpenData Plan.',
        description: 'message displayed when organisation not correct part 3'
    },
    thisIsTheCorrectOrganisation: {
        id: componentName + '.thisIsTheCorrectOrganisation',
        defaultMessage: 'This is the correct organisation',
        description: 'This is the correct organisation message'
    },
    thisIsNotMyOrganisation: {
        id: componentName + '.thisIsNotMyOrganisation',
        defaultMessage: 'This is not my organisation',
        description: 'This is not my organisation message'
    }
});

const styles = createUseStyles(theme => ({
    buttons: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        '& button:first-of-type': {
            marginRight: theme.spacing(2)
        }
    },
    infoTextWrapper:{
        marginTop: theme.spacing(2)
    },
    infoText:{
        marginBottom: theme.spacing(2)
    },
    content: {
        width: 340,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
}));

export default function EnergyAndInfrastructureOrgDetailsForm(props) {
    const {onAccept, onDecline, acceptLabel, declineLabel, working} = props;
    const user = useSelector(state => state.user.current.result);
    const [modified, setModified] = useState(false);
    const [isOrgCorrect, setIsOrgCorrect] = useState(true);
    const [jobTitle, setJobTitle] = useState(user.jobTitle || '');
    const [phone, setPhone] = useState(user.telephoneNumber || '');
    const classes = styles();

    function accept() {
        onAccept(user.org, jobTitle, phone);
    }

    function decline() {
        onDecline(user.org, jobTitle, phone);
    }

    function handleInput(setter) {
        return e => {
            setModified(true);
            setter(e.target.value);
        }
    }

    let titleError = null;
    let phoneError = null;

    if(modified) {
        titleError = validateJobTitle(user, jobTitle);
        phoneError = validatePhoneNumber(user, phone);
    }

    const ready = !titleError && !phoneError;

    // This form is based on the user arriving with an E&I plan. When used during sign-up, user state is 
    // checked in EnergyAndInfrastructurePlanSetup with suitable instructions and alternative actions 
    // shown, in which case this form is not used.
    if (!isEaiUser(user)) {
        return null;
    }

    return <div className={classes.content}>
        <InputLabel>
            <FormattedMessage {...messages.orgName}/>
        </InputLabel>

        <div className={classes.infoTextWrapper}>
            <Typography id='eai.orgName' variant='body1'>
                {user.org}
            </Typography>

            <RadioGroup className={classes.infoTextWrapper} name="isCorrectOrg" value={isOrgCorrect} 
                onChange={(event) => {setIsOrgCorrect(event.target.value === "true")}}>
                <FormControlLabel value={true} control={<Radio />} 
                    label={<Typography variant='body1'>
                        <FormattedMessage {...messages.thisIsTheCorrectOrganisation}/>
                    </Typography>} />
                <FormControlLabel value={false} control={<Radio />} 
                    label={<Typography variant='body1'>
                        <FormattedMessage {...messages.thisIsNotMyOrganisation}/>
                    </Typography>} />
            </RadioGroup>
        </div>

        {!isOrgCorrect &&
            <Fragment>
                <div className={classes.infoTextWrapper}>
                    <Typography variant='body1' className={classes.infoText}>
                        <FormattedMessage {...messages.organisationNotCorrect1} values={{ link: <ExternalLink type='support' /> }} />
                    </Typography>

                    <Typography variant='body1' className={classes.infoText}>
                        <FormattedMessage {...messages.organisationNotCorrect2} />
                    </Typography>

                    <Typography variant='body1' className={classes.infoText}>
                        <FormattedMessage {...messages.organisationNotCorrect3} />
                    </Typography>
                </div>

                <div className={classes.buttons}>
                    <AddButton action={decline}
                        label={declineLabel}
                        working={working}
                        disabled={!ready}
                        showIcon={false}/>
                </div>
            </Fragment>
        }

        {isOrgCorrect &&
            <Fragment>
                <InputBox id='JobTitle'
                          label={messages.jobTitle}
                          error={titleError}
                          value={jobTitle}
                          fullWidth={true}
                          onChange={handleInput(setJobTitle)}/>

                <InputBox id='Phone'
                          label={messages.phone}
                          error={phoneError}
                          value={phone}
                          fullWidth={true}
                          onChange={handleInput(setPhone)}/>

                <div className={classes.buttons}>
                    <AddButton action={accept}
                               label={acceptLabel}
                               working={working}
                               disabled={!ready}
                               showIcon={false}/>
                </div>
            </Fragment>
        }
    </div>;
}

EnergyAndInfrastructureOrgDetailsForm.propTypes = {
    onAccept: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    acceptLabel: PropTypes.object.isRequired,
    declineLabel: PropTypes.object.isRequired,
    working: PropTypes.bool
};
