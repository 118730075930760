import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {ExpansionList, InternalLink} from 'omse-components';
import Parameter from './authentication/APIKeyParameter';
import Header from './authentication/APIKeyHeader';
import OAuth from './authentication/OAuth';
import {defineMessages} from "react-intl";

const messages = defineMessages({
    viewAll: {
        id: 'Documentation.viewAll',
        defaultMessage: 'View all',
        description: 'Label for the View all link'
    },
    closeAll: {
        id: 'Documentation.closeAll',
        defaultMessage: 'Close all',
        description: 'Label for the Close all link'
    }
});

export default function Authentication(props) {
    const {gettingStartedRoute} = props;

    const options = [
        {
            label: 'HTTP Header',
            content: <Header/>
        },
        {
            label: 'HTTP Query Parameter',
            content: <Parameter/>
        },
        {
            label: 'OAuth 2',
            content: <OAuth/>
        }
    ];

    return <Fragment>
        <Typography variant='h2'>Authentication</Typography>
        <Typography variant='body1' component='div'>
            <p>
                To use this API you need to authenticate your requests. Before you can authenticate your request you need to
                add the API into an API Project, which will give you access to a Project API Key and Project API Secret. See
                the <InternalLink path={gettingStartedRoute} message="getting started guide" /> for more information on setting up your API Project.
            </p>
            <p>
                There are three options for authenticating your API requests:
            </p>
        </Typography>
        <ExpansionList options={options} viewAllLabel={messages.viewAll} closeAllLabel={messages.clearAll} />
    </Fragment>
}

Authentication.propTypes = {
    gettingStartedRoute: PropTypes.string
}