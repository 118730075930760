import {useSelector} from 'react-redux';
import {hasPartnerCatalogue} from "../util/permissions";
import {NOT_NEEDED, LOADING, ERROR} from '../constants/state';

export default function usePartnerContracts(productId) {
    const user = useSelector(state => state.user.current.result);
    const contracts = useSelector(state => state.partners.contracts.result);
    const catalogue = useSelector(state => state.partners.catalogue.result);
    const error = useSelector(state => state.partners.contracts.error || state.partners.catalogue.error);

    if(!hasPartnerCatalogue(user)) {
        return NOT_NEEDED;
    }

    if(error) {
        return ERROR;
    }

    if(catalogue && contracts) {
        const product = catalogue.products.find(c => c.id === productId);
        if(product) {
            const bmiCode = product.bmiCode;
            const licences = catalogue.contracts
                .filter(c => {
                    const id = c.id;
                    const contract = contracts[id];
                    if(contract) {
                        const bmi = contract.bmis[bmiCode];
                        if(bmi) {
                            return bmi.active;
                        }
                    }
                    return false;
                })
                .map(c => c.label);
            return licences;

        } else {
            // This product does not seem to be a partner product, as we can't find it in the
            // catalogue. We return a value to signal that to the caller
            return NOT_NEEDED;
        }
    }
    return LOADING;
}
