import React, {useState, useEffect} from 'react';
import useActionIdSelector from "../../../../hooks/useActionIdSelector";
import {validateCqlFilter} from "../../../../modules/recipeEditor/actions";
import {useSelector} from "react-redux";
import {selectFeatureTypes, setFeatureTypeFilter} from "../../../../modules/recipeEditor/actions";
import ChangingSchemaVersionDialog from "./ChangingSchemaVersionDialog";

/**
 * React hook to help manage the checking and validation we need to do before changing a schema version. Returns
 * a function to initiate a change, and may return a dialog if we need to show one to the user.
 *
 * The steps that need to happen to verify the change are a bit tricky. If there is a saved filter then we need to
 * make an API call to check it. Once the check is complete then we might need to pop a dialog to ask permission to
 * discard it.
 *
 * Saved Filter | Check needed | Dialog needed
 * yes          | yes          | only if check fails
 * no           | no           | no
 *
 */
export default function useChangeSchemaVersion(featureTypeId) {
    const [dialog, setDialog] = useState(null);
    const [versionChangeAction, setVersionChangeAction] = useState(null);
    const [validateState, dispatch] = useActionIdSelector('recipeEditor.validateCqlFilter');
    const {featureTypes} = useSelector(store => store.recipeEditor);

    useEffect(function() {
        // Only do something if we have a change in progress. It might just have finished
        if(versionChangeAction && !validateState.working) {
            if(validateState.result?.isValid) {
                // The saved filter is valid. We switch to the new version without a dialog
                if(versionChangeAction) {
                    dispatch(versionChangeAction);
                    setVersionChangeAction(null);
                }
            } else if(validateState.result || validateState.error) {
                // We need permission to discard the filter
                function onConfirm() {
                    // We have been told to go ahead, so clear the old filter
                    dispatch(setFeatureTypeFilter(featureTypeId, null, null));

                    if(versionChangeAction) {
                        // Make the version change
                        dispatch(versionChangeAction);
                        setVersionChangeAction(null);
                    }

                    // Close the dialog off too
                    setDialog(null);
                }

                function onClose() {
                    // We are giving up on the change. Clear both the dialog and the action
                    setDialog(null);
                    setVersionChangeAction(null);
                }

                setDialog(<ChangingSchemaVersionDialog onConfirm={() => onConfirm(true)}
                                                       onClose={onClose}/>);
            }
        }
    }, [versionChangeAction, validateState, dispatch, featureTypeId]);

    function setSchemaVersion(featureTypeVersion) {
        const feature = featureTypes.find(f => f.featureTypeId === featureTypeId);

        // prepare the action that will make the change
        const action = selectFeatureTypes([{
            featureTypeId,
            featureTypeVersion
        }])

        if(feature && feature.filterExpression) {
            // We need to check the version before we can switch to it, so store the action and start to check
            setVersionChangeAction(action);
            dispatch(validateCqlFilter(featureTypeId, featureTypeVersion, feature.filterExpression));
        } else {
            dispatch(action);
        }
    }

    return [setSchemaVersion, dialog];
}