import React, { useState, useEffect } from "react";
import { logOut, findRoute } from "../../util/routes";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { ADMIN_ROLE, USER_ROLE } from "../../../shared/roles";
import { hasShowVernacularNamesPermission } from "../../util/permissions";
import { debounce } from "lodash";

const DEBOUNCE_DELAY = 500;

const events = ["mousemove", "mousedown", "keydown", "touchstart", "load"];

const vernacularRoutes = [
    // These routes can have an extended timeout.
    findRoute("vernacularNames").path,
    findRoute("vernacularNamesReporting").path,
];

const InactivityLogoutProvider = ({ children }) => {
    const location = useLocation();
    const [timeoutId, setTimeoutId] = useState(null);
    const config = useSelector((state) => state.config.current.result);
    const userDetails = useSelector((state) => state.user.current.result);
    const [timeoutWait, setTimeoutWait] = useState(config?.userInactivityTimeout);

    useEffect(() => {
        const isVnPath = vernacularRoutes.includes(location.pathname);
        const isVnUser = hasShowVernacularNamesPermission(userDetails);
        const isPermittedUser = userDetails?.role === USER_ROLE || userDetails?.role === ADMIN_ROLE;
        const timeoutValue =
            isVnPath && isVnUser && isPermittedUser
                ? config?.vernacularInactivityTimeout
                : config?.userInactivityTimeout;
        setTimeoutWait(timeoutValue);
    }, [location, config, userDetails]);

    const resetTimeout = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        if (timeoutWait) {
            const newTimeoutId = setTimeout(logoutHandler, timeoutWait);
            setTimeoutId(newTimeoutId);
        }
    };

    const logoutHandler = () => {
        logOut();
    };

    // Debounce the resetTimeout function as no there is need to clear
    // the timeout for every nano movement of the mouse
    const handleUserActivity = debounce(() => resetTimeout(), DEBOUNCE_DELAY);

    useEffect(() => {
        if (userDetails) {
            events.forEach((event) => window.addEventListener(event, handleUserActivity));
        }
        return () => {
            events.forEach((event) => window.removeEventListener(event, handleUserActivity));
            clearTimeout(timeoutId);
        };
    });

    return <>{children}</>;
};

export default InactivityLogoutProvider;
