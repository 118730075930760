import React from 'react';
import {FormattedMessage, defineMessages} from 'react-intl';
import {Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {ReactComponent as Icon} from '../../../components/icons/dataPackage-large.svg';
import routes from '../../../util/routes';
import Link from "../../../components/Link";
import useFeatureCheck from "../../../util/useFeatureCheck";
import {NGD_DOWNLOADS} from "../../../../shared/features";

const messages = defineMessages({
    new: {
        id: 'EmptyState.new',
        defaultMessage: "Looks like you're new here",
        description: "Text shown when there are no data packages available"
    },
    useThisTool: {
        id: 'EmptyState.useThisTool',
        defaultMessage: "When you create data packages, they will appear on this page.",
        description: "Text shown when there are no data packages available"
    },
    buttonLabel: {
        id: 'EmptyState.buttonLabel',
        defaultMessage: "Find a product and create your first package",
        description: "Label for the button that is shown when there are no data packages available"
    },
    listHeading: {
        id: 'EmptyState.listHeading',
        defaultMessage: "Get your data in a few simple steps",
        description: "Heading for the list of instructions"
    },
    item1: {
        id: 'EmptyState.item1',
        defaultMessage: "Choose a {productLink}",
        description: "Item in the list of instructions"
    },
    item1ngd: {
        id: 'EmptyState.item1ngd',
        defaultMessage: "Choose a {productLink} or {recipeLink}",
        description: "Item in the list of instructions"
    },
    item2: {
        id: 'EmptyState.item2',
        defaultMessage: "Select or draw your area of interest",
        description: "Item in the list of instructions"
    },
    item3: {
        id: 'EmptyState.item3',
        defaultMessage: "Choose a format",
        description: "Item in the list of instructions"
    },
    item4: {
        id: 'EmptyState.item4',
        defaultMessage: "Tell us your update preferences",
        description: "Item in the list of instructions"
    },
    productLinkText: {
        id: 'productLinkText',
        defaultMessage: "product",
        description: "Name for product link"
    },
    recipeLinkText: {
        id: 'recipeLinkText',
        defaultMessage: "recipe",
        description: "Name for recipe link"
    }
});

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        '& h2': {
            marginBottom: theme.spacing(2)
        }
    },
    button: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    list: {
        textAlign: 'left'
    },
    icon: {
        marginBottom: theme.spacing(4)
    },
    headingBottomMargin: {
        marginBottom: theme.spacing(6)
    }
}));

export default function EmptyState() {
    const classes = useStyles();
    const ngdEnabled = useFeatureCheck(NGD_DOWNLOADS);

    let message1 = messages.item1;
    if(ngdEnabled) {
        message1 = messages.item1ngd;
    }

    return <div className={classes.root}>
        <Icon className={classes.icon}/>
        <Typography variant='h2' color="textPrimary">
            <FormattedMessage {...messages.new}/>
        </Typography>
        <Typography variant='body1' className={classes.headingBottomMargin}>
            <FormattedMessage {...messages.useThisTool}/>
        </Typography>
        <Typography variant='body2'>
            <FormattedMessage {...messages.listHeading}/>
        </Typography>
        <Typography variant='body1' component='div' className={classes.list}>
            <ol>
                <li>
                    <FormattedMessage {...message1} values={{
                        productLink: <Link path={routes.premiumDownloads}>
                            <FormattedMessage {...messages.productLinkText} />
                        </Link>,
                        recipeLink: <Link path={routes.recipeLibrary}>
                            <FormattedMessage {...messages.recipeLinkText} />
                        </Link>
                    }}/>
                </li>
                {
                    [2, 3, 4].map(i => <li key={i}>
                        <FormattedMessage {...messages['item' + i]}/>
                    </li>)
                }
            </ol>
        </Typography>
    </div>;
}
