import React, {useState, useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import Typography from '@mui/material/Typography';
import {defineMessages, FormattedMessage} from 'react-intl';
import {border1, ConfirmationDialog, contentPadding, Notification} from 'omse-components';
import ServiceStatusTable from './ServiceStatusTable';
import Link from '../../components/Link';
import routes from '../../util/routes';
import {getMessageForApi} from './util';
import {clearServiceStatusCache, getApisServiceStatus} from '../../modules/serviceStatus/actions';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import {hasClearServiceStatusCachePermission, hasReadServiceStatusCachePermission} from '../../util/permissions';
import useActionIdSelector from '../../hooks/useActionIdSelector';

const messages = defineMessages({
    title: {
        id: 'ServiceStatus.title',
        defaultMessage: 'Service availability dashboard',
        description: 'Title shown on the service status page'
    },
    apisSubtitle: {
        id: 'ServiceStatus.apisSubtitle',
        defaultMessage: 'OS APIs status last 24 hours',
        description: 'Subtitle shown for OS APIs service status section'
    },
    dataHubSubtitle: {
        id: 'ServiceStatus.dataHubSubtitle',
        defaultMessage: 'OS Data Hub status last 24 hours',
        description: 'Subtitle shown for OS Data Hub service status section'
    },
    supportActions: {
        id: 'ServiceStatus.supportActions',
        defaultMessage: 'Support actions',
        description: 'Heading of support actions section'
    },
    viewCacheContent: {
        id: 'ServiceStatus.viewCacheContent',
        defaultMessage: 'View cache content',
        description: 'Text in the view cache content button'
    },
    clearCachesButton: {
        id: 'ServiceStatus.clearCachesButton',
        defaultMessage: 'Clear cache content',
        description: 'Text in the clear caches button'
    },
    clearCachesDialogTitle: {
        id: 'ServiceStatus.clearCachesDialogTitle',
        defaultMessage: 'Clear service status caches?',
        description: 'Title of the clear caches dialog'
    },
    clearCachesDialogContent: {
        id: 'ServiceStatus.clearCachesDialogContent',
        defaultMessage: 'Are you sure you want to clear the service status caches?',
        description: 'Content of the clear caches dialog'
    },
    clearCachesDialogConfirmButton: {
        id: 'ServiceStatus.clearCachesDialogConfirmButton',
        defaultMessage: 'Clear cache content',
        description: 'Content of the clear caches dialog'
    },
    clearCachesDialogErrorMessage: {
        id: 'ServiceStatus.clearCachesDialogErrorMessage',
        defaultMessage: 'An error occurred when trying to clear cache contents',
        description: 'Content of the clear caches dialog'
    },
});


const useStyles = createUseStyles(theme => {
    return {
        header: {
            paddingTop: contentPadding.top,
            paddingRight: contentPadding.right,
            paddingBottom: contentPadding.bottom,
            paddingLeft: contentPadding.left,
            borderBottom: border1,
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile
            }
        },
        mainContent: {
            paddingTop: contentPadding.top,
            paddingRight: contentPadding.right,
            paddingBottom: contentPadding.bottom,
            paddingLeft: contentPadding.left,
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile
            }
        },
        apiTable: {
            marginBottom: theme.spacing(6)
        },
        supportActions: {
            marginTop: theme.spacing(6)
        },
        supportActionsTitle: {
            marginBottom: theme.spacing(1)
        },
        supportActionButton: {
            marginRight: theme.spacing(2)
        },
    };
});

export default function ServiceStatus() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.current.result);
    const clearCache = useSelector(state => state.serviceStatus.clearCache);
    const [showCacheErrorNotification, setShowCacheErrorNotification] = useState(false);
    const [clearCachesDialogOpen, setClearCachesDialogOpen] = useState(false);

    // Make request for service status data.
    useEffect(() => {
        dispatch(getApisServiceStatus())
    }, [dispatch]);

    const { result: apiStatusData, loading } = useSelector(state => state.serviceStatus.current);
    // Add appropriate Link components to each API.
    if(apiStatusData) {
        const addLinkComponentToApiObject = apiData => apiData.component = getApiLink(apiData.id);
        apiStatusData.apis.forEach(addLinkComponentToApiObject);
        apiStatusData.dataHub.forEach(addLinkComponentToApiObject);
    }

    const canReadCache = hasReadServiceStatusCachePermission(user);
    const canClearCache = hasClearServiceStatusCachePermission(user);

    // Show error notification on clear cache request fail.
    useEffect(() => {
        if(clearCache.error) {
            setShowCacheErrorNotification(true);
        }
    }, [clearCache.error]);

    return <div>
        <header>
            <Typography className={classes.header}
                        variant='h1'
                        color='primary'>
                <FormattedMessage {...messages.title} />
            </Typography>
        </header>
        <div className={classes.mainContent}>
            <ServiceStatusTable className={classes.apiTable}
                                loading={loading}
                                title={<Typography variant='h2'>
                                    <FormattedMessage {...messages.apisSubtitle} />
                                </Typography>}
                                data={apiStatusData?.apis}
                                lastTimeReceived={apiStatusData?.lastTimeReceivedApis} 
                                instanceId='os-apis'/>
            <ServiceStatusTable title={<Typography variant='h2'>
                                    <FormattedMessage {...messages.dataHubSubtitle} />
                                </Typography>}
                                loading={loading}
                                data={apiStatusData?.dataHub}
                                lastTimeReceived={apiStatusData?.lastTimeReceivedDataHub} 
                                instanceId='os-datahub'/>

            {(canReadCache || canClearCache) && <div className={classes.supportActions}>
                <Typography variant='h2' className={classes.supportActionsTitle}>
                    <FormattedMessage {...messages.supportActions}/>
                </Typography>
                {canReadCache && <Button color='primary'
                                         variant='contained'
                                         className={classes.supportActionButton}
                                         onClick={() => window.open('/api/serviceStatus/cache', '_self')}>
                    <FormattedMessage {...messages.viewCacheContent}/>
                </Button>}
                {canClearCache && <Button color='primary'
                        variant='contained'
                        className={classes.supportActionButton}
                        onClick={() => setClearCachesDialogOpen(true)}>
                    <FormattedMessage {...messages.clearCachesButton}/>
                </Button>}
                {clearCachesDialogOpen && <ClearCacheDialog messages={messages}
                                                            working={clearCache.working}
                                                            result={clearCache.result}
                                                            error={clearCache.error}
                                                            onClose={() => {
                                                                console.log('ClearCacheDialog onClose')
                                                                setClearCachesDialogOpen(false)
                                                            }} />}
                {showCacheErrorNotification && <Notification variant='error'
                                                   appearance='toast'
                                                   onClose={() => setShowCacheErrorNotification(false)}
                                                   autoClose={true}>
                    <Typography variant='body1' paragraph={true}>
                        <FormattedMessage {...messages.clearCachesDialogErrorMessage}/>
                    </Typography>
                </Notification>}
            </div>}
        </div>
    </div>
}

function ClearCacheDialog({messages, onClose}) {
    const [{working, result, error}, dispatch] = useActionIdSelector('serviceStatus.clearCache');

    // Close dialog when result has returned.
    useEffect(() => {
        if(result !== undefined || error !== undefined) {
            onClose();
        }
    });

    return <ConfirmationDialog title={messages.clearCachesDialogTitle}
                               contentMessages={[messages.clearCachesDialogContent]}
                               confirmationAction={() => dispatch(clearServiceStatusCache())}
                               confirmationButtonLabel={messages.clearCachesDialogConfirmButton}
                               working={working}
                               handleClose={onClose}/>;
}

function getApiLink(apiId) {
    const message = getMessageForApi(apiId);
    return <Link path={routes.serviceStatusApi.replace(':apiId', apiId)}>
        {message ? <FormattedMessage {...message} /> : 'Unknown'}
    </Link>;
}