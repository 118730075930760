// Values of the "user" plan. Assigned to user.plan.
exports.USER_OPEN_DATA_PLAN = 'OpenData';
exports.USER_PREMIUM_DATA_PLAN = 'PremiumData';
exports.USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN = 'EnergyAndInfrastructureData';
exports.USER_PSGA_PLAN = 'PSGA';
exports.USER_OS_INTERNAL_PLAN = 'OSInternal';
exports.USER_PARTNER = 'Partner';

// Values of plan assigned in CRM. Assigned to user.orgPlan.
exports.CRM_ENERGY_AND_INFRASTRUCTURE_PLAN = 'EnergyAndInfrastructurePlan';
exports.CRM_PREMIUM_BELOW_PLAN = 'PremiumBelowPlan';
exports.CRM_PREMIUM_ABOVE_PLAN = 'PremiumAbovePlan';
exports.CRM_PENDING_PSGA_PLAN = 'PendingPSGA';
exports.CRM_PSGA_PLAN = 'PSGA';
exports.CRM_OS_INTERNAL_PLAN = 'OSInternalPlan';

// PlanTag values.
exports.OPEN_PLAN = 'open';
exports.PSGA_PLAN = 'psga';
exports.PREMIUM_PLAN = 'premium';
exports.ENERGY_AND_INFRASTRUCTURE_PLAN = 'energyAndInfrastructure';
