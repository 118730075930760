import React from 'react';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import DownloadImage from '../DownloadImage';
import {ExternalLink} from 'omse-components';
import getProvider from "../../../../hooks/getProvider";
import ExternalEmailButton from "./ExternalEmailButton";

const messages = defineMessages({
    productInformation: {
        id: 'OpenDataDownloadsItem.productInformation',
        defaultMessage: 'Product Information',
        description: 'Label for product info link'
    },
    supportingInformation: {
        id: 'OpenDataDownloadsItem.supportingInformation',
        defaultMessage: 'Supporting information',
        description: 'Label for Supporting Information'
    },
    additionalInfo: {
        id: 'OpenDataDownloadsItem.additionalInfo',
        defaultMessage: 'Also available',
        description: 'Label for Additional Information'
    },
});

const styles = theme => ({
    sideTitle: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2),
            marginBottom: theme.spacing(1.5)
        }
    },
    links: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3),
        gap: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2),
            gap: theme.spacing(2)
        }
    },
    sideInfo: {
        flex: '1',
        marginBottom: theme.spacing(6.5),
        [theme.breakpoints.up('lg')]: {
            marginLeft: theme.spacing(2)
        },
        [theme.breakpoints.down('lg')]: {
            marginLeft: theme.spacing(0)
        }
    }
});

export function OpenDataSideInfo(props) {
    let {classes, item} = props;
    const isThirdPartyProduct = getProvider(item) !== "Ordnance Survey";

    const images = [];
    for(let i = 0; i < item.imageCount; i++) {
        images.push(
            <DownloadImage key={i} images={item.images} fixedIndex={i} productName={item.name} />
        );
    }

    const supportingInfo = [];
    const additionalInfo = [];

    if(isThirdPartyProduct) {
        for (let i = 0; i < item.thirdPartyInfo.supportingInfo.length; i++) {
            supportingInfo.push(
                <ExternalLink
                    key={i}
                    type="generic"
                    href={item.thirdPartyInfo.supportingInfo[i].link}
                    message={item.thirdPartyInfo.supportingInfo[i].name}
                />
            )
        }

        for (let i = 0; i < item.thirdPartyInfo.alsoAvailable.length; i++) {
            additionalInfo.push(
                <ExternalLink
                    key={i}
                    type="generic"
                    href={item.thirdPartyInfo.alsoAvailable[i].link}
                    message={item.thirdPartyInfo.alsoAvailable[i].name}
                />
            )
        }

        return (
            <aside className={classes.sideInfo}>
                <Typography variant='h4' component='h2' className={classes.sideTitle}>
                    <FormattedMessage {...messages.supportingInformation}/>
                </Typography>
                <div className={classes.links}>
                    {item.documentationUrl &&
                            <ExternalLink
                                type="generic"
                                href={item.documentationUrl}
                                message={messages.productInformation}
                            />
                    }
                    { supportingInfo }
                </div>
                { images }
                {item?.thirdPartyInfo?.alsoAvailable[0] && (
                    <>
                        <Typography variant="h4" component="h2" className={classes.sideTitle}>
                            <FormattedMessage {...messages.additionalInfo} />
                        </Typography>
                        <div className={classes.links}>
                            { additionalInfo }
                        </div>
                    </>
                )}
                <ExternalEmailButton item={item}/>
            </aside>
        );
    }


    return (
        <aside className={classes.sideInfo}>
            <Typography variant='h4' component='h2' className={classes.sideTitle}>
                <FormattedMessage {...messages.supportingInformation}/>
            </Typography>
            <div className={classes.links}>
                {item.documentationUrl && (
                    <ExternalLink
                        type="generic"
                        href={item.documentationUrl}
                        message={messages.productInformation}
                    />
                )}
            </div>
            { images }
        </aside>
    );
}

OpenDataSideInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired
};

export default withStyles(styles)(OpenDataSideInfo);
