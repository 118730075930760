const {ACTIVE} = require('./free-trial-state');

/**
 * Whether a product can be used by the current user. This assumes it is given product objects as returned by
 * GET /products, and assumes that the filtering performed by /GET products has already been applied.
 */
exports.canUseProduct = function(product) {
    return !product.hasFreeTrial || product.freeTrialState === ACTIVE;
}

// Note this is NOT the same as having an expired free trial, as a not started free trial is also not active.
exports.hasFreeTrialNotActive = function(product) {
    return product.hasFreeTrial && product.freeTrialState !== ACTIVE;
}