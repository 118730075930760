export const COMMERCIAL_ENQUIRIES_EMAIL = 'commercialenquiries@os.uk';
export const COMMERCIAL_ENQUIRIES_PHONE = '02380 055991';

export const TEN_MINUTES_IN_MS = 600000;

// add unique LocalStorage keys used across omse-portal
// to this LOCAL_STORAGE_KEYS object. This will avoid
// unintentional key re-usage with undesired effects.
export const LOCAL_STORAGE_KEYS = {
    dashboardPremiumAd: 'apiDashPremAdBanner',
}

export const COOKIE_KEYS = {
    workspaceOptIn: "workspaceOptIn"
}