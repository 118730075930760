import React from 'react';
import {createUseStyles} from 'react-jss';
import {osColour, border1} from 'omse-components';
import { Box, Typography } from "@mui/material";
import {defineMessages, FormattedMessage} from 'react-intl';
import ExpandInfo from "./ExpandInfo";
import ExpandIntro from "./ExpandIntro";


const messages = defineMessages({
    expandTitle: {
        id: 'ExpandDataPackage.expandTitle',
        defaultMessage: 'Expand area',
        description: 'expansion title'
    },
    useTheMap: {
        id: 'ExpandDataPackage.useTheMap',
        defaultMessage: 'Use the map drawing tools to expand a polygon, upload your own file or choose a polygon from our library.',
        description: 'use the map label'
    },
    formats: {
        id: 'ExpandDataPackage.info.fileFormat',
        defaultMessage: 'File format:',
        description: 'File format label'
    },
    updates: {
        id: 'ExpandDataPackage.info.updates',
        defaultMessage: 'Updates:',
        description: 'Updates label'
    }
});

const useStyles = createUseStyles(theme => ({
    mainTitle: {
        paddingTop: theme.spacing(6),
        paddingLeft: theme.spacing(3),
        borderBottom: border1
    },
    title: {
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(3),
    },
    titleText: {
        marginBottom: theme.spacing(3),
        color: osColour.primary.berry
    },

    paragraph: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    optionContainer: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        backgroundColor: osColour.neutral.clouds,
        border: '1px solid ' + osColour.neutral.mist,
        margin: theme.spacing(1),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        '& div': {
            marginBottom: theme.spacing(2)
        }
    }
}));


export default function ExpandNgdPanel(props) {
    const {dataPackage} = props;
    const classes = useStyles();

    return <>
        <div className={classes.mainTitle}>
            <Typography variant={'h1'} className={classes.titleText}>
                <FormattedMessage {...messages.expandTitle} />
            </Typography>
        </div>

        <ExpandIntro name={dataPackage.name} id={dataPackage.id} label={dataPackage.productName}/>

        <div className={classes.optionContainer}>
            <Box>
                <Typography variant="h3">
                    <FormattedMessage {...messages.formats}/>
                </Typography>
                <Typography variant='body1'>{dataPackage.dataFormatName}</Typography>
            </Box>
            <Box>
                <Typography variant="h3">
                    <FormattedMessage {...messages.updates}/>
                </Typography>
                <Typography variant='body1'>{dataPackage.updateScheduleName}</Typography>
            </Box>
        </div>

        <Typography className={classes.paragraph}>
            <FormattedMessage {...messages.useTheMap} />
        </Typography>

        <ExpandInfo dataPackage={dataPackage}/>

    </>;
}
