import React, {Fragment, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {defineMessages, FormattedMessage} from 'react-intl';
import {CircularProgress, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import routePaths, {getLocation} from "../util/routes";
import {useHistory} from 'react-router';
import {invitationResponse} from "../modules/organisation/action";
import useSearchParam from "../hooks/useSearchParam";
import InvitationChoice from "./invitation/InvitationChoice";
import UserDetailsPage from "./invitation/UserDetailsPage";
import InvitationError from "./invitation/InvitationError";
import {USER_OPEN_DATA_PLAN} from "../../shared/plans";

const messages = defineMessages({
    title: {
        id: 'Invitation.title',
        defaultMessage: 'Accept Invitation?',
        description: 'Title for the accept invitation dialog'
    }
});

const styles = createUseStyles(theme => ({
    title: {
        marginTop: theme.spacing(8),
        textAlign: 'center',
        marginBottom: theme.spacing(4),
    },
    content: {
        flex: '1 0 auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'column'
    }
}));

const DEFAULT_CONTINUE_VALUE = undefined; // A value that is never really set. If we have any other value for the
                                          // parameter then we know that it is set, and we should respect it.
let actionIdCounter = 0;

const CHOICE_PAGE=0;
const USER_DETAILS_PAGE=1;
const ERROR_PAGE=2;

export default function Invitation({testActionId}) {
    const [actionId] = useState(testActionId || actionIdCounter++);
    const [page, setPage] = useState(CHOICE_PAGE);
    const [accepted, setAccepted] = useState(false);
    const loading = useSelector(state => state.user.current.loading);
    const user = useSelector(state => state.user.current.result);
    const {working, result, error} = useSelector(state => {
        let invitationRespose = state.organisation.invitationResponse;
        if(invitationRespose.actionId !== actionId) {
            invitationRespose = {};
        }
        return invitationRespose;
    });
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = styles();
    let [continuePremiumSignup] = useSearchParam('continuePremiumSetup', DEFAULT_CONTINUE_VALUE);
    continuePremiumSignup = continuePremiumSignup !== DEFAULT_CONTINUE_VALUE;

    useEffect(() => {
        let newLocation;
        const hasResult = result !== undefined;
        const hasNoInvitation = !loading && !user.hasInvitation;

        if(error) {
            setPage(ERROR_PAGE);
            return;
        }
        if(hasResult || hasNoInvitation) {
            if(accepted) {
                setPage(USER_DETAILS_PAGE);
                return;
            }
            if(continuePremiumSignup && user.plan === USER_OPEN_DATA_PLAN) {
                newLocation = getLocation(routePaths.premiumSetup, history.location);
            } else {
                newLocation = getLocation(routePaths.dashboard, history.location);
            }
            history.replace(newLocation);
        }
    }, [accepted, loading, user, error, result, history, continuePremiumSignup]);

    function handleInvitation(accept) {
        setAccepted(accept);
        const action = invitationResponse(accept);
        action.actionId = actionId;
        dispatch(action);
    }

    if (loading) {
        return <div>
            <CircularProgress size={32}/>;
        </div>
    }

    let pageComponent;
    switch(page) {
        case USER_DETAILS_PAGE:
            pageComponent = <UserDetailsPage/>
            break;
        case ERROR_PAGE:
            pageComponent = <InvitationError/>;
            break;
        default:
            // Start on the initial page
            pageComponent = <InvitationChoice handleInvitation={handleInvitation} working={working}/>;
            break;
    }

    return <Fragment>
        <Typography variant='h1' color='primary' className={classes.title}>
            <FormattedMessage {...messages.title}/>
        </Typography>

        <div className={classes.content}>
            { pageComponent }
        </div>
    </Fragment>;
}