const PREFIX = 'action:recipes:';

export const LOAD_RECIPE_LIBRARY_ACTION = PREFIX + 'load';
export const LOADED_RECIPE_LIBRARY_ACTION = PREFIX + 'loaded';
export const LOAD_RECIPE_ACTION = PREFIX + ':recipe:load';
export const LOADED_RECIPE_ACTION = PREFIX + ':recipe:loaded';
export const LOAD_FEATURE_TYPE_SCHEMA_ACTION = PREFIX + ':featureTypeSchema:load';
export const LOADED_FEATURE_TYPE_SCHEMA_ACTION = PREFIX + ':featureTypeSchema:loaded';
export const SHARE_RECIPE_ACTION = PREFIX + ':recipe:share';
export const HANDLE_RECIPE_INVITATION_ACTION = PREFIX + ':recipe:handleInvitation';
export const LOAD_SHARABLE_ORGS_ACTION = PREFIX + ':sharableOrgs:load';
export const LOADED_SHARABLE_ORGS_ACTION = PREFIX + ':sharableOrgs:loaded';
export const LOAD_DATASET_OPTIONS_ACTION = PREFIX + ':datasetOptions:load';
export const LOADED_DATASET_OPTIONS_ACTION = PREFIX + ':datasetOptions:loaded';

export const SET_RECIPE_NAME_ACTION = PREFIX + 'setName';
export const SET_RECIPE_DESCRIPTION_ACTION = PREFIX + 'setDescription';
export const DELETE_RECIPE_ACTION = PREFIX + ':recipe:delete';

export function getRecipeLibrary() {
    return {
        type: LOAD_RECIPE_LIBRARY_ACTION
    };
}

export function getRecipe(id) {
    return {
        type: LOAD_RECIPE_ACTION,
        id
    };
}

export function getDatasetOptions(datasets) {
    return {
        type: LOAD_DATASET_OPTIONS_ACTION,
        datasets
    };
}

export function shareRecipe(id, selectedOrg, message) {
    return {
        type: SHARE_RECIPE_ACTION,
        id,
        selectedOrg,
        message
    };
}

export function getFeatureTypeSchema(featureTypeId, version) {
    return {
        type: LOAD_FEATURE_TYPE_SCHEMA_ACTION,
        featureTypeId,
        version
    };
}

export function handleRecipeInvitation(recipeId, accept) {
    return {
        type: HANDLE_RECIPE_INVITATION_ACTION,
        recipeId,
        accept
    }
}


export function getSharableOrgs(recipeId, prefix) {
    return {
        type: LOAD_SHARABLE_ORGS_ACTION,
        recipeId,
        prefix
    }
}


export function cleanUpSharableOrgs() {
    return {
        type: LOADED_SHARABLE_ORGS_ACTION
    }
}

export function setRecipeName(recipeId, name) {
    return {
        type: SET_RECIPE_NAME_ACTION,
        recipeId,
        name,
        attributeType: 'name'
    };
}

export function setRecipeDescription(recipeId, description) {
    return {
        type: SET_RECIPE_DESCRIPTION_ACTION,
        recipeId,
        description,
        attributeType: 'description'
    };
}

export function deleteRecipe(recipeId) {
    return {
        type: DELETE_RECIPE_ACTION,
        recipeId,
    };
}