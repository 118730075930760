import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Typography from "@mui/material/Typography";
import { osColour, theme } from "omse-components";
import { useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";

const gradientWide = '#ffffff 0%, rgba(255, 255, 255, 1) 42%, rgba(255, 255, 255, 0.65) 55%, rgba(255, 255, 255, 0.5) 62%, rgba(255, 254, 253, 0.1) 85%, rgba(252, 249, 241, 0) 100%';
const gradientLarge =  '#ffffff 0%, rgba(255, 255, 255, 1) 52%, rgba(255, 255, 255, 0.4) 90%, rgba(252, 249, 241, 0) 100%';
const gradientMedium = '#ffffff 0%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 0.8) 82%, rgba(255, 255, 255, 0.5) 90%, rgba(252, 249, 241, 0) 100%';

const HeroBanner = styled('div')((p) => `
	margin: 0;
	padding: 0;
	background-size: cover;
	background-position: right;
	background-repeat: no-repeat;
	${theme.breakpoints.up("sm")} {
		background-image: url(${p.backgroundImage});
	};
	& > div {
		padding: 12em 5em;
		${theme.breakpoints.down("md")} {
			padding: 8em 5em;
		};
		${theme.breakpoints.down("sm")} {
			padding: 2em 1em 4em 1em;
		};
		background: linear-gradient(to right, ${gradientWide});
		${theme.breakpoints.down("lg")} {
			background: linear-gradient(to right, ${gradientLarge});
		};
		${theme.breakpoints.down("md")} {
			background: linear-gradient(to right, ${gradientMedium});
		};
	};
	& > div > div {
		margin: 0 auto;
		max-width: 1400px;
	};
`);

const HeroContent = styled('div')((p) => `
	max-width: 34em;
	${theme.breakpoints.down("md")} {
		max-width: 70%;
	};
	${theme.breakpoints.down("sm")} {
		max-width: 100%;
	};
	& > h1 {
		margin: 0 0 ${theme.spacing(3)} 0;
		padding: 0;
		font-size: 2.5rem;
		color: ${osColour.primary.berry};
		-webkit-font-smoothing: antialiased;
		${theme.breakpoints.down("sm")} {
			font-size: 2rem;
		}
	};
	& > p {
		margin: 0 0 ${theme.spacing(1)} 0;
		padding: 0;
		font-size: 1.25rem;
		line-height: 1.6;
		color: ${osColour.primary.berry};
	};
	& > p:last-of-type {
		margin: 0 0 ${theme.spacing(3)} 0;
	};
	& > div {
		display: flex;
		gap: 1.5em;
		flex-wrap: wrap;
	}
	& > div > button {
		padding: 11px 25px 13px 25px !important;
		-webkit-font-smoothing: antialiased;
		${theme.breakpoints.down("sm")} {
			width: 100%;
		}
	};
	& > div > button:last-of-type {
		background: rgba(255, 255, 255, 0.1);
	}
	& > div > button:last-of-type:hover {
		background: rgba(69, 60, 144, 0.04);
	}
`);

const MobileHeroImage = styled('img')(() => `
	width: 100%;
`);

export const WelcomeContent = (props) => {
	const { title, intro, backgroundImage, backgroundImageMobile, actionButtons } = props;
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<>
			{isMobile && (
				<MobileHeroImage
					src={backgroundImageMobile}
					alt="OSMM Highways network in Southampton"
				/>
			)}
			<HeroBanner backgroundImage={backgroundImage}>
				<div data-testid="gradientScreen">
					<div>
						<HeroContent>
							<Typography variant="h1">
								<FormattedMessage {...title} />
							</Typography>
							{intro && intro.map((introChild) => (
								<Typography variant="body1" key={introChild.defaultMessage}>
									<FormattedMessage {...introChild} />
								</Typography>
							))}
							{actionButtons && (
								<div>
									{actionButtons}
								</div>
							)}
						</HeroContent>
					</div>
				</div>
			</HeroBanner>
		</>
	);
};

WelcomeContent.propTypes = {
	title: PropTypes.object.isRequired,
	intro: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		defaultMessage: PropTypes.string,
		description: PropTypes.string
	})),
	actionButtons: PropTypes.object,
	backgroundImage: PropTypes.string.isRequired,
	backgroundImageMobile: PropTypes.string.isRequired
};

export default WelcomeContent;
