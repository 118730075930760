import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {createUseStyles} from 'react-jss';
import {Typography} from '@mui/material';
import Header from '../documentation/Header';
import Document from '../documentation/Document';
import style from './styles/legal';

const messages = defineMessages({
    title: {
        id: 'ErrorReportingTerms.title',
        defaultMessage: 'Error Reporting Terms',
        description: 'Error Reporting Terms title'
    },
    subtitle: {
        id: 'ErrorReportingTerms.subtitle',
        defaultMessage: 'OS Data Hub Error Reporting Terms',
        description: 'OS Data Hub Error Reporting Terms subtitle'
    }
});

const useStyles = createUseStyles(style);
const useEOTermsStyles = createUseStyles({
    bulletedList: {
        '& ol': {
            listStyle: 'disc'
        }
    },
    secondList: {
        '& ol': {
            counterReset: 'li 4',
            '& li ol li:before': {
                content: 'none'
            }
        }
    },
    thirdList: {
        '& ol': {
            counterReset: 'li 8'
        }
    },
    fourthList: {
        '& ol': {
            counterReset: 'li 9'
        }
    }
});

export function ErrorReportingTerms() {
    const classes = useStyles();
    const classesEOTerms = useEOTermsStyles();

	return (
		<Document>
            <Header back={true}>
                <FormattedMessage {...messages.title} />
            </Header>
            <div className={classes.content}>
                <Typography variant='h2'>
                    <FormattedMessage {...messages.subtitle} />
                </Typography>
			    <Typography variant='h5' component='div' className={classesEOTerms.bulletedList}>
                    <ol>
                        <li>
                            This Agreement sets out the terms on which Ordnance Survey Limited (company registration number 09121572) 
                            (<strong>we, us, our, OS</strong>) makes the  Errors and Omissions Reporting online tool 
                            (the <strong>Errors and Omissions Tool</strong>) available to its customers, including you, 
                            the person or entity entering into this Agreement (<strong>you</strong> and <strong>your</strong>). 
                            You and we are each a <strong>party</strong> and together the <strong>parties</strong>.
                        </li>
                        <li>
                            You warrant that you have entered into the OS Data Hub Terms; in the event of any conflict or inconsistency
                            between these terms and the OS Data Hub Terms in relation to the Errors and Omissions Tool, these terms will have precedence.
                        </li>
                        <li>By using the Errors and Omissions Tool (including accessing any data made available in the tool), you indicate your acceptance of these terms.</li>
                        <li>You acknowledge that the Errors and Omissions Tool only enables you to make a maximum of thirty address searches per day.</li>
                        <li>
                            If you have registered for either a Premium API Data Plan or a Public Sector API Data Plan, the E&O Data (as defined below) contains Address Islands Records.
                            Address Islands Records includes data that has originated from Land & Property Service (in respect of Northern Ireland), the Isle of Man Government (in respect of the Isle of Man),
                            The States of Jersey (in respect of Jersey) and The States of Guernsey (in respect of Guernsey).
                        </li>
                    </ol>
                </Typography>
                
                <Typography variant='h5' component='div' className={classes.numberedList}>
                    <Typography variant='h4' component='h3'>
                        Intellectual Property Rights
                    </Typography>
                    <ol>
                        <li>
                            The Errors and Omissions Tool is provided to allow you to suggest corrections, additions and change intelligence (together, <strong>Change Data</strong>) to Ordnance Survey data.
                        </li>
                        <li>
                            All intellectual property rights (<strong>IPR</strong>) in any Change Data submitted by you via the Errors and Omissions Tool shall belong to 
                            Ordnance Survey and accordingly you assign to Ordnance Survey any and all IPR belonging to you in any such Change Data.
                        </li>
                        <li>
                            Ordnance Survey grants you a licence to use the data accessible via the Errors and Omissions Tool 
                            (the <strong>E&O Data</strong>) for the sole purpose of supplying Change Data to Ordnance Survey.  To be clear, 
                            the E&O Data includes any existing reports of errors that have already been submitted to the 
                            Errors and Omissions Tool and that are visible in the data (<strong>Existing Error Reports</strong>). 
                        </li>
                        <li>
                            You are not permitted to copy, sub-license, distribute, sell or otherwise make available the 
                            E&O Data to third parties in any form, or to otherwise benefit from or rely on the E&O Data.
                        </li>
                    </ol>
                </Typography>
                <Typography variant='h5' component='div' className={`${classes.numberedList} ${classesEOTerms.secondList}`}>
                    <Typography variant='h4'>
                        Warranties and Liability
                    </Typography>
                    <ol>
                        <li>
                            You warrant that:
                            <ol type='a' className={classes.alphabeticList}>
                                <li>you have the authority to assign all IPR in the Change Data in accordance with Clause 2;</li>
                                <li>you have full power to enter into this Agreement;</li>
                                <li>your supply and assignment of the Change Data will not infringe any third party IPR; and</li>
                                <li>although the Change Data may not be error free, you provide it to Ordnance Survey in good faith and have used reasonable endeavours to ensure its accuracy and completeness.</li>
                            </ol>
                        </li>
                        <li>You shall not be liable to Ordnance Survey for misuse by Ordnance Survey of the Change Data.</li>
                        <li>
                            The Existing Error Reports are made available ‘as is’ and Ordnance Survey excludes all representations, 
                            warranties, obligations and liabilities in relation to such information to the maximum extent permitted by law.                                  
                            Ordnance Survey is not liable for any errors or omissions in the Existing Error Reports and shall not be liable 
                            for any loss, injury or damage of any kind caused by their use.   
                        </li>
                        <li>
                            Ordnance Survey may suspend access to the Errors and Omissions Tool for any reason, including but 
                            not limited to repairs, planned maintenance or upgrades, and shall not be liable to you for any such suspension,                                 
                            or for any delay or interruption in the availability of the Errors and Omissions Tool.        
                        </li>
                    </ol>
                </Typography>
                <Typography variant='h5' component='div' className={`${classes.numberedList} ${classesEOTerms.thirdList}`}>
                    <Typography variant='h4'>
                        Term and Termination
                    </Typography >
                    <ol>
                        <li>
                            Ordnance Survey may terminate this Agreement at any time at its sole discretion either by 
                            notice to you in writing and/or by removing your access rights to the Errors and Omissions Tool.
                        </li>
                    </ol>
                </Typography>
                <Typography variant='h5' component='div' className={`${classes.numberedList} ${classesEOTerms.fourthList}`}>
                    <Typography variant='h4'>
                        General
                    </Typography>
                    <ol>
                        <li>Subject to Clause 11, neither party may assign or transfer their rights or obligations under this Agreement without the prior written consent of the other party.</li>
                        <li>Ordnance Survey may assign or transfer rights and obligations under this Agreement to any government body, nominated subcontractor or any entity to which the activities or functions of Ordnance Survey have been transferred.</li>
                        <li>This Agreement shall be governed by the law of England and Wales.</li>
                    </ol>
                </Typography>
            </div>
		</Document>
	);
}

export default ErrorReportingTerms;