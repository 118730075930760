import React from 'react';
import {ReactComponent as InfoIcon} from '../icons/info-notification.svg';
import ClickAwayTooltip from '../ClickAwayTooltip';
import {defineMessages} from 'react-intl';
import {createUseStyles} from 'react-jss';
import {osColour} from 'omse-components';
import classNames from 'classnames';

const messages = defineMessages({
    tooltip: {
        id: 'CostInfoTooltip.tooltip',
        defaultMessage: 'Estimated. Your final bill may differ by a small amount.',
        description: 'estimated final bill message'
    }
});

const useStyles = createUseStyles({
    tooltip: {
        float: 'unset',
        marginRight: 5,
        display: 'inline-flex',
        '& button': {
            top: 5
        },
        '& button > span': {
            display: 'flex'
        }
    },
    tooltipIcon: {
        color: osColour.neutral.charcoal
    }
});

export default function CostInfoTooltip(props) {
    const classes = useStyles(props);
    return <ClickAwayTooltip id='costInfoTip' 
        classes={{clickAwayTooltip: classNames(classes.tooltip, props.classes.tooltip)}} 
        icon={<InfoIcon height={22} width={22} className={classes.tooltipIcon} />} 
        body={messages.tooltip}
        ariaLabel='Cost info'
    />
}

CostInfoTooltip.defaultProps = {
    classes: {}
};