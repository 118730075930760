import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { defineMessages, FormattedMessage } from "react-intl";
import routePaths, { signUpLink } from "../../util/routes";
import NavButton from "../../components/NavButton";
import featureCheck from "../../util/featureCheck";
import { canApplyPsga } from "../../util/permissions";
import ApplyPsgaDialog from "./ApplyPsgaDialog";
import features from "../../../shared/features";
import {
    CRM_PENDING_PSGA_PLAN,
    OPEN_PLAN,
    PREMIUM_PLAN,
    PSGA_PLAN,
    USER_OPEN_DATA_PLAN,
    USER_PREMIUM_DATA_PLAN,
    USER_PSGA_PLAN,
} from "../../../shared/plans";
import styled from "@emotion/styled";

const messages = defineMessages({
    signUpNow: {
        id: "PlanAction.signUpNow",
        defaultMessage: "Sign up now",
        description: "Sign up now label",
    },
    signUpForFree: {
        id: "PlanAction.signUpForFree",
        defaultMessage: "Start for free",
        description: "Start for free label",
    },
    signUpForPSGA: {
        id: "PlanAction.signUpForPSGA",
        defaultMessage: "Sign up now",
        description: "Sign up now label for PSGA",
    },
    signUpForOpenDataAccount: {
        id: "PlanAction.signUpForOpenDataAccount",
        defaultMessage: "Sign up for an OpenData account",
        description: "Sign up for an OpenData account label",
    },
    yourPlanText: {
        id: "PlanAction.yourPlanText",
        defaultMessage: "This is your plan",
        description: "This is your plan label",
    },
    applicationPending: {
        id: "PlanAction.applicationPending",
        defaultMessage: "Application pending",
        description: "Application pending label",
    },
    upgradeToPremium: {
        id: "PlanAction.upgradeToPremium",
        defaultMessage: "Upgrade to Premium",
        description: "Upgrade to Premium label",
    },
    changeToPSGA: {
        id: "PlanAction.changeToPSGA",
        defaultMessage: "Change to Public Sector Plan",
        description: "Change to Public Sector label",
    },
    onlyAccountAdmins: {
        id: "PlanAction.onlyAccountAdmins",
        defaultMessage: "Only account administrators can apply",
        description: "only admins",
    },
});

const StyledPlanAction = styled("div")`
    justify-content: center;
    display: flex;
`;

function PlanAction(props) {
    const { userDetails, planType, config } = props;
    const [applyPsga, setApplyPsga] = useState(false);
    const variant = planType === PREMIUM_PLAN ? "contained" : "outlined";
    let link = signUpLink(planType);

    let planActionContents;

    const buttonIdPrefix = `Action${userDetails ? "UpgradeTo" : "SignUp"}`;

    if (!userDetails) {
        let messageOpts;
        let buttonIdMessage;
        if (planType === OPEN_PLAN) {
            messageOpts = messages.signUpNow;
            buttonIdMessage = "OpenData";
        } else if (planType === PREMIUM_PLAN) {
            messageOpts = messages.signUpForFree;
            buttonIdMessage = "Premium"
        } else if (planType === PSGA_PLAN) {
            buttonIdMessage = "PSGA"
            if (featureCheck(features.PSGA, config)) {
                messageOpts = messages.signUpForPSGA;
            } else {
                messageOpts = messages.signUpForOpenDataAccount;
            }
        } else {
            throw new Error("Unexpected plan type");
        }
        planActionContents = (
            <Button
                variant={variant}
                color="primary"
                onClick={() => window.location.assign(link)}
                id={`${buttonIdPrefix}${buttonIdMessage}`}
            >
                <FormattedMessage {...messageOpts} />
            </Button>
        );
    } else if (
        (userDetails.plan === USER_OPEN_DATA_PLAN && planType === OPEN_PLAN) ||
        (userDetails.plan === USER_PREMIUM_DATA_PLAN && planType === PREMIUM_PLAN) ||
        (userDetails.plan === USER_PSGA_PLAN && planType === PSGA_PLAN)
    ) {
        planActionContents = null;
    } else if (userDetails.orgPlan === CRM_PENDING_PSGA_PLAN && planType === PSGA_PLAN) {
        planActionContents = (
            <Button variant={variant} color="primary" disabled>
                <FormattedMessage {...messages.applicationPending} />
            </Button>
        );
    } else if (
        userDetails.plan === USER_OPEN_DATA_PLAN &&
        planType === PREMIUM_PLAN &&
        userDetails.orgPlan !== CRM_PENDING_PSGA_PLAN
    ) {
        planActionContents = (
            <NavButton
                path={routePaths.premiumSetup}
                variant={variant}
                color="primary"
                id={`${buttonIdPrefix}Premium`}
            >
                <FormattedMessage {...messages.upgradeToPremium} />
            </NavButton>
        );
    } else if (
        userDetails.plan === USER_OPEN_DATA_PLAN &&
        planType === PSGA_PLAN &&
        featureCheck(features.PSGA, config)
    ) {
        // All OpenData users can apply for psga. No need to check permissions.
        planActionContents = (
            <NavButton
                path={routePaths.psgaSetup}
                state={{ upgrade: true }}
                variant={variant}
                color="primary"
                id={`${buttonIdPrefix}PSGA`}
            >
                <FormattedMessage {...messages.changeToPSGA} />
            </NavButton>
        );
    } else if (
        userDetails.plan === USER_PREMIUM_DATA_PLAN &&
        canApplyPsga(userDetails) &&
        planType === PSGA_PLAN &&
        featureCheck(features.PSGA, config)
    ) {
        planActionContents = (
            <Fragment>
                <Button
                    variant={variant}
                    id={`${buttonIdPrefix}PSGA`}
                    color="primary"
                    onClick={() => setApplyPsga(true)}
                >
                    <FormattedMessage {...messages.changeToPSGA} />
                </Button>
                {applyPsga && <ApplyPsgaDialog handleClose={() => setApplyPsga(false)} />}
            </Fragment>
        );
    } else if (
        userDetails.plan === USER_PREMIUM_DATA_PLAN &&
        planType === PSGA_PLAN &&
        featureCheck(features.PSGA, config)
    ) {
        planActionContents = (
            <Typography variant="body1">
                <FormattedMessage {...messages.onlyAccountAdmins} />
            </Typography>
        );
    }

    if (planActionContents) {
        return <StyledPlanAction>{planActionContents}</StyledPlanAction>;
    } else {
        return null;
    }
}

PlanAction.propTypes = {
    userDetails: PropTypes.object,
    planType: PropTypes.oneOf([OPEN_PLAN, PREMIUM_PLAN, PSGA_PLAN]),
    config: PropTypes.object,
};

export default PlanAction;
