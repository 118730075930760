import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import TextBox from '../../components/TextBox';
import {defineMessages} from 'react-intl';

const messages = defineMessages({
    apiKey: {
        id: 'APIKey.key',
        defaultMessage: 'Project API Key',
        description: 'Label for the API key section of the project page'
    },
    apiSecret: {
        id: 'APIKey.secret',
        defaultMessage: 'Project API Secret',
        description: 'Label for the API key section of the project page'
    }
});

export default function ApiKey(props) {
    const {project} = props;

    if (!project || !project.apiKey) {
        return null;
    }

    return <Fragment>
        <TextBox text={project.apiKey} label={messages.apiKey} filledButton={true}/>
        <TextBox text={project.apiSecret} label={messages.apiSecret} filledButton={true}/>
    </Fragment>
}

ApiKey.propTypes = {
    project: PropTypes.object
};
