import React from 'react';
import withStyles from 'react-jss';
import Typography from '@mui/material/Typography';
import styles from './style';
import Table from './Table';
import PropTypes from "prop-types";
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

function ResponseCodes(props) {
    const {codes} = props;
    return <>
        <Typography variant='h2'>Response Codes</Typography>
        <Typography variant='body1' paragraph={true} component='div'>
            <Table header={[
                'Code',
                'Official description',
                'Explanation in context of the API',
            ]} values={codes}/>
        </Typography>
    </>;
}

ResponseCodes.propTypes = {
    codes: PropTypes.array,
}

ResponseCodes.defaultProps = {
    codes: [
        [200, 'OK', 'Request has been successful.'],
        [304, 'Not Modified', <>In response to a conditional <InlineCodeSnippet>GET</InlineCodeSnippet> request this response indicates that the underlying data has not changed since the previous request, and cached results may be re-used.</>],
        [400, 'Bad request', 'E.g. missing query parameter, malformed syntax.'],
        [401, 'Unauthorized', 'The client has not provided authentication or incorrect authentication.'],
        [403, 'Forbidden', 'The client has authenticated its access but does not have sufficient rights to complete the request.'],
        [404, 'Not found', 'The server has not found anything matching the Request-URI.'],
        [405, 'Method not allowed', <>Request used an unsupported HTTP method, e.g. <InlineCodeSnippet>DELETE</InlineCodeSnippet> or <InlineCodeSnippet>PUT</InlineCodeSnippet>.</>],
        [429, 'Too many requests', 'Exceeded the number of requests per minute (rate-limit).'],
        [500, 'Internal server error', 'Generic internal server error.'],
        [503, 'Service unavailable', 'Temporary outage due to overloading or maintenance.'],
    ]
};

export default withStyles(styles)(ResponseCodes);