import styled from "@emotion/styled";
import { Typography, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { osColour } from "omse-components";
import CustomSwitch from "../../components/switch/Switch";
import useTryWorkspace from "../../hooks/useTryWorkspace";
import menuMessages from "./MenuMessages";
import { useSelector } from "react-redux";
import { sendEventToGTM, tagEventKeys } from "../../util/gtm";
import { cookieSettingToIndex, isCookieControlSettingAccepted, openCookieControl } from "../../util/cookie-control";

const StyledContainer = styled.div`
    margin: 20px;
    padding: 12px 20px;
    border: 1px solid ${osColour.neutral.mist};
    background-color: ${osColour.neutral.white};
`;

const StyledSeparator = styled.div`
    height: 1px;
    background-color: ${osColour.neutral.mist};
    margin-top: 12px;
    margin-bottom: 12px;
`;

const StyledTooltipContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    gap: 16px;
`

const WorkspaceSwitch = () => {
    const user = useSelector(state => state.user.current.result)
    const { workspaceEnabled, toggleWorkspace } = useTryWorkspace(user?.id);

    const handleToggle = () => {
        toggleWorkspace()
        sendEventToGTM(tagEventKeys.workspace.optIn, [{ workspaceOptIn: !workspaceEnabled, userPlan: user?.plan }]);
    }

    const functionalCookiesAccepted = isCookieControlSettingAccepted(cookieSettingToIndex.functionality)

    return (
        <StyledContainer data-testid='workplace-mobile-switch'>
            <CustomSwitch
                onChange={handleToggle}
                checked={workspaceEnabled}
                label={workspaceEnabled ? "New workspace" : "Try new workspace"}
                labelPlacement={"start"}
                id={'workspace-toggle'}
                disabled={!functionalCookiesAccepted}
            />
            <StyledSeparator />
            <Typography>
                {!functionalCookiesAccepted ? (
                    <StyledTooltipContainer>
                        <FormattedMessage {...menuMessages.workspaceDisabled}/>
                        <Button color={'primary'} variant={'contained'} onClick={() => openCookieControl()}>
                        <FormattedMessage {...menuMessages.amendCookie}/>
                        </Button>
                    </StyledTooltipContainer>
                ) : workspaceEnabled ? (
                    <FormattedMessage {...menuMessages.workspaceCalloutOff} />
                ) : (
                    <FormattedMessage {...menuMessages.workspaceCallout} />
                )}
            </Typography>
        </StyledContainer>
    );
};

export default WorkspaceSwitch;
