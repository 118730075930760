import styled from '@emotion/styled';
import { osColour } from 'omse-components';
import { Typography } from '@mui/material';
import { FormattedMessage, defineMessages } from 'react-intl';
import { ReactComponent as GithubIcon } from '../../components/icons/github-mark-white.svg';
import { ReactComponent as OSIcon } from '../../components/icons/OS logomark mono light_rgb.svg';
import { ReactComponent as MediumWhiteIcon } from "../../components/icons/mediumIcon.svg";
import { ReactComponent as MaximiseIcon } from "../../components/icons/maximise.svg";
 
const communityContent = defineMessages({
    title: {
        id: 'communityContent.title',
        defaultMessage: 'Community',
        description: 'Community section title',
    },
    dataHubExplorerTitle: {
        id: 'communityContent.dataHubExplorerTitle',
        defaultMessage: 'Data Hub Explorer',
        description: 'Title for Data Hub Explorer',
    },
    dataHubExplorerDesc: {
        id: 'communityContent.dataHubExplorerDesc',
        defaultMessage: 'View and share data samples and code snippets. Python and JavaScript supported.',
        description: 'Data Hub Explorer description',
    },
    osDeveloperBlogTitle: {
        id: 'communityContent.osDeveloperBlogTitle',
        defaultMessage: 'OS Developer Blog',
        description: 'OS Developer Blog Title',
    },
    osDeveloperBlogDesc: {
        id: 'communityContent.osDeveloperBlogDesc',
        defaultMessage: 'Demos showing you how to integrate OS Data Hub APIs into web applications.',
        description: 'OS Developer Blog description',
    },
    osOnGithubTitle: {
        id: 'communityContent.osOnGithubTitle',
        defaultMessage: 'OS on GitHub',
        description: 'OS on GitHub Title',
    },
    osOnGithubDesc: {
        id: 'communityContent.osOnGithubDesc',
        defaultMessage: 'View mapping projects and collaborate with other developers using OS data.',
        description: 'OS on Github description',
    },
    dataHubExplorerLinkText: {
        id: 'communityContent.dataHubLinkText',
        defaultMessage: 'Visit the Data Hub Explorer',
        description: 'Text for the Data Hub Explorer link',
    },
    osDeveloperBlogLinkText: {
        id: 'communityContent.osDeveloperBlogLinkText',
        defaultMessage: 'Follow us on Medium',
        description: 'Text for the OS Developer blog link',
    },
    osOnGithubLinkText: {
        id: 'communityContent.osOnGithubLinkText',
        defaultMessage: 'See what we’re doing on GitHub',
        description: 'Text for the OS on Github link',
    }
});

export const singleColBreakpoint = 920;
export const horizontalPadding = '3em';

const CommunityContainer = styled('section')`
    padding: 4em ${horizontalPadding} 2em ${horizontalPadding};
    background: #2B255A; //OS Primary Tint 'Blue Mid'
    & h2 {
        color: ${osColour.neutral.white};
        font-family: OSGill, sans-serif;
    }
    & > div {
        margin: 0 auto;
        padding: 0;
        max-width: 1400px;
    }
    & > div > div {
        display: grid;
        gap: 2.5em;
        margin-right: 200px;
        margin-top: 1em;
        margin-bottom: 3em;
        grid-template-columns: 1fr 1fr 1fr;
        @media (max-width: 1200px) {
            grid-template-columns: 1fr;
            margin-right: 0;
        }
    }
`;

const LinkContainer = styled("div")`
    display: grid;
    gap: 0.5em;
    margin-top: 1em;
    grid-template-columns: 0.25fr 3fr;
	@media (max-width: 1200px) {
		grid-template-columns: 1fr 11fr;
	}
  @media (max-width: 360px) {
    grid-template-columns: 1fr;
  }
`;

const StyledCard = styled("article")`
    background: #2B255A;
    max-width: 400px;
    & h3 {
        font-family: OSGill, sans-serif;
        font-size: 1.5rem;
        color: ${osColour.neutral.white}
    }
`;

const Description = styled(Typography)`
    font-family: OSGill, sans-serif;
    color: ${osColour.neutral.white};
    font-size: 1rem;
    font-weight: normal;
`;

const NewLink = styled.a`
    font-family: OSGill, sans-serif;
    font-size: 1rem;
    padding-right: 20px;
    color: ${osColour.neutral.white};
    &:visited {
        color: #C7C5DE //OS Primary Tint 'Blue Mid'
    }
    &:hover {
        color: ${osColour.status.warning} !important;
    }
`

const StyledNewLink = (p) => {

    const externalIconStyle = {
        paddingLeft: '3px',
        flexShrink: 0
    }

    let href;
    let message;
    let ariaLabel;
    let ExternalLinkIcon = <MaximiseIcon style={externalIconStyle} width={20} height={20} alt="External Link Icon" />;

    if (p.type === 'dataHubExplorer') {
        href = "https://labs.os.uk/prototyping/data-hub-explorer/";
        message = communityContent.dataHubExplorerLinkText;
        ariaLabel = `${message.defaultMessage} (Opens in a New Tab)`;
    }
    else if (p.type === 'osDeveloperBlog') {
        href = "https://osdeveloper.medium.com/";
        message = communityContent.osDeveloperBlogLinkText;
        ariaLabel = `${message.defaultMessage} (Opens in a New Tab)`;
    }
    else if (p.type === 'osOnGithub') {
        href = "https://github.com/OrdnanceSurvey";
        message = communityContent.osOnGithubLinkText;
        ariaLabel = `${message.defaultMessage} (Opens in a New Tab)`;
    }
    else {
        if (!ariaLabel) {
            ariaLabel = "Aria Label"
        }
    }

    return (
            <NewLink
                message={message}
                href={href}
                aria-label={ariaLabel}
                target="_blank"
                rel="noopener noreferrer"
            >
                {<FormattedMessage {...message} />}
                    {ExternalLinkIcon}
            </NewLink>

    );
};

function Card({ icon, heading, description, link }) {
    return (
        <>
            <StyledCard>
                <Typography variant={"h3"} sx={{ marginBottom: "16px", marginTop: "16px" }}>
                    <FormattedMessage {...heading} />
                </Typography>
                <Description variant={"body1"} sx={{ marginBottom: "16px" }}>
                    <FormattedMessage {...description} />
                </Description>
                <LinkContainer>
                    {icon}
                    {link}
                </LinkContainer>
            </StyledCard>
        </>
    );
}

export default function CommunityContent() {
    return (
            <CommunityContainer>
                <div>
                    <Typography variant="h1" component="h2">
                        <FormattedMessage {...communityContent.title} />
                    </Typography>
                    <div>
                        <Card
                            icon={<OSIcon width={24} height={24} />}
                            heading={communityContent.dataHubExplorerTitle}
                            description={communityContent.dataHubExplorerDesc}
                            link={<StyledNewLink type={'dataHubExplorer'} />}
                        />
                        <Card
                            icon={<MediumWhiteIcon width={24} height={24} />}
                            heading={communityContent.osDeveloperBlogTitle}
                            description={communityContent.osDeveloperBlogDesc}
                            link={<StyledNewLink type={'osDeveloperBlog'} />}
                        />
                        <Card
                            icon={<GithubIcon width={20} height={20} />}
                            heading={communityContent.osOnGithubTitle}
                            description={communityContent.osOnGithubDesc}
                            link={
                                <StyledNewLink
                                    type={'osOnGithub'}
                                />
                            }
                        />
                    </div>
                </div>
            </CommunityContainer>
    )
}
