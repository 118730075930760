import React from 'react';
import Header from "../Header";
import Content from '../Content';
import minPrint from './brandLogoImg/OS_guidelines_API-logo-print-minimum@2x.png';
import maxPrint from './brandLogoImg/OS_guidelines_API-logo-print-maximum@2x.png';
import minDigital from './brandLogoImg/OS_guidelines_API-logo-digital-minimum@2x.png';
import maxDigital from './brandLogoImg/OS_guidelines_API-logo-digital-maximum@2x.png';
import withStyles from 'react-jss';
import BrandImage from './BrandImage';
import { styles } from './BrandStyling';
import {defineMessages} from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Document from '../Document';

const messages = defineMessages({
    minPrintSubcaption: {
        id: 'MinMax.minPrintSubCaption',
        defaultMessage: 'Minimum print size',
        description: 'Minimum print subcaption'
    },
    minDigitalSubcaption: {
        id: 'MinMax.minDigitalSubCaption',
        defaultMessage: 'Minimum digital size',
        description: 'Minimum digital subcaption'
    },
    minTitle: {
        id: 'MinMax.minTitle',
        defaultMessage: 'Minimum size',
        description: 'Minimum size'
    },
    minDescription: {
        id: 'MinMax.minDescription',
        defaultMessage: 'Minimum sizes have been set to ensure legibility for digital applications. The logo should remain the same size at all times, this is a fixed asset on a scalable map.',
        description: 'Minimum size description'
    },
    maxPrintSubcaption: {
        id: 'MinMax.maxPrintSubCaption',
        defaultMessage: 'Maximum print size',
        description: 'Maximum print subcaption'
    },
    maxDigitalSubcaption: {
        id: 'MinMax.maxDigitalSubCaption',
        defaultMessage: 'Maximum digital size',
        description: 'Maximum digital subcaption'
    },
    maxTitle: {
        id: 'MinMax.maxTitle',
        defaultMessage: 'Maximum size',
        description: 'Maximum size'
    },
    maxDescription: {
        id: 'MinMax.maxDescription',
        defaultMessage: 'Maximum sizes have been set as a guide to not exceed on any application.',
        description: 'Maximum size description'
    },
})

function MinMax(props) {

    const {classes} = props;
    const containerClasses = [classes.stackedContainer, classes.twoItems];
    return (
        
        <Document>
            <Header back={true}>Minimum and maximum</Header>

            <Content>
                <div className={classes.mainContainer}>
                    <div className={classNames(containerClasses)}>
                            <BrandImage 
                                image={minPrint} 
                                alt='Diagram of minimum print width of Ordnance Survey logo (20mm)' 
                                subcaption={messages.minPrintSubcaption} />

                            <BrandImage 
                                image={minDigital} 
                                alt='Diagram of minimum digital width of Ordnance Survey logo (83px)' 
                                subcaption={messages.minDigitalSubcaption}
                                title={messages.minTitle}
                                description={messages.minDescription}
                                line={true} />
                    </div>

                    <div className={classNames(containerClasses)}>
                        <BrandImage 
                            image={maxPrint} 
                            alt='Diagram of maximum print width of Ordnance Survey logo (90mm)' 
                            subcaption={messages.maxPrintSubcaption}
                        />
                        <BrandImage 
                            image={maxDigital} 
                            alt='Diagram of maximum digital width of Ordnance Survey logo (225px)' 
                            subcaption={messages.maxDigitalSubcaption}
                            title={messages.maxTitle}
                            description={messages.maxDescription} 
                            line={true}
                        />
                    </div>
                </div>
            </Content>
        </Document>
    )
}

MinMax.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(MinMax)