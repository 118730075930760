// ChartJS defaults
import {Chart} from 'react-chartjs-2';
import {osColour} from "omse-components";

Chart.defaults.global.defaultFontFamily = "'Source Sans Pro','sans-serif'";
Chart.defaults.global.tooltips.mode = 'index';
Chart.defaults.global.tooltips.intersect = false;
Chart.defaults.global.tooltips.backgroundColor = 'white';
Chart.defaults.global.tooltips.titleFontColor = osColour.neutral.charcoal;
Chart.defaults.global.tooltips.titleFontSize = 16;
Chart.defaults.global.tooltips.titleMarginBottom = 12;
Chart.defaults.global.tooltips.titleFontStyle = 'normal';
Chart.defaults.global.tooltips.bodyFontColor = osColour.neutral.stone;
Chart.defaults.global.tooltips.bodyFontSize = 16;
Chart.defaults.global.tooltips.bodySpacing = 10;
Chart.defaults.global.tooltips.borderColor = osColour.neutral.mist;
Chart.defaults.global.tooltips.borderWidth = 2;
Chart.defaults.global.tooltips.cornerRadius = 4;
Chart.defaults.global.tooltips.xPadding = 12;
Chart.defaults.global.tooltips.yPadding = 12;
