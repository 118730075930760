import Button from "@mui/material/Button";
import {defineMessages, FormattedMessage} from "react-intl";
import React from "react";
import {createUseStyles} from 'react-jss';
import {linkButton} from "omse-components";
import {useDispatch} from "react-redux";
import {resetDraftOrder} from '../../../../modules/dataPackages/actions';

const messages = defineMessages({
    resetOptions: {
        id: 'ResetOrderButton.resetOptions',
        defaultMessage: 'Reset all options',
        description: 'reset options message'
    }
})
const styles = createUseStyles({
    resetContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    }
});

const useLinkStyles = createUseStyles({linkButton});

export default function ResetOrderButton() {
    const classes = styles();
    const classesLink = useLinkStyles();
    const dispatch = useDispatch();

    return (
        <div className={classes.resetContainer}>
            <Button id={"resetOrderButton"} className={classesLink.linkButton} data-testid={"resetOrderButton"}
                    onClick={() => dispatch(resetDraftOrder(false))}
                    variant='text'
                    disableRipple>
                <FormattedMessage {...messages.resetOptions}/>
            </Button>
        </div>
    )
}