// Hook that helps to track the current scroll position of an element. We don't want to re-render the page each time
// that the scroll position changes, so this hook returns a stable accessor that can be used to get the current value
// of the scroll position.
// Recent changes in the app mean that the <html> tag at the top of the app can scroll as well, so we also need to be
// able to record and restore the scroll position of that element.

import {useState, useCallback} from "react";

export default function useScrollPosition() {
    const [currentElement, setCurrentElement] = useState(null);

    const setElement = useCallback(node => {
        // We want to keep up to date with the scroll position, so we need to find the nearest element that
        // is scrollable and then store that as the current element
        if(node) {
            do {
                const overflowYStyle = window.getComputedStyle(node).overflowY;
                if('auto' === overflowYStyle) {
                    setCurrentElement(node);

                    // We are done, so end the function
                    return;
                }
                node = node.parentElement;
            } while(node.parentElement);
        } else {
            // The node that we are watching has gone away.
            setCurrentElement(null);
        }
    }, []);

    const getScrollPosition = useCallback(() => {
        let result = {
            htmlElement: document.children[0].scrollTop
        };
        if(currentElement) {
            result.currentElement = currentElement.scrollTop;
        }
        return result;
    }, [currentElement]);

    return { getScrollPosition, setElement };
}
