import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createUseStyles} from 'react-jss';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {CircularProgress, Typography} from '@mui/material';
import { AddButton, LinkButton, modules, Notification, ExternalLink } from 'omse-components';
import {styles} from './DownloadStyles';
import {getRecipeLibrary} from '../../modules/recipes/actions';
import RecipeListItem from './recipeLibrary/RecipeListItem';
import routePaths from '../../util/routes';
import features from '../../../shared/features';
import FeatureCheck from '../../components/FeatureCheck';
import {hasCreateRecipePermission, hasPartnerCatalogue} from "../../util/permissions";
import useNavigator from "../../hooks/useNavigator";
import classNames from 'classnames';
import {ReactComponent as RecipeSVG} from "../../components/icons/recipe-large.svg";
import {useLocation, useHistory} from 'react-router';
import {loadPartnerContractCatalogue, loadPartnerContracts} from "../../modules/partner/actions";
import {ReactComponent as HelpIcon} from "../../components/icons/help-notification.svg";
import {ClickAwayTooltip} from "../../components/ClickAwayTooltip";
import {useResetLocationState} from "./recipeLibrary/useLocationState";
import RecipeLibraryDatePicker, {FROM_DATE, TO_DATE} from "./recipeLibrary/RecipeLibraryDatePicker";
import recipeLibraryStyles from "./recipeLibrary/recipeLibraryStyles";
import useRecipeList from "./recipeLibrary/useRecipeList";
import RecipeLibrarySearch from "./recipeLibrary/RecipeLibrarySearch";
import RecipeLibrarySort, {SORT} from "./recipeLibrary/RecipeLibrarySort";
import RecipeLibraryView from "./recipeLibrary/RecipeLibraryView";

const {getThemeTree} = modules.actions.themes;
const messages = defineMessages({
    title: {
        id: 'RecipeLibrary.title',
        defaultMessage: 'OS Select+Build Recipe Library',
        description: 'Label for recipe library title'
    },
    noSearchResults: {
        id: 'RecipeLibrary.noSearchResults',
        defaultMessage: 'No recipes match your search',
        description: 'Label for no search results'
    },
    findRecipesReset: {
        id: 'RecipeLibrary.findRecipesReset',
        defaultMessage: 'Clear search',
        description: 'Aria text for the search box reset button'
    },
    error: {
        id: 'RecipeLibrary.error',
        defaultMessage: 'There was a problem loading your recipes. Please try again later or {link} if the problem persists.',
        description: 'Label for when an error has occurred loading the recipe library'
    },
    noRecipes: {
        id: 'RecipeLibrary.noRecipes',
        defaultMessage: 'You don\'t have any recipes yet. Create your first one!',
        description: 'Label for when there are not any recipes.'
    },
    noRecipesDescription: {
        id: 'RecipeLibrary.noRecipesDescription',
        defaultMessage: 'Select just what you need from the rich data OS has to offer, and create your own custom data recipe.',
        description: 'Description for when there are not any recipes.'
    },
    noRecipesWithoutPermissions: {
        id: 'RecipeLibrary.noRecipesWithoutPermissions',
        defaultMessage: 'You don\'t have any recipes.',
        description: 'Label for when there are not any recipes and the user does not have permission to create one.'
    },
    create: {
        id: 'RecipeLibrary.create',
        defaultMessage: 'Create a new recipe',
        description: 'Label for the create recipe button.'
    },
    noRecipesCreate: {
        id: 'RecipeLibrary.noRecipesCreate',
        defaultMessage: 'Create your first recipe',
        description: 'Label for the create recipe button when you have no recipes.'
    },
    recipeExplanationTitle: {
        id: 'RecipeLibrary.recipeExplanationTitle',
        defaultMessage: 'What is a recipe?',
        description: 'Title for the section explaining what a recipe is.'
    },
    recipeExplanation: {
        id: 'RecipeLibrary.recipeExplanation',
        defaultMessage: 'A recipe allows you to select only the data you need from everything the OS National Geographic Database has to offer. Once created, you can add data packages to download data from your recipe, as you would from any other Premium Data product.',
        description: 'The section explaining what a recipe is.'
    },
    recipeExplanationTooltipLabel: {
        id: 'RecipeLibrary.recipeExplanationTooltipLabel',
        defaultMessage: 'Show recipe explanation',
        description: 'Recipe explanation tool tip label'
    },
    recipeListPreAmble: {
        id: 'RecipeLibrary.recipeListPreAmble',
        defaultMessage: 'Search your organisation\'s library of recipes created from the OS National Geographic Database, or create your own custom recipe and select just what you need.',
        description: 'The PreAmble for the recipe list.'
    },
    toastAcceptedPrefix: {
        id: 'RecipeLibrary.toastAcceptedPrefix',
        defaultMessage: 'Recipe accepted: ',
        description: 'The prefix for the recipe accepted notification.'
    },
    toastAcceptedMessage: {
        id: 'RecipeLibrary.toastAcceptedMessage',
        defaultMessage: 'The recipe has been added to your recipe library.',
        description: 'The message for the recipe accepted notification.'
    },
    toastRejectedPrefix: {
        id: 'RecipeLibrary.toastRejectedPrefix',
        defaultMessage: 'Recipe rejected: ',
        description: 'The prefix for the recipe rejected notification.'
    },
    toastRejectedMessage: {
        id: 'RecipeLibrary.toastRejectedMessage',
        defaultMessage: 'The recipe has not been added to your recipe library.',
        description: 'The message for the recipe rejected notification.'
    },
    toastCloseAriaLabel: {
        id: 'RecipeLibrary.toastCloseAriaLabel',
        defaultMessage: 'Close alert',
        description: 'The aria label for the close button on the recipe sharing notification.'
    },
    resetFilter:{
        id:'RecipeLibrary.resetFilter',
        defaultMessage: 'Clear filters',
        description: 'Message for the linkbutton that resets the filters.'
    }
});

const useDownloadStyles = createUseStyles(styles);

export default function RecipeLibrary() {
    const resetAll = useResetLocationState([SORT]);
    const downloadClasses = useDownloadStyles();
    const classes = recipeLibraryStyles();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const {navigate} = useNavigator({ path: routePaths.recipeEditor });
    const userDetails = useSelector(state => state.user.current.result);
    const user = useSelector(state => state.user.current.result);
    const intl = useIntl();

    // If we navigated here immediately after accepting/declining a recipe, then we show a notification to the user
    // For the accepting case, the state is a true/false value to tell us if it was accepted or rejected
    // For the deletion case, the state is actually the message that we need to display
    const [showRecipeAccepted, setShowRecipeAccepted] = useState(location?.state?.recipeAccepted);
    const [showRecipeDeleted, setShowRecipeDeleted] = useState(location?.state?.recipeDeleted);

    // Get an up-to-date view of the partner state, if appropriate
    useEffect(() => {
        if(hasPartnerCatalogue(user)) {
            dispatch(loadPartnerContracts());
            dispatch(loadPartnerContractCatalogue());
        }
    }, [dispatch, user]);

    // Recipe library.
    useEffect(() => {
        dispatch(getRecipeLibrary())
    }, [dispatch]);
    const {loading: recipesLoading, error: recipesError, result: allRecipes} = useSelector(state => state.recipes.library);

    useEffect(() => {
        dispatch(getThemeTree());
    }, [dispatch]);
    const {loading: themeTreeLoading, error: themeTreeError, result: themeTree} =
        useSelector(state => state.themes.themeTree);

    // Create a map of feature type ID to theme, collection and feature type info for that feature. This is used to
    // perform filtering of recipes according to theme/collection/feature type labels.
    const featureTypeMap = useMemo(() => {
        if(!themeTree) return {};
        const featureTypeMap = {};
        for(const theme of themeTree) {
            for(const collection of theme.collections) {
                for(const featureType of collection.featureTypes) {
                    featureTypeMap[featureType.featureId] = {theme, collection, featureType};
                }
            }
        }
        return featureTypeMap;
    }, [themeTree]);

    const filteredRecipes = useRecipeList(featureTypeMap);

    // Calculate component state.
    let state;
    if(!recipesError && !themeTreeError && allRecipes && themeTree) {
        // We have a result, so we can use it for now. It's possible that the state is also loading, and when
        // that load completes we will re-render, but we may as well show what we have for now.
        state = 'loaded';
    } else if(recipesLoading || themeTreeLoading) {
        state = 'loading';
    } else {
        state = 'error';
    }

    return <FeatureCheck feature={features.NGD_DOWNLOADS}>
        <header className={downloadClasses.root}>
            <div className={downloadClasses.innerHeader}>
                <Typography variant='h1' color='primary'>
                    <FormattedMessage {...messages.title}/>
                </Typography>
                {hasCreateRecipePermission(userDetails) && <AddButton label={messages.create}
                                                                      action={navigate}/>}
            </div>
            {state === 'loaded' && allRecipes.length > 0 &&
                <div className={classes.headerPreamble}>
                    <Typography variant={'body1'}>
                        <FormattedMessage {...messages.recipeListPreAmble}/>
                        <ClickAwayTooltip classes={{clickAwayTooltip: classes.tooltip, paper: classes.paper}}
                                          icon={<HelpIcon height={22} width={22} className={classes.tooltipIcon} />}
                                          body={messages.recipeExplanation}
                                          ariaLabel={intl.formatMessage(messages.recipeExplanationTooltipLabel)}
                                          id={'recipeExplanation'}
                        />
                    </Typography>

                </div>
            }
        </header>

        <div className={downloadClasses.content}>
            {state === 'loading' && <CircularProgress size={32} className={classes.loadingSpinner} />}

            {state === 'error' && <RecipesErrorNotification />}

            {/* No results of any kind has a special display. */}
            {state === 'loaded' && allRecipes.length === 0 && <NoResultsMessage />}

            {/* Recipes exist. */}
            {state === 'loaded' && allRecipes.length > 0 && <>
                <div className={classes.optionRow}>
                    <RecipeLibrarySearch/>
                    <div className={classNames(classes.optionRow, classes.dateSelectors)}>
                        <RecipeLibraryDatePicker variant={FROM_DATE}/>
                        <RecipeLibraryDatePicker variant={TO_DATE}/>
                    </div>
                </div>
                <div className={classes.optionRow}>
                    <RecipeLibraryView/>
                    <RecipeLibrarySort/>
                </div>
                <LinkButton data-testid={"clearFilters"} onClick={resetAll} className={classes.resetFilters}>
                        <FormattedMessage {...messages.resetFilter}/>
                </LinkButton>
                <ol className={classes.recipeList}>
                    {filteredRecipes.map(recipe => <RecipeListItem key={recipe.id} recipe={recipe} featureTypeMap={featureTypeMap} />)}
                </ol>
            </>}

            {/* No recipes after searching. */}
            {
                state === 'loaded' && allRecipes.length > 0 && filteredRecipes.length === 0 &&
                <NoSearchResultsNotification/>
            }

            {
                showRecipeAccepted !== undefined && <RecipeSharingNotification accepted={showRecipeAccepted}
                                                                               onClose={() => {setShowRecipeAccepted(undefined)}}/>
            }
            {
                showRecipeDeleted && <Notification variant='success' appearance='toast' autoClose={true} onClose={() => {
                    setShowRecipeDeleted(null);
                    const newLocation = {...location};
                    delete newLocation.state.recipeDeleted;
                    history.replace(newLocation);
                }}>
                    <Typography variant='body1'>
                        <FormattedMessage {...showRecipeDeleted}/>
                    </Typography>
                </Notification>
            }
        </div>
    </FeatureCheck>
}

function RecipesErrorNotification() {
    const classes = recipeLibraryStyles();
    return <Notification variant='error'
                         role='alert'
                         appearance='inline'
                         className={classes.error}>
        <Typography variant='body1'>
            <FormattedMessage {...messages.error} values={{ link:
                <ExternalLink type='support' />
            }} />
        </Typography>
    </Notification>
}

function NoResultsMessage() {
    const classes = recipeLibraryStyles();
    const userDetails = useSelector(state => state.user.current.result);
    const emptyListMessage = hasCreateRecipePermission(userDetails) ? messages.noRecipes : messages.noRecipesWithoutPermissions;
    const {navigate} = useNavigator({ path: routePaths.recipeEditor });

    return <div className={classes.noResultsContent}>
        <div className={classes.contentCenter}>
            <RecipeSVG aria-hidden={true}/>
            <Typography variant='h2'className={classNames(classes.emptyListMessage, classes.headingBottomMargin)} color="textPrimary">
                <FormattedMessage {...emptyListMessage}  />
            </Typography>
            <Typography variant='h3' color="textPrimary" component='p'>
                <FormattedMessage {...messages.noRecipesDescription} />
            </Typography>
            {
                hasCreateRecipePermission(userDetails) &&
                <AddButton className={classes.paddedButton} label={messages.noRecipesCreate} action={navigate}/>
            }
        </div>
        <div className={classes.contentLeft}>
            <Typography variant='h2' color="textPrimary" className={classes.headingBottomMargin}>
                <FormattedMessage {...messages.recipeExplanationTitle}  />
            </Typography>
            <Typography variant='h3' color="textPrimary" paragraph={true}>
                <FormattedMessage {...messages.recipeExplanation} />
            </Typography>
        </div>
    </div>
}

function NoSearchResultsNotification() {
    const intl = useIntl();
    const resetAll = useResetLocationState([SORT]);
    return <Notification variant='info'
                         appearance='inline'
                         onClose={resetAll}
                         closeAriaLabel={intl.formatMessage(messages.findRecipesReset)}>
        <Typography variant='body1'>
            <FormattedMessage {...messages.noSearchResults}/>
        </Typography>
    </Notification>;
}

function RecipeSharingNotification({accepted, onClose}) {
    const intl = useIntl();
    let prefix, message;
    if(accepted) {
       prefix = messages.toastAcceptedPrefix;
       message = messages.toastAcceptedMessage;
    } else {
        prefix = messages.toastRejectedPrefix;
        message = messages.toastRejectedMessage;
    }
    return <Notification variant='success'
                         appearance='toast'
                         onClose={onClose}
                         autoClose={true}
                         closeAriaLabel={intl.formatMessage(messages.toastCloseAriaLabel)}
    >
        <Typography variant='body2' component='span'>
            <FormattedMessage {...prefix}/>
        </Typography>
        <Typography variant='body1' component='span'>
            <FormattedMessage {...message}/>
        </Typography>
    </Notification>;
}