import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import {osColour} from 'omse-components';
import cadcorp1LaunchDesktop from './cadcorpSIS/1-launch-desktop.png';
import cadcorp2AddOverlayWizard from './cadcorpSIS/2-add-overlay-wizard.png';
import cadcorp3OSDataHub from './cadcorpSIS/3-os-data-hub.png';
import cadcorp4NoAPIKey from './cadcorpSIS/4-no-api-key.png';
import cadcorp4OSMapsAPI from './cadcorpSIS/4-os-maps-api.png';
import cadcorp4WithAPIKey from './cadcorpSIS/4-with-api-key.png';
import cadcorp5MapsAPILayer from './cadcorpSIS/5-maps-api-layer.png';
import cadcorp5OSMapsAPI from './cadcorpSIS/5-os-maps-api.png';
import WMTSCompletionSummary from './WMTSCompletionSummary';
import PropTypes from 'prop-types';

const styles = {
    guide: {
        '& img': {
            border: `solid ${osColour.neutral.mist} 1px`
        }
    }
};

export function CadcorpSIS(props) {
    const {classes} = props;
    return <Fragment>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The Cadcorp Spatial Information System® (Cadcorp SIS®) is an integrated family of geospatial
                    products comprising desktop, web, and developer applications.
               </p>
                <p>
                    Cadcorp SIS Desktop and Cadcorp SIS Desktop Express (free) connects directly to the Ordnance Survey
                    Data Hub through dedicated wizards.
               </p>
                <p>
                    The following instructions will demonstrate how to connect to the OS Maps API. For the purposes of
                    this guide, the version of Cadcorp SIS Desktop Express used is 9.0.2275.
               </p>
            </Typography>
            <Typography variant='h2'>Integrating OS Maps API in Cadcorp SIS</Typography>
            <Typography variant='body1' paragraph={true} component='div' className={classes.guide}>
                <ol>
                    <li>
                        <p>Start Cadcorp SIS Desktop Express. From the opening window, select a Blank Map or a Map
                            Template with pre-defined base mapping.</p>
                        <img src={cadcorp1LaunchDesktop}
                             alt='Cadcorp SIS create map screen with Blank Map or Map template highlighted' />
                    </li>
                    <li>
                        <p>Navigate to the Home tab at the top of the interface and launch the Add Overlay wizard.</p>
                        <img src={cadcorp2AddOverlayWizard}
                             alt='Cadcorp SIS Home toolbar with Add Overlay button highlighted' />
                    </li>
                    <li>
                        <p>Navigate to Ordnance Survey (GB) → OS (GB) Data Hub → click Next.</p>
                        <img src={cadcorp3OSDataHub}
                             alt='Cadcorp SIS Overlay Types selection screen with OS (GB) Data Hub option and next button
                                  highlighted' />
                    </li>
                    <li>
                        <p>If you do not have an API key yet, you can use the Cadcorp API Key included as standard. The
                            Cadcorp API key is restricted to the free Open Data Plan and cannot be used to access
                            Premium features. Select the OS Maps API → click Next.
                       </p>
                        <img src={cadcorp4OSMapsAPI}
                             alt='Cadcorp SIS OS (GB) Data Hub menu with OS Maps API option and next button highlighted' />

                        <p>Select Yes to use the Cadcorp API Key.</p>
                        <img src={cadcorp4NoAPIKey}
                             alt='Confirmation dialog to use the default Cadcorp key OS Data Hub API with Yes
                                  button highlighted' />

                        <p>If you have an API key, add the API key. If you have access to
                            Premium features, select Premium Plan. Select the OS Maps API → click Next.</p>
                        <img src={cadcorp4WithAPIKey}
                             alt='Cadcorp SIS OS (GB) Data Hub menu with API Key filled in, Premium Plan ticked, and
                                  next button highlighted' />
                    </li>
                    <li>
                        <p>A window will display the OS Maps API layers. Select the style and coordinate reference
                            system projection you wish to use.</p>
                        <img src={cadcorp5MapsAPILayer}
                             alt='Cadcorp SIS OS Data Hub Maps API Layers selection menu with finish button
                                  highlighted' />
                        
                        <p>Click Finish to add the layer to the Map.</p>
                        <img src={cadcorp5OSMapsAPI}
                             alt='Cadcorp SIS display of rendered map using options from previous steps' />
                    </li>
                </ol>
            </Typography>
            <WMTSCompletionSummary software="CadcorpSIS" />
    </Fragment>
}

CadcorpSIS.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string
}

export default withStyles(styles)(CadcorpSIS);