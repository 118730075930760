import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ClickAwayListener, CircularProgress, Tabs, Tab, Popper, Paper, Typography, Button, IconButton, useMediaQuery} from '@mui/material';
import {osColour, arrow, box, linkButton, theme, arrowTooltipStyles} from 'omse-components';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {getNotifications, getAllNotifications, updateAllNotifications} from '../modules/notifications/actions'
import {useDispatch, useSelector} from "react-redux";
import Support from './icons/support.svg';
import NotificationItem from './NotificationItem';
import {ReactComponent as Tick} from "./icons/tick.svg";
import {createUseStyles} from 'react-jss';
import {ReactComponent as CloseIcon} from "./icons/close-large.svg";
import classNames from 'classnames';

const messages = defineMessages({
    markAllAsRead: {
        defaultMessage: 'Mark all as read',
        description: 'Text for mark all button',
        id: 'Notifications.markAllAsRead'
    },
    noNotificationsHeading: {
        defaultMessage: "You're all up to date.",
        description: 'Text for no notifications heading',
        id: 'Notifications.noNotificationsHeading'
    },
    noNotifications: {
        defaultMessage: "No unread notifications here.",
        description: 'Text for no notifications',
        id: 'Notifications.noNotifications'
    },
    viewAllNotifications: {
        defaultMessage: '{viewAll} your read notifications instead',
        description: 'Text for view all notifications',
        id: 'Notifications.viewAllNotifications'
    },
    viewAll: {
        defaultMessage: "View all",
        description: 'Text for view all link',
        id: 'Notifications.viewAll'
    },
    viewMore: {
        defaultMessage: "View more",
        description: 'Text for view more link',
        id: 'Notifications.viewMore'
    },
    unread: {
        defaultMessage: "Unread",
        description: 'Text for unread tab',
        id: 'Notifications.unread'
    },
    showAll: {
        defaultMessage: "Show all",
        description: 'Text for showAll tab',
        id: 'Notifications.showAll'
    }
});

const useStyles = createUseStyles(theme => ({
    popper: {
        boxShadow: box.shadow,
        borderRadius: box.borderRadius,
        '&[data-popper-placement*="bottom"] $arrow': {
            top: 0,
            marginTop: arrow.offset
        },
        '&[data-popper-placement*="top"] $arrow': {
            bottom: 0,
            marginBottom: arrow.offset
        },
        zIndex: 9,
        background: osColour.neutral.white
    },
    notificationHeader: {
        display: 'flex',
        borderBottom: `solid ${osColour.neutral.mist} 1px`,
        alignItems: "center",
        justifyContent: 'space-between'     
    },
    notificationPanel: props => ({
        boxShadow: 'none',
        overflowX: 'hidden',
        overflowY: 'auto',
        minHeight: 100,
        maxHeight: `calc(100vh - ${props.iconOffset}px - ${theme.spacing(9)})`
    }),
    notificationContent: {
        width: 360
    },
    tabsRoot: {
        minHeight: 30
    },
    tabRoot: {
        padding: theme.spacing(1.25),
        margin: theme.spacing(0.75, 0, 0.75, 1),
        minWidth: 'auto',
        opacity: 1,
        ...theme.typography.body1,
        color: osColour.primary.berry,
        '&:hover:not($tabSelected)': {
            boxShadow: '0 3px 0 0 #fff, 0 6px 0 0 ' + osColour.primary.lighterBerry
        },
        minHeight: '36px !important'
    },
    tabSelected: {
        ...theme.typography.body2,
        color: osColour.primary.berry,
        boxShadow: '0 3px 0 0 #fff, 0 6px 0 0 #645baf'
    },
    notificationTabs: {
        width: '100%',
        background: osColour.neutral.white,
        zIndex: 1,
    },
    indicator: {
        backgroundColor: osColour.primary.berry,
        paddingTop: 2
    },
    doneIcon: {
        color: osColour.link.base
    },
    closeIcon: {
        fontSize: '1.75rem',
        marginRight: '8px',
        zIndex: 1
    },
    markAll: {
        '&:hover': {
            '& $doneIcon': {
                color: osColour.link.hover
            }
        }
    },
    markAllText: {
        marginLeft: theme.spacing(0.5)
    },
    secondaryControls: {
        paddingTop: 16,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 6,
        background: osColour.neutral.white,
        position: 'relative'

    },
    robotIcon: {
        margin: '0 auto',
        display: 'block',
        marginBottom: theme.spacing(1)
    },
    noNotificationsContainer: {
        paddingTop: 64,
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 50,
        maxHeight: '100%'
    },
    noNotificationsHeading: {
        textAlign: 'center',
        marginBottom: theme.spacing(1.5)
    },
    noNotifications: {
        textAlign: 'center'
    },
    loading: {
        padding: 20
    },
    warningIcon: {
        float: 'right',
        color: osColour.status['warning'],
        marginTop: 2
    },
    viewMore: {
        paddingTop: 25,
        paddingBottom: 20,
        textAlign: 'center'
    },
    buttonProgress: {
        position: 'absolute',
        left: 'calc(50% - 15px/2)',
        zIndex: 1
    },
    ...arrowTooltipStyles(),
    flexContainer: {
        alignItems: 'center'
    } 
}));

const useLinkStyles = createUseStyles({linkButton});

export default function Notifications(props) {
    const {anchorEl, open, handleClickAway} = props;
    const pageSize = 4;
    const intl = useIntl();
    const unreadTab = intl.formatMessage(messages.unread);
    const showAllTab = intl.formatMessage(messages.showAll);

    let iconOffset = 200;
    if (anchorEl) {
        const iconRect = anchorEl.getBoundingClientRect();
        iconOffset = iconRect.y + iconRect.height;
    }
    const classes = useStyles({iconOffset});
    const classesLink = useLinkStyles();

    const [currentTab, setCurrentTab] = useState(unreadTab);
    const [limit, setLimit] = useState(pageSize);
    const notifications = useSelector(state => state.notifications.current.result || []);
    const loading = useSelector(state => state.notifications.current.loading);
    const update = useSelector(state => state.notifications.update);
    const dispatch = useDispatch();

    const extraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        if (open) {
            if (currentTab === unreadTab) {
                dispatch(getNotifications());
            } else {
                dispatch(getAllNotifications());
            }
        } else {
            setCurrentTab(unreadTab);
        }
    }, [open, dispatch, currentTab, setCurrentTab, unreadTab]);

    // If unmounting then undisplay
    useEffect(()=> handleClickAway, [handleClickAway]);

    const viewAll = () => {
        setFilter({}, showAllTab);
    }

    const viewMore = () => {
        setLimit(limit + pageSize);
    }

    const setFilter = (event, filter) => {
        setCurrentTab(filter);
        setLimit(pageSize);
    }

    const markAllAsRead = () => {
        dispatch(updateAllNotifications());
    }
    
    const tabClasses = {
        root: classes.tabRoot,
        selected: classes.tabSelected
    };

    return <Fragment>
        <Popper
            className={classes.popper}
            anchorEl={anchorEl}
            open={open}
            placement="bottom"
            disablePortal={true}
            popperOptions={{
                strategy: 'fixed'
            }}
            modifiers={[
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        padding: 5
                    }
                },
                {
                    name: 'arrow',
                    enabled: true,
                    options: {
                        element: '.' + classes.arrow
                    }
                },
                {
                    name: 'flip',
                    enabled: false
                }
            ]}>
            <span className={classes.arrow} />
            <ClickAwayListener onClickAway={handleClickAway} mouseEvent='onClick'>
                <div>
                    <div className={classes.notificationHeader}>
                        <Tabs className={classes.notificationTabs}
                                value={currentTab}
                                onChange={setFilter}
                                classes={{root: classes.tabsRoot, indicator: classes.indicator, flexContainer: classes.flexContainer}}>
                            <Tab classes={tabClasses}
                                    value={unreadTab} label={unreadTab} key={unreadTab}/>
                            <Tab classes={tabClasses}
                                    value={showAllTab} label={showAllTab} key={showAllTab}/>
                        </Tabs>
                        {extraSmallScreen && (
                            <IconButton aria-label="close-notification-menu" role="button" className={classes.closeIcon} onClick={handleClickAway}>
                                <CloseIcon width={18} height={18}/>
                            </IconButton>
                        )}
                    </div>
                    <Paper className={classes.notificationPanel}>
                        {!loading && notifications.length > 0 && notifications.some(n => !n.read) &&
                            <div className={classes.secondaryControls}>
                                <Button className={classNames(classesLink.linkButton, classes.markAll)}
                                        onClick={markAllAsRead}
                                        disabled={update.working}
                                        style={{opacity: update.working? 0.6 : 1}}
                                        disableRipple>
                                    <Tick width={24} height={24} className={classes.doneIcon} />
                                    <Typography variant='caption' className={classes.markAllText}>
                                        <FormattedMessage {...messages.markAllAsRead} />
                                    </Typography>
                                    {update.working &&
                                    <CircularProgress size={24} className={classes.buttonProgress}/>
                                    }
                                </Button>
                            </div>
                        }
                        <div className={classes.notificationContent}>

                            {loading &&
                                <div className={classes.loading}>
                                    <CircularProgress size={24} className={classes.buttonProgress}/>
                                </div>
                            }

                            {!loading && notifications.length === 0 &&
                                <div className={classes.noNotificationsContainer}>
                                    <img src={Support} className={classes.robotIcon} alt='No notifications icon' />
                                    <Typography variant="body2" className={classes.noNotificationsHeading}>
                                        <FormattedMessage {...messages.noNotificationsHeading}/>
                                    </Typography>
                                    <Typography variant="body1" className={classes.noNotifications}>
                                        <FormattedMessage {...messages.noNotifications}/>
                                    </Typography>
                                    {currentTab === unreadTab &&
                                        <Typography variant="body1">
                                            <FormattedMessage {...messages.viewAllNotifications} values={{
                                                viewAll: <span className={classesLink.linkButton} onClick={viewAll}>
                                                    <FormattedMessage {...messages.viewAll}/>
                                                </span>
                                            }}/>
                                        </Typography>
                                    }
                                </div>
                            }

                            {!loading && notifications.length > 0 &&
                                notifications.slice(0, limit).map(notification => {
                                    return <NotificationItem key={notification.id} item={notification} handleNavigation={handleClickAway}/>
                                })
                            }

                            {!loading && notifications.length > limit &&
                                <div className={classes.viewMore}>
                                    <Button className={classesLink.linkButton} onClick={viewMore} disableRipple>
                                        <FormattedMessage {...messages.viewMore}/>
                                    </Button>
                                </div>
                            }
                        </div>
                    </Paper>
                </div>
            </ClickAwayListener>
        </Popper>
    </Fragment>
}

Notifications.propTypes = {
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.object,
    handleClickAway: PropTypes.func.isRequired
};
