import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Button, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { osColour } from 'omse-components';
import PropTypes from 'prop-types';
import { PSGA_COMMERCIAL_ENQUIRIES, PARTNER_MEMBER_URL, signUpLink } from '../util/routes';
import { titleKey, bodyKey, subtitleKey, buttonKey, makeMessageKeyFn } from '../util/i18n';
import { ENERGY_AND_INFRASTRUCTURE_PLAN } from "../../shared/plans";
import { useSelector } from 'react-redux';

const useStyles = createUseStyles(theme => ({
    container: {
        border: 'solid 1px ' + osColour.neutral.mist,
        borderRadius: '0 3px 3px 0',
        borderLeft: 'solid 4px ' + osColour.primary.berry,
        background: osColour.neutral.clouds,
        padding: theme.spacing(0, 3, 3, 3),
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            flex: 'auto',
            padding: theme.spacing(0, 2, 2, 2),
            border: 'none',
            borderLeft: 'solid 4px ' + osColour.primary.berry,
            borderRadius: 0
        }
    },
    titleText: {
        paddingTop: theme.spacing(3),
        color: osColour.primary.berry,
        fontSize: '1.75rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem'
        }
    },
    subtitle: {
        color: osColour.neutral.stone,
        fontSize: '1rem'
    },
    body: {
        flexGrow: 2,
        color: osColour.neutral.charcoal,
        marginTop: theme.spacing(2),
        lineHeight: '1.75rem',
        '& a': {
            fontSize: '1.125rem',
            display: 'inline'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            '& a': {
                fontSize: '1rem'
            }
        }
    },
    actionButtons: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap'
    },
    actionButton: {
        background: osColour.neutral.white,
        marginTop: theme.spacing(1),
        alignSelf: 'flex-start',
        '&:first-of-type': {
            marginRight: theme.spacing(2)
        },
    }
}));

export const FIND_PARTNER = 'findPartner';
export const EAI = 'eai';
export const variants = [FIND_PARTNER, EAI];

const componentName = 'InfoActionContent';

const titleElement = 'Title';
const bodyElement = 'Body';
const subtitleElement = 'Subtitle';
const buttonElement = 'Button';
const button2Element = 'Button2';
export const button2Key = makeMessageKeyFn(button2Element);

const componentPrefix = componentName + '.';
const findPartnerPrefix = componentPrefix + FIND_PARTNER;
const eaiPrefix = componentPrefix + EAI;

export const messages = defineMessages({
    [titleKey(FIND_PARTNER)]: {
        id: findPartnerPrefix + titleElement,
        defaultMessage: 'Find an OS licensed partner',
        description: 'Title'
    },
    [bodyKey(FIND_PARTNER)]: {
        id: findPartnerPrefix + bodyElement,
        defaultMessage: 'If you are looking for an OS licensed partner, for example to use data for your internal business processes, please visit our partner finder.',
        description: 'Body'
    },
    [buttonKey(FIND_PARTNER)]: {
        id: findPartnerPrefix + buttonElement,
        defaultMessage: 'Search OS partners',
        description: 'Button'
    },
    [titleKey(EAI)]: {
        id: eaiPrefix + titleElement,
        defaultMessage: 'Energy & Infrastructure',
        description: 'Title'
    },
    [subtitleKey(EAI)]: {
        id: eaiPrefix + subtitleElement,
        defaultMessage: 'For new and existing Energy & Infrastructure customers.',
        description: 'Subtitle'
    },
    [bodyKey(EAI)]: {
        id: eaiPrefix + bodyElement,
        defaultMessage: 'If your business is part of the energy and infrastructure sector you may qualify for our internal business use plan.',
        description: 'Body'
    },
    [buttonKey(EAI)]: {
        id: eaiPrefix + buttonElement,
        defaultMessage: 'Enquire about eligibility',
        description: 'Button'
    },
    [button2Key(EAI)]: {
        id: eaiPrefix + button2Element,
        defaultMessage: 'Existing E&I customer',
        description: 'Button 2'
    }
});

export const actionLinks = {
    [FIND_PARTNER]: PARTNER_MEMBER_URL,
    [EAI]: PSGA_COMMERCIAL_ENQUIRIES
}

export default function InfoActionContent({ title, subtitle, body, actionButton, variant, bodyFormatted, actionNewTab }) {
    const classes = useStyles();
    const userDetails = useSelector(state => state.user.current.result);
    let messageTitle = title;
    let messageBody = body;
    let messageSubtitle = subtitle;
    let messageButton, messageButton2;
    if (variant) {
        messageTitle = messages[titleKey(variant)];
        messageSubtitle = messages[subtitleKey(variant)];
        messageBody = messages[bodyKey(variant)];
        messageButton = messages[buttonKey(variant)];
        messageButton2 = messages[button2Key(variant)];
    }

    const button1Displayed = (actionButton || messageButton);
    const button2Displayed = (variant === EAI && !userDetails);

    return <div className={classes.container}>
        {messageTitle &&
            <Typography variant='h1' className={classes.titleText}>
                <FormattedMessage {...messageTitle} />
            </Typography>
        }

        {messageSubtitle &&
            <Typography variant='h2' className={classes.subtitle}>
                <FormattedMessage {...messageSubtitle} />
            </Typography>
        }

        {(messageBody || bodyFormatted) &&
            <Typography variant='h5' component='p' className={classes.body}>
                {bodyFormatted ? bodyFormatted : <FormattedMessage {...messageBody} />}
            </Typography>
        }

        {(button1Displayed || button2Displayed) &&
            <div className={classes.actionButtons}>
                {button1Displayed && <>
                    {actionButton || <>
                        {messageButton &&
                            <Button onClick={() => window.open(actionLinks[variant], actionNewTab ? '_blank' : '_self')}
                                variant='outlined' color='primary' className={classes.actionButton}>
                                <FormattedMessage {...messageButton} />
                            </Button>
                        }
                    </>
                    }
                </>
                }
                {button2Displayed &&
                    <Button onClick={() => window.location.assign(signUpLink(ENERGY_AND_INFRASTRUCTURE_PLAN))}
                        variant='outlined' color='primary' className={classes.actionButton}>
                        <FormattedMessage {...messageButton2} />
                    </Button>
                }
            </div>
        }
    </div>;
}

InfoActionContent.propTypes = {
    title: PropTypes.object,
    subtitle: PropTypes.object,
    body: PropTypes.object,
    bodyFormatted: PropTypes.object,
    actionButton: PropTypes.object,
    variant: PropTypes.oneOf(variants)
}

InfoActionContent.defaultProps = {
    actionNewTab: true
}
