import React from 'react';
import PropTypes from 'prop-types';
import Link from "./Link";
import {ReactComponent as LeftArrow} from "./icons/backward-arrow-large.svg";
import {FormattedMessage, useIntl, defineMessages} from 'react-intl';
import withStyles from 'react-jss';
import {Typography} from '@mui/material';

const messages = defineMessages({
    backIconAlt: {
        id: 'BackLink.backIconAlt',
        defaultMessage: 'Back to',
        description: 'Alt text for the back icon'
    }
});

function styles(theme) {
    return {
        linkStyle: {
            marginTop: theme.spacing(4.5),
            marginLeft: theme.spacing(3),
            display: 'inline-flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(2)
            }
        },
        linkIcon: {
            width: '16px',
            height: '16px',
            paddingTop: '2px',
            paddingRight: theme.spacing(1)
        }
    };
}

function BackLink(props) {
    const {path, label, classes, state} = props;
    const intl = useIntl();

    return <nav>
        <Link path={path} className={classes.linkStyle} state={state}>
            <LeftArrow className={classes.linkIcon} aria-label={intl.formatMessage(messages.backIconAlt)}/>
            <Typography variant='body1'>
                <FormattedMessage {...label}/>
            </Typography>
        </Link>
    </nav>;
}

BackLink.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    state: PropTypes.object
}

export default withStyles(styles)(BackLink);
