export const PREFIX = "action:partner:";

export const LOAD_PARTNER_CONTRACTS_ACTION = PREFIX + 'loadContracts';
export const LOADED_PARTNER_CONTRACTS_ACTION = PREFIX + 'loadedContracts';
export const LOAD_PARTNER_CONTRACT_CATALOGUE_ACTION = PREFIX + 'loadCatalogue';
export const LOADED_PARTNER_CONTRACT_CATALOGUE_ACTION = PREFIX + 'loadedCatalogue';
export const CREATE_PARTNER_CONTRACTS_ACTION = PREFIX + 'createContracts';

export function loadPartnerContracts() {
    return {
        type: LOAD_PARTNER_CONTRACTS_ACTION
    };
}

export function createPartnerContracts(contracts) {
    return {
        type: CREATE_PARTNER_CONTRACTS_ACTION,
        contracts: contracts
    }
}

export function loadPartnerContractCatalogue() {
    return {
        type: LOAD_PARTNER_CONTRACT_CATALOGUE_ACTION
    };
}