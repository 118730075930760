import React, {Fragment} from 'react';
import styles from './styles';
import {Button, Typography} from '@mui/material';
import {defineMessages, FormattedMessage} from 'react-intl';
import {logOut} from "../../util/routes";
import { ExternalLink } from 'omse-components';

const messages = defineMessages({
    logOut: {
        id: 'InvitationError.logOut',
        defaultMessage: "Log out",
        description: 'Label for the cancel button on the invitation error dialog'
    },
    intro1: {
        id: 'InvitationError.intro1',
        defaultMessage: 'We encountered an error while processing the invitation.',
        description: 'Introduction text for the invitation error page'
    },
    intro2: {
        id: 'InvitationError.intro2',
        defaultMessage: 'Please log out, and then log back into the OS Data Hub. Once you have logged in, you can use the Account settings pages to review your current organisation and check if you have any pending invitations.',
        description: 'Introduction text for the invitation error page'
    },
    intro3: {
        id: 'InvitationError.intro3',
        defaultMessage: 'If the problem continues, please {link}.',
        description: 'Introduction text for the invitation error page'
    }
});

export default function InvitationError() {
    const classes = styles();

    return <Fragment>
        <Typography variant='body1' paragraph={true} className={classes.introText}>
            <FormattedMessage {...messages.intro1}/>
        </Typography>
        <Typography variant='body1' paragraph={true} className={classes.introText}>
            <FormattedMessage {...messages.intro2}/>
        </Typography>
        <Typography variant='body1' paragraph={true} className={classes.introText}>
            <FormattedMessage {...messages.intro3} values={{ link: <ExternalLink type='support' /> }} />
        </Typography>

        <div className={classes.buttons}>
            <Button variant='contained'
                    color='primary'
                    onClick={logOut}
            >
                <FormattedMessage {...messages.logOut}/>
            </Button>
        </div>
    </Fragment>;
}