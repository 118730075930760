import {Typography} from '@mui/material';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import routes from '../../../../../util/routes';
import Link from '../../../../../components/Link';

export const messages = defineMessages({
    prompt: {
        id: 'ProductDependencyMessage.prompt',
        defaultMessage: 'To continue ordering {productName} you need to add {filteredProductsLink} to your account.',
        description: 'prompt'
    }
});

export default function ProductDependencyMessage({productId, productName, products}) {
    const intl = useIntl();

    // Do not render unless there are dependencies.
    if (!Array.isArray(products) || products.length === 0) {
        return null;
    }

    // Use the bmi code from the first result - they should all be the same.
    const bmiCode = products[0].bmiCode;

    // When there are many possible products which can resolve the dependency, join them into a list string e.g. "a, b, c, or d".
    const formattedProductList = new Intl.ListFormat(intl.locale, {style: 'long', type: 'disjunction'})
        .format(products.map(dep => dep.label));

    // Link to the catalogue list page with parameters set to filter the results.
    const filteredProductsLink = <Link path={routes.premiumDownloads} search={{bmi: bmiCode, targetProduct: productId}}>
        {formattedProductList}
    </Link>;

    return <Typography variant='body1'>
        <FormattedMessage {...messages.prompt} values={{productName, filteredProductsLink}} />
    </Typography>
}

ProductDependencyMessage.propTypes = {
    productId: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
    products: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired
}