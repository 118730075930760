import React, {useRef} from "react";
import PropTypes from 'prop-types';
import {IconButton} from "@mui/material";
import {ReactComponent as CollapseIcon} from "../../components/icons/tab_collapse.svg";
import {ReactComponent as ExpandIcon} from "../../components/icons/tab_expand.svg";
import { useTheme } from "@mui/material/styles";
import {createUseStyles} from 'react-jss';
import {defineMessages, useIntl} from "react-intl";
import {Transition} from "react-transition-group";

const TIMEOUT_MS = 1000;

const useStyles = createUseStyles(theme => ({
    panelContainer:{
        right: 0,
        overflow: "hidden",
        display: 'flex',
        pointerEvents: 'none',
        height: `calc(100% - (${theme.spacing(6)} + 20px))`,
        position: 'absolute',
        // This view is only used for mid-sized screens and up, so we allow the panel (including the control button)
        // to use that width. Really wide screens get to see more of the existing page content in the background, but
        // there is enough here to work.
        width: theme.breakpoints.values.md
    },
    panelRoot : {
        flex: '1 1 auto', // Allow the main panel to take up all the available space
        display: "flex",
        height: '100%',
        pointerEvents: 'all',
    },
    panelOverlay: {
        right: 0,
        width: '100%',
        overflow: "hidden",
        display: 'flex',
        pointerEvents: 'none'
    },
    closeTabWrapper: {
        flex: '0 0 auto', // Do not allow the close wrapper to shrink or grow
        display: 'flex',
        alignItems: 'center',
    },
    closeTabIcon: {
        padding: 0,
        marginLeft: theme.spacing(0.8),
        borderRadius: '0%',
        transform: 'rotate(180deg)',
        pointerEvents: 'all'
    }
}));

const messages = defineMessages({
    closeTabAriaLabel: {
        id: 'DataPackageName.closeTabAriaLabel',
        defaultMessage: 'Close Popout',
        description: 'Label used for the aria-label attribute on the close tab button'
    },
    openTabAriaLabel: {
        id: 'DataPackageName.openTabAriaLabel',
        defaultMessage: 'Open Popout',
        description: 'Label used for the aria-label attribute on the open tab button'
    }
});

/**
 * The behaviour of this panel is that the content slides onto the screen, so we can't skip rendering the content
 * when it is not visible - that would stop the animation from working nicely. Once the value is visible, the user
 * can collapse it against the side, which we handle by moving the panel and button across so that we can only see
 * the button.
 *
 * Note that the content within the panel will be unmounted from the react tree once the animation ends, so if the
 * components need state to survive after collapsing then you should put that state in the redux store, not react state.
 */
export default function PopoutPanel({panelContent, visible, open, setOpen, closeTabAriaLabel, openTabAriaLabel}) {
    const intl = useIntl();
    const classes = useStyles();
    const iconHeight = "138";
    const iconWidth = "33";
    const closeTabWrapperRef = useRef();
    const theme = useTheme();

    let tabLabel = closeTabAriaLabel;
    let Icon = CollapseIcon;
    if(!visible || !open) {
        tabLabel = openTabAriaLabel;
        Icon = ExpandIcon
    }

    // Open state position
    const openTransform = 'none';
    // Closed state position
    const closedTransform = `translateX(${theme.breakpoints.values.md - iconWidth - parseFloat(theme.spacing(0.6))}px)`;

    // Required transitions to make things look smooth, instant transitions happen with the render of the page
    const easingTransition = `transform ${TIMEOUT_MS}ms ${theme.transitions.easing.easeOut} 0ms`;
    const sharpeTransition = `transform ${TIMEOUT_MS}ms ${theme.transitions.easing.sharp} 0ms`;
    const instantTransition = `transform 0ms ${theme.transitions.easing.sharp} 0ms`;

    const defaultStyle =  { transform: openTransform, transition: sharpeTransition };
    const transitionStyles = {
        entered: { transform: openTransform, transition: instantTransition },
        entering:  { transform: openTransform, transition: easingTransition },
        exiting:  { transform: closedTransform, transition: sharpeTransition },
        exited:   { transform: closedTransform, transition: instantTransition, visibility : visible ? 'initial' : 'hidden' } // Once we exit if the button should no longer be available hide it
    };

    return (
        <Transition in={visible && open} timeout={TIMEOUT_MS}>
            {(state) => <div className={classes.panelContainer}>
                <div className={classes.panelOverlay} style={{...defaultStyle, ...transitionStyles[state]}}>
                    <div className={classes.closeTabWrapper} ref={closeTabWrapperRef}>
                        <IconButton
                            className={classes.closeTabIcon}
                            style={{pointerEvents: visible ? 'all' : 'none'}}
                            onClick={() => setOpen(!open)}
                            aria-label={tabLabel.defaultMessage ? intl.formatMessage(tabLabel) : tabLabel}
                            size="large">
                            <Icon width={iconWidth} height={iconHeight}/>
                        </IconButton>
                    </div>
                    {/*Once the panel is closed we should stop rendering the panelContent */}
                    {/*so the screen reader doesn't try to read it*/}
                    <div className={classes.panelRoot}>
                        {state !== 'exited' && panelContent}
                    </div>
                </div>
            </div>}
        </Transition>
    );
}

PopoutPanel.propTypes = {
    visible: PropTypes.bool.isRequired,  // When true the popout will be drawn, even if we are only showing the tab button.
    open: PropTypes.bool.isRequired,     // When true the popout will slide out and show the panel content.
    setOpen: PropTypes.func.isRequired,  // Function to allow the tab button to collapse the panel.
    panelContent: PropTypes.node.isRequired,
    closeTabAriaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    openTabAriaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

PopoutPanel.defaultProps = {
    closeTabAriaLabel: messages.closeTabAriaLabel,
    openTabAriaLabel:  messages.openTabAriaLabel
};