
// Check if a given feature is enabled. The config should have been loaded from the redux store, so it is important that
// the calling code will check again if the config reloads. Most React components will do that automatically, as they are
// connected to the store.
export default function check(featureName, config) {
    // Without config we have to assume that all features are turned off
    let features = [];
    if(config && config.features) {
        features = config.features.split(',');
    }
    return features.indexOf(featureName) !== -1;
}