import {createActionReducer, createActionMiddleware} from 'omse-components';
import {CLEAR_PAYMENT_DETAILS_ACTION} from "./actions";
import paymentActionCallback from "./paymentActionCallback";
export const reducer = createActionReducer(CLEAR_PAYMENT_DETAILS_ACTION);

function setupCall(action) {
    return {
        url: '/api/payments/clear',
        method: 'POST'
    };
}

export const middleware = createActionMiddleware(CLEAR_PAYMENT_DETAILS_ACTION, setupCall, paymentActionCallback);
