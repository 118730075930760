import React from 'react';
import {createUseStyles} from 'react-jss';
import styles from './style';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const useStyles = createUseStyles({
    container: {
        overflowX: 'auto'
    },
    noRowBackground: {
        '& tr': {
            backgroundColor: 'transparent !important'
        }
    }
});

const useTableStyles = createUseStyles(theme => styles(theme));

export const OUTLINE = 'outline'; // Without alternating row colour.
export const variants = [OUTLINE];

export default function Table(props) {
    const {className, children, header, values, variant, dataTestId} = props;
    const classes = useStyles(props);
    const classesTable = useTableStyles();

    const tableClasses = [classesTable.table];
    if (className) {
        tableClasses.push(className);
    }

    if (variant === OUTLINE) {
        tableClasses.push(classes.noRowBackground);
    }

    let tableContent;
    if (children) {
        tableContent = children;
    } 

    if (header && values) {
        tableContent = <>
            <thead>
                <tr>
                    {header.map((header, i) => <th key={i}>{header}</th>)}
                </tr>
            </thead>
            <tbody>
                {values.map((row, index) => <tr key={index}>
                    {row.map((cell, i) => <td key={i}>{cell}</td>)}
                </tr>)}
            </tbody>
        </>;
    }

    return <div className={classNames(classes.container, props.classes.container)} tabIndex='0'>
        <table className={classNames(tableClasses)} data-testId={dataTestId}>
            {tableContent}
        </table>
    </div>
}

Table.propTypes = {
    classes: PropTypes.object,
    header: PropTypes.array,
    values: PropTypes.array,
    variant: PropTypes.oneOf(variants)
}

Table.defaultProps = {
    classes: {}
}