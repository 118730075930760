import React from 'react';
import Header from "../Header";
import Content from '../Content';
import exclusionZone from './brandLogoImg/OS_guidelines_API-exclusion-zone@2x.png'
import withStyles from 'react-jss';
import BrandImage from './BrandImage';
import { styles } from './BrandStyling';
import {defineMessages} from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Document from '../Document';

const messages = defineMessages({
    title: {
        id: 'ExclusionZone.title',
        defaultMessage: 'Minimum clear space',
        description: 'Minimum clear space'
    },
    description: {
        id: 'ExclusionZone.description',
        defaultMessage: 'The logo exclusion zone is defined by the size of the ‘O’ from the word ‘Ordnance’ in the wordmark. The dimensions of the ‘O’ should be used on all sides of the logo to ensure clear space. No other element should encroach into this space, such as typography or graphic elements.',
        description: 'Description of the logo exclusion zone'
    },
});

function ExclusionZone(props) {

    const {classes} = props;
    const containerClasses = [classes.mainContainer, classes.oneItem];

    return (
        
        <Document>
            <Header back={true}>Exclusion zone</Header>
            
            <Content>
                <div className={classNames(containerClasses)}>
                    <BrandImage 
                        image={exclusionZone} 
                        alt='Ordnance Survey logo positioned in the bottom-left of a map, within the padding exclusion zone' 
                        title={messages.title} 
                        description={messages.description}
                        line={true}
                    />
                </div>
            </Content>
        </Document>
    )
}

ExclusionZone.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ExclusionZone)