import React from 'react';
import Header from "./Header";
import Content from './Content';
import DocLinks from "./DocLinks";
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import ResponseCodes from "./ResponseCodes";
import styles from './style';
import Authentication from "./Authentication";
import routes from "../../util/routes";
import Document from './Document';
import Table from './Table';
import { ExternalLink } from 'omse-components';
import TextBox from '../../components/TextBox';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

const vtsParameters = [
    ['key', 'required', 'The API Key provided by the OS Data Hub.'],
    ['srs', 'optional', <>The Spatial Reference System for the service. The options available are <InlineCodeSnippet>27700</InlineCodeSnippet> & <InlineCodeSnippet>3857</InlineCodeSnippet>. The service defaults to <InlineCodeSnippet>27700</InlineCodeSnippet>.</>],
];

function VTSParametersTable() {
    return <Table>
        <tbody>
            <tr>
                <th>Parameter</th>
                <th>Required?</th>
                <th>Value</th>
            </tr>
            {vtsParameters.map((parameter, index) =>
                <tr key={index}>
                    <td>
                        <InlineCodeSnippet>
                            {parameter[0]}
                        </InlineCodeSnippet>
                    </td>
                    <td>{parameter[1]}</td>
                    <td>{parameter[2]}</td>
                </tr>
            )}
        </tbody>
    </Table>
}

export function VTSDetail(props) {
    return <Document>
        <Header back={true}>OS Vector Tile API: Technical specification</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains the content of the OS Vector Tile API, and the methods that you can use to call it. 
                    Using OS Vector Tile API requires basic knowledge of interacting with web-based products and the 
                    application of code libraries such as Mapbox GL JS, Leaflet and ArcGIS API for JavaScript.
                </p>
                <p>
                    OS Vector Tile API is currently available as EPSG:27700 (British National Grid which is applicable just 
                    for GB) and EPSG:3857 (Web Mercator which is a global coordinate system). The maps are available as raw 
                    information presented using compressed packets of geographic data using the Protocolbuffer Binary Format 
                    (<InlineCodeSnippet>.pbf</InlineCodeSnippet>). This provides users optimised maps which render quickly on the web and offer a slick mapping experience.
                </p>
                <p>
                    The API is available using a version of RESTful design with KVP (Key Value Pair) elements.
                </p>
                <p>
                    The Vector Tile Service combines the benefits of pre-rendered raster map tiles with vector map data. 
                    Compared to an un-tiled vector map, there is a significantly reduced data transfer size, because only 
                    data within the current viewport, and at the current zoom level will be transferred.
                </p>
                <p>
                    This data can be accessed, styled and interrogated through a web-based application which is accessible via a browser.
                </p>
            </Typography>
            <Typography variant='h1' component={'h2'}>Technical Detail</Typography>
            <Typography variant='body1' paragraph={true}>
                OS Vector Tile API requests are structured using RESTful design combined with keyword-value pair (KVP)
                parameter encoding and HTTP <InlineCodeSnippet>GET</InlineCodeSnippet>. All requests must be encoded correctly according to standard <ExternalLink
                    type="generic"
                    href="https://www.w3schools.com/tags/ref_urlencode.asp"
                    message="percent-encoding"
                /> procedures for a URI.
            </Typography>
            <Authentication gettingStartedRoute={routes.vtsIntro}/>
            <Typography variant='h2'>Encryption</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    As of October 2018, all calls must be made using a secure hypertext encryption (HTTPS).
                </p>
                <p>
                    Ordnance Survey APIs no longer support calls made using SSL v2/v3 or TLS v1.0/v1.1.
                    Thus, all calls made to any of our APIs must support TLS v1.2 as of October 2018 in line with
                    current cyber security recommendations.
                </p>
            </Typography>
            <Typography variant='h2'>Transformation</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Customers should use a transformation utilising the OSTN15/OSGM15 transformation to transform 
                    data between EPSG:27700 and EPSG:3857 when intending to use the data with this service.
                </p>
                <p>
                    Data transformed using the old OSTN02/OSGM02 transformation will result in a small but noticeable 
                    shift in the data when overlaid.
                </p>
                <p>
                    This is only applicable if you are transforming data held in either EPSG:3857 or EPSG:27700 to be 
                    shown on a basemap of the opposite projection.
                </p>
            </Typography>
            <ResponseCodes/>

            <Typography variant='h2'>Content</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Through OS Vector Tile API you can connect to different layers for different use cases, including a detailed basemap and several data overlays.
                </p>
                <p>
                    The map features don’t contain the complete list of attribution from the full data products – we have selected attribution that is useful for visualisation.
                    The inclusion of unique IDs where available allow you to cross reference with the full product e.g. using OS Features API.
                </p>
                <p>
                    <b>Basemap</b> (OS OpenData and Premium data) – This is a detailed OS basemap made from <ExternalLink
                        type="generic"
                        href='https://www.ordnancesurvey.co.uk/products/os-open-zoomstack'
                        message="OS Open Zoomstack"
                    /> and <ExternalLink
                        type="generic"
                        href='https://www.ordnancesurvey.co.uk/products/os-mastermap-topography-layer'
                        message="OS MasterMap Topography Layer"
                    />.
                </p>
                <p>
                    The OS MasterMap Topography Layer building features have a property called <InlineCodeSnippet>RelHMax</InlineCodeSnippet> (height from the ground to the top of the building) which you can use to extrude and create 3D buildings.
                </p>
                <TextBox
                    text="https://api.os.uk/maps/vector/v1/vts"
                    label="OS Vector Tile API - Endpoint"
                    variant="code"
                />
            </Typography>
            <Typography variant='h3'>Data Overlays</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The following layers can be used as overlays to the basemap to add additional information.
                    To access them, add the layer name to the endpoint as described below e.g. <InlineCodeSnippet>https://api.os.uk/maps/vector/v1/vts/{'{'}layer-name{'}'}</InlineCodeSnippet>
                </p>
                <p>
                    <b>Boundaries</b> (OS OpenData) - This is a polygon layer made from <ExternalLink
                        type="generic"
                        href='https://www.ordnancesurvey.co.uk/products/boundary-line'
                        message="Boundary-Line"
                    /> which can be used for identifying boundaries (e.g. Counties, Districts and Parishes) and creating choropleth map overlays.
                </p>
                <TextBox
                    text="https://api.os.uk/maps/vector/v1/vts/boundaries"
                    label="OS Vector Tile API - 'Boundaries' Overlay Endpoint"
                    variant="code"
                />
                <p>
                    <b>Greenspace</b> (Premium data) - This is a polygon layer made from&nbsp;
                    <ExternalLink
                        type="generic"
                        href='https://www.ordnancesurvey.co.uk/products/os-mastermap-greenspace-layer'
                        message="OS MasterMap Greenspace Layer"
                    /> - Britain’s most comprehensive dataset of urban greenspaces.
                </p>
                <TextBox
                    text="https://api.os.uk/maps/vector/v1/vts/greenspace"
                    label="OS Vector Tile API - 'Greenspace' Overlay Endpoint"
                    variant="code"
                />
                <p>
                    <b>Sites</b> (Premium data) - This is a polygon layer made from&nbsp;
                    <ExternalLink
                        type="generic"
                        href='https://www.ordnancesurvey.co.uk/products/os-mastermap-sites-layer'
                        message="OS MasterMap Sites Layer"
                    />. Contains the site extents for key sites such as schools, hospitals and train stations and their main access points.
                </p>
                <TextBox
                    text="https://api.os.uk/maps/vector/v1/vts/sites"
                    label="OS Vector Tile API - 'Sites' Overlay Endpoint"
                    variant="code"
                />
                <p>
                    <b>Water</b> (Premium data) - This is a line layer made from&nbsp;
                    <ExternalLink
                        type="generic"
                        href='https://www.ordnancesurvey.co.uk/products/os-mastermap-networks-water-layer'
                        message="OS MasterMap Water Network Layer"
                    />. Contains detailed centre lines following the curve of the waterways precisely, including rivers, streams and canals.
                </p>
                <TextBox
                    text="https://api.os.uk/maps/vector/v1/vts/water"
                    label="OS Vector Tile API - 'Water' Overlay Endpoint"
                    variant="code"
                />
                <p>
                    <b>Highways</b> (Premium data) - This is a line layer made from&nbsp;
                    <ExternalLink
                        type="generic"
                        href='https://www.ordnancesurvey.co.uk/products/os-mastermap-highways-network-roads'
                        message="OS MasterMap Highways Network"
                    />. Contains detailed centre lines for roads, paths and ferry routes and includes the links that connect them.
                </p>
                <TextBox
                    text="https://api.os.uk/maps/vector/v1/vts/highways"
                    label="OS Vector Tile API - 'Highways' Overlay Endpoint"
                    variant="code"
                />
                <p>
                    <b>Paths</b> (Premium data) - This is a line layer made from&nbsp;
                    <ExternalLink
                        type="generic"
                        href='https://www.ordnancesurvey.co.uk/products/os-detailed-path-network'
                        message="OS Detailed Path Network"
                    /> - a connected path network covering Britain’s National Parks.
                </p>
                <TextBox
                    text="https://api.os.uk/maps/vector/v1/vts/paths"
                    label="OS Vector Tile API - 'Paths' Overlay Endpoint"
                    variant="code"
                />
            </Typography>

            <Typography variant='h2'>Operation</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    OS Vector Tile API has three main RESTful endpoints. These can be built into your web application 
                    or explored individually using a web-browser.
                </p>
            </Typography>

            <Typography variant='h3'>Service Metadata</Typography>
            <Typography variant='body1' paragraph={true} component={'div'}>
                <p>This service is available as a <InlineCodeSnippet>GET</InlineCodeSnippet> resource method allowing you to discover the service metadata.</p>
                <p>When generating your key using the OS Data Hub, a URL will be automatically generated using your new
                    API key. For demonstration purposes we have provided a stripped-down URL below:</p>
                <TextBox
                    text="https://api.os.uk/maps/vector/v1/vts?srs=27700&key="
                    label="OS Vector Tile API - Service Metadata Endpoint"
                    variant="code"
                />
                <VTSParametersTable classes={props.classes}/>
            </Typography>

            <Typography variant='h3'>Styling</Typography>
            <Typography variant='body1' paragraph={true} component={'div'}>
                <p>
                    This service is available as a <InlineCodeSnippet>GET</InlineCodeSnippet> resource method allowing you to discover the compatible style
                    sheets for the service. It is also possible, when creating a service, to create your own
                    styling. To help you get started, you can find some alternative stylesheets in the&nbsp;
                    <ExternalLink type="generic" href='https://github.com/OrdnanceSurvey/OS-Vector-Tile-API-Stylesheets' message="OS-Vector-Tile-API-Stylesheets GitHub repository" />.
                </p>
                <p>When generating your key using the OS Data Hub, a URL will be automatically generated using your new
                    API key. For demonstration purposes we have provided a stripped-down URL below:</p>

                    <TextBox
                        text="https://api.os.uk/maps/vector/v1/vts/resources/styles?srs=27700&key="
                        label="OS Vector Tile API - Styling Endpoint"
                        variant="code"
                    />
                <VTSParametersTable classes={props.classes}/>
            </Typography>

            <Typography variant='h3'>Tile Request</Typography>
            <Typography variant='body1' paragraph={true} component={'div'}>
                <p>This service is available as a <InlineCodeSnippet>GET</InlineCodeSnippet> resource method allowing you to access and download the data for a
                    specific tile. If done using a web-browser you will be required to input the <InlineCodeSnippet>z</InlineCodeSnippet>, <InlineCodeSnippet>y</InlineCodeSnippet>, and <InlineCodeSnippet>x</InlineCodeSnippet> coordinates
                    manually.</p>
                <p>The request will return the specified tile as a <InlineCodeSnippet>.pbf</InlineCodeSnippet> (Protocolbuffer Binary Format).</p>
                <p>When generating your key using the OS Data Hub, a URL will be automatically generated using your new
                    API key. For demonstration purposes we have provided a stripped-down URL below:</p>

                    <TextBox
                        text="https://api.os.uk/maps/vector/v1/vts/tile/{z}/{y}/{x}.pbf?srs=27700&key="
                        label="OS Vector Tile API - Tile Request Endpoint"
                        variant="code"
                    />
                <VTSParametersTable classes={props.classes}/>
            </Typography>
            <DocLinks product='vts' hasGettingStarted={true} hasDemo={true}/>
        </Content>
    </Document>
}

export default withStyles(styles)(VTSDetail);
