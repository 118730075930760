import {createActionReducer, createActionMiddleware} from 'omse-components';
import {SETUP_PAYMENT_DETAILS_ACTION} from "./actions";
import paymentActionCallback from "./paymentActionCallback";

export const reducer = createActionReducer(SETUP_PAYMENT_DETAILS_ACTION);

function setupCall(action) {
    // Remove any empty fields from the address that we were passed
    const addressToUse = {};
    Object.keys(action.billingAddress).forEach(k => {
        if(action.billingAddress[k]) {
            addressToUse[k] = action.billingAddress[k];
        }
    });

    return {
        url: '/api/payments/setup',
        method: 'POST',
        body: {
            session: action.session,
            cardHolderName: action.cardHolderName,
            billingAddress: addressToUse,
            phone: action.phone
        }
    };
}

export const middleware = createActionMiddleware(SETUP_PAYMENT_DETAILS_ACTION, setupCall, paymentActionCallback);
