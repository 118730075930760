import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages} from "react-intl";
import ItemTag from "./tag/ItemTag";
import {
    USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN,
    USER_OPEN_DATA_PLAN,
    USER_OS_INTERNAL_PLAN,
    USER_PREMIUM_DATA_PLAN,
    USER_PSGA_PLAN
} from "../../shared/plans";

const USER_PLANS = [USER_OPEN_DATA_PLAN, USER_PREMIUM_DATA_PLAN, USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN, USER_PSGA_PLAN,USER_OS_INTERNAL_PLAN]

const USER_PLANS_MAP = {
    [USER_OPEN_DATA_PLAN]: 'open',
    [USER_PREMIUM_DATA_PLAN]: 'premium',
    [USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN]: 'eai',
    [USER_PSGA_PLAN]: 'psga',
    [USER_OS_INTERNAL_PLAN]: 'internal'
}

export const messages = defineMessages({
    open: {
        id: 'PlanTag.open',
        defaultMessage: 'Free OS OpenData Plan',
        description: 'OpenData tag'
    },
    premium: {
        id: 'PlanTag.premium',
        defaultMessage: 'Premium Data Plan',
        description: 'Solution Provider tag'
    },
    psga: {
        id: 'PlanTag.psga',
        defaultMessage: 'Public Sector Plan',
        description: 'Public Sector tag'
    },
    internal: {
        id: 'PlanTag.internal',
        defaultMessage: 'OS Internal Plan',
        description: 'OS Internal Plan tag'
    },
    eai: {
        id: 'PlanTag.eai',
        defaultMessage: 'Energy And Infrastructure Plan',
        description: 'Energy And Infrastructure Plan tag'
    }
});

const colours = {
    open: 'success',
    premium: 'berry',
    psga: 'berry',
    internal: 'berry',
    eai: 'berry'
};

export default function PlanTag(props) {
    const {type, size, customLabel, classes} = props;
    const planType = USER_PLANS_MAP[type];
    const colour = colours[planType];
    let label = messages[planType];
    if(customLabel) {
        label = customLabel;
    }

    return <ItemTag colour={colour} label={label} size={size} classes={classes}/>;
}

PlanTag.propTypes = {
    size: PropTypes.oneOf(['sm', 'md']),
    type: PropTypes.oneOf(USER_PLANS).isRequired,
    customLabel: PropTypes.object,
    classes: PropTypes.object  // Allows the ItemTag styles to be overridden
};
