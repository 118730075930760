import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {createUseStyles} from 'react-jss';
import {TOKEN, LITERAL, COMBINER} from "./expressionParser";
import {Typography} from '@mui/material';
import {osColour} from 'omse-components';
import classNames from 'classnames';

const useStyles = createUseStyles(theme => ({
    group: {
        marginLeft: theme.spacing(3)
    },
    string: {
        color: osColour.status.success
    },
    token: {
        paddingRight: theme.spacing(1)
    }
}));

let prefixGenerator = 1;

export default function TreeBasedQueryViewer({item, topLevel}) {
    const [prefix] = useState(prefixGenerator + 1);
    const styles = useStyles();

    if(item.children) {
        if(item.children.length > 1) {
            let groupPrefix = null;
            let groupSuffix = null;
            if(!topLevel) {
                groupPrefix = <TreeItem item={{ type: TOKEN, value: '(' }}/>;
                groupSuffix = <TreeItem item={{ type: TOKEN, value: ')' }}/>;
            }

            return <div className={styles.group}>
                { groupPrefix }
                { item.children.map((item, index) => <TreeBasedQueryViewer key={prefix + '-' + index} item={item}/>) }
                { groupSuffix }
            </div>;
        } else if(item.children.length === 1){
            return <TreeBasedQueryViewer item={item.children[0]} topLevel={topLevel}/>;
        }
        return null;
    }

    // Not a group, so just render a tree item for this node
    return <TreeItem item={item}/>;
}

function TreeItem({item}) {
    const styles = useStyles();

    let variant = 'body1';
    let component = 'span';
    let color = 'initial';
    let classArray = [styles.token];

    if(item.type === LITERAL) {
        variant = 'body2';
        classArray.push(styles.string);
    } else if(item.type === COMBINER) {
        variant = 'body2';
        component = 'p';
        color = 'primary';
    }

    return <Typography variant={variant} component={component} color={color} className={classNames(classArray)}>
        {item.value}
    </Typography>;
}

TreeBasedQueryViewer.propTypes = {
    item: PropTypes.object.isRequired,
    topLevel: PropTypes.bool
};

TreeBasedQueryViewer.defaultProps = {
    topLevel: false
};
