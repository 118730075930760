import React, { Suspense, lazy } from 'react';
import {connect} from 'react-redux';
import Header from "../Header";
import Content from '../Content';
import Typography from '@mui/material/Typography';
import "swagger-ui-react/swagger-ui.css"
import routes from "../../../util/routes";
import Link from '../../../components/Link';
import DocLinks from "../DocLinks";
import Document from '../Document';

const SwaggerUI = lazy(() => import("swagger-ui-react"));

export function DownloadsDetail(props) {
    const {url} = props;
    return <Document>
        <Header back={true}>OS Downloads API: Technical specification</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains the methods that you can use to call the OS Downloads API.
                    Since the content of the API may change as products are changed, added to or removed, you should use the methods described below to get the most current list of content available.
                    If you are not familiar with the OS Downloads API you may want to read the <Link path={routes.downloadsIntro}>getting started guide</Link> before proceeding.
                </p>
                <p>
                    OS Downloads API allows you to access information about available OpenData products and obtain product metadata as well as download links.
                    The API also allows you to access information about your data packages, and to download any available data package versions.
                    The API can be used to automate the retrieval and integration of OS data with your own systems.
                </p>
                <p>
                    To use the OpenData parts of this API (either in your own implementation or using the “try it out” functionality in this document) you do not require an API Key.
                    You do need to use an API Key (or OAuth 2 access token) when accessing the data package endpoints.
                </p>
            </Typography>
            {url &&
                <Suspense fallback={<Typography variant='body1' paragraph={true}>
                    Loading API explorer...
                </Typography>}>
                    <SwaggerUI url={url}
                               docExpansion='list'
                               deepLinking={true}
                               requestInterceptor={request => {
                                   // By default the fetch() API will attempt to follow redirects. For our redirections
                                   // to the blob stores, that is bad news, as:
                                   // - Large responses break the swagger-ui code, and
                                   // - Premium data blob stores are not CORS enabled
                                   // We disable redirects here, to avoid those issues.
                                   //
                                   // We also add the '-v' flag into the curl example, so that if they copy-paste the
                                   // example and give it a try then they will see details of the Location and status code.
                                   return {
                                       ...request,
                                       redirect: 'manual',
                                       curlOptions: [ '-v' ]
                                   };
                               }}
                               responseInterceptor={response => {
                                   if(response.status === 0) {
                                       // The fetch() API returns an opaque response for HTTP redirects, so there isn't
                                       // much that we can do with the result - it looks like a failure and does not
                                       // have any useful data in it. We replace it with a fake response so that something
                                       // helpful is shown in the swagger-ui pane.
                                       const body = {
                                           message: 'The response body and Location header are not available in this view. To see the actual results of the API call copy the above curl command line and run it in a terminal.'
                                       };
                                       const text = JSON.stringify(body);
                                       const data = text;
                                       response = {
                                           ok: true,
                                           status: 307,
                                           statusText: 'Temporary Redirect',
                                           headers: {
                                               Location: 'Unavailable',
                                               'Content-Type': 'application/json'
                                           },
                                           body,
                                           data,
                                           text
                                       };
                                   }
                                   return response;
                               }}
                    />
                </Suspense>
            }
            <DocLinks product='downloads' hasGettingStarted={true} hasTechnicalSpec={true}/>
        </Content>
    </Document>
}

function mapStateToProps(state) {
    const {result} = state.config.current;

    if(result) {
        return {
            url: result.downloadsUrl + '/openapi.yaml'
        };
    }
    return {};
}

export default connect(mapStateToProps)(DownloadsDetail);
