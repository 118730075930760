import React from 'react';
import PropTypes from 'prop-types';
import { CommonDialog } from 'omse-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Typography } from "@mui/material";

const messages = defineMessages({
    optionNotCompatible: {
        id: 'OptionNotAvailableDialog.optionNotCompatible',
        defaultMessage: 'This option is not compatible with your current option choices',
        description: 'option not compatible'
    },
    optionNotAvailable: {
        id: 'OptionNotAvailableDialog.optionNotAvailable',
        defaultMessage: 'Option not available',
        description: 'option not available'
    }
});

export default function OptionNotAvailableDialog(props) {
    const {onClose} = props;
    return <CommonDialog onClose={onClose} title={messages.optionNotAvailable}>
        <Typography variant='body1'>
            <FormattedMessage {...messages.optionNotCompatible}/>
        </Typography>
    </CommonDialog>;
}

OptionNotAvailableDialog.propTypes = {
    onClose: PropTypes.func.isRequired
};
