import React, {Component} from 'react';
import {connect} from 'react-redux';
import Link from '../../components/Link';
import Header from './Header';
import Content from './Content';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import withStyles from 'react-jss';
import { ExpansionList, Notification, ExternalLink } from 'omse-components';
import {
    dataHubExamplesUrl,
    gitHubAddress,
    routes,
    osDataHubExplorerUrl,
    PyWrapperUrl,
    JsWrapperUrl,
    osMedium,
    osToolsSupportUrl,
    osNgdGitBook
} from '../../util/routes';
import {defineMessages, injectIntl} from 'react-intl';
import {hasUseOSPlacesPermission} from '../../util/permissions';
import featureCheck from '../../util/featureCheck';
import features from '../../../shared/features';
import { showMatchDocs } from '../appContent/menu-util';

const messages = defineMessages({
    expandAll: {
        id: 'Authentication.expandAll',
        defaultMessage: 'Expand All',
        description: 'Label for the Expand All link'
    },
    closeAll: {
        id: 'Authentication.closeAll',
        defaultMessage: 'Close all',
        description: 'Label for the Close all link'
    },
    unknownProduct: {
        id: 'DocumentationIndex.unknownProduct',
        defaultMessage: 'Unknown product',
        description: 'Label for unknownProduct route'
    },
    ofa: {
        id: 'DocumentationIndex.ofa',
        defaultMessage: 'OS NGD API – Features',
        description: 'Label for ofa route'
    },
    ota: {
        id: 'DocumentationIndex.ota',
        defaultMessage: 'OS NGD API – Tiles',
        description: 'Label for ota route'
    },
    wfs: {
        id: 'DocumentationIndex.wfs',
        defaultMessage: 'OS Features API',
        description: 'Label for wfs route'
    },
    linkedIdentifiers: {
        id: 'DocumentationIndex.linkedIdentifiers',
        defaultMessage: 'OS Linked Identifiers API',
        description: 'Label for linkedIdentifiers route'
    },
    wmts: {
        id: 'DocumentationIndex.wmts',
        defaultMessage: 'OS Maps API',
        description: 'Label for wmts route'
    },
    names: {
        id: 'DocumentationIndex.names',
        defaultMessage: 'OS Names API',
        description: 'Label for names route'
    },
    places: {
        id: 'DocumentationIndex.places',
        defaultMessage: 'OS Places API',
        description: 'Label for places route'
    },
    match: {
        id: 'DocumentationIndex.match',
        defaultMessage: 'OS Match & Cleanse API',
        description: 'Label for match route'
    },
    vts: {
        id: 'DocumentationIndex.vts',
        defaultMessage: 'OS Vector Tile API',
        description: 'Label for vts route'
    },
    downloads: {
        id: 'DocumentationIndex.downloads',
        defaultMessage: 'OS Downloads API',
        description: 'Label for downloads route'
    },
    oauth: {
        id: 'DocumentationIndex.oauth',
        defaultMessage: 'OAuth 2 API',
        description: 'Label for oauth route'
    },
    unknownDocument: {
        id: 'DocumentationIndex.unknownDocument',
        defaultMessage: 'Unknown document',
        description: 'Label for unknown document'
    },
    Overview: {
        id: 'DocumentationIndex.Overview',
        defaultMessage: '{productName}: Overview',
        description: 'Label for Overview'
    },
    Intro: {
        id: 'DocumentationIndex.Intro',
        defaultMessage: '{productName}: Getting started guide',
        description: 'Label for Getting started guide'
    },
    Detail: {
        id: 'DocumentationIndex.Detail',
        defaultMessage: '{productName}: Technical specification',
        description: 'Label for Getting started guide'
    }
});

function style(theme) {
    return {
        notification: {
            marginBottom: theme.spacing(3),
            paddingRight: theme.spacing(1)
        }
    }
}

function generateHeadingLabel(product, intl) {
    const doc = product.docs[0];
    const route = doc.route;
    let label = intl.formatMessage(messages.unknownProduct);
    const keys = ['wfs', 'vts', 'linkedIdentifiers', 'wmts', 'names', 'places', 'match', 'downloads', 'oauth', 'ofa', 'ota'];
    const match = keys.find(key => route.name.indexOf(key) === 0);
    if (match) {
        label = intl.formatMessage(messages[match]);
    }
    return label;
}

function generateLabel(product, route, intl) {
    const productName = generateHeadingLabel(product, intl);
    let label = intl.formatMessage(messages.unknownDocument);
    const keys = ['Overview', 'Intro', 'Detail'];
    const match = keys.find(key => route.name.indexOf(key) !== -1);
    if (match) {
        label = intl.formatMessage(messages[match], {productName});
    }
    return label;
}

//The order of the return array determines the order that the dropdowns will be displayed on screen
const getApis = (userDetails, config) => {
    let apis = [];
    if(featureCheck(features.NGD_FEATURES, config)){
        apis = apis.concat(['ofa']);
    }
    if(featureCheck(features.NGD_TILES, config)){
        apis = apis.concat(['ota']);
    }
    apis = apis.concat(['downloads', 'wfs', 'linkedIdentifiers', 'wmts']);
    if(showMatchDocs(userDetails, config)) {
        apis = apis.concat(['match']);
    }
    apis = apis.concat(['names']);
    if(hasUseOSPlacesPermission(userDetails) || featureCheck(features.PREMIUM_OS_PLACES, config)) {
        apis = apis.concat(['places']);
    }
    apis = apis.concat(['vts', 'oauth']);
    return apis;
}
const docTypes = ['overview', 'gettingStarted', 'technicalSpec'];

export class Index extends Component {

    state = {products: []}

    componentDidMount() {
        this.initProducts();
    }

    initProducts() {
        const {active, userDetails, config} = this.props;
        const products = [];

        routes.filter(route => route.path.indexOf('/docs/') === 0).forEach(route => {
            getApis(userDetails, config).forEach(api => {

                let product = products.find(p => p.api === api) || {api, docs: []};

                // check api matches route and push doc.type entries
                if (route.name.indexOf(api) !== -1) {
                    docTypes.forEach(docType => {
                        if (route.path.indexOf(docType) !== -1 && ((!active && route.public) || active)) {
                            product.docs.push({docType, route});
                        }
                    });
                }
                if (!products.find(p => p.api === api)) {
                    products.push(product);
                }
            });
        });
        this.setState({products});
    }

    render() {
        const {active, loggedIn, classes, intl} = this.props;
        const { products } = this.state;
        const options = products.filter(item => item.docs.length > 0).map(product => {
            const label = generateHeadingLabel(product, intl);
            const content = (
                <>
                    <ul>
                        {product.docs.map(doc => {
                            return (
                                <li key={doc.route.path}>
                                    <Link path={doc.route.path}>
                                        {generateLabel(product, doc.route, intl)}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                    {(label === 'OS NGD API – Features' || label === 'OS NGD API – Tiles') && (
                        <Typography variant="body1" paragraph={true}>
                            Our NGD API documentation can now be found on our <ExternalLink
                                type='generic'
                                href='https://docs.os.uk/osngd/'
                                message='OS NGD Documentation Platform'
                            />.
                        </Typography>
                    )}
                </>
            );
            return { label, content };
        });

        return <>
            <Header>Documentation</Header>
            <Content>

                {loggedIn && !active &&
                    <Notification variant='error' appearance='inline' classes={{ root: classes.notification }}>
                        <Typography variant='body1'>
                            As your account is suspended you can only see a subset of the documentation.
                            If you think that this is incorrect, please <ExternalLink type='support' />.
                        </Typography>
                    </Notification>
                }

                <>
                    <Typography variant='h2'>
                        OpenData and Premium Data Downloads
                    </Typography>
                    <Typography variant='body1' paragraph={true}>
                        Both our OpenData Downloads and Premium Data Downloads are provided with <ExternalLink
                            type='generic'
                            href={osToolsSupportUrl}
                            message='support documentation'
                        /> such as getting started guides, technical specifications, and case studies.
                    </Typography>
                </><>
                    <Typography variant='h2'>
                        OS Select+Build
                    </Typography>
                    <Typography variant='body1' paragraph={true}>
                        Get tailored views by building your own recipes and data packages, with data sourced directly from the OS National Geographic Database (OS NGD). There is a wide range of learning resources on our external <ExternalLink
                            type='generic'
                            href={osNgdGitBook}
                            message='OS NGD Documentation Platform'
                        />.
                    </Typography>
                </><>
                    <Typography id="osApiProducts" variant='h2'>
                        OS APIs
                    </Typography>
                    <Typography variant='body1'>
                        The following links contain documentation for the API products that are available on the OS Data Hub:
                    </Typography>
                    <ExpansionList options={options} viewAllLabel={messages.expandAll} closeAllLabel={messages.clearAll} />
                </><>
                    <Typography variant="h2">
                        The OS Data Hub Explorer
                    </Typography>
                    <Typography variant="body1" paragraph={true}>
                        Use the <ExternalLink
                            type='generic'
                            href={osDataHubExplorerUrl}
                            message='OS Data Hub Explorer'
                        /> to view a sample of some of the assorted products available on the OS Data Hub, get a better understanding of their schema, and see what is possible with our specialised datasets.
                    </Typography>
                </><>
                    <Typography variant="h2">
                        Developer API Wrappers
                    </Typography>
                    <Typography variant="body1" paragraph={false}>
                        Our API wrappers for Python and JavaScript developers make it easier to interact with and use our APIs:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    Py
                                </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                                primary={<ExternalLink
                                    type='generic'
                                    href={PyWrapperUrl}
                                    message='API Wrapper for Python'
                                />}
                                secondary='Distributed via PyPi.'
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    JS
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<ExternalLink
                                    type='generic'
                                    href={JsWrapperUrl}
                                    message='API Wrapper for JavaScript'
                                />}
                                secondary='Distributed via NPM. Supports TypeScript.'
                            />
                        </ListItem>
                    </List>
                </><>
                    <Typography variant='h2'>
                        Code Examples
                    </Typography>
                    <Typography variant='body1' paragraph={true}>
                        Our copy-and-paste <ExternalLink
                            type='generic'
                            href={dataHubExamplesUrl}
                            message='API Code Examples'
                        /> are a great way to get started with OS data. They showcase the functionality within each API and provide the building blocks for use in your own applications.
                        <br />
                        Available as code examples for various mapping libraries, you can view a live demo of each example project before trying it out for yourself. Our examples are written in HTML, CSS, and JavaScript.
                    </Typography>
                    <Typography variant='body1' paragraph={true}>
                        We also maintain our <ExternalLink
                            type='generic'
                            href={gitHubAddress}
                            message='API Demos'
                        /> repository on GitHub which consists of code samples that demonstrate the OS Maps API, OS Vector Tile API, OS Linked Identifiers API, and the OS Features API.
                        <br />
                        Each product has its own demo; there is also one which combines the functionality of several APIs together to show the location of airports, demonstrating advanced use cases.
                    </Typography>
                </><>
                    <Typography variant="h2">
                        OS Developer Medium
                    </Typography>
                    <Typography variant="body1" paragraph={true}>
                        Visit our <ExternalLink
                            type='generic'
                            href={osMedium}
                            message='OS Developer Medium Account'
                        /> to read the latest industry insights, as well as tutorials and examples from the OS Developer community.
                    </Typography>
                </>
            </Content>
        </>;
    }
}

function mapStateToProps(state) {
    const {result} = state.user.current;
    const currentConfig = state.config.current;
    return {
        userDetails: result,
        loggedIn: result,
        active: result && result.active,
        config: currentConfig && currentConfig.result
    }
}

const styled = withStyles(style)(injectIntl(Index));
export default connect(mapStateToProps)(styled);
