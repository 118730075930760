import React from 'react';
import {createUseStyles} from 'react-jss';
import Typography from '@mui/material/Typography';
import {defineMessages, FormattedMessage} from 'react-intl';
import routes, {generalTermsAddress, cookiesAddress, privacyPolicyAddress} from '../../util/routes';
import { InternalLink, ExternalLink } from 'omse-components';
import Header from '../documentation/Header';
import Document from '../documentation/Document';
import Content from "../documentation/Content";

const messages = defineMessages({
    title: {
        id: 'GeneralTerms.title',
        defaultMessage: 'Overview',
        description: 'Title'
    },
    intro: {
        id: 'GeneralTerms.intro',
        defaultMessage: "Here's where you'll find the legal terms relating to your use of this website, our Privacy Policy and our Cookies Policy.",
        description: 'Intro'
    },
    dataHubIntro: {
        id: 'GeneralTerms.dataHubIntro',
        defaultMessage: 'We have included links below to the terms and conditions that apply to your use and access of the OS Data Hub website and its available services. Please click on these links if you wish to read these examples before entering into them. To enter into these terms and conditions, you must click to accept the relevant terms presented to you when you register for the OS Data Hub or when you select the services you want. We will also notify you of changes to any of these terms and conditions in accordance with their respective terms.',
        description: 'Intro for terms in OS Data Hub group'
    },
    additionalLicenceIntro: {
        id: 'GeneralTerms.additionalLicenceIntro',
        defaultMessage: 'To access the Download Services and OS Places in the OS Data Hub you will need to be signed up to additional licences. Information on these can be found in the links below:',
        description: 'Intro for terms in the additional licence group'
    },

    // OS website
    generalTerms: {
        id: 'GeneralTerms.generalTerms',
        defaultMessage: 'OS Website Terms',
        description: 'OS Website Terms'
    },
    privacyPolicy: {
        id: 'GeneralTerms.privacyPolicy',
        defaultMessage: 'Privacy Policy',
        description: 'Privacy policy'
    },
    cookiePolicy: {
        id: 'GeneralTerms.cookiePolicy',
        defaultMessage: 'Cookies Policy',
        description: 'Cookie policy'
    },

    // Overviews
    apiTermsOverview: {
        id: 'GeneralTerms.apiTermsOverview',
        defaultMessage: '{link} apply if you are using the OS APIs available from the OS Data Hub.',
        description: 'API Terms overview'
    },
    dataHubTermsOverview: {
        id: 'GeneralTerms.dataHubTermsOverview',
        defaultMessage: '{link} apply if you are using the OS Data Hub.',
        description: 'OS Data Hub Terms overview'
    },
    downloadTermsOverview: {
        id: 'GeneralTerms.downloadTermsOverview',
        defaultMessage: '{link} apply if you are using the Premium Data Manual Download Service available from the OS Data Hub.',
        description: 'Download Terms overview'
    },    
    errorReportingTermsOverview: {
        id: 'GeneralTerms.errorReportingTermsOverview',
        defaultMessage: '{link} apply if you are using the Errors and Omissions Tool available from the OS Data Hub.',
        description: 'Error Reporting Terms overview'
    },
    vernacularNamesTermsOverview: {
        id: 'GeneralTerms.vernacularNamesTermsOverview',
        defaultMessage: '{link} apply if you are using the Vernacular Names Tool available from the OS Data Hub.',
        description: 'Vernacular Names Terms overview'
    },
    frameworkContractTermsOverview: {
        id: 'GeneralTerms.frameworkContractTermsOverview',
        defaultMessage: '{link} apply to commercial relationships between Partners and Ordnance Survey.',
        description: 'Framework Contract Terms overview'
    },
    partnerContractsTermsOverview: {
        id: 'GeneralTerms.partnerContractsTermsOverview',
        defaultMessage: '{link} apply to OS Partners.',
        description: 'Partner Contracts Terms overview'
    },
    trialModeTermsOverview: {
        id: 'GeneralTerms.trialModeTermsOverview',
        defaultMessage: '{link} apply if you are trialling OS Places API and/or OS Match & Cleanse API available from the OS Data Hub.',
        description: 'Trial Mode Terms overview'
    },
    slaTermsOverview: {
        id: 'GeneralTerms.slaTermsOverview',
        defaultMessage: '{link} sets out the service levels we provide for the OS Data Hub.',
        description: 'SLA Terms overview'
    },

    // Terms labels
    apiTerms: {
        id: 'GeneralTerms.apiTerms',
        defaultMessage: 'OS API Service Terms',
        description: 'API Terms'
    },
    dataHubTerms: {
        id: 'GeneralTerms.dataHubTerms',
        defaultMessage: 'OS Data Hub Terms',
        description: 'DataHub Terms'
    },
    downloadTerms: {
        id: 'GeneralTerms.downloadTerms',
        defaultMessage: 'OS Download Terms',
        description: 'Download Terms'
    },
    errorReportingTerms: {
        id: 'GeneralTerms.errorReportingTerms',
        defaultMessage: 'Error Reporting Terms',
        description: 'Error Reporting Terms'
    },
    vernacularNamesTerms: {
        id: 'GeneralTerms.vernacularNamesTerms',
        defaultMessage: 'Vernacular Name Terms',
        description: 'Vernacular Names Terms'
    },
    frameworkContractTerms: {
        id: 'GeneralTerms.frameworkContractTerms',
        defaultMessage: 'Framework Contract (Partners) Terms',
        description: 'Framework Contract Terms'
    },
    partnerContractsTerms: {
        id: 'GeneralTerms.partnerContractsTerms',
        defaultMessage: 'Partner Contracts Terms',
        description: 'Partner Contracts Terms'
    },
    trialModeTerms: {
        id: 'GeneralTerms.trialModeTerms',
        defaultMessage: 'Trial Mode Terms',
        description: 'Trial Mode Terms'
    },
    slaTerms: {
        id: 'GeneralTerms.slaTerms',
        defaultMessage: 'OS Data Hub Service Level Agreement',
        description: 'SLA Terms'
    }
});

const useStyles = createUseStyles(theme => ({
    overviews: {
        '& ul': {
            marginBottom: theme.spacing(3)
        },
        '& li': {
            marginBottom: theme.spacing(1)
        },
        '& p:first-of-type': {
            marginTop: 0
        }
    }
}));

const externalLinks = [
    {href: generalTermsAddress, message: messages.generalTerms},
    {href: privacyPolicyAddress, message: messages.privacyPolicy},
    {href: cookiesAddress, message: messages.cookiePolicy}
];

const dataHubTerms = [
    {message: messages.apiTermsOverview, link: {path: routes.apiTermsConditions, message: messages.apiTerms}},
    {message: messages.dataHubTermsOverview, link: {path: routes.termsConditions, message: messages.dataHubTerms}},
    {message: messages.downloadTermsOverview, link: {path: routes.downloadTerms, message: messages.downloadTerms}},
    {message: messages.errorReportingTermsOverview, link: {path: routes.errorReportingTerms, message: messages.errorReportingTerms}},
    {message: messages.vernacularNamesTermsOverview, link: {path: routes.vernacularNamesTerms, message: messages.vernacularNamesTerms}},
    {message: messages.trialModeTermsOverview, link: {path: routes.trialModeTerms, message: messages.trialModeTerms}},
    {message: messages.slaTermsOverview, link: {path: routes.sla, message: messages.slaTerms}}
];

const additionalLicenceTerms = [
    {message: messages.frameworkContractTermsOverview, link: {path: routes.frameworkContractTerms, message: messages.frameworkContractTerms}},
    {message: messages.partnerContractsTermsOverview, link: {path: routes.partnerContracts, message: messages.partnerContractsTerms}}
];

export const TermsOverview = ({item}) => {
    return (
        <Typography variant='body1' component='li'>
            <FormattedMessage {...item.message} values={{
                link: (
                    <InternalLink
                        path={item.link.path}
                        message={item.link.message}
                    />
                )
            }} />
        </Typography>
    );
}

export default function LegalOverview() {
    const classes = useStyles();
    return <Document>
        <Header back={true}>
            <FormattedMessage {...messages.title} />
        </Header>
        <Content>
            <Typography variant='body1' component='div' className={classes.overviews}>
                <p>
                    <FormattedMessage {...messages.intro}/>
                </p>
                <ul>
                    {externalLinks.map((item) => (
                        <li key={item.href}>
                            <ExternalLink type='generic' href={item.href} message={item.message} />
                        </li>
                    ))}
                </ul>

                <p>
                    <FormattedMessage {...messages.dataHubIntro}/>
                </p>
                <ul>
                    {dataHubTerms.map((item) => (
                        <TermsOverview item={item} key={item.link.path} />)
                    )}
                </ul>

                <p>
                    <FormattedMessage {...messages.additionalLicenceIntro}/>
                </p>
                <ul>
                    {additionalLicenceTerms.map((item) => (
                        <TermsOverview item={item} key={item.link.path} />
                    ))}
                </ul>
            </Typography>
        </Content>
    </Document>;
}
