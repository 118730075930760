import React, {useEffect, useState, useRef, Fragment} from 'react';
import {useSelector} from 'react-redux';
import {defineMessages, FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import {createUseStyles} from 'react-jss';
import routePaths, {getLocation} from "../../util/routes";
import {useHistory} from 'react-router';
import {USER_PREMIUM_DATA_PLAN} from "../../../shared/plans";
import PaymentDetailsForm from '../account/PaymentDetailsForm';
import Link from '../../components/Link';
import {AddButton, contentPadding, osColour} from 'omse-components';

const messages = defineMessages({
    title: {
        id: 'PaymentSetup.title',
        defaultMessage: 'Enter payment card details',
        description: 'Title for the payment setup'
    },
    description: {
        id: 'PaymentSetup.description',
        defaultMessage: 'Set up your payment details for a guaranteed uninterrupted service. You\'ll always get your first £1,000 of premium data free each month.',
        description: 'Title for the payment setup'
    },
    skip: {
        id: 'PaymentSetup.skip',
        defaultMessage: 'Skip, I\'ll enter my payment details later',
        description: 'Skip setup link label'
    },
    submit: {
        id: 'PaymentSetup.submit',
        defaultMessage: 'Submit Payment Details',
        description: 'Label for the submit payment details button'
    },
    failed: {
        id: 'PaymentSetup.failed',
        defaultMessage: 'Error encountered, failed to complete payment set up.',
        description: 'Label for failure'
    }
});

const styles = createUseStyles(theme => ({
    title: {
        marginTop: theme.spacing(8),
        textAlign: 'center',
        marginBottom: theme.spacing(4),
    },
    content: {
        flex: '1 0 auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: theme.spacing(8),
        '& p': {
            maxWidth: 370
        },
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile
        }
    },
    buttons: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    link: {
        fontSize: theme.typography.caption.fontSize,
        display: 'block' ,
        textAlign: 'right'
    },
    error: {
        color: osColour.status.error,
        marginBottom: theme.spacing(1.5)
    }
}));

export default function PaymentSetup() {
    const loading = useSelector(state => state.user.current.loading);
    const user = useSelector(state => state.user.current.result);
    const cardDetails = useSelector(state =>
        state.payments.status &&
        state.payments.status.result &&
        state.payments.status.result.cardDetails
    );
    const history = useHistory();
    const classes = styles();

    const [valid, setValid] = useState(false);
    const [working, setWorking] = useState(false);
    const [error, setError] = useState(null);
    const confirmRef = useRef();

    useEffect(() => {
        if(user) {
            if(user.plan !== USER_PREMIUM_DATA_PLAN || cardDetails) {
                // When & if the user completes payment setup they need to move on to the dashboard.
                // This could happen if an existing premium user types in the address, or if the payment setup completes
                // successfully.
                const newLocation = getLocation(routePaths.dashboard, history.location);
                history.replace(newLocation);
            }
        }
    }, [user, history, cardDetails]);

    if(loading) {
        return <div className={classes.root}>
            <CircularProgress size={32}/>;
        </div>
    }

    return <Fragment>
        <Typography variant='h1' color='primary' className={classes.title}>
            <FormattedMessage {...messages.title}/>
        </Typography>
        <div className={classes.content}>
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.description}/>
            </Typography>
            {error &&                
                <Typography variant='body1' className={classes.error}>
                    <FormattedMessage {...messages.failed} />                        
                </Typography>
            }
            <Link className={classes.link} path={routePaths.dashboard}>
                <FormattedMessage {...messages.skip}/>
            </Link>            
            <PaymentDetailsForm
                onValid={setValid}
                onWorking={setWorking}
                onError={setError}
                confirmRef={confirmRef}
            />
            <div className={classes.buttons}>
                <AddButton action={() => confirmRef.current()}
                        label={messages.submit}
                        working={(working && !error)? true : false}
                        disabled={(!valid || error)? true : false}
                        showIcon={false}
                />
            </div>
            <Link className={classes.link} path={routePaths.dashboard}>
                <FormattedMessage {...messages.skip}/>
            </Link>              
        </div>        
    </Fragment>;
}

