import React, {Fragment} from 'react';
import {
    CommonDialog,
    CommonDialogActions,
    Notification,
    ExternalLink
} from 'omse-components';
import {Typography} from '@mui/material';
import {defineMessages, FormattedMessage} from 'react-intl';
import {deleteDataPackage} from "../../../modules/dataPackages/actions";
import useActionIdSelector from '../../../hooks/useActionIdSelector';

const messages = defineMessages({
    deleteTitle: {
        id: 'DataPackage.deleteTitle',
        defaultMessage: 'Permanently delete data package',
        description: 'delete dialog title'
    },
    whenYouDelete: {
        id: 'DataPackage.whenYouDelete',
        defaultMessage: 'When you delete a data package:',
        description: 'dialog message'
    },
    thePackageWillBe: {
        id: 'DataPackage.thePackageWillBe',
        defaultMessage: 'The package will be permanently deleted from your account',
        description: 'dialog message'
    },
    itWillNotAppear: {
        id: 'DataPackage.itWillNotAppear',
        defaultMessage: 'It will not appear in your packages list\n',
        description: 'dialog message'
    },
    itCanNotBe: {
        id: 'DataPackage.itCanNotBe',
        defaultMessage: 'It can not be downloaded',
        description: 'dialog message'
    },
    deleteProceed: {
        id: 'DataPackage.deleteProceed',
        defaultMessage: 'Delete package',
        description: 'delete dialog proceed message'
    },
    deleteCancel: {
        id: 'DataPackage.deleteCancel',
        defaultMessage: 'Keep package',
        description: 'delete dialog cancel message'
    },
    deleteFailure: {
        id: 'DataPackage.deleteFailure',
        defaultMessage: 'There was a problem deleting your data package. Please retry and if the problem persists then {link}.',
        description: 'error message text'
    }
});


export default function DeleteDataPackageDialog({dataPackage, onClose, onSuccess = () => {}}) {
    const [{working, error}, dispatch] = useActionIdSelector("dataPackages.delete");

    const actions = <CommonDialogActions onClose={onClose}
                                         onConfirm={() => dispatch(deleteDataPackage(dataPackage.id))}
                                         confirmLabel={messages.deleteProceed}
                                         cancelLabel={messages.deleteCancel}
                                         working={working}/>;

    let content = <CommonDialog onClose={onClose}
                                title={messages.deleteTitle}
                                actions={actions}>
        <Typography variant='body1'>
            <FormattedMessage {...messages.whenYouDelete}/>
        </Typography>
        <ul>
            <li><Typography variant='body1'>
                <FormattedMessage {...messages.thePackageWillBe}/>
            </Typography></li>
            <li><Typography variant='body1'>
                <FormattedMessage {...messages.itWillNotAppear}/>
            </Typography></li>
            <li><Typography variant='body1'>
                <FormattedMessage {...messages.itCanNotBe}/>
            </Typography></li>
        </ul>
    </CommonDialog>;

    if (error) {
        content = <Notification variant='error' appearance='toast' onClose={onClose}>
            <Typography variant='body1'>
                <FormattedMessage {...messages.deleteFailure} values={{ link: <ExternalLink type='support' /> }} />
            </Typography>
        </Notification>
    }

    return <Fragment>
        {content}
    </Fragment>
}