const PREFIX = 'action:account:';
export const SET_EMAIL_ACTION = PREFIX + 'setEmail';
export const LOADED_EMAIL_ACTION = PREFIX + 'loadedEmail';
export const LOAD_CONTACT_PREFERENCES = PREFIX + 'loadContactPrefs';
export const LOADED_CONTACT_PREFERENCES = PREFIX + 'loadedContactPrefs';
export const SET_CONTACT_PREFERENCES = PREFIX + 'setContactPrefs';

export function setEmail(email) {
    return {
        type: SET_EMAIL_ACTION,
        email
    }
}

export function getContactPreferences() {
    return {
        type: LOAD_CONTACT_PREFERENCES
    }
}

export function setContactPreferences(preferences) {
    return {
        type: SET_CONTACT_PREFERENCES,
        preferences
    }
}

export function clearSetContactPreferences() {
    return {
        type: SET_CONTACT_PREFERENCES + ":clear"
    }
}

