import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';

export default function TextQueryViewer({filterExpression}) {
    return <Typography variant='body1'>
        {filterExpression}
    </Typography>;
}

TextQueryViewer.propTypes = {
    filterExpression: PropTypes.string.isRequired
};
