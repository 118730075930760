import React from 'react';
import PropTypes from 'prop-types';
import {CommonDialog, CommonDialogActions} from 'omse-components';
import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
    dialogTitle: {
        id: 'DiscardFiltersDialog.dialogTitle',
        defaultMessage: 'Discard filter changes',
        description: 'Title for the discard filters dialog'
    },
    dialogTitleSaved: {
        id: 'DiscardFiltersDialog.dialogTitleSaved',
        defaultMessage: 'Discard saved filters',
        description: 'Title for the discard filters dialog'
    },
    message: {
        id: 'DiscardFiltersDialog.message',
        defaultMessage: 'There are unapplied filters. Are you sure you want to close the panel and discard the changes?',
        description: 'Title for the discard filters dialog'
    },
    confirm: {
        id: 'DiscardFiltersDialog.confirm',
        defaultMessage: 'Discard Changes',
        description: 'Label for the discard changes button on the dialog'
    },
    messageSaved: {
        id: 'DiscardFiltersDialog.messageSaved',
        defaultMessage: 'You are removing feature types that have filters applied. Are you sure you want to remove the feature types and discard the filters?',
        description: 'Title for the discard saved filters dialog'
    },
    confirmSaved: {
        id: 'DiscardFiltersDialog.confirmSaved',
        defaultMessage: 'Discard Filters',
        description: 'Label for the discard filters button on the dialog'
    }
});

export default function DiscardFiltersDialog({onClose, onConfirm, saved}) {
    let confirm = messages.confirm;
    let title = messages.dialogTitle;
    let message = messages.message;
    if(saved) {
        confirm = messages.confirmSaved;
        title = messages.dialogTitleSaved;
        message = messages.messageSaved;
    }

    const actions = <CommonDialogActions onClose={onClose}
                                         confirmLabel={confirm}
                                         onConfirm={onConfirm}/>;

    return <CommonDialog onClose={onClose}
                         title={title}
                         actions={actions}>
        <FormattedMessage {...message}/>
    </CommonDialog>
}

DiscardFiltersDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    saved: PropTypes.bool
}

DiscardFiltersDialog.defaultProps = {
    saved: false
}
