import {contentPadding, osColour} from 'omse-components';

const style = theme => ({
    content: {
        fontSize: '1.125rem',
        lineHeight: 1.35,
        color: osColour.neutral.charcoal,
        padding: contentPadding.desktop,
        maxWidth: contentPadding.maxWidth,
        '& h1': {
            fontSize: '1.8rem',
            fontWeight: 400,
            lineHeight: 1.1,
            marginBottom: theme.spacing(3)
        },
        '& section:not(.appendix) h1': {
            fontSize: '1.125rem',
            fontWeight: 600,
            marginBottom: theme.spacing(2)
        },
        '& h2': {
            fontSize: '1.5rem'
        },
        '& a': {
            fontSize: '1.125rem',
            wordWrap: 'break-word',
            display: 'inline'
        },
        '& ul': {
            listStyleType: 'none',
            paddingLeft: 0
        },
        '& li': {
            margin: theme.spacing(1, 0)
        },
        '& .indent': {
            paddingLeft: 40
        },
        '& .block': {
            display: 'block'
        },
        '& ol': {
            listStyleType: 'none',
            counterReset: 'li'
        },
        '& ol.indent': {
            paddingLeft: 80
        },
        '& ol > li::before': { // Typeless default ol is being set; the alternative is many ol[type=1] explicitly in markup.
            content: 'counters(li, ".") " "',
            counterIncrement: 'li',
            fontWeight: 600,
            paddingRight: theme.spacing(1)
        },
        '& ol[type="i"], ol[type="a"], ol.upper-alpha': { // Reset content set in default typeless ol declarations.
            counterReset: 'revert',
            '& li::before': {
                content: 'none'
            }
        },
        '& ol[type="i"]': {
            listStyleType: 'lower-roman'
        },
        '& ol[type="a"]': {
            listStyleType: 'lower-alpha'
        },
        '& ol.upper-alpha': { // Cannot use "ol[type=A]"; case-insensitive attr selectors, type=a exists.
            listStyleType: 'upper-alpha'
        },
        '& section.appendix': {
            '& ol.hidden-first-level-counter': {
                counterReset: 'li 1',
                '& > li::before': {
                    content: 'none'
                }
            },
            '& h1': {
                whiteSpace: 'pre-wrap'
            }
        },
        '& table': {
            marginTop: theme.spacing(1.5),
            border: 'none',
            borderCollapse: 'collapse',
            '& tbody': {
                verticalAlign: 'baseline'
            },
            '& tr': {
                borderBottom: '1px solid ' + osColour.neutral.mist
            },
            '& td, th': {
                padding: theme.spacing(1.5),
                [theme.breakpoints.down('md')]: {
                    overflowWrap: 'break-word',
                    display: 'block',
                    padding: theme.spacing(1)
                }
            },
            '& thead ~ tbody th': {
                fontWeight: 'normal'
            }
        },
        '& table.nowrap': {
            '& th': {
                verticalAlign: 'bottom'
            },
            '& td, th': {
                padding: theme.spacing(1),
                [theme.breakpoints.down('md')]: {
                    display: 'revert',
                    overflowWrap: 'normal'
                }
            }
        },
        '& table.bordered': {
            '& td, th': {
                border: 'solid 1px ' + osColour.neutral.mist
            }
        },
        [theme.breakpoints.down('md')]: {
            '& ol, ul': {
                paddingInlineStart: theme.spacing(1.75)
            },
            '& ol[type=a], ol[type=i]': {
                paddingInlineStart: theme.spacing(4.5)
            }
        },
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile
        },
        '& ul.icons': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
            '& li': {
                display: 'flex'
            },
            '& svg': {
                flex: '0 0 auto',
                marginRight: theme.spacing(1)
            },
        }
    }
});

export default style;