import { apiTermsName, downloadTermsName, generalTermsName } from '../../../../shared/constants';
import {
    hasShowPremiumDownloadsPermissionAndCataloguesToShow,
    hasUseOSPlacesPermission,
    hasViewDataPackagesPermission,
    hasShowNgdDownloadsPermission,
    hasShowVernacularNamesPermission,
    hasShowWorkspacePermission,
    hasManageUsersPermission,
    hasManageLicencesPermission
} from "../../../util/permissions";
import { dataHubExamplesUrl, findRoute, osDataHubExplorerUrl, osNgdGitBook, osSelectAndBuildAccess } from '../../../util/routes';
import messages from '../MenuMessages';
import features from '../../../../shared/features';
import {showMatchDocs} from '../menu-util';
import { isEaiUser } from "../../../util/plans";
import featureCheck from "../../../util/featureCheck";
import { userHasEnabledWorkspace } from '../../../hooks/useTryWorkspace';

/**
 The top level items are used (API, Download etc.)
 menu: items are displayed in the side-menu
 secondary: set to false if secondary menu should be hidden,
 subMenu: items are displayed inline in side-menu, inset under the item
 initRoute: when a subMenu is present, which route to use when opening top-level item
 **/
export const getMainMenu = (userDetails, config) => {
    const downloadsMenuItem = {
        id: 'Download',
        label: messages.download,
        menu: [
            {
                label: messages.premiumDownloads,
                route: findRoute('premiumDownloads'),
                feature: 'premium',
                userCheck: hasShowPremiumDownloadsPermissionAndCataloguesToShow,
            },
            {
                label: messages.manageLicences,
                route: findRoute('manageLicences'),
                userCheck: hasManageLicencesPermission
            },
            {
                label: messages.dataPackages,
                route: findRoute('dataPackages'),
                userCheck: hasViewDataPackagesPermission,
                divider: true
            },
            {
                label: messages.openDataDownloads,
                route: findRoute('openDataDownloads')
            }
        ]
    };

    // Only include NGD if the flag is enabled
    if (featureCheck(features.NGD_DOWNLOADS, config)) {
        downloadsMenuItem.menu.splice(1, 0, {
            label: messages.osSelectBuild,
            feature: features.NGD_DOWNLOADS,
            userCheck: hasShowNgdDownloadsPermission,
            route: findRoute('recipeLibrary'),
        });
    }

    const vnMenu = featureCheck(features.VN, config) ? [{
        id: 'VernacularNames',
        label: messages.vernacularNames,
        secondary: false,
        userCheck: hasShowVernacularNamesPermission,
        route: findRoute('vernacularNames')
    }] : [];

    let eaiApiMenus = [];
    if (featureCheck(features.EAIAPI, config) && isEaiUser(userDetails)) {
        eaiApiMenus = [{
            label: messages.apiPlans, route: findRoute('myApiPlans'),
            subMenu: [
                { label: messages.pricingApiPlans, route: findRoute('pricingApiPlans') },
                { label: messages.addApiPlan, route: findRoute('addApiPlan') },
                { label: messages.addedApiPlan, route: findRoute('addedApiPlan'), hidden:true }
            ]
        }]
    }

    const placesMenuItems = hasUseOSPlacesPermission(userDetails) || featureCheck(features.PREMIUM_OS_PLACES, config) ? [{
        label: messages.placesApi,
        initRoute: findRoute('placesOverview'),
        subMenu: [
            {label: messages.overview, route: findRoute('placesOverview')},
            {label: messages.gettingStarted, route: findRoute('placesIntro')},
            {label: messages.techSpec, route: findRoute('placesDetail')}
        ]
    }] : [];
    const matchMenuItems = showMatchDocs(userDetails, config) ? [{
        label: messages.matchApi,
        initRoute: findRoute('matchOverview'),
        subMenu: [
            {label: messages.overview, route: findRoute('matchOverview')},
            {label: messages.gettingStarted, route: findRoute('matchIntro')},
            {label: messages.techSpec, route: findRoute('matchDetail')}
        ]
    }] : [];
    const ofaMenuItems = featureCheck(features.NGD_FEATURES, config) ? [{
        label: messages.ngdFeaturesApi,
        initRoute: findRoute('ofaOverview'),
        subMenu: [
            {label: messages.overview, route: findRoute('ofaOverview')},
            {label: messages.gettingStarted, route: findRoute('ofaIntro')},
            {label: messages.techSpec, route: findRoute('ofaDetail')},
        ]
    }] : [];
    const otaMenuItems = featureCheck(features.NGD_TILES, config) ? [{
        label: messages.ngdTilesApi,
        initRoute: findRoute('otaOverview'),
        subMenu: [
            {label: messages.overview, route: findRoute('otaOverview')},
            {label: messages.gettingStarted, route: findRoute('otaIntro')},
            {label: messages.techSpec, route: findRoute('otaDetail')},
        ]
    }] : [];

    const workspaceMenuItem = (featureCheck(features.WORKSPACE, config) && hasShowWorkspacePermission(userDetails) && userHasEnabledWorkspace(userDetails?.id)) ? [{
        id: 'Workspace',
        label: messages.workspace,
        route: findRoute('workspace'),
        userCheck: hasShowWorkspacePermission,
        menu: [
            { label: messages.workspace, route: findRoute('workspace'), userCheck: hasShowWorkspacePermission },
            {
                label: messages.apis, route: null,
                subMenu: [
                    { label: messages.apiProjects, route: findRoute('projects') },
                    { label: messages.transactionHistory, route: findRoute('history') },
                    { label: messages.documentation, route: findRoute('documentation') },
                    { label: messages.codeExamples, route: null, externalLink: true, href: dataHubExamplesUrl },
                ]
            },
            {
                label: messages.downloads, route: null,
                subMenu: [
                    { label: messages.openData, route: findRoute('openDataDownloads') },
                    { label: messages.premiumData, route: findRoute('premiumDownloads'), userCheck: hasShowPremiumDownloadsPermissionAndCataloguesToShow },
                    { label: messages.osSelectBuild, route: null, externalLink: true, href: osSelectAndBuildAccess, userCheck: hasShowNgdDownloadsPermission },
                    { label: messages.ngdDocs, route: null, externalLink: true, href: osNgdGitBook, userCheck: hasShowNgdDownloadsPermission},
                ],
            },
            {
                label: messages.apps, route: null,
                subMenu: [
                    { label: messages.errorsReporting, route: findRoute('errorReporting') },
                    { label: messages.vernacularNames, route: findRoute('vernacularNames'), userCheck: hasShowVernacularNamesPermission },
                    { label: messages.dataExplorer, route: null, externalLink: true, href: osDataHubExplorerUrl },
                ],
            },
            {
                label: messages.myTeam, route: null, userCheck: hasManageUsersPermission,
                subMenu: [
                    { label: messages.manageTeamMembers, route: findRoute('manageTeamMembers'), userCheck: hasManageUsersPermission},
                ],
            },
            {
                label: messages.myAccount, route: findRoute('account'),
            },
        ]
    }] : [];

    // Order matters when displaying in top header navigation
    return [
        ...workspaceMenuItem,
        {
            id: 'API',
            label: messages.api,
            menu: [
                {label: messages.dashboard, route: findRoute('dashboard'),
                    subMenu: [
                        {label: messages.history, route: findRoute('history')}
                    ]
                },
                {label: messages.apiProjects, route: findRoute('projects')},
                {label: messages.apiProducts, route: findRoute('apis')},
                ...eaiApiMenus
            ]
        },
        downloadsMenuItem,
        {
            id: 'Documentation',
            label: messages.docs,
            menu: [
                {
                    label: messages.docs, route: findRoute('documentation')
                },
                ...ofaMenuItems,
                ...otaMenuItems,
                {
                    label: messages.downloadsApi,
                    initRoute: findRoute('downloadsOverview'),
                    subMenu: [
                        {label: messages.overview, route: findRoute('downloadsOverview')},
                        {label: messages.gettingStarted, route: findRoute('downloadsIntro')},
                        {label: messages.techSpec, route: findRoute('downloadsDetail')}
                    ]
                },
                {
                    label: messages.featuresApi,
                    initRoute: findRoute('wfsOverview'),
                    subMenu: [
                        {label: messages.overview, route: findRoute('wfsOverview')},
                        {label: messages.gettingStarted, route: findRoute('wfsIntro')},
                        {label: messages.techSpec, route: findRoute('wfsDetail')}
                    ]
                },
                {
                    label: messages.linkedIdentifiersApi,
                    initRoute: findRoute('linkedIdentifiersOverview'),
                    subMenu: [
                        {label: messages.overview, route: findRoute('linkedIdentifiersOverview')},
                        {label: messages.gettingStarted, route: findRoute('linkedIdentifiersIntro')},
                        {label: messages.techSpec, route: findRoute('linkedIdentifiersDetail')}
                    ]
                },
                {
                    label: messages.mapsApi,
                    initRoute: findRoute('wmtsOverview'),
                    subMenu: [
                        {label: messages.overview, route: findRoute('wmtsOverview')},
                        {label: messages.gettingStarted, route: findRoute('wmtsIntro')},
                        {label: messages.techSpec, route: findRoute('wmtsDetail')}
                    ]
                },
                ...matchMenuItems,
                {
                    label: messages.namesApi,
                    initRoute: findRoute('namesOverview'),
                    subMenu: [
                        {label: messages.overview, route: findRoute('namesOverview')},
                        {label: messages.gettingStarted, route: findRoute('namesIntro')},
                        {label: messages.techSpec, route: findRoute('namesDetail')}
                    ]
                },
                ...placesMenuItems,
                {
                    label: messages.vectorApi,
                    initRoute: findRoute('vtsOverview'),
                    subMenu: [
                        {label: messages.overview, route: findRoute('vtsOverview')},
                        {label: messages.gettingStarted, route: findRoute('vtsIntro')},
                        {label: messages.techSpec, route: findRoute('vtsDetail')}
                    ]
                },
                {
                    label: messages.oauthApi,
                    initRoute: findRoute('oauthOverview'),
                    subMenu: [
                        {label: messages.overview, route: findRoute('oauthOverview')},
                        {label: messages.gettingStarted, route: findRoute('oauthIntro')},
                        {label: messages.techSpec, route: findRoute('oauthDetail')}
                    ]
                },
                {
                    label: messages.legal,
                    initRoute: findRoute('legalOverview'),
                    subMenu: [
                        {label: messages.legalOverview, route: findRoute('legalOverview')},
                        {label: messages[apiTermsName], route: findRoute('apiTermsConditions')},
                        {label: messages[generalTermsName], route: findRoute('termsConditions')},
                        {label: messages[downloadTermsName], route: findRoute('downloadTerms')},
                        {label: messages.errorReportingTerms, route: findRoute('errorReportingTerms')},
                        {label: messages.vernacularNamesTerms, route: findRoute('vernacularNamesTerms')},
                        {label: messages.frameworkContractTerms, route: findRoute('frameworkContractTerms')},
                        {label: messages.partnerContracts, route: findRoute('partnerContracts')},
                        {label: messages.trialModeTerms, route: findRoute('trialModeTerms')},
                        {label: messages.sla, route: findRoute('sla')},
                    ]
                },
                {
                    label: messages.brandLogo,
                    initRoute: findRoute('brandImportance'),
                    subMenu: [
                        {label: messages.brandImportance, route: findRoute('brandImportance')},
                        {label: messages.logoOverview, route: findRoute('logoOverview')},
                        {label: messages.logoFullColour, route: findRoute('logoFullColour')},
                        {label: messages.logoWhite, route: findRoute('logoWhite')},
                        {label: messages.copyrightOverview, route: findRoute('copyrightOverview')},
                        {label: messages.logoCopyrightPositioning, route: findRoute('logoCopyrightPositioning')},
                        {label: messages.exclusionZone, route: findRoute('exclusionZone')},
                        {label: messages.minMax, route: findRoute('minMax')},
                        {label: messages.prohibitedUse, route: findRoute('prohibitedUse')},
                    ]
                }
            ]
        },
        {
            id: 'Support',
            label: messages.support,
            menu: [{
                label: messages.faqs,
                initRoute: findRoute('support'),
                subMenu: [
                    {label: messages.supportAccountApi, route: findRoute('support')},
                    {label: messages.supportPlans, route: findRoute('supportPlans')},
                    {label: messages.supportDownload, route: findRoute('supportDownload')},
                    {label: messages.supportOsSelectAndBuild, route: findRoute('supportOsSelectAndBuild')}
                ]
            },
                {label: messages.serviceStatus, route: findRoute('serviceStatus')}
            ]
        },
        {
            id: 'Plans',
            label: messages.plans,
            secondary: false,
            menu: [
                {label: messages.plans, route: findRoute('plans')},
            ]
        },
        {
            id: 'ErrorReporting',
            label: messages.errorsReporting,
            secondary: false,
            menu: [
                {label: messages.errorsReporting, route: findRoute('errorsAndOmissions')}
            ]
        },
        ...vnMenu
    ];
}
