import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { CommonDialog } from "omse-components";
import {Typography} from '@mui/material';

const messages = defineMessages({
    title:{
        id:"unabletochange.title",
        defaultMessage: "Unable to change project",
        description: "Title"
    },
    body: {
        id: "unabletochange.body",
        defaultMessage: "This project contains premium API products, and you have no API plans setup. Please purchase an API plan to continue.",
        description: "Body Text"
    }
})

export default function UnableToChangeModal({ onClose }) {
    return (
        <CommonDialog onClose={onClose}
                      title={messages.title}
                      actions={null}>
            <Typography variant="body1">
                <FormattedMessage {...messages.body}/>
            </Typography>
        </CommonDialog>
    );
}

UnableToChangeModal.propTypes = {
    onClose: PropTypes.func
}
