import React from 'react';
import {ReactComponent as InfoIcon} from "../../../components/icons/info-notification.svg";
import {FormattedMessage} from 'react-intl';
import {osColour} from 'omse-components';
import {createUseStyles} from 'react-jss';
import {Typography} from "@mui/material";
import useExpandInfoMessage from "./useExpandInfoMessage";

const useStyles = createUseStyles(theme => ({
    infoContainer: {
        display: 'flex',
        alignItems: 'center',
        borderLeft: '3px solid',
        borderLeftColor: osColour.status.info,
        backgroundColor: 'rgba(0, 113, 188, 0.1)',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    info: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    infoIcon: {
        color: osColour.status.info,
        paddingLeft: theme.spacing(1),
        minWidth: 24
    }
}));

export default function ExpandInfo(props) {
    const {dataPackage} = props;
    const classes = useStyles();

    let infoMessage = useExpandInfoMessage(dataPackage);

    return <div className={classes.infoContainer}>
        <InfoIcon width={24} height={24} aria-label='info' className={classes.infoIcon}/>
        <Typography className={classes.info}>
            <FormattedMessage {...infoMessage}/>
        </Typography>
    </div>

}