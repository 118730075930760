import {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

// A hook that can be used to select state from the store, but to only return the state when the
// action id in the state matches the action id of the component. This is useful in places where
// we need to ignore old state before performing some action.
// The return from the hook is an array, with the first member being the result of the selector,
// and the second being a dispatch() method that will add the action id in to any action that is
// dispatched.

// We need a strategy for setting unique action ids in the real code, and we also need a strategy
// to create predicatable ones for testing.
let actionIdCounter = 0;
let idGenerationStrategy = () => actionIdCounter++;

const TEST_ID = 'testId';
export function setTestIdStrategy() {
    idGenerationStrategy = () => TEST_ID;
    return TEST_ID;
}

export function resetId() {
    actionIdCounter = 0;
}

// We use a constant for the empty state, so that we return a stable object from the hook
const emptyState = {};

export default function useActionIdSelector(path) {
    const dispatch = useDispatch();

    // Generate an ID using whichever strategy is currently enabled
    const [actionId] = useState(idGenerationStrategy());

    // Passing useState a function allows us to only build this dispatchWrapper once.
    // The result is a helper function that will dispatch the passed event with the correct action id,
    // and as the function returned is exactly the same instance each time the hook is called we won't
    // trigger any extra re-render of the component that is using us.
    const [dispatchWrapper] = useState(() => function(action) {
        action.actionId = actionId;
        dispatch(action);
    });

    const result = useSelector(state => {
        let result = state;
        path.split('.').forEach(step => {
            // If the step is not valid then this will fail. That's a good thing - the paths into our store should
            // be well-known, so if the path is wrong then we want the app to fail in dev mode, so that we can fix it.
            result = result[step];
        });
        if(result.actionId !== actionId) {
            result = emptyState;
        }
        return result;
    });

    return [result, dispatchWrapper];
}