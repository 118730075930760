import PropTypes from 'prop-types';
import {border1, osColour} from 'omse-components';
import Bar from './barGraph/Bar';
import {defineMessages, FormattedMessage} from "react-intl";
import Typography from "@mui/material/Typography";
import classNames from 'classnames';
import CostInfoTooltip from "./payment/CostInfoTooltip";
import GraphKey from './GraphKey';
import styled from '@emotion/styled';

const StyledBarGraph = styled.div(({theme})=> `
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing(3)};
    & .graph {
        position: relative;
        width: 100%;
        height: 20px;
        background-color: ${osColour.neutral.clouds};
        display: flex;
        flex-direction: row;
        border-radius: 3px;
        border: ${border1};
        justify-content: flex-end;
        box-sizing: border-box;
        &.displayCount {
            margin-top: ${theme.spacing(1)};
        }
    }
    & .label {
        color: ${osColour.primary.berry};
        text-align: right;
        margin-top: 4px;
    }
    & .graphLabel {
        color: ${osColour.neutral.stone};
        margin-top: 4px;
    }
    & .countInfo {
        display: flex;
        align-items: baseline;        
    }
    & .counterText {
        line-height: 1.5;
        font-family: OSGill, sans-serif;
        color: ${osColour.neutral.charcoal};
        margin-right: ${theme.spacing(0.75)};
    }
    & .countLabel {
        color: ${osColour.neutral.stone};
    }
    & .graphHeader {
        display: flex;
    }
    & .graphStats {
        flex: 1 1 auto;
    }
    & .graphSecondaryInfo {
        align-self: flex-end;
    }
    & .totals {
        display: flex;
        flex-wrap: wrap;
        & > div {
            margin-right: ${theme.spacing(3)};
            flex: none;
        }
        &.small {
            & .counterText {
                font-size: 1.25rem;
                margin-right: ${theme.spacing(0.5)};
            }
            & .countLabel {
                margin-right: ${theme.spacing(0.5)};
            }
        }
        & > div:last-of-type {
            margin-right: 0;
        }
    }
`);

const messages = defineMessages({
    keyItem: {
        id:'BarGraph.keyItem',
        defaultMessage: '{label}',
        description: 'label for key item'
    },
    transactions: {
        id:'BarGraph.transactions',
        defaultMessage: 'transactions',
        description: 'label for transactions'
    },
    costLabel: {
        id:'BarGraph.costLabel',
        defaultMessage: 'cost',
        description: 'label for cost'
    },
    costPounds: {
        id:'BarGraph.costPounds',
        defaultMessage: '£{data}',
        description: 'cost value with pounds'
    },
    count: {
        id: 'BarGraph.count',
        defaultMessage: '{count, number}',
        description: 'Usage total'
    },
    inclVat: {
        id: 'BarGraph.inclVat',
        defaultMessage: 'incl. VAT',
        description: 'incl. VAT'
    },
    unavailableBill: {
        id: 'BarGraph.unavailableBill',
        defaultMessage: 'Estimated bill currently unavailable',
        description: 'Estimated bill unavailable'
    }
});

const BarGraph = (props) => {
    const {variant, graphData, total, graphStyle, graphLabel, count,
        displayCountLabel, counterTextStyle, countLabel, graphLabelStyle, graphContainerStyle,
        costInPence, costLabel, displayCost,
        tooltip, displayCount, displayKey, keyOnly, className} = props;

    let showInclVat = false;
    let showCostInfoTooltip = false;

    let countLabelMessage = messages.transactions;
    if (countLabel) {
        countLabelMessage = countLabel;
    }

    let costLabelMessage = messages.costLabel;
    if (costLabel) {
        costLabelMessage = costLabel;
    }

    const small = variant === 'small';

    let costInPounds;
    if (typeof costInPence !== 'undefined') {
        costInPounds = 0;
        if (costInPence > 0) {
            costInPounds = (costInPence / 100).toFixed(2);
            showInclVat = true;
            showCostInfoTooltip = true;
        }
    }

    if (keyOnly) {
        return (
            <div className={className}>
                <GraphKey graphData={graphData} />
            </div>
        )
    }

    return (
        <StyledBarGraph className={className} style={{...graphContainerStyle}}>
            <div className='graphHeader'>
                <div className='graphStats'>
                    {graphLabel &&
                        <Typography className='graphLabel' style={{...graphLabelStyle}}>
                            {graphLabel}
                        </Typography>
                    }

                    <div className={classNames({totals: true, small})}>                            
                        {(displayCost && (costInPounds || costInPounds === 0)) &&
                            <div className='countInfo'>
                                <Typography component='span' variant='h2' className='counterText'
                                            style={{...counterTextStyle}}>                                                                
                                    <FormattedMessage {...messages.costPounds} values={{data: costInPounds}} />
                                </Typography>
                                {showInclVat &&
                                <Typography className='countLabel'>
                                    <FormattedMessage {...messages.inclVat}/>
                                </Typography>
                                }
                                {showCostInfoTooltip &&
                                <CostInfoTooltip classes={{tooltip: '.tooltip'}} />
                                }
                                <Typography variant={'body1'} className='countLabel'>
                                    <FormattedMessage {...costLabelMessage} />
                                </Typography>
                            </div>
                        }

                        {(displayCost && (typeof costInPounds === 'undefined')) &&
                        <div className='countInfo'>
                            <Typography component='span' className='countLabel'>
                                <FormattedMessage {...messages.unavailableBill}/>
                            </Typography>
                        </div>
                        }

                        {(displayCount || displayCountLabel) &&
                            <div className='countInfo'>
                                {displayCount && (count || count === 0) && 
                                    <Typography component='span' variant={'h2'} className='counterText'
                                                style={{...counterTextStyle}}>
                                            <FormattedMessage {...messages.count} values={{count}} />                                            
                                    </Typography>
                                }
                                {displayCountLabel &&
                                    <Typography variant={'body1'} className='countLabel'>
                                        <FormattedMessage {...countLabelMessage} values={{data: count}} />
                                    </Typography>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className='graphSecondaryInfo'>
                    {tooltip}
                </div>
            </div>
            <div className={classNames({graph: true, displayCount})} style={{...graphStyle}}>
                {
                    graphData.map((data, index) => {
                        return <Bar key={'bar' + index} data={data} total={total} />
                    })
                }
            </div>
            {displayKey && <GraphKey graphData={graphData}  />}
        </StyledBarGraph>
    );
}

BarGraph.defaultProps = {
    displayCountLabel: true,
    displayKey: false,
    displayCount: false,
    displayCost: false
};

BarGraph.propTypes = {
    graphData: PropTypes.arrayOf(PropTypes.shape({total:PropTypes.number})).isRequired,
    total: PropTypes.number.isRequired,
    graphLabel: PropTypes.object,
    graphStyle: PropTypes.object,
    displayCountLabel: PropTypes.bool,
    counterTextStyle: PropTypes.object,
    graphLabelStyle: PropTypes.object,
    countLabel: PropTypes.object,
    graphContainerStyle: PropTypes.object,
    tooltip: PropTypes.object,
    displayCount: PropTypes.bool,
    displayKey: PropTypes.bool,
    keyOnly: PropTypes.bool
};

export default BarGraph;