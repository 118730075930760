import React from 'react';
import AddCommercialApiDialog from './ApiDialog/AddCommercialApiDialog';
import AddProjectDialog from './ApiDialog/AddProjectDialog';

export default function APIDialog(props) {
    const {loading, hasEaiApiPlans, hasProjects, hasManageApiPermission} = props;

    if (!hasEaiApiPlans && !loading) {
        return <AddCommercialApiDialog hasManageApiPermission={hasManageApiPermission}/>
    } else if(!hasProjects && !loading){
        return <AddProjectDialog/>
    }
    return null;
}
