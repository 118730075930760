import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    '@global': {
        '#root': {
            height: '100%'
        }
    }
});

export default function withFullHeight(WrappedComponent) {
    return function FullHeight(props) {
        const classes = useStyles();
        return <WrappedComponent classes={classes} {...props} />
    }
}