import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import MultiPolygon from 'ol/geom/MultiPolygon';
import MultiPoint from 'ol/geom/MultiPoint';
import Feature from 'ol/Feature';

import union from '@turf/union';
import intersect from '@turf/intersect';
import difference from '@turf/difference';

const geoJson = new GeoJSON();
const wkt = new WKT();

// The turf/union function only works with polygons and multipolygons, and will throw exceptions if we
// pass in the wrong sort of geometry. If the geometries are broken then the helper can throw exceptions.
// Finally, for shapes that have no area then the result geometry is null, so we need to handle that too.
export function removeInternalCoords(olFeature) {
    // remove any internal coords by doing a union on the feature with itself
    try {
        const geoJsonFeature = toGeoJsonFeature(olFeature);
        if(!geoJsonFeature) {
            return null;
        }
        const simplified = union(geoJsonFeature, geoJsonFeature);
        if(!simplified) {
            return null;
        }
        return toOLFeature(simplified);
    } catch {
        // The geometry must be illogical, or it is not a Polygon / MultiPolygon
        return null;
    }
}

export async function mergeFeatures(features) {
    // Add the features in batches, as they are all tiles and probably line up rather well.
    // We can't add them all at once as the turf library thinks the list is too long when ordering imagery
    // (which has a very fine tile grid).
    // As this calculation can take a while we do parts of it async, so that we give the UI a chance to run.
    const batches = [];
    const batchSize = 1000; // Imagery has about 400,000 features, so batches of 1,000 is about right: 400,000 / 1,000 = 400
    for(let index = 0; index < features.length; index += batchSize) {
        const featuresToMerge = features.slice(index, index+batchSize);
        batches.push(await mergeBatch(featuresToMerge));
    }

    return mergeBatch(batches);
}

function mergeBatch(features) {
    if(features.length === 1) {
        return features[0];
    }

    // Do this batch in a setTimeout(), so that other tasks get a chance to run and the UI doesn't freeze
    return new Promise(resolve => {
        setTimeout(() => {
            let multipolygonFeature = polygonsToMultiPolygon(features);
            resolve(removeInternalCoords(multipolygonFeature));
        }, 0);
    });
}

export function toOLGeometry(geoJsonGeometry) {
    return geoJson.readGeometry(geoJsonGeometry);
}

export function toOLFeature(geoJsonFeature) {
    return geoJson.readFeature(geoJsonFeature);
}

export function toGeoJsonFeature(olFeature) {
    return geoJson.writeFeatureObject(olFeature);
}

export function toGeoJsonGeometry(olGeometry) {
    return geoJson.writeGeometryObject(olGeometry);
}

export function wktToOLFeatures(wktFeatures) {
    return wkt.readFeatures(wktFeatures);
}

export function featureToMultiPoint(feature) {
    let coordinates = getCoordinates(feature);
    return new MultiPoint(coordinates);
}

export function getNumberOfCoords(olFeature) {
    return getCoordinates(olFeature).length;
}

function getCoordinates(olFeature) {
    let coordinates;
    if (olFeature.getGeometry().getType() === 'MultiPolygon')
        coordinates = olFeature.getGeometry().getCoordinates().flatMap(p => p).flatMap(c => c);
    else
        coordinates = olFeature.getGeometry().getCoordinates().flatMap(c => c);
    return coordinates;
}

// A linear ring is an array of coordinates. Each coordinate should have length 2.
export function isLinearRing(coordinates) {
    const result = coordinates && coordinates.length > 0 && coordinates.reduce((result, coordinate) => {
        return result && coordinate.length === 2;
    }, true);
    return result;
}

// A polygon is an array of rings
export function isPolygon(coordinates) {
    const result = coordinates && coordinates.length > 0 && coordinates.reduce((result, ring) => {
        return result && isLinearRing(ring);
    }, true);
    return result;
}

// A multipolygon is an array of polygons
export function isMultiPolygon(coordinates) {
    const result = coordinates && coordinates.length > 0 && coordinates.reduce((result, polygon) => {
        return result && isPolygon(polygon);
    }, true);
    return result;
}

export function multiPolygonToPolygons(multiPolygon) {
    return multiPolygon.getGeometry().getPolygons().map(p => new Feature(p));
}

export function polygonsToMultiPolygon(features) {
    let polygons = [];
    let appendPolygons = (geometries) => {
        geometries.forEach((geometry) => {
            if (geometry.getType() === 'MultiPolygon') {
                appendPolygons(geometry.getPolygons());
            } else if (geometry.getType() === 'Polygon') {
                // Adding a geometry into a larger one has side-effects, so we need to clone it
                // in order to leave the original feature alone.
                polygons.push(geometry.clone());
            }
        });
    };

    let geometries = features.map(f => f.getGeometry());
    appendPolygons(geometries);

    return new Feature(new MultiPolygon(polygons));
}

export function clipToLayer(feature, layer) {
    let gjFeature = toGeoJsonFeature(feature);
    let features = layer.getSource().getFeatures() || [];
    features.forEach(layerFeature => {
        let gjLayerFeature = toGeoJsonFeature(layerFeature);
        if (intersect(gjLayerFeature, gjFeature)) {
            let diff = difference(gjFeature, gjLayerFeature);
            if (diff && feature) {
                let olDiff = toOLFeature(diff);
                feature.setGeometry(olDiff.getGeometry());
            } else {
                feature = null;
            }
        }
    });

    return feature;
}