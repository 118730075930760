import {combineReducers} from 'redux';
import {createActionMiddleware, createActionReducer} from 'omse-components';
import {
    CHANGE_RECIPE_DESCRIPTION,
    CHANGE_RECIPE_NAME,
    CREATE_RECIPE,
    DISPLAY_VALIDATION_ERRORS,
    RESET_FEATURE_TYPES,
    RESET_RECIPE_STATE,
    SELECT_FEATURE_TYPES,
    UNSELECT_FEATURE_TYPES,
    SET_FEATURE_TYPE_FILTER,
    SET_CURRENT_QUERY_TREE, setCurrentQueryTree,
    VALIDATE_CQL_FILTER_ACTION
} from './recipeEditor/actions';

const defaultNameState = '';
const defaultDescriptionState = '';
const defaultFeatureTypesState = [];

/* Reducers */
export const recipeEditorReducer = combineReducers({
    name: nameReducer,
    description: descriptionReducer,
    featureTypes: featureTypesReducer,
    createRecipe: createActionReducer(CREATE_RECIPE),
    displayValidationErrors: displayValidationErrorsReducer,
    currentQueryTree: currentQueryTreeReducer,
    validateCqlFilter: createActionReducer(VALIDATE_CQL_FILTER_ACTION)
});

/* Middleware */
export const recipeEditorMiddleware = [
    createActionMiddleware(CREATE_RECIPE, (action, store) => {
        const {name, description, featureTypes} = store.getState().recipeEditor;

        // The feature types structure includes front-end specific tree objects, and we need to trim them
        // out before passing the datasets to the Node layer.
        const datasets = featureTypes.map(
            ({featureTypeId, featureTypeVersion, filterExpression}) => ({featureTypeId, featureTypeVersion, filterExpression})
        );

        return {
            method: 'POST',
            url: '/api/recipes',
            body: {
                name,
                description,
                datasets
            }
        };
    }, () => {}),
    createActionMiddleware(VALIDATE_CQL_FILTER_ACTION, prepareValidateCqlFilter),
    store => next => action => {
        switch(action.type) {
            case UNSELECT_FEATURE_TYPES:
                // If the current feature is de-selected then we must also discard any in-progress query tree
                const state = store.getState();
                const toRemove = action.arrayOfFeatureTypesAndVersions;
                const currentFeatureTypeId = state.recipes.featureTypeSchema.result?.featureId;
                if(toRemove.find(item => item.featureTypeId === currentFeatureTypeId)) {
                    store.dispatch(setCurrentQueryTree());
                }
                break;
            default:
                // Do nothing
        }
        next(action);
    }
];

/* Impl */
function nameReducer(state = defaultNameState, action) {
    switch (action.type) {
        case CHANGE_RECIPE_NAME:
            return action.name;
        case RESET_RECIPE_STATE:
            return defaultNameState;
        default:
            return state;
    }
}

function descriptionReducer(state = defaultDescriptionState, action) {
    switch (action.type) {
        case CHANGE_RECIPE_DESCRIPTION:
            return action.description;
        case RESET_RECIPE_STATE:
            return defaultDescriptionState;
        default:
            return state;
    }
}

function buildFindFunction(featureTypeId) {
    return function(item) {
        return item.featureTypeId === featureTypeId;
    }
}
function buildFilterFunction(featureTypeId) {
    return function(item) {
        return item.featureTypeId !== featureTypeId;
    }
}

function featureTypesReducer(state = defaultFeatureTypesState, action) {
    switch (action.type) {
        case SELECT_FEATURE_TYPES:
        {
            // We have an array of new selections. Some of them might already be in the list, so we just add in the
            // truly new ones.
            const result = [...state];
            action.arrayOfFeatureTypesAndVersions.forEach(({featureTypeId, featureTypeVersion}) => {
                const index = state.findIndex(buildFindFunction(featureTypeId));
                if(index === -1) {
                    result.push({
                        featureTypeId,
                        featureTypeVersion,
                        filterExpression: null,
                        filterTree: null
                    });
                } else {
                    // Shallow-clone the existing item and make sure we have the correct version
                    const newItem = { ...state[index] };
                    newItem.featureTypeVersion = featureTypeVersion;
                    result[index] = newItem;
                }
            });
            return result;
        }
        case UNSELECT_FEATURE_TYPES:
        {
            let idsToRemove = action.arrayOfFeatureTypesAndVersions.map(item => item.featureTypeId);
            let result = state.filter(selectedItem => {
                return !idsToRemove.includes(selectedItem.featureTypeId);
            });
            return result;
        }
        case SET_FEATURE_TYPE_FILTER:
            const item = state.find(buildFindFunction(action.featureTypeId));
            if(!item){return state;}

            const result = state.filter(buildFilterFunction(action.featureTypeId));
            const newItem = {
                ...item,
                filterExpression: action.filterExpression,
                filterTree: action.filterTree
            };
            result.push(newItem);
            return result;
        case RESET_RECIPE_STATE:
        case RESET_FEATURE_TYPES:
            return defaultFeatureTypesState;
        default:
            return state;
    }
}

function displayValidationErrorsReducer(state = false, action) {
    switch (action.type) {
        case DISPLAY_VALIDATION_ERRORS:
            return true;
        case RESET_RECIPE_STATE:
            return false;
        default:
            return state;
    }
}

function currentQueryTreeReducer(state = { tree: null, expression: null, canSave: false, treeEdited: false }, action) {
    switch(action.type) {
        case SET_CURRENT_QUERY_TREE:
            return {
                tree: action.tree,
                expression: action.expression,
                canSave: action.canSave,
                treeEdited: action.treeEdited
            };
        default:
            return state;
    }
}

function prepareValidateCqlFilter(action) {
    return {
        url: `/api/schema/featureTypes/${action.featureTypeId}/version/${action.featureTypeVersion}/validateCqlFilter`,
        method: 'POST',
        body: {
            cqlFilter: action.cqlFilter,
            attributeFilterType: 'DOWNLOAD'
        }
    }
}
