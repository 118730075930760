import React, {Fragment, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import ToolbarButton from "./ToolbarButton";
import {Menu, MenuItem} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {useDispatch} from 'react-redux';
import {setMapTool, resetTooltip} from "../../../../modules/toolbar";

export default function ToolbarMenuButton(props) {
    const {label, ariaLabel, icon, tooltip, menuItems} = props;
    const [showMenu, setShowMenu] = useState(false);
    const anchor = useRef();
    const dispatch = useDispatch();

    function handleItemClick(item) {
        setShowMenu(false);
        if(item) {
            item.onClick();
        } else {
            // Clear out the placeholder tool that we created
            dispatch(setMapTool());
        }
    }
    function handleMenuClick() {
        setShowMenu(true);

        // Clear the current tooltip. The tooltip can't clear itself because the menu overlay has prevented the
        // mouseleave event from happening.
        dispatch(resetTooltip());

        // By putting an new tool onto the map we prevent the default tool from activating while the menu is active.
        dispatch(setMapTool({
            type: 'menuButtonPlaceholder'
        }));
    }

    return <Fragment>
        <ToolbarButton label={label}
                       ariaLabel={ariaLabel}
                       icon={icon}
                       tooltip={tooltip}
                       onClick={handleMenuClick}
                       ref={anchor}
        />
        {
            showMenu && <Menu open={true}
                              onClose={() => handleItemClick()}
                              anchorEl={anchor.current}
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                              getContentAnchorEl={null}
            >
                {
                    menuItems.map(item => <MenuItem key={item.label.id}
                                                    onClick={() => handleItemClick(item)}
                    >
                        <FormattedMessage {...item.label}/>
                    </MenuItem>)
                }
            </Menu>
        }
    </Fragment>
};

ToolbarMenuButton.propTypes = {
    label: PropTypes.object.isRequired,
    ariaLabel: PropTypes.object.isRequired,
    icon: PropTypes.node.isRequired,
    tooltip: PropTypes.object,
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.object.isRequired,
        onClick: PropTypes.func.isRequired
    })).isRequired
};
