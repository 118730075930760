import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '../Table';
import InlineCodeSnippet from '../../../components/InlineCodeSnippet';

export default function MatchStatusCodes() {
    return <>
        <Typography variant='h4'>Logical status codes</Typography>
        <Typography variant='body1' paragraph={true} component='div'>
            <Table header={['Logical status code', 'Logical status description']} values={[
                [<InlineCodeSnippet>1</InlineCodeSnippet>, 'Approved address'],
                [<InlineCodeSnippet>3</InlineCodeSnippet>, 'Alternate address (only applicable to LPI records)'],
                [<InlineCodeSnippet>6</InlineCodeSnippet>, 'Provisional address'],
                [<InlineCodeSnippet>8</InlineCodeSnippet>, 'Historic address']
            ]} />
        </Typography>
        <Typography variant='h4'>Country codes</Typography>
        <Typography variant='body1' paragraph={true} component='div'>
            <Table header={['Country code', 'Country description']} values={[
                [<InlineCodeSnippet>E</InlineCodeSnippet>, 'This record is within England'],
                [<InlineCodeSnippet>W</InlineCodeSnippet>, 'This record is within Wales'],
                [<InlineCodeSnippet>S</InlineCodeSnippet>, 'This record is within Scotland'],
                [<InlineCodeSnippet>N</InlineCodeSnippet>, 'This record is within Northern Ireland'],
                [<InlineCodeSnippet>L</InlineCodeSnippet>, 'This record is within Channel Islands'],
                [<InlineCodeSnippet>M</InlineCodeSnippet>, 'This record is within the Isle of Man'],
                [<InlineCodeSnippet>J</InlineCodeSnippet>, 'This record is not assigned to a country as it falls outside of the land boundaries used']
            ]} />
        </Typography>
    </>;
}
