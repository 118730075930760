import { useRef } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { FormattedMessage, useIntl } from "react-intl";
import { border1, osColour } from "omse-components";
import CopyToClipboard from "./CopyToClipboard";

const Label = styled(Typography)(
    ({ theme }) => `
    margin: 0 0 ${theme.spacing(0.5)} 0;
    color: ${osColour.neutral.stone};
`
);

const Container = styled("div")(
    ({ theme, isCodeSnippet, isDisabled }) => `
    margin: 0 0 ${theme.spacing(2)} 0;
    display: flex;
    align-items: stretch;
    & > p {
        padding: ${theme.spacing(1.5)} 0 ${theme.spacing(1.5)} ${theme.spacing(2)};
        flex: 1 1 auto;
        border: ${border1};
        border-right: 0;
        border-radius: 4px 0 0 4px;
        background: ${osColour.neutral.clouds};
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        white-space: ${isCodeSnippet ? "wrap" : "nowrap"};
        ${isDisabled && `color: ${osColour.neutral.mist};`}
        ${
            isCodeSnippet &&
            `
            font-size: 0.9rem;
            line-height: 1.6em;
            font-family: monospace;
            overflow-wrap: break-word;
        `
        }
    }
`
);

export default function TextBox(p) {
    const { text, label, filledButton, labelId, disabled, variant } = p;
    const intl = useIntl();
    const keyRef = useRef(null);

    return (
        <>
            <Label variant="body2" id={labelId}>
                {label.id ? <FormattedMessage {...label} /> : label}
            </Label>
            <Container isCodeSnippet={variant === "code"} isDisabled={disabled}>
                <Typography variant="body1" ref={keyRef}>
                    {text.id ? <FormattedMessage {...text} /> : text}
                </Typography>
                <CopyToClipboard
                    inputRef={keyRef}
                    ariaDescriptor={label.id ? intl.formatMessage(label) : label}
                    filled={filledButton}
                    disabled={disabled}
                />
            </Container>
        </>
    );
}

TextBox.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    filledButton: PropTypes.bool,
    disabled: PropTypes.bool,
    labelId: PropTypes.string,
    variant: PropTypes.oneOf(["code"]),
};

TextBox.defaultProps = {
    disabled: false,
};
