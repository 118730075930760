import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';

const StyledCard = styled('article')(({ theme }) => `
    border: 1px solid ${theme.palette.neutral.main};
    display: flex;
    flex-direction: column;
    flex: 1 1;
    & img {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
    }
    & .card-detail {
        padding: ${theme.spacing(3)};
        display: flex;
        flex: 1 0;
        flex-direction: column;
        justify-content: space-between;
    }
    & .card-title {
        font-family: OSGill, sans-serif;
        font-size: 1.5rem;
        line-height: 1.3;
    }
    & h4.card-title {
        margin-bottom: ${theme.spacing(2)};
        font-weight: normal;
        font-size: 1.5rem;
    }
    & .card-title, & .card-description {
        margin-bottom: ${theme.spacing(3)};
        color: ${theme.palette.textPrimary.main};
    }
    & a {
        width: fit-content;
    }
`);


const Card = ({ title, titleVariant, titleComponent, description, link, video, imgSrc, imgAlt, imgAltMessage, imgSrcSet, imgSizes }) => {
    const intl = useIntl();
    let media;

    if (video) {
        media = video;
    } else {
        const altText = imgAlt || (imgAltMessage && intl.formatMessage(imgAltMessage)) || '';

        if (imgSrcSet && imgSizes) {
            media = (
                <img srcSet={imgSrcSet} sizes={imgSizes} alt={altText} />
            );
        } else if (imgSrc) {
            media = (
                <img src={imgSrc} alt={altText} />
            );
        }
    }

    return (
        <StyledCard>
            {media}
            <div className='card-detail'>
                <div>
                    <Typography variant={titleVariant} component={titleComponent} className='card-title'>
                        <FormattedMessage {...title} />
                    </Typography>
                    <Typography className='card-description'>
                        <FormattedMessage {...description} />
                    </Typography>
                </div>
                {link}
            </div>
        </StyledCard>
    );
};

const htmlHeadings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

Card.propTypes = {
    title: PropTypes.object.isRequired,
    description: PropTypes.object.isRequired,
    link: PropTypes.object,
    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
    imgAltMessage: PropTypes.object,
    imgSrcSet: PropTypes.string,
    imgSizes: PropTypes.string,
    video: PropTypes.object,
    titleVariant: PropTypes.oneOf(htmlHeadings),
    titleComponent: PropTypes.oneOf(htmlHeadings)
};

Card.defaultProps = {
    titleVariant: 'h2',
    titleComponent: 'h2'
}

export default Card;