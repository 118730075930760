import React from 'react';
import { VernacularNames } from "omse-components";
import FeatureCheck from "../components/FeatureCheck";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getOrganisationUsers } from '../modules/organisation/action';

export function VernacularNamesReporting(props) {
    const {mapTesting} = useSelector(state => state.config.current.result.mapTesting);
    const dispatch = useDispatch()

    if (mapTesting) {
        //This is set in omse-components/src/omse-ol/Map/Map.js
        console.log('Storing map object into the vnMap global variable');
    }

    // As soon as the component loads, try to get the list of users
    useEffect(function() {
        /* Skip looking at the OpenData Users, as it is not applicable to VN and takes a long time to query */
        dispatch(getOrganisationUsers(true, true));
    }, [dispatch]);

    return (
        <FeatureCheck feature="vn">
            <VernacularNames {...props}/>
        </FeatureCheck>
    );
}

export default VernacularNamesReporting;
