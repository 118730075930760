import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import withStyles from 'react-jss';
import {styles} from "../../DownloadStyles";
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import Typography from '@mui/material/Typography';
import {getPremiumCatalogue} from '../../../../modules/premium/actions'
import CircularProgress from '@mui/material/CircularProgress';
import { DropDownMenu, LinkButton } from 'omse-components';
import PremiumDataItem from './PremiumDownloadsItem';
import routes from "../../../../util/routes";
import FeatureCheck from "../../../../components/FeatureCheck";
import SearchBox from "../../../../components/SearchBox";
import Link from "../../../../components/Link";
import {ReactComponent as HelpIcon} from "../../../../components/icons/help-notification.svg";
import {withRouter} from 'react-router';
import queryString from 'query-string';
import { TotalResultsBar } from '../../../../components/TotalResultsBar';

const messages = defineMessages({
    title: {
        id: 'PremiumDownloads.title',
        defaultMessage: 'Premium Data Downloads',
        description: 'Label for Premium data downloads'
    },
    subtitle: {
        id: 'PremiumDownloads.subtitle',
        defaultMessage: 'Explore our premium geospatial and mapping data.',
        description: 'Sub title for Premium data download page'
    },
    searchLabel: {
        id: 'PremiumDownloads.searchLabel',
        defaultMessage: 'Search Premium data downloads',
        description: 'Label for Search Premium data downloads'
    },
    searchPlaceholder: {
        id: 'PremiumDownloads.searchPlaceholder',
        defaultMessage: 'Search by name',
        description: 'Placeholder for Search Premium data downloads'
    },
    allData: {
        id: 'PremiumDownloads.allDataLabel',
        defaultMessage: 'All data',
        description: 'Label for All data'
    },
    noResults: {
        id: 'PremiumDownloads.noResults',
        defaultMessage: 'No downloads match your search',
        description: 'Label for no results'
    },
    faqLink: {
        id: 'PremiumDownloads.faqLink',
        defaultMessage: 'Get help requesting and downloading data',
        description: 'Text for the link for Download FAQs'
    },
    clearSearch: {
        id: 'PremiumDownloads.clearSearch',
        defaultMessage: 'Clear search',
        description: 'Clear search label'
    },
    showingDependencies: {
        id: 'PremiumDownloads.showingDependencies',
        defaultMessage: 'Showing dependencies for {productLabel}. Please select one of the products below to continue.',
        description: 'Showing dependencies'
    },
    clearFilter: {
        id: 'PremiumDownloads.clearFilter',
        defaultMessage: 'Clear filter',
        description: 'Clear filter label'
    }
});

export class PremiumDownloads extends Component {

    state = {
        search: '',
        filteredDownloads: [],
        dataFilterLabel: messages.allData,
        dataFilterItems: [],
        bmiCodeFilter: null,
        targetProduct: null,
        dataFilterValue: ''
    }

    componentDidMount() {
        const {catalogue, location} = this.props;
        if (!catalogue || catalogue.length === 0) {
            this.props.getPremiumCatalogue();
        } else {
            // This copies the full catalogue into the filtered list.
            this.resetSearch();
        }
        if (location) {
            const parsedLocationSearch = queryString.parse(location.search);
            const hasBmiValue = Object.keys(parsedLocationSearch).indexOf('bmi') !== -1;
            if (hasBmiValue) {
                this.setState({bmiCodeFilter: parsedLocationSearch.bmi});
                const hasTargetProductValue = Object.keys(parsedLocationSearch).indexOf('targetProduct') !== -1;
                if (hasTargetProductValue) {
                    this.setState({targetProduct: parsedLocationSearch.targetProduct});
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {catalogue, loading} = this.props;
        const {dataFilterItems, search, bmiCodeFilter} = this.state;

        if (catalogue  && catalogue.length > 0 && ((prevProps.loading && !loading) || dataFilterItems.length === 0)) {
            // Initialise data filter control values
            let dataFilterItems = [{label: messages.allData, action: this.setDataFilter, filter: item => item, value: ''}];
            let uniqueFilters = [];
            catalogue.forEach(item => {
                item.categories.forEach(category => {
                    if (!uniqueFilters.some(filter => category.label === filter)) {
                        uniqueFilters.push(category.label);
                    }
                });
            });
            uniqueFilters.sort();
            uniqueFilters.forEach(filter => dataFilterItems.push({
                label: { id: filter, defaultMessage: filter },
                action: this.setDataFilter,
                filter: item => item.categories.find(c => c.label === filter) !== undefined,
                value: filter
            }));

            let filteredDownloads = catalogue;
            if (bmiCodeFilter) {
                filteredDownloads = this.filterByBmi(catalogue, bmiCodeFilter);
            } else if (search) {
                filteredDownloads = this.filterByName(catalogue, search);
            }
            this.setState({filteredDownloads, dataFilterItems});
        }
    }
    setSearch = search => {
        const {catalogue} = this.props;
        const {dataFilter} = this.state;

        let results = catalogue;

        // Apply any attribute filters to results
        if (dataFilter) {
            results = results.filter(dataFilter)
        }

        if (results) {
            let filteredDownloads = this.filterByName(results, search);
            this.setState({filteredDownloads});
        }
        this.setState({search});
    }

    filterByBmi(catalogue, bmiCodeFilter) {
        return catalogue.filter(product => product.bmiCode === bmiCodeFilter);
    }

    filterByName(catalogue, search) {
        const searchTerms = search.toLowerCase().trim().split(' ');
        return searchTerms.reduce((previous, term) => {
            const results = catalogue.filter(download => {
                return previous.indexOf(download) === -1 && download.label.toLowerCase().indexOf(term) !== -1;
            });
            return previous.concat(results);
        }, []);
    }

    resetSearch = () => {
        const {catalogue} = this.props;
        const newState = {
            search: '',
            dataFilterLabel: messages.allData,
            dataFilter: null,
            dataFilterValue: ''
        };
        if (catalogue) {
            newState.filteredDownloads = catalogue;
        }
        this.setState(newState);
    }

    setDataFilter = item => {
        const {catalogue} = this.props;

        this.setState({
            search: '',
            dataFilterLabel: item.label,
            dataFilter: item.filter,
            dataFilterValue: item.value,
            filteredDownloads: catalogue.filter(item.filter)
        });
    }

    clearDependencyFilter = () => {
        this.setState({targetProduct: null, bmiCodeFilter: null});
        this.resetSearch();
    }

    render() {
        const { classes, loading, catalogue } = this.props;
        const {search, filteredDownloads, targetProduct, bmiCodeFilter, dataFilterValue} = this.state;
        const filteringToShowDependencies = (targetProduct && bmiCodeFilter);

        let controls;
        if (filteringToShowDependencies) {
            if (catalogue) {
                const targetProductLabel = catalogue.find(p => p.id === targetProduct)?.label;
                controls = <div className={classes.dependencyFilter}>
                    {targetProductLabel &&
                        <Typography variant='body1'>
                            <FormattedMessage {...messages.showingDependencies} values={{productLabel: targetProductLabel}}/>
                        </Typography>
                    }
                    <LinkButton className={classes.noticeButton} onClick={this.clearDependencyFilter}>
                        <FormattedMessage {...messages.clearFilter} />
                    </LinkButton>
                    <TotalResultsBar isLoading={loading} totalResults={filteredDownloads.length} />
                </div>
            }
        } else {
            controls = <div className={classes.controls}>
                <SearchBox label={messages.searchLabel}
                    placeholder={messages.searchPlaceholder}
                    search={search}
                    setSearch={this.setSearch}
                    className={classes.searchOption}
                />
                <div className={classes.filterControls}>
                    <DropDownMenu buttonId='dataFilter'
                        buttonProps={{'aria-label': 'Filter by data structure'}}
                        buttonLabel={messages.allData}
                        buttonFontWeight='bold'
                        value={dataFilterValue}
                        buttonVariant='outlined'
                        placement='bottom'
                        buttonClassName={classes.filterControl}
                        items={this.state.dataFilterItems}
                    />
                </div>
                <TotalResultsBar isLoading={loading} totalResults={filteredDownloads.length} />
            </div>
        }
        return <FeatureCheck feature='premium'>
            <header className={classes.root}>
                <Typography variant='h1' color='primary'>
                    <FormattedMessage {...messages.title}/>
                </Typography>
                <Typography variant='body1' color='textPrimary'>
                    <FormattedMessage {...messages.subtitle}/>
                </Typography>

                <Link path={routes.supportDownload} className={classes.faqLinkContainer}>
                    <HelpIcon width={24} height={24} className={classes.icon} aria-hidden='true' />
                    <Typography variant='body1'>
                        <FormattedMessage {...messages.faqLink} />
                    </Typography>
                </Link>
            </header>
            <div className={classes.content}>
                {controls}
                <div className={classes.downloads} aria-live='polite' data-testid={'downloadListContainer'}>
                    {(loading)
                        ? <CircularProgress size={32} className={classes.loader} />
                        : filteredDownloads.map((item) => (
                            <PremiumDataItem
                                item={item}
                                key={item.id}
                                itemType={item.catalogues}
                                path={routes.premiumItem.replace(':productId', item.id)}
                            />
                        ))
                    }
                </div>
            </div>
        </FeatureCheck>;
    }
}

PremiumDownloads.propTypes = {
    getPremiumCatalogue: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

function mapStateToProps(state) {
    const loading = state.premium.catalogue.loading;

    let catalogue = [];
    if (!loading) {
        let catalogueObject = state.premium.catalogue.result;
        if (catalogueObject && catalogueObject.items) {
            catalogue = catalogueObject.items;
        }
    }

    return {
        catalogue,
        loading
    }
}

const withIntl = injectIntl(PremiumDownloads);
const styled = withStyles(styles)(withIntl);
export default withRouter(connect(mapStateToProps, {getPremiumCatalogue})(styled));
