const constants = require("./constants");

function isNgdDataPackage(dataPackage) {
    return dataPackage?.type?.includes('NGD') || dataPackage?.productId === constants.NGDAD_PRODUCT_ID;
}

function isNgdPoweredPremiumDataPackage(dataPackage) {
    return dataPackage?.type === 'NGD_PREMIUM';
}

function isSelectAndBuildDataPackage(dataPackage) {
    return dataPackage?.type === 'NGD';
}

exports.isNgdDataPackage = isNgdDataPackage;
exports.isNgdPoweredPremiumDataPackage = isNgdPoweredPremiumDataPackage;
exports.isSelectAndBuildDataPackage = isSelectAndBuildDataPackage;