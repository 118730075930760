import React from 'react';
import EditableField from "../../../components/EditableField";
import {hasEditPremiumDownloadsPermission} from '../../../util/permissions';
import {setDataPackageName} from "../../../modules/dataPackages/actions";
import {useDispatch, useSelector} from 'react-redux';
import {defineMessages} from 'react-intl';
import {isNgdDataPackage} from "../../../../shared/ngd";

const messages = defineMessages({
    buttonAriaLabel: {
        id: 'DataPackageName.buttonAriaLabel',
        defaultMessage: 'Data package name, click to edit',
        description: 'Label used for the aria-label attribute on the name button'
    },
    iconAriaLabel: {
        id: 'DataPackageName.iconAriaLabel',
        defaultMessage: 'Click to edit the data package name',
        description: 'Label used for the alt text on the edit icon'
    },
    inputAriaLabel: {
        id: 'DataPackageName.inputAriaLabel',
        defaultMessage: 'Data package name',
        description: 'Label used for the aria-label attribute on the name input field'
    },
    maxLength: {
        id: 'DataPackageName.maxLength',
        defaultMessage: 'The data package name must be less than 256 characters long',
        description: 'Label used for error message if the name is too long'
    }
});

export default function DataPackageName({dataPackage}) {
    const user = useSelector(state => state.user.current.result);
    const dispatch = useDispatch();

    const readonly = !hasEditPremiumDownloadsPermission(user) || isNgdDataPackage(dataPackage);

    function setName(name) {
        dispatch(setDataPackageName(dataPackage.id, name));
    }

    return <EditableField fieldValue={dataPackage.name}
                        setFieldValue={setName}
                        readonly={readonly}
                        buttonAriaLabel={messages.buttonAriaLabel}
                        iconAriaLabel={messages.iconAriaLabel}
                        inputAriaLabel={messages.inputAriaLabel}
                        maxLength={255}
                        maxLengthMessage={messages.maxLength}
                        fieldTypeName={"name"}
    />;
}
