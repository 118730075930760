import {NOT_STARTED} from '../../../shared/free-trial-state';
import {defineMessages, FormattedMessage} from 'react-intl';
import React, {useCallback, useState} from 'react';
import {AddButton, CommonDialog, CommonDialogActions} from 'omse-components';
import {useDispatch, useSelector} from 'react-redux';
import {startProductFreeTrial} from '../../modules/products';
import Typography from '@mui/material/Typography';
import {createUseStyles} from 'react-jss';
import routePaths from '../../util/routes';

const messages = defineMessages({
    startFreeTrial: {
        id: 'ProductFreeTrialButton.startFreeTrial',
        defaultMessage: 'Start free trial',
        description: 'Label for the start free trial button'
    },
    dialogTitle: {
        id: 'ProductFreeTrialDialog.dialogTitle',
        defaultMessage: 'Start free trial',
        description: 'Title of the start free trial confirmation dialog'
    },
    dialogMainContent: {
        id: 'ProductFreeTrialDialog.dialogMainContent',
        defaultMessage: 'Are you sure you want to start your {product} 60 days free trial?',
        description: 'Content of the dialog asking a user if they want to start a free trial'
    },
    linkToTrialModeTerms: {
        id: 'ProductFreeTrialMessage.linkToTrialModeTerms',
        defaultMessage: 'By starting this free trial you accept the following <a>terms and conditions</a>.',
        description: 'Text in button accepting start of free trial'
    },
    confirmStartTrialButtonLabel: {
        id: 'ProductFreeTrialMessage.confirmStartTrialButtonLabel',
        defaultMessage: 'Start free trial',
        description: 'Text in button accepting start of free trial'
    },
    cancelStartTrialButtonLabel: {
        id: 'ProductFreeTrialMessage.cancelStartTrialButtonLabel',
        defaultMessage: 'Not yet',
        description: 'Text in button declining start of free trial'
    },
});

const useStyles = createUseStyles(theme => ({
    trialModeTerms: {
        marginBottom: theme.spacing(1)
    }
}));

export default function ProductFreeTrialButton({className, product}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {working} = useSelector(state => state.products.freeTrial);
    const [startFreeTrialDialogOpen, setStartFreeTrialDialogOpen] = useState(false);

    const onClose = useCallback(() => setStartFreeTrialDialogOpen(false), []);
    const onConfirm = useCallback(() => dispatch(startProductFreeTrial(product, product.id)), [dispatch, product]);
    if(product.freeTrialState === NOT_STARTED) {
        const actions = <CommonDialogActions onClose={onClose}
                                             onConfirm={onConfirm}
                                             confirmLabel={messages.confirmStartTrialButtonLabel}
                                             cancelLabel={messages.cancelStartTrialButtonLabel}
                                             working={working}
                                             cancelAllowed={!working} />;
         return <>
             <AddButton className={className}
                        color='secondary'
                        label={messages.startFreeTrial}
                        showIcon={false}
                        working={working}
                        action={() => setStartFreeTrialDialogOpen(true)} />
             {startFreeTrialDialogOpen && <CommonDialog onClose={onClose}
                                                        title={messages.dialogTitle}
                                                        actions={actions}>
                 <Typography variant='body1' className={classes.trialModeTerms}>
                     <FormattedMessage {...messages.dialogMainContent} values={{
                         product: product.name
                     }} />
                 </Typography>
                 <Typography variant='body1'>
                     <FormattedMessage {...messages.linkToTrialModeTerms} values={{
                         // Use a regular anchor tag to open in a new tab.
                         a: chunks => <a href={routePaths.trialModeTerms}  target='_blank' rel='noopener noreferrer'>
                             {chunks}
                         </a>
                     }} />
                 </Typography>
             </CommonDialog>}
       </>;
    } else {
        return null;
    }
}