import {PROJECT_STATS, PROJECT_STATS_LOADED} from "./actions";
import {createLoadingReducer} from 'omse-components';
import {setupMiddleware} from "./statsMiddleware";
import {withOrganisation} from '../../util/organisation';

export const reducer = createLoadingReducer(PROJECT_STATS, PROJECT_STATS_LOADED);

function prepareCall(action, store) {
    return withOrganisation('/api/stats/project/' + action.projectId, store);
}

export const middleware = setupMiddleware(PROJECT_STATS, PROJECT_STATS_LOADED, prepareCall);