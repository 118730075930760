import React, {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import Typography from '@mui/material/Typography';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {border1, contentPadding} from 'omse-components';
import ServiceStatusTable from './ServiceStatusTable';
import Link from '../../components/Link';
import routes from '../../util/routes';
import {ReactComponent as BackwardsArrowIcon} from '../../components/icons/backward-arrow-large.svg';
import {getMessageForApi, getMessageForApiRequest} from './util';
import {getApiServiceStatus} from '../../modules/serviceStatus/actions';
import {useDispatch, useSelector} from 'react-redux';

const messages = defineMessages({
    title: {
        id: 'ServiceStatusApi.title',
        defaultMessage: '{apiName} status last 24 hours',
        description: 'Title for the service status page for a single API'
    },
    backToDashboard: {
        id: 'ServiceStatusApi.backToDashboard',
        defaultMessage: 'Back to dashboard',
        description: 'Text for the link which takes you back to the service status dashboard'
    }
});

const useStyles = createUseStyles(theme => {
    return {
        header: {
            paddingTop: contentPadding.top,
            paddingRight: contentPadding.right,
            paddingBottom: contentPadding.bottom,
            paddingLeft: contentPadding.left,
            borderBottom: border1,
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile
            }
        },
        mainContent: {
            paddingTop: contentPadding.top,
            paddingRight: contentPadding.right,
            paddingBottom: contentPadding.bottom,
            paddingLeft: contentPadding.left,
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile
            }
        },
        dashboardLink: {
            alignItems: 'center'
        },
        backToDashboardText: {
            marginLeft: theme.spacing(1)
        }
    };
});

export default function ServiceStatusApi({match}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();

    // Make request for service status data for this API.
    const {apiId} = match.params;
    useEffect(() => {
        dispatch(getApiServiceStatus(apiId));
    }, [dispatch, apiId]);

    const { result: apiRequestStatusData, loading } = useSelector(state => state.serviceStatus.api.current);
    // Add display components to API data.
    if(apiRequestStatusData) {
        apiRequestStatusData.apiRequestStatuses.forEach(request => {
            const message = getMessageForApiRequest(request.id);
            if(message) {
                request.component = <FormattedMessage {...message} />;
            } else {
                // Default to the name we get back from the server.
                request.component = request.id;
                console.warn(`No localisation has been defined for service status request: ${request.id}`);
            }
        });
    }

    return <div>
        <Typography className={classes.header}
                    variant='h1'
                    color='primary'>
            <FormattedMessage {...messages.title} values={{apiName: intl.formatMessage(getMessageForApi(apiId))}} />
        </Typography>
        <div className={classes.mainContent}>
            <ServiceStatusTable title={<Link className={classes.dashboardLink} path={routes.serviceStatus}>
                                    <BackwardsArrowIcon width={15} height={15} />
                                    <Typography className={classes.backToDashboardText} variant='body1'>
                                        <FormattedMessage {...messages.backToDashboard} />
                                    </Typography>
                                </Link>}
                                showDegradedIcon={false}
                                loading={loading}
                                data={apiRequestStatusData?.apiRequestStatuses}
                                lastTimeReceived={apiRequestStatusData?.lastTimeReceived} />
        </div>
    </div>
}