import React, {useEffect, Fragment} from 'react';
import InputLabel from "@mui/material/InputLabel";
import {FormattedMessage, useIntl} from "react-intl";
import {FormControlLabel, RadioGroup} from "@mui/material";
import ClickAwayTooltip from "../../../../components/ClickAwayTooltip";
import {ReactComponent as HelpIcon} from "../../../../components/icons/help-notification.svg";
import {FieldError, osColour, RadioButton} from "omse-components";
import {defineMessages} from "react-intl";
import {useSelector, useDispatch} from "react-redux";
import {updateDraftOrder} from '../../../../modules/dataPackages/actions';
import {createUseStyles} from 'react-jss';
import Typography from "@mui/material/Typography";
import {useAddDataPackageContext} from '../AddDataPackage';

const messages = defineMessages({
    chooseArea: {
        id: 'ChooseAreaRadio.chooseArea',
        defaultMessage: 'Choose your area',
        description: 'chooseArea label'
    },
    gbInfo: {
        id: 'ChooseAreaRadio.gbInfo',
        defaultMessage: 'Great Britain data only unless you’ve selected Islands which includes Northern Ireland.',
        description: 'All of Britain or Predefined Area tooltip'
    },
    polygonInfo: {
        id: 'ChooseAreaRadio.polygonInfo',
        defaultMessage: 'Use our drawing tools to create a polygon, upload your own file or choose a polygon from our library.',
        description: 'polygon info tooltip'
    },
    areaSelectionNeeded: {
        id: 'ChooseAreaRadio.areaSelectionNeeded',
        defaultMessage: 'Please choose an area on the map',
        description: 'Area selection needed error message'
    },
    tileSelectionNeeded: {
        id: 'ChooseAreaRadio.tileSelectionNeeded',
        defaultMessage: 'Please choose a tiled area on the map',
        description: 'Tile selection needed error message'
    },
    gbHelpButtonAccessibleLabel: {
        id: 'ChooseAreaRadio.gbHelpButtonAccessibleLabel',
        defaultMessage: 'All of Britain or Predefined Area extra information',
        description: 'All of Britain or Predefined Area selection help button accessible label'
    },
    polygonHelpButtonAccessibleLabel: {
        id: 'ChooseAreaRadio.polygonHelpButtonAccessibleLabel',
        defaultMessage: 'Draw polygon extra information',
        description: 'Polygon selection help button accessible label'
    },
});

const styles = createUseStyles((theme) => ({
    chooseArea: {
        display: 'block',
        color: osColour.neutral.charcoal,
        marginLeft: theme.spacing(1)
    },
    chooseAreaLabel: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        margin: theme.spacing(1)
    },
    gbRadioButton: {
        width: 371
    },
    polygonRadioButton: {
        width: 371
    },
    radioButtonOption: {
        display: 'flex',
        alignItems: 'center'
    },
    iconDisabled: {
        background: 'rgba(206,217,224,.5)'
    },
    disabled: {
        color: osColour.neutral.stone
    },
    popper: {
        width: 232,
        lineHeight: 1.5,
        zIndex: 1
    },
    toolTip: {
        height: 24
    }
}))

function MyRadioButton(props) {
    const {option, classes, area, disabled} = props;

    let variant = 'body1';
    if(area === option.id) {
        variant = 'body2';
    }

    return (
        <FormControlLabel key={option.id} value={option.id}
                          control={<RadioButton disabled={disabled} classes={{icon: option.disabled || disabled ? classes.iconDisabled : classes.enabled}}/>}
                          label={
                              <Typography variant={variant} className={option.disabled || disabled ? classes.disabled : classes.enabled}>
                                  <FormattedMessage {...option.label} values = {option.values}/>
                              </Typography>
                          }
        />
    )
}


export default function ChooseAreaRadio(props) {
    const {areaRadioOptions, disabled} = props;
    const intl = useIntl();
    const {setShowInvalidOptionDialog} = useAddDataPackageContext();

    const dispatch = useDispatch();
    const area = useSelector((state) => state.dataPackages.draftOrder.area) || '';
    const {displayErrors, AOI, tiles, tiled} = useSelector((state) => state.dataPackages.draftOrder);
    const classes = styles();

    useEffect(() => {
        if (areaRadioOptions && areaRadioOptions.length > 0) {
            let areaSelected;

            if (area) {
                areaSelected = areaRadioOptions.find(option => option.id === area)
                if (areaSelected.disabled) {
                    areaSelected = null;
                }
            }

            if (!areaSelected) {
                let firstValidOption = areaRadioOptions.find(option => !option.disabled);
                dispatch(updateDraftOrder('area', firstValidOption.id));
            }
        }
    }, [dispatch, areaRadioOptions, area])

    function handleAreaChange(event) {
        const selectedValue = event.target.value;

        let selectedOption = areaRadioOptions.find(area => area.id === selectedValue);
        if (selectedOption && selectedOption.disabled) {
            setShowInvalidOptionDialog(true);
            return;
        }

        dispatch(updateDraftOrder('area', selectedValue));
    }

    function errorToDisplay () {
        let errorMessage;

        if (displayErrors && area === 'Polygon' && !AOI) {
            errorMessage = messages.areaSelectionNeeded;
        } else if (displayErrors && area === 'Polygon' && tiled && (!tiles || !tiles[0])) {
            errorMessage = messages.tileSelectionNeeded;
        }

        return <FieldError error={errorMessage} />
    }

    return <Fragment>
        <InputLabel className={classes.chooseAreaLabel}>
            <FormattedMessage {...messages.chooseArea}/>
        </InputLabel>
        {
            areaRadioOptions && areaRadioOptions.length > 0 &&
            <RadioGroup name='areaRadioGroup'
                        className={classes.chooseArea}
                        value={area}
                        onChange={handleAreaChange}>
                {
                    areaRadioOptions.map(option => {
                        if (option.id === 'GB')
                        return <span key={option.id} className={classes.radioButtonOption}>
                            <span
                                className={classes.gbRadioButton}>
                                <MyRadioButton option={option} disabled={disabled}
                                               classes={classes}
                                               area={area}/>
                            </span>
                            <ClickAwayTooltip
                                id='chooseAreaTip'
                                icon={<HelpIcon width={24} height={24}
                                                style={{
                                                    color: option.disabled || disabled ? osColour.neutral.rock : osColour.primary.berry
                                                }}/>}
                                ariaLabel={intl.formatMessage(messages.gbHelpButtonAccessibleLabel)}
                                body={messages.gbInfo}
                                disabled={option.disabled || disabled}
                                classes={{
                                    clickAwayTooltip: classes.toolTip,
                                    popper: classes.popper
                                }}
                            />
                        </span>
                        if (option.id === 'Polygon')
                            return <span key={option.id} className={classes.radioButtonOption}>
                                <span
                                    className={classes.polygonRadioButton}>
                                    <MyRadioButton option={option} disabled={disabled}
                                                   classes={classes}
                                                   area={area}/>
                                </span>
                                <ClickAwayTooltip
                                    id='chooseAreaTip'
                                    icon={<HelpIcon width={24} height={24}
                                                    style={{
                                                        color: option.disabled || disabled ? osColour.neutral.rock : osColour.primary.berry
                                                    }}/>}
                                    ariaLabel={intl.formatMessage(messages.polygonHelpButtonAccessibleLabel)}
                                    body={messages.polygonInfo}
                                    disabled={option.disabled || disabled}
                                    classes={{
                                        clickAwayTooltip: classes.toolTip,
                                        popper: classes.popper
                                    }}
                                />
                            </span>
                        else
                            return <MyRadioButton option={option} disabled={disabled}
                                                  key={option.id}
                                                  classes={classes}
                                                  area={area}/>
                    })
                }
                { errorToDisplay() }
            </RadioGroup>
        }
    </Fragment>
}