import React from 'react';
import LoadPolygonModal from "./LoadPolygonModal";

const LOAD_POLYGON_TYPE = 'load';

export default function LoadPolygonAction(map, layers) {
    const {drawingLayer, currentPolygonLayer} = layers;
    const component = <LoadPolygonModal drawingLayer={drawingLayer} map={map} currentPolygonLayer={currentPolygonLayer}/>;

    return {
        type: LOAD_POLYGON_TYPE,
        component
    };
}

LoadPolygonAction.type = 'load';