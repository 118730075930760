import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages} from "react-intl";
import ItemTag from "./tag/ItemTag";
import {CATALOGUES} from "../../shared/catalogues";
import withStyles from 'react-jss';

const messages = defineMessages({
    // Pseudo tags
    open: {
        id: 'CatalogueTag.open',
        defaultMessage: 'Free OpenData',
        description: 'OpenData tag'
    },
    osopen: { // Used to avoid regression of the tag on the homepage.
        id: 'CatalogueTag.osopen',
        defaultMessage: 'Free OS OpenData',
        description: 'OpenData tag'
    },
    prem: {
        id: 'CatalogueTag.premium',
        defaultMessage: 'Premium',
        description: 'Premium Data tag'
    },

    // Premium data tags
    psga: {
        id: 'CatalogueTag.psga',
        defaultMessage: 'Public Sector',
        description: 'Public Sector tag'
    },
    part: {
        id: 'CatalogueTag.part',
        defaultMessage: 'Partner',
        description: 'Partner tag'
    },
    com3: {
        id: 'CatalogueTag.com3',
        defaultMessage: 'Energy & Infrastructure',
        description: 'Consumer / Energy & Infrastructure tag'
    }
});

const colours = {
    open: 'success',
    prem: 'berry',
    psga: 'berry',
    part: 'foxglove',
    com3: 'berry'
};

const styles = theme => ({
    tag: {
        alignSelf: 'auto',
        marginTop: 5,
        marginBottom: 1,
        [theme.breakpoints.down('sm')]: {
            marginTop: 2,
            marginBottom: 2
        }
    },
});

export function CatalogueTag(props) {
    const {type, size, classes} = props;
    const catalogue = type.toLowerCase();
    let colour = colours[catalogue] || colours.open;
    let label = messages[catalogue] || messages.open;

    return <ItemTag colour={colour} label={label} size={size} classes={classes}/>;
}

CatalogueTag.propTypes = {
    type: PropTypes.oneOf(CATALOGUES),
    size: PropTypes.oneOf(['sm', 'md']),
    classes: PropTypes.object // Allows the ItemTag styles to be overridden
};

export default withStyles(styles) (CatalogueTag);