import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from "@mui/material";
import Link from "../../../components/Link";
import routePaths from "../../../util/routes";
import {createUseStyles} from 'react-jss';
import {osColour, contentPadding} from 'omse-components';
import { ReactComponent as InfoIcon } from '../../../components/icons/info-notification.svg';
import ClickAwayTooltip from '../../../components/ClickAwayTooltip';
import { defineMessages, FormattedMessage } from "react-intl";

const useStyles = createUseStyles(theme => ({
    bordered:{
        padding: contentPadding.mobile,
        borderTop:`1px solid ${osColour.neutral.rock}`,
        borderBottom:`1px solid ${osColour.neutral.rock}`,
    },
    shortened: {
        display:"inline-block"
    },
    valueText:{
        fontSize:30
    },
    icon: {
        width: 20,
        height: 20,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        color: osColour.primary.berry
    }
}));

const messages = defineMessages({
    planType:{
        id:"apiPlans.orderSummary.planType",
        defaultMessage: "Plan type: {tierLabel} {tooltip}",
        description: "The order summary plan type"
    },
    unitPrice:{
        id:"apiPlans.orderSummary.unitPrice",
        defaultMessage: "Transactions are indicative based on {apiName} / £{tierUnitPrice} per transaction",
        description: "The unit price message"
    },
    viewApiPlans: {
        id:"apiPlans.orderSummary.viewApiPlans",
        defaultMessage:"See our  API breakdown and pricing guide",
        description: "Order summary link text"
    }
})

export default function OrderSummary(props) {
    let {value, tier, tooltipBody, apiName} = props;
    const classes = useStyles();

    let tooltip = null;
    if(tooltipBody){
        tooltip = (<ClickAwayTooltip placement="top" body={tooltipBody} icon={<InfoIcon aria-label='api purchasing info' className={classes.icon}/>} id="pricing-tooltip"/>);
    }
    const tierLabel = tier ? tier.name : '';

    return (
        <Box className={classes.orderSummary}>
            <Typography variant="h2">Order Summary</Typography>
            <Box className={classes.shortened}>
                <Typography variant="caption">
                    <FormattedMessage {...messages.planType} values={{tierLabel: tierLabel, tooltip}}/>
                </Typography>
            </Box>
            <Box className={classes.bordered}>
                {tier &&
                    <Typography variant="body1">
                        <FormattedMessage {...messages.unitPrice}
                                          values={{ tierUnitPrice: tier.unitPrice, apiName: apiName }}/>
                    </Typography>
                }
                <Link path={routePaths.pricingApiPlans}>
                    <Typography variant="body1">
                        <FormattedMessage {...messages.viewApiPlans} />
                    </Typography>
                </Link>
            </Box>
            <Box>
                <Typography variant="h3" className={classes.valueText}>£{value.toLocaleString()}<Typography component="span" variant="body1"> GBP</Typography></Typography>
            </Box>
        </Box>
    )
}

OrderSummary.propTypes = {
    value:PropTypes.number,
    tier:PropTypes.object,
    apiName: PropTypes.string,
    tooltipBody: PropTypes.element
}

OrderSummary.defaultProps = {
    value:0,
    apiName:"API product"
}
