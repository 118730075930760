import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import LineGraph from "../../components/LineGraph";
import {defineMessages, injectIntl, FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import withStyles from 'react-jss';
import {osColour} from 'omse-components';
import {connect} from "react-redux";
import _forOwn from 'lodash/forOwn';
import {USER_OS_INTERNAL_PLAN, USER_PREMIUM_DATA_PLAN, USER_PSGA_PLAN} from '../../../shared/plans';

import classNames from 'classnames';
import stripesSvg from '../../components/icons/stripes.svg';
import {DEV_MODE} from '../../../shared/project-modes';
import {loadStripes} from '../../util/canvas';
import {isOpenDataPersonal} from '../../util/organisation';
import { isEaiUser } from "../../util/plans";

const messages = defineMessages({
    openData: {
        id: 'HistoryGraph.openData',
        defaultMessage: 'OS OpenData',
        description: 'OS OpenData label, used for the transaction graph'
    },
    psga: {
        id: 'HistoryGraph.psga',
        defaultMessage: 'Public Sector',
        description: 'Public sector data label'
    },
    premiumFree: {
        id: 'HistoryGraph.premiumFree',
        defaultMessage: 'Free Premium',
        description: 'Premium free label'
    },
    premiumChargeable: {
        id: 'HistoryGraph.premiumChargeable',
        defaultMessage: 'Costed Premium',
        description: 'Premium paid label'
    },
    devPremium: {
        id: 'HistoryGraph.devPremium',
        defaultMessage: 'Dev Premium',
        description: 'Dev Premium label'
    },
    commercial: {
        id: "HistoryGraph.commercial",
        defaultMessage: "OS Energy & Infrastructure",
        description: "Commercial label"
    },
    commercialDev:{
        id: "HistoryGraph.commercialDev",
        defaultMessage: "Dev Premium",
        description: "Commercial  devlabel"
    },
    psgaData: {
        id: 'HistoryGraph.psgaData',
        defaultMessage: 'Public Sector data',
        description: 'Public sector data label'
    },
    premiumFreeData: {
        id: 'HistoryGraph.premiumFreeData',
        defaultMessage: 'Free Premium data',
        description: 'Premium free data label'
    },
    premiumChargeableData: {
        id: 'HistoryGraph.premiumChargeableData',
        defaultMessage: 'Costed Premium data',
        description: 'Premium paid data label'
    },
    devPremiumData: {
        id: 'HistoryGraph.devPremiumData',
        defaultMessage: 'Dev Premium data',
        description: 'Dev Premium data label'
    },
    commercialData:{
        id: "HistoryGraph.commercialData",
        defaultMessage: "OS Energy & Infrastructure",
        description: "Commercial data label"
    },
    commercialDevData:{
        id: "HistoryGraph.commercialDevData",
        defaultMessage: "Dev premium",
        description: "Commercial dev data label"
    },
    internalData: {
        id: 'HistoryGraph.internalData',
        defaultMessage: 'OS Internal',
        description: 'OS Internal data label'
    },
    total: {
        id: 'HistoryGraph.total',
        defaultMessage: '{total, number}',
        description: 'Total for the graph'
    }
});

const openColour = osColour.primary.berry;
const openFill = alpha(openColour, 0.2);

const premiumColour = osColour.primary.foxglove;
const premiumFill = alpha(premiumColour, 0.2);

const premiumChargeableLineColour = '#D40058';

const premiumFreeSecondaryColour = '#757575';
const premiumFreeSecondaryFill = alpha(premiumFreeSecondaryColour, 0.2);

const psgaColour = osColour.primary.foxglove;
const psgaFill = alpha(psgaColour, 0.2);

const internalColour = osColour.primary.foxglove;
const internalFill = alpha(premiumColour, 0.2);

const commercialColour = osColour.primary.foxglove;
const commercialFill = alpha(premiumColour, 0.2);

const styles = theme => ({
    contentGraph: {
        marginTop: theme.spacing(4),
        paddingBottom: theme.spacing(2)
    },
    legend: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(0.5)
    },
    key: {
        height: theme.spacing(2),
        width: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    chartLabel: {
        display: 'flex',
        alignItems: 'center'
    },
    openLegend: {
        backgroundColor: openColour
    },
    premiumTotalLegend: {
        backgroundColor: premiumColour
    },
    premiumFreeLegend: {
        backgroundColor: premiumColour
    },
    premiumChargeableLegend: {
        backgroundImage: `url(${stripesSvg})`
    },
    psgaLegend: {
        backgroundColor: psgaColour
    },
    premiumFreeSecondaryLegend: {
        backgroundColor: premiumFreeSecondaryColour
    },
    internalLegend: {
        backgroundColor: premiumColour
    },
    commercialLegend:{
        backgroundColor:commercialColour
    },
    total: {
        marginBottom: theme.spacing(1.5),
        color: osColour.neutral.stone
    },
    graphTitle: {
        color: osColour.neutral.stone
    },
    graphHeader: {
        display: 'flex'
    },
    secondaryInfo: {
        flexGrow: 1,
        paddingTop: theme.spacing(0.5),
        marginRight: theme.spacing(2)
    }
});

export class HistoryGraph extends Component {

    state = {};

    componentDidMount() {
        const {stripes} = this.state;
        if (!stripes) {
            loadStripes(result => this.setState({stripes: result}), 0.2);
        }
    }

    formatDate(timestamp) {
        const {interval, intl} = this.props;
        if (interval !== 'year') {
            return intl.formatDate(timestamp, {weekday: 'short', day: 'numeric', month: 'short'});
        }
        return intl.formatDate(timestamp, {month: 'short', year: 'numeric'});
    }


    hideDatasetIfNoValues(datasets, transactionType) {
        if(datasets[transactionType]) {
            const list = datasets[transactionType].data;
            let allZeros = true;
            for (let i = 0; i < list.length; i++) {
                if (list[i] !== 0) {
                    allZeros = false;
                    break;
                }
            }
            datasets[transactionType].hidden = allZeros;
        }
    }

    render() {
        const {classes, graphMode, historyStats, intl, graphTitle, graphTooltip, userDetails, org} = this.props;
        const {stripes} = this.state;

        const labels = [];
        const chartLabels = [];
        let total = 0;
        let graphDataFunction;

        if (historyStats) {
            const datasets = this.getAllDataSets(intl, messages, classes);

            if (graphMode === DEV_MODE) {
                delete datasets.premiumFree;
                delete datasets.premiumChargeable;
            } else {
                delete datasets.premiumTotal;
            }

            const allValueFunctions = this.getAllValueFunctions(messages, classes);
            historyStats.forEach(entry => {
                _forOwn(datasets, (dataset, transactionType) => {
                    const valueFunction = allValueFunctions[transactionType];
                    const value = valueFunction(entry);
                    dataset.data.push(value);
                    total += value;
                });
                labels.push(this.formatDate(entry.timestamp));
            });

            // Show all labels that are needed by the current plan. This is because for the current plan it is
            // useful information seeing that there are zero entries. Also show labels for any non-zero historic data
            // within the timeframe. This is because they used that kind of data in the past so we should show them it.
            if (userDetails.plan !== USER_PREMIUM_DATA_PLAN || isOpenDataPersonal(userDetails, org)) {
                this.hideDatasetIfNoValues(datasets, 'premiumFree');
                this.hideDatasetIfNoValues(datasets, 'premiumChargeable');
                this.hideDatasetIfNoValues(datasets, 'premiumTotal');
            }
            if (userDetails.plan !== USER_PSGA_PLAN || isOpenDataPersonal(userDetails, org)) {
                this.hideDatasetIfNoValues(datasets, 'psga');
            }
            if (userDetails.plan !== USER_OS_INTERNAL_PLAN || isOpenDataPersonal(userDetails, org)) {
                this.hideDatasetIfNoValues(datasets, 'internal');
            }
            if(!isEaiUser(userDetails) || isOpenDataPersonal(userDetails, org)){
                this.hideDatasetIfNoValues(datasets, 'commercial');
            }

            const graphData = {
                labels,
                datasets: []
            };

            const allChartLabels = this.getAllChartLabels(messages, classes);

            // psga and premiumFree both want to use same colour. This is okay as normally only one of these is shown.
            // If both need to be displayed at the same time then change the colour for premiumFree.
            if (!datasets.psga.hidden && !datasets.premiumFree.hidden) {
                datasets.premiumFree.backgroundColor = datasets.premiumFree.pointBorderColor = premiumFreeSecondaryFill;
                datasets.premiumFree.borderColor = datasets.premiumFree.pointBackgroundColor = premiumFreeSecondaryColour;
                datasets.premiumFree.keyClass = allChartLabels.premiumFree.labelClass = classes.premiumFreeSecondaryLegend;
            }

            _forOwn(datasets, (dataset, transactionType) => {
                // We need the hidden datasets as part of the graph data so they are there ready for the situation
                // where we switch to a different time frame (e.g. year) and the previously hidden dataset now has data.
                // Without passing in the hidden datasets the tooltips end up without figures when you switch time
                // frame.
                graphData.datasets.push(dataset);
                if (!dataset.hidden) {
                    // We only need the chart labels for the unhidden datasets as these will update when we switch timeframe.
                    chartLabels.push(allChartLabels[transactionType]);
                }
            });

            graphDataFunction = ((canvas) => {
                if (datasets.premiumChargeable !== undefined) {
                    let fill = osColour.primary.foxglove;
                    if (canvas) {
                        const ctx = canvas.getContext("2d");
                        if (stripes) {
                            fill = ctx.createPattern(stripes, 'repeat');
                        }
                    }
                    datasets.premiumChargeable.backgroundColor = fill;
                }
                return graphData;
            });
        }

        return <div className={classes.contentGraph}>
            <div className={classes.graphHeader}>
                <div className={classes.primaryInfo}>
                    <Typography variant='body2' className={classes.graphTitle}>
                        <FormattedMessage {...graphTitle} />
                    </Typography>
                    <Typography variant='h2' component='div' className={classes.total}>
                        <FormattedMessage {...messages.total} values={{total}}/>
                    </Typography>
                </div>
                {graphTooltip &&
                <div className={classes.secondaryInfo}>
                    {graphTooltip}
                </div>}
            </div>
            {graphDataFunction && <Fragment>
                <LineGraph data={graphDataFunction}/>
                <div className={classes.legend}>
                    {chartLabels.map(chartLabel =>
                        <div key={chartLabel.label.id} className={classes.chartLabel}>
                            <div className={classNames(classes.key, chartLabel.labelClass)}/>
                            <Typography variant='body1'>
                                <FormattedMessage {...chartLabel.label} />
                            </Typography>
                        </div>)}
                </div>
            </Fragment>}
        </div>
    }

    /**
     * Dataset labels:
     * 
     *   label: ...         // Unique identifier used by chartjs.
     *   displayLabel: ...  // Label shown in the tooltip.
     *   ... 
     */
    getAllDataSets(intl, messages, classes) {
        const {graphMode} = this.props;
        return {
            open: {
                label: 'open',
                displayLabel: intl.formatMessage(messages.openData),
                backgroundColor: openFill,
                borderColor: openColour,
                pointBackgroundColor: openColour,
                pointBorderColor: openColour,
                data: [],
                keyClass: classes.openLegend
            },
            psga: {
                label: 'psga',
                displayLabel: intl.formatMessage(messages.psga),
                backgroundColor: psgaFill,
                borderColor: psgaColour,
                pointBackgroundColor: psgaColour,
                pointBorderColor: psgaColour,
                data: [],
                keyClass: classes.psgaLegend
            },
            premiumFree: {
                label: "premiumFree",
                displayLabel: intl.formatMessage(messages.premiumFree),
                backgroundColor: premiumFill,
                borderColor: premiumColour,
                pointBackgroundColor: premiumColour,
                pointBorderColor: premiumColour,
                data: [],
                keyClass: classes.premiumFreeLegend
            },
            premiumChargeable: {
                label: 'premiumChargeable',
                displayLabel: intl.formatMessage(messages.premiumChargeable),
                borderColor: premiumChargeableLineColour,
                borderDash: [5, 2],
                borderWidth: 4,
                pointBackgroundColor: premiumChargeableLineColour,
                pointBorderColor: premiumChargeableLineColour,
                pointRadius: 1.5,
                data: [],
                keyClass: classes.premiumChargeableLegend
            },
            premiumTotal: {
                label: 'premiumTotal',
                displayLabel: intl.formatMessage(messages.devPremium),
                backgroundColor: premiumFill,
                borderColor: premiumColour,
                pointBackgroundColor: premiumColour,
                pointBorderColor: premiumColour,
                data: [],
                keyClass: classes.premiumTotalLegend
            },
            commercial: {
                label: 'commercial',
                displayLabel: intl.formatMessage(graphMode===DEV_MODE ? messages.commercialDev : messages.commercial),
                backgroundColor: commercialFill,
                borderColor: commercialColour,
                pointBackgroundColor: premiumColour,
                pointBorderColor: commercialColour,
                data:[],
                keyClass: classes.commercialLegend
            },
            internal: {
                label: 'internal',
                displayLabel: intl.formatMessage(messages.internalData),
                backgroundColor: internalFill,
                borderColor: internalColour,
                pointBackgroundColor: internalColour,
                pointBorderColor: internalColour,
                data: [],
                keyClass: classes.internalLegend
            }
        };
    }

    /**
     * Chart legend labels: May differ from dataset displayLabel by including a "data" suffix.
     */
    getAllChartLabels(messages, classes) {
        const {graphMode} = this.props;
        return {
            open: {
                label: messages.openData,
                labelClass: classes.openLegend,
            },
            psga: {
                label: messages.psgaData,
                labelClass: classes.psgaLegend
            },
            premiumFree: {
                label: messages.premiumFreeData,
                labelClass: classes.premiumFreeLegend
            },
            premiumChargeable: {
                label: messages.premiumChargeableData,
                labelClass: classes.premiumChargeableLegend
            },
            premiumTotal: {
                label: messages.devPremiumData,
                labelClass: classes.premiumTotalLegend
            },
            commercial: {
                label: graphMode===DEV_MODE ? messages.commercialDevData : messages.commercialData,
                labelClass: classes.commercialLegend
            },
            internal: {
                label: messages.internalData,
                labelClass: classes.internalLegend
            }
        };
    }

    getAllValueFunctions() {
        return {
            open: entry => entry.open,
            psga: entry => entry.psga,
            premiumFree: entry => entry.premium.free,
            premiumChargeable: entry => entry.premium.chargeable,
            premiumTotal: entry => entry.premium.total,
            internal: entry => entry.internal,
            commercial: entry =>entry.commercial
        };
    }
}

HistoryGraph.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    historyStats: PropTypes.array,
    graphTitle: PropTypes.object.isRequired,
    graphTooltip: PropTypes.object,
    graphMode: PropTypes.string,
    userDetails: PropTypes.object.isRequired,
    interval: PropTypes.oneOf(['week', 'month', 'year'])
};

HistoryGraph.defaultProps = {
    graphMode: DEV_MODE
};

const mapStateToProps = state => {
    const { result } = state.user.current;
    const org = state.organisation.current;
    return {
        userDetails: result,
        org
    }
};
const styled = withStyles(styles)(HistoryGraph);
export default connect(mapStateToProps)(injectIntl(styled));
