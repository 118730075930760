import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import withStyles from 'react-jss';
import { DialogContent, DialogTitle, Dialog, Typography, Button } from '@mui/material';
import {Notification, osColour, InputBox, InputLabel, linkButton} from 'omse-components';
import InteractiveGridMap from './InteractiveGridMap';
import GridChip from './customAreaDialog/GridChip';
import ClickAwayTooltip from '../../components/ClickAwayTooltip';
import {ReactComponent as HelpIcon} from '../../components/icons/help-notification.svg';
import logo from '../../components/images/ordnance-survey-logo.svg';
import expandIcon from '../../components/icons/expand.svg';

const messages = defineMessages({
    title: {
        id: 'CustomAreaDialog.title',
        defaultMessage: 'Build your custom area by selecting map tiles',
        description: 'Dialog title'
    },
    cancel: {
        id: 'CustomAreaDialog.cancel',
        defaultMessage: 'Cancel',
        description: 'Cancel label'
    },
    save: {
        id: 'CustomAreaDialog.save',
        defaultMessage: 'Save selection',
        description: 'Save label'
    },
    expand: {
        id: 'CustomAreaDialog.expandCollapseForm',
        defaultMessage: 'Expand the form',
        description: 'Expand form'
    },
    collapse: {
        id: 'CustomAreaDialog.collapse',
        defaultMessage: 'Collapse the form',
        description: 'Collapse label'
    },
    clearAll: {
        id: 'CustomAreaDialog.clearAll',
        defaultMessage: 'Clear selection',
        description: 'Clear all label'
    },
    selectAll: {
        id: 'CustomAreaDialog.selectAll',
        defaultMessage: 'Select all',
        description: 'Select all label'
    },
    enterGridRefs: {
        id: 'CustomAreaDialog.enterGridRefs',
        defaultMessage: 'Select tiles by reference',
        description: 'label for map tile input'
    },
    gridInputPrompt: {
        id: 'CustomAreaDialog.gridInputPrompt',
        defaultMessage: 'Enter map tile references',
        description: 'map tile input prompt'
    },
    gridInputTip: {
        id: 'CustomAreaDialog.gridInputTip',
        defaultMessage: 'Map tile references may be separated by spaces or commas to add more than one at a time.',
        description: 'map tile input tip'
    },
    addGridItemError: {
        id: 'CustomAreaDialog.addGridItemError',
        defaultMessage: 'Invalid map tile reference(s).',
        description: 'Text to display if there is an error selecting an entered map tile ref'
    },
    add: {
        id: 'CustomAreaDialog.add',
        defaultMessage: 'Add',
        description: 'Add label'
    },
    availableGridRefs: {
        id: 'CustomAreaDialog.availableGridRefs',
        defaultMessage: 'Available map tile references',
        description: 'Title for available map tile refs'
    },
    showAvailableGridRefs: {
        id: 'CustomAreaDialog.showAvailableGridRefs',
        defaultMessage: 'List references',
        description: 'Label to show available references'
    },
    hideAvailableGridRefs: {
        id: 'CustomAreaDialog.hideAvailableGridRefs',
        defaultMessage: 'Close list',
        description: 'Label to hide available refs'
    },
    selected: {
        id: 'CustomAreaDialog.selected',
        defaultMessage: '{data, plural, one {# map tile} other {# map tiles}} selected',
        description: 'Label for the selected expansion list'
    },
    helpTip: {
        id: 'CustomAreaDialog.helpTip',
        defaultMessage: 'Grid input help',
        description: 'Label help tip'
    }
});

const styles = theme => ({
    title: {
        background: osColour.neutral.white,
        padding: theme.spacing(3),
        boxShadow: '3px 3px 5px 0 rgba(0, 0, 0, 0.2)',
        overflowY: 'auto',
        zIndex: 50,
        '@media (orientation: landscape)': {
            width: 355,
            padding: '24px 24px 0 24px'
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        }
    },
    dialogContent: {
        padding: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'relative',
        display: 'flex',
        '@media (orientation: portrait)': {
            minHeight: 250
        }
    },
    titleText: {
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(2)
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
            lineHeight: 1.2
        }
    },
    actions: {
        display: 'flex',
        height: 40,
        flex: '0 0 auto',
        '& button': {
            flex: '0 0 auto',
            whiteSpace: 'nowrap'
        },
        '& button:first-child': {
            marginRight: theme.spacing(1.5)
        },
        '@media (orientation: landscape)': {
            justifyContent: 'flex-end',
            paddingBottom: theme.spacing(2)
        },
        '@media (orientation: portrait)': {
            marginTop: theme.spacing(1)
        }
    },
    squaresActions: {
        display: 'flex',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(0.5)
    },
    chipsContainer: {
        '@media (orientation: landscape)': {
            marginBottom: theme.spacing(2)
        },
        '@media (orientation: portrait)': {
            overflowY: 'auto',
            paddingLeft: 3,
            position: 'relative',
            maxHeight: 86,
            '& > div': {
                marginBottom: 8
            }
        }
    },
    dialogPaper: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        flexDirection: 'column',
        borderTop: 'none',
        '@media (orientation: landscape)': {
            flexDirection: 'row'
        }
    },
    gridSquareInput: {
        flexGrow: 2,
        display: 'flex',
        flexDirection: 'column'
    },
    textLinkButton: {
        ...linkButton,
        flex: '1 0 auto',
        marginRight: theme.spacing(2),
        '&:last-of-type': {
            marginRight: 0
        }
    },
    inputRow: {
        display: 'flex',
        alignItems: 'flex-start',
        '& > div': {
            flex: '1 1 auto'
        },
        '& button': {
            marginBottom: theme.spacing(1)
        }
    },
    gridInputBefore: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    gridInput: {
        display: 'flex',
        flex: '1 0 auto',
        minWidth: 'unset',
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        background: osColour.neutral.clouds,
        fontStyle: 'italic'
    },
    gridInputOptions: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    fieldLabel: {
        display: 'flex',
        flex: '1 0 auto',
        alignItems: 'center',
        margin: 0,
        padding: 0
    },
    tooltip: {
        float: 'unset',
        display: 'flex',
        marginLeft: theme.spacing(0.5)
    },
    tooltipIcon: {
        color: osColour.neutral.rock
    },
    inputLabel: {
        padding: 0,
        margin: 0
    },
    availableRefsButton: {
        paddingRight: 0,
        flex: '0 0 auto'
    },
    availableRefs: {
        marginBottom: theme.spacing(1.5),
        padding: theme.spacing(1),
        border: 'solid 2px ' + osColour.neutral.stone
    },
    controlAnchor: {
        background: osColour.neutral.white,
        position: 'absolute',
        zIndex: 1,
        top: 0,
        boxShadow: '3px 3px 5px 0 rgba(0, 0, 0, 0.2)',
        '& button': {
            minWidth: 'auto'
        },
        '@media (orientation: landscape)': {
            top: theme.spacing(1),
            borderRadius: '0 3px 3px 0',
            '& button': {
                padding: theme.spacing(2) + ' ' + theme.spacing(0.75)
            }
        },
        '@media (orientation: portrait)': {
            left: theme.spacing(1),
            borderRadius: '0 0 3px 3px',
            '& button': {
                padding: theme.spacing(0.75) + ' ' + theme.spacing(2)
            }
        }
    },
    selectButton: {
        display: 'block',
        flex: '0 0 auto'
    },    
    logo: {
        pointerEvents: 'none',
		position: 'fixed',
        bottom: 0,
        padding: theme.spacing(1.5) + ' '+ theme.spacing(2.5),
        '@media (orientation: portrait)': {
            left: 60
        }
    },
    selectionSummary: {
        marginLeft: theme.spacing(1.5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: '1 1 auto',
        textAlign: 'right'
    },    
    titleContent: {
        '@media (orientation: landscape)': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between'
        }
    },
    startForm: {},
    collapsedDialogTitle: {
        marginLeft: -404,
        padding: theme.spacing(2) + ' '+ theme.spacing(3),
        '@media (orientation: portrait)': {
            marginLeft: 0,
            padding: theme.spacing(2)
        },
        '& $titleContent > $startForm':{
            display: 'none'
        },
        '& $actions': {
            margin: 0
        }
    },
    expandIcon: {
        transform: 'rotate(180deg)',
        width: 20,
        height: 20,
        '@media (orientation: landscape)': {
            transform: 'rotate(90deg)'
        }
    },
    collapsedDialogContent: {
        '& $expandIcon': {
            transform: 'rotate(0deg)',
            '@media (orientation: landscape)': {
                transform: 'rotate(270deg)'
            }
        },
        '& $logo': {
            left: 60
        }
    }
});

export class CustomAreaDialog extends Component {
    state = {
        squares: [],
        enteredText: '',
        addGridItemError: false,
        showAvailableRefs: false,
        updateMap: false,
        collapsed: false,
        nextChip: -1
    };

    nextChipRef = React.createRef();

    constructor(props) {
        super(props);
        const {selectedArea} = this.props;
        if (selectedArea) {
            this.state.squares = selectedArea;
        }
    }

    componentDidUpdate() {
        if (this.nextChipRef && this.nextChipRef.current) {
            this.nextChipRef.current.focus();
        }
    }

    handleDelete = toDelete => () => {
        const {squares} = this.state;
        const newSquares = squares.filter(square => square !== toDelete);
        let nextIndex = squares.indexOf(toDelete);

        // set index to last chip
        if (nextIndex >= newSquares.length) {
            nextIndex = newSquares.length - 1;
        }

        // no chip found
        if (nextIndex === -1 && newSquares.length > 0) {
            nextIndex = 0;
        }

        this.setSquares(newSquares, nextIndex);
    }
    
    setSquares = (squares, nextChip, clearErrors) => {
        let addGridItemError = this.state.addGridItemError;
        if (clearErrors) {
            addGridItemError = false;
        }
        this.setState({squares, nextChip, addGridItemError});
    }

    onSave = () => {
        const {squares} = this.state;
        this.props.handleSave(squares);
    }

    showError = errorMessage => {
        this.setState({errorMessage});
    }

    acceptNotification = () => {
        this.setState({errorMessage: null});
    }
    
    toggleAvailableRefs = () => {
        this.setState({showAvailableRefs: !this.state.showAvailableRefs, nextChip: -1});
    }

    clearAll = () => {
        this.setSquares([], -1, true);
    }

    activeGridRefs = () => {
        const {grid} = this.props;
        return grid.filter(g => g.active && g.ref !== '');
    }

    selectAll = () => {
        const squares = this.activeGridRefs().map(g => g.ref);
        this.setSquares(squares, -1, true);
    };

    onChangeGridInput = e => {
        const newEnteredText = e.target.value;
        if (newEnteredText !== this.state.enteredText) {
            this.setState({enteredText: newEnteredText, addGridItemError: false, nextChip: -1});
        }
    }

    handleGridInput = () => {
        const {enteredText, squares} = this.state;
        
        if (enteredText && enteredText.length > 0) {

            // reduce multiple spaces to a single space, replace comma and space with a single comma, then split at commas or spaces
            const vals = enteredText.replace(/\s+/g, ' ').replace(/,\s+/g, ',').split(/\s+|,/g);
            const exists = new Set();
            const added = [];
            const newSquares = new Set([...squares]);
            vals.forEach(val => {
                if (val.length === 2) {
                    const v = val.toUpperCase();
                    const inGrid = this.activeGridRefs().find(g => g.ref === v);
                    const inSquares  = squares.indexOf(v) !== -1;
                    if (inGrid && !inSquares) {
                        newSquares.add(v);
                        added.push(v);
                    } else if (inSquares) {
                        exists.add(v);
                    }
                }
            });

            // leave values which could not be added in the input box for correction
            const remainingEnteredText = vals.filter(v => ((added.indexOf(v.toUpperCase()) === -1) && (!exists.has(v.toUpperCase())))).join(',');
            const addGridItemError = (remainingEnteredText.length > 0);
            
            this.setState({squares: [...newSquares], enteredText: remainingEnteredText, addGridItemError});
        }
    }

    render() {
        const {classes, handleClose, grid, intl} = this.props;
        const {squares, errorMessage, enteredText, addGridItemError, showAvailableRefs, collapsed, nextChip} = this.state;
        const validSelection = (squares && squares.length > 0 && squares.some(s => grid.some(g => g.active && g.ref === s)));
        return (
            <Dialog
                open={true}
                classes={{paper: classes.dialogPaper}}
                maxWidth='sm'
                onClose={handleClose}
                fullScreen={true}
                TransitionProps={{
                    onEntered: this.focus
                }}>
                <DialogTitle
                    className={collapsed? classNames(classes.collapsedDialogTitle, classes.title) : classes.title}>
                    <div className={classes.titleContent}>
                        <div className={classes.startForm}>
                            <div className={classes.titleText}>
                                <FormattedMessage {...messages.title} />
                            </div>
                            
                            <div className={classes.gridSquareInput} aria-live='polite'>
                                <div className={classes.gridInputBefore}>
                                    <div className={classes.fieldLabel}>
                                        <InputLabel classes={{inputLabel: classes.inputLabel}} htmlFor='gridInput'>
                                            <FormattedMessage {...messages.enterGridRefs} />
                                        </InputLabel>
                                        <ClickAwayTooltip id='gridInputTip' 
                                            classes={{clickAwayTooltip: classes.tooltip}} 
                                            icon={<HelpIcon width={22} height={22} className={classes.tooltipIcon} />} 
                                            body={messages.gridInputTip}
                                            ariaLabel={intl.formatMessage(messages.helpTip)}
                                        />
                                    </div>
                                    <div className={classes.gridInputOptions}>
                                        <Button className={classNames(classes.availableRefsButton, classes.textLinkButton)}
                                            onClick={this.toggleAvailableRefs}
                                            aria-controls='availableRefs'
                                            disableRipple>
                                            {showAvailableRefs?
                                                <FormattedMessage {...messages.hideAvailableGridRefs} />
                                                :
                                                <FormattedMessage {...messages.showAvailableGridRefs} />
                                            }
                                        </Button>
                                    </div>
                                </div>

                            {showAvailableRefs &&
                                <Typography id='availableRefs' role='region' variant='body1' className={classes.availableRefs}>
                                    <InputLabel classes={{inputLabel: classes.inputLabel}}>
                                        <FormattedMessage {...messages.availableGridRefs} />
                                    </InputLabel>
                                    {this.activeGridRefs().map(g => g.ref).join(', ')}
                                </Typography>
                            }

                            <div className={classes.inputRow}>
                                <InputBox id='gridInput'
                                    classes={{textFieldContainer: classes.gridInput}}
                                    placeholder={messages.gridInputPrompt.defaultMessage}
                                    fullWidth={false}
                                    onChange={this.onChangeGridInput}
                                    onEnterKey={this.handleGridInput}
                                    value={enteredText}
                                    error={addGridItemError? messages.addGridItemError : null}
                                />
                                <Button color='primary' variant='outlined' onClick={this.handleGridInput}>
                                    <FormattedMessage {...messages.add} />
                                </Button>
                            </div>

                            <div className={classes.squaresActions}>
                                {(squares.length < this.activeGridRefs().length) &&
                                    <Button className={classNames(classes.selectButton, classes.textLinkButton)} onClick={this.selectAll} disableRipple>
                                        <FormattedMessage {...messages.selectAll} />
                                    </Button>
                                }
                                {(squares.length > 0) &&
                                    <Button className={classNames(classes.selectButton, classes.textLinkButton)} onClick={this.clearAll} disableRipple>
                                        <FormattedMessage {...messages.clearAll} />
                                    </Button>
                                }
                            </div>

                            {(squares.length > 0) &&
                                <div className={classes.chipsContainer}>
                                    {squares.map((square, i) => <GridChip key={square} square={square} onDelete={this.handleDelete} chipRef={i === nextChip? this.nextChipRef : null} />)}
                                </div>
                            }
                        </div>
                    </div>

                    <div className={classes.actions}>
                        <Button key='cancel' onClick={this.props.handleClose} size='small' variant='outlined'
                                color='primary'>
                            <FormattedMessage {...messages.cancel}/>
                        </Button>
                        <Button key='save' onClick={this.onSave} size='small' variant='contained' color='primary'
                                disabled={!validSelection}>
                            <FormattedMessage {...messages.save}/>
                        </Button>
                        {collapsed && 
                            <div className={classes.selectionSummary} role='status'>
                                <Typography variant='h3'>
                                    <FormattedMessage {...messages.selected} values={{data: squares.length}} />
                                </Typography>
                            </div>
                        }
                    </div>
                </div>
            </DialogTitle>

            {errorMessage &&
                <Notification variant='warning'
                    appearance='toast'
                    onClose={this.acceptNotification}>
                    <Typography variant='body1'>
                        {errorMessage}
                    </Typography>
                </Notification>
            }

            <DialogContent className={collapsed? classNames(classes.dialogContent, classes.collapsedDialogContent) : classes.dialogContent}>
                <div className={classes.controlAnchor}>
                    <Button aria-label={collapsed? messages.expand.defaultMessage : messages.collapse.defaultMessage}
                        onClick={() => {this.setState({collapsed: !this.state.collapsed, updateMap: true})}}>
                            <img src={expandIcon} className={classes.expandIcon} alt='' />
                    </Button>
                </div>
                <InteractiveGridMap squares={squares}
                    grid={grid}
                    handleSquaresChange={this.setSquares}
                    handleError={this.showError}
                    updateSize={this.state.updateMap}
                    postUpdateSize={() => this.setState({updateMap: false})} />
                <img className={classes.logo} width='128' height='32' src={logo} alt='OS logo' />
            </DialogContent>
        </Dialog>
        );
    };
}

CustomAreaDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    selectedArea: PropTypes.arrayOf(PropTypes.string),
    grid: PropTypes.arrayOf(PropTypes.object).isRequired
};

const withIntl = injectIntl(CustomAreaDialog);
export default withStyles(styles)(withIntl);