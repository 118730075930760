import {createLoadingReducer, createLoadingMiddleware} from 'omse-components';
import {
    LOAD_PREMIUM_CATALOGUE_ACTION,
    LOAD_PREMIUM_PRODUCT_ACTION,
    LOAD_PREMIUM_PRODUCT_OPTIONS_ACTION,
    LOADED_PREMIUM_CATALOGUE_ACTION,
    LOADED_PREMIUM_PRODUCT_ACTION,
    LOADED_PREMIUM_PRODUCT_OPTIONS_ACTION,
    LOAD_PREMIUM_LICENSED_PRODUCTS,
    LOADED_PREMIUM_LICENSED_PRODUCTS,
} from "./premium/actions";
import {combineReducers} from 'redux';
import queryString from 'query-string';

const OS_WEBSITE = 'https://www.ordnancesurvey.co.uk';

export const premiumReducer = combineReducers({
    catalogue: createLoadingReducer(LOAD_PREMIUM_CATALOGUE_ACTION, LOADED_PREMIUM_CATALOGUE_ACTION, true, reformat),
    product: createLoadingReducer(LOAD_PREMIUM_PRODUCT_ACTION, LOADED_PREMIUM_PRODUCT_ACTION, true, reformatProduct),
    options: createLoadingReducer(LOAD_PREMIUM_PRODUCT_OPTIONS_ACTION, LOADED_PREMIUM_PRODUCT_OPTIONS_ACTION, true),
    licensedProducts: createLoadingReducer(LOAD_PREMIUM_LICENSED_PRODUCTS, LOADED_PREMIUM_LICENSED_PRODUCTS, true)
});

function prepareCall(action, store) {
    let result;
    switch(action.type) {
        case LOAD_PREMIUM_CATALOGUE_ACTION:
            result = '/api/premium';
            break;
        case LOAD_PREMIUM_PRODUCT_ACTION:
            result = '/api/premium/' + action.id;
            break;
        case LOAD_PREMIUM_PRODUCT_OPTIONS_ACTION:
            result = '/api/premium/' + action.id + '/options';
            let optionParams = queryString.stringify(action.options);
            if(optionParams) {
                result = result + '?'+ optionParams
            }
            break;
        case LOAD_PREMIUM_LICENSED_PRODUCTS:
            result = '/api/premium/licencedProducts';
            break;
        default:
    }
    return result;
}

export const premiumMiddleware = [
    createLoadingMiddleware(LOAD_PREMIUM_CATALOGUE_ACTION, LOADED_PREMIUM_CATALOGUE_ACTION, prepareCall),
    createLoadingMiddleware(LOAD_PREMIUM_PRODUCT_ACTION, LOADED_PREMIUM_PRODUCT_ACTION, prepareCall),
    createLoadingMiddleware(LOAD_PREMIUM_PRODUCT_OPTIONS_ACTION, LOADED_PREMIUM_PRODUCT_OPTIONS_ACTION, prepareCall),
    createLoadingMiddleware(LOAD_PREMIUM_LICENSED_PRODUCTS, LOADED_PREMIUM_LICENSED_PRODUCTS, prepareCall)
];

function reformatProduct(action) {
    let product = action.result;
    if(product) {
        product.customAreaEnabled = product.areas ? product.areas.map(a => a.id).some(a => a === 'Polygon') : false;
        product.dataStructures = product.categories ? product.categories.map(c => c.label).sort() : [];
        product.supplyFormats = product.dataFormats ? product.dataFormats.map(d => d.label).sort() : [];
        product.infoUrl = product.infoUrl ? replaceToken(product.infoUrl, 'http.oswebsite', OS_WEBSITE) : OS_WEBSITE

        if (!product.images) product.images = [];
    }
}

function replaceToken(origString, tokenName, value) {
    return origString.replace('${' + tokenName + '}', value);
}
export function reformat(action) {
    let products = action.result;

    if (products && products.items && products.items.length > 0) {
        products.items.forEach(p => reformatProduct({ result: p }));
    }
}