import { defineMessages } from "react-intl";

export const messages = defineMessages({
    noProjectsReadOnlyHeader: {
        id: 'APIProjects.noProjectsReadOnlyHeader',
        defaultMessage: 'Nothing here yet.',
        description: 'No Projects read-only header text',
    },
    noProjectsHeader: {
      id: 'APIProjects.noProjectsHeader',
      defaultMessage: 'Nothing here yet. Create your first project!',
      description: 'No Projects header text',
    },
    noProjectsAccompaniment: {
        id: 'APIProjects.noProjectsAccompaniment',
        defaultMessage: 'Set up a project, add some APIs to it and track your usage here.',
        description: 'No projects header accompanying text'
    },
    createFirstProjectButton: {
        id: 'APIProjects.createFirstProjectButton',
        defaultMessage: 'Create your first project',
        description: 'Text for the button to create your first project'
    },
    whatIsAProjectHeader: {
        id: 'APIProjects.whatIsAProjectHeader',
        defaultMessage: 'What is a project?',
        description: 'Header for paragraph explaining projects',
    },
    whatIsAProjectParagraph1: {
        id: 'APIProjects.whatIsAProjectParagraph',
        defaultMessage: 'A project is a convenient way for you to group APIs by projects that you are working on. Each project will have its own usage stats ' + 
        'which is useful if you\'re working on lots of projects and need to track them separately.',
        description: 'Fist paragraph for what is a project'
    },
    whatIsAProjectParagraph2: {
        id: 'APIProjects.whatIsAProjectParagraph2',
        defaultMessage: 'You might have one project for your mobile app, and one for your web app to keep all your stats separate. ' +
        'This would let you track which of you projects is using the most API transactions.',
        description: 'Second paragraph for what is a prohect'
    }
});