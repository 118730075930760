import React, {Component}  from 'react';
import PropTypes from 'prop-types';
import { ConfirmationDialog } from 'omse-components';
import {defineMessages} from 'react-intl';

const messages = defineMessages({
    deleteProductTitle: {
        id: 'DeleteProductDialog.deleteTitle',
        defaultMessage: 'Remove {productName} from this project?',
        description: 'Title for the delete product dialog'
    },
    content1: {
        id: 'DeleteProductDialog.content1',
        defaultMessage: 'Your Project API key will no longer be able to authorise calls to this API.',
        description: 'Warning text for the delete product dialog'
    },
    content2: {
        id: 'DeleteProductDialog.content2',
        defaultMessage: 'You\'ll still be able to see historical usage stats for this API.',
        description: 'Warning text for the delete product dialog'
    },
    buttonLabel: {
        id: 'DeleteProductDialog.buttonLabel',
        defaultMessage: 'Remove API from project',
        description: 'Button label for the delete product dialog'
    },

});

export class DeleteProductDialog extends Component {

    render() {
        const {closed, confirmed, productName} = this.props;
        return <ConfirmationDialog title={messages.deleteProductTitle}
                                   titleValues={{productName: productName}}
                                   confirmationAction={confirmed}
                                   contentMessages={[messages.content1, messages.content2]}
                                   handleClose={closed}
                                   confirmationButtonLabel={messages.buttonLabel}/>
    }
}

DeleteProductDialog.propTypes = {
    closed: PropTypes.func.isRequired,
    confirmed: PropTypes.func.isRequired,
    productName: PropTypes.string.isRequired
};

export default DeleteProductDialog;