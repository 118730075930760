import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import {osColour} from 'omse-components';
import pro1 from './arcGISPro/Pro-1.png';
import pro2 from './arcGISPro/Pro-2.png';
import pro3 from './arcGISPro/Pro-3.png';
import pro4 from './arcGISPro/Pro-4.png';
import pro5 from './arcGISPro/Pro-5.png';
import pro6 from './arcGISPro/Pro-6.png';
import pro7 from './arcGISPro/Pro-7.png';
import pro8 from './arcGISPro/Pro-8.png';
import pro9 from './arcGISPro/Pro-9.png';
import WMTSCompletionSummary from './WMTSCompletionSummary';
import PropTypes from 'prop-types';

const styles = {
    guide: {
        '& img': {
            border: `solid ${osColour.neutral.mist} 1px`
        }
    }
};

export function ArcGISPro(props) {
    const {classes} = props;
    return <Fragment>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    For the purposes of this guide the version of ArcGIS Pro used is 2.3.3.
                </p>
            </Typography>
            <Typography variant='h2'>Integrating OS Maps API in ArcGIS Pro</Typography>
            <Typography variant='body1' paragraph={true} component='div' className={classes.guide}>
                <ol>
                    <li>
                        <p>Launch ArcGIS Pro. From the opening page, create a new project with a new map.</p>
                        <img src={pro1} alt="New project dialog" />
                    </li>
                    <li>
                        <p>Give the project a name and save it to a folder of choice.</p>
                    </li>
                    <li>
                        <p>ArcGIS Pro will load up one of the standard template maps. The map may look similar to the below, depending upon which default mapping template is selected in the application.</p>
                        <img src={pro2} alt="Default mapping template" />
                    </li>
                    <li>
                        <p>Ensure that the catalog tab has been selected. The catalog window will be required shortly.</p>
                        <img src={pro3} alt="Catalog tab selected" />
                    </li>
                    <li>
                        <p>To access the OS Maps API service from the OS Data Hub select the ’Insert’ tab and then select ’Connections’.</p>
                        <p>In the drop-down list of options, you will see a number of services available. To open the OS Maps API service, select ’New WMTS Server’.</p>
                        <img src={pro4} alt="'Insert' tab 'Connections' options showing 'New WMTS Server' item selected" />
                    </li>
                    <li>
                        <p>In the new window that appears after you select this option, you will need to insert the OS Maps URL, which includes the key, from the OS Data Hub into the server URL box. No other information needs to be entered. Click 'OK'.</p>
                        <img src={pro5} alt="'Add WMTS Server Connection' dialog" />
                    </li>
                    <li>
                        <p>In the catalog window, you will now see that the OS Maps API service has been added. Underneath this, you will see a number of map styles which are available.</p>
                        <img src={pro6} alt="Available map styles in catalog window" />
                    </li>
                    <li>
                        <p>Next, to add the data to the map view, select ‘Map’, ’Add Data’ and then ’Add data to the Map’.</p>
                        <img src={pro7} alt="'Add data to the map' option in 'Add data' option of 'Map' tab" />
                    </li>
                    <li>
                        <p>Browse to the layer which is required in the Add Data window and select it. Then click ’OK’.</p>
                        <img src={pro8} alt="'Add Data' dialog showing WMTS layers" />
                    </li>
                    <li>
                        <p>The data should now appear in the map window. Use the standard zoom controls to zoom into the area required.</p>
                        <img src={pro9} alt="Map window with map displayed" />
                    </li>
                </ol>
            </Typography>
            <WMTSCompletionSummary software="ArcGIS Pro" />
    </Fragment>
}

ArcGISPro.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ArcGISPro);