import {createLoadingReducer, createLoadingMiddleware, createActionReducer, createActionMiddleware} from 'omse-components';
import {
    LOAD_PARTNER_CONTRACTS_ACTION, LOADED_PARTNER_CONTRACTS_ACTION,
    LOAD_PARTNER_CONTRACT_CATALOGUE_ACTION, LOADED_PARTNER_CONTRACT_CATALOGUE_ACTION,
    CREATE_PARTNER_CONTRACTS_ACTION
} from './partner/actions';
import {combineReducers} from 'redux';
import {ACTION_UPDATE_SUFFIX} from 'omse-components';

export const partnerReducer = combineReducers({
    contracts: createLoadingReducer(LOAD_PARTNER_CONTRACTS_ACTION, LOADED_PARTNER_CONTRACTS_ACTION),
    catalogue: createLoadingReducer(LOAD_PARTNER_CONTRACT_CATALOGUE_ACTION, LOADED_PARTNER_CONTRACT_CATALOGUE_ACTION),
    createContracts: createActionReducer(CREATE_PARTNER_CONTRACTS_ACTION)
});

export const partnerMiddleware = [
    // The create contract API is set up to return the current state of the user's contracts if there are errors
    // creating any of the contracts that have been requested. We use that info to push the current contract state
    // into the store, which will then help to display the current contracts to the user.
    store => next => action => {
        if(action.error && action.type === CREATE_PARTNER_CONTRACTS_ACTION + ACTION_UPDATE_SUFFIX) {
            const error = action.error;
            const response = error.response && error.response.body;
            if(error.statusCode === 500 && typeof response === 'object') {
                // Some of the requested contracts might have been created, so should send the result to the store
                store.dispatch({ type: LOADED_PARTNER_CONTRACTS_ACTION, result: response });
            }
        }
        return next(action);
    },
    createLoadingMiddleware(LOAD_PARTNER_CONTRACTS_ACTION, LOADED_PARTNER_CONTRACTS_ACTION, prepareLoadContractsCall),
    createLoadingMiddleware(LOAD_PARTNER_CONTRACT_CATALOGUE_ACTION, LOADED_PARTNER_CONTRACT_CATALOGUE_ACTION, prepareLoadContractCatalogueCall),
    createActionMiddleware(CREATE_PARTNER_CONTRACTS_ACTION, setupCreateContracts, contractCreated)
];

function prepareLoadContractsCall() {
    return '/api/partners/contracts';
}

function prepareLoadContractCatalogueCall() {
    return '/api/partners/catalogue';
}

function setupCreateContracts(action) {
    return {
        url: '/api/partners/contracts',
        method: 'POST',
        body: action.contracts
    };
}

function contractCreated(store, action, result) {
    store.dispatch({ type: LOADED_PARTNER_CONTRACTS_ACTION, result });
}
