import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import withStyles from 'react-jss';
import {osColour, arrow, box, arrowTooltipStyles} from 'omse-components';
import {ReactComponent as WarningIcon} from './icons/warning-notification.svg';
import Typography from '@mui/material/Typography';

const styles = {
    popper: {
        boxShadow: box.shadow,
        borderRadius: box.borderRadius,
        '&[data-popper-placement*="bottom"] $arrow': {
            top: 0,
            marginTop: arrow.offset
        },
        '&[data-popper-placement*="top"] $arrow': {
            bottom: 0,
            marginBottom: arrow.offset
        },
        zIndex: 10,
        background: osColour.neutral.white,
        padding: 0,
        width: 314,
        cursor: 'pointer'
    },
    notificationPanel: {
        boxShadow: 'none'
    },
    notificationBody: {
        padding: '7px 12px',
        lineHeight: 1.3
    },
    warningIcon: {
        float: 'right',
        color: osColour.neutral.charcoal,
        marginLeft: 3
    },
    ...arrowTooltipStyles()
};

export class PriorityNotification extends Component {

    componentWillUnmount() {
        this.props.handleClickAway();
    }

    render() {
        const {classes, anchorEl, open, item, handleClickAway, handleClick} = this.props;
        return <Fragment>
            <Popper role='alert'
                className={classes.popper}
                anchorEl={anchorEl}
                onClick={handleClick}
                open={open}
                placement='bottom'
                popperOptions={{
                    strategy: 'fixed'
                }}
                modifiers={[
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            boundary: 'viewport',
                            padding: 5
                        }
                    },
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                         element: '.' + classes.arrow
                        }
                    },
                   {
                       name: 'flip',
                        enabled: false
                    }
                ]}>
                <span className={classes.arrow}/>
                <ClickAwayListener onClickAway={handleClickAway} mouseEvent='onClick'>
                    <Paper className={classes.notificationPanel}>
                        <Typography variant='body1' className={classes.notificationBody}>
                            {item.level === 1 &&
                                <WarningIcon height={24} width={24} alt='Attention' className={classes.warningIcon} />
                            }
                            {item.title}
                        </Typography>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </Fragment>
    }
}

PriorityNotification.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClickAway: PropTypes.func.isRequired,
    anchorEl: PropTypes.object,
    item: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired
};

export default withStyles(styles)(PriorityNotification);