import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useId, useRef, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { border1, osColour, Tooltip } from "omse-components";
import { ReactComponent as SuccessIcon } from "./icons/success-notification.svg";
import { Button as MuiButton, Typography } from "@mui/material";

const copyToClipboardMessages = defineMessages({
    buttonCopy: {
        id: "copyToClipboardMessages.buttonCopy",
        defaultMessage: "Copy",
        description: "CopyToClipboard > Button Text",
    },
    buttonAria: {
        id: "copyToClipboardMessages.buttonAria",
        defaultMessage: "Copy {polyfilledContext} to Clipboard",
        description: "CopyToClipboard > Button Aria-Label",
    },
    tooltipContent: {
        id: "copyToClipboardMessages.tooltipContent",
        defaultMessage: "Copied to Clipboard.",
        description: "CopyToClipboard > Tooltip Text Content",
    },
});

const Button = styled(MuiButton)(
    ({ theme, variant }) => `
    color: ${theme.palette.primary.main}
    min-height: 50px;
    border-radius: 0 4px 4px 0;
    ${variant === "outlined" && `border: ${border1};`}
`
);

const TooltipContent = styled("div")(
    ({ theme }) => `
    padding: ${theme.spacing(1)};
    z-index: 999;
    display: flex;
    gap: 0.6em;
    align-items: center;
    border-radius: 0.4em;
    background: ${osColour.neutral.white};
`
);

export default function CopyToClipboard(p) {
    const { inputRef, ariaDescriptor, filled, disabled } = p;
    const intl = useIntl();
    const tooltipId = useId();
    const buttonRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);

    async function handleCopyEvent() {
        // The current state of permissions across browsers permits
        // write access but restricts read. There's no requirement to
        // assert against "navigator.permissions.query()".
        const content = inputRef.current.innerText;
        await navigator.clipboard.writeText(content);
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 5000);
    }

    return (
        <>
            <Button
                color="primary"
                variant={filled ? "contained" : "outlined"}
                onClick={() => handleCopyEvent()}
                ref={buttonRef}
                disabled={disabled}
                aria-label={intl.formatMessage(copyToClipboardMessages.buttonAria, {
                    polyfilledContext: ariaDescriptor ? `'${ariaDescriptor}'` : "snippet",
                })}
            >
                <FormattedMessage {...copyToClipboardMessages.buttonCopy} />
            </Button>
            {showTooltip && (
                <Tooltip
                    id={tooltipId}
                    open={showTooltip}
                    placement="top"
                    anchorEl={buttonRef.current}
                    contents={
                        <TooltipContent>
                            <SuccessIcon
                                width={24}
                                height={24}
                                style={{ color: osColour.status.success }}
                            />
                            <Typography variant="body1" aria-live="assertive">
                                <FormattedMessage {...copyToClipboardMessages.tooltipContent} />
                            </Typography>
                        </TooltipContent>
                    }
                />
            )}
        </>
    );
}

CopyToClipboard.defaultProps = {
    disabled: false,
};

CopyToClipboard.propTypes = {
    inputRef: PropTypes.object.isRequired,
    ariaDescriptor: PropTypes.string,
    filled: PropTypes.bool,
    disabled: PropTypes.bool,
};
