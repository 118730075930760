import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { osColour } from "omse-components";
import { FormattedMessage, defineMessage } from "react-intl";
import { Stack as MuiStack, Chip, Typography } from "@mui/material";

const Stack = styled(MuiStack)`
    margin: 1em 0;
    padding: 0;
    align-items: center;
    & > dt {
        margin: 0 0.4em 0 0;
        color: ${osColour.neutral.stone};
    }
    & > dd {
        margin: 0;
        padding: 0 0.4em;
        color: ${osColour.primary.berry};
        background: ${osColour.primary.superLightBerry};
    }
`;

export default function ThemeChips(p) {
    const ThemesTitleMessage = defineMessage({
        id: "ThemesMessage",
        defaultMessage: "Themes:",
        description: "Element Title (Inline)",
    });

    return (
        <Stack spacing={1} useFlexGap direction="row" flexWrap="wrap" component="dl">
            <Typography component="dt" variant="body1">
                <FormattedMessage {...ThemesTitleMessage} />
            </Typography>
            {p.themes.map((theme) => (
                <Chip key={theme} label={theme} component="dd" />
            ))}
        </Stack>
    );
}

ThemeChips.propTypes = {
    themes: PropTypes.arrayOf(PropTypes.string).isRequired,
};
