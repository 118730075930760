// Hook to encode the stats interval in a search parameter
import {HISTORY_STATS_INTERVAL_WEEK, HISTORY_STATS_INTERVAL_MONTH, HISTORY_STATS_INTERVAL_YEAR} from "../modules/stats/actions";
import useSearchParam from "./useSearchParam";

const validIntervals = [HISTORY_STATS_INTERVAL_WEEK, HISTORY_STATS_INTERVAL_MONTH, HISTORY_STATS_INTERVAL_YEAR];
const defaultInterval = validIntervals[0];

export default function useStatsInterval() {
    let [interval, setInterval] = useSearchParam('interval', defaultInterval);

    // Check that we are passing back a valid value
    if(validIntervals.indexOf(interval) === -1) {
        interval = defaultInterval;
        setInterval(defaultInterval);
    }

    return [interval, setInterval];
}