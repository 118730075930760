import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defineMessages} from "react-intl";
import { ConfirmationDialog } from 'omse-components';

const messages = defineMessages({
    confirmationDialogTitle: {
        id: 'RegenerateKeyDialog.confirmationDialogTitle',
        defaultMessage: 'Regenerate API Key?',
        description: 'Label for the confirmation dialog title'
    },
    confirmationDialogAction: {
        id: 'RegenerateKeyDialog.confirmationDialogAction',
        defaultMessage: 'Regenerate API Key',
        description: 'Label for the confirmation dialog action'
    },
    confirmationDialogContent1: {
        id: 'RegenerateKeyDialog.confirmationDialogContent1',
        defaultMessage: 'This makes your existing key unusable and creates a new key for this project.',
        description: 'Label for the confirmation content 1'
    },
    confirmationDialogContent2: {
        id: 'RegenerateKeyDialog.confirmationDialogContent2',
        defaultMessage: 'You will need to update any applications or connected software to use the new key in order for them to continue working.',
        description: 'Label for the confirmation content 2'
    },
    confirmationDialogContent3: {
        id: 'RegenerateKeyDialog.confirmationDialogContent3',
        defaultMessage: 'You\'ll still be able to see historical usage stats for this project.',
        description: 'Label for the confirmation content 3'
    }
});

class RegenerateKeyDialog extends Component {

    render() {
        const {confirmed, closed} = this.props;

        return (
            <ConfirmationDialog handleClose={closed}
                                title={messages.confirmationDialogTitle}
                                contentMessages={[
                                    messages.confirmationDialogContent1,
                                    messages.confirmationDialogContent2,
                                    messages.confirmationDialogContent3
                                ]}
                                confirmationButtonLabel={messages.confirmationDialogAction}
                                confirmationAction={confirmed}
                                confirmationCodeRequired={true}/>
        );
    }
}

RegenerateKeyDialog.propTypes = {
    closed: PropTypes.func.isRequired,
    confirmed: PropTypes.func.isRequired
};

export default RegenerateKeyDialog;
