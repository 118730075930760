import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import withStyles from 'react-jss';
import EmptyStateMessages from '../../components/EmptyStateMessages';
import {messages} from './emptyProject/EPMessages';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'
import {osColour, linkButton, AddButton, contentPadding} from 'omse-components';
import {hasManageProjectsPermission} from "../../util/permissions";

const styles = theme => ({
    content: {
        maxWidth: contentPadding.maxWidth,
        color: osColour.neutral.charcoal,
        '& h2': {
            marginBottom: theme.spacing(1.5)
        },
        '& h4': {
            marginBottom: theme.spacing(1)
        },
        '& h4:not(:first-of-type)': {
            marginTop: theme.spacing(5)
        },
        '& a': {
            fontSize: '1.125rem'
        }
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
    },
    contentLeft: {
        marginTop: theme.spacing(5),
        paddingTop: theme.spacing(3),
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        },
        marginBottom: theme.spacing(10)
    },
    contentCenter: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(5),
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5)
        }
    },
    linkButton: {
        ...linkButton,
        fontSize: '1.125rem'
    }
});
export class EmptyProject extends Component {

    readOnlyMessage(classes) {
        return <div className={classes.content}>
            <div className={classes.contentCenter}>
                <Typography variant='h2'>
                    <FormattedMessage {...messages.emptyProjectReadOnly} />
                </Typography>
            </div>
            <div className={classes.contentLeft}>
                <EmptyStateMessages />
            </div>
        </div>;
    }

    render() {
        const { classes, userDetails } = this.props;
        if (!hasManageProjectsPermission(userDetails)) {
            return this.readOnlyMessage(classes);
        }

        return (
            <div className={classes.content}>
                <div className={classes.contentCenter}>
                    <Typography variant='h2'>
                        <FormattedMessage {...messages.emptyProject} />
                    </Typography>
                    <Typography variant='h5'>
                        <FormattedMessage {...messages.projectPageDescription} />
                    </Typography>
                    <div className={classes.button}>
                        <AddButton action={this.props.addAPIAction} label={messages.addAPIToThisProjectButton} />
                    </div>
                </div>
                <div className={classes.contentLeft}>
                    <Typography variant='h2'>
                        <FormattedMessage {...messages.whatNext} />
                    </Typography>
                    <Typography variant='h4'>
                        <FormattedMessage {...messages.rename} />
                    </Typography>
                    <Typography component='div' variant='h5' paragraph={true}>
                        <FormattedMessage {...messages.renameParagraph} />
                    </Typography>
                    <Typography variant='h4'>
                        <FormattedMessage {...messages.addAPIs} />
                    </Typography>
                    <Typography component='div' variant='h5' paragraph={true}>
                        <FormattedMessage {...messages.addAPIsParagraph} values={{
                            addLink: <Button component='span' className={classes.linkButton} onClick={this.props.addAPIAction} disableRipple>
                                <FormattedMessage {...messages.addAPILink} />
                            </Button>
                        }} />
                    </Typography>
                    <EmptyStateMessages />
                </div>
            </div>
        )
    }
}

EmptyProject.propTypes = {
    classes: PropTypes.object.isRequired,
    addAPIAction: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        userDetails: state.user.current.result
    };
}

const styled = withStyles(styles)(EmptyProject);
const connected = connect(mapStateToProps)(styled);
export default withRouter(connected);