import React from 'react';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import {createUseStyles} from 'react-jss';
import getCommonStyles from '../../../styles/common';
import premiumOSOrders from '../data/premium-os-orders';
import FeatureIcon from '../../../../components/FeatureIcon';
import Table, {OUTLINE} from '../../../documentation/Table';

const useStylesCommon = createUseStyles(getCommonStyles);

const useStyles = createUseStyles(theme => {
    return {
        datasets: {
            '& th': {
                verticalAlign: 'middle'
            },
            '& tbody th': {
                fontWeight: 'normal'
            }
        },
        centerHeader: {
            textAlign: 'center !important',
            padding: theme.spacing(1) + ' !important'
        },
        center: {
            textAlign: 'center'
        },
        sup: {
            marginLeft: theme.spacing(0.5)
        },
        superscriptOverflow: {
            paddingLeft: 12,
            textIndent: -12
        }
    }
});

export default function PremiumDatasetsTable() {
    const classesCommon = useStylesCommon();
    const classes = useStyles();

    const returnTableData = () => {
        const data = [];
        let item;

        premiumOSOrders.forEach(dataset => {
            const name = dataset.name;
            const rows = [];
            dataset.variants.forEach((variant, index) => {
                item = <tr key={name + variant.name}>
                    {
                        index === 0 && <th rowSpan={dataset.variants.length} scope='rowgroup'>
                            {name}
                        </th>
                    }
                    <th scope='row'>
                        {variant.name}
                        <sup className={classNames(classesCommon.superScriptColor, classes.sup)}>
                            {variant.subscript}
                        </sup>
                    </th>
                    <td className={classes.center}>
                        {
                            variant.sftp && <FeatureIcon on={true}/>
                        }
                    </td>
                    <td className={classes.center}>
                        {
                            variant.dvd && <FeatureIcon on={true}/>
                        }
                    </td>
                </tr>;

                rows.push(item);
            });
            data.push(<tbody key={name}>{rows}</tbody>);
        });

        return data;
    }

    return <>
        <Table className={classes.datasets} variant={OUTLINE}>
            <thead>
                <tr>
                    <Typography variant='subtitle2' component='th' rowSpan='2'>Premium product</Typography>
                    <Typography variant='subtitle2' component='th' rowSpan='2'>Format and Area variant</Typography>
                    <Typography className={classes.centerHeader} variant='subtitle2' component='th' colSpan='2'>Media Option</Typography>
                </tr>
                <tr>
                    <Typography className={classes.centerHeader} variant='subtitle2' component='th'>SFTP</Typography>                    
                    <Typography className={classes.centerHeader} variant='subtitle2' component='th'>DVD/HDD</Typography>
                </tr>
            </thead>
            {returnTableData()}
        </Table>
        <p className={classes.superscriptOverflow}>
            <sup className={classNames(classesCommon.superScriptColor)}>1</sup> User defined area option is available to download from OS Data Hub; however, exceptionally large polygons are not supported by the fulfilment system.</p>
        <p className={classes.superscriptOverflow}>
            <sup className={classNames(classesCommon.superScriptColor)}>2</sup> GB Coverage and 5km tiles options are available to download from OS Data Hub.
        </p>
        <p className={classes.superscriptOverflow}>
            <sup className={classNames(classesCommon.superScriptColor)}>3</sup> Change Only Update (COU) option is available to download from OS Data Hub.
        </p>
    </>
}