import React from 'react';
import {FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import {Notification} from 'omse-components';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';

function style(theme) {
    return {
        notification: {
            marginBottom: theme.spacing(3),
            paddingRight: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                marginLeft: 16,
                marginRight: 16,
                '& p': {
                    paddingLeft: '0 !important'
                },
                '& ol': {
                    paddingLeft: 20
                }
            }
        }
    };
}

export function CompletionSummary(props) {
    const {classes, achievements, software, api} = props;
    return <Notification variant='info' appearance='inline' classes={{root: classes.notification}}>
        <Typography variant='body1' paragraph={true} component='div'>
            <b>Well done!</b> You've just completed the following actions:
            <ol>
                {achievements.map((achievement, i) => {
                    return <li key={`${api}-${software}-${i}`}>
                            <FormattedMessage {...achievement} values={{software}} />
                        </li>;
                })}
            </ol>
        </Typography>
    </Notification>
}

CompletionSummary.propTypes = {
    classes: PropTypes.object.isRequired,
    achievements: PropTypes.array.isRequired,
    software: PropTypes.string.isRequired,
    api: PropTypes.string.isRequired
}

export default withStyles(style)(CompletionSummary);