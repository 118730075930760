import {
    createLoadingReducer,
    createLoadingMiddleware,
    createActionReducer,
    createActionMiddleware
} from 'omse-components';
import {withOrganisation} from '../util/organisation';
import {combineReducers} from 'redux';

const PREFIX = 'action:products:';
export const LOAD_PRODUCTS_ACTION   = PREFIX + 'load';
export const LOADED_PRODUCTS_ACTION = PREFIX + 'loaded';
export const START_PRODUCT_FREE_TRIAL   = PREFIX + 'startFreeTrial';

/* Get products */
export function getProducts() {
    return {
        type: LOAD_PRODUCTS_ACTION
    }
}

export const productsLoadingReducer = createLoadingReducer(LOAD_PRODUCTS_ACTION, LOADED_PRODUCTS_ACTION);

function prepareCall(action, store) {
    return withOrganisation('/api/products', store);
}
export const productsLoadingMiddleware = createLoadingMiddleware(LOAD_PRODUCTS_ACTION, LOADED_PRODUCTS_ACTION, prepareCall);

/* Start free trial */
export function startProductFreeTrial(product, actionId) {
    return {
        type: START_PRODUCT_FREE_TRIAL,
        productId: product.name,
        actionId
    };
}
export const freeTrialReducer = createActionReducer(START_PRODUCT_FREE_TRIAL);

export const freeTrialMiddleware = createActionMiddleware(
    START_PRODUCT_FREE_TRIAL,
    function prepareCall(action, store) {
        return withOrganisation({
            method: 'POST',
            url: `/api/products/${action.productId}/startFreeTrial`
        }, store);
    },
    function success(store, action, products) {
        store.dispatch({
            type: LOADED_PRODUCTS_ACTION,
            result: products,
        });
    }
);


/* Combine */
export const productsReducer = combineReducers({
    current: productsLoadingReducer,
    freeTrial: freeTrialReducer,
});

export const productsMiddleware = [
    productsLoadingMiddleware,
    freeTrialMiddleware,
];