
const premiumDatasets = [
    {id: '25kScaleColourRaster', name: '1:25 000 Scale Colour Raster', gml: false, esriShapeFile: false, csv: false, geoPackage: false, geoTiff: true, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: '50kScaleColourRaster', name: '1:50 000 Scale Colour Raster', gml: false, esriShapeFile: false, csv: false, geoPackage: false, geoTiff: true, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'addressBase', name: 'AddressBase', gml: true, esriShapeFile: false, csv: true, geoPackage: false, geoTiff: false, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'addressBaseCore', name: 'AddressBase Core', gml: false, esriShapeFile: false, csv: true, geoPackage: true, geoTiff: false, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'addressBasePremium', name: 'AddressBase Premium', gml: true, esriShapeFile: false, csv: true, geoPackage: true, geoTiff: false, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'addressBasePremiumIslands', name: 'AddressBase Premium – Islands', gml: true, esriShapeFile: false, csv: true, geoPackage: true, geoTiff: false, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'addressBasePlus', name: 'AddressBase Plus', gml: true, esriShapeFile: false, csv: true, geoPackage: false, geoTiff: false, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'addressBasePlusIslands', name: 'AddressBase Plus – Islands', gml: true, esriShapeFile: false, csv: true, geoPackage: false, geoTiff: false, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'codePoint', name: 'Code-Point', gml: false, esriShapeFile: false, csv: true, geoPackage: true, geoTiff: false, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'codePointWithPolygons', name: 'Code-Point with Polygons', gml: false, esriShapeFile: true, csv: false, geoPackage: true, geoTiff: false, vectorTiles: true, mapInfo: true, jpeg: false, ecw: false, pbf: false},
    {id: 'detailedPathNetwork', name: 'OS Detailed Path Network', gml: true, esriShapeFile: false, csv: false, geoPackage: true, geoTiff: false, vectorTiles: true, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'emergencyServicesGazetteer', name: 'OS Emergency Services Gazetteer', gml: false, esriShapeFile: false, csv: true, geoPackage: true, geoTiff: false, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'buildingHeightAttribute', name: 'OS MasterMap Building Height Attribute', gml: false, esriShapeFile: false, csv: true, geoPackage: false, geoTiff: false, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'greenSpace', name: 'OS MasterMap Greenspace Layer', gml: true, esriShapeFile: true, csv: false, geoPackage: true, geoTiff: false, vectorTiles: true, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'highwaysPathNetwork', name: 'OS MasterMap Highways Network – Paths', gml: true, esriShapeFile: false, csv: false, geoPackage: true, geoTiff: false, vectorTiles: true, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'highwaysRoadNetwork', name: 'OS MasterMap Highways Network – Roads', gml: true, esriShapeFile: false, csv: false, geoPackage: true, geoTiff: false, vectorTiles: true, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'highwaysRoadWithRouting', name: 'OS MasterMap Highways Network – Routing and Asset Management Information', gml: true, esriShapeFile: false, csv: false, geoPackage: true, geoTiff: false, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'imageryLayer', name: 'OS MasterMap Imagery Layer', gml: false, esriShapeFile: false, csv: false, geoPackage: false, geoTiff: true, vectorTiles: false, mapInfo: false, jpeg: true, ecw: true, pbf: false},
    {id: 'sitesLayer', name: 'OS MasterMap Sites Layer', gml: true, esriShapeFile: false, csv: false, geoPackage: true, geoTiff: false, vectorTiles: true, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'topographyLayer', name: 'OS MasterMap Topography Layer', gml: true, esriShapeFile: false, csv: false, geoPackage: true, geoTiff: false, vectorTiles: true, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'waterNetwork', name: 'OS MasterMap Water Network', gml: true, esriShapeFile: false, csv: false, geoPackage: true, geoTiff: false, vectorTiles: true, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'multimodalRoutingNetwork', name: 'OS Multi-modal Routing Network', gml: false, esriShapeFile: false, csv: false, geoPackage: true, geoTiff: false, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: true},
    {id: 'terrain5', name: 'OS Terrain 5', gml: true, esriShapeFile: true, csv: false, geoPackage: false, geoTiff: false, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'vectorMapLocal', name: 'OS VectorMap Local', gml: true, esriShapeFile: false, csv: false, geoPackage: true, geoTiff: false, vectorTiles: true, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'vectorMapLocalBlackWhiteRaster', name: 'OS VectorMap Local Black and White Raster', gml: false, esriShapeFile: false, csv: false, geoPackage: false, geoTiff: true, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'vectorMapLocalColourRaster', name: 'OS VectorMap Local Colour Raster', gml: false, esriShapeFile: false, csv: false, geoPackage: false, geoTiff: true, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
    {id: 'vectorMapLocalBackdropColourRaster', name: 'OS VectorMap Local Backdrop Colour Raster', gml: false, esriShapeFile: false, csv: false, geoPackage: false, geoTiff: true, vectorTiles: false, mapInfo: false, jpeg: false, ecw: false, pbf: false},
];

export default premiumDatasets;