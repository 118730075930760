import styled from '@emotion/styled';
import {singleColBreakpoint} from './ContentContainer';

export const gridGap = '2em';

const CardContainer = styled('div')`
    display: grid;
    gap: ${gridGap};
    align-items: stretch;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
    & > article {
       max-width: 552px;
       min-width: 260px;
    }
    @media (max-width: ${singleColBreakpoint}px) {
        grid-template-columns: auto;
    }
`;

export default CardContainer;