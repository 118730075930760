exports.PREMIUM = 'premium';
exports.NGD_DOWNLOADS = 'ngdDownloads';
exports.NGD_SHARE = 'ngdShare';
exports.NGD_RECIPE_EDITING = 'ngdRecipeEditing';
exports.NGD_FEATURES = 'ngdFeatures';
exports.MONETISATION = 'monetisation';
exports.PSGA = 'psga';
exports.MATCH_AND_CLEANSE = 'matchAndCleanse';
exports.CHANGE_EMAIL = 'changeemail';
exports.SUPPORT_LOG_IN = 'supportLogIn';
exports.PREMIUM_OS_PLACES = 'premiumOsPlaces';
exports.CONTACT_PREFERENCES = 'contactPreferences';
exports.STATS = 'stats';
exports.EAI = 'eai';
exports.EAIAPI = 'eaiapi';
exports.VN = 'vn';
exports.VN_ALTERNATIVE_NAME = 'vnAlternativeName';
exports.PLACES_CUSTODIAN = 'placesCustodian';
exports.NGD_TILES = 'ngdTiles';
exports.WFS_ARCHIVE = 'wfsArchive';
exports.WORKSPACE = 'workspace'
exports.TEAMS = 'teams'

// Dev features.
exports.SERVICE_STATUS_DEV = 'serviceStatusDev';
exports.SERVICE_STATUS_TEST_MONITORS = 'serviceStatusTestMonitors';
