import React, {useEffect, useMemo} from 'react';
import FeatureCheck from "../../components/FeatureCheck";
import {styles} from "./DownloadStyles";
import {createUseStyles} from 'react-jss';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import ContentHeader from '../../components/ContentHeader';
import routePaths from "../../util/routes";
import {CircularProgress, Typography} from '@mui/material';
import EmptyState from "./dataPackages/EmptyState";
import {loadDataPackages} from "../../modules/dataPackages/actions";
import DataPackagesList from "./dataPackages/DataPackagesList";
import { Notification, ExternalLink } from 'omse-components';
import buildFilter from "../../util/search/dataPackageFilter";
import SearchBox from "../../components/SearchBox";
import {ReactComponent as HelpIcon} from "../../components/icons/help-notification.svg";
import Link from "../../components/Link";
import {useHistory, useLocation} from 'react-router';
import useFeatureCheck from '../../util/useFeatureCheck';
import features from '../../../shared/features';

const useStyles = createUseStyles(styles);

const messages = defineMessages({
    title: {
        id: 'DataPackages.title',
        defaultMessage: 'Data packages',
        description: 'Title for the premium packages view'
    },
    premiumDownloads: {
        id: 'DataPackages.premiumDownloads',
        defaultMessage: 'Premium data downloads',
        description: 'Label for the back link'
    },
    error: {
        id: 'DataPackages.error',
        defaultMessage: 'There was a problem loading your data packages. Please try again later or {link} if the problem persists.',
        description: 'Message shown if the data packages cannot be loaded.'
    },
    findPackages: {
        id: 'DataPackages.findPackages',
        defaultMessage: 'Find packages',
        description: 'Label for the package search box'
    },
    findPackagesPlaceholder: {
        id: 'DataPackages.findPackagesPlaceholder',
        defaultMessage: 'Search by package name, number or product',
        description: 'Placeholder text for the package search box'
    },
    findPackagesReset: {
        id: 'DataPackages.findPackagesReset',
        defaultMessage: 'Clear search',
        description: 'Aria text for the package search box reset button'
    },
    noResults: {
        id: 'DataPackages.noResults',
        defaultMessage: 'No data packages match your search',
        description: 'Label for no results'
    },
    faqLink: {
        id: 'DataPackages.faqLink',
        defaultMessage: 'Get help requesting and downloading data',
        description: 'Text for the link for Download FAQs'
    }
});

export default function DataPackages() {
    const classes = useStyles();
    const {result, loading, error} = useSelector(state => state.dataPackages.list);
    const dispatch = useDispatch();
    const intl = useIntl();
    const location = useLocation();
    const history = useHistory();

    // show a toast message if asked to in the location.state
    let toastMessage;
    if (location.state && location.state.toastMessage) {
        function clearToastMessage() {
            const state = { ...location.state };
            delete state.toastMessage;
            history.replace({ state });
        }

        toastMessage = <Notification variant='success' appearance='toast' onClose={clearToastMessage} autoClose={true}>
            <Typography variant='body1'>
                <FormattedMessage {...location.state.toastMessage}/>
            </Typography>
        </Notification>
    }

    // We store the search in location state so that it is restored when the user presses the back button.
    const search = (location.state && location.state.search) || '';
    function setSearch(search) {
        const state = {
            ...location.state,
            search,
            page: 0
        };
        history.replace({
            ...location,
            state
        });
    }

    // Apply the search filtering to the list of data packages. We memoise this, so that we only
    // need to recreate it when either the search criteria or the result changes.
    const packageList = useMemo(() => {
        if(result) {
            return result.filter(buildFilter(search));
        }
        return [];
    }, [result, search]);

    // Load the list of packages as the component loads
    useEffect(() => {
        dispatch(loadDataPackages());
    }, [dispatch]);

    let content, searchComponent, notification;

    // Decide if we should show a loading spinner, or the existing state from the store.
    // - Loading, with old real results:
    //     show what we have, and pop a loading spinner into the list that we display
    // - Loading, with old empty results:
    //     show a loading spinner, as we are not sure if packages exist - the user may have just created their first data package
    // - Loading, with no results:
    //     show a loading spinner
    // - Loading, with an old error:
    //     show a loading spinner (rather than display the old error)
    // - No results and no error:
    //     we have nothing to show, and are probably about to dispatch the load action (in the useEffect). Show a loading spinner in the meantime
    if((loading && (!result || result.length === 0)) ||
       (loading && error) ||
       (!result && !error)) {
        content = <CircularProgress size={32} className={classes.loader}/>;
    } else if(error) {
        notification = <Notification variant='error' appearance='inline'>
            <Typography variant='body1'>
                <FormattedMessage {...messages.error} values={{ link: <ExternalLink type='support' /> }} />
            </Typography>
        </Notification>
    } else if(result.length === 0) {
        content = <EmptyState/>;
    } else {
        if(packageList.length === 0) {
            notification = <Notification variant='info'
                    appearance='inline'
                    onClose={() => setSearch('')}
                    classes={{root: classes.notification}}
                    closeAriaLabel={intl.formatMessage(messages.findPackagesReset)}>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.noResults}/>
                </Typography>
            </Notification>;
        }

        // If we omit the list when the length is zero then the page size state is lost. By leaving the empty
        // list in the page we get the correct page size back when you clear the search filters.
        content = <DataPackagesList packageList={packageList} loading={loading}/>
    }

    // Only show the search box once we have loaded the initial set of data packages. If that set is empty then the
    // user gets the empty state and we are done.
    if(result && result.length > 0) {
        searchComponent = <SearchBox label={messages.findPackages}
                                     placeholder={messages.findPackagesPlaceholder}
                                     search={search}
                                     setSearch={setSearch}
                                     className={classes.searchOption}
                          />;
    }

    const backLinkProps = useFeatureCheck(features.NGD_DOWNLOADS) ? {} : {
        backPath: routePaths.premiumDownloads,
        backLabel: messages.premiumDownloads,
    };
    return <FeatureCheck feature={features.PREMIUM}>
        <ContentHeader
            title={messages.title}
            {...backLinkProps}>
            <Link path={routePaths.supportDownload} className={classes.faqLinkContainer}>
                <HelpIcon width={24} height={24} className={classes.icon} aria-hidden='true' />
                <Typography variant='body1'>
                    <FormattedMessage {...messages.faqLink} />
                </Typography>
            </Link>
        </ContentHeader>
        <div className={classes.contentWithMobilePadding}>
            {toastMessage}
            {searchComponent}
            <div aria-live='polite'>
                {notification}
                {content}
            </div>
        </div>
    </FeatureCheck>;
}
