import React from 'react';
import {CommonDialog} from "omse-components";
import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'ProductLimitDialog.title',
        defaultMessage: 'Project limit reached',
        description: 'Label dialog title'
    },
    body: {
        id: 'ProductLimitDialog.body',
        defaultMessage: `<p>Organisations and individuals may only have up to 100 Data Hub projects.</p>
            <p>Before you can create another project you’ll need to delete an existing one. You can do that by viewing a project you no longer need, opening the <b>Actions</b> menu and selecting <b>Delete project</b>.</p>`,
        description: 'Label dialog body'
    }
});

export default function ProjectLimitDialog(props) {
    const {handleClose} = props;
    return <CommonDialog
        title={messages.title} 
        onClose={handleClose}>
        <FormattedMessage {...messages.body} values={{
             p: (...msg) => <p>{msg}</p>,
             b: (...msg) => <b>{msg}</b>
        }} />
    </CommonDialog>;
}