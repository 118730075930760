export const PREFIX = "action:organisation:";
export const LOAD_ORG_USERS_ACTION = PREFIX + 'users:load';
export const LOADED_ORG_USERS_ACTION = PREFIX + 'users:loaded';
export const INVITE_USER = PREFIX + 'user:invite';
export const CANCEL_INVITE = PREFIX + 'user:cancelInvitation';
export const SET_ROLE = PREFIX + 'user:setRole';
export const REVOKE_USER = PREFIX + 'user:revokeUser';
export const INVITATION_RESPONSE = PREFIX + 'user:invitationResponse';
export const SET_NAME = PREFIX + 'setName';
export const SELECT_ORG = PREFIX + 'selectOrg';
export const SET_ORG_FROM_SEARCH = PREFIX + 'setOrgFromSearch';
export const SET_SEARCH_FROM_ORG = PREFIX + 'setSearchFromOrg';

export function getOrganisationUsers(skipOpenDataOrgs = false, skipOSInternalOrgs = false) {
    return {
        type: LOAD_ORG_USERS_ACTION,
        skipOpenDataOrgs,
        skipOSInternalOrgs
    }
}

export function invite(email, role) {
    return {
        type: INVITE_USER,
        email,
        role
    }
}

export function cancelInvitation(contactId) {
    return {
        type: CANCEL_INVITE,
        contactId
    }
}

export function setRole(contactId, role, sendEmail) {
    return {
        type: SET_ROLE,
        contactId,
        role,
        sendEmail
    }
}

export function revokeUser(contactId, sendEmail) {
    return {
        type: REVOKE_USER,
        contactId,
        sendEmail
    }
}

export function invitationResponse(accept) {
    return {
        type: INVITATION_RESPONSE,
        accept
    }
}

export function setName(name, reason) {
    return {
        type: SET_NAME,
        name,
        reason
    }
}

export function selectOrganisation(organisation) {
    return {
        type: SELECT_ORG,
        organisation
    }
}

export function setSearchFromOrg() {
    return {
        type: SET_SEARCH_FROM_ORG
    }
}

export function setOrgFromSearch() {
    return {
        type: SET_ORG_FROM_SEARCH
    }
}