import React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {DEV_MODE, LIVE_MODE} from "../../shared/project-modes";
import {createUseStyles} from 'react-jss';
import {osColour, contentPadding} from "omse-components";
import {defineMessages, useIntl} from 'react-intl';
import useMode from "../hooks/useMode";

const messages = defineMessages({
    liveProjects: {
        id: 'ModeControl.liveProjects',
        defaultMessage: 'Live projects',
        description: 'Label for the live projects tab'
    },
    devProjects: {
        id: 'ModeControl.devProjects',
        defaultMessage: 'Development projects',
        description: 'Label for the development projects tab'
    }
});

const styles = createUseStyles(theme => ({
    appbar: {
        color: 'white',
        backgroundColor: osColour.primary.berry,
        border: 0,
        boxShadow: 'none',
        zIndex: 1,
        padding: 0,
        paddingLeft: contentPadding.left,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0
        }
    },
    tabsRoot: {
        minHeight: 36
    },
    tab: {
        minWidth: 142,
        minHeight: '36px !important',
        margin: 6,
        padding: '0 12px',
        opacity: 1,
        fontSize: theme.typography.body1.fontSize,
        '&:hover:not($tabSelected)': {
            boxShadow: '0 6px 0 0 ' + osColour.primary.darkerBerry,
            color: osColour.neutral.mist
        }
    },
    tabSelected: {
        fontWeight: 600,
        backgroundColor: osColour.primary.darkerBerry,
        boxShadow: '0 0 0 6px ' + osColour.primary.darkerBerry
    }
}));

export default function ModeControl() {
    const classes = styles();
    const intl = useIntl();
    const [mode, setMode] = useMode();

    let selectedIndex = 0;
    if(mode === DEV_MODE) {
        selectedIndex = 1;
    }

    function onChange(event, newIndex) {
        let newMode = LIVE_MODE;
        if(newIndex) {
            newMode = DEV_MODE;
        }
        setMode(newMode);
    }

    const tabClasses = {
        textColorInherit: classes.tab,
        selected: classes.tabSelected
    }

    return <AppBar position='static' classes={{ root: classes.appbar }}
    >
        <Tabs value={selectedIndex}
              onChange={onChange}
              aria-label="select mode"
              classes={{root: classes.tabsRoot}}
              textColor="inherit"
        >
            <Tab label={intl.formatMessage(messages.liveProjects)}
                 classes={tabClasses}
                 id='select-mode-live'
                 aria-controls='select-mode-live'/>
            <Tab label={intl.formatMessage(messages.devProjects)}
                 classes={tabClasses}
                 id='select-mode-development'
                 aria-controls='select-mode-development'/>
        </Tabs>
    </AppBar>
}

ModeControl.propTypes = {
};
