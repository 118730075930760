import {defineMessages} from 'react-intl';

/*
    Messages here should use an ID which is the camel-case version of the APIs Data Hub ID. The rest is taken care of
    automatically.
 */

const apiMessages = defineMessages({
    wmts: {
        id: 'ServiceStatus.wmtsApiName',
        defaultMessage: 'OS Maps API',
        description: 'API name for  API'
    },
    vts: {
        id: 'ServiceStatus.vtsApiName',
        defaultMessage: 'OS Vector Tile API',
        description: 'API name for Vector Tile API'
    },
    wfs: {
        id: 'ServiceStatus.wfsApiName',
        defaultMessage: 'OS Features API',
        description: 'API name for Features API'
    },
    ofa: {
        id: 'ServiceStatus.ofaApiName',
        defaultMessage: 'OS NGD API – Features',
        description: 'API name for OS NGD API – Features'
    },
    ota: {
        id: 'ServiceStatus.otaApiName',
        defaultMessage: 'OS NGD API – Tiles',
        description: 'API name for OS NGD API – Tiles'
    },
    places: {
        id: 'ServiceStatus.placesApiName',
        defaultMessage: 'OS Places API',
        description: 'API name for Places API'
    },
    match: {
        id: 'ServiceStatus.matchApiName',
        defaultMessage: 'OS Match & Cleanse API',
        description: 'API name for Match & Cleanse API'
    },
    lids: {
        id: 'ServiceStatus.lidsApiName',
        defaultMessage: 'OS Linked Identifiers API',
        description: 'API name for Linked Identifiers API'
    },
    names: {
        id: 'ServiceStatus.namesApiName',
        defaultMessage: 'OS Names API',
        description: 'API name for Names API'
    },
    downloads: {
        id: 'ServiceStatus.downloadsApiName',
        defaultMessage: 'OS Downloads API',
        description: 'API name for Downloads API'
    },
    portal: {
        id: 'ServiceStatus.dataHubPortalApiName',
        defaultMessage: 'OS Data Hub',
        description: 'Name for Data Hub Portal website'
    },
    errorsOmissions: {
        id: 'ServiceStatus.errorsOmissionsApiName',
        defaultMessage: 'Error reporting',
        description: 'Name for the errors and omissions service'
    },
    test: {
        id: 'ServiceStatus.test',
        defaultMessage: 'Test API',
        description: 'Name for the test API'
    }
});

const apiRequestMessages = defineMessages({
    downloadsApi: {
        id: 'ServiceStatus.downloadsApi',
        defaultMessage: 'Downloads API',
        description: 'Name for the Downloads API request'
    },
    lidsApi: {
        id: 'ServiceStatus.lidsApi',
        defaultMessage: 'LIDS Identifier',
        description: 'Name for the LIDS API request'
    },
    lidsFeatureType: {
        id: 'ServiceStatus.lidsFeatureType',
        defaultMessage: 'LIDS FeatureType',
        description: 'Name for the FeatureType API request'
    },
    lidsIdentifierType: {
        id: 'ServiceStatus.lidsIdentifierType',
        defaultMessage: 'LIDS IdentifierType',
        description: 'Name for the LIDS IdentifierType API request'
    },
    lidsProductVersionInfo: {
        id: 'ServiceStatus.lidsProductVersionInfo:',
        defaultMessage: 'LIDS ProductVersionInfo',
        description: 'Name for the LIDS ProductVersionInfo API request'
    },
    placesFind: {
        id: 'ServiceStatus.placesFind',
        defaultMessage: 'Places Find',
        description: 'Name for the Places Find API request'
    },
    placesPostcode: {
        id: 'ServiceStatus.placesPostcode',
        defaultMessage: 'Places Postcode',
        description: 'Name for the Places Postcode API request'
    },
    placesUprn: {
        id: 'ServiceStatus.placesUprn',
        defaultMessage: 'Places UPRN',
        description: 'Name for the Places UPRN API request'
    },
    placesNearest: {
        id: 'ServiceStatus.placesNearest',
        defaultMessage: 'Places Nearest',
        description: 'Name for the Places Nearest API request'
    },
    placesRadius: {
        id: 'ServiceStatus.placesRadius',
        defaultMessage: 'Places Radius',
        description: 'Name for the Places Radius API request'
    },
    placesBbox: {
        id: 'ServiceStatus.placesBbox',
        defaultMessage: 'Places BBox',
        description: 'Name for the Places BBox API request'
    },
    placesPolygon: {
        id: 'ServiceStatus.placesPolygon',
        defaultMessage: 'Places Polygon',
        description: 'Name for the Places Polygon API request'
    },
    matchMatch: {
        id: 'ServiceStatus.matchMatch',
        defaultMessage: 'Match',
        description: 'Name for the Match & Cleanse Match API request'
    },
    namesFind: {
        id: 'ServiceStatus.namesApi',
        defaultMessage: 'Names Find',
        description: 'Name for the Names API request'
    },
    namesNearest: {
        id: 'ServiceStatus.namesNearest',
        defaultMessage: 'Names Nearest',
        description: 'Name for the Names Nearest API request'
    },
    vtsGetCapabilities27700: {
        id: 'ServiceStatus.vts27700GetCapabilities',
        defaultMessage: 'VTS GetCapabilities 27700',
        description: 'Name for the VTS GetCapabilities 27700 API request'
    },
    vtsGetCapabilities3857: {
        id: 'ServiceStatus.vts3857GetCapabilities',
        defaultMessage: 'VTS GetCapabilities 3857',
        description: 'Name for the VTS GetCapabilities 3857 API request'
    },
    vtsTile27700: {
        id: 'ServiceStatus.vtsApi',
        defaultMessage: 'VTS Tile 27700',
        description: 'Name for the VTS API request'
    },
    vtsTile3857: {
        id: 'ServiceStatus.vts3857Tile',
        defaultMessage: 'VTS Tile 3857',
        description: 'Name for the VTS Tile 3857 API request'
    },
    vtsBoundaries27700: {
        id: 'ServiceStatus.vts27700Boundaries',
        defaultMessage: 'VTS Boundaries 27700',
        description: 'Name for the VTS Boundaries 27700 API request'
    },
    vtsBoundaries3857: {
        id: 'ServiceStatus.vts3857Boundaries',
        defaultMessage: 'VTS Boundaries 3857',
        description: 'Name for the VTS Boundaries 3857 API request'
    },
    vtsGreenspace27700: {
        id: 'ServiceStatus.vts27700Greenspace',
        defaultMessage: 'VTS Greenspace 27700',
        description: 'Name for the VTS Greenspace 27700 API request'
    },
    vtsGreenspace3857: {
        id: 'ServiceStatus.vts3857Greenspace',
        defaultMessage: 'VTS Greenspace 3857',
        description: 'Name for the VTS Greenspace 3857 API request'
    },
    vtsSites27700: {
        id: 'ServiceStatus.vts27700Sites',
        defaultMessage: 'VTS Sites 27700',
        description: 'Name for the VTS Sites 27700 API request'
    },
    vtsSites3857: {
        id: 'ServiceStatus.vts3857Sites',
        defaultMessage: 'VTS Sites 3857',
        description: 'Name for the VTS Sites 3857 API request'
    },
    vtsHighways27700: {
        id: 'ServiceStatus.vts27700Highways',
        defaultMessage: 'VTS Highways 27700',
        description: 'Name for the VTS Highways 27700 API request'
    },
    vtsHighways3857: {
        id: 'ServiceStatus.vts3857Highways',
        defaultMessage: 'VTS Highways 3857',
        description: 'Name for the VTS Highways 3857 API request'
    },
    vtsStyles27700: {
        id: 'ServiceStatus.vts27700Styles',
        defaultMessage: 'VTS Styles 27700',
        description: 'Name for the VTS Styles 27700 API request'
    },
    vtsStyles3857: {
        id: 'ServiceStatus.vtsStyles3857',
        defaultMessage: 'VTS Styles 3857',
        description: 'Name for VTS Styles 3857 API request'
    },
    vtsWater27700: {
        id: 'ServiceStatus.vts27700water',
        defaultMessage: 'VTS Water 27700',
        description: 'Name for the VTS Water 27700 API request'
    },
    vtsWater3857: {
        id: 'ServiceStatus.vtsWater3857',
        defaultMessage: 'VTS Water 3857',
        description: 'Name for VTS Water 3857 API request'
    },
    vtsPaths27700: {
        id: 'ServiceStatus.vts27700Paths',
        defaultMessage: 'VTS Paths 27700',
        description: 'Name for the VTS Paths 27700 API request'
    },
    vtsPaths3857: {
        id: 'ServiceStatus.vts3857Paths',
        defaultMessage: 'VTS Paths 3857',
        description: 'Name for the VTS Paths 3857 API request'
    },
    wfsGetCapabilities: {
        id: 'ServiceStatus.wfsGetCapabilities',
        defaultMessage: 'WFS GetCapabilities',
        description: 'Name for the WFS GetCapabilities API request'
    },
    wfsGetFeature: {
        id: 'ServiceStatus.wfsApi',
        defaultMessage: 'WFS GetFeature',
        description: 'Name for the WFS GetFeature API request'
    },
    wfsGetFeaturePremium: {
        id: 'ServiceStatus.wfsGetFeature Premium',
        defaultMessage: 'WFS GetFeature Premium',
        description: 'Name for the WFS GetFeature Premium API request'
    },
    wfsDescribeFeatureType: {
        id: 'ServiceStatus.wfsDescribeFeatureType',
        defaultMessage: 'WFS DescribeFeatureType',
        description: 'Name for the WFS DescribeFeatureType API request'
    },
    wmtsGetTileRoad3857: {
        id: 'ServiceStatus.wmtsApi',
        defaultMessage: 'WMTS GetTile Road 3857',
        description: 'Name for the WMTS GetTile Road 3857 request'
    },
    wmtsGetTileRoad27700: {
        id: 'ServiceStatus.wmtsGetTileRoad27700',
        defaultMessage: 'WMTS GetTile Road 27700',
        description: 'Name for the sssssssssss API request'
    },
    wmtsGetCapabilities: {
        id: 'ServiceStatus.wmtsGetCapabilities',
        defaultMessage: 'WMTS GetCapabilities',
        description: 'Name for the WMTS GetCapabilities API request'
    },
    wmtsGetTileOutdoor27700: {
        id: 'ServiceStatus.wmtsGetTileOutdoor27700',
        defaultMessage: 'WMTS GetTile Outdoor 27700',
        description: 'Name for the WMTS GetTile Outdoor 27700 API request'
    },
    wmtsGetTileOutdoor3857: {
        id: 'ServiceStatus.wmtsGetTileOutdoor3857',
        defaultMessage: 'WMTS GetTile Outdoor 3857',
        description: 'Name for the WMTS GetTile Outdoor 3857'
    },
    wmtsGetTileLight27700: {
        id: 'ServiceStatus.wmtsGetTileLight27700',
        defaultMessage: 'WMTS GetTile Light 27700',
        description: 'Name for the WMTS GetTile Light 27700 API request'
    },
    wmtsGetTileLight3857: {
        id: 'ServiceStatus.wmtsGetTileLight3857',
        defaultMessage: 'WMTS GetTile Light 3857',
        description: 'Name for the WMTS GetTile Light 3857 API request'
    },
    wmtsGetTileLeisure27700: {
        id: 'ServiceStatus.wmtsGetTileLeisure27700',
        defaultMessage: 'WMTS GetTile Leisure 27700',
        description: 'Name for the WMTS GetTile Leisure 27700 API request'
    },
    wmtsZxyGetTileRoad27700: {
        id: 'ServiceStatus.wmtsZxyGetTileRoad27700',
        defaultMessage: 'ZXY GetTile Road 27700',
        description: 'Name for the ZXY GetTile Road 27700 API request'
    },
    wmtsZxyGetTileRoad3857: {
        id: 'ServiceStatus.wmtsZxyGetTileRoad3857',
        defaultMessage: 'ZXY GetTile Road 3857',
        description: 'Name for ZXY GetTile Road 3857 API request'
    },
    wmtsZxyGetTileOutdoor27700: {
        id: 'ServiceStatus.wmtsZxyGetTileOutdoor27700',
        defaultMessage: 'ZXY GetTile Outdoor 27700',
        description: 'Name for the ZXY GetTile Outdoor 27700 API request'
    },
    wmtsZxyGetTileOutdoor3857: {
        id: 'ServiceStatus.wmtsZxyGetTileOutdoor3857',
        defaultMessage: 'ZXY GetTile Outdoor 3857',
        description: 'Name for ZXY GetTile Outdoor 3857 API request'
    },
    wmtsZxyGetTileLight27700: {
        id: 'ServiceStatus.wmtsZxyGetTileLight27700',
        defaultMessage: 'ZXY GetTile Light 27700',
        description: 'Name for the ZXY GetTile Light 27700 API request'
    },
    wmtsZxyGetTileLight3857: {
        id: 'ServiceStatus.wmtsZxyGetTileLight3857',
        defaultMessage: 'ZXY GetTile Light 3857',
        description: 'Name for ZXY GetTile Light 3857 API request'
    },
    wmtsZxyGetTileLeisure27700: {
        id: 'ServiceStatus.wmtsZxyGetTileLeisure27700',
        defaultMessage: 'ZXY GetTile Leisure 27700',
        description: 'Name for the ZXY GetTile Leisure 27700 API request'
    },
    errorsAndOmissions: {
        id: 'ServiceStatus.errorsAndOmissionsApi',
        defaultMessage: 'Errors and Omissions',
        description: 'Name for the Errors and Omissions request'
    },
    portal: {
        id: 'ServiceStatus.portal',
        defaultMessage: 'Portal',
        description: 'Name for the Portal request'
    },
    portalSignUpOrLogIn: {
        id: 'ServiceStatus.portalSignUpOrLogIn',
        defaultMessage: 'Sign Up or Log In',
        description: 'Name for the Portal Sign Up or Log In request'
    },
    portalPasswordReset: {
        id: 'ServiceStatus.portalPasswordReset',
        defaultMessage: 'Password Reset',
        description: 'Name for the Portal Password Reset API request'
    },
    testRequest1: {
        id: 'ServiceStatus.testRequest1',
        defaultMessage: 'Test request 1',
        description: 'Name for test request 1'
    },
    testRequest2: {
        id: 'ServiceStatus.testRequest2',
        defaultMessage: 'Test request 2',
        description: 'Name for test request 2'
    },
    ofaGetApi: {
        id: 'ServiceStatus.ofaGetApi',
        defaultMessage: 'OFA Get API',
        description: 'Name for the OFA Get API request'
    },
    ofaGetConformance: {
        id: 'ServiceStatus.ofaGetConformance',
        defaultMessage: 'OFA Get conformance',
        description: 'Name for the OFA Get conformance API request'
    },
    ofaGetCollections: {
        id: 'ServiceStatus.ofaGetCollections',
        defaultMessage: 'OFA Get collections',
        description: 'Name for the OFA Get collections API request'
    },
    ofaGetBuildinglineItems: {
        id: 'ServiceStatus.ofaGetBuildinglineItems',
        defaultMessage: 'OFA Get BuildingLine items',
        description: 'Name for the OFA Get BuildingLine items API request'
    },
    ofaGetNamedpointItems: {
        id: 'ServiceStatus.ofaGetNamedpointItems',
        defaultMessage: 'OFA Get NamedPoint items',
        description: 'Name for the OFA Get NamedPoint items API request'
    },
    ofaGetSiteItems: {
        id: 'ServiceStatus.ofaGetSiteItems',
        defaultMessage: 'OFA Get Site items',
        description: 'Name for the OFA Get Site items API request'
    },
    ofaGetStreetItems: {
        id: 'ServiceStatus.ofaGetStreetItems',
        defaultMessage: 'OFA Get Street items',
        description: 'Name for the OFA Get Street items API request'
    },
    ofaGetWaternodeItems: {
        id: 'ServiceStatus.ofaGetWaternodeItems',
        defaultMessage: 'OFA Get WaterNode items',
        description: 'Name for the OFA Get WaterNode items API request'
    },
    ofaGetIntertidallineSchema: {
        id: 'ServiceStatus.ofaGetIntertidallineSchema',
        defaultMessage: 'OFA Get InterTidalLine schema',
        description: 'Name for the OFA Get InterTidalLine schema API request'
    },
    ofaGetStructureItem: {
        id: 'ServiceStatus.ofaGetStructureItem',
        defaultMessage: 'OFA Get Structure item',
        description: 'Name for the OFA Get Structure item API request'
    },
    ofaGetRoadItemsBbox: {
        id: 'ServiceStatus.ofaGetRoadItemsBbox',
        defaultMessage: 'OFA Get Road items bbox',
        description: 'Name for the OFA Get Road items with bbox API request'
    },
    ofaGetBuildingpartItemsCqlDatetime: {
        id: 'ServiceStatus.ofaGetBuildingpartItemsCqlDatetime',
        defaultMessage: 'OFA Get BuildingPart items with CQL and datetime',
        description: 'Name for the OFA Get Road items with CQL and datetime API request'
    },
    otaGetApi: {
        id: 'ServiceStatus.otaGetApi',
        defaultMessage: 'OTA Get API',
        description: 'Name for the OTA Get API request'
    },
    otaGetConformance: {
        id: 'ServiceStatus.otaGetConformance',
        defaultMessage: 'OTA Get conformance',
        description: 'Name for the OTA Get conformance API request'
    },
    otaGetCollections: {
        id: 'ServiceStatus.otaGetCollections',
        defaultMessage: 'OTA Get collections',
        description: 'Name for the OTA Get collections API request'
    },
    otaGetTilematrixset: {
        id: 'ServiceStatus.otaGetTilematrixset',
        defaultMessage: 'OTA Get TileMatrixSet',
        description: 'Name for the OTA Get TileMatrixSet API request'
    },
    otaGetNgdBase3857Tile: {
        id: 'ServiceStatus.otaGetNgdBase3857Tile',
        defaultMessage: 'OTA Get API Tile for ngd-base (3857)',
        description: 'Name for the OTA Get ngd-base 3857 Tile API request'
    },
    otaGetNgdBase27700Tile: {
        id: 'ServiceStatus.otaGetNgdBase27700Tile',
        defaultMessage: 'OTA Get API Tile for ngd-base (27700)',
        description: 'Name for the OTA Get ngd-base 27700 Tile API request'
    },
    otaGetAsuBdy3857Tile: {
        id: 'ServiceStatus.otaGetAsuBdy3857Tile',
        defaultMessage: 'OTA Get API Tile for asu-bdy (3857)',
        description: 'Name for the OTA Get asu-bdy 3857 Tile API request'
    },
    otaGetAsuBdy27700Tile: {
        id: 'ServiceStatus.otaGetAsuBdy27700Tile',
        defaultMessage: 'OTA Get API Tile for asu-bdy (27700)',
        description: 'Name for the OTA Get asu-bdy 27700 Tile API request'
    },
    otaGetWtrCtch3857Tile: {
        id: 'ServiceStatus.otaGetWtrCtch3857Tile',
        defaultMessage: 'OTA Get API Tile for wtr-ctch (3857)',
        description: 'Name for the OTA Get wtr-ctch 3857 Tile API request'
    },
    otaGetTrnNtwkRailway27700Tile: {
        id: 'ServiceStatus.otaGetTrnNtwkRailway27700Tile',
        defaultMessage: 'OTA Get API Tile for trn-ntwk-railway (27700)',
        description: 'Name for the OTA Get trn-ntwk-railway 27700 Tile API request'
    },
    otaGetTrnNtwkRailway3857Tile: {
        id: 'ServiceStatus.otaGetTrnNtwkRailway3857Tile',
        defaultMessage: 'OTA Get API Tile for trn-ntwk-railway (3857)',
        description: 'Name for the OTA Get trn-ntwk-railway 3857 Tile API request'
    },
    otaGetWtrCtch27700Tile: {
        id: 'ServiceStatus.otaGetWtrCtch27700Tile',
        defaultMessage: 'OTA Get API Tile for wtr-ctch (27700)',
        description: 'Name for the OTA Get wtr-ctch 27700 Tile API request'
    },
    otaGetNgdBaseStyles: {
        id: 'ServiceStatus.otaGetNgdBaseStyles',
        defaultMessage: 'OTA Get styles for ngd-base',
        description: 'Name for the OTA Get ngd-base styles API request'
    },
    otaGetAsuBdyStyles: {
        id: 'ServiceStatus.otaGetAsuBdyStyles',
        defaultMessage: 'OTA Get styles for asu-bdy',
        description: 'Name for the OTA Get asu-bdy styles API request'
    },
    otaGetTrnNtwkRailwayStyles: {
        id: 'ServiceStatus.otaGetTrnNtwkRailwayStyles',
        defaultMessage: 'OTA Get styles for trn-ntwk-railway',
        description: 'Name for the OTA Get trn-ntwk-railway styles API request'
    },
    otaGetWtrCtchStyles: {
        id: 'ServiceStatus.otaGetWtrCtchStyles',
        defaultMessage: 'OTA Get styles for wtr-ctch',
        description: 'Name for the OTA Get wtr-ctch styles API request'
    },
     wfsArchiveGetCapabilities: {
         id: 'ServiceStatus.wfsArchiveGetCapabilities',
         defaultMessage: 'WFS Archive GetCapabilities',
         description: 'Name for the WFS Archive GetCapabilities API request'
     },
     wfsArchiveGetFeature: {
         id: 'ServiceStatus.wfsArchiveApi',
         defaultMessage: 'WFS Archive GetFeature',
         description: 'Name for the WFS Archive GetFeature API request'
     },
     wfsArchiveGetFeaturePremium: {
         id: 'ServiceStatus.wfsArchiveGetFeature Premium',
         defaultMessage: 'WFS Archive GetFeature Premium',
         description: 'Name for the WFS Archive GetFeature Premium API request'
     },
     wfsArchiveDescribeFeatureType: {
         id: 'ServiceStatus.wfsArchiveDescribeFeatureType',
         defaultMessage: 'WFS Archive DescribeFeatureType',
         description: 'Name for the WFS Archive DescribeFeatureType API request'
     }
});

/** Get a message which localises to the human readable name for a given API. */
export function getMessageForApi(apiId) {
    return apiMessages[getMessageIdForDataHubId(apiId)];
}

/** Get a message which localises to the human readable name for a given API request. */
export function getMessageForApiRequest(apiRequestId) {
    return apiRequestMessages[getMessageIdForDataHubId(apiRequestId)];
}

function getMessageIdForDataHubId(dataHubId) {
    // Convert dashes to camel case. E.g. os-data-hub => osDataHubApiName.
    return `${dataHubId.replace(/-(\w)/g, (match, letter) => letter.toUpperCase())}`;
}