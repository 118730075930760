import React from 'react';
import PropTypes from 'prop-types';
import {useIntl, FormattedMessage, defineMessages} from 'react-intl';
import {ReactComponent as SearchIcon} from "./icons/search.svg";
import {ReactComponent as CloseIcon} from "./icons/close-large.svg";
import {InputLabel, Input, InputAdornment, Button, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {osColour, simulateExternallyAppliedFocusStyle} from 'omse-components';
import classNames from 'classnames';

const styles = createUseStyles(theme => ({
    search: {
        background: osColour.neutral.clouds,
        padding: 0,
        width: '100%',
        '&:focus': {
            fontStyle: 'italic'
        },
        '& ::-webkit-search-cancel-button': {
            display: 'none'
        },
        '&.Mui-focused': simulateExternallyAppliedFocusStyle
    },
    searchMaxWidth: {
        maxWidth: 400
    },
    searchInput: {
        padding: '7px 0',
        lineHeight: 1.5,
        '&::placeholder': {
            fontStyle: 'italic',
            color: osColour.neutral.stone,
            opacity: 1,
            '-webkit-font-smoothing': 'antialiased'
        },
        '&:focus': {
            boxShadow: 'none !important'
        }
    },
    searchInputAdornment: {
        margin: 0,
        padding: '8px 6px 6px 10px',
        height: 'auto',
    },
    button: {
        height: 28,
        width: 28,
        minWidth: 28,
        padding: 0,
        marginRight: 10,
        '&:hover': {
            background: 'transparent'
        }
    },
    closeIcon: {
        fontSize: '1.75rem',
        color: osColour.neutral.stone
    },
    searchLabel: {
        marginBottom: theme.spacing(1)
    },
    searchError:{
        position: "absolute",
        padding: theme.spacing(1),
        width: theme.spacing(39),
        backgroundColor: osColour.neutral.white,
        zIndex: 100,
        border: '1px solid black'
    },
    searchErrorContainer:{
        position: "relative",
        width: '100%',
    }
}));

const messages = defineMessages({
    clear: {
        id: 'SearchBox.clear',
        defaultMessage: 'Clear search',
        description: 'Aria text for the search box reset button'
    }
});

export default function SearchBox(props) {
    const {label, placeholder, search, setSearch, autofocus, className, limitWidth, disabled, error} = props;
    const intl = useIntl();
    const classes = styles();

    const rootClasses = classNames({
        [classes.search]: true,
        [classes.searchMaxWidth]: limitWidth
    });

    return <div className={className}>
        {
            label &&
            <InputLabel htmlFor="search" className={classes.searchLabel}>
                <FormattedMessage {...label}/>
            </InputLabel>
        }
        <Input type='search'
               id='search'
               classes={{
                   root: rootClasses,
                   input: classes.searchInput
               }}
               placeholder={intl.formatMessage(placeholder)}
               onChange={event => setSearch(event.target.value)}
               value={search}
               autoFocus={autofocus}
               disableUnderline={true}
               disabled={disabled}
               startAdornment={
                   <InputAdornment position='start' className={classes.searchInputAdornment}>
                       <SearchIcon height={24} width={24}/>
                   </InputAdornment>
               }
               endAdornment={
                   search.length > 0 &&
                   <InputAdornment position='end'>
                       <Button onClick={() => setSearch('')}
                               className={classes.button}
                               disableRipple={true}
                               disableFocusRipple={true}
                               aria-label={intl.formatMessage(messages.clear)}>
                           <CloseIcon title={intl.formatMessage(messages.clear)} aria-hidden={true} width={24} height={24} className={classes.closeIcon}/>
                       </Button>
                   </InputAdornment>
               }/>
        {error && <div className={classes.searchErrorContainer}>
            <Typography className={classes.searchError} variant={'body1'}>
            <FormattedMessage {...error} />
            </Typography>
        </div>}
    </div>;

}

SearchBox.propTypes = {
    label: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }),
    placeholder: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }).isRequired,
    search: PropTypes.string.isRequired,
    setSearch: PropTypes.func.isRequired,
    autofocus: PropTypes.bool,
    className: PropTypes.string,
    limitWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.object
};
