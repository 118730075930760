import {createActionReducer, createActionMiddleware} from 'omse-components';
import {SET_PROJECT_MODE, LOADED_PROJECT_ACTION} from "./actions";
import {withOrganisation} from '../../util/organisation';

export const reducer = createActionReducer(SET_PROJECT_MODE);

function setupCall(action, store) {
    return withOrganisation({
        url: '/api/projects/' + action.projectId + '/mode',
        method: 'POST',
        body: {
            mode: action.mode
        }
    }, store);
}
function success(store, action) {
    // Now that the project has been updated, put it back into the store
    const { result } = store.getState().project.current;
    if(result && result.id === action.projectId) {
        const newResult = {
            ...result,
            mode: action.mode
        };
        store.dispatch({
            type: LOADED_PROJECT_ACTION,
            result: newResult
        });
    }
}

export const middleware = createActionMiddleware(SET_PROJECT_MODE, setupCall, success);
