import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {List, ListItem, ListItemText, Typography} from '@mui/material';
import {Notification} from 'omse-components';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {createUseStyles} from 'react-jss';
import styles from './styles';
import {fileMatches} from "../../../../../util/search/polygonCollectionSearch";

const myStyles = createUseStyles(theme => ({
    notification: {
        margin: theme.spacing(3),

        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1)
        }
    }
}));

const messages = defineMessages({
    noResults: {
        id: 'SearchResults.noResults',
        defaultMessage: 'No polygons match your search',
        description: 'Text shown when the search does not match any results'
    },
    reset: {
        id: 'SearchResults.reset',
        defaultMessage: 'Clear search',
        description: 'Aria text for the package search box reset button'
    },
});

export default function SearchResults(props) {
    const {searchResults, setSelectedFile, selectedFile, resetSearch} = props;
    const classes = styles();
    const myClasses = myStyles();
    const intl = useIntl();

    useEffect(() => {
        if(searchResults.length === 1) {
            const file = searchResults[0];
            if(!fileMatches(file, selectedFile)) {
                setSelectedFile(file);
            }
        }
    }, [searchResults, setSelectedFile, selectedFile]);

    if(searchResults.length === 0) {
        return <div className={classes.fileList}>
            <Notification variant='info'
                          appearance='inline'
                          onClose={resetSearch}
                          closeAriaLabel={intl.formatMessage(messages.reset)}
                          className={myClasses.notification}
            >
                <Typography variant='body1'>
                    <FormattedMessage {...messages.noResults}/>
                </Typography>
            </Notification>
        </div>;
    }

    return <List className={classes.fileList}>
        {
            searchResults.map(file => {
                let label = file.id;
                if(file.name) {
                    label = file.name + ' - ' + file.id;
                }
                const fullName = file.path + '/' + label;
                let disabled = false;
                if(file.polygonAvailable === false) {
                    disabled = true;
                }
                return <ListItem key={fullName}
                                 button
                                 onClick={() => setSelectedFile(file)}
                                 selected={fileMatches(file, selectedFile)}
                                 disabled={disabled}
                >
                    <ListItemText primary={fullName}/>
                </ListItem>;
            })
        }
    </List>;
}

SearchResults.propTypes = {
    searchResults: PropTypes.array.isRequired,
    selectedFile: PropTypes.object,
    setSelectedFile: PropTypes.func.isRequired,
    resetSearch: PropTypes.func.isRequired
};
