import OFACompletionSummary from "./OFACompletionSummary";
import React from "react";
import QGIS from "../QGIS";
import qgis0 from './qgis/qgis0.png';
import qgis1 from './qgis/qgis1.png';
import qgis2 from './qgis/qgis2.png';
import qgis3 from './qgis/qgis3.png';
import qgis4 from './qgis/qgis4.png';
import qgis5 from './qgis/qgis5.png';
import qgis6 from './qgis/qgis6.png';
import qgis7 from './qgis/qgis7.png';
import qgis8 from './qgis/qgis8.png';
import qgis9 from './qgis/qgis9.png';
import qgis10 from './qgis/qgis10.png';
import qgis11 from './qgis/qgis11.png';
import qgis12 from './qgis/qgis12.png';
import qgis13 from './qgis/qgis13.png';
import qgis14 from './qgis/qgis14.png';

const qgisImages = {
    qgis0,
    qgis1,
    qgis2,
    qgis3,
    qgis4,
    qgis5,
    qgis6,
    qgis7,
    qgis8,
    qgis9,
    qgis10,
    qgis11,
    qgis12,
    qgis13,
    qgis14,
};

const OFAQGIS = () => {
    return (
        <QGIS api='OS NGD API – Features' category='WFS / OGC API - Features' qgisImages={qgisImages}>
            <OFACompletionSummary software="QGIS"/>
        </QGIS>
    );
};

export default OFAQGIS;