import styled from '@emotion/styled';
import { osColour } from 'omse-components';
import { Typography } from '@mui/material';
import PlansCard from './PlanCard';
import NavButton from '../../components/NavButton';
import CatalogueTag from '../../components/CatalogueTag';
import { FormattedMessage, defineMessages } from 'react-intl';

const StyledContainer = styled('section')`
    padding: 4em 3em;
    background: ${osColour.primary.lightestBerry};
    @media (max-width: 600px) {
        padding: 2em 1em;
    }
    & > div {
        margin: 0 auto;
        padding: 0;
        max-width: 1400px;
    }
    & > div > div {
        margin: 2em 0 3em 0;
        padding: 0;
        display: grid;
        justify-items: stretch;
        gap: 2em;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        @media (max-width: 1200px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 750px) {
            grid-template-columns: 1fr;
        }
    }
`;

const StyledNavButton = styled(NavButton)`
    margin: 0 auto;
    display: block;
    font-weight: normal;
    @media (max-width: 750px) {
        width: 100%;
    }
`;

const planBannerMessages = defineMessages({
    title: {
        id: 'planBanner.title',
        defaultMessage: 'Plans and pricing',
        description: 'Title',
    },
    subtitle: {
        id: 'planBanner.subtitle',
        defaultMessage: 'Choose a subscription plan that\'s right for you and your organisation.',
        description: 'Subtitle',
    },
    ctaButton: {
        id: 'planBanner.ctaButton',
        defaultMessage: 'View plans and pricing',
        description: 'CTA Button Text',
    },
    openDataPlanTitle: {
        id: 'planBanner.openDataPlanTitle',
        defaultMessage: 'OpenData',
        description: 'Title for OpenData Plan',
    },
    openDataPlanSummary: {
        id: 'planBanner.openDataPlanSummary',
        defaultMessage: 'Free access to OpenData products and APIs, available to everyone.',
        description: 'Summary for OpenData Plan',
    },
    premiumDataPlanTitle: {
        id: 'planBanner.premiumDataPlanTitle',
        defaultMessage: 'Premium Data',
        description: 'Title for Premium Data Plan',
    },
    premiumDataPlanSummary: {
        id: 'planBanner.premiumDataPlanSummary',
        defaultMessage:
            'Access OS Premium Data products and APIs, get £1,000 of API transactions free each month.',
        description: 'Summary for Premium Data Plan',
    },
    publicSectorPlanTitle: {
        id: 'planBanner.publicSectorPlanTitle',
        defaultMessage: 'Public Sector',
        description: 'Title for Public Sector Plan',
    },
    publicSectorDataPlanSummary: {
        id: 'planBanner.publicSectorPlanSummary',
        defaultMessage:
            'Access OpenData, Premium Data products and APIs, available under the Public Sector Geospatial Agreement.',
        description: 'Summary for Public Sector Plan',
    },
    eAndInfraPlanTitle: {
        id: 'planBanner.eAndInfraPlanTitle',
        defaultMessage: 'Energy and Infrastructure',
        description: 'Title for Energy and Infrastructure Plan',
    },
    eAndInfraPlanSummary: {
        id: 'planBanner.eAndInfraPlanSummary',
        defaultMessage: 'For eligible businesses in the energy and infrastructure sector.',
        description: 'Summary for Energy & Infrastructure Plan',
    },
});

export default function PlanBanner() {
    return (
        <StyledContainer>
            <div>
                <Typography gutterBottom variant='h1' component="h2" sx={{ color: osColour.primary.berry }}>
                    <FormattedMessage {...planBannerMessages.title} />
                </Typography>
                <Typography
                    gutterBottom
                    variant='body1'
                    sx={{
                        color: osColour.neutral.charcoal,
                        fontSize: '20px',
                        fontFamily: 'OSGill, sans-serif'
                    }}
                >
                    <FormattedMessage {...planBannerMessages.subtitle} />
                </Typography>

                <div>
                    <PlansCard
                        accent={osColour.status.success}
                        tag={<CatalogueTag type='OSOPEN' size='sm' />}
                        title={planBannerMessages.openDataPlanTitle}
                        summary={planBannerMessages.openDataPlanSummary}
                    />

                    <PlansCard
                        accent={osColour.primary.berry}
                        tag={<CatalogueTag type='PREM' size='sm' />}
                        title={planBannerMessages.premiumDataPlanTitle}
                        summary={planBannerMessages.premiumDataPlanSummary}
                    />

                    <PlansCard
                        accent={osColour.primary.berry}
                        tag={<CatalogueTag type='PSGA' size='sm' />}
                        title={planBannerMessages.publicSectorPlanTitle}
                        summary={planBannerMessages.publicSectorDataPlanSummary}
                    />

                    <PlansCard
                        accent={osColour.primary.berry}
                        tag={<CatalogueTag type='COM3' size='sm' />}
                        title={planBannerMessages.eAndInfraPlanTitle}
                        summary={planBannerMessages.eAndInfraPlanSummary}
                    />
                </div>

                <StyledNavButton path='/plans' color='primary' variant='contained'>
                    <FormattedMessage {...planBannerMessages.ctaButton} />
                </StyledNavButton>
            </div>
        </StyledContainer>
    );
}
