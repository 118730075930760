import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ProductDependencyDialog from "./ProductDependencyDialog";
import ProductTermsDialog from "./ProductTermsDialog";

export default function ProductDialog(props) {
    const {onConfirm, onClose, contract, product, needsProductTerms, productDependencies} = props;
    const [showTerms, setShowTerms] = useState(needsProductTerms);
    const [showDeps, setShowDeps] = useState(productDependencies.length > 0);

    useEffect(() => {
        if(!showTerms && !showDeps) {
            onConfirm();
        }
    }, [onConfirm, showTerms, showDeps]);

    if(showTerms) {
        return <ProductTermsDialog contract={contract}
                                   product={product}
                                   onConfirm={() => setShowTerms(false)}
                                   onClose={onClose}/>
    }

    if(showDeps) {
        return <ProductDependencyDialog product={product}
                                        productDependencies={productDependencies}
                                        onConfirm={() => setShowDeps(false)}
                                        onClose={onClose}/>;
    }

    return null;
}

ProductDialog.propTypes = {
    contract: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    needsProductTerms: PropTypes.bool,
    productDependencies: PropTypes.array,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};
