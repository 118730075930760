import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import APIUsageStats from './apiProjects/APIUsageStats';
import NoProjects from './apiProjects/NoProjects';
import {connect} from "react-redux";
import {getProjects} from "../modules/projects";
import ProjectList from "./apiProjects/ProjectList";
import {getUserStats, USER_STATS_DETAIL_PROJECT} from "../modules/stats/actions";
import {contentPadding} from 'omse-components';
import CircularProgress from '@mui/material/CircularProgress';

const styles = theme => ({
    content: {
        flex: '1 1 auto',
        flexDirection: 'column'
    },
    list: {
        paddingLeft: contentPadding.left,
        paddingRight: contentPadding.right,
        maxWidth: contentPadding.maxWidth,
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile,
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    loading: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    }
});

export class APIProjects extends Component {
    loadProjects() {
        const {orgId} = this.props;
        this.props.getProjects();
        this.props.getUserStats(USER_STATS_DETAIL_PROJECT, null, orgId);
    }

    componentDidMount() {
        this.loadProjects();
    }

    componentDidUpdate(prevProps) {
        const {orgId} = this.props;
        if (prevProps.orgId !== orgId) {
            this.loadProjects();
        }
    }

    projectPageState() {
        const {loading, projects, userStats, classes} = this.props;

        if(loading) {
            return <CircularProgress size={32} className={classes.loading}/>;
        }

        if(!projects || projects.length === 0) {
            return <NoProjects/>;
        }
        return <ProjectList projects={projects} userStatsResult={userStats.result}/>;
    }

    render() {
        const {classes, projects, userStats} = this.props;

        return <div className={classes.content}>
            <APIUsageStats projects={projects}
                           userStats={userStats}/>
            <div className={classes.list}>
            {
                this.projectPageState()
            }
            </div>
        </div>;
    }
}

APIProjects.propTypes = {
    /** Injected by withStyles */
    classes: PropTypes.object,
    /** Injected by mapStateToProps */
    loading: PropTypes.bool.isRequired,
    /** Injected by mapStateToProps */
    projects: PropTypes.array,
    /** Injected by mapStateToProps */
    userStats: PropTypes.object.isRequired,
    /** Injected by connect */
    getProjects: PropTypes.func.isRequired,
    /** Injected by connect */
    getUserStats: PropTypes.func.isRequired
}

export function mapStateToProps(state) {
    const {result, loading} = state.projects.current;
    const userStats = state.stats.user;
    const orgId = state.organisation.current.id;

    return {
        projects: result,
        loading,
        userStats,
        orgId
    };
}

const styled = withStyles(styles)(APIProjects);
export default connect(mapStateToProps, {getProjects, getUserStats})(styled);