import { Fragment } from 'react';
import PropTypes from 'prop-types';

function handleClick(e) {
    if (e.target.hash.length > 1) {
        e.preventDefault();
        document.getElementById(e.target.hash.substr(1)).scrollIntoView();
    }
}

function Refs(props) {
    const { item, type } = props;
    if (item?.ref && item.ref.length > 0) {
        return (
            <sup>
                {item.ref.map((ref, i) => (
                    <Fragment key={`ref-${type}-${i}`}>
                        <a onClick={handleClick} href={`#${ref}`}>{ref}</a>{i < item.ref.length - 1 && ','}
                    </Fragment>
                ))}
            </sup>
        );
    } else {
        return false;
    }
}

Refs.propTypes = {
    item: PropTypes.object,
    type: PropTypes.string
}

export default Refs;