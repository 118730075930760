import React, {useEffect, useMemo} from "react";
import {Tabs, Tab, Box} from "@mui/material";
import {buttonClasses} from '@mui/material/Button';
import {DropDownMenu, osColour} from "omse-components";
import classNames from 'classnames';
import {useIntl} from "react-intl";
import PropTypes from "prop-types";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import {createUseStyles} from 'react-jss';

const myStyles = createUseStyles(theme => ({
    tabMenu: {
        flex: '1 1 auto',
        minWidth: 0,
        marginLeft: theme.spacing(2),
        borderBottom: `1px solid ${osColour.neutral.mist}`,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginBottom: theme.spacing(2),
        },
        '& button:first-of-type': {
            marginLeft: 0
        }
    },
    tabRoot: {
        padding: theme.spacing(1),
        marginLeft: theme.spacing(2),
        minHeight: 64,
        maxWidth: 'none',
        opacity: 1,
        ...theme.typography.h3,
        color: osColour.primary.berry,
        '&:hover': {
            color: osColour.primary.lighterBerry
        }
    },
    tabSelected: {
        fontWeight: 600
    },
    indicator: {
        backgroundColor: osColour.primary.berry,
        height: theme.spacing(0.5)
    },
    tabSelectControl: {
        marginBottom: theme.spacing(2),
        [`&.${buttonClasses.outlinedPrimary}:not(.${buttonClasses.disabled})`]: {
            border: `1px solid ${osColour.neutral.mist}`
        }
    }
}))

export default function TabGroup (props) {
    const {tabs, tabGroupAriaLabel, onTabChange, openTab} = props;
    const classes = myStyles();
    const theme = useTheme();
    const intl = useIntl();
    let tabMenu;
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const extraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [value, setValue] = React.useState(0);

    const tabHeadings = useMemo(() => {

        function tabClass (index) {
            return value === index ? classNames(classes.tabRoot, classes.tabSelected) : classes.tabRoot
        }

        return tabs.map((tab, i) => {
                return <Tab label={intl.formatMessage(tab.heading)}
                            key={i}
                            index={i}
                            id={i}
                            className={tabClass(i)}/>
        })
    }, [tabs, intl, value, classes])

    const tabComponents = useMemo(() => {

        const tab = tabs[value];
        if(!tab) {
            return null;
        }

        return <Box p={smallScreen ? 0 : 3}>
            {tab.component}
        </Box>
    }, [tabs, value, smallScreen])

    const handleTabChange = (event, tabIndex) => {
        setValue(tabIndex);
        if (onTabChange) {
            onTabChange(tabIndex);
        }
    }

    const handleMenuChange = item => {
        const tabIndex = item.value;
        if (tabIndex >= 0) {
            setValue(tabIndex);
            if (onTabChange) {
                onTabChange(tabIndex);
            }
        }
    }

    useEffect(() => {
        if (openTab >= 0) {
            setValue(openTab);
        }
    }, [openTab, setValue]);

    if (extraSmallScreen) {
        const menuItems = tabs.map((tab, tabIndex) => {
            return {label: intl.formatMessage(tab.heading), value: tabIndex};
        });

        tabMenu = (
            <DropDownMenu
                items={menuItems}
                value={value}
                onChange={handleMenuChange}
                buttonProps={{'aria-label': intl.formatMessage(tabGroupAriaLabel)}}
                buttonVariant='outlined'
                buttonId='package-tab-menu'
                buttonClassName={classes.tabSelectControl}
            />
        )
    } else {
        tabMenu = (
            <Tabs value={value}
                  onChange={handleTabChange}
                  aria-label={intl.formatMessage(tabGroupAriaLabel)}
                  className={classes.tabMenu}
                  TabIndicatorProps={{className: classes.indicator}}>
                {
                    tabHeadings
                }
            </Tabs>
        );
    }


    return <>
        {
            tabMenu
        }
        {
            tabComponents
        }
        </>
}

TabGroup.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        heading: PropTypes.shape({ // A react-intl message
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string.isRequired
        }).isRequired,
        component: PropTypes.node.isRequired
    })).isRequired,
    tabGroupAriaLabel: PropTypes.shape({ // A react-intl message
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }).isRequired,
    onTabChange: PropTypes.func,
    openTab: PropTypes.number
}