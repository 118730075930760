// A hook that can be used in conjunction with ModeControl to handle the mode (LIVE or DEV)
// of stats queries
import {useSelector} from 'react-redux';
import {DEV_MODE, LIVE_MODE} from "../../shared/project-modes";
import useSearchParam from "./useSearchParam";
import {hasManageProjectModePermission} from "../util/permissions";

export default function useMode() {
    let [mode, setMode] = useSearchParam('mode', LIVE_MODE);
    const userDetails = useSelector(state => state.user.current.result);

    if(userDetails) {
        // Check that we are using a valid mode
        let validModes = [LIVE_MODE];
        if(hasManageProjectModePermission(userDetails)) {
            validModes.push(DEV_MODE);
        }
        if(validModes.indexOf(mode) === -1) {
            mode = LIVE_MODE;
            setMode(LIVE_MODE);
        }
    }

    return [mode, setMode];
}