import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
    AddButton,
    InputBox,
    contentPadding,
    osColour,
    InputLabel,
    ExternalLink
} from "omse-components";
import {defineMessages, FormattedMessage} from 'react-intl';
import Button from '@mui/material/Button';
import {createUseStyles} from 'react-jss';
import userConstants from "../../constants/user"
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import {getPsgaOrgs} from '../../modules/psga/actions'
import AutoCompleteListBox from "../../components/AutoCompleteListBox";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import routePaths, {getLocation, PSGA_DESCRIPTION_URL, PSGA_REGISTRATION_URL} from "../../util/routes";
import {useHistory} from 'react-router';
import Link from '@mui/material/Link';
import { CRM_PSGA_PLAN } from '../../../shared/plans';

const nameMatcher = /^[\w-.#$%&()'/, ]+$/;

const messages = defineMessages({
    orgName: {
        id: 'PsgaOrgDetailsForm.orgName',
        defaultMessage: 'Public sector organisation name ',
        description: 'Title for the org name input'
    },
    jobTitle: {
        id: 'PsgaOrgDetailsForm.jobTitle',
        defaultMessage: 'Job title',
        description: 'Title for the job title input'
    },
    phone: {
        id: 'PsgaOrgDetailsForm.phone',
        defaultMessage: 'Your telephone number',
        description: 'Title for the phone number input'
    },
    orgMissing: {
        id: 'PsgaOrgDetailsForm.orgMissing',
        defaultMessage: 'Please enter an organisation name.',
        description: 'Message shown when the org name is incomplete'
    },
    orgNameValidation: {
        id: 'PsgaOrgDetailsForm.orgNameValidation',
        defaultMessage: "The organisation name should consist of letters, numbers and these special characters ._#-$%&()'/,",
        description: 'Message shown when the organisation name is invalid'
    },
    orgNameLength: {
        id: 'PsgaOrgDetailsForm.orgNameLength',
        defaultMessage: "The organisation name should be at most 100 characters long",
        description: 'Message shown when the organisation name is too long'
    },
    cantFindYourOrganisation: {
        id: 'PsgaOrgDetailsForm.cantFindYourOrganisation',
        defaultMessage: 'Can’t find your organisation?',
        description: 'Can’t find your organisation message'
    },
    organisationInfo1: {
        id: 'PsgaOrgDetailsForm.organisationInfo1',
        defaultMessage: 'If your organisation is not in the list above it means they have not applied for a Public Sector Geospatial Agreement (PSGA) with Ordnance Survey.',
        description: 'organisationInfo part 1'
    },
    organisationInfo2: {
        id: 'PsgaOrgDetailsForm.organisationInfo2',
        defaultMessage: 'In order to get free data from OS your organisation must be signed up to the PSGA.',
        description: 'organisationInfo part 2'
    },
    organisationInfo3: {
        id: 'PsgaOrgDetailsForm.organisationInfo3',
        defaultMessage: 'What to do:',
        description: 'organisationInfo part 3'
    },
    organisationInfo4: {
        id: 'PsgaOrgDetailsForm.organisationInfo4',
        defaultMessage: 'Enter your organisation name in the box above. Then continue with the sign up process and we’ll give you an OS OpenData account to get started, then prompt you on how to get your organisation signed up to the PSGA so you can access all the free data you’re entitled to.',
        description: 'organisationInfo part 4'
    },
    organisationNotCorrect1: {
        id: 'PsgaOrgDetailsForm.organisationNotCorrect1',
        defaultMessage: 'If you are not part of this organisation please {link} to let us know.',
        description: 'message displayed when organisation not correct part 1'
    },
    organisationNotCorrect2: {
        id: 'PsgaOrgDetailsForm.organisationNotCorrect2',
        defaultMessage: 'We can’t give you access to the Public Sector Plan right now because you have told us we have you listed under the wrong organisation.',
        description: 'message displayed when organisation not correct part 2'
    },
    organisationNotCorrect3: {
        id: 'PsgaOrgDetailsForm.organisationNotCorrect3',
        defaultMessage: 'For now you may continue on to get access to our OS OpenData Plan.',
        description: 'message displayed when organisation not correct part 3'
    },
    thisIsTheCorrectOrganisation: {
        id: 'PsgaOrgDetailsForm.thisIsTheCorrectOrganisation',
        defaultMessage: 'This is the correct organisation',
        description: 'This is the correct organisation message'
    },
    thisIsNotMyOrganisation: {
        id: 'PsgaOrgDetailsForm.thisIsNotMyOrganisation',
        defaultMessage: 'This is not my organisation',
        description: 'This is not my organisation message'
    },
    youAreAMember: {
        id: 'PsgaOrgDetailsForm.youAreAMember',
        defaultMessage: 'You are a member of this organisation',
        description: 'you are a member'
    },
    weCantGiveYouAccess: {
        id: 'PsgaOrgDetailsForm.weCantGiveYouAccess',
        defaultMessage: 'We can’t give you access to a Public Sector Plan because your organisation is not signed up to the {psga}.',
        description: 'we cant give you access'
    },
    psgaLink: {
        id: 'PsgaOrgDetailsForm.psgaLink',
        defaultMessage: 'Public Sector Geospatial Agreement',
        description: 'psga link'
    },
    ifYouThink: {
        id: 'PsgaOrgDetailsForm.ifYouThink',
        defaultMessage: 'If you think your organisation should be part of the Public Sector Geospatial Agreement, contact your organisation\'s OS Data Hub account administrator {psgaRegistration}.',
        description: 'if you think'
    },
    askThemToApply: {
        id: 'PsgaOrgDetailsForm.askThemToApply',
        defaultMessage: 'and ask them to apply',
        description: 'ask them to apply'
    },
    inTheMeantime: {
        id: 'PsgaOrgDetailsForm.inTheMeantime',
        defaultMessage: 'In the meantime, you can continue to access OS Data Hub using your existing plan.',
        description: 'in the meantime'
    },
    continueToMyDashboard: {
        id: 'PsgaOrgDetailsForm.continueToMyDashboard',
        defaultMessage: 'Continue to my dashboard',
        description: 'continue to my dashboard'
    }
});

const styles = createUseStyles(theme => ({
    buttons: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        '& button:first-of-type': {
            marginRight: theme.spacing(2)
        }
    },
    infoTextWrapper:{
        marginTop: theme.spacing(2)
    },
    infoText:{
        marginBottom: theme.spacing(2)
    },
    infoTextBold: {
        fontWeight: 'bold'
    },
    content: {
        flex: '1 0 auto',
        width:'340px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile
        }
    },
    link: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
        color: osColour.link.base,
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: '1rem',
        textDecoration: 'underline',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'right'
        }
    }
}));

export default function PsgaOrgDetailsForm({onSubmit, onCancel, onDecline, submitLabel, cancelLabel, declineLabel, working}) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPsgaOrgs());
    }, [dispatch]);

    let {loading, result: orgs} = useSelector(state => state.psga.orgs);
    let user = useSelector(state => state.user.current.result);

    const [modified, setModified] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [isOrgCorrect, setIsOrgCorrect] = useState(true);
    const [userHasOrg] = useState(user.org);
    const [inputValue, setInputValue] = useState(user.org || '');
    const [jobTitle, setJobTitle] = useState(user.jobTitle || '');
    const [phone, setPhone] = useState(user.telephoneNumber || '');
    const classes = styles();
    const history = useHistory();

    function apply() {
        onSubmit(inputValue, jobTitle, phone);
    }

    function handleInput(setter) {
        return e => {
            setModified(true);
            setter(e.target.value);
        }
    }

    function goToDashboard() {
        const newLocation = getLocation(routePaths.dashboard, history.location);
        history.replace(newLocation);
    }

    function hasOrg() {
        return user.org ? true : false;
    }

    function isOrgPlanPSGA() {
        return user.orgPlan && user.orgPlan === CRM_PSGA_PLAN;
    }

    function handleAutoCompleteChange(setter) {
        return (e, v) => {
            setModified(true);
            setter(v);
        }
    }

    function cantGiveYouAccessContactAdmin() {
        return <div className={classes.content}>
            <Typography variant='body1' paragraph={true} className={classes.infoTextBold}>
                <FormattedMessage {...messages.youAreAMember}/>
            </Typography>

            <Typography variant={'body1'} paragraph={true} id='cantGiveYouAccess.org'>
                {user.org}
            </Typography>

            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.weCantGiveYouAccess} values={{
                    psga: <Link target="_blank" rel="noopener noreferrer" href={PSGA_DESCRIPTION_URL} underline='always'>
                            <FormattedMessage {...messages.psgaLink} />
                        </Link>
                }}/>
            </Typography>

            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.ifYouThink} values={{
                    psgaRegistration: <Link target="_blank" rel="noopener noreferrer" href={PSGA_REGISTRATION_URL} underline='always'>
                            <FormattedMessage {...messages.askThemToApply} />
                    </Link>
                }}/>
            </Typography>

            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.inTheMeantime}/>
            </Typography>

            <div className={classes.buttons}>
                <AddButton action={goToDashboard}
                           label={messages.continueToMyDashboard}
                           showIcon={false}
                />
            </div>
        </div>
    }

    let orgError = null;
    let titleError = null;
    let phoneError = null;
    // Once any field has been modified then all of the fields are fair game for validation
    if(modified) {
        if(inputValue.length === 0) {
            orgError = messages.orgMissing;
        } else {
            if(inputValue.length > 100) {
                orgError = messages.orgNameLength;
            } else if(!nameMatcher.test(inputValue)) {
                orgError = messages.orgNameValidation;
            }
        }

        // Empty and unmodified job titles should not trigger an error. If we have an invalid title stored
        // then we'll live with it until the user starts editing that field.
        if(jobTitle !== '' && jobTitle !== user.jobTitle) {
            if(jobTitle.length > 100) {
                titleError = userConstants.messages.jobTitleLengthError;
            } else if(!userConstants.nameMatcher.test(jobTitle)) {
                titleError = userConstants.messages.jobTitleValidationError;
            }
        }

        // Empty and unmodified phone numbers should not trigger an error. If we have an invalid number stored
        // then we'll live with it until the user starts editing that field.
        if(phone !== '' && phone !== user.telephoneNumber) {
            if(phone.length > 30) {
                phoneError = userConstants.messages.phoneLengthError;
            } else if(!userConstants.phoneMatcher.test(phone)) {
                phoneError = userConstants.messages.phoneValidationError;
            }
        }
    }

    const ready = inputValue && inputValue.length > 0 && !orgError && !titleError && !phoneError;

    if (hasOrg() && !isOrgPlanPSGA() && !working) {
        return cantGiveYouAccessContactAdmin();
    }

    return <div className={classes.content}>

        <InputLabel>
            <FormattedMessage {...messages.orgName}/>
            {!userHasOrg && "*"}
        </InputLabel>

        {!userHasOrg &&
            <Fragment>
                <AutoCompleteListBox id={"PsgaOrgDetailsForm.organisations"}
                             options={orgs || []}
                             error={orgError}
                             onChange={handleAutoCompleteChange(setInputValue)}
                             loading={loading}/>

                <ButtonBase className={classes.link} onClick={() => setShowInfo(!showInfo)}>
                <FormattedMessage {...messages.cantFindYourOrganisation}/>
                </ButtonBase>
            </Fragment>
        }

        {isOrgPlanPSGA() &&
            <div className={classes.infoTextWrapper}>
                <Typography id='Psga.orgName' variant={'body1'}>
                    {user.org}
                </Typography>

                <RadioGroup className={classes.infoTextWrapper} name="isCorrectOrg" value={isOrgCorrect} onChange={(event) => {setIsOrgCorrect(event.target.value === "true")}}>
                    <FormControlLabel value={true} control={<Radio />} label={<Typography variant={'body1'}><FormattedMessage {...messages.thisIsTheCorrectOrganisation}/></Typography>} />
                    <FormControlLabel value={false} control={<Radio />} label={<Typography variant={'body1'}><FormattedMessage {...messages.thisIsNotMyOrganisation}/></Typography>} />
                </RadioGroup>

            </div>
        }

        {showInfo &&
            <div className={classes.infoTextWrapper}>
                <Typography variant={'body1'} className={classes.infoText}>
                    <FormattedMessage {...messages.organisationInfo1} />
                </Typography>

                <Typography variant={'body1'} className={classes.infoText}>
                    <FormattedMessage {...messages.organisationInfo2} />
                </Typography>

                <Typography variant={'body1'} className={classes.infoTextBold}>
                    <FormattedMessage {...messages.organisationInfo3} />
                </Typography>

                <Typography variant={'body1'} className={classes.infoText}>
                    <FormattedMessage {...messages.organisationInfo4} />
                </Typography>
            </div>
        }

        {!isOrgCorrect &&
            <Fragment>
                <div className={classes.infoTextWrapper}>
                    <Typography variant={'body1'} className={classes.infoText}>
                        <FormattedMessage {...messages.organisationNotCorrect1}
                            values={{ link: <ExternalLink type='support' /> }}
                        />
                    </Typography>

                    <Typography variant={'body1'} className={classes.infoText}>
                        <FormattedMessage {...messages.organisationNotCorrect2} />
                    </Typography>

                    <Typography variant={'body1'} className={classes.infoText}>
                        <FormattedMessage {...messages.organisationNotCorrect3} />
                    </Typography>
                </div>

                <div className={classes.buttons}>
                    <AddButton action={() => onDecline()}
                        label={declineLabel}
                        working={working}
                        disabled={!ready}
                        showIcon={false}
                    />
                </div>
            </Fragment>
        }

        {isOrgCorrect &&
            <Fragment>
                <InputBox id='JobTitle'
                          label={messages.jobTitle}
                          error={titleError}
                          value={jobTitle}
                          fullWidth={true}
                          onChange={handleInput(setJobTitle)}/>

                <InputBox id='Phone'
                          label={messages.phone}
                          error={phoneError}
                          value={phone}
                          fullWidth={true}
                          onChange={handleInput(setPhone)}/>

                <div className={classes.buttons}>
                    {!isOrgPlanPSGA() &&
                        <Button onClick={onCancel}
                                variant='outlined'
                                color='primary'
                                disabled={working}>
                            <FormattedMessage {...cancelLabel}/>
                        </Button>
                    }
                    <AddButton action={apply}
                               label={submitLabel}
                               working={working}
                               disabled={!ready}
                               showIcon={false}
                    />
                </div>
            </Fragment>
        }
    </div>;
}

PsgaOrgDetailsForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,      // Function to be called with orgName, jobTitle, phoneNumber
    onCancel: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    cancelLabel: PropTypes.object.isRequired, // Label for the cancel button
    submitLabel: PropTypes.object.isRequired, // Label for the submit button
    declineLabel: PropTypes.object.isRequired, // Label for the decline button
    working: PropTypes.bool,                  // Should be true while the submit function is working
};
