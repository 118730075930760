/**
 * Pushes an event with custom data to Google Tag Manager (GTM).
 *
 * @param {string} eventName - The name of the event to be pushed to the Data Layer.
 * @param {Array} dataPairs - An array of objects, each containing a sigle key-value pair of custom data.
 */

export const tagEventKeys = {
    workspace: {
        optIn: "Event - OptIn - Click"
    } 
}

export function sendEventToGTM(eventName, dataPairs) {
    window.dataLayer = window.dataLayer || [];
    let eventObject = {
        event: eventName,
    };
    dataPairs.forEach(function (pair) {
        Object.keys(pair).forEach(function (key) {
            eventObject[key] = pair[key];
        });
    });
    window.dataLayer.push(eventObject);
}