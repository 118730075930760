import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

const LoadingContainer = styled("div")`
    display: grid;
    min-height: 8em;
    place-items: center;
`;

export default function RecentItemsLoading() {
    return (
        <LoadingContainer>
            <CircularProgress size={24} />
        </LoadingContainer>
    );
}
