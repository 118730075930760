import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import qgis1 from './qgis/qgis01.png';
import qgis2 from './qgis/qgis02.png';
import qgis3 from './qgis/qgis03.png';
import qgis4 from './qgis/qgis04.png';
import qgis5 from './qgis/qgis05.png';
import qgis6 from './qgis/qgis06.png';
import qgis7 from './qgis/qgis07.png';
import qgis8 from './qgis/qgis08.png';
import qgis9 from './qgis/qgis09.png';
import qgis10 from './qgis/qgis10.png';
import qgis11 from './qgis/qgis11.png';
import qgis12 from './qgis/qgis12.png';
import withStyles from 'react-jss';
import { osColour} from 'omse-components';
import WMTSCompletionSummary from './WMTSCompletionSummary';
import PropTypes from 'prop-types';
import InlineCodeSnippet from '../../../components/InlineCodeSnippet';

const styles = {
    bordered: {
        border: `solid ${osColour.neutral.mist} 1px`
    },
    instructionsList: {
        '& li': {
            paddingLeft: 0
        }
    }
};

export function QGIS(props) {
    const {classes} = props;
    return <Fragment>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    QGIS is an open GIS desktop application that allows you to display, interrogate, visualise and
                    create geospatial information.
                    It is also capable of interacting with geo-centric APIs (for example, a WMTS).
                </p>
                <p>The instructions that follow demonstrate how to integrate the OS Maps API in order to produce a
                    background map in QGIS.</p>
                <p>For the purposes of this guide the version of QGIS used is 3.4.</p>
            </Typography>

            <Typography variant='h2'>Integrating OS Maps API in QGIS</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <ol>
                    <li><p>Open a blank document in QGIS.</p></li>
                    <li>
                        <p>Navigate to Layer → Add Layer → Add WMS/WMTS Layer...</p>
                        <img src={qgis1} alt='QGIS Layer menu' className={classes.bordered}/>
                    </li>
                    <li>
                        <p>The following window will be displayed:</p>
                        <img src={qgis2} alt='QGIS Add WMTS Layer'/>
                    </li>
                    <li>
                        <p>If you have connected to a WMS/WMTS before, you will have the options available in the
                            drop down immediately under the layers tab. If you've connected to OS Maps API
                            before, select the layer in the drop-down menu and click Connect.</p>
                        <img src={qgis3} alt='QGIS Add WMTS Layer window options'/>
                    </li>
                    <li>
                        <p>If you have NOT called OS Maps API before, click the New button below the drop-down menu.</p>
                        <img src={qgis4} alt='QGIS Add WMTS Layer - New button'/>
                    </li>
                    <li>
                        <p>The window that pops up requires the API information you obtained in the OS Data Hub.</p>
                        <img src={qgis5} alt='QGIS New WMS/WMTS connection dialog' className={classes.bordered}/>
                        <p>Provide the details of the API as follows:</p>
                        <ol type='a'>
                            <li><p>Provide a name for the API. You can call the connection whatever you wish,
                                however it is good practice to name your layers so that they are instantly
                                recognisable.</p></li>
                            <li><p>Input the OS Maps API URL in the URL box.</p></li>
                            <li>
                                <p>Your API Key has been generated in the OS Data Hub and would have been
                                    automatically added to the URL.</p>
                                <img src={qgis6} alt='QGIS New WMS/WMTS connetion dialog with values' className={classes.bordered} width="448"/>
                                <p>You will not be required to put styling, zoom or coordinate information in the
                                    URL.</p>
                            </li>
                        </ol>
                    </li>
                    <li>Leave all the other options blank and click OK.
                        <p>It is worth noting that you will NOT require a User name or Password to use the service
                            as all authentication is done through your OS Maps API key.</p>
                        <p>Once you've completed this, the connection details you have supplied will be saved by
                            your QGIS application and it can be used with any of your new or existing geospatial
                            projects.</p>
                    </li>
                    <li>
                        <p>The next step is to select the layer in the drop-down list and click Connect.</p>
                        <img src={qgis7}
                             alt='QGIS Data source manager - Layers tab with populated connection details'/>
                    </li>
                    <li>
                        <p>A table of layers will be displayed as shown below.</p>
                        <img src={qgis8} alt='QGIS Data source manager - Layers table'/>
                    </li>
                    <li>
                        <p>At this point you will select, by clicking on the row, the style you wish to display as
                            well as the coordinate reference system projection you wish to use. For example
                            <InlineCodeSnippet>Road_27700</InlineCodeSnippet> or <InlineCodeSnippet>Road_3857</InlineCodeSnippet>.</p>
                        <p>Select the appropriate layer and click Add. Just click it once.</p>
                        <img src={qgis9} alt='QGIS Data source manager - Tilesets tab - Layer selected'/>
                        <p>It's recommended that you match the projection with the coordinate reference system you
                            wish to use.</p>
                    </li>
                    <li>
                        <p>Click Close and you'll see the map displayed in the QGIS screen.</p>
                        <img src={qgis10} alt='QGIS map screen' className={classes.bordered}/>
                        <p>The map may appear grainy or fuzzy, this is due to the tiles being produced for a
                            specific scale and if your map screen does not match this scale, the tiles appear out of
                            focus.</p>
                    </li>
                    <li>
                        <p>Navigate to View → Panels → Tile Scale...</p>
                        <img src={qgis11} alt='QGIS menu - View → Panels → Tile Scale'
                             className={classes.bordered}/>
                    </li>
                    <li>
                        <p>Now when you select the tile layer in the layer menu and zoom the map using the mouse
                            wheel or the new Tile Scale panel the map will remain crisp and in focus.</p>
                        <img src={qgis12} alt='QGIS Layer properties' className={classes.bordered}/>
                    </li>
                </ol>
            </Typography>
            <WMTSCompletionSummary software="QGIS" />
    </Fragment>
}

QGIS.propTypes = {
    width: PropTypes.string
}

export default withStyles(styles)(QGIS);