import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'TermsConditionsAccept.title',
        defaultMessage: 'Terms and conditions',
        description: 'Terms and conditions title'
    },
    acceptTermsIntro: {
        id: 'TermsConditionsAccept.acceptTermsIntro',
        defaultMessage: 'You’re nearly done. Please accept our terms and conditions.',
        description: 'Text used when a new user is accepting terms for the first time'
    },
    acceptUpdateIntro: {
        id: 'TermsConditionsAccept.acceptUpdateIntro',
        defaultMessage: 'Our terms and conditions have updated. To continue using this part of the OS Data Hub, please accept our updated terms and conditions.',
        description: 'Text used when an existing user is accepting a terms update'
    },
    declineButton: {
        id: 'TermsConditionsAccept.declineButton',
        defaultMessage: 'Decline',
        description: 'Terms and conditions decline button'
    },
    declineAndLogOutButton: {
        id: 'TermsConditionsAccept.declineAndLogOutButton',
        defaultMessage: 'Decline (and log me out)',
        description: 'Terms and conditions decline and log out button'
    },
    acceptButton: {
        id: 'TermsConditionsAccept.acceptButton',
        defaultMessage: 'Accept and continue',
        description: 'Terms and conditions accept button'
    },
    acceptUpdateText: {
        id: 'TermsConditionsAccept.acceptUpdateText',
        defaultMessage: 'By continuing to use your OS Data Hub account you are agreeing to the updated terms and conditions set out above. If you do not agree, you should close your Data Hub account.',
        description: 'Terms and conditions accept update text'
    },
    acceptTermsUpdate: {
        id: 'TermsConditionsAccept.acceptTermsUpdate',
        defaultMessage: 'I understand',
        description: 'Terms and conditions accept button label used when terms are updated'
    },
    acceptButtonMustScrollBottom: {
        id: 'TermsConditionsAccept.acceptButtonMustScrollBottom',
        defaultMessage: 'You must scroll to the bottom of the above terms and conditions before continuing.',
        description: 'Text in the tooltip that pops up when you click accept without scrolling to the bottom of the T&Cs'
    },
    submissionError: {
        id: 'TermsConditionsAccept.submissionError',
        defaultMessage: 'An error occurred. You can try submitting again.',
        description: 'Terms and conditions submission error'
    },
    organisationAcceptWarning: {
        id: 'TermsConditionsAccept.organisationAcceptWarning',
        defaultMessage: 'When you accept these terms and conditions, you do so <b>on behalf of your entire organisation</b>.',
        description: 'Accepting for organisation warning'
    },
    organisationAcceptHelp: {
        id: 'TermsConditionsAccept.organisationAcceptHelp',
        defaultMessage: 'If you do not feel comfortable accepting for your organisation, you can invite a person with signing authority to become an admin user, and they can accept these terms and conditions.',
        description: 'Accepting for organisation help'
    }
});