import React from 'react';
import {FormattedMessage} from 'react-intl';
import Header from '../documentation/Header';
import Document from '../documentation/Document';
import {useLocation} from 'react-router';
import routePaths from '../../util/routes'; 
import titleMessages from './TermsTitles';
import LegalTerms from './LegalTerms';
import {generalTermsName, apiTermsName, downloadTermsName} from '../../../shared/constants';
import { useSelector } from "react-redux";
import { USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN } from "../../../shared/plans";
import useFeatureCheck from "../../util/useFeatureCheck";
import {EAIAPI} from '../../../shared/features';

export default function LegalTermsPage() {
    const location = useLocation();
    const user = useSelector(state => state.user.current.result);
    const hasEaiApiFeature = useFeatureCheck(EAIAPI);
    const isEaiApiUser = hasEaiApiFeature && user?.plan === USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN;
    let termsKey;
    switch(location.pathname) {
        case routePaths.termsConditions:
            termsKey = generalTermsName;
            break;
        case routePaths.downloadTerms:
            termsKey = downloadTermsName;
            break;
        case routePaths.apiTermsConditions:
            termsKey = apiTermsName;
            break;
        default:
            break;
    }
    return termsKey && 
        <Document>
            <Header back={true}>
                <FormattedMessage {...titleMessages[termsKey]} />
            </Header>
            <LegalTerms variant={termsKey} isEaiApi={isEaiApiUser}/>
        </Document>
}
