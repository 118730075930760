import React, {Component, Fragment} from 'react';
import withStyles from 'react-jss';
import {Vector as VectorSource} from 'ol/source';
import {GeoJSON} from 'ol/format';
import {Vector as VectorLayer} from 'ol/layer';
import Collection from 'ol/Collection';
import {Attribution} from 'ol/control';
import PropTypes from 'prop-types';
import gridJson from './grid/OSGB_Grid_100km.js';
import {styleFunction} from './grid/grid-styles.js';
import {buildMapHelper} from 'omse-components';
import { connect } from 'react-redux';

const mapErrorText =
	'Problem initialising map, please enter a tile reference to set the area manually or cancel and retry.';


const tileNameKey = 'TILE_NAME';
const projectionId = 'EPSG:27700';
const clickEvent = 'click';
const pointerMoveEvent = 'pointermove';
const statusKeys = {selected: 'selected', active: 'active'};

const styles = {
	map: {
		flex: '1 0 auto',
		'& button': {
			'&:focus': {
				outline: 'auto'
			}
		},
		'& .ol-attribution': {
			height: 40
		},
		'& .ol-zoom': {
			height: 'initial',
			backgroundColor: 'transparent'
		}
	}
};

export class InteractiveGridMap extends Component {
	constructor(props) {
		super(props);
		this.wrapper = React.createRef();
		this.state = {
			map: undefined,
			features: new Collection()
		};
	}

	componentDidMount() {
		const {handleError, mapTesting} = this.props;
		buildMapHelper(this.wrapper.current, {}, handleError, mapTesting)
		.then(map => {
			this.setState({ map }, () => {
				map.once('postrender', this.setMapControls);
				map.once('postrender', this.initGridLayer);
				map.once('postrender', this.addEventListeners);
			});
		})
		.catch((err) => {
			handleError(mapErrorText);
		});
	}

	componentWillUnmount() {
		const {map} = this.state;
		if(map) {
			map.setTarget(null);
		}
	}

	setMapControls = () => {
		const {map} = this.state;
		if (map) {
			map.getControls().forEach(control => {
				if (control instanceof Attribution) {
					control.setCollapsed(false);
				}
			});
		}
	};

	addEventListeners = () => {
		const {handleSquaresChange} = this.props;
		const {map} = this.state;
		if (map) {
			if (!map.getListeners(clickEvent) || map.getListeners(clickEvent).length === 0) {
				map.on(clickEvent, e => {
					// workaround to prevent text input keeping focus on the first click
					// to be removed when chips replace input
					document.activeElement.blur();

					map.forEachFeatureAtPixel(e.pixel, feature => {
						const status = feature.get('status');
						const ref = feature.get(tileNameKey);
						this.state.features.forEach(f => {
							if (ref === f.get(tileNameKey)) {
								if (status === statusKeys.active) {
									f.set('status', statusKeys.selected);
								}
								if (status === statusKeys.selected) {
									f.set('status', statusKeys.active);
								}
							}
						});
					});
					handleSquaresChange(this.getSelectedFeatures());
				});
			}

			if (!map.getListeners(pointerMoveEvent) || map.getListeners(pointerMoveEvent).length === 0) {
				map.on(pointerMoveEvent, function(e) {
					let hit = map.forEachFeatureAtPixel(e.pixel, function() {
						return true;
					});
					if (hit) {
						map.getTargetElement().style.cursor = 'pointer';
					} else {
						map.getTargetElement().style.cursor = '';
					}
				});
			}
		}
	};

	getSelectedFeatures() {
		return this.state.features
			.getArray()
			.filter(f => f.get('status') === statusKeys.selected)
			.map(f => f.get(tileNameKey));
	}

	initGridLayer = () => {
		const {grid, squares} = this.props;
		const {map} = this.state;
		if (map) {
			// pre-filter according to squares available and pre-selected
			let features = gridJson.features.filter(f => {
				let status = '';
				let ref = f.properties[tileNameKey];
				if (grid.find(g => g.active && g.ref === ref)) {
					if (squares.indexOf(ref) !== -1) {
						status = statusKeys.selected;
					} else {
						status = statusKeys.active;
					}

					f.properties.status = status;
					return f;
				}
				return null;
			});

			const collection = new Collection(
				new GeoJSON({
					featureProjection: projectionId
				}).readFeatures({...gridJson, features})
			);

			this.setState({features: collection});

			const source = new VectorSource({
				features: collection
			});

			const layer = new VectorLayer({
				source: source,
				style: styleFunction,
				name: 'grid'
			});

			map.addLayer(layer);
		}
	};

	updateLayerStatus() {
		const {squares} = this.props;
		this.state.features.getArray().filter(f => {
			const ref = f.get(tileNameKey);

			// square is selected
			if (squares.indexOf(ref) !== -1) {
				f.set('status', statusKeys.selected);
			} else {
				f.set('status', statusKeys.active);
			}
			return f;
		});
	}

	componentDidUpdate(prevProps) {
		const {squares, updateSize, postUpdateSize} = this.props;
		const {map} = this.state;

		// size of container has changed
		if (map && updateSize && !prevProps.updateSize) {
			map.updateSize();
			if (postUpdateSize) {
				postUpdateSize();
			}
		}

		// squares will always change after a click and handleSquaresChange has run, so compare the contents of squares
		// against the features held in state to see if any vector layer changes are required.
		if (prevProps.squares !== squares) {
			if (this.state.features) {
				const selected = this.getSelectedFeatures();

				// when same length, only update layer if any values are different
				if (selected.length === squares.length) {
					let differences = selected.map(e => squares.indexOf(e)).some(r => r === -1);
					if (differences) {
						this.updateLayerStatus();
					}

					// otherwise update layer
				} else {
					this.updateLayerStatus();
				}
			}
		}
	}

	render() {
		const {classes} = this.props;
		return <Fragment>
			<div aria-label='Interactive map of the UK showing selectable 100 km grid squares'
				ref={this.wrapper} 
				className={classes.map}
				onClick={() => document.activeElement.blur()}></div>
		</Fragment>;
	}
}

const mapStateToProps = (state) => {
	const mapTesting = state.config.current.result.mapTesting;
	return { mapTesting };
};

InteractiveGridMap.propTypes = {
	grid: PropTypes.arrayOf(PropTypes.object).isRequired,
	squares: PropTypes.arrayOf(PropTypes.string).isRequired,
	handleSquaresChange: PropTypes.func.isRequired,
	handleError: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {})(withStyles(styles)(InteractiveGridMap));
