import React, {Fragment, useRef, useEffect, useState, useCallback} from 'react';
import {buildMapHelper, os_outdoor, masterMapMaxZoom, Notification} from "omse-components";
import PropTypes from "prop-types";
import {createUseStyles} from 'react-jss';
import logo from "../../../components/images/os-logo-maps.svg";
import {useDispatch, useSelector} from 'react-redux';
import {getPremiumProduct} from "../../../modules/premium/actions";
import DataPackageMapToolbar from "./DataPackageMap/DataPackageMapToolbar";
import {
    createTileLayer,
    createVectorLayer,
    addFeatureToSource,
    cutPolygonFromLayer,
    create1KMTileLayer
} from './DataPackageMap/ol/layer';
import {
    mergeFeatures,
    removeInternalCoords,
    toOLGeometry,
    polygonsToMultiPolygon,
    toGeoJsonGeometry
} from "./DataPackageMap/ol/feature";
import { debounce, throttle, uniq } from 'lodash';
import DataPackageMapModal from "./DataPackageMapModal";
import classNames from 'classnames';
import {Typography} from "@mui/material";

import {fromExtent} from 'ol/geom/Polygon';
import {buffer, createEmpty, extend, isEmpty} from 'ol/extent';
import Feature from 'ol/Feature';
import ExpandMapButton from "./ExpandMapButton";
import {drawingLayerStyle, expandedTileStyle, selectTileStyle, expandedDrawingLayerStyle} from "./DataPackageMap/ol/style";
import {updateDraftOrder} from '../../../modules/dataPackages/actions';
import useCounter from "../../../hooks/useCounter";
import CircularProgress from "@mui/material/CircularProgress";
import {useIntl, defineMessages} from 'react-intl';

const url = '/api/map/layer/{layerId}/query/?f=json&geometry={extent}&geometryType=esriGeometryEnvelope';

const mapErrorText = 'There has been a problem showing the map. Please try again later.';

const CURRENT_TILE_LAYER = 'currentTileLayer';
const CURRENT_POLYGON_LAYER = 'currentPolygonLayer';
const DELETE_LAYER = 'deleteLayer';
const DRAWING_LAYER = 'drawingLayer';
const SELECTED_TILE_LAYER = 'selectedLayer';
const TILE_GRID_LAYER = 'tileLayer';

const styles = createUseStyles(theme => ({
    map: {
        height: 'inherit',
        width: '100%',
        backgroundColor: '#F6F6F6',
        position: 'relative',
        overflow: 'hidden !important',
        "& .ol-zoom": {
            top: "94px",
            height: 'initial',
            backgroundColor: 'transparent'
        },
        "& .ol-attribution": {
            pointerEvents: "none !important",
            height: 40
        },
    },
    expandMap: {
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        opacity: 0.9,
        position: "absolute",
        zIndex: 2,
        width: '98%',
        height: 38,
        bottom: 2,
        left: 2
    },
    logo: {
        position: "absolute",
        bottom: 12,
        left: "2%",
        width: 128,
        height: 32,
        zIndex: 2,
        pointerEvents: "none",
        [theme.breakpoints.down('md')]: {
            display: "none"
        }
    },
    spinner: {
        position: "absolute",
        left: "50%",
        top: "50%",
        zIndex: 1,
        pointerEvents: "none"
    }
}));

const messages = defineMessages({
    spinner: {
        id: "DataPackageMap.spinner",
        defaultMessage: "Calculating tiles",
        description: "Used for the aria-label on the progress spinner"
    }
});

export default function DataPackageMap(props) {
    const {listenerWait, productId, showToolbar, mapClasses, expandable} = props;
    const hasDataPackage = props.dataPackage != null;
    const dataPackage = props.dataPackage || {};

    const dispatch = useDispatch();
    const wrapper = useRef();
    const intl = useIntl();
    const classes = styles();

    const [map, setMap] = useState(null);
    const [drawingLayer, setDrawingLayer] = useState(null);
    // The tileLayer are all the tiles on the page, whether they are selected or not
    const [tileLayer, setTileLayer] = useState(null);
    // Current layers are the loaded layers from existing dataPackage data
    const [currentPolygonLayer, setCurrentPolygonLayer] = useState(null);
    const [currentTileLayer, setCurrentTileLayer] = useState(null);
    //selected layer is the user's current drawing tiles
    const [selectedTileLayer, setSelectedTileLayer] = useState(null);
    const [deleteLayer, setDeleteLayer] = useState(null);
    const [tileLayerLoaded, setTileLayerLoaded] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    // State to keep track when we are computing tiles. It needs to be a counter as there is more than one code path
    // that does the calculation, and they can end up happening concurrently.
    const [computingTiles, incComputingTiles, decComputingTiles] = useCounter();

    let product = useSelector(state => state.premium.product.result);
    const dataPackageResult = useSelector(state => state.dataPackages.create.result);
    const mapTesting = useSelector(state => state.config.current.result.mapTesting);

    const showError = useCallback(errorMessage => {
        setErrorMessage(errorMessage);
    }, []);

    const _isMounted = useRef(true);

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    }, []);

    if (product && product.id !== productId) {
        product = null;
    }

    useEffect(() => {
        if (productId && !product) {
            dispatch(getPremiumProduct(productId));
        }
    }, [dispatch, productId, product]);

    const getLayer = useCallback((name) => {
        if (map) {
            return map.getLayers().getArray().find(l => l.get('name') === name)
        }
    }, [map]);

    // zoom to the union of the extents of the given layers
    const zoomToLayers = useCallback(layers => {
        if (map) {
            let extent = createEmpty();
            layers.filter(layer => layer && layer.getSource().getFeatures().length > 0)
                .map(layer => layer.getSource().getExtent()).forEach(ex => extend(extent, ex));
            if (!isEmpty(extent)) {
                let geom = fromExtent(extent);
                geom.scale(1.3);
                map.getView().fit(geom, {size: map.getSize()});
            }
        }
    }, [map]);

    const getSelectedTiles = useCallback((layers, shouldFilter) => {
        let selectedTiles = [];
        layers.forEach(layer => {
            if (layer) {
                layer.getSource().getFeatures().forEach(feature => {
                    let polygon = feature.getGeometry();
                    tileLayer.getSource().forEachFeatureInExtent(polygon.getExtent(), function (tile) {
                        // openlayers intersectsExtent returns adjacent tiles so.....
                        // shrink the tiles by 1/100th millimetre so we dont get them selected
                        if (polygon.intersectsExtent(buffer(tile.getGeometry().getExtent(), -0.00001))) selectedTiles.push(tile);
                    })
                });
            }
        });

        return selectedTiles;
    }, [tileLayer]);

    // throttle helps smooth rendering when dragging a polygon
    const refreshSelectedTiles = useCallback(() => {
        // requestAnimationFrame stops tile layer flickering when cleared and reloaded
        window.requestAnimationFrame(() => {
            let selectedTiles = getSelectedTiles([drawingLayer], false);
            selectedTileLayer.getSource().clear();
            if (selectedTiles.length > 0) {
                incComputingTiles();
                mergeFeatures(selectedTiles).then(joinedTiles => {
                    decComputingTiles();
                    if(joinedTiles) {
                        selectedTileLayer.getSource().addFeature(joinedTiles);
                    }
                });
            }
        });
    }, [getSelectedTiles, selectedTileLayer, drawingLayer, incComputingTiles, decComputingTiles]);

    const updateDraftOrderAOIAndTiles = useCallback(() => {
        if (tileLayer) {
            if(product.baseLayer) {
                let tileRefs = getSelectedTiles([drawingLayer], true).map(f => f.getId());
                dispatch(updateDraftOrder("tiles", uniq(tileRefs)));
            } else {
                // We must be ordering data for a product on the built-in 1km tile grid (e.g. Imagery)
                // We only want tiles that have been selected by new drawings, so we do not need to put the current
                // polygon layer into the list of layers that we check.
                let features = getSelectedTiles([drawingLayer]);
                if(features.length) {
                    incComputingTiles();
                    mergeFeatures(features).then(mergedFeature => {
                        decComputingTiles();
                        // Ensure that our new geometry is a MultiPolygon, as the Order service cannot accept a simple Polygon.
                        // If the user only picked a simple shape then we might just have a polygon at this point.
                        const multiPolygon = polygonsToMultiPolygon([mergedFeature]);
                        const geoJson = toGeoJsonGeometry(multiPolygon.getGeometry());
                        dispatch(updateDraftOrder("tiledAOI", geoJson));
                    });

                } else {
                    dispatch(updateDraftOrder("tiledAOI", null));
                }
            }
        }

        const source = drawingLayer.getSource();
        const features = source.getFeatures();

        if(features.length) {
            let multiPolygon = polygonsToMultiPolygon(features);
            let geoJson = toGeoJsonGeometry(multiPolygon.getGeometry());

            dispatch(updateDraftOrder("AOI", geoJson));
        } else {
            dispatch(updateDraftOrder("AOI", null));
        }
    }, [drawingLayer, tileLayer, dispatch, getSelectedTiles, product, incComputingTiles, decComputingTiles]);

    useEffect(() => { // zoom to extent when data package created
        if (dataPackageResult) {
            zoomToLayers([drawingLayer, currentPolygonLayer, selectedTileLayer, currentTileLayer]);
        }
    }, [dataPackageResult, zoomToLayers, drawingLayer, selectedTileLayer, currentTileLayer, currentPolygonLayer, tileLayer]);

    useEffect(() => { // add an aoi or tile list to the map if we're given one
        function addAOI(aoi) {
            if (aoi) {
                let feature = removeInternalCoords(new Feature(toOLGeometry(aoi)));
                currentPolygonLayer.getSource().clear();
                currentPolygonLayer.getSource().addFeature(feature);
            }
        }

        function addTiles(tiles) {
            let selectedTiles = tiles.map(tile => tileLayer.getSource().getFeatures()
                .find(f => tile === f.getId()))
                .filter(function(e){return e});
            if (selectedTiles.length > 0) {
                incComputingTiles();
                mergeFeatures(selectedTiles).then(joinedTiles => {
                    decComputingTiles();
                    if (joinedTiles) {
                        currentTileLayer.getSource().clear();
                        currentTileLayer.getSource().addFeature(joinedTiles);
                        if(!dataPackage.polygon) {
                            currentPolygonLayer.getSource().clear();
                            currentPolygonLayer.getSource().addFeature(joinedTiles);
                        }
                        zoomToLayers([currentPolygonLayer, currentTileLayer]);
                    }
                });
            }
        }

        if (currentPolygonLayer && currentTileLayer) {
            if (dataPackage.tiles) {
                if (tileLayer && tileLayerLoaded) { // wait for the tile layer to be loaded for tiled products
                    if (dataPackage.polygon) {
                        addAOI(dataPackage.polygon);
                        refreshSelectedTiles();
                        updateDraftOrderAOIAndTiles();
                    }

                    if (dataPackage.tiles) {
                        addTiles(dataPackage.tiles);
                    }

                    zoomToLayers([currentPolygonLayer, currentTileLayer]);
                }
            } else { // otherwise add it to the map for non tiled products
                addAOI(dataPackage.polygon);
                zoomToLayers([currentPolygonLayer]);
            }
        }
    }, [dataPackage.tiles, dataPackage.polygon, currentPolygonLayer, currentTileLayer, tileLayer, tileLayerLoaded,
        zoomToLayers, refreshSelectedTiles, updateDraftOrderAOIAndTiles, incComputingTiles, decComputingTiles]);

    useEffect(() => { // create the map
        if (!map && wrapper.current) {
            const mapOptions = {
                layerStyles: [os_outdoor],
                mapMaxZoom: masterMapMaxZoom,
                useWmtsResolutions: true
            };

            if (expandable) {
                mapOptions.useWmtsResolutions = false;
                mapOptions.controls = [];
                mapOptions.interactions = [];
            }

            buildMapHelper(wrapper.current, mapOptions, showError, mapTesting)
                .then((m) => {
                    if (_isMounted.current) {
                        setMap(m);
                    }
                })
                .catch(() => { showError(mapErrorText); });
        }
    }, [map, showError, wrapper, expandable, mapTesting]);

    useEffect(() => {  // create the product tile grid layer
        if (map && product) {
            dispatch(updateDraftOrder("tiled", !!product.baseLayer));
            if (product.baseLayer) {
                if (!getLayer(TILE_GRID_LAYER)) {
                    let tl = createTileLayer(TILE_GRID_LAYER, url.replace("{layerId}", product.baseLayer + "_1"), showError)
                    setTileLayer(tl);
                    map.addLayer(tl);

                    let i = 0;
                    tl.getSource().on('featuresloadstart', function () {
                        i++;
                    });

                    tl.getSource().on('featuresloadend', function () {
                        if (--i === 0) setTileLayerLoaded(true);
                    });
                }
            } else if(product.id === 'MMIMAG') {
                if (!getLayer(TILE_GRID_LAYER)) {
                    let tl = create1KMTileLayer(TILE_GRID_LAYER);
                    setTileLayer(tl);
                    map.addLayer(tl);
                    setTileLayerLoaded(true);
                }
            }
        }

        return () => {
            if (map) {
                let layer = getLayer(TILE_GRID_LAYER);
                map.removeLayer(layer)
                setTileLayer(null);
            }
        }
    }, [map, product, getLayer, showError, expandable, dispatch]);

    useEffect(() => {  // create the selected tile layer
        if (map) {
            if (!getLayer(SELECTED_TILE_LAYER)) {
                let sl = createVectorLayer(SELECTED_TILE_LAYER, hasDataPackage ? expandedTileStyle : selectTileStyle);
                setSelectedTileLayer(sl);
                map.addLayer(sl);
            }
        }

        return () => {
            if (map) {
                let layer = getLayer(SELECTED_TILE_LAYER);
                map.removeLayer(layer)
                setSelectedTileLayer(null);
            }
        }
    }, [map, getLayer, hasDataPackage]);

    useEffect(() => { // create the drawing layer
        if (map) {
            if (!getLayer(DRAWING_LAYER)) {
                let dl = createVectorLayer('drawingLayer', hasDataPackage ? expandedDrawingLayerStyle : drawingLayerStyle);
                setDrawingLayer(dl);
                map.addLayer(dl);
                let source = dl.getSource();

                //drawn features need to be merged, loaded features have been merged by the loader
                source.on('addfeature', function(event) {
                    let newFeature = event.feature;

                    if(!newFeature.get("merged")){
                        let cleanFeature = removeInternalCoords(newFeature);
                        if(cleanFeature) {
                            newFeature.setGeometry(cleanFeature.getGeometry());
                            addFeatureToSource(source, newFeature);
                        } else {
                            source.removeFeature(newFeature);
                        }
                    }
                });
            }
        }

        return () => {
            if (map) {
                let layer = getLayer(DRAWING_LAYER);
                map.removeLayer(layer)
                setDrawingLayer(null);
            }
        }
    }, [map, getLayer, currentPolygonLayer, hasDataPackage]);

    useEffect(() => { // create the current data package polygon layer
        if (map && hasDataPackage) {
            if (!getLayer(CURRENT_POLYGON_LAYER)) {
                let pl = createVectorLayer(CURRENT_POLYGON_LAYER);
                setCurrentPolygonLayer(pl);
                map.addLayer(pl);
            }
        }

        return () => {
            if (map) {
                let layer = getLayer(CURRENT_POLYGON_LAYER);
                map.removeLayer(layer);
                setCurrentPolygonLayer(null);
            }
        }
    }, [map, hasDataPackage, getLayer]);

    useEffect(() => { // create the current data package selected tiles layer
        if (map && hasDataPackage) {
            if (!getLayer(CURRENT_TILE_LAYER)) {
                let tl = createVectorLayer(CURRENT_TILE_LAYER, selectTileStyle);
                setCurrentTileLayer(tl);
                map.addLayer(tl);
            }
        }

        return () => {
            if (map) {
                let layer = getLayer(CURRENT_TILE_LAYER);
                map.removeLayer(layer)
                setCurrentTileLayer(null);
            }
        }
    }, [map, hasDataPackage, getLayer]);

    useEffect(() => { // create the delete layer
        if (map && drawingLayer) {
            if (!getLayer(DELETE_LAYER)) {
                let dl = createVectorLayer(DELETE_LAYER);
                setDeleteLayer(dl);

                let source = dl.getSource();

                source.on('addfeature', function(event) {
                    let feature = event.feature;
                    cutPolygonFromLayer(drawingLayer, feature);
                    source.removeFeature(feature);
                    if (tileLayer) {
                        refreshSelectedTiles();
                    }
                    updateDraftOrderAOIAndTiles();
                });
            }
        }

        return () => {
            if (map) {
                let layer = getLayer(DELETE_LAYER);
                map.removeLayer(layer)
                setDeleteLayer(null);
            }
        }
    }, [map, drawingLayer, tileLayer, getLayer, refreshSelectedTiles, updateDraftOrderAOIAndTiles]);

    useEffect(() => { // add the changeListeners to the drawing layer
        if (drawingLayer) {
            // do list tile and aois in a separate listener so they dont slow down the refresh of selected tile layer.
            const updateDraftOrderAOIListener = debounce(() => updateDraftOrderAOIAndTiles(), listenerWait);

            // do this with a throttle as addfeature could be called a couple of thousand times
            const refreshSelectedTilesListener = throttle(() => refreshSelectedTiles(), listenerWait, {trailing: false});

            const source = drawingLayer.getSource();
            if (tileLayer) {
                source.on('addfeature', refreshSelectedTilesListener);
                source.on('clear', refreshSelectedTilesListener);
            }
            source.on('addfeature', updateDraftOrderAOIListener);
            source.on('clear', updateDraftOrderAOIListener);

            return () => {
                source.un('addfeature', updateDraftOrderAOIListener);
                source.un('clear', updateDraftOrderAOIListener);
                if (tileLayer) {
                    source.un('addfeature', refreshSelectedTilesListener);
                    source.un('clear', refreshSelectedTilesListener);
                }
            }
        }
    }, [dispatch, drawingLayer, tileLayer, listenerWait, refreshSelectedTiles, updateDraftOrderAOIAndTiles]);

    const mapClass = classNames(mapClasses, classes.map);

    const canExpand = expandable && dataPackage && (dataPackage.polygon || dataPackage.tiles);

    return <Fragment>
        {showPreviewModal &&
            <DataPackageMapModal handleClose={() => setShowPreviewModal(false)} productId={productId} dataPackage={dataPackage}/>
        }

        {errorMessage &&
            <Notification variant='warning'
                          appearance='toast'
                          onClose={() => {setErrorMessage(null)}}>
                <Typography variant='body1'>
                    {errorMessage}
                </Typography>
            </Notification>
        }

        <div ref={wrapper} data-testid="map" id={'map'}
             className={mapClass}>
            {canExpand &&
                <div className={classes.expandMap}>
                    <ExpandMapButton onClick={() => setShowPreviewModal(true)} />
                </div>
            }

            {showToolbar &&
                <DataPackageMapToolbar map={map}
                    updateDraftOrderAOI = {updateDraftOrderAOIAndTiles}
                    refreshSelectedTiles={refreshSelectedTiles}
                    selectedTileLayer={selectedTileLayer}
                    deleteLayer={deleteLayer}
                    drawingLayer={drawingLayer}
                    tileLayer={tileLayer}
                    currentPolygonLayer={currentPolygonLayer}/>
            }

            {!expandable &&
                <img className={classes.logo} src={logo} alt='OS logo'/>
            }

            {
                computingTiles > 0 && <CircularProgress className={classes.spinner}
                                                        aria-label={intl.formatMessage(messages.spinner)}/>
            }
        </div>
    </Fragment>
}

DataPackageMap.propTypes = {
    productId: PropTypes.string,
    listenerWait: PropTypes.number,
    dataPackage: PropTypes.object,
    showToolbar: PropTypes.bool,
    expandable: PropTypes.bool,
    mapClasses: PropTypes.string
};

DataPackageMap.defaultProps = {
    listenerWait: 250,
    showToolbar: true,
    expandable: false
};
