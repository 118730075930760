import React, { forwardRef, Fragment } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "@mui/material";
import { osColour, VNNavButtons } from "omse-components";
import {createUseStyles} from 'react-jss';
import classNames from 'classnames';
import {navTabs, tabRoot} from '../../util/responsive-navigation';
import { useSelector } from "react-redux";

const useStyles = createUseStyles(theme => ({
    tabsContainer: {
        flex: '1 1 auto',
        minWidth: 0,
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            margin: 0
        },
        '& button:first-of-type': {
            marginLeft: 0
        }
    },
    tabsFixed: {
        paddingLeft: theme.spacing(1)
    },
    tabRoot: {
        padding: 6,
        marginLeft: 8,
        minHeight: 52,
        marginTop: 6,
        marginBottom: 6,
        minWidth: 0,
        opacity: 1,
        ...theme.typography.body1,
        color: osColour.neutral.charcoal,
        fontSize: '1rem',
        '&:hover': {
            color: osColour.primary.lighterBerry
        },
        '&:hover:not($tabSelected), &:focus': {
            boxShadow: '0 3px 0 0 #fff, 0 6px 0 0 ' + osColour.primary.lighterBerry
        },
        [theme.breakpoints.up(1440)]: {
            padding: '6px 20px'
        }
    },
    tabSelected: {
        ...theme.typography.body2,
        color: osColour.primary.berry,
        fontSize: '1rem',
        boxShadow: '0 3px 0 0 #fff, 0 6px 0 0 #645baf'
    },
    menuTabs: {
        zIndex: 1,
        '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap'
        }
    },
    indicator: {
        display: 'none'
    }
}));

export const HeaderNavButtons = ({
    display,
    navLabel,
    currentTab,
    changeTab,
    updateUserActionsMode
}, lastTabRef) => {
    const classes = useStyles();
    const tabs = useSelector(state => state.menu.tabs);
    switch (display) {
        case 'VernacularNames':
            return <VNNavButtons/>
        case 'FullHeader':
            return <Fragment>
                <nav className={classes.tabsContainer} aria-label={navLabel}>
                    <Tabs className={classNames(classes.menuTabs, navTabs)}
                          value={currentTab || false}
                          onChange={changeTab}
                          classes={{indicator: classes.indicator, fixed: classes.tabsFixed}}
                          action={updateUserActionsMode}
                    >
                        {tabs.map((tab, i) => {
                            const other = {};
                            if (i === 0) {
                                other.tabIndex = 0;
                            }
                            if (i === tabs.length - 1 && lastTabRef.current !== undefined) {
                                other.ref = lastTabRef;
                            }
                            return (
                                <Tab
                                classes={{
                                    root: classNames(classes.tabRoot, tabRoot),
                                    selected: classes.tabSelected
                                }}
                                onClick={event => {
                                    if (event.target.firstChild.textContent === currentTab) {
                                        changeTab(event, currentTab);
                                    }
                                }}
                                value={tab.defaultMessage}
                                label={tab.defaultMessage}
                                key={tab.id}
                                disableTouchRipple
                                {...other}
                            />
                        );
                        })}
                    </Tabs>
                </nav>
            </Fragment>
        default:
            return null;
    }
};

const HeaderNavButtonsForwarded = forwardRef(HeaderNavButtons);
HeaderNavButtonsForwarded.propTypes = {
    display: PropTypes.string.isRequired,
    navLabel: PropTypes.string,
    currentTab: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    changeTab: PropTypes.func.isRequired,
    updateUserActionsMode: PropTypes.func.isRequired
}

export default HeaderNavButtonsForwarded;
