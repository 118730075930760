import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {defineMessages, FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {createUseStyles} from 'react-jss';
import {osColour, linkButton} from 'omse-components';
import classNames from 'classnames';
import LiveToDevModal from "./projectMode/LiveToDevModal";
import DevToLiveModal from "./projectMode/DevToLiveModal";
import UnableToChangeModal from "./projectMode/UnableToChangeModal";
import { hasManageProjectModePermission } from "../util/permissions";
import {DEV_MODE} from '../../shared/project-modes';
import {COST_TYPE_PREMIUM} from '../../shared/constants';
import featureCheck from '../util/featureCheck';
import {EAIAPI} from '../../shared/features';
import { isEaiUser } from "../util/plans";

const liveColour = osColour.status.success;
const devColour = osColour.status.error;

const styles = createUseStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        '&.live': {
            color: liveColour
        },
        '&.dev': {
            color: devColour
        }
    },
    dot: {
        height: '16px',
        width: '16px',
        borderRadius: '50%',
        marginRight: theme.spacing(1),
        '&.live': {
            backgroundColor: liveColour
        },
        '&.dev': {
            backgroundColor: devColour
        }
    },
    button: {
        fontWeight: 400,
        padding: '6px 8px !important'
    }
}));

const useLinkStyles = createUseStyles({linkButton});

const messages = defineMessages({
    live: {
        id: 'ProjectMode.live',
        defaultMessage: 'Live',
        description: 'Label for the live mode indicator'
    },
    dev: {
        id: 'ProjectMode.dev',
        defaultMessage: 'Development',
        description: 'Label for the dev mode indicator'
    },
    setFromlive: {
        id: 'ProjectMode.setToDev',
        defaultMessage: '(change to Development mode)',
        description: 'Label for the change to dev mode indicator'
    },
    setFromdev: {
        id: 'ProjectMode.setToLive',
        defaultMessage: '(change to Live mode)',
        description: 'Label for the change to live mode indicator'
    }
});

export default function ProjectMode({project, allowChange, plans}) {
    const classes = styles();
    const classesLink = useLinkStyles();
    const [currentDialog, setCurrentDialog] = useState(null);
    const userDetails = useSelector(state => state.user.current.result);
    const config = useSelector(state => state.config.current.result);

    if(!project || !project.mode) {
        return null;
    }

    // Open data and PSGA users should not be able to see the project mode
    if(!hasManageProjectModePermission(userDetails, project.orgId)) {
        return null;
    }

    const mode = project.mode;
    const rootClasses = classNames(classes.root, mode);
    const dotClasses = classNames(classes.dot, mode);
    const message = messages[mode];
    const setMessage = messages['setFrom' + mode];

    function reset() {
        setCurrentDialog(null);
    }

    function setupDialog() {
        let dialog;

        const isCom3User = isEaiUser(userDetails);
        const projectHasPremium = project.products?.some(product => product.costType === COST_TYPE_PREMIUM) || false;
        const noApiPlans = !plans || plans.length === 0;
        const projectIsDev = project.mode === DEV_MODE;

        if (isCom3User && projectIsDev && noApiPlans && projectHasPremium) {
            dialog = <UnableToChangeModal onClose={reset}/>
        } else if(projectIsDev) {
            dialog = <DevToLiveModal onClose={reset}/>
        } else {
            dialog = <LiveToDevModal onClose={reset} isEai={featureCheck(EAIAPI, config) && isCom3User}/>
        }
        setCurrentDialog(dialog);
    }

    return <div className={rootClasses} data-testid={"project-mode"}>
        <div className={dotClasses}/>
        <Typography variant='body1' color={'inherit'}>
            <FormattedMessage {...message}/>
        </Typography>
        {
            allowChange && <Button className={classNames(classesLink.linkButton, classes.button)}
                                   onClick={setupDialog}
                                   variant='text'
                                   disableRipple
            >
                <FormattedMessage {...setMessage}/>
            </Button>
        }
        {
            currentDialog
        }
    </div>;
}

ProjectMode.propTypes = {
    project: PropTypes.object,
    allowChange: PropTypes.bool
};
