const premiumOsOrders = [
    {
        name: '1:25 000 Scale Colour Raster',
        variants: [{
            name: 'TIFF 8 bit LZW™ Comp. (GB coverage)',
            subscript: 1,
            dvd: true
        },{
            name: 'TIFF Packbits Comp. (GB coverage)',
            subscript: 1,
            dvd: true
        }]
    },
    {
        name: '1:50 000 Scale Colour Raster',
        variants: [{
            name: 'BMP Uncomp. (GB coverage)',
            subscript: 1,
            dvd: true
        },{
            name: 'TIFF 8 bit LZW™ Comp. (GB coverage)',
            subscript: 1,
            dvd: true
        },{
            name: 'TIFF 8 bit Uncomp. (GB coverage)',
            subscript: 1,
            dvd: true
        }]
    },
    {
        name: 'AddressBase',
        variants: [{
            name: 'CSV (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        },{
            name: 'CSV & GML 3.2 (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        },{
            name: 'GML 3.2 (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        }]
    },
    {
        name: 'AddressBase Plus',
        variants: [{
            name: 'CSV (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        },{
            name: 'CSV & GML 3.2 (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        },{
            name: 'GML 3.2 (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        }]
    },
    {
        name: 'AddressBase Premium',
        variants: [{
            name: 'CSV (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        },{
            name: 'CSV & GML 3.2 (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        },{
            name: 'GML 3.2 (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        }]
    },
    {
        name: 'OSMM Topography',
        variants: [{
            name: 'GML Geo chunk 2x2km (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        },{
            name: 'GML Geo chunk 5x5km (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        },{
            name: 'GML Geo chunk 10x10km (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        },{
            name: 'GML Non Geo chunk 10mb (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        },{
            name: 'GML Non Geo chunk 30mb (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        },{
            name: 'GML Non Geo chunk 50mb (User defined area)',
            subscript: 2,
            sftp: true,
            dvd: true
        },{
            name: 'GML Geo chunk 5km (Full supply of GB coverage)',
            subscript: 3,
            dvd: true
        },{
            name: 'GML Non Geo chunk 30mb (Full supply of GB coverage)',
            subscript: 3,
            dvd: true
        }]
    },
    {
        name: 'OS VectorMap Local',
        variants: [{
            name: 'GML 3.2 (GB coverage)',
            subscript: 1,
            dvd: true
        }]
    },
    {
        name: 'OS VectorMap Local - Black and White Raster',
        variants: [{
            name: 'GeoTIFF LZW™ Comp. (GB coverage)',
            subscript: 1,
            dvd: true
        }]
    },
    {
        name: 'OS VectorMap Local - Colour Raster',
        variants: [{
            name: 'GeoTIFF LZW™ Comp. (GB coverage)',
            subscript: 1,
            dvd: true
        }]
    },
    {
        name: 'OS VectorMap Local - Colour Raster - Backdrop',
        variants: [{
            name: 'GeoTIFF LZW™ Comp. (GB coverage)',
            subscript: 1,
            dvd: true
        }]
    }
];

export default premiumOsOrders;