import React, {Fragment, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {
    CommonDialog,
    CommonDialogActions,
    Notification,
    ExternalLink
} from 'omse-components';
import {Typography} from '@mui/material';
import {defineMessages, FormattedMessage} from 'react-intl';
import {clearCreateDataPackage, createDataPackage} from "../../../modules/dataPackages/actions";
import useActionIdSelector from "../../../hooks/useActionIdSelector";
import { RESUPPLY_REQUESTED } from '../../../../shared/constants';
import {isNgdDataPackage} from "../../../../shared/ngd";

const messages = defineMessages({
    resupplyRequestTitle: {
        id: 'DataPackage.resupplyRequestTitle',
        defaultMessage: 'Resupply',
        description: 'resupply request dialog title'
    },
    resupplyRequestMessage: {
        id: 'DataPackage.resupplyRequestMessage',
        defaultMessage: 'Do you want to request a resupply of this data package?',
        description: 'resupply request dialog message'
    },
    resupplyRequestPrice: {
       id: 'DataPackage.resupplyRequestPrice',
       defaultMessage: 'Price: £{price}',
       description: 'resupply request dialog price'
    },
    resupplyRequestProceed: {
        id: 'DataPackage.resupplyRequestProceed',
        defaultMessage: 'Get Resupply',
        description: 'resupply request dialog proceed message'
    },
    resupplySuccess: {
        id: 'DataPackage.resupplySuccess',
        defaultMessage: 'We’re preparing your resupply. We’ll email you when it’s ready.',
        description: 'resupply success message'
    },
    resupplyFailure: {
        id: 'DataPackage.resupplyFailure',
        defaultMessage: 'There was a problem requesting your resupply. Please retry and if the problem persists then {link}.',
        description: 'error message text'
    }
});


export default function ResupplyDataPackageDialog({dataPackage, onClose, isEaiUser=false, resupplyPrice="0.00"}) {
    const dispatch = useDispatch();

    let [{working, result, error}, createDispatch] = useActionIdSelector("dataPackages.create");

    useEffect(() => {
        return () => {
            dispatch(clearCreateDataPackage());
        }
    }, [dispatch]);

    function resupply() {
        let request = {
            id: dataPackage.id,
            packageName: dataPackage.name,
            productId: dataPackage.productId,
            updates: dataPackage.updateScheduleId,
            format: dataPackage.dataFormatId,
            tiles: dataPackage.tiles,
            AOI: dataPackage.polygon,
            area: 'Polygon',
            changeCode: RESUPPLY_REQUESTED,
            catalogueId: dataPackage.catalogueId,
            isNgd: isNgdDataPackage(dataPackage)
        };

        if (dataPackage.fixedPriceAreas && dataPackage.fixedPriceAreas.length) {
            request.area = dataPackage.fixedPriceAreas[0].id;
        }

        createDispatch(createDataPackage(request));
    }

    const actions = <CommonDialogActions onClose={onClose}
                                         onConfirm={resupply}
                                         confirmLabel={messages.resupplyRequestProceed}
                                         working={working}/>;

    let content = <CommonDialog onClose={onClose}
                                title={messages.resupplyRequestTitle}
                                actions={actions}>
        <Typography variant='body1'>
            <FormattedMessage {...messages.resupplyRequestMessage}/>
        </Typography>
        { isEaiUser &&
            <Typography variant='body1'>
                <FormattedMessage {...messages.resupplyRequestPrice} values={{price:resupplyPrice}}/>
            </Typography>
        }

    </CommonDialog>;

    if (result) {
        content = <Notification variant='success' appearance='toast' onClose={onClose} autoClose={true}>
            <Typography variant='body1'>
                <FormattedMessage {...messages.resupplySuccess}/>
            </Typography>
        </Notification>
    }

    if (error) {
        content = <Notification variant='error' appearance='toast' onClose={onClose}>
            <Typography variant='body1'>
                <FormattedMessage {...messages.resupplyFailure} values={{ link: <ExternalLink type='support' /> }} />
            </Typography>
        </Notification>
    }

    return <Fragment>
        {content}
    </Fragment>
}
