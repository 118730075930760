import React, {Fragment} from 'react';
import Header from "./Header";
import Content from './Content';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import ResponseCodes from "./ResponseCodes";
import styles from './style';
import WFSFeatureTypes from './WFSFeatureTypes';
import routes from "../../util/routes";
import Authentication from "./Authentication";
import DocLinks from "./DocLinks";
import Document from './Document';
import Table from './Table';
import ProductArchiveFeatureTypes from "./ProductArchiveFeatureTypes";
import { InternalLink } from 'omse-components';
import TextBox from '../../components/TextBox';
import CodeSnippet from './CodeSnippet';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

const getCapabilites = [
    [<InlineCodeSnippet>key</InlineCodeSnippet>, 'required', 'The API Key provided by the OS Data Hub'],
    [<InlineCodeSnippet>request</InlineCodeSnippet>, 'required', <InlineCodeSnippet>GetCapabilities</InlineCodeSnippet>],
    [<InlineCodeSnippet>service</InlineCodeSnippet>, 'required', <InlineCodeSnippet>WFS</InlineCodeSnippet>],
    [<InlineCodeSnippet>version</InlineCodeSnippet>, 'optional', <InlineCodeSnippet>2.0.0</InlineCodeSnippet>]
];

const describeFeatureType = [
    [<InlineCodeSnippet>key</InlineCodeSnippet>, 'required', 'The API Key provided by the OS Data Hub'],
    [<InlineCodeSnippet>request</InlineCodeSnippet>, 'required', <InlineCodeSnippet>DescribeFeatureType</InlineCodeSnippet>],
    [<InlineCodeSnippet>service</InlineCodeSnippet>, 'required', <InlineCodeSnippet>WFS</InlineCodeSnippet>],
    [<InlineCodeSnippet>version</InlineCodeSnippet>, 'required', <><InlineCodeSnippet>2.0.0</InlineCodeSnippet>, <InlineCodeSnippet>1.1.0</InlineCodeSnippet>, <InlineCodeSnippet>1.0.0</InlineCodeSnippet></>],
    [<InlineCodeSnippet>typeNames</InlineCodeSnippet>, 'required', 'Any of the feature type names listed in the Content section of this document'],
];

const getFeature = [
    [<InlineCodeSnippet>key</InlineCodeSnippet>, 'required', 'The API Key provided by the OS Data Hub'],
    [<InlineCodeSnippet>bbox</InlineCodeSnippet>, 'optional', <>A set of valid WFS BBOX coordinates, in the order {'<bottom-left y, bottom-left x, top-right y, top-right x>'} (Dependent on CRS)</>],
    [<InlineCodeSnippet>filter</InlineCodeSnippet>, 'optional', 'A valid OGC XML filter object (see below)'],
    [<InlineCodeSnippet>count</InlineCodeSnippet>, 'optional', <Fragment>
        <p>An integer stating the maximum number of features to be returned in a single response (default and max is 100 per request)</p>
        <p>In version 1.0 and 1.1 this is known as <InlineCodeSnippet>maxFeatures</InlineCodeSnippet></p>
    </Fragment>],
    [<InlineCodeSnippet>sortBy</InlineCodeSnippet>, 'optional', 'Allows the user to order their response based on search attribute.'],
    [<InlineCodeSnippet>propertyName</InlineCodeSnippet>, 'optional', <>A list of valid feature attribute names, for example, <InlineCodeSnippet>ChangeDate</InlineCodeSnippet>, <InlineCodeSnippet>TOID</InlineCodeSnippet>, <InlineCodeSnippet>PhysicalLevel</InlineCodeSnippet></>],
    [<InlineCodeSnippet>request</InlineCodeSnippet>, 'required', <InlineCodeSnippet>GetFeature</InlineCodeSnippet>],
    [<InlineCodeSnippet>service</InlineCodeSnippet>, 'required', <InlineCodeSnippet>WFS</InlineCodeSnippet>],
    [<InlineCodeSnippet>version</InlineCodeSnippet>, 'required', <><InlineCodeSnippet>2.0.0</InlineCodeSnippet>, <InlineCodeSnippet>1.1.0</InlineCodeSnippet>, <InlineCodeSnippet>1.0.0</InlineCodeSnippet></>],
    [<InlineCodeSnippet>startIndex</InlineCodeSnippet>, 'optional', 'An integer stating which result to start from, when returning a response'],
    [<InlineCodeSnippet>typeNames</InlineCodeSnippet>, 'required', 'Any of the feature type names listed in the Content section of this document'],
    [<InlineCodeSnippet>outputFormat</InlineCodeSnippet>, 'optional', 'Valid output formats are documented in the response to the GetCapabilities request'],
    [<InlineCodeSnippet>resultType</InlineCodeSnippet>, 'optional', <Fragment>
        <p>Valid values are results and hits. The default is results</p>
        <p>
            When running a query with <InlineCodeSnippet>resultType</InlineCodeSnippet> hits and a broad search criteria, you may find that the service times out before returning the response.
            If this happens narrow your search by searching over a smaller geographic area and/or using attribute based filters
        </p>
        <p>See the Limitations section for more details about filtering</p>
    </Fragment>],
    [<InlineCodeSnippet>srsName</InlineCodeSnippet>, 'optional', 'Allows the user to reproject the selected features into another CRS']
];

function WFSDetail() {
    return <Document>
        <Header back={true}>OS Features API: Technical specification</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains the content of the OS Features API, and the methods that you can use to call it.
                    If you are not familiar with the OS Features API you may want to read the <InternalLink path={routes.wfsOverview} message="overview documentation" /> before proceeding.
                </p>
                <p>
                    The OS Features API lets users call and interact with OS data, including the highly detailed OS MasterMap Topography Layer.
                    It does this by exposing the data through an Open Geospatial Consortium Web Feature Service (OGC WFS).
                </p>
            </Typography>
            <WFSFeatureTypes/>
            <ProductArchiveFeatureTypes />
            <Typography variant='h1' component={'h2'}>Technical Detail</Typography>
            <Typography variant='body1' paragraph={true}>
                OS Features API requests are structured using keyword-value pair (KVP) parameter encoding, and HTTP <InlineCodeSnippet>GET</InlineCodeSnippet>.
                All requests must be encoded correctly according to standard percent-encoding procedures for a URI.
            </Typography>
            <Authentication gettingStartedRoute={routes.wfsIntro}/>
            <Typography variant='h2'>Encryption</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    As of October 2018, all calls must be made using a secure hypertext encryption (HTTPS).
                </p>
                <p>
                    Ordnance Survey APIs no longer support calls made using SSL v2/v3 or TLS v1.0/v1.1.
                    Thus, all calls made to any of our APIs must support TLS v1.2 as of October 2018 in line with current cyber security recommendations.
                </p>
            </Typography>
            <ResponseCodes/>
            <Typography variant='h2'>WFS Operation</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The WFS accepts three types of requests:
                </p>
                <ul>
                    <li><b>GetCapabilities</b> request to discover the service metadata.</li>
                    <li><b>DescribeFeatureType</b> request to determine the attributes of a given feature type.</li>
                    <li><b>GetFeature</b> request to request features matching a given query.</li>
                </ul>
            </Typography>
            <Typography variant='h3'>Queries</Typography>
            <Typography variant='body1' paragraph={true}>
                    OS Features API works slightly differently from Ordnance Survey’s other APIs. The number of valid values per 
                    parameter is extensive. This is due to the complexity of the underlying data which leads to a large number 
                    of parameters and values that can be applied to call and filter the WFS. It is recommended that the GetCapabilities 
                    document should be used extensively to shape requests to the service. This will aid with the efficiency of the call.
            </Typography>
            <Typography variant='h3'>GetCapabilities</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Returns a metadata document describing the WFS service provided by the server as well as valid WFS operations and parameters.
                </p>
                <TextBox
                    text="https://api.os.uk/features/v1/wfs?service=wfs&request=getcapabilities&key="
                    label="OS Features API - 'GetCapabilities' Endpoint"
                    variant="code"
                />
                <Table>
                    <tbody>
                        <tr>
                            <th>Parameter</th>
                            <th>Required?</th>
                            <th>Value</th>
                        </tr>
                        {
                            getCapabilites.map((parameter, index) =>
                                <tr key={index}>
                                    <td>{parameter[0]}</td>
                                    <td>{parameter[1]}</td>
                                    <td>{parameter[2]}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Typography>
            <Typography variant='h3'>DescribeFeatureType</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Returns a description of the attributes for each feature type supported by the WFS service.
                </p>
                <TextBox
                    text="https://api.os.uk/features/v1/wfs?service=wfs&version=2.0.0&request=DescribeFeatureType&key="
                    label="OS Features API - 'DescribeFeatureType' Endpoint"
                    variant="code"
                />
                <Table>
                    <tbody>
                        <tr>
                            <th>Parameter</th>
                            <th>Required?</th>
                            <th>Value</th>
                        </tr>
                        {
                            describeFeatureType.map((parameter, index) =>
                                <tr key={index}>
                                    <td>{parameter[0]}</td>
                                    <td>{parameter[1]}</td>
                                    <td>{parameter[2]}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Typography>
            <Typography variant='h3'>GetFeature</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Returns a selection of a specific feature from the WFS including its attribute values and geometry.
                    The maximum number of features you can call at any given time is <InlineCodeSnippet>100</InlineCodeSnippet>.
                </p>
                <TextBox
                    text="https://api.os.uk/features/v1/wfs?service=wfs&version=2.0.0&request=GetFeature&typeNames=Topography_TopographicPoint&count=20&key="
                    label="OS Features API - 'GetFeature' Endpoint"
                    variant="code"
                />
                <Table>
                    <tbody>
                        <tr>
                            <th>Parameter</th>
                            <th>Required?</th>
                            <th>Value</th>
                        </tr>
                        {
                            getFeature.map((parameter, index) =>
                                <tr key={index}>
                                    <td>{parameter[0]}</td>
                                    <td>{parameter[1]}</td>
                                    <td>{parameter[2]}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Typography>
            <Typography variant='h2'>Coordinate Reference System</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    All data supplied by the WFS will default to EPSG:27700 (British National Grid) unless otherwise specified using the <InlineCodeSnippet>srsName</InlineCodeSnippet> attribute.
                </p>
                <p>
                    The exception to this is <InlineCodeSnippet>outputFormat=GeoJSON</InlineCodeSnippet> which will default to EPSG:4326 (WGS84) unless otherwise specified.
                    In order to obtain GeoJSON in EPSG:27700 you must therefore use <InlineCodeSnippet>outputFormat=GeoJSON&srsName=EPSG:27700</InlineCodeSnippet> in your query.
                </p>
            </Typography>
            <Typography variant='h2'>Bounding Box</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The WFS operates using a bounding box (bbox) to denote its area of interest.
                    It operates as a means of focusing your query and ensures you only get the features you are interested in.
                    If you are using a GIS, the bbox is set by your window, however, if you are using a URL or making the call using an XML database,
                    the user will have to manually apply the bbox values. This can be set either in EPSG:27700 (BNG) or EPSG:3857 (Web Mercator).
                    When specifying the coordinates of your bbox, you should ensure that they align with the coordinate reference system (CRS)
                    that you have specified using the <InlineCodeSnippet>srsName</InlineCodeSnippet> parameter.
                </p>
                <p>
                    The bbox parameter is influenced by the CRS the user is applying and will need to account for the differences in coordinate systems when specifying a bounding box.
                </p>
                <p>
                    You should pay attention to the ordering of the components of each ‘x’ and ‘y’ coordinate and ensure that there are no spaces between them.
                    You should order them with the bottom left corner first followed by the top right corner as shown below:
                </p>

                {
                    '<bottom-left x, bottom-left y, top-right x, top-right y> (Dependent on CRS)'
                }
            </Typography>
            <Typography variant='h2'>Limitations and Recommendations</Typography>
            <Typography variant='h3'>Filtering</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The OS Features API service accepts standard OGC XML filter parameters and can be applied following the normal OGC filter structure.
                    When constructing an attribute-based filter, any attribute returned in the DescribeFeatureType response is a valid candidate.
                </p>
                <p>
                    For example, to return all <InlineCodeSnippet>Topography_TopographicArea</InlineCodeSnippet> Features with a <InlineCodeSnippet>DescriptiveGroup</InlineCodeSnippet> of <InlineCodeSnippet>Roadside</InlineCodeSnippet>, a user would submit a <InlineCodeSnippet>GetFeature</InlineCodeSnippet> request with a <InlineCodeSnippet>typeNames</InlineCodeSnippet> parameter of <InlineCodeSnippet>Topography_TopographicArea</InlineCodeSnippet>, and a <InlineCodeSnippet>filter</InlineCodeSnippet> parameter like this:
                </p>
                <CodeSnippet>
                    {
                        '<ogc:Filter>\n' +
                        '  <ogc:PropertyIsEqualTo>\n' +
                        '    <ogc:PropertyName>DescriptiveGroup</ogc:PropertyName>\n' +
                        '    <ogc:Literal>Roadside</ogc:Literal>\n' +
                        '  </ogc:PropertyIsEqualTo>\n' +
                        '</ogc:Filter>'
                    }
                </CodeSnippet>
                <p>
                    Note: If you wish to combine both a bounding box and another filter then the two should be included in a single OGC filter parameter.
                    If the user were to combine a purely attribute-based OGC filter (as above) with a bounding box via the bbox request parameter, the bbox request parameter would take precedence and the attribute filter would be ignored.
                </p>
                <p>
                    A combined bounding box and attribute filter might look something like this:
                </p>
                <CodeSnippet>
                    {
                        '<ogc:Filter>\n' +
                        '  <ogc:And>\n' +
                        '    <ogc:Within>\n' +
                        '      <PropertyName>SHAPE</PropertyName>\n' +
                        '      <gml:Box xmlns:gml="http://www.opengis.net/gml" srsName="EPSG:27700">\n' +
                        '        <gml:coordinates decimal="." cs="," ts=",">\n'  +
                        '          436833.50,115334.90,437643.25,115761.50\n' +
                        '        </gml:coordinates>\n' +
                        '      </gml:Box>\n' +
                        '    </ogc:Within>\n' +
                        '    <ogc:PropertyIsEqualTo>\n' +
                        '      <ogc:PropertyName>DescriptiveGroup</ogc:PropertyName>\n' +
                        '      <ogc:Literal>Roadside</ogc:Literal>\n' +
                        '    </ogc:PropertyIsEqualTo>\n' +
                        '  </ogc:And>\n' +
                        '</ogc:Filter>'
                    }
                </CodeSnippet>
            </Typography>
            <Typography variant='h3'>Paging</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Response paging uses previous and next server generated URIs to enable software applications to navigate through the responses:
                </p>
                <ul>
                    <li>The first response will only have a next URI. </li>
                    <li>Subsequent responses will have both a previous and next URI. </li>
                    <li>The final response will have a previous URI. </li>
                </ul>
                <p>
                    For example, if the user wants to retrieve the third page of 100 results for a given query, they would submit a <InlineCodeSnippet>count</InlineCodeSnippet> value of <InlineCodeSnippet>100</InlineCodeSnippet>
                    and a <InlineCodeSnippet>startIndex</InlineCodeSnippet> of <InlineCodeSnippet>200</InlineCodeSnippet>. The previous and next URIs can be used to page through the responses.
                </p>
                <p>
                    You should not send a request with a <InlineCodeSnippet>count</InlineCodeSnippet> value higher than maximum number of features returned by the service (i.e. 100) as this will truncate the request.
                </p>
            </Typography>
            <Typography variant='h3'>Empty Values</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The API will only return attributes that are populated with values.
                </p>
                <p>
                    Depending on your implementation or the software you use to access the API you may find inconsistencies in the way missing attributes are handled.
                </p>
                <p>
                    For example, QGIS 3.4 treats the value of a missing attribute of type integer on a feature as a <InlineCodeSnippet>NULL</InlineCodeSnippet> value in its data view, whereas a missing attribute of string type is treated as an empty string.
                </p>
            </Typography>
            <DocLinks product='wfs' hasGettingStarted={true} hasDemo={true}/>
        </Content>
    </Document>
}

export default withStyles(styles)(WFSDetail);
