import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {defineMessages, useIntl} from 'react-intl';
import {ReactComponent as Folder} from "../../../../../components/icons/folder.svg";
import {ReactComponent as Forward} from "../../../../../components/icons/forward.svg";
import {ReactComponent as Down} from "../../../../../components/icons/down.svg";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    CircularProgress
} from '@mui/material';
import styles from './styles';

const messages = defineMessages({
    buttonCollapseAriaLabel: {
        id: 'FileCollection.buttonCollapseAriaLabel',
        defaultMessage: 'Folder {label}, click to collapse',
        description: 'Aria label for collapsing a folder'
    },
    buttonExpandAriaLabel: {
        id: 'FileCollection.buttonExpandAriaLabel',
        defaultMessage: 'Folder {label}, click to expand',
        description: 'Aria label for expanding a folder'
    },
    buttonSelectedFolderAriaLabel: {
        id: 'FileCollection.buttonSelectedFolderAriaLabel',
        defaultMessage: 'Folder {label}, selected',
        description: 'Aria label for the selected folder'
    },
    buttonSelectFolderAriaLabel: {
        id: 'FileCollection.buttonSelectFolderAriaLabel',
        defaultMessage: 'Folder {label}, click to select',
        description: 'Aria label for selecting a folder'
    },
});

export default function FileCollection(props) {
    const {parentPath, selectedPath, setSelected, collection, initialOpen, depth} = props;
    const [isOpen, setOpen] = useState(initialOpen);
    const classes = styles();
    const intl = useIntl();

    let myLabel = collection.path;
    let myPath;
    if(parentPath) {
        myPath = parentPath + '/' + myLabel;
    } else {
        myPath = myLabel;
    }


    let icon = <Forward width={24} height={24} aria-hidden={true}/>;
    if(collection.loading) {
        icon = <CircularProgress size={24}/>;
    } else if(isOpen && collection.collections) {
        icon = <Down width={24} height={24} aria-hidden={true}/>;
    }

    function selectOrToggle() {
        if(collection.files) {
            setSelected(myPath);
        } else {
            setOpen(!isOpen);
        }
    }

    let folderSelected = (myPath === selectedPath);

    let expandValue;
    let buttonAriaLabel;
    if (collection.collections) {
        expandValue = isOpen;
        if (isOpen) {
            buttonAriaLabel = intl.formatMessage(messages.buttonCollapseAriaLabel, {label: myLabel});
        } else {
            buttonAriaLabel = intl.formatMessage(messages.buttonExpandAriaLabel, {label: myLabel});
        }
    } else {
        if (folderSelected) {
            buttonAriaLabel = intl.formatMessage(messages.buttonSelectedFolderAriaLabel, {label: myLabel});
        } else {
            buttonAriaLabel = intl.formatMessage(messages.buttonSelectFolderAriaLabel, {label: myLabel});
        }
    }

    return <Fragment>
        <ListItem button
                  selected={folderSelected}
                  onClick={selectOrToggle}
                  className={classes["nested" + depth]}
                  aria-expanded={expandValue}
                  aria-label={buttonAriaLabel}
        >
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemIcon>
                <Folder/>
            </ListItemIcon>
            <ListItemText primary={myLabel}/>
        </ListItem>
        {
            isOpen && collection.collections &&
            <List component='div'>
            {
                collection.collections.map(collection =>
                    <FileCollection key={collection.path}
                                    {...props}
                                    parentPath={myPath}
                                    collection={collection}
                                    depth={depth+1}
                    />)
            }
            </List>
        }
    </Fragment>;
}

FileCollection.propTypes = {
    parentPath: PropTypes.string,
    initialOpen: PropTypes.bool,
    selectedPath: PropTypes.string.isRequired,
    setSelected: PropTypes.func.isRequired,
    collection: PropTypes.shape({
        path: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                defaultMessage: PropTypes.string.isRequired,
                description: PropTypes.string
            })
        ]).isRequired,
        loading: PropTypes.bool,
        collections: PropTypes.array,
        files: PropTypes.array
    }).isRequired,
    depth: PropTypes.number
}

FileCollection.defaultProps = {
    parentPath: '',
    initialOpen: false,
    depth: 0
}
