import React from 'react';
import Header from "./Header";
import Content from './Content';
import featuresImage from "./ofaOverview/os-ngd-api-features-overview.PNG";
import Typography from '@mui/material/Typography';
import DocLinks from "./DocLinks";
import Document from './Document';
import FeatureCheck from "../../components/FeatureCheck";
import UsingOFA from "./UsingOFA";
import { ExternalLink } from 'omse-components';

export default function OFAOverview() {
    return <FeatureCheck feature='ngdFeatures'><Document>
        <Header back={true}>OS NGD API – Features: Overview</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    OS NGD API – Features gives you simple access to the OS National Geographic Database (OS NGD).
                    Get started quickly and request the data you need, as and when you need it, using the latest in API
                    standards (based on the <ExternalLink
                        type="generic"
                        href="https://ogcapi.ogc.org/features/"
                        message="OGC API – Features"
                    /> specification).
                </p>
                <p>
                    This API is self-documenting and allows you to easily discover what OS NGD data is available before using it.
                    Explore the various data collections for free to decide what best suits your needs.
                    The data is ideal for geospatial analysis, provided in GeoJSON format and ready to use in many
                    applications (desktop, web and mobile). Power your applications with rich, consistent and current
                    information about the real world.
                </p>
                <p>
                    The OS NGD contains authoritative data that describes the geography of Great Britain – the data is
                    captured, maintained and improved by Ordnance Survey.
                    The OS NGD enables improved decision-making for new and existing customers in the public
                    and private sectors.
                </p>
                <p>
                    With OS NGD API – Features, you can filter by attribute, location and time to create your own
                    customised data selections. This API can help accelerate your time-to-value by making it easier
                    to build awesome things with our trusted geospatial data. Reduce your data management overheads,
                    automate your workflows and innovate at pace.
                </p>
            </Typography>
            <img src={featuresImage} alt='OS NGD API – Features: map showing NGD Buildings'/>
            <UsingOFA/>
            <DocLinks product='ofa' hasGettingStarted={true} hasTechnicalSpec={true} hasDemo={true}/>
        </Content>
    </Document></FeatureCheck>
}
