import React from 'react';
import {CommonDialog, CommonDialogActions} from "omse-components";
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {apiTermsName, downloadTermsName} from '../../../../shared/constants';
import PropTypes from 'prop-types';
import {productMessage} from '../../../util/terms';

const messages = defineMessages({
    title: {
        id: 'DeclineTermsDialog.title',
        defaultMessage: 'Continue without {product}?',
        description: 'Decline dialog title'
    },
    body: {
        id: 'DeclineTermsDialog.body',
        defaultMessage: 'By declining the terms and conditions you won’t be able to use the {product}.',
        description: 'Decline dialog body'
    },
    back: { 
        id: 'DeclineTermsDialog.back',
        defaultMessage: 'Go back...',
        description: 'Decline dialog back'
    },
    confirm: { 
        id: 'DeclineTermsDialog.confirm',
        defaultMessage: 'Decline and don\'t access {product}',
        description: 'Decline dialog confirm'
    }
});

export default function DeclineTermsDialog({variant, onClose, onCancel}) {
    const intl = useIntl();
    const product = intl.formatMessage(productMessage(variant));
    const declineActions = <CommonDialogActions onClose={onCancel}
        onConfirm={onClose}
        confirmAllowed={true}
        working={false}
        confirmLabel={intl.formatMessage(messages.confirm, {product})}
        cancelLabel={messages.back}
    />;

return <CommonDialog onClose={onClose} actions={declineActions}
            title={intl.formatMessage(messages.title, {product})}>
        <FormattedMessage {...messages.body} values={{product}} />
    </CommonDialog>
}

DeclineTermsDialog.propTypes = {
    variant: PropTypes.oneOf([apiTermsName, downloadTermsName]),
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

DeclineTermsDialog.defaultProps = {
    variant: apiTermsName
}