import styled from "@emotion/styled";
import { osColour } from "omse-components";
import tcWhatsNew from "../img/tc-whats-new.png";
import { Typography, Button, useMediaQuery, useTheme } from "@mui/material";
import { ReactComponent as MaximiseIcon } from "../../../components/icons/maximise.svg";
import { FormattedMessage, defineMessages } from "react-intl";
import { tabHeadingStyles } from "./styles";

const whatsNewMessage = defineMessages({
	title: {
		id: "whatsNew.title",
		defaultMessage: "What’s new on the OS Data Hub",
		description: "Title"
	},
	description: {
		id: "whatsNew.description",
		defaultMessage:
			"We’re constantly improving our data and services. View our product roadmap and see what’s coming next.",
		description: "Description"
	},
	button: {
		id: "whatsNew.button",
		defaultMessage: "View the OS Data Hub roadmap",
		description: "Button text"
	},
	buttonLabel: {
		id: "whatsNew.buttonLabel",
		defaultMessage: "OS Data Hub roadmap (Opens in New Tab)",
		description: "Button label"
	}
});


const StyledSection = styled("section")`
	display: grid;
	gap: 2em;
	grid-template: 1fr / auto auto;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 800px) {
		grid-template: auto auto / 1fr;
	}
`;

const StyledTextContainer = styled("div")`
	max-width: 32em;
	@media (max-width: 800px) {
		max-width: 100%;
	}
	${tabHeadingStyles}
`;

const StyledImage = styled("img")`
	max-width: 100%;
`;

const StyledButton = styled(Button)(
	({ isMobile }) => `
	margin-top: ${isMobile ? "0" : "36px"};
	display: flex;
	gap: 6px;
	@media (max-width: 800px) {
		width: 100%;
	}
`
);

export default function WhatsNew() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<StyledSection>
			<StyledTextContainer>
				<Typography gutterBottom variant="h2" component="h3">
					<FormattedMessage {...whatsNewMessage.title} />
				</Typography>
				<Typography paragraph gutterBottom variant="body1" sx={{ color: osColour.neutral.charcoal }}>
					<FormattedMessage {...whatsNewMessage.description} />
				</Typography>
				<StyledButton
					color="primary"
					variant="outlined"
					onClick={() =>
						window.open(
							"https://roadmap.prodpad.com/76df8d7e-b850-11ed-8fd8-0aabe7cf8f61",
							"_blank",
							"noopener noreferrer"
						)
					}
					isMobile={isMobile}
					aria-label={whatsNewMessage.buttonLabel.defaultMessage}
				>
					<FormattedMessage {...whatsNewMessage.button} />
					<MaximiseIcon height={20} width={20} alt="External Link Icon" />
				</StyledButton>
			</StyledTextContainer>
			<StyledImage src={tcWhatsNew} alt="a long-exposure shot of a busy highway at night" />
		</StyledSection>
	);
}
