import React from 'react';
import SearchBox from "../../../components/SearchBox";
import {defineMessages} from "react-intl";
import useLocationState from "./useLocationState";
import recipeLibraryStyles from "./recipeLibraryStyles";

const messages = defineMessages({
    searchLabel: {
        id: 'RecipeLibrarySearch.searchLabel',
        defaultMessage: 'Find recipes',
        description: 'Label for recipe library search'
    },
    searchPlaceholder: {
        id: 'RecipeLibrarySearch.searchPlaceholder',
        defaultMessage: 'Search by recipe name, author, description or content',
        description: 'Placeholder text for recipe library search input'
    }
});

export const SEARCH = 'search';

export default function RecipeLibrarySearch() {
    const [search, setSearch] = useLocationState(SEARCH);
    const classes = recipeLibraryStyles();
    return <SearchBox label={messages.searchLabel}
                      placeholder={messages.searchPlaceholder}
                      search={search}
                      setSearch={setSearch}
                      className={classes.searchBox}
    />;
}
