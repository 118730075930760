import visaImage from '../components/icons/visa.svg';
import discoverImage from '../components/icons/discover.svg';
import jcbImage from '../components/icons/jcb.svg';
import mastercardImage from '../components/icons/mastercard.svg';
import maestroImage from '../components/icons/maestro.svg';
import amexImage from '../components/icons/amex.svg';

const creditCardType = (cardNumber) => {
    const cardNumberIsString = typeof(cardNumber) === 'string';
    if(cardNumber && cardNumberIsString){
        const longEnough = cardNumber.length >=4;
        // eslint-disable-next-line no-useless-escape
        const regexPattern = new RegExp('^\d{0,4}', 'g');
        const firstFourIsNumber = regexPattern.test(cardNumber);

        if (longEnough && firstFourIsNumber) {
            const cardNum = Number(cardNumber.substring(0,4));

            return cards.find(card => {
                return card.checks.some(check => {
                    return cardNum>=check.lowerBound && cardNum<=check.upperBound;
                })
            })
        }
    }

    return undefined;
};
export default creditCardType;

/*
* The details behind the checks below come from this wikipedia page:
* https://en.wikipedia.org/wiki/Payment_card_number
* Bank Card numbers are set by ISO formats so unlikely to change
*/
const cards = [
    {
        type:'discover',
        displayName: 'Discover',
        image: discoverImage,
        checks:[
        {
            upperBound: 6041,
            lowerBound: 6041,
        },
        {
            upperBound: 6499,
            lowerBound: 6440,
        },
        {
            upperBound: 6599,
            lowerBound: 6500,
        }]
    },
    {
        type:'visa',
        displayName:'Visa',
        image: visaImage,
        checks: [
            {
                upperBound: 4999,
                lowerBound: 4000,
            }
        ]
    },
    {
        type:'american-express',
        displayName: 'American Express',
        image: amexImage,
        checks:[
            {
                upperBound: 3499,
                lowerBound: 3400,
            },
            {
                upperBound: 3799,
                lowerBound: 3700,
            },
        ]
    },
    {
        type: 'jcb',
        displayName:'JCB',
        image: jcbImage,
        checks:[
            {
                upperBound: 3589,
                lowerBound: 3528,
            }
        ]
    },
    {
        type:'mastercard',
        displayName:'Mastercard',
        image:mastercardImage,
        checks: [
            {
                upperBound: 2720,
                lowerBound: 2221,
            },
            {
                upperBound: 5599,
                lowerBound: 5100,
            }
        ]
    },
    {
        type:'maestro',
        displayName:'Maestro',
        image:maestroImage,
        checks: [
            {
                upperBound:6759,
                lowerBound:6759,
            },
            {
                upperBound:6767,
                lowerBound:6767,
            },
            {
                upperBound:5018,
                lowerBound:5018,
            },
            {
                upperBound:5020,
                lowerBound:5020,
            },
            {
                upperBound:5038,
                lowerBound:5038,
            },
            {
                upperBound:5893,
                lowerBound:5893,
            },
            {
                upperBound:6304,
                lowerBound:6304,
            },
            {
                upperBound:6763,
                lowerBound:6761,
            }
        ]
    }
];
