import React, { useEffect } from 'react';
import {defineMessages} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import { Box, Typography, CircularProgress } from '@mui/material';
import ContentHeader from '../../components/ContentHeader';
import routes from '../../util/routes';
import {FormattedMessage} from 'react-intl';
import { contentPadding, osColour, AddButton} from 'omse-components';
import FeatureCheck from '../../components/FeatureCheck';
import * as features from '../../../shared/features';
import useHeading from '../../hooks/useHeading';
import CatalogueTag from '../../components/CatalogueTag';
import { COM3_CATALOGUE } from "../../../shared/catalogues";
import ApiPriceTable from "./ApiPriceTable/ApiPriceTable";
import { useDispatch, useSelector } from "react-redux";
import { getApiProducts, getApiTiers } from "../../modules/commercialApiPlans/actions";

const messages = defineMessages({
    backLabel: {
        id: 'pricingApiPlans.backLabel',
        defaultMessage: 'API Dashboard',
        description: 'back route'
    },
    title: {
        id:'pricingApiPlans.title',
        defaultMessage:'My API Plan',
        description: 'Title of the API Plan page.'
    },
    subtitle: {
        id:'pricingApiPlans.subtitle',
        defaultMessage: 'Purchase an API Plan',
        description: 'Subtitle of the API page.'
    },
    introHeading: {
        id:'pricingApiPlans.introHeading',
        defaultMessage: 'API Pricing',
        description: 'Heading to the API Pricing'
    },
    introBody: {
        id:'pricingApiPlans.introBody',
        defaultMessage: 'Our APIs allow secure access to our backend services through a well defined RESTful service. Standalone applications can consume our data through a HTTP endpoint.',
        description: 'Overview text'
    },
    osPlacesWarning: {
        id: "pricingApiPlans.osPlacesWarning",
        defaultMessage: "Contact us after adding the OS Places API to a project on your account. Get in touch to discuss your planned use of OS Places API, we will get you set up and ready for access"
    }
})

const useStyles = createUseStyles(theme => ({
    content: {
        paddingBottom: contentPadding.bottom,
        paddingLeft: contentPadding.left,
        paddingRight: contentPadding.right,
        maxWidth: contentPadding.maxWidth,
        marginTop: contentPadding.top,
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile,
            paddingTop: 0,
            marginTop: 0
        }
    },
    catalogueTag:{
        position: "absolute",
        right: theme.spacing(1),
        top:theme.spacing(1)
    },
    pricingContainer: {
        position: 'relative',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]:{
            marginTop:theme.spacing(5)
        }
    },
    addPlanButton:{
        position:'absolute',
        right:0,
        bottom:'100%',
        margin:theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            float:'right'
        }
    },
    osPlacesWarning:{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    bulkPricingContainer: {
        marginTop: theme.spacing(2)
    },
    bulkPricingTable: {
        display:"flex",
        alignItems:"stretch",
        marginTop:theme.spacing(3)
    },
    bulkTier:{
        position:"relative",
        padding:theme.spacing(1),
        borderLeft:`1px solid ${osColour.neutral.stone}`,
        "&:last-of-type":{
            borderRight:`1px solid ${osColour.neutral.stone}`
        }
    },
    discountTier:{
        position:"absolute",
        left:-1,
        width:"100%",
        bottom:"100%",
        textAlign:"center",
        backgroundColor:osColour.neutral.mist,
        border:`1px solid ${osColour.neutral.stone}`,
        color: osColour.primary.berry
    }
}));

export default function ApiPlansPricing(props) {
    const classes = useStyles();
    const history = useHistory();
    const title = useHeading() || messages.title;
    const products = useSelector(state => state.commercialApiPlans.products);
    const tiers = useSelector(state => state.commercialApiPlans.tiers);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getApiProducts());
        dispatch(getApiTiers());
    }, [dispatch])

    const goToAddApiPlan = ()=>{
        history.push(routes.addApiPlan);
    }

    const demoProduct = products.result?.find(product => product.demoProduct);

    return (<FeatureCheck feature={features.EAIAPI}>
        <Box>
            <ContentHeader title={title} backPath={routes.dashboard} backLabel={messages.backLabel} subtitle={messages.subtitle}>
                <Box className={classes.catalogueTag}>
                    <CatalogueTag type={COM3_CATALOGUE} size='md' />
                </Box>
            </ContentHeader>
            <Box className={classes.content}>
                <Box className={classes.pricingContainer}>
                    <Typography variant="h2">
                        <FormattedMessage {...messages.introHeading}/>
                    </Typography>
                    <Typography variant="body1">
                        <FormattedMessage {...messages.introBody}/>
                    </Typography>
                    <AddButton
                        action={goToAddApiPlan}
                        label="Purchase API Plan"
                        variant="contained"
                        ariaLabel="purchase api plan button"
                        color="primary"
                        className={classes.addPlanButton}
                    />
                    {
                        products.loading || !products.result ?
                            <CircularProgress/> :
                            <ApiPriceTable showExtraInfo={true} products={products.result} tiers={tiers.result}/>
                    }
                </Box>
                <Box className={classes.osPlacesWarning}>
                    <Typography variant="body2">
                        <FormattedMessage {...messages.osPlacesWarning}/>
                    </Typography>
                </Box>
                <Box className={classes.bulkPricingContainer}>
                    <Typography variant="h2">API Bulk Purchase</Typography>
                    {demoProduct && tiers.result ?
                        <Box className={classes.bulkPricingTable} data-testid="bulk-pricing-container">
                            <Box>
                                <Typography variant="body1">Transactions / Operations</Typography>
                            </Box>
                            <Box className={classes.bulkTier}>
                                <Typography variant="body1">FREE</Typography>
                                <Typography variant="body1">Open OS data</Typography>
                                <Typography variant="body2">FREE per transaction</Typography>
                                <Typography variant="body2">Starting from 0 transactions</Typography>
                            </Box>
                            {
                                demoProduct.tiers.map(demoProductTier =>(
                                    tiers.result.find(tier => demoProductTier.tierId === tier.id).upperValue ?
                                    <Box className={classes.bulkTier}>
                                        {demoProductTier.discount ? <Typography variant="body2" className={classes.discountTier}>{demoProductTier.discountPercent}% discount</Typography>: null}
                                        <Typography variant="h4">{demoProductTier.name}</Typography>
                                        <Typography variant="body1">£{demoProductTier.unitPrice} per transaction</Typography>
                                        <Typography variant="body2">Up to {Math.floor((tiers.result.find(tier=>tier.id===demoProductTier.tierId).upperValue) / demoProductTier.unitPrice).toLocaleString()} transactions in a single purchase</Typography>
                                        <Typography variant="caption">*based on {demoProduct.name}</Typography>
                                    </Box>
                                    : null
                                ))
                            }

                        </Box> : null
                    }
                </Box>
            </Box>
        </Box>
    </FeatureCheck>)
}
