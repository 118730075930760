import React from 'react';
import withStyles from 'react-jss';
import {contentPadding} from 'omse-components';

function styles(theme) {
    return {
        content: {
            flex: '1 1 auto',
            paddingTop: contentPadding.top,
            paddingLeft: contentPadding.left,
            paddingRight: contentPadding.right,
            paddingBottom: contentPadding.bottom,
            maxWidth: contentPadding.maxWidth,
            '& img': {
                maxWidth: '95%'
            },
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile
            },
            '& h1, h2, h3': {
                marginBottom: theme.spacing(2)
            },
            '& pre': {
                overflowX: 'auto'
            }
        }
    };
}

function Content(props) {
    const {classes, children} = props;

    return <div className={classes.content}>
        { children }
    </div>;
}

export default withStyles(styles)(Content);
