//
// Simple parser for filter expressions. This knows about groups (wrapped with brackets), strings delimited with
// single quotes (and including an escape for embedded quotes), numbers, and that's about it.
//
// As we work through the expression we identify tokens, which are passed to the callback. We end up with a sequence
// of calls to the callback function, passing in each token as we find it. It's likely that the callback function will
// assemble the tokens into a tree structure, paying particular attention to the start and end of groups.
//

export const START_GROUP = 'START_GROUP'; // Marker for the start of a group
export const END_GROUP = 'END_GROUP';     // Marker for the end of a group
export const LITERAL = 'LITERAL';         // Marker for a literal value
export const COMBINER = 'COMBINER';       // Marker for an AND or OR
export const TOKEN = 'TOKEN';             // Marker for any other word or character. Could be a field name, an operator, etc.

export default function expressionParser(expression, callback) {

    // As we emit events, we sometimes need to look back. An example is just after an 'IN' or 'BETWEEN'. This
    // helper allows us to look back at the last two events to handle content slightly differently.
    // We need to be careful never to emit an event without using the emitEvent helper - we should not be calling
    // into 'callback' directly, as that would bypass the history handling. To guarantee that, we pass the helper
    // over to the actual parsing function, not the raw callback.
    let history = new Array(2);

    function emitEvent(event) {
        history.shift();
        history.push(event);
        callback(event);
    }

    function checkHistory(countBack, expectedType, valueMatcher) {
        const candidate = history[history.length - countBack];
        const correctType = candidate && candidate.type === expectedType;
        if (valueMatcher) {
            return correctType && valueMatcher.test(candidate.value);
        }
        return correctType;
    }

    return parseExpression(expression, emitEvent, checkHistory);
}

function parseExpression(expression, emitEvent, checkHistory) {

    // As we parse we chop the input expression up into tokens. This variable holds the text for the current token.
    let currentToken = '';

    // If we are inside a string literal (surrounded by single quotes) then the token needs to contain data up to
    // and including the closing quote. This flag helps us keep track of that.
    let insideString = false;

    // If we are parsing a IN(...) list then we don't want the '(' and ')' characters to be treated as the start and
    // end of a group. This flag helps us keep track of that
    let insideIn = false;

    for(let i = 0; i < expression.length; i++) {
        const char = expression.charAt(i);

        if(insideString) {
            currentToken += char;
            if (char === "'") {
                // We either end the string, or we have an escaped single quote. If it's the latter then eat up the
                // escape and keep going
                if (expression.charAt(i + 1) === "'") {
                    i++;
                } else {
                    endCurrentToken();
                }
            }
        } else if(char === "'") {
            endCurrentToken();
            insideString = true;
            currentToken += char;
        } else if(char === '(') {
            endCurrentToken();
            // If we have read an IN token, then we are looking at a "name IN(value, ...)" expression, and we don't
            // want to treat this '(' as the start of a group.
            if(checkHistory(1, TOKEN, /^IN$/i)) {
                insideIn = true;
                emitEvent({ type: TOKEN, value: '(' });
            } else {
                emitEvent({ type: START_GROUP });
            }
        } else if(char === ')') {
            endCurrentToken();
            if(insideIn) {
                insideIn = false;
                emitEvent({ type: TOKEN, value: ')' });
            } else {
                emitEvent({ type: END_GROUP });
            }
        } else if(/\s/.test(char)) {
            endCurrentToken();
        } else {
            currentToken += char;
        }
    }

    // Once we get to here we have looked at every character of the input. We probably still have data in the
    // currentToken variable, so we need to emit that token too.
    endCurrentToken();

    // Helper function that closes off the current token. This gives us a chance to emit specialised tokens like
    // LITERALS or COMBINERS based on what we found. If we have nothing more special then we'll just emit a TOKEN.
    function endCurrentToken() {
        // Quoted strings are always treated as literals, which covers strings and dates. Values that are all numeric
        // plus '.' could be numbers and cannot be keywords or operators... so we treat those as literals too
        if(currentToken.length) {
            const newToken = {
                type: TOKEN,
                value: currentToken
            };

            if(insideString) {
                // Quoted strings are always treated as literals, which covers strings and dates.
                newToken.type = LITERAL;
                insideString = false;

            } else if(/^[0-9\\.]+$/.test(currentToken)) {
                // Values that are all numeric plus '.' could be numbers and cannot be keywords or operators... so we treat those as literals
                newToken.type = LITERAL;

            } else if(/^NULL$/i.test(currentToken)) {
                // We treat nulls as a literal too
                newToken.type = LITERAL;

            } else if(/^AND$/i.test(currentToken)) {
                // If we just had "BETWEEN <literal>" before this 'AND' then we should not treat it as a group combiner
                if(checkHistory(2, TOKEN, /^BETWEEN$/i) && checkHistory(1, LITERAL)) {
                    // Carry on treating this AND as a normal token
                } else {
                    newToken.type = COMBINER;
                }
            } else if(/^OR$/i.test(currentToken)) {
                // All of the 'OR' tokens that we find can be treated as COMBINERS
                newToken.type = COMBINER;
            }

            emitEvent(newToken);

            // Prepare to start reading the next token
            currentToken = '';
        }
    }

}