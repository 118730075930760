import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from "react-intl";
import Typography from "@mui/material/Typography";
import withStyles from 'react-jss';
import {border1, contentPadding, Notification, osColour} from 'omse-components';
import routePaths, {PSGA_REGISTRATION_URL} from '../../util/routes';
import PlanTag from "../../components/PlanTag";
import {
    CRM_PENDING_PSGA_PLAN,
    USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN,
    USER_OPEN_DATA_PLAN,
    USER_OS_INTERNAL_PLAN,
    USER_PREMIUM_DATA_PLAN,
    USER_PSGA_PLAN
} from '../../../shared/plans';
import Link from '@mui/material/Link';
import LinkA from '../../components/Link';
import {useSelector} from 'react-redux';
import {isOpenDataPersonal, withOrganisationMessage} from '../../util/organisation';

const messages = defineMessages({
    title: {
        id: 'DashboardHeader.title',
        defaultMessage: 'Dashboard',
        description: 'Title shown in the dashboard'
    },
    orgTitle: {
        id: 'DashboardHeader.orgTitle',
        defaultMessage: '{org} dashboard',
        description: 'Title when an org is selected'
    },
    personalTitle: {
        id: 'DashboardHeader.personalTitle',
        defaultMessage: 'My Personal dashboard',
        description: 'Title when selected org is type personal'
    },
    notLoggedInText: {
        id: 'DashboardHeader.notLoggedIn.text',
        defaultMessage: 'Welcome to the OS Data Hub.',
        description: 'Text shown in the dashboard when the user is not logged in'
    },
    newLoggedInOpen: {
        id: 'DashboardHeader.newLoggedInOpen.text',
        defaultMessage: 'Your account has been created. You now have access to unlimited OS OpenData.',
        description: 'Text shown in the dashboard when a new OS OpenData user is first logged in'
    },
    newLoggedInPremium: {
        id: 'DashboardHeader.newLoggedInPremium.text',
        defaultMessage: 'Your account has been created. You now have access to unlimited OS OpenData and £1000 of free Premium data.',
        description: 'Text shown in the dashboard when a new Premium Data user is first logged in'
    },
    loggedInText: {
        id: 'DashboardHeader.loggedIn.text',
        defaultMessage: 'Please use the secondary navigation menu to setup API access.',
        description: 'Text shown in the dashboard when a returning user is logged in'
    },
    loadingTitle: {
        id: 'DashboardHeader.loading.title',
        defaultMessage: 'Loading...',
        description: 'Title shown in the dashboard while the user info is still loading'
    },
    loadingText: {
        id: 'DashboardHeader.loading.text',
        defaultMessage: 'Please wait while we load your user details.',
        description: 'Text shown in the dashboard while the user info is still loading'
    },
    freeOpenData: {
        id: 'DashboardHeader.freeOpenData',
        defaultMessage: 'Free OS OpenData plan',
        description: 'Text shown on the dashboard for open data users'
    },
    premiumData: {
        id: 'DashboardHeader.premiumData',
        defaultMessage: 'Premium Data plan',
        description: 'Text shown on the dashboard for premium data users'
    },
    psga: {
        id: 'DashboardHeader.psga',
        defaultMessage: 'Public Sector plan',
        description: 'Text shown on the dashboard for PSGA users'
    },
    applyPsgaMembership: {
        id: 'DashboardHeader.applyPsgaMembership',
        defaultMessage: 'Apply for a PSGA membership in order to access free mapping data for Public Sector Organisations',
        description: 'apply for psga membership text'
    },
    psgaOrganisations: {
        id: 'DashboardHeader.psgaOrganisations',
        defaultMessage: 'Public Sector Organisations that have been accepted for PSGA membership can access a wealth of free data from Ordnance Survey.',
        description: 'public sector org text'
    },
    noPaymentDetailsTitle: {
        id: 'DashboardHeader.noPaymentDetailsTitle',
        defaultMessage: 'Enter your payment details to ensure your service is never interrupted.',
        description: 'preset no payment details title'
    },
    noPaymentDetailsContent: {
        id: 'DashboardHeader.noPaymentDetailsContent',
        defaultMessage: '<a>Provide your payment details</a> for unlimited Premium data.',
        description: 'preset no payment details content',        
    }    
});
const styles = theme => ({
    header: {
        paddingLeft: contentPadding.left,
        paddingTop: contentPadding.top,
        paddingBottom: `calc(${contentPadding.bottom}px - ${theme.spacing(2)})`,
        paddingRight: contentPadding.right,
        textAlign: 'left',
        borderBottom: border1,
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile,
            flexDirection: 'column'
        }
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    titleDesc: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2)        
    },
    rightPanel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    banner: {
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginLeft: theme.spacing(4)
    },    
    infoNotice: {
        marginTop: theme.spacing(1.5),
        color: osColour.neutral.charcoal,
        maxWidth: 1000
    }
});

export function DashboardHeader(props) {
    const {classes} = props;
    const userDetails = useSelector(state => state.user.current.result);
    const userLoading = useSelector(state => state.user.current.loading);
    const configLoading = useSelector(state => state.config.current.loading);
    const org = useSelector(state => state.organisation.current);
    const loading = userLoading || configLoading;
    
    const appNotices = useSelector(state => state.notifications.status.result && state.notifications.status.result.app) || [];
    const missingPaymentDetailsNotice = appNotices.find(notice => notice.id === 'PaymentIssue.CardRequired');
    const showMissingPaymentDetailsNotice = missingPaymentDetailsNotice && !isOpenDataPersonal(userDetails, org);

    const generatePlanTag = () => {
        const openDataPlan = USER_OPEN_DATA_PLAN;
        let plan = openDataPlan;
        if ([USER_PREMIUM_DATA_PLAN, USER_PSGA_PLAN, USER_OS_INTERNAL_PLAN, USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN].includes(userDetails.plan)) {
            plan = userDetails.plan;
        }

        if (isOpenDataPersonal(userDetails, org)) {
            plan = openDataPlan;
        }
        planTag = <PlanTag type={plan} size='md'/>;
    }

    const psgaBanner = () => {
        return (
            <Notification classes={{root: classes.banner}} variant='info' appearance='inline' children={
                <div id='psgaBanner'>
                    <Link target="_blank" rel="noopener noreferrer" href={PSGA_REGISTRATION_URL} underline='always'>
                        <Typography variant='body1'>
                            <FormattedMessage {...messages.applyPsgaMembership} />
                        </Typography>
                    </Link>
                    <Typography variant='body1'>
                        <FormattedMessage {...messages.psgaOrganisations}/>
                    </Typography>
                </div>
            }/>
        )
    };

    let showPsgaBanner = userDetails && userDetails.orgPlan === CRM_PENDING_PSGA_PLAN;

    const titleMessage = withOrganisationMessage(messages.title, messages.orgTitle, messages.personalTitle, userDetails, org);
    let title = <FormattedMessage {...titleMessage}/>;
    let text;
    if (loading) {
        title = <FormattedMessage {...messages.loadingTitle}/>;
        text = <FormattedMessage {...messages.loadingText}/>
    } else if(!userDetails) {
        text = <FormattedMessage {...messages.notLoggedInText}/>
    } else if(!userDetails.active) {
        // Don't set up any text, as the notification is now lower down, in the content section
    } else if(userDetails.newUser && userDetails.plan === USER_OPEN_DATA_PLAN) {
        text = <FormattedMessage {...messages.newLoggedInOpen}/>;
    } else if(userDetails.newUser && userDetails.plan === USER_PREMIUM_DATA_PLAN) {
        text = <FormattedMessage {...messages.newLoggedInPremium}/>;
    } else {
        text = <FormattedMessage {...messages.loggedInText}/>
    }
    let planTag;

    if(userDetails && userDetails.active) {
        generatePlanTag();
    }
    return (
        <header>
            {showPsgaBanner && psgaBanner()}
            <div className={classes.header}>
                <div className={classes.title}>
                    <div className={classes.titleDesc}>
                        <Typography variant='h1'
                                    color='primary'
                                    style={{marginBottom: 12}}>
                            {title}
                        </Typography>
                        <Typography variant='body1'>
                            {text}
                        </Typography>
                    </div>
                    <div className={classes.rightPanel}>
                        {planTag}
                    </div>
                </div>
                {showMissingPaymentDetailsNotice && 
                    <Notification appearance='inline' variant={missingPaymentDetailsNotice.level} classes={{root: classes.infoNotice}}>
                        <Typography variant='body2'>
                            <FormattedMessage {...messages.noPaymentDetailsTitle} />
                        </Typography>
                        <Typography variant='body1'>
                            <FormattedMessage {...messages.noPaymentDetailsContent}
                                values={{a: chunks => (<LinkA path={routePaths.billing}>{chunks}</LinkA>)}} />
                        </Typography>
                    </Notification>
                }
            </div>
        </header>
    );
}

DashboardHeader.propTypes = {
    classes: PropTypes.object
};

export default withStyles(styles)(DashboardHeader);
