import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Typography} from '@mui/material';
import {ReactComponent as DownloadIcon} from "../../../components/icons/download.svg";
import {createUseStyles} from 'react-jss';

const messages = defineMessages({
    individualDownloads: {
        id: 'DataPackage.individualDownloads',
        defaultMessage: 'Individual file downloads',
        description: 'Label for the section of the page that shows individual files.'
    },
});

const myStyles = createUseStyles(theme => ({
    padRight: {
        marginRight: theme.spacing(1)
    },
    downloadLink: {
        display: 'inline-flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    heading: {
        marginBottom: theme.spacing(2)
    }
}));

export default function IndividualDownloads({packageId, version, links}) {
    const classes = myStyles();

    function buildHref(link) {
        const urlPrefix = '/api/dataPackages/' + packageId + '/' + version.id + '/download?';
        const urlParams = {
            fileName: link.fileName
        };
        return urlPrefix + queryString.stringify(urlParams);
    }

    return <Fragment>
        <Typography variant='h3' className={classes.heading}>
            <FormattedMessage {...messages.individualDownloads}/>
        </Typography>

        {
            links.map((link, index) => <div key={'link' + index}>
                <a href={buildHref(link)}
                   download
                   className={classes.downloadLink}>
                    <DownloadIcon width={24} height={24} className={classes.padRight}/>
                    <Typography variant='body1'>
                        { link.fileName }
                    </Typography>
                </a>
            </div>)
        }
    </Fragment>
}

IndividualDownloads.propTypes = {
    packageId: PropTypes.string.isRequired,
    version: PropTypes.object,
    links: PropTypes.arrayOf(PropTypes.shape({
        fileName: PropTypes.string
    })).isRequired
};