import React, {Component} from 'react';
import Typography from '@mui/material/Typography';
import Header from "./Header";
import Content from './Content';
import routes from '../../util/routes';
import DocLinks from "./DocLinks";
import ArcGISOnline from './wfsIntro/ArcGISOnline';
import ArcGISPro from './wfsIntro/ArcGISPro';
import ArcMap from './wfsIntro/ArcMap';
import CadcorpSIS from "./wfsIntro/CadcorpSIS";
import MapInfoSIS from "./wfsIntro/MapInfoSIS";
import GettingStartedGuides from './GettingStartedGuides';
import IntegrateIntro from './IntegrateIntro';
import PropTypes from 'prop-types';
import Document from './Document';
import WFSQGIS from "./wfsIntro/WFSQGIS";
import { InternalLink, ExternalLink, isWidthDown, withWidth } from 'omse-components';

export class WFSIntro extends Component {

    guides = [
        {label: 'QGIS', content: <WFSQGIS/>},
        {label: 'ArcGIS Online', content: <ArcGISOnline />},
        {label: 'ArcGIS Pro', content: <ArcGISPro />},
        {label: 'ArcMap', content: <ArcMap />},
        {label: 'Cadcorp SIS', content: <CadcorpSIS />},
        {label: 'MapInfo Pro', content: <MapInfoSIS />}
    ];

    render() {
        const {width} = this.props;
        const mobile = isWidthDown('sm', width)
        return <Document>
            <Header back={true}>OS Features API: Getting started guide</Header>
            <Content>

                <Typography variant='body1' paragraph={true}>
                    This guide explains how to generate an API key to access the OS Features API, and then how to use
                    that API key within GIS software applications. If you are not familiar with the OS Features API you may want to read the <InternalLink
                    path={routes.wfsOverview} message="overview documentation" /> before proceeding.
                </Typography>

                <Typography variant='h1' component='h2'>Instructions to Generate a Project API Key and URL</Typography>
                <Typography variant='body1' paragraph={true} component='div'>
                    <p>The following instructions describe how to obtain the API service URL and to generate an API
                        key:</p>
                    <ol>
                        <li>Click this link to open the <ExternalLink
                            type="generic"
                            href="/"
                            message="OS Data Hub"
                        /> in a new tab.
                        </li>
                        {mobile &&
                        <li>Click the navigation menu button.</li>
                        }
                        <li>Click "API Dashboard" in the {!mobile && 'navigation'} menu.</li>
                        <li>Click "APIs" in the {!mobile && 'secondary navigation'} menu.</li>
                        <li>Click "Add to API project" next to the OS Features API.</li>
                        <li>If you already have a project you may choose to add the OS Features API into that project,
                            or
                            alternatively Select "Add to NEW PROJECT".
                            <p>The next screen will contain the Project API Key and the API Endpoint address (API
                                URL).</p>
                            <p>You can return to this screen by clicking "My projects" at any point in the future if you
                                need to copy your API key or the API address, or if you need to regenerate your API
                                Key.</p>
                        </li>
                        <li>Keep this page open as you'll need the URL and key when you apply the OS Features API
                            service in your GIS software application.
                        </li>
                    </ol>
                </Typography>

                <IntegrateIntro api='OS Features API' />
                <GettingStartedGuides guides={this.guides} />

                <DocLinks product='wfs' hasTechnicalSpec={true} hasDemo={true}/>
            </Content>
        </Document>
    }
}

WFSIntro.propTypes = {
    width: PropTypes.string
}

export default withWidth()(WFSIntro);
