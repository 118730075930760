import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '../Table';
import { ExternalLink } from "omse-components";
import InlineCodeSnippet from '../../../components/InlineCodeSnippet';

const outputHeaders = ['Key', 'Description', 'Format', 'Size', 'Multiplicity']
const dpaOutput = [
    [<InlineCodeSnippet>UPRN</InlineCodeSnippet>, 'The Unique Property Reference Number. For addresses in Northern Ireland, an empty string is returned.', 'integer', '12', '1'],
    [<InlineCodeSnippet>UDPRN</InlineCodeSnippet>, 'Royal Mail\'s Unique Delivery Point Reference Number.', 'integer', '8', '1'],
    [<InlineCodeSnippet>ADDRESS</InlineCodeSnippet>, 'String containing the concatenated address.', 'char', '472', '1'],
    [<InlineCodeSnippet>PO_BOX_NUMBER</InlineCodeSnippet>, 'Post Office Box (PO Box®) number.', 'char', '6', '0..1'],
    [<InlineCodeSnippet>ORGANISATION_NAME</InlineCodeSnippet>, 'The organisation name is the business name given to a delivery point within a building or small group of buildings. This field could also include entries for churches, public houses and libraries.', 'char', '60', '0..1'],
    [<InlineCodeSnippet>DEPARTMENT_NAME</InlineCodeSnippet>, 'In a few organisations, department name is indicated because mail is received by subdivisions of the main organisation at distinct delivery points.', 'char', '60', '0..1'],
    [<InlineCodeSnippet>SUB_BUILDING_NAME</InlineCodeSnippet>, 'The sub-building name and/or number are identifiers for subdivision of properties.', 'char', '30', '0..1'],
    [<InlineCodeSnippet>BUILDING_NAME</InlineCodeSnippet>, 'The building name is a description applied to a single building or a small group of buildings.', 'char', '50', '0..1'],
    [<InlineCodeSnippet>BUILDING_NUMBER</InlineCodeSnippet>, 'The building number, or postal number, is a number given to a single building or a small group of buildings.', 'integer', '4', '0..1'],
    [<InlineCodeSnippet>DEPENDENT_THOROUGHFARE_NAME</InlineCodeSnippet>, 'In certain places, for example, town centres, there are named thoroughfares within other named thoroughfares.', 'char', '80', '0..1'],
    [<InlineCodeSnippet>THOROUGHFARE_NAME</InlineCodeSnippet>, 'A road, track or named access route on which there are Royal Mail delivery points.', 'char', '80', '0..1'],
    [<InlineCodeSnippet>DOUBLE_DEPENDENT_LOCALITY</InlineCodeSnippet>, 'To distinguish between similar or same thoroughfares within a dependent locality.', 'char', '35', '0..1'],
    [<InlineCodeSnippet>DEPENDENT_LOCALITY</InlineCodeSnippet>, 'Dependent locality areas may define an area within a post town.', 'char', '35', '0..1'],
    [<InlineCodeSnippet>POST_TOWN</InlineCodeSnippet>, 'The town or city in which the Royal Mail sorting office is located.', 'char', '30', '1'],
    [<InlineCodeSnippet>POSTCODE</InlineCodeSnippet>, 'A postcode is an abbreviated form of address made up of combinations of between five and seven alphanumeric characters.', 'char', '8', '1'],
    [<InlineCodeSnippet>RPC</InlineCodeSnippet>, 'Representative Point Code: this describes the accuracy of the coordinate that has been allocated to the BLPU. See code list: RPC_CODE in AddressBase Premium Technical Specification.', 'char', '1', '1'],
    [<InlineCodeSnippet>X_COORDINATE</InlineCodeSnippet>, 'Easting coordinates in metres, defining the location in the British National Grid spatial reference system.', 'float', '6 (2)', '1'],
    [<InlineCodeSnippet>Y_COORDINATE</InlineCodeSnippet>, 'Northing coordinates in metres, defining the location in the British National Grid spatial reference system.', 'float', '7 (2)', '1'],
    [<InlineCodeSnippet>LNG</InlineCodeSnippet>, 'Longitude coordinate, defined based on the requested projection.', 'float', '9', '0..1'],
    [<InlineCodeSnippet>LAT</InlineCodeSnippet>, 'Latitude coordinate, defined based on the requested projection.', 'float', '9', '0..1'],
    [<InlineCodeSnippet>STATUS</InlineCodeSnippet>, <>The status of the feature (i.e. <InlineCodeSnippet>APPROVED</InlineCodeSnippet>, <InlineCodeSnippet>ALTERNATIVE</InlineCodeSnippet>, <InlineCodeSnippet>PROVISIONAL</InlineCodeSnippet>, <InlineCodeSnippet>HISTORICAL</InlineCodeSnippet>). The AddressBase Premium product uses codes of 1,3,6,8 for these however the Address API will provide the text interpretation above.</>, 'char', '11', '1'],
    [<><InlineCodeSnippet>MATCH</InlineCodeSnippet> (Conditional) - Only returned for match resource</>, <>The match score for the query against the returned address from <InlineCodeSnippet>0</InlineCodeSnippet> to <InlineCodeSnippet>1</InlineCodeSnippet>. Resources other than Match will return a fixed value of <InlineCodeSnippet>1.0</InlineCodeSnippet>.</>, 'float', '1 (1)', '0..1'],
    [<><InlineCodeSnippet>MATCH_DESCRIPTION</InlineCodeSnippet> (Conditional) - Only returned for match resource</>, <>The description of the match. Exact: <InlineCodeSnippet>1.0</InlineCodeSnippet> (note: extra information in the input address that doesn’t match the ABPREM version is ignored). Good: <InlineCodeSnippet>0.8</InlineCodeSnippet> – <InlineCodeSnippet>0.9</InlineCodeSnippet> (most of the address matches, but a part of it may be different, such as a different postcode). Partial: <InlineCodeSnippet>0.7</InlineCodeSnippet> (address could represent the same address but has some significant differences). No Match: {'<'} <InlineCodeSnippet>0.7</InlineCodeSnippet>. Resources other than Match will return a fixed value of <InlineCodeSnippet>Exact</InlineCodeSnippet>.</>, 'char', '8', '0..1'],
    [<InlineCodeSnippet>LANGUAGE</InlineCodeSnippet>, 'A code identifying the language in use for the record.', 'char', '2', '1'],
    [<InlineCodeSnippet>COUNTRY_CODE</InlineCodeSnippet>, 'A code identifying the country of the record.', 'char', '1', '1'],
    [<InlineCodeSnippet>COUNTRY_CODE_DESCRIPTION</InlineCodeSnippet>, 'A description that describes the country of the record.', 'char', '100', '1'],
    [<InlineCodeSnippet>LOCAL_CUSTODIAN_CODE</InlineCodeSnippet>, <>Unique identifier of the Local Land and Property Gazetteer (LLPG) Custodian responsible for the maintenance of this record.
        <ExternalLink type="download" href='https://www.ordnancesurvey.co.uk/documents/product-support/support/addressbase-local-custodian-codes.zip' message="Download the Local Custodian code list (ZIP)." /></>, 'integer', '4', '1'],
    [<InlineCodeSnippet>LOCAL_CUSTODIAN_CODE_DESCRIPTION</InlineCodeSnippet>, 'Description of the Local Land and Property Gazetteer (LLPG) Custodian responsible for the maintenance of this record.', 'char', 'as per code definition document', '1'],
    [<InlineCodeSnippet>CLASSIFICATION_CODE</InlineCodeSnippet>, <>A description that describes the classification of the record, for example, <InlineCodeSnippet>CE01HE</InlineCodeSnippet> is a higher education establishment.</>, 'char', '1, 2, 4, 6', '1'],
    [<InlineCodeSnippet>CLASSIFICATION_CODE_DESCRIPTION</InlineCodeSnippet>, <>A description that describes the classification of the record, for example, <InlineCodeSnippet>CE01HE</InlineCodeSnippet> is a higher education establishment.</>, 'char', 'as per code definition document', '1'],
    [<InlineCodeSnippet>POSTAL_ADDRESS_CODE</InlineCodeSnippet>, 'A code that describes the type of postal delivery that the object is subject to.', 'char', '1', '1'],
    [<InlineCodeSnippet>POSTAL_ADDRESS_CODE_DESCRIPTION</InlineCodeSnippet>, 'A description that describes the type of postal delivery that the object is subject to.', 'char', '100', '1'],
    [<InlineCodeSnippet>LOGICAL_STATUS_CODE</InlineCodeSnippet>, <>A code for the logical status of the BLPU. <ExternalLink type="generic" href='https://www.ordnancesurvey.co.uk/documents/product-support/tech-spec/addressbase-premium-technical-specification.pdf' message="See the Logical Status code list (PDF) here." /></>, 'integer', '1', '1'],
    [<InlineCodeSnippet>BLPU_STATE_CODE</InlineCodeSnippet>, <>A code for the physical nature of the property or land object. These are used to represent the physical state or the feature. <ExternalLink type="generic" href='https://www.ordnancesurvey.co.uk/documents/product-support/tech-spec/addressbase-premium-technical-specification.pdf' message="See the BLPU State code list (PDF) here." /></>, 'integer', '1', '1'],
    [<InlineCodeSnippet>BLPU_STATE_CODE_DESCRIPTION</InlineCodeSnippet>, 'A description for the physical nature of the property or land object. These are used to represent the physical state or the feature.', 'char', 'as per code definition document', '0..1'],
    [<InlineCodeSnippet>TOPOGRAPHY_LAYER_TOID</InlineCodeSnippet>, 'Unique key for the application cross reference record.', 'char', '20', '1'],
    [<InlineCodeSnippet>PARENT_UPRN</InlineCodeSnippet>, 'UPRN of the parent record.', 'integer', '12', '0..1'],
    [<InlineCodeSnippet>LAST_UPDATE_DATE</InlineCodeSnippet>, 'Date this record was last updated.', 'date', 'date', '1'],
    [<InlineCodeSnippet>ENTRY_DATE</InlineCodeSnippet>, 'Date of the data entry.', 'date', 'date', '1'],
    [<InlineCodeSnippet>LEGAL_NAME</InlineCodeSnippet>, 'Registered legal name of organisation.', 'char', '60', '0..1'],
    [<InlineCodeSnippet>BLPU_STATE_DATE</InlineCodeSnippet>, 'Date at which the BLPU achieved its current state in the real-world.', 'date', 'date', '0..1'],
    [<InlineCodeSnippet>DELIVERY_POINT_SUFFIX</InlineCodeSnippet>, 'A code to uniquely identify an individual delivery point within a postcode.', 'char', '2', '1'],
    [<InlineCodeSnippet>PARISH_CODE</InlineCodeSnippet>, 'Office for National Statistics (ONS) Parish code.', 'char', '9', '1'],
    [<InlineCodeSnippet>WARD_CODE</InlineCodeSnippet>, 'Office for National Statistics (ONS) Ward code.', 'char', '9', '1']
]
const lpiOutput = [
    [<InlineCodeSnippet>UPRN</InlineCodeSnippet>, 'Foreign key used to reference the delivery point address to the corresponding BLPU.', 'integer', '12', '1'],
    [<InlineCodeSnippet>ADDRESS</InlineCodeSnippet>, 'Concatenated Address field.', 'char', '504', '1'],
    [<InlineCodeSnippet>LANGUAGE</InlineCodeSnippet>, 'A code identifying the language in use for the record.', 'char', '2', '1'],
    [<InlineCodeSnippet>USRN</InlineCodeSnippet>, 'Foreign key linking the Street record to the LPI record.', 'integer', '8', '1'],
    [<InlineCodeSnippet>LPI_KEY</InlineCodeSnippet>, 'Unique key for the LPI.', 'char', '14', '1'],
    [<InlineCodeSnippet>ORGANISATION</InlineCodeSnippet>, 'Name of current occupier on the fascia of the BLPU.', 'char', '100', '1'],
    [<InlineCodeSnippet>SAO_START_NUMBER</InlineCodeSnippet>, 'The number of the secondary addressable object (SAO) or the start of the number range.', 'integer', '4', '0..1'],
    [<InlineCodeSnippet>SAO_START_SUFFIX</InlineCodeSnippet>, <>The suffix to the <InlineCodeSnippet>sao_start_number</InlineCodeSnippet> field.</>, 'char', '2', '0..1'],
    [<InlineCodeSnippet>SAO_END_NUMBER</InlineCodeSnippet>, 'The end of the number range for the SAO.', 'integer', '4', '0..1'],
    [<InlineCodeSnippet>SAO_END_SUFFIX</InlineCodeSnippet>, <>The suffix to the <InlineCodeSnippet>sao_end_number</InlineCodeSnippet> field.</>, 'char', '2', '0..1'],
    [<InlineCodeSnippet>SAO_TEXT</InlineCodeSnippet>, 'Contains the building name or description for the SAO.', 'char', '90', '0..1'],
    [<InlineCodeSnippet>PAO_START_NUMBER</InlineCodeSnippet>, 'The number of the primary addressable object (PAO) or the start of the number range.', 'integer', '4', '0..1'],
    [<InlineCodeSnippet>PAO_START_SUFFIX</InlineCodeSnippet>, <>The suffix to the <InlineCodeSnippet>pao_start_number</InlineCodeSnippet> field.</>, 'char', '2', '0..1'],
    [<InlineCodeSnippet>PAO_END_NUMBER</InlineCodeSnippet>, 'The end of the number range for the PAO.', 'integer', '4', '0..1'],
    [<InlineCodeSnippet>PAO_END_SUFFIX</InlineCodeSnippet>, <>The suffix to the <InlineCodeSnippet>pao_end_number</InlineCodeSnippet> field.</>, 'char', '2', '0..1'],
    [<InlineCodeSnippet>PAO_TEXT</InlineCodeSnippet>, 'Contains the building name or description for the PAO.', 'char', '90', '0..1'],
    [<InlineCodeSnippet>STREET_DESCRIPTION</InlineCodeSnippet>, 'Name, description or street number.', 'char', '100', '0..1'],
    [<InlineCodeSnippet>LOCALITY_NAME</InlineCodeSnippet>, 'A LOCALITY defines an area or geographical identifier within a town, village or hamlet.', 'char', '35', '0..1'],
    [<InlineCodeSnippet>TOWN_NAME</InlineCodeSnippet>, 'Town name.', 'char', '30', '0..1'],
    [<InlineCodeSnippet>ADMINISTRATIVE_AREA</InlineCodeSnippet>, 'Local Highway Authority name.', 'char', '30', '0..1'],
    [<InlineCodeSnippet>AREA_NAME</InlineCodeSnippet>, 'Third level of geographic area name, for example, to record island names or property groups such as crofts.', 'char', '35', '0..1'],
    [<InlineCodeSnippet>POSTCODE_LOCATOR</InlineCodeSnippet>, 'Postcode of the coordinate for the BLPU based purely on a spatial match against Code-Point® with polygons. This field must be used in conjunction with the RPC field to determine the accuracy of its position.', 'char', '8', '1'],
    [<InlineCodeSnippet>RPC</InlineCodeSnippet>, 'Representative Point Code: this describes the accuracy of the coordinate that has been allocated to the BLPU. See code list: RPC_CODE in AddressBase Premium Technical Specification.', 'char', '1', '1'],
    [<InlineCodeSnippet>X_COORDINATE</InlineCodeSnippet>, 'Easting coordinates in metres, defining the location in the British National Grid spatial reference system.', 'float', '6 (2)', '1'],
    [<InlineCodeSnippet>Y_COORDINATE</InlineCodeSnippet>, 'Northing coordinates in metres, defining the location in the British National Grid spatial reference system.', 'float', '7 (2)', '1'],
    [<InlineCodeSnippet>LNG</InlineCodeSnippet>, 'Longitude coordinate, defined based on the requested projection.', 'float', '9', '0..1'],
    [<InlineCodeSnippet>LAT</InlineCodeSnippet>, 'Latitude coordinate, defined based on the request projection.', 'float', '9', '0..1'],
    [<InlineCodeSnippet>STATUS</InlineCodeSnippet>, <>The status of the feature (i.e. <InlineCodeSnippet>APPROVED</InlineCodeSnippet>, <InlineCodeSnippet>ALTERNATIVE</InlineCodeSnippet>, <InlineCodeSnippet>PROVISIONAL</InlineCodeSnippet>, <InlineCodeSnippet>HISTORICAL</InlineCodeSnippet>). The AddressBase Premium product uses codes of 1,3,6,8 for these however the Address API will provide the text interpretation above.</>, 'char', '11', '1'],
    [<><InlineCodeSnippet>MATCH</InlineCodeSnippet> (Conditional) - Only returned for match resource</>, <>The match score for the query against the returned address from <InlineCodeSnippet>0</InlineCodeSnippet> to <InlineCodeSnippet>1</InlineCodeSnippet>. Resources other than Match will return a fixed value of <InlineCodeSnippet>1.0</InlineCodeSnippet>.</>, 'float', '1 (1)', '0..1'],
    [<><InlineCodeSnippet>MATCH_DESCRIPTION</InlineCodeSnippet> (Conditional) - Only returned for match resource</>, <>The description of the match. Exact: <InlineCodeSnippet>1.0</InlineCodeSnippet> (note: extra information in the input address that doesn’t match the ABPREM version is ignored). Good: <InlineCodeSnippet>0.8</InlineCodeSnippet> – <InlineCodeSnippet>0.9</InlineCodeSnippet> (most of the address matches, but a part of it may be different, such as a different postcode). Partial: <InlineCodeSnippet>0.7</InlineCodeSnippet> (address could represent the same address but has some significant differences). No Match: {'<'} <InlineCodeSnippet>0.7</InlineCodeSnippet>. Resources other than Match will return a fixed value of <InlineCodeSnippet>Exact</InlineCodeSnippet>.</>, 'char', '8', '0..1'],
    [<InlineCodeSnippet>COUNTRY_CODE</InlineCodeSnippet>, 'A code identifying the country of the record.', 'char', '1', '1'],
    [<InlineCodeSnippet>COUNTRY_CODE_DESCRIPTION</InlineCodeSnippet>, 'A description that describes the country of the record.', 'char', '100', '1'],
    [<InlineCodeSnippet>LOCAL_CUSTODIAN_CODE</InlineCodeSnippet>, <>Unique identifier of the Local Land and Property Gazetteer (LLPG) Custodian responsible for the maintenance of this record.
        <ExternalLink type="download" href='https://www.ordnancesurvey.co.uk/documents/product-support/support/addressbase-local-custodian-codes.zip' message="Download the Local Custodian code list (ZIP)." />
        </>, 'integer', '4', '1'],
    [<InlineCodeSnippet>LOCAL_CUSTODIAN_CODE_DESCRIPTION</InlineCodeSnippet>, 'Description of the Local Land and Property Gazetteer (LLPG) Custodian responsible for the maintenance of this record.', 'char', 'as per code definition document', '1'],
    [<InlineCodeSnippet>CLASSIFICATION_CODE</InlineCodeSnippet>, <>A code that describes the classification of the record, for example, <InlineCodeSnippet>CE01HE</InlineCodeSnippet> is a higher education establishment. 
        <ExternalLink type="download" href='https://www.ordnancesurvey.co.uk/documents/product-support/support/addressbase-product-classification-scheme.zip' message="Download the AddressBase classification code list (ZIP)." /></>, 'char', '1, 2, 4, 6', '1'],
    [<InlineCodeSnippet>CLASSIFICATION_CODE_DESCRIPTION</InlineCodeSnippet>, <>A description that describes the classification of the record, for example, <InlineCodeSnippet>CE01HE</InlineCodeSnippet> is a higher education establishment.</>, 'char', 'as per code definition document', '1'],
    [<InlineCodeSnippet>POSTAL_ADDRESS_CODE</InlineCodeSnippet>, 'A code that describes the type of postal delivery that the object is subject to.', 'integer', '1', '1'],
    [<InlineCodeSnippet>POSTAL_ADDRESS_CODE_DESCRIPTION</InlineCodeSnippet>, 'A description that describes the type of postal delivery that the object is subject to.', 'char', '100', '1'],
    [<InlineCodeSnippet>STREET_STATE_CODE</InlineCodeSnippet>, 'A code identifying the current state of the Street.', 'integer', '1', '0..1'],
    [<InlineCodeSnippet>STREET_STATE_CODE_DESCRIPTION</InlineCodeSnippet>, 'A description identifying the current state of the Street.', 'char', '100', '0..1'],
    [<InlineCodeSnippet>STREET_CLASSIFICATION_CODE</InlineCodeSnippet>, 'A code for the primary street classification.', 'char', '2', '0..1'],
    [<InlineCodeSnippet>STREET_CLASSIFICATION_CODE_DESCRIPTION</InlineCodeSnippet>, 'A description for the primary street classification.', 'char', 'as per code definition document', '1'],
    [<InlineCodeSnippet>LOGICAL_STATUS_CODE</InlineCodeSnippet>, <>A code for the logical status of the BLPU. <ExternalLink type="generic" href='https://www.ordnancesurvey.co.uk/documents/product-support/tech-spec/addressbase-premium-technical-specification.pdf' message="See the Logical Status code list (PDF) here." />
        </>, 'integer', '1', '1'],
    [<InlineCodeSnippet>BLPU_STATE_CODE</InlineCodeSnippet>, <>A code for the physical nature of the property or land object. These are used to represent the physical state or the feature. <ExternalLink type="generic" href='https://www.ordnancesurvey.co.uk/documents/product-support/tech-spec/addressbase-premium-technical-specification.pdf' message="See the BLPU State code list (PDF) here." />
        </>, 'integer', '1', '1'],
    [<InlineCodeSnippet>BLPU_STATE_CODE_DESCRIPTION</InlineCodeSnippet>, 'A description for the physical nature of the property or land object. These are used to represent the physical state or the feature.', 'char', '1', '0..1'],
    [<InlineCodeSnippet>TOPOGRAPHY_LAYER_TOID</InlineCodeSnippet>, 'Unique key for the application cross reference record.', 'char', '20', '1'],
    [<InlineCodeSnippet>PARENT_UPRN</InlineCodeSnippet>, 'UPRN of the parent record.', 'integer', '12', '0..1'],
    [<InlineCodeSnippet>LAST_UPDATE_DATE</InlineCodeSnippet>, 'Date this record was last updated.', 'date', 'date', '1'],
    [<InlineCodeSnippet>ENTRY_DATE</InlineCodeSnippet>, 'Date of the data entry.', 'date', 'date', '1'],
    [<InlineCodeSnippet>LEGAL_NAME</InlineCodeSnippet>, 'Registered legal name of organisation.', 'char', '60', '0..1'],
    [<InlineCodeSnippet>BLPU_STATE_DATE</InlineCodeSnippet>, 'Date at which the BLPU achieved its current state in the real-world.', 'date', 'date', '0..1'],
    [<InlineCodeSnippet>LPI_LOGICAL_STATUS_CODE</InlineCodeSnippet>, 'A code for the logical status of this record.', 'integer', '1', '1'],
    [<InlineCodeSnippet>LPI_LOGICAL_STATUS_CODE_DESCRIPTION</InlineCodeSnippet>, 'A description of the Logical Status reflects where the LPI has reached in its life cycle. Logical status is important in identification of the addresses’ requirements, for example, whether it is an alternative address or an historic address.', 'char', 'as per code definition document', '0..1'],
    [<InlineCodeSnippet>PARISH_CODE</InlineCodeSnippet>, 'Office for National Statistics (ONS) Parish code.', 'char', '9', '1'],
    [<InlineCodeSnippet>WARD_CODE</InlineCodeSnippet>, 'Office for National Statistics (ONS) Ward code.', 'char', '9', '1']
]
const serviceHeaders = ['Key', 'Description', 'Format', 'Multiplicity']
const serviceMetadata = [
    [<InlineCodeSnippet>Uri</InlineCodeSnippet>, 'The Unique Resource Identifier of this result.', 'char', '1'],
    [<InlineCodeSnippet>Query</InlineCodeSnippet>, 'The query string, decoded.', 'char', '1'],
    [<InlineCodeSnippet>Format</InlineCodeSnippet>, 'The requested format.', 'char', '1'],
    [<InlineCodeSnippet>Dataset</InlineCodeSnippet>, 'The datasets requested.', 'char', '1'],
    [<InlineCodeSnippet>maxresults</InlineCodeSnippet>, 'The maxresults requested.', 'integer', '0..1'],
    [<InlineCodeSnippet>Offset</InlineCodeSnippet>, 'The offset requested.', 'integer', '0..1'],
    [<InlineCodeSnippet>totalresults</InlineCodeSnippet>, 'The total number of results found (may be more than returned).', 'integer', '1'],
    [<InlineCodeSnippet>lr</InlineCodeSnippet>, 'The language requested.', 'char', '0..1'],
    [<InlineCodeSnippet>epoch</InlineCodeSnippet>, 'A version reference for the Data product.', 'char', '1'],
    [<InlineCodeSnippet>filter</InlineCodeSnippet>, <>The <InlineCodeSnippet>fq</InlineCodeSnippet> parameter(s) and their value(s) requested.</>, 'char', '0..1'],
    [<InlineCodeSnippet>output_srs</InlineCodeSnippet>, 'The output spatial reference system.', 'char', '1'],
    [<InlineCodeSnippet>srs</InlineCodeSnippet>, 'The projection the geospatial search coordinates are in. Only returned for Nearest, BBox and Radius resources.', 'char', '0..1'],
    [<InlineCodeSnippet>lastupdate</InlineCodeSnippet>, 'When the data source was last updated.', 'date', '1']
]

export default function MatchDatasetExplained() {
    return <>
        <Typography variant='h4'>Dataset explained</Typography>
        <Typography variant='body1' paragraph={true} component='div'>
            <p>
                AddressBase® Premium Basic Land and Property Units (BLPUs) can reference two types of address and
                with the OS Match and Cleanse API it is possible to search for one at a time, or both. These are the
                Delivery Point Address (DPA) and the Land and Property Identifier (LPI).
            </p>
        </Typography>
        <Typography variant='h4'>DPA Output</Typography>
        <Typography variant='body1' paragraph={true} component='div'>
            <p>
                The DPA address is defined as a property that receives deliveries from Royal Mail, including
                addresses within Northern Ireland, Channel Islands and Isle of Man.
            </p>
        </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <Table header={outputHeaders} values={dpaOutput}/>
            </Typography>
            <Typography variant='h4'>LPI Output</Typography>
        <Typography variant='body1' paragraph={true} component='div'>
            <p>
                The LPI address is a structured text entry using the BS7666 standard that identifies all BLPUs
                within AddressBase® Premium and does not need to receive deliveries from the Royal Mail to exist.
                This means that some non-postal objects (e.g., a church) can be included.
            </p>
        </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <Table header={outputHeaders} values={lpiOutput}/>
                <Typography variant='h4'>Service Metadata</Typography>
                <p>
                    Each request made contains metadata about the request in the header of the response. This
                    information
                    includes the URI, the string that was queried, the format requested and other information:
                </p>
                <Table header={serviceHeaders} values={serviceMetadata}/>
            </Typography>
        <Typography variant='h4'>Match scores and descriptions</Typography>
        <Typography variant='body1' paragraph={true} component='div'>
            <p>
                Every address record returned will have <InlineCodeSnippet>MATCH</InlineCodeSnippet> and <InlineCodeSnippet>MATCH_DESCRIPTION</InlineCodeSnippet> attributes which can help you
                select the most appropriate one:
            </p>
            <Table header={['Match score', 'Match description']}
                   values={[[<InlineCodeSnippet>1.0</InlineCodeSnippet>, 'Exact'], [<><InlineCodeSnippet>0.8</InlineCodeSnippet> - <InlineCodeSnippet>0.9</InlineCodeSnippet></>, 'Good'], [<InlineCodeSnippet>0.7</InlineCodeSnippet>, 'Partial'], [<><InlineCodeSnippet>0.6</InlineCodeSnippet> and under</>, 'No match']]}/>
        </Typography>
    </>
}