exports.CREATE_ISSUES = 'createIssues';
exports.MANAGE_USERS = 'manageUsers';
exports.MANAGE_BILLING = 'manageBilling';
exports.VIEW_BILLING = 'viewBilling';
exports.MANAGE_CONTACT_DETAILS = 'manageContactDetails';
exports.VIEW_COMPANY_DETAILS = 'viewCompanyDetails';
exports.MANAGE_COMPANY_DETAILS = 'manageCompanyDetails';
exports.MANAGE_PROJECT_MODE = 'manageProjectMode';
exports.MANAGE_PROJECTS = 'manageProjects';
exports.EDIT_PREMIUM_DOWNLOADS = 'editPremiumDownloads';
exports.SHOW_PREMIUM_DOWNLOADS = 'showPremiumDownloads';
exports.EDIT_NGD_DOWNLOADS = 'editNgdDownloads';
exports.SHOW_NGD_DOWNLOADS = 'showNgdDownloads';
exports.SHOW_POLYGON_LIBRARY = 'showPolygonLibrary';
exports.VIEW_DATA_PACKAGES = 'viewDataPackages';
exports.CREATE_RECIPE = 'createRecipe';
exports.SHARE_RECIPE = 'shareRecipe';
exports.ACCEPT_RECIPE_INVITATION = 'acceptRecipeInvitation';
exports.APPLY_PSGA = 'applyPsga';
exports.USE_OS_PLACES = 'useOSPlaces';
exports.VIEW_OS_MATCH_AND_CLEANSE_DOCS = 'viewOSMatchAndCleanseDocs';
exports.UPGRADE_ACCOUNT = 'upgradeAccount';
exports.IMPERSONATE_USER = 'impersonateUser';
exports.CLEAR_SERVICE_STATUS_CACHE = 'clearServiceStatusCache';
exports.READ_SERVICE_STATUS_CACHE = 'readServiceStatusCache';
exports.SHOW_VERNACULAR_NAMES = 'showVernacularNames';
exports.WRITE_VERNACULAR_NAMES = 'writeVernacularNames';
exports.MANAGE_EAI_API_PLANS = 'manageEaiApiPlans';
exports.VIEW_EAI_API_PLANS = 'viewEaiApiPlans';
exports.SHOW_WORKSPACE = 'showWorkspace';
exports.MANAGE_LICENCES = 'manageLicences';