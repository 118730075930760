import React from 'react';
import PropTypes from 'prop-types';
import {createUseStyles} from 'react-jss';
import Tooltip from '@mui/material/Tooltip';
import {osColour, box, arrow, arrowTooltipStyles} from 'omse-components';
import Zoom from '@mui/material/Zoom';
import Paper from '@mui/material/Paper';

const useStyles = createUseStyles(theme => ({
    tooltip: {
        background: 'white',
        margin: 0,
        padding: '11px 12px',
        color: osColour.neutral.charcoal,
        transform: 'none !important',
        boxShadow: 'none'
    },
    paper: {
        boxShadow: 'none',
    },
    popper: {
        boxShadow: box.shadow,
        borderRadius: box.borderRadius,
        opacity: 1,
        left: 100,
        '&[data-popper-placement*="bottom"] $arrow': {
            top: 0,
            marginTop: arrow.offset
        },
        '&[data-popper-placement*="bottom"] $tooltip': {
            marginTop: 0
        },
        '&[data-popper-placement*="top"] $arrow': {
            bottom: 2,
            marginBottom: arrow.offset
        },
        '&[data-popper-placement*="top"] $tooltip': {
            marginBottom: 0
        }
    },
    arrow: {
        ...arrowTooltipStyles().arrow,  
        left: '50%'
    }
}));

export default function HoverTooltip({title, children, placement = 'top', PopperProps, TransitionProps}) {
    const classes = useStyles();
    return <Tooltip
        placement={placement}
        TransitionComponent={Zoom}
        TransitionProps={TransitionProps}
        title={
            <Paper className={classes.paper}>
                {title}
                <span className={classes.arrow}/>
            </Paper>
        }
        classes={{tooltip: classes.tooltip, popper: classes.popper}}
        PopperProps={PopperProps}>
        {children}
    </Tooltip>
}

HoverTooltip.propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired
};