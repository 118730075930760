import React from 'react';
import Typography from '@mui/material/Typography';
import Header from "./Header";
import Content from './Content';
import figure1 from './vtsIntro/Figure1.png';
import figure2 from './vtsIntro/Figure2.png';
import figure3 from './vtsIntro/Figure3.png';
import figure4 from './vtsIntro/Figure4.png';
import figure5 from './vtsIntro/Figure5.png';
import figure6 from './vtsIntro/Figure6.png';
import figure7 from './vtsIntro/Figure7.png';
import figure8 from './vtsIntro/Figure8.png';
import DocLinks from "./DocLinks";
import Document from './Document';
import { ExternalLink, isWidthDown, withWidth } from 'omse-components';
import InlineCodeSnippet from "../../components/InlineCodeSnippet";

export function VTSIntro(props) {
    const {width} = props;
    const mobile = isWidthDown('sm', width);
    return <Document>
        <Header back={true}>OS Vector Tile API: Getting started guide</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains how to generate an API key to access the OS Vector Tile API, and then how to use
                    that API key with a Web Development IDE to create a web-based application.
                </p>
                <p>
                    This guide assumes basic knowledge of web development and will require access to an IDE (Integrated
                    Development Environment).
                </p>
            </Typography>
            <Typography variant='h1' component={'h2'}>Instructions to Generate a Project API Key and URL</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>The following instructions describe how to obtain the API service URL and to generate an API key:</p>
                <ol>
                    <li>Click this link to open the <ExternalLink type="generic" href="/" message="OS Data Hub" /> in a new tab.
                    </li>
                    {mobile &&
                        <li>Click the navigation menu button.</li>
                    }
                    <li>Click "API Dashboard" in the {!mobile && 'navigation'} menu.</li>
                    <li>Click "APIs" in the {!mobile && 'secondary navigation'} menu.</li>
                    <li>Click "Add to API project" next to the OS Vector Tile API.</li>
                    <li>If you already have a project you may choose to add the OS Vector Tile API into that
                        project, or alternatively Select "Add to NEW PROJECT".
                        <p>The next screen will contain the Project API Key and the API Endpoint address (API URL).</p>
                        <p>You can return to this screen by clicking "My projects" at any point in the future if you
                            need to copy your API key or the API address, or if you need to regenerate your API Key.</p>
                    </li>
                    <li>Keep this page open as you'll need the key when you apply the OS Vector Tile API service
                        in your web-based application.
                    </li>
                </ol>
                <p>
                    For demonstrative purposes, we will apply the service through an IDE to create a web-based
                    application.
                </p>
            </Typography>
            <Typography variant='h1' component={'h2'}>Instructions to create a web-based application</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    To do this you will require access to an IDE and to Ordnance Survey’s GitHub Demo repository. You
                    will not require a GitHub account in order to access the demos.
                </p>
            </Typography>
            <Typography variant='h2'>Download and install the OSVectorTileAPI repository</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <ol>
                    <li>Navigate to the <ExternalLink
                        type="generic"
                        href="https://github.com/OrdnanceSurvey/OS-Data-Hub-API-Demos"
                        message="API demos repository"
                    /> on GitHub.</li>
                    <li>
                        <p>Click the green <i>Code</i> button.</p>
                        <img src={figure1} alt='GitHub repository clone/download button'/>
                    </li>
                    <li>
                        <p>
                            Click the option to <i>Download ZIP</i>.
                        </p>
                        <img src={figure2} alt='GitHub Download ZIP button'/>
                    </li>
                    <li>
                        <p>Navigate to your downloads page and unzip the <InlineCodeSnippet>OSVectorTileAPI</InlineCodeSnippet> folder from the newly
                            downloaded file and place it in your IDE project repository. When you open up your IDE, the
                            new files should appear automatically in your project window.</p>
                        <div style={{justifyContent: 'space-evenly', display: 'flex'}}>
                            <img src={figure3} alt='IDE project repository'/>
                            <img src={figure4} alt='Project window'/>
                        </div>
                    </li>
                    <li>
                        <p>The folders you have installed contain all the coding required to call and interact with the
                            code libraries. The method differs between IDE, however, to launch the web-based application
                            navigate to, and open the <InlineCodeSnippet>index.html</InlineCodeSnippet> file found in the OpenLayers folder. Once you have done
                            this, run the application.</p>
                        <img src={figure5} alt='index.html in OpenLayers folder'/>
                    </li>
                </ol>
                <Typography variant='h2'>Using the web-based demo</Typography>
                <ol>
                    <li>
                        <p>Running the project will launch a window in your browser that looks like this:</p>
                        <img src={figure6} alt='demo project window'/>
                        <p>The demo application is now running on a temporary local server from your own computer. This
                            sever is private to your IP address and so cannot be shared with other users. This server
                            will exist as long as your IDE is open. You will have to run the <InlineCodeSnippet>index.html</InlineCodeSnippet> file
                            through your IDE each time you wish to use the demo.</p>
                    </li>
                    <li>
                        <p>To use the demo, open up your <i>My projects</i> page on the OS Data Hub and copy the <i>Project
                            API Key</i>.</p>
                        <img src={figure7} alt='Copy API key'/>
                    </li>
                    <li>
                        <p>Paste the key into the <i>Project API</i> Key box on the local server demo and run <i>Load
                            Map</i>.</p>
                        <img src={figure8} alt='VTS map'/>
                        <p>This will then generate a fully styled map using the OS Vector Tile API service available for
                            you to now explore. You can also use the code provided in the demo as the basis for your own
                            web-browser application.</p>
                    </li>
                </ol>
            </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <b>Well done!</b> You've just completed the following actions:
                <ol>
                    <li>You've obtained the URL and generated an API key for OS Vector Tile API.</li>
                    <li>You've accessed and downloaded the OS Vector Tile API demo from our GitHub repository.</li>
                    <li>You have created a localised demo using your IDE and have applied your API key in order to
                        interact with the data.
                    </li>
                </ol>
            </Typography>

            <DocLinks product='vts' hasTechnicalSpec={true} hasDemo={true}/>
        </Content>
    </Document>
}

export default withWidth()(VTSIntro);