import React from 'react';
import Header from "../Header";
import Content from '../Content';
import Typography from '@mui/material/Typography';
import DocLinks from "../DocLinks";
import Document from '../Document';
import { ExternalLink } from 'omse-components';

export default function OAuthOverview(props) {
    return <Document>
        <Header back={true}>OAuth 2 API: Overview</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    OAuth 2 is an authentication mechanism for APIs that allows applications to use time limited tokens for access to resources.
                    A common use case for the OAuth 2 API is when implementing a web application that uses the OS Data Hub APIs.
                    As the user’s browser is not a trusted environment, the author of the web application should not embed the project API key in the application code, but they do need the application to be able to access the API!
                </p>
                <p>
                    One solution to this paradox is to implement a server backend which requests OAuth 2 access tokens using stored authentication credentials.
                    The server backend can then provide the access token to the frontend application code.
                    Once the frontend application code has the access token, it can use it to make HTTP calls directly to any of the OS Data Hub APIs that have been added to your OS Data Hub Project.
                    In the near future the access token will expire, and the frontend application should call into the server backend requesting a new token before making further OS Data Hub API calls.
                </p>
                <p>
                    Using this implementation pattern prevents end-users from seeing your API authentication credentials, and as a result helps to prevent use of your subscription limits outside of your application.
                </p>
                <p>
                    Please note that using the OAuth 2 API in this way will hide your API credentials from public view.
                    However, you should take additional steps in your server backend to ensure that you do not hand out access tokens to unauthorised users.
                    A common option is to only grant access to tokens once the user has logged into the application.
                    Once the end-user has received an access token you cannot monitor or control their use of it, as they can now invoke the OS Data Hub APIs directly.
                </p>
                <p>
                    An alternative way to protect your API key is to implement a server backend that acts as a proxy for the OS Data Hub APIs.
                    Using this approach, the proxy should validate the users’ request before adding in your Project API Key and passing the request on to the OS Data Hub API endpoint.
                    As each request is passing through your proxy, you can exercise fine-grained control over the requests that you allow through.
                    Common options would be to check that the user is logged in before allowing access, and/or validating that the query that the application is making matches a list of permitted queries.
                </p>
                <p>
                    There is no inherent difference to the level of security provided by the OAuth 2 or Proxy approaches, as the API access credentials are stored server-side in both cases.
                </p>
                <p>Using the OAuth 2 API,</p>
                <p>You can:</p>
                <ul>
                    <li>Create time limited access tokens.</li>
                    <li>Hide your API access credentials from end-users.</li>
                    <li>Restrict the available APIs within the project.</li>
                </ul>
                <p>You can't:</p>
                <ul>
                    <li>Restrict which data layers can be accessed using an access token.</li>
                    <li>View mapping or data without making additional calls to the relevant APIs.</li>
                </ul>
            </Typography>

            <DocLinks product='oauth2' hasGettingStarted={true} hasTechnicalSpec={true}/>
            <Typography variant='body1' paragraph={true}>
                Take a look at our <ExternalLink type="generic" href="https://github.com/OrdnanceSurvey/OS-Data-Hub-API-Demos" message="Airports examples on GitHub" />.
                We have two versions available, one demonstrating the OAuth 2 approach, and one demonstrating the use of an API proxy.
            </Typography>
            <Typography variant='body1' paragraph={true}>
                For more information about OAuth 2, please see <ExternalLink type="message" href="https://oauth.net/2/" message="oauth.net" /> and <ExternalLink type="generic" href="https://aaronparecki.com/oauth-2-simplified/" message="aaronparecki.com" />.
            </Typography>

        </Content>
    </Document>
}
