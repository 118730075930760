import Button from "@mui/material/Button";
import {FormattedMessage} from "react-intl";
import React from "react";
import {createUseStyles} from 'react-jss';
import {useSelector} from 'react-redux';
import {createDataPackage, updateDraftOrder} from "../../../../modules/dataPackages/actions";
import {reTestPositiveInteger} from "../../../../../shared/input";
import {COM3_CATALOGUE} from "../../../../../shared/catalogues";

const styles = createUseStyles({
    actionButton: {
        whiteSpace: 'nowrap'
    }
});

export default function SubmitButton(props) {
    const {dispatch, working, label, disabled} = props;
    const draftOrder = useSelector(state => state.dataPackages.draftOrder);
    const datasetOptions = useSelector(state => state.recipes?.datasetOptions?.result);
    const classes = styles();
    const product = useSelector(state => state.premium.product.result);

    function onClick() {
        const packageNameEmpty = !draftOrder.packageName || !draftOrder.packageName.trim();
        const formatEmpty = !draftOrder.format;

        // For Snapback orders we need to supply updates to ONCE
        const updatesEmpty = !draftOrder.isSnapBack && !draftOrder.updates;
        draftOrder.updates = draftOrder.isSnapBack ? "ONCE" : draftOrder.updates;

        const aoiEmpty = draftOrder.area === 'Polygon' && (!draftOrder.AOI);
        const initialSupplyDateEmpty = draftOrder.recipeId && !draftOrder.initialSupplyDataDate;
        const tilesEmpty = draftOrder.area === 'Polygon' && (!draftOrder.tiles || draftOrder.tiles.length === 0);

        const dataPackageIsEai = draftOrder.catalogueId === COM3_CATALOGUE;
        const isExpanding = !!draftOrder.id;
        const renewalEmpty = dataPackageIsEai && !draftOrder.renewalPeriod;
        const terminalsEmpty = dataPackageIsEai  && !product.exactTerminals && !draftOrder.terminals;
        const usersEmpty = dataPackageIsEai && product.exactTerminals && (!draftOrder.users || !reTestPositiveInteger(draftOrder.users));
        const eaiShowErrors = !isExpanding && (renewalEmpty || terminalsEmpty || usersEmpty);

        if(datasetOptions?.length && draftOrder.crs && draftOrder.crs !== 'defaultMix'){
            draftOrder.datasetOptions = datasetOptions.map((option) => {
                return {
                    featureTypeId: option.featureTypeId,
                    outputCrs: option.availableCrs.includes(draftOrder.crs) ? draftOrder.crs : option.defaultCrs
                }
            });
        }

        if (packageNameEmpty || formatEmpty || updatesEmpty || aoiEmpty || initialSupplyDateEmpty || (draftOrder.tiled && tilesEmpty) || eaiShowErrors) {
            dispatch(updateDraftOrder('displayErrors', true));
        } else {
            dispatch(createDataPackage(draftOrder));
        }

    }

    return <Button key='submit'
                   onClick={onClick}
                   size='medium'
                   variant='contained'
                   color='primary'
                   disabled={disabled || working}
                   className={classes.actionButton}>
        <FormattedMessage {...label}/>
    </Button>;
}
