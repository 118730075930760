import {getUserOrganisationById} from './organisation';

import {
    APPLY_PSGA,
    CLEAR_SERVICE_STATUS_CACHE,
    CREATE_ISSUES,
    CREATE_RECIPE,
    EDIT_NGD_DOWNLOADS,
    EDIT_PREMIUM_DOWNLOADS,
    IMPERSONATE_USER,
    MANAGE_BILLING,
    MANAGE_COMPANY_DETAILS,
    MANAGE_CONTACT_DETAILS,
    MANAGE_PROJECT_MODE,
    MANAGE_PROJECTS,
    MANAGE_USERS,
    READ_SERVICE_STATUS_CACHE,
    SHOW_NGD_DOWNLOADS,
    SHOW_PREMIUM_DOWNLOADS,
    UPGRADE_ACCOUNT,
    USE_OS_PLACES,
    VIEW_BILLING,
    VIEW_COMPANY_DETAILS,
    VIEW_DATA_PACKAGES,
    VIEW_OS_MATCH_AND_CLEANSE_DOCS,
    SHARE_RECIPE,
    SHOW_VERNACULAR_NAMES,
    MANAGE_EAI_API_PLANS,
    SHOW_WORKSPACE,
    MANAGE_LICENCES,
    VIEW_EAI_API_PLANS
} from '../../shared/permissions';
import {COM3_CATALOGUE, PART_CATALOGUE} from "../../shared/catalogues";

function hasPermission(user, permission) {
    return user && user.permissions && user.permissions.indexOf(permission) !== -1;
}

export function hasManageUsersPermission(user) {
    return hasPermission(user, MANAGE_USERS);
}

export function hasManageBillingPermission(user) {
    return hasPermission(user, MANAGE_BILLING);
}

export function hasViewBillingPermission(user) {
    return hasPermission(user, VIEW_BILLING);
}

export function hasCreateIssues(user) {
    return hasPermission(user, CREATE_ISSUES);
}

export function hasManageContactDetailsPermission(user) {
    return hasPermission(user, MANAGE_CONTACT_DETAILS);
}

export function hasViewCompanyDetailsPermission(user) {
    return hasPermission(user, VIEW_COMPANY_DETAILS);
}

export function hasShareRecipesPermission(user) {
    return hasPermission(user, SHARE_RECIPE);
}

export function hasManageCompanyDetailsPermission(user) {
    return hasPermission(user, MANAGE_COMPANY_DETAILS);
}

export function hasManageProjectModePermission(user, orgId) {
    if (orgId) {
        const org = getUserOrganisationById(user, orgId);
        return org && org.permissions.indexOf(MANAGE_PROJECT_MODE) !== -1;
    }
    return hasPermission(user, MANAGE_PROJECT_MODE);
}

export function hasManageProjectsPermission(user) {
    return hasPermission(user, MANAGE_PROJECTS);
}

export function hasEditPremiumDownloadsPermission(user) {
    return hasPermission(user, EDIT_PREMIUM_DOWNLOADS);
}

export function hasShowPremiumDownloadsPermission(user) {
    return hasPermission(user, SHOW_PREMIUM_DOWNLOADS);
}

export function hasShowPremiumDownloadsPermissionAndCataloguesToShow(user) {
    return hasShowPremiumDownloadsPermission(user) && Array.isArray(user.catalogues) && user.catalogues.length > 0;
}

export function hasEditNgdDownloadsPermission(user) {
    return hasPermission(user, EDIT_NGD_DOWNLOADS);
}

export function hasShowNgdDownloadsPermission(user) {
    return hasPermission(user, SHOW_NGD_DOWNLOADS);
}

export function hasViewDataPackagesPermission(user) {
    return hasPermission(user, VIEW_DATA_PACKAGES);
}

export function canApplyPsga(user) {
    return hasPermission(user, APPLY_PSGA);
}

export function hasUseOSPlacesPermission(user) {
    return hasPermission(user, USE_OS_PLACES);
}

export function hasViewMatchAndCleanseDocsPermission(user) {
    return hasPermission(user, VIEW_OS_MATCH_AND_CLEANSE_DOCS);
}

export function hasUpgradeAccountPermission(user) {
    return hasPermission(user, UPGRADE_ACCOUNT);
}

export function hasImpersonateUserPermission(user) {
    return hasPermission(user, IMPERSONATE_USER);
}

export function hasReadServiceStatusCachePermission(user) {
    return hasPermission(user, READ_SERVICE_STATUS_CACHE);
}

export function hasClearServiceStatusCachePermission(user) {
    return hasPermission(user, CLEAR_SERVICE_STATUS_CACHE);
}

export function hasPartnerCatalogue(user) {
    return user && user.catalogues && user.catalogues.includes(PART_CATALOGUE);
}

export function hasManageEaiApiPlan(user){
    return hasPermission(user, MANAGE_EAI_API_PLANS);
}
export function hasViewEaiApiPlansPermission(user){
    return hasPermission(user, VIEW_EAI_API_PLANS);
}

export function hasCom3Catalogue(user) {
    return user && user.catalogues && user.catalogues.includes(COM3_CATALOGUE);
}

export function hasCreateRecipePermission(user) {
    return hasPermission(user, CREATE_RECIPE);
}

export function hasShowVernacularNamesPermission(user) {
    return hasPermission(user, SHOW_VERNACULAR_NAMES);
}

export function hasShowWorkspacePermission(user) {
    return hasPermission(user, SHOW_WORKSPACE);
}

export function hasManageLicencesPermission(user) {
    return hasPartnerCatalogue(user) && hasPermission(user, MANAGE_LICENCES);
}