import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Typography, useMediaQuery } from "@mui/material";
import { FormattedMessage, defineMessages } from "react-intl";
import styled from "@emotion/styled";
import {
    ExternalLink,
    InternalLink,
    Notification,
    theme,
} from "omse-components";
import {
    getUserStats,
    USER_STATS_DETAIL_PROJECT,
} from "../../modules/stats/actions";
import useMode from "../../hooks/useMode";
import Transactions from "../dashboard/dashboardContent/Transactions";
import HeadingOrgPlan from "./HeadingOrgPlan";
import CardGallery from "./CardGallery";
import HelpAndSupport from "./HelpAndSupport";

export const usageMessages = defineMessages({
    apiTransactions: {
        id: "WorkspaceSidebar.apiTransactions",
        defaultMessage: "API usage this month",
        description: "API usage heading",
    },
    fullApiMessage: {
        id: "WorkspaceSidebar.fullApiMessage",
        defaultMessage: "See full API usage details",
        description: "Full API link message",
    },
    errorLoading: {
        id: "WorkspaceSidebar.errorLoading",
        defaultMessage:
            "There was a problem loading your usage. Please try again later or {link} if the problem persists.",
        description: "Stats error message",
    },
});

const StyledHeading = styled(Typography)`
  font-size: 1.25em;
  font-weight: 600;
  margin-top: 16px;
`;

const StyledProgress = styled(CircularProgress)(
    ({ theme }) => `
	display: block;
	margin-top: ${theme.spacing(7)};
	margin-bottom: ${theme.spacing(7)};
`
);

const StyledNotification = styled(Notification)(
    ({ theme }) => `
	display: block;
	margin-top: ${theme.spacing(7)};
	margin-bottom: ${theme.spacing(7)};
	max-width: 500px;
	${theme.breakpoints.down("md")} {
		max-width: 100%;
	};
`
);

const LinkContainer = styled("div")`
  margin-bottom: 2.5em;
`;

const WORKSPACE_VARIANT = "workspace";

const WorkspaceSidebar = () => {
    const org = useSelector((state) => state.organisation.current);
    const { loading, error, result: userStats } = useSelector((state) => state.stats.user);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isTablet = useMediaQuery(theme.breakpoints.down(1315));
    const dispatch = useDispatch();
    const [mode] = useMode();

    const orgId = org?.id;
    useEffect(() => {
        if (!userStats) {
            dispatch(getUserStats(USER_STATS_DETAIL_PROJECT, mode, orgId, true));
        }
    }, [dispatch, mode, orgId, userStats]);

    const renderTransactions = () => {
        if (loading) {
            return <StyledProgress data-testid="workspace-loading" />;
        }

        if (error) {
            return (
                <StyledNotification variant="error" role="alert" appearance="inline">
                    <Typography variant="body1" sx={{ marginRight: "6px" }}>
                        <FormattedMessage
                            {...usageMessages.errorLoading}
                            values={{ link: <ExternalLink type="support" /> }}
                        />
                    </Typography>
                </StyledNotification>
            );
        }

        return (
            <>
                <StyledHeading variant={"h2"}>
                    <FormattedMessage {...usageMessages.apiTransactions} />
                </StyledHeading>
                <Transactions variant={WORKSPACE_VARIANT} mode={mode} />
            </>
        );
    };

    return (
        <section data-testid="workspace-sidebar">
            {!isTablet && <HeadingOrgPlan />}
            {renderTransactions()}
            <LinkContainer>
                <InternalLink
                    message={usageMessages.fullApiMessage}
                    path={"/history"}
                />
            </LinkContainer>
            {isMobile && <CardGallery />}
            <HelpAndSupport />
        </section>
    );
};

export default WorkspaceSidebar;
