import React, {Component} from 'react';
import Header from "./Header";
import PropTypes from 'prop-types';
import Document from './Document';
import FeatureCheck from "../../components/FeatureCheck";
import Content from "./Content";
import Typography from "@mui/material/Typography";
import DocLinks from "./DocLinks";
import { ExternalLink, isWidthDown, withWidth } from 'omse-components';
import OTACompletionSummary from "./otaIntro/OTACompletionSummary";
import OTACodePlayground from "./otaIntro/OTACodePlayground";


export class OTAIntro extends Component {

    render() {
        const {width} = this.props;
        const mobile = isWidthDown('sm', width);
        return <FeatureCheck feature='ngdTiles'><Document>
            <Header back={true}>OS NGD API – Tiles: Getting started guide</Header>
            <Content>
                <Typography variant='body1' paragraph={true}>
                    <p>This guide explains how to generate an API key to access OS NGD API – Tiles, and how to use that
                        API key with our interactive code examples to quickly create a web-based application.</p>
                    <p>This guide assumes you have basic knowledge of web development.</p>
                </Typography>
                <Typography variant='h1' component='h2'>Instructions to Generate a Project API Key and URL</Typography>
                <Typography variant='body1' paragraph={true} component='div'>
                    <p>The following instructions describe how to obtain the API service URL and to generate an API
                        key:</p>
                    <ol>
                        <li>Click this link to open the <ExternalLink
                            type="generic"
                            href="/"
                            message="OS Data Hub"
                        /> in a new tab.</li>
                        {mobile &&
                            <li>Click the navigation menu button.</li>
                        }
                        <li>Click "API Dashboard" in the {!mobile && 'navigation'} menu.</li>
                        <li>Click "APIs" in the {!mobile && 'secondary navigation'} menu.</li>
                        <li>In OS NGD API – Tiles, click "Add to API project".</li>
                        <li>In the dropdown:</li>
                        <ol type='a'>
                            <li>Select the project to which you want to add OS NGD API – Tiles.</li>
                            <li>Alternatively, select "Add to NEW PROJECT", enter a valid project name and click “Create Project”.</li>
                        </ol>
                        <li>The next page contains the Project API Key and the API Endpoint address (API URL).
                            Keep it open as you'll need the key later when you apply the
                            OS NGD API – Tiles service in your web-based application.

                        </li>
                    </ol>
                    <p>Note: You can return to this page at any time to copy your API key or API address, or
                        to regenerate your API Key, by navigating to
                        “API Dashboard {'>'} "My projects" {'>'} Project Name. </p>
                </Typography>
                <OTACodePlayground />
                <OTACompletionSummary software='demo' />
                <DocLinks product='ota' hasTechnicalSpec={true} hasDemo={true}/>
            </Content>
        </Document></FeatureCheck>
    }
}

OTAIntro.propTypes = {
    width: PropTypes.string
}

export default withWidth()(OTAIntro);