
const PREFIX = 'action:payments:';
export const SETUP_PAYMENT_DETAILS_ACTION = PREFIX + 'cardDetails:setup';
export const CLEAR_PAYMENT_DETAILS_ACTION = PREFIX + 'cardDetails:clear';
export const LOAD_PAYMENT_STATUS_ACTION = PREFIX + 'status:load';
export const LOADED_PAYMENT_STATUS_ACTION = PREFIX + 'status:loaded';
export const LOAD_PAYMENT_CONFIG_DATA_ACTION = PREFIX + 'config:load';
export const LOADED_PAYMENT_CONFIG_DATA_ACTION = PREFIX + 'config:loaded';
export const LOAD_PAYMENT_HISTORY_ACTION = PREFIX + 'history:load';
export const LOADED_PAYMENT_HISTORY_ACTION = PREFIX + 'history:loaded';
export const RETRY_PAYMENTS_ACTION = PREFIX + 'retry';

export function setupPaymentDetails(actionId, cardHolderName, session, billingAddress, phone) {
    return {
        type: SETUP_PAYMENT_DETAILS_ACTION,
        actionId,
        cardHolderName,
        session,
        billingAddress,
        phone
    };
}

export function clearPaymentDetails() {
    return {
        type: CLEAR_PAYMENT_DETAILS_ACTION
    };
}

export function getPaymentStatus() {
    return {
        type: LOAD_PAYMENT_STATUS_ACTION
    };
}

export function getPaymentHistory(dateFrom, dateTo="", paymentStatus = "") {
    return {
        type: LOAD_PAYMENT_HISTORY_ACTION,
        paymentStatus,
        dateFrom,
        dateTo
    };
}

export function retryPayments(actionId) {
    return {
        type: RETRY_PAYMENTS_ACTION,
        actionId
    };
}

export function getPaymentConfigData() {
    return {
        type: LOAD_PAYMENT_CONFIG_DATA_ACTION
    };
}
