import React from "react";
import Box from '@mui/material/Box';
import {
    pickersLayoutClasses,
    PickersLayoutContentWrapper,
    PickersLayoutRoot,
    usePickerLayout,
} from '@mui/x-date-pickers/PickersLayout';
import { DateField } from '@mui/x-date-pickers/DateField';

export default function LayoutWithKeyboardView(props) {
    const { value, onChange } = props;
    const [showKeyboardView, setShowKeyboardView] = React.useState(false);

    const { toolbar, tabs, content, actionBar } = usePickerLayout({
        ...props,
        slotProps: {
            ...props.slotProps,
            toolbar: {
                ...props.slotProps?.toolbar,
                showKeyboardViewSwitch: props.wrapperVariant === 'mobile',
                showKeyboardView,
                setShowKeyboardView,
            },
        },
    });

    return (
        <PickersLayoutRoot ownerState={props}>
            {toolbar}
            {actionBar}
            <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
                {tabs}
                {showKeyboardView ? (
                    <Box sx={{ mx: 3, my: 2, width: 272 }}>
                        <DateField value={value} onChange={onChange} sx={{ width: '100%' }} slotProps={{ textField: { variant: 'standard' } }} />
                    </Box>
                ) : (
                    content
                )}
            </PickersLayoutContentWrapper>
        </PickersLayoutRoot>
    );
}
