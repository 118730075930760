import React from 'react';
import {Typography} from "@mui/material";
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles(theme => ({
    packageName: {
        paddingTop: theme.spacing(4)
    },
    paragraph: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(1),
    }
}));

export default function ExpandIntro(props) {
    const {name, id, label} = props;
    const classes = useStyles();

    return <div className={classes.paragraph}>
        <Typography variant='h2' className={classes.packageName}>
            {name}
        </Typography>
        <Typography color='textSecondary'>
            {id}
        </Typography>
        <Typography variant="subtitle1" component="h3">
            {label}
        </Typography>
    </div>;
}
