import {createLoadingReducer, createLoadingMiddleware, ACTION_UPDATE_SUFFIX} from 'omse-components';
import {
    LOAD_PAYMENT_STATUS_ACTION,
    LOADED_PAYMENT_STATUS_ACTION, 
    SETUP_PAYMENT_DETAILS_ACTION, 
    LOADED_PAYMENT_HISTORY_ACTION, 
    LOAD_PAYMENT_HISTORY_ACTION,
    LOAD_PAYMENT_CONFIG_DATA_ACTION,
    LOADED_PAYMENT_CONFIG_DATA_ACTION,
    getPaymentStatus
} from "./payments/actions";
import {reducer as setupPaymentReducer, middleware as setupPaymentMiddleware} from './payments/setup';
import {reducer as clearPaymentReducer, middleware as clearPaymentMiddleware} from "./payments/clear";
import {reducer as retryPaymentsReducer, middleware as retryPaymentsMiddleware} from "./payments/retry";
import {combineReducers} from 'redux';
import {getNotificationStatus} from "./notifications/actions";

export const paymentsReducer = combineReducers({
    config: createLoadingReducer(LOAD_PAYMENT_CONFIG_DATA_ACTION, LOADED_PAYMENT_CONFIG_DATA_ACTION, true, reformat),
    status: createLoadingReducer(LOAD_PAYMENT_STATUS_ACTION, LOADED_PAYMENT_STATUS_ACTION, true),
    history: createLoadingReducer(LOAD_PAYMENT_HISTORY_ACTION, LOADED_PAYMENT_HISTORY_ACTION, true),
    setupPaymentDetails: setupPaymentReducer,
    clearPaymentDetails: clearPaymentReducer,
    retryPayments: retryPaymentsReducer
});

function preparePaymentConfigDataCall(action) {
    return '/api/payments/config';
}

function preparePaymentStatusCall(action) {
    return '/api/payments/status';
}

function preparePaymentHistoryCall(action) {
    const {paymentStatus, dateFrom, dateTo} = action;
    return `/api/payments/history?status=${paymentStatus}&from=${dateFrom}&to=${dateTo}`;
}

const SETUP_PAYMENT_DETAILS_RESPONSE = SETUP_PAYMENT_DETAILS_ACTION + ACTION_UPDATE_SUFFIX;

export const paymentsMiddleware = [
    createLoadingMiddleware(LOAD_PAYMENT_CONFIG_DATA_ACTION, LOADED_PAYMENT_CONFIG_DATA_ACTION, preparePaymentConfigDataCall),
    createLoadingMiddleware(LOAD_PAYMENT_STATUS_ACTION, LOADED_PAYMENT_STATUS_ACTION, preparePaymentStatusCall),
    createLoadingMiddleware(LOAD_PAYMENT_HISTORY_ACTION, LOADED_PAYMENT_HISTORY_ACTION, preparePaymentHistoryCall),
    setupPaymentMiddleware,
    clearPaymentMiddleware,
    retryPaymentsMiddleware,

    // Add in more middleware, to auto-refresh the card details if the result is pending. Once the card is verified
    // then we get the payment status too, as some payments should be being retried at that point.
    store => next => action => {
        if(action.type === LOADED_PAYMENT_STATUS_ACTION && action.result) {
            let autoRefresh = false;
            let waitTime = 5000;
            const {cardDetails, balance, nextPayment} = action.result;
            if(cardDetails && cardDetails.status === 'PENDING') {
                autoRefresh = true;
            }
            if(balance === undefined) {
                autoRefresh = true;
            }
            if(nextPayment && nextPayment.status === 'PROCESSING') {
                autoRefresh = true;
            }
            if(cardDetails && cardDetails.status === 'VERIFIED') {
                const oldState = store.getState().payments.status.result;
                if (oldState && oldState.cardDetails && oldState.cardDetails.status === 'PENDING') {
                    autoRefresh = true;
                    waitTime = 0;
                }
            }
            if(autoRefresh) {
                if(waitTime > 0) {
                    setTimeout(() => {
                        store.dispatch(getPaymentStatus());
                    }, waitTime);
                } else {
                    store.dispatch(getPaymentStatus());
                }
            }

            if(cardDetails && (cardDetails.status === 'FAILED' || cardDetails.status === 'PASSED')) {
                const oldState = store.getState().payments.status.result;
                if (oldState && oldState.cardDetails && oldState.cardDetails.status === 'PENDING') {
                    store.dispatch(getNotificationStatus());
                }
            }
        }
        return next(action);
    },

    // Another piece of middleware, to force the payment status to reload if we fail to set up a new card
    store => next => action => {
        if(action.type === SETUP_PAYMENT_DETAILS_RESPONSE && action.error) {
            store.dispatch(getPaymentStatus());
        }
        return next(action);
    }
];

export function reformat(action) {
    let paymentsConfigData = action.result;
    if (paymentsConfigData && paymentsConfigData.vatRate) {
        paymentsConfigData.vatRate = parseFloat(paymentsConfigData.vatRate);
    }
}
