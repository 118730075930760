import React, {useState, useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {createUseStyles} from 'react-jss';
import classNames from 'classnames';
import {Typography} from '@mui/material';
import {AddButton, Notification, Tooltip, osColour} from 'omse-components';
import withFullHeight from '../../../util/withFullHeight';

const useStyles = createUseStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1',
        // Ensures the flex item can shrink below its content size.
        minHeight: 0,
        minWidth: 0,
        padding: theme.spacing(4),
        overflow: 'auto'
    },
    title: {
        marginBottom: theme.spacing(1)
    },
    acceptTermsIntro: {
        marginBottom: theme.spacing(0.5),
        '& p': {
            margin: theme.spacing(0, 0, 1, 0)
        }
    },
    tAndCsContainers: {
        border: `1px solid ${osColour.neutral.mist}`,
        flex: '1 1',
        overflow: 'auto',
        marginBottom: theme.spacing(1),
        '& > div': {
            padding: theme.spacing(3)
        },
        minHeight: 200
    },
    buttons: {
        marginTop: theme.spacing(2),
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        }
    },
    button: {
        marginBottom: theme.spacing(1)
    },
    declineButton: {
        marginRight: theme.spacing(3)
    },
    warning: {
        color: osColour.status.error
    }
}));

/**
 * Component which displays the Data Hub terms and conditions and gives buttons to decline or accept them.
 *
 * - If the user has not scrolled to the end of the T&Cs then the accept button will be disabled.
 * - When either the accept or decline button is working both buttons will be disabled.
 * - Error is a toast notification.
 *
 * Note this component is designed to take up any remaining space in a flex container.
 */
const TermsConditionsAccept = ({ onAcceptClick, acceptWorking, onDeclineClick, declineWorking, declineHasRoute, error, 
    isTermsUpdate, termsAndConditions, messages, intro, acceptingForOrganisation}) => {
    const classes = useStyles();

    // Only enable the accept button when scrolled to nearly the end.
    const [tAndCsAreScrolledAtEnd, setTAndCsAreScrolledToEnd] = useState(false);
    const onScroll = useCallback(evt => {
        const {scrollHeight, scrollTop, clientHeight} = evt.target;
        const pxFromBottomToConsiderEnd = 100;
        const minScrollTopToConsiderEnd = scrollHeight - clientHeight - pxFromBottomToConsiderEnd;
        const isAtEnd = scrollTop >= minScrollTopToConsiderEnd;
        setTAndCsAreScrolledToEnd(isAtEnd);
    }, []);

    // Ensure if an action has been triggered both buttons are disabled.
    const isActionPending = acceptWorking || declineWorking;

    // Final disabled state for buttons.
    const declineButtonDisabled = isActionPending;
    const acceptButtonDisabled = !tAndCsAreScrolledAtEnd || isActionPending;

    // Display a tooltip with info if the user clicks the accept button before scrolling T&Cs to the end.
    const [acceptTooltipDisplayed, setAcceptTooltipDisplayed] = useState(false);
    const onAcceptButtonWrapperClick = useCallback( () => {
        if(!isActionPending && !acceptTooltipDisplayed && !tAndCsAreScrolledAtEnd) {
            setAcceptTooltipDisplayed(true);
            setTimeout(() => setAcceptTooltipDisplayed(false), 5000);
        }
    }, [isActionPending, acceptTooltipDisplayed, tAndCsAreScrolledAtEnd]);
    // If the user has scrolled to the end we no longer want to display tooltip about needing to be at the end.
    if(tAndCsAreScrolledAtEnd && acceptTooltipDisplayed) {
        setAcceptTooltipDisplayed(false);
    }
    const acceptButtonRef = useRef(null); // We will hang the tooltip off this.

    const declineButtonLabel = declineHasRoute? messages.declineButton : messages.declineAndLogOutButton;

    return <div className={classes.wrapper} data-testid='terms-condition-accept'>
        <Typography variant='h1' className={classes.title}>
            <FormattedMessage {...messages.title}/>
        </Typography>
        <Typography variant='body1' component='div' className={classes.acceptTermsIntro}>
                {intro || <p>
                    {isTermsUpdate?
                        <FormattedMessage {...messages.acceptUpdateIntro}/>
                        :
                        <FormattedMessage {...messages.acceptTermsIntro}/>
                    }
                </p>
            }
            {acceptingForOrganisation &&
                <>
                    <p className={classes.warning}>
                        <FormattedMessage {...messages.organisationAcceptWarning} values={{b: chunks => <b>{chunks}</b>}}/>
                    </p>
                    <p><FormattedMessage {...messages.organisationAcceptHelp}/></p>
                </>
            }
        </Typography>
        <div aria-required='true' onScroll={onScroll} className={classes.tAndCsContainers}>
            { termsAndConditions }
        </div>
        {isTermsUpdate &&
            <Typography variant='body1'>
                <FormattedMessage {...messages.acceptUpdateText}/>
            </Typography>
        }
        <div className={classes.buttons}>
            {!isTermsUpdate &&
                <AddButton variant='contained'
                        color='primary'
                        showIcon={false}
                        label={declineButtonLabel}
                        className={classNames(classes.button, classes.declineButton)}
                        disabled={declineButtonDisabled}
                        action={onDeclineClick} />
            }
            <Tooltip open={acceptTooltipDisplayed}
                     anchorEl={acceptButtonRef.current}
                     body={messages.acceptButtonMustScrollBottom}
                     maxWidth={200}
                     handleClickAway={useCallback(() => setAcceptTooltipDisplayed(false), [])} />
            {/* When buttons are disabled they won't trigger click events. Use a wrapper div to listen for clicks on the
                disabled button. */}
            <span onClick={onAcceptButtonWrapperClick}>
                <AddButton variant='contained'
                           showIcon={false}
                           label={isTermsUpdate? messages.acceptTermsUpdate : messages.acceptButton}
                           className={classes.button}
                           disabled={acceptButtonDisabled}
                           working={acceptWorking}
                           action={onAcceptClick}
                           ref={acceptButtonRef} />
            </span>
        </div>
        {error && <Notification variant='error' appearance='toast'>
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.submissionError}/>
            </Typography>
        </Notification>}
    </div>
}

TermsConditionsAccept.propTypes = {
    onAcceptClick: PropTypes.func.isRequired,
    onDeclineClick: PropTypes.func.isRequired,
    isTermsUpdate: PropTypes.bool.isRequired,
    messages: PropTypes.object.isRequired,
    termsAndConditions: PropTypes.object.isRequired,
    intro: PropTypes.object,
    acceptingForOrganisation: PropTypes.bool,
    declineHasRoute: PropTypes.bool
};

export default withFullHeight(TermsConditionsAccept);