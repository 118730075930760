import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import {osColour} from 'omse-components';
import {defineMessages, FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import graphStyles from './styles/graph';
import HoverTooltip from '../HoverTooltip';

const messages = defineMessages({
    transactions: {
        id:'BarSegment.transactions',
        defaultMessage: '{data, plural, one {# transaction} other {# transactions}}',
        description: 'label for transactions'
    }
});

const styles = theme => ({
    openDataKey: {
        backgroundColor: osColour.primary.lighterBerry
    },
    premiumDataKey: {
        backgroundColor: osColour.primary.foxglove
    },
    psgaDataKey: {
        backgroundColor: osColour.primary.foxglove
    },
    tooltipTransactionTotal: {
        fontWeight: 'normal',
    },
    data: {
        height: '100%',
        margin: 0,
        padding: 0,
        borderRadius: 0,
        borderLeft: `${osColour.neutral.clouds} 1px dotted`,
        '&:first-child': {
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            borderLeft: 0
        },
        '&:hover': {
            cursor: 'pointer'
        },
    }, 
    ...graphStyles(theme)
});

export class BarSegment extends React.Component {
    state = {
        tooltipLeft: 0
    };

    render() {
        const { classes, item, total } = this.props;

        let percentage = (item.data / total) * 100 || 0;
        percentage = Math.round(percentage);

        return ((percentage > 0) && <HoverTooltip title={<>
                <Typography variant='body2' className={classes.keyLabel}>
                    <span className={classNames(classes.graphKey, classes[item.class])}></span>
                    {item.name}
                </Typography>
                <Typography variant='body2' className={classes.tooltipTransactionTotal}>
                    <FormattedMessage {...messages.transactions} values={{data: item.data}}/>
                </Typography>
            </>} PopperProps={{
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset', 
                            options: {
                                offset: [this.state.tooltipLeft, 8]
                            }
                        },
                        {
                            name: 'preventOverflow',
                            options: {
                                padding: 5
                            }
                        }
                    ]
                }
            }}>
                <div style={{width: `${percentage}%`}}
                     className={classNames(classes.data, classes[item.class], item.class, 'graphData')}
                     onMouseMove={(e) => {
                         let rect = e.target.getBoundingClientRect();
                         this.setState({tooltipLeft: parseInt(e.clientX - rect.left) - parseInt(rect.width / 2)});
                     }}/>
            </HoverTooltip>
        );
    }
}

BarSegment.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired
};

export default withStyles(styles)(BarSegment);