import React from 'react';
import Typography from '@mui/material/Typography';
import Header from "../Header";
import Content from '../Content';
import DocLinks from "../DocLinks";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import routes from '../../../util/routes';
import CodeSnippet from '../CodeSnippet';
import Document from '../Document';
import { ExternalLink, InternalLink } from "omse-components";
import InlineCodeSnippet from '../../../components/InlineCodeSnippet';

export default function DownloadsIntro() {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    return <Document>
        <Header back={true}>OS Downloads API: Getting started guide</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>This guide explains the steps required to automate the download of OS data using the OS Downloads API.</p>
            </Typography>
            <Typography variant='h1' component='h2'>
                Prerequisites
            </Typography>
            <Typography variant='body1' paragraph={true}>
                You will need a basic understanding of automatic processing of JSON data and basic procedural (if-then-else) programming.
            </Typography>
            <Typography variant='body1' paragraph={true}>
                An API key is needed to access data packages through the OS Downloads API. An API key is not required to access OpenData.
            </Typography>
            <Typography variant='body1' paragraph={true}>
                You may wish to read the <InternalLink path={routes.supportDownload} message="download support documentation" /> before working with data packages.
            </Typography>

            <Typography variant='h1' component='h2'>Instructions to Generate a Project API Key and URL</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>The following instructions describe how to obtain the API service URL and to generate an API key:</p>
                <ol>
                    <li>Click this link to open the <ExternalLink
                        type="generic"
                        href="/"
                        message="OS Data Hub"
                    /> in a new tab.</li>
                    {mobile &&
                    <li>Click the navigation menu button.</li>
                    }
                    <li>Click "API Dashboard" in the {!mobile && 'navigation'} menu.</li>
                    <li>Click "APIs" in the {!mobile && 'secondary navigation'} menu.</li>
                    <li>Click "Add to API project" next to the OS Downloads API.</li>
                    <li>If you already have a project you may choose to add the OS Downloads API into that project,
                        or
                        alternatively Select "Add to NEW PROJECT".
                        <p>The next screen will contain the Project API Key and the API Endpoint address (API URL).</p>
                        <p>You can return to this screen by clicking "My projects" at any point in the future if you
                            need to copy your API key or the API address, or if you need to regenerate your API
                            key.</p>
                    </li>
                    <li>Keep this page open as you'll need the URL and key when you access the OS Downloads API.
                    </li>
                </ol>
            </Typography>

            <Typography variant='h1' component='h2'>
                Downloading OpenData
            </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>These instructions are generic and are intended to explain the necessary steps. Please see the worked code example for one possible implementation.</p>

                <ol>
                    <li>
                        Call the <InlineCodeSnippet>/products</InlineCodeSnippet> endpoint to obtain a list of products and their associated IDs.
                        <p>Similar to a <InternalLink path={routes.openDataDownloads} message="manual download" /> we first need to discover what is available. Be mindful that list items can change.</p>
                    </li>
                    <li>
                        Interrogate the JSON response to identify the dataset you are interested in.
                        <p>Each entry in the JSON response provides the Name, Description, Release Version, Download URL and Product ID.</p>
                        <p>You may at this point wish to create a loop that iterates through all available datasets or a subset that you are interested in based on common attributes (e.g. Vector data in a certain format).</p>
                        <p>You could store the product id and version. That way you can easily check periodically for new versions of the data being available, simply by comparing the version we provide with the one you hold.</p>
                    </li>
                    <li>
                        Using the product id from the previous response make a call to the <InlineCodeSnippet>{'/products/{productId}/downloads'}</InlineCodeSnippet> endpoint.
                        <p>This will result in a list of all available download links. This may be a single link for some datasets or a set of links split by area covered and/or formats supplied.</p>
                        <p>Once you obtain the download link for the area of the dataset in the format you require you are ready to download the data, ready for automated processing by whichever means your system requires.</p>
                    </li>
                </ol>
            </Typography>
            <Typography variant='h2' component='h3'>
                Sample implementation using Node.js
            </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>This example relies on a working installation of <ExternalLink
                    type="generic"
                    href="https://nodejs.org"
                    message="Node.js"
                /> and the popular <ExternalLink
                    type="generic"
                    href="https://github.com/axios/axios"
                    message="axios"
                /> module.</p>
                <ol>
                    <li>
                    Get the list of products available including their basic information such as their product id and version.

                    <CodeSnippet>{`const axios = require('axios');

async function getList() {
    const productList = await axios('https://api.os.uk/downloads/v1/products');
    /* For explanation and debugging purposes we display the full response from the API in the console */
    console.log(productList.data)
    for (const product of productList.data) {
        /* This will print out the product ID which can be used in another request. This would be the ideal place
           to call another function which continues the processing or filters down the results to the set required. */
        console.log(product.id)
    }
}
getList()`}
                    </CodeSnippet>
                    </li>
                    <li>
                    Obtain information for a specific product.

                    <p>While the key parts of this are already provided in step one, this shows another way of getting to specific detail.</p>
                    <CodeSnippet>{`const axios = require('axios');

/* This function will return the specific information on Open Greenspace */
async function getProductDetails() {
    const greenspaceDetails = await axios('https://api.os.uk/downloads/v1/products/OpenGreenspace');
    console.log(greenspaceDetails.data)
    /* At this point we could insert another function to process the results or act on them/download them */
}
getProductDetails()`}</CodeSnippet>
                    </li>
                    <li>
                        Download the data.

                        <p>
                            In this example we are hard coding the product and format we are interested in (OpenGreenspace in ESRI® Shapefile).
                            This can also be provided dynamically into the function allowing the same function to be used for multiple products and formats.
                        </p>
                        <CodeSnippet>{`const fs = require('fs');
const axios = require('axios');

/* ============================================================
Function: Uses Axios to download file as stream using Promise
============================================================ */
const download_file = (url, filename) =>
    axios({
        url,
        responseType: 'stream'
    }).then(
        response =>
            new Promise((resolve, reject) => {
                response.data
                    .pipe(fs.createWriteStream(filename))
                    .on('finish', () => resolve())
                    .on('error', e => reject(e));
            }
    )
);

/* ============================================================
Download Files in Order
============================================================ */
async function downloadFiles() {
    try {
        const downloadInfo = await axios.get('https://api.os.uk/downloads/v1/products/OpenGreenspace/downloads')
        for (const download of downloadInfo.data) {
            if(download.area !== 'GB' && download.format === 'ESRI® Shapefile') {
                let downloadFile = await download_file(download.url, \`$\{download.area}.zip\`);
                console.log(\`Downloaded file $\{download.area}\`)
            }
        }
        console.log('Completed downloading files')
    } catch (error) {
        console.error(error);
    }
}

downloadFiles()`}</CodeSnippet>
                    </li>
                </ol>
            </Typography>

            <Typography variant='h1' component='h2'>
                Downloading data packages
            </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>These instructions are generic and are intended to explain the necessary steps. Please see the worked code example for one possible implementation.</p>

                <ol>
                    <li>
                        Call the <InlineCodeSnippet>/dataPackages</InlineCodeSnippet> endpoint to obtain a list of data packages and their associated IDs.
                        <p>Similar to a <InternalLink path={routes.dataPackages} message="manual download" /> we first need to discover which data packages are available. Be mindful that list items can change.</p>
                    </li>
                    <li>
                        Interrogate the JSON response to identify the data package you are interested in.
                        <p>Each entry in the JSON response provides data package metadata, as well as information about available versions of the data package.</p>
                        <p>You may at this point wish to create a loop that iterates through all available data packages or a subset that you are interested in based on common attributes (e.g. Vector data in a certain format).</p>
                        <p>You could store the data package id and version ids. That way you can easily check periodically for new versions of the data package being available, simply by comparing the versions we provide with the ones that you hold.</p>
                    </li>
                    <li>
                        Using the data package id from the previous response, make a call to the <InlineCodeSnippet>{'/dataPackages/{dataPackageId}/versions/latest'}</InlineCodeSnippet> endpoint.
                        <p>This will return information about the most recent data package version, including a list of the files that are available to download.</p>
                        <p>Once you obtain the download links for the data package version you are ready to download the data.</p>
                    </li>
                </ol>
            </Typography>
            <Typography variant='h2' component='h3'>
                Sample implementation using Node.js
            </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>This example relies on a working installation of <ExternalLink
                    type="generic"
                    href="https://nodejs.org"
                    message="Node.js"
                /> and the popular <ExternalLink
                    type="generic"
                    href="https://github.com/axios/axios"
                    message="axios"
                /> module.</p>
                <ol>
                    <li>
                        Get the list of available data packages including basic information such as their product id and versions.

                        <CodeSnippet>{`const axios = require('axios');
const apiKey = 'Insert API key here';

async function getList() {
    const dataPackageList = await axios({
        url: 'https://api.os.uk/downloads/v1/dataPackages',
        headers: {
            key: apiKey
        }
    });
    /* For explanation and debugging purposes we display the full response from the API in the console */
    console.log(dataPackageList.data);
    for (const dataPackage of dataPackageList.data) {
        /* This will print out the url of the data package id which can be used in another request. This would be the
           ideal place to call another function which continues the processing or filters down the results to the set required. */
        console.log(dataPackage.url);
    }
}
getList();`}
                        </CodeSnippet>
                    </li>
                    <li>
                        Obtain information for a specific data package.

                        <p>While the key parts of this are already provided in step one, this shows another way of getting to specific data package details.</p>
                        <CodeSnippet>{`const axios = require('axios');

/* This function will return information about a specific data package.
   You will need to insert both a dataPackageId and API key to complete the implementation. */
const apiKey = 'Insert API key here';
const dataPackageId = 'Insert data package id here';

async function getDataPackageDetails() {
    const dataPackageDetails = await axios({
        url: 'https://api.os.uk/downloads/v1/dataPackages/' + dataPackageId,
        headers: {
            key: apiKey
        }
    });
    console.log(dataPackageDetails.data);
    /* At this point we could insert another function to process the results or act on them/download them */
}
getDataPackageDetails();`}</CodeSnippet>
                    </li>
                    <li>
                        Download the data.

                        <p>
                            In this example we are hard coding the data package id that we are interested in.
                            This can also be provided dynamically into the function allowing the same function to be used for multiple products and formats.
                        </p>
                        <CodeSnippet>{`const fs = require('fs');
const axios = require('axios');

const apiKey = 'Insert API key here';
const dataPackageId = 'Insert data package id here';

/* ============================================================
Function: Uses Axios to download file as stream using Promise
============================================================ */
const download_file = (url, fileName) =>
    axios({
        url,
        headers: {
            key: apiKey
        },
        responseType: 'stream'
    }).then(
        response =>
            new Promise((resolve, reject) => {
                response.data
                    .pipe(fs.createWriteStream(fileName))
                    .on('finish', () => resolve())
                    .on('error', e => reject(e));
            }
    )
);

/* ============================================================
Download all of the files in the latest data package version
============================================================ */
async function downloadFiles() {
    try {
        const downloadInfo = await axios({
            url: 'https://api.os.uk/downloads/v1/dataPackages/' + dataPackageId + '/versions/latest',
            headers: {
                key: apiKey
            }
        });
        for (const download of downloadInfo.data.downloads) {
            let downloadFile = await download_file(download.url, download.fileName);
            console.log(\`Downloaded file $\{download.fileName}\`);
        }
        console.log('Completed downloading files');
    } catch (error) {
        console.error(error);
    }
}

downloadFiles();`}</CodeSnippet>
                    </li>
                </ol>
            </Typography>

            <DocLinks product='downloads' hasTechnicalSpec={true}/>
        </Content>
    </Document>
}
