import React from "react";
import Header from "../Header";
import Content from "../Content";
import Typography from "@mui/material/Typography";
import corpVidImg from "./brandLogoImg/OS_guidelines_Corporate-video-image@2x.png";
import withStyles from "react-jss";
import BrandImage from "./BrandImage";
import { styles } from "./BrandStyling";
import { defineMessages, FormattedMessage } from "react-intl";
import classNames from "classnames";
import PropTypes from "prop-types";
import Document from "../Document";
import { ExternalLink } from "omse-components";

const messages = defineMessages({
    intro: {
        id: "BrandImportance.intro",
        defaultMessage:
            "It is important that our brand is applied correctly and consistently by you and adheres to the terms and conditions of our API solutions. It is also imperative that the nature of our roles are correctly communicated through the application of our logo and the correct explanation of our relationship.",
        description: "Importance of using brand correctly",
    },
    document: {
        id: "BrandImportance.document",
        defaultMessage:
            "This document provides guidance for you, as our API solution users, on the application of our branding. For these applications the API logo is used within our APIs only.",
        description: "Document purpose introduction",
    },
    title: {
        id: "BrandImportance.title",
        defaultMessage: "Why is this important to us?",
        description: "Why is this important title",
    },
    brand: {
        id: "BrandImportance.brand",
        defaultMessage:
            "Our brand is our personality, it represents who we are, so it’s extremely important that we protect our credibility and reputation. If you are using our API solutions it’s important that our brand is applied correctly. Through following these guidelines and applying the correct logo to our API solutions we can ensure that our brand is being represented correctly.",
        description: "Information about our brand",
    },
    contactParagraph: {
        id: "BrandImportance.contactParagraph",
        defaultMessage:
            "If you have any further questions about the use of our brand on your applications please ",
        description: "Contact brand team",
    },
    helpfulLinks: {
        id: "BrandImportance.helpfulLinks",
        defaultMessage: "Helpful links:",
        description: "Helpful links",
    },
    githubLink: {
        id: "BrandImportance.githubLink",
        defaultMessage: "OS API Branding on GitHub",
        description: "Link to GitHub",
    },
    githubLinkDesc: {
        id: "BrandImportance.githubLinkDesc",
        defaultMessage:
            " - Branding for users developing solutions using OS data supplied via our APIs.",
        description: "Link to GitHub",
    },
    logoDownloadLink: {
        id: "BrandImportance.logoDownloadLink",
        defaultMessage: "OS API logo download (ZIP)",
        description: "API logo download",
    },
    logoDownloadDesc: {
        id: "BrandImportance.logoDownloadDesc",
        defaultMessage: " - Download the OS API logo from our Brand Portal.",
        description: "API logo download",
    },
});

function BrandImportance(props) {
    const { classes } = props;
    const containerClasses = [classes.mainContainer, classes.oneItem];
    const textClasses = [classes.oneItem, classes.list];

    return (
        <Document>
            <Header back={true}>The importance of our brand</Header>

            <Content>
                <div className={classNames(containerClasses)}>
                    <BrandImage
                        image={corpVidImg}
                        alt="Decorative small-scale map of unknown region with purple and yellow hues"
                    />
                </div>

                <Typography
                    variant="body1"
                    paragraph={true}
                    component="div"
                    color="textPrimary"
                    className={classNames(textClasses)}
                >
                    <p>
                        <FormattedMessage {...messages.intro} />
                    </p>
                    <p>
                        <FormattedMessage {...messages.document} />
                    </p>
                    <p>
                        <strong>
                            <FormattedMessage {...messages.title} />
                        </strong>
                    </p>
                    <p>
                        <FormattedMessage {...messages.brand} />
                    </p>
                    <p>
                        <FormattedMessage {...messages.contactParagraph} />
                        <ExternalLink type="support" />
                    </p>

                    <p>
                        <strong>
                            <FormattedMessage {...messages.helpfulLinks} />
                        </strong>
                    </p>
                    <ul>
                        <li>
                            <ExternalLink
                                type="generic"
                                href="https://github.com/OrdnanceSurvey/os-api-branding"
                                message={messages.githubLink}
                            />
                            <FormattedMessage {...messages.githubLinkDesc} />
                        </li>
                        <li>
                            <ExternalLink
                                type="download"
                                href="/brandResources/os-maps-logo-maps.zip"
                                message={messages.logoDownloadLink}
                            />
                            <FormattedMessage {...messages.logoDownloadDesc} />
                        </li>
                    </ul>
                </Typography>
            </Content>
        </Document>
    );
}

BrandImportance.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BrandImportance);
