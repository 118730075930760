import React from 'react';
import Header from "../Header";
import Content from '../Content';
import Typography from '@mui/material/Typography';
import DocLinks from "../DocLinks";
import Document from '../Document';

export default function DownloadsOverview(props) {
    return <Document>
        <Header back={true}>OS Downloads API: Overview</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The OS Downloads API is a service that lets you automate the discovery and download of OpenData and data packages.
                    In doing so it improves the accessibility of OS data.
                </p>
                <p>You can:</p>
                <ul>
                    <li>Discover which OpenData products are available</li>
                    <li>Discover which data packages are available</li>
                    <li>Automate the download of OpenData and data packages</li>
                    <li>Request various coverage areas depending on the OpenData dataset</li>
                    <li>Request various formats depending on the OpenData dataset</li>
                    <li>Request metadata on available OpenData datasets including thumbnail images</li>
                </ul>
                <p>You can't:</p>
                <ul>
                    <li>Access previous releases of OpenData datasets</li>
                    <li>Create new data packages</li>
                    <li>Request alterations to existing data packages</li>
                </ul>
            </Typography>
            <DocLinks product='downloads' hasGettingStarted={true} hasTechnicalSpec={true}/>
        </Content>
    </Document>
}
