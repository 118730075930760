import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import DownloadImageModal from "./DownloadImageModal";
import SwipeableViews from 'react-swipeable-views';
import {ENTER} from '../../../constants/keys';
import {injectIntl} from 'react-intl';

const styles = theme => ({
    productImgContainer: {
        flex: '0 0 auto',
        width: 196,
        height: 196,
        overflow: 'hidden',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    productImg: {
        cursor: 'zoom-in',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'auto'
        }
    }
});

export class DownloadImage extends Component {

    state = {
        open: -1,
        index: 0
    };

    setupOpen = index => () => {
        this.setState({
            open: index
        });
    }

    close = () => {
        this.setState({
            open: -1
        });
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        const {open, index} = this.state;
        const {fixedIndex, images, animate, classes, productName} = this.props;

        if(animate && !this.timer) {
            this.timer = setInterval(() => {
                const {images} = this.props;
                let newIndex = (this.state.index + 1) % images.length;
                this.setState({index: newIndex});
            }, 5000);
        }
        if(!animate && this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }

        let content;
        if(fixedIndex !== -1) {
            content = this.setupImage(fixedIndex);
        } else {
            let imgArray = [];
            for (let i = 0; i < images.length; i++) {
                imgArray.push(this.setupImage(i));
            }
            content = <SwipeableViews disabled={true} index={index} slideStyle={{overflow: 'hidden'}}>
                {imgArray}
            </SwipeableViews>
        }

        return <Fragment>
            {open !== -1 &&
                <DownloadImageModal images={images} initialIndex={open} close={this.close} productName={productName}/>
            }
            <div className={classes.productImgContainer} 
                tabIndex='0'
                onKeyDown={e => {
                    if (e.key === ENTER) {
                        this.setState({open: 0});
                    }
                }
            }>
                {content}
            </div>
        </Fragment>

    }

    setupImage(index) {
        const {images, classes, productName} = this.props;
        const myUrl = images[index].small;
        const myOpen = this.setupOpen(index);
        return <img key={index}
                    src={myUrl}
                    height={196}
                    className={classes.productImg}
                    alt={`Example product: ${productName} ${index + 1}`}
                    onClick={myOpen}
                    onKeyPress={myOpen} />
    }
}

DownloadImage.propTypes = {
    classes: PropTypes.object.isRequired,
    images: PropTypes.array.isRequired,
    fixedIndex: PropTypes.number.isRequired,
    productName: PropTypes.string
};

DownloadImage.defaultProps = {
    fixedIndex: -1
};

export default withStyles(styles)(injectIntl(DownloadImage));
