import {contentPadding, osColour} from 'omse-components';

const style = theme => ({
    content: {
        flex: '1 1 auto',
        paddingLeft: contentPadding.left,
        paddingRight: contentPadding.right,
        paddingBottom: contentPadding.bottom,
        maxWidth: contentPadding.maxWidth,
        marginTop: theme.spacing(8),
        '& h2, h4': {
            color: osColour.neutral.charcoal
        },
        '& h2': {
            marginBottom: theme.spacing(3)
        },
        '& p': {
            marginTop: theme.spacing(1),
            color: osColour.neutral.charcoal
        },
        '& ul': {
            listStyleType: 'none',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(5),
            paddingLeft: 0,
            '& li h2': {
                marginTop: theme.spacing(5)
            }
        },
        '& li': {
            marginBottom: theme.spacing(1.5),
            color: osColour.neutral.charcoal
        },
        '& li table': {
            marginTop: theme.spacing(1.5)
        },
        '& ol': {
            color: osColour.neutral.charcoal
        },
        '& a': {
            fontSize: '1.125rem'
        },
        '& ul$iconList': {
            paddingLeft: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 0
            }
        },
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile,
            margin: 0
        }
    },
    iconList: {
        '& li': {
            display: 'flex',
        },
        '& li > span': {
            display: 'block'
        },
        '& li svg': {
            marginRight: theme.spacing(1),
            flex: '0 0 auto'
        }
    },
    success: {
        color: osColour.neutral.charcoal
    },
    warning: {
        color: osColour.neutral.charcoal
    },
    error: {
        color: osColour.neutral.charcoal
    },
    osAPITermHeading: {
        marginTop: theme.spacing(7.5)
    },
    listItemStyle: {
        marginLeft: theme.spacing(5)
    },
    numberedList: {
        '& ol': {
            counterReset: 'li'
        },
        '& li': {
            display: 'block'
        },
        '& ol li:before': {
            content: 'counters(li, ".") " "',
            counterIncrement: 'li',
            fontWeight: 'bold',
            paddingRight: theme.spacing(1)
        }
    },
    alphabeticList: {
        '& ol li:before': {
            content: 'none',
            counterIncrement: ''
        },
        '& li':{
            display: 'list-item'
        },
        '& li:before': {
            content: 'none !important'
        }
    },
    bulletedList: {
        listStyleType: 'disc'
    },
    appendixTable: {
        border: 'none',
        borderCollapse: 'collapse',
        '& td': {
            padding: theme.spacing(1.5, 1.5)
        },
        '& tbody': {
            verticalAlign: 'baseline'
        }
    },
    styleListItems: {
        display:'list-item'
    },
    table: {
        border: 'none',
        borderCollapse: 'collapse',
        '& tbody': {
            verticalAlign: 'baseline'
        },
        '& tr': {
            borderBottom: '1px solid ' + osColour.neutral.mist,
            color: osColour.neutral.charcoal
        },
        '& th': {
            fontWeight: 'normal',
            textAlign: 'left',
            padding: theme.spacing(1.5, 1.5)
        },
        '& td': {
            padding: theme.spacing(1.5, 1.5),
            color: osColour.neutral.charcoal,
            [theme.breakpoints.down('sm')]: {
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                display: 'block'
            }
        },
        '& p': {
            marginTop: 0
        },
        '& p:last-of-type': {
            marginBottom: 0
        },
        styleListItems: {
            display:'list-item'
        }
    },
    loading: {
        marginTop: theme.spacing(3)
    }
});

export default style;

export const headingStyles = theme => ({
    osAPITermHeading: {
        marginTop: theme.spacing(7.5),
        whiteSpace: 'pre'
    }
});