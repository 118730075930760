import { defineMessages } from "react-intl";

export const apiProjectMessages = defineMessages({
    emptyMessage: {
        id: 'WorkspaceAPIProjects.emptyMessage',
        defaultMessage: "This is where you can view API projects you've created",
        description: 'Empty message'
    },
    createProjectButton: {
        id: 'WorkspaceAPIProjects.createProjectButton',
        defaultMessage: 'Create your first API project',
        description: 'Create button text'
    }
});