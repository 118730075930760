import {osColour} from 'omse-components';

export default function supportStyles(theme) {
    return {
        support: {            
            color: osColour.neutral.charcoal,
            '& h3': {
                lineHeight: 1.38
            },
            '& h2': {
                marginBottom: theme.spacing(2.5)
            },
            '& ul': {
                listStyleType: 'disc'
            },
            '& ol': {
                padding: '0 ' + theme.spacing(3.5),
                '& li': {
                    marginBottom: theme.spacing(1),
                    '& a': {
                        display: 'inline'
                    }
                }
            },
            '& img': {
                boxShadow: '0 4px 8px 2px rgba(0, 0, 0, 0.2)',
                margin: '20px 0',
                display: 'block'
            }
        },
        faqs: {
            display: 'flex',
            marginBottom: theme.spacing(4.5),
            marginTop: theme.spacing(3),
            '& ul': {
                padding: 0
            },
            '& h3': {
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(2)
                
            },
            '& h3:first-of-type': {
                marginTop: 0
            }
        },
        item: {
            padding:0
        },
        divider: {
            height: 1,
            width: '100%',
            borderTop: `solid ${osColour.neutral.mist} 1px`,
            marginBottom: theme.spacing(3)
        },
        center: {
            textAlign: 'center'
        },
        table: {
            border: 'none',
            borderCollapse: 'collapse',
            '& th': {
                fontWeight: 600,
                color: osColour.primary.berry,
                backgroundColor: 'white',
                borderBottom: '2px solid ' + osColour.primary.berry,
                padding: theme.spacing(2),
                textAlign: 'left',
                verticalAlign: 'bottom',
                [theme.breakpoints.up('lg')]: {
                    whiteSpace: 'nowrap'
                }
            },
            '& tr': {
                borderBottom: '1px solid ' + osColour.neutral.mist
            },
            '& td': {
                padding: theme.spacing(1, 2)
            },
            '& p': {
                marginTop: 0
            },
            '& p:last-of-type': {
                marginBottom: 0
            },
            '& h6': {
                fontSize: '1.125rem'
            }
        },
        downloadButtonImageStyle: {
            maxWidth: 150,
            width: '30%',
            height: "auto",
            margin: '4px 8px',
            verticalAlign: 'middle',
            display: 'inline'
        },
        downloadImgMinWidth: {
            minWidth: '90%'
        },
        downloadImgWidth: {
            width: '80%'
        }
    }
}