import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import CircularProgress from '@mui/material/CircularProgress';
import {defineMessages, FormattedMessage, FormattedRelativeTime} from 'react-intl';
import {osColour} from 'omse-components';

const messages = defineMessages({
    unavailable: {
        id: 'StateTimestamp.unavailable',
        defaultMessage: '(Statistics are not available)',
        description: 'Message shown when statistics are not available'
    },
    generating: {
        id: 'StateTimestamp.generating',
        defaultMessage: '(Generating statistics)',
        description: 'Message shown when statistics are first being generated'
    },
    updating: {
        id: 'StateTimestamp.updating',
        defaultMessage: '(Statistics generated {when}, updating)',
        description: 'Message shown when old stats have been received'
    },
    timestamp: {
        id: 'StateTimestamp.timestamp',
        defaultMessage: '(Statistics generated {when})',
        description: 'Message shown when stats have been received'
    }
});

const styles = theme => ({
    root: {
        display: 'flex'
    },
    text: {
        color: osColour.neutral.stone,
        display: 'flex',
        flexFlow: 'column-reverse',
        marginBottom: theme.spacing(1/3)
    },
    spinner: {
        marginLeft: theme.spacing(1),
        color: osColour.neutral.stone,
    }
})

function render(props) {
    const {stats, classes} = props;

    let message;
    let spinner = <CircularProgress size={18} className={classes.spinner}/>;
    if(stats && stats.error) {
        message = <FormattedMessage {...messages.unavailable}/>;
        spinner = null;
    } else if(!stats.result || !stats.result.timestamp) {
        message = <FormattedMessage {...messages.generating}/>;
    } else if(stats.result.updating) {
        message = <FormattedMessage {...messages.updating} values={{
            when: <FormattedRelativeTime value={(stats.result.timestamp - Date.now()) / 1000} updateIntervalInSeconds={10}/>
        }}/>;
    } else {
        message = <FormattedMessage {...messages.timestamp} values={{
            when: <FormattedRelativeTime value={(stats.result.timestamp - Date.now()) / 1000} updateIntervalInSeconds={10}/>
        }}/>;
        spinner = null;
    }

    return <div className={classes.root} aria-live='off'>
        <Typography variant='body1' className={classes.text}>{message}</Typography>
        {spinner}
    </div>;
}

render.propTypes = {
    stats: PropTypes.object,
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(render);
