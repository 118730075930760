import React from 'react';
import {useSelector} from 'react-redux';
import {createUseStyles} from 'react-jss';
import classNames from 'classnames';
import {osColour} from 'omse-components';
import {Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';

const styles = createUseStyles(theme => ({
    tooltip:{
        position: 'absolute',
        paddingLeft: theme.spacing(2),
        top: 57,        // Matches the height of the toolbar + border
        left: 0,
        right: 0,
        zIndex: 1,      // Matches the rest of the toolbar
        backgroundColor: osColour.neutral.clouds
    },
    activeTooltip: {
        backgroundColor: osColour.primary.berry,
        color: 'white'
    }
}));

export default function ToolbarTooltip() {
    const message = useSelector(state => state.toolbar.tooltip);
    const tool = useSelector(state => state.toolbar.activeTool);
    const classes = styles();

    let tooltipToShow = tool.tooltip || {};
    let active = true;
    if(message.id && message.id !== tooltipToShow.id) {
        tooltipToShow = message;
        active = false;
    }

    if(!tooltipToShow.id) {
        return null;
    }

    const tooltipClasses = classNames({
        [classes.tooltip]: true,
        [classes.activeTooltip]: active
    });

    return <Typography component='div'
                       variant='body1'
                       id='tooltip'
                       className={tooltipClasses}>
        <FormattedMessage {...tooltipToShow}/>
    </Typography>;
}
