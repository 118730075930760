import React from 'react';
import {ReactComponent as ExpandIcon} from "../../../components/icons/expand-arrows.svg";
import Button from '@mui/material/Button';
import {defineMessages, FormattedMessage} from 'react-intl';
import {createUseStyles} from 'react-jss';
import {linkButton} from "omse-components";
import classNames from 'classnames';

const messages = defineMessages({
    expandMap: {
        id: 'DataPackageMap.expandMap',
        defaultMessage: 'Expand map',
        description: 'expand map button'
    }
});

const styles = createUseStyles({
    expandIcon: {
        paddingLeft: 4,
        paddingRight: 8,
        width: 24,
        height: 24,
    },
    expandButton: {
        width: '100%',
        justifyContent: 'left'
    }
});

const useLinkStyles = createUseStyles({linkButton});

export default function ExpandMapButton({onClick}) {
    const classes = styles();
    const classesLink = useLinkStyles();
    return <Button id="expandMapButton" onClick={() => onClick()} className={classNames(classesLink.linkButton, classes.expandButton)} disableRipple>
        <ExpandIcon className={classes.expandIcon}/>
        <FormattedMessage {...messages.expandMap}/>
    </Button>
}