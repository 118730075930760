import React from 'react';
import {CommonDialog} from "omse-components";
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {apiTermsName, downloadTermsName} from '../../../../shared/constants';
import PropTypes from 'prop-types';
import termsTitleMessages from '../TermsTitles';
import {productMessage} from '../../../util/terms';

const messages = defineMessages({
    title: {
        id: 'DisallowedTermsAcceptanceDialog.title',
        defaultMessage: 'Please contact your administrator',
        description: 'Dialog title'
    },
    body: {
        id: 'DisallowedTermsAcceptanceDialog.body',
        defaultMessage: 'To continue using our {product}, your organisation must accept the {terms}. Please contact your organisation’s administrator.',
        description: 'Dialog body'
    }
});

export default function DisallowedTermsAcceptanceDialog({variant, handleClose}) {
    const intl = useIntl();
    const product = intl.formatMessage(productMessage(variant));
    const terms = intl.formatMessage(termsTitleMessages[variant]);
    return <CommonDialog title={messages.title} onClose={handleClose}>
        <FormattedMessage {...messages.body} values={{product, terms}} />
    </CommonDialog>;
}

DisallowedTermsAcceptanceDialog.propTypes = {
    variant: PropTypes.oneOf([apiTermsName, downloadTermsName]),
    handleClose: PropTypes.func.isRequired
}

DisallowedTermsAcceptanceDialog.defaultProps = {
    variant: apiTermsName
}