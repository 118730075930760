import {createActionReducer, createActionMiddleware} from 'omse-components';
import {REGENERATE_KEY_ACTION, LOADED_PROJECT_ACTION} from "./actions";
import {withOrganisation} from '../../util/organisation';

export const reducer = createActionReducer(REGENERATE_KEY_ACTION);

function setupCall(action, store) {
    return withOrganisation({
        url: '/api/projects/' + action.id + '/regenerateKey',
        method: 'POST',
        body: {}       
    }, store);
}
function success(store, action, response) {
    // Now that the project has been updated, put it back into the store
    const { result } = store.getState().project.current;
    if(result && result.id === action.id) {
        const newResult = {
            ...result,
            apiKey: response.apiKey,
            apiSecret: response.apiSecret,
            products: response.products
        };
        store.dispatch({
            type: LOADED_PROJECT_ACTION,
            result: newResult
        });
    }
}

export const middleware = createActionMiddleware(REGENERATE_KEY_ACTION, setupCall, success);
