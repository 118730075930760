import React, {Fragment, useEffect} from 'react';
import {CircularProgress, Typography} from '@mui/material';
import {defineMessages, FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import {loadPartnerContractCatalogue, loadPartnerContracts} from '../modules/partner/actions';
import {useDispatch, useSelector} from 'react-redux';
import Link from "./Link";
import routePaths, {matchRoute} from '../util/routes';
import {createUseStyles} from 'react-jss';
import { contentPadding, osColour, ExternalLink } from 'omse-components';
import {ReactComponent as Icon} from './icons/licence.svg';
import {useLocation} from 'react-router';
import usePartnerContracts from "../hooks/usePartnerContracts";
import {NOT_NEEDED, LOADING, ERROR} from '../constants/state';
import { hasPartnerCatalogue } from "../util/permissions";
import classNames from 'classnames';
import {NGD_PRODUCT_ID, NGDAD_PRODUCT_ID} from "../../shared/constants";

const messages = defineMessages({
    heading: {
        id: 'PartnerLicences.heading',
        defaultMessage: 'LICENCE',
        description: 'Heading for the PartnerLicences component'
    },
    intro: {
        id: 'PartnerLicences.intro',
        defaultMessage: 'You have licensed this product for the following use cases: ',
        description: 'Intro for the PartnerLicences component'
    },
    none: {
        id: 'PartnerLicences.node',
        defaultMessage: 'You have not licensed this product for any use cases.',
        description: 'Intro for the PartnerLicences component'
    },
    link: {
        id: 'PartnerLicences.link',
        defaultMessage: 'Manage licensing options for this product',
        description: 'Link text for the PartnerLicences component'
    },
    error: {
        id: 'PartnerLicences.error',
        defaultMessage: 'There was a problem loading the licence state. Please retry and if the problem persists then {link}.',
        description: 'Link text for the PartnerLicences component'
    }
});

const useStyles = createUseStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),

        // Fit to the normal max width, allowing for the padding (2x2) and border (0.5) on this component
        maxWidth: `calc(${contentPadding.maxWidth}px - ${theme.spacing(4.5)})`,

        backgroundColor: osColour.neutral.clouds,
        borderLeft: theme.spacing(0.5) + ' solid ' + osColour.primary.berry,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingRight: theme.spacing(2)
    }
}));

export default function PartnerLicences(props) {
    const classes = useStyles(props);
    let {productId} = props;

    // The partner licences are all worked out using 'OSNGD', but the product id that is stored in S+B data
    // packages is 'NGDAD'. This forces the productId over to the one that we need to display the correct
    // partner licensing.
    if(productId === NGDAD_PRODUCT_ID) {
        productId = NGD_PRODUCT_ID;
    }

    const dispatch = useDispatch();
    const licences = usePartnerContracts(productId);
    const location = useLocation();
    const user = useSelector(state => state.user.current.result);

    useEffect(() => {
        if(hasPartnerCatalogue(user)) {
            dispatch(loadPartnerContracts());
            dispatch(loadPartnerContractCatalogue());
        }
    }, [dispatch, user]);

    let showLink = false;

    let content;
    switch(licences) {
        case ERROR:
            content = <Fragment>
                <Typography variant='body1' component='span'>
                    <FormattedMessage {...messages.error} values={{ link: <ExternalLink type='support' /> }} />
                </Typography>
            </Fragment>
            break;
        case LOADING:
            content = <CircularProgress size={24}/>;
            break;
        case NOT_NEEDED:
            // Avoid this widget entirely
            return null;
        default:
            // Not error, not loading, and we need the content
            if(licences.length) {
                content = <Fragment>
                    <Typography variant='body1' component='span'>
                        <FormattedMessage {...messages.intro}/>
                    </Typography>
                    <Typography variant='body2' component='span'>
                        {
                            licences.length && licences.join(', ') + '.'
                        }
                    </Typography>
                </Fragment>;

            } else {
                content = <Typography variant='body1' component='span'>
                    <FormattedMessage {...messages.none}/>
                </Typography>;
            }

            // Only show the link if we successfully loaded the catalogue, and we are on one of the correct pages
            const route = matchRoute(location.pathname);
            if(route.path === routePaths.premiumItem || route.path === routePaths.dataPackage) {
                showLink = true;
            }
    }

    return <div className={classNames(classes.root, props.classes.root)}>
        <div className={classes.icon}>
            <Icon alt=''/>
            <Typography color='primary' variant='caption'>
                <FormattedMessage {...messages.heading}/>
            </Typography>
        </div>
        <div id={"licenceBanner"}>
            <div>
                { content }
            </div>
            {
                showLink && <Link path={routePaths.manageLicences} state={{productId}}>
                    <FormattedMessage {...messages.link}/>
                </Link>
            }
        </div>
    </div>
}

PartnerLicences.propTypes = {
    productId: PropTypes.string
};

PartnerLicences.defaultProps = {
    classes: {}
};