import { CLOSE_MENU_ACTION, OPEN_NAV_ACTION, OPEN_ACCOUNT_MENU, setTabs, SET_TABS, RELOAD_TABS } from "./menu/actions";
import { modules } from 'omse-components';
import { getTabLabels } from "../util/menu";

export function menuReducer(state = { nav: false, account: false, tabs: [] }, action) {
    switch (action.type) {
        case (OPEN_NAV_ACTION):
            return ({ ...state, nav: true, account: false });
        case (CLOSE_MENU_ACTION):
            return ({ ...state, nav: false, account: false });
        case (OPEN_ACCOUNT_MENU):
            return ({ ...state, nav: false, account: true });
        case (SET_TABS):
            return ({ ...state, tabs: action.tabs });
        case (RELOAD_TABS):
            return ({ ...state });       
        default:
            return state;
    }
}

const { LOADED_USER_ACTION } = modules.actions.user;
const { LOADED_CONFIG_ACTION } = modules.actions.config;

export const menuMiddleware = store => next => action => {

    // Skip this middleware for other actions.
    const matchingAction = [LOADED_USER_ACTION, LOADED_CONFIG_ACTION, RELOAD_TABS].includes(action.type);
    if (!matchingAction) {
        return next(action);
    }

    let config, user;

    switch (action.type) {
        case LOADED_USER_ACTION:
            config = store.getState()?.config.current?.result;
            user = action.result;
            // If the user is not logged in, set the tabs. We specifically handle this case here because the LOADED_CONFIG_ACTION loads first, 
            // and doing so prevents default tabs from appearing before subsequently rendering the appropriate tabs for a logged-in user
            if(!user) {
                const tabs = getTabLabels(user, config);
                store.dispatch(setTabs(tabs));
            }
            if (config && user) {
                const tabs = getTabLabels(user, config);
                store.dispatch(setTabs(tabs));
            }
            break;
        case LOADED_CONFIG_ACTION:
            config = action.result;
            user = store.getState()?.user.current?.result;
            if (config && user) {
                const tabs = getTabLabels(user, config);
                store.dispatch(setTabs(tabs));
            }
            break;
        case RELOAD_TABS:
            config = store.getState()?.config.current?.result;
            user = store.getState()?.user.current?.result;
            if (config && user) {
                const tabs = getTabLabels(user, config);
                store.dispatch(setTabs(tabs));
            }
            break;
        default:
    }
    return next(action);
};