import React from 'react';
import {defineMessages, FormattedMessage} from "react-intl";
import {Typography} from '@mui/material';
import {ThemeTreeView} from 'omse-components';
import useRecipe from "../../../hooks/useRecipe";

const messages = defineMessages({
    label: {
        id: 'ThemeTree.label',
        defaultMessage: 'Recipe:',
        description: 'Label for recipe theme tree.'
    }
});

export default function ThemeTree({onItemSelected, buildAction, showVersion}) {
    const {recipe} = useRecipe();

    if(!recipe) {
        return null;
    }
    const datasets = recipe.datasets;
    const heading = <Typography variant='body1' color='textSecondary'>
        <FormattedMessage {...messages.label}/>
    </Typography>
    return <ThemeTreeView datasets={datasets} onItemSelected={onItemSelected} heading={heading} buildAction={buildAction} showVersion={showVersion}/>
}

