import React from 'react';
import propTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';
import {CommonDialog, CommonDialogActions} from "omse-components";

/**
 * This dialog is popped when the user is changing schema version, and we need them to make a choice about their filter.
 */

const messages = defineMessages({
    title: {
        id: 'ChangingSchemaVersionDialog.title',
        defaultMessage: 'Remove feature type filter',
        description: 'Title for the dialog that pops up when changing schema version, if there are incompatible filters.'
    },
    discardFilter: {
        id: 'ChangingSchemaVersionDialog.discardFilter',
        defaultMessage: 'Remove filter',
        description: 'Label for the confirm button when there is a filter that must be discarded.'
    },
    unusableSavedFilter: {
        id: 'ChangingSchemaVersionDialog.unusableSavedFilter',
        defaultMessage: 'Your filter is not compatible with the new schema version. To change to the new schema version you must remove the filter.',
        description: 'Text shown when we have a saved filter, but it cannot be used with the new schema version'
    }
});

export default function ChangingSchemaVersionDialog({ onConfirm, onClose }) {
    const actions = <CommonDialogActions onClose={onClose}
                                         confirmLabel={messages.discardFilter}
                                         onConfirm={onConfirm}/>;

    return <CommonDialog onClose={onClose}
                         title={messages.title}
                         actions={actions}>
        <FormattedMessage {...messages.unusableSavedFilter}/>
    </CommonDialog>
}

ChangingSchemaVersionDialog.propTypes = {
    onConfirm: propTypes.func.isRequired,
    onClose: propTypes.func.isRequired
};
