import React, {useState, useEffect} from 'react';
import ContentHeader from '../../components/ContentHeader';
import { defineMessages, FormattedMessage } from "react-intl";
import routes, { commercialContactUs } from '../../util/routes';
import useHeading from "../../hooks/useHeading";
import FeatureCheck from "../../components/FeatureCheck";
import * as features from "../../../shared/features";
import { COM3_CATALOGUE } from "../../../shared/catalogues";
import { Box, Typography, Button, CircularProgress} from "@mui/material";
import {createUseStyles} from 'react-jss';
import CatalogueTag from '../../components/CatalogueTag';
import { contentPadding, osColour } from "omse-components";
import ApiPlanSlider, {CONTACT_US} from './apiPlanSlider/ApiPlanSlider';
import Link from '../../components/Link';
import OrderSummary from './OrderSummary/OrderSummary';
import ContactUs from './ContactUs/ContactUs';
import { useDispatch, useSelector } from "react-redux";
import {getApiProducts, purchaseApiPlan, getApiTiers} from "../../modules/commercialApiPlans/actions";
import ApiPriceTable from "./ApiPriceTable/ApiPriceTable";
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';

const messages = defineMessages({
    backLabel: {
        id: 'addApiPlans.add.backLabel',
        defaultMessage: 'API Dashboard',
        description: 'back route'
    },
    title: {
        id:'addApiPlans.add.title',
        defaultMessage:'My API Plan',
        description: 'Title of the API Plan page.'
    },
    subtitle: {
        id:'addApiPlans.add.subtitle',
        defaultMessage: 'Purchase Plan',
        description: "Subtitle on the purchase API plan page"
    },
    introHeading:{
        id:'addApiPlans.add.heading2',
        defaultMessage:"Purchase API transactions",
        description:"Smaller heading for the purchase api plan page"
    },
    introSubtitle:{
        id:"addApiPlans.intro.subtitle",
        defaultMessage: "Add additional API transactions to your account",
        description: "subtitle to the into section on the add api plan page"
    },
    introBody:{
        id:"addApiPlans.intro.body",
        defaultMessage:"Use the slider below to purchase additional transactions to use for this paid period",
        description:"The introduction body text to the slider component"
    },
    sliderBox: {
        id: "addApiPlans.sliderBox",
        defaultMessage: "If you require more transactions, please {link}.",
        description: "The content of the slider box info panel"
    },
    termsContainerTitle:{
        id:"addApiPlans.terms.title",
        description:"Terms title for the purchase API Plans page",
        defaultMessage: "Terms of service"
    },
    termsContainerBody:{
        id:"addApiPlans.terms.body",
        description:"Terms body for the purchase API Plans page",
        defaultMessage: "By using this product you agree to the terms and conditions of the following license:"
    },
    termsContainerLink:{
        id:"addApiPlans.terms.link",
        description:"Terms link for the purchase API Plans page",
        defaultMessage: "API terms and conditions"
    },
    osPlacesWarning: {
        id: "addApiPlans.osPlacesWarning",
        defaultMessage: "Contact us after adding the OS Places API to a project on your account. Get in touch to discuss your planned use of OS Places API, we will get you set up and ready for access"
    }
})

const useStyles = createUseStyles(theme => ({
    catalogueTag:{
        position: "absolute",
        right: theme.spacing(1),
        top:theme.spacing(1)
    },
    content: {
        position: "relative",
        paddingBottom: contentPadding.bottom,
        paddingLeft: contentPadding.left,
        paddingRight: contentPadding.right,
        maxWidth: contentPadding.maxWidth,
        marginTop: contentPadding.top,
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile,
            paddingTop: 0,
            marginTop: 0
        }
    },

    tierDetail:{
    },
    controlsContainer:{
        padding: contentPadding.mobile,
        '& button':{
            margin:theme.spacing(1),
            marginLeft:0
        }
    },
    sliderBox:{
        position: "absolute",
        right:0,
        top:0,
        border: `1px solid ${osColour.primary.berry}`,
        maxWidth:200,
        padding:theme.spacing(1)
    },
    actionsContainer:{
        display:"flex",
        marginTop:theme.spacing(1),
        marginBottom:theme.spacing(1)
    },
    termsContainer: {
        "&>*:first-child":{
            marginTop:theme.spacing(1),
            marginBottom:theme.spacing(2)
        }
    }
}));

export default function AddApiPlan() {
    const title = useHeading() || messages.title;
    const classes = useStyles();
    const dispatch = useDispatch();

    const [sliderValue, setSliderValue] = useState(0);
    const [selectedTier, setSelectedTier] = useState(null);
    const [openConfirmationModal, setOpenModal] = useState(false);

    const purchase = useSelector(state => state.commercialApiPlans.purchase);
    const purchaseIsWorking = purchase.working;
    const purchaseHasError = !!purchase.error;

    const tiers = useSelector(state => state.commercialApiPlans.tiers);
    //this is loaded, need to consider a undefined state
    const products = useSelector(state => state.commercialApiPlans.products);
    //find the demo product
    const demoProduct = products.result?.find(product => product.demoProduct);


    const openModal = ()=>{
        setOpenModal(true);
    }

    const closeModal = ()=>{
        setOpenModal(false);
    }

    const handlePurchase = ()=>{
        dispatch(purchaseApiPlan(selectedTier.tierId, sliderValue));
    }

    useEffect(()=>{
        //this will fire on initialisation and when slidervalue is changed
        if(demoProduct && tiers.result && sliderValue !== CONTACT_US){
            const tierId = tiers.result.find(tier => {
                return sliderValue >= tier.lowerValue && sliderValue <= tier.upperValue;
            }).id;
            const selectTier = demoProduct.tiers.find(tier => tier.tierId === tierId);
            setSelectedTier(selectTier);
        }
    //eslint-disable-next-line
    }, [sliderValue, demoProduct]);

    useEffect(()=>{
        dispatch(getApiProducts());
        dispatch(getApiTiers());
    }, [dispatch]);

    let content = <CircularProgress />

    const noProducts = !products.loading && (products.error || !demoProduct);
    const noTiers = !tiers.loading && (!tiers.result || tiers.error);
    if(noProducts || noTiers){
        content = <>
            <Typography variant="h2">There has been an error</Typography>
            <Typography variant="body1">There has been an error loading the page. Please check again later.</Typography>
        </>
    }else if(!products.loading && demoProduct && !tiers.loading){
        let minTier, maxTier;
        tiers.result.forEach(tier => {
            if(!minTier || tier.lowerValue < minTier.lowerValue){
                minTier = tier;
            }

            if(!maxTier || tier.upperValue > maxTier.upperValue){
                maxTier = tier;
            }
        });

        const isContactUs = sliderValue === CONTACT_US;

        content = <>
            <Typography variant="h2">
                <FormattedMessage {...messages.introHeading}/>
            </Typography>
            <Typography variant="h3">
                <FormattedMessage {...messages.introSubtitle}/>
            </Typography>
            <Typography variant="body1">
                <FormattedMessage {...messages.introBody}/>
            </Typography>
            <ApiPlanSlider
                demoProduct={demoProduct}
                value={sliderValue}
                handleChange={setSliderValue}
                tiers={tiers.result}
            />

            {isContactUs ?
                (<>
                    <Box className={classes.sliderBox} data-testid="slider_contact_us">
                        <Typography variant="body1">
                            <FormattedMessage {...messages.sliderBox} values={{
                                link: <a href={commercialContactUs} target="_blank" rel="noreferrer">contact us</a>
                            }}/>
                        </Typography>
                    </Box>
                    <ContactUs/>
                </>) :
                <OrderSummary tier={selectedTier} apiName={demoProduct.name} value={sliderValue} tooltipBody={<ApiPriceTable showExtraInfo={false} products={products.result} tiers={tiers.result}/>}/>
            }
            <Box className={classes.controlsContainer}>
                <Typography variant="caption">Visit our <Link path={routes.pricingApiPlans}>API breakdown and pricing guide</Link> for a full list of APIs and their transaction / cost usage.</Typography>
                <Box className={classes.actionsContainer}>
                    <Link path={routes.dashboard}><Button variant="outlined">Cancel</Button></Link>
                    <Button variant="contained" color="primary" disabled={sliderValue <= minTier.lowerValue || isContactUs || selectedTier?.unitPrice === 0} onClick={openModal}>Confirm</Button>
                </Box>
                <Box className={classes.termsContainer}>
                    <Typography variant="body2">
                        <FormattedMessage {...messages.osPlacesWarning}/>
                    </Typography>
                    <Typography variant="subtitle2">
                        <FormattedMessage {...messages.termsContainerTitle}/>
                    </Typography>
                    <Typography variant="body1">
                        <FormattedMessage {...messages.termsContainerBody}/>
                    </Typography>
                    <Typography variant="body1">
                        <Link path={routes.apiTermsConditions}>
                            <FormattedMessage {...messages.termsContainerLink}/>
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </>
    }

    return (<FeatureCheck feature={features.EAIAPI}>
        <Box>
            <ContentHeader title={title} backPath={routes.dashboard} backLabel={messages.backLabel}>
                <Box className={classes.catalogueTag}>
                    <CatalogueTag type={COM3_CATALOGUE} size='md' />
                </Box>
            </ContentHeader>
            <Box className={classes.content}>
                {content}
                {openConfirmationModal &&
                    <ConfirmationModal onClose={closeModal}
                                       onConfirm={handlePurchase}
                                       value={sliderValue}
                                       tier={selectedTier}
                                       product={demoProduct}
                                       working={purchaseIsWorking}
                                       error={purchaseHasError}
                    />
                }
            </Box>
        </Box>
    </FeatureCheck>)
}
