import React, {Fragment, useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import routes, {privacyPolicyAddress} from '../../util/routes';
import FeatureCheck from "../../components/FeatureCheck";
import ContentHeader from '../../components/ContentHeader';
import {FormattedMessage, defineMessages, useIntl} from 'react-intl';
import {contentPadding, osColour, AddButton, Notification, StyledCheckbox} from 'omse-components';
import {createUseStyles} from 'react-jss';
import {useSelector, useDispatch} from 'react-redux';
import {getContactPreferences, setContactPreferences, clearSetContactPreferences} from '../../modules/account/actions';
import {FormGroup, CircularProgress} from '@mui/material';
import {cloneDeep} from 'lodash';
import {Prompt} from 'react-router';
import useHeading from '../../hooks/useHeading';

const messages = defineMessages({
	accountLink: {
		id: 'ContactPreferences.backLink',
		defaultMessage: 'Account settings',
		description: 'Label for backlink'
	},
	title: {
		id: 'ContactPreferences.title',
		defaultMessage: 'Contact preferences',
		description: 'Contact title'
	},
	intro: {
		id: 'ContactPreferences.intro',
		defaultMessage: 'All the information you provide will be used in accordance with our <a>privacy policy</a>.',
		description: 'Intro text'
	},
	marketingPrompt: {
		id: 'ContactPreferences.marketingPrompt',
		defaultMessage: 'Please choose from the options below if you would like to receive information about the products and services from Ordnance Survey Limited and its group of companies.',
		description: 'Text for marketing preferences list'
	},
	saveChanges: {
        id: 'ContactPreferences.saveChanges',
        defaultMessage: 'Save changes',
        description: 'Label for the Save changes button'
    },
    savingChanges: {
        id: 'ContactPreferences.savingChanges',
        defaultMessage: 'Saving changes',
        description: 'Label for button when saving changes'
    },
    saved: {
        id: 'ContactPreferences.saved',
        defaultMessage: 'Your changes have been saved.',
        description: 'Label for the saved changes notification'
    },
	unsaved: {
		id: 'ContactPreferences.unsaved',
        defaultMessage: 'You have unsaved changes. Are you sure you want to leave?',
        description: 'Label for the unsaved changes notification'
	},
	error: {
		id: 'ContactPreferences.error',
		defaultMessage: 'There was a problem saving your preferences. Please try again later.',
		description: 'Label for update error notification'
	},
	noPreference: {
		id: 'ContactPreferences.noPreference',
		defaultMessage: 'No preference',
		description: 'Label for no preference checkbox item'
	}
});

const useStyles = createUseStyles(theme => {
	return {
		content: {
			flex: '1 1 auto',
			paddingLeft: contentPadding.left,
			paddingRight: contentPadding.right,
			paddingBottom: contentPadding.bottom,
			maxWidth: contentPadding.maxWidth,
			marginTop: theme.spacing(8),
			[theme.breakpoints.down('sm')]: {
				padding: contentPadding.mobile,
				margin: 0
			},
			'& label': {
				color: osColour.neutral.charcoal
			},
			'& p, > div, h2': {
				marginBottom: theme.spacing(1)
			}
		},
		actionButton: {
			marginTop: theme.spacing(2),
			display: 'block'
		},
		checkboxIcon: {
			width: 16,
			height: 16
		}
	};
});

export default function ContactPreferences() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const intl = useIntl();
    const preferences = useSelector(state => state.account.contactPreferences);
	const update = useSelector(state => state.account.updateContactPreferences);
	const [changes, setChanges] = useState([]);
	const [notification, setNotification] = useState(null);
	const [toggle, setToggle] = useState(false);
	const [preferenceItems, setPreferenceItems] = useState([]);
	const [anyContactPref, setAnyContactPref] = useState(false);
	const typeContact = 'contact';
	const typeMarketing = 'marketing';

	useEffect(() => {
		dispatch(getContactPreferences());
		return function cleanup() {
			setNotification(null);
			dispatch(clearSetContactPreferences());
		}
	}, [dispatch]);

	useEffect(() => {
		if (update.result && update.result.length > 0 && !update.error) {
			setNotification(messages.saved);
			setChanges([]);
		}

		if (update.error) {
			setNotification(messages.error);
		}
	}, [update]);

	useEffect(() => {
		if (preferences.result) {
			const contactItems = cloneDeep(preferences.result.contact).map(i => {
				i.type = typeContact;
				return i;
			});
			const marketingItems = cloneDeep(preferences.result.marketing).map(i => {
				i.type = typeMarketing;
				return i;
			});

			if (contactItems.every(pref => pref.value === false) || contactItems.every(pref => pref.value === true)) {
				setAnyContactPref(true);
				contactItems.forEach(pref => pref.value = false);
			}

			const items = [...contactItems, ...marketingItems];
			setPreferenceItems(items);
		}
	}, [preferences]);


	function modifyChanges(name, value) {
		const modified = e => e.name === name && (e.value !== value);

		const existingChange = changes.find(e => e.name === name);
		const items = preferences.result.contact.concat(preferences.result.marketing);
		const itemModified = items.find(modified);
		if (itemModified) {
			if (existingChange) {
				existingChange.value = value;
			} else {
				changes.push({name, value});
			}

		// Item isn't modified but its included as an existing change, so remove it.
		} else {
			if (existingChange) {
				const newChanges = changes.filter(c => c.name !== existingChange.name);
				setChanges(newChanges);
			}
		}
	}

	const handleChange = (name, value) => {
		const modified = e => e.name === name && (e.value !== value);
		
		// Update state controlling the checkbox lists
		const itemToggled = preferenceItems.find(modified);
		if (itemToggled) {
			itemToggled.value = value;
		}

		// Update state used when saving form
		modifyChanges(name, value);

		// State change to ensure the UI is updated
		setToggle(!toggle);
	};

	const save = () => {
		if (changes.length > 0) {
			dispatch(setContactPreferences(changes));
		}
	};

	const acceptNotification = () => {
		setNotification(null);
	}

	function checkboxItem(pref, itemType) {
		const clickAction = () => {
			if (itemType === typeContact && anyContactPref) {
				setAnyContactPref(false);
				const contactPrefs = preferenceItems.filter(i => i.type === typeContact);
				contactPrefs.forEach(pref => modifyChanges(pref.name, false));
			}
			handleChange(pref.name, !pref.value, itemType);
		}
		return <StyledCheckbox key={pref.name} 
			classes={{icon: classes.checkboxIcon}}
			checked={pref.value} 
			onClick={clickAction} 
			label={{id: `item_${pref.name}`, defaultMessage: pref.name}} 
			disabled={update.working}
		/>
	}

	const marketingPrefs = preferenceItems.filter(i => i.type === typeMarketing);

	const title = useHeading() || messages.title;
	return (<FeatureCheck feature='contactPreferences'>
		<Prompt when={changes.length > 0} message={
			(location, action) => {
				if (action === 'PUSH') {
					return intl.formatMessage(messages.unsaved);
				}
				return true;
			}
		} />
		<ContentHeader
			backPath={routes.account} 
			backLabel={messages.accountLink} 
			title={title} />
		<div className={classes.content}>
			{notification &&
				<Notification variant={update.error? 'error':'success'}
							appearance='toast'
							autoClose={true}
							onClose={acceptNotification}>
					<Typography variant='body1'>
						<FormattedMessage {...notification} />
					</Typography>
				</Notification>
			}
			<Typography variant='body1'>
				<FormattedMessage {...messages.intro} values={{
						a: msg => <a href={privacyPolicyAddress} target="_blank" rel="noopener noreferrer">{msg}</a>
					}}
				/>
			</Typography>
			{!preferences.loading &&
				<Fragment>
					<Typography variant='body1'>
						<FormattedMessage {...messages.marketingPrompt} />
					</Typography>
					{(marketingPrefs.length > 0) && 
						<FormGroup>
							{marketingPrefs.map(pref => checkboxItem(pref, typeMarketing))}
						</FormGroup>
					}
				</Fragment>
			}
			{(preferences.loading || update.loading) &&
				<CircularProgress size={32} />
			}
			<AddButton
				classes={{button: classes.actionButton}}
				label={update.working? messages.savingChanges : messages.saveChanges}
				showIcon={false}
				working={update.working}
				action={save}
				disabled={changes.length === 0}
			/>
		</div>
	</FeatureCheck>);
}
