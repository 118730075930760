import React from 'react';
import NavButton from "../../../components/NavButton";
import routes from "../../../util/routes";
import {Typography} from "@mui/material";
import {defineMessages, FormattedMessage} from 'react-intl';
import {ReactComponent as SuccessIcon} from "../../../components/icons/success-green.svg";
import ExpandInfo from "./ExpandInfo";
import ExpandIntro from "./ExpandIntro";
import {osColour, border1} from 'omse-components';
import {createUseStyles} from 'react-jss';

const messages = defineMessages({
    preparingTitle: {
        id: 'ExpandDataPackage.preparingTitle',
        defaultMessage: 'Preparing expansion',
        description: 'preparing title'
    },
    thankYou: {
        id: 'ExpandDataPackage.thankYou',
        defaultMessage: 'Thank you.',
        description: 'thank you text'
    },
    weArePreparing: {
        id: 'ExpandDataPackage.weArePreparing',
        defaultMessage: "We're preparing your data. We'll email you when its ready.",
        description: 'we are preparing text'
    },
    backToPackage: {
        id: 'ExpandDataPackage.backToPackage',
        defaultMessage: "Back to package details",
        description: 'back button text'
    }
});

const useStyles = createUseStyles(theme => ({
    mainTitle: {
        paddingTop: theme.spacing(6),
        paddingLeft: theme.spacing(3),
        borderBottom: border1
    },
    titleText: {
        marginBottom: theme.spacing(3),
        color: osColour.primary.berry
    },
    successContainer: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));

export default function ExpandSuccessPanel(props) {
    const {dataPackage, label} = props;
    const classes = useStyles();
    return <>
        <div className={classes.mainTitle}>
            <Typography variant={'h1'} className={classes.titleText}>
                <FormattedMessage {...messages.preparingTitle}/>
            </Typography>
        </div>

        <ExpandIntro name={dataPackage.name} id={dataPackage.id} label={label} />

        <div className={classes.successContainer}>
            <SuccessIcon width={64} height={64}/>
            <Typography variant={'body1'} paragraph={true}>
                <FormattedMessage {...messages.thankYou} />
            </Typography>
            <Typography variant={'body1'} paragraph={true} align={'center'}>
                <FormattedMessage {...messages.weArePreparing} />
            </Typography>
        </div>

        <ExpandInfo dataPackage={dataPackage}/>

        <div className={classes.successContainer}>
            <NavButton path={routes.dataPackage.replace(":packageId", dataPackage.id)}
                       color='primary' variant='contained'>
                <FormattedMessage {...messages.backToPackage}/>
            </NavButton>
        </div>
    </>;
}