import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import PlanTag from "../../components/PlanTag";

const StyledHeading = styled(Typography)`
  font-size: 1.25em;
  font-weight: 600;
  margin-top: 16px;
`;

const StyledSubHeadingContainer = styled.div`
  margin-bottom: 36px;
`;

const HeadingOrgPlan = () => {
  const user = useSelector((state) => state.user.current.result);
  return (
    <>
      <StyledHeading variant={"h2"} sx={{ marginBottom: "12px" }}>
        {user?.org}
      </StyledHeading>
      <StyledSubHeadingContainer>
        <PlanTag type={user?.plan} size={"md"} />
      </StyledSubHeadingContainer>
    </>
  );
};

export default HeadingOrgPlan;
