import React from 'react';
import Header from "./Header";
import Content from './Content';
import figure1 from "./wfsOverview/Figure1.png";
import Typography from '@mui/material/Typography';
import UsingWFS from "./UsingWFS";
import DocLinks from "./DocLinks";
import Document from './Document';

export default function WFSOverview(props) {
    return <Document>
        <Header back={true}>OS Features API: Overview</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Use OS Features API to filter Ordnance Survey data and receive exactly what you need, 
                    as and when you need it. Buildings, roads, rivers, hospitals, playing fields, greenspaces 
                    – there are many data layers available. There are lots of options for applying spatial filters 
                    (e.g. Intersects), attribute filters and even combinations. For example, you can select all 
                    buildings within a specified area of interest (a polygon feature).
                </p>
                <p>
                    Benefit from current, detailed and accurate data to help you generate new location-based insight. 
                    OS Features API contains OS MasterMap Topography Layer and much more, and can remove the need to 
                    download, store and manage large and complex datasets.
                </p>
                <p>
                OS Features API gives you direct access to the detailed geometries and rich attribution of individual features. 
                It is based on the OGC standard Web Feature Service (WFS) so is interoperable with lots of GIS software and 
                web mapping libraries.
                </p>
            </Typography>
            <img src={figure1} alt='OS Features API: large-scale map of urban area with land plots highlighted'/>
            <UsingWFS/>
            <DocLinks product='wfs' hasGettingStarted={true} hasTechnicalSpec={true} hasDemo={true}/>
        </Content>
    </Document>
}
