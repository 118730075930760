const PREFIX = 'action:stats:';
export const PROJECT_STATS = PREFIX + 'project';
export const PROJECT_STATS_LOADED = PREFIX + 'projectLoaded';
export const USER_STATS = PREFIX + 'user';
export const USER_STATS_LOADED = PREFIX + 'userLoaded';
export const HISTORY_STATS = PREFIX + 'history';
export const HISTORY_STATS_LOADED = PREFIX + 'historyLoaded';

export function getProjectStats(projectId) {
    return {
        type: PROJECT_STATS,
        projectId
    }
}

export const USER_STATS_DETAIL_PRODUCT_TYPE = 'productType';
export const USER_STATS_DETAIL_PROJECT = 'project';
// We expect the detail parameter to be one of the above constants, or null
// The mode should be constants.LIVE_MODE or constants.DEV_MODE, or null
// Passing a includeType arg of true wil ensure the project type usage is always returned
export function getUserStats(detail, mode, orgId, includeType = false) {
    return {
        type: USER_STATS,
        detail,
        mode,
        orgId,
        includeType
    }
}


export const HISTORY_STATS_INTERVAL_WEEK = 'week';
export const HISTORY_STATS_INTERVAL_MONTH = 'month';
export const HISTORY_STATS_INTERVAL_YEAR = 'year';
export function getHistoryStats(interval, projectId, mode, orgId) {
    return {
        type: HISTORY_STATS,
        interval,
        projectId,
        mode,
        orgId
    }
}

