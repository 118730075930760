import React from 'react';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import {createUseStyles} from 'react-jss';
import getCommonStyles from '../../../styles/common';
import getBaseStyles from '../styles/support';
import FeatureIcon from '../../../../components/FeatureIcon';
import premiumDatasets from '../data/premium-datasets';
import Table, {OUTLINE} from '../../../documentation/Table';

const useStylesCommon = createUseStyles(getCommonStyles);
const useStylesBase = createUseStyles(getBaseStyles);

const useStyles = createUseStyles(theme => {
    return {
        datasets: {
            '& tbody th': {
                fontWeight: 'normal'
            },
            '& th:nth-child(n+2)': {
                textAlign: 'center'
            },
            //Giving the CSV column a minWidth to ensure enough width for tick + superscript
            '& th:nth-child(4)': {
                minWidth: theme.spacing(4)
            },
            '& tr > td:first-child > sup': {
                marginLeft: theme.spacing(0.5),
            },
            [theme.breakpoints.down('lg')]: {
                //Giving first column minWidth to stop the height from expanding too much
                '& th:nth-child(1)': {
                    minWidth: theme.spacing(25)
                }
            },
        },
        superscriptOverflow: {
            paddingLeft: 12,
            textIndent: -12
        }
    };
});


export default function PremiumDatasetsTable(props) {
    const classesCommon = useStylesCommon(props);
    const classesBase = useStylesBase(props);
    const classes = useStyles(props);
    const {activities = premiumDatasets} = props;

    // Maintain correct ordering when NGD Powered is switched on/off
    const sups = [];

    function addSup(sups, id, text) {
        sups.push({
            id,
            sup: <sup className={classNames(classesCommon.superScriptColor)}>{sups.length + 1}</sup>,
            text
        });
    }

    addSup(sups, "codePoint", "Code-Point format is ‘CSV and NTF’.");
    addSup(sups, "emergencyServicesGazetteer", "OS Emergency Services Gazetteer will align to the AddressBase Premium specification.");
    addSup(sups, "buildingHeightAttribute", "OS MasterMap Building Height Attribute is included as part of the OS MasterMap Topography Layer product,  supplied as a separate dataset.");
    addSup(sups, "sitesLayer", "OS MasterMap Sites Layer is part of the OS MasterMap Topography Layer product, supplied as a separate dataset.");
    const footNotes = sups.map(sup => {
        return <p key={sup.id}>{sup.sup} {sup.text}</p>;
    });

    const tableData = activities
        .map(dataset => {
            const key = dataset.id;
            return <tr key={key}>
                <th>
                    {dataset.name}
                    {dataset.id === 'emergencyServicesGazetteer' && sups.find(s => dataset.id === s.id).sup}
                    {dataset.id === 'buildingHeightAttribute' && sups.find(s => dataset.id === s.id).sup}
                    {dataset.id === 'sitesLayer' && sups.find(s => dataset.id === s.id).sup}
                </th>
                <td className={classesBase.center}>
                    <FeatureIcon on={dataset.gml}/>
                </td>
                <td className={classesBase.center}>
                    <FeatureIcon on={dataset.esriShapeFile}/>
                </td>
                <td className={classesBase.center}>
                    <FeatureIcon on={dataset.csv}/>
                    {dataset.id === 'codePoint' && sups.find(s => dataset.id === s.id).sup}
                </td>
                <td className={classesBase.center}>
                    <FeatureIcon on={dataset.geoPackage}/>
                </td>
                <td className={classesBase.center}>
                    <FeatureIcon on={dataset.geoTiff}/>
                </td>
                <td className={classesBase.center}>
                    <FeatureIcon on={dataset.vectorTiles}/>
                </td>
                <td className={classesBase.center}>
                    <FeatureIcon on={dataset.mapInfo}/>
                </td>
                <td className={classesBase.center}>
                    <FeatureIcon on={dataset.jpeg}/>
                </td>
                <td className={classesBase.center}>
                    <FeatureIcon on={dataset.ecw}/>
                </td>
                <td className={classesBase.center}>
                    <FeatureIcon on={dataset.pbf}/>
                </td>
            </tr>
        });


    return <>
        <Table className={classes.datasets} variant={OUTLINE} dataTestId="premium-dataset-formats">
            <thead>
            <tr>
                <Typography component='th'>Premium datasets</Typography>
                <Typography component='th'>GML</Typography>
                <Typography component='th'>ESRI shapefile</Typography>
                <Typography component='th'>CSV</Typography>
                <Typography component='th'>GeoPackage</Typography>
                <Typography component='th'>GeoTIFF</Typography>
                <Typography component='th'>Vector tiles</Typography>
                <Typography component='th'>MapInfo</Typography>
                <Typography component='th'>JPEG</Typography>
                <Typography component='th'>ECW</Typography>
                <Typography component='th'>PBF</Typography>
            </tr>
            </thead>
            <tbody>
            {tableData}
            </tbody>
        </Table>
        <div>
            {footNotes}
        </div>
    </>
}