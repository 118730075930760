import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import {InputLabel, Select, MenuItem} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {ReactComponent as ExpandIcon} from "../../../../components/icons/expand.svg";
import {FieldError, osColour, StyledCheckbox} from "omse-components";
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from "react-redux";
import {updateDraftOrder} from '../../../../modules/dataPackages/actions';
import classNames from 'classnames';
import {useAddDataPackageContext} from '../AddDataPackage';
import ClickAwayTooltip from "../../../../components/ClickAwayTooltip";
import {ReactComponent as HelpIcon} from "../../../../components/icons/help-notification.svg";

const styles = createUseStyles(theme => ({
    field: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        maxWidth: 396,
        margin: theme.spacing(1.5,1,0,1),
        '& label': {
            marginBottom: theme.spacing(1)
        }
    },
    dropdown: {
        lineHeight: '1.5rem',
        marginRight: 10,
        maxWidth: 360,
        backgroundColor: osColour.neutral.white,
        border: 'none',
        '&.is-error $select': {
            borderColor: osColour.status.error
        }
    },
    select: {
        '&:focus': {
            backgroundColor: osColour.neutral.white,
            borderRadius: 3
        },
        border: 'solid 1px ' +osColour.neutral.stone,
        borderRadius: 3,
        color: osColour.neutral.charcoal,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(5)+' !important'
    },
    selectIcon: {
        color: osColour.primary.berry,
        right: theme.spacing(1.5),
        height: 24,
        width: 24,
        top: 'auto'
    },
    disabled: {
        color: osColour.neutral.rock
    },
    tooltip: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
        marginLeft: theme.spacing(1)
    },
    dropdownWithTooltip: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    menuList: {
        '& li': {
            margin: 6
        }
    },
    checkbox: {
        paddingTop: theme.spacing(0.5),
        alignSelf: "flex-start"
    }
}));

const SELECT_MENU_ITEM = 'select';

function DataPackageLabeledCheckBox(props) {
    const {checkBoxMessage, checkBoxFunction, isChecked} = props;
    const classes = styles();
    return <StyledCheckbox checked={isChecked}
                           classes={{checkBox: classes.checkbox}}
                           onClick={() => {checkBoxFunction(!isChecked)}}
                           label={checkBoxMessage}/>;
}

export default function DataPackageDropdownField(props) {
    const {options, fieldName, labelMessage, selectMessage, fieldErrorMessage, checkBoxMessage, checkBoxFieldName, checkBoxFunction, infoMessage} = props;
    const {setShowInvalidOptionDialog} = useAddDataPackageContext();

    const selectedItem = useSelector((state) => state.dataPackages.draftOrder[fieldName]) || SELECT_MENU_ITEM;
    const displayErrors = useSelector((state) => state.dataPackages.draftOrder.displayErrors);
    const isChecked = useSelector((state) => !!state.dataPackages.draftOrder[checkBoxFieldName]); // Force the value to be a boolean

    const dispatch = useDispatch();
    const classes = styles();
    const intl = useIntl();

    useEffect(() => {
        if (selectedItem === SELECT_MENU_ITEM) {
            if (options.length === 1) {
                dispatch(updateDraftOrder(fieldName, options[0].id));
            }
            return;
        }

        const selectedOption = options.find(option => option.id === selectedItem);

        if (selectedOption && !selectedOption.enabled) {
            dispatch(updateDraftOrder(fieldName, null));
        }
    }, [options, dispatch, selectedItem, fieldName]);

    function handleChange (event) {
        const selectedValue = event.target.value;

        let selectedOption = options.find(option => option.id === selectedValue);

        if (selectedOption) {
            if (!selectedOption.enabled) {
                setShowInvalidOptionDialog(true);
            } else {
                dispatch(updateDraftOrder(fieldName, selectedValue));
            }
        }
    }

    function Tooltip() {
        if(props.tooltipMessage && props.tooltipAriaLabelMessage){
            return (
                <ClickAwayTooltip className={classes.tooltip}
                                  id={fieldName}
                                  icon={<HelpIcon width={24} height={24}
                                                  style={{
                                                      color: osColour.primary.berry
                                                  }}/>}
                                  body={props.tooltipMessage}
                                  ariaLabel={intl.formatMessage(props.tooltipAriaLabelMessage)}
                                  disabled={false}
                />
            );
        }
        else {
            return null
        }
    }

    const optionsLabelId = fieldName + '_label';
    return (
        <div className={classes.field}>
            <InputLabel id={optionsLabelId} className={classes.optionsLabel}>
                <FormattedMessage {...labelMessage}/>
            </InputLabel>
            { infoMessage && infoMessage }
            {   // Only show the check box is a message is available for it
                checkBoxMessage && <DataPackageLabeledCheckBox checkBoxMessage={checkBoxMessage}
                                                               checkBoxFieldName={checkBoxFieldName}
                                                               checkBoxFunction={checkBoxFunction}
                                                               isChecked={isChecked}/>}
            {   // Hide the selection if the checkbox is checked
                (!checkBoxMessage || !isChecked) && <>
                    <div className={classes.dropdownWithTooltip}>
                        <Select
                            variant='standard'
                            disableUnderline={true}
                            className={classNames(classes.dropdown, {'is-error': displayErrors && selectedItem === SELECT_MENU_ITEM})}
                            classes={{
                                select: classes.select,
                                icon: classes.selectIcon
                            }}
                            MenuProps={{classes: {list: classes.menuList}}}
                            value={selectedItem}
                            onChange={handleChange}
                            fullWidth
                            disabled={options.length === 1}
                            IconComponent={ExpandIcon}
                            error={true}
                            // Need to include the inputProps to satisfy wave, but the input is aria-hidden and even users using
                            // screen readers won't be expected to use the input, so it's not really needed.
                            inputProps={{'aria-labelledby': optionsLabelId}}
                            labelId={optionsLabelId}>
                            <MenuItem value={SELECT_MENU_ITEM} disabled>
                                <FormattedMessage {...selectMessage} />
                            </MenuItem>
                            {options && options.map(item => (
                                <MenuItem key={item.id}
                                          value={item.id}
                                          className={item.enabled ? "" : classes.disabled}
                                          data-testid={item.id}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <Tooltip className={classes.tooltip} />
                    </div>
                    <FieldError error={displayErrors && selectedItem === SELECT_MENU_ITEM && fieldErrorMessage}/>
                </>}


        </div>
    )
}

DataPackageDropdownField.propTypes = {
    options: PropTypes.array.isRequired,
    fieldName: PropTypes.string.isRequired, 
    labelMessage: PropTypes.object.isRequired, 
    selectMessage: PropTypes.object.isRequired,
    fieldErrorMessage: PropTypes.object.isRequired,
    tooltipMessage: PropTypes.object,
    tooltipAriaLabelMessage: PropTypes.object,
    infoMessage: PropTypes.object
};
