import React from 'react';
import Typography from "@mui/material/Typography";
import Table from './Table';
import {SpanTable} from "./CalculateRowSpan";
import routes from "../../util/routes";
import { ExternalLink, InternalLink } from 'omse-components';
import InlineCodeSnippet from "../../components/InlineCodeSnippet";


const featureLinks = {
    'bld': <ExternalLink type="generic" href="https://docs.os.uk/osngd/data-structure/buildings" message="Buildings" />,
    'gnm': <ExternalLink type="generic" href="https://docs.os.uk/osngd/data-structure/geographical-names" message="Geographical Names" />,
    'lnd': <ExternalLink type="generic" href="https://docs.os.uk/osngd/data-structure/land" message="Land" />,
    'lus': <ExternalLink type="generic" href="https://docs.os.uk/osngd/data-structure/land-use" message="Land Use" />,
    'str': <ExternalLink type="generic" href="https://docs.os.uk/osngd/data-structure/structures" message="Structures" />,
    'trn': <ExternalLink type="generic" href="https://docs.os.uk/osngd/data-structure/transport" message="Transport" />,
    'wtr': <ExternalLink type="generic" href="https://docs.os.uk/osngd/data-structure/water" message="Water" />,
}

const featureContent = {
    'bld': {
        'primary': featureLinks.bld,
        'Building Features': ['Building Part']
    },
    'gnm': {
        'primary': featureLinks.gnm,
        'Named Features': ['Named Point'],
    },
    'lnd': {
        'primary': featureLinks.lnd,
        'Land Features': ['Land, Land Point, Landform, Landform Line']
    },
    'lus': {
        'primary': featureLinks.lus,
        'Land Use Features': ['Site']
    },
    'str': {
        'primary': featureLinks.str,
        'Structures': ['Compound Structure, Field Boundary, Structure, Structure Line']
    },
    'trn':  {
        'primary': featureLinks.trn,
        'Transport Features': ['Cartographic Rail Detail, Rail, Road Line, Road Track or Path'],
        'Transport Network': ['Path, Path Link, Road'],
    },
    'wtr': {
        'primary': featureLinks.wtr,
        'Water Features': ['Inter Tidal Line, Tidal Boundary, Water, Water Point'],
        'Water Network': ['Water Link, Water Link Set, Water Node']
    },
}

const dataOverlaysHeader = [
    'Theme',
    'NGD Collection',
    'Collection Id',
    'NGD Feature Types'
    ]
const asuFeatureTypes = 'Boundary High Water Mark, Ceremonial County, Country, Devolved Parliament Constituency, ' +
    'Devolved Parliament Electoral Region, Electoral Division, GLA Assembly Constituency, Historic County, ' +
    'Historic European Region, Lower Tier Local Authority, Parish Or Community, Polling District, Region, Regional Authority,' +
    ' Upper Tier Local Authority, Ward, Westminster Constituency'
const dataOverlaysContent = [
    [<ExternalLink type="generic" href="https://docs.os.uk/osngd/data-structure/administrative-and-statistical-units" message="Administrative and Statistical Units" />, 'Boundaries', <InlineCodeSnippet>asu-bdy</InlineCodeSnippet>, asuFeatureTypes],
    [featureLinks.trn, 'Transport Network', <InlineCodeSnippet>trn-ntwk-railway</InlineCodeSnippet>, 'Railway Link, Railway Link Set, Railway Node'],
    [<ExternalLink type="generic" href="https://docs.os.uk/osngd/data-structure/water" message="Water" />, 'Water Features', <InlineCodeSnippet>wtr-ctch</InlineCodeSnippet>, 'River Basin Catchment, Waterbody Catchment']
]

export default function OTAFeatureContent() {
    return <>
        <Typography variant='h1' component={'h2'}>Content</Typography>
        <Typography variant='body1' paragraph={true} component='div'>
            <p>
                The following table details the OS NGD datasets that were used to create the OS NGD API – Tiles basemap.
                The result is a detailed OS basemap that combines <ExternalLink
                    type="generic"
                    href="https://www.ordnancesurvey.co.uk/products/os-open-zoomstack"
                    message="OS Open Zoomstack"
                /> and OS NGD data.
            </p>
            <p>
                The map features do not contain the complete list of attribution from the OS NGD datasets;
                we have only selected attribution that is useful for visualisation. The inclusion of unique IDs
                (where available), allow you to cross reference with the full product, for example
                with <InternalLink path={routes.ofaIntro} message="OS NGD API – Features" />.
            </p>
            <p>
                Click the link in the Theme column to find out more about each OS NGD dataset.
            </p>
        </Typography>
        <Typography variant='h2'>Basemap (<InlineCodeSnippet>ngd-base</InlineCodeSnippet>)</Typography>
        <Typography variant='body1' component='div'>
        <SpanTable headers={['Theme', 'NGD Collection', 'NGD Feature Types']} spanData={featureContent} />
        </Typography>
        <p></p>
        <Typography variant='h2' >Data Overlays</Typography>
        <Typography variant='body1' component='div'>
        <Table header={dataOverlaysHeader} values={dataOverlaysContent} />
        </Typography>
    </>
}
