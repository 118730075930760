import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {CommonDialog, CommonDialogActions} from 'omse-components';
import {FormattedMessage, defineMessages, useIntl} from 'react-intl';
import {Input, Typography} from '@mui/material';
import ShareRecipeSearchBox from "./ShareRecipeSearchBox";
import {createUseStyles} from 'react-jss';
import {shareRecipe} from "../../../../modules/recipes/actions";
import useShareableOrgsCache from "./useShareableOrgsCache";
import useActionIdSelector from "../../../../hooks/useActionIdSelector";

const messages = defineMessages({
    title: {
        id: "ShareRecipeDialog.title",
        defaultMessage: "Share recipe",
        description: "Title for the partner licence required dialog"
    },
    info: {
        id: "ShareRecipeDialog.info",
        defaultMessage: "You can share this recipe with another organisation. That organisation will be able to create data packages from it but not share your recipe with any other organisations.",
        description: "Information on Sharing Recipes"
    },
    messageTitle: {
        id: "ShareRecipeDialog.messageTitle",
        defaultMessage: "Message:",
        description: "Message Box Title"
    },
    confirm: {
        id: "ShareRecipeDialog.confirm",
        defaultMessage: "Send",
        description: "Label for the confirm button on recipe share dialog"
    },
    cancel: {
        id: "ShareRecipeDialog.cancel",
        defaultMessage: "Cancel",
        description: "Label for the cancel button on recipe share dialog"
    },
    messageLabel: {
        id: "ShareRecipeDialog.messageLabel",
        defaultMessage: "Message Text Area",
        description: "Label for the Message text area"
    },
    messagePlaceholder: {
        id: "ShareRecipeDialog.messaagePlaceholder",
        defaultMessage: "Enter a message for the recipient of the recipe",
        description: "Placeholder for the Message text area"
    },
    errorSharingRecipe: {
        id: "ShareRecipeDialog.errorSharingRecipe",
        defaultMessage: "There was an error while attempting to share the recipe",
        description: "Placeholder for the Message text area"
    }
});

const styles = createUseStyles(theme => ({
    dialogueBody: {
        gap: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column'
    },
    messageTextArea:{
        width: '100%',
        resize: 'none'
    },
    input: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
    innerInput: {
        margin: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingTop: 6,
        paddingBottom: 5,
        '&::placeholder': {
            paddingTop: 2
        }
    },
}));



export default function ShareRecipeDialog(props) {
    const {onClose, recipeId} = props;
    const minPrefixLength = 2;

    const intl = useIntl();
    const classes = styles();

    const [lastPrefix, setLastPrefix] = useState('');
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [message, setMessage] = useState(null);

    const {orgNames, loading} = useShareableOrgsCache(recipeId, lastPrefix, minPrefixLength);
    const [{working, result, error}, dispatch] = useActionIdSelector("recipes.shareRecipe");

    useEffect(() => {
        if(result === selectedOrg && !error){
            onClose(result);
        }
    },[onClose, result, selectedOrg, error]);

    const onInputChange = (input) => {
        setLastPrefix(input);
    }

    const share = () => {
        if(selectedOrg){
            dispatch(shareRecipe(recipeId,  selectedOrg, message ? message : ''));
        }
    };

    const actions = <CommonDialogActions working={working}
                                         showCancelButton={true}
                                         confirmLabel={intl.formatMessage(messages.confirm)}
                                         confirmAllowed={orgNames && selectedOrg && orgNames.includes(selectedOrg)}
                                         onConfirm={share}
                                         onClose={() => {onClose();}} />;


    return <CommonDialog onClose={() => {onClose();}} actions={actions} title={messages.title} error={error && intl.formatMessage(messages.errorSharingRecipe)}>
        <div className={classes.dialogueBody}>
            <Typography variant='body1'>
                <FormattedMessage {...messages.info}/>
            </Typography>
            <ShareRecipeSearchBox onReset={()=>{setLastPrefix("");}}
                                  onInputChange={onInputChange}
                                  onValueChange={setSelectedOrg}
                                  options={orgNames}
                                  loading={loading}
                                  minInput={minPrefixLength}
            />
            <div>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.messageTitle}/>
                </Typography>
                <Input inputProps={{...Input.inputProps, 'aria-label':intl.formatMessage(messages.messageLabel)}}
                       type='text'
                       multiline
                       rows={8}
                       disableUnderline={true}
                       onChange={(event) => {setMessage(event.target.value)}}
                       placeholder={intl.formatMessage(messages.messagePlaceholder)}
                       className={classes.messageTextArea}
                       classes={{
                           root: classes.input,
                           input: classes.innerInput
                       }}
                />
            </div>
        </div>
    </CommonDialog>;
}

ShareRecipeDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    recipeId: PropTypes.string.isRequired
};
