import React from 'react';
import {connect} from 'react-redux';
import Error404 from "../pages/Error404";
import featureCheck from "../util/featureCheck";
function FeatureCheck(props) {
    const {ok, children} = props;

    if(ok) {
        return children;
    } else {
        return <Error404/>
    }
}

function mapStateToProps(state, ownProps) {
    const {feature} = ownProps;
    const {result} = state.config.current;
    return {
        ok: result && featureCheck(feature, result)
    };
}

export default connect(mapStateToProps)(FeatureCheck);
