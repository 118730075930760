import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import List from '@mui/material/List';
import Product from './products/Product';
import {getProducts} from "../modules/products";
import {getProjects} from "../modules/projects";
import {defineMessages} from 'react-intl';
import {contentPadding} from 'omse-components';
import CircularProgress from '@mui/material/CircularProgress';
import ContentHeader from '../components/ContentHeader';
import NameProjectDialog from "../components/createProjectButton/NameProjectDialog";
import ProjectLimitDialog from '../components/createProjectButton/ProjectLimitDialog';
import {useSelector} from 'react-redux';
import {createUseStyles} from 'react-jss';
import useProjectsLimit from '../hooks/useProjectsLimit';
import {closeNewProjectDialog} from '../modules/project/actions';

const messages = defineMessages({
    title: {
        id: 'Products.title',
        defaultMessage: 'APIs',
        description: 'Title text'
    }
});

const useStyles = createUseStyles(theme => ({
    content: {
        paddingBottom: contentPadding.bottom,
        paddingLeft: contentPadding.left,
        paddingRight: contentPadding.right,
        maxWidth: contentPadding.maxWidth,
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile,
            paddingTop: 0,
            marginTop: 0
        }
    },
    productList: {
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    loading: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    }
}));

export default function Products() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const componentId = 'Products';
    
    const [availableProjects] = useProjectsLimit();
    
    const products = useSelector(state => state.products.current.result);
    const loading = useSelector(state => state.products.current.loading);
    const projects = useSelector(state => state.projects.current.result);
    const org = useSelector(state => state.organisation.current);
    const orgId = org && org.id;
    
    const projectDialogId = useSelector(state => state.project.newProject.dialogId);
    const projectDialogOpen = useSelector(state => state.project.newProject.open) && (projectDialogId === componentId);
    const newProduct = useSelector(state => state.project.newProject.addProduct);
    const handleNameProjectClose = () => dispatch(closeNewProjectDialog());

    useEffect(() => {
        dispatch(getProducts());
        dispatch(getProjects());
    }, [orgId, dispatch])

    return <>
        <ContentHeader title={messages.title} />
        <div className={classes.content}>
            <List className={classes.productList}>
                {loading &&
                    <CircularProgress size={32} className={classes.loading}/>
                }
                {
                    !loading && products && products.map(product => 
                        <Product key={product.name} product={product} projects={projects} projectDialogId={componentId} />
                    )
                }
            </List>
            {projectDialogOpen &&
                <>
                    {availableProjects?
                        <NameProjectDialog handleClose={handleNameProjectClose} product={newProduct} />
                        :
                        <ProjectLimitDialog handleClose={handleNameProjectClose} />
                    }
                </>
            }
        </div>
    </>
}
