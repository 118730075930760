import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl, defineMessages, FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import {createProject} from "../../modules/project/actions";
import {connect} from 'react-redux';
import withStyles from 'react-jss';
import InputLabel from "@mui/material/InputLabel";
import classNames from 'classnames';
import {ReactComponent as TickIcon} from "../icons/tick.svg";
import {ReactComponent as CloseSmallIcon} from "../icons/close-small.svg";
import {CommonDialog, CommonDialogActions, osColour} from 'omse-components';

const messages = defineMessages({
    dialogTitle: {
        id: 'NameProjectDialog.dialogTitle',
        defaultMessage: 'Give your new project a name',
        description: 'Title for the dialog box to name your project'
    },
    dialogContent: {
        id: 'NameProjectDialog.dialogContent',
        defaultMessage: 'Please choose a unique name for your project',
        description: 'Content for the dialog box to name your project'
    },
    projectNameInput: {
        id: 'NameProjectDialog.projectNameInput',
        defaultMessage: 'Enter project name',
        description: 'Text for label of Project Name input box'
    },
    enterNamePlaceholder: {
        id: 'NameProjectDialog.enterNamePlaceholder',
        defaultMessage: 'Enter a name',
        description: 'Enter a name placeholder'
    },
    submitButtonText: {
        id: 'NameProjectDialog.submitButtonText',
        defaultMessage: 'Create project',
        description: 'Text for creating a project'
    },
    inputTextRestrictions: {
        id: 'NameProjectDialog.inputTextRestrictions',
        defaultMessage: 'Your project name should only contain letters, numbers, spaces and these special characters ._#-$%',
        description: 'Text for project name input'
    },
    inputProjectNameAlreadyExists: {
        id: 'NameProjectDialog.inputProjectNameAlreadyExists',
        defaultMessage: 'Project name already exists',
        description: 'Text for project name already exists'
    },
    cancelButton: {
        id: 'NameProjectDialog.cancel',
        defaultMessage: 'Cancel',
        description: 'Label for cancel button',
    },
    invalidProjectName: {
        id: 'NameProjectDialog.invalidProjectName',
        defaultMessage: 'Invalid project name, it should only contain letters, numbers, spaces and these special characters ._#-$%',
        description: 'Invalid project name text'
    },
    validProjectName: {
        id: 'NameProjectDialog.validProjectName',
        defaultMessage: 'Valid project name',
        description: 'Valid project name text'
    }    
});

const styles = theme => ({
    input: {
        marginTop: theme.spacing(1),
        width: '100%',

        '&.error': {
            borderColor: osColour.status.error
        }
    },
    innerInput: {
        fontSize: '1.125rem',
        margin: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingTop: 6,
        paddingBottom: 5,
        '&::placeholder': {
            paddingTop: 2
        }
    },
    inputLabel: {
        color: osColour.neutral.stone,
        '&.success': {
            color: osColour.status.success
        },
        '&.error': {
            color: osColour.status.error
        }
    },
    closeIcon: {
        color: osColour.status.error,
        marginRight: theme.spacing(0.5),
        overflow: 'hidden',
        flexShrink: 0
    },
    successIcon: {
        color: osColour.status.success,
        marginRight: theme.spacing(0.5),
        overflow: 'hidden',
        flexShrink: 0
    },
    inputHelpContainer: {
        display: 'flex',
        marginTop: theme.spacing(1)
    }
});

/*
    * can contain letters and numbers
    * can contain . _ # - $ %
    * we will allow spaces to be inputted but will need to format app ID to strip spaces and replaces with hyphen
    * \b[A-Z0-9._\-$%# ]+$
 */
export class NameProjectDialog extends Component {
    state = {
        doesComply: false,
        value: ""
    };

    handleClose = () => {
        this.props.handleClose();
    };

    onClick = () => {
        const {actionId, product} = this.props;
        const {value} = this.state;

        this.props.createProject(product, actionId, value.trim());
    };

    onChange = (event) => {
        const value = event.target.value;
        const displayName = value.trim();
        let nameUsesInvalidChars = false;
        let projectsAlreadyExists = false;
        if (displayName !== "") {
            let match = displayName.match(/^[A-Za-z0-9._\-$%#\s]+$/g);
            nameUsesInvalidChars = match === null;
            projectsAlreadyExists = this.props.existingProjects.find(({name}) => name === displayName);
        }
        const doesComply = displayName !== "" && !nameUsesInvalidChars && !projectsAlreadyExists;

        this.setState({
            value,
            doesComply,
            nameUsesInvalidChars,
            projectsAlreadyExists
        })
    };

    handleKey = event => {
        if(event.key === 'Enter') {
            const value = event.target.value;
            const {actionId, product} = this.props;
            const {doesComply} = this.state;

            if (doesComply) {
                event.preventDefault();
                this.props.createProject(product, actionId, value.trim());
            }
        }
    };

    render() {
        const {classes, creating, error, intl} = this.props;
        const {doesComply, value, projectsAlreadyExists, nameUsesInvalidChars} = this.state;
        const violation = value !== "" && !doesComply;

        const inputRootClasses = classNames({
            [classes.input]: true,
            error: violation
        });


        // Help info.
        const iconSize = 24;

        const actions = <CommonDialogActions onClose={this.handleClose}
                                             onConfirm={this.onClick}
                                             confirmAllowed={doesComply && !creating}
                                             working={creating}
                                             confirmLabel={messages.submitButtonText}

        />;

        let errorMessage = null;
        if(error && error.httpResponse) {
            errorMessage = error.httpResponse;
        }

        const ErrorMessage = function(props) {
            const {children} = props;
            return <div className={classes.inputHelpContainer}>
                <CloseSmallIcon width={iconSize} height={iconSize} className={classes.closeIcon}/>
                <Typography className={classNames(classes.inputLabel, 'error')} variant='body1'>
                    {children}
                </Typography>
            </div>
        }

        return <CommonDialog onClose={this.handleClose}
                             title={messages.dialogTitle}
                             error={errorMessage}
                             actions={actions}
        >
            <InputLabel htmlFor="projectName">
                <FormattedMessage {...messages.projectNameInput}/>
            </InputLabel>
            <Input
                autoFocus={true}
                value={value}
                onChange={this.onChange}
                onKeyDown={this.handleKey}
                type='text'
                id='projectName'
                placeholder={intl.formatMessage(messages.enterNamePlaceholder)}
                disableUnderline={true}
                classes={{
                    root: inputRootClasses,
                    input: classes.innerInput
                }}
            />
            <div aria-live='polite'>
                {projectsAlreadyExists &&
                    <ErrorMessage>
                        <FormattedMessage {...messages.inputProjectNameAlreadyExists} />
                    </ErrorMessage>
                }

                {(!projectsAlreadyExists && value !== '') &&
                    <>
                        {nameUsesInvalidChars?
                            <ErrorMessage>
                                <FormattedMessage {...messages.invalidProjectName} />
                            </ErrorMessage>
                        :
                            <div className={classes.inputHelpContainer}>
                                <TickIcon width={iconSize} height={iconSize} className={classes.successIcon}/>
                                <Typography className={classNames(classes.inputLabel, 'success')} variant='body1'>
                                    <FormattedMessage {...messages.validProjectName} />
                                </Typography>
                            </div>
                        }
                    </>
                }
            </div>
            <Typography className={classNames(classes.inputHelpContainer, classes.inputLabel)} variant='body1'>
                <FormattedMessage {...messages.inputTextRestrictions} />
            </Typography>
        </CommonDialog>;
    }
}

NameProjectDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    product: PropTypes.string,
    testId: PropTypes.number // Used to force the component id, for testing
};

let componentId = 0;
export function mapStateToProps(ownProps) {
    const uniqueId = componentId++;
    return function(state, ownProps) {
        const {testId} = ownProps;
        const myId = testId || uniqueId;
        let { working, actionId, error } = state.project.create;
        return {
            existingProjects: state.projects.current.result || [],
            actionId: myId,
            creating: actionId === myId && working,
            error: actionId === myId && error
        }
    }
}

const withIntl = injectIntl(NameProjectDialog);
export const styled = withStyles(styles)(withIntl);
export default connect(mapStateToProps, { createProject })(styled);
