import React, {Fragment, useEffect, useState} from "react";
import {osColour, border1} from "omse-components";
import {createUseStyles} from 'react-jss';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import {defineMessages} from 'react-intl';
import {setMapTool} from "../../../../modules/toolbar";

import SelectAndModifyAction from "./SelectAndModifyAction";
import AddPolygonAction from "./AddPolygonAction";
import DeletePolygonAction from "./DeletePolygonAction";
import ResetAction from "./ResetAction";
import SavePolygonAction from "./polygonLibrary/SavePolygonAction";
import LoadPolygonAction from "./polygonLibrary/LoadPolygonAction";
import UploadPolygonAction from "./polygonLibrary/UploadPolygonAction";
import AddRectangleAction from "./AddRectangleAction";
import DeleteRectangleAction from "./DeleteRectangleAction";

import {ReactComponent as LoadIcon} from '../../../../components/icons/upload-menu.svg';
import {ReactComponent as SaveIcon} from '../../../../components/icons/save.svg';
import {ReactComponent as AddPolygonIcon} from "../../../../components/icons/polygon-add.svg";
import {ReactComponent as DeletePolygonIcon} from "../../../../components/icons/polygon-delete.svg";
import {ReactComponent as ResetIcon} from "../../../../components/icons/reset.svg";
import {ReactComponent as AddRectangleIcon} from "../../../../components/icons/rectangle-add.svg";
import {ReactComponent as DeleteRectangleIcon} from "../../../../components/icons/rectangle-delete.svg";

import ToolbarButton from './ToolbarButton';
import ToolbarMenuButton from "./ToolbarMenuButton";
import ToolbarSplitButton from "./ToolbarSplitButton";
import ToolbarTooltip from "./ToolbarTooltip";
import {SiteSearch} from 'omse-components';

const messages = defineMessages({
    load: {
        id: 'DataPackageMapToolbar.load',
        defaultMessage: 'Load Polygon',
        description: 'Label for the load button on the map toolbar'
    },
    loadAria: {
        id: 'DataPackageMapToolbar.loadAria',
        defaultMessage: 'Load polygon from polygon library',
        description: 'Aria-Label for the load button on the map toolbar'
    },
    loadTooltip: {
        id: 'DataPackageMapToolbar.loadTooltip',
        defaultMessage: 'Load polygon from polygon library or upload a polygon or tile list.',
        description: 'Tooltip shown when hovering over the load tool on the map toolbar'
    },
    loadFromPolygonLibrary: {
        id: 'DataPackageMapToolbar.loadFromPolygonLibrary',
        defaultMessage: 'Load polygon from the polygon library',
        description: 'Label for the load from polygon library option in the load menu'
    },
    uploadPolygon: {
        id: 'DataPackageMapToolbar.uploadPolygon',
        defaultMessage: 'Upload polygon',
        description: 'Label for the upload polygon option in the load menu'
    },
    save: {
        id: 'DataPackageMapToolbar.save',
        defaultMessage: 'Save',
        description: 'Label for the save button on the map toolbar'
    },
    saveAria: {
        id: 'DataPackageMapToolbar.saveAira',
        defaultMessage: 'Save polygon to polygon library',
        description: 'Aria-Label for the save button on the map toolbar'
    },
    saveTooltip: {
        id: 'DataPackageMapToolbar.saveTooltip',
        defaultMessage: 'Save the polygon you have just drawn to the Polygon Library.',
        description: 'Tooltip shown when hovering over the save tool on the map toolbar'
    },
    addPolygon: {
        id: 'DataPackageMapToolbar.addPolygon',
        defaultMessage: 'Polygon',
        description: 'polygon button label'
    },
    addPolygonAria: {
        id: 'DataPackageMapToolbar.addPolygonAria',
        defaultMessage: 'Add polygon to map',
        description: 'polygon button label for screen readers'
    },
    addPolygonTooltip: {
        id: 'DataPackageMapToolbar.addPolygonTooltip',
        defaultMessage: 'Click to add polygon points, double-click to finish. Shift + Left Click and drag to draw freehand. Ctrl + Z to undo last point. [Esc] to cancel sketch.',
        description: 'Tooltip shown when the draw polygon tool is active'
    },
    deletePolygon: {
        id: 'DataPackageMapToolbar.deletePolygon',
        defaultMessage: 'Polygon',
        description: 'polygon button label'
    },
    deletePolygonAria: {
        id: 'DataPackageMapToolbar.deletePolygonAria',
        defaultMessage: 'Delete an area',
        description: 'polygon button label for screen readers'
    },
    deletePolygonTooltip: {
        id: 'DataPackageMapToolbar.deletePolygonTooltip',
        defaultMessage: 'Click to add points defining the area to erase, double click to finish. [Esc] to restore.',
        description: 'Tooltip shown when the polygon tool is active'
    },
    polygonMenuAria: {
        id: 'DataPackageMapToolbar.polygonMenuAria',
        defaultMessage: 'Choose a polygon tool.',
        description: 'Screen-reader label for the drop down button by the polygon tool'
    },
    resetLabel: {
        id: 'DataPackageMapToolbar.resetLabel',
        defaultMessage: 'Reset',
        description: 'Label for the reset button on the map toolbar'
    },
    resetAriaLabel: {
        id: 'DataPackageMapToolbar.resetAriaLabel',
        defaultMessage: 'Resets the map',
        description: 'Aria-Label for the reset button on the map toolbar'
    },
    resetToolTip: {
        id: 'DataPackageMapToolbar.resetToolTip',
        defaultMessage: 'Reset drawing and delete all polygons from the map.',
        description: 'Tooltip shown when hovering over the reset tool on the map toolbar'
    },
    addRectangleLabel: {
        id: 'DataPackageMapToolbar.addRectangleLabel',
        defaultMessage: 'Rectangle',
        description: 'rectangle button label'
    },
    addRectangleAriaLabel: {
        id: 'DataPackageMapToolbar.addRectangleAriaLabel',
        defaultMessage: 'Add rectangle to map',
        description: 'rectangle button label for screen readers'
    },
    addRectangleTooltip: {
        id: 'DataPackageMapToolbar.addRectangleTooltip',
        defaultMessage: 'Click to start drawing a rectangle and click again to finish. [Esc] to cancel.',
        description: 'Tooltip shown when the draw rectangle tool is active'
    },
    deleteRectangleLabel: {
        id: 'DataPackageMapToolbar.deleteRectangleLabel',
        defaultMessage: 'Rectangle',
        description: 'delete rectangle button label'
    },
    deleteRectangleAriaLabel: {
        id: 'DataPackageMapToolbar.deleteRectangleAriaLabel',
        defaultMessage: 'Delete an area',
        description: 'delete rectangle button label for screen readers'
    },
    deleteRectangleTooltip: {
        id: 'DataPackageMapToolbar.deleteRectangleTooltip',
        defaultMessage: 'Click to start drawing a rectangle defining the area to erase and click again to finish. [Esc] to restore.',
        description: 'Tooltip shown when the delete rectangle tool is active'
    },
    rectangleMenuAria: {
        id: 'DataPackageMapToolbar.rectangleMenuAria',
        defaultMessage: 'Choose a rectangle tool.',
        description: 'Screen-reader label for the drop down button by the rectangle tool'
    },
    defaultToolTooltip: {
        id: 'DataPackageMapToolbar.defaultToolTooltip',
        defaultMessage: 'Click a polygon to edit it. Click and drag to pan the map.',
        description: 'Tooltip for the default map tool'
    }
});

const styles = createUseStyles(theme => ({
    toolbar: {
        flex: '1 0 auto',
        display: 'flex',
        alignItems: 'flex-end',
        borderBottom: `solid ${osColour.primary.berry} 2px`,
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        width: '100%',
        position: 'absolute',
        zIndex: 1,
        backgroundColor: osColour.neutral.white,
        paddingTop: theme.spacing(1)
    },
    divider: {
        boxSizing: 'border-box',
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: 1,
        height: `calc(48px - ${theme.spacing(2)})`, // To match the button height, minus spacing
        border: border1
    }
}));

export default function DataPackageMapToolbar (props) {
    const area = useSelector((state) => state.dataPackages.draftOrder.area);
    const tool = useSelector(state => state.toolbar.activeTool);
    const dataPackage = useSelector(state => state.dataPackages.create.result);
    const [drawingHasFeatures, setDrawingHasFeatures] = useState(false);
    const dispatch = useDispatch();

    const {map, drawingLayer, tileLayer, deleteLayer, selectedTileLayer, refreshSelectedTiles, updateDraftOrderAOI, currentPolygonLayer} = props;
    const drawingLayerSource = drawingLayer && drawingLayer.getSource();
    const classes = styles();

    let showToolbar = area && area === 'Polygon' && !dataPackage;

    // Keep track of the features on the drawing layer, so that we can enable/disable the buttons at the
    // right time.
    useEffect(() => {
        if(!drawingLayerSource) {
            return;
        }

        function checkFeatures() {
            const count = drawingLayerSource.getFeatures().length;
            const hasFeatures = count > 0;
            if(hasFeatures !== drawingHasFeatures) {
                setDrawingHasFeatures(hasFeatures);
            }
        }

        // Register listeners to keep an eye on the feature count
        drawingLayerSource.on('clear', checkFeatures);
        drawingLayerSource.on('addfeature', checkFeatures);
        drawingLayerSource.on('removefeature', checkFeatures);

        // Check the feature count for the first time
        checkFeatures();

        return function cleanUp() {
            // Clean up the listeners when we are done
            drawingLayerSource.un('clear', checkFeatures);
            drawingLayerSource.un('addfeature', checkFeatures);
            drawingLayerSource.un('removefeature', checkFeatures);
        }
    }, [drawingLayerSource, drawingHasFeatures, setDrawingHasFeatures]);

    useEffect(() => {
        // Turn off the active map tool and clear the drawing layer if there should be no toolbar shown
        if(!showToolbar) {
            dispatch(setMapTool());
            if (!dataPackage) {
                if (drawingLayer) {
                    drawingLayer.getSource().clear();
                }
                if (selectedTileLayer) {
                    selectedTileLayer.getSource().clear();
                }
            }
        }

        // Turn on the select and modify tool, if there is no other active tool and there are features to edit
        if(showToolbar && drawingHasFeatures && !tool.type) {
            const newTool = SelectAndModifyAction(map, {drawingLayer, tileLayer, currentPolygonLayer}, refreshSelectedTiles, updateDraftOrderAOI);
            newTool.tooltip = messages.defaultToolTooltip;
            dispatch(setMapTool(newTool));
        }
    }, [dispatch, showToolbar, map, drawingLayer, tileLayer, selectedTileLayer, currentPolygonLayer, drawingHasFeatures, tool, dataPackage, refreshSelectedTiles, updateDraftOrderAOI]);

    if(!showToolbar) {
        return null;
    }

    // Set up a function that will enable the given action. This is used as the onClick() for the
    // buttons on the toolbar
    function setupClick(action, tooltip) {
        return function onClick() {
            // If the old tool was the same type as the current one then this is just a deactivation and we don't need
            // to set up a new tool. If the types are different then we need to activate the new one.
            let newTool = null;
            if(tool.type !== action.type) {
                newTool = action(map, {drawingLayer, deleteLayer, selectedTileLayer, currentPolygonLayer});
                if(newTool) {
                    newTool.tooltip = tooltip;
                }
            }
            dispatch(setMapTool(newTool));
        }
    }

    return <Fragment>
        <div className={classes.toolbar}>
            <SiteSearch map={map}/>
            <ToolbarSplitButton options={[
                {
                    label: messages.addPolygon,
                    ariaLabel: messages.addPolygonAria,
                    icon: <AddPolygonIcon/>,
                    tooltip: messages.addPolygonTooltip,
                    active: tool && tool.type === AddPolygonAction.type,
                    onClick: setupClick(AddPolygonAction, messages.addPolygonTooltip)
                },
                {
                    label: messages.deletePolygon,
                    ariaLabel: messages.deletePolygonAria,
                    icon: <DeletePolygonIcon/>,
                    tooltip: messages.deletePolygonTooltip,
                    active: tool && tool.type === DeletePolygonAction.type,
                    disabled: !drawingHasFeatures,
                    onClick: setupClick(DeletePolygonAction, messages.deletePolygonTooltip)
                }
            ]}
                                menuAriaLabel={messages.polygonMenuAria}
            />

            <ToolbarSplitButton options={[
                {
                    label: messages.addRectangleLabel,
                    ariaLabel: messages.addRectangleAriaLabel,
                    icon: <AddRectangleIcon/>,
                    tooltip: messages.addRectangleTooltip,
                    active: tool && tool.type === AddRectangleAction.type,
                    onClick: setupClick(AddRectangleAction, messages.addRectangleTooltip)
                },
                {
                    label: messages.deleteRectangleLabel,
                    ariaLabel: messages.deleteRectangleAriaLabel,
                    icon: <DeleteRectangleIcon/>,
                    tooltip: messages.deleteRectangleTooltip,
                    active: tool && tool.type === DeleteRectangleAction.type,
                    disabled: !drawingHasFeatures,
                    onClick: setupClick(DeleteRectangleAction, messages.deleteRectangleTooltip)
                }
            ]}
                                menuAriaLabel={messages.rectangleMenuAria}
            />

            <div className={classes.divider}/>

            <ToolbarButton label={messages.resetLabel}
                           ariaLabel={messages.resetAriaLabel}
                           icon={<ResetIcon width={24} height={24}/>}
                           tooltip={messages.resetToolTip}
                           disabled={!drawingHasFeatures}
                           onClick={setupClick(ResetAction)}/>

            <ToolbarMenuButton label={messages.load}
                               ariaLabel={messages.loadAria}
                               icon={<LoadIcon/>}
                               tooltip={messages.loadTooltip}
                               menuItems={[
                                   {
                                       label: messages.loadFromPolygonLibrary,
                                       onClick: setupClick(LoadPolygonAction)
                                   },
                                   {
                                       label: messages.uploadPolygon,
                                       onClick: setupClick(UploadPolygonAction)
                                   }
                               ]}/>

            <ToolbarButton label={messages.save}
                           ariaLabel={messages.saveAria}
                           icon={<SaveIcon width={24} height={24}/>}
                           tooltip={messages.saveTooltip}
                           disabled={!drawingHasFeatures}
                           onClick={setupClick(SavePolygonAction)}/>
        </div>
        <ToolbarTooltip/>
        {
            (tool && tool.component) || null
        }
    </Fragment>;
}

DataPackageMapToolbar.propTypes = {
    map: PropTypes.object,
    drawingLayer: PropTypes.object,
    deleteLayer: PropTypes.object,
    selectedTileLayer: PropTypes.object,
    currentPolygonLayer: PropTypes.object,
    refreshSelectedTiles: PropTypes.func,
    updateDraftOrderAOI: PropTypes.func
};