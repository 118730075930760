import React, { useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from './components/header/Header';
import AppContent from './pages/AppContent';
import { setHistory } from './modules/history';
import { modules } from "omse-components";
import { routeDescription, routeTitle } from './util/metadata';
import { Helmet } from "react-helmet";
import { setOrgFromSearch, setSearchFromOrg } from './modules/organisation/action';
import { tabsPrefix, tabsRE } from './util/responsive-navigation';
import { useLocation, useHistory } from 'react-router-dom';
import { findRoute } from './util/routes';

const { getConfig } = modules.actions.config;

const App = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const [title, setTitle] = useState(routeTitle(location));
    const [description, setDescription] = useState(routeDescription(location));

    const config = useSelector(state => state.config.current.result);
    const userDetails = useSelector(state => state.user.current.result);
    const menu = useSelector(state => state.menu);
    
    const headerRef = useRef();

    useEffect(() => {
        dispatch(getConfig());
        let unsubscribeFromHistory;
        if (history) {
            dispatch(setHistory(history));

            const handleLocationChange = (newLocation) => {
                setTitle(routeTitle(newLocation));
                setDescription(routeDescription(newLocation));
                dispatch(setSearchFromOrg());
        
                if (newLocation?.hash === '') {
                    if (newLocation.pathname !== findRoute('account').path) {
                        window.scrollTo(0, 0);
                    }
                }
            };

            unsubscribeFromHistory = history.listen(handleLocationChange);
            handleLocationChange(history.location);
        }

        return () => {
            if (unsubscribeFromHistory) {
                unsubscribeFromHistory();
            }
        };
    }, [dispatch, history]);

    useEffect(() => {
        if (config && window.ga) {
            // Register with google analytics (only if not running locally)
            window.ga('create', config.trackingId, 'auto');
        }
    }, [config]);

    useEffect(() => { 
        if (userDetails) {
            dispatch(setOrgFromSearch());
        }
    }, [dispatch, userDetails]);

    useEffect(() => {
        if (menu.tabs && menu.tabs.length > 0) {
            const newToken = tabsPrefix + menu.tabs.length;
            const existing = Array.from(document.body.classList.values()).find(item => item.match(new RegExp(tabsRE)));
            if (existing) {
                if (existing !== newToken) {
                    document.body.classList.replace(existing, newToken)
                }
            } else {
                document.body.classList.add(newToken);
            }
        }
    }, [menu.tabs]);

    return (
        <>
            <Helmet>
                <meta name="description" content={description}></meta>
                <title>{title}</title>
            </Helmet>
            <Header headerRef={headerRef} />
            <AppContent headerRef={headerRef} />
        </>
    );
}

export default App;
