import {LOADED_PAYMENT_STATUS_ACTION} from "./actions";

export default function paymentActionCallback(store, action, result) {
    // Now that the action request has completed, replace the current state with the response from
    // the server. If the result is empty then the whole billing page will show an error message.
    store.dispatch({
        type: LOADED_PAYMENT_STATUS_ACTION,
        result: result
    });
}
