import React from 'react';
import Header from "../Header";
import Content from '../Content';
import copyrightGuidelines from './brandLogoImg/OS_guidelines_API-copyright@2x.png';
import withStyles from 'react-jss';
import BrandImage from './BrandImage';
import { styles } from './BrandStyling';
import {defineMessages} from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Document from '../Document';

const messages = defineMessages({
    title: {
        id: 'CopyrightOverview.title',
        defaultMessage: 'Copyright',
        description: 'Copyright'
    },
    description: {
        id: 'CopyrightOverview.description',
        defaultMessage: 'A downloadable asset is available. This includes the copyright statement and links for both error reporting and OS terms and conditions on a tinted bar. This should be edited to the correct date while retaining all original links.',
        description: 'Description of copyright overview image'
    },
});


function CopyrightOverview(props) {

    const {classes} = props;
    const containerClasses = [classes.mainContainer, classes.oneItem]
    
    return (
        
        <Document>
            <Header back={true}>Copyright overview</Header>
            
            <Content>
                    <div className={classNames(containerClasses)}>
                            <BrandImage 
                                image={copyrightGuidelines} 
                                alt='Diagram of Ordnance Survey copyright text placement'
                                title={messages.title}
                                description={messages.description}
                                line={true}
                            />
                    </div> 
            </Content>
        </Document>
    )
}

CopyrightOverview.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CopyrightOverview)