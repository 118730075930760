import styled from "@emotion/styled";
import { findRoute } from "../../util/routes";
import { Typography } from "@mui/material";
import { FormattedMessage, defineMessages } from "react-intl";
import { osColour, ExternalLink, InternalLink } from "omse-components";
import { ReactComponent as Learn } from "../../components/icons/learn.svg";
import { ReactComponent as EmailBold } from "../../components/icons/email-bold.svg";
import { ReactComponent as Documentation } from "../../components/icons/documentation.svg";

const StyledHeading = styled(Typography)`
	font-size: 1.25em;
	font-weight: 600;
	margin-top: 16px;
    margin-bottom: 16px;
`;

const SupportLink = styled("div")`
    margin: 0.6em 0;
    padding: 0;
    display: flex;
    gap: 1em;
    align-items: center;
    & > svg {
        display: block;
        margin: 0;
        padding: 0.4em;
        width: 1.2em;
        height: 1.2em;
        flex-shrink: 0;
        border-radius: 10em;
        color: ${osColour.primary.berry};
        background: ${osColour.neutral.clouds};
    }
`;

const messages = defineMessages({
    title: {
        id: "messages.title",
        defaultMessage: "Help and Support",
        description: "Help and support Section > H2 Title",
    },
    examplesLinkText: {
        id: "messages.examplesLinkText",
        defaultMessage: "Examples and Tutorials",
        description: "Help and support Section > Link 2 (Examples and Tutorials) Message",
    },
    documentationLinkText: {
        id: "messages.documentationLinkText",
        defaultMessage: "Documentation",
        description: "Help and support Section > Link 3 (Documentation) Message",
    },
});

export default function HelpAndSupport() {
    return (
        <section data-testid="helpAndSupportSection">
            <StyledHeading variant={"h2"}>
                <FormattedMessage {...messages.title} />
            </StyledHeading>
            <SupportLink>
                <EmailBold />
                <ExternalLink type="supportCaps" />
            </SupportLink>
            <SupportLink>
                <Learn />
                <ExternalLink
                    href="https://labs.os.uk/public/os-data-hub-examples/"
                    message={messages.examplesLinkText}
                />
            </SupportLink>
            <SupportLink>
                <Documentation />
                <InternalLink
                    path={findRoute("documentation").path}
                    message={messages.documentationLinkText}
                />
            </SupportLink>
        </section>
    );
}
