import React, {Fragment} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import { ExternalLink } from 'omse-components';
import Typography from '@mui/material/Typography';

const messages = defineMessages({
    error: {
        id: 'InviteError.error',
        defaultMessage:
            "We encountered an unexpected error while trying invite the new team member. " +
            "The following message may help to explain the cause of the error:",
        description: 'Displayed when we get an unexpected error when inviting a new user'
    },
    errorNextSteps: {
        id: 'InviteError.errorNextSteps',
        defaultMessage:
            "Please wait for a while, and then try to invite the user again. " +
            `If the problem persists then please {link} and we will take care of it.`,
        description: 'Displayed when we get an unexpected error when inviting a new user'
    },
    emailAdvice: {
        id: 'InviteError.emailAdvice',
        defaultMessage:
            "Please ensure you send this email from the email address you used to register with the OS Data Hub and include:",
        description: 'Displayed when we get an unexpected error when inviting a new user'
    },
    orgName: {
        id: 'InviteError.orgName',
        defaultMessage: "Your organisation name",
        description: 'Displayed when we get an unexpected error when inviting a new user'
    },
    emailAddress: {
        id: 'InviteError.emailAddress',
        defaultMessage: "The new user’s email address",
        description: 'Displayed when we get an unexpected error when inviting a new user'
    },
    Invite001: {
        id: 'InviteError.Invite001',
        defaultMessage: "We were unable to invite the new team member, as the user is already a member of another organisation.",
        description: 'Displayed when we get an Invite001 error from the server'
    },
    Invite001NextSteps: {
        id: 'InviteError.Invite001NextSteps',
        defaultMessage: "The new user will need help from Ordnance Survey support to move into your organisation. " +
            'Please {link} and we will take care of it.',
        description: 'Next steps displayed when we get an Invite001 error from the server'
    },
    Invite002: {
        id: 'InviteError.Invite002',
        defaultMessage: "We were unable to invite the new team member, as the user already has an outstanding invitation from another organisation.",
        description: 'Displayed when we get an Invite002 error from the server'
    },
    Invite002NextSteps: {
        id: 'InviteError.Invite002NextSteps',
        defaultMessage: "The new user will need to decline the other invitation before you can invite them. " +
            "Please contact the user, and ask them to log into the OS Data Hub to handle their invitation. " +
            'If you are unable to resolve this yourself, please {link}.',
        description: 'Next steps displayed when we get an Invite002 error from the server'
    },
    Invite003: {
        id: 'InviteError.Invite003',
        defaultMessage: "We were unable to invite the new team member, as they are already a member of your organisation.",
        description: 'Displayed when we get an Invite003 error from the server',
        skipEmailGuidance: true
    },
    Invite003NextSteps: {
        id: 'InviteError.Invite003NextSteps',
        defaultMessage: "If you wish to change the role assigned to the user then please close this dialog and set their new role on the Manage team members page.",
        description: 'Next steps displayed when we get an Invite003 error from the server'
    },
    Invite100: {
        id: 'InviteError.Invite100',
        defaultMessage: "We encountered an internal error while trying to invite the new team member.",
        description: 'Displayed when we get an Invite001 error from the server'
    },
    Invite100NextSteps: {
        id: 'InviteError.Invite100NextSteps',
        defaultMessage: "Please wait for a while, and then try to invite the user again. " +
            'If the problem persists then please {link} and we will take care of it.',
        description: 'Displayed when we get an Invite001 error from the server'
    }
});

export default function InviteError({error}) {
    let errorMessage;
    let nextSteps;
    let displayHttpResponse = false;
    let errorCode;
    const index = error.httpResponse.indexOf(':');
    if(index > 0) {
        errorCode = error.httpResponse.substr(0, index);
    }
    if(messages[errorCode]) {
        errorMessage = messages[errorCode];
        nextSteps = messages[errorCode + 'NextSteps'];
    } else {
        errorMessage = messages.error;
        displayHttpResponse = true;
        nextSteps = messages.errorNextSteps;
    }

    return <Fragment>
        <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...errorMessage} values={{ link: <ExternalLink type='support' /> }} />
        </Typography>
        {
            displayHttpResponse &&
            <Typography variant='body1' paragraph={true}>
                { error.httpResponse }
            </Typography>
        }
        <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...nextSteps} values={{ link: <ExternalLink type='support' /> }} />
        </Typography>
        {
            !errorMessage.skipEmailGuidance &&
                <Fragment>
                    <Typography variant='body1' paragraph={true}>
                        <FormattedMessage {...messages.emailAdvice}/>
                    </Typography>
                    <ul>
                        { [messages.orgName, messages.emailAddress].map(m =>
                            <li key={m.id}><Typography variant='body1'>
                                <FormattedMessage {...m}/>
                            </Typography></li>
                        ) }
                    </ul>
                </Fragment>
        }
    </Fragment>;

}