import DOMPurify from 'dompurify';

export {
    formattedTextToSanitizedHTML,
    checkAndAppendContract,
    checkAndAppendPDF
}

function formattedTextToSanitizedHTML(input) {
    return DOMPurify.sanitize(input.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
}

function checkAndAppendContract(title) {
    if (!title) return "";
    return (title.indexOf("Contract") === -1) ? `${title} Contract` : title;
}

function checkAndAppendPDF(url, fileSize = 0) {
    const regexFilter = /.pdf/i
    if (!regexFilter.test(url.slice(-4))) return ""
    return (fileSize)
        ? ` (PDF - ${fileSize.toFixed(2)} MB)`
        : ' (PDF)'
}