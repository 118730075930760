import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {RadioGroup, Typography} from '@mui/material';
import routePaths from "../../util/routes";
import {AddButton, LabelledRadioButton} from 'omse-components';
import NavButton from "../../components/NavButton";
import useSearchParam from "../../hooks/useSearchParam";
import Link from '../../components/Link';
import InvitationSkipDialog from './InvitationSkipDialog';
import styles from './styles';

const messages = defineMessages({
    intro: {
        id: 'InvitationChoice.intro',
        defaultMessage: 'You have been invited to join the {invitingOrg} organisation',
        description: 'Intro for the accept invitation dialog'
    },
    acceptLabel: {
        id: 'InvitationChoice.acceptLabel',
        defaultMessage: 'Please choose to accept or decline the invitation',
        description: 'aria-label for the accept RadioGroup'
    },
    yes: {
        id: 'InvitationChoice.yes',
        defaultMessage: 'Yes, accept invitation to the organisation',
        description: 'Yes option for the accept invitation dialog'
    },
    yesHelp: {
        id: 'InvitationChoice.yesHelp',
        defaultMessage: 'You’ll have access to all the organisation’s API projects and access to your existing projects on your personal account if you accept the invite.',
        description: 'Help text for the yes option for the accept invitation dialog'
    },
    no: {
        id: 'InvitationChoice.no',
        defaultMessage: "Decline this invitation and don't show me again",
        description: 'No option for the accept invitation dialog'
    },
    noHelp: {
        id: 'InvitationChoice.noHelp',
        defaultMessage: "You won't be added to the organisation",
        description: 'Help text for the no option for the accept invitation dialog'
    },
    newUserNo: {
        id: 'InvitationChoice.newUserNo',
        defaultMessage: "No, decline this invitation and continue the sign-up process.",
        description: 'No option for the accept invitation dialog shown for new users'
    },
    newUserNoHelp: {
        id: 'InvitationChoice.newUserNoHelp',
        defaultMessage: "You’ll be a DataHub user that is independent of the above organisation.",
        description: 'Help text for the no option for the accept invitation dialog shown for new users'
    },
    continue: {
        id: 'InvitationChoice.continue',
        defaultMessage: "Continue",
        description: 'Label for the continue button on the accept invitation dialog'
    },
    cancel: {
        id: 'InvitationChoice.cancel',
        defaultMessage: "Cancel",
        description: 'Label for the cancel button on the accept invitation dialog'
    },
    skip: {
        id: 'InvitationChoice.skip',
        defaultMessage: "Skip, I'll do this later",
        description: 'Label for the cancel button on the accept invitation dialog'
    }
});

const DEFAULT_CONTINUE_VALUE = undefined; // A value that is never really set. If we have any other value for the
                                          // parameter then we know that it is set, and we should respect it.

export default function Invitation({working, handleInvitation}) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [accept, setAccept] = useState('yes');
    const user = useSelector(state => state.user.current.result);
    const classes = styles();
    const intl = useIntl();
    let [continuePremiumSignup] = useSearchParam('continuePremiumSetup', DEFAULT_CONTINUE_VALUE);
    continuePremiumSignup = continuePremiumSignup !== DEFAULT_CONTINUE_VALUE;

    const handleBtnAction = () => {
        handleInvitation(accept === 'yes');
    }

    let noLabel = messages.no;
    let noHelp = messages.noHelp;
    if(user.newUser) {
        noLabel = messages.newUserNo;
        noHelp = messages.newUserNoHelp;
    }

    let skipAction =  <Link path={routePaths.dashboard}>
                         <FormattedMessage {...messages.skip}/>
                    </Link>

    if(continuePremiumSignup) {
      skipAction =  <div onClick={() => setShowConfirmDialog(true)} className={classes.linkStyle}>
          <FormattedMessage {...messages.skip}/>
        </div>
    }

    return (
      <div className={classes.content}>
        <Typography variant='body1'>
          <FormattedMessage
            {...messages.intro}
            values={{
              invitingOrg: user.hasInvitation,
            }}
          />
        </Typography>
        <RadioGroup
          name='accept'
          className={classes.radioGroup}
          aria-label={intl.formatMessage(messages.acceptLabel)}
          value={accept}
          onChange={(e) => setAccept(e.target.value)}
        >
          <LabelledRadioButton selectedOption={accept} option={"yes"} label={messages.yes} data-testid='radio-select-yes' />
          <Typography variant='body1' color='textSecondary'>
            <FormattedMessage {...messages.yesHelp} />
          </Typography>
          <LabelledRadioButton selectedOption={accept} option={"no"} label={noLabel} data-testid='radio-select-no' />
          <Typography variant='body1' color='textSecondary'>
            <FormattedMessage {...noHelp} />
          </Typography>
        </RadioGroup>
        <div className={classes.buttons}>
          <NavButton path={routePaths.dashboard} variant='outlined' color='primary'>
            <FormattedMessage {...messages.cancel} />
          </NavButton>

          <AddButton action={handleBtnAction} label={messages.continue} working={working} showIcon={false} data-testId />
        </div>
        <div className={classes.buttons}>{skipAction}</div>

        {showConfirmDialog && <InvitationSkipDialog handleClose={() => setShowConfirmDialog(false)} data-testid='invitation-skip-dialog' />}
      </div>
    );
}