import styled from "@emotion/styled";
import { defineMessages } from "react-intl";
import { useSelector } from "react-redux";
import {
    OPEN_PLAN,
    PSGA_PLAN,
    PREMIUM_PLAN,
    USER_OPEN_DATA_PLAN,
    USER_PREMIUM_DATA_PLAN,
    USER_PSGA_PLAN,
    CRM_PENDING_PSGA_PLAN,
    USER_OS_INTERNAL_PLAN,
    USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN,
} from "../../../shared/plans";
import PlanItem from "./PlanItem";

const messages = defineMessages({
    openDataPlan: {
        id: "PlansOverview.openDataPlan",
        defaultMessage: "OS OpenData Plan",
        description: "OS OpenData title",
    },
    openDataSubtitle: {
        id: "PlansOverview.openDataSubtitle",
        defaultMessage: "Freely available for anyone or a business.",
        description: "OpenData subtitle",
    },
    solutionProviderPlan: {
        id: "PlansOverview.solutionProviderPlan",
        defaultMessage: "Premium Plan",
        description: "Solution provider title",
    },
    solutionProviderSubtitle: {
        id: "PlansOverview.solutionProviderSubtitle",
        defaultMessage: "Available for solution providers partnering with OS.",
        description: "Solution provider subtitle",
    },
    psgaPlan: {
        id: "PlansOverview.psgaPlan",
        defaultMessage: "Public Sector Plan",
        description: "Public Sector plan title",
    },
    psgaSubtitle: {
        id: "PlansOverview.psgaSubtitle",
        defaultMessage: "OS OpenData and Premium data <a>for PSGA members</a>.",
        description: "Public Sector plan subtitle",
    },
    feature1: {
        id: "PlansOverview.feature1",
        defaultMessage: "<b>OS OpenData APIs</b> (free access)",
        description: "Feature 1",
    },
    feature2b: {
        id: "PlansOverview.feature2b",
        defaultMessage: "<b>Premium data APIs</b> (paid-for data)",
        description: "Feature 2b",
    },
    feature2c: {
        id: "PlansOverview.feature2c",
        defaultMessage: "<b>Premium data APIs</b> (free access)",
        description: "Feature 2c",
    },
    feature3a: {
        id: "PlansOverview.feature3a",
        defaultMessage: "Development mode",
        description: "Feature 3a",
    },
    feature3b: {
        id: "PlansOverview.feature3b",
        defaultMessage: "<b>Up to £1,000 of free premium data every month</b>",
        description: "Feature 3b",
    },
    feature3c: {
        id: "PlansOverview.feature3c",
        defaultMessage: "Unlimited Premium data downloads",
        description: "Feature 3c",
    },
    feature4b: {
        id: "PlansOverview.feature4b",
        defaultMessage:
            "Development mode - unlimited free Premium data for projects in development mode",
        description: "Feature 4b",
    },
    feature5a: {
        id: "PlansOverview.feature5a",
        defaultMessage: "Free for internal business use",
        description: "Feature 5a",
    },
    feature5c: {
        id: "PlansOverview.feature5c",
        defaultMessage: "Can be used for core business",
        description: "Feature 5c",
    },
    feature6: {
        id: "PlansOverview.feature6",
        defaultMessage: "Not for internal business use",
        description: "Feature 6",
    },
    viewPricingDetails: {
        id: "PlansOverview.viewPricingDetails",
        defaultMessage: "View pricing details",
        description: "View pricing details",
    },
    freeFootnote: {
        id: "PlansOverview.freeFootnote",
        defaultMessage:
            "Free for creating apps, products and services for 3rd parties including the public",
        description: "Free footnote",
    },
});

const openPlan = {
    features: [
        { enabled: true, text: messages.feature1, ref: ["1"] },
        { enabled: false, text: messages.feature2b },
        { enabled: false, text: messages.feature3a },
        { enabled: true, text: messages.feature5a },
    ],
};

const premiumPlan = {
    features: [
        { enabled: true, text: messages.feature1, ref: ["1"] },
        { enabled: true, text: messages.feature2b, ref: ["1", "2"] },
        { enabled: true, text: messages.feature3b, ref: ["3"], premiumStyle: true },
        { enabled: true, text: messages.feature4b, ref: ["4"] },
        { enabled: false, text: messages.feature6, ref: ["5"] },
    ],
    footnotes: [{ text: messages.freeFootnote, ref: ["5"] }],
};

const psgaPlan = {
    features: [
        { enabled: true, text: messages.feature1, ref: ["1", "8"] },
        { enabled: true, text: messages.feature2c, ref: ["1", "7", "8"] },
        { enabled: true, text: messages.feature3c },
        { enabled: true, text: messages.feature5c },
    ],
};

const StyledPlansOverview = styled("div")(
    ({ theme }) => `
    margin: ${theme.spacing(2)} 0 ${theme.spacing(1)} 0;
    display: grid;
    gap: ${theme.spacing(2)};
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 1050px) {
        grid-template-columns: 1fr 1fr;
        justify-content: space-evenly;
        gap: ${theme.spacing(4)};
    }
    @media (max-width: 800px) {
        padding: ${theme.spacing(2)};
        grid-template-columns: 1fr;
        place-items: center;
        & > * {
            width: 80%;
        }
    }
`
);

function PlansOverview() {
    const user = useSelector((state) => state?.user.current?.result);
    const isEaiUser = user?.plan === USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN;
    const isOsInternalUser = user?.plan === USER_OS_INTERNAL_PLAN;
    const isPsgaOrgMember = user?.orgPlan === USER_PSGA_PLAN;
    const hidePlanActions = isPsgaOrgMember || isEaiUser || isOsInternalUser;

    return (
        <StyledPlansOverview>
            <PlanItem
                isActivePlan={user?.plan === USER_OPEN_DATA_PLAN}
                title={messages.openDataPlan}
                subtitle={messages.openDataSubtitle}
                plan={openPlan}
                planType={OPEN_PLAN}
                showActions={!hidePlanActions}
            />
            <PlanItem
                isActivePlan={user?.plan === USER_PREMIUM_DATA_PLAN}
                title={messages.solutionProviderPlan}
                subtitle={messages.solutionProviderSubtitle}
                plan={premiumPlan}
                planType={PREMIUM_PLAN}
                showRecommended={!user || (user.plan === USER_OPEN_DATA_PLAN && user.orgPlan !== CRM_PENDING_PSGA_PLAN)}
                showActions={!hidePlanActions}
            />
            <PlanItem
                isActivePlan={user?.plan === USER_PSGA_PLAN}
                title={messages.psgaPlan}
                subtitle={messages.psgaSubtitle}
                subtitleLink="https://www.ordnancesurvey.co.uk/business-government/sectors/public-sector"
                plan={psgaPlan}
                planType={PSGA_PLAN}
                showActions={!hidePlanActions}
            />
        </StyledPlansOverview>
    );
}

export default PlansOverview;
