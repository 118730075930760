import {border1, contentPadding, osColour} from 'omse-components';

export function styles(theme) {
    return {
        root: {
            paddingTop: contentPadding.top,
            paddingRight: contentPadding.right,
            paddingBottom: contentPadding.bottom,
            paddingLeft: contentPadding.left,
            borderBottom: border1,
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile
            }
        },
        rootWithBackLink: {
            paddingTop: contentPadding.top - contentPadding.backLink,
            paddingRight: contentPadding.right,
            paddingBottom: contentPadding.bottom,
            paddingLeft: contentPadding.left,
            borderBottom: border1,
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile
            }
        },
        innerHeader: {
            // We don't want the button to move all the way to the right on large screens, so we put the actual header
            // content into a nested div, and apply maxWidth to that. The outer header does use the whole width, and
            // that makes the border underneath the header look correct.
            display: 'flex',
            gap: theme.spacing(2),

            // Similar to the overall max, but a bit bigger to give the button some room. This matches the API Project list.
            maxWidth: contentPadding.maxWidth + contentPadding.left * 3,

            justifyContent: 'space-between',
            alignItems: 'start',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        },
        title: {
            flex: '1 1 auto',
            gap: '1em',
            display: 'flex',
            alignItems: 'center'
        },
        content: {
            flex: '1 1 auto',
            paddingLeft: contentPadding.left,
            paddingRight: contentPadding.right,
            maxWidth: contentPadding.maxWidth,
            marginTop: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                marginTop: 0
            }
        },
        contentWithMobilePadding: {
            flex: '1 1 auto',
            paddingLeft: contentPadding.left,
            paddingRight: contentPadding.right,
            maxWidth: contentPadding.maxWidth,
            marginTop: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile,
                marginTop: 0
            }
        },
        widerContentWithMobilePadding: {
            flex: '1 1 auto',
            maxWidth: 1468,
            paddingLeft: contentPadding.left,
            paddingRight: contentPadding.right,
            marginTop: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile,
                marginTop: 0
            }
        },
        controls: {
            [theme.breakpoints.down('sm')]: {
                padding:  '24px 16px'
            }
        },
        searchOption: {
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
            padding: '0 0 16px 0',
            maxWidth: 560,
            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.spacing(1.5)
            }
        },
        button: {
            height: 28,
            width: 28,
            minWidth: 28,
            padding: 0,
            marginRight: 10,
            '&:hover': {
                background: 'transparent'
            }
        },
        filterControls: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                display: 'block'
            }
        },
        filterControl: {
            minWidth: 194,
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginTop: theme.spacing(1)
            },
            marginRight: theme.spacing(2)
        },
        filterControlOutlined: {
            paddingRight: '10px !important',
            [theme.breakpoints.down('sm')]: {
                marginRight: 0
            }
        },
        downloads: {
            marginTop: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                marginTop: 0
            }
        },
        notification: {
            [theme.breakpoints.down('sm')]: {
                margin: '0 16px'
            }
        },
        loader: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(2),
                marginBottom: theme.spacing(2)
            }
        },
        faqLinkContainer: {
            marginTop: theme.spacing(2)
        },
        icon: {
            color: osColour.primary.berry,
            marginRight: theme.spacing(0.5)
        },
        dependencyFilter: {
            background: osColour.neutral.clouds,
            padding: theme.spacing(2)
        },
        noticeButton: {
            minWidth: 'auto'
        }
    };
}
