import {OPEN_NEW_PROJECT_DIALOG, CLOSE_NEW_PROJECT_DIALOG} from "./actions";

export function reducer(state = {open: false, addProduct: null, dialogId: null}, action) {
    switch (action.type) {
        case (OPEN_NEW_PROJECT_DIALOG):
            return ({open: true, addProduct: action.addProduct, dialogId: action.componentId});
        case (CLOSE_NEW_PROJECT_DIALOG):
            return ({open: false, addProduct: null, dialogId: null});
        default:
            return state;
    }
}