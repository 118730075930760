import React from 'react';
import Header from "./Header";
import Content from './Content';
import DocLinks from "./DocLinks";
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import ResponseCodes from "./ResponseCodes";
import styles from './style';
import Authentication from "./Authentication";
import ParametersTable from './ParametersTable';
import TypesTable from './TypesTable';
import routes from "../../util/routes";
import Document from './Document';
import TextBox from '../../components/TextBox';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

const namesNearestParameters = [
    [<InlineCodeSnippet>key</InlineCodeSnippet>, 'required', 'string', 'free text', 'Your unique means of authentication provided by the self-service portal to access the API.'],
    [<InlineCodeSnippet>point</InlineCodeSnippet>, 'required', 'string', 'A BNG coordinate set', 'A set of British National Grid coordinates to which the nearest record in a straight line should be found. The precision of the coordinates is to two decimal places (i.e. 1cm accuracy).'],
    [<InlineCodeSnippet>radius</InlineCodeSnippet>, 'optional', 'double', <><InlineCodeSnippet>0.01</InlineCodeSnippet> - <InlineCodeSnippet>1000</InlineCodeSnippet></>, <>The radius in metres to search within. Maximum is 1,000 metres. If the radius exceeds this amount then an error message will be returned (see below). The precision of the distance is to two decimal places (i.e. 1 cm accuracy). Default: <InlineCodeSnippet>100</InlineCodeSnippet>.</>],
    [<InlineCodeSnippet>format</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>JSON</InlineCodeSnippet><br /><InlineCodeSnippet>XML</InlineCodeSnippet></>, <>The format the response will be returned in. Default: <InlineCodeSnippet>JSON</InlineCodeSnippet>.</>],
    [<InlineCodeSnippet>fq</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>{'local_type:<local type>'}</InlineCodeSnippet></>, 'Filters the results by local_type. Available local types are found below.']
];

const namesFindParameters = [
    [<InlineCodeSnippet>key</InlineCodeSnippet>, 'required', 'string', 'free text', 'Your unique means of authentication provided by the self-service portal to access the API.'],
    [<InlineCodeSnippet>query</InlineCodeSnippet>, 'required', 'string', 'free text', 'The free text search parameter.'],
    [<InlineCodeSnippet>format</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>JSON</InlineCodeSnippet><br /><InlineCodeSnippet>XML</InlineCodeSnippet></>, <>The format the response will be returned in. Default: <InlineCodeSnippet>JSON</InlineCodeSnippet>.</>],
    [<InlineCodeSnippet>maxresults</InlineCodeSnippet>, 'optional', 'integer', <><InlineCodeSnippet>1</InlineCodeSnippet> - <InlineCodeSnippet>100</InlineCodeSnippet></>, <>The maximum number of results to return. Default: <InlineCodeSnippet>100</InlineCodeSnippet>.</>],
    [<InlineCodeSnippet>offset</InlineCodeSnippet>, 'optional', 'integer', 'an integer', 'Offset the list of returned results by this amount.'],
    [<InlineCodeSnippet>bounds</InlineCodeSnippet>, 'optional', 'string', '<BoundingBox>', <>Biases the results to a certain area. The {'<BoundingBox>'} is a British National Grid bounding box in the form: XMIN,YMIN,XMAX,YMAX e.g. <InlineCodeSnippet>&bounds=414000,114000,414100,114100</InlineCodeSnippet>.</>],
    [<InlineCodeSnippet>fq</InlineCodeSnippet>, 'optional', 'string', <><InlineCodeSnippet>{'local_type:<local type>'}</InlineCodeSnippet></>, <>Filters the results by bounding box or local_type. The bounding box is a British National Grid bounding box in the form: XMIN,YMIN,XMAX,YMAX e.g. <InlineCodeSnippet>&fq=BBOX:414000,114000,414100,114100</InlineCodeSnippet>. Available local types are found below.</>]
];

const localTypes = [['transportNetwork', 'Airfield'],
    ['transportNetwork', 'Airport'],
    ['hydrography', 'Bay'],
    ['landcover', 'Beach'],
    ['transportNetwork', 'Bus_Station'],
    ['hydrography', 'Channel'],
    ['other', 'Chemical_Works'],
    ['landform', 'Cirque_Or_Hollow'],
    ['populatedPlace', 'City'],
    ['landform', 'Cliff_Or_Slope'],
    ['transportNetwork', 'Coach_Station'],
    ['landform', 'Coastal_Headland'],
    ['other', 'Electricity_Distribution'],
    ['other', 'Electricity_Production'],
    ['hydrography', 'Estuary'],
    ['other', 'Further_Education'],
    ['other', 'Gas_Distribution_or_Storage'],
    ['landform', 'Group_Of_Islands'],
    ['populatedPlace', 'Hamlet'],
    ['transportNetwork', 'Harbour'],
    ['transportNetwork', 'Helicopter_Station'],
    ['transportNetwork', 'Heliport'],
    ['other', 'Higher_or_University_Education'],
    ['landform', 'Hill_Or_Mountain'],
    ['landform', 'Hill_Or_Mountain_Ranges'],
    ['other', 'Hospice'],
    ['other', 'Hospital'],
    ['hydrography', 'Inland_Water'],
    ['landform', 'Island'],
    ['other', 'Medical_Care_Accommodation'],
    ['transportNetwork', 'Named_Road'],
    ['other', 'Non_State_Primary_Education'],
    ['other', 'Non_State_Secondary_Education'],
    ['transportNetwork', 'Numbered_Road'],
    ['other', 'Oil_Distribution_or_Storage'],
    ['other', 'Oil_Refining'],
    ['other', 'Oil_Terminal'],
    ['landform', 'Other_Coastal_Landform'],
    ['landcover', 'Other_Landcover'],
    ['landform', 'Other_Landform'],
    ['populatedPlace', 'Other_Settlement'],
    ['transportNetwork', 'Passenger_Ferry_Terminal'],
    ['transportNetwork', 'Port_Consisting_of_Docks_and_Nautical_Berthing'],
    ['other', 'Postcode'],
    ['other', 'Primary_Education'],
    ['transportNetwork', 'Railway'],
    ['transportNetwork', 'Railway_Station'],
    ['transportNetwork', 'Road_User_Services'],
    ['hydrography', 'Sea'],
    ['other', 'Secondary_Education'],
    ['transportNetwork', 'Section_Of_Named_Road'],
    ['transportNetwork', 'Section_Of_Numbered_Road'],
    ['other', 'Special_Needs_Education'],
    ['landform', 'Spot_Height'],
    ['populatedPlace', 'Suburban_Area'],
    ['hydrography', 'Tidal_Water'],
    ['populatedPlace', 'Town'],
    ['transportNetwork', 'Tramway'],
    ['landcover', 'Urban_Greenspace'],
    ['landform', 'Valley'],
    ['transportNetwork', 'Vehicular_Ferry_Terminal'],
    ['transportNetwork', 'Vehicular_Rail_Terminal'],
    ['populatedPlace', 'Village'],
    ['hydrography', 'Waterfall'],
    ['landcover', 'Wetland'],
    ['landcover', 'Woodland_Or_Forest']];

function NamesDetail(props) {
    return <Document>
        <Header back={true}>OS Names API: Technical specification</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains the content of the OS Names API, and the methods that you can use to call it.
                </p>
                <p>
                    The OS Names API is a geographic directory containing basic information about identifiable places. Those places are divided into themes, but the name of the place is the key property we use in queries.
                </p>
                <p>
                    Within OS Names, place names aren’t unique. Extra location details are provided to help users refine their queries and accurately identify the named place they’re interested in. These details include postcode district, populated place, district/borough, county/unitary authority, European region and country.
                </p>
                <p>
                    We also recognise that a place may have more than one name: one, such as Cardiff (in English) and then another, Caerdydd (in Welsh).
                </p>
                <p>
                    Our specification will extend the Infrastructure for Spatial Information in the European Community (INSPIRE) Geographical Names theme to ensure it’s compliant with European open data initiatives.
                </p>
            </Typography>
            <Typography variant='h1' component={'h2'}>Technical Detail</Typography>
            <Typography variant='body1' paragraph={true}>
                The OS Names API is a RESTful API, which returns queries to the service in either XML or JSON. The service offers two resources: ‘find’ and ‘nearest’.
                Find is a string-based lookup service, while nearest uses a pair of BNG coordinates to return the nearest features.            </Typography>
            <Authentication gettingStartedRoute={routes.namesIntro}/>
            <Typography variant='h2'>Encryption</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    As of October 2018, all calls must be made using a secure hypertext encryption (HTTPS).
                </p>
                <p>
                    Ordnance Survey APIs no longer support calls made using SSL v2/v3 or TLS v1.0/v1.1.
                    Thus, all calls made to any of our APIs must support TLS v1.2 as of 24th October 2018 in line with
                    current cyber security recommendations.
                </p>
            </Typography>
            <ResponseCodes/>
            <Typography variant='h2'>Operation</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    OS Names API has two types of request:
                </p>
                <ul>
                    <li><b>Find</b> can locate a feature using just its name.</li>
                    <li><b>Nearest</b> can find the features closest to a given point.</li>
                </ul>
            </Typography>

            <Typography variant='h3'>Find</Typography>
            <Typography variant='body1' paragraph={true} component={'div'}>
                <p>A free string text search of OS Names, intended to be an ambiguous/fuzzy search.</p>
                <TextBox
                    text="https://api.os.uk/search/names/v1/find?query=so16+0as&key="
                    label="OS Names API - 'Find' Endpoint"
                    variant="code"
                />
                <ParametersTable classes={props.classes} values={namesFindParameters}/>
            </Typography>

            <Typography variant='h3'>Nearest</Typography>
            <Typography variant='body1' paragraph={true} component={'div'}>
                <p>The nearest resource returns the closest address to a given point.
                    This is done by using a pair of BNG coordinates (to two decimal places or less) as the input.
                    The property will only be considered to be included by the search if the Address Seed is in range of the search,
                    regardless of the property’s physical boundaries.</p>
                <TextBox
                    text="https://api.os.uk/search/names/v1/nearest?point=440200,449300&key="
                    label="OS Names API - 'Nearest' Endpoint"
                    variant="code"
                />
                <ParametersTable classes={props.classes} values={namesNearestParameters}/>
            </Typography>

            <Typography variant='h2'>Filtering</Typography>
            <Typography variant='body1' paragraph={true} component={'div'}>
                <p>It is possible to filter your search using multiple local types given in the table below. For example:</p>
                <TextBox
                    text="https://api.os.uk/search/names/v1/find?query=Glasgow&fq=LOCAL_TYPE:City LOCAL_TYPE:Bay&key="
                    label="OS Names API - 'Find' Endpoint with Additional Filtering"
                    variant="code"
                />
                <TypesTable classes={props.classes} values={localTypes}/>
            </Typography>
            <DocLinks product='names' hasGettingStarted={true} hasDemo={true}/>
        </Content>
    </Document>
}

export default withStyles(styles)(NamesDetail);