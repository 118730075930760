import React from 'react';
import Header from "../Header";
import Content from '../Content';
import prohibitedUse1 from './brandLogoImg/OS_guidelines_API-logo-prohibited-use-1@2x.png';
import prohibitedUse2 from './brandLogoImg/OS_guidelines_API-logo-prohibited-use-2@2x.png';
import prohibitedUse3 from './brandLogoImg/OS_guidelines_API-logo-prohibited-use-3@2x.png';
import prohibitedUse4 from './brandLogoImg/OS_guidelines_API-logo-prohibited-use-4@2x.png';
import prohibitedUse5 from './brandLogoImg/OS_guidelines_API-logo-prohibited-use-5@2x.png';
import prohibitedUse6 from './brandLogoImg/OS_guidelines_API-logo-prohibited-use-6@2x.png';
import prohibitedUse7 from './brandLogoImg/OS_guidelines_API-logo-prohibited-use-7@2x.png';
import prohibitedUse8 from './brandLogoImg/OS_guidelines_API-logo-prohibited-use-8@2x.png';
import withStyles from 'react-jss';
import BrandImage from './BrandImage';
import { styles } from './BrandStyling';
import {defineMessages} from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Document from '../Document';

const messages = defineMessages({
    prohibitedUseSubcaption1: {
        id: 'ProhibitedUse.prohibitedUseSubcaption1',
        defaultMessage: 'Don’t stretch or distort the logo.',
        description: 'Stretching and distorting'
    },
    prohibitedUseSubcaption2: {
        id: 'ProhibitedUse.prohibitedUseSubcaption2',
        defaultMessage: 'Don’t apply transparency and ensure contrast is sufficient.',
        description: 'Transparency and contrast'
    },
    prohibitedUseSubcaption3: {
        id: 'ProhibitedUse.prohibitedUseSubcaption3',
        defaultMessage: 'Don’t add drop-shadows or any other effects.',
        description: 'Drop-shadows and other effects'
    },
    prohibitedUseSubcaption4: {
        id: 'ProhibitedUse.prohibitedUseSubcaption4',
        defaultMessage: 'Don’t separate the logomark and the wordmark.',
        description: 'Separating logomark and wordmark'
    },
    prohibitedUseSubcaption5: {
        id: 'ProhibitedUse.prohibitedUseSubcaption5',
        defaultMessage: 'Don’t change the colours in any way.',
        description: 'Changing colours'
    },
    prohibitedUseSubcaption6: {
        id: 'ProhibitedUse.prohibitedUseSubcaption6',
        defaultMessage: 'Don’t use the white logo on illustrated maps.',
        description: 'White logo on illustrated maps'
    },
    prohibitedUseSubcaption7: {
        id: 'ProhibitedUse.prohibitedUseSubcaption7',
        defaultMessage: 'Don’t use the coloured logo on satellite or photographic styled maps.',
        description: 'Coloured logo on sattelite and photographic maps'
    },
    prohibitedUseSubcaption8: {
        id: 'ProhibitedUse.prohibitedUseSubcaption8',
        defaultMessage: 'Don’t place on a block colour background.',
        description: 'Block colour background'
    },
});

function ProhibitedUse(props) {

    const {classes} = props;
    const containerClasses = [classes.mainContainer, classes.eightItems];

    return (
        
        <Document>
            <Header back={true}>Prohibited use</Header>
           
            <Content>
                <div className={classNames(containerClasses)}>
                    <BrandImage 
                        image={prohibitedUse1} 
                        alt='Example of prohibited use of the Ordnance Survey logo: stretched and distorted'
                        subcaption={messages.prohibitedUseSubcaption1}
                    />
                    <BrandImage 
                        image={prohibitedUse2} 
                        alt='Example of prohibited use of the Ordnance Survey logo: logo has transparency applied' 
                        subcaption={messages.prohibitedUseSubcaption2}
                    />
                    <BrandImage 
                        image={prohibitedUse3} 
                        alt='Example of prohibited use of the Ordnance Survey logo: drop-shadow added to logo outline' 
                        subcaption={messages.prohibitedUseSubcaption3}
                    />
                    <BrandImage 
                        image={prohibitedUse4} 
                        alt='Example of prohibited use of the Ordnance Survey logo: separation of logomark and wordmark' 
                        subcaption={messages.prohibitedUseSubcaption4}
                    />
                    <BrandImage 
                        image={prohibitedUse5} 
                        alt='Example of prohibited use of the Ordnance Survey logo: hue/colours altered from brand guidelines' 
                        subcaption={messages.prohibitedUseSubcaption5}
                    />
                    <BrandImage 
                        image={prohibitedUse6} 
                        alt='Example of prohibited use of the Ordnance Survey logo: use of white-fill logo on an illustrated map'
                        subcaption={messages.prohibitedUseSubcaption6}
                    />
                    <BrandImage 
                        image={prohibitedUse7} 
                        alt='Example of prohibited use of the Ordnance Survey logo: use of colour logo on a satellite map' 
                        subcaption={messages.prohibitedUseSubcaption7}
                    />
                    <BrandImage 
                        image={prohibitedUse8} 
                        alt='Example of prohibited use of the Ordnance Survey logo: use of logo in front of a block-colour'
                        subcaption={messages.prohibitedUseSubcaption8}
                    />
                </div>
            </Content>
        </Document>
    )
}

ProhibitedUse.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ProhibitedUse)