import React, {useState, useEffect, useCallback, useMemo, Fragment} from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';
import {loadPolygon, clearLoadedPolygon} from "../../../../../modules/polygon";
import {loadDataPackage, clearLoadedDataPackage} from "../../../../../modules/dataPackages/actions";
import {CommonDialog, CommonDialogActions} from 'omse-components';
import {List} from '@mui/material';
import {setMapTool} from "../../../../../modules/toolbar";
import FileCollection from "./FileCollection";
import FileList from "./FileList";
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import {isMultiPolygon, isPolygon} from "../ol/feature";
import {fromExtent} from 'ol/geom/Polygon';
import WKT from 'ol/format/WKT';
import GeoJSON from 'ol/format/GeoJSON';
import SearchResults from "./SearchResults";

import styles from './styles';
import {checkFeatureMerging} from "../ol/layer";
import SearchBox from "../../../../../components/SearchBox";
import polygonCollectionSearch, {fileMatches} from "../../../../../util/search/polygonCollectionSearch";
import usePolygonCatalogue from "./usePolygonCatalogue";
import useLoadedPolygon from "./useLoadedPolygon";
import { Vector as VectorSource } from "ol/source";

const messages = defineMessages({
    title: {
        id: 'LoadPolygonModal.title',
        defaultMessage: 'Load from polygon library',
        description: 'Title for the load polygon modal'
    },
    caveat: {
        id: 'LoadPolygonModal.caveat',
        defaultMessage: 'The OS Predefined polygons are provided to facilitate the ordering process; they do not represent definitive alignments of boundaries.',
        description: 'Caveat shown on the load polygon modal'
    },
    load: {
        id: 'LoadPolygonModal.load',
        defaultMessage: 'Load',
        description: 'Label for the load button'
    },
    error: {
        id: 'LoadPolygonModal.error',
        defaultMessage: 'There was an error loading your polygon. Please try again later and contact support if the problem continues.',
        description: 'Message shown if the polygon could not be loaded.'
    },
    catalogueError: {
        id: 'LoadPolygonModal.catalogueError',
        defaultMessage: 'There was an error loading the polygon library. Please try again later and contact support if the problem continues.',
        description: 'Message shown if the polygon library could not be loaded.'
    },
    maximumCoordsError: {
        id: 'LoadPolygonModal.maximumCoordsError',
        defaultMessage: "To ensure good performance the OS Data Hub imposes a limit of {max} vertices. The polygon you are loading would mean that limit is exceeded. Please simplify your area of interest and try again.",
        description: 'Error message when too many coords loaded'
    },
    maximumFeaturesError: {
        id: 'LoadPolygonModal.maximumFeaturesError',
        defaultMessage: "To ensure good performance the OS Data Hub imposes a limit of ​​​​{max}​​​​​​​​ polygons. The polygon you are loading would mean that limit is exceeded. Please simplify your area of interest and try again.",
        description: 'Error message when too many features loaded'
    },
    placeholder: {
        id: 'LoadPolygonModal.placeholder',
        defaultMessage: "Search for a file",
        description: 'Placeholder text for the search box'
    }
});

export default function LoadPolygonModal(props) {
    const {drawingLayer, map, currentPolygonLayer} = props;
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const classes = styles();
    const dispatch = useDispatch();
    const [currentPath, setCurrentPath] = useState('');
    const [search, setSearch] = useState('');

    const [catalogue, paths, catalogueError] = usePolygonCatalogue()
    const [polygon, polygonLoading, polygonError] = useLoadedPolygon(file);

    const source = drawingLayer && drawingLayer.getSource();

    const onClose = useCallback(() => {
        dispatch(setMapTool());
    }, [dispatch]);

    const zoomToExtent = useCallback(() => {
        let zoomSource = new VectorSource({
            features: [...drawingLayer.getSource().getFeatures()]
        });
        if (currentPolygonLayer) {
            zoomSource.addFeatures(currentPolygonLayer.getSource().getFeatures())
        }

        if (zoomSource.getFeatures().length > 0) {
            let geom = fromExtent(zoomSource.getExtent());
            geom.scale(1.3);
            map.getView().fit(geom, {size: map.getSize()});
        }
    }, [map, drawingLayer, currentPolygonLayer]);

    useEffect(() => {
        if(currentPath === '') {
            setCurrentPath(paths.savedPolygons);
        }
    }, [currentPath, paths.savedPolygons]);

    useEffect(() => {
        if(catalogueError) {
            setError({message: {...messages.catalogueError}});
        }
    }, [catalogueError]);

    useEffect(() => {
        if(polygonError) {
            setError({message: {...messages.error}});
        }
    }, [polygonError]);

    useEffect(() => {
        return function cleanUp() {
            // If we managed to load the full details of a polygon or data package then we should clear them as the
            // component shuts down. This ensures that another load won't find the data and assume it is up to date.
            dispatch(clearLoadedPolygon());
            dispatch(clearLoadedDataPackage());
        }
    }, [dispatch]);

    useEffect(() => {
        if(polygon) {
            let features;
            if(Array.isArray(polygon)) {
                // The geometries are stored as a raw array. Each entry is either a polygon or a multipolygon.

                features = polygon.map(coords => {
                    // If the coordinates are an array of linear rings then we have a polygon, but if the coordinates are
                    // an array of polygons then we have a multipolygon.
                    let geometry;
                    if(isMultiPolygon(coords)) {
                        geometry = new MultiPolygon(coords);
                    } else if(isPolygon(coords)) {
                        geometry = new Polygon(coords);
                    }
                    return new Feature(geometry);
                });

            } else if(typeof polygon === 'string') {
                // The geometry is stored as WKT
                const reader = new WKT();
                features = reader.readFeatures(polygon, { dataProjection: '27700', featureProjection: '27700' });

            } else {
                // The geometry is stored as GeoJSON
                const reader = new GeoJSON({ dataProjection: '27700', featureProjection: '27700' });
                features = reader.readFeatures(polygon);
            }

            let sources = [source];

            checkFeatureMerging(sources, features, messages.maximumCoordsError, messages.maximumFeaturesError).then(f => {
                source.clear();
                source.addFeatures(f);
                zoomToExtent();
                onClose();
            }, error => {
                setError(error);
            });
        }
    }, [polygon, onClose, source, zoomToExtent, currentPolygonLayer]);

    const onConfirm = useCallback(function onConfirm() {
        // If the file was found via search then the path is stored on the file itself. If we are just browsing
        // the collections then the file must belong to the current path.
        let path = file.path || currentPath;

        if(path.startsWith(paths.predefinedPolygons)) {
            // The predefined catalogue is full of simple strings which we can use as the id, but if we did a search
            // to find it then it will have an id. We need to check both.
            path = path.substr(paths.predefinedPolygons.length + 1);
            const id = file.id || file;
            dispatch(loadPolygon(id, path));
        } else if(path.startsWith(paths.savedPolygons)) {
            // We load predefined polygons by id, with no path needed
            dispatch(loadPolygon(file.id));
        } else {
            // We need to load the data package
            dispatch(loadDataPackage(file.id));
        }
    }, [currentPath, file, dispatch, paths])

    // Find the files pointed to by the current path. We use the filter call to remove null/empty path steps.
    const steps = currentPath.split('/').filter(path => path);
    let currentCollection = catalogue;
    for(const step of steps) {
        currentCollection = currentCollection.collections.find(c => c.path === step);
    }
    const files = currentCollection.files;

    // Each time the search changes we update the search results. There might be other reasons to re-draw the
    // component, so we use a memo to try and minimise render time.
    const searchResults = useMemo(() => {
        if(!search) {
            return null;
        }
        return polygonCollectionSearch(search, catalogue);
    }, [search, catalogue]);

    // If we had a file/search selected, and the path/search has changed, then we need to clear that selection
    useEffect(() => {
        if(file) {
            if(searchResults) {
                if(!searchResults.find(f => fileMatches(f, file))) {
                    setFile(null);
                }
            } else {
                if(!files.find(f => fileMatches(f, file))) {
                    setFile(null);
                }
            }
        }
    }, [searchResults, files, file]);

    const setSelectedFile = useCallback(function setSelectedFile(newFile) {
        if(fileMatches(newFile, file)) {
            // This file was already selected. Go ahead and load the file
            onConfirm();
        } else {
            setFile(newFile);
        }
    }, [setFile, onConfirm, file]);

    const actions = <CommonDialogActions confirmLabel={messages.load}
                                         confirmAllowed={!!file}
                                         onClose={onClose}
                                         onConfirm={onConfirm}
                                         working={polygonLoading}
                                         error = {error}/>;

    return <CommonDialog onClose={onClose}
                         title={messages.title}
                         subtitle={messages.caveat}
                         actions={actions}
                         contentClassName={classes.dialogContent}
                         muiClasses={{paperWidthSm: classes.muiDialogRoot}}>
        <div className={classes.inputWrapper}>
            <SearchBox placeholder={messages.placeholder}
                       search={search}
                       setSearch={setSearch}
                       className={classes.search}
                       limitWidth={true}
            />
        </div>
        <div className={classes.listWrapper}>
            {
                searchResults !== null &&
                <SearchResults searchResults={searchResults}
                               selectedFile={file}
                               setSelectedFile={setSelectedFile}
                               resetSearch={() => setSearch('')}
                />
            }
            {
                searchResults === null &&
                <Fragment>
                    <List className={classes.folderList}>
                        {
                            catalogue.collections.map((collection, index) =>
                                <FileCollection key={collection.path}
                                                initialOpen={index === 0}
                                                collection={collection}
                                                selectedPath={currentPath}
                                                setSelected={setCurrentPath}/>)
                        }
                    </List>
                    <FileList files={files}
                              selectedFile={file}
                              setSelectedFile={setSelectedFile}
                    />
                </Fragment>
            }
        </div>
    </CommonDialog>
}
