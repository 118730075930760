import React, {Fragment} from 'react';
import SiteNotices from './siteNotices/SiteNotices';
import {activeNotices as userActiveNotices, getContent as userGetContent} from './siteNotices/data/user';
import {activeNotices as supportActiveNotices, getContent as supportGetContent} from './siteNotices/data/support';

export default function UserSiteNotices() {
    return <Fragment>
        <SiteNotices activeNotices={supportActiveNotices} getContent={supportGetContent} />
        <SiteNotices activeNotices={userActiveNotices} getContent={userGetContent} />
    </Fragment>
}