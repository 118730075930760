import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getPremiumCatalogue} from '../modules/premium/actions';
import {ERROR, LOADING, NOT_NEEDED} from '../constants/state';
import {COM3_CATALOGUE} from '../../shared/catalogues';
import {isIncludedInCatalogue} from '../util/product';
import {hasCom3Catalogue} from "../util/permissions";

export default function useProductTerms(productId) {
    const user = useSelector(state => state.user.current.result);
    const catalogue = useSelector(state => state.premium.catalogue.result);
    const error = useSelector(state => state.premium.catalogue.error);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!catalogue) {
            dispatch(getPremiumCatalogue());
        }
    }, [catalogue, dispatch]);
    
    if (error) {
        return ERROR;
    }

    if (!catalogue) {
        return LOADING;
    }

    // Applicable to users and products of EAI.
    if(hasCom3Catalogue(user)) {
        const result = catalogue.items
            .filter(product => isIncludedInCatalogue(product, COM3_CATALOGUE))
            .find(product => product.id === productId);

        if (result?.productTerms) {
            return result;
        }
    }
    return NOT_NEEDED;
}
