import React, {Component} from 'react';
import withStyles from 'react-jss';
import {FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import {osColour} from 'omse-components';
import {defineMessages} from 'react-intl';
import routes, {privacyPolicyAddress, cookiesAddress, generalTermsAddress} from '../util/routes';
import Link from '../components/Link';


const messages = defineMessages({
    copyright: {
        id: 'Footer.copyright',
        defaultMessage: '© Ordnance Survey {year}',
        description: 'Copyright text'
    },
    privacyPolicyLink: {
        id: 'Footer.privacyPolicyLink',
        defaultMessage: 'Privacy policy',
        description: 'Privacy policy link text'
    },
    cookiesLink: {
        id: 'Footer.cookiesLink',
        defaultMessage: 'Cookies',
        description: 'Cookies link text'
    },
    generalTermsLink: {
        id: 'Footer.generalTermsLink',
        defaultMessage: 'OS Website Terms',
        description: 'Text for the link to the OS EULA'
    },
    legalTermsLink: {
        id: 'Footer.legalTermsLink',
        defaultMessage: 'Legal Terms',
        description: 'Legal Terms link'
    }

});

const styles = theme => ({
    footer: {
        background: osColour.neutral.clouds,
        borderTop: `solid ${osColour.neutral.mist} 1px`,
        color: osColour.neutral.stone,
        textAlign: 'right',
        paddingRight: theme.spacing(2.5),
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        flex: '0 0 auto',
        zIndex: 1,

        // Avoid clashing with the cookie banner on small screens
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(5)
        },

        '& a': {
            marginLeft: theme.spacing(1.5),
            height: 20,
            textDecoration: 'none',
            color: osColour.neutral.stone,

            '&:hover': {
                textDecoration: 'underline',
            },
        },

        '& span': {
            height: 20,
        },

        '& p': {
            lineHeight: 1.7,
        }
    },
    spacer: {
        paddingLeft: theme.spacing(1.5)
    }
});

export class Footer extends Component {
    render() {
        const {classes} = this.props;
        return (<footer className={classes.footer}>
                <Typography variant={'body1'}>
                    <span>
                        <FormattedMessage {...messages.copyright} values={{year: new Date().getFullYear()}}/>
                    </span>

                    <span className={classes.spacer}>&nbsp;|&nbsp;</span>
                    
                    <a href={privacyPolicyAddress} target={"_blank"}  rel="noopener noreferrer">
                        <FormattedMessage {...messages.privacyPolicyLink} />
                    </a>

                    <span className={classes.spacer}>&nbsp;|&nbsp;</span>

                    <a href={cookiesAddress} target={"_blank"}  rel="noopener noreferrer">
                        <FormattedMessage {...messages.cookiesLink} />
                    </a>

                    <span className={classes.spacer}>&nbsp;|&nbsp;</span>
                    
                    <a href={generalTermsAddress} target={"_blank"}  rel="noopener noreferrer">
                        <FormattedMessage {...messages.generalTermsLink} />
                    </a>

                    <span className={classes.spacer}>&nbsp;|&nbsp;</span>

                    <Link path={routes.legalOverview}>
                        <FormattedMessage {...messages.legalTermsLink} />
                    </Link>

                </Typography>
            </footer>)
    }
}

export default withStyles(styles)(Footer);