import React from 'react';
import PropTypes from 'prop-types';
import {goToLandingPage} from "../../../util/routes";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {defineMessages, FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router';
import TitledSingleColWithButton from '../../../components/layout/TitledSingleColWithButton';

const componentName = 'UserIssuePlanDenied';

export const messages = defineMessages({
    youAreAMember: {
        id: componentName + '.youAreAMember',
        defaultMessage: 'You are a member of this organisation',
        description: 'you are a member'
    },
    weCantGiveYouAccess: {
        id: componentName + '.weCantGiveYouAccess',
        defaultMessage: 'We can’t give you access to the {plan} because your organisation is signed-up to use a different plan.',
        description: 'we cant give you access'
    },
    ifYouThink: {
        id: componentName + '.ifYouThink',
        defaultMessage: 'If you think your organisation should have access to the {plan}, contact your organisation\'s OS Data Hub account administrator {askToApply}.',
        description: 'if you think'
    },
    askThemToApply: {
        id: componentName + '.askThemToApply',
        defaultMessage: 'and ask them to apply',
        description: 'ask them to apply'
    },
    inTheMeantime: {
        id: componentName + '.inTheMeantime',
        defaultMessage: 'In the meantime, you can continue to access OS Data Hub using your existing plan.',
        description: 'in the meantime'
    },
    continueWithExistingPlan: {
        id: componentName + '.continueWithExistingPlan',
        defaultMessage: 'Continue with existing plan',
        description: 'continue with existing plan'
    }
});

export default function UserIssuePlanDenied(props) {
    const {title, orgName, planName, applyUrl} = props;
    const history = useHistory();
    return <TitledSingleColWithButton title={title}
                buttonAction={() => goToLandingPage(history)}
                buttonMessage={messages.continueWithExistingPlan}>

        <Typography variant='body2' paragraph={true}>
            <FormattedMessage {...messages.youAreAMember}/>
        </Typography>

        <Typography variant='body1' paragraph={true} id='cantGiveYouAccess.org' values={{plan: planName}}>
            {orgName}
        </Typography>

        <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...messages.weCantGiveYouAccess} values={{plan: planName}} />
        </Typography>

        <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...messages.ifYouThink} values={{
                askToApply: <Link target="_blank" rel="noopener noreferrer" href={applyUrl} underline='always'>
                        <FormattedMessage {...messages.askThemToApply} />
                </Link>,
                plan: planName
            }}/>
        </Typography>

        <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...messages.inTheMeantime}/>
        </Typography>
    </TitledSingleColWithButton>
}

UserIssuePlanDenied.propTypes = {
    title: PropTypes.object.isRequired,
    orgName: PropTypes.string.isRequired,
    planName: PropTypes.string.isRequired,
    applyUrl: PropTypes.string.isRequired
}