import React from "react";
import { ExternalLink } from "omse-components";
import OTAImage0 from './OTAImage/OTAImage0.png'
import OTAImage1 from './OTAImage/OTAImage1.png'
import OTAImage2 from './OTAImage/OTAImage2.png'
import Typography from "@mui/material/Typography";
import InlineCodeSnippet from "../../../components/InlineCodeSnippet";

const OTACodePlayground = () => {
    return (
        <>
            <Typography variant='h1' component={'h2'}>Instructions to interact with code playground </Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                    <p>In this section, we demonstrate how to quickly get started with the OS NGD API – Tiles through our interactive code examples for OpenLayers.</p>
                    <p>Other examples are available to show you how to implement the API in other common web mapping libraries such as Leaflet and MapLibre GL JS.
                    </p>
                    <ol>
                        <li>Navigate to the <ExternalLink
                            type="generic"
                            href="https://labs.os.uk/public/osngd/os-ngd-api-tiles/"
                            message="OS NGD API – Tiles code examples"
                        />.</li>
                        <li>Under the Code Playgrounds sub-heading, navigate to the OpenLayers (EPSG: 3857) – Basic Map example.</li>

                        <li>To interact with the code examples, you will need a valid API key.
                            On <ExternalLink
                                type="generic"
                                href="/"
                                message="OS Data Hub"
                            /> navigate to “API Dashboard” {'>'} “My projects”,
                            open the project page and copy the “Project API Key”.
                            <img src={OTAImage0} alt='A user copying their API key from a project in OS Data Hub.' />
                        </li>

                        <li>
                            <p>Paste the key into the <InlineCodeSnippet>apiKey</InlineCodeSnippet> variable, replacing <InlineCodeSnippet>INSERT_API_KEY_HERE</InlineCodeSnippet>.</p>
                            <img src={OTAImage1} alt='A user copying their API key from a project in OS Data Hub into the apiKey variable in the code example.'/>
                            <p>This will generate a fully-styled map using the OS NGD API – Tiles service that is
                                available for you to explore. You can also use the code provided as the basis on
                                which to build your own application.</p>
                            <img src={OTAImage2} alt='A fully styled map after a user copied their API key into the apiKey variable in the code example.' />
                        </li>
                    </ol>
                </Typography>
        </>
    );
};

export default OTACodePlayground;