import React from 'react';
import {useSelector} from "react-redux";
import {defineMessages} from 'react-intl';
import routes from "../util/routes";
import ContentHeader from '../components/ContentHeader';
import TransactionHistory from './history/TransactionHistory';
import {withOrganisationMessage} from '../util/organisation';

const messages = defineMessages({
    backLink: {
        id: 'History.backLink',
        defaultMessage: 'Dashboard',
        description: 'Label for the link that navigates back to the dashboard'
    },
    title: {
        id: 'History.title',
        defaultMessage: 'API transaction history',
        description: 'Title for the transaction history page'
    },
    orgTitle: {
        id: 'History.orgTitle',
        defaultMessage: '{org} API transaction history',
        description: 'Title when an org is selected'
    },
    personalTitle: {
        id: 'History.personalTitle',
        defaultMessage: 'My Personal API transaction history',
        description: 'Title when selected org is type personal'
    },
});

export default function History() {
    const userDetails = useSelector(state => state.user.current.result);
    const org = useSelector(state => state.organisation.current);
    const title = withOrganisationMessage(messages.title, messages.orgTitle, messages.personalTitle, userDetails, org);

    // Using a wrapper div instead of a Fragment sets up a new context for z-order, so that the AppBar in the
    // history view doesn't overlap the notifcation panel. Ideally we'd move the notification panel up in the
    // zIndex, but to do that we need to adjust the header and PriorityNotification components.
    return <div>
        <ContentHeader
            backPath={routes.dashboard} 
            backLabel={messages.backLink} 
            title={title} />
        <TransactionHistory/>
    </div>;
}