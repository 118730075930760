// Component used to apply a tag or label to content in the portal. Used by PlanTag and CatalogueTag
import React from 'react';
import PropTypes from 'prop-types';
import {createUseStyles} from 'react-jss';
import Typography from "@mui/material/Typography";
import classNames from 'classnames';
import {osColour} from 'omse-components';
import {defineMessages, FormattedMessage, useIntl} from "react-intl";

const messages = defineMessages({
    decorativeLabelAria: {
        id: 'ItemTag.decorativeLabelAria',
        defaultMessage: 'Decorative label: {label}',
        description: 'Decorative label'
    }
})

const useStyles = createUseStyles(theme => ({
    tag: {
        alignSelf: 'flex-start',
        display: 'inline-flex',
        marginBottom: theme.spacing(1),
        height: 28,
        '& svg': {
            position: 'relative',
            top: -1
        }
    },
    tagCaption: {
        fontWeight: 600,
        padding: '7px 7px 7px 16px',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        '-webkit-font-smoothing': 'antialiased'
    },
    success: {
        background: osColour.neutral.clouds,
        color: osColour.status.success
    },
    berry: {
        background: osColour.neutral.clouds,
        color: osColour.primary.berry
    },
    foxglove: {
        background: osColour.neutral.clouds,
        color: osColour.primary.foxglove
    },
    md: {
        height: 32,
        '& $tagCaption': {
            fontSize: '1rem'
        }
    },
    lg: {
        height: 36,
        '& $tagCaption': {
            fontSize: '1.2rem'
        }
    }
}));

export default function ItemTag(props) {
    const classes = useStyles(props);
    const {colour, size, label} = props;
    let highlightClass = classes[colour];

    const intl = useIntl();
    const tagClasses = [classes.tag, props.classes.tag, highlightClass];

    let height = 30;
    if (size && size === 'md') {
        height = 33;
        tagClasses.push(classes.md);
    } else if(size && size === 'lg') {
        height = 37;
        tagClasses.push(classes.lg);
    }

    let intlLabel = intl.formatMessage(label);
    let ariaLabel = intl.formatMessage(messages.decorativeLabelAria, {label: intlLabel});

    return (<div className={classNames(tagClasses)} role="img" alt='' aria-label={ariaLabel}>
        <Typography variant='caption' className={classes.tagCaption}>
            <FormattedMessage {...label}/>
        </Typography>
        <svg xmlns="http://www.w3.org/2000/svg" height={height} width="9" role='img' alt='' aria-hidden={true}>
            <polygon fill="white" points={`0,${height} 8,0 9,0 9,${height}`} />
        </svg>
    </div>)
}

ItemTag.propTypes = {
    size: PropTypes.oneOf(['sm', 'md']),
    colour: PropTypes.oneOf(['success', 'berry', 'foxglove']).isRequired,
    label: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired,
        description: PropTypes.string
    }),
    classes: PropTypes.object // Allows the styles to be overridden
};

ItemTag.defaultProps = {
    classes: {}
};
