import React from 'react';
import UploadPolygonModal from "./UploadPolygonModal";

const UPLOAD_POLYGON_TYPE = 'load';

export default function UploadPolygonAction(map, layers) {
    const {drawingLayer, currentPolygonLayer} = layers;

    const component = <UploadPolygonModal drawingLayer={drawingLayer} map={map} currentPolygonLayer={currentPolygonLayer}/>;

    return {
        type: UPLOAD_POLYGON_TYPE,
        component
    };
}

UploadPolygonAction.type = UPLOAD_POLYGON_TYPE;