//  A hook to return the vatRate adjuster.
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";
import {getPaymentConfigData} from "../modules/payments/actions";

export default function useVatRateAdjuster() {
    const dispatch = useDispatch();
    const paymentsConfigData = useSelector((state) => state.payments.config.result);

    useEffect(() => {
        if (!paymentsConfigData) {
            dispatch(getPaymentConfigData());
        }
    }, [paymentsConfigData, dispatch]);

    return paymentsConfigData && (paymentsConfigData.vatRate + 1);
}