import {createLoadingReducer, createLoadingMiddleware} from 'omse-components';
import {LOAD_NOTIFICATIONS_ACTION, LOADED_NOTIFICATIONS_ACTION, LOAD_NOTIFICATIONS_STATUS_ACTION, LOADED_NOTIFICATIONS_STATUS_ACTION} from "./notifications/actions";
import {reducer as updateReducer, middleware as updateNotificationsMiddleware} from './notifications/update';
import {combineReducers} from 'redux';

export const notificationsReducer = combineReducers({
    status: createLoadingReducer(LOAD_NOTIFICATIONS_STATUS_ACTION, LOADED_NOTIFICATIONS_STATUS_ACTION, true),
    current: createLoadingReducer(LOAD_NOTIFICATIONS_ACTION, LOADED_NOTIFICATIONS_ACTION, true),
    update: updateReducer
});

function prepareCall(action) {
    return '/api/notifications/' + (action.filter? '?filter=' + action.filter : '');
}

function prepareStatusCall() {
    return '/api/notifications/status';
}

export const notificationsMiddleware = [
    createLoadingMiddleware(LOAD_NOTIFICATIONS_ACTION, LOADED_NOTIFICATIONS_ACTION, prepareCall),
    createLoadingMiddleware(LOAD_NOTIFICATIONS_STATUS_ACTION, LOADED_NOTIFICATIONS_STATUS_ACTION, prepareStatusCall),
    updateNotificationsMiddleware
];