import React from 'react';
import { Box, Typography } from "@mui/material";
import {osColour} from "omse-components";
import Link from './Link';
import {createUseStyles} from 'react-jss';
import {defineMessages, FormattedMessage} from "react-intl";

const useStyles = createUseStyles(theme => ({
    existingProductsContainer: {
        border: `1px solid ${osColour.neutral.mist}`,
        borderTop: `2px solid ${osColour.primary.berry}`,
        marginTop:20,
        maxWidth: '300px',
        background: osColour.neutral.white,
        [theme.breakpoints.down('md')]: {
            maxWidth: "100%"
        }
    },
    existingIntro: {
        padding:20
    },
    existingItem: {
        marginTop: 5
    },
    listContainer: {
        background: osColour.neutral.mist,
        margin:0,
        padding:20,
        listStyle: 'none'
    }
}));

const messages = defineMessages({
    existingPackagesHeading : {
        id: 'ExistingRecipeDownloads.existingPackageHeading',
        defaultMessage: 'Existing Packages',
        description: 'existing packages heading'
    },
    ngdExistingPackages: {
        id: 'ExistingRecipeDownloads.existingPackages',
        defaultMessage: 'You have existing packages for this recipe. To order more data for a specific package, click below:',
        description: 'existing packages text'
    },
    premiumExistingPackages: {
        id: 'PremiumExistingDownloads.existingProducts',
        defaultMessage: 'You have existing packages for this product. To order more data for a specific package, click below:',
        description: 'existing products text'
    }
});

export const PREMIUM = 'premium';
export const NGD = 'ngd';

export default function ExistingPackagesMessage(props) {
    const { existingDataPackages = [], product=PREMIUM } = props;
    const classes = useStyles();
    return (existingDataPackages && existingDataPackages.length > 0 && <Box className={classes.existingProductsContainer} data-testid="existing-products-container">
        <Box className={classes.existingIntro}>
            <Typography variant="h3">
                <FormattedMessage {...messages.existingPackagesHeading} />
            </Typography>
            <Typography>
                <FormattedMessage {...messages[`${product}ExistingPackages`]} />
            </Typography>
        </Box>
        {existingDataPackages.length > 0 &&
            <ul className={classes.listContainer}>
                {existingDataPackages.map(p => {
                    return <li key={p.id} className={classes.existingItem}>
                        <Typography variant="body1">
                            <Link path={`/downloads/packages/${p.id}`}>{p.name}</Link>
                        </Typography>
                    </li>
                })}
            </ul>
        }
    </Box>);
}
