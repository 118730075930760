import {border1, contentPadding} from 'omse-components';
import styled from '@emotion/styled';

export const StyledErrorPage = styled.div(({theme}) => `
    flex: 1 1 auto;
    flex-direction: column;
    & .content {
        padding-left: ${contentPadding.left}px;
        padding-top: ${contentPadding.top}px;
        padding-bottom: ${contentPadding.bottom}px;
        padding-right: ${contentPadding.right}px;
        ${theme.breakpoints.down('sm')} {
            padding: ${contentPadding.mobile};
            flex-direction: column;
        }
        & h2 {
            margin-bottom: ${theme.spacing(2)};
        }
    }
    & .header {
        border-bottom: ${border1};
    }
    & .body {
        max-width: 600px;
    }
    & .bodyText {
        text-align: left;
    }
`)
