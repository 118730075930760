import React, {useEffect, useState} from 'react';
import {InputBoxNumeric, osColour} from "omse-components";
import {useDispatch, useSelector} from "react-redux";
import {updateDraftOrder} from "../../../../modules/dataPackages/actions";
import {defineMessages, useIntl} from "react-intl";
import {createUseStyles} from 'react-jss';
import features from "../../../../../shared/features";
import useFeatureCheck from "../../../../util/useFeatureCheck";
import CatalogueScopedField from "./CatalogueScopedField";
import {COM3_CATALOGUE} from '../../../../../shared/catalogues';
import {reTestPositiveInteger} from '../../../../../shared/input';

const styles = createUseStyles(theme => ({
    field: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        margin: theme.spacing(-0.5,1,0,1),
        maxWidth: 360,
        '& label': {
            marginBottom: theme.spacing(1)
        }
    },
    disabled: {
        color: osColour.neutral.rock
    }
}));

export const messages = defineMessages({
    label: {
        id: 'ExactUsersInput.label',
        defaultMessage: 'Exact number of users',
        description: 'Exact users input field label'
    },
    placeholder: {
        id: 'ExactUsersInput.placeholder',
        defaultMessage: 'Enter a number of users',
        description: 'Placeholder label'
    },
    fieldError: {
        id: 'ExactUsersInput.fieldError',
        defaultMessage: 'Please enter an exact number of users (1-50000 inclusive)',
        description: 'Required error message'
    }
});

export default function ExactUsersInput() {

    const users = useSelector(state => state.dataPackages.draftOrder.users) || "";
    const displayErrors = useSelector(state => state.dataPackages.draftOrder.displayErrors);
    const dispatch = useDispatch();
    const classes = styles();
    const intl = useIntl();
    const [hasValidUsers, setHasValidUsers] = useState(true);

    useEffect(() => {
        if (displayErrors) {
            setHasValidUsers(reTestPositiveInteger(users));
        }
    }, [displayErrors, users]
    );

    function handleChange(event) {
        setHasValidUsers(reTestPositiveInteger(event.target.value));
        dispatch(updateDraftOrder('users', event.target.value));
    }

    return (useFeatureCheck(features.EAI))
        ? <CatalogueScopedField catalogueId={COM3_CATALOGUE}>
            <div className={classes.field}>
                <InputBoxNumeric id='users'
                                 label={messages.label}
                                 value={users}
                                 onChange={handleChange}
                                 fullWidth
                                 placeholder={intl.formatMessage(messages.placeholder)}
                                 error={!hasValidUsers && messages.fieldError}

                />
            </div>
        </CatalogueScopedField>
        : null

}
