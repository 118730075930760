import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import {contentPadding} from 'omse-components';
import {createUseStyles} from 'react-jss';
import {AddButton} from 'omse-components';

const useStyles = createUseStyles(theme => ({
    title: {
        marginTop: theme.spacing(8),
        textAlign: 'center',
        marginBottom: theme.spacing(4)
    },
    content: {
        flex: '1 0 auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 500,
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile
        }
    },
    buttons: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        '& button:first-of-type': {
            marginRight: theme.spacing(2)
        }
    }
}));

export default function TitledSingleColWithButton(props) {
    const {children, title, buttonAction, buttonMessage} = props;
    const classes = useStyles(props);
    return <div className={classes.content}>
         <Typography variant='h1' color='primary' className={classes.title}>
            <FormattedMessage {...title} />
        </Typography>

        {children}

        {(buttonAction && buttonMessage) &&
            <div className={classes.buttons}>
                <AddButton action={buttonAction}
                        label={buttonMessage}
                        showIcon={false} />
            </div>
        }
    </div>
}

TitledSingleColWithButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    title: PropTypes.object.isRequired,
    buttonAction: PropTypes.func,
    buttonMessage: PropTypes.object
}
