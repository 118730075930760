import {createUseStyles} from 'react-jss';
import React from 'react';
import Typography from '@mui/material/Typography';
import {osColour, primaryColour} from 'omse-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const stageSize = 29;
const stageBorder = 2
const stageOuterSize = stageSize + (stageBorder * 2);
const halfStageOuterSize = Math.floor(stageOuterSize / 2);
const useStyles = createUseStyles(theme => ({
    root: {
        display: 'flex',
        position: 'relative',
        flex: '1 1 auto',
    },
    stageIndicator: {
        height: stageSize,
        width: stageSize,
        borderRadius: 17,
        border: `solid ${primaryColour} ${stageBorder}px`,
        backgroundColor: osColour.neutral.white,
        display: 'flex',
        justifyContent: 'center',
        '& p': {
            color: primaryColour,
            fontSize: '1.125rem',
            fontWeight: 600,
            lineHeight: 1.5
        },
        marginRight: 7,
        position: 'relative',
        '&.disabled': {
            borderColor: osColour.neutral.stone,
            '& p': {
                color: osColour.neutral.stone,
            }
        },
        flex: '0 0 auto'
    },
    stageLine: {
        borderLeft: 'dotted 2px #746cac',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: halfStageOuterSize,
        '&.disabled': {
            borderColor: osColour.neutral.stone
        }
    },
    content: {
        flex: '1 1 auto' // Have main content grow to consume any excess space.
    }
}));

export default function StepListItem({
                                         children,
                                         className,
                                         classNameContent,
                                         disabled,
                                         lineDisabled,
                                         stepNum,
                                         showLine,
}) {
    const classes = useStyles();
    return <li className={classNames(classes.root, className)}>
        {showLine && <div className={classNames({[classes.stageLine]: true, disabled: lineDisabled})} aria-hidden={true} />}
        <div className={classNames({[classes.stageIndicator]: true, disabled: disabled})}>
            <Typography variant='body1'>{stepNum}</Typography>
        </div>
        <div className={classNames(classes.content, classNameContent)}>
            {children}
        </div>
    </li>;
}

StepListItem.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    classNameContent: PropTypes.string,
    disabled: PropTypes.bool,
    lineDisabled: PropTypes.bool,

    // Users don't need to set these properties, they will be set automatically by the parent StepList.
    stepNum: PropTypes.number,
    showLine: PropTypes.bool,
};

StepListItem.defaultProps = {
    disabled: false,
    lineDisabled: false
};