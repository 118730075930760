import React, {useState, Fragment} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import useActionIdSelector from "../../../hooks/useActionIdSelector";
import {Typography, TextField, CircularProgress, InputAdornment} from '@mui/material';
import {DropDownMenu, InputLabel, InputBox, osColour, linkButton, modules} from 'omse-components';
import {createUseStyles} from 'react-jss';
import Autocomplete from '@mui/material/Autocomplete';
import COUNTRY_CODES from "./CountryCodes";

const {addressLookup, clearAddressLookup} = modules.actions.address;

const messages = defineMessages({
   billingAddressLabel: {
       id: 'AddressDetails.billingAddressLabel',
       defaultMessage: 'Billing address',
       description: 'Label for the billing address'
   },
   billingAddressPlaceholder: {
       id: 'AddressDetails.billingAddressPlaceholder',
       defaultMessage: 'Enter your address',
       description: 'Placeholder for the billing address'
   },
   addressLabel: {
       id: 'AddressDetails.addressLabel',
       defaultMessage: 'Address line {index}',
       description: 'Label for the address 1, 2, 3 field on the address'
   },
   city: {
       id: 'AddressDetails.city',
       defaultMessage: 'City',
       description: 'Label for the city field on the address'
   },
   state: {
       id: 'AddressDetails.state',
       defaultMessage: 'County',
       description: 'Label for the state field in the address'
   },
   postalCode: {
       id: 'AddressDetails.postalCode',
       defaultMessage: 'Postcode',
       description: 'Label for the postcode field on the address'
   },
   countryCode: {
       id: 'AddressDetails.countryCode',
       defaultMessage: 'Country',
       description: 'Label for the country field on the address'
   },
   requiredFieldError: {
       id: 'AddressDetails.requiredFieldError',
       defaultMessage: 'This field is required',
       description: 'Error message when a required address field is empty'
   },
   invalidPostcode: {
       id: 'AddressDetails.invalidPostcode',
       defaultMessage: 'The postcode is invalid',
       description: 'Error message when the postcode is invalid'
   },
   addressLookupError: {
       id: 'AddressDetails.addressLookupError',
       defaultMessage: 'We were unable to find any matching addresses. Please edit the billing address and try again, or enter the address manually.',
       description: 'Error message when the address lookup fails'
   },
   enterManually: {
       id: 'AddressDetails.enterManually',
       defaultMessage: 'Enter address manually',
       description: 'Label for the enter address manually button'
   },
   addressSearch: {
        id: 'AddressDetails.addressSearch',
        defaultMessage: 'Address search',
        description: 'Address search label'
    },
    useAddressSearch: {
        id: 'AddressDetails.useAddressSearch',
        defaultMessage: 'use the address search',
        description: 'Use the address search label'
    },
    useAddressSearchPrompt: {
        id: 'AddressDetails.useAddressSearchPrompt',
        defaultMessage: 'Enter the address below, or ',
        description: 'Enter address below label'
    }
});

const useLinkStyles = createUseStyles({linkButton});

const styles = createUseStyles(theme => ({
    autocompleteInputRoot: {
        display: 'flex',
        border: 'solid 1px ' + osColour.neutral.stone,
        borderRadius: 3,
        paddingLeft: theme.spacing(2)
    },
    autocompletePaper: {
        boxShadow: '0 1px 7px 1px rgba(0, 0, 0, 0.4)'
    },
    autocompleteEndAdornment: {
        right: theme.spacing(1)
    },
    inputAdornmentEnd: {
        marginRight: theme.spacing(1)
    },    
    searchModePrompt: {
        marginTop: theme.spacing(1)
    }
}));

const POSTCODE_CHECK = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i;

const ADDRESS_PREFIX = 'address';
const FIRST_ADDRESS_FIELD = ADDRESS_PREFIX + '1';
const CITY_FIELD = 'city';
const COUNTY_FIELD = 'state';
const POSTCODE_FIELD = 'postalCode';
const COUNTRY_FIELD = 'countryCode';

const ADDRESS_TEMPLATE = {
    [FIRST_ADDRESS_FIELD]: '',
    [ADDRESS_PREFIX + '2']: '',
    [ADDRESS_PREFIX + '3']: '',
    [CITY_FIELD]: '',
    [COUNTY_FIELD]: '',
    [COUNTRY_FIELD]: 'GB',
    [POSTCODE_FIELD]: ''
};

export default function AddressDetails({onAddressEntered}) {
    const [enterManually, setEnterManually] = useState(false);
    const [hasEdited, setHasEdited] = useState(false);
    const [address, setAddress] = useState(ADDRESS_TEMPLATE);
    const [focused, setFocused] = useState(false);
    const [{result, working}, dispatch] = useActionIdSelector('address.addressLookup');
    const intl = useIntl();
    const classes = styles();
    const classesLink = useLinkStyles();

    function handleClick(event) {
        event.preventDefault();
        setAddress(enterManuallyOption);
        setEnterManually(true);        
    }

    function handleSwitchToAutocomplete() {
        setEnterManually(false);
        onAddressEntered(null);
        dispatch(clearAddressLookup());
    }

    const enterManuallyOption = {
        ...ADDRESS_TEMPLATE
    };

    function updateAddressField(name, value) {
        const newAddress = {
            ...address,
            [name]: value
        };
        setAddress(newAddress);
        setHasEdited(true);

        let validPostcode;
        if(newAddress[COUNTRY_FIELD] === 'GB') {
            validPostcode = POSTCODE_CHECK.test(newAddress[POSTCODE_FIELD]);
        } else {
            validPostcode = newAddress[POSTCODE_FIELD] !== '';
        }

        if(validPostcode && newAddress.address1 !== '' && newAddress.city !== '') {
            onAddressEntered(newAddress);
        } else {
            onAddressEntered(null);
        }
    }

    if(enterManually) {
        return <div>            
            <Typography component="div" className={classes.searchModePrompt}>
                <FormattedMessage {...messages.useAddressSearchPrompt} />
                <a href="#addressSearch" className={classesLink.linkButton} 
                        onClick={handleSwitchToAutocomplete}>
                    <FormattedMessage {...messages.useAddressSearch} />
                </a>
            </Typography>
            {
                Object.keys(ADDRESS_TEMPLATE)
                    .filter(name => name !== COUNTRY_FIELD)
                    .map((name, index) => {
                    let error = null;
                    let label;
                    if(name.startsWith(ADDRESS_PREFIX)) {
                        label = messages.addressLabel;
                    } else {
                        label = messages[name];
                    }

                    // Validate the address1, city, and postcode
                    if((name === FIRST_ADDRESS_FIELD || name === CITY_FIELD) && address[name] === '' && hasEdited) {
                        error = messages.requiredFieldError;
                    }
                    if(name === POSTCODE_FIELD && hasEdited) {
                        if(address[COUNTRY_FIELD] === 'GB') {
                            if(!POSTCODE_CHECK.test(address[POSTCODE_FIELD])) {
                                error = messages.invalidPostcode;
                            }
                        } else {
                            if(address[POSTCODE_FIELD] === '') {
                                error = messages.requiredFieldError;
                            }
                        }
                    }

                    return <InputBox key={name}
                                     id={name}
                                     label={label}
                                     labelValues={{ index: index + 1 }}
                                     value={address[name]}
                                     onChange={event => updateAddressField(name, event.target.value)}
                                     error={error}
                                     fullWidth={true}
                    />;
                })
            }
            <div>
                <InputLabel id={'countryLabel'} htmlFor='countrySelect'>
                    <FormattedMessage {...messages[COUNTRY_FIELD]}/>
                </InputLabel>
                <DropDownMenu 
                    buttonId='countrySelect'
                    buttonVariant='outlined'
                    variant='block'
                    value={address[COUNTRY_FIELD]}
                    onChange={item => updateAddressField(COUNTRY_FIELD, item.code)}
                    items={COUNTRY_CODES}
                    dataLabelKey='name'
                    dataValueKey='code'
                    mode='modal'
                />
            </div>
        </div>;
    }

    function inputChange(event, value, reason) {
        if(reason === 'input') {
            dispatch(addressLookup(value))
        } else if(reason === 'clear') {
            dispatch(clearAddressLookup());
        }
    }

    function autocompleteChange(event, value, reason) {
        if(reason === 'selectOption') {
            setAddress(value);
            setEnterManually(true);
            if(value === enterManuallyOption) {
                // Do nothing, the user should now see the address form, and the details will have been filled
                // in from the template (e.g. the country is set up)
            } else {
                setHasEdited(true);
                onAddressEntered(value);
            }
        }
    }

    let options = [];
    if(result) {
        options = result.slice();
    }
    options.push(enterManuallyOption);

    return (
        <Fragment>
             <InputLabel htmlFor='addressSearch'>
                <FormattedMessage {...messages.addressSearch}/>
            </InputLabel>
            <Autocomplete
                id='addressSearch'
                freeSolo
                fullWidth={true}
                autoHighlight={true}
                openOnFocus={true}
                getOptionLabel={option => option.city}
                isOptionEqualToValue={(option, value) => false}
                options={options}
                loading={working}
                onChange={autocompleteChange}
                onInputChange={inputChange}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                filterOptions={options => options}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant='standard'
                        placeholder={intl.formatMessage(messages.billingAddressPlaceholder)}
                        autoFocus={focused}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position={'end'} classes={{positionEnd: classes.inputAdornmentEnd}}>
                                    {working ? <CircularProgress color="inherit" size={16} /> : null}
                                    {params.InputProps.endAdornment}
                                </InputAdornment>
                            )
                        }}
                    />
                )}
                renderOption={(props, option) => {
                    switch(option) {
                        case enterManuallyOption:
                            return <li {...props}>
                                <span>Search for an address, <a href="#enterAddressManually" onClick={handleClick}>or enter one manually</a></span>
                            </li>;
                        default:
                            return <li {...props} key={option.id}>{option.completeAddress}</li>
                    }
                }}
                classes={{
                    inputRoot: classes.autocompleteInputRoot,
                    paper: classes.autocompletePaper,
                    endAdornment: classes.autocompleteEndAdornment
                }}
            />
        </Fragment>
    );
}