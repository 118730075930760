import React from 'react';
import PropTypes from 'prop-types';
import PackageNameInput from "./PackageNameInput";
import ResetOrderButton from "./ResetOrderButton";
import ChooseAreaRadio from "./ChooseAreaRadio";
import FormatDropdown from "./FormatDropdown";
import UpdatesDropdown from "./UpdatesDropdown";
import InitialSupplyDateDropdown from "./InitialSupplyDateDropdown";
import {defineMessages, FormattedMessage} from "react-intl";
import {createUseStyles} from 'react-jss';
import {osColour} from 'omse-components';
import {Typography} from "@mui/material";
import RenewalPeriodDropdown from "./RenewalPeriodDropdown";
import TerminalsDropdown from "./TerminalsDropdown";
import {useSelector} from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import {hasCom3Catalogue} from "../../../../util/permissions";
import ExactUsersInput from "./ExactUsersInput";
import * as features from "../../../../../shared/features";
import featureCheck from '../../../../util/featureCheck';
import CrsDropdown from "./CrsDropdown";

const messages = defineMessages({
    title: {
        id: 'AddDataPackagePanel.title',
        defaultMessage: 'Add a data package',
        description: 'Dialog title for adding a data package'
    },
    editTitle: {
        id: 'AddDataPackagePanel.editTitle',
        defaultMessage: 'Edit data package',
        description: 'Dialog title for edit mode'
    }
});

const useStyles = createUseStyles(theme => ({
    title: {
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(5),
        borderBottom: '1px solid ' + osColour.neutral.mist,
        zIndex: 50
    },
    titleText: {
        color: osColour.primary.berry
    },
    optionContainer: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        backgroundColor: osColour.neutral.clouds,
        border: '1px solid ' + osColour.neutral.mist,
        margin: theme.spacing(1),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    dataPackageTitle: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        color: osColour.neutral.charcoal,
    },
    inputContainer: {
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    loading: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(2)
    }
}));

export default function AddDataPackagePanel(props) {
    const {
        editMode,
        subtitle,
        areaRadioOptions,
        formatOptions,
        crsOptions,
        updatesOptions,
        initialSupplyDateOptions,
        renewalOptions,
        terminalsOptions,
        inputContainerExtraChildren,
        isNgd
    } = props;
    const classes = useStyles();
    const product = useSelector(state => state.premium.product.result);
    const user = useSelector(state => state.user.current.result);
    const config = useSelector(state => state.config.current?.result);

    return <>
        <div className={classes.title}>
            <Typography variant='h1' className={classes.titleText}>
                {editMode ?
                    <FormattedMessage {...messages.editTitle} />
                    :
                    <FormattedMessage {...messages.title} />
                }
            </Typography>
        </div>

        <Typography variant='subtitle1'
                    component='h2'
                    className={classes.dataPackageTitle} data-testid="product-subtitle">
            {subtitle}
        </Typography>

        <div className={classes.inputContainer}>
            <PackageNameInput/>
            {inputContainerExtraChildren}
        </div>

        <div className={classes.optionContainer}>

            {!editMode && <ResetOrderButton/>}

            <ChooseAreaRadio areaRadioOptions={areaRadioOptions}
                             disabled={editMode} />

            {!!crsOptions?.length && <CrsDropdown crsOptions={crsOptions}/>}

            <FormatDropdown formatOptions={formatOptions} />

            <UpdatesDropdown updatesOptions={updatesOptions} isNgd={isNgd} />

            {initialSupplyDateOptions && <InitialSupplyDateDropdown initialSupplyDateOptions={initialSupplyDateOptions} />}

            {/* Displays loading state for COM3 catalogue fields dependant on product */}
            {featureCheck(features.EAI, config) && <>
                {(hasCom3Catalogue(user) && !product)
                    ? <CircularProgress size={18} className={classes.loading} />
                    : <>
                        <RenewalPeriodDropdown renewalOptions={renewalOptions} />

                        {product && product.exactTerminals
                            ? <ExactUsersInput/>
                            : <TerminalsDropdown terminalsOptions={terminalsOptions} />}

                    </>
                }
            </>}
        </div>
    </>;
}

AddDataPackagePanel.propTypes = {
    editMode: PropTypes.bool,
    subtitle: PropTypes.string.isRequired,
    areaRadioOptions: PropTypes.array.isRequired,
    formatOptions: PropTypes.array.isRequired,
    updatesOptions: PropTypes.array.isRequired,
    initialSupplyDateOptions: PropTypes.array,
    renewalOptions: PropTypes.array,
    terminalsOptions: PropTypes.array,
    inputContainerExtraChildren: PropTypes.object
};
