import {useSelector} from 'react-redux';
import {defineMessages} from 'react-intl';

const messages = defineMessages({
    fixedAreaOption: {
        id: 'AddDataPackagePremiumNgd.fixedAreaOption',
        defaultMessage: 'All of {area}',
        description: 'fixedAreaOption label'
    },
    areaOptionCustomAOI: {
        id: 'AddDataPackagePremiumNgd.areaOptionCustomAOI',
        defaultMessage: 'Draw a polygon/upload a file/use an OS polygon',
        description: 'areaOptionCustomAOI label'
    },
});

/**
 * Returns the different options that should be used to populate the Premium Add Data Package page components used to
 * configure the data package.
 *
 * @returns {{areaRadioOptions: [], formatOptions: [], updatesOptions: [], renewalOptions: [], terminalsOptions: []}}
 */
export default function useDataPackageNgdPoweredPremiumOptions(product) {
    const format =  useSelector(state => state.dataPackages.draftOrder.format);
    const area =    useSelector(state => state.dataPackages.draftOrder.area);
    const updates = useSelector(state => state.dataPackages.draftOrder.updates);

    const areaRadioOptions = getAreaRadioOptions({product, format, updates});
    const formatOptions = getFormatOptions({product, area, updates});
    const updatesOptions =  getUpdateOptions({product, area, format});
    const renewalOptions = [], terminalsOptions = [];

    return {areaRadioOptions, formatOptions, updatesOptions, renewalOptions, terminalsOptions};
}

function buildAreaOptions(product) {
    if (!product) {
        return [];
    }
    let areaOptions = product.areas;
    let fixedAreaOptions = areaOptions.filter(option => !option.id.includes('Polygon'));
    let customAreas = areaOptions.filter(option => option.id.includes('Polygon'));
    let options = [];

    if (fixedAreaOptions.length > 0) {
        fixedAreaOptions.forEach(option => {
            let fixedAreaOption = {
                id: option.id,
                label: messages.fixedAreaOption,
                values: {area: option.label},
                disabled: false
            };
            options.push(fixedAreaOption);
        })
    } else {
        let allOfGbOption = {
            id: 'GB',
            label: messages.fixedAreaOption,
            values: {area: 'Great Britain'},
            disabled: true
        };

        options.push(allOfGbOption);
    }
    let customAreaOption;
    if (customAreas.length > 0) {
        let customArea = customAreas[0];
        customAreaOption = {
            id: customArea.id,
            label: messages.areaOptionCustomAOI,
            disabled: false
        };
    } else {
        customAreaOption = {
            id: 'Polygon',
            label: messages.areaOptionCustomAOI,
            disabled: true
        };
    }
    options.push(customAreaOption);

    return options;
}

function getAreaRadioOptions({product, format, updates}) {
    const areaOptions = buildAreaOptions(product);
    return areaOptions;
}

function getFormatOptions({product, area, updates}) {
    let formatOptions = [];
    if(product?.dataFormats) {
        formatOptions = product.dataFormats.map(format =>
            {
                let enabled = true;
                if(updates && updates.includes('COU')) {
                    // We only support COU for CSV-based formats
                    enabled = format.id.includes('CSV');
                }
                return {enabled, ...format}
            }
        );
    }
    return formatOptions;
}

function getUpdateOptions({product, area, format}) {
    let updatesOptions = [
        {id: 'ONCE', label: 'Not Required', enabled: true},
        {id: 'MONTHLY_FULL', label: 'Monthly Full Refresh', enabled: true}
    ];
    if(product?.dataFormats.find(o => o.id.includes('CSV'))) {
        let enabled = true;
        if(format && !format.includes('CSV')) {
            enabled = false;
        }

        updatesOptions.push({id: 'MONTHLY_COU', label: 'Monthly COU', enabled});
    }

    return updatesOptions;
}