import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {resetTooltip, setTooltip} from "../../../../modules/toolbar";

export default function useTooltipHandler(tooltip) {
    const dispatch = useDispatch();

    const hoverCallback = useCallback(element => {
        if(element) {
            element.addEventListener('mouseenter', () => dispatch(setTooltip(tooltip)));
            element.addEventListener('mouseover', () => dispatch(setTooltip(tooltip)));
            element.addEventListener('mouseleave', () => dispatch(resetTooltip()));
        }
    }, [tooltip, dispatch]);

    return hoverCallback;
}