import {cleanUpSharableOrgs, getSharableOrgs} from "../../../../modules/recipes/actions";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

export default function useShareableOrgsCache(recipeId, prefix, minPrefixLength){
    const dispatch = useDispatch();
    const [cachedPrefixes, setCachedPrefixes] = useState({});
    let {result: shareableOrgs} = useSelector(state => state.recipes.shareableOrgs);
    const prefixToLoad = prefix.substring(0, minPrefixLength).toLowerCase();

    function filterOptions(orgs){
        const lowerValue = prefix.toLowerCase();
        return orgs.filter(org => org.toLowerCase().startsWith(lowerValue));
    }

    useEffect(() => {
        if(shareableOrgs) {
            const loadedPrefix = shareableOrgs.prefix;
            setCachedPrefixes(cachedPrefixes => {
                return {...cachedPrefixes, [loadedPrefix] : {loading: false, orgNames: shareableOrgs}};
            });
        }
        return function cleanUp(){
            dispatch(cleanUpSharableOrgs());
        };
    }, [shareableOrgs, dispatch]);

    useEffect(() => {
        if(prefix.length >= minPrefixLength) {
            if (!cachedPrefixes[prefixToLoad]) {
                dispatch(getSharableOrgs(recipeId, prefixToLoad));
                setCachedPrefixes({...cachedPrefixes, [prefixToLoad] : {loading: true, orgNames: []}});
            }
        }
    }, [dispatch, cachedPrefixes, prefix, recipeId, prefixToLoad, minPrefixLength]);

    let result = {loading: false, orgNames: []};
    if(prefix.length >= minPrefixLength && cachedPrefixes[prefixToLoad]) {
        result = {
            loading: cachedPrefixes[prefixToLoad].loading,
            orgNames: filterOptions(cachedPrefixes[prefixToLoad].orgNames)
        };
    }
    return result;
}