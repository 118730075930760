import styled from "@emotion/styled";
import { osColour } from "omse-components";

const Code = styled("code")`
    display: inline-block;
    margin: 0.1em;
    padding: 0em 0.4em;
    font-size: 0.8em;
    font-family: monospace;
    letter-spacing: 0.02em;
    color: ${osColour.neutral.charcoal};
    background: ${osColour.neutral.clouds};
    border: 1px dashed ${osColour.neutral.mist};
    border-radius: 0.3em;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 1px 0px;
`;

export default function InlineCodeSnippet(p) {
    const { children } = p;

    return p.children ? <Code>{children}</Code> : null;
}
