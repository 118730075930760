import {
    createLoadingReducer,
    createLoadingMiddleware,
    createActionReducer,
    createActionMiddleware
} from 'omse-components';
import {LOAD_API_PLANS, LOADED_API_PLANS, LOAD_API_PRODUCTS, LOADED_API_PRODUCTS, PURCHASE_API_PLAN, LOAD_API_TIERS, LOADED_API_TIERS} from './commercialApiPlans/actions';
import {combineReducers} from 'redux';
import routes from '../util/routes';

export const commercialApiPlansReducer = combineReducers({
    plans: createLoadingReducer(LOAD_API_PLANS, LOADED_API_PLANS, null, updatePlans),
    products:createLoadingReducer(LOAD_API_PRODUCTS, LOADED_API_PRODUCTS),
    tiers:createLoadingReducer(LOAD_API_TIERS, LOADED_API_TIERS),
    purchase:createActionReducer(PURCHASE_API_PLAN)
});

function updatePlans(action){
    return action?.result?.forEach(plan => {
        const created = new Date(plan.created);
        plan.expirationDate = new Date(created.getFullYear()+1, created.getMonth(), created.getDate())
    })
}

function prepareGetPlans(action, store) {
    return '/api/commercialApiPlans'
}

function prepareGetApiProducts(action, store){
    return "/api/commercialApiPlans/products"
}

function prepareGetApiTiers(action, store){
    return "/api/commercialApiPlans/tiers";
}

function preparePurchaseApiPlans(action, store){
    return {
        method: "POST",
        url: "/api/commercialApiPlans/purchase",
        body:{
            tierId: action.tierId,
            openingBalance: action.openingBalance,
            currency: "GBP"
        }
    }
}

function purchasedApiPlan(store, action, result){
    if(result && result.id && result.openingBalance && action.openingBalance === Number(result.openingBalance)){
        store.getState().history.push(routes.addedApiPlan, result);
    }
}

export const commercialApiPlansMiddleware = [
    createLoadingMiddleware(LOAD_API_PLANS, LOADED_API_PLANS, prepareGetPlans),
    createLoadingMiddleware(LOAD_API_PRODUCTS, LOADED_API_PRODUCTS, prepareGetApiProducts),
    createLoadingMiddleware(LOAD_API_TIERS, LOADED_API_TIERS, prepareGetApiTiers),
    createActionMiddleware(PURCHASE_API_PLAN, preparePurchaseApiPlans, purchasedApiPlan)
];
