import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import {osColour} from 'omse-components';
import mapInfoWebServices from './mapInfoPro/map-info-web-services.png';
import mapInfoServerInfo from './mapInfoPro/map-info-server-info-dialog.png';
import mapInfoWmtsLayers from './mapInfoPro/map-info-list-wmts-layers.png';
import mapInfoZoomedMap from './mapInfoPro/map-info-zoomed-map.png';
import WMTSCompletionSummary from './WMTSCompletionSummary';
import PropTypes from 'prop-types';

const styles = {
    guide: {
        '& img': {
            border: `solid ${osColour.neutral.mist} 1px`
        }
    }
};

export function MapInfoPro(props) {
    const {classes} = props;
    return <Fragment>
        <Typography variant='body1' paragraph={true} component='div' className={classes.guide}>
            <p>For the purposes of this guide the version of MapInfo Pro used is 2019 (64-bit).</p>
        </Typography>
        <Typography variant='h2'>Integrating OS Maps API in MapInfo Pro</Typography>
        <Typography variant='body1' paragraph={true} component='div' className={classes.guide}>
            <ol>
                <li>
                    <p>
                        Launch MapInfo Pro. With an empty workspace visible, navigate to the Home tab and select the
                        drop-down arrow under the Open button to reveal Web Services.
                    </p>
                    <img src={mapInfoWebServices} alt='MapInfo Pro Web Services screen'/>
                </li>
                <li>
                    <p>Select Mapping (WMTS) to open the WMTS Table configuration window.</p>
                </li>
                <li>
                    <p>
                        Click on the Servers… button to open the WMTS Servers List window. From here, you have the
                        option of adding new WMTS feeds. Click on the Add… button.
                    </p>
                </li>
                <li>
                    <p>
                        In the new window that appears after you have selected this option, you will need to insert
                        the OS Maps URL, which includes the key, from the OS Data Hub into the Server URL box.
                        Whilst you are under no obligation to include a description, it may help with
                        identification, to provide a name in the Description box. Click OK.
                    </p>
                    <img src={mapInfoServerInfo} alt='MapInfo Pro WMTS Server Information dialog'/>
                </li>
                <li>
                    <p>
                        When back in the WMTS Servers List configuration window, select the new connection you have
                        just made and click OK.
                    </p>
                </li>
                <li>
                    <p>
                        Back in the WMTS Table window, you will now see that the OS Maps API service has been added
                        and a list of map styles are available.
                    </p>
                    <img src={mapInfoWmtsLayers} alt='MapInfo Pro WMTS Layers list'/>
                </li>
                <li>
                    <p>Select the map style that you require and leave all others blank or as default.</p>
                </li>
                <li>
                    <p>
                        The data should now appear in a new Untitled Map window. Use the standard map controls to
                        zoom into the area required.
                    </p>
                    <img src={mapInfoZoomedMap} alt='MapInfo Pro map screen with zoom controls visible'/>
                </li>
            </ol>
        </Typography>
        <WMTSCompletionSummary software="MapInfo Pro"/>
    </Fragment>
}

MapInfoPro.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string
}

export default withStyles(styles)(MapInfoPro);