import {createLoadingReducer, createLoadingMiddleware, createActionReducer, createActionMiddleware} from 'omse-components';
import {LOAD_PSGA_ORGS_ACTION, LOADED_PSGA_ORGS_ACTION, ACCEPT_PSGA_ORG_ACTION, DECLINE_PSGA_ORG_ACTION} from "./psga/actions";
import {combineReducers} from 'redux';

export const psgaReducer = combineReducers({
    orgs: createLoadingReducer(LOAD_PSGA_ORGS_ACTION, LOADED_PSGA_ORGS_ACTION, true),
    accept: createActionReducer(ACCEPT_PSGA_ORG_ACTION),
    decline: createActionReducer(DECLINE_PSGA_ORG_ACTION),
});

function prepareCall(action, store) {
    return '/api/psga/orgs';
}

function setupAcceptOrg(action) {
    return {
        url: '/api/psga/accept',
        method: 'PUT',
        body: {
            job: action.job,
            phone: action.phone
        }
    }
}

function setupDeclineOrg() {
    return {
        url: '/api/psga/decline',
        method: 'PUT'
    }
}

export const psgaMiddleware = [
    createLoadingMiddleware(LOAD_PSGA_ORGS_ACTION, LOADED_PSGA_ORGS_ACTION, prepareCall),
    createActionMiddleware(ACCEPT_PSGA_ORG_ACTION, setupAcceptOrg),
    createActionMiddleware(DECLINE_PSGA_ORG_ACTION, setupDeclineOrg),
];
