import React from 'react';
import {createUseStyles} from 'react-jss';
import {Typography} from '@mui/material';
import {osColour, isWidthDown, withWidth} from 'omse-components';
import {defineMessages, FormattedMessage} from 'react-intl';
import {currencyFormatter, dateFormater} from './PaymentHelperFunctions';
import {useSelector} from 'react-redux';
import {ReactComponent as SuccessIcon} from '../../../components/icons/success-green.svg';
import {ReactComponent as WarningIcon} from '../../../components/icons/warning-notification.svg';
import classNames from 'classnames';
import Table from '../../documentation/Table';

const messages = defineMessages({
	requestDate: {
		id: 'PaymentList.requestDate',
		defaultMessage: 'Request date',
		description: 'Text for request date'
	},
	paymentDate: {
		id: 'PaymentList.paymentDate',
		defaultMessage: 'Payment date',
		description: 'Text for payment date'
	},
	card: {
		id: 'PaymentList.card',
		defaultMessage: 'Card',
		description: 'Text for payment date'
	},
	amount: {
		id: 'PaymentList.amount',
		defaultMessage: 'Amount (inc.VAT)',
		description: 'Text for payment amount'
	},
	status: {
		id: 'PaymentList.status',
		defaultMessage: 'Status',
		description: 'Text for status'
	}
});
const styles = createUseStyles((theme) => {
	return {
		amount: {
			textAlign: "right", 
			[theme.breakpoints.down('md')]: {
				textAlign: "left", 
			},
		},
		flexStatus12: {
			textAlign: 'right'
		},
		withIcon: {
			display: 'flex !important',
			alignItems: 'center'
		},
		paymentsContent: {
			'& td': {
				padding: theme.spacing(1),
				whiteSpace: 'nowrap'
			},
			marginTop: theme.spacing(1),
			[theme.breakpoints.down('sm')]: {
					width: '100%'
			}
		},
		mobileTile: {
			paddingRight: theme.spacing(0.5)
		},
		gridSpacing: {
			[theme.breakpoints.down('sm')]: {
				padding: '8px 16px'
			}
		}
	};
});

const useStylesTableTranspose = createUseStyles(theme => ({
    withTranspose: {
        [theme.breakpoints.down('sm')]: {
            '& tr, td': {
                display: 'block',
            },
            '& tr': {
                padding: '20px 0'
            },
            '& td': {
                padding: '2px 16px'
            }
        }
    }
}));

export function PaymentList(props) {
	const history = useSelector((state) => state.payments.history.result);
	const mobileView = isWidthDown('xs', props.width);
	const classes = styles();
	const classesTableTranspose = useStylesTableTranspose();
	return (
		<Table className={classNames(classes.paymentsContent, classesTableTranspose.withTranspose)} aria-live='polite'>
			{!mobileView && (
				<thead>
					<tr>
						<th>
							<Typography variant='h4' component='h2'>
								<FormattedMessage {...messages.requestDate} />
							</Typography>
						</th>
						<th>
							<Typography variant='h4' component='h2'>
								<FormattedMessage {...messages.paymentDate} />
							</Typography>
						</th>
						<th>
							<Typography variant='h4' component='h2'>
								<FormattedMessage {...messages.card} />
							</Typography>
						</th>
						<th className={classes.amount}>
							<Typography variant='h4' component='h2'>
								<FormattedMessage {...messages.amount} />
							</Typography>
						</th>
						<th className={classes.flexStatus12}>
							<Typography variant='h4' component='h2'>
								<FormattedMessage {...messages.status} />
							</Typography>
						</th>
					</tr>
				</thead>
			)}
			<tbody>
			{history &&
				history.map((payment) => {
					const isPaid = payment.status === 'PAID';
					let textColor = 'textPrimary';
					if (!isPaid) {
						textColor = 'textSecondary';
					}
					return (
							<tr key={payment.transactionId}>
								<td>
									{mobileView && (
										<Typography
											variant='body1'
											color='textSecondary'
											className={classes.mobileTile}
											component='span'>
											<FormattedMessage {...messages.requestDate} />:
										</Typography>
									)}
									<Typography variant='body1' color={textColor} component='span'>
										{dateFormater(payment.createdDate)}
									</Typography>
								</td>
								<td>
									{mobileView && (
										<Typography
											variant='body1'
											color='textSecondary'
											className={classes.mobileTile}
											component='span'>
											<FormattedMessage {...messages.paymentDate} />:
										</Typography>
									)}
									<Typography variant='body1' color={textColor} component='span'>
										{isPaid ? dateFormater(payment.updatedDate) : "-"}
									</Typography>
								</td>
								<td>
									{mobileView && (
										<Typography
											variant='body1'
											color='textSecondary'
											className={classes.mobileTile}
											component='span'>
											<FormattedMessage {...messages.card} />:
										</Typography>
									)}
									<Typography variant='body1' color={textColor} component='span'>
										{payment.cardNumber && payment.cardNumber.substr(8, 8)}
									</Typography>
								</td>
								<td className={classes.amount}>
									{mobileView && (
										<Typography
											variant='body1'
											color='textSecondary'
											className={classes.mobileTile}
											component='span'>
											<FormattedMessage {...messages.amount} />:
										</Typography>
									)}
									<Typography variant='body1' color={textColor} component='span'>
										{currencyFormatter.format(payment.totalValue)}
									</Typography>
								</td>
								<td className={classes.withIcon}>
									{mobileView && (
										<Typography
											variant='body1'
											color='textSecondary'
											className={classes.mobileTile}
											component='span'>
											<FormattedMessage {...messages.status} />:
										</Typography>
									)}
									<Typography
										color={textColor}
										variant='body1'
										component='span'
										className={classes.mobileTile}>
										{payment.status}
									</Typography>
									{isPaid ? (
										<SuccessIcon width={20} height={20} />
									) : (
										<WarningIcon width={20} height={20} color={osColour.neutral.charcoal} />
									)}
								</td>
							</tr>
					);
				})}
			</tbody>
		</Table>
	);
}

export default withWidth()(PaymentList);
