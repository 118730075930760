import React from 'react';
import {connect} from 'react-redux';
import Header from "../Header";
import Content from '../Content';
import DocLinks from "../DocLinks";
import Typography from '@mui/material/Typography';
import "swagger-ui-react/swagger-ui.css"
import style from '../style';
import withStyles from 'react-jss';
import TextBox from '../../../components/TextBox';
import Document from '../Document';
import Table from '../Table';
import InlineCodeSnippet from '../../../components/InlineCodeSnippet';

// This interactive demo is disabled, as we need to enable CORS on the apigee oauth endpoint before it can work.
// See OMSE-1815
// import OAuthDemo from "./OAuthDemo";

function OAuthDetail(props) {
    const {url, classes} = props;

    return <Document>
        <Header back={true}>OAuth 2 API: Technical specification</Header>
        <Content>
            <Typography variant='body1' paragraph={true}>
                This guide explains how you can use the  OAuth 2 API to create access tokens. Once you have an access token you can use it to authenticate requests to other OS Data Hub APIs.
            </Typography>
            <Typography variant='h2' className={classes.heading}>Generating an access token</Typography>
            <Typography variant='body1' paragraph={true}>
                To create an access token, you must send a HTTP POST request to the OS DataHub OAuth 2 token endpoint.
                The endpoint is secured with HTTP Basic Authentication, where the username is the Project API Key and the password is the Project API Secret.
            </Typography>
            <TextBox
                text={url + '/oauth2/token/v1'}
                label='OAuth 2 Token Endpoint'
                variant="code"
            />
            <Typography variant='h3' className={classes.heading}>
                Required HTTP Headers
            </Typography>
            <Typography variant='body1' component='div'>
                <Table>
                    <tbody>
                    <tr>
                        <th>Name</th>
                        <th>Value</th>
                        <th>Description</th>
                    </tr>
                    <tr>
                        <td>Authorization</td>
                        <td>
                            <InlineCodeSnippet>Basic &lt;encoded credentials&gt;</InlineCodeSnippet>
                        </td>
                        <td>
                            <p>
                                The encoded credentials are a base-64 encoded version of a string containing the
                                username (Project API Key), a single <InlineCodeSnippet>:</InlineCodeSnippet>, and the password (Project API Secret).
                                Once you have the encoded value you should set the value of the Authorization header to
                                'Basic', a space, and the encoded value, e.g. <InlineCodeSnippet>Basic {'<encodedValue>'}</InlineCodeSnippet>.
                            </p>
                            <p>
                                Many HTTP frameworks have support for HTTP Basic Authentication and will set the Authorization header for you.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>Content-Type</td>
                        <td>
                            <InlineCodeSnippet>application/x-www-form-urlencoded</InlineCodeSnippet>
                        </td>
                        <td>
                            <p>
                                The request body must be form-encoded.
                            </p>
                            <p>
                                Many HTTP frameworks have support for form-encoded messages and will set the content type automatically.
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Typography>
            <Typography variant='h3' className={classes.heading}>
                Request body
            </Typography>
            <Typography variant='body1' paragraph={true}>
                The request body must be form-encoded, with the following properties:
            </Typography>
            <Typography variant='body1' component='div'>
                <Table>
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                            <th>Description</th>
                        </tr>
                        <tr>
                            <td><InlineCodeSnippet>grant_type</InlineCodeSnippet></td>
                            <td>
                                <InlineCodeSnippet>client_credentials</InlineCodeSnippet>
                            </td>
                            <td>
                                The only supported grant_type is <InlineCodeSnippet>client_credentials</InlineCodeSnippet>.
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Typography>
            <Typography variant='h3' className={classes.heading}>
                Response body
            </Typography>
            <Typography variant='body1' paragraph={true}>
                The response body is returned as a JSON object, with the following properties:
            </Typography>
            <Typography variant='body1' component='div'>
                <Table>
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <th>Example Value</th>
                            <th>Description</th>
                        </tr>
                        <tr>
                            <td>
                                <InlineCodeSnippet>
                                    access_token
                                </InlineCodeSnippet>
                            </td>
                            <td>
                                <InlineCodeSnippet>
                                    ZclClFcksJlZ19vnJOt09Yj3xme0
                                </InlineCodeSnippet>
                            </td>
                            <td>The new access token.</td>
                        </tr>
                        <tr>
                            <td>
                                <InlineCodeSnippet>
                                    expires_in
                                </InlineCodeSnippet>
                            </td>
                            <td>
                                <InlineCodeSnippet>
                                    299
                                </InlineCodeSnippet>
                            </td>
                            <td>The time until the token expires, in seconds.</td>
                        </tr>
                        <tr>
                            <td>
                                <InlineCodeSnippet>
                                    issued_at
                                </InlineCodeSnippet>
                            </td>
                            <td>
                                <InlineCodeSnippet>
                                    1576249823754
                                </InlineCodeSnippet>
                            </td>
                            <td>The time at which the token was created.</td>
                        </tr>
                        <tr>
                            <td>
                                <InlineCodeSnippet>
                                    token_type
                                </InlineCodeSnippet>
                            </td>
                            <td>
                                <InlineCodeSnippet>
                                    Bearer
                                </InlineCodeSnippet>
                            </td>
                            <td>The type of the token. This is always <InlineCodeSnippet>Bearer</InlineCodeSnippet>.</td>
                        </tr>
                    </tbody>
                </Table>
            </Typography>
            <Typography variant='h2' className={classes.heading}>Using the access token</Typography>
            <Typography variant='body1' paragraph={true}>
                Once you have generated your access token, you can use it to authenticate your requests to other OS Data Hub APIs.
            </Typography>
            <Typography variant='body1' paragraph={true}>
                To use the access token you must include an Authorization header on your API request.
                The value of the Authorization header should be 'Bearer', a space, and then the access token, e.g. <InlineCodeSnippet>Bearer {'<accessToken>'}</InlineCodeSnippet>.
            </Typography>
            <DocLinks product='oauth2' hasGettingStarted={true}/>
            {/*<OAuthDemo url={url}/>*/}
        </Content>
    </Document>
}

function mapStateToProps(state) {
    const {result} = state.config.current;

    if(result) {
        return {
            url: result.apigeeUrl
        };
    }
    return {};
}

const styled = withStyles(style)(OAuthDetail);
export default connect(mapStateToProps)(styled);
