import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation, useRouteMatch} from 'react-router';
import {useTheme, useMediaQuery, Typography, CircularProgress} from "@mui/material";
import {createUseStyles} from 'react-jss';
import { AddButton, contentPadding, Notification, osColour, ExternalLink } from 'omse-components';
import BackLink from '../../../../components/BackLink';
import {defineMessages, FormattedMessage} from 'react-intl';
import routes, {getLocation} from "../../../../util/routes";
import PremiumDesc from './PremiumDesc';
import DownloadImage from "../DownloadImage";
import {clearPremiumProduct, getPremiumProduct} from "../../../../modules/premium/actions";
import {hasCom3Catalogue, hasEditPremiumDownloadsPermission, hasPartnerCatalogue} from "../../../../util/permissions";
import PartnerLicences from "../../../../components/PartnerLicences";
import CatalogueTag from "../../../../components/CatalogueTag";
import usePartnerContracts from "../../../../hooks/usePartnerContracts";
import {ERROR, LOADING, NOT_NEEDED} from "../../../../constants/state";
import PartnerLicenceRequiredDialog from "../../premiumItem/PartnerLicenceRequiredDialog";
import useProductTerms from "../../../../hooks/useProductTerms";
import ProductTermsDialog from "./ProductTermsDialog";
import {isIncludedInCatalogue} from '../../../../util/product';
import {COM3_CATALOGUE, PART_CATALOGUE} from '../../../../../shared/catalogues';
import PremiumProductDependencyDialog from "./productDependency/PremiumProductDependencyDialog";
import ProductDependencyInfo from './productDependency/ProductDependencyInfo';
import useProductDependency from '../../../../hooks/useProductDependency';
import {loadDataPackages} from "../../../../modules/dataPackages/actions";
import featureCheck from "../../../../util/featureCheck";
import * as features from "../../../../../shared/features";
import ExistingPackagesMessage, {PREMIUM} from "../../../../components/ExistingPackagesMessage";

const messages = defineMessages({
    premiumDownloads: {
        id: 'PremiumItem.backLink',
        defaultMessage: 'Premium data downloads',
        description: 'Label for back link'
    },
    productInformation: {
        id: 'PremiumItem.productInformation',
        defaultMessage: 'Available on our website',
        description: 'Label for product info link'
    },
    addDataPackage: {
        id: 'PremiumItem.addDataPackage',
        defaultMessage: 'Add data package',
        description: 'Label for adding a data package'
    },
    documentation: {
        id: 'PremiumItem.documentation',
        defaultMessage: 'Product overview and documentation',
        description: 'Label for Documentation'
    },
    error: {
        id: 'PremiumItem.error',
        defaultMessage: 'There was a problem loading the data required for "Add data package". Please retry and if the problem persists then {link}.',
        description: 'error message'
    }
});

const useStyles = createUseStyles(theme => ({
    sideTitle: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2),
        }
    },
    links: {
        marginBottom: theme.spacing(3),
        '& a': {
            marginBottom: theme.spacing(1.5),
            display: 'flex'
        },
        '& svg': {
            position: 'relative',
            top: theme.spacing(-1),
            left: 5
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2)
        }
    },
    sideInfo: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(6.5),
        flex: '1 0 auto',
        [theme.breakpoints.down('md')]: {
            marginRight: 70
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: 0
        },
        paddingTop: contentPadding.top - contentPadding.backLink - 10,
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile,
            paddingTop: theme.spacing(3)
        }
    },
    root: {
        flex:1,
        paddingTop: contentPadding.top - contentPadding.backLink - 10,
        paddingRight: contentPadding.right,
        paddingLeft: contentPadding.left,
        maxWidth: 1400,
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile,
            paddingTop: theme.spacing(3)
        }
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column'
        }
    },
    titleText: {
        width: '100%',
        display:'flex',
        [theme.breakpoints.down('lg')]: {
            flexWrap: 'wrap-reverse',
            alignItems: 'flex-end'
        },
        justifyContent: 'space-between'
    },
    productTitle: {
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    ImageContainer: {
        display: 'flex',
        flex: '1 0 auto',
        flexDirection: 'row',
        alignContent: 'space-evenly',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    addDataPackage: {
        height: 40,
        alignSelf: 'flex-end'
    },
    actions: {
        color: osColour.neutral.charcoal,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('lg')]: {
            marginTop: theme.spacing(3),
            display: 'block'
        }
    },
    details: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column'
        }
    },
    partnerLicences: {
        marginTop: 0,
        marginBottom: theme.spacing(2)
    }
}));

export default function PremiumItem(props) {
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const classes = useStyles(props);
    const theme = useTheme();
    const smallView = useMediaQuery(theme.breakpoints.down('lg'));

    const [dialog, setDialog] = useState(null);
    const [productRequested, setProductRequested] = useState(false);

    const productId = match.params.productId;

    const user = useSelector(state => state.user.current.result);
    const productState = useSelector(state => state.premium.product);
    const dataPackages = useSelector(state => state.dataPackages.list.result);
    const config = useSelector(state => state.config?.current?.result);

    const productTermsResult = useProductTerms(productId);
    const partnerLicences = usePartnerContracts(productId);
    const productDependencyResult = useProductDependency(productId);

    const [dataInitialising, setDataInitialising] = useState(true);
    const [showDataInitialising, setShowDataInitialising] = useState(false);
    const [showError, setShowError] = useState(true);
    const [filteredDataPackages, setFilteredDataPackages] = useState([]);

    const [item, setItem] = useState(((location.state && location.state.item) || productState?.result));

    if(item && item.id !== productId) {
        setDialog(null);
        setItem(null);
    }

    useEffect(() => {
        dispatch(loadDataPackages())
    }, [dispatch]);

    useEffect(() => {
        if (dataPackages?.length) {
            setFilteredDataPackages(dataPackages.filter(item => item.productId === productId));
        }
    }, [productId, dataPackages])

    useEffect(() => {
        if(!item && productState?.result) {
            setItem(productState.result);
        }
    }, [productState, item]);

    // Get the product, if it is not already available.
    useEffect(() => {
        if(!item) {
            dispatch(getPremiumProduct(productId));
            setProductRequested(true);
        }
    }, [dispatch, productId, item]);

    // When the component closes we do not want to leave any stale error state in the store,
    // so we return a function to clear up the loaded product.
    useEffect(() => {
        return function cleanup() {
            dispatch(clearPremiumProduct());
        }
    }, [dispatch]);

    // If our attempt to load the product failed then go to the 404 page
    useEffect(() => {
        if(productRequested && productState.error) {
            const newLocation = getLocation(routes.error404Premium, location);
            history.push(newLocation);
        }
    }, [dispatch, productRequested, productState, item, history, location]);

    const goToAddDataPackage = useCallback(() => {
        const path = routes.dataPackageNew.replace(':productId', item.id);
        const newLocation = getLocation(path, location, {}, { state: {product: item, canGoBack: true } });
        history.push(newLocation);
    }, [history, item, location]);

    const handleClick = useCallback((event, acceptedTerms) => {
        let additionalDialog;

        const partnerLicencesAllowAddDataPackage = partnerLicences === NOT_NEEDED || (Array.isArray(partnerLicences) && partnerLicences.length > 0);
        const productDependencyAllowAddDataPackage = (productDependencyResult === NOT_NEEDED || (Array.isArray(productDependencyResult) && productDependencyResult.length === 0));
        const productTermsAllowAddDataPackage = (productTermsResult === NOT_NEEDED) || acceptedTerms;

        const allowAddDataPackage = (partnerLicencesAllowAddDataPackage && productDependencyAllowAddDataPackage && productTermsAllowAddDataPackage);

        // Partner licences
        if (hasPartnerCatalogue(user) && isIncludedInCatalogue(item, PART_CATALOGUE)) {
            if (dataInitialising) {
                setShowDataInitialising(true);
            } else {
                if (!allowAddDataPackage) {
                    additionalDialog = <PartnerLicenceRequiredDialog onClose={closeDialog} productId={productId}/>;
                }
            }
        }

        // Commercial product terms and dependency dialogs.
        if (hasCom3Catalogue(user) && isIncludedInCatalogue(item, COM3_CATALOGUE) && !acceptedTerms) {
            if (dataInitialising) {
                setShowDataInitialising(true);
            } else {
                if (!productDependencyAllowAddDataPackage) {
                    additionalDialog = <PremiumProductDependencyDialog onClose={closeDialog} productId={item.id} productName={item.label} products={productDependencyResult} />;
                } else if (!productTermsAllowAddDataPackage) {
                    additionalDialog = <ProductTermsDialog onClose={closeDialog} onConfirm={() => handleClick(null, true)} product={productTermsResult} />;
                }
            }
        }

        if(allowAddDataPackage) {
            goToAddDataPackage();
        } else {
            setDialog(additionalDialog);
        }
    }, [goToAddDataPackage, dataInitialising, item, partnerLicences, productDependencyResult, productId, productTermsResult, user]);

    // If any background data changes, set the data initialised state.
    useEffect(() => {
        const isInitialised = result => (result !== LOADING && result !== ERROR);
        const prerequisitesInitialised = isInitialised(partnerLicences)
            && isInitialised(productTermsResult) 
            && isInitialised(productDependencyResult);
        setDataInitialising(!prerequisitesInitialised);
    }, [partnerLicences, productDependencyResult, productTermsResult]);

    // Once all data is initalised, if a call has been queued, which is indicated by showDataInitialising,
    // action it via the click handler.
    useEffect(() => {
        if(!dataInitialising && showDataInitialising) {
            setShowDataInitialising(false);
            handleClick();
        }
    }, [handleClick, productDependencyResult, setShowDataInitialising, showDataInitialising, dataInitialising]);

    function closeDialog() {
        setDialog(null);
    }

    if (!item) {
        return <>
            <BackLink path={routes.premiumDownloads} label={messages.premiumDownloads}/>
            <header className={classes.root}>
                <CircularProgress size={32} className={classes.loader} />
            </header>
        </>
    }

    const images = [];
    if (item.images) {
        for(let i = 0; i < item.images.length; i++) {
            images.push(
                <DownloadImage key={i} images={item.images} fixedIndex={i} productName={item.label} />
            );
        }
    }

    const dataError = productDependencyResult === ERROR || productTermsResult === ERROR || partnerLicences === ERROR;

    const actions = hasEditPremiumDownloadsPermission(user) && <div className={classes.actions}>
        <AddButton classes={{button: classes.addDataPackage}}
                   label={messages.addDataPackage}
                   action={handleClick}
                   disabled={(showDataInitialising && dataInitialising) || dataError}
                   working={showDataInitialising && dataInitialising}
        />
        <ProductDependencyInfo productId={item.id} productName={item.label} products={productDependencyResult} />
        { featureCheck(features.EAI, config) && filteredDataPackages.length > 0 &&
            <ExistingPackagesMessage existingDataPackages={filteredDataPackages} product={PREMIUM}/>
        }
    </div>;

    return <>
        {(dataError && showError) &&
            <Notification appearance='toast' variant='error' onClose={() => setShowError(false)}>
                <Typography variant='body1' className={classes.errorText}>
                    <FormattedMessage {...messages.error} values={{ link: <ExternalLink type='support' /> }} />
                </Typography>
            </Notification>
        }
        <BackLink path={routes.premiumDownloads} label={messages.premiumDownloads}/>
        <div className={classes.root}>
            <header>
                <div className={classes.titleContainer}>
                    <div className={classes.titleText}>
                        <Typography variant='h1' color='primary' className={classes.productTitle}>
                            {item.label}
                        </Typography>
                        <div>{item.catalogues.map(aItem => <CatalogueTag key={aItem.id} type={aItem.id} size='md' />)}</div>
                    </div>
                </div>
            </header>

            <div className={classes.details}>
                <div>
                    <PartnerLicences productId={item.id} classes={{ root: classes.partnerLicences }}/>
                    <PremiumDesc item={item} />
                    { smallView && actions }
                    <aside className={classes.sideInfo}>
                        <Typography variant='h4' className={classes.sideTitle}>
                            <FormattedMessage {...messages.documentation}/>
                        </Typography>
                        <div className={classes.links}>
                            <a target="_blank" rel="noopener noreferrer" href={item.infoUrl}>
                                <Typography variant='body1'>
                                    <FormattedMessage {...messages.productInformation}/>
                                </Typography>
                            </a>
                        </div>

                        <div className={classes.ImageContainer}>
                            { images }
                        </div>
                    </aside>
                </div>
                { !smallView && actions }
            </div>
        </div>
        {dialog}
    </>;
}

PremiumItem.propTypes = {
};
