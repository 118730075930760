import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { InternalLink, ExternalLink } from 'omse-components';
import routes from '../../util/routes';

export default function DocLinks(props) {
    const {product, hasGettingStarted, hasTechnicalSpec, hasDemo} = props;

    let productName = '';
    let startLink;
    let techLink;
    let demoLink;

    switch (product) {
        case 'wfs':
            productName = 'OS Features API';
            startLink = <InternalLink path={routes.wfsIntro} message="getting started guide" />;
            techLink = <InternalLink path={routes.wfsDetail} message="technical specification" />;
            demoLink = <ExternalLink type="generic" href='https://labs.os.uk/public/os-data-hub-examples/os-features-api/' message="code examples" />;
            break;
        case 'wmts':
            productName = 'OS Maps API';
            startLink = <InternalLink path={routes.wmtsIntro} message="getting started guide" />;
            techLink = <InternalLink path={routes.wmtsDetail} message="technical specification" />;
            demoLink = <ExternalLink type="generic" href='https://labs.os.uk/public/os-data-hub-examples/os-maps-api/' message="code examples" />;
            break;
        case 'match':
            productName = 'OS Match & Cleanse API';
            startLink = <InternalLink path={routes.matchIntro} message="getting started guide" />;
            techLink = <InternalLink path={routes.matchDetail} message="technical specification" />;
            break;
        case 'names':
            productName = 'OS Names API';
            startLink = <InternalLink path={routes.namesIntro} message="getting started guide" />;
            techLink = <InternalLink path={routes.namesDetail} message="technical specification" />;
            demoLink = <ExternalLink type="generic" href='https://labs.os.uk/public/os-data-hub-examples/os-names-api/' message="code examples" />;
            break;
        case 'places':
            productName = 'OS Places API';
            startLink = <InternalLink path={routes.placesIntro} message="getting started guide" />;
            techLink = <InternalLink path={routes.placesDetail} message="technical specification" />;
            demoLink = <ExternalLink type="generic" href='https://labs.os.uk/public/os-data-hub-examples/os-places-api/' message="code examples" />;
            break;
        case 'vts':
            productName = 'OS Vector Tile API';
            startLink = <InternalLink path={routes.vtsIntro} message="getting started guide" />;
            techLink = <InternalLink path={routes.vtsDetail} message="technical specification" />;
            demoLink = <ExternalLink type="generic" href='https://labs.os.uk/public/os-data-hub-examples/os-vector-tile-api/' message="code examples" />;
            break;
        case 'downloads':
            productName = 'OS Downloads API';
            startLink = <InternalLink path={routes.downloadsIntro} message="getting started guide" />;
            techLink = <InternalLink path={routes.downloadsDetail} message="technical specification" />;
            break;
        case 'oauth2':
            productName = 'OAuth 2 API';
            startLink = <InternalLink path={routes.oauthIntro} message="getting started guide" />;
            techLink = <InternalLink path={routes.oauthDetail} message="technical specification" />;
            break;
        case 'linkedIdentifiers':
            productName = 'OS Linked Identifiers API';
            startLink = <InternalLink path={routes.linkedIdentifiersIntro} message="getting started guide" />;
            techLink = <InternalLink path={routes.linkedIdentifiersDetail} message="technical specification" />;
            demoLink = <ExternalLink type="generic" href='https://labs.os.uk/public/os-data-hub-examples/os-linked-identifiers-api/' message="code examples" />;
            break;
        case 'ofa':
            productName = 'OS NGD API – Features';
            startLink = <InternalLink path={routes.ofaIntro} message="getting started guide" />;
            techLink = <InternalLink path={routes.ofaDetail} message="technical specification" />;
            demoLink = <ExternalLink type="generic" href='https://labs.os.uk/public/osngd/os-ngd-api-features' message="code examples" />;
            break;
        case 'ota':
            productName = 'OS NGD API – Tiles';
            startLink = <InternalLink path={routes.otaIntro} message="getting started guide" />;
            techLink = <InternalLink path={routes.otaDetail} message="technical specification" />;
            demoLink = <ExternalLink type="generic" href='https://labs.os.uk/public/osngd/os-ngd-api-tiles' message="code examples" />;
            break;
        default:
            break;
    }

    return <Fragment>
            <Typography variant='h1' component={'h2'}>What next?</Typography>
        <Typography variant='body1' component="div">
            <p>For further reading on the {productName}, refer to the&nbsp;
            { hasGettingStarted && startLink}
            { hasTechnicalSpec &&
                <Fragment>
                    {hasGettingStarted && hasDemo && ', '}
                    {hasGettingStarted && !hasDemo && ', or the '}
                    {techLink}
                </Fragment>
            }
            { hasDemo &&
                ((!hasGettingStarted && !hasTechnicalSpec) ? {demoLink} : <Fragment> or check out {demoLink}</Fragment>)
            }
                .
            </p>
        </Typography>
    </Fragment>
}

DocLinks.propTypes = {
    product: PropTypes.oneOf(['wfs','wmts', 'names', 'vts','downloads', 'oauth2', 'linkedIdentifiers', 'places', 'match', 'ofa', 'ota']).isRequired,
    hasGettingStarted: PropTypes.bool,
    hasTechnicalSpec: PropTypes.bool,
    hasDemo: PropTypes.bool
};

