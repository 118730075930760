import {
    createLoadingReducer,
    createLoadingMiddleware,
    createActionReducer,
    createActionMiddleware
} from 'omse-components';
import {
    LOAD_SERVICE_STATUS_API_ACTION, LOADED_SERVICE_STATUS_APIS_ACTION,
    LOAD_SERVICE_STATUS_APIS_ACTION, LOADED_SERVICE_STATUS_API_ACTION,
    CLEAR_SERVICE_STATUS_CACHE_ACTION
} from './serviceStatus/actions';
import {combineReducers} from 'redux';
export const serviceStatusReducer = combineReducers({
    current: createLoadingReducer(LOAD_SERVICE_STATUS_APIS_ACTION, LOADED_SERVICE_STATUS_APIS_ACTION),
    api: combineReducers({
        current: createLoadingReducer(LOAD_SERVICE_STATUS_API_ACTION, LOADED_SERVICE_STATUS_API_ACTION)
    }),
    clearCache: createActionReducer(CLEAR_SERVICE_STATUS_CACHE_ACTION)
});

export const serviceStatusMiddleware = [
    createLoadingMiddleware(LOAD_SERVICE_STATUS_APIS_ACTION, LOADED_SERVICE_STATUS_APIS_ACTION, function() {
        return '/api/serviceStatus';
    }),
    createLoadingMiddleware(LOAD_SERVICE_STATUS_API_ACTION, LOADED_SERVICE_STATUS_API_ACTION, function(action) {
        return `/api/serviceStatus/api/${action.apiId}`;
    }),
    createActionMiddleware(CLEAR_SERVICE_STATUS_CACHE_ACTION, () => ({
        url: '/api/serviceStatus/cache',
        method: 'DELETE'
    }))
];
