import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {AddButton} from "omse-components";
import NameProjectDialog from "./createProjectButton/NameProjectDialog";
import ProjectLimitDialog from './createProjectButton/ProjectLimitDialog';
import useProjectsLimit from '../hooks/useProjectsLimit';
import {openNewProjectDialog, closeNewProjectDialog} from '../modules/project/actions';
import {withTermsActionCheck} from '../util/terms';
import {apiTermsName} from '../../shared/constants';

export default function CreateProjectButton(props) {
    const {label, showIcon, componentId} = props;
    const [availableProjects, initialising] = useProjectsLimit();
    const dispatch = useDispatch();
    const {working} = useSelector(state => state.project.create);
    
    const user = useSelector(state => state.user.current.result);
    const org = useSelector(state => state.organisation.current);
    
    const projectDialogId = useSelector(state => state.project.newProject.dialogId);
    const projectDialogOpen = useSelector(state => state.project.newProject.open) && (projectDialogId === componentId);
    const handleNameProjectClose = () => dispatch(closeNewProjectDialog());
    const onAddAction = () => dispatch(withTermsActionCheck(apiTermsName, openNewProjectDialog(componentId), user, org));

    return <>
        {!initialising &&
            <AddButton label={label} showIcon={showIcon} working={working}
                    action={onAddAction} />
        }
        {projectDialogOpen &&
            <>
                {availableProjects?
                    <NameProjectDialog handleClose={handleNameProjectClose}/>
                    :
                    <ProjectLimitDialog handleClose={handleNameProjectClose}/>
                }
            </>
        }
    </>
}

CreateProjectButton.propTypes = {
    label: PropTypes.object.isRequired,
    showIcon: PropTypes.bool,
    componentId: PropTypes.string.isRequired
};

