import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router';
import {defineMessages, FormattedMessage} from "react-intl";
import {createUseStyles} from 'react-jss';
import Typography from "@mui/material/Typography";
import { Notification, osColour, ExternalLink } from 'omse-components';
import Button from "@mui/material/Button";
import classNames from 'classnames';
import CircularProgress from "@mui/material/CircularProgress";
import SubmitButton from "./addDataPackage/SubmitButton";
import useActionIdSelector from "../../../hooks/useActionIdSelector";
import {clearCreateDataPackage, resetDraftOrder} from "../../../modules/dataPackages/actions";
import OptionNotAvailableDialog from './addDataPackage/OptionNotAvailableDialog';
import {hasCom3Catalogue} from '../../../util/permissions';
import {useSelector} from "react-redux";
import PackagePricePanel from "./addDataPackage/PackagePricePanel";
import featureCheck from '../../../util/featureCheck';
import * as features from '../../../../shared/features';
import routePaths from "../../../util/routes";
import useBackNavigation from "../../../hooks/useBackNavigation";

const messages = defineMessages({
    cancel: {
        id: 'AddDataPackage.cancel',
        defaultMessage: 'Cancel',
        description: 'Cancel label'
    },
    errorMessage: {
        id: 'AddDataPackage.errorMessage',
        defaultMessage: 'There was a problem creating your data package. Please retry and if the problem persists then {link}.',
        description: 'error message text'
    },
    submit: {
        id: 'AddDataPackage.submit',
        defaultMessage: 'Create data package',
        description: 'submit label'
    }
});

const styles = createUseStyles(theme => ({
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(1)} 0`,
        backgroundColor: osColour.neutral.clouds
    },
    firstButton: {
        marginRight: theme.spacing(2)
    },
    actionButton: {
        whiteSpace: 'nowrap'
    },
    leftSide: {
        zIndex: 1,
        boxShadow: '0 1px 6px 3px rgba(0, 0, 0, 0.2)',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: 'auto'
        }
    },
    rightSide: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            height: '40vh'
        }
    },
    downloadPackageContainer: {
        flexGrow: 1,
        overflowY: 'auto',
        width: '500px',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    loadingContainer: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        width: 500,
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            overflowY: 'auto',
        },
        position: 'relative',
        zIndex: '1',
        overflowX: 'hidden',
        overflowY: 'hidden',
    }
}));

// Compound components pattern for sharing state without prop drilling.
const AddDataPackageContext = React.createContext();

export function useAddDataPackageContext() {
    const context = React.useContext(AddDataPackageContext);
    if (!context) {
        throw new Error('Attempting to render outside AddDataPackage component');
    }
    return context;
}

export default function AddDataPackage({
    panel,
    successPanel,
    dataPackageMap,
    dialog,
    errorMessage,
    submitLabel
}) {
    const params = useParams();
    const onCancel = useBackNavigation(getDefaultPath());
    const classes = styles();

    function getDefaultPath() {
        let newPath = routePaths.recipeLibrary;
        if(params.packageId) {
            newPath = routePaths.dataPackage.replace(':packageId', params.packageId);
        } else if(params.productId) {
            newPath = routePaths.premiumItem.replace(':productId', params.productId);
        }
        return newPath;
    }

    const [showInvalidOptionDialog, setShowInvalidOptionDialog] = useState(false);
    const contextValue = useMemo(() => ({setShowInvalidOptionDialog}), [setShowInvalidOptionDialog]);

    let [{working, error, result}, addDispatch] = useActionIdSelector("dataPackages.create");
    const loading = !panel;

    const user = useSelector(state => state.user.current.result);
    const product = useSelector(state => state.premium.product.result);
    const initialising = hasCom3Catalogue(user) && !product;

    const priceState = useSelector(state => state.dataPackages.price);
    const eaiDisable = hasCom3Catalogue(user) && (priceState.working || !priceState.result?.totalPrice)
    const config = useSelector(state => state.config.current?.result);
    
    useEffect(() => {
        return function cleanup() {
            // The data package map will zoom to the extent of any successful create, so we
            // need to clear that state when the component closes. This is a good time to clean
            // up the draft order too, so that we have a clean slate if the user tries to create
            // another data package.
            addDispatch(clearCreateDataPackage());
            addDispatch(resetDraftOrder(true));
        }
    }, [addDispatch]);

    let panelContent;
    if(result) {
        panelContent = <div className={classes.downloadPackageContainer}>
            {successPanel}
        </div>;
    } else if(loading || working) {
        panelContent = <div className={classes.loadingContainer}>
            <CircularProgress size={32} className={classes.loader}/>
        </div>;
    } else {
        panelContent = <div className={classes.downloadPackageContainer}>
            { panel }
        </div>
    }

    let actions;
    if(!result) {
        actions = <div className={classes.actions}>
            <Button key='cancel'
                    onClick={onCancel}
                    size='medium'
                    variant='outlined'
                    color='primary'
                    disabled={working}
                    className={classNames(classes.firstButton, classes.actionButton)}>
                <FormattedMessage {...messages.cancel}/>
            </Button>
            <SubmitButton dispatch={addDispatch}
                          working={working}
                          disabled={initialising || eaiDisable}
                          label={submitLabel} />
        </div>;
    }

    return <>
        {error && <Notification variant='error'
                                appearance='inline'
                                role='alert'
                                onClose={() => addDispatch(clearCreateDataPackage())}
        >
            <Typography variant='body1'>
                <FormattedMessage {...errorMessage} values={{ link: <ExternalLink type='support' /> }} />
            </Typography>
        </Notification>}

        {showInvalidOptionDialog && <OptionNotAvailableDialog onClose={() => setShowInvalidOptionDialog(false)}/>}
        {
            // Display a dialog over the top, if we have been asked to
            dialog
        }

        <div className={classes.container}>
            <div className={classes.rightSide}>
                {dataPackageMap}
            </div>
            <div className={classes.leftSide}  data-testid="add-data-package-panel">
                <AddDataPackageContext.Provider value={contextValue}>
                    {panelContent}
                </AddDataPackageContext.Provider>
                {
                    featureCheck(features.EAI, config) && hasCom3Catalogue(user) && <PackagePricePanel/>
                }

                {actions}
            </div>
        </div>
    </>;
}

AddDataPackage.propTypes = {
    panel: PropTypes.object,         // May be missing when the component is first loading
    successPanel: PropTypes.object,  // May be missing when the component is first loading
    dataPackageMap: PropTypes.object.isRequired,
    dialog: PropTypes.object,
    errorMessage: PropTypes.object   // Allows the parent component to customise the error message. 'email' is available as an insert.
};

AddDataPackage.defaultProps = {
    errorMessage: messages.errorMessage,
    submitLabel: messages.submit
};
