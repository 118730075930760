import {useState, useCallback} from 'react';

// This hook gives a simple way to keep track of a counter. The increment and decrement functions are stable
// (e.g. they do not change every time the value changes), and they do not accidentally work on an old value
// (which could happen if they trapped the current count in a closure).

export default function useCounter(initialCount = 0) {
    const [count, setCount] = useState(initialCount);

    const increment = useCallback(() => {
        // Note that we do not use 'count' here, instead we supply a function that takes the current value. That
        // ensures that this memoised function does not trap an out of date value in its closure.
        setCount(c => c + 1);
    }, [setCount]);
    const decrement = useCallback(() => {
        // Again, we are careful not to use 'count' here.
        setCount(c => c - 1);
    }, [setCount]);


    return [count, increment, decrement];
}
