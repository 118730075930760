import React, {Fragment, useRef, useEffect} from 'react';
import {useHistory} from 'react-router';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {osColour, arrow, box, linkButton, arrowTooltipStyles} from 'omse-components';
import classNames from 'classnames';
import {ReactComponent as WarningIcon} from './icons/warning-notification.svg';
import {defineMessages, FormattedMessage} from 'react-intl';
import {updateNotification} from '../modules/notifications/actions'
import {createUseStyles} from 'react-jss';
import {useSelector, useDispatch} from "react-redux";
import routePaths, {getLocation} from "../util/routes";
import NavButton from './NavButton';
import DOMPurify from 'dompurify';

const messages = defineMessages({
    markAsRead: {
        defaultMessage: 'Mark as read',
        description: 'Text for mark as read button',
        id: 'NotificationItem.markAsRead'
    },
    markAsUnread: {
        defaultMessage: 'Unread',
        description: 'Text for unread button',
        id: 'NotificationItem.unread'
    },
    viewInvitation: {
        defaultMessage: 'View invitation',
        description: 'view invitation button text',
        id: 'NotificationItem.viewInvitation'
    },
    duration: {
        defaultMessage: 'Just now',
        description: 'Default text for duration label',
        id: 'NotificationItem.duration'
    },
    second: {
        id: 'NotificationItem.second',
        defaultMessage: '{data, plural, one {# second} zero {Just now} other {# seconds}} ago',
        description: 'Second label'
    },
    minute: {
        id: 'NotificationItem.minute',
        defaultMessage: '{data, plural, one {# minute} other {# minutes}} ago',
        description: 'Minute label'
    },
    hour: {
        id: 'NotificationItem.hour',
        defaultMessage: '{data, plural, one {# hour} other {# hours}} ago',
        description: 'Hour label'
    },
    day: {
        id: 'NotificationItem.day',
        defaultMessage: '{data, plural, one {# day} other {# days}} ago',
        description: 'Day label'
    },
    month: {
        id: 'NotificationItem.month',
        defaultMessage: '{data, plural, one {# month} other {# months}} ago',
        description: 'Month label'
    },
    year: {
        id: 'NotificationItem.year',
        defaultMessage: '{data, plural, one {# year} other {# years}} ago',
        description: 'Year label'
    },
    attention: {
        id: 'NotificationItem.attention',
        defaultMessage: 'Attention',
        description: 'Attention label'
    }
});

const useStyles = createUseStyles(theme => ({
    notificationItem: {
        width: 320,
        padding: '12px 20px',
        marginBottom: 2,
        background: '#f3f3f3',
        '&.unread': {
            background: 'transparent'
        }
    },
    notificationTitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    notificationBody: {
        marginBottom: theme.spacing(1),
        '& p': {
            marginTop: 0,
            marginBottom: 8
        },
        '& h1,h2,h3': {
            marginTop: 5,
            marginBottom: 5,
            fontSize: '1rem'
        }
    },
    attention: {
        color: osColour.neutral.charcoal,
        display: 'inline-flex',
        alignItems: 'center',
        minHeight: 28,
        background: osColour.status.warning,
        '& svg:last-of-type': {
            position: 'relative',
            right: -0.5
        },
        paddingLeft: 20,
        position: 'relative',
        left: -20
    },
    attentionCaption: {
        fontSize: '1rem',
        padding: '0 9px 0 6px',
        lineHeight: 1,
        color: osColour.neutral.charcoal,
        whiteSpace: 'nowrap'
    },
    itemButton: {
        minWidth: 'initial',
        padding: 0,
        '&:hover': {
            background: 'transparent'
        },
        '&.working': {
            opacity: 0.6
        }
    },
    tooltipPopper: {
        boxShadow: box.shadow,
        borderRadius: box.borderRadius,
        '&[data-popper-placement*="bottom"] $arrow': {
            top: 0,
            marginTop: arrow.offset
        },
        '&[data-popper-placement*="top"] $arrow': {
            bottom: 0,
            marginBottom: arrow.offset
        },
        '&[data-popper-placement*="top"] $tooltipDate': {
            marginBottom: 0
        },
        background: osColour.neutral.white,
        opacity: 1,
        transition: 'none'
    },
    tooltipDate: {
        margin: 0,
        background: 'transparent',
        padding: 0,
        boxShadow: 'none'
    },
    dateContent: {
        padding: '9px 11px',
        background: osColour.neutral.white,
        color: osColour.neutral.stone,
        zIndex: 1,
        borderRadius: box.borderRadius,
        margin: 0
    },
    sentDate: {
        color: osColour.neutral.stone,
        float: 'right'
    },
    messageAction: {
        padding: '0 0 ' + theme.spacing(1.5) + ' 0'
    }, 
    ...arrowTooltipStyles()
}));

const useLinkStyles = createUseStyles({linkButton});

export function calculateDuration(sentStr, now = new Date()) {
    const sent = new Date(sentStr);    
    let sec = (now.getTime() - sent.getTime()) / 1000;
    let min, hour, day, month, year = 0;
    let d = {message: messages.duration};
    if (sec > 0) {
        d = {message: messages.second, value: sec};
    }
    if (sec >= 60) {
        min = sec / 60;
        d = {message: messages.minute, value: min};
    }
    if (min >= 60) {
        hour = min / 60;
        d = {message: messages.hour, value: hour};
    }
    if (hour >= 24) {
        day = hour / 24;
        d = {message: messages.day, value: day};
    }
    if (day >= 30) {
        month = day / 30;
        d = {message: messages.month, value: month};
    }
    if (month >= 12) {
        year = month / 12;
        d = {message: messages.year, value: year};
    }
    return d;
}

export default function NotificationItem(props) {
    const {item, handleNavigation} = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const classesLink = useLinkStyles();
    const contentRef = useRef();
    const history = useHistory();
    const userDetails = useSelector(state => state.user.current.result);

    useEffect(() => {
        function interceptor(event) {
            const path = event.target.getAttribute('href');
            if(path.startsWith('/')) {
                event.preventDefault();
                const newLocation = getLocation(path, history.location);
                history.push(newLocation);
                handleNavigation();
            }
        }
        let tags = [];
        if(contentRef.current) {
            tags = contentRef.current.getElementsByTagName('a');
            for(var i = 0; i < tags.length; i++) {
                tags[i].addEventListener('click', interceptor);
            }
        }

        return () => {
            for(var i = 0; i < tags.length; i++) {
                tags[i].removeEventListener('click', interceptor);
            }
        }
    }, [contentRef, history, handleNavigation]);

    function formatDate(sentStr) {
        let d = new Date(sentStr);
        let options = {
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
            year: 'numeric',
            month: 'short',
            day: '2-digit'
        };
        return new Intl.DateTimeFormat(undefined, options).format(d);
    }

    function markAsRead(e, notification) {
        notification.working = true;
        dispatch(updateNotification(notification.id, {read: true, type: notification.type}));
    }

    function markAsUnread(e, notification) {
        notification.working = true;
        dispatch(updateNotification(notification.id, {read: false}));
    }
    
    const notificationItemClasses = classNames({
        [classes.notificationItem]: true,
        unread: item.read
    });

    const working = item.working;

    const itemButtonClass = classNames({
        [classes.itemButton]: true,
        working
    });

    const duration = calculateDuration(item.sent);

    if (item.title && item.title.indexOf('You’ve been invited to join') === 0
        && userDetails && userDetails.hasInvitation && item.title.indexOf(userDetails.hasInvitation) !== -1) {
            const action = <NavButton key={routePaths.invitation} path={routePaths.invitation} classes={{root: itemButtonClass}}>
                <Typography variant='body1' className={classesLink.linkButton}>
                    <FormattedMessage {...messages.viewInvitation}/>
                </Typography>
            </NavButton>;
            item.actions = [action];
    }

    return <div className={notificationItemClasses} key={item.id}>
        {item.level === 1 &&
            <div className={classes.attention}>
                <WarningIcon alt='' height={24} width={24} />
                <Typography variant='caption' component='span' className={classes.attentionCaption}>
                    <FormattedMessage {...messages.attention}/>
                </Typography>
                <svg xmlns="http://www.w3.org/2000/svg" height="28" width="9" role='img' alt=''>
                    <polygon fill={item.read? "#fff" : "#f3f3f3"} points="0,30 8,0 9,0 9,30"/>
                </svg>
            </div>
        }
        <Typography variant="body2" component='h1' className={classes.notificationTitle}>
            {item.title}
        </Typography>
        {item.actions && item.actions.length > 0 &&
            <Typography variant='body1' className={classes.messageAction}>
                {item.actions.map(action => action)}
            </Typography>
        }
        <Typography variant='body1' className={classes.notificationBody} 
            ref={contentRef} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.body)}} />
        {item.read?
            <Button className={itemButtonClass}
                    onClick={e => markAsUnread(e, item)}
                    disabled={item.working}
                    disableRipple>
                <Typography variant="caption" className={classesLink.linkButton}>
                    <FormattedMessage {...messages.markAsUnread}/>
                </Typography>
            </Button>
            :
            <Button className={itemButtonClass}
                    onClick={e => markAsRead(e, item)}
                    disabled={item.working}
                    disableRipple>
                <Typography variant="caption" className={classesLink.linkButton}>
                    <FormattedMessage {...messages.markAsRead}/>
                </Typography>
            </Button>
        }
        {item.sent &&
            <Fragment>
                <Tooltip
                    TransitionProps={{timeout: 0}}
                    title={<Fragment>
                        <span className={classes.arrow}/>
                        <Typography variant='body1' className={classes.dateContent}>
                            {formatDate(item.sent)}
                        </Typography>
                    </Fragment>}
                    placement="top"
                    classes={{popper: classes.tooltipPopper, tooltip: classes.tooltipDate}}
                    PopperProps={{
                        popperOptions: {
                            strategy: 'fixed',
                            modifiers: [
                                {
                                    name: 'arrow',
                                    enabled: true,
                                    options: {
                                        element: '.' + classes.arrow,
                                    }
                                },
                                {
                                    name: 'preventOverflow',
                                    enabled: true,
                                    options: {
                                        padding: 5
                                    }
                                }
                            ]
                        }
                    }}>
                    <ButtonBase className={classes.sentDate} disableRipple>
                        <Typography variant="caption" className={classes.itemInfo}>
                            <FormattedMessage {...duration.message} values={{data: parseInt(duration.value)}}/>
                        </Typography>
                    </ButtonBase>
                </Tooltip>
            </Fragment>
        }
    </div>
}

NotificationItem.propTypes = {
    item: PropTypes.object.isRequired,
    handleNavigation: PropTypes.func.isRequired
};