import React, {useCallback, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {modules} from 'omse-components';
import {useHistory, useLocation} from "react-router-dom";
import TermsConditionsAccept from './TermsConditionsAccept';
import DisallowedTermsAcceptanceDialog from './DisallowedTermsAcceptanceDialog';
import DeclineTermsDialog from './DeclineTermsDialog';
import DetailedAcceptTermsIntro from './DetailedAcceptTermsIntro';
import LegalTerms from "../LegalTerms";
import messages from './TermsConditionsAcceptMessages';
import {
    generalTermsName,
    apiTermsName,
    downloadTermsName,
    allTermsNames,
    allTermsLatestVersions,
    latestEaiApiTermsVersion
} from '../../../../shared/constants';
import {termsAcceptState, usesOrganisationTerms, viewsOrganisationTermsOnly} from '../../../../shared/terms';
import {isOpenDataPersonal} from '../../../util/organisation';
import {clearTerms} from '../../../modules/terms/actions';
import {getUserTermsAcceptState} from '../../../util/terms';
import routePaths, {getLocation, logOut} from '../../../util/routes'
import { USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN } from "../../../../shared/plans";
import useFeatureCheck from "../../../util/useFeatureCheck";
import { EAIAPI } from "../../../../shared/features";

const {acceptTerms} = modules.actions.user;

 export default function withTermsBarrier(WrappedComponent, variant, actionScopedTermsMode) {
    return function TermsBarrier() {
        const user = useSelector(state => state.user.current.result);
        const org = useSelector(state => state.organisation.current);
        const displayedActionBasedTerms = useSelector(state => state.terms);
        const hasEaiApiFeature = useFeatureCheck(EAIAPI);

        const location = useLocation();
        const history = useHistory();
        const dispatch = useDispatch();

        const usingPersonalOrg = isOpenDataPersonal(user, org);
        const userTermsAcceptance = getUserTermsAcceptState(variant, user, org);
        const isTermsUpdate = userTermsAcceptance === termsAcceptState.update;
        const isLoggedIn = user && user.terms;
        const isEaiUser =  hasEaiApiFeature && isLoggedIn && user.plan === USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN;

        let declineLocation, termsAcceptIntro, disallowTermsAcceptanceDialog, onTermsNotAccepted;
        switch(variant) {
            case apiTermsName:
                declineLocation = getLocation(routePaths.landing, location);
                termsAcceptIntro = !isTermsUpdate? <DetailedAcceptTermsIntro /> : null;
                break;
            case downloadTermsName:
                declineLocation = getLocation(routePaths.openDataDownloads, location);
                break;
            default:
        }

        useEffect(() => {
            return function() {
                if (displayedActionBasedTerms[variant]) {
                    dispatch(clearTerms(variant));
                }
            }
        }, [dispatch, displayedActionBasedTerms]);

        // General terms does not allow acceptance on behalf of the organisation or use confirmation dialogs
        let orgAcceptanceEnabled, declineConfirmationEnabled;
        if (variant !== generalTermsName) {
            orgAcceptanceEnabled = true;
            declineConfirmationEnabled = true;
        }

        // Accept
        const onAcceptClick = useCallback(() => {
            const checkedVersion = isEaiUser && variant===apiTermsName ? latestEaiApiTermsVersion : allTermsLatestVersions[variant];
            const terms = {name: variant, version: checkedVersion};
            if (usingPersonalOrg) {
                terms.orgId = org.id;
            }
            dispatch(acceptTerms(terms));

        }, [dispatch, org, usingPersonalOrg, isEaiUser]);
        const acceptWorking = useSelector(state => state.user?.acceptTerms?.working);
        const errorOccurred = useSelector(state => state.user?.acceptTerms?.error != null);
        
        // Decline
        const [showDeclineWarning, setShowDeclineWarning] = useState(false);
        let onDeclineClick = () => {
            return logOut();
        };

        if (declineConfirmationEnabled) {
            onDeclineClick = () => setShowDeclineWarning(true);
            onTermsNotAccepted = () => {
                // Ensure decline warning is hidden
                setShowDeclineWarning(false);

                // Clear action scoped terms state
                if (actionScopedTermsMode) {
                    dispatch(clearTerms(variant));

                // Route user to a decline page
                } else {
                    history.replace(declineLocation);
                }
            }

            disallowTermsAcceptanceDialog = 
                <DisallowedTermsAcceptanceDialog variant={variant} handleClose={onTermsNotAccepted} />;
        }
        
        const showActionScopedViewOrganisationTermsOnlyDialog = (actionScopedTermsMode && displayedActionBasedTerms[variant]) && viewsOrganisationTermsOnly(user.role);

        if (!isLoggedIn || 
            (userTermsAcceptance === termsAcceptState.latest) || 
            (actionScopedTermsMode && !displayedActionBasedTerms[variant]) || 
            (showActionScopedViewOrganisationTermsOnlyDialog)) {
                return <>
                    <WrappedComponent />

                    {/* When in action scoped mode and terms should be shown but user does not have permission to accept, a dialog is displayed over the page */}
                    {(orgAcceptanceEnabled && showActionScopedViewOrganisationTermsOnlyDialog) &&
                        <>{disallowTermsAcceptanceDialog}</>
                    }
                </>;

        } else {
            return <>
                <TermsConditionsAccept onAcceptClick={onAcceptClick}
                    acceptWorking={acceptWorking}
                    onDeclineClick={onDeclineClick}
                    declineWorking={false}
                    declineHasRoute={(declineLocation !== undefined)}
                    error={errorOccurred}
                    isTermsUpdate={isTermsUpdate}
                    termsAndConditions={<LegalTerms variant={variant} isEaiApi={isEaiUser}/>}
                    messages={messages}
                    acceptingForOrganisation={orgAcceptanceEnabled? (!usingPersonalOrg && usesOrganisationTerms(user.role)) : false}
                    intro={termsAcceptIntro}
                />

                {(declineConfirmationEnabled && showDeclineWarning) && 
                    <DeclineTermsDialog variant={variant} onClose={onTermsNotAccepted} onCancel={() => setShowDeclineWarning(false)} />
                }

                {/* If the user does not have permission to accept the terms a dialog may be shown over the terms acceptance page */}
                {(orgAcceptanceEnabled && viewsOrganisationTermsOnly(user.role)) &&
                    <>{disallowTermsAcceptanceDialog}</>
                }
            </>;
        }
    }
}

withTermsBarrier.propTypes = {
    WrappedComponent: PropTypes.object.isRequired,
    variant: PropTypes.oneOf([allTermsNames]).isRequired,
    actionScopedTermsMode: PropTypes.bool
}

withTermsBarrier.defaultProps = {
    variant: generalTermsName
}
