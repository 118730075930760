import React, {Fragment, useEffect} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import withStyles from 'react-jss';
import {Typography} from '@mui/material';
import {Link, useHistory, useLocation} from 'react-router-dom';
import routes, {getLocation} from '../util/routes'
import MapLowRes from './VNImageFolder/wedding-cake.png';
import RouterLink from '../../client/components/Link';
import Button from '@mui/material/Button';
import FeatureCheck from '../../client/components/FeatureCheck';

const messages = defineMessages({
    title: {
        id: 'VernacularNamesLanding.title',
        defaultMessage: 'Vernacular Names',
        description: 'Text for VN title'
    },
    intro: {
        id: 'VernacularNamesLanding.intro',
        defaultMessage: 'Vernacular names are colloquial, alternative or local names given to places or objects. Examples of vernacular names include:',
        description: 'Text for VN intro'
    },
    weddingCakeTitle: {
        id: 'VernacularNamesLanding.weddingCakeTitle',
        defaultMessage: 'Wedding Cake',
        description: 'Title for Wedding Cake'
    },
    weddingCakeInfo: {
        id: 'VernacularNamesLanding.weddingCakeInfo',
        defaultMessage: 'A vernacular name for the Queen Victoria Memorial, London',
        description: 'Info text for Wedding Cake'
    },
    squintyBridgeTitle: {
        id: 'VernacularNamesLanding.squintyBridgeTitle',
        defaultMessage: 'Squinty Bridge',
        description: 'Title for Squinty Bridge'
    },
    squintyBridgeInfo: {
        id: 'VernacularNamesLanding.squintyBridgeInfo',
        defaultMessage: 'A vernacular name for the Clyde Arc Bridge, Glasgow',
        description: 'Info text for Squinty Bridge'
    },
    tigerBayTitle: {
        id: 'VernacularNamesLanding.tigerBayTitle',
        defaultMessage: 'Tiger Bay',
        description: 'Title for Tiger Bay'
    },
    tigerBayInfo: {
        id: 'VernacularNamesLanding.tigerBayInfo',
        defaultMessage: 'A vernacular name for Cardiff Bay, Cardiff',
        description: 'Info text for Tiger Bay'
    },
    esOperator: {
        id: 'VernacularNamesLanding.esOperator',
        defaultMessage:
            'If you\'re an Emergency Service operator, this tool will allow you to capture vernacular names and view other vernacular names that have already been submitted.',
        description: 'Text for Emergency Services paragraph'
    },
    sharingData: {
        id: 'VernacularNamesLanding.sharingData',
        defaultMessage: 'By sharing your data with OS, this will allow us to share with other Emergency Services via the Emergency Services Gazetteer, ' +
            'a comprehensive and maintained dataset for the location of names and places.',
        description: 'Text for data sharing paragraph'
    },
    tsAndCsText: {
        id: 'VernacularNamesLanding.tsAndCsText',
        defaultMessage: 'By clicking the below button to access the application, you accept the {link}',
        description: 'Text for Ts&Cs paragraph'
    },
    tsAndCsLink: {
        id: 'VernacularNamesLanding.tsAndCsLink',
        defaultMessage: 'Vernacular Names tool terms and conditions.',
        description: 'Text for Ts&Cs link'
    },
    openVN: {
        id: 'VernacularNamesLanding.openVN',
        defaultMessage: 'Open Application',
        description: 'Open VN app text'
    }
});

function styles(theme) {
    return {
        title: {
            paddingBottom: theme.spacing(4),
            fontSize: '2.5rem',
            fontFamily: "'Gill Sans Regular','Source Sans Pro','sans-serif'",
            [theme.breakpoints.down('lg')]: {
                paddingBottom: theme.spacing(2),
                fontSize: '1.875rem'
            }
        },
        splitScreen: {
            padding: `${theme.spacing(4)} 0px`,
            position: 'relative',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
            flexShrink: 0,
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                paddingBottom: 0,
                marginBottom: theme.spacing(2)
            },
        },
        leftSide: {
            width: '40%',
            maxWidth: 640,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            height: "100%",
            padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
            [theme.breakpoints.down('md')]: {
                width: '90%',
                maxWidth: 600,
                alignSelf: 'center',
                padding: '10px 0px'
            }
        },
        rightSide: {
            width: '40%',
            display: 'flex',
            justifyContent: 'center',
            padding: `${theme.spacing(2)} 0px`,
            height: '600px',
            [theme.breakpoints.down('md')]: {
                maxHeight: null,
                height: 'auto',
                width: '90%',
                alignSelf: 'center',
                padding: `${theme.spacing(2)} 0px`,
                flexShrink: 3
            }
        },
        image: {
            overflow: 'hidden',
            objectFit: 'contain',
            height: '100%',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                width: '90%',
                height: 'auto',
                objectFit: 'cover'
            }
        },
        button: {
            marginBottom: theme.spacing(5),
            marginTop: theme.spacing(1)
        },
        gutter: {
            marginBottom: theme.spacing(2)
        },
        vnTitle: {
            marginBottom: 0,
            fontWeight: 'bold'
        },
    };
}

export function VernacularNamesLanding(props) {
    const {classes} = props;
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (location.search) {
            history.push(
                getLocation(routes.vernacularNamesReporting, location)
            );
        }
    }, [location, history]);

    return (<FeatureCheck feature='vn'>
            <Fragment>
                <div className={classes.splitScreen}>
                    <div className={classes.leftSide}>
                        <Typography color='primary' variant='h1' className={classes.title}>
                            <FormattedMessage {...messages.title} />
                        </Typography>

                        <Typography variant='body1' component='h2' className={classes.gutter}>
                            <FormattedMessage {...messages.intro} />
                        </Typography>

                        <Typography variant='h3' paragraph={true} className={classes.vnTitle}>
                            <FormattedMessage {...messages.weddingCakeTitle} />
                        </Typography>
                        <Typography variant='body1' className={classes.gutter}>
                            <FormattedMessage {...messages.weddingCakeInfo} />
                        </Typography>

                        <Typography variant='h3' paragraph={true} className={classes.vnTitle}>
                            <FormattedMessage {...messages.squintyBridgeTitle} />
                        </Typography>
                        <Typography variant='body1' className={classes.gutter}>
                            <FormattedMessage {...messages.squintyBridgeInfo} />
                        </Typography>

                        <Typography variant='h3' paragraph={true} className={classes.vnTitle}>
                            <FormattedMessage {...messages.tigerBayTitle} />
                        </Typography>
                        <Typography variant='body1' className={classes.gutter}>
                            <FormattedMessage {...messages.tigerBayInfo} />
                        </Typography>

                        <Typography variant='body1' className={classes.gutter}>
                            <FormattedMessage {...messages.esOperator} />
                        </Typography>

                        <Typography variant='body1' className={classes.gutter}>
                            <FormattedMessage {...messages.sharingData} />
                        </Typography>

                        <Typography variant='body1' className={classes.gutter}>
                            <FormattedMessage {...messages.tsAndCsText} values={{
                                link: <RouterLink path={routes.vernacularNamesTerms}>
                                    <FormattedMessage {...messages.tsAndCsLink}/>
                                </RouterLink>
                            }}/>
                        </Typography>

                        <Link target="_self" to={routes.vernacularNamesReporting}>
                            <Button variant='contained' color='primary' className={classes.button}>
                                <FormattedMessage {...messages.openVN}/>&nbsp;
                            </Button>
                        </Link>

                    </div>
                    <div className={classes.rightSide}>
                        <img src={MapLowRes} alt='Map with Vernacular Name pins on it' className={classes.image}/>
                    </div>
                </div>
            </Fragment>
        </FeatureCheck>
    );
}

export default withStyles(styles)(VernacularNamesLanding);
