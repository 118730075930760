import React from 'react';
import Typography from '@mui/material/Typography';
import {createUseStyles} from 'react-jss';
import {drawingTools} from "../data/drawing-tools";
import Table, {OUTLINE} from '../../../documentation/Table';

const useStyles = createUseStyles({
    tableData: {
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro,sans-serif',
        fontWeight: 600,
        lineHeight: 1.43
    },
    product: {
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro,sans-serif',
        fontWeight: 400,
        lineHeight: 1.5
    }
});

export default function DrawingToolsTable() {
    const classes = useStyles();
    return <Table variant={OUTLINE}>
        <thead>
            <tr>
                <Typography variant='subtitle2' component='th'>Tool</Typography>
                <Typography variant='subtitle2' component='th'>Definition</Typography>
            </tr>
        </thead>
        <tbody>
            {drawingTools.map(item => {
                return (<tr key={item.name}>
                    <td className={classes.tableData}>{item.name}</td>
                    <td className={classes.product}>{item.definition}</td>
                </tr>)})
            }
        </tbody>
    </Table>
}