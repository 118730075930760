import React, { useEffect } from 'react';
import {createUseStyles} from 'react-jss';
import {osColour, DropDownMenu} from 'omse-components';
import {useSelector, useDispatch} from 'react-redux';
import {selectOrganisation} from '../../modules/organisation/action';
import {isOpenDataPersonalType, isDefaultType, getDefaultOrganisation} from '../../util/organisation';
import routePaths, {matchRoute, findRoute, getLocation, accountRoutes, orgSelectRoutes} from '../../util/routes';
import {useLocation} from 'react-router-dom';
import {ExpansionList} from 'omse-components';
import {useHistory} from 'react-router';
import {defineMessages, useIntl} from "react-intl";
import styled from '@emotion/styled';

const messages = defineMessages({
    personal: {
        id: 'OrganisationSelect.personal',
        defaultMessage: 'Personal',
        description: 'Label to use when the item is OpenData'
    },
    organisation: {
        id: 'OrganisationSelect.organisation',
        defaultMessage: 'Organisation',
        description: 'Form control label'
    },
    organisationAriaLabel: {
        id: 'OrganisationSelect.organisationAriaLabel',
        defaultMessage: '{org} organisation',
        description: 'Organisation aria label'
    }
});


const useListStyles = createUseStyles((theme) => {
    return {
        list: {
            '& li': {
                marginLeft: 6,
                marginRight: 6,
                padding: theme.spacing(1) + ' ' + theme.spacing(2),
                fontWeight: 'normal',
                '&:focus': {
                    background: osColour.primary.lightestBerry
                }
            },
            '& li:hover': {
                cursor: 'pointer'
            }
        }
    }
});

const useExpansionStyles = createUseStyles((theme) => {
    return {
        expandSummary: {
            margin: 6,
            padding: 0,
            boxShadow: '0 0 0 6px ' + osColour.primary.berry,
            background: osColour.primary.berry,
            color: osColour.neutral.white,
            paddingLeft: theme.spacing(2),
            '&:focus': {
                outline: 'auto',
                background: osColour.primary.berry
            }
        },
        root: {
            maxWidth: 'unset',
            margin: 0
        },
        list: {
            margin: 0
        },
        expandSummaryIcon: {
           padding: 6,
           color: osColour.neutral.white
        }
    }
});

const StyledDropDownMenu = styled(DropDownMenu)(
    ({theme}) => `
    & button, button:hover {
      background-color: ${theme.palette.primary.main};
      color: ${osColour.neutral.white};
      border-radius: 0;
      padding: 6px 35px 6px 6px;
      margin: 6px;
      box-shadow: 0 0 0 6px ${theme.palette.primary.main};
      font-size: 1.125rem;
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: calc(100% - 12px);
      display: block;
      text-align: start;
    }
    & svg {
      position: absolute;
      right: 6px;
    }
    & .MuiPopper-root {
        min-width: 259px;
    }
`);

export default function OrganisationSelect(props) {
    const classesList = useListStyles();
    const classesExpansionList = useExpansionStyles();
    const user = useSelector(state => state.user.current.result);
    const orgId = useSelector(state => state.organisation.current.id);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const intl = useIntl();
    const {variant, onSelect} = props;

    function onChange(item) {
        const id = item.value;
        const org = user.orgs.find(org => org.value === id);
        if (org) {
            dispatch(selectOrganisation(org));

            // redirect if viewing account pages
            if (accountRoutes.find(r => matchRoute(location.pathname).path === r.path)) {
                const newLocation = getLocation(routePaths.account, location);
                history.push(newLocation);
            }

            // redirect if viewing a project
            if (findRoute('project').path === matchRoute(location.pathname).path) {
                const newLocation = getLocation(routePaths.projects, location);
                history.push(newLocation);
            }

            if (onSelect) {
                onSelect();
            }
        }
    }

    // When we navigate to a section that does not allow org selection then revert to the default org for the user
    useEffect(function() {
        if(matchRoute(location.pathname)) {
            if(!orgSelectRoutes.find(r => matchRoute(location.pathname).path === r.path)) {
                const defaultOrg = getDefaultOrganisation(user);
                if(orgId && orgId !== defaultOrg.value) {
                    dispatch(selectOrganisation(defaultOrg));
                }
            }
        }
    }, [location, user, dispatch, orgId]);
   
    if(matchRoute(location.pathname)) {
        if (!orgSelectRoutes.find(r => matchRoute(location.pathname).path === r.path)) {
            return false
        }
    }
    
    if (!user || !user.orgs || user.orgs.length <= 1) {
        return false;
    }

    let selected;
    if (orgId) {
        selected = user.orgs.find(org => org.value === orgId);
    }

    if (!selected) {
        selected = user.orgs.find(org => isDefaultType(org));
    }

    // rename if OpenData personal type
    const orgItems = user.orgs.map(org => {
        if (isOpenDataPersonalType(org)) {
            org.name = messages.personal.defaultMessage;
        }
        return org;
    });

    let labelValue;
    if (selected.name === 'Personal') {
        labelValue = intl.formatMessage(messages.personal);
    } else {
        labelValue = `${intl.formatMessage(messages.organisationAriaLabel, { org: selected.name })}`;
    }

    if (variant === 'list') {
        const content = <ul className={classesList.list}>
            {orgItems.map(item => <li tabIndex='0' key={item.value} onClick={() => onChange(item)}>{item.name}</li>)}
        </ul>

        const options = [{
            label: (selected && selected.name)? selected.name : null,
            content: content
        }];
        
        return <ExpansionList options={options} showControls={false} classes={classesExpansionList} ariaLabel={labelValue}/>
    }
    return (
        <StyledDropDownMenu
            value={(selected && selected.value) || ''}
            onChange={onChange}
            items={orgItems}
            dataLabelKey='name'
            buttonId='selectOrganisation'
            variant='block'
            strategy='fixed'
            overflowPadding={16}
        />
    );
}