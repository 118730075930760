import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import routes from "../util/routes";
import Typography from "@mui/material/Typography";
import Link from './Link';
import EmptyStateMessages from './EmptyStateMessages';
import Button from "@mui/material/Button";
import {linkButton, osColour} from 'omse-components';
import {useDispatch, useSelector} from "react-redux";
import {createUseStyles} from 'react-jss';
import {openNewProjectDialog, closeNewProjectDialog} from '../modules/project/actions';
import {withTermsActionCheck} from '../util/terms';
import NameProjectDialog from "./createProjectButton/NameProjectDialog";
import {apiTermsName} from '../../shared/constants';

const messages = defineMessages({
    waysToGetStarted: {
        id: 'WaysToStart.waysToGetStarted',
        defaultMessage: 'There are 2 ways to get started:',
        description: 'Header for ways to get started list'
    },
    wayOne: {
        id: 'WaysToStart.wayOne',
        defaultMessage: 'You can {link} and add one to a brand new project.',
        description: 'Description of way number one',
    },
    browseAPIsLink: {
        id: 'WaysToStart.browseAPIsLink',
        defaultMessage: 'browse all the APIs',
        description: 'Link to browse APIs'
    },
    wayTwo: {
        id: 'WaysToStart.wayTwo',
        defaultMessage: 'You can {link} now, and add APIs to it.',
        description: 'Description of way number two and a helper paragraph'
    },
    createNewLink : {
        id: 'WaysToStart.createNewLink',
        defaultMessage: 'create a new project',
        description: 'Link to create a new project'
    },
    whatNextHeader: {
        id: 'WaysToStart.whatNextHeader',
        defaultMessage: 'What Next?',
        description: 'Header for paragraph explaining what to do next',
    },
    createFirstProjectSubHead: {
        id: 'WaysToStart.createFirstProjectSubHead',
        defaultMessage: 'Create your first project and add some APIs to it.',
        description: 'Sub heading for creating your first project'
    }
});

const useStyles = createUseStyles(theme => ({
    whatNext: {
        color: osColour.neutral.charcoal,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(10),
        '& h2': {
            marginBottom: theme.spacing(2)
        },
        '& h3': {
            marginBottom: theme.spacing(1)
        },
        '& a': {
            fontSize: '1.125rem'
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(4)
        }
    },
    linkButton: {
        ...linkButton,
        fontSize: '1.125rem',
        '&:hover': {
            background: 'transparent',
            textDecoration: 'underline',
            color: osColour.link.hover
        }
    }
}));

const waysToGetStartedList = [
    {message: messages.wayOne, linkPath: routes.apis, linkMessage: messages.browseAPIsLink}
];

const listOfWays = waysToGetStartedList.map((way, index) =>
    <li key={index}>
        <Typography variant='h5' component='p'>
            <FormattedMessage {...way.message} values={{
                link: <Link path={way.linkPath}>
                    <FormattedMessage {...way.linkMessage}/>
                </Link>
            }}/>
        </Typography>
    </li>);

export default function WhatNext() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const componentId = 'WhatNext';

    const user = useSelector(state => state.user.current.result);
    const org = useSelector(state => state.organisation.current);
    
    const projectDialogId = useSelector(state => state.project.newProject.dialogId);
    const projectDialogOpen = useSelector(state => state.project.newProject.open) && (projectDialogId === componentId);
    const onCreateProjectLink = () => dispatch(withTermsActionCheck(apiTermsName, openNewProjectDialog(componentId), user, org));
    const handleNameProjectClose = () => dispatch(closeNewProjectDialog());

    return <div className={classes.whatNext}>
        <Typography variant='h2'>
            <FormattedMessage {...messages.whatNextHeader} />
        </Typography>
        <Typography variant='h4' component='h3'>
            <FormattedMessage {...messages.createFirstProjectSubHead} />
        </Typography>
        <Typography variant='h5' component='p'>
            <FormattedMessage {...messages.waysToGetStarted} />
        </Typography>
        <ul>
            {listOfWays}
            <li>
                <Typography variant='h5' component='p'>
                    <FormattedMessage {...messages.wayTwo} values={{
                        link: <Button component='span' className={classes.linkButton} onClick={onCreateProjectLink}>
                            <FormattedMessage {...messages.createNewLink}/>
                        </Button>
                    }}/>
                    {projectDialogOpen && 
                        <NameProjectDialog handleClose={handleNameProjectClose} />
                    }
                </Typography>
            </li>
        </ul>
        <EmptyStateMessages/>
    </div>;
}
