import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import {osColour,ExternalLink} from 'omse-components';
import arcmap1 from './arcmap/Map-1.png'
import arcmap2 from './arcmap/Map-2.png'
import arcmap3 from './arcmap/Map-3.png'
import arcmap4 from './arcmap/Map-4.png'
import arcmap5 from './arcmap/Map-5.png'
import arcmap6 from './arcmap/Map-6.png'
import arcmap7 from './arcmap/Map-7.png'
import arcmap8 from './arcmap/Map-8.png'
import arcmap9 from './arcmap/Map-9.png'
import WFSCompletionSummary from './WFSCompletionSummary';
import PropTypes from 'prop-types';

const styles = {
    guide: {
        '& img': {
            border: `solid ${osColour.neutral.mist} 1px`
        }
    }
};

export function ArcMap(props) {
    const {classes} = props;
    return <Fragment>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    The examples shown use version 10.5 of ESRI ArcMap. It is expected that later versions will behave in a similar fashion as described below, along with some earlier versions. If in doubt, please contact your ESRI representative for information on compatibility.
                </p>
                <p>
                    Adding a WFS service to ArcMap requires the use of the Data Interoperability Extension for ArcGIS.
                </p>
            </Typography>
            <Typography variant='h2'>Integrating OS Features API in ArcMap</Typography>
            <Typography variant='body1' paragraph={true} component='div' className={classes.guide}>
                <ol>
                    <li>
                        <p>
                            In order to access the OS Features API with ArcMap, you must first install the <ExternalLink type="generic" href="https://desktop.arcgis.com/en/arcmap/latest/extensions/data-interoperability/installing-the-data-interoperability-extension.htm" message="Data Interoperability Tools extension" />.
                        </p>
                    </li>
                    <li>
                        <p>Start ArcMap. Open the Arc Toolbox window and select the Data Interoperability Tools extension.</p>
                        <img src={arcmap1} alt='ArcToolbox window showing Interoperability extension' />
                    </li>
                    <li>
                        <p>Select 'Quick Import'.</p>
                    </li>
                    <li>
                        <p>Select ‘Input Dataset' and then under the format in the next window, select ‘More Formats’.</p>
                        <img src={arcmap2} alt="'Specify Data Source' window with 'More Formats..' option selected'" />
                    </li>
                    <li>
                        <p>The web feature service option should appear. More formats may be available if you have a licence for the full extension. The example is using the free version of the Interoperability extension. Select the WFS format and click 'OK'.</p>
                        <img src={arcmap3} alt="FME Reader Gallery with WFS item selected" />
                    </li>
                    <li>
                        <p>Enter the URL from the OS Data Hub which includes the OS Features service API key in the dataset box. Then click the 'Parameters...' button.</p>
                        <img src={arcmap4} alt="'Specify Data Source' dialog" />
                    </li>
                    <li>
                        <p>In the next window you need to specify the feature types. Click the button with the three dots next to the feature types box.</p>
                        <img src={arcmap5} alt="'Feature Types' option within the 'Constraints' group" />
                    </li>
                    <li>
                        <p>In the window that opens, select the data layers you want to add and click ‘OK’.</p>
                        <img src={arcmap6} alt="'Select Feature Types' dialog" />
                    </li>
                    <li>
                        <p>Click 'OK' on two remaining open dialogue windows.</p>
                    </li>
                    <li>
                        <p>Choose where to save the exported Geodatabase.</p>
                        <img src={arcmap7} alt="'Specify Geodatabase directory' dialog" />
                    </li>
                    <li>
                        <p>You should now see the exported Geodatabase with the selected data layers in the catalog window.</p>
                        <img src={arcmap8} alt="Catalog window displaying a connection with data layers, within Interoperability Connections" />
                    </li>
                    <li>
                        <p>You can now add the data to the map by dragging a data layer onto the map view, or by selecting ‘Add data’, then navigating to the Geodatabase and then selecting the desired layers.</p>
                    </li>
                    <li>
                        <p>WFS data can be styled in the same way as file based vector data.</p>
                        <img src={arcmap9} alt="Map view displaying data" />
                    </li>
                </ol>
            </Typography>
            <WFSCompletionSummary software="ArcMap" />
    </Fragment>
}

ArcMap.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ArcMap);