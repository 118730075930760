import { FormattedMessage, defineMessages } from "react-intl";
import { ExternalLink, InternalLink, osColour } from "omse-components";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import ContentContainer from "./ContentContainer";
import { ReactComponent as ContactSupportIcon } from "./img/contact-support.svg";
import { ReactComponent as SupportAgentIcon } from "./img/support-agent.svg";
import { ReactComponent as TilesIcon } from "../../components/icons/online-support.svg";

const gettingStarted = defineMessages({
	title: {
		id: "gettingStarted.title",
		defaultMessage: "Getting started",
		description: "Title"
	},
	subtitle: {
		id: "gettingStarted.subtitle",
		defaultMessage: "We'll help you on every step of your journey.",
		description: "Subtitle"
	},
	docsTitle: {
		id: "gettingStarted.docsTitle",
		defaultMessage: "Documentation",
		description: "Docs Card Title"
	},
	docsSubtitle: {
		id: "gettingStarted.docsSubtitle",
		defaultMessage: "API product overviews, getting started guides and technical specifications.",
		description: "Docs Card Subtitle"
	},
	docsLink: {
		id: "gettingStarted.docsLink",
		defaultMessage: "View documentation",
		description: "Docs Card Link"
	},
	supportTitle: {
		id: "gettingStarted.supportTitle",
		defaultMessage: "Data Hub support",
		description: "Support Card Title"
	},
	supportSubtitle: {
		id: "gettingStarted.supportSubtitle",
		defaultMessage: "Help with setting up an account, using our products and contacting us.",
		description: "Support Card Subtitle"
	},
	supportLink: {
		id: "gettingStarted.supportLink",
		defaultMessage: "Learn more",
		description: "Support Card Link"
	},
	productSupportTitle: {
		id: "gettingStarted.productSupportTitle",
		defaultMessage: "OS tools and support",
		description: "Product Support Card Title"
	},
	productSupportSubtitle: {
		id: "gettingStarted.productSupportSubtitle",
		defaultMessage: "Product support, licensing guides, information on our data and help with your accounts.",
		description: "Product Support Subtitle"
	},
	productSupportLink: {
		id: "gettingStarted.productSupportLink",
		defaultMessage: "Find support",
		description: "Product Support Card Link"
	}
});

const Container = styled("div")`
	display: grid;
	gap: 2em;
	grid-template-columns: 1fr 1fr 1fr;
	@media (max-width: 1200px) {
		grid-template-columns: 1fr;
	}
`;

const SectionHeading = styled(Typography)`
	margin-bottom: 0.35em !important;
	font-family: OSGill, sans serif !important;
`;

const StyledCard = styled("article")`
	border: 1px solid #DDD;
	background: #F5F5F5;
	padding: 24px;
	border-bottom: 3px solid ${osColour.primary.berry};
`;

const Heading = styled(Typography)`
	font-family: OSGill;
	font-size: 24px;
	color: #333333;
`;

const Description = styled(Typography)`
	color: #333333;
	font-size: 18px;
`;

function Card({ icon, heading, description, link }) {
	return (
		<StyledCard>
			{icon}
			<Heading variant={"h3"} sx={{ marginBottom: "16px", marginTop: "16px" }}>
				<FormattedMessage {...heading} />
			</Heading>
			<Description variant={"body1"} sx={{ marginBottom: "24px" }}>
				<FormattedMessage {...description} />
			</Description>
			{link}
		</StyledCard>
	);
}

export default function GettingStarted() {
	return (
		<ContentContainer>
			<SectionHeading gutterBottom variant="h1" component="h2">
				<FormattedMessage {...gettingStarted.title} />
			</SectionHeading>
			<Typography
				variant="body1"
				sx={{
					color: osColour.neutral.charcoal,
					fontFamily: "OSGill, sans-serif",
					fontSize: "20px",
					marginBottom: "48px"
				}}
			>
				<FormattedMessage {...gettingStarted.subtitle} />
			</Typography>
			<Container>
				<Card
					icon={<TilesIcon width={48} height={48} />}
					heading={gettingStarted.docsTitle}
					description={gettingStarted.docsSubtitle}
					link={<InternalLink path={"/docs"} message={gettingStarted.docsLink} />}
				/>
				<Card
					icon={<SupportAgentIcon width={48} height={48} />}
					heading={gettingStarted.supportTitle}
					description={gettingStarted.supportSubtitle}
					link={<InternalLink path={"/support"} message={gettingStarted.supportLink} />}
				/>
				<Card
					icon={<ContactSupportIcon width={48} height={48} />}
					heading={gettingStarted.productSupportTitle}
					description={gettingStarted.productSupportSubtitle}
					link={
						<ExternalLink
							href={"https://www.ordnancesurvey.co.uk/customers/support"}
							message={gettingStarted.productSupportLink}
						/>
					}
				/>
			</Container>
		</ContentContainer>
	);
}
