import React from 'react';
import Typography from '@mui/material/Typography';
import Header from "./Header";
import Content from './Content';
import DocLinks from "./DocLinks";
import CodeSnippet from './CodeSnippet';
import Document from './Document';
import { ExternalLink, isWidthDown, withWidth } from 'omse-components';

export function NamesIntro(props) {
    const {width} = props;
    const mobile = isWidthDown('sm', width);
    return <Document>
        <Header back={true}>OS Names API: Getting started guide</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains how to generate an API key to access the OS Names API, and then how to use
                    that API key with a Web Development IDE to create a web-based application.
                </p>
                <p>
                    This guide assumes basic knowledge of web development and will require access to an IDE (Integrated
                    Development Environment).
                </p>
            </Typography>
            <Typography variant='h1' component={'h2'}>Instructions to Generate a Project API Key and URL</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>The following instructions describe how to obtain the API service URL and to generate an API key:</p>
                <ol>
                    <li>Click this link to open the <ExternalLink type="generic" href="/" message="OS Data Hub" /> in a new tab.
                    </li>
                    {mobile &&
                        <li>Click the navigation menu button.</li>
                    }
                    <li>Click "API Dashboard" in the {!mobile && 'navigation'} menu.</li>
                    <li>Click "APIs" in the {!mobile && 'secondary navigation'} menu.</li>
                    <li>Click "Add to API project" next to the OS Names API.</li>
                    <li>If you already have a project you may choose to add the OS Names API into that
                        project, or alternatively Select "Add to NEW PROJECT".
                        <p>The next screen will contain the Project API Key and the API Endpoint address (API URL).</p>
                        <p>You can return to this screen by clicking "My projects" at any point in the future if you
                            need to copy your API key or the API address, or if you need to regenerate your API Key.</p>
                    </li>
                    <li>Keep this page open as you'll need the key when you apply the OS Names API service
                        in your web-based application.
                    </li>
                </ol>
                <p>
                    For demonstrative purposes, we will apply the service through an IDE to create a web-based
                    application.
                </p>
            </Typography>

            <Typography variant='h1' component={'h2'}>Sample implementation using Node.js</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>These examples rely on a working installation of <ExternalLink type="generic" href='https://nodejs.org' message="Node.js" /> and the popular <ExternalLink type="generic" href='https://github.com/axios/axios' message="axios" /> module.</p>
                <ol>
                    <li>
                        Run a find query.
                        <CodeSnippet>{`const axios = require('axios');

const apiKey = 'INSERT_YOUR_API_KEY_HERE';

async function find() {
    axios.get('https://api.os.uk/search/names/v1/find?query=Southampton&key=' + apiKey)
    .then(function (response) {
        /* For explanation and debugging purposes we display the full response from the API in the console */
        console.log(JSON.stringify(response.data, null, 2));
    });
}
find()`}
                        </CodeSnippet>
                    </li>
                    <li>
                        Run a nearest query.
                        <CodeSnippet>{`const axios = require('axios');

const apiKey = 'INSERT_YOUR_API_KEY_HERE';

async function nearest() {
    axios.get('https://api.os.uk/search/names/v1/nearest?point=440200,449300&key=' + apiKey)
    .then(function (response) {
        /* For explanation and debugging purposes we display the full response from the API in the console */
        console.log(JSON.stringify(response.data, null, 2));
    });
}
nearest()`}
                        </CodeSnippet>
                    </li>
                </ol>
            </Typography>
            <DocLinks product='names' hasTechnicalSpec={true} hasDemo={true}/>
        </Content>
    </Document>
}

export default withWidth()(NamesIntro);