import { contentPadding, osColour } from 'omse-components';
import { css } from '@emotion/css';

const commonStylesJSON = {
    superScriptColor: {
        fontSize: '0.875rem',
        fontWeight: 600,
        color: `${osColour.status.error} !important`
    }
};

export default function commonStyles() {
    return commonStylesJSON;
};

export const superScriptClass = css(commonStylesJSON.superScriptColor);

export const tableStyles = theme => ({
    table: {
        border: 'none',
        borderCollapse: 'collapse',
        '& thead th': {
            fontWeight: 600,
            color: osColour.primary.berry,
            backgroundColor: 'white',
            borderBottom: '2px solid ' + osColour.primary.berry,
            padding: theme.spacing(1),
            textAlign: 'left',
            verticalAlign: 'top'
        },
        '& tbody': {
            verticalAlign: 'baseline'
        },
        '& tr': {
            borderBottom: '1px solid ' + osColour.neutral.mist,
        },
        '& td, tbody th': {
            padding: theme.spacing(1.5, 1.5)
        },
        '& tbody th': {
            textAlign: 'left'
        }
    }
});

export const centeredContent = `
    margin: 0 auto;
    max-width: ${contentPadding.maxWidth}px;
    padding-top: ${contentPadding.top}px;
    padding-left: ${contentPadding.left}px;
    padding-right: ${contentPadding.right}px;
    padding-bottom: ${contentPadding.bottom}px;
`;
