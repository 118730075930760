import {createLoadingReducer, createLoadingMiddleware, createActionMiddleware, createActionReducer} from 'omse-components';
import {SET_EMAIL_ACTION, LOADED_EMAIL_ACTION, LOAD_CONTACT_PREFERENCES, LOADED_CONTACT_PREFERENCES, SET_CONTACT_PREFERENCES} from "./account/actions";
import {combineReducers} from 'redux';
import {cloneDeep} from 'lodash';

export const accountReducer = combineReducers({
    email: createLoadingReducer(SET_EMAIL_ACTION, LOADED_EMAIL_ACTION, false), 
    contactPreferences: createLoadingReducer(LOAD_CONTACT_PREFERENCES, LOADED_CONTACT_PREFERENCES, true),
    updateContactPreferences: createActionReducer(SET_CONTACT_PREFERENCES)
});

function prepareCall(action) {
    return {
        url: '/api/auth/changeEmailPreprocess',
        method: 'POST',
        body: {
            new_email: action.email
        }
    };
}

function prepareLoadContactPreferences() {
    return {
        url: '/api/contactPrefs',
        method: 'GET'
    };
}

function setupContactPrefsCall(action) {
    return {
        url: '/api/contactPrefs',
        method: 'POST',
        body: {
            preferences: action.preferences
        }
    };
}

function contactPrefsSuccess(store, action, result) {
    const state = store.getState();
    const {contactPreferences} = state.account;
    const newContactItems = cloneDeep(contactPreferences.result.contact);
    const newMarketingItems = cloneDeep(contactPreferences.result.marketing);
    
    result.forEach(update => {
        const match = item => item.name === update.name;
        const contactItem = newContactItems.find(match);
        if (contactItem) {
            contactItem.value = update.value;
        } else {
            const marketingItem = newMarketingItems.find(match);
            if (marketingItem) {
                marketingItem.value = update.value;
            }
        }
    });

    const newResult = {contact: newContactItems, marketing: newMarketingItems};
    store.dispatch({type: LOADED_CONTACT_PREFERENCES, result: newResult});
}

export const accountMiddleware = [
    createLoadingMiddleware(SET_EMAIL_ACTION, LOADED_EMAIL_ACTION, prepareCall),
    createLoadingMiddleware(LOAD_CONTACT_PREFERENCES, LOADED_CONTACT_PREFERENCES, prepareLoadContactPreferences),
    createActionMiddleware(SET_CONTACT_PREFERENCES, setupContactPrefsCall, contactPrefsSuccess)
];