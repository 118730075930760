import React from 'react';
import {CommonDialog, CommonDialogActions} from 'omse-components';
import PropTypes from 'prop-types'
import {defineMessages, FormattedMessage} from 'react-intl';
import {Typography} from '@mui/material';

const messages = defineMessages({
    title: {
        id: 'ProductDependencyDialog.title',
        defaultMessage: '{productName} dependencies',
        description: 'Label for the title on the dialog'
    },
    agree: {
        id: 'ProductDependencyDialog.agree',
        defaultMessage: 'Add product dependencies',
        description: 'Label for the confirmation button on the dialog'
    },
    intro: {
        id: 'ProductDependencyDialog.intro',
        defaultMessage: '{productName} has dependencies on the following products: {dependencies}.',
        description: 'Text for the product dependency dialog'
    }
});

export default function ProductDependencyDialog(props) {
    const {onConfirm, onClose, product, productDependencies} = props;

    const actions = <CommonDialogActions onConfirm={onConfirm}
                                         onClose={onClose}
                                         confirmLabel={messages.agree}
    />;

    return <CommonDialog actions={actions}
                         onClose={onClose}
                         title={messages.title}
                         titleValues={{
                             productName: product.label
                         }}
    >
        <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...messages.intro} values={{
                productName: product.label,
                dependencies: productDependencies.map(p => p.label).join(', ')
            }}/>
        </Typography>
    </CommonDialog>;
}

ProductDependencyDialog.propTypes = {
    product: PropTypes.object.isRequired,
    productDependencies: PropTypes.arrayOf(PropTypes.object).isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};
