import React, {Fragment} from 'react';

const activities = [
    {id: 'permission:invite', name: 'Invite team members', admin: true, financeUser: false, user: false, contractor: false, contractorRead: false},
    {id: 'permission:changeRole', name: 'Change user role', admin: true, financeUser: false, user: false, contractor: false, contractorRead: false},
    {id: 'permission:manageTeam', name: 'Manage team members', admin: true, financeUser: false, user: false, contractor: false, contractorRead: false},
    {id: 'permission:revokeUser', name: 'Revoke user access', admin: true, financeUser: false, user: false, contractor: false, contractorRead: false},
    {id: 'permission:passwordChange', name: 'Change password', admin: true, financeUser: true, user: true, contractor: true, contractorRead: true},
    {id: 'permission:changeProfile', name: 'Change profile information', admin: true, financeUser: true, user: true, contractor: true, contractorRead: true},
    {id: 'permission:changeCompanyInfo', name: 'Change company information', admin: true, financeUser: false, user: false, contractor: false, contractorRead: false},
    {id: 'permission:marketingPref', name: 'Change marketing preferences', admin: true, financeUser: true, user: true, contractor: true, contractorRead: true},
    {id: 'permission:manageProject', name: 'Create/manage a project (including add/remove API)', admin: true, financeUser: true, user: true, contractor: true, contractorRead: false},
    {id: 'permission:regenKey', name: 'Regenerate API key', admin: true, financeUser: true, user: true, contractor: true, contractorRead: false},
    {id: 'permission:apiUsage', name: 'View API usage', admin: true, financeUser: true, user: true, contractor: true, contractorRead: true},
    {id: 'permission:premiumUsage', name: 'View premium usage', admin: true, financeUser: true, user: true, contractor: true, contractorRead: true},
    {id: 'permission:downloadOpenData', name: 'Download OS OpenData', admin: true, financeUser: true, user: true, contractor: true, contractorRead: true},
    {id: 'permission:downloadPremiumData', name: 'Download OS Premium data', publicSectorOrPartnerOnly: true, admin: true, financeUser: true, user: true, contractor: true, contractorRead: true},
    {id: 'permission:createDataPackage', name: 'Create a data package', publicSectorOrPartnerOnly: true, admin: true, financeUser: false, user: false, contractor: true, contractorRead: false},
    {id: 'permission:editDataPackage', name: 'Edit data package (expand/resupply)', publicSectorOrPartnerOnly: true, admin: true, financeUser: false, user: false, contractor: true, contractorRead: false},
    {id: 'permission:deleteDataPackage', name: 'Delete a data package', publicSectorOrPartnerOnly: true, admin: true, financeUser: false, user: false, contractor: true, contractorRead: false},
    {id: 'permission:stopDataPackageUpdates', name: 'Stop data package updates', publicSectorOrPartnerOnly: true, admin: true, financeUser: false, user: false, contractor: true, contractorRead: false},
    {id: 'permission:signPartnerUsageContract', name: 'Sign new Partner Usage Contract', partnerOnly: true, admin: true, financeUser: false, user: false, contractor: true, contractorRead: false},
    {id: 'permission:projectMode', name: 'Change project mode', admin: true, user: true, financeUser: true, premiumOnly: true, contractor: false, contractorRead: false},
    {id: 'permission:cardDetails', name: <Fragment><span>Add/</span>change/remove payment card details</Fragment>, admin: true, financeUser: true, user: false, premiumOnly: true, contractor: false, contractorRead: false},
    {id: 'permission:retryPayment', name: 'Retry a declined payment', admin: true, financeUser: true, user: false, premiumOnly: true, contractor: false, contractorRead: false},
    {id: 'permission:paymentHistory', name: 'View payment history', admin: true, financeUser: true, user: false, premiumOnly: true, contractor: false, contractorRead: false},

    {id: 'notification:welcome', name: 'Welcome to OS Data Hub', admin: true, financeUser: true, user: true, contractor: true, contractorRead: true},
    {id: 'notification:invite', name: 'Invitation accepted/declined', admin: true, financeUser: false, user: false,  contractor: false, contractorRead: false},
    {id: 'notification:inviteExpired', name: 'Invitation expired', admin: true, financeUser: false, user: false, contractor: false, contractorRead: false},
    {id: 'notification:changeRole', name: 'Change of role by Admin', admin: false, financeUser: true, user: true, contractor: true, contractorRead: true},
    {id: 'notification:payment', name: 'Successful/Unsuccessful payment', admin: true, financeUser: true, user: false, premiumOnly: true,  contractor: false, contractorRead: false},
    {id: 'notification:paymentDelay', name: 'Payment delay', admin: true, financeUser: true, user: false, premiumOnly: true, contractor: false, contractorRead: false},
    {id: 'notification:cardDetails',name: 'Change credit/debit card details', admin: true, financeUser: true, user: false, premiumOnly: true,  contractor: false, contractorRead: false},
    {id: 'notification:cardExpiring', name: 'Card expiring soon', admin: true, financeUser: true, user: false, premiumOnly: true,  contractor: false, contractorRead: false},
    {id: 'notification:apiThresholdUsage', name: '% use of free £1,000 premium data threshold used', admin: true, financeUser: true, user: true, premiumOnly: true,  contractor: false, contractorRead: false},
    {id: 'notification:apiCreditUsed', name: '% of your £2,400 (including VAT based at 20%) credit limit used', admin: true, financeUser: true, user: false, premiumOnly: true,  contractor: false, contractorRead: false},
    {id: 'notification:newDataPackage', name: 'New data package confirmation', publicSectorOrPartnerOnly: true, admin: true, financeUser: false, user: false, contractor: true, contractorRead: false},
    {id: 'notification:dataReadyDownload', name: 'Data ready to download', publicSectorOrPartnerOnly: true, admin: true, financeUser: false, user: false, contractor: true, contractorRead: false},
    {id: 'notification:edittedDataPackage', name: 'Edit data package (expand/resupply)', publicSectorOrPartnerOnly: true, admin: true, financeUser: false, user: false, contractor: true, contractorRead: false},
    {id: 'notification:noUpdateAvailable', name: 'No update available', publicSectorOrPartnerOnly: true, admin: true, financeUser: false, user: false, contractor: true, contractorRead: false},
    {id: 'notification:deleteDataPackage', name: 'Delete a data package', publicSectorOrPartnerOnly: true, admin: true, financeUser: false, user: false, contractor: true, contractorRead: false},
    {id: 'notification:stopDataPackageUpdates', name: 'Stop data package updates', publicSectorOrPartnerOnly: true, admin: true, financeUser: false, user: false, contractor: true, contractorRead: false},
    {id: 'notification:signPartnerUsageContract', name: 'Sign new Partner Usage Contract', partnerOnly: true, admin: true, financeUser: false, user: false, contractor: true, contractorRead: false},
    {id:"notification:purchaseNewPlan", name:"Purchase new plan", eaiOnly: true, admin: true, financeUser: false, user: false, contractor: false, contractorRead: false},
    {id:"notification:percent80Used", name:"80% API Plan money used", eaiOnly: true, admin: true, financeUser: false, user: false, contractor: false, contractorRead: false},
    {id:"notification:percent100Used", name:"100% API Plan money used", eaiOnly: true, admin: true, financeUser: false, user: false, contractor: false, contractorRead: false},
    {id:"notification:planNearExpiration", name:"API Plan about to expire", eaiOnly: true, admin: true, financeUser: false, user: false, contractor: false, contractorRead: false},
    {id:"notification:planExpiration", name:"API Plan has expired", eaiOnly: true, admin: true, financeUser: false, user: false, contractor: false, contractorRead: false}

];
export default activities;
