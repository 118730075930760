import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import style from './styles/legal-v2';
import {createUseStyles} from 'react-jss';
import DOMPurify from 'dompurify';
import dataHubTerms from './html/os-data-hub-terms.html';
import downloadTerms from './html/download-service-terms.html';
import apiTerms from './html/api-terms.html';
import eaiApiTerms from './html/eai-api-terms.html';
import {generalTermsName, apiTermsName, downloadTermsName, allTermsNames} from '../../../shared/constants';

const useStyles = createUseStyles(style);

export default function LegalTerms(props) {
    const {variant, isEaiApi} = props;
    let terms;
    switch(variant) {
        case generalTermsName:
            terms = dataHubTerms;
            break;
        case downloadTermsName:
            terms = downloadTerms;
            break;
        case apiTermsName:
            terms = isEaiApi ? eaiApiTerms : apiTerms;
            break;
        default:
            break;
    }

    const classes = useStyles();
    return <Typography component='div' className={classes.content}>
        <div
            data-testid="terms-container"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(terms, { ADD_ATTR: ['target', 'aria-label', 'rel'] }) }}
        />
    </Typography>;
}

LegalTerms.propTypes = {
    variant: PropTypes.oneOf(allTermsNames).isRequired,
    isEai: PropTypes.bool
};
