import React from 'react';
import Header from "./Header";
import Content from './Content';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import styles from './style';
import ResponseCodes from './ResponseCodes';
import ZoomLevels from './ZoomLevels';
import { InternalLink, ExternalLink } from 'omse-components';
import routes from "../../util/routes";
import Authentication from "./Authentication";
import DocLinks from "./DocLinks";
import Document from './Document';
import Table from './Table';
import TextBox from '../../components/TextBox';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

const parametersWmts = [
    ['key', 'required', '', 'string', 'The API Key provided by the OS Data Hub.'],
    ['service', 'required', 'WMTS', 'string', 'The service that is being accessed.'],
    ['request', 'required', 'GetTile', 'string', 'The type of request being made. If a GetCapabilities request is being made, the other parameters are not required other than authentication.'],
    ['version', 'optional', '2.0.0', 'string', 'The version number of the service.'],
    ['height', 'optional', '256', 'integer', 'The Height of the tile.'],
    ['width', 'optional', '256', 'integer', 'The Width of the tile.'],
    ['outputformat', 'optional', 'image/png', 'string', 'The format of the returned tile.'],
    ['style', 'required', 'default', 'string', 'Taken from the Identifier of the chosen Layer\'s Style property, as described in the Capabilities document.'],
    ['layer', 'required', 'Road_27700', 'string', 'Taken from the Identifier of the chosen Layer, as described in the Capabilities document.'],
    ['tileMatrixSet', 'required', 'EPSG:27700', 'string', 'Taken from the Identifier of the chosen TileMatrixSet, as described in the Capabilities document.'],
    ['tileMatrix', 'required', '18', 'integer', 'Taken from the Identifier of the chosen TileMatrixSet\'s TileMatrix, as described in the Capabilities document.'],
    ['tileRow', 'required', '89006', 'integer', 'The requested row of the called tile.'],
    ['tileCol', 'required', '12475', 'integer', 'The requested column of the called tile.']
];

const parametersZxy = [
    ['layer', 'required', 'Road_27700', 'string', 'The tile set layer that will be requested.'],
    ['z', 'required', '18', 'integer', 'The z index of the map layer.'],
    ['x', 'required', '126', 'integer', 'The x index of the map layer.'],
    ['y', 'required', '85', 'integer', 'The y index of the map layer.'],
    ['key', 'required', '', 'string', 'The API Key provided by the OS Data Hub.']
];

export function WMTSDetail() {
    return <Document>
        <Header back={true}>OS Maps API: Technical specification</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This guide explains the content of the OS Maps API, and the methods that you can use to call it.
                    If you are not familiar with the OS Maps API you may want to read the <InternalLink path={routes.wmtsOverview} message="overview documentation" /> before proceeding.
                </p>
                <p>
                    OS Maps API is available as EPSG:27700 (British National Grid which is applicable just for GB) and EPSG:3857 (Web Mercator which is a global coordinate system).
                    All four of our styles are available; Road, Outdoor, Light in both 27700 and 3857, plus Leisure in 27700.
                    The API is available using the Open Geospatial Consortium Web Map Tile Service (OGC WMTS) standard and Restful ZXY.
                </p>
            </Typography>
            <Typography variant='h1' component='h2'>Technical Detail</Typography>
            <Typography variant='body1' paragraph={true}>
                OS Maps API requests are structured using keyword-value pair (KVP) parameter encoding, and HTTP <InlineCodeSnippet>GET</InlineCodeSnippet>.
                All requests must be encoded correctly according to standard <ExternalLink type="generic" href="https://www.w3schools.com/tags/ref_urlencode.asp" message="percent-encoding" /> procedures for a URI.
            </Typography>
            <Authentication gettingStartedRoute={routes.wmtsIntro}/>
            <Typography variant='h2'>Encryption</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    As of October 2018, all calls must be made using a secure hypertext encryption (HTTPS).
                </p>
                <p>
                    Ordnance Survey APIs no longer support calls made using SSL v2/v3 or TLS v1.0/v1.1.
                    Thus, all calls made to any of our APIs must support TLS v1.2 as of October 2018 in line with current cyber security recommendations.
                </p>
            </Typography>
            <Typography variant='h2'>Transformation</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    Customers should use a transformation utilising the OSTN15/OSGM15 transformation to transform data between EPSG:27700 and EPSG:3857 when intending to use the data with this service.
                </p>
                <p>
                    Data transformed using the old OSTN02/OSGM02 transformation will result in a small but noticeable shift in the data when overlaid.
                </p>
                <p>
                    This is only applicable if you are transforming data held in either EPSG:3857 or EPSG:27700 to be shown on a basemap of the opposite projection.
                </p>
            </Typography>
            <ResponseCodes/>
            <Typography variant='h2'>Content</Typography>
            <Typography variant='h3'>Layers and Styles</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <Table>
                    <tbody>
                        <tr>
                            <th>Style</th>
                            <th>Projection</th>
                            <th>Layer Name</th>
                            <th>Tile Matrix Set</th>
                        </tr>
                        <tr>
                            <td>Road</td>
                            <td>EPSG:27700</td>
                            <td>
                                <InlineCodeSnippet>
                                    Road_27700
                                </InlineCodeSnippet>
                            </td>
                            <td>EPSG:27700</td>
                        </tr>
                        <tr>
                            <td>
                                Road
                            </td>
                            <td>EPSG:3857</td>
                            <td>
                                <InlineCodeSnippet>
                                    Road_3857
                                </InlineCodeSnippet>
                            </td>
                            <td>EPSG:3857</td>
                        </tr>
                        <tr>
                            <td>Outdoor</td>
                            <td>EPSG:27700</td>
                            <td>
                                <InlineCodeSnippet>
                                    Outdoor_27700
                                </InlineCodeSnippet>
                            </td>
                            <td>EPSG:27700</td>
                        </tr>
                        <tr>
                            <td>Outdoor</td>
                            <td>EPSG:3857</td>
                            <td>
                                <InlineCodeSnippet>
                                    Outdoor_3857
                                </InlineCodeSnippet>
                            </td>
                            <td>EPSG:3857</td>
                        </tr>
                        <tr>
                            <td>Light</td>
                            <td>EPSG:27700</td>
                            <td>
                                <InlineCodeSnippet>
                                    Light_27700
                                </InlineCodeSnippet>
                            </td>
                            <td>EPSG:27700</td>
                        </tr>
                        <tr>
                            <td>Light</td>
                            <td>EPSG:3857</td>
                            <td>
                                <InlineCodeSnippet>
                                    Light_3857
                                </InlineCodeSnippet>
                            </td>
                            <td>EPSG:3857</td>
                        </tr>
                        <tr>
                            <td>Leisure</td>
                            <td>EPSG:27700</td>
                            <td>
                                <InlineCodeSnippet>
                                Leisure_27700
                                </InlineCodeSnippet>
                            </td>
                            <td>EPSG:27700</td>
                        </tr>
                    </tbody>
                </Table>
            </Typography>
            <ZoomLevels srid='EPSG:3857'/>
            <ZoomLevels srid='EPSG:27700'/>
            <Typography variant='h2'>WMTS Parameters</Typography>
            <Typography variant='h3'>Definition</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    This service is available as a GET resource method allowing OGC KVP WMTS access.
                    When generating your key using the OS Data Hub, a URL will be automatically generated using your new API key.
                    For demonstration purposes with regards to the parameters we have provided a stripped-down URL below.
                </p>
                <TextBox
                    text="https://api.os.uk/maps/raster/v1/wmts?request=getcapabilities&key="
                    label="OS Maps API - WMTS Endpoint"
                    variant="code"
                />
            </Typography>
            <Typography variant='h3'>Query Parameters</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <Table>
                    <tbody>
                    <tr>
                        <th>Parameter</th>
                        <th>Required?</th>
                        <th>Example</th>
                        <th>Value</th>
                        <th>Notes</th>
                    </tr>
                    {
                        parametersWmts.map((param, index) =>
                            <tr key={index}>
                                <td>
                                    <InlineCodeSnippet>
                                        {param[0]}
                                    </InlineCodeSnippet>
                                </td>
                                <td>{param[1]}</td>
                                <td>
                                    {param[2] && (
                                        <InlineCodeSnippet>
                                            {param[2]}
                                        </InlineCodeSnippet>
                                    )}
                                </td>
                                <td>{param[3]}</td>
                                <td>{param[4]}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </Typography>
            <Typography variant='h2'>ZXY Parameters</Typography>
            <Typography variant='h3'>Definition</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                The ZXY endpoint is a stripped back endpoint for OS Maps API and is designed to be dropped into mapping libraries for quick use.
                </p>
                <TextBox
                    text="https://api.os.uk/maps/raster/v1/zxy/layer/{z}/{x}/{y}.png?key="
                    label="OS Maps API - ZXY Endpoint"
                    variant="code"
                />
            </Typography>
            <Typography variant='h3'>Query Parameters</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <Table>
                    <tbody>
                        <tr>
                            <th>Parameter</th>
                            <th>Required?</th>
                            <th>Example</th>
                            <th>Value</th>
                            <th>Notes</th>
                        </tr>
                        {
                            parametersZxy.map((param, index) =>
                                <tr key={index}>
                                    <td>
                                        <InlineCodeSnippet>
                                            {param[0]}
                                        </InlineCodeSnippet>
                                    </td>
                                    <td>{param[1]}</td>
                                    <td>
                                        {param[2] && (
                                            <InlineCodeSnippet>
                                                {param[2]}
                                            </InlineCodeSnippet>
                                        )}
                                    </td>
                                    <td>{param[3]}</td>
                                    <td>{param[4]}</td>
                                </tr>
                            )   
                        }
                    </tbody>
                </Table>
            </Typography>
            <DocLinks product='wmts' hasGettingStarted={true} hasDemo={true}/>
        </Content>
    </Document>
}

export default withStyles(styles)(WMTSDetail);
