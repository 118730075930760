import React, {useState, useEffect} from 'react';
import useRecipe from "../../../hooks/useRecipe";
import useFeatureCheck from "../../../util/useFeatureCheck";
import features from "../../../../shared/features";
import {hasEditNgdDownloadsPermission, hasShareRecipesPermission} from "../../../util/permissions";
import usePartnerContracts from "../../../hooks/usePartnerContracts";
import {NGD_PRODUCT_ID} from "../../../../shared/constants";
import {LOADING, NOT_NEEDED} from "../../../constants/state";
import routePaths, {getLocation} from "../../../util/routes";
import {Typography, Button} from "@mui/material";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import SharedWithMeTag from "../../../components/SharedWithMeTag";
import {AddButton, DropDownMenu, Notification, osColour} from "omse-components";
import ShareRecipeConfirmationDialog from "./dialog/ShareRecipeConfirmationDialog";
import {ReactComponent as ShareIcon} from "../../../components/icons/share.svg";
import {ReactComponent as BinIcon} from "../../../components/icons/bin.svg";
import ShareRecipeDialog from "./dialog/ShareRecipeDialog";
import PartnerLicenceRequiredDialog from "../premiumItem/PartnerLicenceRequiredDialog";
import {createUseStyles} from 'react-jss';
import {styles} from "../DownloadStyles";
import {useHistory, useLocation} from "react-router";
import {useSelector} from "react-redux";
import SharedWithDialog from "./dialog/SharedWithDialog";
import PartnerLicences from "../../../components/PartnerLicences";
import EditableField from "../../../components/EditableField";
import feature from "../../../../shared/features";
import {setRecipeName} from "../../../modules/recipes/actions";
import useActionIdSelector from "../../../hooks/useActionIdSelector";
import DeleteRecipeDialog from "./dialog/DeleteRecipeDialog";
import NotActiveTag from "../../../components/NotActiveTag";
import {ADMIN_ROLE} from "../../../../shared/roles";

const messages = defineMessages({
    title: {
        id: 'RecipeDetailsHeader.title',
        defaultMessage: 'Recipe Details',
        description: 'Title for the recipe details page, used while the recipe itself is loading'
    },
    add: {
        id: 'RecipeDetailsHeader.add',
        defaultMessage: 'Add data package',
        description: 'Label for the add data package button.'
    },
    accept: {
        id: 'RecipeDetailsHeader.accept',
        defaultMessage: 'Accept recipe',
        description: 'Label for the accept recipe button.'
    },
    reject: {
        id: 'RecipeDetailsHeader.reject',
        defaultMessage: 'Reject recipe',
        description: 'Label for the reject recipe button.'
    },
    share: {
        id: 'RecipeDetailsHeader.share',
        defaultMessage: 'Share recipe',
        description: 'Label for the share recipe button.'
    },
    edit: {
        id: 'RecipeDetailsHeader.edit',
        defaultMessage: 'Edit recipe',
        description: 'Label for the edit recipe button.'
    },
    delete: {
        id: 'RecipeDetailsHeader.delete',
        defaultMessage: 'Delete recipe',
        description: 'Label for the deleting recipe button.'
    },
    sharedRecipeSuccessfully: {
        id: 'RecipeDetailsHeader.sharedRecipeSuccessfully',
        defaultMessage: 'You have successfully shared your recipe with {orgName}',
        description: 'Notification message for successful share '
    },
    sharedWith: {
        id: 'RecipeDetailsHeader.sharedWith',
        defaultMessage: 'Shared with {count, plural, one {# organisation} other {# organisations}}',
        description: 'Label for the button that opens the shared-with dialog'
    },
    buttonAriaLabel: {
        id: 'RecipeDetailsHeader.buttonAriaLabel',
        defaultMessage: 'Recipe name, click to edit',
        description: 'Label used for the aria-label attribute on the name button'
    },
    iconAriaLabel: {
        id: 'RecipeDetailsHeader.iconAriaLabel',
        defaultMessage: 'Click to edit the recipe name',
        description: 'Label used for the alt text on the edit icon'
    },
    inputAriaLabel: {
        id: 'RecipeDetailsHeader.inputAriaLabel',
        defaultMessage: 'Recipe name',
        description: 'Label used for the aria-label attribute on the name input field'
    },
    maxLength: {
        id: 'RecipeDetailsHeader.maxLength',
        defaultMessage: 'The recipe name must be less than 256 characters long',
        description: 'Label used for error message if the name is too long'
    },
    setNameError: {
        id: 'RecipeDetailsHeader.setNameError',
        defaultMessage: 'An error occurred while renaming the recipe. Please try again and contact support if the problem continues.',
        description: 'Notification that is shown when a recipe rename fails'
    },
    recipeActions: {
        id: 'RecipeDetailsHeader.recipeActions',
        defaultMessage: 'Recipe actions',
        description: 'Recipe actions dropdown text'
    },
    recipeActionsLabel: {
        id: 'RecipeDetailsHeader.recipeActionsLabel',
        defaultMessage: 'Recipe actions dropdown',
        description: 'Recipe actions dropdown accessibility label'
    },
    deletedRecipeSuccessfully: {
        id: 'RecipeDetailsHeader.deletedRecipeSuccessfully',
        defaultMessage: 'You have successfully deleted your recipe',
        description: 'Notification message for successful deletion'
    }
});

const useDownloadStyles = createUseStyles(styles);
const useStyles = createUseStyles(theme => ({
    verticalRecipeActions: {
        flex: '0 0 auto',
        gap: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column'
    },
    horizontalRecipeActions: {
        flex: '0 0 auto',
        display: 'flex',
        gap: theme.spacing(2)
    },
    titleContainer: {
        flex: '1 1 auto'
    },
    icon: {
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(2),
        width: theme.spacing(3),
        fill: osColour.primary.berry
    }
}));

export default function RecipeDetailsHeader() {
    const intl = useIntl();
    const downloadClasses = useDownloadStyles();
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const user = useSelector(state => state.user.current.result);
    const {recipe} = useRecipe();
    const licences = usePartnerContracts(NGD_PRODUCT_ID);
    const sharingEnabled = useFeatureCheck(features.NGD_SHARE);
    const canEditRecipe = user.role === ADMIN_ROLE || !!recipe?.isOwner;
    const recipeEditing = useFeatureCheck(feature.NGD_RECIPE_EDITING) && recipe && !recipe.sharedWithMe && hasEditNgdDownloadsPermission(user) && canEditRecipe;

    // By using the action id selector this instance of the recipe details component won't display success/failure
    // from other instances.
    const [setNameResult, dispatch] = useActionIdSelector("recipes.setName");

    const [notification, setNotification] = useState(null);
    const [dialog, setDialog] = useState(null);

    // When the recipe is renamed then an action is dispatched and eventually a result will come back. If the
    // action fails then we need to show an error. When the notification is dismissed the error state will still
    // be in the redux store, but as it doesn't change until the next attempt, the effect won't fire again until
    // a new result arrives.
    useEffect(() => {
        if(setNameResult.error) {
            setNotification(<Notification variant='error'
                                          appearance='toast'
                                          autoClose={false}
                                          onClose={() => setNotification(null)}>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.setNameError}/>
                </Typography>
            </Notification>);
        }
    }, [setNameResult]);

    const recipeActionButtons = {
        shareButton: {
            label: intl.formatMessage(messages.share),
            action: showShareDataPackage,
            icon: <ShareIcon className={classes.icon} />,
            disabled: false
        },
        // editButton: {
        //     label: intl.formatMessage(messages.edit),
        //     action: showShareDataPackage,
        //     icon: <EditIcon className={classes.icon} />,
        //     disabled: true
        // },
        seperator:{
            separator : true
        },
        deleteButton: {
            label: intl.formatMessage(messages.delete),
            action: deleteRecipe,
            icon: <BinIcon className={classes.icon} />,
            disabled: false
        }
    };
    let recipeActionDropdown = null;
    let showAddButton = false;
    let showSharedWith = false;
    if(recipe) {
        if(recipe.viewingInvitation) {
            // Do nothing as the buttons are hidden anyway. We will draw the accept/reject buttons instead
        } else if(recipe.sharedWithMe) {
            showAddButton = hasEditNgdDownloadsPermission(user) && !recipe.deleted;
        } else {
            showAddButton = hasEditNgdDownloadsPermission(user) && !recipe.deleted;
            showSharedWith = recipe.sharedOrgs && recipe.sharedOrgs.length > 0;
            recipeActionButtons.shareButton.disabled = !(sharingEnabled && hasShareRecipesPermission(user)) || recipe.deleted;
            recipeActionButtons.deleteButton.disabled = !recipeEditing || showSharedWith || recipe.deleted;
            recipeActionDropdown = <DropDownMenu
                buttonLabel={messages.recipeActions}
                buttonVariant='outlined'
                buttonClassName={classes.spaceButton}
                items={Object.values(recipeActionButtons)}
                dataValueKey='label'
                buttonProps={{'aria-label': intl.formatMessage(messages.recipeActionsLabel)}}
            />;
        }
    }

    function setName(name) {
        dispatch(setRecipeName(recipe.id, name));
    }

    function addDataPackage() {
        if (licences === NOT_NEEDED || (Array.isArray(licences) && licences.length)) {
            const path = routePaths.dataPackageNewNgd.replace(':recipeId', recipe.id);
            const newLocation = getLocation(path, location, {}, { state: { canGoBack: true }});
            history.push(newLocation);
        } else {
            setDialog(<PartnerLicenceRequiredDialog onClose={onClose} productId={NGD_PRODUCT_ID} />);
        }
    }

    function deleteRecipe(){
        setDialog(<DeleteRecipeDialog onClose={closeDeleteDialog} recipeId={recipe.id} hasDatapackages={recipe.datapackages?.length > 0}/>);
    }

    function showAcceptOrRejectRecipe(type) {
        setDialog(<ShareRecipeConfirmationDialog onClose={onClose} confirmationType={type}/>);
    }

    function showShareDataPackage() {
        setDialog(<ShareRecipeDialog onClose={closeShareDialog} recipeId={recipe.id}/>);
    }

    function showSharedWithDialog() {
        setDialog(<SharedWithDialog onClose={onClose}/>);
    }

    function onClose() {
        setDialog(null);
    }

    function closeShareDialog(org) {
        if (org) {
            setNotification(
                <Notification
                    variant='success'
                    appearance='toast'
                    autoClose={true}
                    onClose={() => setNotification(null)}
                >
                    <Typography variant='body1'>
                        <FormattedMessage {...messages.sharedRecipeSuccessfully} values={{orgName: org}}/>
                    </Typography>
                </Notification>);
        }
        onClose();
    }

    function closeDeleteDialog(deleted) {
        if(deleted) {
            if(recipe.datapackages?.length) {
                // There are data packages, so show a notification and but stay on the page
                setNotification(
                    <Notification
                        variant='success'
                        appearance='toast'
                        autoClose={true}
                        onClose={() => setNotification(null)}
                    >
                        <Typography variant='body1'>
                            <FormattedMessage {...messages.deletedRecipeSuccessfully}/>
                        </Typography>
                    </Notification>);
            } else {
                // There are no data packages, so re-route the user back to the recipe library
                const newLocation = getLocation(
                    routePaths.recipeLibrary, location, {}, { state: { recipeDeleted: messages.deletedRecipeSuccessfully }}
                );
                history.push(newLocation);
            }
        }
        onClose();
    }

    return <div>
        <header>
            <div className={downloadClasses.innerHeader}>
                <div className={classes.titleContainer}>
                    <div className={downloadClasses.title}>
                        <EditableField  fieldValue={recipe?.name || intl.formatMessage(messages.title)}
                                        setFieldValue={setName}
                                        readonly={!recipe || !recipeEditing || recipe.deleted}
                                        buttonAriaLabel={messages.buttonAriaLabel}
                                        iconAriaLabel={messages.iconAriaLabel}
                                        inputAriaLabel={messages.inputAriaLabel}
                                        maxLength={255}
                                        maxLengthMessage={messages.maxLength}
                                        fieldTypeName={"name"}/>
                        {recipe?.sharedWithMe && <SharedWithMeTag/>}
                        {recipe?.deleted && <NotActiveTag/>}
                    </div>
                    {
                        // Only show partner licence info once we've actually got access to the recipe
                        (recipe?.viewingInvitation !== true) && <PartnerLicences productId={NGD_PRODUCT_ID}/>
                    }
                </div>
                {
                    showSharedWith &&
                    <Button variant='text'
                            color='primary'
                            className={classes.horizontalRecipeActions}
                            onClick={showSharedWithDialog}
                            startIcon={<span aria-hidden="true" className="material-symbols-outlined">group</span>}>
                        <FormattedMessage {...messages.sharedWith} values={{ count: recipe.sharedOrgs.length }}/>
                    </Button>
                }
                {
                    recipe?.viewingInvitation &&
                    <div className={classes.horizontalRecipeActions}>
                        <AddButton  label={messages.accept}
                                    action={() => showAcceptOrRejectRecipe('ACCEPT')}
                                    showIcon={false}
                        />
                        <AddButton  label={messages.reject}
                                    action={() => showAcceptOrRejectRecipe('REJECT')}
                                    variant='outlined'
                                    showIcon={false}
                        />
                    </div>
                }
                <div className={classes.verticalRecipeActions}>
                    {
                        showAddButton && <AddButton label={messages.add}
                                                    disabled={licences === LOADING}
                                                    working={licences === LOADING}
                                                    action={addDataPackage}/>
                    }
                    {recipeActionDropdown}
                </div>
                {dialog}
                {notification}
            </div>
        </header>
    </div>;
}
