import React from 'react';
import PropTypes from 'prop-types';
import Table from './Table';

export default function ParametersTable(props) {
    return <Table classes={props.classes} header={[
        'Parameter',
        'Required?',
        'Data Type',
        'Accepted Values',
        'Description'
    ]} values={props.values} />;
}

ParametersTable.propTypes = {
    values: PropTypes.array.isRequired
}