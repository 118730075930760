// React hook to store the selected project in a search parameter, when on the history stats page
import useSearchParam from "./useSearchParam";
import {useSelector} from 'react-redux';

const noProject = '';

export default function useStatsProjectId() {
    let [projectId, setProjectId] = useSearchParam('projectId', noProject);
    let projects = useSelector(state => state.projects.current.result);

    if(projectId !== noProject && projects) {
        // Now that we have a list of projects, check that we have a valid choice
        if(!projects.find(p => p.projectId === projectId)) {
            projectId = noProject;
            setProjectId(noProject);
        }
    }

    return [projectId, setProjectId];
}
