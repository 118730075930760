import {createUseStyles} from 'react-jss';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReactComponent as UpArrow } from "./icons/upArrowLarge.svg";

const useStyles = createUseStyles((theme) => {
    return {
        li: {
            paddingTop: 0,
            paddingBottom: theme.spacing(1),
            paddingLeft: 0,
            alignItems: 'start',
            width: 'auto',
            '& img': {
                display: 'block'
            }
        },
        text: {
            padding: 0,
        },
        icon: {
            margin: 'auto',
            marginRight: theme.spacing(1),
            alignItems: 'flex-start'
        },
        get liWithIcon() {
            // add additional spacing
            return {
                ...this.listItem,
                marginBottom: theme.spacing(4)
            }
        }
    }
});

export function LinkToAnchor(props) {
    const classes = useStyles();
    const item = {
        id: (props?.item) ? props.item.id : 'main-content',
        title: (props?.item) ? props.item.title : 'Back to top'
    };
    return (
        <ListItem className={(item.id === 'main-content') ? classes.liWithIcon : classes.li}>
            {(item.id === 'main-content') &&
                <ListItemIcon className={classes.icon}>
                    <UpArrow />
                </ListItemIcon>
            }
            <ListItemText className={classes.text}>
                <a href={`#${item.id}`}>{item.title}</a>
            </ListItemText>
        </ListItem>
    );
}