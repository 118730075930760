import React from 'react';
import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import {useLocation} from 'react-router';
import {getLocation} from "../util/routes";
import useNavigator from "../hooks/useNavigator";

export default function Link({path, state, className, children, hash, search, ariaLabel}) {
    const location = useLocation();
    const {navigate, setElement} = useNavigator({path, search, hash, state});

    const newLocation = getLocation(path, location, search);
    if(typeof state === 'object') {
        newLocation.state = state;
    }
    newLocation.hash = hash;

    const extraProps = {};
    if (ariaLabel) {
        extraProps['aria-label'] = ariaLabel;
    }

    return <RouterLink to={newLocation}
                       className={className}
                       onClick={navigate}
                       ref={setElement}
                       {...extraProps}>
        {children}
    </RouterLink>
}

Link.propTypes = {
    path: PropTypes.string.isRequired,
    state: PropTypes.oneOfType([
        PropTypes.object,                // Optional, state to pass to the new location, or
        PropTypes.func                   // a function that can build the state when the button is clicked
    ]),
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
    hash: PropTypes.string,
    search: PropTypes.object,
    ariaLabel: PropTypes.string
};

Link.defaultProps = {
    preserveSearch: []
};