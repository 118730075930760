import Polygon from 'ol/geom/Polygon';

const LETTERS_100_K = "VWXYZQRSTULMNOPFGHJKABCDE";
const LETTERS_500_K = "STNOHJ";
const GRID_SIZE_100_K = 100000;
const GRID_SIZE_500_K = 500000;

const GRID_REFERENCE_REGEX = "^(H(P|T|U|W|X|Y|Z)|N(A|B|C|D|F|G|H|J|K|L|M|N|O|R|S|T|U|W|X|Y|Z)|OV|S(C|D|E|H|J|K|M|N|O|P|R|S|T|U|V|W|X|Y|Z)|T(A|F|G|L|M|Q|R|V)){1}(\\d{4}|\\d{2})?(NE|NW|SE|SW)?$";
const QUADRANT_REGEX = ".*(NE|NW|SE|SW)$";

export function gridRefToPolygon(gridRef) {
    let ref = gridRef.toUpperCase();
    
    if (!ref.match(GRID_REFERENCE_REGEX)) {
        throw Error("Grid reference is invalid");
    }

    let coord = getCoord(ref);
    let tileSize = calculateTileSize(ref);

    let coordinates = [[coord.x, coord.y], [coord.x + tileSize, coord.y], [coord.x + tileSize, coord.y + tileSize], [coord.x, coord.y + tileSize], [coord.x, coord.y]];

    return new Polygon([coordinates]);
}

function calculateTileSize(ref) {
    let quadrant = ref.length > 2 && ref.match(QUADRANT_REGEX);
    let length = quadrant ? ref.length - 2 : ref.length;

    let tileSize = Math.pow(10, 6 - (length / 2));
    if (quadrant) {
        return tileSize / 2;
    } else {
        return tileSize;
    }
}

export function getCoord(ref) {
    let ref500X = GRID_SIZE_500_K * Math.floor(LETTERS_500_K.indexOf(ref.substring(0, 1)) % 2);
    let ref500Y = GRID_SIZE_500_K * Math.floor(LETTERS_500_K.indexOf(ref.substring(0, 1)) / 2);
    let ref100X = GRID_SIZE_100_K * Math.floor(LETTERS_100_K.indexOf(ref.substring(1, 2)) % 5);
    let ref100Y = GRID_SIZE_100_K * Math.floor(LETTERS_100_K.indexOf(ref.substring(1, 2)) / 5);

    if ((ref500X < 0) || (ref500Y < 0) || (ref100X < 0) || (ref100Y < 0)) {
        throw new Error("Grid reference has an invalid 100km reference");
    }

    let x100k = ref500X + ref100X;
    let y100k = ref500Y + ref100Y;

    if (ref.length !== 2) {
        // Get any quadrant and define padding string
        let padX = "000000";
        let padY = "000000";
        let quadrant = false;
        if (ref.endsWith("SW")) {
            quadrant = true;
        } else if (ref.endsWith("NW")) {
            padY = "500000";
            quadrant = true;
        } else if (ref.endsWith("NE")) {
            padX = "500000";
            padY = "500000";
            quadrant = true;
        } else if (ref.endsWith("SE")) {
            padX = "500000";
            quadrant = true;
        }

        let numerals = "";
        if (quadrant) {
            if (ref.length > 4) {
                numerals = ref.substring(2, ref.length - 2);
            }
        } else {
            numerals = ref.substring(2);
        }

        let coordPartLen = Math.floor(numerals.length / 2);

        let xPart = parseInt((numerals.substring(0, coordPartLen) + padX).substring(0, 5));
        let yPart = parseInt((numerals.substring(coordPartLen) + padY).substring(0, 5));

        x100k += xPart;
        y100k += yPart;
    }

    return {x: x100k, y: y100k};
}