export const PREFIX = 'action:menu:';
export const CLOSE_MENU_ACTION = PREFIX + 'close';
export const OPEN_NAV_ACTION = PREFIX + 'nav:open';
export const OPEN_ACCOUNT_MENU = PREFIX + 'account:open';
export const SET_TABS = PREFIX + 'tabs:set';
export const RELOAD_TABS = PREFIX + 'tabs:reload';

export function openNavMenu() {
    return {
        type: OPEN_NAV_ACTION
    }
}

export function closeMenu() {
    return {
        type: CLOSE_MENU_ACTION
    }
}

export function openAccountMenu() {
    return {
        type: OPEN_ACCOUNT_MENU
    }
}

export function setTabs(tabs) {
    return {
        type: SET_TABS,
        tabs
    }
}

export function reloadTabs() {
    return {
        type: RELOAD_TABS
    }
}