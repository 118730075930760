import React from 'react';
import Typography from "@mui/material/Typography";
import Table from './Table';
import { ExternalLink } from 'omse-components';
import InlineCodeSnippet from '../../components/InlineCodeSnippet';

const dataProducts = {
    // 'Boundary-Line': {
    //     open: true,
    //     featureTypeFootnote: true,
    //     featureTypePrefix: 'BoundaryLine_',
    //     featureTypes: [
    //         { name: 'BoundaryLine', type: 'Area' },
    //         { name: 'CommunityWard', type: 'Area' },
    //         { name: 'PollingDistrict', type: 'Area' },
    //         { name: 'SupplementaryBoundary', type: 'Area' },
    //         { name: 'HighWaterLine', type: 'Line'}
    //     ]
    // },
    'OS Detailed Path Network': {
        open: false,
        featureTypePrefix: 'DetailedPathNetwork_',
        featureTypes: [
            { name: 'RouteLink', type: 'Line' },
            { name: 'RouteNode', type: 'Point' }
        ],
        link: <ExternalLink type="generic" href="https://www.ordnancesurvey.co.uk/products/os-detailed-path-network" message="OS Detailed Path Network" />
    },
    'OS MasterMap Highways Network': {
        open: false,
        featureTypePrefix: 'Highways_',
        featureTypes: [
            { name: 'RoadLink', type: 'Line' },
            { name: 'RoadNode', type: 'Point' },
            { name: 'FerryLink', type: 'Line' },
            { name: 'FerryNode', type: 'Point' },
            { name: 'Street', type: 'Line' },
            { name: 'ConnectingLink', type: 'Line' },
            { name: 'ConnectingNode', type: 'Point' },
            { name: 'PathLink', type: 'Line' },
            { name: 'PathNode', type: 'Point' }
        ],
        link: <ExternalLink type="generic" href='https://www.ordnancesurvey.co.uk/products/os-mastermap-highways-network-roads' message="OS MasterMap Highways Network" />
    },
    'OS MasterMap Greenspace Layer': {
        open: false,
        featureTypePrefix: 'Greenspace_',
        featureTypes: [{ name: 'GreenspaceArea', type: 'Area' }],
        link: <ExternalLink type="generic" href='https://www.ordnancesurvey.co.uk/products/os-mastermap-greenspace-layer' message="OS MasterMap Greenspace Layer" />
    },
    'OS MasterMap Sites Layer': {
        open: false,
        featureTypePrefix: 'Sites_',
        featureTypes: [
            { name: 'AccessPoint', type: 'Point' },
            { name: 'RoutingPoint', type: 'Point' },
            { name: 'FunctionalSite', type: 'Area' }
        ],
        link: <ExternalLink type="generic" href='https://www.ordnancesurvey.co.uk/products/os-mastermap-sites-layer' message="OS MasterMap Sites Layer" />
    },
    'OS MasterMap Topography Layer': {
        open: false,
        featureTypePrefix: 'Topography_',
        featureTypes: [
            { name: 'CartographicText', type: 'Point' },
            { name: 'CartographicSymbol', type: 'Point' },
            { name: 'TopographicPoint', type: 'Point' },
            { name: 'TopographicLine', type: 'Line' },
            { name: 'TopographicArea', type: 'Area' },
            { name: 'BoundaryLine', type: 'Line'}
        ],
        link: <ExternalLink type="generic" href='https://www.ordnancesurvey.co.uk/products/os-mastermap-topography-layer' message="OS MasterMap Topography Layer" />
    },
    'OS MasterMap Water Network Layer': {
        open: false,
        featureTypePrefix: 'WaterNetwork_',
        featureTypes: [
            { name: 'HydroNode', type: 'Point' },
            { name: 'WatercourseLink', type: 'Line' }
        ],
        link: <ExternalLink type="generic" href='https://www.ordnancesurvey.co.uk/products/os-mastermap-networks-water-layer' message="OS MasterMap Water Network Layer" />
    },
    'OS Open Zoomstack': {
        open: true,
        featureTypeFootnote: true,
        featureTypePrefix: 'Zoomstack_',
        featureTypes: [
            { name: 'Airports', type: 'Point' },
            { name: 'Boundaries', type: 'Line' },
            { name: 'Contours', type: 'Line' },
            { name: 'DistrictBuildings', type: 'Area' },
            { name: 'ETL', type: 'Line' },
            { name: 'Foreshore', type: 'Area' },
            { name: 'Greenspace', type: 'Area' },
            { name: 'LocalBuildings', type: 'Area' },
            { name: 'Names', type: 'Point' },
            { name: 'NationalParks', type: 'Area' },
            { name: 'RailwayStations', type: 'Point' },
            { name: 'Rail', type: 'Line' },
            { name: 'RoadsLocal', type: 'Line' },
            { name: 'RoadsNational', type: 'Line' },
            { name: 'RoadsRegional', type: 'Line' },
            { name: 'Sites', type: 'Area' },
            { name: 'Surfacewater', type: 'Area' },
            { name: 'UrbanAreas', type: 'Area' },
            { name: 'Waterlines', type: 'Line' },
            { name: 'Woodland', type: 'Area' },
        ],
        link: <ExternalLink type="generic" href='https://www.ordnancesurvey.co.uk/products/os-open-zoomstack' message="OS Open Zoomstack" />
    },
    'OS Open UPRN': {
        open: true,
        featureTypeFootnote: true,
        featureTypePrefix: 'OpenUPRN_',
        featureTypes: [
            { name: 'Address', type: 'Point' }
        ],
        link: <ExternalLink type="generic" href='https://www.ordnancesurvey.co.uk/products/os-open-uprn' message="OS Open UPRN" />
    },
    'OS Open USRN': {
        open: true,
        featureTypeFootnote: true,
        featureTypePrefix: 'OpenUSRN_',
        featureTypes: [
            { name: 'USRN', type: 'Line' }
        ],
        link: <ExternalLink type="generic" href='https://www.ordnancesurvey.co.uk/products/os-open-usrn' message="OS Open USRN" />
    },
    'OS Open TOID': {
        open: true,
        featureTypeFootnote: true,
        featureTypePrefix: 'OpenTOID_',
        featureTypes: [
            { name: 'TopographyLayer', type: 'Point' },
            { name: 'HighwaysNetwork', type: 'Point' },
            { name: 'SitesLayer', type: 'Point' }
        ],
        link: <ExternalLink type="generic" href='https://www.ordnancesurvey.co.uk/products/os-open-toid' message="OS Open TOID" />
    }
};



function typeNames(product) {
    return product.featureTypes
        .map(t => (<InlineCodeSnippet>{product.featureTypePrefix + t.name}</InlineCodeSnippet>))
        .sort()
}

function geometryTypeNames(product, type) {
    const result = product.featureTypes
        .filter(t => t.type===type)
        .map(t => (<InlineCodeSnippet>{product.featureTypePrefix + t.name}</InlineCodeSnippet>))
        .sort()
    if(result === '') {
        return 'N/A';
    }
    return result;
}

export default function WFSFeatureTypes() {
    return <>
        <Typography variant='h1' component={'h2'}>Content</Typography>
        <Typography variant='body1' paragraph={true} component='div'>
            <p>
                The following table documents the OS data products that are available through the OS Features API. 
                For each product, the table shows if the data is OS OpenData or Premium data, and lists the valid 
                WFS feature type names.
            </p>
            <p>
                Click on the product name to find out more information about the dataset.
            </p>
            <Table>
                <tbody>
                    <tr>
                        <th>Product</th>
                        <th>Data category</th>
                        <th>Feature type names</th>
                    </tr>
                    {
                        Object.keys(dataProducts).map(productName => {
                            const product = dataProducts[productName];
                            return <tr key={productName}>
                                <td>{product.link}</td>
                                <td>{product.open ? 'Open data' : 'Premium data'}</td>
                                <td>
                                    {typeNames(product).map((item, i) => (
                                        <>
                                            {i !== 0 && <br />}
                                            {item}
                                        </>
                                    ))}
                                </td>
                            </tr>;
                        })
                    }
                </tbody>
            </Table>
            <p>These products can be accessed through the <InlineCodeSnippet>typeNames</InlineCodeSnippet> parameter as either an area, point or line feature.</p>
            <p>The following product features are available through the following <InlineCodeSnippet>typeNames</InlineCodeSnippet>:</p>
            <Table>
                <tbody>
                    <tr>
                        <th>Product</th>
                        <th>Area</th>
                        <th>Line</th>
                        <th>Point</th>
                    </tr>
                    {Object.keys(dataProducts).map(productName => {
                        const product = dataProducts[productName];
                        return <tr key={productName}>
                            <td>{productName}</td>
                            {['Area', 'Line', 'Point'].map((geometryType) => {
                                const fields = geometryTypeNames(product, geometryType);
                                return (
                                    <td>
                                        {(fields.length === 0)
                                            ? 'N/A'
                                            : fields.map((item, i) => (
                                                <>
                                                    {i !== 0 && <br />}
                                                    {item}
                                                </>
                                            ))
                                        }
                                    </td>
                                );
                            })}
                        </tr>;
                    })}
                </tbody>
            </Table>
        </Typography>

    </>
}
