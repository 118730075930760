import React, {Component, Fragment} from 'react';
import {Line} from 'react-chartjs-2';
import PropTypes from 'prop-types';
import {osColour, isWidthDown, withWidth} from 'omse-components';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import withStyles from 'react-jss';
import 'chartjs-plugin-annotation';
import classNames from 'classnames';
import {ARROW_LEFT, ARROW_RIGHT, ESCAPE} from '../constants/keys';

const arrowBasis = 10;
const boxShadow = '0 1px 3px 1px rgba(0, 0, 0, 0.4)';
const borderRadius = '2px';
const keyMargin = 4;

const styles = (theme) => ({
    lineGraphWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        '&:focus': {
            outline: 'none'
        }
    },
    lineGraph: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row'
    },
    paper: {
        padding: theme.spacing(1.5),
        paddingBottom: theme.spacing(1),
        background: osColour.neutral.white,
        position: 'relative',
        boxShadow: 'none',
        zIndex: 2,
        borderRadius
    },
    popper: {
        top: theme.spacing(-0.5),
        transform: 'translateY(-100%)',
        zIndex: 1,
        opacity: 1,
        display: 'none',
        position: 'absolute',
        boxShadow,
        borderRadius
    },
    arrow: {
        top: theme.spacing(-0.5),
        width: 25,
        height: 25,
        marginLeft: -12.5,
        position: 'absolute',
        overflow: 'hidden',
        display: 'none',
        '&:after': {      
            display: 'block',
            content: '""',
            position: 'absolute',
            width: arrowBasis,
            height: arrowBasis,
            background: '#fff',
            transform: 'rotate(-45deg)',
            borderRadius: '0 1px',
            top: -5,
            left: 6.5,
            zIndex: 5,
            boxShadow
        }
    },
    key: {
        height: theme.spacing(2),
        width: theme.spacing(2),
        marginRight: keyMargin,
        marginTop: keyMargin,
        flex: 'none'
    },
    item: {
        display: 'flex',
        '& > div': {
            display: 'flex',
            flexWrap: 'wrap'
        }
    },
    title: {
        marginBottom: theme.spacing(0.5)
    },
    label: {
        color: osColour.neutral.stone,
        marginRight: theme.spacing(0.5)
    }
});

export class LineGraph extends Component {

    chartRef = React.createRef();
    tooltipRef = React.createRef();
    tooltipDateRef = React.createRef();
    arrowRef = React.createRef();
    tooltipIndex = 0;

    state = {}

    line = {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-1',
        borderColor: osColour.neutral.stone,
        borderWidth: 2,
        borderDash: [2, 2]
    };

    componentDidMount() {
        if (!this.state.data) {
            const data = typeof this.props.data === 'function'? this.props.data() : this.props.data;
            this.setState({data}, () => {
                if (this.state.data && this.state.data.datasets) {
                    this.state.data.datasets.forEach(item => item.ref = React.createRef());
                    const config = this.chartOptions();
                    this.setState({config});
                }
            });
        }

        window.addEventListener('resize', this.hideTooltip);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.hideTooltip);
    }

    chartOptions = () => {
        return {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    id: 'x-axis-1'
                }],
                yAxes: [{
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        min: 0,
                        suggestedMax: 5
                    }
                }]
            },
            annotation: {
                annotations: []
            },
            tooltips: {
                enabled: false,
                custom: this.customTooltips
            }
        }
    }

    customTooltips = (tooltip) => {
        const {theme} = this.props;
        const chart = this.chartRef.current.chartInstance;
        const canvas = chart.canvas;

        // Hide tooltip and line annotation when tooltip is opaque
        if (tooltip.opacity === 0) {
            this.hideTooltip();
            chart.update();
            return;
        }

        // Update values
        const date = tooltip.dataPoints[0].xLabel;
        const tooltipDateEl = this.tooltipDateRef.current;
        tooltipDateEl.innerHTML = date;
        if (this.state.data && this.state.data.datasets) {
            this.state.data.datasets.forEach((item, index) => {
                // If some datasets are hidden the tooltip array will have fewer elements than the dataPoints array.
                // This means we must use the datasetIndex value from the tooltip to relate them.
                const currentDataPoint = tooltip.dataPoints.find(point => point.datasetIndex === index);
                if (item.ref && item.ref.current && currentDataPoint) {
                    item.ref.current.innerHTML = currentDataPoint.yLabel;
                }
            });
        }

        // Update position
        const tooltipWidth = tooltip.width + keyMargin;
        const tooltipLeft = canvas.offsetLeft + tooltip.caretX - (tooltipWidth / 2);
        const tooltipEl = this.tooltipRef.current;
        tooltipEl.style.width = tooltipWidth + 'px';
        tooltipEl.style.left = tooltipLeft + 'px';
        tooltipEl.style.display = 'block';
        
        // update arrow position
        const arrowEl = this.arrowRef.current;
        arrowEl.style.left = tooltip.caretX + 'px';
        arrowEl.style.display = 'block';

        // positioning at graph limits
        if (isWidthDown('xs', this.props.width)) {

            // min
            if (Math.round(chart.chartArea.left) >= tooltipLeft) {
                tooltipEl.style.left = `calc(${canvas.offsetLeft + tooltip.caretX}px - ${theme.spacing(3.5)})`;
                tooltipEl.style.right = 'auto';
            }

            // max
            if (Math.round(chart.chartArea.right) <= (tooltipLeft + tooltipWidth)) {
                tooltipEl.style.left = 'auto';
                tooltipEl.style.right = theme.spacing(-1);
            }
        }

        // Add dashed line annotation
        tooltip.dataPoints.forEach(p => {
            if (Math.round(p.x) === Math.round(tooltip.caretX)) {
    
                // Update line value to match x axis value of points
                if (p.xLabel && this.line.value !== p.xLabel) {
                    this.line.value = p.xLabel;

                    // Hide if on the y-axis (index 0)
                    chart.options.annotation.annotations = p.index !== 0? [this.line] : [];
                    chart.update();
                }
            }
        });
    }

    hideTooltip = () => {
        const chart = this.chartRef.current.chartInstance;
        this.arrowRef.current.style.display = 'none';
        this.tooltipRef.current.style.display = 'none';
        chart.options.annotation.annotations = [];
        this.line.value = '';
        chart.update();
    }

    keyDown = (e, graphFocussed) => {
        if (e.key === ARROW_RIGHT || e.key === ARROW_LEFT || graphFocussed) {
            const chart = this.chartRef.current.chartInstance;
            if (chart) {
                const canvas = chart.canvas;
                const scales = chart.scales;
                if (canvas && scales) {
                    const xAxis = scales['x-axis-1'];
                    if (xAxis) {
                        const xGrid = xAxis['_gridLineItems'];
                        if (xGrid) {
                            if (this.tooltipIndex < xGrid.length - 1 && e.key === ARROW_RIGHT) {
                                this.tooltipIndex++;
                            }
                            if (this.tooltipIndex > 0 && e.key === ARROW_LEFT) {
                                this.tooltipIndex--;
                            }
                            if (this.tooltipIndex >= 0 && this.tooltipIndex < xGrid.length) {
                                const newX = (xGrid[this.tooltipIndex].x1 || 0) + canvas.offsetParent.offsetLeft;
                                const newY = xGrid[this.tooltipIndex].y1 || 0;
                                const evt = new MouseEvent('click', {
                                    view: window,
                                    bubbles: true,
                                    cancelable: true,
                                    clientX: newX,
                                    clientY: newY
                                });
                                canvas.dispatchEvent(evt);
                            }
                        }
                    }
                }
            }
        }
        if (e.key === ESCAPE) {
            this.hideTooltip();
        }
    }

    graphFocused = () => {
        this.keyDown({key: null}, true);
    }

    graphUnfocused = () => {
        this.hideTooltip();
    }

    render() {
        const {classes, data} = this.props;
        const datasets = (this.state.data && this.state.data.datasets) || [];
        const config = this.state.config;
        
        if (config) {
            config.tooltips.custom = this.customTooltips;
        }

        return <div className={classes.lineGraphWrapper} tabIndex='0' onKeyDown={this.keyDown} onFocus={this.graphFocused} onBlur={this.graphUnfocused}>
            {config &&
                <Fragment>
                    <ClickAwayListener onClickAway={this.hideTooltip} mouseEvent='onMouseDown' touchEvent='onTouchStart'>
                        <Line ref={this.chartRef} className={classes.lineGraph} data={data} options={config} height={200} />
                    </ClickAwayListener>
                    <div ref={this.tooltipRef} role='tooltip' className={classes.popper} x-placement='top'>
                        <div className={classes.paper}>
                            <Typography variant='body1' className={classes.title}>
                                <span ref={this.tooltipDateRef}></span>
                            </Typography>
                            {datasets.filter(dataset => !dataset.hidden).map(item => {
                                return <Typography variant='body1' component='div' className={classes.item} key={item.label}>
                                            <span className={classNames(classes.key, item.keyClass)}></span>
                                            <div>
                                                <span className={classes.label}>{item.displayLabel + ':'}</span>
                                                {item.ref && <span ref={item.ref} />}
                                            </div>
                                        </Typography>
                                })
                            }
                        </div>
                    </div>
                    <span ref={this.arrowRef} className={classes.arrow} />
                </Fragment>
            }
        </div>
    }
}

LineGraph.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

export default withWidth()(withStyles(styles, {injectTheme: true})(LineGraph));

