export const tabsPrefix = "tabs-";
export const tabsRE = tabsPrefix + "[0-9]+";

export const tabRoot = 'tab-root';

export const navSide = 'nav-side';
export const navHeader = 'nav-header';
export const navTabs = 'nav-tabs';
export const navIcon = 'nav-icon';
export const navMobile = 'nav-mobile';

export const navHiddenTabsApp = 'hidden-tabs-app'
export const navMinimalHeader = 'minimal-header';
export const navNowrap = 'nowrap';
export const navActionsContainer = 'nav-actions-container';