import React from 'react';
import {createUseStyles} from 'react-jss';
import {FormattedMessage} from 'react-intl';
import {ReactComponent as HelpIcon} from './icons/help-notification.svg';
import {styles} from '../pages/download/DownloadStyles';
import {LinkButton} from 'omse-components';

const useDownloadStyles = createUseStyles(styles);
const useStyles = createUseStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: theme.spacing(2)
    },
}));

/**
 * A help button with some clickable text.
 */
export default function HelpButton({helpMessage, onClick}) {
    const downloadClasses = useDownloadStyles();
    const classes = useStyles();

    return <div className={classes.root}>
        <HelpIcon width={24} height={24} className={downloadClasses.icon} aria-hidden='true' />
        <LinkButton onClick={onClick}>
            <FormattedMessage {...helpMessage} />
        </LinkButton>
    </div>;
}