import {USER_STATS, USER_STATS_LOADED} from "./actions";
import {createLoadingReducer} from 'omse-components';
import {setupMiddleware} from "./statsMiddleware";
import {withOrganisation} from "../../util/organisation";

// We flag the user stats as 'loading' from the beginning, so that the app doesn't try to render stats
// in the interval between loading the app and sending the first request to load the stats.
export const reducer = createLoadingReducer(USER_STATS, USER_STATS_LOADED, true, copyAttrs);

// When the stats are returned we only want to display them if they reflect the correct mode, so we copy the
// mode from the request over to the result.
function copyAttrs(action) {
    if(action.result) {
        action.result.mode = action.originalAction.mode;
        action.result.orgId = action.originalAction.orgId;
    }
}

function prepareCall(action, store) {
    const params = [];
    if(action.detail) {
        params.push('detail=' + action.detail);
    }
    if(action.mode) {
        params.push('mode=' + action.mode);
    }
    if(action.includeType) {
        params.push('includeType=' + action.includeType)
    }

    return withOrganisation('/api/stats/user?' + params.join('&'), store);
}

export const middleware = setupMiddleware(USER_STATS, USER_STATS_LOADED, prepareCall);
