import React, {useMemo} from 'react';
import {defineMessages, useIntl} from "react-intl";
import useLocationState from "./useLocationState";
import recipeLibraryStyles from "./recipeLibraryStyles";
import {DropDownMenu} from 'omse-components';

const messages = defineMessages({
    userRecipe: {
        id: 'RecipeLibraryView.userRecipe',
        defaultMessage: 'Recipe I created',
        description: 'Text for view recipe dropdown.'
    },
    organisationRecipe: {
        id: 'RecipeLibraryView.organisationRecipe',
        defaultMessage: 'My organisation\'s recipes',
        description: 'Text for view recipe dropdown.'
    },
    organisationsSharedRecipes: {
        id: 'RecipeLibraryView.organisationsSharedRecipes',
        defaultMessage: 'Recipes my organisation has shared',
        description: 'Text for view recipe dropdown.'
    },
    sharedRecipes:{
        id: 'RecipeLibraryView.sharedRecipes',
        defaultMessage: "Recipes shared with my organisation",
        description: 'Text for view recipe dropdown.'
    },
    viewLabel:{
        id: 'RecipeLibraryView.viewLabel',
        defaultMessage: "View",
        description: 'Label for view recipe dropdown.'
    },
    all:{
        id:'RecipeLibraryView.all',
        defaultMessage: 'All recipes',
        description: 'Label for view recipe dropdown.'
    },
});

export const VIEW = 'view';

export default function RecipeLibraryView() {
    const intl = useIntl();
    const classes = recipeLibraryStyles();
    const [view, setView] = useLocationState(VIEW);
    const options = useMemo(() => {
        return [{
            label: intl.formatMessage(messages.all),
            value: ''
        }, {
            label: intl.formatMessage(messages.userRecipe),
            value: 'userRecipe'
        }, {
            label: intl.formatMessage(messages.organisationRecipe),
            value: 'organisationRecipe'
        }, {
            label: intl.formatMessage(messages.organisationsSharedRecipes),
            value: 'organisationsSharedRecipes'
        }, {
            label: intl.formatMessage(messages.sharedRecipes),
            value: 'sharedRecipes'
        }];
    }, [intl]);

    return <div className={classes.select}>
        <DropDownMenu
            buttonId='recipe-sort-types'
            buttonVariant='outlined'
            items={options}
            value={view || ''}
            label={intl.formatMessage(messages.viewLabel)}
            onChange={item => setView(item.value)}
        />
    </div>;
}
