import React from 'react';
import Typography from '@mui/material/Typography';

export default function UsingOFA(props) {
    return <Typography variant='body1' paragraph={true} component='div'>
        <p>
            With OS NGD API – Features:
        </p>
        <p>You can:</p>
        <ul>
            <li>Request specific features using spatial, attribute and/or time queries.</li>
            <li>Interrogate highly detailed feature information.</li>
            <li>Freely discover what data collection are available.</li>
            <li>Explore the data schemas.</li>
            <li>Request data in GeoJSON format.</li>
            <li>Visualise Ordnance Survey data and apply your own styling.</li>
        </ul>
        <p>You can't:</p>
        <ul>
            <li>Create a scalable map of Great Britain across zoom levels.</li>
            <li>Request more than 100 features in a single transaction.</li>
            <li>Access OS NGD Address Theme or Administrative & Statistical Units Theme data.</li>
        </ul>
    </Typography>
}
