import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import TreeBasedQueryViewer from "./TreeBasedQueryViewer";
import treeBuilder from "./treeBuilder";
import TextQueryViewer from "./TextQueryViewer";

export default function FeatureQueryViewer({filterExpression}) {

    const tree = useMemo(function() {
        try {
            return treeBuilder(filterExpression);
        } catch(e) {
            return null;
        }
    }, [filterExpression]);

    if(tree) {
        return <TreeBasedQueryViewer item={tree} topLevel={true}/>;
    } else {
        return <TextQueryViewer filterExpression={filterExpression}/>;
    }
}

FeatureQueryViewer.propTypes = {
    filterExpression: PropTypes.string.isRequired
};
