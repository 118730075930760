import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {defineMessages} from 'react-intl';
import withStyles from 'react-jss';
import {deleteProject, getProject, regenerateKey} from "../modules/project/actions";
import {getProjectStats} from '../modules/stats/actions'
import {getProducts} from "../modules/products";
import {getApiPlans} from '../modules/commercialApiPlans/actions';
import {getPaymentConfigData} from "../modules/payments/actions";
import Product from './apiProject/Product';
import routes, {getLocation} from "../util/routes";
import APIKey from './apiProject/APIKey';
import EmptyProject from './apiProject/EmptyProject';
import ProjectName from './apiProject/ProjectName';
import DeleteProjectDialog from './apiProject/DeleteProjectDialog';
import RegenerateKeyDialog from './apiProject/RegenerateKeyDialog';
import AddAPIDialog from '../components/AddAPIDialog';
import BarGraph from "../components/BarGraph";
import {AddButton, DropDownMenu, border1, contentPadding} from "omse-components";
import StatsTimestamp from '../components/StatsTimestamp';
import BackLink from '../components/BackLink';
import {generateTitle} from '../util/titles';
import ProjectMode from "../components/ProjectMode";
import {USER_PREMIUM_DATA_PLAN, USER_PSGA_PLAN} from '../../shared/plans';
import {
    OPEN,
    PREMIUM_FREE,
    PREMIUM_CHARGEABLE,
    statsLabels,
    statsMessages,
    PSGA,
    INTERNAL,
    PREMIUM_FREE_SECONDARY, COMMERCIAL
} from '../components/barGraph/styles/graph.js';
import {hasManageProjectsPermission} from "../util/permissions";
import {CircularProgress} from '@mui/material';
import {withOrganisationMessage} from '../util/organisation';
import {setOrgFromSearch} from '../modules/organisation/action';
import {keyBy} from 'lodash';
import classNames from 'classnames';
import {EAIAPI} from '../../shared/features';
import featureCheck from "../util/featureCheck";
import { isEaiUser } from "../util/plans";

const messages = defineMessages({
    projectLink: {
        id: 'APIProject.projectLink',
        defaultMessage: 'All projects',
        description: 'Label for the link that navigates back to the project list'
    },
    orgProjectLink: {
        id: 'APIProject.orgProjectLink',
        defaultMessage: '{org} projects',
        description: 'Label for the link when an org is selected'
    },
    personalLink: {
        id: 'APIProject.personalLink',
        defaultMessage: 'My Personal projects',
        description: 'Label for the link when an org is personal'
    },
    actions: {
        id: 'APIProject.actions',
        defaultMessage: 'Actions',
        description: 'Label for actions drop-down menu'
    },
    deleteProject: {
        id: 'APIProject.delete',
        defaultMessage: 'Delete this project',
        description: 'Label for the Delete this project option'
    },
    regenerateApiKey: {
        id: 'APIProject.regenerateKey',
        defaultMessage: 'Regenerate API Key',
        description: 'Label for the regenerate API key option'
    },
    addAPIButton: {
        id: 'APIProject.addAPIButton',
        defaultMessage: 'Add API',
        description: 'Add API button text'
    },
    addAPILabel: {
        id: 'APIProject.addAPILabel',
        defaultMessage: 'Add API to this project',
        description: 'Add API to project title'
    }
});

function styles(theme) {
    return {
        root: {
            paddingTop: contentPadding.top - contentPadding.backLink,
            paddingRight: contentPadding.right,
            paddingBottom: theme.spacing(6),
            paddingLeft: contentPadding.left,
            borderBottom: border1,
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile
            }
        },
        products: {
            flex: '1 1 auto',
            paddingLeft: contentPadding.left,
            paddingRight: contentPadding.right,
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile,
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        heading: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            // Similar to the overall max, but a bit bigger to give the buttons some room
            maxWidth: contentPadding.maxWidth + contentPadding.left * 3
        },
        headerWidth: {
            maxWidth: contentPadding.maxWidth
        },
        marginLeft: {
            marginLeft: theme.spacing(2)
        },
        actions: {
            display: 'flex',
            flex: 'none'
        },
        projectMetadata: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: theme.spacing(2),
            '& > div': {
                flex: 'none',
                alignItems: 'center',
                marginBottom: 0,
                marginRight: theme.spacing(0.5)
            },
            '& > div:last-of-type': {
                marginRight: 0
            }
        },
        statsTimestamp: {
            height: theme.spacing(4.5),
            padding: '6px 0',
            '& > p': {
                margin: 0
            }
        },
        loader: {
            margin: theme.spacing(6) + ' 0'
        }
    };
}

export class APIProject extends Component {
    state = {
        deleting: false,
        regenerate: false,
        adding: false
    };

    confirmationAction = () => {
        this.props.regenerateKey(this.props.project.id);
        this.setState({regenerate: false})
    };

    componentDidMount() {
        const {getApiPlans, userDetails, match, getProject, getProjectStats, getProducts, setOrgFromSearch, vatRate, getPaymentConfigData, config} = this.props;
        const projectId = match.params.projectId;
        setOrgFromSearch();
        getProject(projectId);
        getProjectStats(projectId);
        getProducts();

        if(featureCheck(EAIAPI, config) && isEaiUser(userDetails)){
            getApiPlans();
        }

        if (!vatRate) {
            getPaymentConfigData();
        }
    }

    componentDidUpdate(prevProps) {
        const {projectError, history, location, project, loading} = this.props;
        if(projectError) {
            // Display a 404 page bespoke to an incorrect project name
            const newLocation = getLocation(routes.error404Projects, location);
            history.push(newLocation);
        }

        // Update title after project has loaded or the project name changes. Title updates are usually handled in
        // App.js using history.listen but it's called here because the content is dynamically loaded.
        if (project && project.name && ((prevProps.loading && !loading) || !prevProps.project || (prevProps.project && prevProps.project.name !== project.name))) {
            const titledLocation = {...location, state: {title: project.name}};
            document.title = generateTitle(titledLocation);
        }
    }

    productPageState() {
        const { stats, project, products, loading } = this.props;
        const statsResult = stats.result;

        if(loading) {
            return null;
        }
        if(project && project.products && project.products.length && products) {
            const productsByName = keyBy(products, 'name');
            return project.products.map(product => {
                // Merge info from /products call into our project product objects.
                // product.id from /projects === product.name from /products, so ensure we don't overwrite those bits.
                const {name, displayName, ...otherProductProps} = productsByName[product.id];
                Object.assign(product, otherProductProps);
                let productStats = -1;
                let projectTotalUsage = undefined;
                if (statsResult && statsResult.timestamp) {
                    projectTotalUsage = statsResult.open + statsResult.premium.total.transactions + statsResult.psga + statsResult.internal;
                    productStats = statsResult.products[product.id];
                }
                return <Product key={product.id}
                                projectId={project.id}
                                projectMode={project.mode}
                                product={product}
                                productStats={productStats}
                                projectTotalUsage={projectTotalUsage}/>}
            );
        } else {
            return <EmptyProject addAPIAction={() => this.setState({adding: true})} />;
        }
    }

    deleteConfirmed = () => {
        const {deleteProject, project} = this.props;
        deleteProject(project.id);
    };

    displayProjectStats() {
        const {stats, userDetails, vatRate} = this.props;
        const statsResult = stats.result;

        if (statsResult && statsResult.timestamp) {
            let totalUsage = 0;

            let totalCost;
            if (vatRate && !isNaN(vatRate)) {
                totalCost = statsResult.premium.total.price * (1 + vatRate);
            }

            let breakdown = [];

            const allDataSets = {
                open: {
                    name: statsLabels.open,
                    data: statsResult.open,
                    class: OPEN
                },
                premiumFree: {
                    name: statsLabels.premium.free,
                    data: statsResult.premium.free,
                    class: PREMIUM_FREE
                },
                premiumChargeable: {
                    name: statsLabels.premium.chargeable,
                    data: statsResult.premium.chargeable,
                    class: PREMIUM_CHARGEABLE
                },
                psga: {
                    name: statsLabels.psga,
                    data: statsResult.psga,
                    class: PSGA
                },
                commercial: {
                    name: statsLabels.commercial.live,
                    data: statsResult.commercial,
                    class: COMMERCIAL
                },
                internal: {
                    name: statsLabels.internal,
                    data: statsResult.internal,
                    class: INTERNAL
                }
            }

            for (let dataType in allDataSets) {
                totalUsage += allDataSets[dataType].data;
                breakdown.push(allDataSets[dataType]);
            }

            if (userDetails.plan === USER_PSGA_PLAN) {
                allDataSets.premiumFree.class = PREMIUM_FREE_SECONDARY;
            }

            const graphData = [{total: totalUsage, breakdown:breakdown}];

            return <BarGraph graphData={graphData}
                        total={totalUsage}
                        count={totalUsage}
                        countLabel={{...statsMessages.countLabel}}
                        costInPence={totalCost}
                        costLabel={{...statsMessages.costLabel}}
                        graphStyle={{height: 22, marginTop: 4, marginBottom: 0}}
                        displayCountLabel={true}
                        displayCount={true}
                        displayKey={true} 
                        displayCost={userDetails.plan === USER_PREMIUM_DATA_PLAN}
                    />
        }
    };

    render() {
        const {location, project, classes, stats, products, userDetails, loading, org, apiPlans} = this.props;
        const {deleting, regenerate, adding} = this.state;

        const name = (project && project.name) ||
            (location.state && location.state.projectName);

        const projectActions = [
            { label: messages.deleteProject, action: () => this.setState({deleting: true}) }
        ];

        if (project && project.products && project.products.length > 0) {
            projectActions.push( { label: messages.regenerateApiKey, action: () => this.setState({regenerate: true}) })
        }

        const linkLabel = withOrganisationMessage(messages.projectLink, messages.orgProjectLink, messages.personalLink, userDetails, org);

        return <>
            <BackLink path={routes.projects} label={linkLabel}/>
            <div className={classes.root} data-testid="apiproject">
                <header className={classes.heading}>
                    <ProjectName name={name}/>
                    {project && hasManageProjectsPermission(userDetails) &&
                        <div className={classes.actions}>
                            <DropDownMenu buttonLabel={messages.actions}
                                buttonVariant='outlined'
                                buttonColor='primary'
                                variant='block'
                                placement='bottom-end'
                                items={projectActions}
                                buttonClasses={{root: classes.marginLeft}}
                                staticButtonText
                            />
                            <AddButton classes={{button: classes.marginLeft}} action={() => this.setState({adding: true})}
                                label={messages.addAPIButton} />
                        </div>
                    }
                </header>
                <div className={classNames(classes.projectMetadata, classes.headerWidth)}>
                    <ProjectMode project={project} allowChange={true} plans={apiPlans}/>
                    <StatsTimestamp stats={stats} classes={{root: classes.statsTimestamp}} />
                </div>
                <div className={classes.headerWidth}>
                    {
                        this.displayProjectStats()
                    }
                    {
                        project && project.products && project.products.length > 0 && <APIKey project={project} />
                    }
                </div>
            </div>
            <div className={classes.products}> 
                {loading?
                    <CircularProgress size={32} className={classes.loader} />
                :
                    <>{this.productPageState()}</>
                }
            </div>
            { deleting && <DeleteProjectDialog closed={() => this.setState({deleting: false})}
                                                 confirmed={this.deleteConfirmed}/>
            }

            {regenerate && <RegenerateKeyDialog closed={() => this.setState({regenerate: false})}
                                                   confirmed={this.confirmationAction}/>
            }
            {adding &&
                <AddAPIDialog title={messages.addAPILabel}
                                    confirmationAction={() => this.setState({adding: false})}
                                    open={true}
                                    handleClose={() => this.setState({adding: false})}
                                    products={products}
                                    project={project}
                                    apiPlans={apiPlans}
                />
            }

        </>;
    }
}

APIProject.propTypes = {
    getProject: PropTypes.func.isRequired,
    deleteProject: PropTypes.func.isRequired,
    regenerateKey: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    project: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.object),
    stats: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    getPaymentConfigData: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
    const userDetails = state.user.current.result;
    const { match } = ownProps;
    let { result, error } = state.project.current;
    if(result && match.params && (result.id !== match.params.projectId)) {
        result = null;
    }
    let stats = state.stats.project;
    const org = state.organisation.current;

    const paymentsConfigData = state.payments.config.result;
    const vatRate = paymentsConfigData && paymentsConfigData.vatRate;

    return {
        project: result,
        projectError: error,
        loading: state.project.current.loading || state.products.current.loading,
        stats,
        products: state.products.current.result,
        userDetails,
        org,
        vatRate,
        apiPlans: state.commercialApiPlans.plans.result,
        config: state.config.current.result
    }
}

const styled = withStyles(styles)(APIProject);
const connected = connect(mapStateToProps, {getApiPlans, getProject, deleteProject, regenerateKey, getProjectStats, getProducts, setOrgFromSearch, getPaymentConfigData})(styled);
export default withRouter(connected);
