import {createUseStyles} from 'react-jss';
import {contentPadding} from 'omse-components';

const recipeLibraryStyles = createUseStyles(theme => ({
    recipeList: {
        margin: theme.spacing(4) + ' 0 auto 0',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
        }
    },
    noResultsContent:{
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile
        }
    },
    contentCenter:{
        paddingTop: theme.spacing(3),
        flex: '1 0 auto',
        textAlign: 'center',
        margin: '0 auto',
        maxWidth: 530
    },
    contentLeft:{
        paddingTop: theme.spacing(3)
    },
    paddedButton:{
        marginTop: theme.spacing(3)
    },
    emptyListMessage:{
        marginTop: theme.spacing(4)
    },
    headingBottomMargin:{
        marginBottom: theme.spacing(2)
    },
    noRecipes: {
        textAlign: 'center',
    },
    searchBox: {
        display: 'flex',
        flex: '1 0 416px',
        flexDirection: 'column',
        [theme.breakpoints.down(650)]: {
            flex: '1 0 auto'
        }
    },
    loadingSpinner: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(2)
        }
    },
    error: {
        marginBottom: theme.spacing(5)
    },
    headerPreamble: {
        marginTop: theme.spacing(3),
        maxWidth: '40em'
    },
    tooltip: {
        float: 'unset',
        marginLeft: theme.spacing(1),
        display: 'inline-flex',
        '& button': {
            top: 5,
            minWidth: '24px',
            padding: '0px !important'
        },
        '& button > span': {
            display: 'flex'
        },
    },
    paper: {
        display: 'block',
        maxWidth: 'inherit',
        '& p': {
            width: '20em'
        }
    },
    tooltipIcon: {
        color: theme.palette.primary.main
    },
    optionRow: {
        display: "flex",
        flexFlow: 'row wrap',
        gap: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            margin: contentPadding.mobile,
            marginBottom: theme.spacing(1),
            flexDirection: 'column'
        }
    },
    select: {
        flex: '1 1',
        display: 'flex',
        flexDirection: "column",
        minWidth: 310
    },
    resetFilters:{
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(2)
        }
    },
    dateSelectors: {
        alignItems: 'flex-end',
        flex: '1 1 auto',
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    }
}));

export default recipeLibraryStyles
