import React from 'react';
import Header from "../Header";
import Content from '../Content';
import Typography from '@mui/material/Typography';
import logoExample1 from './brandLogoImg/OS_guidelines_API-logo-example-1@2x.png';
import logoExample2 from './brandLogoImg/OS_guidelines_API-logo-example-2@2x.png';
import logoExample3 from './brandLogoImg/OS_guidelines_API-logo-example-3@2x.png';
import withStyles from 'react-jss';
import BrandImage from './BrandImage';
import { styles } from './BrandStyling';
import {defineMessages, FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Document from '../Document';

const messages = defineMessages({
    paragraph: {
        id: 'LogoFullColour.paragraph',
        defaultMessage: 'Our coloured API logo is to be used on any illustrated map as shown below.',
        description: 'Colour API logo'
    }
})

function LogoFullColour(props) {

    const {classes} = props;
    const containerClasses = [classes.mainContainer, classes.threeItems];
    return (
        
        <Document>
            <Header back={true}>Logo overview - full colour</Header>

            <Content>
                <Typography variant='body1' paragraph={true} component='div' color='textPrimary'>
                    <FormattedMessage {...messages.paragraph} />
                </Typography>

                <div className={classNames(containerClasses)}>
                        <BrandImage 
                            image={logoExample1} 
                            alt='Example of full-colour Ordnance Survey logo on OS medium-scale cartograhic map'
                        />

                        <BrandImage 
                            image={logoExample2} 
                            alt='Example of full-colour Ordnance Survey logo on OS large-scale road map'
                        />

                        <BrandImage 
                            image={logoExample3} 
                            alt='Example of full-colour Ordnance Survey logo on OS medium-scale tourist map'
                        />
                </div>
            </Content>
        </Document>
    )
}

LogoFullColour.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(LogoFullColour)