import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import {osColour} from 'omse-components';
import online1 from './agol/Online-1.png'
import online2 from './agol/Online-2.png'
import online3 from './agol/Online-3.png'
import online4 from './agol/Online-4.png'
import online5 from './agol/Online-5.png'
import online6 from './agol/Online-6.png'
import online7 from './agol/Online-7.png'
import online8 from './agol/Online-8.png'
import WFSCompletionSummary from './WFSCompletionSummary';
import PropTypes from 'prop-types';
import InlineCodeSnippet from '../../../components/InlineCodeSnippet';

const styles = {
    guide: {
        '& img': {
            border: `solid ${osColour.neutral.mist} 1px`
        }
    }
};

export function ArcGISOnline(props) {
    const {classes} = props;
    return <Fragment>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    To follow this guide you will need access to the ArcGIS Online service.
                </p>
            </Typography>
            <Typography variant='h2'>Integrating OS Features API in ArcGIS Online</Typography>
            <Typography variant='body1' paragraph={true} component='div' className={classes.guide}>
                <ol>
                    <li>
                        <p>Login to ArcGIS Online. On the homepage click 'Map'.
                        </p>
                        <p>
                            You will see an online map which could differ from the one shown according to your account settings.
                        </p>
                        <img src={online1} alt="ArcGIS Online showing initial map" />
                    </li>
                    <li>
                        <p>Select ‘Add’ and then ‘Add Layer from Web’.</p>
                        <img src={online2} alt="‘Add’ menu showing the ‘Add Layer from Web’ option" />
                    </li>
                    <li>
                        <p>In the next box, select from the drop down ‘A WFS OGC web service’.</p>
                        <p>Enter the URL for the OS Features API without the API key into the appropriate URL box.</p>
                        <img src={online3} alt="'Add Layer from Web' dialog" />
                    </li>
                    <li>
                        <p>Click 'Add Custom Parameters'.</p>
                    </li>
                    <li>
                        <p>In the parameters box, click 'Add Parameter', enter <InlineCodeSnippet>key</InlineCodeSnippet> into the Parameter column and the actual OS Features API key into the Value column.</p>
                        <img src={online4} alt="'Add Layer from Web' dialog with 'key' parameter being entered" width="729" />
                    </li>
                    <li>
                        <p>The ‘Get Layers’ button will become active. Click this button and then a drop-down list of layers available from the OS Features service will appear.</p>
                        <img src={online5} alt="'Add Layer from Web' showing Layers options" />
                    </li>
                    <li>
                        <p>Select the layer you would like to show and click ‘Add Layer’.</p>
                    </li>
                    <li>
                        <p>The selected layer will now appear on top of the Base Map. Please note that in this case, the Coordinate Reference System does not need to be changed because the OS Features service is serving the data in the same projection as the base mapping from ESRI which is Web Mercator.</p>
                        <img src={online6} alt="Base Map displaying selected layer" />
                    </li>
                    <li>
                        <p>You can style, query and inspect WFS data in the same way as other vector data.</p>
                        <img src={online7} alt="Layer options for 'UrbanAreas'" />
                        <img src={online8} alt="'UrbanAreas' vector data attribution list" />
                    </li>
                </ol>
            </Typography>
            <WFSCompletionSummary software="ArcGIS Online" />
    </Fragment>
}

ArcGISOnline.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ArcGISOnline);