export const PREFIX = 'action:terms:';
export const OPEN_TERMS_ACTION = PREFIX + 'open';
export const CLEAR_TERMS_ACTION = PREFIX + 'clear';

export function openTerms(terms, onAccept) {
    return {
        type: OPEN_TERMS_ACTION,
        terms,
        onAccept
    }
}

export function clearTerms(terms) {
    return {
        type: CLEAR_TERMS_ACTION,
        terms
    }
}
