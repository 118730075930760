import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {apiTermsName} from '../../../../shared/constants';
import PropTypes from 'prop-types';

const apiMessages = defineMessages({
    start: { 
        id: 'DetailedAcceptTermsIntro.start',
        defaultMessage: 'Please accept our API terms and conditions.',
        description: 'Start prompt'
    },
    instructions: {
        id: 'DetailedAcceptTermsIntro.instructions',
        defaultMessage: 'If you decline the API terms and conditions, you can still use the Data Hub but you will not have access to our APIs.  You will have another opportunity to accept these terms if you try to add an API to a project.',
        description: 'Instructions'
    }
});

export default function DetailedAcceptTermsIntro({variant}) {
    let messages;
    switch(variant) {
        default:
            messages = apiMessages;
    }
    return <>
        <p><FormattedMessage {...messages.start} /></p>
        <p><FormattedMessage {...messages.instructions} /></p>
    </>;
}

DetailedAcceptTermsIntro.propTypes = {
    variant: PropTypes.oneOf([apiTermsName])
}

DetailedAcceptTermsIntro.defaultProps = {
    variant: apiTermsName
}