import React, {Fragment, useState} from "react";
import {TextField, InputAdornment} from "@mui/material";
import { defineMessages, useIntl } from "react-intl";
import Autocomplete from '@mui/material/Autocomplete';
import {createUseStyles} from 'react-jss';
import classname from "classnames";
import {osColour} from "omse-components";

const messages = defineMessages({
    placeholder: {
        id: "ShareRecipeSearchBox.placeholder",
        defaultMessage: "Type an organisation name to select from the list",
        description: "Placeholder"
    },
    label: {
        id: "ShareRecipeSearchBox.label",
        defaultMessage: "Type an organisation name to select from the list",
        description: "search box label"
    },
    noResult: {
        id: "ShareRecipeSearchBox.noResult",
        defaultMessage: "No results, please try again",
        description: "Description No results, please try again"
    },
    enterMoreCharacters: {
        id: "ShareRecipeSearchBox.enterMoreCharacters",
        defaultMessage: "Please enter at least two characters",
        description: "Description No results, please try again"
    },
    loading: {
        id: "ShareRecipeSearchBox.loading",
        defaultMessage: "Loading...",
        description: "Message to display while search results are loading"
    },

});

const styles = createUseStyles(theme => ({
    autocompleteInputRoot: {
        display: "flex",
        border: `solid ${osColour.neutral.stone} 1px`,
        borderRadius: 3,
        background: "white",
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 4,
        "& input:first-child": {
            padding: "6px 10px !important"
        }
    },
    autocompletePaper: {
        boxShadow: "0 1px 7px 1px rgba(0, 0, 0, 0.4)",
        textTransform: "capitalise"
    },
    autocompleteEndAdornment: {
        right: theme.spacing(1),
        "& svg": {
            color: osColour.status.success
        }
    },
    inputAdornmentEnd: {
        marginRight: theme.spacing(1)
    },
    option: {
        padding: theme.spacing(1.5),
        borderBottom: `1px solid ${osColour.neutral.mist}`
    },
    listBox: {
        padding: 0
    },
    focused: {
        border: `solid 1px ${osColour.status.success}`
    }
}));

export function ShareRecipeSearchBox(props) {
    const { onInputChange, onReset, onValueChange, options, minInput, loading} = props;
    const [inputValue, setInputValue] = useState('');

    const classes = styles();
    const intl = useIntl();
    const noResult = [intl.formatMessage(messages.noResult)];
    const toFewCharacters = [intl.formatMessage(messages.enterMoreCharacters)];

    function inputChange(event, value) {
        if (options) {
            if(value.length >= minInput) {
                onInputChange(value);
            }else{
                onReset();
            }
        }
        setInputValue(value);
    }

    function autocompleteChange(event, value, reason) {
        if (reason === "selectOption" && value) {
            onValueChange(value);
            onInputChange(value);
        }else if (reason === "clear") {
            onValueChange(null);
        }
    }

    // change focus of text border when it has a value
    let inputRootClass = classes.autocompleteInputRoot;
    if (inputValue) {
        inputRootClass = classname(classes.autocompleteInputRoot, classes.focused);
    }

    // TextField for the AutoComplete component
    const renderedTextField = params => {
        return (
            <TextField
                {...params}
                id={"orgSearch"}
                placeholder={intl.formatMessage(messages.placeholder)}
                variant='standard'
                inputProps={{
                    ...params.inputProps,
                    'aria-label':intl.formatMessage(messages.label)
                }}
                InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment
                            position={"end"}
                            classes={{
                                positionEnd: classes.inputAdornmentEnd
                            }}
                        >
                            {params.InputProps.endAdornment}
                        </InputAdornment>
                    )
                }}
            />
        );
    };

    let optionsToShow = options;
    if(inputValue.length < minInput){
        optionsToShow = toFewCharacters;
    }else if(options.length === 0 && !loading){
        optionsToShow = noResult;
    }

    // TextField for the AutoComplete component
    const renderedAutoCompleteField = () => {
        return (
            <Autocomplete
                freeSolo
                clearOnEscape
                handleHomeEndKeys
                clearOnBlur
                selectOnFocus
                fullWidth={true}
                autoHighlight={true}
                openOnFocus={true}
                getOptionLabel={options => options ? options : ''}
                getOptionDisabled={option => option === noResult[0] || option === toFewCharacters[0]}
                options={optionsToShow}
                loading={loading}
                loadingText={intl.formatMessage(messages.loading)}
                onChange={autocompleteChange}
                onInputChange={inputChange}
                filterOptions={options => options}
                renderInput={params => renderedTextField(params)}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                role={'alert'}
                classes={{
                    inputRoot: inputRootClass,
                    paper: classes.autocompletePaper,
                    endAdornment: classes.autocompleteEndAdornment,
                    option: classes.option,
                    listbox: classes.listBox
                }}
            />
        );
    };

    let classificationComponent = "";
    let errorTextInfo = "";
    classificationComponent = renderedAutoCompleteField();

    return (
        <Fragment>
            {classificationComponent}
            {errorTextInfo}
        </Fragment>
    );
}

export default ShareRecipeSearchBox;