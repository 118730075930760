import {defineMessages} from "react-intl";

const messages = defineMessages({
    AndButton: {
        id: 'queryBuilderLabelMaker.AndButton',
        defaultMessage: 'And Conjunction for {group} {active}',
        description: 'And Conjunction Label'
    },
    OrButton: {
        id: 'queryBuilderLabelMaker.OrButton',
        defaultMessage: 'Or Conjunction for {group} {active}',
        description: 'Or Conjunction Label'
    },
    AddRule: {
        id: 'queryBuilderLabelMaker.AddRule',
        defaultMessage: 'Add Rule to {group}',
        description: 'Add Rule Button Label'
    },
    AddGroup: {
        id: 'queryBuilderLabelMaker.AddGroup',
        defaultMessage: 'Add Sub-Group to {group}',
        description: 'Add Group Button Label'
    },
    DeleteGroup: {
        id: 'queryBuilderLabelMaker.DeleteGroup',
        defaultMessage: 'Delete {group}',
        description: 'Delete Group Button Label'
    },
    DeleteRule: {
        id: 'queryBuilderLabelMaker.DeleteRule',
        defaultMessage: 'Delete {group} {rule}',
        description: 'Delete Rule Button Label'
    },
    RuleField: {
        id: 'queryBuilderLabelMaker.RuleField',
        defaultMessage: 'Field for {rule} in {group}',
        description: 'Rule Field Label'
    },
    RuleOperator: {
        id: 'queryBuilderLabelMaker.RuleOperator',
        defaultMessage: 'Operator for {rule} in {group}',
        description: 'Rule Field Label'
    },
    RuleValue: {
        id: 'queryBuilderLabelMaker.RuleValue',
        defaultMessage: 'Value for {rule} in {group}',
        description: 'Rule Field Label'
    },
    RuleBetweenFromValue: {
        id: 'queryBuilderLabelMaker.RuleBetweenFromValue',
        defaultMessage: 'Between from value for {rule} in {group}',
        description: 'Rule Field Label'
    },
    RuleBetweenToValue: {
        id: 'queryBuilderLabelMaker.RuleBetweenToValue',
        defaultMessage: 'Between to value for {rule} in {group}',
        description: 'Rule Field Label'
    },
    Group: {
        id: 'queryBuilderLabelMaker.Group',
        defaultMessage: 'Main Group',
        description: 'Translatable word for group'
    },
    SubGroup: {
        id: 'queryBuilderLabelMaker.SubGroup',
        defaultMessage: 'Sub-Group {number}',
        description: 'Translatable word for SubGroup'
    },
    Rule: {
        id: 'queryBuilderLabelMaker.Rule',
        defaultMessage: 'Rule {number}',
        description: 'Translatable word for rule'
    },
    Inactive: {
        id: 'queryBuilderLabelMaker.Inactive',
        defaultMessage: 'Inactive',
        description: 'Translatable word for Inactive'
    },
    Active: {
        id: 'queryBuilderLabelMaker.Active',
        defaultMessage: 'Active',
        description: 'Translatable word for Active'
    }
});

// Get a child node from array of nest positions and element reference
function getNode(ref, position){
    let newRef = ref;
    try {
        position.forEach(pos => newRef = newRef.childNodes[pos]);
    }catch(error){
        return undefined;
    }
    return newRef;
}

// Gets the main group from the querybuilder root reference
function getMainGroup(ref) {   return getNode(ref, [0,0,0]);  }

// Maps of buttons/fields locations and corresponding label translations
const topGroupActionMap = {
    'AddRuleButton'     :   { selector : (ref) => getNode(ref, [0,1,0]), intlKey: 'AddRule' },
    'AddGroupButton'    :   { selector : (ref) => getNode(ref, [0,1,1]), intlKey: 'AddGroup' },
}
const conjunctionMap = {
    'And'               :   { selector :(ref) => getNode(ref, [0,0,0,0,0]), intlKey: 'AndButton' },
    'Or'                :   { selector :(ref) => getNode(ref, [0,0,0,0,1]), intlKey: 'OrButton' },
}
const subGroupActionMap = {
    'AddRuleButton'     :   { selector : (ref) => getNode(ref, [0,1,0]), intlKey: 'AddRule' },
    'DeleteGroupButton' :   { selector : (ref) => getNode(ref, [0,1,1]), intlKey: 'DeleteGroup' },
}
const ruleElementMap = {
    'delete' : { selector :(ref) => getNode(ref, [0,1,0]), intlKey: 'DeleteRule' },
    'field' : { selector :(ref) => getNode(ref, [0,0,0,0,0,0,0,0,0]), intlKey: 'RuleField' },
    'operator' : { selector :(ref) => getNode(ref, [0,0,0,1,0,0]), intlKey: 'RuleOperator' },
    'operatorInput' : { selector :(ref) => getNode(ref, [0,0,0,1,0,0,1]), intlKey: 'RuleOperator' },
    'value' : { selector :(ref) => getNode(ref, [0,0,0,2,0,0,0,0,0,0]), intlKey: 'RuleValue' },
    'betweenValue':{ selector :(ref) => getNode(ref, [0,0,0,2,0,2,0,0,0,0]), intlKey: 'RuleBetweenToValue' }
};
const invalueSelector = (ref) => getNode(ref, [0,0,0,2,0,0,0,0]);
const invalueSelectorInput = (ref) => getNode(ref, [0,0,0,2,0,0,0,0,1]);

function addLabel(ref, label) {
    if (ref && ref.setAttribute){
        ref.setAttribute('aria-label', label);
        return true;
    }
    return false;
}

function hasRuleOrGroup(groupRef) {
    // Check if a given Group has any groups or rules
    const parent = getNode(groupRef, [1]);
    return parent && parent.childNodes.length > 1;
}

function labelGroupHeadings(groupRef, isSubGroup, groupName, intl){
    // The main group and subgroups have slightly different button mapping
    // In the future if the config is changed this section may need to be revised
    const actionMap = !isSubGroup ? topGroupActionMap : subGroupActionMap;
    // Populate Add Rule/Group Labels
    Object.values(actionMap).forEach((v) => {
        const action = v.selector(groupRef);
        const labelText = intl.formatMessage(messages[v.intlKey], {group : groupName})
        addLabel(action, labelText);
    });
    if(hasRuleOrGroup(groupRef)){
        // Populate And/Or Conjunction Labels if applicable
        Object.values(conjunctionMap).forEach(v => {
            const conjunction = v.selector(groupRef);
            const activeLabel = conjunction.className.includes('MuiButton-containedPrimary') ? intl.formatMessage(messages.Active) : intl.formatMessage(messages.Inactive);
            const label = intl.formatMessage(messages[v.intlKey], {group: groupName, active: activeLabel});
            addLabel(conjunction, label);
        });
    }
}

function labelRulesAndSubGroups(groupRef, groupName, intl){
    const groupChildContainer = getNode(groupRef, [1]);
    if(groupChildContainer && groupChildContainer.childNodes){
        const subGroups = [].filter.call(groupChildContainer.childNodes, groupOrRule => getNode(groupOrRule, [0,0]).className.includes('group--header'));
        subGroups.forEach((subGroup, i) => {
            const subGroupRef = getNode(subGroup, [0]);
            const subGroupName = intl.formatMessage(messages.SubGroup, {number: i+1});
            // Label the subgroup heading buttons (And/Or and AddRule/AddGroup)
            labelGroupHeadings(subGroupRef, true, subGroupName, intl);
            // Recurse through further subgroups and label appropriately
            // Note : at the moment we don't support more than one nested group
            // in the query builder config however if we did this code will handel
            // that scenario
            labelRulesAndSubGroups(subGroupRef, subGroupName, intl);
        });

        const rules = [].filter.call(groupChildContainer.childNodes,groupOrRule => !getNode(groupOrRule, [0,0]).className.includes('group--header'));
        rules.forEach((rule, i) => {
            // Add labels for all input fields and the delete button
            labelRule(rule, groupName, i + 1, intl);
        });
    }
}

function labelRule(ruleRef, groupName, i, intl){
    const ruleLabel = intl.formatMessage(messages.Rule, {number: i});
    // Label the Fields and buttons for a given rule
    for (const [k, v] of Object.entries(ruleElementMap)) {
        let ref = v.selector(ruleRef);
        // some elements may not be present until a certain stage in the filter, skip if they are present yet.
        if(ref){
            const intlKey = (k === 'value') && ruleElementMap['betweenValue'].selector(ruleRef) ? 'RuleBetweenFromValue' : v.intlKey;
            const label = intl.formatMessage(messages[intlKey], {rule: ruleLabel, group:groupName});
            // If there is a value but it's not lableable we are most likely in the "IN" edge case
            if(!addLabel(ref, label) && k === 'value'){
                // So label this awkward value with a slightly different selector
                addLabel(invalueSelector(ruleRef), label);
                addLabel(invalueSelectorInput(ruleRef), label)
            }
        }
    }
}

export default function queryBuilderLabelMaker(queryBuilderRef, intl) {
    const mainGroup = getMainGroup(queryBuilderRef);
    const mainGroupName = intl.formatMessage(messages.Group);
    // Label the main groups heading buttons (And/Or and AddRule/AddGroup)
    labelGroupHeadings(mainGroup, false, mainGroupName, intl);
    // Loop through rules and subgroups
    labelRulesAndSubGroups(mainGroup,  mainGroupName, intl);
}