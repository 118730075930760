import React from 'react';
import Header from "../Header";
import Content from '../Content';
import logoExample from './brandLogoImg/OS_guidelines_API-copyright-logo-example@2x.png';
import logoMobile from './brandLogoImg/OS_guidelines_API-copyright-mobile@2x.png';
import withStyles from 'react-jss';
import BrandImage from './BrandImage';
import { styles } from './BrandStyling';
import {defineMessages} from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Document from '../Document';

const messages = defineMessages({
    logoExampleTitle: {
        id: 'LogoCopyrightPositioning.logoExampleTitle',
        defaultMessage: 'Logo',
        description: 'Logo'
    },
    logoExampleDescription: {
        id: 'LogoCopyrightPositioning.logoExampleDescription',
        defaultMessage: 'Our logo should be positioned in the embedded map window across all your digital applications. This positioning should also be followed on a web and mobile application.',
        description: 'Logo positioning'
    },

    logoMobileTitle: {
        id: 'LogoCopyrightPositioning.logoMobileTitle',
        defaultMessage: 'Copyright statement',
        description: 'Copyright statement'
    },
    logoMobileDescription: {
        id: 'LogoCopyrightPositioning.logoMobileDescription',
        defaultMessage: 'Our copyright statement, terms and conditions and error reporting link should be positioned within the the embedded map across your web applications. This information should still be present within your mobile applications however this should sit within your menu bar, rather than on the map itself, to ensure that the limited view is not obscured.',
        description: 'Copyright statement positioning'
    },
});

function LogoCopyrightPositioning(props) {

    const {classes} = props;
    const containerClasses = [classes.mainContainer, classes.twoItems];

    return (
        
        <Document>
            <Header back={true}>Logo and copyright positioning</Header>
            <Content>
                <div className={classNames(containerClasses)}>
                        <BrandImage 
                            image={logoExample} 
                            alt='Ordnance Survey full-colour logo positioned in the bottom-left of a desktop web map' 
                            title={messages.logoExampleTitle}
                            description={messages.logoExampleDescription}
                            line={true}
                        />
                        <BrandImage 
                            image={logoMobile} 
                            alt='Ordnance Survey white-fill logo positioned in the bottom-left of a mobile map with satellite imagery' 
                            title={messages.logoMobileTitle}
                            description={messages.logoMobileDescription}
                            line={true}
                        />
                </div>
            </Content>
        </Document>
    )
}

LogoCopyrightPositioning.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(LogoCopyrightPositioning)