import {drawingLayerStyle, tileLayerStyle, imageryTileLayerStyle} from './style'
import {
    toGeoJsonFeature,
    toOLFeature,
    toOLGeometry,
    multiPolygonToPolygons,
    polygonsToMultiPolygon,
    removeInternalCoords, getNumberOfCoords
} from "./feature";
import {getCoord} from './gridRef';

import {defineMessages} from 'react-intl';

import VectorSource from 'ol/source/Vector';
import { arcgisToGeoJSON } from '@esri/arcgis-to-geojson-utils';
import Feature from 'ol/Feature';
import Polygon, {fromExtent} from 'ol/geom/Polygon';
import {Vector} from 'ol/layer';

import intersect from '@turf/intersect';
import union from '@turf/union';
import difference from '@turf/difference';
import lineOverlap from '@turf/line-overlap';

const tileGridErrorText = 'There has been a problem loading the tile grid. Please try again later.';

const MAX_NO_OF_COORDS = 50000;
const MAX_NO_OF_FEATURES = 2000;

const messages = defineMessages({
    maximumCoordsError: {
        id: 'layer.maximumCoordsError',
        defaultMessage: "{max} vertices exceeded",
        description: 'Error message when too many coords loaded'
    },
    maximumFeaturesError: {
        id: 'layer.maximumFeaturesError',
        defaultMessage: "{max} polygons exceeded",
        description: 'Error message when too many features loaded'
    },
    invalidGeometry: {
        id: 'layer.invalidGeometry',
        defaultMessage: "The geometry is not valid",
        description: 'Error message when the geometry is not valid'
    }
});

export function createTileLayer(name, url, handleError) {
    function convertToOLFeature(f) {
        f.geometry.spatialReference = {};
        let geom = arcgisToGeoJSON(f.geometry);
        let feature =  new Feature();
        feature.setId(f.attributes.AREA_ID);
        feature.set('geometry', new Polygon(geom.coordinates), true);
        return feature;
    }

    let tileSource = new VectorSource({
        loader: async function (extent, resolution, projection, onSuccess, onFailure) {
            fetch(url.replace("{extent}", extent.join(","))).then(res => res.json())
                .then(response => {
                    if (response && response.features) {
                        let features = response.features.map(convertToOLFeature);
                        if (features.length > 0) {
                            tileSource.addFeatures(features);
                        }
                        onSuccess(features);
                    }
                }, error => {
                    handleError(tileGridErrorText);
                    onFailure();
                });
        },

        strategy: function() { return[[0, 0, 400000, 400000], [400000, 0, 700000, 400000],
            [0, 400000, 400000, 800000], [400000, 400000, 700000, 800000],
            [0, 800000, 400000, 1300000], [400000, 800000, 700000, 1300000]
        ]}
    });

    let layer = new Vector({
        source: tileSource,
        style: tileLayerStyle
    });

    layer.set('name', name);

    return layer;
}

// This is the list of 100km squares that we need for the 1km grid. The list was generated by listing the available
// data directories for the imagery product in production, and then formatting it to look like the BNG map.
// A more accurate tile list could be generated by listing the actual download files, but we would proably want to
// do that as an offline process and store the result somewhere.
const OneHundredKMSquares = [
                    'HP',
               'HT','HU',
     'HW','HX','HY','HZ',
'NA','NB','NC','ND',
'NF','NG','NH','NJ','NK',
'NL','NM','NN','NO',
     'NR','NS','NT','NU',
     'NW','NX','NY','NZ',
               'SD','SE','TA',
          'SH','SJ','SK','TF','TG',
     'SM','SN','SO','SP','TL','TM',
     'SR','SS','ST','SU','TQ','TR',
'SV','SW','SX','SY','SZ','TV'
];
const ONE_KM = 1000;
function generateOneKmGrid() {
    let features = [];
    OneHundredKMSquares.forEach(name => {
        const {x, y} = getCoord(name);
        for(let easting = 0; easting < 100; easting++) {
            for(let northing = 0; northing < 100; northing++) {
                // 1KM square, out from the origin of this tile
                const xMin = x + (easting * ONE_KM);
                const yMin = y + (northing * ONE_KM);
                const xMax = xMin + ONE_KM;
                const yMax = yMin + ONE_KM;

                const polygon = fromExtent([xMin, yMin, xMax, yMax]);
                const tileName = name +
                    ("0" + easting).slice(-2) +
                    ("0" + northing).slice(-2);

                const feature = new Feature(polygon);
                feature.setId(tileName);
                features.push(feature);
            }
        }
    });
    return features;
}

export function create1KMTileLayer(name) {
    let tileSource = new VectorSource();

    let features = generateOneKmGrid();
    tileSource.addFeatures(features);

    let layer = new Vector({
        source: tileSource,
        style: imageryTileLayerStyle,
    });

    layer.set('name', name);
    return layer;
}

export function createVectorLayer(name, style = drawingLayerStyle) {
    let source = new VectorSource({wrapX: false});
    let layer = new Vector({
        source: source,
        style: style
    });
    layer.set('name', name);
    return layer;
}

export function intersects(feature1, feature2) {
    if (intersect(feature1, feature2)) return true;

    // intersect will return a null value if two polygons touching so check with lineOverlap
    return lineOverlap(feature1, feature2).features.length > 0;
}

export function addFeatureToSource(source, newFeature) {
    // find out if the feature intersects with any existing features and merge them together
    let newGeoJsonFeature = toGeoJsonFeature(newFeature);

    let newSource = new VectorSource();
    newSource.addFeature(newFeature);

    source.getFeatures().forEach((f) => {
        // find out if the new feature intersects the extent of an existing feature before doing the slower intersects function
        // should filter out more non intersecting features quicker than using intersects
        newSource.forEachFeatureIntersectingExtent(f.getGeometry().getExtent(), function () {
            if (newFeature.ol_uid !== f.ol_uid) {
                let geoJsonFeature = toGeoJsonFeature(f);
                if (intersects(newGeoJsonFeature, geoJsonFeature)) {
                    newGeoJsonFeature = union(newGeoJsonFeature, geoJsonFeature);
                    newFeature.setGeometry(toOLGeometry(newGeoJsonFeature.geometry));
                    source.removeFeature(f);
                }
            }
        });
    });
    return source;
}

/**
 * Cookie cut the geometry of the feature from all the features in the layer
 */
export function cutPolygonFromLayer(layer, feature) {
    let gjFeature = toGeoJsonFeature(feature);
    let features = layer.getSource().getFeatures() || [];
    features.forEach(layerFeature => {
        let gjLayerFeature = toGeoJsonFeature(layerFeature);
        if (intersect(gjLayerFeature, gjFeature)) {
            let diff = difference(gjLayerFeature, gjFeature);
            if (diff) {
                let olDiff = toOLFeature(diff);
                if (olDiff.getGeometry().getType() === 'MultiPolygon') {
                    layer.getSource().removeFeature(layerFeature);
                    layer.getSource().addFeatures(multiPolygonToPolygons(olDiff));
                } else {
                    layerFeature.setGeometry(olDiff.getGeometry());
                }
            } else {
                layer.getSource().removeFeature(layerFeature);
            }
        }
    });
}

export function cloneSource(source) {
    let features = source.getFeatures().map(f => f.clone());
    return new VectorSource({features: features});
}

/**
 * Checks that when the given features have been merged into the given sources that the vertices and polygons limits
 * have not been exceeded. Leaves the features in the sources intact by performing the merge on a cloned version. Returns
 * promise containing the merged features which can be used to reload the drawing layer. These will be flagged with a
 * "merged" attribute to signify that a merge has already been performed. The map addFeature listener uses this so merging
 * is done twice when these features are added.
 * @param olSources Will generally be the drawingLayer source and the currentlyPolygonLayer source (for expansions).
 * @param olFeatures The features that will merged.
 * @returns {Promise<unknown>} A promise containing the merged features.
 */
export function checkFeatureMerging(olSources, olFeatures,
            maximumCoordsError = messages.maximumCoordsError,
            maximumFeaturesError = messages.maximumFeaturesError) {
    return new Promise((resolve, reject) => {
        // clone the features in the sources so we dont change anything if the merge exceeds the limits
        let source = new VectorSource({});
        olSources.map(s => cloneSource(s)).forEach(c => source.addFeatures(c.getFeatures()));
        source.addFeatures(olFeatures);

        // merge everything as one multipolygon makes it quicker
        let feature = polygonsToMultiPolygon(source.getFeatures());
        if (feature.getGeometry().getPolygons().length === 0) {
            resolve([]);
            return;
        }
        let merged = removeInternalCoords(feature);
        if(!merged) {
            reject({message: messages.invalidGeometry});
            return;
        }

        let totalCoords = getNumberOfCoords(merged);
        if (totalCoords > MAX_NO_OF_COORDS) {
            reject({message: maximumCoordsError, values: {max: MAX_NO_OF_COORDS}});
            return;
        }

        // spilt back into individual polygons
        let features = [];
        if (merged.getGeometry().getType() === 'MultiPolygon') {
            features.push(...multiPolygonToPolygons(merged));
        } else {
            features.push(merged);
        }

        if (features.length > MAX_NO_OF_FEATURES) {
            reject({message: maximumFeaturesError, values: {max: MAX_NO_OF_FEATURES}});
            return;
        }

        // tag them as already merged so the addFeatures listener doesnt try to merge again
        features.forEach(f => f.set('merged', true));

        resolve(features);
    });
}
