import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import InlineCodeSnippet from '../../../components/InlineCodeSnippet';

export default function APIKeyParameter() {
    return <Fragment>
        <Typography variant='body1'>
            You can choose to authenticate your API request using a HTTP query parameter.
            The parameter name should be <InlineCodeSnippet>key</InlineCodeSnippet>, and the value should be the Project API Key.
        </Typography>
    </Fragment>
}