import React, { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import {osColour} from 'omse-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';


const styles = theme => ({
    container: {
        display: 'flex',
        flex: '0 1 auto',
        flexFlow: 'column wrap',
        margin: theme.spacing(1),

            '& hr': {
                borderTop: `1px dotted ${osColour.secondary.slate}`,
                width: '100%',
                marginTop: theme.spacing(2),
            },

            '& img': {
                width: '100%',
                alignSelf: 'center',
            },
    },

    title: { 
        color: osColour.neutral.charcoal,
        fontWeight: 'bold',
        maxWidth: '100%',
        textAlign: 'left',
    },

    description: {
            color: osColour.neutral.charcoal,
            maxWidth: '100%',
            textAlign: 'left',
        },
    
    subcaption: {
        color: osColour.neutral.stone,
        maxWidth: '100%',
        textAlign: 'left',
    },

});


function BrandImage(props)  {

        const { classes, image, alt, subcaption, line, title, description } = props;      
        
        return <div className={classes.container}>
                    <img src={image} alt={alt} />
                    {subcaption &&
                        <Typography variant='caption' className={classes.subcaption}><FormattedMessage {...subcaption} /></Typography>
                    }
                    {line && 
                        <hr />
                    }
                    {title && 
                        <Fragment>
                        <Typography variant='body1' className={classes.title}><FormattedMessage {...title} /></Typography>
                        <Typography variant='body1' className={classes.description}><FormattedMessage {...description} /></Typography>
                        </Fragment>
                    }
        </div>;
};

BrandImage.propTypes = {
    image: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    title: PropTypes.object,
    description: PropTypes.object,
    line: PropTypes.bool,
    subcaption: PropTypes.object,
}

export default withStyles(styles)(BrandImage);