import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadDataPackages } from "../../../modules/dataPackages/actions";
import buildFilter from "../../../util/search/dataPackageFilter";
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import DataPackagesTable from "../../download/dataPackages/DataPackagesTable";
import { dataPackagesMessages } from "./messages";
import { InternalLink, osColour } from "omse-components";
import Accordion from "../../../components/Accordion";
import RecentItemsActions from "./RecentItemsActions";
import { findRoute } from "../../../util/routes";
import styled from "@emotion/styled";
import RecentItemsLoading from "./RecentItemsLoading";
import RecentItemsError from "./RecentItemsError";
import RecentItemsEmpty from "./RecentItemsEmpty";
import dataPackageSVG from "../../../components/icons/dataPackage-large.svg";

const RecentPackagesTable = styled(DataPackagesTable)(() => `
    .table {
		& .th {
			background-color: ${osColour.neutral.white};
			& p {
			color: ${osColour.neutral.charcoal};
			}
		}
`);

export default function DataPackages({ openByDefault }) {
    const { result, loading, error } = useSelector(
        (state) => state.dataPackages.list
    );
    const orgId = useSelector((state) => state.organisation.current.id);
    const dispatch = useDispatch();
    const location = useLocation();

    const routeForDataPackages = findRoute("dataPackages");

    const search = (location.state && location.state.search) || "";

    const packageList = useMemo(() => {
        if (result) {
            let filteredResult;
            filteredResult = result.filter(buildFilter(search));
            return filteredResult.slice(0, 5);
        }
        return [];
    }, [result, search]);

    useEffect(() => {
        dispatch(loadDataPackages());
    }, [dispatch, orgId]);

    return (
        <Accordion
            title={dataPackagesMessages.title}
            openByDefault={openByDefault}
            showContentBorder={Boolean(error || loading || !packageList.length)}
        >
            <>
                {loading && <RecentItemsLoading />}
                {!loading && (
                    <>
                        {error && (
                            <RecentItemsError
                                errorName={dataPackagesMessages.errorName.defaultMessage}
                            />
                        )}
                        {(!error && packageList.length !== 0) && (
                            <>
                                <RecentPackagesTable packageList={packageList} />
                                <RecentItemsActions>
                                    <InternalLink
                                        path={routeForDataPackages.path}
                                        message={dataPackagesMessages.viewMore}
                                    />
                                </RecentItemsActions>
                            </>
                        )}
                        {(!error && (!packageList || packageList.length === 0)) && (
                            <RecentItemsEmpty
                                icon={dataPackageSVG}
                                iconWidth={80}
                                iconAlt="Data Packages icon"
                                message={dataPackagesMessages.emptyMessage}
                            />
                        )}
                    </>
                )}
            </>
        </Accordion>
    );
}

DataPackages.defaultProps = {
    openByDefault: false
};

DataPackages.propTypes = {
    openByDefault: PropTypes.bool
};