import {createRectangleDrawingTool} from "./ol/tool";
import {deleteStyle} from "./ol/style";

const DELETE_RECTANGLE_TYPE = 'deleteRectangle';

export default function DeleteRectangleAction (map, layers) {
    const {deleteLayer} = layers;

    const tool = createRectangleDrawingTool(deleteLayer, deleteStyle);
    map.addInteraction(tool);

    return {
        type: DELETE_RECTANGLE_TYPE,
        deactivate: () => {
            map.removeInteraction(tool);
        }
    }
}

DeleteRectangleAction.type = DELETE_RECTANGLE_TYPE;