/**
 * Perform a fuzzy case-insensitive search on a set of text.
 *
 * The text to search for is split by word, and will match if any of the words matches any of the text in the set. E.g.:
 * const textToSearchIn = new Set(['the apple', 'my tree', 'something else'])
 * searchTextSet('apple tree', textToSearchIn) // matches
 * searchTextSet('apple tree missing', textToSearchIn) // does not match
 *
 * @param {string} textToSearchFor Text to search for.
 * @param {Set<string>} textToSearchIn Text to search in.
 * @returns {boolean}
 */
export default function searchText(textToSearchFor, textToSearchIn) {
    const searchWords = textToSearchFor.toLowerCase().split(/\s+/);
    const textToSearchArrayLower = Array.from(textToSearchIn)
        .filter(val => typeof val === 'string' || val instanceof String) // Filter out non-string values
        .map(val => val.toLowerCase());

    // Every word we're searching for.
    return searchWords.every(searchWord =>
        // Should match at least one part of the text we're searching.
        textToSearchArrayLower.some(text => text.includes(searchWord))
    );
}