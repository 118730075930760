import React, {useState} from "react";
import {defineMessages, FormattedMessage} from "react-intl";
import DataPackageDropdownField from './DataPackageDropdownField';
import {useSelector} from "react-redux";
import {Typography} from "@mui/material";
import {LinkButton} from "omse-components";
import MixedCrsDialog from "./MixedCrsDialog";

export const messages = defineMessages({
    coordinateReferenceSystem: {
        id: 'CrsDropdown.coordinateReferenceSystem',
        defaultMessage: 'Coordinate Reference System',
        description: 'Coordinate Reference System label'
    },
    select: {
        id: 'CrsDropdown.select',
        defaultMessage: 'Select CRS',
        description: 'Error label'
    },
    fieldError: {
        id: 'CrsDropdown.fieldErrorMessage',
        defaultMessage: 'CRS is required',
        description: 'Error label'
    },
    mixedCrsInfo: {
        id: 'CrsDropdown.mixedCrsInfo',
        defaultMessage: 'The collections in this recipe have different default coordinate reference systems. {link}',
        description: 'Message to display default CRS types differ between feature types'
    },
    mixedCrsInfoLinkText: {
        id: 'CrsDropdown.mixedCrsInfoLinkText',
        defaultMessage: 'View the CRS details',
        description: 'Link text for mixed CRS info, opens a panel to show more information about the default crs types'
    }
});

export default function CrsDropdown({crsOptions}) {
    const datasetOptions = useSelector((state) => {
        return state.recipes.datasetOptions.result;
    });
    const [showMixCrsDialogue, setShowMixCrsDialogue] = useState(false);

    let infoMessage = null, mixCrsDialogue = null;
    if(datasetOptions){
        const mixedCrs = datasetOptions.filter(option => datasetOptions[0].defaultCrs !== option.defaultCrs).length > 0;
        if(mixedCrs){
            mixCrsDialogue = <MixedCrsDialog onClose={() => {setShowMixCrsDialogue(false)}} crsOptions={crsOptions}/>;
            infoMessage = <Typography variant='caption' >
                <FormattedMessage {...messages.mixedCrsInfo} values={{
                    link: <LinkButton onClick={() => {setShowMixCrsDialogue(true)}}>
                        <Typography variant='caption' >
                            <FormattedMessage {...messages.mixedCrsInfoLinkText} />
                        </Typography>
                    </LinkButton>
                }}/>
            </Typography>
        }
    }

    return <>
        <DataPackageDropdownField
            options={crsOptions}
            infoMessage={infoMessage}
            fieldName='crs'
            selectMessage={messages.select}
            labelMessage={messages.coordinateReferenceSystem}
            fieldErrorMessage={messages.fieldError}
        />
        {showMixCrsDialogue && mixCrsDialogue}
    </>;
}