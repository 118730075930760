import React from 'react';
import Typography from '@mui/material/Typography';
import withStyles from 'react-jss';
import { osColour } from 'omse-components';
import { defineMessages } from 'react-intl';
import OFACompletionSummary, { messages as ofamessages } from './OFACompletionSummary';

import img0LaunchCadcorp  from './cadcorp/Launch-Cadcorp-SIS.png';
import img1OverlayWizard from './cadcorp/Add-Overlay-Wizard.png';
import img2OverlayTypes from './cadcorp/OSGB-Data-Hub-Overlay-Types.png';
import img3Recipe from './cadcorp/OS-NGD-Features-Recipe.png';
import img4NGDFeatures from './cadcorp/OS-NGD-Features.png';

const cadcorpImages = {
    img0LaunchCadcorp,
    img1OverlayWizard,
    img2OverlayTypes,
    img3Recipe,
    img4NGDFeatures,
}

const messages = defineMessages({
    achievement3: {
        id: 'OFACadcorpCompletionSummary.achievement3',
        defaultMessage: `You've filtered the features that are loaded by a maximum number or a specific area.`,
        description: 'Achievement 3',
    },
});

const styles = {
    bordered: {
        border: `solid ${osColour.neutral.mist} 1px`
    }
};

export const OFACadcorp = (props) => {
    const { classes, api, children } = props;
    return (
        <div>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>The Cadcorp Spatial Information System® (Cadcorp SIS®) is an integrated family of geospatial products comprising desktop, web, and developer applications.</p>
                <p>Cadcorp SIS Desktop connects directly to the Ordnance Survey Data Hub through dedicated wizards.</p>
                <p>The instructions that follow demonstrate how to connect to the OS NGD API – Features using Cadcorp SIS Desktop version 9.1.1668.</p>
            </Typography>
            <Typography variant='h2'>Integrating {api} in Cadcorp SIS</Typography>
            <Typography variant='body1' paragraph={true} component='div'>
                <ol>
                    <li>
                        <p> In Cadcorp SIS Desktop, open an existing map or create a new one. </p>
                        <img src={cadcorpImages.img0LaunchCadcorp} alt='Cadcorp SIS open existing map or create a new one' className={classes.bordered} />
                    </li>
                    <li>
                        <p>In the Home tab, click Add Overlay.</p>
                        <img src={cadcorpImages.img1OverlayWizard} alt='Cadcorp SIS open existing map or create a new one' className={classes.bordered} />
                    </li>
                    <li>
                        <p>In the Overlay Types dialog, select Ordnance Survey (GB) →	 OS (GB) Data Hub, and then click Next. </p>
                    </li>
                    <li>
                        <p>In the OS (GB) Data Hub dialog: </p>
                        <ol type="a">
                            <li>
                                <p>Select OS National Geographic Database (NGD) API – Features  </p>
                            </li>
                            <li>
                                <p>Enter a valid API key.  </p>
                            </li>
                            <li>
                                <p>Select Premium/Public Sector Plan, if you have this plan.</p>
                            </li>
                            <li>
                                <p>Select Save in the UI settings database (encrypted). </p>
                            </li>
                            <li>
                                <p>Click Next.</p>
                                <img src={cadcorpImages.img2OverlayTypes} alt='Cadcorp SIS Desktop OS (GB) Data Hub dialog' className={classes.bordered} />
                            </li>

                        </ol>
                    </li>
                    <li>
                        <p>In the OS Data Hub NGD API – Features dialog: </p>
                        <ol type="a">
                            <li>
                                <p>Well-known ‘recipe’: Select a pre-defined recipe, if available. </p>
                            </li>
                            <li>
                                <p>Data Themes: Select your data themes in the left panel. </p>
                            </li>
                            <li>
                                <p>Features: The feature types available within the selected themes display in the right panel. Use the editing tools to delete feature types or to change the order in which they display in your SIS Workspace Definition (SWD).  </p>
                            </li>
                            <li>
                                <p>Select either: </p>
                                <ol type="i">
                                    <li>
                                        <p>Local cache: To store the data temporarily on your machine. When you save the SWD and reopen it, the data will be fetched for a second time. </p>
                                    </li>
                                    <li>
                                        <p>One-off import: To import the data. These imports have a larger file size, but the data is not queried for a second time when the file is reopened.  </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p>Set Filtering options: These settings are used in conjunction and define how much data is required for display. It is recommended that you always set a spatial filter and feature limit. </p>
                                <ol type="i">
                                    <li>
                                        <p>Spatial: The ‘Intersect with current view extent’ option limits the download to only selected features within the current window extent. You can also load features within a specific area of interest by using the polygon feature to draw your area of interest on the map BEFORE opening the Add Overlay dialog. </p>
                                    </li>
                                    <li>
                                        <p>Maximum number of features: Limits the number of feature values downloaded to the number set. Note: This limit is applied per feature within any filtered spatial area.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p>Click Finish.</p>
                            </li>
                        </ol>
                        <img src={cadcorpImages.img3Recipe} alt='Cadcorp SIS Desktop OS Data Hub NGD API - Features Data Themes and Feature Types dialog' className={classes.bordered} />
                    </li>
                    <li>
                        <p>The selected themes and feature types display in the SWD. </p>
                        <img src={cadcorpImages.img4NGDFeatures} alt='Cadcorp SIS Desktop loaded OS NGD API - Features in SWD and map area' className={classes.bordered} />
                    </li>
                </ol>
            </Typography>
            <OFACompletionSummary software='Cadcorp SIS' achievements={[ofamessages.achievement1, ofamessages.achievement2, messages.achievement3]} />
            {children}
        </div>
    )
}

export default withStyles(styles)(OFACadcorp);
