import {createActionReducer, createActionMiddleware} from 'omse-components';
import {RETRY_PAYMENTS_ACTION} from "./actions";
import paymentActionCallback from "./paymentActionCallback";

export const reducer = createActionReducer(RETRY_PAYMENTS_ACTION);

function setupCall() {
    return {
        url: '/api/payments/retry',
        method: 'POST'
    };
}

export const middleware = createActionMiddleware(RETRY_PAYMENTS_ACTION, setupCall, paymentActionCallback);