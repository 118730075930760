import React, {useState, useCallback} from "react";
import {createUseStyles} from 'react-jss';
import {IconButton} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import {Notification} from 'omse-components';
import DataPackageMap from "./DataPackageMap";
import {box} from 'omse-components';
import {ReactComponent as CloseIcon} from "../../../components/icons/close-large.svg";

const myStyles = createUseStyles(theme => ({
    map: {
        width: '100%',
        height: '80vh',
    },
    mapZoom: {
        "& .ol-zoom": {
            top: "15px !important"
        }
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: theme.spacing(7),
        boxShadow: box.shadow,
        zIndex: 2,
        flexWrap: 'wrap',
        padding: '12px 40px'
    },
    root: {
        padding: 0,
        flex: 1,
        margin: 'auto'
    }
}));

export default function DataPackageMapModal({handleClose, productId, dataPackage}) {
    const myClasses = myStyles();
    const [errorMessage, setErrorMessage] = useState(null);

    const showError = useCallback(errorMessage => {
        setErrorMessage(errorMessage);
    }, [setErrorMessage]);

    return (
        <Dialog fullWidth={true} maxWidth={'lg'} open={true} onClose={handleClose}>
            <div className={myClasses.title}>
                    <DialogTitle className={myClasses.root}>
                        {dataPackage.name}
                    </DialogTitle>
                <IconButton onClick={handleClose} size="large">
                    <CloseIcon height={24} width={24} />
                </IconButton>
            </div>

            <div className={myClasses.map}>
                {
                    errorMessage &&
                    <Notification variant='warning'
                                  appearance='toast'
                                  onClose={() => {setErrorMessage(null)}}>
                        <Typography variant='body1'>
                            {errorMessage}
                        </Typography>
                    </Notification>
                }

                <DataPackageMap handleError={showError} showToolbar={false} productId={productId} dataPackage={dataPackage} mapClasses={myClasses.mapZoom} />
            </div>
        </Dialog>
    );
}