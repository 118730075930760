import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Typography from '@mui/material/Typography';
import {DropDownMenu, AddButton, CommonDialog, isWidthDown, withWidth} from 'omse-components';
import {defineMessages, useIntl, FormattedMessage, FormattedRelativeTime} from 'react-intl';
import withStyles from 'react-jss';
import {invite} from '../../../modules/organisation/action';
import ChangeRoleDialog from "./ChangeRoleDialog";
import {ADMIN_ROLE} from "../../../../shared/roles";
import {messages as userMessages} from "../../../constants/user";
import CancelInvitationDialog from "./CancelInvitationDialog";
import RevokeAccessDialog from "./RevokeAccessDialog";
import {osColour} from 'omse-components';

const messages = defineMessages({
    actionButton: {
        id: 'TeamMember.actionButton',
        defaultMessage: 'Actions',
        description: 'Label for the actions button'
    },
    resendInvitation: {
        id: 'TeamMember.resendInvitation',
        defaultMessage: 'Resend invitation',
        description: 'Label for the resend invitation menu option'
    },
    cancelInvitation: {
        id: 'TeamMember.cancelInvitation',
        defaultMessage: 'Cancel invitation',
        description: 'Label for the cancel invitation menu option'
    },
    changeUserToAdminLink: {
        id: 'TeamMember.changeUserToAdminLink',
        defaultMessage: 'Change an existing user’s role to be an Admin',
        description: 'Link for changing member to admin user'
    },
    createNewAdminLink: {
        id: 'TeamMember.createNewAdminLink',
        defaultMessage: 'Invite a new person to the OS Data Hub as an Admin',
        description: 'Link for inviting a new admin user'
    },
    notYetAccepted: {
        id: 'TeamMember.notYetAccepted',
        defaultMessage: 'Not yet accepted',
        description: 'Used instead of a name when the user has not accepted an invitation'
    },
    invited: {
        id: 'TeamMember.invited',
        defaultMessage: 'Invited {when}',
        description: 'Used to display the invitation time to the user'
    },
    changeRole: {
        id: 'TeamMember.changeRole',
        defaultMessage: 'Change role',
        description: 'Label for the change role menu item'
    },
    revokeAccess: {
        id: 'TeamMember.revokeAccess',
        defaultMessage: 'Revoke access',
        description: 'Label for the revoke access menu item'
    },
    youCannot: {
        id: 'TeamMember.youCannot',
        defaultMessage: "You cannot perform this action",
        description: 'Title for the dialog shown if the sole admin of the organisation tries to change the role'
    },
    explanation1: {
        id: 'TeamMember.explanation1',
        defaultMessage: "You cannot change the role of the last Admin user for your organisation.",
        description: 'Text for the dialog shown if the sole admin of the organisation tries to change the role'
    },
    explanation2: {
        id: 'TeamMember.explanation2',
        defaultMessage: "Create another Admin user and ensure they accept the invitation before attempting this action again.",
        description: 'Text for the dialog shown if the sole admin of the organisation tries to change the role'
    },
    optionsHeader: {
        id: 'TeamMember.optionsHeader',
        defaultMessage: "You can:",
        description: 'Options header for the dialog shown if the sole admin of the organisation tries to change the role'
    }
});

const componentName = 'TeamMember';

export const headerMessages = defineMessages({
    user: {
        id: componentName + '.user',
        defaultMessage: 'User',
        description: 'User label'
    },
    role: {
        id: componentName + '.role',
        defaultMessage: 'Role',
        description: 'Role label'
    },
    actions: {
        id: componentName + '.actions',
        defaultMessage: 'Actions',
        description: 'Actions label'
    }
});

const styles = theme => ({
    nameCell: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '150px',
        paddingRight: theme.spacing(1),
        maxWidth: 'calc(100vw - 520px)',
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block',
            maxWidth: 'calc(100vw - 40px)'
        }
    },
    roleCell:{
        paddingRight: theme.spacing(1),
    },
    email:{
        overflow:'hidden',
        textOverflow: 'ellipsis'
    },
    actionCell: {
        textAlign: 'right'
    },
    invitationCell: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    finalButton: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('sm')]:{
            marginLeft: 0,
            marginTop: theme.spacing(1)
        }
    },
    link:{
        color: osColour.link.base,
        paddingTop: theme.spacing(1),
        '&:hover': {
            cursor: 'pointer',
            color: osColour.link.hover
        }
    },
    inline:{
        display: 'inline-block'
    },
    role:{
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    mobileLabel: {
        marginTop: theme.spacing(1),
        display: 'none',
        [theme.breakpoints.down('sm')]:{
            display: 'block'
        }
    }
});

export function TeamMember({user, setShowInviteNewMembers, width, classes}) {
    let dispatch = useDispatch();
    let adminCount = useSelector(state => {
        const orgUsers = state.organisation.users.result;
        if(orgUsers) {
            return orgUsers.adminCount;
        }
    });
    let [showChangeRole, setShowChangeRole] = useState(false);
    let [showRevokeAcess, setShowRevokeAccess] = useState(false);
    let [showCancelInvitation, setShowCancelInvitation] = useState(false);
    let intl = useIntl();
    let userName;
    let {role, email} = user;
    let textColor = 'initial';
    let finalCellContent;
    let finalCellClassName;

    if(!user.invitedDate) {
        let actions = [
            {
                label: messages.changeRole,
                actionAriaLabel: `${intl.formatMessage(messages.changeRole)} for ${email}`,
                action: () => setShowChangeRole(true)
            },
            {
                label: messages.revokeAccess,
                actionAriaLabel: `${intl.formatMessage(messages.revokeAccess)} for ${email}`,
                action: () => setShowRevokeAccess(true)
            }
        ];
        finalCellClassName = 'actionCell';
        userName = user.firstName + " " + user.lastName;
        finalCellContent = (
            <div className={classes.inline}>
                <DropDownMenu 
                    staticButtonText
                    buttonFontWeight='bold'
                    buttonLabel={messages.actionButton}
                    buttonProps={{'aria-label': `${intl.formatMessage(messages.actionButton)} for ${email}`}}
                    items={actions}
                    placement='bottom-end'
                />
            </div>
        );

    } else {
        textColor = 'textSecondary';
        finalCellClassName = 'invitationCell';
        userName = intl.formatMessage(messages.notYetAccepted);
        const when = (Date.parse(user.invitedDate).valueOf() - Date.now()) / 1000;

        finalCellContent = <Fragment>
            <div className={classes.invitation}>
                <Typography variant='body1' color={textColor}>
                    <FormattedMessage {...messages.invited} values={{
                        when: <FormattedRelativeTime value={when} updateIntervalInSeconds={10}/>
                    }}/>
                </Typography>
                <AddButton showIcon={false}
                           label={messages.resendInvitation}
                           ariaLabel={`${intl.formatMessage(messages.resendInvitation)} for ${email} for the role ${role}`}
                           variant='outlined'
                           action={() => dispatch(invite(email, role))}/>
            </div>
            <div className={classes.finalButton}>
                <AddButton showIcon={false}
                           label={messages.cancelInvitation}
                           ariaLabel={`${intl.formatMessage(messages.cancelInvitation)} for ${email} for the role ${role}`}
                           variant='outlined'
                           action={() => setShowCancelInvitation(true)}/>
            </div>
        </Fragment>
    }

    const onlyAdminDialog = (onClose) => {
        const handleNewAdminClick = () =>{
            onClose();
            setShowInviteNewMembers(true);
        };
        return (
            <CommonDialog onClose={onClose} title={messages.youCannot}>
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.explanation1}/>
            </Typography>
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.explanation2}/>
            </Typography>
            <Typography variant='body1'>
                <FormattedMessage {...messages.optionsHeader}/>
            </Typography>
            <Typography className={classes.link} variant='body1' onClick={onClose}>
                <FormattedMessage {...messages.changeUserToAdminLink}/>
            </Typography>
            <Typography className={classes.link} variant='body1' onClick={handleNewAdminClick}>
                <FormattedMessage {...messages.createNewAdminLink}/>
            </Typography>
        </CommonDialog>
        )
    }

    function setupChangeRoleDialog() {
        let onClose = () => setShowChangeRole(false);
        if(role === ADMIN_ROLE && adminCount === 1) {
            return onlyAdminDialog(onClose);
        }
        return <ChangeRoleDialog user={user} onClose={onClose}/>
    }
    function setupCancelInvitationDialog() {
        return <CancelInvitationDialog handleClose={() => setShowCancelInvitation(false)}
                                       invitedContactId={user.id}/>
    }
    function setupRevokeAccessDialog() {
        let onClose =() => setShowRevokeAccess(false);
        if(role === ADMIN_ROLE && adminCount === 1) {
            return onlyAdminDialog(onClose);
        }
        return <RevokeAccessDialog user={user} onClose={onClose}/>
    }

    return <Fragment>
        <tr>
            <td className={classes.nameCell}>
                { showChangeRole && setupChangeRoleDialog() }
                { showRevokeAcess && setupRevokeAccessDialog() }
                { showCancelInvitation && setupCancelInvitationDialog() }

                <Typography variant='body2' className={classes.mobileLabel}>
                    <FormattedMessage {...headerMessages.user} />
                </Typography>
                <Typography variant='body2' color={textColor}>{userName}</Typography>
                <Typography className={classes.email} variant='body1' color={textColor}>{email}</Typography>
                {isWidthDown('xs', width) &&
                    <Fragment>
                        <Typography variant='body2' className={classes.mobileLabel}>
                            <FormattedMessage {...headerMessages.role} />
                        </Typography>
                        <Typography className={classes.role} variant='body1' color={textColor}><FormattedMessage {...userMessages[role]}/></Typography>
                        {finalCellContent}
                    </Fragment>
                        }
            </td>

            {!isWidthDown('xs', width) &&
                <Fragment>
                    <td className={classes.roleCell}>
                        <Typography variant='body1' color={textColor}><FormattedMessage {...userMessages[role]}/></Typography>
                    </td>
                    <td className={classes[finalCellClassName]}>
                        {finalCellContent}
                    </td>
                </Fragment>
            }
        </tr>
    </Fragment>;
}


const styled = withWidth()(withStyles(styles)(TeamMember));
export default styled;


