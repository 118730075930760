const PREFIX = 'action:recipeEditor:';

export const CHANGE_RECIPE_NAME = PREFIX + 'changeRecipeName';
export const CHANGE_RECIPE_DESCRIPTION = PREFIX + 'changeRecipeDescription';
export const SELECT_FEATURE_TYPES = PREFIX + 'selectFeatureTypes';
export const UNSELECT_FEATURE_TYPES = PREFIX + 'unselectFeatureTypes';
export const SET_FEATURE_TYPE_FILTER = PREFIX + 'setFeatureTypeFilter';
export const RESET_FEATURE_TYPES = PREFIX + 'resetFeatureTypes';

export const CREATE_RECIPE = PREFIX + 'createRecipe';
export const RESET_RECIPE_STATE = PREFIX + 'resetRecipeState';
export const DISPLAY_VALIDATION_ERRORS = PREFIX + 'displayErrors';

export const SET_CURRENT_QUERY_TREE = PREFIX + 'setCurrentQueryTree';

export const VALIDATE_CQL_FILTER_ACTION = PREFIX + ':featureType:validateCqlFilter';

export function changeRecipeName(name) {
    return {
        type: CHANGE_RECIPE_NAME,
        name,
    };
}

export function changeRecipeDescription(description) {
    return {
        type: CHANGE_RECIPE_DESCRIPTION,
        description,
    };
}

export function selectFeatureTypes(arrayOfFeatureTypesAndVersions) {
    return {
        type: SELECT_FEATURE_TYPES,
        arrayOfFeatureTypesAndVersions
    };
}

export function unselectFeatureTypes(arrayOfFeatureTypesAndVersions) {
    return {
        type: UNSELECT_FEATURE_TYPES,
        arrayOfFeatureTypesAndVersions
    };
}

export function setFeatureTypeFilter(featureTypeId, filterExpression, filterTree) {
    return {
        type: SET_FEATURE_TYPE_FILTER,
        featureTypeId,
        filterExpression,
        filterTree
    };
}

export function resetFeatureTypes() {
    return {
        type: RESET_FEATURE_TYPES
    };
}

export function createRecipe() {
    return {
        type: CREATE_RECIPE
    };
}

export function displayErrors() {
    return {
        type: DISPLAY_VALIDATION_ERRORS
    };
}

export function resetRecipeState() {
    return {
        type: RESET_RECIPE_STATE
    };
}

/**
 * The current tree state gives us a place to save the in-process edit of the filter expression. The 'treeEdited'
 * field is also important, as recipe editor needs to pop up dialogs if people are about to navigate away from a
 * modified filter.
 */
export function setCurrentQueryTree(tree = null, expression = null, canSave = false, treeEdited = false) {
    return {
        type: SET_CURRENT_QUERY_TREE,
        tree,
        expression,
        canSave,
        treeEdited
    }
}

export function validateCqlFilter(featureTypeId, featureTypeVersion, cqlFilter) {
    return {
        type: VALIDATE_CQL_FILTER_ACTION,
        featureTypeId,
        featureTypeVersion,
        cqlFilter
    }
}
