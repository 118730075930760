import React, {useEffect} from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import {createUseStyles} from 'react-jss';
import {Box, Typography, Button} from '@mui/material';
import FeatureCheck from '../../components/FeatureCheck';
import {EAIAPI} from '../../../shared/features';
import ContentHeader from '../../components/ContentHeader';
import useHeading from "../../hooks/useHeading";
import CatalogueTag from '../../components/CatalogueTag';
import Link from '../../components/Link';
import {ReactComponent as TickIcon} from '../../components/icons/success-green.svg';
import { useDispatch, useSelector } from "react-redux";
import { COM3_CATALOGUE } from "../../../shared/catalogues";
import routes from '../../util/routes';
import { contentPadding } from "omse-components";
import { getApiProducts, resetPurchaseApiPlan } from "../../modules/commercialApiPlans/actions";
import {osColour} from 'omse-components';
import {useHistory} from 'react-router';

const messages = defineMessages({
    backLabel:{
        id:"addedApiPlan.backLabel",
        defaultMessage: "API Dashboard",
        description: "Back label on added api plan page"
    },
    title:{
        id:'addedApiPlan.title',
        defaultMessage: "Energy and Infrastructure API plan setup",
        description:"Title of the purchase conformation page"
    },
    success: {
        id: "addedApiPlan.success",
        defaultMessage:"Success! Your invoice request has been received.",
        description: "success title"
    },
    thankYou:{
        id:"addedApiPlan.thankYou",
        defaultMessage: "Thank you.",
        description: "thank you"
    },
    confirmationTitle: {
        id: "addedApiPlan.confirmationTitle",
        defaultMessage: "Order Confirmation",
        description: "Order confirmation title"
    },
    confirmationTier: {
        id: "addedApiPlan.tier",
        defaultMessage: "Plan type: {tierName}",
        description: "Confirmation of Tier"
    },
    confirmationTransactions:{
        id:"addedApiPlan.transactions",
        defaultMessage: "Transactions: {transactions} transactions",
        description: "confirmation of transactions"
    },
    confirmationUnitPrice: {
        id: "addedApiPlan.unitPrice",
        defaultMessage: "Each transaction costing £{unitPrice} for {productName}",
        description: "confirmation of demo product unit price"
    },
    pricingLink: {
        id: "addedApiPlan.pricingLink",
        defaultMessage: "See our API breakdown and pricing guide",
        description: "link text to pricing guide"
    },
    confirmationAmount:{
        id: "addedApiPlan.amount",
        defaultMessage: "£{amount} {currency}",
        description: "confirmation amount"
    },
    dashboardButton: {
        id: "addedApiPLan.dashboardButton",
        defaultMessage: "API dashboard",
        description:"text for link button at bottom of page"
    }
});

const useStyles = createUseStyles(theme => ({
    catalogueTag:{
        position: "absolute",
        right: theme.spacing(1),
        top:theme.spacing(1)
    },
    content: {
        position: "relative",
        paddingBottom: contentPadding.bottom,
        paddingLeft: contentPadding.left,
        paddingRight: contentPadding.right,
        maxWidth: contentPadding.maxWidth,
        marginTop: contentPadding.top,
        [theme.breakpoints.down('sm')]: {
            padding: contentPadding.mobile,
            paddingTop: 0,
            marginTop: 0
        }
    },
    success: {
        textAlign: "center",
        marginBottom: theme.spacing(5)
    },
    confirmationDetails: {
        margin: `${theme.spacing(2)} 0`,
        padding: `${theme.spacing(2)} 0`,
        borderTop: `solid 1px ${osColour.neutral.rock}`,
        borderBottom: `solid 1px ${osColour.neutral.rock}`,

    },
    amount: {
        fontSize:"2rem",
        fontWeight: 'bold'
    },
    dashButton: {
        textDecoration:"none",
        float:"right"
    }
}));

export default function ConfirmApiPlanPurchase() {
    const title = useHeading() || messages.title;
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    let purchasedPlan = useSelector(state => state.commercialApiPlans.purchase?.result);

    if(!purchasedPlan){
        //The only way to this page is to be pushed to it by purchasing an api plan. If it has gotten here it means that the state cleared early.
        history.replace(routes.dashboard);
    }

    const products = useSelector(state => state.commercialApiPlans.products?.result);
    //find the demo product
    const demoProduct = products?.find(product => product.demoProduct);
    const purchasedProductTier = demoProduct && demoProduct?.tiers.find(productTier => productTier.tierId === purchasedPlan.tierId);

    const purchasedTransactions = purchasedProductTier && Math.floor(purchasedPlan.openingBalance / purchasedProductTier.unitPrice).toLocaleString();

    useEffect(()=>{
        if(!products){
            dispatch(getApiProducts());
        }
        return()=>{
            dispatch(resetPurchaseApiPlan());
        }
    }, [products, dispatch]);


    //purchased tier information, demo product information
    //history to push people to dashboard
    return (<FeatureCheck feature={EAIAPI}>
        <Box data-testid="purchased-plan">
        <ContentHeader title={title} backPath={routes.dashboard} backLabel={messages.backLabel}>
            <Box className={classes.catalogueTag}>
                <CatalogueTag type={COM3_CATALOGUE} size='md' />
            </Box>
        </ContentHeader>
        <Box className={classes.content}>
            <Box className={classes.success}>
                <Typography variant="h2">
                    <FormattedMessage {...messages.success}/>
                </Typography>
                <TickIcon width={200} height={200}/>
                <Typography variant="body1">
                    <FormattedMessage {...messages.thankYou}/>
                </Typography>
            </Box>

            <Box>
                <Typography variant="h2">
                    <FormattedMessage {...messages.confirmationTitle}/>
                </Typography>
                {purchasedProductTier &&
                    <Box className={classes.confirmationDetails}>
                        <Typography variant="body2">
                            <FormattedMessage {...messages.confirmationTier} values={{tierName:purchasedProductTier.name}}/>
                        </Typography>
                        <Typography variant="body1">
                            <FormattedMessage {...messages.confirmationTransactions} values={{transactions: purchasedTransactions}}/>
                        </Typography>
                        <Typography variant="body1">
                            <FormattedMessage {...messages.confirmationUnitPrice} values={{unitPrice:purchasedProductTier.unitPrice, productName:demoProduct.name}}/>
                        </Typography>
                        <Typography variant="body1">
                            <Link path={routes.pricingApiPlans}>
                                <FormattedMessage {...messages.pricingLink}/>
                            </Link>
                        </Typography>
                    </Box>
                }
                {purchasedPlan &&
                    <Typography variant="h3" className={classes.amount}>
                        <FormattedMessage {...messages.confirmationAmount} values={{
                            amount: Number(purchasedPlan.openingBalance).toLocaleString(),
                            currency: purchasedPlan.currency
                        }}/>
                    </Typography>
                }
                <Link path={routes.dashboard} className={classes.dashButton}>
                    <Button variant="contained" color="primary">
                        <Typography variant="body1">
                            <FormattedMessage {...messages.dashboardButton}/>
                        </Typography>
                    </Button>
                </Link>
            </Box>
        </Box>
        </Box>
    </FeatureCheck>)
}
