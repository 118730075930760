import React from 'react';
import Header from './Header';
import Content from './Content';
import Typography from '@mui/material/Typography';
import DocLinks from './DocLinks';
import matchOverviewImg from './matchOverview/match-overview.png';
import Document from './Document';

export default function MatchOverview() {
    return <Document>
        <Header back={true}>OS Match & Cleanse API: Overview</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>Do you have UK address records that are incorrect or missing important information?</p>
                <p>
                    Improve efficiency, guarantee accuracy and reduce costs with OS Match & Cleanse API. Match, correct
                    and validate addresses against authoritative data from AddressBase® Premium and AddressBase® Premium - Islands, 
                    providing clean and up-to-date address records that you can have confidence in.
                </p>
                <p>
                    With OS Match & Cleanse API, you’ll have instant, reliable results to make deduping and cleansing
                    addresses easier for your business. Transform partial address information into the authoritative
                    address to enhance customer data and asset management.
                </p>
                <p>
                    Use partial or complete addresses to identify and match your targets – get information about
                    residential and commercial properties quickly and easily. Each address record contains geographic
                    co-ordinates meaning that you can position it accurately on a map. They also contain the Unique
                    Property Reference Number (UPRN) and Topographic Identifier (TOID) so you can cross reference the
                    results with other datasets and ensure consistency.
                </p>
            </Typography>
            <img src={matchOverviewImg} alt='OS Match & Cleanse API: map with an address search input and result overlain.'/>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>With OS Match & Cleanse API,</p>
                <p>You can:</p>
                <ul>
                    <li>Match a specific address accurately</li>
                    <li>Cleanse partial or incorrect addresses</li>
                    <li>Obtain the UPRN for matched addresses</li>
                    <li>Search both Royal Mail and Local Authority address datasets</li>
                    <li>Geocode addresses (add the location element)</li>
                    <li>Match addresses in the United Kingdom, Isle of Man, Jersey and Guernsey</li>
                </ul>
                <p>You can't:</p>
                <ul>
                    <li>Match addresses outside the United Kingdom, Isle of Man, Jersey and Guernsey</li>
                    <li>Locate general areas such as cities, forests or beaches</li>
                    <li>Carry out bulk matching (transactions are subject to API throttling)</li>
                </ul>
            </Typography>
            <DocLinks product='match' hasGettingStarted={true} hasTechnicalSpec={true} hasDemo={false}/>
        </Content>
    </Document>
}