const queryString = require('query-string');
const {OPEN_DATA_PERSONAL, DEFAULT_TYPE} = require('../../shared/organisations');

// selects the message to return based on user and organisation parameters
export function withOrganisationMessage(defaultMessage, orgMessage, openDataMessage, user, organisation) {
    
     // user has many orgs
    if (user && user.orgs && user.orgs.length > 1) {

        // an organisation is selected
        if (organisation && organisation.id) {
            const currentOrg = getUserOrganisation(user, organisation);
            if (currentOrg && currentOrg.name) {
                if (isOpenDataPersonalType(currentOrg)) {
                    return {...openDataMessage};
                } else {
                    return { ...orgMessage, values: { org: currentOrg.name } };
                }
            }

        // initial state when user has orgs but none are selected, use the default
        } else {
            const defaultOrg = getDefaultOrganisation(user);
            if (defaultOrg && defaultOrg.name) {
                return { ...orgMessage, values: { org: defaultOrg.name } };
            }
        }
    }
    return defaultMessage;
}

// append current orgId from store to url or body
export function withOrganisation(target, store) {
    const org = store && store.getState().organisation && store.getState().organisation.current;

    if (typeof target === 'string') {
        let url = target;
        let params = {};
        if (org && org.id) {
            params = {...params, orgId: org.id};
            const parsed = queryString.parseUrl(url);
            parsed.query = {...parsed.query, ...params};
            url = queryString.stringifyUrl(parsed);
        }
        return url
    }

    if (typeof target === 'object') {
        const config = {...target};
        if (org && org.id) {
            if (config.body) {
                config.body = {...config.body, orgId: org.id};
            } else {
                config.body = {orgId: org.id};
            }
        }
        return config;
    }

    return target;
}

export const getUserOrganisation = (user, org) => {
    return org && getUserOrganisationById(user, org.id);
};
export const getUserOrganisationById = (user, orgId) => {
    return orgId && user && user.orgs && user.orgs.find(userOrg => userOrg.value === orgId);
};
export const getDefaultOrganisation = (user) => {
    return user && user.orgs && user.orgs.find(isDefaultType);
};
export const isOpenDataPersonal = (user, org) => {
    return user && org && isOpenDataPersonalType(getUserOrganisation(user, org));
};
export const isOpenDataPersonalById = (user, orgId) => {
    return user && orgId && isOpenDataPersonalType(getUserOrganisationById(user, orgId));
};
export const isOpenDataPersonalType = result => (result && result.type === OPEN_DATA_PERSONAL);
export const isDefaultType = result => (result && result.type === DEFAULT_TYPE);
export const hasManyOrganisations = user => user && user.orgs && user.orgs.length > 1;

export const getUserDetailsDefaultContext = (userDetails) => {
    let defaultContextUserDetails = userDetails;
    if (hasManyOrganisations(userDetails)) {
        const userDefaultOrg = getDefaultOrganisation(userDetails);
        if (userDefaultOrg) {
            defaultContextUserDetails = {
                ...userDetails,
                permissions: userDefaultOrg.permissions
            }
        }
    }
    return defaultContextUserDetails;
}