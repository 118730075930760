import React from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { recipesMessages } from "./messages";
import { FormattedMessage } from "react-intl";
import RecipeCard from "./recipes/RecipeCard";
import routes, { getLocation, findRoute } from "../../../util/routes";
import { useHistory, useLocation } from "react-router";
import RecentItemsError from "./RecentItemsError";
import RecentItemsActions from "./RecentItemsActions";
import NavButton from "../../../components/NavButton";
import Accordion from "../../../components/Accordion";
import RecentItemsLoading from "./RecentItemsLoading";
import { useDispatch, useSelector } from "react-redux";
import { InternalLink, modules } from "omse-components";
import { NGD_PRODUCT_ID } from "../../../../shared/constants";
import { getRecipeLibrary } from "../../../modules/recipes/actions";
import usePartnerContracts from "../../../hooks/usePartnerContracts";
import { ERROR, LOADING, NOT_NEEDED } from "../../../constants/state";
import {
    hasEditNgdDownloadsPermission,
    hasCreateRecipePermission,
    hasPartnerCatalogue,
} from "../../../util/permissions";
import recipeSVG from "../../../components/icons/recipe-large.svg";
import RecentItemsEmpty from "./RecentItemsEmpty";
import {
    loadPartnerContracts,
    loadPartnerContractCatalogue,
} from "../../../modules/partner/actions";
import PartnerLicenceRequiredDialog from "../../download/premiumItem/PartnerLicenceRequiredDialog";

const ItemsContainer = styled("div")`
    display: grid;
    overflow: auto;
    max-height: 20em;
    grid-template-rows: 1fr;
`;

export default function Recipes(p) {
    const { openByDefault } = p;
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const userData = useSelector((state) => state.user.current);
    const themeTreeData = useSelector((state) => state.themes.themeTree);
    const recipeLibraryData = useSelector((state) => state.recipes.library);
    const partnerContracts = usePartnerContracts(NGD_PRODUCT_ID);
    const [recipes, setRecipes] = useState({
        list: [],
        isError: false,
        isLoading: true,
        canWriteRecipe: false,
        canWriteDataPackage: false,
        hasNgdDownloadsLicence: false,
    });

    // Handle this outside useEffect to avoid update depth errors.
    // We must pass this variable into several useEffects to prevent
    // any DOM elements briefly appearing during loading states.
    const _hasNgdDownloadsLicence = partnerContracts === NOT_NEEDED || partnerContracts?.length > 0;

    useEffect(() => {
        dispatch(getRecipeLibrary());
        dispatch(modules.actions.themes.getThemeTree());
        if(hasPartnerCatalogue(userData?.result)) {            
            dispatch(loadPartnerContracts());
            dispatch(loadPartnerContractCatalogue());
        }
    }, [dispatch, userData]);

    useEffect(() => {
        if (
            userData.error ||
            recipeLibraryData.error ||
            themeTreeData.error ||
            partnerContracts === ERROR
        ) {
            setRecipes((prevState) => ({
                ...prevState,
                isError: true,
                isLoading: false,
            }));
        }
    }, [userData.error, recipeLibraryData.error, themeTreeData.error, partnerContracts]);

    useEffect(() => {
        if (userData.loading || recipeLibraryData.loading || themeTreeData.loading) {
            setRecipes((prevState) => ({
                ...prevState,
                isLoading: true,
            }));
        }
    }, [userData.loading, recipeLibraryData.loading, themeTreeData.loading]);

    useEffect(() => {
        if (userData.result) {
            setRecipes((prevState) => ({
                ...prevState,
                canWriteRecipe: hasCreateRecipePermission(userData.result),
                canWriteDataPackage: hasEditNgdDownloadsPermission(userData.result),
                hasNgdDownloadsLicence: _hasNgdDownloadsLicence,
                isLoading: false,
            }));
        }
    }, [userData.result, _hasNgdDownloadsLicence]);

    useEffect(() => {
        if (recipeLibraryData?.result && themeTreeData?.result) {
            // take a slice of the first (most recent) 5 recipes
            const subset = recipeLibraryData.result.slice(0, 5);
            const parsedSubset = subset.map((recipe) => ({
                id: recipe.id,
                name: recipe.name,
                author: recipe.createdBy,
                sharedWithMe: recipe.sharedWithMe,
                themes: parseRecipeThemes(recipe.datasets, themeTreeData.result),
                created: parseRecipeCreatedDate(recipe.created),
            }));
            setRecipes((prevState) => ({
                ...prevState,
                list: parsedSubset,
                isLoading: false,
            }));
        }
    }, [recipeLibraryData.result, themeTreeData.result]);

    function handleAddDataPackageButtonClick(id) {
        if (recipes.hasNgdDownloadsLicence) {
            const newLocation = getLocation(
                routes.dataPackageNewNgd.replace(":recipeId", id),
                location,
                {}
            );
            history.push(newLocation);
        } else {
            setShowModal(true);
        }
    }

    return (
        <>
            {showModal &&
                ReactDOM.createPortal(
                    <PartnerLicenceRequiredDialog
                        productId={NGD_PRODUCT_ID}
                        onClose={() => setShowModal(false)}
                    />,
                    document.body
                )}

            <Accordion
                title={recipesMessages.title}
                openByDefault={openByDefault}
                showContentBorder={recipes.isError || recipes.isLoading || !recipes.list.length}
            >
                <>
                    {recipes.isLoading && <RecentItemsLoading />}
                    {!recipes.isLoading && recipes.isError && (
                        <RecentItemsError errorName={recipesMessages.errorName.defaultMessage} />
                    )}
                    {!recipes.isLoading && !recipes.isError && (
                        <>
                            {recipeLibraryData?.result && !recipes.list.length ? (
                                <RecentItemsEmpty
                                    message={recipesMessages.emptyMessage}
                                    icon={recipeSVG}
                                    iconWidth={100}
                                    iconAlt="Recipes icon"
                                    actionButton={
                                        <NavButton
                                            color="primary"
                                            variant="contained"
                                            disabled={!recipes.canWriteRecipe}
                                            path={findRoute("recipeEditor").path}
                                        >
                                            <FormattedMessage {...recipesMessages.emptyAddButton} />
                                        </NavButton>
                                    }
                                />
                            ) : (
                                <>
                                    <ItemsContainer>
                                        {recipes.list.map((recipe) => (
                                            <RecipeCard
                                                key={recipe.id}
                                                recipeMetadata={recipe}
                                                userHasWritePermission={recipes.canWriteDataPackage}
                                                licencesAreLoading={partnerContracts === LOADING}
                                                handleAddDataPackageButtonClick={
                                                    handleAddDataPackageButtonClick
                                                }
                                            />
                                        ))}
                                    </ItemsContainer>
                                    <RecentItemsActions>
                                        <>
                                            <InternalLink
                                                path={findRoute("recipeLibrary").path}
                                                message={recipesMessages.viewMore}
                                            />
                                            {recipes.canWriteRecipe && (
                                                <NavButton
                                                    color="primary"
                                                    variant="contained"
                                                    path={findRoute("recipeEditor").path}
                                                >
                                                    <FormattedMessage {...recipesMessages.addNew} />
                                                </NavButton>
                                            )}
                                        </>
                                    </RecentItemsActions>
                                </>
                            )}
                        </>
                    )}
                </>
            </Accordion>
        </>
    );
}

function parseRecipeThemes(recipeDatasets, themeTreeResult) {
    const themes = new Set(
        recipeDatasets.map((dataset) => {
            // Return the first three chars of the NGD code.
            return dataset.featureTypeId.slice(0, 3);
        })
    );
    return [...themes].map((themeCategory) => {
        const matches = themeTreeResult
            ? themeTreeResult.filter((theme) => theme.themeId === themeCategory)
            : [];
        return matches.length ? matches[0].label : themeCategory;
    });
}

function parseRecipeCreatedDate(recipeTimestamp) {
    let ts = recipeTimestamp.slice(8, 10);
    ts += `/${recipeTimestamp.slice(5, 7)}`;
    ts += `/${recipeTimestamp.slice(0, 4)}`;
    return ts;
}
