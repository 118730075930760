import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {createUseStyles} from 'react-jss';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Typography, Button} from '@mui/material';
import queryString from 'query-string';

const useStyles = createUseStyles(theme => ({
    padRight: {
        marginRight: theme.spacing(1)
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    buttonArea: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(6)
    },
    groupHeading: {
        marginBottom: theme.spacing(2)
    }
}));

const messages = defineMessages({
    groupedDownloads: {
        id: 'GroupedDownloads.groupedDownloads',
        defaultMessage: 'Grouped downloads',
        description: 'Label for the section of the page that shows grouped files.'
    },
    groupedDownloadsIntro: {
        id: 'GroupedDownloads.groupedDownloadsIntro',
        defaultMessage: 'Download multiple files at a time',
        description: 'Intro for the grouped files section.'
    },
    groupedDownloadsIntro2: {
        id: 'GroupedDownloads.groupedDownloadsIntro2',
        defaultMessage: '(your browser may only allow you to download a few zip files at a time)',
        description: 'Intro for the grouped files section.'
    },
    zipsNotAvailable: {
        id: 'GroupedDownloads.zipsNotAvailable',
        defaultMessage: 'Grouped downloads are not available for this data package version.',
        description: 'Message shown when grouped downloads are not available.'
    },
    buttonLabel: {
        id: 'GroupedDownloads.buttonLabel',
        defaultMessage: 'Download zip file {name} ({fileCount} files)',
        description: 'Label for the zip file download button.'
    }
});

export default function GroupedDownloads({packageId, versionId, links}) {
    const classes = useStyles();

    // Find out how many files are available in each of the zips. We end up with an array, where each value is the
    // count of files in that particular zip. We also work out the highest file count.
    let maxCount = 0;
    const zipCounts = links.reduce((zipCounts, link) => {
        const zip = link.zip;
        if(zip !== undefined) {
            if(zipCounts[zip]) {
                zipCounts[zip] += 1;
            } else {
                zipCounts[zip] = 1;
            }
            if(zipCounts[zip] > maxCount) {
                maxCount = zipCounts[zip];
            }
        }
        return zipCounts;
    }, []);

    function buildHref(zipIndex) {
        const urlPrefix = '/api/dataPackages/' + packageId + '/' + versionId + '/download?';
        const urlParams = {
            zip: zipIndex
        }
        return urlPrefix + queryString.stringify(urlParams);
    }

    function download(zipIndex) {
        // Create a link that points to the correct location, and then simulate a click
        const a = document.createElement("a");
        a.download = true;
        a.href = buildHref(zipIndex);
        a.click();
    }

    if(zipCounts.length === 0) {
        // The zip strategy isn't working for this data package version. Just display a message and let the user
        // use the direct download links
        return <Fragment>
            <Typography variant='h3' className={classes.groupHeading}>
                <FormattedMessage {...messages.groupedDownloads}/>
            </Typography>
            <Typography variant='body1' className={classes.buttonArea} color='textSecondary'>
                <FormattedMessage {...messages.zipsNotAvailable}/>
            </Typography>
        </Fragment>;
    }

    function pad(string, digits) {
        while(string.length < digits) {
            string = '0' + string;
        }

        return string;
    }

    function buildName(index) {
        // Human readable names start from 1, not from 0
        let name = (index + 1).toString();

        // Add leading padding if we need to
        let digits = zipCounts.length.toString().length;

        return pad(name, digits);
    }
    function buildCount(count) {
        let result = count.toString();

        // Add leading padding if we need to
        let digits = maxCount.toString().length;

        return pad(result, digits);
    }

    return <Fragment>
        <Typography variant='h3' className={classes.groupHeading}>
            <FormattedMessage {...messages.groupedDownloads}/>
        </Typography>
        <Typography variant='body1' component='span' paragraph={true} className={classes.padRight}>
            <FormattedMessage {...messages.groupedDownloadsIntro}/>
        </Typography>
        <Typography variant='body1' component='span' color='textSecondary'>
            <FormattedMessage {...messages.groupedDownloadsIntro2}/>
        </Typography>

        <div className={classes.buttonArea}>
            {
                zipCounts.map((fileCount, index) =>
                    <Button key={'zip' + index}
                            color='primary'
                            variant='outlined'
                            onClick={e => download(index)}
                            className={classes.button}>
                        <FormattedMessage {...messages.buttonLabel}
                            values={{
                                name: buildName(index),
                                fileCount: buildCount(fileCount)
                            }}
                        />
                    </Button>)
            }
        </div>

    </Fragment>
}

GroupedDownloads.propTypes = {
    packageId: PropTypes.string.isRequired,
    versionId: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
        DataSize: PropTypes.number, // Counted in 1K units, as that is what DOAS provides
    })).isRequired
};
