import React from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";

const fadeIn = keyframes`
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	50% {
		opacity: 0.5;
		transform: translateY(10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
`;

const fadeOut = keyframes`
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 0;
	}
`;

const Container = styled("div")`
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    animation: ${(props) => (props.isVisible ? fadeIn : fadeOut)} 0.3s ease;
	animation-fill-mode: forwards;
`;

const FadeInOutContainer = ({ children, visbilityThreshold = 0.3 }) => {
    const [targetRef, isVisible] = useIntersectionObserver({
        visbilityThreshold
    });
    return (
        <Container ref={targetRef} isVisible={isVisible} data-testid={'animation-container'}>
            {children}
        </Container>
    );
};

FadeInOutContainer.propTypes = {
    children: PropTypes.node.isRequired,
    visbilityThreshold: PropTypes.number
};

export default FadeInOutContainer;
