import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import Typography from "@mui/material/Typography";
import {osColour, withWidth} from 'omse-components';
import {FormattedMessage} from "react-intl";
import classNames from 'classnames';
import {ReactComponent as InfoIcon} from '../icons/info-notification.svg';
import {ReactComponent as SuccessIcon} from '../icons/success-notification.svg';
import {ReactComponent as WarningIcon} from '../icons/warning-notification.svg';
import {ReactComponent as ErrorIcon} from '../icons/error-notification.svg';
import Link from '../Link';

const style = theme => ({
    notice: {
        display: 'flex',
        flex: '0 0 auto',
        zIndex: 5,
        background: osColour.neutral.clouds,
        padding: theme.spacing(0.5) + ' '+ theme.spacing(3),
        color: osColour.neutral.charcoal,
        '& a, a:hover': {
            fontSize: '0.875rem'
        },
        '&.light': {
            color: osColour.neutral.white
        },
        '&.light a': {
            color: osColour.neutral.white + ' !important'
         },
         '&.light a:focus': {
            outline: 'solid 2px ' + osColour.neutral.white
         },
        '&.info': {
            background: '#2979af'
         },
         '&.success': {
            background: osColour.status.success
         },
         '&.error': {
            background: '#c63d54'
         },
         '&.internal': {
            background: osColour.primary.foxglove
         },
         '&.dark a': {
            color: osColour.neutral.charcoal + ' !important'
         },
         '&.dark a:focus': {
            outline: 'solid 2px ' + osColour.neutral.charcoal
         },
         '&.warning': {
            background: osColour.status.warning
         },
         '&.tag': {
             paddingLeft: 0,
             '& div': {
                marginRight: theme.spacing(0.5)
,            }
         }
    },
    noticeText: {
        lineHeight: 1.72,
        '& span': {
            marginRight:theme.spacing(0.5)
        }
    },
    icon: {
        flex: '0 0 auto',
        marginRight: theme.spacing(0.5)
    }
});

export function SiteNotice(props) {
    const {classes, linkPath, linkText, caption, content, tag} = props;
    let {variant} = props;
    let info, success, warning, error, internal, Icon, light, dark;

    switch (variant) {
        case 'info':
            Icon = InfoIcon;
            info = true;
            light = true;
            break;
        case 'success':
            Icon = SuccessIcon;
            success = true;
            light = true;
            break;
        case 'warning':
            Icon = WarningIcon;
            warning = true;
            dark = true;
            break;
        case 'error':
            Icon = ErrorIcon;
            error = true;
            light = true;
            break;
        case 'internal':
            Icon = WarningIcon;
            internal = true;
            light = true;
            break;
        default:
            break;
    }

    const noticeClasses = {[classes.notice]: true, info, success, warning, error, internal, light, dark};

    if (tag) {
        noticeClasses.tag = true;
    }

    return (<div className={classNames(noticeClasses)}>
        {tag}
        {Icon &&
            <Icon className={classes.icon} width={24} height={24} />
        }
        {(caption || content || (linkPath && linkText)) &&
            <Typography variant='caption' className={classes.noticeText}>
                {caption &&
                    <span><FormattedMessage {...caption} /></span>
                }
                {content &&
                    <Fragment>
                        {content}
                    </Fragment>
                }
                {(linkPath && linkText) &&
                    <Link path={linkPath}><FormattedMessage {...linkText} /></Link>
                }
            </Typography>
        }
    </div>)
}

SiteNotice.propTypes = {
    classes: PropTypes.object.isRequired,
    tag: PropTypes.object,
    linkPath: PropTypes.string,
    linkText: PropTypes.object,
    caption: PropTypes.object,
    content:  PropTypes.object,
    variant: PropTypes.oneOf(['info', 'success', 'warning', 'error', 'internal'])
}

const styled = withStyles(style)(SiteNotice);
export default withWidth()(styled);