import React from 'react';
import {useSelector} from 'react-redux';
import MissingPermission from "./MissingPermission";

/**
 * Wraps a component so that you can only view the content if the logged in user has the correct permissions.
 * By definition, all routes that require permissions are non-public, so we don't need to check if the user
 * is logged in.
 */
export default function withPermissionBarrier(Component, permissionCheck) {
    return function PermissionsBarrier() {
        const user = useSelector(state => state.user.current.result);

        if(permissionCheck(user)) {
            return <Component/>;
        }
        return <MissingPermission user={user}/>;
    };
}