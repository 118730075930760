import PropTypes from "prop-types";
import styled from "@emotion/styled";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { defineMessages, useIntl } from "react-intl";
import FeaturesList from "../../components/FeatureList";
import PlanAction from "./PlanAction";
import Refs from "./Refs";
import { Box, Typography, Chip } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ExternalLink, osColour } from "omse-components";
import { OPEN_PLAN, PREMIUM_PLAN, PSGA_PLAN } from "../../../shared/plans";

const StyledPlanItem = styled("article")(
    ({ theme, isActivePlan }) => `
    padding: ${theme.spacing(3)};
    max-width: 20em;
    border: solid 1px ${osColour.neutral.mist};
    border-radius: 0.2em;
    ${
        isActivePlan &&
        `
        border: solid 1px ${osColour.primary.berry};
        box-shadow: 0 -3px 0 0 ${osColour.primary.berry};
    `
    }

    display: flex;
    flex-direction: column;
    background: ${osColour.neutral.white};

    & sup {
        vertical-align: super;
        line-height: 0;
        margin-left: 2px;
        & a, a:hover {
            text-decoration: none;
        }
        & a:hover {
            opacity: 0.8;
            text-decoration: underline;
        }
        & a:focus {
            padding: ${theme.spacing(1)};
        }
    }
    & sup, sup > a, sup > a:hover {
        font-size: 0.875rem;
        font-weight: 600;
        color: ${osColour.status.error} !important;
    }

    & .titles {
        font-weight: normal;
        text-align: center;
        color: ${osColour.neutral.charcoal};
    }

    & .planTitle {
        margin-bottom: 5px;
    }

    & .subtitle: {
        // Aligns plan bullet points by ensuring text always fills two lines (OpenData plan text is only one line).
        min-height: 3rem;
    }

    & .planLink {
        line-height: 1.5;
        width: 217px;
        display: inline-block;
    }

    & ul.features {
        margin-top: ${theme.spacing(4)};
        line-height: 1;
        padding: 0;
        & li {
            list-style-type: none;
            margin-bottom: 12px;
        }
        & li > p {
            // Making a flex container so the SVG will take up an entire "column". This ensures that when the paragraph
            // text after the SVG overflows below it, it does not actually flow below it but continues to align with
            // the side of the SVG.
            display: flex;
            font-weight: normal;
            line-height: 1.5;
            margin: 0;
        }
        & svg {
            flex-shrink: 0;
            flex-grow: 0;
            color: ${osColour.neutral.stone};
            margin-right: 8px;
            margin-bottom: 5px;
        }
    }

    & .footnotes {
        margin-top: auto;
        font-weight: normal;
        text-align: center;
        ${theme.breakpoints.down("sm")} {
            margin-bottom: ${theme.spacing(3)};
        }
    }
`
);

const planItemMessages = defineMessages({
    recommended: {
        id: "planItemMessages.recommended",
        defaultMessage: "Recommended",
    },
    currentPlan: {
        id: "planItemMessages.currentPlan",
        defaultMessage: "Current Plan",
    },
});

export function PlanItem(props) {
    const {
        isActivePlan,
        title,
        subtitle,
        subtitleLink,
        plan,
        planType,
        showRecommended,
        showActions,
    } = props;
    const config = useSelector((state) => state.config.current?.result);
    const userDetails = useSelector((state) => state.user.current?.result);
    const intl = useIntl();

    return (
        <StyledPlanItem id={title.id} isActivePlan={isActivePlan}>
            <Box
                sx={(theme) => ({
                    padding: "0.5em 0 0.5em 0",
                    minHeight: "3em",
                    textAlign: "center",
                    [theme.breakpoints.down("md")]: {
                        minHeight: 0,
                        paddingBottom: "1em",
                    },
                })}
            >
                {showRecommended && (
                    <Chip
                        color="secondary"
                        variant="outlined"
                        label={intl.formatMessage(planItemMessages.recommended)}
                    />
                )}
                {isActivePlan && (
                    <Chip
                        color="primary"
                        variant="outlined"
                        label={intl.formatMessage(planItemMessages.currentPlan)}
                    />
                )}
            </Box>
            <Typography variant="h2" component="h3" className={classNames("titles", "planTitle")}>
                <FormattedMessage {...title} />
            </Typography>
            <Typography variant="body2" className={classNames("titles", "subtitle")}>
                <span className="planLink">
                    <FormattedMessage
                        {...subtitle}
                        values={{
                            a: (chunks) => (
                                <ExternalLink href={subtitleLink} message={chunks.toString()} />
                            ),
                        }}
                    />
                </span>
            </Typography>
            {showActions && (
                <Box
                    sx={(theme) => ({
                        minHeight: "2.6em",
                        paddingTop: "1.6em",
                        [theme.breakpoints.down("md")]: {
                            minHeight: 0,
                            paddingTop: "0.6em",
                        },
                    })}
                >
                    <PlanAction planType={planType} userDetails={userDetails} config={config} />
                </Box>
            )}
            <ul className="features">
                <FeaturesList plan={plan} type={planType} />
            </ul>
            <Typography variant="body2" component="div" className="footnotes">
                {plan.footnotes &&
                    plan.footnotes.map((p) => (
                        <span key={p.text.id + planType}>
                            <FormattedMessage
                                {...p.text}
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <Refs item={p} type={planType} />
                        </span>
                    ))}
            </Typography>
        </StyledPlanItem>
    );
}

PlanItem.propTypes = {
    isActivePlan: PropTypes.bool,
    title: PropTypes.object.isRequired,
    subtitle: PropTypes.object.isRequired,
    subtitleLink: PropTypes.string,
    planType: PropTypes.oneOf([OPEN_PLAN, PREMIUM_PLAN, PSGA_PLAN]),
    showRecommended: PropTypes.bool,
};

export default PlanItem;
