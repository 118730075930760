import {osColour} from 'omse-components';

export default function styles(theme) {
    return {
        table: {
            border: 'none',
            borderCollapse: 'collapse',
            '& th':{
                fontWeight: 600,
                color: osColour.primary.berry,
                backgroundColor: 'white',
                borderBottom: '2px solid ' + osColour.primary.berry,
                padding: theme.spacing(1,2),
                whiteSpace: 'nowrap',
                textAlign: 'left',
                verticalAlign: 'bottom'
            },
            '& thead ~ tbody th': {
                color: osColour.neutral.charcoal,
                border: 0,
                whiteSpace: 'unset'
            },
            '& tr': {
                borderBottom: '1px solid ' + osColour.neutral.mist,
            },
            '& tr:nth-child(odd)': {
                backgroundColor: '#f6f5f9'
            },
            '& td': {
                padding: theme.spacing(1, 2),
            },
            '& p': {
                marginTop: 0
            },
            '& p:last-of-type': {
                marginBottom: 0
            }
        },
        heading: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(2)
        }
    };
}

