import React, { Fragment, useEffect, useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import withStyles from 'react-jss';
import { Typography } from '@mui/material';
import { osColour, AddButton, ExternalLink } from 'omse-components';
import { useLocation, useHistory } from 'react-router-dom';
import routes, { getLocation } from '../util/routes'
import RouterLink from '../components/Link';
import VisuallyHidden from '../components/util/VisuallyHidden'

const messages = defineMessages({
	title: {
		id: 'ErrorOmissionsLanding.title',
		defaultMessage: 'What are you looking for?',
		description: 'Text for title'
	},
	subTitleSupport: {
		id: 'ErrorOmissionsLanding.subTitleSupport',
		defaultMessage: 'Technical support issues using our data and APIs',
		description: 'Text for subTitleSupport'
	},
	subTitleMap: {
		id: 'ErrorOmissionsLanding.subTitleMap',
		defaultMessage: 'Think our map or data is wrong?',
		description: 'Text for subTitleMap'
	},
	supportContent: {
		id: 'ErrorOmissionsLanding.supportContent',
		defaultMessage:
			'Our Docs and Support pages offer guidance on using the API Keys and endpoints as well as code snippets and demos.<p>For technical support you can use the popular developer Q&A site GIS Stack Exchange to raise your technical questions. Be sure to tag your questions with ordnance-survey and any other relevant tags.</p>',
		description: 'Text for supportContent'
	},
	developerSupportTitle: {
		id: 'ErrorOmissionsLanding.developerSupportTitle',
		defaultMessage: 'Support from OS Developers',
		description: 'Text for developerSupportTitle'
	},
	gitHubSubTitle: {
		id: 'ErrorOmissionsLanding.gitHubSubTitle',
		defaultMessage: 'Github',
		description: 'Developer Support Title'
	},
	stackExchangeSubTitle: {
		id: 'ErrorOmissionsLanding.stackExchangeSubTitle',
		defaultMessage: 'GIS Stack Exchange',
		description: 'Community Support Title'
	},
	developerSupportText: {
		id: 'ErrorOmissionsLanding.developerSupportText',
		defaultMessage:
			'Can’t find what you are looking for from the resources available? Check out our GitHub repositories and raise an issue with our developers on GitHub.',
		description: 'Text for developerSupportText'
	},
	communitySupportTitle: {
		id: 'ErrorOmissionsLanding.communitySupportTitle',
		defaultMessage: 'Community Support',
		description: 'Text for communitySupportTitle'
	},
	communitySupportText: {
		id: 'ErrorOmissionsLanding.communitySupportText',
		defaultMessage:
			'You can use the popular developer Q&A site GIS Stack Exchange to raise your technical questions. Be sure to tag your questions with ordnance-survey and any other relevant tags.',
		description: 'Text for communitySupportText'
	},
	customerServiceText: {
		id: 'ErrorOmissionsLanding.customerServiceText',
		defaultMessage: 'Or {link}.',
		description: 'Text for customerServiceText'
	},
	mapSupportContent: {
		id: 'ErrorOmissionsLanding.mapSupportContent',
		defaultMessage:
			'If you think something’s wrong with our underlying mapping data (like something’s been named incorrectly, doesn’t exist on our mapping, or has incorrect attribution), you can continue on to report incorrect mapping data.',
		description: 'Text for mapSupportContent'
	},
	errorReportingTermsText: {
		id: 'ErrorOmissionsLanding.errorReportingTermsText',
		defaultMessage: 'By clicking the below button to access our Errors and Omissions tool you accept the {link}.',
		description: 'text for the error reporting terms'
	},
	errorReportingTermsLink: {
        id: 'ErrorOmissionsLanding.errorReportingTermsLink',
        defaultMessage: 'Errors and Omissions Terms and Conditions',
        description: 'link for the error reporting terms'
    },
	errorReportingText: {
		id: 'ErrorOmissionsLanding.errorReportingText',
		defaultMessage: 'Report incorrect mapping data',
		description: 'Text for errorReportingText'
	}
});

function styles(theme) {
	return {
		title: {
			padding: '40px 0',
			fontSize: '2.5rem',
			fontFamily: "'Gill Sans Regular','Source Sans Pro','sans-serif'",
			[theme.breakpoints.down('lg')]: {
				paddingBottom: 23,
				fontSize: '1.875rem'
			},
			[theme.breakpoints.down('md')]: {
				paddingBottom: 20,
			}
		},
		splitScreen: {
			paddingBottom: 40,
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				paddingBottom: 0,
			},
			position: 'relative',
			justifyContent: 'center',
			display: 'flex',
			flexDirection: 'row',
			flexShrink: 0,
			'& h3': {
				fontSize: '1.5rem'
			}
		},
		leftSide: {
			width: '50%',
			position: 'relative',
			padding: '142px 40px',
			maxWidth: 640,
			flexDirection: 'column',
			[theme.breakpoints.down('lg')]: {
				paddingTop: 112,
				fontSize: '1.875rem'
			},
			[theme.breakpoints.down('md')]: {
				width: '90%',
				alignSelf: 'center',
				padding: '10px 0px'
			}
		},
		rightSide: {
			width: '50%',
			maxWidth: 640,
			padding: '10px 40px',
			[theme.breakpoints.down('md')]: {
				width: '90%',
				alignSelf: 'center',
				padding: '10px 0px'
			}
		},
		button: {
			marginBottom: theme.spacing(5),
			marginTop: theme.spacing(1)
		},
		link: {
			textDecoration: 'underline',
			alignItems: "center",
			fontWeight: 'bold',
			display: 'flex',
			color: osColour.link.base,
            '&:visited': {
                color: osColour.link.visited
            }
		},
		csLink: {
            display: 'inline',
            textDecoration: 'underline',
            color: osColour.link.base,
            '&:visited': {
                color: osColour.link.visited
            }
		},
		typoText: {
			paddingTop: 10,
			paddingBottom: 24,
		},
		paddingTop: {
			paddingTop: theme.spacing(2),
			[theme.breakpoints.down('md')]: {
				paddingTop: 0,
			}
		},
		routerLink: {
			fontSize: '1.125rem'
		}
	};
}

export function ErrorOmissionsLanding(props) {
	const {classes} = props;
    const location = useLocation();
	const history = useHistory();
	
	const goToErrorReporting = useCallback(() => {
		const newLocation = getLocation(routes.errorReporting, location);
		history.push(newLocation);
	}, [history, location])

	useEffect(() => {
		if (location.search) {
			goToErrorReporting();
		}
	}, [location, goToErrorReporting]);


	return (
		<Fragment>
			<VisuallyHidden>{<h1>Error Reporting</h1>}</VisuallyHidden>
			<div className={classes.splitScreen}>
				<div className={classes.rightSide}>
					<Typography color='primary' variant='h2' className={classes.title}>
						<FormattedMessage {...messages.title} />
					</Typography>

					<Typography variant='subtitle1' component='h3' color='primary'>
						<FormattedMessage {...messages.subTitleSupport} />
					</Typography>
					<Typography variant='h5' component='p' color='textPrimary' className={classes.typoText}>
						<FormattedMessage {...messages.supportContent} values={{
                        	p: chunks => <p>{chunks}</p> }} 
						/>
					</Typography>

					<Typography variant='subtitle1' component='h3' color='primary'>
						<FormattedMessage {...messages.gitHubSubTitle} />
					</Typography>
					<ExternalLink
						type='generic'
						href='https://github.com/ordnancesurvey'
						message={messages.developerSupportTitle}
					/>
					<Typography variant='h5' component='p' color='textPrimary' className={classes.typoText}>
						<FormattedMessage {...messages.developerSupportText} />
					</Typography>

					<Typography variant='subtitle1' component='h3' color='primary'>
						<FormattedMessage {...messages.communitySupportTitle} />
					</Typography>
					<ExternalLink
						type='generic'
						href='https://gis.stackexchange.com/questions/tagged/ordnance-survey'
						message={messages.communitySupportTitle}
					/>
					<Typography variant='h5' component='p' color='textPrimary' className={classes.typoText}>
						<FormattedMessage {...messages.communitySupportText} />
					</Typography>

					<Typography variant='h5' component='p' color='textPrimary' className={classes.paddingTop}>
						<FormattedMessage {...messages.customerServiceText} values={{ link: <ExternalLink type='support' /> }}
						/>
					</Typography>
				</div>
				<div className={classes.leftSide}>
					<Typography variant='subtitle1' component='h3' color='primary'>
						<FormattedMessage {...messages.subTitleMap} />
					</Typography>
					<Typography variant='h5' component='p' color='textPrimary' className={classes.typoText}>
						<FormattedMessage {...messages.mapSupportContent} />
					</Typography>
					<Typography variant='h5' component='p' color='textPrimary' className={classes.typoText}>
						<FormattedMessage {...messages.errorReportingTermsText} values={{
                    		link: <RouterLink path={routes.errorReportingTerms} className={classes.routerLink}>
                        			<FormattedMessage {...messages.errorReportingTermsLink}/>
                    			</RouterLink>
                		}}/>
					</Typography>
					<AddButton
						className={classes.button}
						label={messages.errorReportingText}
						showIcon={false}
						action={() => goToErrorReporting()}
					/>
				</div>
			</div>
		</Fragment>
	);
}

export default withStyles(styles)(ErrorOmissionsLanding);
