import React, {useState} from 'react';
import {TextField, FormControl} from '@mui/material';

/**
 * Replacement for the Number widget that is bundled with react-awesome-query-builder. This version allows us to
 * control the <input> element that is in the page, which helps us address some accessibility issues. Having our
 * own component also allows us to enforce the allowed characters as the user types... to help them only input
 * valid values.
 */
export default function NumberWidget(props) {
    const {value, setValue, placeholder, fieldDefinition} = props;

    // We keep our own state for the text in the field. We do this because the value in the tree may become NaN at some
    // point, and we don't want to clobber the user's input with that value.
    const [textValue, setTextValue] = useState(value || value === 0 ? "" + value : "");

    const integer = fieldDefinition.fieldSettings.integer;
    let pattern = "[0-9\\.+\\-]*";
    if(integer) {
        pattern = "[0-9+\\-]*";
    }
    let matcher = RegExp("^" + pattern + "$");
    let maxLength = fieldDefinition.fieldSettings.maxLength;

    function onChange(event) {
        let newValue = event.target.value;

        // Reject any unsupported input
        if(!matcher.test(newValue)) {
            return;
        }

        // If we got to here then we have input that matches the allowed characters. The value might still not be
        // a valid number, but that's ok - this widget needs to allow the user to move through invalid states as
        // they edit.
        setTextValue(newValue);
        if(newValue === "") {
            setValue(undefined);
        } else {
            setValue(Number(newValue));
        }
    }

    return <FormControl>
        <TextField
            type="text"
            variant='standard'
            value={textValue}
            placeholder={placeholder}
            onChange={onChange}
            inputProps={{
                pattern,
                maxLength,
                inputMode: 'numeric'
            }}
        />
    </FormControl>;
}
