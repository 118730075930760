import React from 'react';
import Header from "../Header";
import Content from '../Content';
import Typography from '@mui/material/Typography';
import apiLogoWhite from './brandLogoImg/OS_guidelines_OS-API-logo-white@2x.png';
import apiLogoColour from './brandLogoImg/OS_guidelines_OS-API-logo@2x.png';
import withStyles from 'react-jss';
import BrandImage from './BrandImage';
import { styles } from './BrandStyling';
import {defineMessages, FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Document from '../Document';

const messages = defineMessages({
    paragraph: {
        id: 'LogoOverview.paragraph',
        defaultMessage: 'To ensure our logo stands out on a map application, there is a keyline ‘blur’ added to both logo versions to ensure readibility. These logo versions can only be used on a digital map application.',
        description: 'Logo keyline blur'
    },
    colourTitle: {
        id: 'LogoOverview.colourTitle',
        defaultMessage: 'Full colour API logo',
        description: 'Full colour API logo'
    },
    colourDescription: {
        id: 'LogoOverview.colourDescription',
        defaultMessage: 'Our full colour logo is the preferred application option on a digital map.',
        description: 'Full colour logo'
    },

    whiteTitle: {
        id: 'LogoOverview.whiteTitle',
        defaultMessage: 'White API logo',
        description: 'White API logo'
    },
    whiteDescription: {
        id: 'LogoOverview.whiteDescription',
        defaultMessage: 'The white logo with a grey keyline is the alternative application option on a digital map.',
        description: 'White API logo'
    }
});

function LogoOverview(props) {

    const {classes} = props;
    const containerClasses = [classes.mainContainer, classes.twoItems];

    return (
        
        <Document>
            <Header back={true}>Logo overview</Header>

            <Content>
                <Typography variant='body1' paragraph={true} component='div' color='textPrimary' className='oneItem'>
                    <FormattedMessage {...messages.paragraph} />
                </Typography>

                <div className={classNames(containerClasses)}>

                        <BrandImage 
                            image={apiLogoColour} 
                            alt='Full-colour Ordnance Survey API logo' 
                            title={messages.colourTitle}
                            description={messages.colourDescription}
                            line={true} />

                        <BrandImage 
                            image={apiLogoWhite} 
                            alt='White-fill Ordnance Survey API logo' 
                            title={messages.whiteTitle}
                            description={messages.whiteDescription} 
                            line={true} />
                </div>  

            </Content>
        </Document>
    )
}

LogoOverview.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(LogoOverview)