import React from 'react';
import menuMessages from '../appContent/MenuMessages';
import {createUseStyles} from 'react-jss';
import Typography from '@mui/material/Typography';
import {defineMessages, FormattedMessage} from 'react-intl';
import Header from '../documentation/Header';
import Document from '../documentation/Document';
import style from './styles/legal';
import { ExternalLink } from 'omse-components';
import {hasPartnerCatalogue} from "../../util/permissions";
import {useSelector} from 'react-redux';

const messages = defineMessages({
    intro: {
        id: 'FCT.intro',
        defaultMessage: 'The Framework Contract Partners (FCP) sets out the terms and conditions for the commercial relationship between our Partners and Ordnance Survey. An FCP needs to be in place alongside at least one other partner contract before you can place an order to download OS digital products.',
        description: 'Intro'
    },
    prompt: {
        id: 'FCT.prompt',
        defaultMessage: 'For the full FCP terms, please see: {contractLink}',
        description: 'Prompt'
    },
    link: {
        id: 'FCT.link',
        defaultMessage: 'Framework Contract (Partners) Terms (PDF)',
        description: 'Link'
    }
});

const useStyles = createUseStyles(style);

export default function FrameworkContractTerms() {
    const classes = useStyles();
    const user = useSelector(state => state.user.current.result);

    return <Document>
        <Header back={true}>
            <FormattedMessage {...menuMessages.frameworkContractTerms} />
        </Header>
        <div className={classes.content}>
            <Typography variant='h5' component='p'>
                <FormattedMessage {...messages.intro} />
            </Typography>
            {(user && hasPartnerCatalogue(user)) && 
                <Typography variant='h5' component='p'>
                    <FormattedMessage {...messages.prompt} values={{
                        contractLink: <ExternalLink 
                            type='generic'
                            href="https://www.ordnancesurvey.co.uk/documents/licences/framework-contract-partners.pdf" 
                            message={messages.link} 
                        />}} />
                </Typography>
            }
        </div>
    </Document>
}