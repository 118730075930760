import React, {Fragment} from "react";
import {Typography} from "@mui/material";
import {defineMessages, FormattedDate, FormattedMessage} from "react-intl";
import {createUseStyles} from 'react-jss';
import {contentPadding, osColour} from "omse-components";
import routePaths from "../../../util/routes";
import Link from "../../../components/Link";
import PropTypes from "prop-types";
import DataPackageMap from "../premiumItem/DataPackageMap";
import noPreviewImage from "./no-preview.svg";
import {hasCom3Catalogue} from "../../../util/permissions";
import featureCheck from '../../../util/featureCheck';
import feature from '../../../../shared/features';
import optionsLookup from '../../../util/optionsLabelLookup';
import NotActiveTag from "../../../components/NotActiveTag";
import classNames from 'classnames';


const messages = defineMessages({

    fileFormat: {
        id: 'DataPackageMetadata.fileFormat',
        defaultMessage: 'File format: ',
        description: 'Label for the file format value.'
    },
    updates: {
        id: 'DataPackageMetadata.updates',
        defaultMessage: 'Updates: ',
        description: 'Label for the updates value.'
    },
    areaRequested: {
        id: 'DataPackageMetadata.areaRequested',
        defaultMessage: 'Area requested: ',
        description: 'Label for the area requested.'
    },
    dateCreated: {
        id: 'DataPackageMetadata.dateCreated',
        defaultMessage: 'Date created: ',
        description: 'Label for the date created.'
    },
    createdBy: {
        id: 'DataPackageMetadata.createdBy',
        defaultMessage: 'Created by: ',
        description: 'Label for the created by.'
    },
    unknownValue: {
        id: 'DataPackageMetadata.unknownValue',
        defaultMessage: 'Unknown',
        description: 'Label for unknown value.'
    },
    allOfArea: {
        id: 'DataPackageMetadata.allOfArea',
        defaultMessage: 'All of Britain or Predefined Area',
        description: 'All of Britain or Predefined Area label'
    },
    areaOfInterest: {
        id: 'DataPackageMetadata.areaOfInterest',
        defaultMessage: 'Area of Interest',
        description: 'area of interest label'
    },
    noPreview: {
        id: 'DataPackageMetadata.noPreview',
        defaultMessage: 'no preview available',
        description: 'No preview label'
    },
    price: {
        id: 'DataPackageMetadata.price',
        defaultMessage: 'Price:',
        description: 'price label for COM3 customers only'
    },
    terminals:{
        id:"DataPackageMetadata.terminals",
        defaultMessage: "Terminals:",
        description:"Terminal label for COM3 customers only"
    },
    users:{
        id:"DataPackageMetadata.users",
        defaultMessage: "Users:",
        description: "Users label for COM3 customers only"
    }
});


const myStyles = createUseStyles(theme => ({
    packageMetadata: {
        marginTop: theme.spacing(3),
        backgroundColor: osColour.neutral.clouds,
        maxWidth: contentPadding.maxWidth,
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        }
    },
    aligned: {
        display: 'flex',
    },
    padRight: {
        marginRight: theme.spacing(1),
        width: 108
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    padBottom: {
        marginBottom: theme.spacing(1)
    },
    padLeft: {
        paddingLeft: theme.spacing(3)
    },
    link: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4)
    },
    noMargin: {
        margin: 0
    },
    map: {
        width: 210,
        height: 285,

        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingBottom: 20,
            width: '100%'
        }
    },
    noPreviewContainer: {
        width: 210,
        height: 285,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: 180
        }
    }
}));

export default function DataPackageMetadata(props) {
    const {dataPackage, user, config, productOptions, recipe} = props;
    const classes = myStyles();

    let createdByValue = dataPackage.createdBy != null ?
        <Fragment>
            <p className={classes.noMargin}>{dataPackage.createdBy.firstName} {dataPackage.createdBy.lastName}</p>
            <p className={classes.noMargin}>{dataPackage.createdBy.email}</p>
        </Fragment> :
        <FormattedMessage {...messages.unknownValue}/>;

    let areaRequestedValue = dataPackage.fixedPriceAreas && dataPackage.fixedPriceAreas.length > 0 ?
        <FormattedMessage {...messages.allOfArea}/> :
        dataPackage.tiles || dataPackage.polygon ?
            <FormattedMessage {...messages.areaOfInterest}/>
            :
            <FormattedMessage {...messages.unknownValue}/>;

    const metaData = [
        {
            label: messages.areaRequested,
            value: areaRequestedValue
        },
        {
            label: messages.fileFormat,
            value: dataPackage.dataFormatName
        },
        {
            label: messages.updates,
            value: dataPackage.updateScheduleName
        },
        {
            label: messages.dateCreated,
            value: <FormattedDate value={dataPackage.createdOn}
                                  day='numeric'
                                  month='long'
                                  year='numeric'/>
        },
        {
            label: messages.createdBy,
            value: createdByValue
        }
    ];

    //Only display the price metadata if the correct feature is activated.
    if(featureCheck(feature.EAI, config) && hasCom3Catalogue(user)){
        //need to double check the product
        const terminalLabel = optionsLookup(dataPackage.terminals, productOptions?.terminals);
        if(dataPackage.terminals && terminalLabel){
             metaData.push({
                label: messages.terminals,
                value: terminalLabel
            })
        }
        if(dataPackage.users){
            metaData.push({
                label: messages.users,
                value: dataPackage.users
            })
        }
    }

    let mapImage = <div className={classes.noPreviewContainer}>
        <img width={88} src={noPreviewImage} alt='No preview available'/>
        <Typography variant='subtitle1' color='textSecondary'>
            <FormattedMessage {...messages.noPreview}/>
        </Typography>
    </div>;

    if (
        (dataPackage.fixedPriceAreas && dataPackage.fixedPriceAreas.length > 0) ||
        (dataPackage.tiles && dataPackage.tiles.length > 0) ||
        dataPackage.polygon
    ) {
        mapImage = <div className={classes.map}>
            <DataPackageMap productId={dataPackage.productId} showToolbar={false} expandable={true} dataPackage={dataPackage}/>
        </div>;
    }

    let linkPath;
    if(dataPackage.recipeId) {
        linkPath = routePaths.recipeDetails.replace(':recipeId', dataPackage.recipeId);
    } else {
        linkPath = routePaths.premiumItem.replace(':productId', dataPackage.productId);
    }

    return <div className={classes.packageMetadata}>

        {mapImage}

        <div className={classes.padLeft}>
            <Link path={linkPath}
                  className={classNames(classes.link, classes.marginRight)}>
                <Typography variant='body1'>
                    {dataPackage.productName}
                </Typography>
            </Link>
            {recipe && recipe.deleted && <NotActiveTag showToolTip={true}/>}
            <div className={classes.padBottom}>
                {
                    metaData.map(item =>
                        <div key={item.label.id} className={classes.aligned}>
                            <Typography variant='body1' color='textSecondary' className={classes.padRight}>
                                <FormattedMessage {...item.label}/>
                            </Typography>
                            <Typography variant='body1' component={'div'}>
                                {item.value}
                            </Typography>
                        </div>
                    )
                }
            </div>
        </div>
    </div>
}

DataPackageMetadata.propTypes = {
    dataPackage: PropTypes.object.isRequired
};
