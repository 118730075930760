import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';
import {createUseStyles} from 'react-jss';
import Typography from '@mui/material/Typography';
import {goToLandingPage} from "../../../util/routes";
import {useHistory} from 'react-router';
import TitledSingleColWithButton from '../../../components/layout/TitledSingleColWithButton';
import { ExternalLink } from 'omse-components';

const componentName = 'UserIssueContactAdmin';

export const messages = defineMessages({
    needToContactAdmin: {
        id: componentName + '.needToContactAdmin',
        defaultMessage: 'You need to contact your OS Data Hub account administrator',
        description: 'contact admin title'
    },
    notRegistered: {
        id: componentName + '.notRegistered',
        defaultMessage: "We can see you’re trying to sign up with an existing organisation but that organisation does not have you registered as a user on the OS Data Hub.",
        description: 'user not registered message'
    },
    youShould: {
        id: componentName + '.youShould',
        defaultMessage: 'You should contact your organisation’s OS Data Hub administrator and ask them to invite you to access your organisation’s OS Data Hub account.',
        description: 'contact admin instruction message'
    },
    noOrg: {
        id: componentName + '.noOrg',
        defaultMessage: "Your OS Data Hub account is not currently associated with an organisation.",
        description: 'no organisation'
    },
    contactUs: {
        id: componentName + '.contactUs',
        defaultMessage: `If you don’t know who your account administrator is, {link}.`,
        description: 'Displayed when we have failed to set up the new organisation'
    },
    dashboardMessage: {
        id: componentName + '.dashboardMessage',
        defaultMessage: "In the meantime, you may continue to use a free OS OpenData Plan.",
        description: 'Displayed when we have failed to set up the new organisation'
    },
    getOpenDataPlan: {
        id: componentName + '.getOpenDataPlan',
        defaultMessage: 'Get OS OpenData Plan',
        description: 'get os open data plan'
    },
    continueToMyDashboard: {
        id: componentName + '.continueToMyDashboard',
        defaultMessage: 'Continue to my dashboard',
        description: 'continue to my dashboard'
    }
});

const useStyles = createUseStyles(theme => ({
    emailLink: {
        '& a': {
            fontSize: theme.typography.caption.fontSize
        }
    }
}));

export default function UserIssueContactAdmin(props) {
    const {notRegistered, noOrganisation} = props;
    const classes = useStyles();
    const history = useHistory();
    return <TitledSingleColWithButton title={messages.needToContactAdmin}
                buttonAction={() => goToLandingPage(history)}
                buttonMessage={messages.getOpenDataPlan}>
        {notRegistered &&
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.notRegistered}/>
            </Typography>
        }

        {noOrganisation && 
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.noOrg}/>
            </Typography>
        }

        {(notRegistered || noOrganisation) &&
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.youShould}/>
            </Typography>
        }

        <Typography variant='body1' paragraph={true}>
            <FormattedMessage {...messages.dashboardMessage}/>
        </Typography>

        <Typography variant='caption' paragraph={true} className={classes.emailLink}>
            <FormattedMessage {...messages.contactUs} values={{ link: <ExternalLink type='support' /> }} />
        </Typography>
    </TitledSingleColWithButton>
}

UserIssueContactAdmin.propTypes = {
    notRegistered: PropTypes.bool,
    noOrganisation: PropTypes.bool
}

UserIssueContactAdmin.defaultProps = {
    notRegistered: false,
    noOrganisation: false
}