import React, {Fragment, useEffect} from 'react';
import {useHistory} from 'react-router';
import Typography from '@mui/material/Typography';
import FeatureCheck from '../../components/FeatureCheck';
import routes, {getLocation} from '../../util/routes';
import ContentHeader from '../../components/ContentHeader';
import {FormattedMessage, defineMessages} from 'react-intl';
import {contentPadding, osColour} from 'omse-components';
import {createUseStyles} from 'react-jss';
import {useSelector, useDispatch} from 'react-redux';
import {getPaymentHistory, getPaymentStatus} from '../../modules/payments/actions';
import PaymentList from './payments/PaymentList';
import PaymentFilters from './payments/PaymentFilters';
import {hasManageBillingPermission} from '../../util/permissions';
import {currencyFormatter, formatCost, getDateFrom} from './payments/PaymentHelperFunctions';
import CostInfoTooltip from '../../components/payment/CostInfoTooltip';
import useVatRateAdjuster from "../../hooks/useVatRateAdjuster";
import {CRM_PREMIUM_BELOW_PLAN, CRM_PREMIUM_ABOVE_PLAN} from "../../../shared/plans";

const messages = defineMessages({
	accountLink: {
		id: 'Payments.backLink',
		defaultMessage: 'Account settings',
		description: 'Label for the back link on the billing information page'
	},
	title: {
		id: 'Payments.title',
		defaultMessage: 'Payments',
		description: 'Title for the billing information page'
	},
	intro0: {
		id: 'Payments.intro0',
		defaultMessage: 'Current balance: {currentBalance} incl. VAT {tooltip}',
		description: 'Zeroth paragraph of intro text for the payment page'
	},
	intro0forZeroBalance: {
		id: 'Payments.intro0forZeroBalance',
		defaultMessage: 'Current balance: {currentBalance}',
		description: 'Zeroth paragraph of intro text when zero balance for the payment page'
	},
	intro1: {
		id: 'Payments.intro1',
		defaultMessage: 'Payments will be requested at the end of the month, or when your balance reaches {threshold} incl. VAT.',
		description: 'First paragraph of intro text for the payment page'
	},
	intro1VatNotAvailable: {
		id: 'Payments.intro1VatNotAvailable',
		defaultMessage: 'Payments will be requested at the end of the month, or when your balance reaches £2,000 excl. VAT.',
		description: 'First paragraph of intro text for the payment page when VAT rate not available'
	},
	intro2: {
		id: 'Payments.intro2',
		defaultMessage: 'We only store the last 12 months of payment information',
		description: 'Second paragraph of intro text for the billing page'
	},
	onlyAdmins1: {
		id: 'Payments.onlyAdmins1',
		defaultMessage: 'Only an Admin user or Finance user of this account can make changes to billing information.',
		description: 'First paragraph of intro text for non-admin view of page'
	},
	onlyAdmins2: {
		id: 'Payments.onlyAdmins2',
		defaultMessage:
			"If you have questions about billing, you should contact your organisation's Admin or Finance user.",
		description: 'Second paragraph of intro text for non-admin view of page'
	},
	error: {
		id: 'Payments.error',
		defaultMessage: 'We are unable to display your payment information at this time. Please try again later.',
		description: 'Message shown if we fail to load payment status from the server'
	},
	unavailableBill: {
		id: 'Payments.unavailableBill',
		defaultMessage: 'Estimated bill currently unavailable',
		description: 'Estimated bill unavailable'
	}
});

const styles = createUseStyles((theme) => {
	return {
		content: {
			flex: '1 1 auto',
			paddingLeft: contentPadding.left,
			paddingRight: contentPadding.right,
			paddingBottom: contentPadding.bottom,
			maxWidth: contentPadding.maxWidth,
			marginTop: theme.spacing(8),
			[theme.breakpoints.down('sm')]: {
				padding: contentPadding.mobile,
				margin: 0
			}
		},
		typoProp: {
			display: 'block',
			color: osColour.neutral.stone
		}
	};
});

export default function Payments() {
	const classes = styles();
	const dispatch = useDispatch();
	const history = useHistory();
	const paymentHistory = useSelector((state) => state.payments.history);
	const status = useSelector((state) => state.payments.status.result);
	const user = useSelector((state) => state.user.current.result);
	const vatRateAdjuster = useVatRateAdjuster();

	if(!user || (user.orgPlan !== CRM_PREMIUM_BELOW_PLAN && user.orgPlan !== CRM_PREMIUM_ABOVE_PLAN)) {
		// The orgPlan is used here so that users on plans that can never have payment information get navigated away.
		// Users who are on plans that can have payment information but don't have the right role get shown the
		// onlyAdmins1 and onlyAdmins2 messages.
		const newLocation = getLocation(routes.account, history.location);
		history.replace(newLocation);
	}

	useEffect(() => {
		// shows last 12 months of history by default
		dispatch(getPaymentHistory(getDateFrom()));
		dispatch(getPaymentStatus());
	}, [dispatch]);

	let balanceInclVat;
	const balance = status && status.balance;
	if (balance) {
		if (balance === 0) {
			balanceInclVat = balance;
		} else {
			if (vatRateAdjuster) {
				balanceInclVat = balance * vatRateAdjuster;
			}
		}
	}

	let thresholdInclVat;
	const threshold = status && status.threshold;
	if (threshold && vatRateAdjuster) {
		thresholdInclVat = threshold * vatRateAdjuster;
	}

	let pageContent = (
		<Fragment>
			{typeof balanceInclVat === 'undefined' &&
			<Typography variant='body1'>
				<FormattedMessage {...messages.unavailableBill}/>
			</Typography>
			}
			{balanceInclVat && balanceInclVat === 0 &&
			<Typography variant='body1'>
				<FormattedMessage {...messages.intro0forZeroBalance}
								  values={{
								  	currentBalance: <b>{currencyFormatter.format(formatCost(balanceInclVat))}</b>
								  }}
				/>
			</Typography>
			}
			{balanceInclVat && balanceInclVat > 0 &&
			<Typography variant='body1'>
				<FormattedMessage
					{...messages.intro0}
					values={{
						currentBalance: <b>{currencyFormatter.format(formatCost(balanceInclVat))}</b>,
						tooltip: <CostInfoTooltip />
					}}
				/>
			</Typography>
			}
			{typeof thresholdInclVat === 'undefined' &&
			<Typography variant='caption' className={classes.typoProp}>
				<FormattedMessage {...messages.intro1VatNotAvailable} />
			</Typography>
			}
			{typeof thresholdInclVat !== 'undefined' &&
			<Typography variant='caption' className={classes.typoProp}>
				<FormattedMessage
					{...messages.intro1}
					values={{
						threshold: <span>{currencyFormatter.format(formatCost(thresholdInclVat))}</span>,
					}}
				/>
			</Typography>
			}
			<Typography variant='caption' paragraph={true} className={classes.typoProp}>
				<FormattedMessage {...messages.intro2} />
			</Typography>
			<PaymentFilters />
			<PaymentList />
		</Fragment>
	);
	if (user && !hasManageBillingPermission(user)) {
		pageContent = (
			<Fragment>
				<Typography variant='body1' paragraph={true}>
					<FormattedMessage {...messages.onlyAdmins1} />
				</Typography>
				<Typography variant='body1' paragraph={true}>
					<FormattedMessage {...messages.onlyAdmins2} />
				</Typography>
			</Fragment>
		);
	} else if (paymentHistory.error || (!paymentHistory.loading && !paymentHistory.result)) {
		pageContent = (
			<Typography variant='body1' paragraph={true}>
				<FormattedMessage {...messages.error} />
			</Typography>
		);
	}

	return (
		<FeatureCheck feature='monetisation'>
			<ContentHeader
				backPath={routes.account} 
				backLabel={messages.accountLink} 
				title={messages.title} />
			<div className={classes.content}>{pageContent}</div>
		</FeatureCheck>
	);
}
