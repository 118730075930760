import {ACTIVE, EXPIRED, NOT_STARTED} from '../../../shared/free-trial-state';
import Typography from '@mui/material/Typography';
import {defineMessages, FormattedMessage} from 'react-intl';
import React from 'react';
import {osColour} from 'omse-components';
import classNames from 'classnames';
import {createUseStyles} from 'react-jss';
import {COMMERCIAL_ENQUIRIES_EMAIL, COMMERCIAL_ENQUIRIES_PHONE} from '../../constants/constants';

const messages = defineMessages({
    freeTrialNotStarted: {
        id: 'ProductFreeTrialMessage.freeTrialNotStarted',
        defaultMessage: 'Use this API free for {days} days',
        description: 'Text describing a free trial that has not yet started'
    },
    freeTrialActive: {
        id: 'ProductFreeTrialMessage.freeTrialActive',
        defaultMessage: '<b>Free trial in progress</b> ({freeTrialDaysRemaining} days remaining)',
        description: 'Text describing a free trial that is active'
    },
    freeTrialExpired: {
        id: 'ProductFreeTrialMessage.freeTrialExpired',
        defaultMessage: 'Your {productName} {freeTrialDays} days free trial has expired',
        description: 'Text describing a free trial that is expired'
    },
    freeTrialExpiredUnlock: {
        id: 'ProductFreeTrialMessage.freeTrialExpiredUnlock',
        defaultMessage: 'To unlock call: {phone}, or email: {email}',
        description: 'Text describing how to unlock a product with a free trial that is expired'
    },
});


const useStyles = createUseStyles({
    freeTrialText: {
        color: osColour.primary.foxglove
    },
});

export default function ProductFreeTrialMessage({className, product}) {
    const classes = useStyles();
    if(product.freeTrialState === NOT_STARTED) {
        return <Typography variant='body1' component='p' className={classNames(className, classes.freeTrialText)}>
            <FormattedMessage {...messages.freeTrialNotStarted} values={{days: product.freeTrialDays}} />
        </Typography>;
    }
    if(product.freeTrialState === ACTIVE) {
        return <Typography variant='body1' component='p' className={classNames(className, classes.freeTrialText)}>
            <FormattedMessage {...messages.freeTrialActive} values={{
                freeTrialDaysRemaining: product.freeTrialDaysRemaining,
                b: chunks => <Typography variant='body2' component='strong'>{chunks}</Typography>
            }} />
        </Typography>;
    }
    if(product.freeTrialState === EXPIRED) {
        return <div className={className}>
            <Typography variant='body2' component='p' className={classes.freeTrialText}>
                <FormattedMessage {...messages.freeTrialExpired} values={{
                    productName: product.displayName,
                    freeTrialDays: product.freeTrialDays
                }} />
            </Typography>
            <Typography variant='body1' component='p'>
                <ProductUnlockFormattedMessage />
            </Typography>
        </div>;
    }
    return null;
}

export function ProductUnlockFormattedMessage() {
    return <FormattedMessage {...messages.freeTrialExpiredUnlock} values={{
        phone: <a href={'tel:' + COMMERCIAL_ENQUIRIES_PHONE}>{COMMERCIAL_ENQUIRIES_PHONE}</a>,
        email: <a href={'mailto:' + COMMERCIAL_ENQUIRIES_EMAIL}>{COMMERCIAL_ENQUIRIES_EMAIL}</a>
    }} />;
}