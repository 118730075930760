import React, {useState, useMemo, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from "react-infinite-scroll-component";
import {List, ListItem, ListItemText} from '@mui/material';
import {useIntl, defineMessages} from 'react-intl';
import styles from './styles';

const messages = defineMessages({
    endMessage: {
        id: 'FileList.endMessage',
        defaultMessage: '... and {count} more',
        description: 'Message shown if someone scrolls to the end of the list and we cannot show any more results'
    }
});

const SCROLL_CHUNK_SIZE = 100;
const SCROLL_MAX_SIZE = 500;

export default function FileList(props) {
    const {files, selectedFile, setSelectedFile} = props;
    const [displayCount, setDisplayCount] = useState(SCROLL_CHUNK_SIZE);
    const classes = styles();
    const listRef = useRef();
    const intl = useIntl();

    useEffect(() => {
        // When the files collection changes then scroll back to the top, and reset the display count
        if(listRef.current) {
            listRef.current.scrollTop = 0;
        }
        setDisplayCount(SCROLL_CHUNK_SIZE);
    }, [files]);

    const endMessage = useMemo(() => {
        if(files.length <= SCROLL_MAX_SIZE) {
            return null;
        }

        const message = intl.formatMessage(messages.endMessage, { count: files.length - SCROLL_MAX_SIZE });
        return <ListItem>
            <ListItemText primary={message}/>
        </ListItem>;
    }, [intl, files]);

    const filesToShow = files.slice(0, displayCount);

    return <List className={classes.fileList} id="fileList" ref={listRef}>
        <InfiniteScroll dataLength={filesToShow.length}
                        hasMore={filesToShow.length < files.length && displayCount < SCROLL_MAX_SIZE}
                        next={() => setDisplayCount(displayCount + SCROLL_CHUNK_SIZE)}
                        endMessage={endMessage}
                        scrollableTarget="fileList">
            {
                filesToShow.map(f => {
                    let label = f.id || f;
                    if(f.name) {
                        label = f.name + ' - ' + f.id;
                    }
                    let disabled = false;
                    if(f.polygonAvailable === false) {
                        disabled = true;
                    }
                    return <ListItem key={label}
                                     button
                                     onClick={() => setSelectedFile(f)}
                                     selected={selectedFile === f}
                                     disabled={disabled}
                    >
                        <ListItemText primary={label}/>
                    </ListItem>
                })
            }
        </InfiniteScroll>
    </List>;
}

FileList.propTypes = {
    files: PropTypes.array,
    selectedFile: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    setSelectedFile: PropTypes.func.isRequired
}

FileList.defaultProps = {
    files: []
}