import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Pagination} from 'omse-components';
import {setDownloadsPageSize} from "../../../modules/dataPackages/actions";
import {useLocation, useHistory} from 'react-router';
import DataPackagesTable from "./DataPackagesTable";

export default function DataPackagesList(props) {
    const {packageList, loading} = props;
    const pageSize = useSelector(state => state.dataPackages.pageSize);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const page = (location.state && location.state.page) || 0;

    // Don't draw the table if we have nothing to show. The component is being kept alive to maintain the page size state.
    if(packageList.length === 0) {
        return null;
    }

    function setPage(page) {
        const state = {
            ...location.state,
            page
        }
        history.replace({
            ...location,
            state
        });
    }

    return <Fragment>
            {packageList &&
                <>
                    <DataPackagesTable packageList={packageList}/>
                    <Pagination page={{
                        number: page,
                        size: pageSize,
                        totalElements: packageList.length
                    }}
                                onChangePage={setPage}
                                onChangeRowsPerPage={size => {
                                    setPage(0);
                                    dispatch(setDownloadsPageSize(size));
                                }}
                                loading={loading}
                    />
                </>
            }
    </Fragment>;
}

DataPackagesList.propTypes = {
    packageList: PropTypes.array.isRequired,
    // loading: PropTypes.bool
};
