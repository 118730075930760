// The stats middleware is similar to the generic loading middleware, but we are working with a synchronous API
// As a result we need to schedule calls back to the server if the stats are still in use on the current page.
import {createLoadingMiddleware} from 'omse-components';

const TEN_SECONDS = 10 * 1000;
const TEN_MINUTES = 10 * 60 * 1000;

export function setupMiddleware(actionType, loadingActionType, prepareCall, expiry = TEN_MINUTES, retry = TEN_SECONDS) {
    const middleware = [];
    let currentAction = null;

    middleware.push(createLoadingMiddleware(actionType, loadingActionType, prepareCall, true));
    middleware.push(store => next => action => {
        switch(action.type) {
            case actionType: {
                currentAction = action;
                break;
            }
            case loadingActionType: {
                // There could be a load coming back for an old stats request. We don't want to let those through
                // to the store, so if we spot one then stop it from continuing on the middleware chain.
                if(action.originalAction !== currentAction) {
                    return null;
                }

                // If the server returned an error then the stats are not working, and we need to give up.
                // Check if the stats were actually empty, or out of date. If they are then we should re-run the
                // original action after the retry interval. After we schedule this timeout the code continues
                // on to send the action down the middleare chain.
                if(action.error) {
                    // Fall through
                } else if(action.result === null ||
                    (Date.now() - action.result.timestamp) > expiry) {

                    // Mark this result as updating, so that the UI can show that we are still loading
                    if(!action.result) {
                        action.result = {};
                    }
                    action.result.updating = true;

                    setTimeout(() => {
                        if(currentAction === action.originalAction) {
                            store.dispatch(action.originalAction);
                        }
                    }, retry);
                }
                break;
            }
            default:
            // Do nothing
        }
        return next(action);
    });

    return middleware;
}


