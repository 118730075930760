import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { osColour } from "omse-components";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { recentGenericMessages } from "./messages";

const ErrorContainer = styled("div")`
    display: grid;
    min-height: 10em;
    place-items: center;
    & > div {
        max-width: 24em;
        text-align: center;
        color: ${osColour.neutral.charcoal};
    }
`;

export default function RecentItemsError(p) {
    const { errorName } = p;
    return (
        <ErrorContainer>
            <div>
                <Typography variant="h4">
                    <FormattedMessage {...recentGenericMessages.errorTitle} />
                </Typography>
                <Typography variant="body1">
                    <FormattedMessage
                        {...recentGenericMessages.errorSummary}
                        values={{ item: errorName }}
                    />
                </Typography>
            </div>
        </ErrorContainer>
    );
}

RecentItemsError.propTypes = {
    errorName: PropTypes.string.isRequired,
};
