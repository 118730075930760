import React, { Fragment, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, FormattedDate } from "react-intl";
import { osColour } from 'omse-components';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography, CircularProgress, Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import StatsTimestamp from '../../../components/StatsTimestamp';
import BarGraph from "../../../components/BarGraph";
import ClickAwayTooltip from '../../../components/ClickAwayTooltip';
import APIUsage from "./transactions/APIUsage";
import { OPEN, PREMIUM_FREE, PREMIUM_CHARGEABLE, PSGA, statsLabels } from '../../../components/barGraph/styles/graph.js';
import { hasManageProjectModePermission, hasManageEaiApiPlan } from "../../../util/permissions";
import { isEaiUser } from '../../../util/plans';
import APIDialog from './transactions/APIDialog';
import useFeatureCheck from '../../../util/useFeatureCheck';
import { EAIAPI } from '../../../../shared/features';
import EaiTabMenu from './transactions/EAIAPIUsage/EaiTabMenu';
import EaiUsage from "./transactions/EAIAPIUsage/EaiUsage";
import { getProjects } from '../../../modules/projects';
import { getApiPlans, getApiProducts } from '../../../modules/commercialApiPlans/actions';
import { USER_PSGA_PLAN, USER_PREMIUM_DATA_PLAN, USER_OS_INTERNAL_PLAN } from '../../../../shared/plans';
import { EAI_TIER_OVERAGE } from '../../../../shared/constants';
import { OPEN_DATA_PERSONAL } from '../../../../shared/organisations';
import { LIVE_MODE, DEV_MODE } from "../../../../shared/project-modes";
import { ReactComponent as HelpIcon } from "../../../components/icons/help-notification.svg";

const messages = defineMessages({
    psgaPlanStartDateText: {
        id: 'Transactions.psgaPlanStartDateText',
        defaultMessage: 'Plan start date: ',
        description: 'Label for PSGA start date text'
    },
    transactionHeader: {
        id: 'Transactions.transactionHeader',
        defaultMessage: 'API usage this month',
        description: 'Label for transaction header label'
    },
    transactionHeaderPremiumLive: {
        id: 'Transactions.transactionHeaderPremiumLive',
        defaultMessage: 'API usage for live projects this month',
        description: 'Label for transaction header label (live mode)'
    },
    transactionHeaderPremiumDev: {
        id: 'Transactions.transactionHeaderPremiumDev',
        defaultMessage: 'API usage for development projects this month',
        description: 'Label for transaction header label (dev mode)'
    },
    mapViewsLabel: {
        id: 'Transactions.mapViewsLabel',
        defaultMessage: 'Map views this month',
        description: 'Label for map views graph'
    },
    featureRequestsLabel: {
        id: 'Transactions.featureRequestsLabel',
        defaultMessage: 'Feature requests this month',
        description: 'Label for feature requests graph'
    },
    mapViewsWorkspaceLabel: {
        id: 'Transactions.mapViewsWorkspaceLabel',
        defaultMessage: 'Map views',
        description: 'Label for map views graph'
    },
    featureRequestsWorkspaceLabel: {
        id: 'Transactions.featureRequestsWorkspaceLabel',
        defaultMessage: 'Feature requests',
        description: 'Label for feature requests graph'
    },
    whatIsMapLabel: {
        id: 'Help.whatIsMapLabel',
        defaultMessage: 'What is a map view?',
        description: 'Label for What is a map view tooltip'
    },
    whatIsMapBody: {
        id: 'Transactions.whatIsMapBody',
        defaultMessage: 'A map view represents 15 map tiles returned from a raster mapping API, or 4 map tiles from a vector tile API.',
        description: 'Content for What is a map view tooltip'
    },
    whatIsFeatureRequestLabel: {
        id: 'Transactions.whatIsFeatureRequestLabel',
        defaultMessage: 'What is a feature request?',
        description: 'Label for What is a feature request tooltip'
    },
    whatIsFeatureRequestBody: {
        id: 'Transactions.whatIsFeatureRequestBody',
        defaultMessage: 'A feature request is a single call to a Feature API, OS Names API or OS Linked Identifiers API.',
        description: 'Content for What is a feature request tooltip'
    }
});

const PSGAHeaderContainer = styled('div')(({ theme }) =>`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing(1.5)}
`)

const TransactionHeaderContainer = styled('div')(({ theme }) =>`
    display: flex;
    marginBottom: theme.spacing(1.5);
`)

const TransactionHeader = styled(Typography)(
  ({ theme }) => `
    margin-right: ${theme.spacing(1.5)}
`)

const GraphLabelContainer = styled('span')(({ theme }) =>`
    display: flex;
    gap: ${theme.spacing(1)}
`)

const BarGraphContainer = styled('div')(({theme, variant}) =>`
    margin-top: ${variant === 'dashboard' && theme.spacing(7)}
`)

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))`
    & .MuiTooltip-tooltip {
        border-radius: 10px;
        background: ${osColour.primary.lightestBerry};
        box-shadow: 0px 2px 4px 0px rgba(43, 37, 90, 0.25);
        padding: ${({ theme }) => theme.spacing(2)};
    }
`

export default function Transactions({mode, variant = 'dashboard'}) {
    const userDetails = useSelector(state => state.user.current.result);
    const userStats = useSelector(state => state.stats.user);
    const apiPlans = useSelector(state => state.commercialApiPlans.plans);
    const eaiProducts = useSelector(state => state.commercialApiPlans.products);
    const selectedOrgId = useSelector(state => state.organisation.current?.id);
    const projects = useSelector(state => state.projects.current);

    const [eaiMenuValue, setEaiMenuValue] = useState(1);

    const dispatch = useDispatch();
    const hasEAIAPIFeature = useFeatureCheck(EAIAPI);

    const isPersonal = userDetails.orgs?.find(org => org.value === selectedOrgId)?.type === OPEN_DATA_PERSONAL;
    const hasLiveProjects = projects.result?.some(project => project.mode === LIVE_MODE);

    const isLiveMode = mode===LIVE_MODE;

    useEffect(()=>{
        const isEai = hasEAIAPIFeature && isEaiUser(userDetails);
        if(isEai && isLiveMode && !isPersonal){
            setEaiMenuValue(0);
            dispatch(getApiPlans());
            dispatch(getApiProducts());
        }
        return ()=>setEaiMenuValue(1);
    }, [dispatch, hasEAIAPIFeature, userDetails, userDetails.plan, isLiveMode, isPersonal]);

    useEffect(()=>{
        if(hasEAIAPIFeature && isEaiUser(userDetails) && projects.result === null){
            dispatch(getProjects())
        }
    }, [dispatch, projects.result, hasEAIAPIFeature, userDetails, userDetails.plan]);

    // Only display stats if the current result matches the mode that we are asked to show
    const userResult = userStats.result && (userStats.result.mode === mode) && userStats.result;

    function displayProductUsage(type, graphMessage, tooltip) {
        if (userResult) {
            let openData = {
                name: statsLabels.open,
                data: 0,
                class: OPEN
            };
            let premiumFreeData =  {
                name: statsLabels.premium.free,
                data: 0,
                class: PREMIUM_FREE
            };
            let premiumChargeableData =  {
                name: statsLabels.premium.chargeable,
                data: 0,
                class: PREMIUM_CHARGEABLE
            };
            let psgaData =  {
                name: statsLabels.psga,
                data: 0,
                class: PSGA
            };
            let internalData = {
                name: statsLabels.internal,
                data: 0,
                class: PREMIUM_FREE
            }
            let commercialData = {
                name: statsLabels.commercial.dev,
                data: 0,
                class: PREMIUM_FREE
            }

            let totalUsageForUser = 0;
            let totalUsageForProductType = 0;

            if (mode === DEV_MODE) {
                    premiumFreeData.name = statsLabels.premium.dev;
            }

            if (userResult.productTypes && userResult?.productTypes[type]) {

                if (userDetails.plan === USER_PSGA_PLAN) {
                    totalUsageForUser = userResult.open.transactions + userResult.psga.transactions;
                    totalUsageForProductType = userResult.productTypes[type].open + userResult.productTypes[type].psga;
                    psgaData.data = userResult.productTypes[type].psga;
                } else if (userDetails.plan === USER_OS_INTERNAL_PLAN) {
                    totalUsageForUser = userResult.open.transactions + userResult.internal.transactions;
                    totalUsageForProductType = userResult.productTypes[type].open + userResult.productTypes[type].internal;
                    internalData.data = userResult.productTypes[type].internal;
                } else if (userDetails.plan === USER_PREMIUM_DATA_PLAN) {
                    totalUsageForUser = userResult.open.transactions + userResult.premium.transactions;
                    totalUsageForProductType = userResult.productTypes[type].open + userResult.productTypes[type].premium.total.transactions;
                    premiumFreeData.data = userResult.productTypes[type].premium.free;
                    premiumChargeableData.data = userResult.productTypes[type].premium.chargeable;
                } else if (isEaiUser(userDetails)
                    && hasEAIAPIFeature
                    && mode === DEV_MODE) {
                    totalUsageForUser = userResult.open.transactions + userResult.commercial.transactions;
                    totalUsageForProductType = userResult.productTypes[type].open + userResult.productTypes[type].commercial;
                    commercialData.data = userResult.productTypes[type].commercial;
                } else {
                    totalUsageForUser = userResult.open.transactions;
                    totalUsageForProductType = userResult.productTypes[type].open;
                }
                openData.data = userResult.productTypes[type].open;
            }
            const breakdown = [openData, premiumFreeData, premiumChargeableData, psgaData, internalData, commercialData];
            const graphData = [{total: totalUsageForProductType, breakdown}];

            return <BarGraph graphData={graphData}
                             total={totalUsageForUser}
                             count={totalUsageForProductType}
                             graphLabel={graphMessage}
                             graphStyle={{height: 14}}
                             counterTextStyle={{color: osColour.neutral.stone}}
                             tooltip={tooltip}
                             displayCount={true}
                             displayCountLabel={false}
                    />
        }
    }

    let headerMessage = messages.transactionHeader;
    if(hasManageProjectModePermission(userDetails)) {
        if (isLiveMode) {
            headerMessage = messages.transactionHeaderPremiumLive;
        }
        if (mode === DEV_MODE) {
            headerMessage = messages.transactionHeaderPremiumDev;
        }
    }

    const eaiLoading = apiPlans.loading || eaiProducts.loading || projects.loading;
    let eaiContent = eaiLoading &&<CircularProgress size={32}/>
    const isEAIAPI = hasEAIAPIFeature && isEaiUser(userDetails) && !isPersonal;
    const eaiApiPlans = !apiPlans.loading && apiPlans.result;
    let hasApiProducts = !eaiProducts.loading && eaiProducts.result;

    if (isEAIAPI && isLiveMode) {
        let currentEaiPlan = null;
        let eaiDemoProduct = null;

        if(eaiApiPlans?.length > 0){
            currentEaiPlan = eaiApiPlans.find(plan => {
                return plan.provisionalActivePlan && plan.tierId!==EAI_TIER_OVERAGE;
            });
            if(!currentEaiPlan){
                //if we haven't found a current plan, we need to look for the most recent one that has ended,
                //if 2 plans have ended on the same date, their provisional ends will always be the same,
                //then we need to show the most recently purchased that has ended.
                eaiApiPlans.filter(plan => plan.provisionalEnd && plan.tierId !== EAI_TIER_OVERAGE)
                    .forEach(plan =>{
                        const currentProvEnd = new Date(currentEaiPlan?.provisionalEnd || "2000-01-01T00:00:00.000");
                        const currentCreated = new Date(currentEaiPlan?.created || "2000-01-01T00:00:00.000");
                        const planProvEnd = new Date(plan.provisionalEnd);
                        const planCreated = new Date(plan.created);

                        const planEndedSooner = planProvEnd.getTime()> currentProvEnd.getTime();
                        const planEndedSameTime = planProvEnd.getTime() === currentProvEnd.getTime();
                        const planCreatedSooner = planCreated.getTime() > currentCreated.getTime();

                        if(planEndedSooner || (planEndedSameTime && planCreatedSooner)){
                            currentEaiPlan = plan;
                        }
                });
            }
        }

        if(hasApiProducts){
            eaiDemoProduct = eaiProducts.result.find(product => product.demoProduct);
            eaiContent = eaiDemoProduct && <EaiUsage currentPlan={currentEaiPlan} demoProduct={eaiDemoProduct} hasManageApiPermission={hasManageEaiApiPlan(userDetails)}/>
        }
    }

    const isDashboardVariant = variant === "dashboard"

    let transactionsHeader, mapGraphMessage, featureGraphMessage, mapClickTooltip, featureGraphClickTooltip

    if (isDashboardVariant) {
		transactionsHeader = (
			<TransactionHeaderContainer>
				<TransactionHeader variant="h2">
					<FormattedMessage {...headerMessage} />
				</TransactionHeader>
				<StatsTimestamp stats={userStats} />
			</TransactionHeaderContainer>
		);
        mapGraphMessage = (
            <FormattedMessage {...messages.mapViewsLabel} />
        ) 
        featureGraphMessage = (
            <FormattedMessage {...messages.featureRequestsLabel} />
        )
        mapClickTooltip = (
            <ClickAwayTooltip
                label={messages.whatIsMapLabel}
                body={messages.whatIsMapBody}
                id="whatIsMapTx"
            />
        )
        featureGraphClickTooltip = (
            <ClickAwayTooltip
                label={messages.whatIsFeatureRequestLabel}
                body={messages.whatIsFeatureRequestBody}
                id="whatIsFeatureReqTx"
            />
        )
	} else {
        mapGraphMessage = (
            <GraphLabelContainer>
                <FormattedMessage {...messages.mapViewsWorkspaceLabel} />
                <HoverToolTip label={messages.whatIsMapLabel} content={messages.whatIsMapBody}/>
            </GraphLabelContainer>
        )
        featureGraphMessage = (
            <GraphLabelContainer>
                <FormattedMessage {...messages.featureRequestsWorkspaceLabel} />
                <HoverToolTip label={messages.whatIsFeatureRequestLabel} content={messages.whatIsFeatureRequestBody}/>
            </GraphLabelContainer>
        )
    }

    return (
		<div data-testid="transactions" tabIndex={0}>
			{isDashboardVariant && isEAIAPI && isLiveMode && (
				<APIDialog
					loading={eaiLoading}
					hasEaiApiPlans={eaiApiPlans?.length > 0}
					hasProjects={hasLiveProjects}
					hasManageApiPermission={hasManageEaiApiPlan(userDetails)}
				/>
			)}
			{isDashboardVariant && userDetails.plan === USER_PSGA_PLAN && userDetails.planStartDate && (
				<PSGAHeaderContainer>
					<Typography variant="h5" component="p">
						<FormattedMessage {...messages.psgaPlanStartDateText} />
						<FormattedDate value={userDetails.planStartDate} day="numeric" month="long" year="numeric" />
					</Typography>
				</PSGAHeaderContainer>
			)}
			{isDashboardVariant && isEAIAPI && isLiveMode && <EaiTabMenu clickHandler={setEaiMenuValue} activeTab={eaiMenuValue} />}
			{isDashboardVariant && isEAIAPI && eaiMenuValue === 0 && eaiContent}
			{(!isEAIAPI || eaiMenuValue === 1) && (
				<>
					{transactionsHeader}
					{userResult?.timestamp && (
						<Fragment>
							<APIUsage userStatsResult={userResult} />
							<BarGraphContainer>
								{displayProductUsage(
									"map",
                                    mapGraphMessage,
                                    mapClickTooltip
								)}
								{displayProductUsage(
									"feature",
									featureGraphMessage,
                                    featureGraphClickTooltip
								)}
							</BarGraphContainer>
						</Fragment>
					)}
				</>
			)}
		</div>
	);
}

const HoverToolTip = ({ label, content }) => {
    const theme = useTheme()
    return (
        <StyledTooltip 
            tabIndex={0}
            role={'button'}
            title={
                <>
                    <Typography sx={{ fontWeight: 600, marginBottom: theme.spacing(1)}}>
                        <FormattedMessage { ...label}/>
                    </Typography>
                    <Typography>
                        <FormattedMessage { ...content}/>
                    </Typography>
                </>
            } 
            placement={'top'}
        >
            <HelpIcon width={24} height={24} color={osColour.primary.berry}/>
        </StyledTooltip>
    )
}

HoverToolTip.propTypes = {
    label: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired
}

Transactions.propTypes = {
    mode: PropTypes.string.isRequired,
    variant: PropTypes.string
};

