// This module handles the single project that the user has currently selected in the app. The project
// details are loaded as the user navigates to the project.

import {createLoadingReducer, createLoadingMiddleware} from 'omse-components';
import {LOAD_PROJECT_ACTION, LOADED_PROJECT_ACTION} from "./project/actions";
import {reducer as addProductReducer, middleware as addProductMiddleware} from './project/addProduct';
import {reducer as createReducer, middleware as createMiddleware} from './project/create';
import {reducer as deleteReducer, middleware as deleteMiddleware} from './project/delete';
import {reducer as setModeReducer, middleware as setModeMiddleware} from './project/setMode';
import {reducer as setNameReducer, middleware as setNameMiddleware} from './project/setName';
import {reducer as regenerateKeyReducer, middleware as regenerateKeyMiddleware} from './project/regenerateKey';
import {reducer as deleteProductReducer, middleware as deleteProductMiddleware} from './project/deleteProduct';
import {reducer as newProjectReducer} from './project/newProject';
import {combineReducers} from 'redux';
import {withOrganisation} from '../util/organisation';

export const projectReducer = combineReducers({
    current: createLoadingReducer(LOAD_PROJECT_ACTION, LOADED_PROJECT_ACTION),
    addProduct: addProductReducer,
    create: createReducer,
    delete: deleteReducer,
    setMode: setModeReducer,
    setName: setNameReducer,
    regenerateKey: regenerateKeyReducer,
    deleteProduct: deleteProductReducer,
    newProject: newProjectReducer
});

function prepareCall(action, store) {
    return withOrganisation('/api/projects/'+ action.projectId, store);
}
export const projectMiddleware = [
    createLoadingMiddleware(LOAD_PROJECT_ACTION, LOADED_PROJECT_ACTION, prepareCall),
    addProductMiddleware,
    createMiddleware,
    deleteMiddleware,
    setModeMiddleware,
    setNameMiddleware,
    regenerateKeyMiddleware,
    deleteProductMiddleware,
];
