import {
    createActionMiddleware,
    createActionReducer,
    createLoadingMiddleware,
    createLoadingReducer
} from 'omse-components';
import {
    CREATE_DATA_PACKAGE_ACTION,
    DELETE_DATA_PACKAGE_ACTION,
    GET_DATA_PACKAGE_PRICE_ACTION,
    LOAD_DATA_PACKAGE_ACTION,
    LOAD_DATA_PACKAGE_VERSION_ACTION,
    LOAD_DATA_PACKAGES_ACTION,
    LOADED_DATA_PACKAGE_ACTION,
    LOADED_DATA_PACKAGE_VERSION_ACTION,
    LOADED_DATA_PACKAGES_ACTION,
    RESET_DRAFT_ORDER_ACTION,
    SET_DATA_PACKAGE_NAME_ACTION,
    STOP_DATA_PACKAGE_UPDATES_ACTION,
    UPDATE_DATA_PACKAGE_DOWNLOAD_FORMAT_ACTION,
    UPDATE_DRAFT_ORDER_ACTION
} from './dataPackages/actions';
import {combineReducers} from 'redux';
import routes, {getLocation} from "../util/routes";
import {defineMessages} from 'react-intl';
import {isNgdDataPackage} from "../../shared/ngd";

const messages = defineMessages({
    deleteSuccess: {
        id: 'DataPackages.deleteSuccess',
        defaultMessage: 'You have successfully deleted your data package.',
        description: 'delete success message'
    }
});

const initialPageSize = 10;

export const dataPackageReducer = combineReducers({
    create: createActionReducer(CREATE_DATA_PACKAGE_ACTION),
    setName: createActionReducer(SET_DATA_PACKAGE_NAME_ACTION),
    delete: createActionReducer(DELETE_DATA_PACKAGE_ACTION),
    stopUpdates: createActionReducer(STOP_DATA_PACKAGE_UPDATES_ACTION),
    updateFormat: createActionReducer(UPDATE_DATA_PACKAGE_DOWNLOAD_FORMAT_ACTION),
    price: createActionReducer(GET_DATA_PACKAGE_PRICE_ACTION),
    list: createLoadingReducer(LOAD_DATA_PACKAGES_ACTION, LOADED_DATA_PACKAGES_ACTION, true),
    current: createLoadingReducer(LOAD_DATA_PACKAGE_ACTION, LOADED_DATA_PACKAGE_ACTION),
    version: createLoadingReducer(LOAD_DATA_PACKAGE_VERSION_ACTION, LOADED_DATA_PACKAGE_VERSION_ACTION),
    pageSize: (size = initialPageSize, action) => {
        return (action && action.size) || size;
    },
    draftOrder: draftOrderReducer
});

export const dataPackageMiddleware = [
    // Lock the current data package as soon as a delete request is issued. This must be defined before the action
    // middleware, as the action middleware will consume the action as soon as it receives it.
    store => next => action => {
        if(action &&
            (action.type === DELETE_DATA_PACKAGE_ACTION
                || action.type === STOP_DATA_PACKAGE_UPDATES_ACTION
                || action.type === UPDATE_DATA_PACKAGE_DOWNLOAD_FORMAT_ACTION)) {
            setCurrentDataPackageLock(store, action.packageId);
        }
        return next(action);
    },

    createActionMiddleware(CREATE_DATA_PACKAGE_ACTION, setupCreateDataPackage, lockDataPackage),
    createActionMiddleware(SET_DATA_PACKAGE_NAME_ACTION, setupSetDataPackageName, successfullySetName),
    createActionMiddleware(DELETE_DATA_PACKAGE_ACTION, setupDeleteDataPackage, packageDeleted),
    createActionMiddleware(STOP_DATA_PACKAGE_UPDATES_ACTION, setupStopUpdates, updatesStopped),
    createActionMiddleware(UPDATE_DATA_PACKAGE_DOWNLOAD_FORMAT_ACTION, setupUpdateFormat, formatUpdated),
    createActionMiddleware(GET_DATA_PACKAGE_PRICE_ACTION, setupPrice, priceReturned),
    createLoadingMiddleware(LOAD_DATA_PACKAGES_ACTION, LOADED_DATA_PACKAGES_ACTION, prepareLoadPackagesCall),
    createLoadingMiddleware(LOAD_DATA_PACKAGE_ACTION, LOADED_DATA_PACKAGE_ACTION, prepareLoadPackageCall),
    createLoadingMiddleware(LOAD_DATA_PACKAGE_VERSION_ACTION, LOADED_DATA_PACKAGE_VERSION_ACTION, prepareLoadPackageVersionCall)
];


function setupCreateDataPackage(action) {
    return {
        url: '/api/dataPackages',
        method: 'POST',
        body: action.order
    };
}

function lockDataPackage(store, action) {
    // A request to expand an existing data package needs to lock the data package, so that we can't expand it again
    // until this expansion is complete. The order only includes an id if the package already existed, and if there
    // is no id then the call to setCurrentDataPackageLock is harmless
    setCurrentDataPackageLock(store, action.order.id);
}

function setCurrentDataPackageLock(store, dataPackageId) {
    const state = store.getState();
    const currentDataPackage = state.dataPackages.current.result;
    if (currentDataPackage && currentDataPackage.id === dataPackageId) {
        const updatedPackage = {
            ...currentDataPackage,
            locked: true
        };

        store.dispatch({ type: LOADED_DATA_PACKAGE_ACTION, result: updatedPackage });
    }
}

function setupSetDataPackageName(action) {
    return {
        url: '/api/dataPackages/' + action.packageId + '/name',
        method: 'PUT',
        body: {
            name: action.name
        }
    };
}

function setupDeleteDataPackage(action) {
    return {
        url: '/api/dataPackages/' + action.packageId,
        method: 'DELETE'
    };
}

function setupStopUpdates(action) {
    return {
        url: '/api/dataPackages/' + action.packageId + "/updates",
        method: 'DELETE'
    };
}

function setupUpdateFormat(action) {
    return {
        url: '/api/dataPackages/' + action.dataPackage.id + '/update/format',
        method: 'PUT',
        body: action.dataPackage
    }
}

function setupPrice(action) {
    return {
        url: '/api/price/',
        method: 'POST',
        body: action.dataPackage
    };
}

function prepareLoadPackagesCall(action) {
    return '/api/dataPackages';
}

function prepareLoadPackageCall(action) {
    return '/api/dataPackages/' + action.packageId;
}

function prepareLoadPackageVersionCall(action) {
    return '/api/dataPackages/' + action.packageId + '/' + action.versionId;
}

// Once the data package name has been set, we should update the data package and data package lists with the new name.
function successfullySetName(store, action) {
    function updatePackage(dataPackage) {
        if(dataPackage && dataPackage.id === action.packageId) {
            return {
                ...dataPackage,
                name: action.name
            };
        }
        return dataPackage;
    }

    const state = store.getState();

    const updatedPackage = updatePackage(state.dataPackages.current.result);
    if(updatedPackage) {
        store.dispatch({ type: LOADED_DATA_PACKAGE_ACTION, result: updatedPackage });
    }

    const dataPackageList = state.dataPackages.list.result;
    if(dataPackageList) {
        const updatedList = dataPackageList.map(updatePackage);
        store.dispatch({ type: LOADED_DATA_PACKAGES_ACTION, result: updatedList });
    }
}

function formatUpdated(store, action) {
    function updatePackage(dataPackage) {
        if (dataPackage && dataPackage.id === action.dataPackage.id) {
            return {
                ...dataPackage,
                dataFormatId: action.dataPackage.format.id,
                dataFormatName: action.dataPackage.format.label
            };
        }
        return dataPackage;
    }

    const state = store.getState();
    const currentPackage = state.dataPackages.current.result;
    const updatedPackage = updatePackage(currentPackage);

    if (updatedPackage){
        store.dispatch({type: LOADED_DATA_PACKAGE_ACTION, result: updatedPackage});
    }
}

function packageDeleted(store, action) {
    // delete the package from the stored list
    const state = store.getState();
    const dataPackageList = state.dataPackages.list.result;
    if(dataPackageList) {
        const updatedList = dataPackageList.filter(p => { return p && p.id !== action.packageId });
        store.dispatch({ type: LOADED_DATA_PACKAGES_ACTION, result: updatedList });
    }

    // Now that the package has been deleted, navigate to the packages list.
    const history = state.history;
    const newLocation = getLocation(routes.dataPackages, history.location);
    history.replace(newLocation, {toastMessage: messages.deleteSuccess});
}

function updatesStopped(store, action) {

    function updateNgdDataPackage(dataPackage, user) {
        let changeLog = dataPackage.changeLog || [];

        changeLog.unshift({
            changeCode: "UPDATES_STOPPED",
            changedBy: `${user.firstName} ${user.lastName}`,
            date: new Date().toISOString()
        });

        return {
            ...dataPackage,
            updateScheduleName: "Not Required",
            status: "COMPLETED",
            locked: false,
            changeLog
        };
    }

    function updatePremiumDataPackage(dataPackage, user) {
        let changeLog = dataPackage.changeLog || [];

        changeLog.unshift({
            changeCode: "UPDATES_STOPPED",
            changedBy: `${user.firstName} ${user.lastName}`,
            date: new Date().toISOString()
        });

        return {
            ...dataPackage,
            updateScheduleId: "NOTREQD",
            updateScheduleName: "Not Required",
            locked: false,
            changeLog
        };
    }

    function updatePackage(dataPackage, user) {
        if(!dataPackage || dataPackage.id !== action.packageId) {
            return;
        }

        if (isNgdDataPackage(dataPackage)) {
            return updateNgdDataPackage(dataPackage, user);
        } else {
            return updatePremiumDataPackage(dataPackage, user);
        }
    }

    const state = store.getState();
    const user = state.user.current.result;
    const updatedPackage = updatePackage(state.dataPackages.current.result, user);

    if(updatedPackage) {
        store.dispatch({ type: LOADED_DATA_PACKAGE_ACTION, result: updatedPackage });
    }
}

function priceReturned(store) {
    const state = store.getState();

    // Normally there is an invoicePrice but some products, like Topo Sites, are zero-rated and only have a totalPrice.
    const invoicePrice = state.dataPackages.price?.result?.invoicePrice;
    const totalPrice = state.dataPackages.price?.result?.totalPrice;
    const newPrice = invoicePrice ? invoicePrice : totalPrice;
    const currentPrice = state.dataPackages.draftOrder?.price;

    if (newPrice !== currentPrice) {
        store.dispatch({ type: UPDATE_DRAFT_ORDER_ACTION, name: 'price', value: newPrice });
    }
}

// When the user presses the 'reset all' button in the UI we don't actually want to clear all of the
// draft order, as some of the fields are set up initially and need to keep their values. This array
// allows us to handle them all in a consistent way.
const PRESERVED_FIELDS = ['packageName', 'catalogueId', 'productId', 'recipeId', 'tiled', 'hasFilters'];

function draftOrderReducer(state = {packageName: ''}, action) {
    switch (action.type) {
        case RESET_DRAFT_ORDER_ACTION:
            const result = {}
            if(!action.resetAll) {
                PRESERVED_FIELDS.forEach(f => {
                    result[f] = state[f];
                });
            }
            return result;
        case UPDATE_DRAFT_ORDER_ACTION:
            return {
                ...state,
                [action.name]: action.value
            }
        default:
            return state;
    }
}
