import React from 'react';
import Typography from '@mui/material/Typography';
import {createUseStyles} from 'react-jss';
import getCommonStyles from "../../../styles/common";
import Table, {OUTLINE} from '../../../documentation/Table';

const useStylesCommon = createUseStyles(getCommonStyles);

const useStyles = createUseStyles(theme => ({
    tableData: {
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro,sans-serif',
        fontWeight: 600,
        lineHeight: 1.43
    },
    product: {
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro,sans-serif',
        fontWeight: 400,
        lineHeight: 1.5
    },
    table: {
        '& thead th': {
            padding: theme.spacing(2)
        },
        '& th, td': {
            padding: theme.spacing(1,2)
        }
    }
}));

export default function TransactionTable(props) {
    const {products} = props;
    const classesCommon = useStylesCommon(props);
    const classes = useStyles(props);

    const getSup = (sups) => <sup className={classesCommon.superScriptColor}>{sups}</sup>;
    const getSups = (product)=>{
        const sups = [];
        product.publicSectorAndPremiumOnly && sups.push(1);
        product.premiumOnly && sups.push(2)
        product.excludeEai && sups.push(3)
        return getSup(sups.join(', '))
    }

    return <>
        <Table variant={OUTLINE} className={classes.table}>
            <thead>
                <tr>
                    <Typography variant='subtitle2' component='th'>API</Typography>
                    <Typography variant='subtitle2' component='th'>1 Transaction</Typography>
                </tr>
            </thead>
            <tbody>
                {products.map(product => {
                    return (
                        <tr key={product.productName}>
                            <th className={classes.tableData}>
                                {product.productName}
                                {getSups(product)}
                            </th>
                            <td className={classes.product}>{product.transactionTypes.map(t => <div key={t.name}>{t.name}</div>)}</td>
                        </tr>)
                    })
                }
            </tbody>
        </Table>
        {
            products
                .find(product => product.publicSectorAndPremiumOnly) &&
                    <p id="publicSectorAndPremiumOnly">{getSup(1)} Only for Public Sector Plan and Premium Plan organisations</p>
        }
        {
            products
                .find(product => product.premiumOnly) &&
                    <p id="premiumOnly">{getSup(2)} Only for Premium Plan organisations</p>
        }
        {
            products
                .find(product => product.excludeEai) &&
                <p id="excludeEai">{getSup(3)} Not available for Energy and Infrastructure Plan Users</p>
        }
    </>
}
