import { osColour } from "omse-components";
import styled from "@emotion/styled";

export const singleColBreakpoint = 920;
export const horizontalPadding = '3em';

const StyledWidthDelimiter = styled('div')`
    margin: 0 auto;
    padding: 0;
    max-width: 1400px;
`;

const StyledContainer = styled('div')`
    margin: 5em ${horizontalPadding};
    background: ${osColour.neutral.white};
    @media (max-width: 600px) {
        margin: 2em 1em;
    }
    & h2 {
        margin-bottom: 1em;
        color: ${osColour.primary.berry};
        font-family: OSGill, sans-serif;
        @media (max-width: ${singleColBreakpoint}px) {
            margin-bottom: 0.75em;
        }
    }
`;

const ContentContainer = ({ children }) => (
    <StyledContainer>
        <StyledWidthDelimiter>
            {children}
        </StyledWidthDelimiter>
    </StyledContainer>
);

export default ContentContainer;