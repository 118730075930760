import React from 'react';
import {useDispatch} from 'react-redux';
import {defineMessages} from "react-intl";
import {ConfirmationDialog} from 'omse-components';
import {cancelInvitation} from "../../../modules/organisation/action";

const messages = defineMessages({
    title: {
        id: 'CancelInvitationDialog.title',
        defaultMessage: 'Cancel invitation',
        description: 'Title for the cancel invitation dialog'
    },
    confirm: {
        id: 'CancelInvitationDialog.confirm',
        defaultMessage: 'Are you sure that you want to cancel this invitation?',
        description: 'Content for the cancel invitation dialog'
    },
    action: {
        id: 'CancelInvitationDialog.action',
        defaultMessage: 'Cancel invitation',
        description: 'Label for the cancel invitation button'
    }
});

export default function CancelInvitationDialog({invitedContactId, handleClose}) {
    const dispatch = useDispatch();

    return <ConfirmationDialog title={messages.title}
                               contentMessages={[messages.confirm]}
                               confirmationAction={() => {
                                   dispatch(cancelInvitation(invitedContactId));
                                   handleClose();
                               }}
                               confirmationButtonLabel={messages.action}
                               handleClose={handleClose}
    />;
}
