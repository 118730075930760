import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {CommonDialog, CommonDialogActions} from 'omse-components';
import {Typography} from '@mui/material';

const messages = defineMessages({
    title: {
        id: 'ConfirmOverwriteModal.title',
        defaultMessage: 'Replace current file?',
        description: 'Title for the confirm overwrite modal'
    },
    confirm: {
        id: 'ConfirmOverwriteModal.confirm',
        defaultMessage: 'Replace file',
        description: 'Label for the save button'
    },
    question: {
        id: 'ConfirmOverwriteModal.question',
        defaultMessage: '"{name}" already exists. Do you want to replace it?',
        description: 'Text for the content of the dialog'
    }
});

export default function ConfirmOverwriteModal(props) {
    const {name, onClose} = props;

    const actions = <CommonDialogActions confirmLabel={messages.confirm}
                                         onClose={onClose}
                                         onConfirm={() => onClose(true)}/>;

    return <CommonDialog actions={actions}
                         onClose={onClose}
                         title={messages.title}>
        <Typography variant='body1'>
            <FormattedMessage {...messages.question} values={{
                name
            }}/>
        </Typography>
    </CommonDialog>;
}