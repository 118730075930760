import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getPremiumProduct} from "../../../modules/premium/actions";
import AddDataPackageNgdPoweredPremium from "./AddDataPackageNgdPoweredPremium";
import AddDataPackageLegacyPremium from "./AddDataPackageLegacyPremium";
import routes, {useRedirect} from "../../../util/routes";
import { CircularProgress } from "@mui/material";

// When adding premium data packages we may be working with a legacy product, or a ngd-powered one. This component
// takes care of loading the correct product (which may already be available in the location state) and then putting
// the correct Legacy / NGD powered component into the page.
export default function AddDataPackagePremium() {
    const { productId } = useParams();
    const { state } = useLocation();
    const redirect = useRedirect();

    const dispatch = useDispatch();
    const productFromStore = useSelector(state => state.premium.product.result);
    const errorFromStore = useSelector(state => state.premium.product.error);

    const [loading, setLoading] = useState(false);

    // Look for the product in the location state. If it is not there then we will need to load it from the redux store
    // instead
    let product = state?.product || productFromStore;
    if(product && product.id !== productId) {
        product = null;
    }
    useEffect(function() {
        if(!product) {
            dispatch(getPremiumProduct(productId));
            setLoading(true);
        }
    }, [product, productId, dispatch]);

    // Handle errors loading the product details
    useEffect(function() {
        if(loading && errorFromStore) {
            // We have failed to load the product details. Let's go back to the premium product list
            redirect.replace(routes.premiumDownloads);
        }
    }, [errorFromStore, loading, redirect]);

    if(product) {
        if(product.ngdPoweredProduct) {
            return <AddDataPackageNgdPoweredPremium product={product}/>;
        } else {
            return <AddDataPackageLegacyPremium/>;
        }
    } else {
        // We are still loading the product
        return <CircularProgress/>;
    }
}

