import {defineMessages} from 'react-intl';

export const titleMessages = defineMessages({
    distribution: {
        id: 'PartnerContractTitle.Distribution',
        defaultMessage: 'Distribution',
        description: 'Distribution'
    },
    consumer: {
        id: 'PartnerContractTitle.ConsumerSolutions',
        defaultMessage: 'Consumer Solutions',
        description: 'Consumer Solutions'
    },    
    printed: {
        id: 'PartnerContractTitle.PrintedProducts',
        defaultMessage: 'Printed Products',
        description: 'Printed Products'
    },
    developer: {
        id: 'PartnerContractTitle.Developer',
        defaultMessage: 'Developer',
        description: 'Developer'
    },
    businessOnline: {
        id: 'PartnerContractTitle.BusinessOnline',
        defaultMessage: 'Business Solutions – Online Viewing',
        description: 'Business Solutions – Online Viewing'
    },
    businessData: {
        id: 'PartnerContractTitle.BusinessData',
        defaultMessage: 'Business Solutions – Data Components',
        description: 'Business Solutions – Data Components'
    },
    trackingScheduling: {
        id: 'PartnerContractTitle.TrackingScheduling',
        defaultMessage: 'Tracking and Scheduling',
        description: 'Tracking and Scheduling'
    },
    educational: {
        id: 'PartnerContractTitle.EducationalServiceProvider',
        defaultMessage: 'Educational Service Provider',
        description: 'Educational Service Provider'
    },
    osNet: {
        id: 'PartnerContractTitle.OSNet',
        defaultMessage: 'OS Net®',
        description: 'OS Net®'
    },
    ngd: {
        id: 'PartnerContractTitle.Ngd',
        defaultMessage: 'OS NGD - Distribution and Data Component Contract',
        description: 'OS NGD - Distribution and Data Component Contract'
    }
});

export const summaryMessages = defineMessages({
    distribution: {
        id: 'PartnerContractSummary.Distribution',
        defaultMessage: 'Enables reselling of OS data for their end user\'s business use. The royalty is subscription-based (annual or multiples thereof), with the exception of OS MasterMap Imagery, which is perpetual.',
        description: 'Distribution'
    },
    consumer: {
        id: 'PartnerContractSummary.ConsumerSolutions',
        defaultMessage: 'Enables the partner to create digital solutions using OS data for consumers. The royalty is subscription-based (daily, weekly, monthly, annual or multiples thereof), perpetual or transactional.',
        description: 'Consumer Solutions'
    },    
    printed: {
        id: 'PartnerContractSummary.PrintedProducts',
        defaultMessage: 'Enables the partner to create paper and merchandise products using OS data. The royalty is perpetual.',
        description: 'Printed Products'
    },
    developer: {
        id: 'PartnerContractSummary.Developer',
        defaultMessage: 'Enables the partner to develop, evaluate, test and demonstrate their products and / or services created using OS data with / to third parties on a royalty-free basis.',
        description: 'Developer'
    },
    businessOnline: {
        id: 'PartnerContractSummary.BusinessOnline',
        defaultMessage: 'Enables the partner to create online applications using OS data for their end user’s business use. The royalty is transactional.',
        description: 'Business Solutions – Online Viewing'
    },
    businessData: {
        id: 'PartnerContractSummary.BusinessData',
        defaultMessage: 'Enables the partner to create a solution from components of OS data for their end user’s internal use. The royalty is either subscription (annual or multiples thereof) or perpetual.',
        description: 'Business Solutions – Data Components'
    },
    trackingScheduling: {
        id: 'PartnerContractSummary.TrackingScheduling',
        defaultMessage: 'Enables the partner to develop tracking and / or scheduling applications using OS data for use by the logistics sector. The royalty is subscription per device.',
        description: 'Tracking and Scheduling'
    },
    educational: {
        id: 'PartnerContractSummary.EducationalServiceProvider',
        defaultMessage: 'Enables the partner to develop applications for schools and universities. The royalty is an annual subscription paid by the partner.',
        description: 'Educational Service Provider'
    },
    osNet: {
        id: 'PartnerContractSummary.OSNet',
        defaultMessage: 'Enables the partner to create positional correction solutions using OS base stations. The royalty is either per device or an annual subscription paid by the partner.',
        description: 'OS Net®'
    },
    ngd: {
        id: 'PartnerContractSummary.Ngd',
        defaultMessage: 'Enables the partner to create a product and/or service using OS NGD data for their end user’s internal use. The royalty is either on a subscription basis (annual or multiples thereof) or perpetual basis.',
        description: 'OS NGD - Distribution and Data Component Contract'
    }
});

export const partnerContracts = {
    distribution: {
        contractIds: ['LPCON-DST', 'LPCON-DSTADDR']
    },
    consumer: {
        contractIds: ['LPCON-CS', 'LPCON-CSADDR']
    },    
    printed: {
        contractIds: ['LPCON-PP']
    },
    developer: {
        contractIds: ['LPCON-DEV']
    },
    businessOnline: {
        contractIds: ['LPCON-BSOV', 'LPCON-BSOVADDR']
    },
    businessData: {
        contractIds: ['LPCON-BSDC', 'LPCON-BSDCADDR']
    },
    trackingScheduling: {
        contractIds: ['LPCON-TS', 'LPCON-TSADDR']
    },
    educational: {
        contractIds: ['LPCON-ESPC']
    },
    osNet: {
        host: 'https://www.ordnancesurvey.co.uk',
        contracts: [
            {
                label: 'OS Net® Framework Interconnect Contract',
                path: '/documents/partners-secure/os-net-framework-interconnect.pdf'
            },
            {
                label: 'Surveying Services Contract under OS Net® Framework Interconnect',
                path: '/documents/partners-secure/os-net-surveying-services-contract.pdf'
            },
            {
                label: 'Agricultural Services Contract under OS Net® Framework Interconnect',
                path: '/documents/partners-secure/os-net-agricultural-services-contract.pdf'
            },
            {
                label: 'OS Net Business Solutions Contract under OS Net® Framework Interconnect',
                path: '/documents/partners-secure/licences/os-net-business-solutions-contract-example-apr24.pdf'
            },
            {
                label: 'OS Net® Service Level Agreement',
                path: '/documents/partners-secure/os-net-service-level-agreement.pdf'
            },
            {
                label: 'Developer Agreement for OS Net®',
                path: '/documents/partners-secure/licences/os-net-developer-agreement-sep20.pdf'
            },
            {
                label: 'Partner Agreement for Pilot of OS Net®',
                path: '/documents/partners-secure/licences/os-net-partner-pilot-agreement-example.pdf'
            }
        ]
    },
    ngd: {
        contractIds: ['LPCON-NGD']
    }
};