import React, {Component} from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DashboardHeader from "./dashboard/DashboardHeader";
import DashboardContent from "./dashboard/DashboardContent";
import withStyles from 'react-jss';

const styles = {
    content: {
        flex: '1 1 auto',
        flexDirection: 'column'
    }
};

export class Dashboard extends Component {
    render() {
        const { classes, loading } = this.props;
        return <div className={classes.content}>
            <DashboardHeader />
            {
                !loading && <DashboardContent/>
            }
        </div>
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object,
    loading: PropTypes.bool
}

function mapStateToProps(state) {
    const { loading } = state.user.current;
    const configLoading = state.config.current.loading;
    return {
        loading: configLoading || loading
    };
}

const styled = withStyles(styles) (Dashboard);
const connected = connect(mapStateToProps)(styled);
export default withRouter(connected);