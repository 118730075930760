import React, {useState} from 'react';
import withStyles from 'react-jss';
import {defineMessages, FormattedMessage} from 'react-intl';
import {DropDownMenu, osColour} from 'omse-components';
import {useDispatch, useSelector} from 'react-redux';
import {getPaymentHistory} from '../../../modules/payments/actions';
import {currencyFormatter, getDateFrom, getDateTo} from './PaymentHelperFunctions'
import {Typography} from '@mui/material';

const messages = defineMessages({
	yearToDate: {
		id: 'PaymentFilters.yearToDate',
		defaultMessage: 'Year to date',
		description: 'yearToDate description'
	},
	monthToDate: {
		id: 'PaymentFilters.monthToDate',
		defaultMessage: 'Month to date',
		description: 'monthToDate description'
	},
	previousMonth: {
		id: 'PaymentFilters.previousMonth',
		defaultMessage: 'Previous Month',
		description: 'previousMonth description'
	},
	last30Days: {
		id: 'PaymentFilters.last30Days',
		defaultMessage: 'Last 30 days',
		description: 'last30Days description'
	},
	last12months: {
		id: 'PaymentFilters.last12months',
		defaultMessage: 'Last 12 months',
		description: 'last12months description'
	},
	failed: {
		id: 'PaymentFilters.failed',
		defaultMessage: 'Failed',
		description: 'failed description'
	},
	ofAnyKind: {
		id: 'PaymentFilters.ofAnyKind',
		defaultMessage: 'All',
		description: 'open status description'
	},
	succeeded: {
		id: 'PaymentFilters.succeeded',
		defaultMessage: 'Paid',
		description: 'close status description'
	},
	totalPaidPeriod: {
		id: 'PaymentFilters.totalPaidPeriod',
		defaultMessage: 'Total paid over {indexContent}: {amount} incl. VAT',
		description: 'text for total paid period'
	},
	totalPaidPeriodWhenZeroAmount: {
		id: 'PaymentFilters.totalPaidPeriodWhenZeroAmount',
		defaultMessage: 'Total paid over {indexContent}: £0.00',
		description: 'text for total paid period when zero amount'
	},
	indexContent: {
		id: 'PaymentFilters.indexContent',
		defaultMessage: 'all available ({filter})',
		description: 'text for total paid period'
	},
	showingPeriod: {
		id: 'PaymentFilters.showingPeriod',
		defaultMessage: 'Showing Period',
		description: 'text for showing Period'
	}
});

const styles = (theme) => ({
	filter: {
		alignItems: 'center',
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(0.5),
			display: 'block'
		}
	},
	status: {
		lineHeight: 1.7,
		color: osColour.status.success,
		paddingRight: '5px'
	},
	summary: {
        paddingBottom: theme.spacing(0.5),
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8125rem'
		}
	},
	text: {
		padding: 0
	},
	spaceButton: {
        marginLeft: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(0),
            marginTop: theme.spacing(1)
		}
	}
});

export function PaymentFilters(props) {
	const {classes} = props;
	const [paidStatus, setPaidStatus] = useState(messages.ofAnyKind);
	const [dateFilter, setDateFilter] = useState(messages.last12months);
	const {result} = useSelector((state) => state.payments.history);
	const dispatch = useDispatch();

	const setSortDate = (item) => {
		const {label, dateFrom, dateTo} = item;
		const isPaidStatus = statusItems.find((item) => item.label === paidStatus).isPaid;
		dispatch(getPaymentHistory(dateFrom, dateTo, isPaidStatus));
		setDateFilter(label);
	};

	const setSortStatus = (item) => {
		const dateItem = dateItems.find((item) => item.label === dateFilter)
		const {dateFrom, dateTo} = dateItem;
		dispatch(getPaymentHistory(dateFrom, dateTo, item.isPaid));
		setPaidStatus(item.label);
	};

	let totalAmountCharged = 0;
	result &&
		result.forEach((item) => {
			if (item.status === 'PAID') {
				totalAmountCharged += parseFloat(item.totalValue);
			}
		});

	const dateItems = [
		{
			label: messages['last12months'],
			action: setSortDate,
			dateFrom: getDateFrom()
		},
		{
			label: messages['yearToDate'],
			action: setSortDate,
			dateFrom: getDateFrom('yearToDate')
		},
		{
			label: messages['monthToDate'],
			action: setSortDate,
			dateFrom: getDateFrom('monthToDate')
		},
		{
			label: messages['previousMonth'],
            action: setSortDate,
			dateFrom: getDateFrom('previousMonth'),
			dateTo: getDateTo()
		},
		{
			label: messages['last30Days'],
			action: setSortDate,
			dateFrom: getDateFrom('last30Days')
		}
	];

	const statusItems = [
		{label: messages['ofAnyKind'], action: setSortStatus, isPaid: ''},
		{label: messages['succeeded'], action: setSortStatus, isPaid: true},
		{label: messages['failed'], action: setSortStatus, isPaid: false}
	];

	const failedStatus = paidStatus === messages.failed;

	return (<>
		<div role='status'>
			{!failedStatus && totalAmountCharged > 0 &&
			<Typography variant='body1' className={classes.summary}>
				<FormattedMessage
					{...messages.totalPaidPeriod}
					values={{
						amount: <span>{currencyFormatter.format(totalAmountCharged)}</span>,
						indexContent: (
							<i>
								<FormattedMessage
									{...messages.indexContent}
									values={{
										filter: <span>{dateFilter.defaultMessage}</span>
									}}
								/>
							</i>
						)
					}}
				/>
			</Typography>
			}
			{!failedStatus && totalAmountCharged === 0 &&
			<Typography variant='body1' className={classes.summary}>
				<FormattedMessage
					{...messages.totalPaidPeriodWhenZeroAmount}
					values={{
						indexContent: (
							<i>
								<FormattedMessage
									{...messages.indexContent}
									values={{
										filter: <span>{dateFilter.defaultMessage}</span>
									}}
								/>
							</i>
						)
					}}
				/>
			</Typography>
			}
			</div>
			<div className={classes.filter} >
				<Typography className={classes.summary}>
					<FormattedMessage {...messages.showingPeriod} />
				</Typography>
				<DropDownMenu
					buttonVariant='outlined'
					value={dateFilter}
                    buttonClassName={classes.spaceButton}
					items={dateItems}
					dataValueKey='label'
					buttonProps={{'aria-label': 'Filter by date'}}
				/>

				<DropDownMenu
					buttonVariant='outlined'
					value={paidStatus}
					buttonClassName={classes.spaceButton}
					items={statusItems}
					dataValueKey='label'
					buttonProps={{'aria-label': 'Filter by status'}}
				/>
			</div>
		</>
	);
}

export default withStyles(styles)(PaymentFilters);
