import { defineMessages } from "react-intl"

export const recentGenericMessages = defineMessages({
    errorTitle: {
        id: 'recentGenericMessages.errorTitle',
        defaultMessage: 'Uh oh. That\'s an error.',
        description: 'Accordion > OnErrorEvent > Error Title'
    },
    errorSummary: {
        id: 'recentGenericMessages.errorSummary',
        defaultMessage: 'Something went wrong whilst loading your {item}. Please refresh the page to try again.',
        description: 'Accordion > OnErrorEvent > Error Summary'
    },
})

export const apiProjectsMessages = defineMessages({
    title: {
        id: 'apiProjectsMessages.title',
        defaultMessage: 'Recent API Projects',
        description: 'API Projects > Accordion Title'
    },
    addNew: {
        id: 'apiProjectsMessages.addNew',
        defaultMessage: 'Create a new project',
        description: 'API Projects > Create New Item'
    },
    viewMore: {
        id: 'apiProjectsMessages.viewMore',
        defaultMessage: 'View all API projects',
        description: 'API Projects > View All Items'
    },
    errorName: {
        id: 'apiProjectsMessages.errorName',
        defaultMessage: 'recent API projects',
        description: 'API Projects > Loading Error Summary Text'
    },
})

export const dataPackagesMessages = defineMessages({
    title: {
        id: 'dataPackagesMessages.title',
        defaultMessage: 'Recent Data Packages',
        description: 'Data Packages > Accordion Title'
    },
    addNew: {
        id: 'dataPackageMessages.addNew',
        defaultMessage: 'Add data package',
        description: 'Data Packages > Create New Item'
    },
    viewMore: {
        id: 'dataPackageMessages.viewMore',
        defaultMessage: 'View all data packages',
        description: 'Data Packages > View All Items'
    },
    errorName: {
        id: 'dataPackagesMessages.errorName',
        defaultMessage: 'recent data packages',
        description: 'Data Packages > Loading Error Summary Text'
    },
    emptyMessage: {
        id: 'dataPackagesMessages.emptyMessage',
        defaultMessage: 'You\'ve not created any data packages yet. You can add them in your recipe library.',
        description: 'Data Packages > No Data Packages Summary Text'
    },
})

export const recipesMessages = defineMessages({
    title: {
        id: 'recipesMessages.title',
        defaultMessage: 'Recent Recipes',
        description: 'Recipes > Accordion Title'
    },
    addNew: {
        id: 'recipesMessages.addNew',
        defaultMessage: 'Create a new recipe',
        description: 'Recipes > Create New Item'
    },
    viewMore: {
        id: 'recipesMessages.viewMore',
        defaultMessage: 'View all recipes',
        description: 'Recipes > View All Items'
    },
    errorName: {
        id: 'recipesMessages.errorName',
        defaultMessage: 'recent recipes',
        description: 'Recipes > Error Summary Text'
    },
    emptyMessage: {
        id: 'recipesMessages.emptyMessage',
        defaultMessage: 'This is where you can view recipes you\'ve created.',
        description: 'Recipes > No Recipes Summary Text'
    },
    emptyAddButton: {
        id: 'recipesMessages.emptyAddButton',
        defaultMessage: 'Create your first recipe',
        description: 'Recipes > No Recipes Create Button'
    },
    instanceAuthor: {
        id: 'recipesMessages.instanceAuthor',
        defaultMessage: 'Author:',
        description: 'Recipes > Recipe Instance Card > Author Name'
    },
    instanceDate: {
        id: 'recipesMessages.instanceDate',
        defaultMessage: 'Creation Date:',
        description: 'Recipes > Recipe Instance Card > Date'
    },
    instanceAddDataPackage: {
        id: 'recipesMessages.instanceAddDataPackage',
        defaultMessage: 'Add data package',
        description: 'Recipes > Recipe Instance Card > Add Data Package Button'
    }
})