import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import './QueryBuilder.css';
import {IconButton, Typography, CircularProgress} from "@mui/material";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {createUseStyles} from 'react-jss';
import {ReactComponent as CloseIcon} from "../../../components/icons/close-large.svg";
import FeatureFilterQueryBuilder from "./FeatureFilterQueryBuilder";


const useStyles = createUseStyles(theme => ({
    filterPanelHeader: {
        display: "flex",
        padding: theme.spacing(3)
    },
    closeButton: {
        marginLeft: 'auto',
    },
    rightSide: {
        [theme.breakpoints.down('md')]: {
            // Should take up the entire width when on mobile.
            flex: '1',
        },
        position: 'relative',
        boxShadow: '0 -5px 6px 3px rgba(0, 0, 0, 0.2)',
        overflow: 'auto',
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
    filterPanelIntro:{
        margin: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column'
    },
    clearFilterButton: {
        marginLeft: theme.spacing(4)
    },
    loadingSpinner: {
        margin: theme.spacing(4)
    }
}));

const messages = defineMessages({
    filterPanelHeading: {
        id: 'FeatureFilterPanel.filterPanelHeading',
        defaultMessage: 'Advanced Filter Options',
        description: 'The heading for feature filter panel'
    },
    filterPanelIntro: {
        id: 'FeatureFilterPanel.filterPanelIntro',
        defaultMessage: 'An optional step for those with advanced OS data knowledge.',
        description: 'The message introducing the filter panel'
    },
    filterPanelMessage: {
        id: 'FeatureFilterPanel.filterPanelMessage',
        // ToDo NGD-235769: add ' {linebreak}{link}' to the end of this line when the video link is reinstated
        defaultMessage: 'Create a filter by clicking {addRule}, then selecting an attribute name from the list. {linebreak}' +
                        'To create a nested group of rules, like brackets in an Excel formula or SQL query, click {addGroup}.',
        description: 'The message explaining usage of the filter panel'
    },
    linkMessage: {
        id: 'FeatureFilterPanel.linkMessage',
        defaultMessage: 'See an example.',
        description: 'Text for the link to the documentation video'
    },
    linkLabel: {
        id: 'FeatureFilterPanel.linkLabel',
        defaultMessage: 'Click here to see an example video of using attribute filtering',
        description: 'Label for the link to the documentation video'
    },
    closeButton: {
        id: 'FeatureFilterPanel.closeButton',
        defaultMessage: 'Close filter options',
        description: 'Aria label for close button'
    }
});

export default function FeatureFilterPanel ({listItem, closePanel}) {
    const classes = useStyles();
    const iconSize = 24;
    const {result: featureTypeSchema} = useSelector(state => state.recipes.featureTypeSchema);
    const selectedFeatureId = listItem?.featureId;
    const intl = useIntl();

    // Make sure that we have the correct schema before showing any content in the filter panel
    const isLoading = !featureTypeSchema || !selectedFeatureId || (featureTypeSchema.featureId !== selectedFeatureId);
    // If we have the correct schema configure the query builder content
    return <div className={classes.rightSide}>
        <div className={classes.filterPanelHeader}>
            <Typography variant='h2' color='primary'>
                <FormattedMessage {...messages.filterPanelHeading} />
            </Typography>
            <IconButton size='small'
                        aria-label={intl.formatMessage(messages.closeButton)}
                        className={classes.closeButton}
                        onClick={() => closePanel(listItem)}>
                <CloseIcon width={iconSize} height={iconSize} />
            </IconButton>
        </div>
        <div className={classes.filterPanelIntro}>
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.filterPanelIntro}/>
            </Typography>
            <Typography variant='body1' paragraph={true}>
                <FormattedMessage {...messages.filterPanelMessage}
                                  values={{
                                      // Todo NGD-235769 : Reinstate this code once we have a tutorial video
                                      // link:<a href="https://example.com" target={"_blank"}  rel="noopener noreferrer" aria-label={intl.formatMessage(messages.linkLabel)}>
                                      //     <FormattedMessage {...messages.linkMessage}/>
                                      // </a>,
                                      linebreak: <br/>,
                                      addRule: <Typography variant='body2' component='span'>+ Add Rule</Typography>,
                                      addGroup: <Typography variant='body2' color='primary' component='span'>+ Add Group</Typography>,
                                  }}
                />
            </Typography>
        </div>
        {isLoading ? <CircularProgress className={classes.loadingSpinner} size={32}/> : <FeatureFilterQueryBuilder/>}
    </div>;
}

FeatureFilterPanel.propTypes = {
    listItem: PropTypes.object,
    closePanel: PropTypes.func.isRequired
}