import userConstants from "../../../constants/user";

export function validateJobTitle(user, jobTitle) {
    let titleError = null;
    // Empty and unmodified job titles should not trigger an error. If we have an invalid title stored
    // then we'll live with it until the user starts editing that field.
    if(jobTitle !== '' && jobTitle !== user.jobTitle) {
        if(jobTitle.length > 100) {
            titleError = userConstants.messages.jobTitleLengthError;
        } else if(!userConstants.nameMatcher.test(jobTitle)) {
            titleError = userConstants.messages.jobTitleValidationError;
        }
    }
    return titleError;
}

export function validatePhoneNumber(user, phone) {
    let phoneError = null;
    // Empty and unmodified phone numbers should not trigger an error. If we have an invalid number stored
    // then we'll live with it until the user starts editing that field.
    if(phone !== '' && phone !== user.telephoneNumber) {
        if(phone.length > 30) {
            phoneError = userConstants.messages.phoneLengthError;
        } else if(!userConstants.phoneMatcher.test(phone)) {
            phoneError = userConstants.messages.phoneValidationError;
        }
    }
    return phoneError;
}