import React from 'react';
import {createUseStyles} from 'react-jss';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {osColour} from "omse-components";

const useStyles = createUseStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440
    },
    cell: {
        fontWeight: 'normal'
    },
    headingText: {
        background: osColour.primary.superLightBerry,
        color: osColour.primary.berry,
        fontWeight: 'bold'
    },
    headerCol: {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        borderRight: '2px solid ' + osColour.primary.berry
    },
    colRowHeader: {
        zIndex: 2 // Less than mobile menu z-index
    },
    headerRow: {
        '&:not(:first-of-type)': {
            zIndex: 1
        },
        borderBottom: '2px solid ' + osColour.primary.berry
    }
});

export default function StickyDataGridTable(props) {
    const {rows, columns} = props;
    const classes = useStyles();

    return <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => {
                                const headerContents = column.renderHeader ? column.renderHeader(column) : column.label;
                                return <TableCell
                                    key={column.id}
                                    align={column.align}
                                    className={index === 0 ? `${classes.colRowHeader} ${classes.headerRow} ${classes.headerCol} ${classes.headingText}` : `${classes.headingText} ${classes.headerRow}`}
                                    style={{minWidth: column.minWidth}}>
                                    {headerContents}
                                </TableCell>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {columns.map((column, index) => {
                                        const value = row[column.field];
                                        const cellContents = column.renderCell ? column.renderCell(value) : value;
                                        return <TableCell className={index === 0 ? `${classes.headerCol} ${classes.headingText}` : classes.cell} key={column.id} align={column.align}>
                                                {cellContents}
                                            </TableCell>;
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>;
}
