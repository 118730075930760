import {EXPIRED, NOT_STARTED} from '../../../shared/free-trial-state';
import {defineMessages, FormattedMessage} from 'react-intl';
import React from 'react';
import {createUseStyles} from 'react-jss';
import HoverTooltip from '../../components/HoverTooltip';
import Typography from '@mui/material/Typography';

const messages = defineMessages({
    startFreeTrial: {
        id: 'ProductFreeTrialHoverTooltip.startFreeTrial',
        defaultMessage: 'Start your free trial to add this API to your project.',
        description: 'Label for the tooltip displayed over the add to project button when a free trial is not started'
    },
    expiredFreeTrialTitle: {
        id: 'ProductFreeTrialHoverTooltip.expiredFreeTrialTitle',
        defaultMessage: 'Your free trial has expired.',
        description: 'Title for the tooltip displayed over the add to project button when a free trial is expired'
    },
    expiredFreeTrialContent: {
        id: 'ProductFreeTrialHoverTooltip.expiredFreeTrialContent',
        defaultMessage: 'To unlock this API please contact us.',
        description: 'Content for the tooltip displayed over the add to project button when a free trial is expired'
    },
});

const useStyles = createUseStyles(theme => ({
    expiredFreeTrialTitle: {
        paddingBottom: theme.spacing(1)
    },
}));

export default function ProductFreeTrialHoverTooltip({product, children}) {
    const classes = useStyles();

    if(product.freeTrialState === NOT_STARTED || product.freeTrialState === EXPIRED) {
        let tooltipTitle;
        if(product.freeTrialState === NOT_STARTED) {
            tooltipTitle = <Typography variant='body1'>
                <FormattedMessage {...messages.startFreeTrial} />
            </Typography>;
        } else {
            tooltipTitle = <div>
                <Typography variant='body2' className={classes.expiredFreeTrialTitle}>
                    <FormattedMessage {...messages.expiredFreeTrialTitle} />
                </Typography>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.expiredFreeTrialContent} />
                </Typography>
            </div>;
        }
         return <HoverTooltip title={tooltipTitle}>
             {/* The span is required because disabled inputs (such as buttons) won't work as Tooltip children. */}
             <span>{children}</span>
         </HoverTooltip>;
    } else {
        return children;
    }
}