import React, {Fragment, Component} from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import withStyles from 'react-jss';
import {Tooltip, linkButton, osColour} from "omse-components";
import Button from '@mui/material/Button';
import VisuallyHidden from './util/VisuallyHidden';

function styles(theme) {
    return {
        filledButton: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: '50px'
        },
        popper: {
          minWidth: 200,
          lineHeight: 1.5,
          zIndex: 9999
        },
        paper: {
            display: 'block'
        },
        clickAwayTooltip: {
            float: 'right'
        },
        tooltipLink: {
            ...linkButton,
            minWidth: 'auto',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'right'
            }
        },
        tooltipSpan:{
            color: osColour.link.base,
            fontFamily: 'Source Sans Pro, sans-serif',
            fontSize: '1rem',
            textAlign: 'left',
            '&:hover':{
                cursor: 'pointer'
            }
        },
        focused: {
            outline: 'auto'
        }
    };
}

export class ClickAwayTooltip extends Component {

    state = {
        open: false
    };

    buttonRef = React.createRef();
    containerRef = React.createRef();

    toggleToolTip = () => {
        this.setState({open: !this.state.open});
    }

    handleClickAway = (e) => {
        if(this.state.open) {
            this.setState({open: false});
            e.preventDefault();
        }
    }

    render() {
        const {classes, label, title, body, toolTipValue, icon, disabled, ariaLabel, id, placement, maxWidth} = this.props;
        const {open} = this.state;

        const clickableToolTipValue = (
            <span className={classes.tooltipSpan} onClick={this.toggleToolTip} ref={this.buttonRef}>
                {toolTipValue}
            </span>
        );

        const tooltipId = 't_' + id;
        return <Fragment>
            <span className={classes.clickAwayTooltip}>
                {!!toolTipValue ?
                    <FormattedMessage {...label} values={{toolTipValue: clickableToolTipValue}}/>
                        :
                    <Button className={classes.tooltipLink}
                        onClick={this.toggleToolTip} 
                        ref={this.buttonRef} 
                        disabled={disabled} 
                        aria-describedby={open ? tooltipId : undefined}
                        classes={{focusVisible: classes.focused}}
                        disableRipple>
                        {icon && <span aria-hidden={true}>{icon}</span>}
                        {ariaLabel && <VisuallyHidden>{ariaLabel}</VisuallyHidden>}
                        {label && <FormattedMessage {...label}/>}
                    </Button>
                }
                <Tooltip open={open}
                    id={tooltipId}
                    container={this.containerRef.current}
                    anchorEl={this.buttonRef.current}
                    title={title}
                    body={body}
                    classes={{popper: classes.popper, paper: classes.paper}}
                    handleClickAway={this.handleClickAway}
                    placement={placement}
                    maxWidth={maxWidth || 200}>
                </Tooltip>
            </span>
            <span aria-live='polite' ref={this.containerRef}></span>
        </Fragment>;
    }
}

ClickAwayTooltip.propTypes = {
    id: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.object,
    title: PropTypes.object,
    body: PropTypes.object.isRequired,
    icon: PropTypes.object,
    disabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
    maxWidth: PropTypes.number
};

export default withStyles(styles)(ClickAwayTooltip);
