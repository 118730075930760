import React from 'react';
import { Typography } from "@mui/material";
import {commercialContactUs} from '../../../util/routes';
import {defineMessages,FormattedMessage} from 'react-intl';

const messages = defineMessages({
    title:{
        id: "contactus.title",
        defaultMessage: "Contact Us",
        description: "Title of the contact us component"
    },
    body: {
        id: "contactus.body",
        defaultMessage: "If you require more transactions, please {link}"
    }
})

export default function ContactUs() {
    return (<>
        <Typography variant="h2"><FormattedMessage {...messages.title}/></Typography>
        <Typography variant="body1">
            <FormattedMessage {...messages.body} values={{link:<a target="_blank" rel="noreferrer" href={commercialContactUs}>contact us</a>}}/>
        </Typography>
    </>)
}
