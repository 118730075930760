import React from "react";
import PropTypes from "prop-types";
import { Tooltip, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import styled from "@emotion/styled";
import { osColour } from "omse-components";

const StyledSwitch = styled(Switch)`
    padding: 8px;
    &.MuiSwitch-root {
        height: 100%;
    }
    &.MuiSwitch-root .Mui-checked {
        color: ${osColour.neutral.white};
        opacity: 1;
    }
    & .MuiSwitch-track {
        border-radius: ${22 / 2}px;
        height: 14px;
        width: 34px;
        border: 2px solid;
        border-color: ${osColour.primary.berry};
        background-color: white;
        opacity: 1;
    }
    & .MuiSwitch-thumb {
        width: 8px;
        height: 8px;
        margin: 4px;
    }
    & .MuiSwitch-switchBase {
        color: ${osColour.primary.berry};
        &.Mui-checked {
            & + .MuiSwitch-track {
                opacity: 1;
                background-color: ${osColour.primary.berry};
            }
        }
    }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
    gap: 10px;
    margin-left: 0px;
    margin-right: 0px;
    &.Mui-disabled {
        cursor: not-allowed
    }
`;

const StyleTypography = styled(Typography)`
    font-weight: 600;
`;

const StyledTooltip = styled(({ className, disabled, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))`
    & .MuiTooltip-tooltip {
        max-width: 350px;
        line-height: 28px;
        box-shadow: 0px 4px 8px 0px rgba(43, 37, 90, 0.25);
        padding: ${({ theme }) => theme.spacing(3)};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }
`;



const CustomSwitch = ({
    id,
    label,
    labelPlacement,
    tooltip,
    onChange,
    disabled,
    checked = false,
}) => {
    const ariaDescriptionId = "switch-label";

    const switchComponent = (
        <StyledFormControlLabel
            control={
                <StyledSwitch
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    inputProps={{
                        "role": "switch",
                        "aria-checked": checked,
                        "aria-describedby": ariaDescriptionId,
                        "id": id
                    }}
                />
            }
            label={
                <StyleTypography tabIndex={0} id={ariaDescriptionId} component={'span'}>
                    {label}
                </StyleTypography>
            }
            labelPlacement={labelPlacement}
        />
    );

    if (tooltip) {
        return (
            <StyledTooltip title={tooltip}>
                {switchComponent}
            </StyledTooltip>
        )
    }

    return switchComponent;
};

CustomSwitch.propTypes = {
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    labelPlacement: PropTypes.oneOf(["bottom", "end", "start", "top"]),
    tooltip: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
};

export default CustomSwitch;
