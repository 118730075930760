import React, {Fragment} from 'react';
import {ReactComponent as TickIcon} from "../../../components/icons/tick.svg";
import {ReactComponent as CrossIcon} from "../../../components/icons/close-small.svg";
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {osColour} from 'omse-components';

const useStyles = createUseStyles(theme => ({
    fieldMessage: {
        position: 'relative', 
        display: 'none'
    },
    fieldIcon: {      
        flexShrink: 0,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(-2.75)
        }
    },
    invalid: {
        color: osColour.status.error
    },
    valid: {
        color: osColour.status.success
    }
}));

export default function FieldValidationMessage(props) {
    const classes = useStyles(props);
    const {valid, invalid} = props;
    return <Fragment>
        <Typography component="div" variant="caption" className={classNames(classes.fieldMessage, classes.invalid, props.classes.fieldMessage, props.classes.invalid)} role="alert">
            <CrossIcon width={24} height={24} alt='invalid' className={classNames(classes.fieldIcon)} />
            {invalid &&
                <FormattedMessage {...invalid} />
            }
        </Typography>
        <Typography component="div" variant="caption" className={classNames(classes.fieldMessage, classes.valid, props.classes.fieldMessage, props.classes.valid)}>
            <TickIcon width={22} height={22} alt='valid' className={classNames(classes.fieldIcon)} />
            {valid &&
                <FormattedMessage {...valid} />
            }
        </Typography>
    </Fragment>
}

FieldValidationMessage.defaultProps = {
    classes: {}
}