import React, {useMemo} from 'react';
import {defineMessages, useIntl} from "react-intl";
import useLocationState from "./useLocationState";
import recipeLibraryStyles from "./recipeLibraryStyles";
import {DropDownMenu} from 'omse-components';

const messages = defineMessages({
    latestCreationDate: {
        id: 'RecipeLibrarySort.latestCreationDate',
        defaultMessage: 'Creation date - latest first',
        description: 'Text for sort recipe dropdown.'
    },
    earliestCreationDate: {
        id: 'RecipeLibrarySort.earliestCreationDate',
        defaultMessage: 'Creation date - earliest',
        description: 'Text for sort recipe dropdown.'
    },
    recipeName: {
        id: 'RecipeLibrarySort.recipeName',
        defaultMessage: 'Recipe name',
        description: 'Label for sort recipe dropdown.'
    },
    authorName: {
        id: 'RecipeLibrarySort.authorName',
        defaultMessage: 'Author name',
        description: 'Label for sort recipe dropdown.'
    },
    sortLabel: {
        id: 'RecipeLibrarySort.sortLabel',
        defaultMessage: 'Sort by',
        description: 'Label for sort recipe dropdown.'
    }
});

export const SORT = 'sort';

export default function RecipeLibrarySort() {
    const intl = useIntl();
    const classes = recipeLibraryStyles();
    const [sort, setSort] = useLocationState(SORT);
    const options = useMemo(() => {
        return [{
            label: intl.formatMessage(messages.latestCreationDate),
            value: ''
        }, {
            label: intl.formatMessage(messages.earliestCreationDate),
            value: 'earliestCreationDate'
        }, {
            label: intl.formatMessage(messages.recipeName),
            value: 'recipeName'
        }, {
            label: intl.formatMessage(messages.authorName),
            value: 'authorName'
        }];
    }, [intl]);

    return <div className={classes.select}>
        <DropDownMenu
            buttonId='recipe-sort-by'
            buttonVariant='outlined'
            items={options}
            value={sort}
            label={intl.formatMessage(messages.sortLabel)}
            onChange={item => setSort(item.value)}
        />
    </div>
}
