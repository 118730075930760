import React from 'react';
import {ReactComponent as Arrow} from "../../../components/icons/backward-arrow-large.svg";
import {CircularProgress, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {defineMessages, FormattedMessage} from 'react-intl';
import {
    contentPadding,
    LinkButton,
    Notification,
    osColour,
    ExternalLink
} from 'omse-components';
import {styles} from '../DownloadStyles';
import {useSelector} from "react-redux";
import classNames from 'classnames';
import FeatureVersions from "./FeatureVersions";

const messages = defineMessages({
    themeTitle: {
        id: 'ItemDetails.themeTitle',
        defaultMessage: 'Theme',
        description: 'Title for Theme details'
    },
    collectionTitle: {
        id: 'ItemDetails.collectionTitle',
        defaultMessage: 'Collection',
        description: 'Title for Collections details'
    },
    featureTypeTitle: {
        id: 'ItemDetails.featureTypeTitle',
        defaultMessage: 'Feature Type',
        description: 'Title for Feature Type details'
    },
    collectionFooterTitle: {
        id: 'ItemDetails.collectionFooterTitle',
        defaultMessage: 'Collections',
        description: 'Title for sub-collections of a theme'
    },
    featureTypeFooterTitle: {
        id: 'ItemDetails.featureTypeFooterTitle',
        defaultMessage: 'Feature Types',
        description: 'Title for sub-feature-types of a collection'
    },
    error: {
        id: 'ItemDetails.error',
        defaultMessage: 'There was a problem loading the feature types properties. Please try again later or {link} if the problem persists.',
        description: 'Label for when an error has occurred loading the feature types properties'
    }
});

const useDownloadStyles = createUseStyles(styles);
const useStyles = createUseStyles((theme, ...args) => {
    return {
        root: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto'
        },
        header: (props) => {
            const styles = {
                [theme.breakpoints.down('sm')]: {
                    paddingTop: 0,
                }
            };
            if(props?.reducedPadding) {
                styles.padding = 0;
                styles.paddingBottom = theme.spacing(2);
            }
            return styles;
        },
        headerTopRow: {
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
        },
        content: (props) => {
            const styles = {
                maxWidth: 'none',
                paddingBottom: theme.spacing(4),
                [theme.breakpoints.down('md')]: {
                    flexShrink: 0, // Prevent content from shrinking on mobile so the parent container scrolls instead.
                },
            };
            if(props?.reducedPadding) {
                styles.paddingLeft = 0;
                styles.marginTop = theme.spacing(2);
                styles[theme.breakpoints.down('md')] = {
                    marginTop: 0
                };
            }
            return styles;
        },
        resetLinkOverride: {
            color: "#0071bc",
            paddingBottom: props => props?.reducedPadding ? 0 : theme.spacing(2)
        },
        featureTypePropertyBox: {
            border: `1px solid ${osColour.neutral.mist}`,
            padding: theme.spacing(1),
            // Assign max width to list items so they don't grow too large. But below we allow the content to expand to the
            // entire size of the container with maxWidth: none, to have the scrollbar on the far right. Otherwise it
            // sits halfway along the page at 1000px and looks a bit strange.
            maxWidth: contentPadding.maxWidth,
            marginBottom: theme.spacing(2),
            '&:last-of-type': {
                marginBottom: 0,
            }
        },
        subItemsHeading: {
            paddingBottom: theme.spacing(1)
        },
        itemDetail: {
            marginBottom: theme.spacing(2),
            '&:last-of-type': {
                marginBottom: 0,
            }
        },
        resetLinkIcon:{
            width:'16px',
            height: '16px',
            paddingRight: theme.spacing(1),
            paddingBottom: props => props?.reducedPadding ? 0 : '14px'
        }
    };
});

export default function ItemDetails({
                                        listItem,
                                        showResetButton,
                                        resetButtonLabel,
                                        onResetClick,
                                        reducedPadding,
                                        fixedSchemaVersion,
                                        tryToCloseFilterPanel,
                                        resetButtonIcon
}) {
    const downloadClasses = useDownloadStyles();
    const classes = useStyles({reducedPadding});

    let headerTitle;
    let footerTitle;
    let subItems;
    let isFeatureType = false;
    // Theme
    if(listItem.collections) {
        headerTitle = messages.themeTitle;
        footerTitle = messages.collectionFooterTitle;
        subItems = listItem.collections;
    }
    // Collection
    else if(listItem.featureTypes) {
        headerTitle = messages.collectionTitle;
        footerTitle = messages.featureTypeFooterTitle;
        subItems = listItem.featureTypes;
    }
    // Feature type
    else {
        isFeatureType = true;
        headerTitle = messages.featureTypeTitle;
        subItems = []
    }

    let {result, error} = useSelector(state => state.recipes.featureTypeSchema);
    if(!listItem.featureId === result?.featureId) {
        result = null;
    }
    const backArrow = <Arrow className={classes.resetLinkIcon}/>;
    const resetButton = <LinkButton onClick={onResetClick}>
        {resetButtonIcon && backArrow}
        <Typography variant='body1' color='primary' className={classes.resetLinkOverride} >
            <FormattedMessage {...resetButtonLabel} />
        </Typography>
    </LinkButton>;
    return <div className={classes.root}>
        <header className={classNames(downloadClasses.root, classes.header, reducedPadding ? classes.headerReducedPadding : null)}>
            {showResetButton === 'top' && resetButton}
            <div className={classes.headerTopRow}>
                <Typography variant='subtitle1' component='h2' color='primary' >
                    <FormattedMessage {...headerTitle} />
                </Typography>
                {showResetButton === 'right' && resetButton}
            </div>
            <Typography variant='h1' component='h3' color='primary' className={classes.instructionsTitle}>
                {listItem.label}
            </Typography>
            {listItem.description && <Typography variant='body1' paragraph={isFeatureType}>
                {listItem.description}
            </Typography>}
            {
                isFeatureType && <FeatureVersions featureTypeId={listItem.featureId}
                                                  versions={fixedSchemaVersion ? [fixedSchemaVersion] : listItem.versions}
                                                  tryToCloseFilterPanel={tryToCloseFilterPanel}
                />
            }
        </header>
        <div className={classNames(downloadClasses.contentWithMobilePadding, classes.content)}>
            {!isFeatureType && <Typography variant='subtitle1' component='h4' color='primary' className={classes.subItemsHeading}>
                <FormattedMessage {...footerTitle} />
            </Typography> }
            {subItems.map(item => <div key={item.label} className={classes.itemDetail}>
                <Typography variant='h2' component='h5' color='primary'>
                    {item.label}
                </Typography>
                {item.description && <Typography variant='body1'>
                    {item.description}
                </Typography>}
            </div>)}
            {isFeatureType && (!result && !error) && <CircularProgress size={32} />}
            {isFeatureType && result?.properties && Object.entries(result.properties)
                .map(([id, value]) => <div key={id} className={classes.featureTypePropertyBox}>
                    <Typography variant='h2' color='primary'>
                        {id}
                    </Typography>
                    {value && <Typography variant='body1'>
                        {value.description}
                    </Typography>}
                </div>)}
            {isFeatureType && error && <RecipesErrorNotification/>}
        </div>
    </div>;
}

ItemDetails.defaultProps = {
    showResetButton: true,
};

function RecipesErrorNotification() {
    const classes = useStyles();
    return <Notification variant='error'
                         role='alert'
                         appearance='inline'
                         className={classes.error}>
        <Typography variant='body1'>
            <FormattedMessage {...messages.error} values={{ link:
                <ExternalLink type='support' />
            }}
            />
        </Typography>
    </Notification>
}