import React, {useState, useCallback, useEffect} from 'react';
import {defineMessages, useIntl, FormattedMessage} from 'react-intl';
import {useSelector, useDispatch} from 'react-redux';
import {loadSavedPolygonCatalogue, storePolygon} from "../../../../../modules/polygon";
import {CommonDialog, CommonDialogActions, Notification} from 'omse-components';
import useActionIdSelector from "../../../../../hooks/useActionIdSelector";
import ConfirmOverwriteModal from "./ConfirmOverwriteModal";
import {setMapTool} from "../../../../../modules/toolbar";
import FileCollection from "./FileCollection";
import FileList from "./FileList";
import {
    Input,
    InputLabel,
    List,
    Typography
} from '@mui/material';
import styles from './styles';

const messages = defineMessages({
    title: {
        id: 'SavePolygonModal.title',
        defaultMessage: 'Save to polygon library',
        description: 'Title for the save polygon modal'
    },
    save: {
        id: 'SavePolygonModal.save',
        defaultMessage: 'Save',
        description: 'Label for the save button'
    },
    saveAs: {
        id: 'SavePolygonModal.saveAs',
        defaultMessage: 'Save as:',
        description: 'Label for name input'
    },
    saveAsPlaceholder: {
        id: 'SavePolygonModal.saveAsPlaceholder',
        defaultMessage: 'Give your polygon a name',
        description: 'Placeholder text for the name input'
    },
    saved: {
        id: 'SavePolygonModal.saved',
        defaultMessage: 'Saved',
        description: 'Label for the Saved entry in the navigation list'
    },
    validateChars: {
        id: 'SavePolygonModal.validateChars',
        defaultMessage: 'The name is invalid. Please enter an name that does not contain forward or backward slashes.',
        description: 'Message shown if the name contains "/" or "\\"'
    },
    validateLength: {
        id: 'SavePolygonModal.validateLength',
        defaultMessage: 'The name is too long. Please enter a shorter name.',
        description: 'Message shown if the name is too long'
    },
    error409: {
        id: 'SavePolygonModal.error409',
        defaultMessage: 'Another user has modified the polygon. Please choose another name, or cancel and retry.',
        description: 'Message shown if the polygon has been modified by someone else'
    },
    error404: {
        id: 'SavePolygonModal.error404',
        defaultMessage: 'Another user has deleted the polygon. Please choose another name, or cancel and retry.',
        description: 'Message shown if the polygon has been deleted by someone else'
    },
    error: {
        id: 'SavePolygonModal.error',
        defaultMessage: 'There was an error saving your polygon. Please try again later and contact support if the problem continues.',
        description: 'Message shown if the polygon could not be saved.'
    },
    catalogueError: {
        id: 'SavePolygonModal.catalogueError',
        defaultMessage: 'There was an error loading the polygon library. Please try again later and contact support if the problem continues.',
        description: 'Message shown if the polygon library could not be loaded.'
    },
    saveComplete: {
        id: 'SavePolygonButton.saveComplete',
        defaultMessage: 'Your polygon has been saved to the polygon library.',
        description: 'Aria-Label for the save button on the map toolbar'
    }
});


export default function SavePolygonModal() {
    const [name, setName] = useState('');
    const [askForConfirmation, setAskForConfirmation] = useState(false);
    const intl = useIntl();

    const catalogue = useSelector(state => state.polygon.savedCatalogue.result);
    const catalogueError = useSelector(state => state.polygon.savedCatalogue.error);
    const catalogueLoading = useSelector(state => state.polygon.savedCatalogue.loading);

    const geometry = useSelector(state => state.dataPackages.draftOrder.AOI);
    const [store, dispatchStore] = useActionIdSelector("polygon.store");
    const dispatch = useDispatch();

    const onClose = useCallback(result => {
        let nextTool;
        if(result === true) {
            nextTool = {
                type: 'temporaryNotification',
                component: <Notification variant='success'
                                             appearance='toast'
                                             onClose={() => dispatch(setMapTool())}
                                             autoClose={true}>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.saveComplete} />
                </Typography>
                </Notification>
            };
        }
        dispatch(setMapTool(nextTool));
    }, [dispatch]);

    useEffect(() => {
        dispatch(loadSavedPolygonCatalogue());
    },[dispatch]);

    useEffect(() => {
        if(store.result !== undefined) {
            // We are done, it's time to close the dialog
            onClose(true);
        }
    }, [store, onClose]);

    const placeholder = intl.formatMessage(messages.saveAsPlaceholder);
    const classes = styles();
    const working = store.working;
    const error = store.error;
    const trimmedName = name.trim();

    const existingPolygons = (catalogue && catalogue.saved) || [];
    const currentPolygon = existingPolygons.find(p => p.id === trimmedName);

    let nameValid = trimmedName.length > 0;
    let errorMessage;
    if(trimmedName.length > 30) {
        errorMessage = <FormattedMessage {...messages.validateLength}/>;
        nameValid = false;
    } else if(trimmedName.indexOf('/') !== -1 || trimmedName.indexOf('\\') !== -1) {
        errorMessage = <FormattedMessage {...messages.validateChars}/>;
        nameValid = false;
    } else if(error && error.httpStatus) {
        const message = messages['error' + error.httpStatus] || messages.error;
        errorMessage = <FormattedMessage {...message}/>;
    } else if(catalogueError) {
        errorMessage = <FormattedMessage {...catalogueError}/>;
    }

    function handleKey(event) {
        if(event.key === 'Enter') {
            event.preventDefault();
            onConfirm();
        }
    }

    const onConfirm = useCallback(function onConfirm() {
        if(!nameValid) {
            return;
        }
        let etag = currentPolygon && currentPolygon._etag;

        if(etag) {
            setAskForConfirmation(true);
        } else {
            dispatchStore(storePolygon(trimmedName, geometry, etag));
        }
    }, [nameValid, currentPolygon, trimmedName, geometry, dispatchStore]);

    function closeOverwriteModal(result) {
        setAskForConfirmation(false);
        if(result === true) {
            let etag = currentPolygon._etag;
            dispatchStore(storePolygon(trimmedName, geometry, etag));
        }
    }

    let savedLabel = intl.formatMessage(messages.saved);

    const savedCollection = {
        path: savedLabel,
        loading: catalogueLoading,
        files: existingPolygons
    }

    const setSelectedFile = useCallback(function setSelectedFile(newFile) {
        const id = newFile.id;
        if(name === id) {
            // This file was already selected. Go ahead and save the file
            onConfirm();
        } else {
            setName(id);
        }
    }, [name, setName, onConfirm]);

    const actions = <CommonDialogActions confirmLabel={messages.save}
                                         confirmAllowed={nameValid}
                                         onClose={onClose}
                                         onConfirm={onConfirm}
                                         working={working}
                                         />;

    return <CommonDialog onClose={onClose}
                         title={messages.title}
                         actions={actions}
                         error={errorMessage}
                         contentClassName={classes.dialogContent}
                         muiClasses={{paperWidthSm: classes.muiDialogRoot}}
    >
        <div className={classes.inputWrapper}>
            <InputLabel htmlFor='name'>
                <FormattedMessage {...messages.saveAs}/>
            </InputLabel>
            <Input id='name'
                   className={classes.input}
                   value={name}
                   onChange={e => setName(e.target.value)}
                   onKeyUp={handleKey}
                   placeholder={placeholder}
                   autoFocus={true}
                   disableUnderline={true}
                   required={true}/>
        </div>
        <div className={classes.listWrapper}>
            <List className={classes.folderList}>
                <FileCollection setSelected={() => {}}
                                selectedPath={savedLabel}
                                collection={savedCollection}/>
            </List>
            <FileList files={existingPolygons}
                      setSelectedFile={setSelectedFile}
                      selectedFile={currentPolygon}/>
        </div>
        {
            askForConfirmation && <ConfirmOverwriteModal name={name} onClose={closeOverwriteModal}/>
        }
    </CommonDialog>;
}