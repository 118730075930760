import {ACTION_RESET_SUFFIX} from 'omse-components';

const PREFIX = 'action:psga:';

export const LOAD_PSGA_ORGS_ACTION = PREFIX + 'orgs_load';
export const LOADED_PSGA_ORGS_ACTION = PREFIX + 'orgs_loaded';
export const ACCEPT_PSGA_ORG_ACTION = PREFIX + 'accept';
export const DECLINE_PSGA_ORG_ACTION = PREFIX + 'decline';

export function getPsgaOrgs() {
    return {
        type: LOAD_PSGA_ORGS_ACTION
    }
}

export function acceptOrg(job, phone) {
    return {
        type: ACCEPT_PSGA_ORG_ACTION,
        job,
        phone
    }
}

export function clearDeclineOrg() {
    return {
        type: DECLINE_PSGA_ORG_ACTION + ACTION_RESET_SUFFIX
    }
}

export function declineOrg() {
    return {
        type: DECLINE_PSGA_ORG_ACTION
    }
}