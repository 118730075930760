import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {createUseStyles} from 'react-jss';
import {Typography} from "@mui/material";
import {InlineDialog} from "omse-components"
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import routes from '../../../../../util/routes';

const messages = defineMessages({
    title:{
        id:'AddAPIDialog.heading',
        defaultMessage: 'Add an Energy and Infrastructure plan to your account',
        description: 'Heading for API dialog'
    },
    content1: {
        id: 'AddAPIDialog.content1',
        defaultMessage: 'It looks like you don\'t currently have an API plan setup. ',
        description: "first paragraph of the dialog content"
    },
    documentationLink: {
        id: 'AddAPIDialog.link',
        defaultMessage: 'API documentation',
        description: 'link translation'
    },
    content2: {
        id: 'AddAPIDialog.content2',
        defaultMessage: 'To add an Energy and Infrastructure API plan, view our pricing or look up our {link}.',
        description: "second paragraph of the dialog content"
    },
    viewDocsButton: {
        id:"AddAPIDialog.viewDocsButton",
        defaultMessage: "View API documentation",
        description: "button text for viewing api documentation"
    },
    viewPricingButton: {
        id:"AddAPIDialog.viewPricingButton",
        defaultMessage: "View pricing",
        description: "button text for viewing the API pricing information"
    },
    addApiPlanButton: {
        id:"AddAPIDialog.addApiPlanButton",
        defaultMessage: "Add an API plan",
        description:"button text for adding an API plan"
    }
});
const useStyles = createUseStyles(theme => ({
    content1: {
        marginBottom: theme.spacing(2),
        fontWeight: "bold"
    }
}))

export default function AddCommercialApiDialog(props) {
    const {hasManageApiPermission} = props;
    const intl = useIntl();
    const classes = useStyles();
    const history = useHistory();

    const goTo = (route) => {
        history.push(route);
    }

    let leftLabel = intl.formatMessage(messages.viewDocsButton);
    let rightLabel = intl.formatMessage(messages.viewPricingButton);
    let leftButtonRoute = routes.documentation;
    let rightButtonRoute = routes.pricingApiPlans;

    if(hasManageApiPermission){
        leftLabel = intl.formatMessage(messages.viewPricingButton);
        rightLabel = intl.formatMessage(messages.addApiPlanButton);
        leftButtonRoute = routes.pricingApiPlans;
        rightButtonRoute = routes.addApiPlan;
    }

    return <InlineDialog
        title={messages.title}
        closeLabel={leftLabel}
        onClose={()=>goTo(leftButtonRoute)}
        confirmLabel={rightLabel}
        onConfirm={()=>goTo(rightButtonRoute)}
    >
        <Typography variant="body1" className={classes.content1}>
            <FormattedMessage {...messages.content1} />
        </Typography>
        <Typography variant="body1">
            <FormattedMessage {...messages.content2} values={{
                link: <Link to={routes.documentation}><FormattedMessage {...messages.documentationLink}/></Link>
            }}/>
        </Typography>
    </InlineDialog>
}
