import React, {Component} from 'react';
import {defineMessages} from 'react-intl';
import CompletionSummary from '../CompletionSummary';
import PropTypes from 'prop-types';

const messages = defineMessages({
    achievement1: {
        id: 'WMTSCompletionSummary.achievement1',
        defaultMessage: `You've obtained the URL and generated an API key for OS Maps API.`,
        description: 'Achievement 1'
    },
    achievement2: {
        id: 'WMTSCompletionSummary.achievement2',
        defaultMessage: `You've used the URL and Key to apply an OS Maps API layer in {software}.`,
        description: 'Achievement 2'
    },
    achievement3: {
        id: 'WMTSCompletionSummary.achievement3',
        defaultMessage: `You've selected one of the styled outputs and coordinate reference systems for the WMTS
layer and displayed it in {software}.`,
        description: 'Achievement 3'
    }
});

export class WMTSCompletionSummary extends Component {

    achievements = [
        messages.achievement1,
        messages.achievement2,
        messages.achievement3
    ];

    render() {
        const {software} = this.props;
        return <CompletionSummary achievements={this.achievements} software={software} api={`wmts`} />
    }
}

WMTSCompletionSummary.propTypes = {
    software: PropTypes.string.isRequired
}

export default WMTSCompletionSummary;