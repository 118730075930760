import {combineReducers} from 'redux';
import {createLoadingReducer, createLoadingMiddleware} from 'omse-components';
import {withOrganisation} from '../util/organisation';

const PREFIX = 'action:projects:';
const LOAD_PROJECTS_ACTION   = PREFIX + 'load';
const LOADED_PROJECTS_ACTION = PREFIX + 'loaded';

const ASSOCIATED_PROJECTS_PREFIX = 'action:associatedprojects:';
const LOAD_ASSOCIATED_PROJECTS_ACTION = ASSOCIATED_PROJECTS_PREFIX + 'load';
const LOADED_ASSOCIATED_PROJECTS_ACTION = ASSOCIATED_PROJECTS_PREFIX + 'loaded';

export function getProjects() {
    return {
        type: LOAD_PROJECTS_ACTION
    };
}

export function getAssociatedProjects(orgId) {
    return {
        type: LOAD_ASSOCIATED_PROJECTS_ACTION,
        orgId
    };
}

export function clearAssociatedProjects() {
    return {
        type: LOADED_ASSOCIATED_PROJECTS_ACTION,
        result: null
    }
}

export const projectsReducer = combineReducers({
    current: createLoadingReducer(LOAD_PROJECTS_ACTION, LOADED_PROJECTS_ACTION, true),
    associated: createLoadingReducer(LOAD_ASSOCIATED_PROJECTS_ACTION, LOADED_ASSOCIATED_PROJECTS_ACTION, true)
});

function prepareCall(action, store) {
    return withOrganisation('/api/projects', store);
}

function prepareAssociatedProjectsCall(action) {
    return '/api/projects?orgId=' + action.orgId;
}

export const projectsMiddleware = [
    createLoadingMiddleware(LOAD_PROJECTS_ACTION, LOADED_PROJECTS_ACTION, prepareCall),
    createLoadingMiddleware(LOAD_ASSOCIATED_PROJECTS_ACTION, LOADED_ASSOCIATED_PROJECTS_ACTION, prepareAssociatedProjectsCall)
];
