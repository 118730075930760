import React, {Fragment, useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Menu, MenuItem} from '@mui/material';
import {ReactComponent as ExpandIcon} from './expand-active.svg';
import {ReactComponent as CollapseIcon} from './collapse-active.svg';
import buttonStyles from "./buttonStyles";
import {useIntl, FormattedMessage} from "react-intl";
import classNames from 'classnames';
import useTooltipHandler from "./useTooltipHandler";
import {useDispatch} from 'react-redux';
import {setMapTool, resetTooltip} from "../../../../modules/toolbar";

export default function ToolbarSplitButton(props) {
    const {options, menuAriaLabel} = props;
    const intl = useIntl();
    const [showMenu, setShowMenu] = useState(false);
    const [currentOptionIndex, setCurrentOptionIndex] = useState(0);
    const classes = buttonStyles();
    const anchor = useRef();
    const dispatch = useDispatch();

    const currentOption = options[currentOptionIndex];

    const hoverCallback = useTooltipHandler(currentOption.tooltip);

    useEffect(() => {
        if(currentOption.disabled) {
            // If a disabled tool is active then we should turn it off.
            if(currentOption.active) {
                dispatch(setMapTool());
            }

            // The current option is not valid any more. It's not very helpful to leave
            // that tool as the current option, so try to find an option that is not disabled,
            // and switch to that instead.
            let newIndex = options.findIndex(o => !o.disabled);
            if(newIndex === -1) {
                // we may as well go back to the start of the list
                newIndex = 0;
            }
            if(newIndex !== currentOptionIndex) {
                setCurrentOptionIndex(newIndex);
            }
        }
    }, [currentOption, options, currentOptionIndex, setCurrentOptionIndex, dispatch]);

    function menuOptionClick(index) {
        setShowMenu(false);
        if(index !== -1) {
            setCurrentOptionIndex(index);
            options[index].onClick();
        } else {
            // Clear out the placeholder tool that we created
            dispatch(setMapTool());
        }
    }
    function menuClick() {
        setShowMenu(true);

        // Clear the current tooltip. The tooltip can't clear itself because the menu overlay has prevented the
        // mouseleave event from happening.
        dispatch(resetTooltip());

        // By putting an new tool onto the map we prevent the default tool from activating while the menu is active.
        dispatch(setMapTool({
            type: 'splitButtonPlaceholder'
        }));
    }

    return <Fragment>
        <ButtonGroup color='primary'
                     variant={currentOption.active ? 'contained' : 'outlined'}
                     ref={hoverCallback}
        >
            <Button disableTouchRipple={true} disableRipple={true}
                    onClick={currentOption.onClick}
                    disabled={currentOption.disabled}
                    classes={{root: classNames(classes.button, classes.splitButton, classes.verticalIconButton), startIcon: classes.icon}}
                    aria-label={intl.formatMessage(currentOption.ariaLabel)}
                    startIcon={currentOption.icon}
                    ref={anchor}
            >
                <FormattedMessage {...currentOption.label}/>
            </Button>
            <Button disableTouchRipple={true} disableRipple={true}
                    aria-label={intl.formatMessage(menuAriaLabel)}
                    classes={{root: classNames(classes.button, classes.splitMenuButton, classes.verticalIconButton), startIcon: classes.icon}}
                    startIcon={showMenu ? <CollapseIcon/> : <ExpandIcon/>}
                    onClick={menuClick}
            />
        </ButtonGroup>
        {
            showMenu && <Menu open={true}
                              onClose={() => menuOptionClick(-1)}
                              anchorEl={anchor.current}
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                              getContentAnchorEl={null}
            >
                {
                    options.map((item, index) => <MenuItem key={item.label.id}
                                                           onClick={() => menuOptionClick(index)}
                                                           disabled={item.disabled}
                    >
                        {item.icon}
                        <FormattedMessage {...item.ariaLabel}/>
                    </MenuItem>)
                }
            </Menu>
        }
    </Fragment>;
}

ToolbarSplitButton.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.object,
        ariaLabel: PropTypes.object,
        icon: PropTypes.node,
        onClick: PropTypes.func,
        tooltip: PropTypes.object,
        active: PropTypes.bool,
        disabled: PropTypes.bool
    })).isRequired,
    menuAriaLabel: PropTypes.object
};
