import React, {useEffect, useState} from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import {createUseStyles} from 'react-jss';
import { Box, Typography, Button } from "@mui/material";
import FeatureCheck from "../components/FeatureCheck";
import { EAIAPI } from "../../shared/features";
import { useDispatch, useSelector } from "react-redux";
import { getApiPlans, getApiTiers, getApiProducts } from "../modules/commercialApiPlans/actions";
import useHeading from "../hooks/useHeading";
import ContentHeader from '../components/ContentHeader';
import routes from '../util/routes';
import { COM3_CATALOGUE } from "../../shared/catalogues";
import { CatalogueTag } from "../components/CatalogueTag";
import { contentPadding, AddButton, osColour } from "omse-components";
import ApiPlanDetails from "./apiPlans/ApiPlanDetails/ApiPlanDetails"
import {hasManageEaiApiPlan} from '../util/permissions';
import ApiPlansDropdown from "./apiPlans/ApiPlanDetails/ApiPlansDropdown";
import { useHistory } from "react-router-dom";
import { EAI_TIER_OVERAGE } from "../../shared/constants";
import Link from '../components/Link';

const messages = defineMessages({
    title:{
        id: "myApiPlans.title",
        defaultMessage: "My API Plans",
        description: "Title"
    },
    subtitle: {
        id: "myApiPlans.subtitle",
        defaultMessage: "Purchased plans",
        description: "Title"
    },
    overageWarning: {
        id: "apiPlanDetails.overageWarning",
        defaultMessage:"Looks like your previously purchased plan was consumed or has expired. You should think about purchasing a new API plan soon."
    },
    noPlan: {
        id: "myApiPlans.noPlans",
        defaultMessage: "There is no active API plans. You should think about purchasing a new API plan soon.",
        description: "Title"
    },
    backLabel: {
        id: "myApiPlans.backLabel",
        defaultMessage: "Back to dashboard",
        description: "back label"
    },
    currentPlanTitle: {
        id: "myApiPlans.currentPlanTitle",
        defaultMessage: "Current Plan",
        description: "current plan title"
    },
    otherPlanTitle: {
        id: "myApiPlans.otherPlanTitle",
        defaultMessage: "Other Plans",
        description: "other plan title"
    },

    pendingIntro: {
        id: "apiPlanDetails.pendingPlans",
        defaultMessage: "This is a list of pending Plans. These plans have not started yet and will be activated once your current plan has been consumed or expired.",
        description: "pending intro"
    },
    previousIntro: {
        id: "apiPlanDetails.previousPlans",
        defaultMessage: "This is a list of previous Plans. These plans have already been consumed or expired and will not be activated again.",
        description: "previous intro"
    },
    pricingLink:{
        id: "apiPlanDetails.pricingLink",
        defaultMessage: "Pricing information",
        description: "Pricing information"
    }
});

const useStyles = createUseStyles(theme => ({
    content: {
        paddingBottom: contentPadding.bottom,
        paddingLeft: contentPadding.left,
        paddingRight: contentPadding.right,
        maxWidth: contentPadding.maxWidth,
        marginTop: contentPadding.top,
        [theme.breakpoints.down('xs')]: {
            padding: contentPadding.mobile,
            paddingTop: 0,
            marginTop: 0
        }
    },
    errorMessage: {
        color: osColour.status.error
    },
    myApiPlansContainer:{
        position: 'relative',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]:{
            marginTop:theme.spacing(5)
        }
    },
    buttonContainer:{
        position:'absolute',
        right:0,
        bottom:'100%',
        display:"flex",
        margin:theme.spacing(2),
        "& button": {
            marginLeft: theme.spacing(1),
        },
    },
    catalogueTag:{
        position: "absolute",
        right: theme.spacing(1),
        top:theme.spacing(1)
    },

}));

export const PENDING = 'pending';
export const PREVIOUS = 'previous';

export default function MyApiPlans() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const title = useHeading();
    const history = useHistory();

    const user = useSelector(state => state.user.current.result);
    const apiPlans = useSelector(state => state.commercialApiPlans.plans.result);
    const tiers = useSelector(state => state.commercialApiPlans.tiers.result);
    const products = useSelector(state => state.commercialApiPlans.products.result);
    const [demoProduct, setDemoProduct] = useState(products?.find(product=>product.demoProduct) || null);

    const goToAddApiPlan = () => {
        history.push(routes.addApiPlan);
    }

    useEffect(() => {
        dispatch(getApiPlans());
        dispatch(getApiTiers());
        dispatch(getApiProducts());
    }, [dispatch]);

    useEffect(()=>{
        const dp = products?.find(product=>product.demoProduct);
        setDemoProduct(dp)
    }, [products]);

    if(apiPlans && demoProduct && tiers){
        apiPlans.forEach(plan => {
            plan.tier = tiers.find(tier => tier.id === plan.tierId);

            plan.tier.unitPrice = demoProduct.tiers.find(productTier => productTier.tierId === plan.tierId).unitPrice;
            plan.openingTransactions = Math.floor(Number(plan.openingBalance) / plan.tier.unitPrice);
            plan.provisionalTransactions = Math.floor(Number(plan.provisionalBalance) / plan.tier.unitPrice);
            plan.usedTransactions = plan.openingTransactions - plan.provisionalTransactions;
        })
    }

    const activePlan = apiPlans?.find(plan => plan.provisionalActivePlan);
    const pendingPlans = apiPlans?.filter(plan => plan.openingBalance === plan.provisionalBalance);
    const previousPlans = apiPlans?.filter(plan => {
        if(!plan.provisionalEnd){
            return false;
        }

        const today = new Date();
        const planEnd = new Date(plan.provisionalEnd);
        return planEnd < today;
    });

    let planDetails = <Typography variant="body2" className={classes.errorMessage} data-testid="no-plan-warning">
        <FormattedMessage {...messages.noPlan}/>
    </Typography>;
    if(activePlan?.tierId === EAI_TIER_OVERAGE){
        planDetails = <Typography variant="body2" className={classes.errorMessage} data-testid="overage-warning">
            <FormattedMessage {...messages.overageWarning}/>
        </Typography>
    } else if(activePlan){
        planDetails = <ApiPlanDetails plan={activePlan} demoProductName={demoProduct?.name}/>
    }

    return (
        <FeatureCheck feature={EAIAPI}>
            <Box>
                <ContentHeader title={title} backPath={routes.dashboard} backLabel={messages.backLabel} subtitle={messages.subtitle}>
                    <Box className={classes.catalogueTag}>
                        <CatalogueTag type={COM3_CATALOGUE} size='md' />
                    </Box>
                </ContentHeader>
            </Box>
            <Box className={classes.content}>
                <Box className={classes.myApiPlansContainer}>
                    <Typography variant="h2">
                        <FormattedMessage {...messages.currentPlanTitle}/>
                    </Typography>
                    <Box className={classes.buttonContainer}>
                        <Link path={routes.pricingApiPlans}>
                            <Button variant="outlined" color="primary" >
                                <FormattedMessage {...messages.pricingLink} />
                            </Button>
                        </Link>
                        {hasManageEaiApiPlan(user) &&
                            <AddButton
                                action={goToAddApiPlan}
                                label="Purchase API Plan"
                                variant="contained"
                                ariaLabel="purchase api plan button"
                                color="primary"
                            />
                        }
                    </Box>
                    {planDetails}
                </Box>
                <Box>
                    <Typography variant="h2">
                        <FormattedMessage {...messages.otherPlanTitle}/>
                    </Typography>
                    <ApiPlansDropdown type={PENDING} plans={pendingPlans} title="Pending Plans">
                        <Typography variant="body1">
                            <FormattedMessage {...messages.pendingIntro}/>
                        </Typography>
                    </ApiPlansDropdown>
                    <ApiPlansDropdown type={PREVIOUS} plans={previousPlans} title="Previous Plans">
                        <Typography variant="body1">
                            <FormattedMessage {...messages.previousIntro}/>
                        </Typography>
                    </ApiPlansDropdown>
                </Box>
            </Box>
        </FeatureCheck>
    )
}
