import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {osColour} from "omse-components";
import {FormattedMessage} from 'react-intl';
import {createUseStyles} from 'react-jss';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import { useTheme } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };
    
    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    ref={gridRef}
                    // Set width constant (PSGA form content) here as percentage value does not work as expected
                    // likely due to MUI's popper component sitting outside of any parent elements
                    width={340}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};

const styles = createUseStyles(({
    orgBox: {
        marginTop: '0px'
    },
    fieldError: {
        color: osColour.status.error
    }
}));

export default function AutoCompleteListBox({options, error, onChange, loading, id, ...props}) {
    const classes = styles();
    return (<Fragment>
        <Autocomplete
            id={id}
            freeSolo
            fullWidth={true}
            options={options}
            selectOnFocus
            onInputChange={onChange}
            loading={loading}
            ListboxComponent={ListboxComponent}
            size="small"
            renderInput={(params) => (
                <TextField
                    {...params}
                    margin="normal"
                    variant="outlined"
                    error={error ? true : false}
                    classes={{root: classes.orgBox}}
                />
            )}
        />
        {error &&
            <Typography variant='caption' component='div' className={classes.fieldError} role='alert'>
                <FormattedMessage {...error} />
            </Typography>
        }
    </Fragment>
    )
}