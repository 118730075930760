/** 
 * Creates a key for use in i18n messages.
 * 
 * @param {string} prefix: e.g. variant of component.
 * @param {string} stem: e.g. message element name such as Title, Body.
 */
function messageKey(prefix = '', stem = '') {
    return prefix + stem;
}

/** 
 * Builds a message key function specific to a particular type of message element.
 * 
 * @param {string} messageElement: Title, Body etc.
 */ 
export const makeMessageKeyFn = messageElement => prefix => messageKey(prefix, messageElement);

/** 
 * Common message element util functions
 */
export const titleKey = makeMessageKeyFn('Title');
export const bodyKey = makeMessageKeyFn('Body');
export const subtitleKey = makeMessageKeyFn('Subtitle');
export const buttonKey = makeMessageKeyFn('Button');