import PropTypes from 'prop-types';
import BarSegment from './BarSegment';
import styled from '@emotion/styled';

const StyledBar = styled.div`
    width: 100%;
    height: 100%;
    & .breakdownContainer {
        display: flex;
        width: 100%;
        position: relative;
        height: inherit;
    }
`;

export const Bar = ({data, total}) => {
    return (
        <StyledBar>
            {data.breakdown &&
                <div className='breakdownContainer'>
                    {
                        data.breakdown.map(subdata => {
                            return <BarSegment
                                key={subdata.name + subdata.data}
                                item={subdata}
                                total={total} />
                        })
                    }
                </div>
            }
        </StyledBar>
    )
};

Bar.propTypes = {
    data: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    style: PropTypes.object
};
export default Bar;