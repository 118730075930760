import React from 'react';
import Header from "./Header";
import Content from './Content';
import tilesImage from "./otaOverview/os-ngd-api-tiles-overview.png";
import Typography from '@mui/material/Typography';
import Document from './Document';
import FeatureCheck from "../../components/FeatureCheck";
import UsingOTA from "./UsingOTA";
import DocLinks from "./DocLinks";
import { ExternalLink } from 'omse-components';

export default function OTAOverview() {
    return <FeatureCheck feature='ngdTiles'><Document>
        <Header back={true}>OS NGD API – Tiles: Overview</Header>
        <Content>
            <Typography variant='body1' paragraph={true} component='div'>
                <p>
                    OS NGD API – Tiles offers you a vector tile service powered by the OS National Geographic Database
                    (OS NGD). It provides a detailed and customisable basemap based on the latest <ExternalLink
                        type="generic"
                        href="https://docs.ogc.org/is/20-057/20-057.html"
                        message="OGC API – Tiles"
                    /> standard to help you create stunning and interactive web maps. It can be used with most
                    web mapping libraries, including OpenLayers, MapLibre GL JS and Leaflet. A major benefit of vector
                    tiles is that they are optimised for use across the internet and are great for building interactive
                    web maps that allow users to zoom, pan, rotate, tilt and more.
                </p>
                <p>
                    You have a choice between using Ordnance Survey styles or creating your own. You can customise the
                    content and style to create a professional-looking map that perfectly meets your needs, matches your
                    branding, and pleases your customers.
                </p>
                <p>
                    OS NGD API – Tiles is available in two projections; British National Grid for GB data and Web
                    Mercator, a global coordinate system.
                </p>
            </Typography>
            <img src={tilesImage} alt='OS NGD API – Tiles API: map containing a highways network'/>
            <UsingOTA/>
            <DocLinks product='ota' hasGettingStarted={true} hasTechnicalSpec={true} hasDemo={false}/>
        </Content>

    </Document></FeatureCheck>
}
